import { Editor as TinyReactEditor } from "@tinymce/tinymce-react"
import cx from "classnames"
import Editor, { EditorProps } from "components/commons/Editor/Editor"
import { LocaleNamespace } from "config/intl/helpers"
import AuthContext from "contexts/AuthContext"
import { useTranslation } from "hooks/specific/useTranslation"
import { forwardRef, useContext, useEffect, useMemo, useState } from "react"
import uniqid from "uniqid"
import { ReportingEditorItemBuilder, variabilize } from "./ContextEditor.helpers"
import { ReportingEditorVarStringifiedStyle } from "./ContextEditor.styles"
import { buildDefaultToolbarButtons } from "./ContextEditorToolbarButtons"

export type IContextEditorDynamicContent = (store: any, translator: any) => string

export interface ContextEditorProps {
	className?: string
	content: IContextEditorDynamicContent | string
	store?: {
		custom?: Record<any, any> | null
		localeConfig?: {
			ns?: LocaleNamespace
			prePath?: string
		}
		// Merge store
		merge?: boolean
	}
	onChange?: (innerHTML: string) => void
	paths?: Array<string>
	ignoredPaths?: Array<string>
	saveContentDisabled?: undefined | boolean
	exportPdfDisabled?: undefined | boolean
	saveData?: Record<any, any> | null
	init?: EditorProps["init"]
}

/**
 * ContextEditor Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param {Array<string>} paths - same as whitelist
 * @param template
 * @constructor
 * @return {React.FC<ContextEditorProps>}
 */
const ContextEditor = forwardRef<TinyReactEditor, ContextEditorProps>((props: ContextEditorProps, ref) => {
	const {
		className,
		content,
		ignoredPaths,
		store,
		onChange,
		paths,
		saveContentDisabled,
		exportPdfDisabled,
		saveData,
		init,
	} = props

	const editorRef = ref
	const authContext = useContext(AuthContext)
	const customStore = store?.merge ? { ...authContext, ...store?.custom } : store?.custom || authContext
	const ns = store?.localeConfig?.ns || LocaleNamespace.Context
	const t = useTranslation(ns, store?.localeConfig?.prePath)
	const [_content, setContent] = useState<string | null>(null)

	// Handle changes
	useEffect(() => {
		const newContent: string = variabilize(content, customStore, t)
		setContent(newContent)

		// @ts-ignore
		// editorRef.current?.editor?.setContent(newContent); // Use this method exists to update the content. Use Tiny WYSIWYG as React Controlled Component doesn't work very well when the value is updated.
	}, [content, customStore])

	// Context menus
	const memoizedContextMenu = useMemo(() => {
		if (!paths?.length) return
		const { items } = new ReportingEditorItemBuilder(
			// @ts-ignore
			() => editorRef.current?.editor,
			t,
			paths,
			// @ts-ignore
			ignoredPaths,
			customStore
		).build()
		return [{ id: uniqid(), items }]
	}, [paths, customStore])

	// Toolbar buttons
	const ToolbarButtons = buildDefaultToolbarButtons(t, saveContentDisabled, exportPdfDisabled, saveData)

	const classes: string = cx("context-editor", className)

	/**
	 * Handle editor change
	 * @param innerHTML
	 */
	function handleChange(innerHTML: string): void {
		if (_content === innerHTML) return
		setContent(innerHTML)
		if (onChange) onChange(innerHTML)
	}

	return (
		<Editor
			ref={editorRef}
			className={classes}
			content={_content!}
			// @ts-ignore
			contextMenus={memoizedContextMenu}
			onChange={handleChange}
			init={init}
			toolbarMenuButtons={{ additional: ToolbarButtons }}
			style={ReportingEditorVarStringifiedStyle}
		/>
	)
})

export default ContextEditor
