import { HiboutikProductGenerated, PatientInsurance } from "@audiowizard/common"
import { Badge } from "reactstrap"

type insuranceBadgeProps = {
	patientAmc: PatientInsurance
	selectedProducts?: HiboutikProductGenerated[]
	hasBothEars: boolean
}

const InsuranceBadge = ({ patientAmc, selectedProducts, hasBothEars }: insuranceBadgeProps): JSX.Element => {
	return (
		<div>
			<Badge color="secondary py-2">{`Mutuelle : ${patientAmc?.label ?? "Inconnue"}`}</Badge>
			{patientAmc &&
				selectedProducts?.filter((p) => p.categoryName ?? p.category_name === "Appareils auditifs").length !==
					0 && (
					<>
						{selectedProducts?.some((product) => +product.class === 1) &&
						selectedProducts?.some((product) => +product.class === 2) ? null : (
							<>
								<span className="font-weight-bold mx-4">Prise en charge</span>
								<Badge color="primary" className="py-2">
									{selectedProducts?.some((product) => +product.class === 1) &&
										`${hasBothEars ? +patientAmc.classOneCover * 2 : patientAmc.classOneCover} €`}
									{selectedProducts?.some((product) => +product.class === 2) &&
										`${hasBothEars ? +patientAmc.classTwoCover * 2 : patientAmc.classTwoCover} €`}
								</Badge>
							</>
						)}
					</>
				)}
		</div>
	)
}
export default InsuranceBadge
