import { PropsWithChildren } from "react"
import "./Layout.scss"
import cx from "classnames"

export interface LayoutProps {
	tag: React.ElementType
}

export type LayoutHeaderProps = PropsWithChildren<{
	tag?: React.ElementType
	hide?: boolean
}>

export type LayoutSidebarProps = PropsWithChildren<{
	tag?: React.ElementType
	hide?: boolean
}>

export type LayoutContentProps = PropsWithChildren<{
	tag?: React.ElementType
	hide?: boolean
}>

function Layout(props: LayoutProps): JSX.Element {
	const { tag: Tag, ...rest } = props
	const classNames = cx("layout")
	return <Tag {...rest} className={classNames} />
}

function Header(props: LayoutHeaderProps): JSX.Element {
	const { tag: Tag = "div", hide = false, children } = props
	const classNames = cx("layout__header", { "layout__header--hide": hide })
	return <Tag className={classNames}>{children}</Tag>
}

function Sidebar(props: LayoutSidebarProps): JSX.Element {
	const { tag: Tag = "div", hide = false, children } = props
	const classNames = cx("layout__sidebar", { "layout__sidebar--hide": hide })
	return <Tag className={classNames}>{children}</Tag>
}

function Content(props: LayoutContentProps): JSX.Element {
	const { tag: Tag = "div", hide = false, children } = props
	const classNames = cx("layout__content", { "layout__content--hide": hide })
	return <Tag className={classNames}>{children}</Tag>
}

Layout.Header = Header
Layout.Sidebar = Sidebar
Layout.Content = Content

Layout.defaultProps = {
	tag: "div",
}

export default Layout
