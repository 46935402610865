import API from "../../../../API"
import { User } from "@audiowizard/common"
import { useQuery } from "react-query"

export const LaboratoryTeamKey = "LaboratoryTeamKey"
export default function useLaboratoryTeam() {
	return useQuery({
		queryFn: () =>
			API.findAll<User[]>("USERS_API", "/with_laboratories?isArchived[]=0&isArchived[]=1&pagination=false"),
		queryKey: [LaboratoryTeamKey],
	})
}
