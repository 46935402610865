import { Input, Table } from "antd"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { Col } from "reactstrap"
import OmniSearchSante from "../../components/effects/OmnisearchSante"
import Button from "../../components/utils/Button"
import { PROFESSIONNEL_DE_SANTES } from "../../config"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import { convertPhoneNumber, formatPhoneNumber, prescriberCategory } from "../../services/functions"
import PrescribersModal from "./Prescribers.modal"

// TODO: voir comment on affiche ORL/Medecin
// Ca peut etre un code couleur, une colonne ou deux en plus, des checkboxs, ...

const MesPresripteurs = () => {
	const { setOrls, setDoctors } = useContext(AuthContext)
	const location = useLocation()
	const [loading, setLoading] = useState(true)
	const [prescribers, setPrescribers] = useState([])
	const [searchPrescribers, setSearchPrescribers] = useState([])
	const [apiPrescribers, setApiPrescribers] = useState([])
	const [prescriber, setPrescriber] = useState({})
	const [openAdd, setOpenAdd] = useState(false)
	const [openModify, setOpenModify] = useState(false)
	const [errors, setErrors] = useState({
		email: "",
		firstName: "",
		lastName: "",
		gender: "",
		city: "",
		adress: "",
		phone: "",
		cpo: "",
	})

	const columns = [
		{
			title: "Prenom",
			dataIndex: "firstName",
			sorter: (a, b) => {
				a = a.firstName || ""
				b = b.firstName || ""
				return a.localeCompare(b)
			},
			width: "20%",
		},
		{
			title: "Nom",
			dataIndex: "lastName",
			sorter: (a, b) => {
				a = a.lastName || ""
				b = b.lastName || ""
				return a.localeCompare(b)
			},
			width: "20%",
		},
		{
			title: "Email",
			dataIndex: "email",
			sorter: (a, b) => {
				a = a.email || ""
				b = b.email || ""
				return a.localeCompare(b)
			},
			render: (email) => <span>{email || "non renseigné"}</span>,
			width: "30%",
		},
		{
			title: "Telephone",
			dataIndex: "phone",
			sorter: (a, b) => {
				a = a.phone || ""
				b = b.phone || ""
				return a.localeCompare(b)
			},
			render: (phone) => (
				<span className={"badge badge-pill " + (phone ? "badge-info" : "badge-secondary")}>
					{phone ? formatPhoneNumber(phone) : "Non renseigné"}
				</span>
			),
			width: "15%",
		},
		{
			title: "Ville",
			dataIndex: "city",
			sorter: (a, b) => {
				a = a.city || ""
				b = b.city || ""
				return a.localeCompare(b)
			},
			render: (city) => <span>{city || "non renseigné"}</span>,
			width: "15%",
		},
	]

	const refreshPrescribers = () => {
		setLoading(true)
		API.findAll("PRESCRIBERS_API", "?pagination=false&order[id]=DESC").then((data) => {
			setLoading(false)
			setOrls(data.filter((p) => p.category === "ORL"))
			setDoctors(data.filter((p) => p.category === "DOCTOR"))
			setPrescribers(data)
			setSearchPrescribers(data)
		})
	}

	useEffect(() => {
		refreshPrescribers()
		if (location.state?.prescriber) {
			setPrescriber(location.state.prescriber)
			setOpenModify(true)
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [])

	const handlePrescriberClickOmnisearch = (p, target) => {
		if (target !== "API") {
			toast.info("Le prescripteur est déjà enregistré")
			setPrescriber(p)
			return
		}
		API.sante(PROFESSIONNEL_DE_SANTES, p["@id"] || p.id || "").then((data) => {
			if (data.identificationNationalePP.substr(0, 1) === "8")
				data.identificationNationalePP = data.identificationNationalePP.substr(1)
			const wizardPrescriber = {
				firstName: data.prenom || "",
				lastName: data.nom || "",
				city: data.libelleCommune || "",
				cpo: data.codePostal ? data.codePostal.toString() : "",
				adress:
					data.numeroVoie && data.libelleTypeVoie && data.libelleVoie
						? data.numeroVoie + " " + data.libelleTypeVoie + " " + data.libelleVoie
						: data.bureauCedex || "",
				adeli: data.finess || "",
				rpps: data.identificationNationalePP || "",
				gender: data.codeCivilite === "M" ? "HOMME" : "FEMME",
				email: data.email || "",
				phone: convertPhoneNumber(data.telephone) || convertPhoneNumber(data.telephoneDeux) || null,
				url: data.email && data.email.substr(data.email.indexOf("@")),
				category: prescriberCategory(data),
				categoryDetails: data.libelleSavoireFaire || "",
				idApiSante: data.id || "",
			}

			API.findAll("PRESCRIBERS_API", `?rpps=${wizardPrescriber.rpps}`).then((data) => {
				if (data.length === 0) {
					API.create("PRESCRIBERS_API", wizardPrescriber).then(() => {
						refreshPrescribers()
						toast.success(`Le prescripteur ${wizardPrescriber.lastName} a bien été ajouté`)
					})
				} else {
					toast.info(`Le prescripteur ${wizardPrescriber.lastName} est déjà enregistré`)
				}
			})
		})
	}

	const handleSubmitAddPrescriber = (event) => {
		if (event) event.preventDefault()

		const newPrescriber = {
			firstName: prescriber.firstName || "",
			lastName: prescriber.lastName || "",
			email: prescriber.email || "",
			gender: prescriber.gender || "",
			phone: convertPhoneNumber(prescriber.phone),
			city: prescriber.city || "",
			adress: prescriber.adress || "",
			cpo: prescriber.cpo || "",
			category: prescriber.category || "ORL",
			adeli: prescriber.adeli || "",
			rpps: prescriber.rpps || "",
			finess: prescriber.finess || "",
		}

		API.create("PRESCRIBERS_API", newPrescriber)
			.then(() => {
				refreshPrescribers()
				setOpenAdd(false)
				toast.success(`Le Dr ${prescriber.lastName} a bien été ajouté`)
			})
			.catch((error) => {
				if (error.response.status === 422) {
					toast.error("Veuillez renseigner un nom et un prénom")
				} else {
					toast.error("Erreur lors de l'enregistrement")
				}
			})
	}

	const handleSubmitChangePrescriber = (event) => {
		event && event.preventDefault()

		const newPrescriber = {
			firstName: prescriber.firstName || "",
			lastName: prescriber.lastName || "",
			email: prescriber.email || "",
			gender: prescriber.gender || "",
			phone: convertPhoneNumber(prescriber.phone) || null,
			city: prescriber.city || "",
			adress: prescriber.adress || "",
			cpo: prescriber.cpo || "",
			category: prescriber.category || "ORL",
			adeli: prescriber.adeli || "",
			rpps: prescriber.rpps || "",
			finess: prescriber.finess || "",
		}
		API.update("PRESCRIBERS_API", prescriber.id, newPrescriber)
			.then(() => {
				refreshPrescribers()
				setOpenModify(false)
				toast.success(`Le Dr ${prescriber.lastName} a bien été modifié`)
			})
			.catch(({ response }) => {
				const { violations } = response?.data
				if (violations) {
					const apiErrors = {}
					violations.forEach(({ propertyPath, message }) => {
						apiErrors[propertyPath] = message
						toast.error("Erreur : " + message)
					})
					setErrors(apiErrors)
				}
			})
	}

	return (
		<Col>
			<SectionHeader title="Liste des prescripteurs" />
			<div className="row d-flex justify-content-center my-4">
				<Button
					className="d-none"
					id="btn-modalChangePrescriber"
					dataToggle="modal"
					dataTarget="#modalChangePrescriber"
				/>
				<button
					className="btn"
					type="button"
					data-action="omnisearch-open"
					data-target="#prescriberSearch"
					data-cy="trigger-omnisearch-prescriber">
					<span className="btn-inner--icon d-block">
						<i className="fad fa-search fa-2x" />
					</span>
					<span className="btn-inner--icon d-block pt-2">Rechercher un prescripteur à ajouter</span>
				</button>
			</div>
			<div className="mt-5 mb-3">
				<Input
					size="large"
					className="rounded"
					placeholder="Rechercher par prénom, nom, téléphone, adeli, rpps."
					onChange={(e) => {
						const debounce = _.debounce((search) => {
							const sanitizedSearch = _.replace(_.lowerCase(_.deburr(search)), /\s/g, "")

							setSearchPrescribers(
								prescribers.filter((prescriber) => {
									const onFilterValues = [
										prescriber?.firstName,
										prescriber?.lastName,
										prescriber?.adeli,
										formatPhoneNumber(prescriber?.phone),
										prescriber?.rpps,
									]

									return onFilterValues
										.filter((isTruthy) => isTruthy)
										.map((value) => _.replace(_.lowerCase(_.deburr(value)), /\s/g, ""))
										.some((value) => value.includes(sanitizedSearch))
								})
							)
						}, 200)

						debounce(e.target.value)
					}}
				/>
			</div>
			<div className="table-responsive">
				<Table
					rowKey={"id"}
					columns={columns}
					dataSource={searchPrescribers}
					pagination={{ pageSize: 15, position: ["bottomLeft"] }}
					loading={loading}
					showHeader
					onRow={(prescriber) => {
						return {
							onClick: () => {
								setOpenModify(true)
								setPrescriber(prescriber)
							},
						}
					}}
					bordered={true}
				/>
				<OmniSearchSante
					id="prescriberSearch"
					target="prescribers"
					placeholder="Recherche du prescripteur"
					titleTextSearch="Prescripteurs suggérés"
					ressources={apiPrescribers}
					onClick={handlePrescriberClickOmnisearch}
					setRessources={setApiPrescribers}
					defaultRessource={prescribers}
					button={
						<Button
							className="btn btn-outline-primary btn-block"
							dataToggle="modal"
							dataTarget="#modalAddPrescriber"
							onClick={() => {
								setOpenAdd(true)
								setPrescriber({})
							}}>
							Ajouter un prescripteur
						</Button>
					}
				/>

				<PrescribersModal
					id="modalAddPrescriber"
					title="Ajouter un prescripteur"
					handleSubmitPrescriber={handleSubmitAddPrescriber}
					errors={errors}
					setErrors={setErrors}
					prescriber={prescriber}
					setPrescriber={setPrescriber}
					open={openAdd}
					setOpen={setOpenAdd}
					chooseCategory
				/>

				<PrescribersModal
					id="modalChangePrescriber"
					title="Modifier un prescripteur"
					handleSubmitPrescriber={handleSubmitChangePrescriber}
					errors={errors}
					setErrors={setErrors}
					prescriber={prescriber}
					setPrescriber={setPrescriber}
					open={openModify}
					setOpen={setOpenModify}
					chooseCategory
				/>
			</div>
		</Col>
	)
}

export default MesPresripteurs
