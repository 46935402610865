/* eslint-disable react-hooks/exhaustive-deps */
import useHasRole from "components/Hooks/useHasRole"
import dayjs from "dayjs"
import { Link, NavLink } from "react-router-dom"
import * as AuthApi from "../../services/AuthApi"
import CacheSystem from "../../services/CacheSystem/Cache.ts"
import { clearServiceWorkerCache } from "../../services/functions"
import DropDownAffiliate from "./AffiliateDropDown"

const DropDownMenu = () => {
	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")
	const isManager = useHasRole("ROLE_MANAGER")
	const isFranchisedManager = useHasRole("ROLE_MANAGER_FRANCHISED")
	const isAssistant = useHasRole("ROLE_ASSISTANT")
	const isAudio = useHasRole("ROLE_USER")

	const UserRoleIsAllowed = isManager || isFranchisedManager || isAssistant || isAudio

	const handleLogout = (event) => {
		if ("$crisp" in window) {
			window.CRISP_TOKEN_ID = null
			window.$crisp.push(["do", "session:reset"])
		}
		event.preventDefault()
		CacheSystem.clear()
		AuthApi.logout()
		window.location.href = "/"
	}

	if (isAffiliate || isAffiliateManager) {
		return <DropDownAffiliate />
	} else
		return (
			<div
				className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow"
				aria-labelledby="navbar-primary_dropdown_1">
				{UserRoleIsAllowed && (
					<NavLink
						to="/gestion-compte/profil/informations"
						className="dropdown-item dropdown-link  py-1 px-2">
						<i className="fad fa-briefcase fa-fw" />
						<span>Gestion du compte</span>
					</NavLink>
				)}

				{UserRoleIsAllowed && (
					<NavLink
						to="/parametres/personnalisation/options"
						className="dropdown-item dropdown-link  py-1 px-2">
						<i className="fad fa-cog fa-fw" />
						<span>Paramètres</span>
					</NavLink>
				)}
				{UserRoleIsAllowed && (
					<NavLink
						to="/gestion-compte/abonnement/parrainages"
						className=" bg-gradient-primary dropdown-item current-offer-link text-white py-1 px-2">
						<i className="fas fa-gift fa-fw dropdown-icon-solid" />
						<span>Offre du moment</span>
					</NavLink>
				)}
				<Link
					to="#"
					onClick={async () => {
						clearServiceWorkerCache()
					}}
					className="dropdown-item text-warning py-1 px-2">
					<i className="fad fa-database fa-fw" />
					<span>Supprimer le cache</span>
				</Link>

				<Link to="/se-connecter" onClick={handleLogout} className="dropdown-item text-danger py-1 px-2">
					<i className="fad fa-sign-out-alt fa-fw" />
					<span>Déconnexion</span>
				</Link>
				<div className="dropdown-divider py-1 px-2" />
				{window.BUILD_DATE && (
					<div style={{ fontSize: "12px", textAlign: "center" }}>
						Dernière mise à jour <br />
						{dayjs(window.BUILD_DATE).format("DD/MM/YY à HH:mm")}{" "}
					</div>
				)}
			</div>
		)
}

export default DropDownMenu
