import { lazy } from "react"
import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, TeletransRoutePrefix, TeletransRoutes } from "./routes"
import CoverageDemandListPage from "../../pages/CoverageDemand/CoverageDemandListPage"

const TeletransSuivi = lazy(() => import("../../pages/FSV/SuiviTeletransmission"))
const TeletransControle = lazy(() => import("../../pages/FSV/PaiementTeletransSuiviRecu"))
const TeletransFsv = lazy(() => import("../../pages/FSV/FsvEmbedded"))

function TeletransRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={TeletransRoutePrefix}>
			<CardItem name="Contrôle" url={noPrefix(TeletransRoutes.Controle)} component={TeletransControle} />
			<CardItem name="Suivi" url={noPrefix(TeletransRoutes.Suivi)} component={TeletransSuivi} />
			<CardItem name="FSV" url={noPrefix(TeletransRoutes.Fsv)} component={TeletransFsv} />
			<CardItem name="PEC" url={noPrefix(TeletransRoutes.CoverageDemand)} component={CoverageDemandListPage} />
		</CardWithTabs>
	)
}

export default TeletransRouter
