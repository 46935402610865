import { lazy } from "react"
import { CardWithTabs, CardItem } from "components/CardWithTabs/Card"

import ReturnFormList from "./Return/ReturnFormList"
import Equipment from "./Equipped/Equipped"
import MarketValue from "./MarketValue/MarketValue"
import ReturnForm from "./Return/ReturnForm"
import Exports from "./Exports/Exports"
import InventoryList from "./Inventory/InventoryList"
import Inventory from "./Inventory/Inventory"
import DepotList from "./Depots/Depots"
import TransferList from "./Transfer/TransferList"
import Transfer from "./Transfer/Transfer"
import ListeAppareille from "pages/Gestion-Stock/FicheProduit"

const Stock = lazy(() => import("./Stock/Stock"))
export const StockRouterPrefix = "/mon-stock"

const StockRouter = (): JSX.Element => {
	return (
		<CardWithTabs urlprefix={StockRouterPrefix}>
			<CardItem name={"Stock"} url={"/stock"} component={Stock} />
			<CardItem
				name={"Fiche produit"}
				url={"/fiche-produit/:id"}
				useSlug={"id"}
				component={ListeAppareille}
				onlyVisibleOnExactRoute
			/>
			<CardItem name={"Appareillés"} url={"/appareilles"} component={Equipment} />
			<CardItem name={"Dépôts et ferme"} url={"/depots"} component={DepotList} />
			<CardItem name={"Retours"} url={"/retours"} component={ReturnFormList} />
			<CardItem name="Nouveau retour" url="/nouveau-retour" component={ReturnForm} onlyVisibleOnExactRoute />
			<CardItem name="Editer un retour" url="/retour/:id" component={ReturnForm} onlyVisibleOnExactRoute />
			<CardItem name={"Inventaire"} url={"/inventaire"} component={InventoryList} />
			<CardItem
				name={"Nouvel Inventaire"}
				url={"/nouvel-inventaire"}
				component={Inventory}
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name={"Inventaire"}
				url={"/inventaire/:id"}
				useSlug={"id"}
				component={Inventory}
				onlyVisibleOnExactRoute
			/>
			<CardItem name={"Transfert"} url={"/transfert"} component={TransferList} />
			<CardItem name={"Transfert"} url={"/transfert/:id"} component={Transfer} onlyVisibleOnExactRoute />

			<CardItem name={"Valeur marchande"} url={"/valeur-marchande"} component={MarketValue} />
			<CardItem name={"Export"} url={"/exports"} component={Exports} />
		</CardWithTabs>
	)
}

export default StockRouter
