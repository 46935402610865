import { ParametreRoutePrefix } from "router/Parametre/routes"

export const Packsroutes = {
	Packs: `${ParametreRoutePrefix}/packs`,
	CréationPack: `${ParametreRoutePrefix}/packs/creation`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(ParametreRoutePrefix, "")
}
