import { CheckOutlined, CloseOutlined, EyeFilled, EyeInvisibleFilled, MenuOutlined } from "@ant-design/icons"
import { Switch, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import axios from "axios"
import { COMPANY_DASHBOARD_COLUMN_API } from "config"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { SortableContainer as ReactSortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import { toast } from "react-toastify"
import API from "services/API"
import AuthContext from "../../../contexts/AuthContext"

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />)

const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainer = ReactSortableContainer((props: any) => <tbody {...props} />)

type DashBoardRowWithIndexAndKey = {
	[key: string]: string | number | boolean | undefined
	displayOrder: number
	label: string
	isDefault?: boolean
	isVisible: boolean
	isBlocked: boolean
	key: string
	nonFillableComponent?: boolean
	"@id": string
	id: number
}

const DashBoardSettings = (): JSX.Element => {
	const queryClient = useQueryClient()
	const { companySettings } = useContext(AuthContext)
	const [dashBoardRows, setDashBoardRow] = useState<DashBoardRowWithIndexAndKey[]>([])

	useEffect(() => {
		if (!companySettings?.patientsDashboardSetting) return

		const res: DashBoardRowWithIndexAndKey[] = Object.entries(companySettings.patientsDashboardSetting!)
			.reduce((result: any, data: any) => {
				if (data[0] === "@id" || data[0] === "@type" || data[0] === "id" || data[1] == null) return result

				data[1]["key"] = data[0]
				result.push(data[1])
				return result
			}, [])
			.sort(function (a: DashBoardRowWithIndexAndKey, b: DashBoardRowWithIndexAndKey) {
				return a.displayOrder - b.displayOrder
			})
		setDashBoardRow(res)
	}, [companySettings])

	const { mutate: updateDashboardOrder, isLoading: isUpdatingOrder } = useMutation(
		async (newOrder: string[]) =>
			await axios.post(COMPANY_DASHBOARD_COLUMN_API + "/order", { columnSettingOrder: newOrder }),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("COMPANY_SETTINGS")
			},
			onError: () => {
				toast.error("Erreur lors de la mise à jour des paramètres.")
			},
		}
	)

	const { mutate: updateDashboardSettings, isLoading: isUpdating } = useMutation(
		async (settings: DashBoardRowWithIndexAndKey) =>
			await API.update("COMPANY_DASHBOARD_COLUMN_API", settings.id, settings),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("COMPANY_SETTINGS")
			},
			onError: () => {
				toast.error("Erreur lors de la mise à jour des paramètres.")
			},
		}
	)

	const columns: ColumnsType<DashBoardRowWithIndexAndKey> = [
		{
			title: "Trier",
			dataIndex: "sort",
			width: 30,
			className: "drag-visible text-center",
			render: (sort, record) => (record.isDefault ? <div /> : <DragHandle />),
		},
		{
			title: "Nom de la colone",
			dataIndex: "label",
			className: "drag-visible",
		},
		{
			title: "Donnée requise",
			dataIndex: "isRequired",
			className: "text-center drag-hidden",
			render: (isRequired, record) => (
				<div className="d-flex">
					{!record["isDefault"] && !record["nonFillableComponent"] && (
						<Switch
							checked={isRequired}
							checkedChildren={<CheckOutlined style={{ display: "block" }} />}
							unCheckedChildren={<CloseOutlined style={{ display: "block" }} />}
							onClick={(value) => {
								handleChange(value, record, "isRequired")
							}}
							disabled={!record.isVisible || isUpdating || record.isBlocked}
						/>
					)}
				</div>
			),
			width: 100,
		},
		{
			title: "Visible",
			dataIndex: "isVisible",
			className: "text-center drag-hidden",
			render: (isVisible, record) => (
				<div className="d-flex">
					{!record["isDefault"] && (
						<Switch
							checked={isVisible}
							checkedChildren={<EyeFilled style={{ display: "block" }} />}
							unCheckedChildren={<EyeInvisibleFilled style={{ display: "block" }} />}
							disabled={isUpdating}
							onClick={(value) => {
								return handleChange(value, record, "isVisible")
							}}
						/>
					)}
				</div>
			),
			width: 100,
		},
		{
			title: "Colone par défaut",
			dataIndex: "isDefault",
			align: "center",
			width: "9rem",
			className: "drag-hidden",
			render: (isDefault: boolean) =>
				isDefault ? <CheckOutlined className="text-primary" /> : <CloseOutlined className="text-danger" />,
		},
	]

	const handleChange = useCallback(
		(value: any, record: DashBoardRowWithIndexAndKey, dataIndex: string) => {
			if (dashBoardRows.length === 0) return

			const dashBoardRowsCopy = [...dashBoardRows]
			const recordIndex = dashBoardRowsCopy.findIndex((el) => el.id === record.id)
			dashBoardRowsCopy[recordIndex][dataIndex] = value
			updateDashboardSettings(dashBoardRowsCopy[recordIndex])
		},
		[dashBoardRows]
	)

	const handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
		if (oldIndex === newIndex) return

		const orderedPacks = [...dashBoardRows]
		const element = orderedPacks[oldIndex]
		orderedPacks.splice(oldIndex, 1)
		orderedPacks.splice(newIndex, 0, element)
		updateDashboardOrder(orderedPacks.map((data) => data["@id"]))
	}

	return (
		<Table
			dataSource={dashBoardRows}
			columns={columns}
			pagination={false}
			rowKey="displayOrder"
			loading={isUpdatingOrder}
			components={{
				body: {
					wrapper: (props: any) => (
						<SortableContainer
							useDragHandle
							disableAutoscroll
							helperClass="row-dragging"
							onSortEnd={handleSortEnd}
							{...props}
						/>
					),
					row: ({ className, style, ...props }: any) => {
						const displayOrder = dashBoardRows.findIndex((q) => q.displayOrder === props["data-row-key"])
						return dashBoardRows[displayOrder]?.isDefault ? (
							<tr key={displayOrder} {...props} />
						) : (
							<SortableItem index={displayOrder} {...props} />
						)
					},
				},
			}}
		/>
	)
}
export default DashBoardSettings
