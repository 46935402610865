import { Toolbar } from "@bryntum/calendar"
import { updateHourHeight } from "./BryntumAgenda.helper"
import ButtonRefresh from "./ButtonRefresh"
import ButtonSaveView from "./ButtonSaveView"

ButtonRefresh.initClass()
ButtonSaveView.initClass()
export class MyTbar extends Toolbar {
	static get $name(): string {
		return "MyTbar"
	}

	static get type(): string {
		return "mytbar"
	}

	static get configurable(): any {
		return {
			items: {
				viewWidth: {
					cls: "arrow-h",
					type: "slider",
					weight: 640,
					min: 12,
					max: 150,
					value: 80,
					width: 110,
					unit: "em",
					showValue: false,
					showTooltip: false,
					onInput({ value }: { value: number }) {
						// All views must be synced with the requested resource width
						// @ts-ignore librairie not well define
						this.up("calendar").eachView((v: any) => (v.resourceWidth = value + "em"))
					},
					text: "Gérer la largeur",
				},
				hourHeight: {
					cls: "arrow-v",
					type: "slider",
					weight: 640,
					min: 50,
					max: 150,
					value: 100,
					width: 110,
					unit: "em",
					showValue: false,
					showTooltip: false,
					onInput({ value }: { value: number }) {
						// All views must be synced with the requested resource width
						// @ts-ignore librairie not well define
						updateHourHeight(this.up("calendar"), value)
					},
					text: "Gérer la hauteur",
				},
				refresh: {
					type: "buttonRefresh",
				},
				saveView: {
					type: "saveView",
				},
				pdfExport: {
					type: "button",
					text: "Export Pdf",
					icon: "b-fa-file-export",
					hidden: true,
					onClick() {
						// @ts-ignore
						this.up("calendar").activeView.features.pdfExport.onExportDialogExport({
							exporterType: "singlepage",
						})
					},
				},
			},
		}
	}
}
