/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import * as AuthApi from "../../services/AuthApi"
import CacheSystem from "../../services/CacheSystem/Cache.ts"
import { clearServiceWorkerCache } from "../../services/functions"

const DropDownAffiliate = () => {
	const handleLogout = (event) => {
		event.preventDefault()
		CacheSystem.clear()
		AuthApi.logout()
		window.location.href = "/"
	}

	return (
		<div>
			<div
				className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow"
				aria-labelledby="navbar-primary_dropdown_1">
				<Link to="/mon-compte/mes-informations" className="dropdown-item">
					<i className="fad fa-user" />
					<span>Mes Informations</span>
				</Link>
				<Link
					to="#"
					onClick={async () => {
						clearServiceWorkerCache()
					}}
					className="dropdown-item text-warning">
					<i className="fad fa-database" />
					<span>Supprimer le cache</span>
				</Link>
				<Link to="/se-connecter" onClick={handleLogout} className="dropdown-item text-danger">
					<i className="fad fa-sign-out-alt" />
					<span>Déconnexion</span>
				</Link>
				<div className="dropdown-divider" />
				{window.BUILD_DATE && (
					<div style={{ fontSize: "12px" }}>
						Dernière mise à jour : {dayjs(window.BUILD_DATE).format("DD/MM/YY à HH:mm")}{" "}
					</div>
				)}
			</div>
		</div>
	)
}

export default DropDownAffiliate
