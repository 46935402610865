import { Select } from "antd"
import { useMemo } from "react"
import { useAllProductsQuery } from "components/Hooks/commonQueries"
import { partialSearch } from "services/functions"

type AsyncProductSelectHiboutikProps = {
	allowClear?: boolean
	disabled?: boolean
	placeholder?: string
	searchValue?: string
	productCategoryId?: number
	onChange?: () => void
	onClear?: () => void
	onDeselect?: () => void
	onSelect: (_: any, option: any) => void
}

export default function AsyncProductSelectHiboutik({
	allowClear = true,
	disabled,
	placeholder = "Sélectionner un produit",
	searchValue,
	productCategoryId,
	onChange,
	onClear,
	onDeselect,
	onSelect,
}: AsyncProductSelectHiboutikProps): JSX.Element {
	const { data: allProducts, isFetching } = useAllProductsQuery()

	const options = useMemo(() => {
		if (isFetching || allProducts == null) return []

		const tmp = []
		let products = [...allProducts]

		if (productCategoryId) products = products.filter((p) => p.categoryId === productCategoryId)

		for (const product in products) {
			const label = products[product].model

			tmp.push({
				detail: products[product],
				value: products[product].id,
				label,
			})
		}
		return tmp
	}, [allProducts, productCategoryId])

	return (
		<Select
			allowClear={allowClear}
			disabled={disabled}
			options={options}
			placeholder={placeholder}
			searchValue={searchValue}
			style={{ width: "100%" }}
			showSearch
			optionFilterProp="children"
			filterOption={(input, option) => {
				if (input.length < 2) return false
				return partialSearch(option!.label as string, input)
			}}
			onChange={onChange}
			onClear={onClear}
			onDeselect={onDeselect}
			onSelect={onSelect}
		/>
	)
}
