/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react"
import { Table } from "antd"
import API from "../../../services/API"
//import API_Hiboutik from "../../../services/API_Hiboutik"
import dayjs from "dayjs"
import { formatDatetimeForDB } from "../../../services/functions"
import AuthContext from "../../../contexts/AuthContext"
import API_PDF from "../../../services/API_PDF"
import axios from "axios"
import { API_URL } from "../../../config"
import ButtonRounded from "components/Buttons/ButtonRounded"
import useCustomTitle from "components/Hooks/useTitle"

const columns = [
	{
		title: "Date",
		dataIndex: "dueDate",
		render: (d) => {
			if (d) {
				return dayjs(d).format("DD/MM/YYYY")
			}
			return "N/C"
		},
	},
	{
		title: "Date d'acquittement",
		dataIndex: "paidDate",
		render: (d) => {
			if (d) {
				return dayjs(d).format("DD/MM/YYYY")
			}
			return "N/C"
		},
	},
	// {
	// 	title: "ID Vente",
	// 	dataIndex: "saleId",
	// },
	{
		title: "Patient",
		dataIndex: "saleCustomerName",
		width: "15%",
	},
	{
		title: "Montant",
		dataIndex: "amount",
		render: (d) => {
			return <>{Number(d).toFixed(2)} €</>
		},
	},
	{
		title: "Numéro",
		dataIndex: "comment",
	},
]

const GestionCheques = ({ paymentType }) => {
	useCustomTitle(paymentType === "CHE" ? "Paiements | Dépôt de chèques" : "Paiements | Dépôt de CB")
	const { user, laboratory } = useContext(AuthContext)
	const [needRefresh, setNeedRefresh] = useState(true)
	const [loading, setLoading] = useState(true)
	const [type, setType] = useState("CHE")
	const [valideList, setValideList] = useState([])
	const [depotList, setDepotList] = useState([])
	const [busy, setBusy] = useState(false)
	const [commentValidation, setCommentValidation] = useState("")
	const [commentDepot, setCommentDepot] = useState("")

	const columnsValide = [
		...columns,
		{
			title: "Sélectionner",
			dataIndex: "id",
			render: (_value, row) => {
				const index = valideList.findIndex((f) => f.id === row.id)
				return (
					<>
						<button
							type="button"
							className="btn p-0"
							onClick={() => {
								const tmp = [...valideList]
								tmp[index].checked = !tmp[index].checked
								setValideList(tmp)
							}}>
							{valideList?.[index]?.checked ? "☑️" : "⬜"}
						</button>
					</>
				)
			},
		},
	]

	const columnsDepose = [
		...columns,
		{
			title: "Sélectionner",
			dataIndex: "id",
			render: (_value, row) => {
				const index = depotList.findIndex((f) => f.id === row.id)
				return (
					<>
						<button
							type="button"
							className="btn p-0"
							onClick={() => {
								const tmp = [...depotList]
								tmp[index].checked = !tmp[index].checked
								setDepotList(tmp)
							}}>
							{depotList?.[index]?.checked ? "☑️" : "⬜"}
						</button>
					</>
				)
			},
		},
	]

	useEffect(() => {
		if (needRefresh === true) {
			;(async function () {
				try {
					setLoading(true)
					setType(paymentType)
					setCommentDepot("")
					setCommentValidation("")

					const customersIds = new Set()
					let customersDetail = []

					const encaisse = await API.findAll(
						"PAYMENTS_API",
						`?state=ENCAISSE&type=${paymentType}&pagination=false&saleWarehouseId=` +
							(laboratory.warehouseIdHiboutik || 1)
					)
					const valide = await API.findAll(
						"PAYMENTS_API",
						`?state=VALIDE&type=${paymentType}&pagination=false&saleWarehouseId=` +
							(laboratory.warehouseIdHiboutik || 1)
					)

					for (const payment of encaisse) {
						customersIds.add(payment.saleCustomerId)
					}
					for (const payment of valide) {
						customersIds.add(payment.saleCustomerId)
					}
					if (customersIds.size)
						await axios
							.get(API_URL + "/search/patients/by_list/customer_id/" + btoa([...customersIds].join(",")))
							.then((r) => {
								customersDetail = [...r.data]
							})
							.catch((e) => console.error(e))
					setValideList(
						encaisse.map((value, index) => {
							const details = customersDetail.find(
								(f) => +f.hiboutik_customer_id === +value.saleCustomerId
							)
							value.saleCustomerName = `${details?.first_name || "Inconnu"} ${details?.last_name || ""}`

							value.key = index
							value.checked = false
							return value
						})
					)
					setDepotList(
						valide.map((value, index) => {
							const details = customersDetail.find(
								(f) => +f.hiboutik_customer_id === +value.saleCustomerId
							)
							value.saleCustomerName = `${details?.first_name || "Inconnu"} ${details?.last_name || ""}`

							value.key = index
							value.checked = false
							return value
						})
					)
				} catch (e) {
					console.error(e)
				} finally {
					setNeedRefresh(false)
					setLoading(false)
				}
			})()
		}
	}, [needRefresh])

	const handleValidate = async () => {
		const data = valideList.filter((f) => f.checked === true)
		const dataCashRegisterState = []
		const dataPaymentsRecieved = []
		setBusy(true)
		const dataForPdf = {
			company: {
				logo: laboratory?.subCompany?.logo ?? user?.company?.logo,
				companyName: laboratory?.legalLabel,
				laboratoryName: laboratory?.label,
				laboratoryAddress: laboratory?.adress,
				laboratoryCpo: laboratory?.cpo,
				laboratoryFiness: laboratory?.finess,
				laboratoryMail: laboratory?.email,
				laboratoryPhone: laboratory?.phone,
				laboratoryRCS: laboratory?.rcs,
				laboratorySiret: laboratory?.siret,
				laboratoryTVA: laboratory?.tva,
				laboratoryCity: laboratory?.city,
			},
			date: dayjs().format("DD MMM YYYY, HH[h]mm"),
			comment: commentValidation,
			total: null,
		}

		for (const che of data) {
			try {
				await API.update("PAYMENTS_API", che.id, {
					validationDate: formatDatetimeForDB(),
					state: "VALIDE",
				})

				dataCashRegisterState.push({
					value: che.amount.toFixed(2),
					total: che.amount.toFixed(2),
					numero: che.comment,
					type: type,
					multiplier: 1,
					sale_id: che.saleId,
					payment_id: che.paymentId,
					patient: che.saleCustomerName,
				})
				dataPaymentsRecieved.push({
					type: type,
					sale_id: che.saleId,
					total: che.amount.toFixed(2),
					date: che.paidDate ? dayjs(che.paidDate).format("DD/MM/YYYY") : "",
					patient: che.saleCustomerName,
				})
			} catch (e) {
				console.error(e)
			}
		}
		setNeedRefresh(true)
		dataForPdf.cashRegisterState = [...dataCashRegisterState]
		dataForPdf.paymentsRecieved = [...dataPaymentsRecieved]
		dataForPdf.totalCashRegister = dataCashRegisterState.reduce((acc, item) => acc + Number(item.total), 0)
		dataForPdf.totalCashRegister = dataForPdf.totalCashRegister.toFixed(2)
		dataForPdf.total = dataPaymentsRecieved.reduce((acc, item) => acc + Number(item.total), 0)
		dataForPdf.total = dataForPdf.total.toFixed(2)
		await API_PDF.uploadFromCarboneToDb(dataForPdf, "VALIDATION_CAISSE", "pdf", {
			...dataForPdf,
			type: "CAISSE_VALIDATION",
		})
		setBusy(false)
	}

	//comments: "{"value":39,"multiplier":1,"total":39,"numero":"AAAAAAAAAAAAAAAAAAAAA","type":"CASH_OUT"}"

	const handleDepot = async () => {
		const data = depotList.filter((f) => f.checked === true)
		setBusy(true)

		const dataCashRegisterState = []
		const dataForPdf = {
			company: {
				logo: laboratory?.subCompany?.logo ?? user?.company?.logo,
				companyName: laboratory?.legalLabel,
				laboratoryName: laboratory?.label,
				laboratoryAddress: laboratory?.adress,
				laboratoryCpo: laboratory?.cpo,
				laboratoryFiness: laboratory?.finess,
				laboratoryMail: laboratory?.email,
				laboratoryPhone: laboratory?.phone,
				laboratoryRCS: laboratory?.rcs,
				laboratorySiret: laboratory?.siret,
				laboratoryTVA: laboratory?.tva,
				laboratoryCity: laboratory?.city,
			},
			date: dayjs().format("DD MMM YYYY, HH[h]mm"),
			comment: commentDepot,
			total: null,
		}
		for (const che of data) {
			try {
				// const data = {
				// 	store_id: che.saleWarehouseId,
				// 	amount: che.amount,
				// 	currency_code: che.currency,
				// 	comments: JSON.stringify({
				// 		payment: type,
				// 		value: che.amount,
				// 		total: che.amount,
				// 		numero: che.comment,
				// 		type: "CASH_OUT",
				// 		multiplier: 1,
				// 		sale_id: che.saleId,
				// 		payment_id: che.paymentId,
				// 	}),
				// }
				dataCashRegisterState.push({
					value: Number(che.amount).toFixed(2),
					total: Number(che.amount).toFixed(2),
					numero: che.comment,
					type: type,
					multiplier: 1,
					sale_id: che.saleId,
					payment_id: che.paymentId,
					patient: che.saleCustomerName,
				})
				// if (type === "CHE") {
				// 	await API_Hiboutik.cashOut(data)
				// }
				await API.update("PAYMENTS_API", che.id, {
					state: "DEPOSE",
					depotDate: formatDatetimeForDB(),
				})
			} catch (e) {
				console.error(e)
			}
		}
		setNeedRefresh(true)
		dataForPdf.cashRegisterState = [...dataCashRegisterState]
		dataForPdf.total = dataCashRegisterState.reduce((acc, item) => acc + Number(item.total), 0)
		dataForPdf.total = dataForPdf.total.toFixed(2)
		await API_PDF.uploadFromCarboneToDb(dataForPdf, "DEPOT_BANQUE", "pdf", {
			...dataForPdf,
			type: "CAISSE_DEPOT",
		})
		setBusy(false)
	}

	return (
		<>
			<div className="card p-4 mt-3">
				<h5>{type === "CHE" ? "Chèques" : "Cartes"} à valider :</h5> <br />
				<Table
					pagination={{ position: ["bottomLeft"] }}
					columns={columnsValide}
					dataSource={valideList}
					loading={loading}
				/>
				<textarea
					className="form-control mt-2 mb-3"
					value={commentValidation || ""}
					placeholder="Ajouter un commentaire pour cette validation... (optionnel)"
					onChange={(event) => {
						const value = event.target.value
						setCommentValidation(value)
					}}
				/>
				<ButtonRounded
					className="m-auto"
					color="primary-outlined"
					isDisabled={busy || loading || !valideList.filter((f) => f.checked === true).length}
					onClick={() => handleValidate()}
					type="button">
					Valider les {type === "CHE" ? "chèques" : "cartes"} sélectionnés
				</ButtonRounded>
			</div>
			<div className="card p-4 mt-3">
				<h5>{type === "CHE" ? "Chèques" : "Cartes"} à déposer :</h5> <br />
				<Table
					pagination={{ position: ["bottomLeft"] }}
					columns={columnsDepose}
					dataSource={depotList}
					loading={loading}
				/>
				<textarea
					className="form-control mt-2 mb-3"
					value={commentDepot || ""}
					placeholder="Ajouter un commentaire pour ce dépôt... (optionnel)"
					onChange={(event) => {
						const value = event.target.value
						setCommentDepot(value)
					}}
				/>
				<ButtonRounded
					className="m-auto"
					color="primary-outlined"
					isDisabled={busy || loading || !depotList.filter((f) => f.checked === true).length}
					onClick={() => handleDepot()}
					type="button">
					Déposer les {type === "CHE" ? "chèques" : "cartes"} sélectionnés
				</ButtonRounded>
			</div>
		</>
	)
}

export default GestionCheques
