import {
	CoverageDemandState,
	HumanReadableDemandState,
} from "../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import { useMemo } from "react"
import "./CoverageDemandBadge.scss"
import getCoverageDemandColorAndText from "../../utils/getCoverageDemandColorAndText"

interface Props {
	state: CoverageDemandState
}

export default function CoverageDemandBadge({ state }: Props) {
	const backgroundColor = useMemo(() => getCoverageDemandColorAndText(state), [state])

	return (
		<div style={{ backgroundColor }} className={"coverage-demand-badge"}>
			{HumanReadableDemandState[state]}
		</div>
	)
}
