import {
	Container,
	Widget,
	RecurrenceCombo,
	RecurrenceModel,
	RecurrenceLegendButton,
	RecurrenceEditor,
	RecurrenceConfirmationPopup,
	FieldContainer,
} from "@bryntum/calendar"
import { AWEventModel } from "./AWEventModel"

interface RecurrenceFieldWidgetMap {
	editRecurrenceButton: RecurrenceLegendButton & { recurrence: RecurrenceModel; value: string | null }
	recurrenceCombo: RecurrenceCombo & { recurrence: RecurrenceModel }
}

interface RecurrenceFieldContainer {
	extraData: {
		eventRecord: AWEventModel
		recurrence: RecurrenceModel
		setRecurrence: React.Dispatch<React.SetStateAction<RecurrenceModel | undefined>>
	}
}

export const configRecurringContainer = {
	items: {
		recurrenceField: {
			type: "CustomRecurrenceField",
		},
	},
	listeners: {
		beforeupdatedisabled(data: { type: string; disabled: boolean; source: FieldContainer }) {
			const { disabled, source } = data
			const { recurrenceCombo } = source.widgetMap as RecurrenceFieldWidgetMap
			if (disabled) {
				recurrenceCombo.disabled = true
				recurrenceCombo.value = { value: "none" }
			} else {
				recurrenceCombo.disabled = false
			}
		},
	},
}

/**
 * On utilise une version React de l’éditeur d’événement
 * Cette class permet d’utiliser les outils de la librairie bryntum
 * pour manipuler les événements récurrents
 * la partie récurrence est géré séparément dans la fenêtre d’edition,
 * a la sauvegarde de l’événement la récurrence est mise à jour.
 */
export default class BryntumRecurrenceField extends Container {
	static get $name(): string {
		return "CustomRecurrenceField"
	}

	static get type(): string {
		return "customrecurrencefield"
	}

	doDestroy(): void {
		this._recurrenceConfirmation && this.recurrenceConfirmation.destroy()
		this._recurrenceEditor && this.recurrenceEditor.destroy()
		super.doDestroy()
	}

	_recurrence!: RecurrenceModel | null
	_wasEmpty!: boolean | null
	_recurrenceEditor!: RecurrenceEditor
	_recurrenceConfirmation!: RecurrenceConfirmationPopup
	parent!: { extraData: { eventRecord: AWEventModel } }

	get recurrence(): RecurrenceModel | null {
		return this._recurrence
	}

	set recurrence(recurrence) {
		this._recurrence = recurrence
	}

	get wasEmpty(): boolean | null {
		return this._wasEmpty
	}

	set wasEmpty(wasEmpty) {
		this._wasEmpty = wasEmpty
	}

	set recurrenceConfirmation(recurrenceConfirmation) {
		this._recurrenceConfirmation = recurrenceConfirmation
	}

	get recurrenceConfirmation(): RecurrenceConfirmationPopup {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const me = this
		let recurrenceConfirmation = me._recurrenceConfirmation

		// @ts-ignore
		if (!recurrenceConfirmation || !recurrenceConfirmation.$$name) {
			// @ts-ignore
			recurrenceConfirmation = Widget.create(
				Object.assign(
					{
						type: "recurrenceconfirmation",
						owner: me,
					},
					recurrenceConfirmation
				)
			)
			me._recurrenceConfirmation = recurrenceConfirmation
		}

		return recurrenceConfirmation
	}

	static get configurable(): any {
		return {
			items: {
				/**
				 * Reference to the `Repeat` event field, if used
				 * @member {Scheduler.view.recurrence.field.RecurrenceCombo} recurrenceCombo
				 * @readonly
				 */
				recurrenceCombo: {
					type: "recurrencecombo",
					label: "L{EventEdit.Repeat}",
					ref: "recurrenceCombo",
					weight: 700,
					labelCls: "aw-label-recurrence",
					cls: "aw-recurrence-combo",
					tooltip: "Pas de récurrence possible en cas de patient",
				},

				/**
				 * Reference to the button that opens the event repeat settings dialog, if used
				 * @member {Scheduler.view.recurrence.RecurrenceLegendButton} editRecurrenceButton
				 * @readonly
				 */
				editRecurrenceButton: {
					type: "recurrencelegendbutton",
					ref: "editRecurrenceButton",
					name: "recurrenceRule",
					color: "b-gray",
					flex: "1",
					//recurrence: "day",
					weight: 800,
				},
			},
		}
	}

	updateRecurrenceFields(recurrence: RecurrenceModel | null): void {
		const me = this as BryntumRecurrenceField,
			{ editRecurrenceButton, recurrenceCombo } = me.widgetMap as RecurrenceFieldWidgetMap

		if (recurrenceCombo) {
			// @ts-ignore
			recurrenceCombo.recurrence = recurrence
		} // update the recurrence legend

		if (editRecurrenceButton) {
			// @ts-ignore
			editRecurrenceButton.recurrence = recurrence
			editRecurrenceButton.value = recurrence ? recurrence.rule : null

			if (recurrence && recurrence.frequency !== "none") {
				editRecurrenceButton.show()
			} else {
				editRecurrenceButton.hide()
			}
		}
	}

	loadRecurrenceData(recurrence: RecurrenceModel | null): void {
		this.recurrence = recurrence
		this.updateRecurrenceFields(recurrence)
	}

	makeRecurrence(rule?: string): RecurrenceModel {
		const event = (this.owner as unknown as RecurrenceFieldContainer).extraData.eventRecord
		const eventCopy = event.copy() as AWEventModel
		let recurrence = event.recurrence

		if (!rule && recurrence) {
			recurrence = recurrence.copy() as RecurrenceModel
		} else {
			// @ts-ignore
			recurrence = new event.recurrenceModel(
				rule
					? {
							rule,
					  }
					: {}
			)
		} // bind cloned recurrence to the cloned event

		recurrence.timeSpan = eventCopy // update cloned event w/ start date from the UI field

		eventCopy.setStartDate(event.startDate as Date)
		// @ts-ignore
		recurrence.suspendTimeSpanNotifying()
		return recurrence
	}

	onPaint = (): void => {
		const { recurrenceEditor, onRecurrenceComboChange } = this,
			widgetMap = this.widgetMap as RecurrenceFieldWidgetMap,
			{ eventRecord, recurrence } = (this.owner as unknown as RecurrenceFieldContainer).extraData
		if (widgetMap.editRecurrenceButton) {
			widgetMap.editRecurrenceButton.menu = recurrenceEditor
		}

		if (widgetMap.recurrenceCombo) {
			widgetMap.recurrenceCombo.on({
				change: onRecurrenceComboChange,
				thisObj: this,
			})
		}

		if (recurrence && eventRecord) {
			this.loadRecurrenceData(recurrence)
			return
		}

		if (eventRecord && eventRecord.supportsRecurring) {
			this.loadRecurrenceData(eventRecord.recurrence ? this.makeRecurrence() : null)
		}
	}

	set recurrenceEditor(recurrenceEditor) {
		this._recurrenceEditor = recurrenceEditor
	}

	get recurrenceEditor(): RecurrenceEditor {
		let recurrenceEditor = this._recurrenceEditor // Recurrence editor is centered and modal.
		// @ts-ignore
		if (!recurrenceEditor || !recurrenceEditor.$$name) {
			// @ts-ignore bryntum d.ts file wrong
			recurrenceEditor = Widget.create(
				Object.assign(
					{
						type: "recurrenceeditor",
						autoShow: false,
						centered: true,
						modal: true,
						constrainTo: globalThis,
						anchor: false,
						rootElement: null,
						saveHandler: this.recurrenceEditorSaveHandler,
						cancelHandler: this.recurrenceEditorCancelHandler,
						onBeforeShow: this.onBeforeShowRecurrenceEditor.bind(this),
						thisObj: this,
					},
					recurrenceEditor
				)
			)
			this._recurrenceEditor = recurrenceEditor // Must set *after* construction, otherwise it becomes the default state
			// to reset readOnly back to.  Must use direct property access because
			// getter consults state of editor.
		}

		return recurrenceEditor
	}

	onBeforeShowRecurrenceEditor(): void {
		const { recurrenceEditor } = this,
			{ eventRecord } = (this.owner as unknown as RecurrenceFieldContainer).extraData
		if (recurrenceEditor && eventRecord && eventRecord.supportsRecurring) {
			// if the event has no recurrence yet ..initialize it before showing recurrence editor
			if (!this.recurrence) {
				this.recurrence = this.makeRecurrence()
			} // update the cloned recurrence w/ up to date start date value

			this.recurrence.timeSpan.setStartDate(eventRecord.startDate as Date) // load RecurrenceModel record into the recurrence editor

			recurrenceEditor.record = this.recurrence // In case they drag it. Centered falls off if the widget has position set.

			// @ts-ignore
			recurrenceEditor.centered = true
		}
	}

	recurrenceEditorSaveHandler(editor: RecurrenceEditor, recurrence: RecurrenceModel): void {
		const { setRecurrence } = (this.owner as unknown as RecurrenceFieldContainer).extraData
		// @ts-ignore bryntum d.ts file is incomplet
		if (editor.widgetMap?.intervalField?.value <= 0) {
			return
		}
		setRecurrence(recurrence)
		// @ts-ignore bryntum d.ts file is wrong
		editor.syncEventRecord(recurrence) // update the recurrence related UI
		this.updateRecurrenceFields(recurrence)
		this.wasEmpty = false
		editor.close()
	}

	recurrenceEditorCancelHandler(editor: RecurrenceEditor): void {
		const me = this as BryntumRecurrenceField
		const { setRecurrence } = (this.owner as unknown as RecurrenceFieldContainer).extraData
		// necessaire sinon le combo passe à daily
		if (this.wasEmpty) {
			const resetRecurence = me.makeRecurrence("FREQ=none")
			setRecurrence(resetRecurence)
			this.updateRecurrenceFields(resetRecurence)
		}
		this.wasEmpty = false
		editor.close()
	}

	onRecurrenceComboChange({
		source,
		value,
		userAction,
	}: {
		source: RecurrenceCombo & { customValue: string; recurrence: RecurrenceModel }
		value: string
		userAction: boolean
	}): void {
		const me = this as BryntumRecurrenceField,
			{ recurrenceEditor } = me
		const { setRecurrence, recurrence } = (this.owner as unknown as RecurrenceFieldContainer).extraData
		if (!userAction) return
		if (value === source.customValue) {
			// if user picked "Custom" - show recurrence editor

			// This will recurse through the change event into the opposite side
			// of the value test which will call updateRecurrenceFields, where the
			// assignment to the value of the recurrenceCombo will be a non-change.
			// That will sync the state of the recurrenceButton.
			source.recurrence = me.makeRecurrence()
			this.wasEmpty = true

			recurrenceEditor.show()
		} // user has picked some frequency -> make a new recurrence based on it
		else {
			const newRecurrence = value ? me.makeRecurrence(`FREQ=${value}`) : undefined
			if (
				(recurrence && newRecurrence && recurrence.rule === newRecurrence.rule) ||
				(newRecurrence === undefined && recurrence === undefined)
			)
				return

			setRecurrence(newRecurrence)

			if (newRecurrence) {
				this.recurrence = newRecurrence
				this.updateRecurrenceFields(newRecurrence)
			}
		}
	}
}
