import {
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	LeftSquareOutlined,
	PlusCircleOutlined,
	WarningOutlined,
} from "@ant-design/icons"
import { Template } from "@audiowizard/common"
import { Checkbox, Row, Select } from "antd"
import cx from "classnames"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import LoadingModal from "components/effects/ModalLoading"
import FieldWithError from "components/forms/FieldWithError"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { LocaleNamespace } from "config/intl/helpers"
import AuthContext from "contexts/AuthContext"
import { random } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useRef, useState } from "react"
import EmailEditor, { Design } from "react-email-editor"
import { useTranslation } from "react-i18next"
import { Prompt, RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import { Col } from "reactstrap"
import API from "services/API"
import { capitalizeFirstLetter } from "utils/types/primitives/String/capitalizeFirstLetter"
import EditionSelect from "../../../components/commons/Selects/EditionSelect/EditionSelect"
import { mergeTags } from "../../../components/containers/UnlayerEditor/custom/index"
import UnlayerEditor from "../../../components/containers/UnlayerEditor/UnlayerEditor"
import ChangeModal from "./ChangedModal"
import { defaultTemplate } from "./custom/defaultTemplate"
import type { TemplateForForm } from "./custom/listDefaultOptions"
import { labelTypeTemplates, tempDefaultOption } from "./custom/listDefaultOptions"
import {
	createTemplate,
	deleteTemplate,
	getTemplate,
	mergeDeep,
	updateDefault,
	updateTemplate,
} from "./TemplateForm.helper"
import "./TemplateForm.scss"
import NewTemplateModal from "./TemplateModal"

interface OptionProps {
	data: TemplateForForm
}

type PropsTemplateForm = RouteComponentProps<{ id: string }> & {
	className?: string
}

const CustomOption: React.FC<OptionProps> = ({ data }) => {
	return (
		<div className={"edition-select__custom-option"}>
			<div className={"edition-select__custom-option-label"}>
				{data.label} <span className={"edition-select__custom-option-description"}>{data.description}</span>{" "}
			</div>
			{data.isDefault && <CheckCircleOutlined className="mb-4" title="Modèle par défaut" />}
			{!data.custom && <LeftSquareOutlined className="mb-4" title="exemple de modèle" />}
		</div>
	)
}

const emptyTemplate: TemplateForForm = {
	"@type": "Template",
	"@id": "",
	label: "",
	description: "",
	type: "",
	isDefault: false,
	unlayerSetting: "",
	unlayerId: "",
	edited: false,
	custom: false,
}

CustomOption.propTypes = {
	// @ts-ignore
	data: PropTypes.object,
}
/**
 * TemplateForm Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<TemplateForm>}
 */
const TemplateForm: React.FC<PropsTemplateForm> = ({ match }) => {
	const { id } = match?.params
	const { templates: customTemplates, setTemplates: setContextTemplate } = useContext(AuthContext)

	const { t } = useTranslation(LocaleNamespace.Template)
	const [templates, _setTemplates] = useState<TemplateForForm[]>(tempDefaultOption) // list of the templates available
	const [activeTemplate, _setActiveTemplate] = useState<TemplateForForm>(emptyTemplate)
	const [templateUpdated, _setTemplateUpdated] = useState(false)
	const [renderTemplate, setRenderTemplate] = useState("")
	const [selectTemplateId, setSelectTemplateId] = useState("")
	const templateUpdatedRef = useRef(false) // use to check if the actual template is being updated
	const activeTemplateRef = useRef<TemplateForForm>(emptyTemplate) // states aren't always reachable from events, so we need to use ref too
	const editorRef = useRef<EmailEditor>(null)
	const [confirmUpdate, setConfirmUpdate] = useState(false)
	const [myMergeTags, setMyMergeTags] = useState({})
	const [forceUpdate, setForceUpdate] = useState(random()) // use to force reload the events on the editor
	const [showCreateTemplateModale, setShowCreateTemplateModale] = useState(false)
	const [errors, setErrors] = useState<Record<string, string>>({ label: "" })
	const [showDetails, setShowDetails] = useState(false)
	const [unlayerEditorLoading, setUnlayerEditorLoader] = useState(false)

	const classes: string = cx("template-form")

	const setTemplateUpdated = (isUpdated: boolean): void => {
		templateUpdatedRef.current = isUpdated
		_setTemplateUpdated(isUpdated)
	}

	const setActiveTemplateRef = (template: TemplateForForm): void => {
		activeTemplateRef.current = template
		_setActiveTemplate(template)
	}

	const setTemplates = (templates: TemplateForForm[]): void => {
		_setTemplates(templates)
		setContextTemplate(templates.filter((t) => t.custom))
	}

	const options = []

	for (const option in labelTypeTemplates) {
		options.push({
			value: option,
			label: <div className={"edition-select__custom-option"}>{t(labelTypeTemplates[option])}</div>,
		})
	}

	const handleChangeType = (selectedValue: string): void => {
		if (selectedValue === "") {
			setErrors({
				...errors,
				type: "Choix du type de modèle obligatoire.",
			})
		} else {
			setErrors({
				...errors,
				type: "",
			})
		}
		if (activeTemplate) {
			setActiveTemplateRef({
				...activeTemplate,
				type: selectedValue,
				edited: true,
			})
			setTemplateUpdated(true)
		}
	}

	useEffectAsync(async () => {
		const newTemplates = [
			...customTemplates.map(function (template) {
				return {
					...template,
					edited: false,
					custom: true,
					unlayerId: template["@id"],
				}
			}),
			...tempDefaultOption,
		]
		setTemplates(newTemplates)
		setTemplateUpdated(false)
		if (id) {
			changeTemplate("/templates/" + id, newTemplates)
		} else {
			setMyMergeTags({ ...(await mergeTags.defaultMergeTag(t)) })
		}
	}, [])

	const changeTemplate = async (selectedOption?: string, newTemplates?: TemplateForForm[]): Promise<void> => {
		selectedOption = selectedOption || selectTemplateId
		const tempTemplate = (newTemplates || templates).find((t: TemplateForForm) => {
			return t.unlayerId === selectedOption
		})
		if (tempTemplate) {
			if (tempTemplate && tempTemplate.type && tempTemplate.type in mergeTags) {
				setMyMergeTags(
					mergeDeep(
						await mergeTags.defaultMergeTag(t),
						await mergeTags[tempTemplate.type as keyof typeof mergeTags](t)
					)
				)
			} else {
				setMyMergeTags({ ...(await mergeTags.defaultMergeTag(t)) })
			}
			// if we don't have the detail of the template
			if (tempTemplate.custom && !tempTemplate.unlayerSetting) {
				getTemplate(selectedOption).then((fullTemplate) => {
					if (fullTemplate) {
						const freshTemplate: TemplateForForm = {
							...fullTemplate,
							unlayerId: fullTemplate["@id"],
							edited: false,
							custom: true,
						}
						setActiveTemplateRef(freshTemplate)
						setRenderTemplate(fullTemplate.unlayerSetting || "")
						setForceUpdate(Math.random())
						setTemplateUpdated(false)
					}
				})
			} else if (tempTemplate && !tempTemplate.custom && !tempTemplate.unlayerSetting) {
				// @ts-ignore
				setActiveTemplateRef(tempTemplate)
				setRenderTemplate("")
				// @ts-ignore
				editorRef.current?.editor.loadTemplate(tempTemplate?.unlayerId)
				setTemplateUpdated(false)
				setForceUpdate(Math.random())
			} else {
				setActiveTemplateRef(tempTemplate)
				setRenderTemplate(tempTemplate.unlayerSetting || "")
				setTemplateUpdated(false)
				setForceUpdate(Math.random())
			}
		}
	}

	const onChangeSelect = (selectedOption: string): void => {
		setSelectTemplateId(selectedOption)
		if (activeTemplate?.unlayerId !== selectedOption && templateUpdated) {
			setConfirmUpdate(true)
			return
		}
		changeTemplate(selectedOption)
	}

	const onConfirmModal = (): void => {
		changeTemplate()
		setConfirmUpdate(false)
	}

	const handleChangeLabel = ({ currentTarget }: { currentTarget: { value: string } }): void => {
		setErrors({
			...errors,
			label: "",
		})
		if (activeTemplate) {
			setActiveTemplateRef({
				...activeTemplate,
				label: currentTarget.value || "",
				edited: true,
			})
			setTemplateUpdated(true)
		}
	}

	const handleChangeDescription = ({ currentTarget }: { currentTarget: { value: string } }): void => {
		if (activeTemplate) {
			setActiveTemplateRef({
				...activeTemplate,
				description: currentTarget.value || "",
				edited: true,
			})
			setTemplateUpdated(true)
		}
	}

	const handleChangeCSS = ({ currentTarget }: { currentTarget: { value: string } }): void => {
		if (activeTemplate) {
			setActiveTemplateRef({
				...activeTemplate,
				css: currentTarget.value || "",
				edited: true,
			})
			setTemplateUpdated(true)
		}
	}

	const handleChangeDefault = ({ target }: { target: { checked: boolean } }): void => {
		if (activeTemplate) {
			setActiveTemplateRef({
				...activeTemplate,
				isDefault: target.checked,
				edited: true,
			})
			setTemplateUpdated(true)
		}
	}

	const replaceTemplateInTemplates = (templates: TemplateForForm[], template: TemplateForForm): TemplateForForm[] => {
		return templates.map((t) => {
			if (t.unlayerId !== template.unlayerId) {
				return t
			} else {
				return template
			}
		})
	}

	const onCreateTemplate = (tempTemplate: TemplateForForm): void => {
		createTemplate(tempTemplate).then((retourCreateTemplate) => {
			const data = retourCreateTemplate?.data

			if (data as Template) {
				const newTemplate: TemplateForForm = {
					...tempTemplate,
					unlayerId: (data as Template)["@id"],
					"@id": (data as Template)["@id"],
					edited: false,
					custom: true,
				}
				updateDefault(activeTemplate, templates)
					.then((updatedTemplates) => {
						if (Array.isArray(updatedTemplates)) {
							setTemplates([...updatedTemplates, newTemplate])
						} else {
							setTemplates([...templates, newTemplate])
						}
						setActiveTemplateRef(newTemplate)
						setTemplateUpdated(false)
						toast.success("Mise à jour du modèle avec succès")
					})
					.catch(() => {
						setTemplates(replaceTemplateInTemplates(templates, newTemplate))
						setTemplateUpdated(false)
						toast.success("Mise à jour du modèle avec succès")
					})
				toast.success("Création d'un modèle personnalisé avec succès")
			} else if (retourCreateTemplate as Record<string, string>) {
				setErrors(retourCreateTemplate as Record<string, string>)
				toast.error("Erreur lors de la création! Donnée non conforme")
			} else {
				toast.error("Problème lors de l'appel service de création")
			}
		})
	}

	const handleClickOnSave = (design: Design, html: string, endSpinner: () => void): void => {
		try {
			if (activeTemplate) {
				const tempTemplate: TemplateForForm = {
					...activeTemplate,
					content: html,
					edited: false,
					unlayerSetting: JSON.stringify(design),
				}
				if (tempTemplate.custom) {
					updateTemplate(tempTemplate).then((updatedTemplate) => {
						if (updatedTemplate?.data as Template) {
							setActiveTemplateRef(tempTemplate)
							updateDefault(tempTemplate, templates)
								.then((updatedTemplates) => {
									if (updatedTemplates as TemplateForForm[]) {
										setTemplates(
											replaceTemplateInTemplates(
												updatedTemplates as TemplateForForm[],
												tempTemplate
											)
										)
									} else {
										setTemplates(
											replaceTemplateInTemplates(templates as TemplateForForm[], tempTemplate)
										)
									}
									setTemplateUpdated(false)
									toast.success("Mise à jour du modèle avec succès")
								})
								.catch(() => {
									setTemplates(replaceTemplateInTemplates(templates, tempTemplate))
									setTemplateUpdated(false)
									toast.success("Mise à jour du modèle avec succès")
								})
						} else if (updatedTemplate as Record<string, string>) {
							setErrors(updatedTemplate as Record<string, string>)
							toast.error("Erreur lors de la mise à jour! Donnée non conforme")
						} else {
							toast.error("Problème lors de l'appel service de création")
						}
						endSpinner()
					})
				} else {
					onCreateTemplate(tempTemplate)
					endSpinner()
				}
			}
		} catch (error) {
			toast.error("Erreur lors de l'enregistrement du template!")
			console.error(error)
			endSpinner()
		}
	}

	const handleClickOnDuplicate = (design: Design, html: string, endSpinner: () => void): void => {
		try {
			if (activeTemplate) {
				const tempTemplate: TemplateForForm = {
					...activeTemplate,
					"@id": "",
					content: html,
					edited: false,
					unlayerSetting: JSON.stringify(design),
				}
				onCreateTemplate(tempTemplate)
				endSpinner()
			}
		} catch (error) {
			toast.error("Erreur lors de la duplication du template!")
			console.error(error)
			endSpinner()
		}
	}

	const handleOnChangeInEditor = (): void => {
		if (templateUpdatedRef && activeTemplateRef?.current?.type) {
			setTemplateUpdated(true)
		}
	}

	const handleDeleteTemplate = async (): Promise<void> => {
		const hasConfirmed = await confirmWithModal({
			title: "Supprimer",
			text: `Voulez-vous vraiment supprimer le modèle ${activeTemplate?.label}`,
		})
		if (!hasConfirmed) return
		try {
			if (activeTemplate) {
				if (activeTemplate.custom && activeTemplate.unlayerId) {
					deleteTemplate(activeTemplate.unlayerId).then(() => {
						const idToRemove = activeTemplate.unlayerId
						setActiveTemplateRef(emptyTemplate)
						setTemplates([...templates.filter((g: TemplateForForm) => g.unlayerId !== idToRemove)])
						setTemplateUpdated(false)
						setRenderTemplate(JSON.stringify(defaultTemplate))
						setForceUpdate(Math.random())
					})
					toast.success("Suppression du modèle avec succès.")
				} else {
					toast.error("Impossible de supprimer les exemples de modèle.")
				}
			}
		} catch (error) {
			toast.error("Erreur lors de la suppression du modèle")
			console.error(error)
		}
	}

	const handleCreateNew = async (): Promise<void> => {
		if (templateUpdated) {
			const hasConfirmed = await confirmWithModal({
				title: "Modifications non sauvegardées",
				text: "Confirmer la création d'un nouveau modèle, les données non sauvegardées seront perdues.",
			})
			if (!hasConfirmed) return
		}
		setShowCreateTemplateModale(true)
	}

	const onCreateNewTemplate = (typeTemplate: string): void => {
		setActiveTemplateRef({
			...emptyTemplate,
			type: typeTemplate,
		})
		setTemplateUpdated(true)
		setRenderTemplate(JSON.stringify(defaultTemplate))
		setForceUpdate(Math.random())
		setShowCreateTemplateModale(false)
	}

	const handleSendEmail = async (dataForTemplate: string, callback: () => void): Promise<void> => {
		if (activeTemplateRef.current) {
			if (!dataForTemplate) {
				callback()
				return
			}
			try {
				if (activeTemplate && activeTemplate["@id"]) {
					await API.templateSendMail(activeTemplate["@id"], dataForTemplate)
					toast.success("E-mail envoyé avec succès")
				}
			} catch (err) {
				console.error(err)
				toast.error("Échec de l'envoie de votre email")
			} finally {
				callback()
			}
		}
	}

	return (
		<>
			<Prompt
				when={templateUpdated}
				message="Modifications non sauvegardées en court, voulez-vous quitter la page?"
			/>
			<Col>
				<div className={classes}>
					{/*// @ts-ignore*/}
					<SectionHeader title="Editer vos modèles" />
					<div className={"template-form__content"}>
						<form>
							<Row>
								<Col span={18}>
									{
										<>
											<label>
												{capitalizeFirstLetter(t("form.input.templateSelect.label"))}{" "}
												{templateUpdated && (
													<span style={{ color: "orange" }} className="pl-4">
														<WarningOutlined style={{ fontSize: "32px" }} />
														{t("form.control.templateUpdated")}
													</span>
												)}
											</label>
											<EditionSelect
												options={templates
													.filter((option: TemplateForForm) => {
														return option.unlayerId !== ""
													})
													.map((option: TemplateForForm) => {
														return {
															value: option.unlayerId || "",
															label: <CustomOption data={option} />,
														}
													})}
												onChange={onChangeSelect}
												value={activeTemplate?.unlayerId || ""}
											/>
										</>
									}
								</Col>
								<Col span={6} className="pl-4 mt-4">
									<button
										className="btn btn-outline-info"
										onClick={() => {
											setShowDetails(!showDetails)
										}}
										type="button"
										title="afficher le détail du modèle"
										disabled={activeTemplate?.type ? false : true}>
										<EditOutlined />
									</button>
									<button
										className="btn btn-outline-info"
										onClick={handleCreateNew}
										type="button"
										title="créer un nouveau modèle vide">
										<PlusCircleOutlined />
									</button>
									<button
										className="btn btn-outline-info"
										onClick={handleDeleteTemplate}
										type="button"
										title="supprimer le modèle sélectionné"
										disabled={activeTemplate?.type ? false : true}>
										<DeleteOutlined />
									</button>
								</Col>
							</Row>
							{activeTemplate?.type && (
								<>
									<div
										id="detailTemplate"
										style={{
											maxHeight: showDetails ? "500px" : 0,
											overflow: "hidden",
											transition: "0.5 max-height",
										}}>
										<Row>
											<Col span={12}>
												<FieldWithError
													name="label"
													label={capitalizeFirstLetter(t("form.input.templateName.label"))}
													value={activeTemplate.label || ""}
													type="text"
													className="mr-2"
													onChange={handleChangeLabel}
													error={errors.label || ""}
													required={true}
													invalidMessage="Le label est obligatoire"
												/>
											</Col>
											<Col span={6} className="pl-4">
												<label>
													{capitalizeFirstLetter(t("form.input.templateType.label"))}
												</label>
												<Select
													className={cx(
														"form-control w-100 removeantd-class antd-add-padding",
														{
															"is-invalid": errors.type,
														}
													)}
													value={
														activeTemplate &&
														activeTemplate.type &&
														labelTypeTemplates[activeTemplate.type] &&
														capitalizeFirstLetter(
															t(labelTypeTemplates[activeTemplate.type])
														)
													}
													options={options}
													onChange={handleChangeType}
													placeholder={capitalizeFirstLetter(
														t("form.input.templateType.placeholder")
													)}
												/>
											</Col>
											<Col span={6} className="mt-5 pl-4">
												<label style={{ fontSize: "1.1rem" }}>
													{capitalizeFirstLetter(t("form.input.default.label"))} :{" "}
												</label>
												<Checkbox
													className="pl-2"
													onChange={handleChangeDefault}
													checked={activeTemplate.isDefault}
												/>
											</Col>
										</Row>
										<Row>
											<Col span={18}>
												<label>
													{capitalizeFirstLetter(t("form.input.templateDescription.label"))}
												</label>
												<textarea
													className="form-control"
													disabled={false}
													placeholder={capitalizeFirstLetter(
														t("form.input.templateDescription.placeholder")
													)}
													rows={3}
													value={activeTemplate.description || ""}
													onChange={handleChangeDescription}
												/>
											</Col>
										</Row>
										{
											// inactive feature for now
											false && (
												<Row>
													<Col span={18}>
														<label>
															{capitalizeFirstLetter(t("form.input.css.label"))}
														</label>
														<textarea
															className="form-control"
															disabled={false}
															placeholder={capitalizeFirstLetter(
																t("form.input.css.placeholder")
															)}
															rows={3}
															value={activeTemplate.css || ""}
															onChange={handleChangeCSS}
														/>
													</Col>
												</Row>
											)
										}
									</div>
								</>
							)}
							<div style={(!activeTemplate.type && { display: "none" }) || {}}>
								<label>Édition du modèle</label>
								<UnlayerEditor
									template={renderTemplate || ""}
									onSave={handleClickOnSave}
									onDuplicate={handleClickOnDuplicate}
									ref={editorRef}
									mergeTags={myMergeTags}
									labelTemplate={activeTemplate?.label}
									onChange={handleOnChangeInEditor}
									forceUpdate={forceUpdate}
									onEditorLoad={() => setUnlayerEditorLoader(true)}
									showSaveButton={true}
									css={activeTemplate?.css}
									actions={[
										{
											label: "Envoyer en tant que email",
											function: handleSendEmail,
											export: false,
											withData: true,
										},
									]}
								/>
							</div>
						</form>
					</div>
					<ChangeModal
						updateDetected={confirmUpdate || false}
						onClose={() => {
							setSelectTemplateId(activeTemplate?.unlayerId || "")
							setConfirmUpdate(false)
						}}
						onConfirm={onConfirmModal}
					/>
					<NewTemplateModal
						onClose={() => {
							setShowCreateTemplateModale(false)
						}}
						onConfirm={(typeTemplate: string) => {
							onCreateNewTemplate(typeTemplate)
						}}
						showCreateTemplateModale={showCreateTemplateModale}
					/>
				</div>
			</Col>
			{!unlayerEditorLoading && <LoadingModal message="Chargement des modèles..." isOpen={true} />}
		</>
	)
}

TemplateForm.propTypes = {
	// @ts-ignore
	match: PropTypes.object,
}
export default TemplateForm
