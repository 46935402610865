export const itemsSousTraitants = [
	{
		id: 1,
		value: "Informatique",
		label: "INFORMATIQUE",
	},
	{
		id: 2,
		value: "Cloud",
		label: "CLOUD",
	},
	{
		id: 3,
		value: "Comptabilité",
		label: "COMPTABILITE",
	},
	{
		id: 4,
		value: "Entreprise de communication",
		label: "COMMUNICATION",
	},
]
