import { Transfer } from "@audiowizard/common"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { colorFromStr, getIdFromIri } from "services/functions"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		width: "80px",
		title: "Transfer",
		dataIndex: "transfer",
		render: (transfer: Transfer): JSX.Element => (
			<Link to={"/mon-stock/transfert/" + getIdFromIri(transfer["@id"])}>
				<button type="button" className="btn btn-block btn-sm btn-secondary pt-1 pb-1 text-left">
					voir
				</button>
			</Link>
		),
	},
	{
		title: "Origine",
		dataIndex: "transfer",
		render: (transfer: Transfer): JSX.Element => {
			const label = transfer?.senderLaboratory?.label

			if (!label) return <>-</>

			return (
				<>
					<span className="pill-laboratory" style={{ backgroundColor: colorFromStr(label) }} /> {label}
				</>
			)
		},
	},
	{
		title: "Destination",
		dataIndex: "transfer",
		render: (transfer: Transfer): JSX.Element => {
			const label = transfer?.recipientLaboratory?.label

			if (!label) return <>-</>

			return (
				<>
					<span className="pill-laboratory" style={{ backgroundColor: colorFromStr(label) }} /> {label}
				</>
			)
		},
	},
	{
		wdith: "140px",
		title: "Déclinaison",
		dataIndex: "sizeName",
	},
	{
		title: "Quantité",
		dataIndex: "quantity",
		align: "center",
		width: "60px",
	},
	{
		title: "Numéro de série",
		dataIndex: "serialNumber",
		render: (serialNumber: string): JSX.Element => (
			<>
				{serialNumber ? (
					<span style={{ fontFamily: "Monospace" }}>{serialNumber}</span>
				) : (
					<span style={{ opacity: "0.5" }}>Sans numéro de série</span>
				)}
			</>
		),
	},
	{
		title: "Date de transfert",
		dataIndex: "transfer",
		width: "140px",
		render: (transfer: Transfer): string =>
			transfer?.validatedAt ? dayjs(transfer?.validatedAt).format("DD/MM/YY HH:mm") : "Non finalisé",
	},
]
