import { forwardRef } from "react"
import * as React from "react"
import { Override } from "@audiowizard/common"

type ButtonProps = React.PropsWithChildren<
	Override<
		React.ComponentPropsWithoutRef<"button">,
		{
			text?: string
			icon?: string
			dataToggle?: string
			dataTarget?: string

			className?: string
			dismiss?: boolean
			dataDismiss?: boolean
			dataCy?: string
		}
	>
>
export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
	{
		text,
		icon,
		dataToggle,
		dataTarget,
		className = "m-2 btn btn-outline-primary btn-block",
		children,
		dismiss = false,
		dataDismiss = dismiss,
		type = "button",
		disabled = false,
		dataCy = undefined,
		...props
	}: ButtonProps,
	ref
) {
	return (
		<>
			{(dataToggle && dataTarget && (
				<button
					ref={ref}
					className={className}
					data-toggle={dataToggle}
					data-target={dataTarget}
					type={type}
					data-cy={dataCy}
					disabled={disabled}
					{...props}>
					{text} {icon && <i className={"fad fa-" + icon} />}
				</button>
			)) || (
				<button
					ref={ref}
					className={className}
					data-dismiss={dataDismiss ? "modal" : false}
					type={type}
					data-cy={dataCy}
					disabled={disabled}
					{...props}>
					{children}
					{text} {icon && <i className={"fad fa-" + icon} />}
				</button>
			)}
		</>
	)
})
