const Toggle = ({ isOn, handleToggle, onColor }) => {
	return (
		<>
			<input
				className="react-switch-checkbox"
				id="react-switch-new"
				type="checkbox"
				checked={isOn}
				onChange={handleToggle}
			/>
			<label style={{ background: isOn && onColor }} className="react-switch-label" htmlFor={"react-switch-new"}>
				{isOn && <span id="react-switch-text-yes">Oui</span>} <span className="react-switch-button" />{" "}
				{!isOn && <span id="react-switch-text-no">Non</span>}
			</label>
		</>
	)
}

export default Toggle
