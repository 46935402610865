import { Affiliation, User, UserRole } from "@audiowizard/common"
import { Select, Switch } from "antd"
import { useAllLaboratoriesQuery } from "components/Hooks/commonQueries"
import { useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap"
import { haveRole, partialSearch, SortAlphabetically } from "services/functions"
import { fetchUser } from "services/AuthApi"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import UserRegistrationStatusSelect, {
	AudioStatus,
} from "components/forms/UserRegistrationStatusSelect/UserRegistrationStatusSelect"

const defaultValue: {
	roles: UserRole[]
	laboratories: string[]
	isArchived: boolean
	registrationStatus?: AudioStatus
	affiliation?: Affiliation
} = {
	roles: ["ROLE_USER"],
	laboratories: [],
	isArchived: false,
	registrationStatus: AudioStatus.Other,
}

type EditUserModalProps = {
	open: boolean
	onClose: () => void
	user?: User | null
	isLoadingAffiliations: boolean | undefined
	affiliations: Affiliation[] | undefined
}
export default function EditUserModal({
	open,
	onClose,
	user: selectedUser,
	affiliations,
}: EditUserModalProps): JSX.Element {
	const queryClient = useQueryClient()
	const { user, setUser } = useContext(AuthContext)

	const isEditingSelf = user.id === selectedUser?.id
	const isEditingOtherManager = !isEditingSelf && selectedUser != null && haveRole(selectedUser, "ROLE_MANAGER")

	const [values, setValues] = useState(defaultValue)

	const { data: laboratories, isLoading: isLoadingLaboratories } = useAllLaboratoriesQuery()

	useEffect(() => {
		if (selectedUser == null) return
		setValues({
			...values,
			laboratories: selectedUser.laboratories!.map((l) => l["@id"]),
			affiliation: selectedUser.affiliation,
			roles: selectedUser.roles!,
			isArchived: !!selectedUser.isArchived,
			// @ts-ignore need common update
			registrationStatus: selectedUser.registrationStatus || AudioStatus.Other,
		})
	}, [selectedUser])

	const { mutateAsync: updateUser, isLoading } = useMutation(() => {
		if (selectedUser == null || isEditingOtherManager) return Promise.resolve(void 0)

		const data: Partial<typeof defaultValue> = {
			laboratories: values.laboratories,
		}

		if (values.registrationStatus) {
			data.registrationStatus = values.registrationStatus
		}
		// Only add roles to query when not editing self
		if (!isEditingSelf) {
			data.roles = values.roles
		}
		data.isArchived = values.isArchived

		return API.patch("USERS_API", selectedUser.id!, data)
	})

	const updateSelfUserLabs = async (): Promise<void> => {
		const selfUser = await fetchUser()
		const selfUserLabs = [...(selfUser.laboratories ?? [])]

		if (selfUserLabs.length) {
			SortAlphabetically(selfUserLabs, "label")
		}

		setUser((prev) => ({ ...prev, laboratories: selfUserLabs }))
	}

	const handleSubmit = async (): Promise<void> => {
		try {
			await updateUser()

			if (isEditingSelf) {
				await updateSelfUserLabs()
			}

			await queryClient.invalidateQueries("USERS_API")
			toast.success("Modifications enregistrées")
			onClose()
		} catch {
			toast.error("Impossible d'enregistrer la modification, veuillez réessayer")
		}
	}
	return (
		<Modal isOpen={open} toggle={onClose} centered size="lg">
			<form
				onSubmit={async (e) => {
					e.preventDefault()
					await handleSubmit()
				}}>
				<ModalHeader tag="div">
					<div className="d-flex align-items-center">
						<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
							<i className="fad fa-user" />
						</div>

						<h6 className="mb-0">Modifier les informations</h6>

						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
							title="Fermer"
							onClick={onClose}>
							<i className="fas fa-times" />
						</button>
					</div>
				</ModalHeader>

				<ModalBody>
					<Row>
						<Col>
							<div className="w-100 form-group">
								<label htmlFor="roles">Rôle</label>
								<Select
									id="roles"
									className="form-control w-100 removeantd-class antd-add-padding"
									disabled={
										isEditingSelf ||
										isEditingOtherManager ||
										values!.roles![0] === "ROLE_AFFILIATE" ||
										values!.roles![0] === "ROLE_AFFILIATE_MANAGER"
									}
									value={values!.roles![0]}
									onChange={(role) => setValues({ ...values, roles: [role] })}>
									<Select.Option value="ROLE_MANAGER">Manager</Select.Option>
									<Select.Option value="ROLE_MANAGER_FRANCHISED">Manager laboratoire</Select.Option>
									<Select.Option value="ROLE_USER">Audioprothésiste</Select.Option>
									<Select.Option value="ROLE_ASSISTANT">Assistant(e)</Select.Option>
									<Select.Option value="ROLE_AFFILIATE">Affilié</Select.Option>
								</Select>
								{isEditingSelf && (
									<small className="text-warning">Vous ne pouvez pas modifier votre rôle.</small>
								)}
								{isEditingOtherManager && (
									<small className="text-warning">
										Vous ne pouvez pas modifier le rôle d'un autre manager.
									</small>
								)}
							</div>
						</Col>
						<Col sm={8}>
							<UserRegistrationStatusSelect
								registrationStatus={values.registrationStatus}
								roles={values?.roles}
								disabled={isEditingOtherManager}
								handleChangeRegistrationStatus={(status: AudioStatus) =>
									setValues((oldValues) => ({ ...oldValues, registrationStatus: status }))
								}
							/>
							{isEditingOtherManager && (
								<small className="text-warning">
									Vous ne pouvez pas modifier le statut d'un autre manager.
								</small>
							)}
						</Col>
					</Row>
					{values.roles.includes("ROLE_AFFILIATE") && (
						<Row>
							<Col>
								<div className="w-100 form-group">
									<label htmlFor="roles">Affiliation</label>
									<Select
										id="affiliation"
										className="form-control w-100 removeantd-class antd-add-padding"
										disabled
										value={values.affiliation}
										onChange={(affiliation) => setValues({ ...values, affiliation: affiliation })}>
										{affiliations?.map((affiliation) => (
											<Select.Option key={affiliation.id} value={affiliation?.["@id"]}>
												{affiliation.label}
											</Select.Option>
										))}
									</Select>
									{isEditingOtherManager && (
										<small className="text-warning">
											Vous ne pouvez pas modifier l'affiliation d'un autre manager.
										</small>
									)}
								</div>
							</Col>
						</Row>
					)}

					<Row>
						<Col>
							<div className="w-100 form-group">
								<label htmlFor="select-laboratory">Laboratoires associés *</label>
								<Select
									showSearch
									filterOption={(input, option) => partialSearch(option?.label as string, input)}
									className="w-100"
									size="large"
									mode="multiple"
									placeholder="Merci de sélectionner les laboratoires associés à cet utilisateur"
									disabled={isEditingOtherManager}
									loading={isLoadingLaboratories}
									value={values.laboratories}
									onChange={(laboratories) => setValues({ ...values, laboratories })}
									options={laboratories?.map((lab) => {
										return {
											label: lab?.label,
											value: lab?.["@id"],
										}
									})}
								/>

								{isEditingOtherManager && (
									<small className="text-warning">
										Vous ne pouvez pas modifier les laboratoires d'un autre manager.
									</small>
								)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div>
								<Switch
									checked={values.isArchived}
									onChange={(checked) => {
										setValues({ ...values, isArchived: checked })
									}}
								/>{" "}
								<label>{values.isArchived ? "inactif" : "actif"}</label>
							</div>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter>
					<Button color="primary" type="submit" disabled={isEditingOtherManager || isLoading}>
						{isLoading && <Spinner size="sm" />} Modifier
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	)
}
