import useHasRole from "components/Hooks/useHasRole"
import { TableauDeBord } from "pages/TableauDeBord/TableauDeBord"
import { lazy } from "react"
import { Redirect, Switch } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import Statistiques from "pages/statistiques/Index"

const MesPatients = lazy(() => import("../pages/MesPatients"))
const MesRendezvous = lazy(() => import("../pages/MesRendezvous"))
const HistoriqueRendezvous = lazy(() => import("../pages/fiche-patient/HistoriqueRendezVous"))
const FichePatientAffilie = lazy(() => import("../pages/fiche-patient/0-FichePatientAffilie"))
const RendezVousNote = lazy(() => import("../pages/rendez-vous/Notes"))
const PremiereVisite = lazy(() => import("../pages/etapes/0-premiere-visite/Index"))
const Visite = lazy(() => import("../pages/etapes/5-visite/Index"))
const SuiteVisite = lazy(() => import("../pages/etapes/0-suite-visite/Index"))
const Adaptation = lazy(() => import("../pages/etapes/1-adaptation/Index"))
const Essais = lazy(() => import("../pages/etapes/2-essais/Index"))
const Appareille = lazy(() => import("../pages/etapes/3-appareille/Index"))
const Relance = lazy(() => import("../pages/etapes/4-relance/Index"))
const SalleAttenteUI = lazy(() => import("../pages/salle-attente/Index"))
const BryntumAgenda = lazy(() => import("pages/Schedules/Agenda/BryntumAgenda"))
const HomePage = lazy(() => import("../pages/accueil/HomePage"))
const MesMutuelles = lazy(() => import("../pages/mon-compte/MutuellesCard"))
const MesInformations = lazy(() => import("../pages/mon-compte/MesInformationsCard"))

const AffiliateRouter = () => {
	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")
	return (
		<>
			{(isAffiliate || isAffiliateManager) && (
				<>
					<Switch>
						<PrivateRoute path="/tableau-de-bord" component={TableauDeBord} />
						<PrivateRoute path="/mes-patients/" component={MesPatients} />
						<PrivateRoute path="/mes-rendezvous" component={MesRendezvous} />
						<PrivateRoute path="/mes-patients/:status" component={MesPatients} />
						<PrivateRoute
							path="/fiche-patient/:id/historique-rendez-vous"
							component={HistoriqueRendezvous}
						/>
						<PrivateRoute path="/fiche-patient/:id" component={FichePatientAffilie} />
						<PrivateRoute path="/rendez-vous/notes" component={RendezVousNote} />
						<PrivateRoute path="/premiere-visite/:id" component={PremiereVisite} />
						<PrivateRoute path="/visite/:id" component={Visite} />
						<PrivateRoute path="/suite-visite/:id" component={SuiteVisite} />
						<PrivateRoute path="/salle-attente/:id" component={SalleAttenteUI} allowPatientUI />
						<PrivateRoute path="/adaptation/:id" component={Adaptation} />
						<PrivateRoute path="/essais/:id" component={Essais} />
						<PrivateRoute path="/appareille/:id" component={Appareille} />
						<PrivateRoute path="/relance/:id" component={Relance} />
						<PrivateRoute path="/agenda" component={BryntumAgenda} />
						<PrivateRoute path="/mon-compte/mes-mutuelles" component={MesMutuelles} />
						<PrivateRoute path="/statistiques" component={Statistiques} />
						<PrivateRoute path="/mon-compte/mes-informations" component={MesInformations} />
						<PrivateRoute path="/" component={HomePage} />
						<Redirect to="/" />
					</Switch>
				</>
			)}
		</>
	)
}

export default AffiliateRouter
