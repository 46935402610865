import { Link } from "react-router-dom"
import { Input, Form } from "antd"

const LastNameCol = (editingKey, isAddingLead, sort) => {
	const lastNameRender = (lastName, record) => {
		return editingKey === record.key ? (
			<Form.Item
				name={"patient.lastName"}
				style={{
					margin: 0,
				}}>
				<Input style={{ maxWidth: "100px" }} />
			</Form.Item>
		) : (
			<Link
				className="mx-2"
				to={{
					pathname: "/fiche-patient/" + record?.["patient.id"],
				}}>
				{" "}
				{lastName.toUpperCase()}{" "}
			</Link>
		)
	}

	return {
		title: null,
		key: "lastName",
		dataIndex: "patient.lastName",
		width: "100px",
		fixed: "left",
		render: lastNameRender,
		sorter: !isAddingLead,
		sortOrder: sort?.field === "patient.lastName" ? sort.order : null,
		validate: (cond) =>
			!cond["patient.lastName"] || cond["patient.lastName"] === ""
				? "Veuillez renseigner le nom du patient."
				: "",
	}
}

export default LastNameCol
