import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Switch } from "antd"

interface CustomSwitchProps<T> {
	checked: boolean
	onChange: (value: any, row: T, param: keyof T) => void
	param: keyof T
	row: T
}

const CustomSwitchForTable = <T,>({ checked, onChange, param, row }: CustomSwitchProps<T>): JSX.Element => {
	return (
		<Switch
			checked={checked}
			checkedChildren={<CheckOutlined />}
			unCheckedChildren={<CloseOutlined />}
			onChange={(value) => {
				onChange(value, row, param)
			}}
			defaultChecked
		/>
	)
}

export default CustomSwitchForTable
