import AuthContext from "contexts/AuthContext"
import { useContext } from "react"

const APT_COMPANY_ID = 46

export function useIsApt(): boolean {
	const { user } = useContext(AuthContext)

	return user.company?.id === APT_COMPANY_ID
}
