export const itemsConfiguration = [
	{
		id: 1,
		label: "Devis simplifié",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église, 69000, Lyon",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 6,
				variable: "{d.company.adeli}",
				description: "Numéro adeli de l'utilisateur connecté",
				dataType: "42 01 02 03 7",
			},
			{
				id: 7,
				variable: "{d.company.siret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 8,
				variable: "{d.company.rcs}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.finess}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 10,
				variable: "{d.company.tva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 11,
				variable: "{d.patient.patientName}",
				description: "Nom du patient",
				dataType: "Marcel Durand",
			},
			{
				id: 12,
				variable: "{d.patient.patientMail}",
				description: "Adresse email du patient",
				dataType: "marcel@durand.fr",
			},
			{
				id: 13,
				variable: "{d.patient.patientAdress}",
				description: "Adresse postale du patient",
				dataType: "10 place Jean Jaurès, 42000, Saint-Etienne",
			},
			{
				id: 14,
				variable: "{d.patient.secu}",
				description: "Numéro de sécurité sociale du patient",
				dataType: "1 69 05 00 006 000 80",
			},
			{
				id: 15,
				variable: "{d.isAtLeastOneProduct}",
				description: "Condition vérifiant si le devis contient au moins un produit",
				dataType: "true",
			},
			{
				id: 16,
				variable: "{d.isAtLeastOneOther}",
				description: "Condition vérifiant si le devis contient au moins un accessoire",
				dataType: "true",
			},
			{
				id: 17,
				variable: "{d.products[i].productDetails.product_model}",
				description: "Modèle du produit",
				dataType: "Audéo M90-R",
			},
			{
				id: 18,
				variable: "{d.products[i].productDetails.product_supplier_reference}",
				description: "Fabricant du produit",
				dataType: "Sonova",
			},
			{
				id: 19,
				variable: "{d.products[i].productDetails.ear}",
				description: "Oreille concernée",
				dataType: "Gauche",
			},
			{
				id: 20,
				variable: "{d.products[i].HTPrice}",
				description: "Prix Hors Taxe",
				dataType: "200€",
			},
			{
				id: 21,
				variable: "{d.products[i].discount}",
				description: "Remise appliquée",
				dataType: "50€",
			},
			{
				id: 22,
				variable: "{d.products[i].HTPriceWithoutDiscount}",
				description: "Prix HT après remise",
				dataType: "150€",
			},
			{
				id: 23,
				variable: "{d.products[i].TVA}",
				description: "Montant de TVA",
				dataType: "8,25€",
			},
			{
				id: 24,
				variable: "{d.products[i].TTCPrice}",
				description: "Prix Toutes Taxes Comprises",
				dataType: "158,25€",
			},
			{
				id: 25,
				variable: "{d.accessories[i].accessoryDetails.product_model}",
				description: "Modèle de l'accessoire",
				dataType: "Accessoire Audéo",
			},
			{
				id: 26,
				variable: "{d.accessories[i].accessoryDetails.product_supplier_reference}",
				description: "Fabricant du produit",
				dataType: "Sonova",
			},
			{
				id: 27,
				variable: "{d.accessories[i].HTPrice}",
				description: "Prix Hors Taxe",
				dataType: "50€",
			},
			{
				id: 28,
				variable: "{d.accessories[i].TVA}",
				description: "Montant de TVA",
				dataType: "10€",
			},
			{
				id: 29,
				variable: "{d.products[i].TTCPrice}",
				description: "Prix Toutes Taxes Comprises",
				dataType: "60€",
			},
			{
				id: 30,
				variable: "{d.secuClass1}",
				description: "Remboursement de la sécurité sociale pour la Classe 1",
				dataType: "100€",
			},
			{
				id: 31,
				variable: "{d.secuClass2}",
				description: "Remboursement de la sécurité sociale pour la Classe 2",
				dataType: "10€",
			},
			{
				id: 32,
				variable: "{d.amountInsuranceClass1}",
				description: "Remboursement de l'assurance pour la Classe 1",
				dataType: "50€",
			},
			{
				id: 33,
				variable: "{d.amountInsuranceClass2}",
				description: "Remboursement de l'assurance pour la Classe 2",
				dataType: "30€",
			},
		],
	},
	{
		id: 2,
		label: "Chiffre d'affaires par catégorie de produits",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.total.quantity}",
				description: "Quantité totale de produits vendus",
				dataType: "100",
			},
			{
				id: 13,
				variable: "{d.total.grossTaxesIncluded}",
				description: "Montant Brut TTC",
				dataType: "11 000€",
			},
			{
				id: 14,
				variable: "{d.total.discount}",
				description: "Remise",
				dataType: "1000€",
			},
			{
				id: 15,
				variable: "{d.total.netTaxesIncluded}",
				description: "Montant Net TTC",
				dataType: "10 000€",
			},
			{
				id: 16,
				variable: "{d.total.vat}",
				description: "Montant TVA",
				dataType: "550€",
			},
			{
				id: 17,
				variable: "{d.total.grossWithoutTax}",
				description: "Montant Brut HT",
				dataType: "9 000€",
			},
			{
				id: 18,
				variable: "{d.total.netWithoutTax}",
				description: "Montant Net HT",
				dataType: "8 000€",
			},
			{
				id: 19,
				variable: "{d.total.amountNormalVAT}",
				description: "Montant de TVA à 20%",
				dataType: "1000€",
			},
			{
				id: 20,
				variable: "{d.total.amountReducedVAT}",
				description: "Montant de TVA à 5,5%",
				dataType: "1000€",
			},
			{
				id: 21,
				variable: "{d.products[i]categoryName}",
				description: "Nom de la catégorie de produits",
				dataType: "Appareils auditifs",
			},
			{
				id: 22,
				variable: "{d.products[i]quantity}",
				description: "Quantité de produits par catégorie",
				dataType: "100",
			},
			{
				id: 23,
				variable: "{d.products[i]discount}",
				description: "Quantité de produits par catégorie",
				dataType: "100",
			},
			{
				id: 24,
				variable: "{d.products[i]grossTaxesIncluded}",
				description: "Montant Brut TTC par catégorie",
				dataType: "11 000€",
			},
			{
				id: 25,
				variable: "{d.products[i]discount}",
				description: "Remise par catégorie",
				dataType: "1000€",
			},
			{
				id: 26,
				variable: "{d.products[i]netTaxesIncluded}",
				description: "Montant Net TTC par catégorie",
				dataType: "10 000€",
			},
			{
				id: 27,
				variable: "{d.products[i]vat}",
				description: "Montant TVA par catégorie",
				dataType: "550€",
			},
			{
				id: 28,
				variable: "{d.products[i]grossWithoutTax}",
				description: "Montant Brut HT par catégorie",
				dataType: "9 000€",
			},
			{
				id: 29,
				variable: "{d.products[i]netWithoutTax}",
				description: "Montant Net HT par catégorie",
				dataType: "8 000€",
			},
			{
				id: 30,
				variable: "{d.products[i]amountNormalVAT}",
				description: "Montant de TVA à 20% par catégorie",
				dataType: "1000€",
			},
			{
				id: 31,
				variable: "{d.products[i]amountReducedVAT}",
				description: "Montant de TVA à 5,5% par catégorie",
				dataType: "1000€",
			},
		],
	},
	{
		id: 3,
		label: "Détails des encaissements par jour",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.total.cb}",
				description: "Montant total encaissé par cartes bancaires",
				dataType: "100€",
			},
			{
				id: 13,
				variable: "{d.total.check}",
				description: "Montant total encaissé par chèques",
				dataType: "2200€",
			},
			{
				id: 14,
				variable: "{d.total.cash}",
				description: "Montant total encaissé par espèces",
				dataType: "500€",
			},
			{
				id: 15,
				variable: "{d.total.transfer}",
				description: "Montant total encaissé par virements",
				dataType: "3000€",
			},
			{
				id: 16,
				variable: "{d.total.mutual}",
				description: "Montant total encaissé par les mutuelles",
				dataType: "1000€",
			},
			{
				id: 17,
				variable: "{d.total.secu}",
				description: "Montant total encaissé par la sécurité sociale",
				dataType: "300€",
			},
			{
				id: 18,
				variable: "{d.details[i].date}",
				description: "Journée d'encaissement",
				dataType: "14/11/2020",
			},
			{
				id: 19,
				variable: "{d.details[i].check}",
				description: "Montant encaissé par chèques par jour",
				dataType: "200€",
			},
			{
				id: 20,
				variable: "{d.details[i].cash}",
				description: "Montant encaissé par espèces par jour",
				dataType: "500€",
			},
			{
				id: 21,
				variable: "{d.details[i].transfer}",
				description: "Montant encaissé par virements par jour",
				dataType: "1000€",
			},
			{
				id: 22,
				variable: "{d.details[i].mutual}",
				description: "Montant encaissé par les mutuelles par jour",
				dataType: "3000€",
			},
			{
				id: 22,
				variable: "{d.details[i].mutual}",
				description: "Montant encaissé par la sécurité sociale par jour",
				dataType: "2000€",
			},
		],
	},
	{
		id: 4,
		label: "Feuille de caisse",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.header.totalPayments}",
				description: "Total des règlements",
				dataType: "10 000€",
			},
			{
				id: 13,
				variable: "{d.header.taxRefund}",
				description: "Total de la détaxe",
				dataType: "1000€",
			},
			{
				id: 14,
				variable: "{d.header.totalAdded}",
				description: "Total des règlements + détaxe",
				dataType: "11 000€",
			},
			{
				id: 15,
				variable: "{d.cashFundTable.inputs.startPeriodCash}",
				description: "Fond de caisse début de période",
				dataType: "20 000€",
			},
			{
				id: 16,
				variable: "{d.cashFundTable.inputs.cashReceipts}",
				description: "Encaissements en espèces",
				dataType: "5000€",
			},
			{
				id: 17,
				variable: "{d.cashFundTable.inputs.variousReceipts}",
				description: "Entrées diverses",
				dataType: "2000€",
			},
			{
				id: 18,
				variable: "{d.cashFundTable.inputs.totalCashReceipts}",
				description: "Total des entrées en espèces",
				dataType: "27 000€",
			},
			{
				id: 19,
				variable: "{d.cashFundTable.inputs.totalCashReceipts}",
				description: "Total des entrées en espèces",
				dataType: "27 000€",
			},
			{
				id: 20,
				variable: "{d.cashFundTable.outputs.cashMovements}",
				description: "Sorties de caisse",
				dataType: "2000€",
			},
			{
				id: 21,
				variable: "{d.cashFundTable.outputs.bankCashRemittance}",
				description: "Remise d'espèces en banque",
				dataType: "3000€",
			},
			{
				id: 22,
				variable: "{d.cashFundTable.outputs.totalCashMovements}",
				description: "Total des sorties d'espèces",
				dataType: "5000€",
			},
			{
				id: 23,
				variable: "{d.cashFundTable.endPeriodCash}",
				description: "Fond de caisse en fin de période",
				dataType: "10 000€",
			},
			{
				id: 24,
				variable: "{d.cashFundTable.cashErrors}",
				description: "Erreurs de caisse",
				dataType: "300€",
			},
			{
				id: 25,
				variable: "{d.cashFundTable.finalCashFund}",
				description: "Fond de caisse définitif",
				dataType: "8000€",
			},
			{
				id: 26,
				variable: "{d.cashDetailsTable[i].label}",
				description: "Nom de l'encaissement",
				dataType: "Carte bancaire",
			},
			{
				id: 23,
				variable: "{d.cashDetailsTable[i].quantity}",
				description: "Quantité par type d'encaissement",
				dataType: "10",
			},
			{
				id: 27,
				variable: "{d.cashDetailsTable[i].amount}",
				description: "Montant par type d'encaissement",
				dataType: "2000€",
			},
			{
				id: 28,
				variable: "{d.toBeCashedLater[i].label}",
				description: "Nom d'encaissement à encaisser plus tard (paiements différés)",
				dataType: "Chèque",
			},
			{
				id: 29,
				variable: "{d.toBeCashedLater[i].quantity}",
				description: "Quantité par type d'encaissement à encaisser plus tard (paiements différés)",
				dataType: "5",
			},
			{
				id: 30,
				variable: "{d.toBeCashedLater[i].amount}",
				description: "Montant par type d'encaissement à encaisser plus tard (paiements différés)",
				dataType: "500€",
			},
			{
				id: 31,
				variable: "{d.otherMoves.quantity}",
				description: "Quantité des avoirs utilisés",
				dataType: "10",
			},
			{
				id: 32,
				variable: "{d.otherMoves.amount}",
				description: "Montant des avoirs utilisés",
				dataType: "500€",
			},
		],
	},
	{
		id: 5,
		label: "Journal de caisse",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.data[i].center}",
				description: "Nom du centre",
				dataType: "Laboratoire du Dauphinée",
			},
			{
				id: 13,
				variable: "{d.data[i].date}",
				description: "Date",
				dataType: "14/11/2020",
			},
			{
				id: 14,
				variable: "{d.data[i].invoiceNumber}",
				description: "Numéro de facture",
				dataType: "2020-11-20-23",
			},
			{
				id: 15,
				variable: "{d.data[i].description}",
				description: "Description de la vente",
				dataType: "CHE Jean Durand",
			},
			{
				id: 16,
				variable: "{d.data[i].debit}",
				description: "Montant total",
				dataType: "300€",
			},
			{
				id: 17,
				variable: "{d.data[i].credit}",
				description: "Montant déjà payé",
				dataType: "100€",
			},
			{
				id: 18,
				variable: "{d.data[i].remainingSold}",
				description: "Montant devant être payé",
				dataType: "200€",
			},
			{
				id: 19,
				variable: "{d.totalHT}",
				description: "Montant total des ventes",
				dataType: "10 000€",
			},
			{
				id: 20,
				variable: "{d.totalVAT}",
				description: "Montant total des entrées",
				dataType: "8 000€",
			},
			{
				id: 21,
				variable: "{d.totalTTC}",
				description: "Montant total du reste dû",
				dataType: "2 000€",
			},
		],
	},
	{
		id: 6,
		label: "Journal des ventes",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.data[i].center}",
				description: "Nom du centre",
				dataType: "Laboratoire du Dauphinée",
			},
			{
				id: 13,
				variable: "{d.data[i].date}",
				description: "Date",
				dataType: "14/11/2020",
			},
			{
				id: 14,
				variable: "{d.data[i].invoiceNumber}",
				description: "Numéro de facture",
				dataType: "2020-11-20-23",
			},
			{
				id: 15,
				variable: "{d.data[i].HTReducedVAT}",
				description: "Montant de la TVA à 5,5%",
				dataType: "55€",
			},
			{
				id: 16,
				variable: "{d.data[i].HTNormalVAT}",
				description: "Montant de la TVA à 20%",
				dataType: "200€",
			},
			{
				id: 17,
				variable: "{d.data[i].totalAmountNormalVAT}",
				description: "Montant de la TVA à 20%",
				dataType: "200€",
			},
			{
				id: 18,
				variable: "{d.data[i].remainingSold}",
				description: "Montant devant être payé",
				dataType: "200€",
			},
			{
				id: 19,
				variable: "{d.totalHT}",
				description: "Montant total des ventes",
				dataType: "10 000€",
			},
			{
				id: 20,
				variable: "{d.totalVAT}",
				description: "Montant total des entrées",
				dataType: "8 000€",
			},
			{
				id: 21,
				variable: "{d.totalTTC}",
				description: "Montant total du reste dû",
				dataType: "2 000€",
			},
		],
	},
	{
		id: 7,
		label: "Mouvements de caisse",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.data[i].date}",
				description: "Date du mouvement de caisse",
				dataType: "25/12/2020",
			},
			{
				id: 12,
				variable: "{d.data[i].type}",
				description: "Type du mouvement de caisse",
				dataType: "Retrait",
			},
			{
				id: 12,
				variable: "{d.data[i].total}",
				description: "Montant du mouvement de caisse",
				dataType: "300€",
			},
			{
				id: 13,
				variable: "{d.data[i].total}",
				description: "Total des mouvements de caisse pour la période choisie",
				dataType: "5500€",
			},
		],
	},
	{
		id: 8,
		label: "Restes à encaisser",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.data[i].dateOfSale}",
				description: "Date de la vente",
				dataType: "15/12/2020",
			},
			{
				id: 13,
				variable: "{d.data[i].dateOfPayment}",
				description: "Date du paiement",
				dataType: "20/12/2020",
			},
			{
				id: 14,
				variable: "{d.data[i].invoiceNumber}",
				description: "Numéro de facture",
				dataType: "CB Jean Durand",
			},
			{
				id: 15,
				variable: "{d.data[i].client}",
				description: "Nom du client",
				dataType: "Fred Hermel",
			},
			{
				id: 16,
				variable: "{d.data[i].paymentType}",
				description: "Type de paiement",
				dataType: "CB",
			},
			{
				id: 17,
				variable: "{d.data[i].amount}",
				description: "Montant du paiement",
				dataType: "300€",
			},
			{
				id: 18,
				variable: "{d.totalBankCard}",
				description: "Total des paiements par carte bancaire",
				dataType: "100€",
			},
			{
				id: 19,
				variable: "{d.totalCash}",
				description: "Total des paiements par espèces",
				dataType: "100€",
			},
			{
				id: 20,
				variable: "{d.totalTransfer}",
				description: "Total des paiements par virements",
				dataType: "100€",
			},
			{
				id: 21,
				variable: "{d.totalCheck}",
				description: "Total des paiements par chèques",
				dataType: "100€",
			},
			{
				id: 22,
				variable: "{d.totalMutual}",
				description: "Total des paiements par mutuelles",
				dataType: "100€",
			},
			{
				id: 22,
				variable: "{d.totalSecu}",
				description: "Total des paiements par sécurité sociale",
				dataType: "100€",
			},
			{
				id: 22,
				variable: "{d.totalSecu}",
				description: "Total des paiements",
				dataType: "100€",
			},
		],
	},
	{
		id: 9,
		label: "SAV",
		json: [
			{
				id: 0,
				variable: "{d.company.companyLogo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.companyName}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.company.userName}",
				description: "Utilisateur connecté de votre entreprise",
				dataType: "Jean Dupont",
			},
			{
				id: 3,
				variable: "{d.company.laboratoryAddress}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.company.laboratoryCpo}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.company.laboratoryCity}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.company.laboratoryPhone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.company.laboratoryMail}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.laboratoryRCS}",
				description: "Numéro RCS du laboratoire",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.laboratorySiret}",
				description: "Numéro SIRET du laboratoire",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.laboratoryFiness}",
				description: "Numéro FINESS du laboratoire",
				dataType: "42 01 02 03 7",
			},
			{
				id: 11,
				variable: "{d.company.laboratoryTva}",
				description: "Numéro TVA du laboratoire",
				dataType: "FR44651650332",
			},
			{
				id: 12,
				variable: "{d.details.savNumber}",
				description: "Numéro du SAV",
				dataType: "4",
			},
			{
				id: 13,
				variable: "{d.details.sentDate}",
				description: "Numéro du SAV",
				dataType: "4",
			},
			{
				id: 14,
				variable: "{d.details.model}",
				description: "Modèle concerné",
				dataType: "Audéo M90-R",
			},
			{
				id: 15,
				variable: "{d.details.manufacturer}",
				description: "Fabricant",
				dataType: "Sonova",
			},
			{
				id: 16,
				variable: "{d.details.serialNumber}",
				description: "Numéro de série",
				dataType: "1728HZSX8",
			},
			{
				id: 17,
				variable: "{d.details.ear}",
				description: "Oreille concernée",
				dataType: "Droite",
			},
			{
				id: 18,
				variable: "{d.details.purchasedDate}",
				description: "Date d'achat",
				dataType: "10/10/2020",
			},
			{
				id: 19,
				variable: "{d.details.warranty}",
				description: "Durée de la garantie du produit",
				dataType: "4 ans",
			},
			{
				id: 20,
				variable: "{d.details.breakdowns}",
				description: "Pannes observées",
				dataType: "Muet, endommagé.",
			},
			{
				id: 21,
				variable: "{d.details.repairs}",
				description: "Réparations effectuées",
				dataType: "Oreillette.",
			},
			{
				id: 22,
				variable: "{d.details.returnedDate}",
				description: "Date de retour de réparation",
				dataType: "30/12/2020",
			},
			{
				id: 23,
				variable: "{d.details.breakdownsComment}",
				description: "Commentaires des pannes",
				dataType: "L'appareil ne fonctionnait pas correments, un problème de son perdurait.",
			},
			{
				id: 24,
				variable: "{d.details.repairsComment}",
				description: "Commentaires des réparations",
				dataType: "L'appareil fonctionne de nouveau, un dérèglement s'était produit.",
			},
			{
				id: 25,
				variable: "{d.details.repairPriceWithoutTax}",
				description: "Prix de la réparation HT",
				dataType: "200€",
			},
			{
				id: 26,
				variable: "{d.details.repairPriceWithTax}",
				description: "Prix de la réparation TTC",
				dataType: "220€",
			},
		],
	},
	{
		id: 10,
		label: "Facture",
		json: [
			{
				id: 0,
				variable: "{d.company.logo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.label}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.laboratoire.laboratoryName}",
				description: "Nom de votre laboratoire",
				dataType: "Laboratoire du Dauphiné",
			},
			{
				id: 3,
				variable: "{d.laboratoire.adresse}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.laboratoire.code_postal}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.laboratoire.ville}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.laboratoire.telephone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.laboratoire.email}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.rcs}",
				description: "Numéro RCS de l'entreprise",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.siret}",
				description: "Numéro SIRET de l'entreprise",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.siren}",
				description: "Numéro SIREN de l'entreprise",
				dataType: "846798731200052",
			},
			{
				id: 11,
				variable: "{d.company.capitalStock}",
				description: "Capital social de l'entreprise",
				dataType: "10 000€",
			},
			{
				id: 11,
				variable: "{d.company.tva}",
				description: "Numéro TVA de l'entreprise",
				dataType: "FR44651650332",
			},
			{
				id: 11,
				variable: "{d.ventes.complete_date_jour}/{d.ventes.complete_date_mois}/{d.ventes.complete_date_annee}",
				description: "Date de la facture",
				dataType: "10/10/2020",
			},
			{
				id: 12,
				variable: "{d.id}",
				description: "Identifiant de la facture",
				dataType: "52",
			},
			{
				id: 13,
				variable: "{d.patient.nom}",
				description: "Nom du patient",
				dataType: "Dupont",
			},
			{
				id: 14,
				variable: "{d.patient.prenom}",
				description: "Nom du patient",
				dataType: "Marcel",
			},
			{
				id: 15,
				variable: "{d.patient.email}",
				description: "Adresse email du patient",
				dataType: "marcel.durand@gmail.com",
			},
			{
				id: 16,
				variable: "{d.patient.adresse}",
				description: "Adresse postale du patient",
				dataType: "5 rue du port",
			},
			{
				id: 17,
				variable: "{d.patient.code_postal}",
				description: "Code postal du patient",
				dataType: "13000",
			},
			{
				id: 18,
				variable: "{d.patient.ville}",
				description: "Ville du patient",
				dataType: "Marseille",
			},
			{
				id: 19,
				variable: "{d.patient.secu}",
				description: "Numéro de sécurité sociale du patient",
				dataType: "1 69 05 00 006 000 80",
			},
			{
				id: 20,
				variable:
					"{d.ventes.achats[i].brand_name} {d.ventes.achats[i].category_name} {d.ventes.achats[i].product_model}",
				description: "Modèle, catégorie de produit, et fabricant du produit",
				dataType: "Sonova Appareils auditifs Audéo M90-R",
			},
			{
				id: 21,
				variable: "{d.ventes.achats[i].size_name}",
				description: "Couleur du produit",
				dataType: "Beige",
			},
			{
				id: 22,
				variable: "{d.ventes.achats[i].serial_number}",
				description: "Numéro de série du produit",
				dataType: "XHSK62899",
			},
			{
				id: 23,
				variable: "{d.ventes.achats[i].quantity}",
				description: "Quantité de produits",
				dataType: "3",
			},
			{
				id: 24,
				variable: "{d.ventes.achats[i].item_unit_net}",
				description: "Prix unitaire Hors Taxe",
				dataType: "200€",
			},
			{
				id: 25,
				variable: "{d.ventes.achats[i].tax_value}",
				description: "Montant de TVA",
				dataType: "20€",
			},
			{
				id: 26,
				variable: "{d.ventes.achats[i].item_discount_gross}",
				description: "Remise appliquée",
				dataType: "100€",
			},
			{
				id: 27,
				variable: "{d.ventes.achats[i].item_total_net}",
				description: "Montant hors taxe",
				dataType: "1000€",
			},
			{
				id: 28,
				variable: "{d.ventes.achats[i].item_total_gross}",
				description: "Montant toutes taxes comprises",
				dataType: "2000€",
			},
			{
				id: 29,
				variable: "{d.ventes.paiement}",
				description: "Type du paiement",
				dataType: "CB",
			},
			{
				id: 30,
				variable: "{d.status}",
				description: "Status de la facture",
				dataType: "ACQUITTE",
			},
			{
				id: 31,
				variable: "{d.ventes.payment_details[i].payment_type}",
				description: "Type de règlement par vente effectuée",
				dataType: "CB",
			},
			{
				id: 32,
				variable: "{d.ventes.payment_details[i].payment_amount}",
				description: "Montant par vente effectuée",
				dataType: "400€",
			},
			{
				id: 33,
				variable: "{d.ventes.payment_details[i].payment_date}",
				description: "Date d'encaissement prévue",
				dataType: "400€",
			},
			{
				id: 34,
				variable: "{d.amountDetails[i].line}",
				description: "Numéro ligne de facture",
				dataType: "1",
			},
			{
				id: 35,
				variable: "{d.amountDetails[i].HTAmount}",
				description: "Montant Hors Taxe de la ligne de facture",
				dataType: "100€",
			},
			{
				id: 36,
				variable: "{d.amountDetails[i].TTCAmount}",
				description: "Montant TTC de la ligne de facture",
				dataType: "100€",
			},
			{
				id: 37,
				variable: "{d.amountDetails[i].VAT}",
				description: "Pourcentage de TVA appliqué par ligne de facture",
				dataType: "20%",
			},
			{
				id: 38,
				variable: "{d.amountDetails[i].VATAmount}",
				description: "Montant de TVA appliqué par ligne de facture",
				dataType: "2000€",
			},
			{
				id: 39,
				variable: "{d.amount.totalAmountHT}",
				description: "Montant total HT de la facture",
				dataType: "5000€",
			},
			{
				id: 40,
				variable: "{d.amount.totalAmountTTC}",
				description: "Montant total TTC de la facture",
				dataType: "7000€",
			},
			{
				id: 41,
				variable: "{d.amount.totalAmountTVA}",
				description: "Montant total TVA de la facture",
				dataType: "1000€",
			},
			{
				id: 42,
				variable: "{d.amount.partSecu}",
				description: "Montant TTC pris en charge par la sécurité sociale",
				dataType: "500€",
			},
			{
				id: 43,
				variable: "{d.amount.partMut}",
				description: "Montant TTC pris en charge par la mutuelle",
				dataType: "300€",
			},
			{
				id: 44,
				variable: "{d.amount.restCharge}",
				description: "Reste à charge TTC pour le patient",
				dataType: "400€",
			},
		],
	},
	{
		id: 11,
		label: "Facture différée",
		json: [
			{
				id: 0,
				variable: "{d.company.logo}",
				description: "Image de votre entreprise",
				dataType: "Mon logo",
			},
			{
				id: 1,
				variable: "{d.company.label}",
				description: "Nom de votre entreprise",
				dataType: "SAS Ma Petite Entreprise",
			},
			{
				id: 2,
				variable: "{d.laboratoire.laboratoryName}",
				description: "Nom de votre laboratoire",
				dataType: "Laboratoire du Dauphiné",
			},
			{
				id: 3,
				variable: "{d.laboratoire.adresse}",
				description: "Adresse postale de votre laboratoire",
				dataType: "5 place de l'église",
			},
			{
				id: 4,
				variable: "{d.laboratoire.code_postal}",
				description: "Code postal de votre laboratoire",
				dataType: "69000",
			},
			{
				id: 5,
				variable: "{d.laboratoire.ville}",
				description: "Ville de votre laboratoire",
				dataType: "Lyon",
			},
			{
				id: 6,
				variable: "{d.laboratoire.telephone}",
				description: "Numéro de téléphone de votre laboratoire",
				dataType: "06 01 02 03 04",
			},
			{
				id: 7,
				variable: "{d.laboratoire.email}",
				description: "Adresse email de votre laboratoire",
				dataType: "email@monlaboratoire.fr",
			},
			{
				id: 8,
				variable: "{d.company.rcs}",
				description: "Numéro RCS de l'entreprise",
				dataType: "RCS Paris  654 987 321",
			},
			{
				id: 9,
				variable: "{d.company.siret}",
				description: "Numéro SIRET de l'entreprise",
				dataType: "846798731200052",
			},
			{
				id: 10,
				variable: "{d.company.siren}",
				description: "Numéro SIREN de l'entreprise",
				dataType: "846798731200052",
			},
			{
				id: 11,
				variable: "{d.company.capitalStock}",
				description: "Capital social de l'entreprise",
				dataType: "10 000€",
			},
			{
				id: 11,
				variable: "{d.company.tva}",
				description: "Numéro TVA de l'entreprise",
				dataType: "FR44651650332",
			},
			{
				id: 11,
				variable: "{d.ventes.complete_date_jour}/{d.ventes.complete_date_mois}/{d.ventes.complete_date_annee}",
				description: "Date de la facture",
				dataType: "10/10/2020",
			},
			{
				id: 12,
				variable: "{d.id}",
				description: "Identifiant de la facture",
				dataType: "52",
			},
			{
				id: 13,
				variable: "{d.patient.nom}",
				description: "Nom du patient",
				dataType: "Dupont",
			},
			{
				id: 14,
				variable: "{d.patient.prenom}",
				description: "Nom du patient",
				dataType: "Marcel",
			},
			{
				id: 15,
				variable: "{d.patient.email}",
				description: "Adresse email du patient",
				dataType: "marcel.durand@gmail.com",
			},
			{
				id: 16,
				variable: "{d.patient.adresse}",
				description: "Adresse postale du patient",
				dataType: "5 rue du port",
			},
			{
				id: 17,
				variable: "{d.patient.code_postal}",
				description: "Code postal du patient",
				dataType: "13000",
			},
			{
				id: 18,
				variable: "{d.patient.ville}",
				description: "Ville du patient",
				dataType: "Marseille",
			},
			{
				id: 19,
				variable: "{d.patient.secu}",
				description: "Numéro de sécurité sociale du patient",
				dataType: "1 69 05 00 006 000 80",
			},
			{
				id: 20,
				variable:
					"{d.ventes.achats[i].brand_name} {d.ventes.achats[i].category_name} {d.ventes.achats[i].product_model}",
				description: "Modèle, catégorie de produit, et fabricant du produit",
				dataType: "Sonova Appareils auditifs Audéo M90-R",
			},
			{
				id: 21,
				variable: "{d.ventes.achats[i].size_name}",
				description: "Couleur du produit",
				dataType: "Beige",
			},
			{
				id: 22,
				variable: "{d.ventes.achats[i].serial_number}",
				description: "Numéro de série du produit",
				dataType: "XHSK62899",
			},
			{
				id: 23,
				variable: "{d.ventes.achats[i].quantity}",
				description: "Quantité de produits",
				dataType: "3",
			},
			{
				id: 24,
				variable: "{d.ventes.achats[i].item_unit_net}",
				description: "Prix unitaire Hors Taxe",
				dataType: "200€",
			},
			{
				id: 25,
				variable: "{d.ventes.achats[i].tax_value}",
				description: "Montant de TVA",
				dataType: "20€",
			},
			{
				id: 26,
				variable: "{d.ventes.achats[i].item_discount_gross}",
				description: "Remise appliquée",
				dataType: "100€",
			},
			{
				id: 27,
				variable: "{d.ventes.achats[i].item_total_net}",
				description: "Montant hors taxe",
				dataType: "1000€",
			},
			{
				id: 28,
				variable: "{d.ventes.achats[i].item_total_gross}",
				description: "Montant toutes taxes comprises",
				dataType: "2000€",
			},
			{
				id: 29,
				variable: "{d.ventes.paiement}",
				description: "Type du paiement",
				dataType: "CB",
			},
			{
				id: 30,
				variable: "{d.status}",
				description: "Status de la facture",
				dataType: "ACQUITTE",
			},
			{
				id: 31,
				variable: "{d.ventes.payment_details[i].payment_type}",
				description: "Type de règlement par vente effectuée",
				dataType: "CB",
			},
			{
				id: 32,
				variable: "{d.ventes.payment_details[i].payment_amount}",
				description: "Montant par vente effectuée",
				dataType: "400€",
			},
			{
				id: 33,
				variable: "{d.ventes.payment_details[i].payment_date}",
				description: "Date d'encaissement prévue",
				dataType: "400€",
			},
			{
				id: 34,
				variable: "{d.amountDetails[i].line}",
				description: "Numéro ligne de facture",
				dataType: "1",
			},
			{
				id: 35,
				variable: "{d.amountDetails[i].HTAmount}",
				description: "Montant Hors Taxe de la ligne de facture",
				dataType: "100€",
			},
			{
				id: 36,
				variable: "{d.amountDetails[i].TTCAmount}",
				description: "Montant TTC de la ligne de facture",
				dataType: "100€",
			},
			{
				id: 37,
				variable: "{d.amountDetails[i].VAT}",
				description: "Pourcentage de TVA appliqué par ligne de facture",
				dataType: "20%",
			},
			{
				id: 38,
				variable: "{d.amountDetails[i].VATAmount}",
				description: "Montant de TVA appliqué par ligne de facture",
				dataType: "2000€",
			},
			{
				id: 39,
				variable: "{d.amount.totalAmountHT}",
				description: "Montant total HT de la facture",
				dataType: "5000€",
			},
			{
				id: 40,
				variable: "{d.amount.totalAmountTTC}",
				description: "Montant total TTC de la facture",
				dataType: "7000€",
			},
			{
				id: 41,
				variable: "{d.amount.totalAmountTVA}",
				description: "Montant total TVA de la facture",
				dataType: "1000€",
			},
			{
				id: 42,
				variable: "{d.amount.partSecu}",
				description: "Montant TTC pris en charge par la sécurité sociale",
				dataType: "500€",
			},
			{
				id: 43,
				variable: "{d.amount.partMut}",
				description: "Montant TTC pris en charge par la mutuelle",
				dataType: "300€",
			},
			{
				id: 44,
				variable: "{d.amount.restCharge}",
				description: "Reste à charge TTC pour le patient",
				dataType: "400€",
			},
		],
	},
]
