import { Switch } from "antd"
import cx from "classnames"
import useHasRole from "components/Hooks/useHasRole"
import AuthContext from "contexts/AuthContext"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import Tooltip from "react-tooltip"
import { Col, Row } from "reactstrap"
import API from "../../../services/API"
import { lockState } from "../Utilities"
import PatientEquipmentSelect from "./PatientEquipmentSelect"

const Product = ({
	product,
	index,
	updateFunction,
	busy,
	productBusy,
	setProductsBusy,
	data,
	configuration,
	onChange, // when product is updated
	onError,
	productList,
}) => {
	const { companySettings } = useContext(AuthContext)
	const location = useLocation()
	const [serialNumber, setSerialNumber] = useState(product.product_serial_number)
	const [quantity, setQuantity] = useState(product.quantity)
	const timeoutSerialNumber = useRef(null)
	const timeoutQuantity = useRef(null)

	const [asSerialNumberIncorrectFormat, setAsSerialNumberIncorrectFormat] = useState(false)

	const isManager = useHasRole("ROLE_MANAGER")

	const oldSerialNumber = useMemo(() => product.product_serial_number, [])

	// Forces with_serial_number to only have one patientEquipment
	const selectedPatientEquipments = useMemo(() => {
		if (product.with_serial_number) {
			return product.patientEquipment.length === 0 ? [] : [product.patientEquipment[0]]
		} else {
			return product.patientEquipment
		}
	}, [product.with_serial_number, product.patientEquipment])

	const detail = (function () {
		const productDetail = data.products.find((f) => f.product_id === product?.product_id)

		if (!productDetail) {
			const res = {
				product_model: "Produit archivé",
				archived: true,
			}
			return res
		}

		const res = {
			product_model: productDetail?.product_model,
			...data.suppliers.find((f) => f.supplier_id === productDetail?.product_supplier),
			...data.sizes?.[productDetail?.product_size_type],
			...data.categories.find((f) => f.category_id === productDetail?.product_category),
		}
		return res
	})()

	// Used to check for duplicate serial numbers
	const { data: serialNumbersOfProduct } = useQuery(
		[
			"LOGISTIC_PRODUCTS_API",
			"/serial_numbers",
			{ productId: product.product_id, productSizeId: product.size_id, "logistic.id": configuration.logistic_id },
		],
		async () => {
			const searchParams = new URLSearchParams({ productId: product.product_id })
			if (product.size_id != null) {
				searchParams.set("productSizeId", product.size_id.toString())
			}
			if (configuration.logistic_id != null) {
				searchParams.set("logistic.id!", configuration.logistic_id.toString())
			}

			const serialNumbers = await API.findAll("LOGISTIC_PRODUCTS_API", `/serial_numbers?${searchParams}`)
			return new Set(serialNumbers)
		},
		{
			enabled: product.with_serial_number,
		}
	)
	const serialNumberAlreadyInStock =
		product.with_serial_number && serialNumbersOfProduct != null && serialNumbersOfProduct.has(serialNumber)
	useEffect(() => {
		onError(serialNumberAlreadyInStock)
	}, [serialNumberAlreadyInStock])

	const deleteProduct = async () => {
		await updateFunction("RECEIVED_STATE", index, "DELETE")
	}

	const changeQuantity = async (event) => {
		const value = event.target.value

		if (value < selectedPatientEquipments.length) {
			alert("Vous ne pouvez pas enlever de la quantité, trop d'assignation")
			return
		}
		clearTimeout(timeoutQuantity.current)
		try {
			await updateFunction("QUANTITY", index, value)
			setQuantity(value)
		} catch (error) {
			setQuantity(product.quantity)
		}
	}

	const changeQuantityTimeout = (event) => {
		const value = event.target.value
		if (value <= 0) {
			return
		}

		setQuantity(value)

		if (value < selectedPatientEquipments.length) {
			alert("Vous ne pouvez pas enlever de la quantité, trop d'assignation")
			return
		}
		clearTimeout(timeoutQuantity.current)
		timeoutQuantity.current = null
		timeoutQuantity.current = setTimeout(async () => {
			try {
				await updateFunction("QUANTITY", index, value)
				setQuantity(value)
			} catch (error) {
				setQuantity(product.quantity)
			}
		}, 3000)
	}

	const checkSerialNumber = (input) => {
		const regex = /^[a-zA-Z0-9()]+$/
		if (input && !regex.test(input)) {
			setAsSerialNumberIncorrectFormat(true)
			return false
		}
		setAsSerialNumberIncorrectFormat(false)
		return true
	}

	const handleSerialNumberPaste = (event) => {
		event.preventDefault()
		const pastedString = event.clipboardData.getData("text").replace(/[^a-zA-Z0-9()]+/g, "")
		setSerialNumber(pastedString)
	}

	const changeSerialNumber = async (event) => {
		const value = event.target.value
		if (!checkSerialNumber(value)) {
			return
		}

		clearTimeout(timeoutSerialNumber.current)
		await updateFunction("SERIAL_NUMBER", index, value)
		setSerialNumber(value)
	}

	const changeSerialNumberTimeout = async (event) => {
		const value = event.target.value
		if (!checkSerialNumber(value)) {
			return
		}

		clearTimeout(timeoutSerialNumber.current)
		timeoutSerialNumber.current = null
		timeoutSerialNumber.current = setTimeout(async () => {
			await updateFunction("SERIAL_NUMBER", index, value)
		}, 6000)
		setSerialNumber(value)
	}

	const changeState = async (event) => {
		await updateFunction("RECEIVED_STATE", index, event)
	}

	const changeDepot = async (event) => {
		await updateFunction("DEPOT", index, event)
	}

	const classFromState = useMemo(() => {
		if (product.lock & (lockState.SAV | lockState.SAV_DONE)) {
			return "SAV"
		} else if (product.lock & lockState.VENTE_ATTENTE) {
			return "VENTE_ATTENTE"
		} else if (product.lock & lockState.VENDU) {
			return "VENDU"
		} else if (product.lock & lockState.RENVOYE) {
			return "RENVOYE"
		}
	}, [product.lock])

	const tooltipFromLock = useMemo(() => {
		if (product.isReturnedViaInventory) return "qui a été renvoyé via un inventaire"
		if (!product.lock) return null

		if ((product.lock & lockState.VENTE_ATTENTE) === lockState.VENTE_ATTENTE) {
			return "dans une vente en attente"
		}
		if ((product.lock & lockState.VENDU) === lockState.VENDU) {
			return "déjà vendu"
		}
		if ((product.lock & lockState.RENVOYE) === lockState.RENVOYE) {
			return "renvoyé au fournisseur"
		}
		if ((product.lock & lockState.SAV) === lockState.SAV) {
			return "en SAV"
		}
		if ((product.lock & lockState.TRANSFERRED) === lockState.TRANSFERRED) {
			return "qui a été transferré"
		}
	}, [product.lock, product.isReturnedViaInventory])

	const isSerialNumberAlreadyInInventoryOrder =
		product.product_serial_number &&
		productList.filter((p) => p.product_serial_number === product.product_serial_number).length > 1

	return (
		<div
			className={
				" bl-item" +
				(product?.lock || product.isTransferred ? " edition-blocked" : "") +
				` blocked-${classFromState}`
			}>
			<Row
				data-tip={
					product?.lock || product?.isReturnedViaInventory || product.isTransferred
						? "Vous ne pouvez pas modifier un produit " + tooltipFromLock
						: ""
				}>
				<Col xs={4}>
					{detail.supplier_name && <div className="bl-list-supplier-name">{detail.supplier_name}</div>}
					<div className="bl-list-product-name">{detail.product_model}</div>
					<div className="bl-list-size-name">
						{detail?.sizes?.[product.product_size_id]?.size_name ?? "Pas de déclinaison"}
					</div>
					{/* <pre>
						( {product.inventory_input_detail_id}, {product.logistic_product_id} , {index} )
					</pre> */}
				</Col>

				<Col xs={2}>
					<span className="bl-label-numeroserie">
						{product.with_serial_number ? "Numéro de série" : "Quantité"}
					</span>
					{product.with_serial_number && (
						<>
							<input
								type="text"
								value={serialNumber || ""}
								className={cx("form-control bl-label-numeroserie-input", {
									"is-invalid": serialNumberAlreadyInStock,
								})}
								style={{ fontFamily: "monospace", fontSize: "16px" }}
								onChange={changeSerialNumberTimeout}
								onBlur={changeSerialNumber}
								onPaste={handleSerialNumberPaste}
								disabled={
									product?.lock ||
									busy ||
									productBusy ||
									detail?.archived ||
									(location.state?.isEditing &&
										!companySettings.serialNumberModificationAllowed &&
										!isManager &&
										oldSerialNumber)
								}
							/>
							{serialNumberAlreadyInStock && (
								<div className="d-block invalid-feedback">
									Ce numéro de série est déjà présent dans un autre bon de livraison.
								</div>
							)}

							{isSerialNumberAlreadyInInventoryOrder && (
								<div className="d-block invalid-feedback">
									Ce numéro de série est déjà présent dans ce bon de livraison.
								</div>
							)}

							{asSerialNumberIncorrectFormat && (
								<div className="d-block invalid-feedback">
									Le caractère que vous essayez d'entrer est interdit.
								</div>
							)}
						</>
					)}
					{!product.with_serial_number && (
						<input
							type="number"
							value={quantity}
							className="form-control bl-label-numeroserie-input"
							onBlur={changeQuantity}
							onChange={changeQuantityTimeout}
							min={1}
							disabled={product?.lock || busy || productBusy || detail?.archived}
						/>
					)}
				</Col>
				<Col xs={2}>
					<PatientEquipmentSelect
						product={product}
						detail={detail}
						laboratoryIri={configuration.laboratory}
						warehouseId={configuration?.warehouse_id}
						received={product.received}
						disabled={
							busy ||
							product?.lock ||
							productBusy ||
							(product.with_serial_number && !product.product_serial_number) ||
							!product.quantity ||
							detail?.archived
						}
						hidden={product?.lock}
						value={selectedPatientEquipments}
						onChange={(patientEquipment) => onChange({ ...product, patientEquipment })}
						onLoadingChange={(loading) => setProductsBusy((old) => ({ ...old, [index]: loading }))}
					/>
				</Col>
				<Col xs={1} style={{ maxWidth: "140px" }}>
					<Switch
						style={{ display: product?.lock ? "none" : "block" }}
						checkedChildren="réceptionné"
						unCheckedChildren="non réceptionné"
						checked={product.received || false}
						onChange={changeState}
						disabled={
							(product.with_serial_number && !product.product_serial_number && !product.received) ||
							(product.received && selectedPatientEquipments.length > 0) || // prevent unchecking if equipment associated
							product?.lock ||
							busy ||
							productBusy ||
							product.lock_received_state ||
							detail?.archived
						}
					/>
				</Col>
				<Col xs={1} style={{ maxWidth: "140px" }}>
					<Switch
						style={{ display: product?.lock ? "none" : "block" }}
						checkedChildren="en dépôt"
						unCheckedChildren="ferme"
						checked={product?.depotState === "EN_DEPOT" || product?.isDepot ? true : false}
						disabled={
							!product.with_serial_number ||
							product?.lock ||
							busy ||
							productBusy ||
							!product.received ||
							!configuration.depotEndDate ||
							detail?.archived
						}
						onChange={(event) => changeDepot(event)}
					/>
				</Col>
				<Col
					xs={1}
					className={cx(
						"d-flex align-items-center",
						product.received ||
							product?.lock ||
							busy ||
							productBusy ||
							product?.logistic_product_id === -1 ||
							product?.inventory_input_detail_id
							? "text-black"
							: "text-danger cursor-pointer"
					)}
					style={{ maxWidth: "70px", height: "50px", display: product?.lock ? "none" : "block" }}
					key={product}
					onClick={() => {
						if (
							product.received ||
							product?.lock ||
							busy ||
							productBusy ||
							product?.logistic_product_id === -1 ||
							product?.inventory_input_detail_id
						) {
							return
						}
						deleteProduct()
					}}>
					<i className="fad fa-2x fa-minus-circle" />
				</Col>
			</Row>
			<Tooltip />
		</div>
	)
}

export default Product
