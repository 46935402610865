function safeJsonParse(str: string): [any, string?] {
	try {
		return [null, JSON.parse(str)]
	} catch (err) {
		return [err]
	}
}

class localStorage {
	static get(key: string): any {
		try {
			const data = window.localStorage.getItem(key)
			if (!data) return {}

			const [err, result] = safeJsonParse(data)
			if (err) return data
			return result ? result : {}
		} catch (error) {
			console.error("localStorage.get(", key, ") ", error)
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static set(key: string, value: any): void {
		try {
			if (typeof value === "object") value = JSON.stringify(value)
			window.localStorage.setItem(key, value)
		} catch (error) {
			console.error("localStorage.set(", key, ", ", value, ") ", error)
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static update(key: string, value: any): void | null {
		const oldData = localStorage.get(key)
		if (typeof oldData !== "object") return null
		if (!oldData) {
			localStorage.set(key, value)
			return null
		}
		const updated = Object.assign(oldData, value)
		localStorage.set(key, updated)
	}

	static del(key: string): void {
		window.localStorage.removeItem(key)
	}

	static clear(): void {
		window.localStorage.clear()
	}

	static getWithExpiry(key: string): any {
		const item = localStorage.get(key)

		// if the item doesn't exist, return null
		if (Object.keys(item).length === 0) {
			return null
		}

		const now = new Date()

		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.del(key)
			return null
		}
		return item.value
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static setWithExpiry(key: string, value: any, ttl: number): void {
		const now = new Date()

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		}
		localStorage.set(key, JSON.stringify(item))
	}
}

export default localStorage
