import { Prescriber } from "@audiowizard/common"

const LastPrescriberCol = (queryfilters: any, prescribers: Prescriber[]): any => {
	const lastPrescriberColRender = (prescriber: Prescriber): JSX.Element => (
		<strong className="mx-2">
			{prescriber && prescriber.firstName && prescriber.lastName
				? `${prescriber.firstName} ${prescriber.lastName}`
				: ""}
		</strong>
	)

	return {
		title: null,
		key: "lastPrescriber",
		dataIndex: "patient.lastPrescriber",
		render: lastPrescriberColRender,
		filters: prescribers?.map((prescriber) => ({
			text: `${prescriber.firstName} ${prescriber.lastName}`,
			value: prescriber.id,
		})),
		filteredValue: queryfilters?.lastPrescriber || null,
		filterSearch: true,
		validate: (cond: any) =>
			!cond["prescriber.firstName"] || cond["prescriber.firstName"] === ""
				? "Impossible de mettre à jour le tableau, vous devez choisir un PRESCRIPTEUR"
				: "",
	}
}

export default LastPrescriberCol
