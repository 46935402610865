import { HiboutikSupplier } from "@audiowizard/common"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import useHasRole from "components/Hooks/useHasRole"
import { useState } from "react"
import { useQueryClient } from "react-query"
import { Col, Row } from "reactstrap"
import SuppliersModal from "../Suppliers.modal"
import { SuppliersTable } from "./CatalogueTables"

const Suppliers = (): JSX.Element => {
	const queryClient = useQueryClient()
	const isManager = useHasRole("ROLE_MANAGER")

	const [supplier, setSupplier] = useState<HiboutikSupplier | null>(null)
	const [supplierSearch, setSupplierSearch] = useState("")
	const [isOpen, setIsOpen] = useState(false)

	const handleSupplierRowClick = (supplier: HiboutikSupplier): void => {
		setSupplier(supplier)
		setIsOpen(true)
	}

	return (
		<Col>
			<SectionHeader title="Liste des fournisseurs">
				{isManager && (
					<ButtonRounded
						color="primary-outlined"
						icon="fa-plus"
						onClick={() => {
							setSupplier(null)
							setIsOpen(true)
						}}>
						Ajouter un fournisseur
					</ButtonRounded>
				)}
			</SectionHeader>
			<Row>
				<Col md={8}>
					<input
						type="search"
						name="search"
						placeholder="Rechercher un fournisseur"
						className="form-control"
						value={supplierSearch}
						onChange={(e) => setSupplierSearch(e.target.value)}
					/>
				</Col>
			</Row>
			<Row className="my-4">
				<Col md={12}>
					<div className="table-responsive">
						<SuppliersTable nameSearchQuery={supplierSearch} onRowClick={handleSupplierRowClick} />
					</div>
				</Col>
			</Row>
			<SuppliersModal
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				supplier={supplier}
				setSupplier={setSupplier}
				refreshSetter={() => queryClient.invalidateQueries("API_Hiboutik.getSuppliers")}
			/>
		</Col>
	)
}

export default Suppliers
