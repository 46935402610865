import { useEffect, useRef, useState } from "react"

/**
 *
 * @param query Media Query css
 * @returns Si la media query match ou non
 *
 * @example const landscape = useMediaQuery("(orientation: landscape)")
 * @example const isMobile = useMediaQuery("(max-width: 575.98px)")
 */
export default function useMediaQuery(query: string): boolean {
	const mediaQueryRef = useRef<MediaQueryList>()
	const [matches, setMatches] = useState<boolean>()

	useEffect(() => {
		mediaQueryRef.current = window.matchMedia(query)
		setMatches(mediaQueryRef.current.matches)

		const listener = (ev: MediaQueryListEvent): void => setMatches(ev.matches)
		mediaQueryRef.current.addEventListener("change", listener)

		return () => {
			mediaQueryRef.current!.removeEventListener("change", listener)
		}
	}, [query])

	return matches!
}
