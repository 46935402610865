import { Col, Row, Space } from "antd"
import AuthContext from "contexts/AuthContext"
import { useContext, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import API from "services/API"
import "./CashOutflowReasonSetting.scss"

import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { toast } from "react-toastify"
import CashOutflowReasonComponent, { CashOutflowReason } from "./CashOutflowReasonComponent"

const CashOutflowReasonSetting: React.FC = () => {
	const queryClient = useQueryClient()
	const { user } = useContext(AuthContext)
	const [newLabel, setNewLabel] = useState<string>("")
	const { data: cashOutflowReasons } = useQuery<CashOutflowReason[]>(
		["CASH_OUTFLOW_REASON_API"],
		async () => {
			try {
				return await API.findAll("CASH_OUTFLOW_REASON_API")
			} catch (err) {
				console.error("Impossible de récupérer les motifs")
				toast.error("Problème lors de la récupération des motifs")
				return []
			}
		},
		{
			staleTime: Infinity,
		}
	)

	const { mutate: createOutflowReason } = useMutation(
		(newOutflowReason: { label: string; company: string }) =>
			API.create("CASH_OUTFLOW_REASON_API", newOutflowReason),
		{
			onSuccess: () => queryClient.invalidateQueries(["CASH_OUTFLOW_REASON_API"]),
			onError: () => {
				toast.error("Erreur lors de la création du motif de sortie")
			},
		}
	)

	return (
		<Col>
			<SectionHeader title="Configurer les motifs de sortie de caisse" />
			<Space direction="vertical" className="w-100 ml-3 outflow-reason">
				<Row className="b-4">
					<Col span={5}>
						<input
							type="text"
							className="form-control"
							placeholder="Nom du motif de sortie"
							name="label"
							onChange={(e) => setNewLabel(e.target.value)}
							value={newLabel}
						/>
					</Col>
					<Col span={4} className="ml-3">
						<ButtonRounded
							icon="fa-plus"
							color="primary-outlined"
							type="button"
							onClick={() => {
								if (!newLabel || newLabel === "" || !user.company?.["@id"]) return
								createOutflowReason({ label: newLabel, company: user.company["@id"] })

								setNewLabel("")
							}}>
							Ajouter
						</ButtonRounded>
					</Col>
				</Row>
				<Row className="outflow-reason-card-group">
					{cashOutflowReasons?.map((cashOutflowReason) => (
						<CashOutflowReasonComponent key={cashOutflowReason["@id"]} item={cashOutflowReason} />
					))}
				</Row>
			</Space>
		</Col>
	)
}

export default CashOutflowReasonSetting
