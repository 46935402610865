import { Laboratory, Transfer, TransferRetrocessionInvoice } from "@audiowizard/common"
import LoadingModal from "components/effects/ModalLoading"
import { DateInputOnly } from "components/forms/DateInput"
import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import { Dayjs } from "dayjs"
//@ts-ignore
import { saveAs } from "file-saver"
import JSZip from "jszip"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import API from "services/API"
import { GlobalClient } from "services/clients/axios/globalClient"
import { formatDatetimeForDB, getIdFromIri } from "services/functions"

type Filters = {
	senderLaboratory: null | Laboratory
	recipientLaboratory: null | Laboratory
	startDate: null | Dayjs
	endDate: null | Dayjs
}

type FileRef = {
	name: string
	buffer: any
}

const ModalExtractRetrocessionInvoices = ({
	isOpen,
	setOpen,
}: {
	isOpen: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}): JSX.Element => {
	const zipFilesRef = useRef<FileRef[]>([])
	const [loadingProgress, setLoadingProgress] = useState({ total: 0, current: 0 })

	const [filters, setFilters] = useState<Filters>({
		senderLaboratory: null,
		recipientLaboratory: null,
		startDate: null,
		endDate: null,
	})

	useEffect(() => {
		if (!isOpen) return

		setFilters({ senderLaboratory: null, recipientLaboratory: null, startDate: null, endDate: null })
	}, [isOpen])

	const getRetrocessionInvoice = async (transfer: Transfer): Promise<void> => {
		try {
			const invoices = await API.findAll<TransferRetrocessionInvoice[]>(
				"TRANSFER_RETROCESSION_INVOICES_API",
				`?transfer=${getIdFromIri(transfer["@id"])}`
			)

			if (!invoices.length || !invoices?.[0]?.["@id"]) {
				throw new Error("No invoice found for this transfer.")
			}

			const invoice = await API.get<TransferRetrocessionInvoice>(invoices[0]["@id"] as unknown as string)

			if (!invoice.signature) {
				throw new Error("No invoice or no signature")
			}
			const { data } = await GlobalClient.get(invoice?.signature, {
				responseType: "arraybuffer",
			})

			zipFilesRef.current.push({
				name: `Transfert du ${transfer.validatedAt}`,
				buffer: data,
			})

			setLoadingProgress((old) => ({ ...old, current: old.current + 1 }))
		} catch (error) {
			console.error(error)
		}
	}

	const getAllRetrocessionInvoices = async (): Promise<void> => {
		try {
			const apiFilters = []

			if (filters.senderLaboratory) apiFilters.push(`senderLaboratory=${filters.senderLaboratory?.id}`)
			if (filters.recipientLaboratory) apiFilters.push(`recipientLaboratory=${filters.recipientLaboratory?.id}`)
			if (filters.startDate) {
				const date = filters.startDate.startOf("day").toDate()
				apiFilters.push(`validatedAt[after]=${formatDatetimeForDB(date)}`)
			}
			if (filters.endDate) {
				const date = filters.endDate.endOf("day").toDate()
				apiFilters.push(`validatedAt[before]=${formatDatetimeForDB(date)}`)
			}

			const transfers = await API.findAll<Transfer[]>(
				"TRANSFER_API",
				`?generateInvoice=1&pagination=false${apiFilters.join("&")}`
			)

			if (!transfers.length) {
				toast.error("Aucune facture de rétrocession disponible avec ces filtres.")
				return
			}

			setLoadingProgress({ total: transfers.length, current: 0 })

			for (const transfer of transfers) {
				await getRetrocessionInvoice(transfer)
			}

			if (zipFilesRef.current.length === 0) {
				console.error("Zip is empty")
				toast.error("Problème lors de la récupération des factures")
				return
			}

			const zip = new JSZip()
			const folder = zip.folder("Export facture de rétrocession des transferts")

			for (const file of zipFilesRef.current) {
				folder!.file(`${file.name}.pdf`, file.buffer)
			}

			zip.generateAsync({ type: "blob" }).then((blob) => {
				saveAs(blob, `${Date.now()}-FACTURES`)
			})

			setOpen(false)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} centered size="lg" className="modal-loading">
				<ModalHeader>Extraction des factures de rétrocessions</ModalHeader>
				<ModalBody>
					<div className="cardtabs-subtitle ">Date de début (obligatoire)</div>
					<DateInputOnly
						hasError={!filters.startDate}
						allowClear={false}
						onSelect={(value) => {
							setFilters((old) => ({ ...old, startDate: value }))
						}}
					/>

					<div className="cardtabs-subtitle">Date de fin (obligatoire)</div>
					<DateInputOnly
						hasError={!filters.endDate}
						allowClear={false}
						onSelect={(value) => {
							setFilters((old) => ({ ...old, endDate: value }))
						}}
					/>

					<div className="cardtabs-subtitle mt-4">Laboratoire émetteur</div>
					<AsyncLaboratorySelect
						size="large"
						className="w-100"
						onChange={(_, laboratory) => {
							setFilters((old) => ({ ...old, senderLaboratory: laboratory }))
						}}
					/>

					<div className="cardtabs-subtitle">Laboratoire destinataire</div>
					<AsyncLaboratorySelect
						size="large"
						className="w-100"
						onChange={(_, laboratory) => {
							setFilters((old) => ({ ...old, recipientLaboratory: laboratory }))
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<button type="button" className="btn btn-outline-danger" onClick={() => setOpen(false)}>
						Annuler
					</button>
					<button
						type="button"
						className="btn btn-primary"
						style={{ minWidth: "256px" }}
						onClick={() => {
							getAllRetrocessionInvoices()
						}}
						disabled={
							!filters.endDate ||
							!filters.startDate ||
							(loadingProgress.total !== 0 && loadingProgress.current !== loadingProgress.total)
						}>
						Extraire les factures
					</button>
				</ModalFooter>
			</Modal>
			<LoadingModal
				isOpen={loadingProgress.total !== 0 && loadingProgress.current !== loadingProgress.total}
				message={`${Math.floor((loadingProgress.current / loadingProgress.total) * 100)} %`}
			/>
		</>
	)
}

export default ModalExtractRetrocessionInvoices
