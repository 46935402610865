/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Modal as ModalStrap, ModalFooter, ModalBody, ModalHeader } from "reactstrap"
import { Checkbox } from "antd"
import FieldWithError from "../../components/forms/FieldWithError"
import PhoneInput from "../../components/forms/PhoneInput"
import Select from "../../components/forms/Select"
import { HandleChange } from "../../services/functions"
import ButtonRounded from "components/Buttons/ButtonRounded"

const PrescribersModal = ({
	prescriber,
	setPrescriber,
	handleSubmitPrescriber,
	id,
	title,
	errors,
	setErrors,
	chooseCategory,
	open,
	setOpen,
}) => {
	const [tmpPrescriber, setTmpPrescriber] = useState()
	const [noRpps, setNoRpps] = useState(false)

	const close = () => {
		typeof setErrors === "function" && setErrors({})
		setTmpPrescriber()
		setOpen(false)
	}

	const replaceOrNot = (value) => {
		if (!tmpPrescriber?.[value]) return prescriber?.[value]
		if (!prescriber?.[value]) return tmpPrescriber?.[value]
		else if (prescriber?.[value] && tmpPrescriber?.[value]) return tmpPrescriber?.[value]
	}

	const handleRppsAndFiness = (e) => {
		const { name, value } = e.target
		if (name === "rpps" && (value.length === 0 || value.length < 13)) HandleChange(e, prescriber, setPrescriber)
		else if (name === "finess" && (value.length === 0 || value.length < 10))
			HandleChange(e, prescriber, setPrescriber)
	}

	const validatePrescriberRppsAndFiness = () => {
		let err = {}
		const rppsLength = prescriber?.rpps?.length
		if (rppsLength > 0 && rppsLength < 11) {
			err.rpps = "Le rpps doit comporter 11 ou 12 caractères"
		}
		const finessLength = prescriber?.finess?.length
		if (finessLength > 0 && finessLength !== 9) {
			err.finess = "Le finess / AM doit comporter 9 caractères"
		}
		return err
	}

	const confirm = () => {
		try {
			const validationRppsAndFiness = validatePrescriberRppsAndFiness()

			const hasError = Object.keys(validationRppsAndFiness).length
			if (!hasError) {
				handleSubmitPrescriber()
				close()
			} else {
				setErrors({ ...validationRppsAndFiness })
			}
		} catch (error) {
			console.error(error)
		}
	}

	// Fix gender not having a default value
	useEffect(() => {
		if (prescriber?.gender == null) {
			setPrescriber({ ...prescriber, gender: "HOMME" })
		}
		if (prescriber?.cpo === "99") setNoRpps(true)
	}, [prescriber])

	return (
		<ModalStrap isOpen={open ? true : false} centered size="xl">
			<ModalHeader>
				<div className="modal-custom-header">
					<div className="modal-custom-header">{title}</div>
					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={close}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				<div>
					<div className="row col-sm-12">
						{chooseCategory && (
							<div className="col-sm-4">
								<Select
									id={"category-" + id}
									name="category"
									label="Type de prescripteur"
									onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
									value={prescriber && prescriber.category}
									error={errors.category || ""}>
									<option value="ORL" defaultValue>
										Orl
									</option>
									<option value="DOCTOR">Médecin</option>
								</Select>
							</div>
						)}
					</div>
					<div className="row col-sm-12">
						<div className="col-sm-4">
							<FieldWithError
								id={"firstName-" + id}
								name="firstName"
								label="Prénom"
								value={replaceOrNot("firstName")}
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								error={errors.firstName || ""}
								invalidMessage="Le prénom est obligatoire"
								required={true}
								validation={!prescriber?.firstName ? tmpPrescriber?.firstName : undefined}
							/>
						</div>
						<div className="col-sm-4">
							<FieldWithError
								id={"lastName-" + id}
								name="lastName"
								label="Nom"
								value={replaceOrNot("lastName")}
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								error={errors.lastName || ""}
								invalidMessage="Le nom est obligatoire"
								required={true}
								validation={!prescriber?.lastName ? tmpPrescriber?.lastName : undefined}
							/>
						</div>
						<div className="col-sm-4">
							<Select
								id={"gender-" + id}
								name="gender"
								label="Genre"
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								value={prescriber && prescriber.gender}
								error={errors.gender || ""}>
								<option value="HOMME" defaultValue>
									Homme
								</option>
								<option value="FEMME">Femme</option>
							</Select>
						</div>
					</div>
					<div className="row col-sm-12">
						<div className="col-sm-6">
							<FieldWithError
								id={"email-" + id}
								name="email"
								label="Email"
								type="email"
								value={prescriber?.email}
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								error={errors.email || ""}
								invalidMessage="Merci d'entrer une adresse email valide"
								validation={undefined}
							/>
						</div>
						<div className="col-sm-6">
							<PhoneInput
								className="w-100"
								id={"phone-" + id}
								name="phone"
								label="Téléphone"
								value={prescriber?.phone}
								onChange={(phone) => setPrescriber({ ...prescriber, phone })}
								error={errors.phone || ""}
							/>
						</div>
					</div>
					<div className="col-sm-6 m-2">
						<Checkbox
							onChange={(e) => {
								const val = e?.target?.checked
								setNoRpps(val)
								if (val) setPrescriber((old) => ({ ...old, cpo: "99" }))
							}}
							checked={noRpps}>
							Prescripteur exerçant à l'étranger
						</Checkbox>
					</div>
					<div className="row col-sm-12">
						<div className="col-sm-4">
							<FieldWithError
								id={"adress-" + id}
								name="adress"
								label="Adresse"
								value={replaceOrNot("adress")}
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								error={errors.adress}
								validation={!prescriber?.adress ? tmpPrescriber?.adress : undefined}
							/>
						</div>
						<div className="col-sm-4">
							<FieldWithError
								id={"cpo-" + id}
								name="cpo"
								label="Code Postal"
								type="number"
								value={replaceOrNot("cpo")}
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								error={errors.cpo}
								invalidMessage="Merci d'entrer un code postal valide"
								validation={!prescriber?.cpo ? tmpPrescriber?.cpo : undefined}
								isDisabled={noRpps}
							/>
						</div>

						<div className="col-sm-4">
							<FieldWithError
								id={"city-" + id}
								name="city"
								label="Ville"
								value={replaceOrNot("city")}
								onChange={(event) => HandleChange(event, prescriber, setPrescriber)}
								error={errors.city}
								minLength="3"
								maxLength="100"
								validation={!prescriber?.city ? tmpPrescriber?.city : undefined}
							/>
						</div>
					</div>
					<div className="row col-sm-12">
						<div className="col-sm-4">
							<FieldWithError
								id={"adeli-" + id}
								name="adeli"
								label="Adeli"
								type="number"
								value={replaceOrNot("adeli")}
								onChange={(event) => {
									if (event.target.value.length > 9) return
									HandleChange(event, prescriber, setPrescriber)
								}}
								validation={!prescriber?.adeli ? tmpPrescriber?.adeli : undefined}
							/>
						</div>
						<div className="col-sm-4">
							<FieldWithError
								id={"rpps-" + id}
								name="rpps"
								label="RPPS"
								type={noRpps ? "text" : "number"}
								value={noRpps ? "non-applicable" : replaceOrNot("rpps")}
								onChange={(event) => handleRppsAndFiness(event)}
								validation={errors.rpps}
								error={errors.rpps}
								isDisabled={noRpps}
							/>
						</div>
						<div className="col-sm-4">
							<FieldWithError
								id={"finess-" + id}
								name="finess"
								label="Finess / AM"
								type="number"
								value={replaceOrNot("finess")}
								onChange={(event) => handleRppsAndFiness(event)}
								validation={errors.finess}
								error={errors.finess}
							/>
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<ButtonRounded type="button" color="primary-outlined" onClick={confirm}>
					{id === "modalAddPrescriber" ? "Ajouter" : "Modifier"}
				</ButtonRounded>
			</ModalFooter>
		</ModalStrap>
	)
}

PrescribersModal.propTypes = {
	handleSubmitPrescriber: PropTypes.func,
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default PrescribersModal
