import React, { Dispatch, SetStateAction } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

interface ConfirmModal {
	show: boolean
	title: string
	content: JSX.Element
	fctCancel: () => void
	btnCancel: string
	fctConfirm: () => void
	btnConfirm: string
	result: boolean
}

interface ScheduleConfirmModalProps {
	confirmModal: ConfirmModal
	setConfirmModal: Dispatch<SetStateAction<ConfirmModal>>
}

const ScheduleConfirmModal: React.FC<ScheduleConfirmModalProps> = ({ confirmModal, setConfirmModal }) => {
	return (
		<Modal isOpen={confirmModal?.show} backdrop="static">
			<ModalHeader>{confirmModal?.title}</ModalHeader>
			<ModalBody>{confirmModal?.content}</ModalBody>
			<ModalFooter>
				<Button
					color="secondary"
					onClick={() => {
						setConfirmModal((o) => ({ ...o, result: false, show: false }))
						if (typeof confirmModal?.fctCancel === "function") confirmModal.fctCancel()
					}}>
					{confirmModal?.btnCancel}
				</Button>
				<Button
					color="primary"
					onClick={() => {
						setConfirmModal((o) => ({ ...o, result: true, show: false }))
						if (typeof confirmModal?.fctConfirm === "function") confirmModal.fctConfirm()
					}}>
					{confirmModal?.btnConfirm}
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default ScheduleConfirmModal
