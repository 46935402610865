import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import SecondaryStatusSettings from "pages/Settings/SecondaryStatus/SecondaryStatus"
import { ParametreRoutePrefix } from "router/Personnalisation/routes"
import { StatutsRoutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

function StatutsRouter(): JSX.Element {
	useCustomTitle("Paramètres | Statuts et filtres")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem
				name="Personnalisation de l'agenda"
				component={SecondaryStatusSettings}
				url={noPrefix(StatutsRoutes.StatutsEtFiltres)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default StatutsRouter
