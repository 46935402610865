import { useState } from "react"
import { Input, Divider } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { formatDatetimeForDB, getScheduleLabel } from "services/functions"
import dayjs from "dayjs"

export const IsRelanceRDV = (name, list) => (list || []).includes(name)

export const findClosestNonRelanceSched = (dates, callback, queryfilters, scheduleTypesList) => {
	const now = new Date()
	let closest = Infinity

	dates.forEach(function (d) {
		const date = new Date(d.dateOf)
		if (
			!IsRelanceRDV(queryfilters[d.status]?.customLabel || queryfilters[d.status]?.label, scheduleTypesList) &&
			date >= now &&
			(date < new Date(closest.dateOf) || date < (closest !== Infinity ? new Date(closest.dateOf) : closest))
		) {
			closest = d
		}
	})
	return closest !== Infinity ? closest : callback
}

/* 
https://docs.google.com/spreadsheets/d/19D_TkcN5Ka2t3hxwZaCG8m7f4pGsfK_wzwve8e1vlCA/edit#gid=0

Attention les rendez-vous de relance ont pour principes de s'afficher ou non même si leurs dates sont dépassées ou pas, utilisant la date et heure actuelle, dans les cas suivants : 
- lorsque aucun RDV n'a été programmé mais que le dernier RDV en date est une RELANCE, afficher le rendez-vous de RELANCE, me si la date est dépassée
- Si le prochain RDV est un rendez-vous de RELANCE mais qu'il existe un autre RDV après celui-ci l'afficher prochain RDV qui n'est pas une RELANCE
- si le dossier du patient n'est pas complet et qu'il éxiste un RDV / RELANCE de type facture, afficher celui-ci
*/
export const SpecialSPFilter = (
	nextSchedule,
	schedules,
	missingsInformationsOnPatientFolder,
	scheduleTypes,
	scheduleTypesList
) => {
	let newNextSchedule = nextSchedule
	if (
		!nextSchedule &&
		schedules &&
		schedules[0] &&
		scheduleTypes &&
		scheduleTypes[schedules[0].status] &&
		IsRelanceRDV(
			scheduleTypes[schedules[0].status]?.customLabel || scheduleTypes[schedules[0].status]?.label,
			scheduleTypesList
		)
	) {
		newNextSchedule = schedules[0]
		newNextSchedule.dateOf = dayjs(new Date()).format()
	}
	if (
		nextSchedule &&
		schedules &&
		scheduleTypes &&
		IsRelanceRDV(
			scheduleTypes[nextSchedule.status]?.customLabel || scheduleTypes[nextSchedule.status]?.label,
			scheduleTypesList
		)
	)
		newNextSchedule = findClosestNonRelanceSched(schedules, nextSchedule, scheduleTypes, scheduleTypesList)
	if (missingsInformationsOnPatientFolder !== 0 && schedules && scheduleTypes) {
		for (const schedule of schedules) {
			if (getScheduleLabel(scheduleTypes, schedule.status) === "Facturé") {
				newNextSchedule = schedule
				newNextSchedule.dateOf = dayjs(new Date()).format()
			}
		}
	}

	return newNextSchedule
}

export const ExportSpecialSPFilter = (nextSchedule, schedules, missingsInformationsOnPatientFolder, scheduleTypes) => {
	let newNextSchedule = { status: nextSchedule, dateOf: null }
	if (
		!nextSchedule &&
		schedules &&
		schedules[0] &&
		scheduleTypes &&
		scheduleTypes[schedules[0].status] &&
		IsRelanceRDV(
			scheduleTypes[schedules[0].status]?.customLabel || scheduleTypes[schedules[0].status]?.label,
			Object.entries(scheduleTypes).map((v) => v[1].label)
		)
	) {
		newNextSchedule.status = schedules[0].status
		newNextSchedule.dateOf = dayjs().format("YYYY-MM-DD hh:mm")
	}
	if (
		nextSchedule &&
		schedules &&
		scheduleTypes &&
		IsRelanceRDV(
			scheduleTypes[nextSchedule]?.customLabel || scheduleTypes[nextSchedule]?.label,
			Object.entries(scheduleTypes).map((v) => v[1].label)
		)
	)
		newNextSchedule = findClosestNonRelanceSched(schedules, { status: nextSchedule, dateOf: null }, scheduleTypes)
	if (missingsInformationsOnPatientFolder !== 0 && schedules && scheduleTypes)
		schedules.forEach((item) => {
			if (getScheduleLabel(scheduleTypes, item.status) === "Facturé") {
				newNextSchedule.status = item.status
				newNextSchedule.dateOf = dayjs().format("YYYY-MM-DD hh:mm")
			}
		})
	return newNextSchedule
}

export const CustomSelectDropDown = ({ isManager, isAffiliate, isAffiliateManager, handleDropDownClick, children }) => {
	const [name, setName] = useState("")
	if (!isManager && !isAffiliate && !isAffiliateManager) return <div>{children}</div>

	return (
		<div>
			{children}
			<Divider style={{ margin: "4px 0" }} />
			<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
				<Input style={{ flex: "auto" }} value={name} onChange={(e) => setName(e.target.value)} />
				<div
					style={{
						flex: "none",
						padding: "8px",
						display: "block",
						cursor: "pointer",
					}}
					onClick={() => {
						handleDropDownClick(name)
						setName("")
					}}>
					<PlusOutlined />
				</div>
			</div>
		</div>
	)
}

export const setDates = (data, setEvtData) => {
	if (data?.length === 1) {
		setEvtData((old) => ({ ...old, start: formatDatetimeForDB(data[0]) }))
		setEvtData((old) => ({ ...old, end: formatDatetimeForDB(data[0]).add(15, "minute") }))
	}
	if (data?.length === 2) {
		setEvtData((old) => ({ ...old, start: formatDatetimeForDB(data[0]) }))
		setEvtData((old) => ({ ...old, end: formatDatetimeForDB(data[1]) }))
	}
}
