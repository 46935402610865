import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const InvitationsRoutePrefix = GestionCompteRoutePrefix + "/invitations"

export const InvitationsRoutes = {
	MesInvitations: `${InvitationsRoutePrefix}/mes-invitations`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(InvitationsRoutePrefix, "")
}
