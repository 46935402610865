/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"
import Chart from "react-apexcharts"

import { API_URL } from "../../config"

const Attentes = () => {
	const [loading, setLoading] = useState(true)
	const [options, setOptions] = useState({
		series: [
			{
				data: [],
			},
			{
				data: [],
			},
		],
		chart: {
			type: "bar",
			height: 430,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				dataLabels: {
					position: "top",
				},
			},
		},
		dataLabels: {
			enabled: true,
			style: {
				fontSize: "12px",
				colors: ["#fff"],
			},
		},
		stroke: {
			show: true,
			width: 1,
			colors: ["#fff"],
		},
		xaxis: {
			categories: [],
		},
		noData: {
			text: "pas assez d'informations pour produire le graphique",
		},
	})

	const getRendezVous = async () => {
		const labels = []
		try {
			const result = await axios.get(API_URL + "/stats/patient_gene")
			if (!result?.data?.message[0]?.length || !result?.data?.message[1]?.length) {
				return setLoading(false)
			}
			result?.data?.message[0].forEach((e) => {
				labels.push(e.label.toUpperCase())
			})
			result?.data?.message[1].forEach((e) => {
				if (!labels.includes(e.label.toUpperCase())) labels.push(e.label.toUpperCase())
			})
			const under = new Array(labels?.length).fill(0)
			const over = new Array(labels?.length).fill(0)
			const sum = new Array(labels?.length).fill(0)

			result?.data?.message[0].forEach((e) => {
				under[labels.indexOf(e.label.toUpperCase())] = parseInt(e.total)
				sum[labels.indexOf(e.label.toUpperCase())] = parseInt(e.total)
			})
			result?.data?.message[1].forEach((e) => {
				over[labels.indexOf(e.label.toUpperCase())] = parseInt(e.total)
				sum[labels.indexOf(e.label.toUpperCase())] += parseInt(e.total)
			})

			setOptions({
				series: [
					{ name: "Niveau de gêne <= 5", data: under },
					{ name: "Niveau de gêne > 5", data: over },
					{ data: sum, name: "Total" },
				],
				chart: {
					type: "bar",
				},
				plotOptions: {
					bar: {
						horizontal: true,
						dataLabels: {
							position: "top",
						},
					},
				},
				dataLabels: {
					enabled: false,
					style: {
						fontSize: "12px",
						colors: ["#fff"],
					},
				},
				stroke: {
					show: false,
					width: 1,
					colors: ["#fff"],
				},
				xaxis: {
					categories: labels,
				},
				noData: {
					text: "pas assez d'informations pour produire le graphique",
				},
			})

			setLoading(false)
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getRendezVous()
	}, [])

	return (
		<>
			<h5 className="mb-4">Classement des gênes</h5>
			<Chart options={options} series={options.series} type="bar" width="100%" />

			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
