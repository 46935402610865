import { HiboutikBrand, HiboutikCategory, HiboutikProductEntity, HiboutikSupplier } from "@audiowizard/common"
import { Table } from "antd"
import { ColumnType } from "antd/lib/table"
import { useBrandsQuery, useCategoriesQuery, useSuppliersQuery } from "components/Hooks/commonQueries"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { Badge } from "reactstrap"
import { CatalogueRoutePrefix } from "router/Catalogue/routes"
import API from "services/API"
import { formatCurrency, partialSearch } from "services/functions"

function useProductsQueries(): {
	allProducts: HiboutikProductEntity[]
	suppliers: HiboutikSupplier[]
	brands: HiboutikBrand[]
	categories: HiboutikCategory[]
	isLoading: boolean
} {
	const { data: allProducts, isLoading: isLoadingProducts } = useQuery<HiboutikProductEntity[]>(
		["PRODUCTS_API", { pagination: false }],
		async () => await API.findAll("PRODUCTS_API", "?pagination=false"),
		{ staleTime: Infinity }
	)

	const { data: suppliers, isLoading: isLoadingSuppliers } = useSuppliersQuery()
	const { data: brands, isLoading: isLoadingBrands } = useBrandsQuery()
	const { data: categories, isLoading: isLoadingCategories } = useCategoriesQuery()

	const isLoading = isLoadingProducts || isLoadingSuppliers || isLoadingBrands || isLoadingCategories
	return {
		allProducts: allProducts ?? [],
		suppliers: suppliers ?? [],
		brands: brands ?? [],
		categories: categories ?? [],
		isLoading,
	}
}

type ProductsTableProps = {
	modelSearchQuery: string
}
export function ProductsTable({ modelSearchQuery }: ProductsTableProps): JSX.Element {
	const history = useHistory()
	const { allProducts, suppliers, brands, categories, isLoading } = useProductsQueries()

	const columns: ColumnType<HiboutikProductEntity>[] = [
		{
			title: "Label",
			dataIndex: "model",
			sorter: (a, b) => {
				const aM = a.model || ""
				const bM = b.model || ""
				return aM.localeCompare(bM)
			},
			defaultSortOrder: "ascend",
			width: "20%",
		},
		{
			title: "Marque",
			dataIndex: "brandName",
			sorter: (a, b) => {
				const aB = a.brandName || ""
				const bB = b.brandName || ""
				return aB.localeCompare(bB)
			},
			filters: [...brands.map((b) => ({ text: b.brand_name, value: b.brand_id })), { text: "N/A", value: 0 }],
			filterSearch: true,
			onFilter: (brandId, product) => product.brandId === brandId,
			width: "10%",
			render: (brandName) =>
				brandName != null ? <span>{brandName}</span> : <span className="mr-2 badge badge-info">N/A</span>,
		},
		{
			title: "Prix d'achat HT (€)",
			dataIndex: "supplyPrice",
			sorter: (a, b) => (a.supplyPrice ?? 0) - (b.supplyPrice ?? 0),
			width: "12.5%",
			render: formatCurrency,
		},
		{
			title: "Prix de vente TTC (€)",
			dataIndex: "price",
			sorter: (a, b) => (a.price ?? 0) - (b.price ?? 0),
			width: "12.5%",
			render: formatCurrency,
		},
		{
			title: "Catégorie",
			dataIndex: "categoryId",
			sorter: (a, b) => {
				const aC = a.categoryName || ""
				const bC = b.categoryName || ""
				return aC.localeCompare(bC)
			},
			filters: [
				...categories.map((c) => ({
					text: (
						<span className="badge badge-primary" style={{ backgroundColor: c.category_bck_color }}>
							{c.category_name}
						</span>
					),
					value: c.category_id,
					searchValue: c.category_name,
				})),
				{
					text: "N/A",
					value: 0,
					searchValue: "N/A",
				} as any,
			],
			filterSearch: (search, filter: any) => partialSearch(filter.searchValue, search),
			onFilter: (categoryId, product) => product.categoryId === categoryId,
			render: (categoryId) => {
				if (categoryId === 0) return <span className="mr-2 badge badge-info">N/A</span>

				const cat = categories.find((c) => c.category_id === categoryId)

				if (!cat) return <span className="mr-2 badge badge-info">N/A</span>
				return (
					<span className="mr-2 badge badge-primary" style={{ backgroundColor: cat.category_bck_color }}>
						{cat.category_name}
					</span>
				)
			},
			width: "10%",
		},
		{
			title: "Fournisseur",
			dataIndex: "supplierName",
			sorter: (a, b) => {
				const aS = a.supplierName || ""
				const bS = b.supplierName || ""
				return aS.localeCompare(bS)
			},
			filters: [
				...suppliers.map((s) => ({ text: s.supplier_name, value: s.supplier_id })),
				{ text: "N/A", value: 0 },
			],
			filterSearch: true,
			onFilter: (supplierId, product) => product.supplierId === supplierId,
			render: (supplierName) =>
				supplierName != null ? <span>{supplierName}</span> : <span className="mr-2 badge badge-info">N/A</span>,
			width: "10%",
		},
	]

	const filteredProducts = useMemo(() => {
		if (modelSearchQuery?.length === 0) return allProducts

		return allProducts.filter((p) => partialSearch(p.model, modelSearchQuery))
	}, [allProducts, modelSearchQuery])

	return (
		<Table
			columns={columns}
			dataSource={filteredProducts}
			loading={isLoading}
			pagination={{ position: ["bottomLeft"] }}
			onRow={(product) => ({
				onClick: () => history.push(`${CatalogueRoutePrefix}/produit/${product.id}`),
			})}
		/>
	)
}

type BrandsTableProps = {
	nameSearchQuery: string
	onRowClick: (brand: HiboutikBrand) => void
}
export function BrandsTable({ nameSearchQuery, onRowClick }: BrandsTableProps): JSX.Element {
	const { data: brands, isLoading } = useBrandsQuery()

	const columns: ColumnType<HiboutikBrand>[] = [
		{
			title: "Marques",
			dataIndex: "brand_name",
			sorter: (a, b) => {
				const aName = a.brand_name || ""
				const bName = b.brand_name || ""
				return aName.localeCompare(bName)
			},
		},
	]

	const filteredBrands = useMemo(() => {
		if (brands == null) return []
		if (nameSearchQuery.length === 0) return brands

		return brands.filter((b) => partialSearch(b.brand_name, nameSearchQuery))
	}, [brands, nameSearchQuery])

	return (
		<Table
			columns={columns}
			dataSource={filteredBrands}
			loading={isLoading}
			pagination={{ position: ["bottomLeft"] }}
			onRow={(brand) => ({
				onClick: () => onRowClick(brand),
			})}
		/>
	)
}

type SuppliersTableProps = {
	nameSearchQuery: string
	onRowClick: (brand: HiboutikSupplier) => void
}
export function SuppliersTable({ nameSearchQuery, onRowClick }: SuppliersTableProps): JSX.Element {
	const { data: suppliers, isLoading } = useSuppliersQuery()

	const columns: ColumnType<HiboutikSupplier>[] = [
		{
			title: "Fournisseur",
			dataIndex: "supplier_name",
			sorter: (a, b) => {
				const aName = a.supplier_name || ""
				const bName = b.supplier_name || ""
				return aName.localeCompare(bName)
			},
			defaultSortOrder: "ascend",
			width: "20%",
		},
		{
			title: "Email",
			dataIndex: "supplier_email",
			width: "20%",
		},
		{
			title: "Téléphone",
			dataIndex: "supplier_contact",
			width: "20%",
		},
		{
			title: "Adresse",
			dataIndex: "supplier_address",
			width: "40%",
		},
	]

	const filteredSuppliers = useMemo(() => {
		if (suppliers == null) return []
		if (nameSearchQuery.length === 0) return suppliers

		return suppliers.filter((s) => partialSearch(s.supplier_name, nameSearchQuery))
	}, [suppliers, nameSearchQuery])

	return (
		<Table
			columns={columns}
			dataSource={filteredSuppliers}
			loading={isLoading}
			pagination={{ position: ["bottomLeft"] }}
			onRow={(supplier) => ({
				onClick: () => onRowClick(supplier),
			})}
		/>
	)
}

type CategoriesTableProps = {
	nameSearchQuery: string
}
export function CategoriesTable({ nameSearchQuery }: CategoriesTableProps): JSX.Element {
	const { data: categories, isLoading } = useCategoriesQuery()

	const columns: ColumnType<HiboutikCategory>[] = [
		{
			title: "Catégorie",
			dataIndex: "category_name",
			sorter: (a, b) => {
				const aName = a.category_name || ""
				const bName = b.category_name || ""
				return aName.localeCompare(bName)
			},
			defaultSortOrder: "ascend",
			width: "20%",
		},
		{
			title: "Catégorie active",
			dataIndex: "category_enabled",
			filters: [
				{ text: <Badge color="primary">Oui</Badge>, value: 1 },
				{ text: <Badge color="danger">Non</Badge>, value: 0 },
			],
			onFilter: (value, category) => category.category_enabled === value,
			render: (category_enabled) =>
				category_enabled && category_enabled === 1 ? (
					<Badge color="primary">Oui</Badge>
				) : (
					<Badge color="danger">Non</Badge>
				),
			width: "20%",
		},
		{
			title: "Couleur du tag",
			dataIndex: "category_bck_color",
			render: (color) => (
				<>
					{color && (
						<span className="mr-2 badge badge-primary" style={{ backgroundColor: color }}>
							{" "}
						</span>
					)}
				</>
			),
			width: "20%",
		},
	]

	const filteredCategories = useMemo(() => {
		if (categories == null) return []
		if (nameSearchQuery.length === 0) return categories

		return categories.filter((c) => partialSearch(c.category_name, nameSearchQuery))
	}, [categories, nameSearchQuery])

	return (
		<Table
			columns={columns}
			dataSource={filteredCategories}
			loading={isLoading}
			pagination={{ position: ["bottomLeft"] }}
		/>
	)
}
