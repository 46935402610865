import axios from "axios"

/**
 * Preload a request and return the response data if exists
 * @param url
 * @param unsigned
 * @returns {any|undefined} - some data if exists, else undefined
 */
export async function preload(url: string, unsigned?: boolean): Promise<any | undefined> {
	const connector = axios.create({
		transformRequest: (data, headers) => {
			if (unsigned) delete headers!.Authorization // FIXME: Audiowizard credentials
			return data
		},
	})
	return await connector(url)
		.then((r) => r?.data)
		.catch((e) => undefined)
}
