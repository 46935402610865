import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"

import { CheckOutlined } from "@ant-design/icons"
import { Form, Row, Select } from "antd"
import useCustomTitle from "components/Hooks/useTitle"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import SpinnerAW from "components/utils/SpinnerAW"
import { CashOutflowReason } from "pages/Settings/CashOutflowReason/CashOutflowReasonComponent"
import DocumentUploader from "pages/fiche-patient/Documents/Modal.DocumentUploader"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { formatCurrency } from "services/functions"
import {
	TaxInputValue,
	calcEsp,
	calcHTFromTTC,
	calcTVAFromTTC,
	calcTotalHT,
	calcTotalTTC,
	calcTotalVAT,
	generatePdfSortieDeCaisse,
	useFetchInitialState,
} from "./caisse.helpers"
import TaxInput from "./components/TaxInput"
import { CashOutflowVat, CashRecord, defaultTax, typeAccounting } from "./moneyItems"

const SortieDeCaisseAchat = (): JSX.Element => {
	useCustomTitle("Comptabilité | Sortie de caisse")
	const { laboratory, user } = useContext(AuthContext)
	const [bypassValidation, setBypassValida] = useState(false)
	const [espValues, setEspValues] = useState<CashRecord>()
	const [comment, setComment] = useState("")
	const [modalUpload, setModalUpload] = useState(false)
	const [receipt, setReceipt] = useState<string>()
	const [reason, setReason] = useState<CashOutflowReason>()
	const [tax, setTax] = useState<TaxInputValue[]>(defaultTax)

	const queryClient = useQueryClient()

	const { last, loading: isLoading } = useFetchInitialState(laboratory)

	const { mutate: handleSortie, isLoading: isBusy } = useMutation(
		async (): Promise<void> => {
			if (!espValues) return

			const lastState = last?.state
			const cashOutflowVats = tax
				.filter((t) => t.value !== 0)
				.map(
					(t): CashOutflowVat => ({
						taxRate: t.taxRate,
						allTaxIncludeAmount: t.value,
						taxFreeAmount: Math.round(calcHTFromTTC(t.value, t.taxRate) * 100) / 100,
						vatAmount: Math.round(calcTVAFromTTC(t.value, t.taxRate) * 100) / 100,
					})
				)

			await API.create("ACCOUNTING_API", {
				type: typeAccounting.sortieEspece,
				expectedAmount: 0,
				amount: calcEsp(espValues),
				laboratory: laboratory?.["@id"],
				comment,
				receipt: receipt,
				cashOutflowReason: reason?.["@id"],
				cashOutflowVats,
			})

			if (!lastState) return
			await generatePdfSortieDeCaisse({
				espValues,
				user,
				laboratory,
				comment,
				reason: reason?.label,
				cashOutflowVats,
				tax: tax,
			})

			setComment("")
			setEspValues(undefined)
			setReceipt(undefined)
			setReason(undefined)
			setTax(defaultTax)
			queryClient.invalidateQueries(["ACCOUNTING_API", "?order[createdAt]=desc&laboratory=" + laboratory.id])
		},
		{
			onError: (error) => {
				console.log(error)
			},
		}
	)

	const { data: reasons, isLoading: isLoadingReasons } = useQuery<CashOutflowReason[]>(
		["CASH_OUTFLOW_REASON_API"],
		async () => {
			if (laboratory) {
				try {
					return await API.findAll("CASH_OUTFLOW_REASON_API")
				} catch (err) {
					console.error("Impossible de récupérer les motifs")
					toast.error("Problème lors de la récupération des motifs")
					return []
				}
			} else {
				return []
			}
		},
		{
			staleTime: Infinity,
		}
	)

	useEffect(() => {
		setEspValues({ total: tax.reduce((total, tax) => tax.value + total, 0) })
	}, [tax])

	return (
		<>
			{(isLoading || isLoadingReasons) && <SpinnerAW />}

			<>
				<SectionHeader title="Configurer une sortie de caisse pour achat" />
				<div className="d-flex flex-column">
					<label>Motif de sortie</label>
					<Select
						value={reason?.["@id"] || ""}
						className="select-sub"
						onChange={(value) => {
							const selectedLaboratory = reasons?.find((l) => l["@id"] === value)
							setReason(selectedLaboratory)
						}}>
						{reasons?.map((c) => (
							<Select.Option key={c["@id"]} value={c["@id"]}>
								{c.label}
							</Select.Option>
						))}
					</Select>
				</div>
				<div
					style={{
						display: last?.validation?.createdAt || bypassValidation ? "none" : "flex",
						position: "absolute",
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(255, 255, 255, .8)",
						zIndex: "5",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{!isLoading && (
						<button
							type="button"
							className="btn btn-block btn-primary"
							style={{ maxWidth: "80%" }}
							onClick={() => setBypassValida(true)}>
							Vous n'avez fait aucune validation de caisse, cliquez ici pour effectuer une sortie de
							caisse
						</button>
					)}
				</div>
				<div className="d-flex flex-column mb-2">
					<h6>
						Montant de la sortie :{" "}
						<strong style={{ fontSize: "18px" }}>{formatCurrency(calcEsp(espValues))}</strong>
					</h6>
					{last?.state?.createdAt &&
						(last.state.type === typeAccounting.validationEspece ||
							last.state.type === typeAccounting.reassortEspece) && (
							<>
								<h6>
									Fond de caisse actuel :{" "}
									<strong style={{ fontSize: "18px" }}>
										{formatCurrency(calcEsp(last.state?.change))}
									</strong>
								</h6>
								<h6>
									Nouveau fond de caisse après sortie :{" "}
									<strong style={{ fontSize: "18px" }}>
										{formatCurrency(calcEsp(last.state?.change) - calcEsp(espValues))}
									</strong>
								</h6>
							</>
						)}
					<h6>
						<strong style={{ fontSize: "18px" }}>
							TOTAL HT: {calcTotalHT(tax)} - TOTAL TVA: {calcTotalVAT(tax)} - TOTAL TTC:{" "}
							{calcTotalTTC(tax)}
						</strong>
					</h6>
				</div>
				<Form labelCol={{ span: 6 }}>
					<Row style={{ width: "100%" }} className="mb-2">
						{tax.map((t) => (
							<TaxInput
								tax={t.taxRate}
								value={t.value}
								key={`tax_${t.taxRate}`}
								setValue={(value) =>
									setTax((old) =>
										old.map((ot) => {
											if (ot.taxRate === t.taxRate)
												return {
													taxRate: t.taxRate,
													value: value || 0,
												}
											return ot
										})
									)
								}
							/>
						))}
					</Row>
				</Form>

				<textarea
					className="form-control mb-2"
					placeholder="Ajouter un commentaire"
					value={comment}
					maxLength={255}
					onChange={(e) => {
						const value = e.target.value
						setComment(value)
					}}
					title={"Commentaire limité à 255 caractères"}
				/>
				<button type="button" className="btn btn-primary btn-block" onClick={() => setModalUpload(true)}>
					Ajouter un nouveau document {receipt && <CheckOutlined />}
				</button>
				<button
					type="button"
					disabled={isBusy}
					className="btn btn-outline-primary btn-block"
					onClick={() => handleSortie()}>
					Sortie de caisse
				</button>
			</>

			<DocumentUploader
				onClose={() => setModalUpload(false)}
				isOpen={modalUpload}
				refreshDocuments={(document) => setReceipt(document)}
				patientIri={""}
				// @ts-ignore need to update commun
				documentType={"CAISSE_JUSTIFICATIF"}
			/>
		</>
	)
}

export default SortieDeCaisseAchat
