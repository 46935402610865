import { ScheduleType } from "@audiowizard/common"
import { colorFromStr, colorToHex, SortAlphabetically } from "services/functions"
import API from "../../services/API"

export const hexToDec = (hex: number): number => {
	return parseInt(hex.toString().substring(1), 16)
}

export const getColor = (color: number, type: string): string => {
	if (color === 1621148) {
		return colorFromStr(type)
	}
	return colorToHex(color)
}

export interface FrontScheduleType extends ScheduleType {
	color: string
	customLabel?: string
	time?: number
	isRelance?: boolean
	hidden?: boolean
}

const getSchedulesType = async (): Promise<Record<string, FrontScheduleType & { color: string }>> => {
	const result: Record<string, FrontScheduleType> = {}

	try {
		const res = SortAlphabetically(await API.findAll("SCHEDULE_TYPES"), "label")
		for (const item of res) {
			if (item.scheduleStatus) {
				result[item.scheduleStatus] = {
					"@id": item["@id"],
					"@type": item["@type"],
					color: item.backgroundColor ? getColor(item.backgroundColor || 0, item.scheduleStatus) : "#000000",
					customLabel: item?.label,
					label: item?.label,
					time: item.duration,
					action: item.action,
					scheduleInterval: item.scheduleInterval,
					// @ts-ignore étrange car cela fait partie de scheduleGroupe et non schedule
					isRelance: item.isRelance,
					// @ts-ignore étrange car cela fait partie de scheduleGroupe et non schedule
					patientTags: item.patientTags,
				}
			}
		}
	} catch (error) {
		console.error(error)
	}

	return result
}

export default getSchedulesType
