export function getLastState(data) {
	let last = {}
	let tmpDate = ""
	data = data.filter((d) => d.type !== "SORTIE_ESPECE")
	for (const v of data) {
		const date = new Date(v.createdAt).getTime()
		if (date > tmpDate) {
			tmpDate = date
			last = { ...v }
		}
	}
	last.state = { ...last?.data?.state }
	last.data = { ...last?.data?.cashfund }
	return last
}
