import { Laboratory, Document } from "@audiowizard/common"
import { Row, Form, Col, Button } from "antd"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { Container } from "reactstrap"
import API from "services/API"
import { formatCurrency } from "services/functions"
import { DownloadExport } from "../DownloadExport"
import { CashOutflowVat, defaultTax, FullAccounting } from "../moneyItems"
import TaxInputHistorique from "./TaxInputHistorique"
import TaxLabelHistorique from "./TaxLabelHistorique"

interface ExpendedRowCashOutflowProps {
	isManager: boolean
	record: FullAccounting
	laboratory: Laboratory
	setDocument: Dispatch<SetStateAction<Document | undefined>>
	setModalViewerOpen: Dispatch<SetStateAction<boolean>>
	setModalUpload: Dispatch<SetStateAction<boolean>>
	setCurrentRecord: Dispatch<SetStateAction<FullAccounting | undefined>>
}

const ExpendedRowCashOutflow = ({
	isManager,
	record,
	laboratory,
	setDocument,
	setModalViewerOpen,
	setModalUpload,
	setCurrentRecord,
}: ExpendedRowCashOutflowProps): JSX.Element => {
	const [isEditable, setIsEditable] = useState<boolean>(false)
	const [updatedRecord, setUpdatedRecord] = useState<FullAccounting>()

	const { user } = useContext(AuthContext)

	const queryClient = useQueryClient()

	useEffect(() => {
		setUpdatedRecord({ ...record })
	}, [record])

	const { mutate: handleSaveCashOutflow } = useMutation(async (event: any): Promise<void> => {
		// for accounting we can not modify old account, we need to create a new one to cancel it and a new one with the new value

		if (!record.id) return
		const inverseRecord: Partial<FullAccounting> = {
			cashOutflowReason: record.cashOutflowReason,
			expectedAmount: 0,
			amount: record.amount ? record.amount * -1 : record.amount,
			cashOutflowVats: record?.cashOutflowVats?.map((c) => ({
				taxRate: c.taxRate,
				taxFreeAmount: c.taxFreeAmount * -1,
				allTaxIncludeAmount: c.allTaxIncludeAmount * -1,
				vatAmount: c.vatAmount * -1,
			})),
			laboratory: record.laboratory,
			type: record.type,
			comment: `Annulation sortie d’espèce du ${dayjs(record.createdAt).format("DD/MM/YYYY")}`,
			cancelAccounting: record["@id"],
		}

		await API.create("ACCOUNTING_API", inverseRecord)

		const correctionRecord: Partial<FullAccounting> = {
			cashOutflowReason: updatedRecord?.cashOutflowReason,
			expectedAmount: 0,
			amount: updatedRecord?.cashOutflowVats?.reduce((t, c) => t + c.allTaxIncludeAmount, 0),
			cashOutflowVats: updatedRecord?.cashOutflowVats?.map((c) => ({
				taxRate: c.taxRate,
				taxFreeAmount: c.taxFreeAmount,
				allTaxIncludeAmount: c.allTaxIncludeAmount,
				vatAmount: c.vatAmount,
			})),
			laboratory: record.laboratory,
			type: record.type,
			comment: `Correction sortie d’espèce du ${dayjs(record.createdAt).format("DD/MM/YYYY")}`,
			replaceAccounting: record["@id"],
		}

		await API.create("ACCOUNTING_API", correctionRecord)

		await API.update("ACCOUNTING_API", record.id, { ...record, cancelDate: new Date() })

		await queryClient.invalidateQueries("ACCOUNTING_API")

		setIsEditable(false)
	})

	const handleShowInput = (): void => {
		setIsEditable(true)
	}

	return (
		<Container fluid>
			<Form labelCol={{ span: 5 }}>
				<Row>
					<Col span={24}>
						<span>
							Motif : {record.cashOutflowReason?.label || ""} {record.cancelDate && "(annulée)"}{" "}
							{record.cancelAccounting && "(annulation)"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col>
						<strong style={{ fontSize: "14px" }}>
							TOTAL HT:{" "}
							{formatCurrency(
								updatedRecord?.cashOutflowVats?.reduce((total, t) => total + t.taxFreeAmount, 0) || 0
							)}{" "}
							- TOTAL TVA:{" "}
							{formatCurrency(
								updatedRecord?.cashOutflowVats?.reduce((total, t) => total + t.vatAmount, 0) || 0
							)}{" "}
							- TOTAL TTC:{" "}
							{formatCurrency(
								updatedRecord?.cashOutflowVats?.reduce(
									(total, t) => total + t.allTaxIncludeAmount,
									0
								) || 0
							)}
						</strong>
					</Col>
				</Row>
				<Row style={{ width: "100%" }} className="mb-2">
					{defaultTax.map((tax) => {
						const currentTax: CashOutflowVat = updatedRecord?.cashOutflowVats?.find(
							(t: CashOutflowVat) => t.taxRate === tax.taxRate
						) || {
							taxRate: tax.taxRate,
							taxFreeAmount: 0,
							allTaxIncludeAmount: 0,
							vatAmount: 0,
						}
						if (isEditable)
							return (
								<TaxInputHistorique
									key={`tax_historique_${currentTax.taxRate}`}
									tax={currentTax}
									setValue={(value) =>
										setUpdatedRecord((old) => {
											old = old as unknown as FullAccounting
											let currentCashOutflowVats = old?.cashOutflowVats
												? [...old?.cashOutflowVats]
												: []
											let alreadyExist = false
											currentCashOutflowVats = currentCashOutflowVats.map((c) => {
												if (c.taxRate === currentTax.taxRate) {
													alreadyExist = true
													return value
												}
												return c
											})
											if (!alreadyExist) {
												currentCashOutflowVats.push(value)
											}

											return {
												...old,
												cashOutflowVats: currentCashOutflowVats,
											}
										})
									}
								/>
							)

						return <TaxLabelHistorique key={`tax_historique_${tax.taxRate}`} tax={currentTax} />
					})}
					{!isEditable && isManager && !record.cancelDate && !record.cancelAccounting && (
						<Col flex="120px" style={{ textAlign: "center" }}>
							<Button style={{ marginTop: 50 }} type="primary" onClick={handleShowInput}>
								Modifier
							</Button>
						</Col>
					)}
					{isEditable && isManager && (
						<Col flex="120px" style={{ textAlign: "center" }}>
							<Button style={{ marginTop: 50 }} type="primary" onClick={handleSaveCashOutflow}>
								Sauvegarder
							</Button>
						</Col>
					)}
				</Row>
			</Form>
			<Row style={{ overflowWrap: "anywhere" }} className="my-1">
				{record.comment || "Aucun commentaire"}
			</Row>
			<Row className="my-4">
				<DownloadExport
					previousData={record}
					data={record}
					user={user}
					laboratory={laboratory}
					setDocument={setDocument}
					setModalViewerOpen={setModalViewerOpen}
					setModalUpload={setModalUpload}
					setCurrentRecord={setCurrentRecord}
				/>
			</Row>
		</Container>
	)
}

export default ExpendedRowCashOutflow
