export const TeletransRoutePrefix = "/teletransmissions"

export const TeletransRoutes = {
	Controle: `${TeletransRoutePrefix}/controle`,
	Suivi: `${TeletransRoutePrefix}/suivi`,
	Fsv: `${TeletransRoutePrefix}/fsv`,
	CoverageDemand: `${TeletransRoutePrefix}/pec`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(TeletransRoutePrefix, "")
}
