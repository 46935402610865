import ButtonRounded from "components/Buttons/ButtonRounded"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { Col, ModalBody, Modal, Row, ModalHeader, ModalFooter } from "reactstrap"
import FieldWithError from "../../components/forms/FieldWithError"
import Select from "../../components/forms/Select"
import { itemsSousTraitants } from "../../datas/items/itemsSousTraitants"
import API from "../../services/API"
import { HandleChange } from "../../services/functions"

const SubcontractorsModal = ({ subcontractor, setSubcontractor, subcontractors, refreshSetter, isOpen, onClose }) => {
	const handleSubmit = async (event) => {
		event.preventDefault()

		try {
			if (!subcontractor.id) {
				await API.create("SUBCONTRACTORS_API", subcontractor)
				toast.success("Le sous-traitant " + subcontractor.label + " a bien été ajouté")
				refreshSetter && refreshSetter(true)
			} else {
				await API.update("SUBCONTRACTORS_API", subcontractor.id, subcontractor)
				toast.success("Le sous-traitant " + subcontractor.label + " a bien été modifié")
				refreshSetter && refreshSetter(true)
			}
			onClose()
		} catch (error) {
			console.error(error)
			toast.error("Une erreur s'est produite, veuillez réessayer")
		}
	}

	const handleDelete = async () => {
		try {
			await API.delete(subcontractor["@id"])
			toast.success("Le sous-traitant " + subcontractor.label + " a bien été supprimé")
			refreshSetter && refreshSetter(true)
			onClose()
		} catch (error) {
			console.error(error)
			toast.error("Une erreur s'est produite, veuillez réessayer")
		}
	}

	useEffect(() => {
		if (!subcontractor.id && !subcontractor.category) {
			setSubcontractor((sc) => ({ ...sc, category: itemsSousTraitants[0].value }))
		}
		// eslint-disable-next-line
	}, [subcontractor])

	return (
		<Modal toggle={onClose} isOpen={isOpen} centered size="lg" className="modal-div">
			<ModalHeader tag="div">
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-plus" />
					</div>

					<h6 className="mb-0">
						{subcontractor &&
							(subcontractor.id ? "Modifier le sous-traitant " : "Ajouter un sous-traitant")}
					</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				<>
					<Row>
						<Col md="6">
							<Select
								label="Catégorie"
								api="SUBCONTRACTORS_API"
								name="category"
								onChange={(event) => HandleChange(event, subcontractor, setSubcontractor)}
								defaultValue={subcontractor.category}>
								{itemsSousTraitants.map((item) => (
									<option
										key={item.id}
										value={item.value}
										selected={item.value === subcontractor.category ? "selected" : ""}>
										{item.value}
									</option>
								))}
							</Select>
						</Col>
						<Col md="6">
							<FieldWithError
								name="label"
								label="Nom"
								placeholder="Nom du sous-traitant"
								onChange={(event) => HandleChange(event, subcontractor, setSubcontractor)}
								value={subcontractor.label}
							/>
						</Col>
					</Row>
					<FieldWithError
						name="description"
						label="Description"
						placeholder="Description"
						onChange={(event) => HandleChange(event, subcontractor, setSubcontractor)}
						value={subcontractor.description}
					/>
				</>
			</ModalBody>
			<ModalFooter>
				{subcontractor.id && (
					<ButtonRounded color="danger-outlined" onClick={() => handleDelete()}>
						Supprimer
					</ButtonRounded>
				)}
				<ButtonRounded color="primary-outlined" onClick={(e) => handleSubmit(e)}>
					{subcontractor.id ? "Modifier " : "Ajouter"}
				</ButtonRounded>
			</ModalFooter>
		</Modal>
	)
}

export default SubcontractorsModal
