export function mergeDeep(...objects) {
	const isObject = (obj) => obj && typeof obj === "object"

	return objects.reduce((prev, obj) => {
		Object.keys(obj).forEach((key) => {
			const pVal = prev[key]
			const oVal = obj[key]

			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = pVal.concat(...oVal)
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = mergeDeep(pVal, oVal)
			} else {
				prev[key] = oVal
			}
		})

		return prev
	}, {})
}

export function downloadFromUrl(href, filename = null, ext = null) {
	try {
		const link = document.createElement("a")

		link.download = `download-${filename}${ext ? `.${ext}` : ""}`
		link.href = href
		link.target = "_blank"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	} catch (e) {
		console.error(e)
	}
}

export function downloadBase64(blobBinary = null, filename = null, ext = null) {
	try {
		const link = document.createElement("a")

		link.href = window.URL.createObjectURL(blobBinary)
		link.download = `${filename}${ext ? `.${ext}` : ""}`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	} catch (e) {
		console.error(e)
	}
}

export async function blobTo(blob, type, blobType = "application/pdf") {
	return new Promise((resolve) => {
		if (type === "base64") {
			const tmp = new Blob([blob], {
				type: blobType,
			})
			const reader = new FileReader()
			reader.readAsDataURL(tmp)
			reader.onloadend = function () {
				resolve(reader.result)
				return reader.result
			}
		} else if (type === "blob") {
			const tmp = new Blob([blob], {
				type: blobType,
			})
			resolve(tmp)
			return tmp
		} else {
			resolve(blob)
			return blob
		}
	})
}

/**
 *
 * @param {number} originalPrice
 * @param {number} discountedPrice
 * @returns {number}
 */
export function discountPercentage(originalPrice, discountedPrice) {
	return ((originalPrice - discountedPrice) / originalPrice) * 100
}

/**
 * @enum {OS}
 */
export const OS = {
	Windows: 0,
	Linux: 1,
	Mac: 2,
	Unknown: 3,
}

/**
 * @returns {OS}
 */
export function detectBrowserOS() {
	const ua = navigator.userAgent

	// Regex trouvés ici : https://github.com/DamonOehlman/detect-browser/blob/59abf2506e3999398f395a5ebd214de0d6944014/src/index.ts#L168
	if (/Windows/.test(ua)) return OS.Windows
	if (/(Mac_PowerPC)|(Macintosh)/.test(ua)) return OS.Mac
	if (/(Linux)|(X11)/.test(ua)) return OS.Linux

	return OS.Unknown
}
