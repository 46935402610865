import { SubCompany } from "@audiowizard/common"
import { Row, Space, Select } from "antd"
import EditableTable from "components/CustomTable/EditableTable"
import { useContext, useEffect, useMemo, useState } from "react"
import { PaymentType, PaymentTypeForTable, PaymentTypePayload, useFetchPaymentType } from "./PaymentTypes.helpers"
import "./PaymentTypes.scss"
import { PaymentTypeTags, refundPaymentMethods } from "../../vente/_Utility"
import CustomSwitchForTable from "./CustomSwitchForTable"
import API from "services/API"
import { toast } from "react-toastify"
import AuthContext from "contexts/AuthContext"
import { useQuery, useQueryClient } from "react-query"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { Col } from "reactstrap"

// to fetch from commun when in commun
export type HiboutikPaymentType = {
	currency: "EUR"
	payment_type: PaymentTypeTags
	open_drawer: 0 | 1
	enabled: 0 | 1
	accounting_account: ""
}

const PaymentTypes: React.FC = () => {
	const [paymentTypes, setPaymentTypes] = useState<PaymentTypeForTable[]>([])
	const [subCompany, setSubCompany] = useState<SubCompany>()

	const { paymentTypes: initialStatePaymentTypes, remove } = useFetchPaymentType(subCompany)
	const { laboratory: ctxLaboratory } = useContext(AuthContext)

	const queryClient = useQueryClient()

	const { data: subCompanies } = useQuery<SubCompany[]>(
		["subCompany"],
		() => API.findAll<SubCompany[]>("SUB_COMPANIES_API", "?pagination=false"),
		{ staleTime: 3600 * 1000 * 24 } // one day
	)

	useEffect(() => {
		if (!subCompanies) return
		const selectedSubCompany = subCompanies.find((c) => c["@id"] === ctxLaboratory.subCompany?.["@id"])
		if (selectedSubCompany) {
			setSubCompany(selectedSubCompany)
		}
	}, [ctxLaboratory, subCompanies])

	useEffect(() => {
		if (initialStatePaymentTypes.length === 0) return
		setPaymentTypes(
			initialStatePaymentTypes.filter((paymentType) => !["DIV"].includes(paymentType.hiboutikReference))
		)
	}, [initialStatePaymentTypes])

	const updatePaymentType = async ({
		paymentType,
		payload,
		subCompany,
	}: {
		paymentType?: PaymentType
		payload?: PaymentTypePayload
		subCompany: SubCompany
	}): Promise<PaymentType> => {
		if (paymentType) {
			try {
				const updatePayment = await API.update<PaymentType>("PAYMENT_TYPES_API", paymentType.id, {
					...paymentType,
				})
				await queryClient.invalidateQueries("payment_type")
				return updatePayment.data
			} catch (err) {
				console.log(err)
				toast.error("Erreur lors de l'enregistrement du type de paiement")
				throw err
			}
		} else {
			try {
				const newPaymentType = await API.create<PaymentType>("PAYMENT_TYPES_API", {
					...payload,
					subCompany: subCompany?.["@id"],
				})
				await queryClient.invalidateQueries("payment_type")
				return newPaymentType.data
			} catch (err) {
				console.error(err)
				toast.error("Erreur lors de l'enregistrement du type de paiement")
				throw err
			}
		}
	}

	const handleOnSaveSingle = useMemo(
		() =>
			async (value: any, row: PaymentTypeForTable, param: keyof PaymentTypeForTable): Promise<void> => {
				const paymentTypePayload = {
					...row,
					[param]: value,
				}
				if (!subCompany) return
				try {
					let updatedRow: PaymentType
					if (paymentTypePayload["@id"]) {
						updatedRow = await updatePaymentType({
							paymentType: paymentTypePayload as PaymentType,
							subCompany,
						})
					} else {
						updatedRow = await updatePaymentType({ payload: paymentTypePayload, subCompany })
					}
					setPaymentTypes((old) => [
						...old.map((_row) => {
							if (_row.key === row.key) {
								return {
									..._row,
									[param]: value ? true : false,
									id: updatedRow.id,
									"@id": updatedRow["@id"],
								}
							} else {
								return _row
							}
						}),
					])
					remove()
				} catch {
					console.error("Payment type non sauvegardé, erreur lors de la sauvegarde.")
					toast.error("Payment non sauvegardé, erreur lors de la sauvegarde.")
				}
			},
		[subCompany]
	)

	const handleOnSave = useMemo(
		() =>
			async (data: any[], row: PaymentTypeForTable): Promise<void> => {
				const previousState = [...paymentTypes]
				if (!subCompany) return
				try {
					let updatedRow: PaymentType
					if (row["@id"]) {
						updatedRow = await updatePaymentType({
							paymentType: row as PaymentType,
							subCompany,
						})
					} else {
						updatedRow = await updatePaymentType({ payload: row, subCompany })
						setPaymentTypes((old) => [
							...old.map((_row) => {
								if (_row.key === row.key) {
									updatedRow = updatedRow as PaymentType
									return {
										...row,
										id: updatedRow.id,
										"@id": updatedRow["@id"],
									}
								} else {
									return _row
								}
							}),
						])
						remove()
					}
				} catch {
					setPaymentTypes(previousState)
					return
				}
			},
		[subCompany, paymentTypes]
	)

	const columns = [
		{
			title: "Tag",
			dataIndex: "hiboutikReference",
			width: "100px",
		},
		{
			title: "Label",
			dataIndex: "label",
			editable: false,
		},
		{
			title: "Paiement direct",
			dataIndex: "isDirectPayment",
			editable: false,
			width: "150px",
			render: (isDirectPayment: boolean, row: any /*, rowId: number*/) => (
				<CustomSwitchForTable<PaymentTypeForTable>
					checked={isDirectPayment}
					onChange={handleOnSaveSingle}
					param={"isDirectPayment"}
					row={row}
				/>
			),
		},
		{
			title: "Paiement différé",
			dataIndex: "isDeferredPayment",
			width: "150px",
			editable: false,
			render: (isDeferredPayment: boolean, row: any /*, rowId: number*/) => (
				<CustomSwitchForTable<PaymentTypeForTable>
					checked={isDeferredPayment}
					onChange={handleOnSaveSingle}
					param={"isDeferredPayment"}
					row={row}
				/>
			),
		},
	]

	return (
		<Col>
			<SectionHeader title="Configurer les modes de paiements" />
			<Space direction="vertical" className="w-100 ml-3 payment-types">
				<Row justify="space-between" align="middle">
					<div className="d-flex flex-column">
						<label>Société</label>
						<Select
							value={subCompany?.["@id"] || ""}
							className="select-sub"
							onChange={(value) => {
								const selectedSubCompany = subCompanies?.find((c) => c["@id"] === value)
								setSubCompany(selectedSubCompany)
							}}>
							{subCompanies?.map((c) => (
								<Select.Option key={c["@id"]} value={c["@id"]}>
									{c.legalLabel}
								</Select.Option>
							))}
						</Select>
					</div>
				</Row>
				<Row>
					<EditableTable
						columns={columns}
						dataSource={paymentTypes}
						onSave={(data, row) => {
							setPaymentTypes(data)
							handleOnSave(data, row)
						}}
					/>
				</Row>
			</Space>
		</Col>
	)
}

export default PaymentTypes
