import { HiboutikProductEntity, HiboutikStockAvailable, LogisticProduct, PatientEquipment } from "@audiowizard/common"
import { cloneDeep } from "lodash"
import { toast } from "react-toastify"
import uuid from "react-uuid"

export interface AvailableSerialNumberType extends HiboutikProductEntity {
	"@id": string
	brandId: number
	brandName?: string
	categoryId: number
	categoryName?: string
	id: number
	inventoryInputDetailId: number
	patientEquipment?: PatientEquipment | PatientEquipment[]
	logisticProduct?: LogisticProduct
	key: string
	quantityAvailable?: number
	sizeId?: number
	sizeName?: string
	serialNumber?: string
}

export const extractAvailableSerialnumber = (products: HiboutikProductEntity[]): any[] => {
	const tmp: any[] = cloneDeep(products ?? [])
	const result: any[] = []

	for (const product of tmp) {
		if (!product.stockManagement || !product?.stockAvailable?.length) continue

		for (const item of product.stockAvailable) {
			delete product.stockAvailable
			delete product.sizes
			delete product.quantityAvailable
			delete product.sizeDetails
			delete item["@id"]
			delete item["@type"]
			if (!item.serialNumber) continue
			result.push({
				...product,
				...item,
				key: uuid(),
			})
		}
	}

	return result
}

export const extractAvailableWithoutSerialnumber = (products: any[]): any[] => {
	const tmp: any[] = cloneDeep(products ?? [])
	const result: any[] = []

	for (const product of tmp) {
		if (!product.stockManagement || !product?.stockAvailable?.length) continue

		for (const item of product.stockAvailable) {
			delete product.stockAvailable
			delete product.sizes
			delete item["@id"]
			delete item["@type"]
			delete product.quantityAvailable
			delete product.sizeDetails
			if (item.serialNumber) continue
			result.push({
				...product,
				...item,
				key: uuid(),
			})
		}
	}
	return result
}

export const isProductStillAvailable = (
	stockAvailable: HiboutikStockAvailable[],
	product: Record<string, any>
): boolean => {
	try {
		const productQuantity = product.quantity ?? product.originalQuantity ?? 0

		const checkByQuantity = stockAvailable.find(
			(available) =>
				available.id === product.productId &&
				(product?.productSizeId ? available?.sizeId === product?.productSizeId : !available?.sizeId) &&
				!available.serialNumber
		)

		const checkByInventoryInputDetailid = stockAvailable.find(
			(available) => available.inventoryInputDetailId === product.inventoryInputDetailId
		)

		const checkBySerialNumber = stockAvailable.find(
			(available) =>
				available.id === product.productId &&
				(product?.productSizeId ? available?.sizeId === product?.productSizeId : !available?.sizeId) &&
				available.serialNumber === product.productSerialNumber
		)

		if (
			(product.productSerialNumber ? !checkBySerialNumber && !checkByInventoryInputDetailid : false) ||
			//@ts-ignore
			(checkByQuantity ? checkByQuantity.quantity < productQuantity : false)
		) {
			return true
		}
	} catch (error) {
		toast.warn("Impossible de vérifier la disponibilité de NOM PRODUIT / DECLINAISON")
	}

	return false
}
