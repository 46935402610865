import { Laboratory, User } from "@audiowizard/common"
import dayjs from "dayjs"
import _ from "lodash"
import { useQuery } from "react-query"
import API from "services/API"
import API_PDF from "services/API_PDF"
import { formatCurrency, rounded } from "services/functions"
import {
	CashOutflowVat,
	CashRecord,
	CashType,
	FullAccounting,
	LastState,
	moneyItems,
	typeAccounting,
} from "./moneyItems"

export const calcEsp = (cash?: CashRecord): number => {
	let total = 0
	if (!cash) return 0
	if (cash.total) {
		return cash.total
	}
	for (const esp in cash) {
		if (esp === "total") continue
		const amount = moneyItems.find((f) => f.name === esp)
		if (amount) {
			// @ts-ignore
			total += +amount.value * +cash[esp as CashType]
		}
	}
	return rounded(total)
}

export const detailsEsp: Partial<Record<CashType, string>> = {
	CINQ_CENT_EUROS: "500",
	DEUX_CENT_EUROS: "200",
	CENT_EUROS: "100",
	CINQUANTE_EUROS: "50",
	VINGT_EUROS: "20",
	DIX_EUROS: "10",
	CINQ_EUROS: "5",
	DEUX_EUROS: "2",
	UN_EUROS: "1",
	CINQUANTE_CTS: ".50",
	VINGT_CTS: ".20",
	DIX_CTS: ".10",
	CINQ_CTS: ".05",
	DEUX_CTS: ".02",
	UN_CTS: ".01",
}

export const calcDiff = (a?: CashRecord, b?: CashRecord): CashRecord => {
	const r: CashRecord = {}
	a = a || {}
	if (!b) return r
	for (const v in b) {
		r[v as CashType] = b?.[v as CashType] || 0
	}
	for (const v in a) {
		if (r[v as CashType] === undefined) {
			r[v as CashType] = 0
		}

		// @ts-ignore
		r[v as CashType] -= rounded(a?.[v as CashType] || 0)
	}
	return r
}

export function getLastState(data: FullAccounting[]): LastState | null {
	data = data.filter((d) => d.type !== typeAccounting.sortieEspece)
	const last = _.maxBy(data, (d) => {
		if (d.createdAt) return new Date(d.createdAt).getTime()
		return 0
	})
	if (!last) return null
	return {
		...last,
		state: { ...last?.data?.state },
		change: { ...last?.data?.cashfund },
	}
}

const generateHeader = (laboratory: Laboratory, user: User) => {
	return {
		logo: laboratory?.subCompany?.logo ?? user.company?.logo,
		companyName: laboratory?.legalLabel,
		laboratoryName: laboratory?.label,
		laboratoryAddress: laboratory?.adress,
		laboratoryCpo: laboratory?.cpo,
		laboratoryCity: laboratory?.city,
		laboratoryPhone: laboratory?.phone,
		laboratoryMail: laboratory?.email,
		laboratoryRCS: laboratory?.rcs,
		laboratorySiret: laboratory?.siret,
		laboratoryFiness: laboratory?.finess,
		laboratoryTVA: laboratory?.tva,
	}
}

export const generatePdfDepot = async ({
	totalState,
	oldTotal,
	newTotal,
	espValues,
	user,
	laboratory,
	comment,
}: {
	totalState: number
	oldTotal: number
	newTotal: number
	espValues: CashRecord
	user: User
	laboratory: Laboratory
	comment: string
}): Promise<void> => {
	if (!espValues) return

	// Réutilisation ancien code caisse
	const companyHeaders = generateHeader(laboratory, user)

	const cashRegisterState = []
	for (const esp in espValues) {
		if (esp === "total") continue
		const amount = moneyItems.find((f) => f.name === esp)
		if (amount) {
			cashRegisterState.push({
				type: "ESPECE",
				value: amount.label,
				multiplier: espValues[esp as CashType],
				total: amount.value * (espValues[esp as CashType] || 0),
			})
		}
	}

	const dataForPdf = {
		date: dayjs().format("LL"),
		comment: comment,
		company: companyHeaders,
		oldCashFund: oldTotal,
		newCashFund: newTotal,
		cashRegisterState,
		total: totalState,
	}

	await API_PDF.uploadFromCarboneToDb(dataForPdf, "DEPOT_BANQUE", "pdf", {
		type: typeAccounting.depotEspece,
	})
}

export const generatePdfSortieDeCaisse = async ({
	espValues,
	user,
	laboratory,
	comment,
	reason = "",
	cashOutflowVats,
	tax,
}: {
	espValues: CashRecord
	user: User
	laboratory: Laboratory
	comment: string
	reason?: string
	cashOutflowVats: CashOutflowVat[]
	tax: TaxInputValue[]
}): Promise<void> => {
	if (!espValues) return

	// Réutilisation ancien code caisse
	const companyHeaders = generateHeader(laboratory, user)

	const dataForPdf = {
		date: dayjs().format("LL"),
		comment: comment,
		company: companyHeaders,
		outflowVats: cashOutflowVats.map((vat) => ({
			label: `TVA ${vat.taxRate} %`,
			HT: formatCurrency(vat.taxFreeAmount),
			TVA: formatCurrency(vat.vatAmount),
			TTC: formatCurrency(vat.allTaxIncludeAmount),
		})),
		totalHT: calcTotalHT(tax),
		totalTVA: calcTotalVAT(tax),
		totalTTC: calcTotalTTC(tax),
		reason: reason,
	}

	await API_PDF.uploadFromCarboneToDb(dataForPdf, "SORTIE_CAISSE", "pdf", {
		type: "CAISSE_SORTIE",
	})
}

export const postDepot = async (
	typeAccounting: string,
	espValues: CashRecord,
	laboratory: Laboratory,
	user: User,
	comment: string,
	lastState?: LastState
): Promise<void> => {
	let tmpData: CashRecord | undefined
	if (lastState?.createdAt) {
		tmpData = calcDiff(espValues, lastState.change)
	} else {
		tmpData = { ...espValues }
	}

	await API.create("ACCOUNTING_API", {
		type: typeAccounting,
		data: {
			state: { ...espValues },
			cashfund: { ...tmpData },
		},
		expectedAmount: 0,
		amount: calcEsp(espValues),
		laboratory: laboratory?.["@id"],
		comment,
	})

	if (!lastState) return
	await generatePdfDepot({
		totalState: calcEsp(espValues),
		oldTotal: calcEsp(lastState.change),
		newTotal: calcEsp(calcDiff(espValues, lastState?.change)),
		espValues,
		user,
		laboratory,
		comment,
	})
}

export const calcHTFromTTC = (value: number, tax: number): number => {
	return (value * 100) / (100 + tax)
}

export const calcTVAFromTTC = (value: number, tax: number): number => {
	return (value * tax) / (100 + tax)
}

export const formatTva = (tax: number): string => {
	return Intl.NumberFormat("fr", { maximumSignificantDigits: 2 }).format(tax)
}

export const useFetchInitialState = (
	laboratory: Laboratory
): { last: { validation: FullAccounting | undefined; state: LastState | null } | undefined; loading: boolean } => {
	const { data: last, isLoading: loading } = useQuery(
		["ACCOUNTING_API", "?order[createdAt]=desc&laboratory=" + laboratory.id],
		async () =>
			await API.findAll<FullAccounting[]>("ACCOUNTING_API", "?order[createdAt]=desc&laboratory=" + laboratory.id),

		{
			select: (res) => {
				const lastValidation = res.find((f) => f.type === typeAccounting.validationEspece)
				const lastState = getLastState(res)
				return { validation: lastValidation, state: lastState }
			},
			onError: (error) => {
				console.error(error)
				return { validation: undefined, state: null }
			},
		}
	)
	return { last, loading }
}

export interface TaxInputValue {
	taxRate: number
	value: number
}

export const calcTotalHT = (tax: TaxInputValue[]): string => {
	return formatCurrency(
		tax.reduce((total, t) => total + Math.round(calcHTFromTTC(t.value, t.taxRate) * 100) / 100, 0)
	)
}

export const calcTotalVAT = (tax: TaxInputValue[]): string => {
	return formatCurrency(
		tax.reduce((total, t) => total + Math.round(calcTVAFromTTC(t.value, t.taxRate) * 100) / 100, 0)
	)
}

export const calcTotalTTC = (tax: TaxInputValue[]): string => {
	return formatCurrency(tax.reduce((total, t) => total + t.value, 0))
}
