import AuthContext from "contexts/AuthContext"
import React, { useContext, useEffect, useRef } from "react"

declare global {
	interface Window {
		Frill: Frill
		Frill_Config: FrillConfig[]
	}

	class Frill {
		widget(config: FrillConfig): FrillWidget
	}

	class FrillWidget {
		open(): void
		close(): void
		destroy(): void
		markAsRead(): void
		identify({ email, name }: { email: string | undefined; name: string | undefined }): void
	}

	interface FrillConfig {
		key: string
		container?: HTMLElement
		settings?: any
		ssoToken?: string
		callbacks?: {
			onReady?(frillWidget: FrillWidget): void
			onBadgeCount?(event: { count: number }): void
		}
	}
}

const FrillWidget: React.FC = () => {
	const frillRef = useRef<FrillWidget | null>(null)
	const { user } = useContext(AuthContext)

	useEffect(() => {
		const config: FrillConfig = {
			key: "e88c0c05-b317-4dce-88fd-446e8e1bb53d",
			callbacks: {
				onReady: (frillWidget) => {
					frillRef.current = frillWidget
					if (!user) return
					frillWidget.identify({ email: user.email, name: user.lastName + " " + user.firstName })
				},
			},
		}

		if ("Frill" in window) {
			frillRef.current = window.Frill.widget(config)
		} else {
			;(window as any).Frill_Config = ((window as any).Frill_Config as any[]) || []
			;(window as any).Frill_Config.push(config)
		}

		return () => {
			if (frillRef.current) {
				frillRef.current.destroy()
			}
			if (window.Frill_Config) {
				window.Frill_Config = window.Frill_Config.filter((c) => c !== config)
			}
		}
	}, [])

	return (
		<button className="leftbar__link my-2 audiowizard-frill-widget mt-auto">
			<span className="fad fa-lightbulb-on fa-fw">lightbulb-on</span>
			<span className="leftbar__link__label">Suggérer une idée</span>
		</button>
	)
}

export default FrillWidget
