/** Fichier de Traduction, bryntum étant en anglais de base */

// This will be a truthy empty string so it can be used as a localized result.
const emptyString = new String() // eslint-disable-line no-new-wrappers

export const locale = {
	localeName: "Fr",
	localeDesc: "Français",

	//region Shared localization

	Button: {
		Refresh: "Rafraîchir",
		SaveView: "Sauvegarde Vue",
	},

	EventEdit: {
		Name: "Nom",
		Resource: "Ressource",
		Start: "Début",
		End: "Fin",
		Save: "Confirmer",
		Delete: "Supprimer",
		Cancel: "Annuler",
		"Edit event": "Éditer un rendez-vous",
		Repeat: "Répéter",
		Calendar: "Calendrier",
		"All day": "Toute la journée",
		day: "Jour",
		week: "Semaine",
		month: "Mois",
		year: "Année",
		decade: "Décade",
	},

	Combo: {
		noResults: "Aucun résultat",
	},

	Popup: {
		close: "Fermer la modale",
	},

	UndoRedo: {
		Undo: "Annuler",
		Redo: "Refaire",
		UndoLastAction: "Annuler dernière action",
		RedoLastAction: "Refaire dernière action",
	},

	//endregion

	// Translations for common words and phrases which are used by all classes.
	Object: {
		Yes: "Oui",
		No: "Non",
		Cancel: "Annuler",
		Ok: "OK",
		newEvent: "Nouveau rendez-vous",
	},

	//region Features

	ColumnPicker: {
		column: "Colonne",
		columnsMenu: "Colonne",
		hideColumn: "Cacher colonne",
		hideColumnShort: "Cacher",
		newColumns: "Nouvelle colonne",
	},

	Filter: {
		applyFilter: "Applique filtre",
		filter: "Filtrer",
		editFilter: "Éditer filtre",
		on: "Sur",
		before: "Avant",
		after: "Après",
		equals: "Égale",
		lessThan: "Moins que",
		moreThan: "Plus que",
		removeFilter: "Supprimer filtre",
	},

	FilterBar: {
		enableFilterBar: "Activer filtre",
		disableFilterBar: "Supprimer filtre",
	},

	Group: {
		group: "Groupe",
		groupAscending: "Groupe croissant",
		groupDescending: "Groupe décroissant",
		groupAscendingShort: "Croissant",
		groupDescendingShort: "Décroissant",
		stopGrouping: "Arrêter groupe",
		stopGroupingShort: "Arrêter",
	},

	MergeCells: {
		mergeCells: "Grouper les cellules",
		menuTooltip: "Grouper les cellules avec le même contenue",
	},

	Search: {
		searchForValue: "Rechercher la valeur",
	},

	Sort: {
		sort: "Trie",
		sortAscending: "Trie croissant",
		sortDescending: "Trie décroissant",
		multiSort: "Trie multiple",
		removeSorter: "Supprimer le trie",
		addSortAscending: "Ajouter trie croissant",
		addSortDescending: "Ajouter trie décroissant",
		toggleSortAscending: "Modifier trie croissant",
		toggleSortDescending: "Modifier trie décroissant",
		sortAscendingShort: "Croissant",
		sortDescendingShort: "Décroissant",
		removeSorterShort: "Supprimer",
		addSortAscendingShort: "+ croissant",
		addSortDescendingShort: "+ décroissant",
	},

	//region Grid

	RatingColumn: {
		cellLabel: (column) =>
			`${column.text} ${column.location?.record ? `Estimation : ${column.location.record[column.field]}` : ""}`,
	},

	GridBase: {
		loadFailedMessage: "Erreur de chargement!",
		syncFailedMessage: "Erreur de synchronisation!",
		unspecifiedFailure: "Erreur inattendue",
		networkFailure: "Erreur de réseau",
		parseFailure: "Erreur de traitement",
		loadMask: "Chargement...",
		syncMask: "Synchronisation en court...",
		noRows: "Aucun Rendez-vous à afficher",
		moveColumnLeft: "Déplacer la colonne vers la gauche",
		moveColumnRight: "Déplacer lo colonne vers le droite",
		moveColumnTo: function (region) {
			return "Déplacer le colonne vers " + region
		},
	},

	CellMenu: {
		removeRow: "Supprimer",
	},

	//endregion

	//region Widgets

	FilePicker: {
		file: "Fichier",
	},

	Field: {
		badInput: "Donnée invalide",
		patternMismatch: "Donnée invalide",
		rangeOverflow: function (value) {
			return "La valeur doit être égale ou inférieure à 30 " + value.max
		},
		rangeUnderflow: function (value) {
			return "La valeur doit être égale ou supérieure à " + value.min
		},
		stepMismatch: "La valeur ne correspond pas",
		tooLong: "Valeur trop longue",
		tooShort: "Valeur trop courte",
		typeMismatch: "La donnée n'est pas au bon format",
		valueMissing: "Donnée obligatoire",

		fieldRequired: "Donnée obligatoire",
		invalidValue: "Donnée invalide",
		maximumValueViolation: "Valeur trop importante",
		minimumValueViolation: "Valeur trop petite",
		validateFilter: "Veuillez sélectionner une donnée dans la liste",
	},

	DateField: {
		invalidDate: "Date invalide",
	},

	DatePicker: {
		gotoPrevYear: "Année précédente",
		gotoPrevMonth: "Mois précédent",
		gotoNextMonth: "Mois suivant",
		gotoNextYear: "Année suivante",
	},

	NumberFormat: {
		locale: "de",
		currency: "EUR",
	},

	DurationField: {
		invalidUnit: "Unité invalide",
	},

	TimeField: {
		invalidTime: "Date invalide",
	},

	TimePicker: {
		hour: "Heure",
		minute: "Minute",
	},

	//endregion

	//region Others

	DateHelper: {
		locale: "fr",
		weekStartDay: 1,
		// Non-working days which match weekends by default, but can be changed according to schedule needs
		nonWorkingDays: {
			0: true,
			6: true,
		},
		// Days considered as weekends by the selected country, but could be working days in the schedule
		weekends: {
			0: true,
			6: true,
		},
		unitNames: [
			{ single: "Milliseconde", plural: "Milliseconde", abbrev: "ms" },
			{ single: "Seconde", plural: "Secondes", abbrev: "s" },
			{ single: "Minute", plural: "Minutes", abbrev: "min" },
			{ single: "Heure", plural: "Heures", abbrev: "h" },
			{ single: "Jour", plural: "Jours", abbrev: "j" },
			{ single: "Semaine", plural: "Semaines", abbrev: "s" },
			{ single: "Mois", plural: "Mois", abbrev: "m" },
			{ single: "Quartal", plural: "Trimestre", abbrev: "Q" },
			{ single: "Année", plural: "Années", abbrev: "an" },
			{ single: "Décennie", plural: "Décennies", abbrev: "dc" },
		],
		// Used to build a RegExp for parsing time units.
		// The full names from above are added into the generated Regexp.
		// So you may type "2 w" or "2 wk" or "2 week" or "2 weeks" into a DurationField.
		// When generating its display value though, it uses the full localized names above.
		unitAbbreviations: [
			["mil"],
			["s", "sec"],
			["m", "min"],
			["h", "hr"],
			["d"],
			["w", "wk"],
			["mo", "mon", "mnt"],
			["q", "quar", "qrt"],
			["y", "yr"],
			["dek"],
		],
		parsers: {
			L: "DD.MM.YYYY",
			LT: "HH:mm",
		},
		ordinalSuffix: function (number) {
			return number
		},
	},

	PagingToolbar: {
		firstPage: "Première page",
		prevPage: "Dernière page consultée",
		page: "Page",
		nextPage: "Page suivante",
		lastPage: "Dernière page",
		reload: "Recharger la page actuelle",
		noRecords: "Aucun rendez-vous à afficher",
		pageCountTemplate: function (data) {
			return "sur " + data.lastPage
		},
		summaryTemplate: function (data) {
			return "Résultat " + data.start + " - " + data.end + " de " + data.allCount
		},
	},

	PanelCollapser: {
		Collapse: "Réduire",
		Expand: "Ouvrir",
	},

	List: {
		loading: "Chargement...",
		List: "Liste",
	},

	EventList: {
		List: "Liste",
	},

	RowCopyPaste: {
		cutRecord: "Couper enregistrement",
		copyRecord: "Copier enregistrement",
		pasteRecord: "Coller enregistrement",
	},

	//region Export

	PdfExport: {
		"Waiting for response from server": "Attente de la réponse du serveur...",
		"Export failed": "Échec de l'export",
		"Server error": "Erreur serveur",
		"Generating pages": "Generation de la page ...",
	},

	ExportDialog: {
		width: "40em",
		labelWidth: "12em",
		exportSettings: "Export paramètres",
		export: "Export",
		exporterType: "Export des types",
		cancel: "Abandonner",
		fileFormat: "Format du fichier",
		rows: "Lignes",
		alignRows: "Alignement des lignes",
		columns: "Colonnes",
		paperFormat: "Format papier",
		orientation: "Orientation",
		repeatHeader: "Répéter haut de page",
	},

	ExportRowsCombo: {
		all: "Toutes les lignes",
		visible: "Lignes visibles",
	},

	ExportOrientationCombo: {
		portrait: "Portrait",
		landscape: "Paysage",
	},

	EventCopyPaste: {
		copyEvent: "Copier événement",
		cutEvent: "Couper événement",
		pasteEvent: "Pauser événement",
	},

	SinglePageExporter: {
		singlepage: "Page unique",
	},

	MultiPageExporter: {
		multipage: "Pages multiple",
		exportingPage: function (data) {
			return "Page exportée " + data.currentPage + "/" + data.totalPages
		},
	},

	MultiPageVerticalExporter: {
		multipagevertical: "Pages multiples",
		exportingPage: function (data) {
			return "Page exportée " + data.currentPage + "/" + data.totalPages
		},
	},

	//endregion

	//endregion

	//region Scheduler

	ResourceInfoColumn: {
		eventCountText: function (data) {
			return data + " rendez-vous"
		},
	},

	Dependencies: {
		from: "De",
		to: "Vers",
		valid: "Valide",
		invalid: "Invalide",
	},

	EventDrag: {
		eventOverlapsExisting: "L'événement chevauche un événement déjà existant",
		noDropOutsideTimeline: "Impossible de déplacer un événement un dehors de la zone du calendrier",
	},

	SchedulerBase: {
		"Add event": "Créer le rendez-vous",
		"Delete event": "Supprimer le rendez-vous",
		"Unassign event": "Détacher un événement",
	},

	TimeAxisHeaderMenu: {
		pickZoomLevel: "Zoom",
		activeDateRange: "Dates actives",
		startText: "Début du text",
		endText: "Fin du text",
		todayText: "Aujourd’hui",
	},

	EventFilter: {
		filterEvents: "Filtrer les rendez-vous",
		byName: "Par nom",
	},

	TimeRanges: {
		showCurrentTimeLine: "Montrer la date actuelle",
	},

	PresetManager: {
		minuteAndHour: {
			topDateFormat: "ddd DD.MM, HH:mm",
		},
		hourAndDay: {
			topDateFormat: "ddd DD.MM",
		},
		weekAndDay: {
			displayDateFormat: "HH:mm",
		},
	},

	RecurrenceConfirmationPopup: {
		"delete-title": "Vous supprimer un événement",
		"delete-all-message": "Voulez-vous supprimer toutes les occurrences de cet événement?",
		"delete-further-message":
			"Voulez-vous supprimer cet événement et toutes les occurrences futures de cet événement, ou uniquement l'occurrence sélectionnée?",
		"delete-further-btn-text": "Supprimer tous les événements futurs",
		"delete-only-this-btn-text": "Supprimer uniquement cet événement",

		"update-title": "Vous modifier un événement récurrent",
		"update-all-message": "Voulez-vous modifier toutes les occurrences de cet événement?",
		"update-further-message":
			"Voulez-vous modifier uniquement cette occurrence de l'événement, ou cet événement et tous les événements futurs?",
		"update-further-btn-text": "Tous les événements futurs",
		"update-only-this-btn-text": "Juste cet événement",

		Yes: "Oui",
		Cancel: "Annuler",

		width: 600,
	},

	RecurrenceLegend: {
		// list delimiters
		" and ": " et ",
		// frequency patterns
		Daily: "Quotidien",
		"Weekly on {1}": function (data) {
			return "Hebdomadaire le " + data.days
		},
		"Monthly on {1}": function (data) {
			return "Mensuel le " + data.days
		},
		"Yearly on {1} of {2}": function (data) {
			return "Annuel le " + data.days + " " + data.months
		},
		"Every {0} days": function (data) {
			return "Tous les " + data.interval + " jours"
		},
		"Every {0} weeks on {1}": function (data) {
			return "Tous les " + data.interval + " semaines le " + data.days
		},
		"Every {0} months on {1}": function (data) {
			return "Tous les " + data.interval + " mois le " + data.days
		},
		"Every {0} years on {1} of {2}": function (data) {
			return "Tous les " + data.interval + " ans le " + data.days + " " + data.months
		},
		// day position translations
		position1: "premier",
		position2: "deuxième",
		position3: "troisième",
		position4: "quatrième",
		position5: "cinquième",
		"position-1": "dernier",
		// day options
		day: "Jour",
		weekday: "Jour de la semaine",
		"weekend day": "Jour du weekend",
		// {0} - day position info ("the last"/"the first"/...)
		// {1} - day info ("Sunday"/"Monday"/.../"day"/"weekday"/"weekend day")
		// For example:
		//  "the last Sunday"
		//  "the first weekday"
		//  "the second weekend day"
		daysFormat: function (data) {
			return data.position + " " + data.days
		},
	},

	RecurrenceEditor: {
		"Repeat event": "Événement récurrent",
		Cancel: "Annuler",
		Save: "Sauvegarder",
		Frequency: "Fréquence",
		Every: "Tout(e/s)",
		DAILYintervalUnit: "Jour",
		WEEKLYintervalUnit: "Semaine",
		MONTHLYintervalUnit: "Mois",
		YEARLYintervalUnit: "Année",
		Each: "Tout(e/s)",
		"On the": "Le",
		"End repeat": "Fin",
		"time(s)": "fois",
	},

	RecurrenceDaysCombo: {
		day: "jour",
		weekday: "semaine",
		"weekend day": "jour de la semaine",
	},

	RecurrencePositionsCombo: {
		position1: "première",
		position2: "deuxième",
		position3: "troisième",
		position4: "quatrième",
		position5: "cinquième",
		"position-1": "dernière",
	},

	RecurrenceStopConditionCombo: {
		Never: "Jamais",
		After: "Après",
		"On date": "Au jour (exclu)",
	},

	RecurrenceFrequencyCombo: {
		Daily: "quotidien",
		Weekly: "hebdomadaire",
		Monthly: "mensuel",
		Yearly: "annuel",
	},

	RecurrenceCombo: {
		None: "non",
		Custom: "Personnalisé...",
	},

	//region Features

	Summary: {
		"Résumé pour": function (date) {
			return "Résumé pour " + date
		},
	},

	//endregion

	//region Export

	ScheduleRangeCombo: {
		completeview: "Vue globale",
		currentview: "Vue actuelle",
		daterange: "Plage de date",
		completedata: "Date complète",
	},

	ExcelExporter: {
		"No resource assigned": "Pas d'agenda associé",
	},

	//endregion

	//region Examples

	Column: {
		columnLabel: (column) =>
			`${column.text ? `${column.text} diviser. ` : ""}ESPACE pour le menu contextuel${
				column.sortable ? ", ENTRER pour trier" : ""
			}`,
		cellLabel: emptyString,
		Name: "Nom",
		Percent: "Pourcentage",
		Start: "Début",
		Finish: "Fin",
		Date: "Date",
		Type: "Type",

		End: "Fin",
	},

	//endregion

	CrudManagerView: {
		serverResponseLabel: "Réponse du serveur:",
	},

	// endregion

	// region Calendar

	Calendar: {
		Today: "Aujourd'hui",
		next: (unit) => `Suivant ${unit}`,
		previous: (unit) => `${unit} Précédent`,
		plusMore: (value) => `+${value} `,
		allDay: "Toute la journée",
		endsOn: (d) => `fini le ${d}`,
		weekOfYear: ([y, w]) => `Semaine ${w}, ${y}`,
	},

	EventMenu: {
		duplicateEvent: "Dupliquer rendez-vous",
		copy: "Copier",
	},

	CalendarDrag: {
		holdCtrlForRecurrence: "Maintenez la touche CTRL enfoncée pour un événement récurrent",
	},

	CalendarMixin: {
		eventCount: (count) => `${count || "Aucun"} Rendez-vous`,
	},

	EventTip: {
		"Edit event": "Éditer un rendez-vous",
	},

	EventRenderer: {
		withoutClient: "Sans patient",
	},

	AgendaView: {
		Agenda: "Agenda",
		Week: "Semaine",
	},

	MonthView: {
		Month: "Mois",
		monthUnit: "mois",
	},

	WeekView: {
		Week: "Semaine",
		weekUnit: "semaine",
	},

	YearView: {
		Year: "Année",
		yearUnit: "an",
	},

	DayView: {
		Day: "Jour",
		dayUnit: "jour",
		daysUnit: "jours",
	},

	Sidebar: {
		"Filter events": "Filtrer les rendez-vous",
	},

	// endregion
}
