import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import useHasRole from "components/Hooks/useHasRole"
import { Link, useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import Button from "../../../components/utils/Button"
import { ProductsTable } from "./CatalogueTables"
import { useState } from "react"
import { CatalogueRoutePrefix } from "router/Catalogue/routes"

const Products = (): JSX.Element => {
	const [productSearch, onProductSearchChange] = useState("")

	const history = useHistory()
	const isManager = useHasRole("ROLE_MANAGER")

	return (
		<Col>
			<SectionHeader title="Liste des produits">
				{isManager && (
					<ButtonRounded
						color="primary-outlined"
						icon="fa-plus"
						onClick={() => history.push(`${CatalogueRoutePrefix}/nouveau-produit`)}>
						Ajouter un produit
					</ButtonRounded>
				)}
			</SectionHeader>
			<Row className="my-2">
				<Col md={8}>
					<input
						type="search"
						name="search"
						placeholder="Rechercher un produit"
						className="form-control"
						value={productSearch}
						onChange={(e) => {
							console.log(e)
							onProductSearchChange(e.target.value)
						}}
					/>
				</Col>
				<Col>
					<Link to="/parametres/catalogues/verification-produits">
						<button type="button" className="btn btn-block btn-secondary">
							Vérification des produits
						</button>
					</Link>
				</Col>
			</Row>
			<Row className="my-4">
				<Col md={12}>
					<div className="table-responsive">
						<ProductsTable modelSearchQuery={productSearch} />
					</div>
				</Col>
			</Row>
			<Button id="btn-modal-modifier-product" className="d-none" />
		</Col>
	)
}

export default Products
