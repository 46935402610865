import { useState, useEffect } from "react"
import Modal from "../../../components/effects/ReactModal"
import { useDropzone } from "react-dropzone"
import { toast } from "react-toastify"
import { last } from "lodash"
import API_PDF from "../../../services/API_PDF"
import { findOnlyPersonalizedTemplateId } from "../../../services/functions"

const UploadModal = ({ isOpen, setIsOpen, chosenTemplate, personalizations, setPersonalizations }) => {
	const [documentToAdd, setDocumentToAdd] = useState({
		filename: "",
		patient: null,
		binary: null,
		type: "",
		extension: null,
		label: "",
	})
	const [disable, setDisable] = useState(true)
	const [templateId, setTemplateId] = useState("")

	useEffect(() => {
		if (isOpen) {
			const id = findOnlyPersonalizedTemplateId(personalizations, chosenTemplate.type)
			if (id) {
				setTemplateId(id)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	const handleClose = () => {
		setDocumentToAdd({
			filename: "",
			patient: null,
			binary: null,
			type: "",
			extension: null,
			label: "",
		})
		setTemplateId("")
		setIsOpen(false)
	}

	const importConvertedFile = (file) => {
		const reader = new FileReader()

		const name = file.name.replace(/[^a-zA-Z0-9-. ]/g, "")
		const extension = last(name.split("."))
		const filename = name.split(".")[0]
		const label = file.label || filename

		reader.onabort = () => console.error("file reading was aborted")
		reader.onerror = () => console.error("file reading has failed")
		reader.onload = () => {
			const binary = reader.result
			setDocumentToAdd((old) => ({
				...old,
				binary,
				extension,
				filename,
				label,
				patient: null,
			}))
		}
		reader.readAsDataURL(file)
	}

	const onDrop = (acceptedFiles) => {
		acceptedFiles.forEach((file) => importConvertedFile(file))
		setDisable(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop })

	const saveDocument = (e, document) => {
		e.preventDefault()
		if (!document.binary) return
		const file = acceptedFiles[0]
		const customTemplateId = findOnlyPersonalizedTemplateId(personalizations, chosenTemplate.type)

		API_PDF.postNewCarboneTemplate({ ...document, file }, chosenTemplate, setPersonalizations)
			.then(async () => {
				if (customTemplateId) await API_PDF.deleteTemplate(customTemplateId)
				toast.success("Le template est enregistré avec succès")
				handleClose()
			})
			.catch(() => {
				toast.error("Erreur lors de l'enregistrement du template")
			})
	}

	const isUnsupportedFormat = (document) => {
		if (document.extension && !["odt", "doc", "docx", "xlsx"].includes(document.extension)) {
			toast.warn("Merci de choisir un document avec une extension adéquate")
			setDocumentToAdd({
				filename: "",
				patient: null,
				binary: null,
				type: "",
				extension: null,
				label: "",
			})
			setDisable(true)
		} else if (document.filename && !document.extension) {
			toast.warn("Vérifiez le format de ce fichier, format non reconnu")
			setDocumentToAdd({
				filename: "",
				patient: null,
				binary: null,
				type: "",
				extension: null,
				label: "",
			})
			setDisable(true)
		} else {
			return
		}
	}

	useEffect(() => {
		if (documentToAdd && documentToAdd.filename) {
			isUnsupportedFormat(documentToAdd)
		}
	}, [documentToAdd])

	const handleDownloadTemplate = (id, name) => {
		try {
			API_PDF.getCarboneTemplate(id, name)
		} catch (error) {
			toast.error("Votre document n'a pas pu être téléchargé")
			console.error(error)
		}
	}

	const handleRemoveTemplate = (id, name) => {
		try {
			API_PDF.removeCarboneTemplate(id, name, setPersonalizations)
			handleClose()
			toast.info(`Votre template ${name} a bien été supprimé`)
		} catch (error) {
			toast.error("Votre document n'a pas pu être supprimé")
			console.error(error)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={`Vous pouvez mettre à jour votre document (${chosenTemplate?.label})`}
			onClose={handleClose}
			confirmDisabled={!!disable}>
			<>
				<div className="container d-flex justify-content-around flex-column">
					{templateId && (
						<div className="d-flex flex-column justify-content-center align-items-center mb-4">
							<p className="w-100">Vous utilisez déjà un template que vous avez personnalisé</p>
							<div className="d-flex justify-content-between w-100">
								<button
									className="btn btn-info"
									onClick={() => handleDownloadTemplate(templateId, chosenTemplate.name)}>
									Télécharger mon template
									<i className="fad fa-file-download ml-4" />
								</button>
								<button
									className="btn btn-danger"
									onClick={() => handleRemoveTemplate(templateId, chosenTemplate.name)}>
									Supprimer mon template
									<i className="fad fa-trash ml-4" />
								</button>
							</div>
						</div>
					)}
					<form
						className="m-2 d-flex flex-column justify-content-center"
						onSubmit={(e) => {
							saveDocument(e, documentToAdd)
						}}>
						{templateId && !documentToAdd.binary && <p>Télécharger un nouveau template:</p>}
						{documentToAdd?.binary &&
							["odt", "docx", "xlsx", "doc", "xsl"].includes(documentToAdd.extension) && (
								<span className="mb-4 d-block w-50 mx-auto text-center">
									<span>
										{documentToAdd.filename}.{documentToAdd.extension}
									</span>
									<br />
									<i
										style={{ fontSize: "12rem" }}
										className={`fad fa-file-${
											["odt", "doc", "docx"].includes(documentToAdd.extension)
												? "word icon-info"
												: "excel icon-primary"
										}`}
									/>
								</span>
							)}

						<div {...getRootProps()} className={"dropzone mb-3 cursor-pointer"}>
							<input name="file" type="file" {...getInputProps()} />
							{isDragActive ? (
								<p>Relacher ici</p>
							) : (
								<p className="text-center">
									Glisser-déposer un fichier ou cliquer ici
									<br /> formats supportés : .odt, .doc, .docx, .xlsx
								</p>
							)}
						</div>
						<button type="submit" className="btn btn-primary btn-block">
							Enregistrer
						</button>
					</form>
				</div>
			</>
		</Modal>
	)
}

export default UploadModal
