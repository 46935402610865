import { Checkbox, Form, Input, Radio } from "antd"
import { Badge } from "reactstrap"
import { AdminQuotePack } from "./AdministrationInterface"

type AdminPackFormProps = {
	value: AdminQuotePack
	onChange: (value: AdminQuotePack) => void
}
export default function AdminPackForm({ value, onChange }: AdminPackFormProps): JSX.Element {
	const labelError = value.label.trim().length === 0

	return (
		<Form autoComplete="off" layout="inline">
			<Form.Item
				htmlFor="pack-preferences-form-label"
				label="Nom du Pack"
				validateStatus={labelError ? "error" : undefined}
				help={labelError ? "Le nom du pack est vide." : undefined}>
				<Input
					id="pack-preferences-form-label"
					value={value.label}
					onChange={(e) => onChange({ ...value, label: e.target.value })}
				/>
			</Form.Item>

			<Form.Item htmlFor="pack-preferences-form-default">
				<Checkbox
					id="pack-preferences-form-default"
					checked={value.isDefault}
					onChange={(e) => onChange({ ...value, isDefault: e.target.checked })}>
					Par défaut
				</Checkbox>
			</Form.Item>

			<Form.Item htmlFor="pack-preferences-form-c2s">
				<Checkbox
					id="pack-preferences-form-c2s"
					checked={value.isC2S}
					onChange={(e) => onChange({ ...value, isC2S: e.target.checked })}>
					CMU/C2S
				</Checkbox>
			</Form.Item>

			<Form.Item htmlFor="pack-preferences-form-color" label="Couleur du pack">
				<Input
					id="pack-preferences-form-color"
					style={{ width: "44px", padding: "4px" }}
					type="color"
					value={value.color}
					onChange={(e) => onChange({ ...value, color: e.target.value })}
				/>
			</Form.Item>

			<Form.Item htmlFor="pack-preferences-default-class" label="Classe par défaut">
				<Radio.Group
					optionType="button"
					options={[
						{ label: <Badge color="danger">Classe 1</Badge>, value: "FIRST_CLASS" },
						{ label: <Badge color="primary">Classe 2</Badge>, value: "SECOND_CLASS" },
					]}
					value={value.defaultClass}
					onChange={(e) => onChange({ ...value, defaultClass: e.target.value })}
				/>
			</Form.Item>
		</Form>
	)
}
