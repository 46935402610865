import { DocumentType } from "@audiowizard/common"

export const itemsDocumentsAvailable: { label: string; value: DocumentType; manuallySelectable: boolean }[] = [
	{
		label: "Pièce d'identité",
		value: "IDENTITE",
		manuallySelectable: true,
	},
	{
		label: "Carte mutuelle",
		value: "CONTRAT_ASSURANCE",
		manuallySelectable: true,
	},
	{
		label: "Justificatif de domicile",
		value: "ATTESTATION_DOMICILE",
		manuallySelectable: true,
	},
	{
		label: "RGPD signé",
		value: "RGPD_SIGNE",
		manuallySelectable: true,
	},
	{
		label: "Audiogramme",
		value: "AUDIOGRAMME",
		manuallySelectable: true,
	},
	{
		label: "Impédancemétrie",
		value: "IMPEDANCEMETRIE",
		manuallySelectable: true,
	},
	{
		label: "Ordonnance",
		value: "ORDONNANCE",
		manuallySelectable: true,
	},
	{
		label: "Devis normalisé",
		value: "DEVIS",
		manuallySelectable: false,
	},
	{
		label: "Devis simplifié",
		value: "DEVIS_SIMPLIFIE",
		manuallySelectable: false,
	},
	{
		label: "Devis externe",
		value: "DEVIS_EXT",
		manuallySelectable: true,
	},
	{
		label: "Demande PEC",
		value: "PEC_DEMAND",
		manuallySelectable: true,
	},
	{
		label: "Devis PEC",
		value: "PEC_QUOTE",
		manuallySelectable: true,
	},
	{
		label: "Bordereau PEC",
		value: "PEC_SLIP",
		manuallySelectable: true,
	},
	{
		label: "Facture PEC",
		value: "PEC_INVOICE",
		manuallySelectable: true,
	},
	{
		label: "Demande de MDPH",
		value: "MDPH",
		manuallySelectable: true,
	},
	{ label: "PEC signée", value: "PEC_SIGNEE", manuallySelectable: true },
	{
		label: "Feuille de prêt",
		value: "PRET",
		manuallySelectable: true,
	},
	{
		label: "Devis signé",
		value: "DEVIS_SIGNE",
		manuallySelectable: true,
	},
	{
		label: "Facture",
		value: "FACTURE_EXT",
		manuallySelectable: true,
	},
	{
		label: "Feuille de soin",
		value: "FEUILLE_SOIN",
		manuallySelectable: true,
	},

	{
		label: "Compte-Rendu",
		value: "COMPTE_RENDU",
		manuallySelectable: true,
	},
	{
		label: "Bon de commande",
		value: "BON_DE_COMMANDE",
		manuallySelectable: true,
	},
	{
		label: "Bon de livraison extérieur",
		value: "BON_DE_LIVRAISON",
		manuallySelectable: true,
	},
	{ label: "Bon de dépôt caisse", value: "CAISSE_DEPOT", manuallySelectable: true },
	{ label: "Bon de validation caisse", value: "CAISSE_VALIDATION", manuallySelectable: true },
	{ label: "Bon de réassort caisse", value: "CAISSE_REASSORT", manuallySelectable: true },
	// @ts-ignore - nécessite mis-à-jour du commun
	{ label: "Justificatif de sortie de caisse", value: "CAISSE_JUSTIFICATIF", manuallySelectable: true },
	{
		label: "Scan oreille droite",
		value: "SCAN_OREILLE_DROITE",
		manuallySelectable: true,
	},
	{
		label: "Scan oreille gauche",
		value: "SCAN_OREILLE_GAUCHE",
		manuallySelectable: true,
	},
	{ label: "Prescription ophtalmologue", value: "PRESCRIPTION_CECITE", manuallySelectable: true },
	{
		label: "Assurance",
		value: "ASSURANCE",
		manuallySelectable: true,
	},
	{
		label: "Annexe au bon de livraison",
		value: "BL_ANNEX",
		manuallySelectable: true,
	},
	{
		label: "Autre",
		value: "AUTRE",
		manuallySelectable: true,
	},
]

export const documentTypeLabel = itemsDocumentsAvailable.flat().reduce((obj, { label, value }) => {
	obj[value] = label
	return obj
}, {} as Record<DocumentType, string>)
