import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import GestionCheques from "pages/mon-compte/ma-caisse/GestionChequesCB"
import ListePaiementRecu from "pages/mon-compte/ma-caisse/PaiementRecu"
import { noPrefix, PaiementsRoutePrefix, PaiementsRoutes } from "./routes"

function PaiementsRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={PaiementsRoutePrefix}>
			<CardItem
				name="En attente"
				url={noPrefix(PaiementsRoutes.EnAttente)}
				component={() => <ListePaiementRecu paymentState="attente" />}
			/>
			<CardItem
				name="Reçus"
				url={noPrefix(PaiementsRoutes.Reçus)}
				component={() => <ListePaiementRecu paymentState="reçu" />}
			/>
			<CardItem
				name="Dépôt de chèques"
				url={noPrefix(PaiementsRoutes.Cheques)}
				component={() => <GestionCheques paymentType="CHE" />}
			/>
			<CardItem
				name="Dépôt de cartes bancaires"
				url={noPrefix(PaiementsRoutes.CarteBancaire)}
				component={() => <GestionCheques paymentType="CB" />}
			/>
		</CardWithTabs>
	)
}

export default PaiementsRouter
