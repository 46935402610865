import { Patient, PatientEquipment, PatientInsurance } from "@audiowizard/common"
import { EquipmentCategory, EquipmentStatus } from "constants/patient/equipment"
import { useMemo } from "react"
import { useQuery } from "react-query"
import API from "services/API"

interface HibouTag {
	tag_id: number
	tab_label: string
}

export default function usePatientClass(patient?: Patient): [number | undefined, boolean | undefined] {
	const { data: patientInsurances } = useQuery<PatientInsurance[]>(
		["PATIENT_INSURANCE_API", { patient: patient?.["@id"] }],
		() => API.findAll("PATIENT_INSURANCE_API", `?patient=${patient?.["@id"]}`),
		{ staleTime: Infinity, enabled: !!patient }
	)
	const { data: patientEquipments } = useQuery<PatientEquipment[]>(
		["PATIENT_EQUIPMENTS_API", { patient: patient?.["@id"] }],
		() => API.findAll("PATIENT_EQUIPMENTS_API", `?patient=${patient?.["@id"]}`),
		{ staleTime: Infinity, enabled: !!patient }
	)

	const patientHasCmu = useMemo(() => {
		if (!patientInsurances) return undefined
		return patientInsurances.some((insurance) => insurance.isC2S)
	}, [patientInsurances])

	const filteredEquipments = useMemo(() => {
		if (!patientEquipments) return undefined
		const validEquipmentStatus = [EquipmentStatus.Essai, EquipmentStatus.Vendu, EquipmentStatus.Exterieur]

		return patientEquipments.filter(
			(equipment) =>
				validEquipmentStatus.includes(equipment!.status as EquipmentStatus) &&
				equipment.category === EquipmentCategory.Auditif
		)
	}, [patientEquipments])

	const patientLastEquipmentClass = useMemo(() => {
		if (!filteredEquipments || filteredEquipments.length === 0) return undefined
		filteredEquipments.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime())
		const lastEquipment = filteredEquipments.shift()!

		/**
		 * tag_id 23 -> represente la classe 1
		 * tag_id 24 -> represente la classe 2
		 * tag_label -> classe : "1" "2"
		 */

		return +(
			lastEquipment.class ||
			lastEquipment.customTags?.classe ||
			lastEquipment.productJsonHiboutik?.class ||
			lastEquipment.productJsonHiboutik?.tags?.find((tag: HibouTag) => [23, 24].includes(tag.tag_id))
				?.tag_label ||
			0
		)
	}, [filteredEquipments])

	return [patientLastEquipmentClass, patientHasCmu]
}
