import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { ParametreAgendaRoutes, noPrefix } from "./routes"
import { ParametreRoutePrefix } from "router/Personnalisation/routes"
import TypeRendezVous from "pages/Settings/SchedulesType/ScheduleTypes"
import useCustomTitle from "components/Hooks/useTitle"

function ParametreAgendaRouter(): JSX.Element {
	useCustomTitle("Paramètres | Agenda")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem
				name="Personnalisation de l'agenda"
				component={TypeRendezVous}
				url={noPrefix(ParametreAgendaRoutes.ParametreAgenda)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default ParametreAgendaRouter
