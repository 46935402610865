/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
//import API_Hiboutik from "../../../services/API_Hiboutik"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"

import { InputNumber } from "antd"
import dayjs from "dayjs"
import { QueryClient } from "react-query"
import { Col, Row } from "reactstrap"
import API_PDF from "services/API_PDF"
import { rounded } from "services/functions"
import { moneyItems as items } from "./moneyItems"
import { getLastState } from "./Utilities"
import ButtonRounded from "components/Buttons/ButtonRounded"
import useCustomTitle from "components/Hooks/useTitle"

const detailsEsp = {
	CINQ_CENT_EUROS: "500",
	DEUX_CENT_EUROS: "200",
	CENT_EUROS: "100",
	CINQUANTE_EUROS: "50",
	VINGT_EUROS: "20",
	DIX_EUROS: "10",
	CINQ_EUROS: "5",
	DEUX_EUROS: 2,
	UN_EUROS: 1,
	CINQUANTE_CTS: ".50",
	VINGT_CTS: ".20",
	DIX_CTS: ".10",
	CINQ_CTS: ".05",
	DEUX_CTS: ".02",
	UN_CTS: ".01",
}

const ReassortCaisse = () => {
	useCustomTitle("Caisse | Réassort")
	const { laboratory, company, user } = useContext(AuthContext)
	const [busy, setBusy] = useState(false)
	//const [lastValidation, setLastValidation] = useState({})
	const [lastState, setLastState] = useState({})
	const [espValues, setEspValues] = useState({})
	const [needRefresh, setNeedRefresh] = useState(new Date())
	const [loading, setLoading] = useState(true)
	const [comment, setComment] = useState("")

	const calcEsp = (data) => {
		let total = 0
		if (data?.total) {
			return data?.total
		}
		for (const esp in data) {
			if (esp === "total") {
				continue
			}
			const amount = items.find((f) => f.name === esp)
			total += +amount.value * +data[esp]
		}
		return rounded(total)
	}

	const calcDiff = (a, b) => {
		const r = {}
		const tmpA = { ...a }
		const tmpB = { ...b }

		for (const v in tmpB) {
			r[v] = +tmpB?.[v]
		}
		for (const v in tmpA) {
			if (!(v in r)) {
				r[v] = 0
			}
			r[v] += +tmpA?.[v]
			r[v] = rounded(r[v])
		}
		return r
	}

	useEffect(() => {
		;(async function () {
			try {
				setLoading(true)
				const res = await API.findAll("ACCOUNTING_API", "?order[createdAt]=desc&laboratory=" + laboratory.id)
				const lastState = getLastState(res)

				setLastState(lastState)
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		})()
	}, [needRefresh])

	const getData = (data) => {
		const result = []

		for (const esp in data) {
			if (esp === "total") continue
			if (!+data[esp]) continue
			const amount = detailsEsp?.[esp]
			result.push({
				label: detailsEsp?.[esp] + " €",
				value: detailsEsp?.[esp],
				multiplier: data[esp],
				total: rounded(+amount * +data[esp]),
			})
		}
		result.sort((a, b) => a.value - b.value)
		result.push({
			label: "Total",
			multiplier: result.reduce((acc, item) => +acc + +item.multiplier, 0),
			total: rounded(result.reduce((acc, item) => +acc + +item.total, 0)),
		})
		return result
	}

	const downloadPdf = async (data) => {
		const csvData = getData(data.data.data.cashfund)

		const tmp = csvData.filter((f) => f.label !== "Total")
		const total = csvData.find((f) => f.label === "Total")

		const pdfData = {
			logo: laboratory?.subCompany?.logo ?? user?.company?.logo,
			company,
			laboratory,
			dateStart: dayjs(data.data.createdAt).format("DD-MM-YY HH-mm"),
			dateEnd: dayjs(data.data.createdAt).format("DD-MM-YY HH-mm"),
			cashRegisterState: [...tmp],
			totalCashRegister: total.total,
			comment: data.data.comment || "",
		}

		await API_PDF.downloadFromCarbonne(pdfData, "RESUME_FOND_DE_CAISSE")
	}

	const handleReassort = async () => {
		try {
			setBusy(true)

			let tmpData = {}
			if (lastState?.createdAt) {
				tmpData = calcDiff(espValues, lastState.data)
			} else {
				tmpData = espValues
			}

			const accountingData = await API.create("ACCOUNTING_API", {
				type: "REASSORT_ESPECE",
				data: {
					state: { ...espValues },
					cashfund: { ...tmpData },
				},
				expectedAmount: 0,
				amount: calcEsp(espValues),
				comment,
				laboratory: laboratory?.["@id"],
			})

			await downloadPdf(accountingData)
			setEspValues({})
		} catch (error) {
			console.error(error)
		} finally {
			setNeedRefresh(new Date())
			QueryClient.invalidateQueries("ACCOUNTING_API")
			setBusy(false)
		}
	}

	return (
		<div className="d-flex flex-column h-100 position-relative">
			{loading && (
				<div className="overlay-loading-aw">
					<div className="overlay-loading-logo" />
					<div className="overlay-loading-text">Chargement ...</div>
				</div>
			)}
			<Row>
				<Col className="my-3" xs="12">
					<h6>
						Montant du réassort :{" "}
						<strong style={{ fontSize: "18px" }}>{calcEsp(espValues).toLocaleString("fr")} € </strong>
					</h6>
					{lastState?.createdAt && (
						<>
							<h6>
								Fond de caisse actuel :{" "}
								<strong style={{ fontSize: "18px" }}>
									{calcEsp(lastState?.data).toLocaleString("fr")} €{" "}
								</strong>
							</h6>
							<h6>
								Nouveau fond de caisse après réassort :{" "}
								<strong style={{ fontSize: "18px" }}>
									{calcEsp(calcDiff(espValues, lastState?.data)).toLocaleString("fr")}
									&nbsp;€
								</strong>
							</h6>
						</>
					)}
				</Col>
				<Col className="my-3" xs="12">
					<form className="row ">
						{items.map((i) => {
							return (
								<div key={`${i.id}-${i.name}`} className="col-md-6">
									<div className="form-group row d-flex justify-content-center align-items-center">
										<label
											htmlFor={i.name}
											className="col-1 col-md-3 d-flex flex-column align-items-center">
											{i.label}
										</label>
										<InputNumber
											type="number"
											className="col-5 col-md-8 align-items-center"
											min="0"
											placeholder="0"
											value={espValues?.[i.name]}
											onChange={(value) => {
												setEspValues((old) => ({
													...old,
													[i.name]: value,
													total: null,
												}))
											}}
											disabled={espValues?.total}
										/>
										<div className="col-1 col-md-1">
											{lastState?.createdAt && (+lastState?.data?.[i.name] || 0)}
										</div>
									</div>
								</div>
							)
						})}
						<div className="col-md-12">
							<textarea
								className="form-control mb-3"
								placeholder="Ajouter un commentaire"
								value={comment}
								onChange={(e) => {
									const value = e.target.value
									setComment(value)
								}}
							/>
						</div>
						<div className="col-md-12">
							<ButtonRounded
								className="m-auto"
								color="primary-outlined"
								isDisabled={busy}
								onClick={handleReassort}
								type="button">
								Réassort
							</ButtonRounded>
						</div>
					</form>
				</Col>
			</Row>
		</div>
	)
}

export default ReassortCaisse
