import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const EquipeRoutePrefix = GestionCompteRoutePrefix + "/equipe"

export const EquipeRoutes = {
	Equipe: `${EquipeRoutePrefix}/gestion`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(EquipeRoutePrefix, "")
}
