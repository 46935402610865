import AuthContext from "contexts/AuthContext"
import { useContext } from "react"
import { Select } from "antd"
import { partialSearch } from "services/functions"

const LaboratoryCol = (
	userLaboratoryIdToName,
	handlePatientChange,
	editingKey,
	isAddingLead,
	laboratories,
	queryfilters,
	isManager,
	laboratory,
	isAffiliate
) => {
	const contextLaboratory = laboratory
	const LaboratoryRender = (laboratory, record) => {
		record["patient.laboratory"] = record["patient.laboratory"] || contextLaboratory.id
		return editingKey === record.key ? (
			<Select
				showSearch
				style={{ width: "100%" }}
				defaultValue={contextLaboratory}
				value={record["patient.laboratory"]}
				onChange={(value) => {
					handlePatientChange(value, record, "patient.laboratory")
					if (isAddingLead) {
						handlePatientChange("", record, "patient.scheduleDate")
						handlePatientChange({ start: "", end: "" }, record, "patient.scheduleTime")
					}
				}}
				filterOption={(input, option) => partialSearch(option.label, input)}
				options={laboratories?.map((l) => ({
					value: l.id,
					label: !isAffiliate ? `${l.label}` : `(${l.id}) ${l.label}`,
				}))}
			/>
		) : (
			!isAffiliate ? (
				<strong className="dashboard-lab mx-2 small-cell-text"> {userLaboratoryIdToName(laboratory)} </strong>
			) : <strong className="dashboard-lab mx-2 small-cell-text"> ({laboratory}) {userLaboratoryIdToName(laboratory)} </strong>
		)
	}

	return {
		title: null,
		key: "laboratory",
		dataIndex: "patient.laboratory",
		width: "150px",
		fixed: "left",
		render: LaboratoryRender,
		defaultFilteredValue: !isManager ? [`${laboratory.id}`] : [],
		filters: laboratories.map((opt) => {
			return {
				text: !isAffiliate ? `${opt.label}` : `(${opt.id}) ${opt.label}`,
				value: opt.id,
			}
		}),
		filteredValue: queryfilters?.laboratory || null,
		filterSearch: true,
		validate: (cond) => (cond["patient.laboratory"] === "" ? "Veuillez choisir un laboratoire." : ""),
	}
}

export default LaboratoryCol
