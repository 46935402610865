import { PatientEquipment } from "@audiowizard/common"
import API from "services/API"
import API_Hiboutik from "services/API_Hiboutik"
import { getCustomTags } from "services/functions"

const getTagsInArray = (productJsonHiboutik: any, tags: any[]): any => {
	if (Array.isArray(productJsonHiboutik?.tags) && productJsonHiboutik?.tags.length > 0) {
		return getCustomTags(productJsonHiboutik, tags, true)
	}
	if (Object.keys(productJsonHiboutik?.tags).length > 0) {
		return productJsonHiboutik.tags
	}
	return null
}

const fetchTagsFromHiboutik = async (productId: number, tags: any[]): Promise<any[]> => {
	try {
		const product = await API_Hiboutik.getProductById(productId)
		if (!product || product.length === 0) return []
		return getTagsInArray(product[0], tags)
	} catch {
		console.error("Erreur lors de l’appel hiboutik, produit non trouvé")
		return []
	}
}

export const updateTagsFromPatientEquipment = async (
	patientEquipment: PatientEquipment,
	tags: any,
	needToUpdate?: boolean
): Promise<Record<string, string>> => {
	if (
		!patientEquipment.customTags ||
		(Array.isArray(patientEquipment.customTags) && patientEquipment.customTags.length === 0)
	) {
		let customTags = getTagsInArray(patientEquipment?.productJsonHiboutik, tags)
		if (!customTags && patientEquipment.productIdHiboutik) {
			customTags = await fetchTagsFromHiboutik(patientEquipment.productIdHiboutik, tags)

			if (customTags && patientEquipment.id && needToUpdate) {
				await API.update("PATIENT_EQUIPMENTS_API", patientEquipment.id, {
					customTags: customTags,
				})
			}
		}
		return customTags || {}
	}
	return patientEquipment.customTags
}
