import { useQuery, useQueryClient } from "react-query"
import {
	UserInsuranceCoverageDemandService,
	userInsuranceCoverageDemandServiceKey,
	userInsuranceCoverageDemandServicePatientKey,
	userInsuranceCoverageDemandServiceStateKey,
} from "../userInsuranceCoverageDemandService"
import axios from "axios"
import { toast } from "react-toastify"

export default function useUserInsuranceCoverageDemandState(id?: number, refetchInterval?: number) {
	const client = useQueryClient()

	return useQuery({
		queryFn: () => UserInsuranceCoverageDemandService.getState(id!),
		queryKey: [userInsuranceCoverageDemandServiceStateKey, id],
		cacheTime: 0,
		staleTime: 0,
		onSuccess: async () =>
			Promise.allSettled([
				client.invalidateQueries([userInsuranceCoverageDemandServiceKey]),
				client.invalidateQueries([userInsuranceCoverageDemandServicePatientKey]),
			]),
		onError: (e) => {
			if (axios.isAxiosError(e) && e.response?.status === 403) {
				toast.error(
					"Impossible de vous identifier auprès de Datamut, veuillez vérifier vos identifiants réseau et laboratoire."
				)
			} else {
				toast.error("Une erreur s’est produite lors de la récupération de l’état de la PEC.")
			}
		},
		refetchInterval,
		refetchOnWindowFocus: true,
		enabled: Boolean(id),
	})
}
