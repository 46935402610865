import calculateTotalAmounts from "./amountCalculator"

import { Laboratory, Patient, User } from "@audiowizard/common"
import { PaymentTypesReference } from "pages/Settings/paymentTypes/PaymentTypes.helpers"
import { UseTranslationOptions } from "react-i18next"
import { buildFooter, buildSubCompanyLogo } from "utils/templates/Common.helper"

export function buildContextStore(
	user: User,
	laboratory: Laboratory,
	patient: Patient,
	store: { [key: string]: any } | undefined,
	t: ((path: string, options?: UseTranslationOptions, prePath?: string) => string) | undefined,
	data: { [key: string]: any } | undefined,
	paymentTypes?: PaymentTypesReference
): any {
	if (!store || !t || !data) return null

	const { company } = store
	return {
		context: { user, patient, company, laboratory },
		invoice: {
			data,
			calculatedData: calculateTotalAmounts(data),
			companyLogo: buildSubCompanyLogo(store, t),
			footer: buildFooter(store, t),
		},
		paymentTypes: paymentTypes || {},
	}
}
