export interface UserInsuranceCoverageDemandFormModel {
	deviceClass: 1 | 2
	documents: string[]
	patient: string
	network: string
	insuranceCompany: string
	mainUserAtTime: string
}

export interface UserInsuranceCoverageDemandModel {
	id: number
	createdAt: string
	updatedAt: string | null
	state: CoverageDemandState
	datamutId: number
	deviceClass: 1 | 2
	documents: UserInsuranceCoverageDemandDocumentModel[]
	mainUserAtTime: SimpleUserModel
	laboratory: {
		"@id": string
		"@type": string
		label: string
		id: number
	}
	network: {
		"@id": string
		"@type": string
		name: string
	}
	insuranceCompany: {
		"@id": string
		"@type": string
		id: number
		name: string
	}
	patient: SimpleUserModel
	createdBy: SimpleUserModel
	lastAction: string | null
}

interface SimpleUserModel {
	"@id": string
	"@type": string
	firstName: string
	lastName: string
	fullName: string
}

export interface UserInsuranceCoverageDemandDocumentModel {
	"@id": string
	"@type": string
	type: string
	id: string
	label: string
	path: string
	signature: string
	numero: number | null
}

export enum CoverageDemandState {
	WAITING_INSERT = "waiting_insert",
	INSERTED = "inserted",
	INSERT_ERROR = "insert_error",
	WAITING_TRANSFORM = "waiting_transform",
	WAITING_NETWORK = "waiting_network",
	VALIDATED = "validated",
	INVOICED_NETWORK = "invoiced_network",
	BANK_TRANSFER_ISSUED = "bank_transfer_issued",
	PAID = "paid",
	CANCELED = "canceled",
	DECLINED = "declined",
	FUNCTIONAL_ERROR = "functional_error",
	SYSTEM_ERROR = "system_error",
	ONGOING_ERROR = "ongoing_error",
}

export interface HumanReadableDemandStateInterface {
	[key: string]: string
}

export const HumanReadableDemandState: HumanReadableDemandStateInterface = {
	waiting_insert: "Attente d’insertion",
	inserted: "En attente",
	insert_error: "Erreur d’insertion",
	waiting_transform: "En attente de transformation",
	waiting_network: "En attente réseau",
	validated: "Validée",
	invoiced_network: "Facturée réseau",
	bank_transfer_issued: "Virement émis",
	paid: "Payée",
	canceled: "Annulée",
	declined: "Refusée",
	functional_error: "Erreur fonctionnelle",
	system_error: "Erreur système",
	ongoing_error: "Erreur en cours de traitement",
}

export interface CoverageDemandStateInterface {
	state: string
	modifiedAt: string | null
	error: string | null
	percent: number
	amoAmount: number | null
	amcAmount: number | null
	userDueAmount: number | null
}
