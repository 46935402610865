const DeceasedCol = (queryfilters) => {
	const DeceasedRender = (deceased) => {
		return <strong className="mx-2">{deceased ? "Oui" : "Non"}</strong>
	}

	const deceasedOptions = [
		{ text: "Oui", value: true },
		{ text: "Non", value: false },
	]

	return {
		title: "Décédé",
		key: "isDeceased",
		dataIndex: "patient.isDeceased",
		render: DeceasedRender,
		filters: deceasedOptions?.map((option) => ({
			text: option.text,
			value: option.value,
		})),
		filteredValue: queryfilters?.isDeceased || null,
		filterSearch: true,
		validate: (cond) =>
			!cond["patient.isDeceased"] || cond["patient.isDeceased"] === ""
				? "Veuillez choisir si le patient est décédé ou non."
				: "",
	}
}

export default DeceasedCol
