/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode } from "react"

import * as React from "react"
import { Spinner } from "reactstrap"

type ModalProps = {
	id: string
	children?: ReactNode
	handleSubmit?: React.ComponentPropsWithoutRef<"form">["onSubmit"]
	handleDelete?: () => void
	disableSubmitButtons?: boolean
	title?: string
	icon?: string
	loading?: boolean
	enableClose?: boolean
	onClose?: (() => void) | boolean
	dataCy?: string

	/** Type du <button> de confirmation. "submit" pour utiliser `handleSubmit` */
	buttonType: React.ComponentPropsWithoutRef<"button">["type"]
}

/** @deprecated Utiliser le composant <Modal> de reactstrap à la place */
function Modal({
	id,
	title,
	children,
	icon = "user",
	loading = false,
	handleSubmit = undefined,
	handleDelete = undefined,
	onClose,
	dataCy,
	disableSubmitButtons = false,
	buttonType = "button",
}: ModalProps): JSX.Element {
	return (
		<div
			className="modal fade "
			id={id}
			role="dialog"
			aria-labelledby={id}
			aria-hidden="true"
			data-keyboard={onClose ? "false" : "true"}
			data-backdrop={onClose ? "static" : "true"}>
			<div className="modal-dialog modal-lg modal-dialog-centered justify-content-center">
				<form
					id={"modal-form-id-" + id}
					className="w-100"
					onSubmit={handleSubmit ? handleSubmit : (e) => e.preventDefault()}>
					<div className="modal-content">
						<div className="modal-header">
							<div className="modal-title d-flex align-items-center" id={"title-" + id}>
								<div>
									<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
										<i className={"fad fa-" + icon} />
									</div>
								</div>
								<div>
									<h6 className="mb-0">{title}</h6>
								</div>
							</div>
							<button
								type="button"
								className="icon-sm icon-danger rounded-circle border-0 mr-3 close-icon"
								data-dismiss="modal"
								id={id + "-btnClose"}
								aria-label="Close"
								data-cy={dataCy + "-btn-close"}
								onClick={typeof onClose === "function" ? onClose : undefined}>
								<i className="fas fa-times" />
							</button>
						</div>
						<div className="modal-body">{children}</div>
						{handleSubmit && (
							<div className="modal-footer">
								<button
									type={buttonType}
									className="btn btn-sm btn-secondary"
									data-cy={dataCy ? dataCy + "-btn" : title + "-btn"}
									disabled={disableSubmitButtons || loading}>
									{loading ? <Spinner size="sm" /> : title ? title : "Enregistrer"}
								</button>
								{handleDelete && (
									<button
										className="btn btn-sm btn-danger"
										type="button"
										onClick={() => handleDelete()}
										disabled={disableSubmitButtons}>
										Supprimer
									</button>
								)}
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

export default Modal
