import React, { useState } from "react"
import PropTypes from "prop-types"
import { Table, Button, Form } from "antd"
import { FormInstance } from "antd/lib/form"
import EditableCell from "./EditableCell"
import "./EditableTable.scss"

const EditableContext = React.createContext<FormInstance<any> | null>(null)
export { EditableContext }

interface EditableRowProps {
	index?: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
	const [form] = Form.useForm()
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

EditableRow.propTypes = {
	index: PropTypes.number,
}

type EditableTableProps = Parameters<typeof Table>[0] & {
	onSave(c: any[], r: any): void
	allowAddRow?: boolean
	dataSource: DataType[]
}

export interface DataType {
	key: React.Key
	[k: string]: any
}

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>
export type ColumnsTypes = (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[]

const EditableTable: React.FC<EditableTableProps> = ({
	columns = [],
	dataSource = [],
	onSave,
	allowAddRow = false,
	pagination = undefined,
}: EditableTableProps) => {
	const [freezeRows, setFreezeRows] = useState<Record<string, boolean>>({})
	//not functional
	// const handleDelete = (key: React.Key): void => {
	// 	const allData = [...dataSource]
	// 	setDataSource(allData.filter((item) => item.key !== key))
	// }

	// not functional
	const handleAdd = (): void => {
		// todo should be improve, get proper id
		// const newData: DataType = {
		// 	key: count,
		// 	name: `Edward King ${count}`,
		// 	age: "32",
		// 	address: `London, Park Lane no. ${count}`,
		// }
		// setDataSource([...dataSource, newData])
	}

	const handleSave = async (row: DataType): Promise<void> => {
		const newData = [...dataSource]
		const index = newData.findIndex((item) => row.key === item.key)
		const item = newData[index]
		newData.splice(index, 1, {
			...item,
			...row,
		})
		await onSave(newData, row)
	}
	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	}

	const setFreeze = (key: React.Key, freeze: boolean): void => {
		setFreezeRows({
			...freezeRows,
			[key]: freeze,
		})
	}
	const customColumns = (columns as ColumnsTypes).map((col) => {
		if (col && !col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: DataType) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: async (data: DataType) => {
					setFreeze(data.key, true)
					await handleSave(data)
					setFreeze(data.key, false)
				},
				freezeRows: freezeRows,
			}),
		}
	})
	return (
		<div>
			{allowAddRow && (
				<Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
					Add a row
				</Button>
			)}
			<Table
				components={components}
				rowClassName={() => "editable-row"}
				bordered
				dataSource={dataSource}
				columns={customColumns as ColumnTypes}
				pagination={pagination}
			/>
		</div>
	)
}

export default EditableTable
