import { Document, Laboratory, Patient, PatientEquipment } from "@audiowizard/common"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import API from "services/API"
import API_Hiboutik from "services/API_Hiboutik"
import { formatDateForDB } from "services/functions"
import { createJson } from "services/scriptFactures"

const handlePaymentDate = (date?: string): string => {
	if (date == null || date === "0000-00-00") return dayjs().format("YYYY-MM-DD")
	return date
}

const updateOriginalSalePayments = async (payment: Record<string, any>): Promise<void> => {
	try {
		const paymentId = payment.payment_detail_id

		if (payment.payment_date === "0000-00-00")
			await API_Hiboutik.saleUpdateDiv(paymentId, "payment_date", dayjs().format("YYYY-MM-DD"))

		if (payment.payment_date_paid === "0000-00-00")
			await API_Hiboutik.saleUpdateDiv(paymentId, "payment_date_paid", dayjs().format("YYYY-MM-DD"))
	} catch (error) {
		toast.warn("Un problème est survenu durant la mise à jour des paiements de la vente d'origine")
		console.error(error)
	}
}

const updateReversedSalePayments = async (reversedSaleId: number, payment: Record<string, any>): Promise<void> => {
	try {
		const result = await API_Hiboutik.saleAddDivPayment(
			reversedSaleId,
			payment.payment_type,
			+payment.payment_amount * -1,
			handlePaymentDate(payment?.payment_date)
		)

		await API_Hiboutik.saleUpdateDiv(
			result.data.payment_detail_id,
			"payment_date_paid",
			handlePaymentDate(payment.payment_date_paid)
		)
	} catch (error) {
		toast.warn("Un problème est survenu durant la mise à jour des paiements de la contre-vente")
		console.error(error)
	}
}

const handleSalesWithoutPayments = async (saleId: number, reversedSaleId: number, saleTotal: number): Promise<void> => {
	try {
		const date = dayjs().format("YYYY-MM-DD")
		let result = null

		result = await API_Hiboutik.saleAddDivPayment(saleId, "SMDP", saleTotal, date)
		await API_Hiboutik.saleUpdateDiv(result.data.payment_detail_id, "payment_date_paid", date)

		result = await API_Hiboutik.saleAddDivPayment(saleId, "SMDP", saleTotal * -1, date)
		await API_Hiboutik.saleUpdateDiv(result.data.payment_detail_id, "payment_date_paid", date)
	} catch (error) {
		toast.warn("Un problème est survenu durant la mise à jour des paiements pour la vente et sa contre-vente")
		console.error(error)
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saleCancel = async (sale: any, document: Document, removePatientEquipments: boolean): Promise<void> => {
	let reversed_line_items = null

	sale = await API_Hiboutik.getSale(sale.sale_id)
	const doc = await API.find<Document>("DOCUMENTS_API", document.id!)
	const patient: Patient = await API.find("PATIENTS_API", doc.patient!.id!)
	// clone array, because we are removing equipments when they get deleted ot status updated
	// doing this because this array is ONLY used for that
	const patientEquipments: PatientEquipment[] = [...(patient.patientEquipments || [])]

	const laboratory = await API.get<Laboratory>(doc.laboratories?.[0] as unknown as string)
	// @ts-ignore
	const user = await API.get(doc.user![0]["@id"])

	if (!doc || !patient || !laboratory || !user) {
		toast.warn("Impossible d'annuler la vente")
		return
	}

	const reverseSale = await API_Hiboutik.saleCreate(sale.store_id)

	await API_Hiboutik.saleUpdate(reverseSale.data.sale_id, "customer_id", sale.customer_id)
	await API_Hiboutik.saleUpdate(reverseSale.data.sale_id, "payment", sale.payment)

	try {
		reversed_line_items = sale.line_items.map((v: any) => ({
			...v,
			item_discount_gross: +v?.item_discount_gross * -1,
			item_discount_net: +v?.item_discount_net * -1,
			item_discount_tax: +v?.item_discount_tax * -1,
			item_total_gross: +v?.item_total_gross * -1,
			item_total_net: +v?.item_total_net * -1,
			item_total_tax: +v?.item_total_tax * -1,
			item_unit_discount_gross: +v?.item_unit_discount_gross * -1,
			item_unit_discount_net: +v?.item_unit_discount_net * -1,
			item_unit_gross: +v?.item_unit_gross * -1,
			item_unit_gross_wo_discount: +v?.item_unit_gross_wo_discount * -1,
			item_unit_net: +v?.item_unit_net * -1,
			item_unit_net_wo_discount: +v?.item_unit_net_wo_discount * -1,
			item_unit_tax: +v?.item_unit_tax * -1,
			multiple_product_price: +v?.multiple_product_price * -1,
			discount: +v?.discount * -1,
		}))
	} catch (error) {
		console.error(error)
	}

	try {
		for (const item of sale.line_items) {
			const { avoir_id: barcode } = await API_Hiboutik.createCredit(item.line_item_id, 0)
			await API_Hiboutik.applyCredit(reverseSale.data.sale_id, barcode)
		}

		try {
			if (sale.payment === "DIV") {
				if (sale.payment_details?.length) {
					for (const payment of sale.payment_details) {
						await updateOriginalSalePayments(payment)
						await updateReversedSalePayments(reverseSale.data.sale_id, payment)
					}
				} else {
					await handleSalesWithoutPayments(sale.sale_id, +sale.sale_total_gross, reverseSale.data.sale_id)
				}
			}
			await API.delete("/store/sale/cancel/" + sale?.sale_id)
		} catch (error) {
			console.error(error)
		}

		// On traite les équipements patient uniquement sur les ventes classique et non pas les facture de télétransmission
		if (document.type === "FACTURE") {
			for (const item of sale.line_items) {
				const foundIndex = patientEquipments.findIndex(
					(f) =>
						f.status === "VENDU" &&
						f.productIdHiboutik === item.product_id &&
						(item.product_size ? item.product_size === f.sizeIdHiboutik : 1) &&
						(item.serial_number ? item.serial_number === f.serialNumber : 1)
				)
				if (foundIndex === -1) continue

				if (removePatientEquipments) {
					await API.delete(patientEquipments[foundIndex]["@id"])
				} else {
					await API.update("PATIENT_EQUIPMENTS_API", patientEquipments[foundIndex].id!, {
						status: "ESSAI",
						datePurchase: null,
					})
				}

				patientEquipments.splice(foundIndex, 1)
			}
		}

		let createdDocument

		try {
			createdDocument = await API.createDocument<any>({
				filename: `facture_contre_vente_${dayjs().format("YYYYMMDDHHmmss")}`,
				label: "Facture contre-vente",
				type: document.type,
				status: "CONTRE-VENTE",
				laboratory: document?.laboratories?.[0],
				patient: doc?.patient?.["@id"],
				seller: doc?.seller?.["@id"] || null,
				numero: -1,
				config: null,
			})

			const commentStr = `Contre vente pour la Facture: ${document?.invoiceNumber || -1} (${
				sale?.sale_id || "--"
			}-${doc?.id || "--"})`

			await API_Hiboutik.saleAddComment(reverseSale.data.sale_id, commentStr)

			await API.update("DOCUMENTS_API", createdDocument.data.id!, {
				edit: {
					comments: commentStr,
				},
			})

			await API_Hiboutik.saleEnd(reverseSale.data.sale_id)
			const reversedSaleDetail = await API_Hiboutik.getSale(reverseSale.data.sale_id)
			const subCompany = await API.find("SUB_COMPANIES_API", laboratory.subCompany!.id!)

			const json_fact = createJson(reversedSaleDetail, doc?.patient, user, laboratory, subCompany)
			if (reversed_line_items) {
				json_fact.ventes.achats = [...reversed_line_items]
			}
			await API.updateWithIri(createdDocument.data["@id"], {
				numero: json_fact?.ventes?.sale_id,
				config: json_fact,
			})

			try {
				if (reversedSaleDetail.payment === "DIV") {
					if (document.config.fse) {
						await API.create("TODOS_API", {
							label: "Contacter la CPAM pour annuler la demande de remboursement",
							status: "TODO",
							//@ts-ignore
							laboratory: patient.laboratory ? patient.laboratory["@id"] : laboratory["@id"],
							patient: patient["@id"],
							type: "CONTACTER_CPAM_ANNULATION_FSE",
							realisationDate: formatDateForDB(dayjs()),
						})
					}
				}
			} catch (error) {
				console.error(error)
			}
		} catch (error: any) {
			const responseUrl = error?.request?.responseURL
			console.error(error)
			if (responseUrl.includes("store/sales/close") && createdDocument) {
				toast.error("Impossible de terminer l'annulation de la vente")
				await API.delete(createdDocument.data["@id"])
			}
		}

		if (document.type === "FACTURE") {
			await API.update("DOCUMENTS_API", document.id!, {
				edit: {
					status: "VENDU",
				},
			})
		} else if (document.type === "FACTURE_SUIVI_TELETRANS") {
			await API.update("DOCUMENTS_API", document.id!, {
				edit: {
					status: "ANNULE_SUIVI_TELETRANS",
				},
			})
		}
	} catch (e) {
		toast.error("Erreur lors de l'annulation de la vente")
	}
}
