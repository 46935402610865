import API from "../../../API"
import { USER_INSURANCE_NETWORKS } from "../../../../config"
import { UserInsuranceNetworkModel, UserInsuranceNetworkRefModel } from "./userInsurance.model"

export const userInsuranceNetworkQueryKey = "USER_INSURANCE_NETWORKS"
export const userInsuranceNetworkApiBase = "/user_insurance_networks"

export const userInsuranceNetwork = {
	list: async () => await API.findAll<UserInsuranceNetworkModel[]>("USER_INSURANCE_NETWORKS"),
	refs: async () => {
        return API.get<UserInsuranceNetworkRefModel[]>(`${userInsuranceNetworkApiBase}/refs`)
	},
}
