import { Form } from "antd"
import { PhoneInputOnly } from "components/forms/PhoneInput"
import { parsePhoneNumber } from "react-phone-number-input/max"
import { formatPhoneNumber } from "services/functions"

const PhoneCol = (editingKey) => {
	function phoneRender(phone, record) {
		return editingKey === record.key ? (
			<Form.Item
				name={"patient.phone"}
				style={{
					margin: 0,
				}}>
				<PhoneInputOnly inputStyle={{ width: "120px", fontSize: "14px", padding: "5px" }} />
			</Form.Item>
		) : (
			<span className="mx-2">{formatPhoneNumber(phone).replace(/\s/g, "")}</span>
		)
	}

	return {
		title: null,
		key: "phone",
		dataIndex: "patient.phone",
		width: "150px",
		render: phoneRender,
		validate: (cond) => {
			const number = parsePhoneNumber(cond["patient.phone"] ?? "")
			const isValid = number != null && number.isValid()
			return !isValid ? "Le numéro de téléphone renseigné est incorrect." : ""
		},
	}
}

export default PhoneCol
