import { ParametreRoutePrefix } from "router/Parametre/routes"

export const CaisseParamètresPrefix = ParametreRoutePrefix + "/caisse"

export const CaisseParamètresRoutes = {
	Paiements: `${CaisseParamètresPrefix}/paiements`,
	SortieDeCaisse: `${CaisseParamètresPrefix}/sortie`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(CaisseParamètresPrefix, "")
}
