/* eslint-disable @typescript-eslint/no-use-before-define */
import { Form, Table } from "antd"
import { useAllLaboratoriesQuery, useAllPrescribersQuery } from "components/Hooks/commonQueries"
import { useIsApt } from "components/Hooks/useIsApt"
import useSavedState from "components/Hooks/useSavedState"
import dayjs from "dayjs"
import AgendaModal from "pages/Schedules/Agenda/ModalAgendaContainer"
import DashBoardSettings from "pages/TableauDeBord/DashBoardSettings/DashBoardSettings"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { formatDateForDB, getScheduleLabel, haveRole, SortAlphabetically } from "services/functions"
import Card from "../../components/utils/Card"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import getSchedulesType from "../Schedules/getScheduleType"
import "../TableauDeBord/TableauDeBord.scss"
import ActionControlerCol from "./Columns/ActionControlerCol"
import OriginDetailCol from "./Columns/OriginDetailCol"
import DPECCol from "./Columns/DPECCol"
import FileCompletedCol from "./Columns/FileCompletedCol"
import FirstNameCol from "./Columns/FirstNameCol"
import GenderCol from "./Columns/GenderCol"
import LaboratoryCol from "./Columns/LaboratoryCol"
import LastNameCol from "./Columns/LastNameCol"
import LastScheduleDateCol from "./Columns/LastScheduleDateCol"
import LastScheduleStatusCol from "./Columns/LastScheduleStatusCol"
import MainUserCol from "./Columns/MainUserCol"
import NextScheduleDateCol from "./Columns/NextScheduleDateCol"
import NextScheduleStatusCol from "./Columns/NextScheduleStatusCol"
import OriginCol from "./Columns/OriginCol"
import PECCol from "./Columns/PECCol"
import PhoneCol from "./Columns/PhoneCol"
import PhoneMobileCol from "./Columns/PhoneMobileCol"
import SecondaryStatusCol from "./Columns/SecondaryStatusCol"
import SourceCol from "./Columns/SourceCol"
import StatusDCol from "./Columns/StatusDCol"
import useHasRole from "components/Hooks/useHasRole"
import DeceasedCol from "./Columns/DeceasedCol"
import DernierAppareillage from "./Columns/DernierAppareillage"
import LastPrescriberCol from "./Columns/LastPrescriberCol"
import OrlCol from "./Columns/OrlCol"
import { TableauDeBordBlocNotes } from "./TableauDeBordBlocNotes"
import { TableFilters } from "./TableFilters"
import { IsRelanceRDV, setDates, SpecialSPFilter } from "./Utils"
import useCustomTitle from "components/Hooks/useTitle"
import { sortBy } from "lodash"

// Custom html cell
// Use destructuring to remove some properties
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
	restProps.style["padding"] = "6px 6px"
	return <td {...restProps}>{children}</td>
}

async function createPatientInsurance(patientInsurance, patientIri, defaultUserInsuranceIri) {
	if (patientInsurance.coverDateInquiry && patientInsurance.coverDateInquiry !== "") {
		patientInsurance.patient = patientIri
		patientInsurance.userInsurance = defaultUserInsuranceIri
		await API.create("PATIENT_INSURANCE_API", patientInsurance)
	}
}

export const TableauDeBord = () => {
	useCustomTitle("Tableau de bord")
	const isApt = useIsApt()
	const { user, laboratory, uiDisplay, companySettings } = useContext(AuthContext)
	const isManager = useMemo(() => haveRole(user, "ROLE_MANAGER"), [user])
	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")
	const queryClient = useQueryClient()

	const [currentRecord, setCurrentRecord] = useState(null)
	const [tableData, setTableData] = useState([])

	const [name, setName] = useState("")
	const [page, setPage] = useState(1)
	const [sort, setSort] = useState(null)
	const [quickFilterData, setQuickFilterData] = useSavedState("dashboardQuickFilterData", {
		nextScheduleStatus: null,
		lastScheduleStatus: null,
		fileCompleted: null,
	})
	const [queryfilters, setQueryFilters] = useSavedState("dashboardFilters", {
		laboratory: [laboratory.id],
		nextScheduleStatus: null,
		lastScheduleStatus: null,
		source: null,
		origin: null,
		originDetails: null,
		fileCompleted: null,
		pec: null,
		gender: null,
		isDeceased: null,
		mainOrl: null,
		lastPrescriber: null,
	})
	const [dashBoardRows, setdashBoardRow] = useState([])
	const [patientsCount, setPatientsCount] = useState(0)
	const [availableSlots, setAvailableSlots] = useState([])
	const [sourceD, setSourceD] = useState([])
	const [origin, setOrigin] = useState([]) // TODO: array of origins, hence renames as origins (but tricky!)

	/**
	 * The 1+ new PatientOriginDetail names added (then to create) from the drop-down menu in column « sous-origine »
	 */
	const originDetailsState = useState([])
	/** @type {{name: string}[]} originDetails */
	const originDetails = originDetailsState[0]
	const setOriginDetails = originDetailsState[1]

	/**
	 * The 1+ PatientOriginDetail retrieved from the Patient’s Origin,
	 * ie the origin.patientOriginDetails where origin matches patient.origin
	 */
	const originDetailsInitialState = useState([])
	/** @type {{"@id": string; "@type": string; id: ?number; name: ?string}[]} originDetailsInitial */
	const originDetailsInitial = originDetailsInitialState[0]
	const setOriginDetailsInitial = originDetailsInitialState[1]

	const [loading, setLoading] = useState(false)
	const [editingKey, setEditingKey] = useState("")
	const [noteKey, setNoteKey] = useState("")
	const [isAddingLead, setIsAddingLead] = useState(false) // true when creating a new line, false when modifying an existing line
	const [tableSettingsVisible, setTableSettingsVisible] = useState(false)
	const [evtData, setEvtData] = useState({ start: "", end: "" })
	const [selectedLaboratory, setSelectedLaboratory] = useState(null)
	const [selectedUser, setSelectedUser] = useState(null)
	const [showModalAgenda, setShowModalAgenda] = useState(false)
	const [form] = Form.useForm()

	const isEditing = (record) => record != null && record.key === editingKey
	const isNoteVisible = (record) => record != null && record.key === noteKey

	const { data: laboratoriesOrNull, isLoading: isLoadingLaboratories } = useAllLaboratoriesQuery()
	const laboratories = laboratoriesOrNull ?? []

	// si l’affiliation n’est pas partie des sources nous devons la créer
	useEffect(() => {
		if (isAffiliate || isAffiliateManager) {
			if (!sourceD.some((item) => item.name === user.affiliation?.code)) {
				setSourceD([...sourceD, { name: user.affiliation?.code }])
			}
		}
	}, [isAffiliate, isAffiliateManager, sourceD])

	const { data: scheduleTypes, isFetching: fetchingScheduleTypes } = useQuery(
		["SCHEDULE_TYPES"],
		async () => await getSchedulesType(user.id),
		{ staleTime: Infinity }
	)

	const { mutate: getAvailableSlots, isLoading: isAvailableSlotsMutating } = useMutation(
		async (payload) => await API.create("LABORATORIES_SLOTS_API", payload),
		{
			onSuccess: (response) => setAvailableSlots(response.data.availableSlots["hydra:member"]),
		}
	)

	const { data: prescribers } = useAllPrescribersQuery()

	const { data: myTeam } = useQuery(
		[
			"USERS_API",
			"/with_laboratories",
			{ pagination: false, roles: ["ROLE_USER", "ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"] },
		],
		async () =>
			await API.findAll(
				"USERS_API",
				"/with_laboratories?pagination=false&roles[]=ROLE_USER&roles[]=ROLE_MANAGER&roles[]=ROLE_MANAGER_FRANCHISED"
			),
		{
			staleTime: Infinity,
			notifyOnChangeProps: ["data", "error"],
			notifyOnChangePropsExclusions: ["isStale"],
		}
	)

	const { data: allTags, isLoading: isLoadingTags } = useQuery(
		"PATIENT_TAGS_API",
		async () => SortAlphabetically(await API.findAll("PATIENT_TAGS_API"), "text"),
		{
			staleTime: Infinity,
		}
	)
	const { data: sourceDInitial } = useQuery(
		"PATIENT_SOURCES_API",
		async () => await API.findAll("PATIENT_SOURCES_API"),
		{
			staleTime: Infinity,
		}
	)
	const { data: originsInitial } = useQuery(
		"ORIGINS_API",
		async () => SortAlphabetically(await API.findAll("ORIGINS_API")),
		{
			staleTime: Infinity,
		}
	)

	const { data: dossierType, isLoading: isLoadingSchedulesGroups } = useQuery(
		"SCHEDULE_TYPE_GROUPS_API",
		async () => await API.findAll("SCHEDULE_TYPE_GROUPS_API"),
		{
			staleTime: Infinity,
			select: (scheduleTypeGroups) => {
				const processData = {}
				for (const scheduleTypeGroup of scheduleTypeGroups) {
					for (const scheduleType of scheduleTypeGroup.scheduleTypes) {
						processData[scheduleType.label] = {
							color: scheduleTypeGroup.color,
							label: scheduleTypeGroup.label,
						}
					}
				}
				return processData
			},
		}
	)

	const originDetailsQuery = useQuery(
		"PATIENTS_ORIGIN_DETAILS_API",
		async () => SortAlphabetically(await API.findAll("PATIENTS_ORIGIN_DETAILS_API"), "label"),
		{ staleTime: Infinity }
	)
	/**
	 * @type {{
	 *   "@id": string
	 *   "@type": string
	 *   id: number
	 *   origin: string
	 *   label: string
	 *   name: ?string
	 *   patientReferer: ?Object
	 *   prescriberReferer: ?Object
	 * }[]} originDetailsFromRoute
	 */
	const originDetailsFromRoute = originDetailsQuery.data

	const {
		data: patientData,
		isLoading,
		isFetching,
	} = useQuery(
		["PATIENTS_API", { scheduleTypes, dossierType, name, quickFilterData, queryfilters, sort, page }],
		async ({ signal }) => {
			if (!scheduleTypes || !dossierType) return

			// if quick filter has no corresponding schedule type, no rows should be returned
			if (quickFilterData.nextScheduleStatus != null && quickFilterData.nextScheduleStatus.length === 0) return []

			if (quickFilterData.lastScheduleStatus != null && quickFilterData.lastScheduleStatus.length === 0) return []

			const searchParams = new URLSearchParams()
			searchParams.append("page", page)
			searchParams.append("itemsPerPage", "10")

			if (name) {
				for (const v of name.split(/\s+/)) searchParams.append("omnisearch[]", v)
			}
			if (sort?.order) searchParams.append(`order[${sort.columnKey}]`, sort.order === "descend" ? "desc" : "asc")

			if (queryfilters.source != null) {
				for (const source of queryfilters.source) searchParams.append("patientSource.name[]", source)
			}
			if (queryfilters.user != null) {
				for (const mainUser of queryfilters.user) searchParams.append("mainUser.id[]", mainUser)
			}
			if (queryfilters.mainOrl != null) {
				for (const mainOrl of queryfilters.mainOrl) searchParams.append("mainOrl.id[]", mainOrl)
			}
			if (queryfilters.lastPrescriber != null) {
				for (const lastPrescriber of queryfilters.lastPrescriber)
					searchParams.append("lastPrescriber.id[]", lastPrescriber)
			}
			if (queryfilters.origin != null) {
				for (const origin of queryfilters.origin) searchParams.append("origin.name[]", origin)
			}
			if (queryfilters.originDetails != null) {
				for (const originDetails of queryfilters.originDetails)
					searchParams.append("patientOriginDetail[]", originDetails)
			}

			if (queryfilters.isDeceased != null) {
				searchParams.append("isPatientDeceased", queryfilters.isDeceased)
			}
			if (queryfilters.gender != null) {
				for (const g of queryfilters.gender) searchParams.append("gender[]", g)
			}
			if (queryfilters.pec != null) {
				console.log("PEC Filters", queryfilters.pec)
				queryfilters.pec.forEach((s) => searchParams.append("coverageDemands.state[]", s))
			}

			if (quickFilterData.fileCompleted != null || queryfilters.fileCompleted != null)
				searchParams.append(
					"isPatientFolderComplete",
					quickFilterData.fileCompleted ?? queryfilters.fileCompleted
				)

			if (queryfilters.nextScheduleStatus != null) {
				for (const nextStatus of queryfilters.nextScheduleStatus) {
					searchParams.append("nextScheduleStatus[]", nextStatus)
				}
			}

			if (queryfilters.nextScheduleDateOf?.length > 0) {
				searchParams.append("nextScheduleDateOf[after]", queryfilters.nextScheduleDateOf[0])
				searchParams.append("nextScheduleDateOf[before]", queryfilters.nextScheduleDateOf[1])
			}

			if (queryfilters.lastScheduleStatus != null) {
				for (const lastStatus of queryfilters.lastScheduleStatus) {
					searchParams.append("lastScheduleStatus[]", lastStatus)
				}
			}

			if (queryfilters.lastScheduleDateOf?.length > 0) {
				searchParams.append("lastScheduleDateOf[after]", queryfilters.lastScheduleDateOf[0])
				searchParams.append("lastScheduleDateOf[before]", queryfilters.lastScheduleDateOf[1])
			}

			if (queryfilters.secondaryStatus != null) {
				let tmp = queryfilters.secondaryStatus
				const index = tmp.indexOf("aucun")
				if (index !== -1) {
					tmp[index] = "null"
				}
				searchParams.append("patientTags[]", tmp)
			}

			if (queryfilters.laboratory != null) {
				for (const laboratoryId of queryfilters.laboratory) searchParams.append("laboratory[]", laboratoryId)
			}

			const tmp2 = await API.findAll("PATIENTS_FULL_API", `?${searchParams}`, true, { signal })

			const newData = tmp2["hydra:member"].reduce((result, patient) => {
				if (!patient) return result
				const fileCompleted = patient.missingsInformationsOnPatientFolder.map((item, i, { length }) =>
					i + 1 !== length ? item + " \n\r" : item
				)

				if (isApt) {
					patient.nextSchedule = SpecialSPFilter(
						patient.nextSchedule,
						patient.schedules,
						patient.missingsInformationsOnPatientFolder.length,
						scheduleTypes,
						scheduleRelanceList ||
							Object.entries(scheduleTypes)
								.filter((data) => data[1].isRelance)
								.map((v) => v[1].label)
								.filter((data) => data.isRelance)
					)
				}

				const lastEquipmentDatePurchase = patient.patientEquipments?.sort(
					(a, b) => new Date(b.datePurchase) - new Date(a.datePurchase)
				)[0]?.datePurchase

				result.push({
					"patient.id": patient.id,
					key: patient.id + 1,
					"patient.gender": patient.gender,
					"patient.isDeceased": patient.dead,
					"patient.lastEquipmentDatePurchase": lastEquipmentDatePurchase,
					"patient.lastName": patient.lastName,
					"patient.firstName": patient.firstName,
					"patient.phone": patient.phone,
					"patient.phoneMobile": patient.phoneMobile,
					"patient.laboratory": patient.laboratory.id,
					"patient.secondaryStatus": patient.patientTags.map((a) => a.text),
					"patient.fileCompleted": {
						status: patient.missingsInformationsOnPatientFolder.length === 0,
						data: fileCompleted,
					},
					"patient.source": patient.patientSource?.name ?? "",
					"patient.origin": patient.origin ?? null,
					"patient.originDetail": patient.patientOriginDetail ?? null,
					// Last schedule: status and date
					"patient.lastScheduleStatus": patient.lastSchedule?.status ?? null,
					"patient.lastScheduleDateOf": patient.lastSchedule?.dateOf ?? null,
					// Next schedule: status and date
					"patient.nextSchedule": patient.nextSchedule ?? "",
					"schedule.nextDateOf": patient.nextSchedule?.dateOf ?? "",
					// patientStatus
					"patient.DStatus":
						dossierType[
							patient?.nextSchedule && scheduleTypes
								? IsRelanceRDV(
										getScheduleLabel(scheduleTypes, patient.nextSchedule.status, ""),
										scheduleRelanceList
								  )
									? "RELANCE"
									: getScheduleLabel(scheduleTypes, patient.nextSchedule.status, "")
								: ""
						],
					"patient.patientInsurances": patient.patientInsurances ?? [],
					"patient.selectedPatientInsurances":
						patient.patientInsurances.length > 0
							? patient.patientInsurances[patient?.patientInsurances.length - 1].coverDateInquiry
							: null,
					"patient.pec": sortBy(patient.coverageDemands, ["id"]).reverse().shift()?.state,
					"patient.dpec": sortBy(patient.coverageDemands, ["id"]).reverse().shift()?.createdAt,
					"user.lastName": patient.mainUser?.lastName ?? "",
					"user.firstName": patient.mainUser?.firstName ?? "",
					"user.id": patient.mainUser?.["@id"] ?? "",
					"patient.mainOrl": patient.mainOrl ?? null,
					"patient.lastPrescriber": patient.lastPrescriber ?? null,
				})
				return result
			}, [])
			if (currentRecord && isAddingLead) {
				const formData = form.getFieldsValue()
				currentRecord["patient.lastName"] = formData["patient.lastName"]
				currentRecord["patient.firstName"] = formData["patient.firstName"]
				currentRecord["patient.phone"] = formData["patient.phone"]
				setIsAddingLead(true)
				edit(currentRecord)
				return [currentRecord].concat(newData)
			}
			return { data: newData, nbData: tmp2["hydra:totalItems"] }
		},
		{
			staleTime: Infinity,
		}
	)

	const [scheduleRelanceList, filterSS] = useMemo(() => {
		if (scheduleTypes == null) return [[], []]

		const scheduleRelanceList = Object.entries(scheduleTypes)
			.filter((data) => data[1].isRelance)
			.map((v) => v[1].label)

		const filterSS = Object.values(scheduleTypes).reduce((obj, scheduleType) => {
			obj[scheduleType.label] = scheduleType.patientTags.map((data) => data.text)
			return obj
		}, {})

		return [scheduleRelanceList, filterSS]
	}, [scheduleTypes])

	// Translate a laboratory id to Label
	const userLaboratoryIdToName = useCallback((id) => laboratories.find((l) => l.id === id)?.label, [laboratories])

	// Called when a date is updated, then get the available slots for the current date and schedule type
	const handleDateChange = async (date, dateString, record) => {
		if (!dateString) {
			return
		}
		const payload = {
			/**
			 * Affiliate users do not care about selecting the audioprosthetist,
			 * hence user is set to null so the backend returns the available slots of
			 * all the laboratory’s audioprosthetist.
			 */
			user: isAffiliate ? null : record["user.id"],
			laboratory: "/laboratories/" + record["patient.laboratory"],
			date: dayjs(dateString).format(),
			scheduleStatus: record["patient.nextSchedule"],
		}
		getAvailableSlots(payload)
		handlePatientChange(date, record, "patient.scheduleDate")
	}

	// Called when we change the main user of a patient
	const handleUserChange = (id, record) => {
		try {
			const index = tableData.findIndex((item) => record.key === item.key)
			const value = myTeam.find((x) => x.id === id)
			if (index > -1) {
				const newData = [...tableData]
				newData[index]["user.lastName"] = value.lastName
				newData[index]["user.firstName"] = value.firstName
				newData[index]["user.id"] = "/users/" + value.id
				setTableData(newData)
			}
			if (isAddingLead) {
				handlePatientChange("", record, "patient.scheduleDate")
				handlePatientChange({ start: "", end: "" }, record, "patient.scheduleTime")
			}
			setCurrentRecord(record)
			setSelectedUser(value)
		} catch (error) {
			console.error(error)
		}
	}

	// Generic method to update a specific patient value, using the value, the current row and the index at which the value is supposed to be updated
	const handlePatientChange = useCallback(
		(value, record, indexName) => {
			try {
				const index = tableData.findIndex((item) => record.key === item.key)
				if (index > -1) {
					// handlePatientChange might be called multiple times in a row, so we have to update the old objects too to avoid having some properties be overriden
					tableData[index][indexName] = value
					tableData[index] = { ...tableData[index] }

					setTableData([...tableData])
					setCurrentRecord(tableData[index])
				}
				//* seems to improve perf a lot leaving the previous one just in case *//
			} catch (error) {
				console.error(error)
			}
		},
		[tableData, currentRecord]
	)

	// used to canceld the editing / creation of a row
	const cancel = (key) => {
		setEditingKey("")
		if (isAddingLead) {
			const newData = [...tableData]
			const index = newData.findIndex((item) => key === item.key)
			newData.splice(index, 1)
			setTableData(newData)
			setIsAddingLead(false)
		}
		setCurrentRecord(null)
	}

	// used when we start edditing a row
	const edit = (record) => {
		setEditingKey(record.key)
		form.setFieldsValue({
			lastName: "",
			firstName: "",
			...record,
		})
		/**
		 * Do not hydrage originDetailsInitial if the user is affiliate:
		 * affiliate users should only have access to origin details returned by
		 * GET /patient_origin_details
		 */
		if (!isAffiliate && record["patient.origin"]) {
			const originOfThePatient = originsInitial.find(
				(origin) => origin["@id"] === record["patient.origin"]["@id"]
			)
			setOriginDetailsInitial(originOfThePatient?.patientOriginDetails ?? [])
		} else {
			setOriginDetailsInitial([])
		}
	}

	// used to find if all the row are correctly filed before sending the data
	const validateFormFields = (fields, row) => {
		fields["patient.lastName"] = row["patient.lastName"]
		fields["patient.firstName"] = row["patient.firstName"]
		fields["patient.phone"] = row["patient.phone"]
		for (const item of dashBoardRows) {
			if (item?.isRequired && !item?.nonFillableComponent && availableCols[item.key]?.validate != null) {
				const res = availableCols[item.key].validate(fields)
				if (res !== "") {
					toast.error(res)
					return false
				}
			}
		}
		return true
	}

	// To create PatientSource, Origin and PatientOriginDetail entities
	const { isLoading: isEntityMutating, mutateAsync: mutateEntityAsync } = useMutation(
		/**
		 * @param {{route: string; payload: Object}} variables
		 */
		async (variables) => {
			try {
				return await API.create(variables.route, variables.payload)
			} catch (error) {
				console.error(error)
			}
		}
	)

	const { isLoading: isScheduleMutating, mutate: mutateSchedule } = useMutation(
		/**
		 * @param {{
		 *   dateEnd: any
		 *   dateOf: any
		 *   laboratory: string
		 *   preScheduleNote: any
		 *   state: string
		 *   status: string
		 *   user: string
		 *   patient: string
		 * }} schedule
		 */
		async (schedule) => {
			try {
				return await API.create("SCHEDULES_API", schedule)
			} catch (error) {
				console.error(error)
			}
		}
	)

	const { isLoading: isPatientAndDependanciesMutating, mutateAsync: mutatePatientAndDependances } = useMutation(
		/**
		 * @param {{patient: Object; patientInsurances: ?Object; coverDateInquiry: ?Object}} variables
		 */
		async (variables) => {
			let response
			if (variables.type === "create") {
				response = await API.create("PATIENTS_API", variables.patient)
			} else if (variables.type === "update") {
				response = await API.update("PATIENTS_API", variables.patient.id, variables.patient)
			}

			let defaultUserInsurance = await API.find("USER_INSURANCES_API", "?amcId=0")
			let defaultUserInsuranceIri = defaultUserInsurance?.["hydra:member"]?.[0]?.["@id"]

			if (defaultUserInsuranceIri && variables.patientInsurances) {
				defaultUserInsurance = await API.create("USER_INSURANCES_API", variables.patientInsurances)
				defaultUserInsuranceIri = defaultUserInsurance?.data?.["@id"]
			}

			if (variables.type === "create") {
				await createPatientInsurance(variables.patientInsurances, response.data["@id"], defaultUserInsuranceIri)
			}

			if (
				variables.type === "update" &&
				response.data.patientInsurances.length === 0 &&
				variables.coverDateInquiry &&
				variables.coverDateInquiry !== ""
			) {
				const payload = {
					amcId: "0",
					label: "Non renseigné",
					isTP: true,
					isC2S: false,
					isMutnum: false,
					isRNM: false,
					startDate: null,
					endDate: null,
					coverDate: null,
					coverDateInquiry: formatDateForDB(variables.coverDateInquiry),
					classOneCover: null,
					classTwoCover: null,
					patient: response.data["@id"],
					userInsurance: defaultUserInsuranceIri,
				}
				await API.create("PATIENT_INSURANCE_API", payload)
			}

			return response
		},
		{
			onError: (err, variables, previousValue) => {
				queryClient.setQueryData(variables.route, previousValue)
				toast.error(
					"Impossible de mettre à jour le tableau, une donnée est incorrecte. Erreur : " +
						err.response.data["hydra:description"]
				)
			},
			onSuccess: async (response, variables) => {
				if (variables.type === "create") {
					toast.success(
						`Le patient ${response.data.firstName} ${response.data.lastName.toUpperCase()} a été créé`
					)
				} else if (variables.type === "update") {
					toast.success(
						`Le patient ${response.data.firstName} ${response.data.lastName.toUpperCase()} a été mis à jour`
					)
				}

				await Promise.all([
					queryClient.invalidateQueries("PATIENT_INSURANCE_API"),
					queryClient.invalidateQueries("SCHEDULES_API"),
					queryClient.invalidateQueries(variables.route),
				])
			},
		}
	)

	//add a tag to a patient
	const saveTags = (tagsList) => {
		const tags = []
		if (tagsList)
			for (const item of tagsList) {
				for (const data of allTags) if (item === data.text) tags.push(data["@id"])
			}
		return tags
	}

	//add and create a new source for a patient
	const saveSource = async () => {
		const sourceComplete = sourceDInitial
		if (sourceD.length !== 0) {
			for (const item of sourceD) {
				const response = await mutateEntityAsync("PATIENT_SOURCES_API", {
					name: item.name,
				})
				sourceComplete.push(response.data)
			}
			setSourceD([])
		}
		return sourceComplete
	}

	//add and create a new origin for a patient
	const saveOrigin = async () => {
		const originNew = originsInitial
		if (origin.length !== 0) {
			for (const item of origin) {
				const response = await mutateEntityAsync("ORIGINS_API", {
					name: item.name,
					originDetails: [],
				})
				originNew.push(response.data)
			}
			setOrigin([])
		}
		return originNew
	}

	//add and create a new origin details for a patient
	const saveOriginDetails = async (origin) => {
		if (originDetails.length !== 0) {
			for (const originDetail of originDetails) {
				// TODO: on est dans une fonction async => donc autant appeler directement API.create non? ou bien a-t-on besoin du isLoading?
				const payload = {
					name: originDetail.name,
					origin: origin["@id"],
					prescriberReferer: originDetail.prescriberReferer ?? null,
					patientReferer: originDetail.patientReferer ?? null,
				}
				const response = await mutateEntityAsync({
					route: "PATIENTS_ORIGIN_DETAILS_API",
					payload: payload,
				})
				originDetailsInitial.push(response.data)
			}
			setOriginDetails([])
			queryClient.invalidateQueries("ORIGINS_API")
		}
		return originDetailsInitial
	}

	const createPatientAndDependances = async (patient, data) => {
		data["patient.patientInsurances"]["coverDateInquiry"] = data["patient.selectedPatientInsurances"]

		const response = await mutatePatientAndDependances({
			type: "create",
			patient: patient,
			patientInsurances: data["patient.patientInsurances"],
		})

		const schedule = {
			dateEnd: data["patient.scheduleTime"].end,
			dateOf: data["patient.scheduleTime"].start,
			laboratory: "/laboratories/" + data["patient.laboratory"],
			preScheduleNote: null,
			state: "WAITING",
			status: data["patient.nextSchedule"],
			type: "CLASSIQUE",
			/**
			 * Affiliated users do not directly select the patient’s user (they do not have the user column),
			 * instead, the patient’s user is selected when selecting the slot.
			 */
			user: isAffiliate ? "/users/" + data["patient.scheduleTime"].userId : data["user.id"],
			patient: response.data["@id"],
		}

		if (
			schedule.dateEnd !== "" &&
			schedule.dateOf !== "" &&
			schedule.status !== "" &&
			schedule.laboratory !== "" &&
			schedule.user !== ""
		) {
			mutateSchedule(schedule)
		}
	}

	const updatePatientAndDependances = async (patient, newData, index, row) => {
		const item = newData[index]
		newData.splice(index, 1, { ...item, ...row })
		patient.id = item["patient.id"]
		if (
			item["patient.patientInsurances"] &&
			item["patient.patientInsurances"].length > 0 &&
			item["patient.selectedPatientInsurances"] &&
			item["patient.selectedPatientInsurances"] !== ""
		)
			await API.update(
				"PATIENT_INSURANCE_API",
				item["patient.patientInsurances"][item["patient.patientInsurances"].length - 1]["@id"].replace(
					"/patient_insurances/",
					""
				),
				{
					coverDateInquiry: formatDateForDB(item["patient.selectedPatientInsurances"]),
				}
			)
		await mutatePatientAndDependances({
			type: "update",
			patient: patient,
			coverDateInquiry: item["patient.selectedPatientInsurances"],
		})
	}

	// called when we want to save a change or add a new row to the data
	const save = async (key) => {
		try {
			setLoading(true)
			const row = await form.validateFields()
			const newData = [...tableData]
			const index = newData.findIndex((item) => key === item.key)

			if (!validateFormFields(newData[index], row)) {
				setLoading(false)
				return
			}
			const tags = saveTags(newData[index]["patient.secondaryStatus"])
			let sourceC = [],
				originNew = []
			if (!isAffiliate && !isAffiliateManager) {
				sourceC = await saveSource()
				originNew = await saveOrigin()
			}

			const origin = originsInitial.find((o) => o.name === newData[index]["patient.origin"]?.name) // need to refind from name, because it might have been POSTed
			const originDetailC = await saveOriginDetails(origin)

			/**
			 * Special logic for special origins
			 * @type {string} patientOriginDetail IRI: /patient_origin_details/{id}
			 */
			const patientOriginDetail = await (async () => {
				if (newData[index]["patient.originDetail"] == null) return null

				switch (origin.special) {
					case "PATIENT_REFERER": {
						// find already existing origin details with this patient
						let originDetails = (
							await API.findAll(
								"PATIENTS_ORIGIN_DETAILS_API",
								`?patientReferer=${newData[index]["patient.originDetail"].patientReferer["@id"]}`
							)
						)[0]
						// create if it doesn't already exists
						if (originDetails == null) {
							originDetails = (
								await API.create("PATIENTS_ORIGIN_DETAILS_API", {
									origin: origin["@id"],
									patientReferer: newData[index]["patient.originDetail"].patientReferer["@id"],
								})
							).data
						}

						return originDetails["@id"]
					}

					case "PRESCRIBER_ORL_REFERER":
					case "PRESCRIBER_DOCTOR_REFERER": {
						// find already existing origin details with this prescriber
						let originDetails = (
							await API.findAll(
								"PATIENTS_ORIGIN_DETAILS_API",
								`?prescriberReferer=${newData[index]["patient.originDetail"].prescriberReferer["@id"]}`
							)
						)[0]
						// create if it doesn't already exists
						if (originDetails == null) {
							originDetails = (
								await API.create("PATIENTS_ORIGIN_DETAILS_API", {
									origin: origin["@id"],
									prescriberReferer: newData[index]["patient.originDetail"].prescriberReferer["@id"],
								})
							).data
						}

						return originDetails["@id"]
					}

					default:
						return originDetailC.find((x) => x.name === newData[index]["patient.originDetail"]?.name)?.["@id"] // prettier-ignore
				}
			})()

			const patient = {
				firstName: row["patient.firstName"],
				lastName: row["patient.lastName"],
				gender: newData[index]["patient.gender"],
				isDeceased: row["patient.dead"],
				phone: row["patient.phone"] || null, // "" -> null
				phoneMobile: row["patient.phoneMobile"] || null, // "" -> null
				laboratory: "/laboratories/" + newData[index]["patient.laboratory"],
				patientTags: tags,
				patientSource: sourceC.find((x) => x.name === newData[index]["patient.source"])?.["@id"],
				origin: originNew.find((x) => x.name === newData[index]["patient.origin"]?.name)?.["@id"],
				// If the chosen originDetail is a new one, "@id" does not exist, so fallback on the IRI returned from its creation
				patientOriginDetail: newData[index]?.["patient.originDetail"]?.["@id"] ?? patientOriginDetail,
				mainUser: newData[index]["user.id"] || null, // mainUser should be either a valid IRI or null, but never an empty string
				mainOrl: newData[index]["mainOrl.id"] || null,
				lastPrescriber: newData[index]["lastPrescriber.id"] || null,
			}

			if (!isAddingLead) {
				updatePatientAndDependances(patient, newData, index, row)
				setEditingKey("")
			} else {
				await createPatientAndDependances(patient, newData[index])
				// remove edit patient. it will be refetched
				newData.splice(index, 1)
				setTableData(newData)

				setEditingKey("")
				setIsAddingLead(false)
			}
			setCurrentRecord(null)
		} catch (err) {
			console.error(err)
			toast.error("Erreur lors de la sauvegarde du nouveau patient")
		} finally {
			setLoading(false)
		}
	}

	// Called when a quick filter is updated it takes an array of filters to apply in params and the state to know if we apply or remove the filters
	const quickFilter = (filterData, state) => {
		const tmp = {
			nextScheduleStatus: null,
			fileCompleted: null,
		}

		if (!state && filterData) tmp[filterData.label] = filterData.value
		setPage(1)
		setQuickFilterData(tmp)
		setQueryFilters({ ...queryfilters, nextScheduleStatus: tmp.nextScheduleStatus ?? [] })
	}

	// Called by antd when an element changed in the table
	const handleTableChange = useCallback(
		(pagination, filters, sorter) => {
			setPage(pagination.current)

			// only apply filter/sort when not adding or editing
			if (!isAddingLead && editingKey === "") {
				if (filters) setQueryFilters(filters)
				if (sorter) setSort(sorter)
			}
		},
		[isAddingLead, editingKey]
	)
	//all the available dashboard colunm, will be used to generate the user dashboard using the settings the manager selected if you want to add one please use the existing ones as a model
	//all the function here basically return an antd column object so you may use everything from antd. You also need to add a "isRequierd" condition which return a bool if the current cell data is correct when the user want to validate the form
	const availableCols = useMemo(
		() => ({
			patientLaboratory: LaboratoryCol(
				userLaboratoryIdToName,
				handlePatientChange,
				editingKey,
				isAddingLead,
				laboratories,
				queryfilters,
				isManager,
				laboratory,
				isAffiliate || isAffiliateManager
			),
			patientLastname: LastNameCol(editingKey, isAddingLead, sort),
			patientFirstname: FirstNameCol(editingKey, isAddingLead, sort),
			patientIsDead: DeceasedCol(queryfilters),
			patientGender: GenderCol(handlePatientChange, editingKey),
			patientUser: MainUserCol(myTeam, editingKey, handleUserChange, queryfilters),
			patientOrl: OrlCol(queryfilters, prescribers),
			patientLastPrescriber: LastPrescriberCol(queryfilters, prescribers),
			lastScheduleStartDate: LastScheduleDateCol(editingKey, isAddingLead, queryfilters, sort),
			lastScheduleStatus: LastScheduleStatusCol(queryfilters, quickFilterData, scheduleTypes),
			patientNextSchedule: NextScheduleDateCol(
				() => setShowModalAgenda(true),
				isAvailableSlotsMutating,
				handlePatientChange,
				editingKey,
				isAddingLead,
				handleDateChange,
				availableSlots,
				queryfilters,
				sort,
				isAffiliate
			),
			patientMainStatus: NextScheduleStatusCol(
				handlePatientChange,
				editingKey,
				isAddingLead,
				queryfilters,
				quickFilterData,
				scheduleTypes
			),
			patientSecondaryStatus: SecondaryStatusCol(
				isApt,
				scheduleTypes,
				handlePatientChange,
				editingKey,
				allTags,
				filterSS,
				queryfilters
			),
			patientSource: SourceCol(
				handlePatientChange,
				editingKey,
				sourceD,
				setSourceD,
				queryfilters,
				isManager,
				sourceDInitial
			),
			patientOrigin: OriginCol(
				originsInitial,
				handlePatientChange,
				editingKey,
				setOriginDetailsInitial,
				setOriginDetails,
				setOrigin,
				queryfilters,
				isManager,
				origin
			),
			patientOriginDetail: OriginDetailCol(
				originDetailsInitial,
				handlePatientChange,
				editingKey,
				originDetails,
				originDetailsFromRoute,
				queryfilters,
				isManager,
				isAffiliate,
				isAffiliateManager,
				setOriginDetails
			),
			patientScheduleStatus: StatusDCol(),
			patientFileCompleted: FileCompletedCol(queryfilters),
			patientPhone: PhoneCol(editingKey),
			patientMobilePhone: PhoneMobileCol(editingKey),
			patientDPEC: DPECCol(queryfilters, sort),
			patientPEC: PECCol(queryfilters),
			patientLastEquipmentDatePurchase: DernierAppareillage(sort),
			//	patientNotes: NotesCol(), TODO: use new bloc notes
		}),
		[
			handlePatientChange,
			editingKey,
			isAddingLead,
			laboratories,
			isManager,
			laboratory,
			availableSlots,
			scheduleTypes,
			sourceD,
			sourceDInitial,
			origin,
			originsInitial,
			originDetails,
			originDetailsInitial,
			isAvailableSlotsMutating,
			queryfilters,
			quickFilterData,
			sort,
			originDetailsFromRoute,
			filterSS,
		]
	)

	//generate the table using the list set by the manager in the dashboard settings
	const generateTableCol = (list, availableList) => {
		if (list == null) return []
		const table = list.reduce((arr, item) => {
			if (availableList[item.key] != null) {
				availableList[item.key].title = item.label // set the column title with the one from backend /company_settings/me
				arr.push(availableList[item.key])
			}
			return arr
		}, [])

		table.push(
			ActionControlerCol(cancel, isEditing, editingKey, isAddingLead, edit, setNoteKey, setCurrentRecord, save)
		)
		return table
	}

	//merge all the columns and add event for edit
	const mergedColumns = useMemo(
		() =>
			generateTableCol(dashBoardRows, availableCols).map((col) => {
				col.filteredValue = queryfilters[col.key] || null // null évite une alerte dans la console

				if (!col.editable) return col
				return {
					...col,
					onCell: (record) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						editing: isEditing(record),
					}),
				}
			}),
		[dashBoardRows, availableCols, queryfilters]
	)

	useEffect(() => {
		setCurrentRecord(null)
		setIsAddingLead(false)
	}, [])

	useEffect(() => {
		if (!companySettings?.patientsDashboardSetting) return
		const res = Object.entries(companySettings.patientsDashboardSetting)
			.reduce((result, data) => {
				if (data[0] === "@id" || data[0] === "@type" || data[0] === "id" || data[1] == null) return result

				if (isAffiliate && data[0] === "patientOriginDetail") {
					data[1].isVisible = true
					data[1].isRequired = true
				}
				data[1]["key"] = data[0]
				result.push(data[1])
				return result
			}, [])
			.sort(function (a, b) {
				return a.displayOrder - b.displayOrder
			})
			.filter((item) => item.isVisible)

		setdashBoardRow(res)
	}, [companySettings])

	useEffect(() => {
		if (patientData == null) return
		setTableData(patientData.data)
		setPatientsCount(patientData.nbData)
	}, [patientData])

	useEffect(() => {
		setLoading(false)
	}, [tableData])

	useEffect(() => {
		if (!isAddingLead) return

		setPage(1)
	}, [isAddingLead])

	useEffect(() => {
		if (!uiDisplay.scheduleModal) queryClient.invalidateQueries("PATIENTS_API")
	}, [uiDisplay.scheduleModal])

	useEffect(() => {
		if (!isAddingLead || !evtData) return

		const index = tableData.findIndex((item) => currentRecord.key === item.key)
		if (index === -1) return

		const newData = [...tableData]
		newData[index]["patient.scheduleTime"] = evtData
		newData[index]["patient.scheduleDate"] = dayjs(evtData.start)
		setTableData(newData)
	}, [evtData])

	return (
		<Card
			customHeader={
				<TableFilters
					setCurrentRecord={setCurrentRecord}
					setTableData={setTableData}
					setIsAddingLead={setIsAddingLead}
					edit={edit}
					tableData={tableData}
					scheduleTypes={scheduleTypes}
					scheduleRelanceList={scheduleRelanceList}
					patientsCount={patientsCount}
					quickFilter={quickFilter}
					queryfilters={queryfilters}
					resetQueryFiltersAndSort={() => {
						setQueryFilters({
							laboratory: [laboratory.id],
							nextScheduleStatus: null,
							lastScheduleStatus: null,
							source: null,
							origin: null,
							originDetails: null,
							fileCompleted: null,
							pec: null,
							gender: null,
						})
						setSort(null)
					}}
					isAddingLead={isAddingLead}
					setName={setName}
					tableSettingsVisible={tableSettingsVisible}
					setTableSettingsVisible={setTableSettingsVisible}
					originsInitial={originsInitial}
				/>
			}>
			{tableSettingsVisible ? (
				<DashBoardSettings style={{ visibility: !tableSettingsVisible ? "hidden" : "visible" }} />
			) : (
				<Form form={form} component={false}>
					<Table
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						bordered
						dataSource={tableData}
						columns={mergedColumns}
						rowClassName="dashboard-row editable-row"
						loading={
							loading ||
							isLoading ||
							isEntityMutating ||
							isScheduleMutating ||
							isPatientAndDependanciesMutating ||
							isFetching ||
							isLoadingLaboratories ||
							fetchingScheduleTypes ||
							isLoadingSchedulesGroups ||
							isLoadingTags
						}
						className="dashboard-table"
						onChange={(pagination, filters, sorter, extra) =>
							handleTableChange(pagination, filters, sorter, extra)
						}
						scroll={{ x: "max-content" }}
						size="default"
						pagination={{
							position: isAddingLead ? [] : ["bottomLeft"],
							//pageSize: isAddingLead ? 11 : 10, // display 10 patient, plus possibly the one currently being created
							pageSize: 10,
							showSizeChanger: false,
							current: page,
							total: patientsCount,
						}}
					/>
				</Form>
			)}
			<div>
				{isNoteVisible(currentRecord) && (
					<TableauDeBordBlocNotes
						record={currentRecord}
						setNoteKey={setNoteKey}
						tableData={tableData}
						setTableData={setTableData}
						test={scheduleTypes}
					/>
				)}
				{showModalAgenda && (
					<AgendaModal
						onlyAvailable={true}
						setSelectedDate={(data) =>
							setDates(
								data.map((date) => dayjs(date)),
								setEvtData
							)
						}
						fromDashboard={true}
						setShow={() => setShowModalAgenda(false)}
						setSelectedLaboratory={setSelectedLaboratory}
						defaultStartDate={
							(currentRecord["patient.scheduleDate"] &&
								currentRecord["patient.scheduleDate"]?.toDate()) ||
							new Date()
						}
						setSelectedUser={setSelectedUser}
						appointmentFromScheduleSelector={{
							startDate: currentRecord["patient.scheduleDate"]
								? currentRecord["patient.scheduleDate"].toDate()
								: new Date(),
							laboratory: currentRecord["patient.laboratory"],
							user: selectedUser?.id || user.id,
							type: currentRecord["patient.nextSchedule"],
						}}
					/>
				)}
			</div>
		</Card>
	)
}
