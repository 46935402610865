export const StatsRoutePrefix = "/statistiques"

export const StatsRoutes = {
	Metiers: `${StatsRoutePrefix}/metiers`,
	Financiers: `${StatsRoutePrefix}/financiers`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(StatsRoutePrefix, "")
}
