// @ts-ignore
import { HiboutikBrand, HiboutikCategory, HiboutikProduct } from "@audiowizard/common"
import _ from "lodash"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import API_Hiboutik from "services/API_Hiboutik"
import { partialSearch } from "services/functions"
import ObjectSelect, { ObjectSelectProps } from "./ObjectSelect"

async function fetchCategoriesById(): Promise<Record<number, HiboutikCategory>> {
	const categories: HiboutikCategory[] = await API_Hiboutik.getAllCategories()
	return _.keyBy(categories, "category_id")
}
async function fetchBrandsById(): Promise<Record<number, HiboutikBrand>> {
	const brands: HiboutikBrand[] = await API_Hiboutik.getBrands()
	return _.keyBy(brands, "brand_id")
}

export type HiboutikCategoryName =
	| "Appareils auditifs"
	| "Accessoire"
	| "Accessoires implants"
	| "Piles"
	| "Ecouteur"
	| "Embout rembourse"
	| "Entretien"
	| "Filtres"
	| "Domes"
	| "Autres"
	| "Protection et prevention"
	| "Micro-tubes"
	| "Assurance"
	| "Service"
	| "Implant"
	| "Reparation"
	| "Piles implants"
	| "Batteries implant"
	| "Ecouteur avec SN"
	| "Embout avec SN"
	| "Ancrage osseux"
	| "Ancrage osseux avec SN"
	| "Protection et prevention avec SN"
	| "Garantie"
	| "Cros"
type HiboutikProductSelectValue = {
	id: number
	model: string
	price: number
	categoryId: number
	categoryName: string
	brandId: number
	brandName: string
}

type HiboutikProductSelectProps = Omit<
	ObjectSelectProps<HiboutikProductSelectValue>,
	"optionToLabel" | "optionToKey" | "options"
> & {
	categories?: HiboutikCategoryName[]
}

export default function HiboutikProductSelect({ categories, ...props }: HiboutikProductSelectProps): JSX.Element {
	const { data: products, isLoading } = useQuery<HiboutikProductSelectValue[]>(
		["HIBOUTIK", "HiboutikProductSelect.products"],
		async () => {
			const [hiboutikProducts, categoriesById, brandsById]: [
				HiboutikProduct[],
				Record<number, HiboutikCategory>,
				Record<number, HiboutikBrand>
			] = await Promise.all([
				API_Hiboutik.getAllProducts({ stock_management: "ALL" }),
				fetchCategoriesById(),
				fetchBrandsById(),
			])

			return hiboutikProducts.map((p) => ({
				id: p.product_id,
				price: Number(p.product_price),
				model: p.product_model,
				categoryId: p.product_category,
				categoryName: categoriesById![p.product_category]?.category_name ?? "Catégorie Inconnu",
				brandId: p.product_brand,
				brandName: brandsById![p.product_brand]?.brand_name ?? "Marque Inconnu",
			}))
		},
		{
			onError: () => toast.error("Erreur lors du chargement des produits."),
		}
	)

	const filteredProducts = useMemo(() => {
		if (products == null) return []
		if (categories == null) return products // no filtering

		// Filter on category
		// deburr and lowercase to avoid accent issues
		const lowercaseCategories = categories.map((c) => _.deburr(c).toLowerCase())
		return products.filter((p) => lowercaseCategories.includes(_.deburr(p.categoryName).toLowerCase()))
	}, [products, categories])

	return (
		<ObjectSelect<HiboutikProductSelectValue>
			filterOption={(search, option) =>
				partialSearch(option?.["data-product-model"] + option?.["data-product-brand"], search)
			}
			loading={isLoading}
			optionToLabel={(p) => (
				<>
					<strong>{p.brandName}</strong> {p.model}
				</>
			)}
			optionToKey={(p) => p.id}
			optionExtraData={(p) => ({ "data-product-model": p.model, "data-product-brand": p.brandName })}
			options={filteredProducts}
			{...props}
		/>
	)
}
