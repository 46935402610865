import { Select } from "antd"
import { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import API from "services/API"
import { partialSearch } from "services/functions"
import { CustomSelectDropDown } from "../Utils"
import { spaceship } from "utils/stringsUtils"

function OriginDetailSelect({
	record,
	isManager,
	isAffiliate,
	isAffiliateManager,
	originDetailsInitial,
	originDetails,
	originDetailsFromRoute,
	setOriginDetails,
	handlePatientChange,
}) {
	// Merge existing originDetails with the newly added ones (not yet created)
	const allOriginDetails = useMemo(() => {
		/**
		 * Existing originDetails depends on the user role:
		 * - non-affiliate users: retrieved from the origin matchin the patient’s origin
		 * - affiliate users: retrieved from GET /patient_origin_details, with filtering
		 *   the originDetails with name null as (i) this composant is for named originDetails
		 *   and (ii) affiliate users use only named originDetails.
		 */
		const originDetailsExisting = isAffiliate
			? originDetailsFromRoute.filter((originDetail) => originDetail.name != null)
			: originDetailsInitial

		return [...originDetailsExisting, ...originDetails].sort((a, b) =>
			spaceship(a.name.toLowerCase(), b.name.toLowerCase())
		)
	}, [originDetailsInitial, originDetails])

	return (
		<Select
			allowClear
			showSearch
			filterOption={(search, option) => partialSearch(option.label, search)}
			style={{ width: "100%", minWidth: "120px" }}
			placeholder="Sélectionner une sous-origine"
			value={record["patient.originDetail"]?.name}
			dropdownRender={(body) => {
				return (
					<CustomSelectDropDown
						isManager={isManager}
						isAffiliate={isAffiliate}
						isAffiliateManager={isAffiliateManager}
						handleDropDownClick={(name) => {
							// Function called when adding a new originDetail (via the « + » button)
							if (!name) {
								return toast.error("Veuillez renseigner une sous-origine")
							}
							if (!originDetails.some((item) => item.name === name)) {
								setOriginDetails([...originDetails, { name }])
								handlePatientChange({ name, label: name }, record, "patient.originDetail")
							}
						}}>
						{body}
					</CustomSelectDropDown>
				)
			}}
			onChange={(name) => {
				handlePatientChange(
					allOriginDetails.find((o) => o.name === name),
					record,
					"patient.originDetail"
				)
			}}
			options={allOriginDetails
				.sort((a, b) => b.name - a.name)
				.map((o) => ({
					label: o.name,
					value: o.name,
				}))}
		/>
	)
}

function OriginDetailsPatientSelect({ record, handlePatientChange }) {
	const [search, setSearch] = useState("")

	const currentPatientReferer = record["patient.originDetail"]?.patientReferer

	const { data: patients, isLoading } = useQuery(["PATIENTS_API", { omnisearch: search }], async () => {
		const searchParams = new URLSearchParams()
		searchParams.append("order[id]", "desc")

		for (const word of search.trim().split(/\s+/)) {
			searchParams.append("omnisearch[]", word)
		}

		return API.findAll("PATIENTS_API", `?${searchParams}`)
	})

	const patientsWithCurrent = useMemo(() => {
		if (patients == null) return []

		// add current patient if it isn't alrady in patients options
		if (currentPatientReferer != null && !patients.some((p) => p.id === currentPatientReferer.id)) {
			return [currentPatientReferer, ...patients]
		}

		return patients
	}, [patients, currentPatientReferer])

	return (
		<Select
			style={{ width: "100%", minWidth: "120px" }}
			placeholder="Sélectionner un patient"
			loading={isLoading}
			showSearch
			filterOption={(search, option) => partialSearch(option.label, search)}
			searchValue={search}
			onSearch={setSearch}
			value={currentPatientReferer?.["@id"]}
			onChange={(patientRefererIri) => {
				const patientReferer = patientsWithCurrent.find((p) => p["@id"] === patientRefererIri)
				handlePatientChange({ patientReferer }, record, "patient.originDetail")
			}}
			options={patientsWithCurrent.map((p) => ({
				label: `${p.lastName} ${p.firstName}`,
				value: p["@id"],
			}))}
		/>
	)
}

function OriginDetailsPrescriberSelect({ category, record, handlePatientChange }) {
	const { data: prescribers, isLoading } = useQuery(
		["PRESCRIBERS_API", { category, pagination: false, "order[id]": "DESC" }],
		() => API.findAll("PRESCRIBERS_API", `?category=${category}&pagination=false&order[id]=DESC`)
	)

	// can be entity or iri string directly
	const prescriberReferer = record["patient.originDetail"]?.prescriberReferer
	const prescriberRefererIri =
		prescriberReferer != null && typeof prescriberReferer === "object"
			? prescriberReferer["@id"]
			: prescriberReferer

	return (
		<Select
			style={{ width: "100%", minWidth: "120px" }}
			placeholder={`Sélectionner un ${category === "ORL" ? "ORL" : "Médecin Généraliste"}`}
			loading={isLoading}
			showSearch
			filterOption={(search, option) => partialSearch(option.label, search)}
			value={prescriberRefererIri}
			onChange={(prescriberRefererIri) => {
				const prescriberReferer = prescribers.find((p) => p["@id"] === prescriberRefererIri)
				handlePatientChange({ prescriberReferer }, record, "patient.originDetail")
			}}
			options={prescribers?.map((p) => ({
				label: `${p.lastName} ${p.firstName}`,
				value: p["@id"],
			}))}
		/>
	)
}

const OriginDetailCol = (
	originDetailsInitial,
	handlePatientChange,
	editingKey,
	originDetails,
	originDetailsFromRoute,
	queryfilters,
	isManager,
	isAffiliate,
	isAffiliateManager,
	setOriginDetails
) => {
	const originDetailRender = (_, record) => {
		if (editingKey === record.key) {
			const origin = record["patient.origin"]

			if (origin == null) {
				return (
					<p className="m-0" style={{ textAlign: "center" }}>
						Veuillez sélectionner une origine.
					</p>
				)
			}

			if (origin.special === "PATIENT_REFERER") {
				return <OriginDetailsPatientSelect record={record} handlePatientChange={handlePatientChange} />
			}

			if (origin.special === "PRESCRIBER_ORL_REFERER") {
				return (
					<OriginDetailsPrescriberSelect
						category="ORL"
						record={record}
						handlePatientChange={handlePatientChange}
					/>
				)
			}

			if (origin.special === "PRESCRIBER_DOCTOR_REFERER") {
				return <OriginDetailsPrescriberSelect category="DOCTOR" Je sais pas si faut le close />
			}

			return (
				<OriginDetailSelect
					record={record}
					isManager={isManager}
					isAffiliate={isAffiliate}
					isAffiliateManager={isAffiliateManager}
					originDetailsInitial={originDetailsInitial}
					originDetails={originDetails}
					originDetailsFromRoute={originDetailsFromRoute}
					setOriginDetails={setOriginDetails}
					handlePatientChange={handlePatientChange}
				/>
			)
		}

		return <p style={{ textAlign: "center", margin: 0 }}>{record["patient.originDetail"]?.label}</p>
	}
	return {
		title: null,
		key: "originDetail",
		dataIndex: "patient.originDetail",
		with: "100px",
		render: originDetailRender,
		filters: originDetailsFromRoute?.map((origin) => ({
			text: origin.label,
			value: origin.id,
		})),
		filteredValue: queryfilters?.originDetail || null,
		filterSearch: true,
		validate: (cond) =>
			!cond["patient.originDetail"] || cond["patient.originDetail"] === ""
				? "Vous n'avez pas renseigné de sous-origine."
				: "",
	}
}
export default OriginDetailCol
