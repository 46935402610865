import { Laboratory, Transfer } from "@audiowizard/common"
import { Table } from "antd"
import useCustomTitle from "components/Hooks/useTitle"
import ModalFileViewer, { ModalFileViewerProps } from "components/ModalFileViewer/Modal.FileViewer"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useContext, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import uuid from "react-uuid"
import API from "services/API"
import { colorFromStr, haveRole } from "services/functions"
import DeleteLogisticForm from "../Shared/DeleteLogisticFormButton"
import ExportCsvButton from "./ExportCsv"
import Filters, { FiltersType } from "./Filters"
import ModalCreateNewTransfer from "./Modal.CreateNew"
import ModalExtractRetrocessionInvoices from "./Modal.DownloadAllRetrocessionInvoices"

import axios from "axios"
import ButtonRounded from "components/Buttons/ButtonRounded"
import useHasRole from "components/Hooks/useHasRole"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { API_URL } from "config"
import { Alert } from "reactstrap"
import "./style.scss"

const TransferList = (): JSX.Element => {
	useCustomTitle("Stock | Transferts")

	const { user } = useContext(AuthContext)

	const isManager = useHasRole("ROLE_MANAGER")
	const isManagerFranchised = useHasRole("ROLE_MANAGER_FRANCHISED")
	const isAssistantAuthorized = useHasRole("ROLE_ASSISTANT") && user.company?.label?.includes("MANEO")

	const hasDownloadAuthorization = isManager || isManagerFranchised || isAssistantAuthorized

	const queryClient = useQueryClient()

	const [page, setPage] = useState<number>(1)
	const [numberOfInventory, setNumberOfInventory] = useState<number>(0)
	const history = useHistory()
	const [createModal, setCreateModal] = useState<boolean>(false)
	const [modalExtractRetrocession, setModalExtractRetrocession] = useState<boolean>(false)
	const [modalFileViewer, setModalFileViewer] = useState<ModalFileViewerProps>({
		isOpen: false,
		title: "Document",
		signature: null,
	})

	const [filters, setFilters] = useState<FiltersType>({
		senderLaboratory: null,
		recipientLaboratory: null,
		generateInvoice: null,
	})

	const { data: inventories, isLoading } = useQuery(
		["TRANSFERS_LIST", page, filters.senderLaboratory, filters.recipientLaboratory, filters.generateInvoice],
		async () => {
			const apiFilters = []

			if (filters.senderLaboratory) apiFilters.push(`senderLaboratory=${filters.senderLaboratory?.id}`)
			if (filters.recipientLaboratory) apiFilters.push(`recipientLaboratory=${filters.recipientLaboratory?.id}`)
			if (filters.generateInvoice != null) apiFilters.push(`generateInvoice=${filters.generateInvoice ? 1 : 0}`)

			const result = await API.findAll(
				"TRANSFER_API",
				`?page=${page}&order[createdAt]=DESC&${apiFilters.join("&")}`,
				true
			)

			setNumberOfInventory(result?.["hydra:totalItems"])

			return (result?.["hydra:member"] ?? []).map((m: any) => ({ ...m, key: uuid(), inventoryState: "NONE" }))
		},
		{ keepPreviousData: true, cacheTime: 0 }
	)

	const columns = [
		{
			title: "Date",
			dataIndex: "createdAt",
			render: (createdAt: Date) => dayjs(createdAt).format("DD/MM/YY, HH[h]mm"),
			className: "w-15",
		},
		{
			title: "Émetteur",
			dataIndex: "senderLaboratory",
			render: (senderLaboratory: Laboratory) => (
				<span className="laboratory-label">
					<span
						className="pill-laboratory"
						style={{ backgroundColor: colorFromStr(senderLaboratory?.label) }}
					/>

					{senderLaboratory?.label}
				</span>
			),
		},
		{
			title: "Destinataire",
			dataIndex: "recipientLaboratory",
			render: (recipientLaboratory: Laboratory) => (
				<span className="laboratory-label">
					<span
						className="pill-laboratory"
						style={{ backgroundColor: colorFromStr(recipientLaboratory?.label) }}
					/>
					{recipientLaboratory?.label}
				</span>
			),
		},
		{ title: "Nb de produit", dataIndex: "transferredProductCount", align: "center" },
		{
			title: "Rétrocession",
			dataIndex: "generateInvoice",
			align: "center",
			render: (generateInvoice: boolean, row: Transfer) => (
				<>
					{generateInvoice && row.transferRetrocessionInvoice ? (
						<button
							type="button"
							className="btn btn-sm pt-1 pb-1 btn-secondary"
							disabled={!hasDownloadAuthorization || !row.validatedAt}
							onAuxClick={() => {
								axios.put(API_URL + `/transfers/${row.id}/generate_pdf`, {})
							}}
							onClick={async (event) => {
								const target = event.target as HTMLButtonElement
								try {
									target.disabled = true
									const result: any = await API.get(
										row.transferRetrocessionInvoice as unknown as string
									)
									setModalFileViewer({
										signature: result.signature,
										title: `Facture de rétrocession du ${dayjs(result.createdAt).format(
											"DD/MM/YYYY"
										)}`,
										isOpen: true,
									})
								} catch (error) {
									console.error(error)
								} finally {
									target.disabled = false
								}
							}}>
							Voir la facture
						</button>
					) : (
						"Sans"
					)}
				</>
			),
		},
		{
			title: "Date de validation",
			dataIndex: "validatedAt",
			align: "center",
			className: "w-15",
			render: (validatedAt: Date) =>
				validatedAt ? dayjs(validatedAt).format("DD/MM/YY, HH[h]mm") : "En attente",
		},
		{
			title: "-",
			align: "center",
			render: (_: void, transfer: Transfer) => (
				<div className="d-flex ">
					<ExportCsvButton transfer={transfer} />

					<DeleteLogisticForm
						logisticForm={transfer!}
						onFinish={() => queryClient.invalidateQueries("TRANSFERS_LIST")}
					/>
				</div>
			),
		},
	]

	// APT Only Logic
	const canCreateTransfer = useMemo(() => {
		if (!user) return false

		const isCompanyApt = user.company?.id === 46
		const hasSiege = user.laboratories?.find((l) => l.id === 57)
		const isManagerFranchised = haveRole(user, "ROLE_MANAGER_FRANCHISED")

		if (!isCompanyApt) return true

		return isManagerFranchised || (isCompanyApt && hasSiege)
	}, [user])

	return (
		<>
			<SectionHeader title="Filtres" />
			<Filters setFilters={setFilters} filters={filters} />
			<SectionHeader title="Liste des transferts" />
			<div className=" d-flex justify-content-between">
				{!canCreateTransfer ? (
					<Alert color="warning" style={{ fontSize: "18px" }}>
						Uniquement les managers ou le siège sont autorisés à faire des transferts.
					</Alert>
				) : (
					<ButtonRounded
						icon="fa-plus"
						className="mb-3"
						color="primary-outlined"
						type="button"
						onClick={() => setCreateModal(true)}>
						Nouveau transfert
					</ButtonRounded>
				)}

				{(isManager || isManagerFranchised) && hasDownloadAuthorization && (
					<ButtonRounded
						className="mb-3"
						color="primary-outlined"
						type="button"
						onClick={() => setModalExtractRetrocession(true)}>
						Télécharger toutes les factures de rétrocession
					</ButtonRounded>
				)}
			</div>

			<Table
				dataSource={inventories}
				// @ts-ignore
				columns={columns}
				loading={isLoading}
				pagination={{
					total: numberOfInventory,
					current: page,
					onChange: (page: number) => setPage(page),
				}}
				onRow={(record) => {
					return {
						onClick: (event) => {
							const target = event.target as HTMLElement

							if (target?.tagName?.toLocaleLowerCase() === "a") return
							if (target?.tagName?.toLocaleLowerCase() === "button") return
							if (target?.tagName?.toLocaleLowerCase() === "svg") return
							if (target?.tagName?.toLocaleLowerCase() === "path") return
							history.push("/mon-stock/transfert/" + record.id)
						},
					}
				}}
				rowClassName="cursor-pointer"
			/>
			<ModalCreateNewTransfer isOpen={createModal} setOpen={setCreateModal} />
			<ModalExtractRetrocessionInvoices isOpen={modalExtractRetrocession} setOpen={setModalExtractRetrocession} />
			<ModalFileViewer
				isOpen={modalFileViewer.isOpen}
				signature={modalFileViewer.signature}
				title={modalFileViewer.title}
				onClose={() => {
					setModalFileViewer((old) => ({ ...old, isOpen: false }))
				}}
			/>
		</>
	)
}

export default TransferList
