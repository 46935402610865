// @ts-nocheck
import dayjs, { Dayjs } from "dayjs"
import API from "services/API"
import API_FSV from "./api"
import { amcLabels } from "../etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"
import { nonHandledButPublicAmcs } from "datas/teletrans"
import parser from "xml-js"
import { deburr } from "lodash"
import { toast } from "react-toastify"
import { productionUrl } from "backend-routes"
import { IsObjectEmpty } from "services/functions"

const soapParsed = (soap) => parser.xml2json(soap, { compact: false, spaces: 4 })

export const hasTeletransService = (subscriptions) => {
	const teletrans = subscriptions?.find((subscription) => subscription?.service?.name === "télétransmission")
	console.log({ hasTeletransService: Boolean(teletrans) })
	return true
	// return teletrans ? true : false
}

/**
 * Determines if a cover is valid (not expired or has started)
 *
 * @param {string} start
 * @param {string} end
 */
export const dateFilter = (start: string | Dayjs, end: string | Dayjs): boolean => {
	const today = dayjs()

	if (!start && !end) return true
	else if (start && end) return today.isBetween(dayjs(start).startOf("day"), dayjs(end).endOf("day"), null, "[]")
	else if (!start && end) return today.isSameOrBefore(end)
	else if (start && !end) return today.isSameOrAfter(start)

	return false
}

/**
 * Retrieves all active covers for amo and amc
 *
 * @param {object} patient Object du patient
 * @param {boolean} forceRefresh Boolean pour forcer la récupération via l'API des couvertures
 */

export const findActiveAMx = async (patient, forceRefresh = true) => {
	const active = {
		amo: null,
		amc: null,
	}
	let amos = patient?.patientSocialSecurity || []
	let amcs = patient?.patientInsurances || []

	try {
		if (forceRefresh) {
			amos = await API.findAll("PATIENT_SECU_API", `?patient=${patient.id}`)
			amcs = await API.findAll("PATIENT_INSURANCE_API", `?patient=${patient.id}`)
		}

		active.amo = amos.find((m) => dateFilter(m.startDate, m.endDate)) || null
		active.amc = amcs.find((m) => dateFilter(m.startDate, m.endDate)) || null

		return active
	} catch (error) {
		console.error(error)
		return active
	}
}

/**
 * Finds if AMC is special and requires amount transformations within the app.
 * Examples of special amc are: C2S, MSA, Camieg/Mutieg, MGEN, ...
 *
 * @param {object} amc Object with private cover data
 * @param {object} patient Object with patient data
 */

export const findSpecialAmcRegimen = async (patientAmc = null, patient) => {
	const amISpecial = (am) => {
		const regimen = amcLabels.find((e) => +e.id === +am.amcId)
		if (!regimen) return null
		if (nonHandledButPublicAmcs.includes(am.amcId)) regimen.handled = false
		else regimen.handled = true
		return regimen ?? null
	}

	try {
		if (patientAmc) {
			return amISpecial(patientAmc)
		} else {
			const { amc } = await findActiveAMx(patient)

			return amc ? amISpecial(amc) : null
		}
	} catch (error) {
		console.error(error)
	}
}

/** @returns {import("@audiowizard/common").PatientSocialSecurity|import("@audiowizard/common").PatientInsurance} */
export const findActiveAm = (am, setter?: (any) => void): any => {
	let activeAm = {}
	const ams = Array.isArray(am) ? am : [am]

	for (const am of ams) {
		if (!am) continue

		if (dateFilter(am.startDate, am.endDate)) {
			activeAm = am
		}
	}

	if (setter && typeof setter === "function") {
		setter(IsObjectEmpty(activeAm) ? false : activeAm)
	}

	return activeAm
}

export const findActiveAmFromFSV = (am: any): any => {
	let activeAm: any = {}

	let ams: any[] = []

	if (am?.[0]) {
		ams = Object.values(am)
	} else {
		ams = [am]
	}

	for (const am of ams) {
		if (!am) continue
		if (
			dateFilter(
				(typeof am.dateDeb === "string" && dayjs(am.dateDeb, "YYYYMMDD")) || "",
				(typeof am.dateFin === "string" && dayjs(am.dateFin, "YYYYMMDD")) || ""
			)
		) {
			activeAm = am
		}
	}

	return activeAm
}

const sanitize = (text) => {
	if (!text) return ""
	else
		return deburr(text)
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
}

export const parseCV = (xml) => {
	const beneficiaires = []
	let numeroSecuCommun
	let cleNumeroSecuCommun
	let AmoGrandRegime
	let AmoCaisseGestionnaire
	let AmoCentreGestionnaire
	let codePostalCommun
	let adresseCommune
	// formattage xml
	const parsed = soapParsed(xml)
	// conversion en json
	const json = JSON.parse(parsed)

	const filteredObj = json?.elements[2].elements
	filteredObj &&
		filteredObj.forEach((e) => {
			// RECUPERATION DU NUMERO DE SECU (SI IL EST PARTAGÉ)
			if (e.name === "groupe101") {
				e.elements.forEach((e) => {
					switch (e.name) {
						case "CV_NumeroNIR":
							numeroSecuCommun = e.elements?.[0].text
							break

						case "CV_ClefNIR":
							cleNumeroSecuCommun = e.elements?.[0].text
							break

						case "CV_CodeRegime":
							AmoGrandRegime = e.elements?.[0].text
							break

						case "CV_CaisseGestionnaire":
							AmoCaisseGestionnaire = e.elements?.[0].text
							break

						case "CV_CentreGestionnaire":
							AmoCentreGestionnaire = e.elements?.[0].text
							break

						default:
							break
					}
				})
			}
			// RECUPERATION DES INFORMATIONS DES BENEFICIAIRES
			else if (e.name === "groupe104") {
				const newBeneficiaire = {}
				const couverturesAMO = []
				const mutuelles = []
				const mutuelle = {}
				e.elements.forEach((e, i) => {
					switch (e.name) {
						case "CV_NomUsuelAMO":
							newBeneficiaire.nom = sanitize(e.elements[i].text)
							break
						case "CV_PrenomAMO":
							newBeneficiaire.prenom = sanitize(e.elements?.[0].text)
							break
						case "CV_Adresse3AMO" && e.elements:
							adresseCommune = e.elements?.[0].text
							newBeneficiaire.adresse = sanitize(e.elements?.[0].text)
							break
						case "CV_Adresse3AMO":
							newBeneficiaire.adresse = sanitize(adresseCommune)
							break
						case "CV_Adresse5AMO" && e.elements:
							codePostalCommun = e.elements?.[0].text
							newBeneficiaire.city = sanitize(e.elements?.[0].text)
							break
						case "CV_Adresse5AMO":
							newBeneficiaire.city = codePostalCommun
							break
						case "CV_DateNaissanceAMO":
							newBeneficiaire.dateNaissanceBdd = e.elements?.[0].text
							break
						case "CV_RangNaissanceAMO":
							newBeneficiaire.rangNaissance = e.elements?.[0].text
							break
						case "CV_QualiteAMO":
							newBeneficiaire.qualitePatient = e.elements?.[0].text
							break
						case "CV_CodeServiceAMO":
							newBeneficiaire.codeServiceAMO = e.elements?.[0].text
							break

						case "groupe105":
							newBeneficiaire.dateDebutDroitsAMO = e.elements?.[0]?.elements?.[0]?.text || null
							newBeneficiaire.dateFinDroitsAMO = e.elements[1]?.elements?.[0]?.text || null
							break

						case "groupe106":
							const couverture = {}
							couverture.startDate = e?.elements?.[0]?.elements?.[0]?.text || null
							couverture.endDate = e?.elements[1]?.elements?.[0]?.text || null
							couverture.ALD = e?.elements[2]?.elements?.[0]?.text || null
							couverture.situationCode = e?.elements[3]?.elements?.[0]?.text || null
							couverturesAMO.push(couverture)
							break

						case "groupe109":
							e.elements.forEach((e) => {
								switch (e.name) {
									case "CV_DateDebutValiditePresenceDonneesAMC":
										mutuelle.startDate = e?.elements?.[0]?.text || null
										break
									case "CV_DateFinValiditePresenceDonneesAMC":
										mutuelle.endDate = e?.elements?.[0]?.text || null
										break
									case "CV_NumeroComplementaireB2":
										mutuelle.insuranceId = e?.elements?.[0]?.text || null
										break
									case "CV_NumeroAdherentAMC":
										mutuelle.numeroAdherent = e?.elements?.[0]?.text || null
										break
									case "CV_IndicateurTraitementAMC":
										mutuelle.indicateurDeTraitement =
											`${e?.elements?.[0] && e?.elements?.[0]?.text}109` || null
										break
									case "CV_CodeRoutageFluxAMC":
										mutuelle.codeRoutage = e?.elements?.[0]?.text || null
										break
									case "CV_NomDomaineAMC":
										mutuelle.nomDeDomaine = e?.elements?.[0]?.text || null
										break
									case "CV_CodeAiguillageSTSAMC":
										mutuelle.codeAiguillage = e?.elements?.[0]?.text || null
										break

									default:
										break
								}
								mutuelle.isTp = null
							})
							mutuelles.push(mutuelle)
							break
						case "groupe107":
							e.elements.forEach((e) => {
								switch (e.name) {
									case "CV_IdMutuelle":
										mutuelle.insuranceId = e?.elements?.[0]?.text || null
										break
									case "CV_IndicateurTraitementMutuelle":
										mutuelle.indicateurDeTraitement = `${e?.elements?.[0]?.text}107` || null
										break
									case "CV_Garanties":
										mutuelle.garantie = e?.elements?.[0]?.text || null
										break
									case "groupe108":
										mutuelle.startDate = e?.elements?.[0]?.elements?.[0]?.text || null
										mutuelle.endDate = e?.elements[1]?.elements?.[0]?.text || null
										break
									default:
										break
								}
							})
							mutuelles.push(mutuelle)
							break
						default:
							break
					}
				})

				newBeneficiaire.couverturesAMO = couverturesAMO
				newBeneficiaire.mutuelles = mutuelles

				if (numeroSecuCommun) {
					newBeneficiaire.numeroSecuCommun = numeroSecuCommun
					newBeneficiaire.cleNumeroSecuCommun = cleNumeroSecuCommun
					newBeneficiaire.sexe = numeroSecuCommun[0] === "1" ? "homme" : "femme"
					newBeneficiaire.AmoGrandRegime = AmoGrandRegime
					newBeneficiaire.AmoCaisseGestionnaire = AmoCaisseGestionnaire
					newBeneficiaire.AmoCentreGestionnaire = AmoCentreGestionnaire
				} else newBeneficiaire.sexe = "homme"
				beneficiaires.push(newBeneficiaire)
			}
		})
	return beneficiaires
}

export const parseCPS = (xml) => {
	const professionalData = {}
	const structures = []
	// formattage xml
	const parsed = soapParsed(xml)
	// conversion en json
	const json = JSON.parse(parsed)
	const filteredObj = json?.elements[2].elements
	filteredObj &&
		filteredObj.forEach((e) => {
			if (e.name === "groupe1") {
				let rpps = ""
				e.elements.forEach((e) => {
					switch (e.name) {
						case "PS_NumeroNIR":
							rpps = e?.elements?.[0]?.text
							break
						case "PS_ClefNIR":
							rpps = rpps + e?.elements?.[0]?.text
							professionalData.rpps = rpps
							break
						case "PS_Nom":
							professionalData.lastName = sanitize(e?.elements?.[0]?.text)
							break
						case "PS_Prenom":
							professionalData.firstName = sanitize(e?.elements?.[0]?.text)
							break
						default:
							break
					}
				})
			} else if (e.name === "groupe2") {
				const structure = {}
				e.elements.forEach((e) => {
					switch (e.name) {
						case "PS_RaisonSocialeStructure":
							structure.nomStructure = sanitize(e?.elements?.[0]?.text)
							break
						case "PS_NumeroIdFact":
							structure.finess = e?.elements?.[0]?.text
							break
						case "PS_ClefNumeroIdFact":
							structure.finess = structure.finess + e?.elements?.[0]?.text
							break
						default:
							break
					}
				})
				structures.push(structure)
			}
		})
	professionalData.structures = structures
	return professionalData
}

export const parseGed = (data) => {
	const json = soapParsed(data.toString())
	const newObj = JSON.parse(json)
	const idDocument = newObj?.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements[1]?.elements
	const codeSortie = newObj?.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements[0]?.elements
	return { idDocument: idDocument[0].text, codeSortie: codeSortie[0].text }
}

// PARSER POUR LES RETOURS WEBSERVICES (transmettreFacture, transmettrePatient, etc.)
export const parseSoap = (data) => {
	const patientData = {}
	const couvertureAMO = []
	const couvertureAMC = []
	const json = soapParsed(data.toString())
	const newObj = JSON.parse(json)
	const filteredObj = newObj && newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements[1]?.elements
	const errorObj = newObj && newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements?.[0]

	if (filteredObj) {
		filteredObj.forEach((e) => {
			switch (e.name) {
				case "adresse":
					e.elements
						.filter((el) => el.elements)
						.forEach((e, index) => (patientData[`ligneAdresse-${index}`] = e?.elements?.[0]?.text))
					break
				case "couvertureAMO":
					const tmpAmo = {}
					e.elements
						.filter(
							(el) =>
								(el.name === "dateDeb" ||
									el.name === "dateFin" ||
									el.name === "codeJustifExoneration" ||
									el.name === "isTp") &&
								el.elements
						)
						.forEach((e) => {
							tmpAmo[e.name] = e["elements"][0]["text"]
						})
					couvertureAMO.push(tmpAmo)
					break
				case "couvertureAMC":
					const tmpAmc = {}
					e.elements
						.filter((el) => (el.name === "codeJustifExoneration" || el.name === "isTp") && el.elements)
						.forEach((e) => {
							tmpAmc[e.name] = e["elements"][0]["text"]
						})
					couvertureAMC.push(tmpAmc)
					break
				case "codeCivilite":
					patientData.gender = e["elements"][0]["text"]
					break
				case "nomUsuel":
					patientData.lastName = e["elements"][0]["text"]
					break
				case "prenom":
					patientData.firstName = e["elements"][0]["text"]
					break
				case "dateNaissance":
					patientData.birthDate = e["elements"][0]["text"]
					break
				case "nirIndividu":
					patientData.idSecu = e["elements"][0]["text"]
					break
				case "nirIndividuCle":
					patientData.idSecuCle = e["elements"][0]["text"]
					break
				case "idPatient":
					patientData.idFSV = e["elements"][0]["text"]
					break
				default:
					break
			}
		})
		if (couvertureAMO) patientData.couvertureAMO = couvertureAMO
		if (couvertureAMC) patientData.couvertureAMC = couvertureAMC
	} else if (errorObj) {
		const err = errorObj[1]?.elements?.[0]?.text
		if (err) patientData.error = err
	}

	console.log({ patientData })
	return patientData
}

export const parseAmc = (data) => {
	let couvertureAMC
	const json = soapParsed(data.toString())
	const newObj = JSON.parse(json)
	if (!newObj) return false
	const transmissionValide = newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements?.[0]?.elements
	const drePossible = newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements[1]?.elements

	if (transmissionValide.name === "transmissionValide" && transmissionValide.elements?.[0].text === "false") {
		const err = newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements[2].elements
		console.log({ codeErreur: err[0].elements?.[0].text, libelleErreur: err[1].elements?.[0].text })

		return { codeErreur: err[0].elements?.[0].text, libelleErreur: err[1].elements?.[0].text }
	}
	if (drePossible) {
		if (drePossible?.[0]?.text === "false") {
			const err = newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements[2].elements
			console.log({ codeErreur: err[0].elements?.[0].text, libelleErreur: err[1].elements?.[0].text })

			return { codeErreur: err[0].elements?.[0].text, libelleErreur: err[1].elements?.[0].text }
		} else {
			drePossible.forEach((e) => {
				if (e.name === "couvertureAMC") {
					const tempObj = {}
					e.elements
						.filter((el) => (el.name === "codeJustifExoneration" || el.name === "isTp") && el.elements)
						.forEach((e) => {
							tempObj[e.name] = e["elements"][0]["text"]
						})
					couvertureAMC.push(tempObj)
				}
				return couvertureAMC
			})
		}
	}
}

// PARSER POUR consulterConventions()
export const parseConventions = (data) => {
	if (!data) return "No data to parse"
	const res = []
	const json = soapParsed(data.toString())
	const newObj = JSON.parse(json)
	const conventionsObj = newObj.elements?.[0].elements?.[0].elements?.[0].elements?.[0].elements

	if (conventionsObj[0]?.attributes["xsi:nil"] === "true") {
		conventionsObj.shift()

		conventionsObj.forEach((convention) => {
			const tmpConvention = {}
			convention.elements.forEach((el) => {
				if (!el.name || !el.elements?.[0]?.text) return
				tmpConvention[el.name] = el.elements?.[0]?.text
			})
			res.push(tmpConvention)
		})
		return res
	} else return "AMC non reconnue"
}

//
// Nouvelles fonctions utilitaires
//

export const parseXml2Json = (orig, dest = {}, ignore = []) => {
	const tmp = {}
	for (const s of Object.keys(orig)) {
		if (ignore?.includes(s)) {
		} else if (orig[s]?._text) {
			dest[s] = orig[s]?._text
			tmp[s] = orig[s]?._text
		} else {
			if (!orig[s]?._attributes) dest[s] = parseXml2Json(orig[s])
		}
	}
	return tmp
}

export const JuxtaGenServiceBody = (prefix, data) => {
	const result = []

	for (const key in data) {
		let xmlkey = `${prefix}:${key}`
		if (data[key]?.format && data[key]?.value) xmlkey += ` format="${data[key].format}"`
		result.push(`<${xmlkey}>`)
		if (typeof data[key] === "object" && !data[key]?.format) {
			result.push(...JuxtaGenServiceBody(prefix, data[key]))
		} else if (data[key]?.format && data[key]?.value) {
			result.push(data[key]?.value)
		} else {
			result.push(data[key])
		}
		result.push(`</${prefix}:${key}>`)
	}
	return result
}

export const GenerateJuxtaXML = (service, data, options = null) => {
	const result = [],
		envelope = [],
		defaultOptions = {
			soapenv: "http://schemas.xmlsoap.org/soap/envelope/",
			jux: "http://www.juxta.fr",
			xsd: "XsdWebServiceFSV.xsd",
		}
	let prefix = "xsd"

	if (!options) {
		envelope.push(`xmlns:soapenv="${defaultOptions.soapenv}"`)
		envelope.push(`xmlns:jux="${defaultOptions.jux}"`)
		envelope.push(`xmlns:xsd="${defaultOptions.xsd}"`)
	} else {
		if (options.soapenv) {
			envelope.push(`xmlns:soapenv="${options.soapenv}"`)
		}
		if (options.jux) {
			envelope.push(`xmlns:jux="${options.jux}"`)
		}
		if (options.xsd) {
			envelope.push(`xmlns:xsd="${options.xsd}"`)
		}
		if (options.ged) {
			envelope.push(`xmlns:ged="${options.ged}"`)
			prefix = "ged"
		}
	}
	result.push(`<soapenv:Envelope ${envelope.join(" ")}>`)
	result.push("<soapenv:Header/>")
	result.push("<soapenv:Body>")
	result.push(`<jux:${service}>`)
	result.push(...JuxtaGenServiceBody(prefix, data))
	result.push(`</jux:${service}>`)
	result.push("</soapenv:Body>")
	result.push("</soapenv:Envelope>")
	return result.join("")
}

const headerTheme = "%2347c6a9"
const headerTitle = "AudioWizard"
let juxtaHost = ""

if (process.env.REACT_APP_JUXTA_ENDPOINT_FSV_PROD && productionUrl.includes(document.location.host)) {
	juxtaHost = "https://audiowizard-fsv.juxta.cloud"
} else juxtaHost = "https://audiowizard-test-fsv-test.juxta.cloud"

export const urlTeletransmission = (rpps, finess, companyId) => {
	if (companyId === 91) juxtaHost = "https://espace-sante-aulnay-fsv.juxta.cloud"
	let url = juxtaHost + "/Pages/Post/FSE-DRE/Fsv_Teletransmission.aspx"

	if (!finess || !rpps) return (url += `?couleurTheme=${headerTheme}&nomSite=${headerTitle}`)
	const host = encodeURIComponent(window.location.protocol + "//" + window.location.host)

	url += `?URLRetour=${host}&numFiness=${finess}&numNatPs=${rpps}&couleurTheme=${headerTheme}&nomSite=${headerTitle}`
	return url
}

export const urlAdri = (rpps, finess, idFSV, companyId) => {
	if (companyId === 91) juxtaHost = "https://espace-sante-aulnay-fsv.juxta.cloud"
	let url = juxtaHost + "/Pages/EspacePatient/Patient/Fsv_DossierPatient.aspx"
	const host = encodeURIComponent(window.location.protocol + "//" + window.location.host)
	if (!idFSV) idFSV = "-1"
	url += `?URLRetour=${host}&numFiness=${finess}&numNatPs=${rpps}&idPatient=${idFSV}&couleurTheme=${headerTheme}&nomSite=${headerTitle}`
	return url
}

export const urlFacturation = (rpps, finess, idFacture, idPatient, companyId) => {
	if (companyId === 91) juxtaHost = "https://espace-sante-aulnay-fsv.juxta.cloud"
	let url = juxtaHost + "/Pages/EspacePatient/Facturation/Fsv_Facturation.aspx"
	const host = encodeURIComponent(window.location.protocol + "//" + window.location.host)
	if (!idFacture) idFacture = "-1"
	url += `?URLRetour=${host}/fiche-patient/${idPatient}&numFiness=${finess}&numNatPs=${rpps}&idFacture=${idFacture}&couleurTheme=${headerTheme}&nomSite=${headerTitle}`
	return url
}

export const isTeletransInvoiceNotDuplicate = async (patientId, showAlert): Promise<boolean> => {
	const doc = await API.findAll(
		"DOCUMENTS_API",
		`?patient=${patientId}&type=FACTURE_SUIVI_TELETRANS&status=NON_TELETRANSMIS_SUIVI_TELETRANS`
	)
	if (doc.length === 0) return true
	if (showAlert) {
		toast.error("Une télétransmission de suivi en attente existe déjà pour ce patient")
	}
	return false
}

export const getTpStatus = (source) => {
	if (source.teletransData?.hasOwnProperty("secuTierPayant"))
		return source.teletransData.secuTierPayant ? "TP" : "Hors-TP"
	else if (source?.config?.teletransData?.hasOwnProperty("secuTierPayant"))
		return source.config.teletransData.secuTierPayant ? "TP" : "Hors-TP"
	return "non-renseigné"
}

export const isPatientCoverSafe = async (patient) => {
	const patientCover = API_FSV.recursivelySearchForKey(await API_FSV.consulterClient(patient.idFSV), "couvertureAMO")
	if (!patientCover) return false

	if (patientCover?.isSVPossible) return patientCover?.isSVPossible === "true"
	else return patientCover?.[0]?.isSVPossible === "true"
}
