import { HIB } from "./Routes"
import { Rules } from "./Interface"

const RULES: Rules[] = [
	{
		get: HIB.PRODUCTS,
		put: HIB.PRODUCT,
		post: /(.*).hiboutik.com\/api\/products\/?(.*)?/,
		delete: HIB.PRODUCT,
		clear: [HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.PRODUCTS_TAGS,
		post: /(.*).hiboutik.com\/api\/products_tags\/\d+/,
		clear: [HIB.PRODUCTS_TAGS],
		validity: 240,
	},
	{
		get: HIB.SIZES,
		clear: [HIB.SIZES, HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.BRANDS,
		post: HIB.BRANDS,
		put: HIB.BRANDS,
		clear: [HIB.BRANDS, HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.CATEGORIES,
		post: HIB.CATEGORIES,
		clear: [HIB.CATEGORIES, HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.SIZES_PRETTY,
		clear: [HIB.SIZES_PRETTY],
		validity: 240,
	},
	{
		get: HIB.SIZES_TYPES,
		clear: [HIB.SIZES_TYPES],
		validity: 240,
	},
	{
		get: HIB.SIZES,
		clear: [HIB.SIZES],
		validity: 240,
	},
	{
		get: HIB.SUPPLIERS,
		put: HIB.SUPPLIERS,
		post: HIB.SUPPLIERS,
		clear: [HIB.SUPPLIERS],
		validity: 240,
	},
	{
		get: HIB.WAREHOUSES,
		clear: [HIB.WAREHOUSES],
		validity: 240,
	},
]

export default RULES
