/* eslint-disable react-hooks/exhaustive-deps */
import { Document } from "@audiowizard/common"
import { Pagination, Table } from "antd"
import useEffectAsync from "components/Hooks/useEffectAsync"
import useHasRole from "components/Hooks/useHasRole"
import SpinnerAW from "components/utils/SpinnerAW"
import DocumentUploader from "pages/fiche-patient/Documents/Modal.DocumentUploader"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Col, Container, Row } from "reactstrap"
import { frenchDate } from "services/functions"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import DocumentViewer from "../../fiche-patient/Documents/Modal.DocumentViewer"
import { DownloadExport } from "./DownloadExport"
import { detailsEsp } from "./caisse.helpers"
import ExpendedRowCashOutflow from "./components/ExpendedRowCashOutflow"
import { CashType, FullAccounting, typeAccounting } from "./moneyItems"
import useCustomTitle from "components/Hooks/useTitle"

const colorBill: Partial<Record<CashType, string>> = {
	CINQ_CENT_EUROS: "#d473d4",
	DEUX_CENT_EUROS: "#ffcc33",
	CENT_EUROS: "#33cc00",
	CINQUANTE_EUROS: "#ff9333",
	VINGT_EUROS: "#99ccff",
	DIX_EUROS: "#ff9999",
	CINQ_EUROS: "#cccccc",
}
const labels = {
	VALIDATION_ESPECE: "Validation",
	REASSORT_ESPECE: "Réassort",
	DEPOT_ESPECE: "Dépôt",
	SORTIE_ESPECE: "Sortie de caisse pour achat",
}

const colorByType = (type: string): string => {
	return (
		{
			VALIDATION_ESPECE: "text-primary",
			DEPOT_ESPECE: "text-warning",
			REASSORT_ESPECE: "text-info",
			SORTIE_ESPECE: "text-info",
		}[type] ?? ""
	)
}

const iconByType = (type: string): string => {
	return (
		{
			VALIDATION_ESPECE: "fad fa-check",
			DEPOT_ESPECE: "fad fa-minus",
			REASSORT_ESPECE: "fad fa-plus",
			SORTIE_ESPECE: "fad fa-minus",
		}[type] ?? ""
	)
}

const EspHistoriqueCaisse = (): JSX.Element => {
	useCustomTitle("Caisse | Historique")
	const queryClient = useQueryClient()
	const { laboratory, user } = useContext(AuthContext)

	const [data, setData] = useState<FullAccounting[]>([])
	const [pageSize, setPageSize] = useState(5)
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [laboratoryFilter] = useState(laboratory.id)
	const [needRefresh, setNeedRefresh] = useState(true)
	const [modalViewerOpen, setModalViewerOpen] = useState(false)
	const [document, setDocument] = useState<Document>()
	const [modalUpload, setModalUpload] = useState(false)
	const [currentRecord, setCurrentRecord] = useState<FullAccounting>()

	const isManager = useHasRole("ROLE_MANAGER")

	const searchParamsRecord = useMemo(() => {
		return {
			page: currentPage.toString(),
			itemsPerPage: pageSize.toString(),
			laboratory: laboratoryFilter?.toString() || "",
			"order[createdAt]": "desc",
		}
	}, [currentPage, pageSize, laboratoryFilter])

	const { data: accountingData, isLoading: loading } = useQuery(
		["ACCOUNTING_API", searchParamsRecord],
		() => {
			const params = new URLSearchParams(searchParamsRecord)
			return API.findAll<FullAccounting[]>("ACCOUNTING_API", `?${params}`, true)
		},
		{ staleTime: Infinity }
	)

	useEffect(() => {
		if (!accountingData) return

		setTotalItems(accountingData["hydra:totalItems"])
		setData(accountingData["hydra:member"])
	}, [accountingData])

	useEffectAsync(async () => {
		if (!needRefresh) return
		await queryClient.invalidateQueries("ACCOUNTING_API")
		setNeedRefresh(false)
	}, [needRefresh])

	const tableColumns = useMemo(
		() => [
			{
				title: "Type",
				dataIndex: "type",
				key: "type",
				render: (type: string, record: FullAccounting) => {
					return (
						<>
							<i className={iconByType(type)} /> {(labels as any)[type] || type}{" "}
							{record.cancelDate && "(annulée)"} {record.cancelAccounting && "(annulation)"}{" "}
							{record.replaceAccounting && "(correction)"}
						</>
					)
				},
			},
			{
				title: "Date",
				dataIndex: "createdAt",
				key: "date",
				render: (createdAt: Date) => frenchDate(createdAt),
			},
			{
				title: "Montant (€)",
				dataIndex: "amount",
				key: "montant",
			},
			{
				title: "Montant attendu (€)",
				dataIndex: "expectedAmount",
				key: "montant attendu",
				render: (expectedAmount: string, record: FullAccounting) => {
					return record.type === "VALIDATION_ESPECE" ? expectedAmount : "X"
				},
			},
			{
				title: "Ecart (€)",
				key: "ecart",
				render: (_: any, record: FullAccounting) => {
					return record.type === "VALIDATION_ESPECE"
						? (record.amount || 0) - (record.expectedAmount || 0)
						: "X"
				},
			},
		],
		[]
	)

	const addDocument = async (record: FullAccounting, document: string): Promise<void> => {
		if (!record.id) return
		try {
			const tmpRecord = { ...record, receipt: document }
			await API.update("ACCOUNTING_API", record.id, tmpRecord)
			setData((data) => {
				return data.map((r) => {
					if (r["@id"] === record["@id"]) return tmpRecord as unknown as FullAccounting
					return r
				})
			})
			setCurrentRecord(undefined)
		} catch {
			toast.error("Problème lors de l’enregistrement du document")
		}
	}

	const tableExpandedRowRender = useCallback((record: FullAccounting, index: number) => {
		// @ts-ignore car pas encore généré dans le common
		if (record.type === typeAccounting.sortieEspece) {
			return (
				<ExpendedRowCashOutflow
					record={record}
					isManager={isManager}
					laboratory={laboratory}
					setDocument={setDocument}
					setModalViewerOpen={setModalViewerOpen}
					setModalUpload={setModalUpload}
					setCurrentRecord={setCurrentRecord}
				/>
			)
		}
		return (
			<Container fluid>
				<Row className="my-1 col-esp-hist">
					<Col>Espèces :</Col>
					{Object.keys(detailsEsp).map((value) => (
						<Col
							key={`${index}-${value}`}
							className="caisse-hist-esp"
							style={{ color: (colorBill as any)?.[value] }}>
							{(detailsEsp as any)[value]}
						</Col>
					))}
				</Row>
				<Row className="my-1 col-esp-hist">
					<Col>Action :</Col>
					{Object.keys(detailsEsp).map((value) => (
						<Col key={`${index}-${value}`} className="caisse-hist-esp">
							{(record?.data?.state as any)?.[value] > 0 && (record?.data?.state as any)?.[value]}
						</Col>
					))}
				</Row>
				<Row className="my-1 col-esp-hist">
					<Col>Caisse :</Col>
					{Object.keys(detailsEsp).map((value) => (
						<Col key={`${index}-${value}`} className="caisse-hist-esp">
							{(record?.data?.cashfund as any)?.[value] > 0 && (record?.data?.cashfund as any)?.[value]}
						</Col>
					))}
				</Row>
				<Row className="my-4">
					<DownloadExport
						previousData={data?.[index - 1]}
						data={record}
						user={user}
						laboratory={laboratory}
						setDocument={setDocument}
						setModalViewerOpen={setModalViewerOpen}
						setModalUpload={setModalUpload}
						setCurrentRecord={setCurrentRecord}
					/>
				</Row>
				<Row style={{ overflowWrap: "anywhere" }} className="my-1">
					{record.comment || "Aucun commentaire"}
				</Row>
			</Container>
		)
	}, [])

	return (
		<>
			{loading && <SpinnerAW />}
			<div className="d-flex flex-column">
				<Row className="my-3">
					<Col>
						<Table<FullAccounting>
							dataSource={data}
							pagination={false}
							columns={tableColumns}
							expandRowByClick={true}
							rowKey={(record) => record["@id"]}
							expandedRowRender={tableExpandedRowRender}
							rowClassName={(record) => colorByType(record.type || "")}
						/>
					</Col>
				</Row>
				<Row className="m-3">
					<Col>
						<Pagination
							current={currentPage}
							pageSize={pageSize}
							total={totalItems}
							showTitle={false}
							responsive={true}
							showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} éléments`}
							onChange={(page, pageSize) => {
								setCurrentPage(page)
								setPageSize(pageSize)
							}}
						/>
					</Col>
				</Row>
			</div>
			{document && (
				<DocumentViewer
					onClose={() => setModalViewerOpen(false)}
					isOpen={modalViewerOpen}
					document={document} // {} pour ne avoir des erreurs undefined si aucun document choisi
					refreshDocuments={() => undefined}
					hideRemove={true}
				/>
			)}
			<DocumentUploader
				onClose={() => setModalUpload(false)}
				isOpen={modalUpload}
				refreshDocuments={(document) => {
					if (!currentRecord || !document) return
					addDocument(currentRecord, document)
					return undefined
				}}
				patientIri={""}
				// @ts-ignore need to update commun
				documentType={"CAISSE_JUSTIFICATIF"}
			/>
		</>
	)
}

export default EspHistoriqueCaisse
