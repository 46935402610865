import { useContext } from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import AuthContext from "../contexts/AuthContext"

type PrivateRouteProps = RouteProps & {
	allowPatientUI?: boolean
	children?: React.ReactNode
}
function PrivateRoute({ allowPatientUI = false, children, ...props }: PrivateRouteProps): JSX.Element {
	const { isAuthenticated, patientUI } = useContext(AuthContext)

	if (!isAuthenticated) return <Redirect to="/login" />

	// Limite l'accès aux pages en mode patientUI
	if (!allowPatientUI && patientUI) return <Redirect to="/salle-attente/consentement-rgpd" />

	return <Route {...props}>{children}</Route>
}

export default PrivateRoute
