import { Card, CardBody, CardFooter } from "reactstrap"
import "./MesPartenaires.scss"

interface Partner {
	label: string
	header: string
	logo: string
	description: string
	url: string
	color: string
}

type PartnerCardProps = {
	partner: Partner
}

const PartnerCard = ({ partner }: PartnerCardProps): JSX.Element => {
	return (
		<Card className="partner-card m-5">
			<img src={partner.header} alt={partner.label} className="partner-card-header " />
			<CardBody tag="div" className="d-flex d-flex-column w-100">
				<div className="w-25">
					<img src={partner.logo} alt={partner.label + "logo"} className="mr-3" />
				</div>
				<div className="w-75">
					<h5>{partner.label}</h5>
					<p>{partner.description}</p>
				</div>
			</CardBody>
			<CardFooter className="border-0 d-flex justify-content-center">
				<a href={partner.url} target="_blank" rel="noopener noreferrer">
					<button className="partner-button-pill" style={{ backgroundColor: partner.color }}>
						<span className="text-center px-2 text-white">
							<u>En savoir plus</u>
						</span>
					</button>
				</a>
			</CardFooter>
		</Card>
	)
}

export default PartnerCard
