import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { EquipeRoutePrefix, EquipeRoutes, noPrefix } from "./routes"

import MonEquipe from "pages/mon-compte/mon-equipe/Index"
import useCustomTitle from "components/Hooks/useTitle"

function EquipeRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Equipe")
	return (
		<CardWithTabs urlprefix={EquipeRoutePrefix}>
			<CardItem
				name="Gestion de vos équipes"
				component={MonEquipe}
				url={noPrefix(EquipeRoutes.Equipe)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default EquipeRouter
