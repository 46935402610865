import { useContext, useState } from "react"
import AuthContext from "../../../contexts/AuthContext"

import { InputNumber } from "antd"
import ButtonRounded from "components/Buttons/ButtonRounded"
import useCustomTitle from "components/Hooks/useTitle"
import { useMutation, useQueryClient } from "react-query"
import { formatCurrency } from "services/functions"
import { calcDiff, calcEsp, postDepot, useFetchInitialState } from "./caisse.helpers"
import CashInput from "./components/CashInput"
import { CashRecord, moneyItems as items, typeAccounting } from "./moneyItems"

const Depot = (): JSX.Element => {
	useCustomTitle("Caisse | Dépôt")
	const { laboratory, user } = useContext(AuthContext)
	const [bypassValidation, setBypassValida] = useState(false)
	const [espValues, setEspValues] = useState<CashRecord>()
	const [comment, setComment] = useState("")

	const queryClient = useQueryClient()

	const { last, loading } = useFetchInitialState(laboratory)

	const { mutate: handleDepot, isLoading: isBusy } = useMutation(
		async (): Promise<void> => {
			if (!espValues) return

			await postDepot(typeAccounting.depotEspece, espValues, laboratory, user, comment, last?.state || undefined)

			setComment("")
			setEspValues(undefined)
			await queryClient.invalidateQueries([
				"ACCOUNTING_API",
				"?order[createdAt]=desc&laboratory=" + laboratory.id,
			])
		},
		{
			onError: (error) => {
				console.log(error)
			},
		}
	)

	return (
		<>
			{loading && (
				<div className="overlay-loading-aw">
					<div className="overlay-loading-logo" />
					<div className="overlay-loading-text">Chargement ...</div>
				</div>
			)}

			<>
				<div className="d-flex justify-content-between mb-3">
					<h5 className="mb-1">Déposer des espèces</h5>
				</div>

				<div
					style={{
						display: last?.validation?.createdAt || bypassValidation ? "none" : "flex",
						position: "absolute",
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(255, 255, 255, .8)",
						zIndex: "5",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{!loading && (
						<button
							type="button"
							className="btn btn-block btn-primary"
							style={{ maxWidth: "80%" }}
							onClick={() => setBypassValida(true)}>
							Vous n'avez fait aucune validation de caisse, cliquez ici pour effectuer un dépôt
						</button>
					)}
				</div>
				<div className="d-flex flex-column mb-2">
					<h6>
						Montant du dépôt :{" "}
						<strong style={{ fontSize: "18px" }}>{formatCurrency(calcEsp(espValues))}</strong>
					</h6>
					{last?.state?.createdAt &&
						(last.state.type === typeAccounting.validationEspece ||
							last.state.type === typeAccounting.reassortEspece) && (
							<>
								<h6>
									Fond de caisse actuel :{" "}
									<strong style={{ fontSize: "18px" }}>
										{formatCurrency(calcEsp(last.state?.change))}
									</strong>
								</h6>
								<h6>
									Nouveau fond de caisse après dépôt :{" "}
									<strong style={{ fontSize: "18px" }}>
										{formatCurrency(calcEsp(calcDiff(espValues, last.state?.change)))}
										&nbsp;€
									</strong>
								</h6>
								<h6
									className="text-primary cursor-pointer"
									onClick={() => setEspValues({ ...last.state?.change })}>
									<i className="fad fa-copy" /> Préremplir avec les données de la validation
								</h6>
							</>
						)}
				</div>
				<form className="row ">
					{items.map((i) => (
						<CashInput
							key={`${i.id}-${i.name}`}
							cashItem={i}
							espValues={espValues}
							lastState={last?.state || undefined}
							onChange={(value: number) => {
								setEspValues((old) => ({
									...old,
									[i.name]: value,
									total: undefined,
								}))
							}}
							bypassValidation={bypassValidation}
						/>
					))}
					<div className="col-md-6">
						{bypassValidation && (
							<div className="form-group row d-flex justify-content-center align-items-center">
								<label className="col-1 col-md-3 d-flex flex-column align-items-center">
									Total à déposer
								</label>
								<InputNumber<number>
									type="number"
									className="col-5 col-md-8 align-items-center"
									onChange={(total) => {
										setEspValues({ total: total })
									}}
									disabled={isBusy}
								/>
							</div>
						)}
					</div>
				</form>

				<textarea
					className="form-control mb-3"
					placeholder="Ajouter un commentaire"
					value={comment}
					onChange={(e) => {
						const value = e.target.value
						setComment(value)
					}}
				/>
				<ButtonRounded
					className="m-auto"
					color="primary-outlined"
					isDisabled={isBusy}
					onClick={() => handleDepot()}
					type="button">
					Valider la caisse
				</ButtonRounded>
			</>
		</>
	)
}

export default Depot
