/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import Modal from "../../../components/effects/ReactModal"
//import DataTable from "../../../components/utils/DataTable"
import { itemsConfiguration } from "../../../datas/items/itemsConfiguration"
import { Table } from "antd"

const ConfigurationModal = ({ isOpen, setIsOpen, chosenTemplate, setChosenTemplate }) => {
	const [jsonTemplate, setJsonTemplate] = useState([])
	const [data] = useState([])
	//const [refresh, setRefresh] = useState()
	const [, setSortedInfo] = useState()

	useEffect(() => {
		setJsonTemplate(itemsConfiguration.find((i2) => i2.label === chosenTemplate?.label)?.json)
	}, [isOpen])

	const handleClose = () => {
		setChosenTemplate({})
		setIsOpen(false)
	}

	const handleChange = (pagination, filters, sorter) => {
		setSortedInfo(sorter)
	}

	const columns = [
		{
			title: "Variable",
			dataIndex: "variable",
			sorter: (a, b) => a.variable - b.variable,
			// sortOrder: sortedInfo.columnKey === "variable" && sortedInfo.order,
		},
		{
			title: "Description",
			dataIndex: "description",
			sorter: (a, b) => a.key - b.key,
		},
		{
			title: "Exemple / Type de données",
			dataIndex: "dataType",
			sorter: (a, b) => a.key - b.key,
		},
	]

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={`Vos variables et la configuration de votre template ${chosenTemplate?.label}`}
			onClose={handleClose}>
			<>
				<div className="container d-flex justify-content-around flex-column">
					<div className="d-flex flex-column justify-content-center align-items-center mb-4">
						<Table
							pagination={{ position: ["bottomLeft"] }}
							columns={columns}
							dataSource={jsonTemplate}
							onChange={handleChange}
							rowKey={(record) => {
								const index = data.indexOf(record)
								const id = record.id
								return `${id}-${index}`
							}}
						/>
					</div>
				</div>
			</>
		</Modal>
	)
}

export default ConfigurationModal
