/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import { Table } from "antd"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Col } from "reactstrap"
import API_Hiboutik from "../../../services/API_Hiboutik"

const DisplayMandatoryTags = (data, tag) => {
	switch (data) {
		case "OK":
			return (
				<h6>
					<div className="badge badge-primary">{tag[0].tag}</div>
				</h6>
			)
		case "MISSING":
			return (
				<h6>
					<div className="badge badge-danger">non renseigné</div>
				</h6>
			)
		case "OVER":
			return (
				<h6>
					{tag.map((v, k) => (
						<span className="badge badge-danger ml-1 mr-1" key={k}>
							{v.tag}
						</span>
					))}
				</h6>
			)
		default:
			return <></>
	}
}

const colums = [
	{
		title: "Produit",
		dataIndex: "product_model",
		width: "40%",
		render: (d, row) => (
			<Link to={"/mon-stock/catalogue/produit/" + row.product_id}>
				<button className="btn btn-secondary btn-block text-left">{d ? d : "[PAS DE NOM RENSEIGNÉ]"}</button>
			</Link>
		),
	},
	{
		title: "Type",
		dataIndex: "type",
		align: "center",
		width: "15%",
		sorter: (a, b) => a?.type?.localeCompare(b?.type),
		render: (d, row) => {
			const tag = row.tags.filter((f) => f.tag_cat === "type")
			return DisplayMandatoryTags(d, tag)
		},
	},
	{
		title: "Énergie",
		dataIndex: "energy",
		key: "energy",
		align: "center",
		width: "15%",
		sorter: (a, b) => a?.energy?.localeCompare(b?.energy),
		render: (d, row) => {
			const tag = row.tags.filter((f) => f.tag_cat === "energy")
			return DisplayMandatoryTags(d, tag)
		},
	},
	{
		title: "Classe",
		dataIndex: "classe",
		align: "center",
		width: "15%",
		sorter: (a, b) => a?.classe?.localeCompare(b?.classe),
		render: (d, row) => {
			const tag = row.tags.filter((f) => f.tag_cat === "classe")
			return DisplayMandatoryTags(d, tag)
		},
	},
	{
		title: "Autre",
		dateIndex: "tags",
		align: "center",
		width: "10%",
		render: (_, row) => {
			const tag = row.tags.filter((f) => !["classe", "energy", "type"].includes(f.tag_cat))
			return (
				<>
					{tag.map((v) => (
						<h6 key={v.tag_cat}>
							<div className="badge badge-secondary">{v.tag === "true" ? v.tag_cat : ""}</div>
						</h6>
					))}
				</>
			)
		},
	},
]

const tagsChecklist = [
	{ tag_cat: "type", max: 1, min: 1 },
	{ tag_cat: "energy", max: 1, min: 1 },
	{ tag_cat: "classe", max: 1, min: 1 },
	{ tag_cat: "bobine", max: 1, min: 0 },
	{ tag_cat: "telecommande", max: 1, min: 0 },
	{ tag_cat: "bluetooth", max: 1, min: 0 },
	{ tag_cat: "antiLarsen", max: 1, min: 0 },
	{ tag_cat: "reducteurAcouphene", max: 1, min: 0 },
	{ tag_cat: "nombreCanaux", max: 1, min: 0 },
	{ tag_cat: "puissant", max: 1, min: 0 },
]

const checkTags = (tags) => {
	const state = {}

	for (const tag of tagsChecklist) {
		const count = tags.filter((f) => f.tag_cat === tag.tag_cat)?.length
		if (count > tag.max) state[tag.tag_cat] = "OVER"
		else if (count < tag.min) state[tag.tag_cat] = "MISSING"
		else state[tag.tag_cat] = "OK"
	}
	return state
}

function VerificationCatalogue() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	const init = async () => {
		try {
			let productsList = await API_Hiboutik.getAllProducts({ product_category: 1, stock_management: "ALL" }),
				tagsList = await API_Hiboutik.getAllTagsOfProducts(),
				tagsCat = await API_Hiboutik.getAllTags(),
				tagsProcessed = []

			for (const tag in tagsCat) {
				for (const el of tagsCat[tag].tag_details || []) {
					tagsProcessed.push({
						tag_cat_id: tagsCat[tag].tag_cat_id,
						tag_cat: tagsCat[tag].tag_cat,
						...el,
					})
				}
			}

			productsList = productsList
				.map((v, key) => {
					const tags = tagsList
						.filter((f) => +f.product_id === +v.product_id)
						.map((m) => ({ ...m, ...tagsProcessed.find((f2) => +f2.tag_id === +m.tag_id) }))
					const n = {
						product_model: v.product_model,
						product_id: v.product_id,
						tags,
						...checkTags(tags),
						key,
					}
					return n
				})
				.filter((f) => f.classe !== "OK" || f.type !== "OK" || f.energy !== "OK")
			setData(productsList)
			setLoading(false)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		init()
	}, [])

	return (
		<Col>
			<SectionHeader title="Vérification des produits" />
			{!loading && (
				<div
					className={"alert " + (data.length ? "alert-warning" : "alert-primary")}
					style={{ fontSize: "16px" }}>
					{data.length ? (
						<>
							<strong>{data.length}</strong>{" "}
							{data.length > 1 ? "produits incomplets détectés." : "produit incomplet détecté."}
							<br />
							<br />
							Voici la liste des appareils auditifs dont les informations sont incorrecte. <br />
							La classe, le type d'énergie ainsi que le type d'appareil doivent être renseignés et ne pas
							être en doublons.
						</>
					) : (
						<>Aucun appareil auditif dont les informations sont incomplète détecté.</>
					)}
				</div>
			)}
			<Table pagination={{ position: ["bottomLeft"] }} loading={loading} columns={colums} dataSource={data} />
		</Col>
	)
}

export default withRouter(VerificationCatalogue)
