import { omit } from "lodash"
import PDFObject from "pdfobject"
import { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react"

type PdfViewerMozillaPdfJsProps = ComponentPropsWithoutRef<"iframe">
function PdfViewerMozillaPdfJs({ src, onLoad, ...props }: PdfViewerMozillaPdfJsProps): JSX.Element {
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const [iframeLoaded, setIframeLoaded] = useState(false)

	// Base 64 are too long to be put in the url, so instead call the right function in the iframe after it loads
	useEffect(() => {
		if (iframeRef.current == null || !iframeLoaded) return

		const iframeWindow = iframeRef.current.contentWindow as any
		iframeWindow.PDFViewerApplication.open(src)
	}, [src, iframeLoaded, iframeRef.current])

	return (
		<iframe
			ref={iframeRef}
			title="PDF Viewer"
			src="/static/pdf-js/web/viewer.html?file="
			frameBorder={0}
			width="100%"
			height="100%"
			allowFullScreen
			onLoad={(e) => {
				setIframeLoaded(true)

				onLoad?.(e)
			}}
			{...props}
		/>
	)
}

type PdfViewerNativeProps = ComponentPropsWithoutRef<"embed">
function PdfViewerNative(props: PdfViewerNativeProps): JSX.Element {
	return <embed type="application/pdf" width="100%" height="100%" {...props} />
}

type PdfViewerProps = {
	forceIframe?: boolean
} & PdfViewerMozillaPdfJsProps &
	PdfViewerNativeProps

export default function PdfViewer(props: PdfViewerProps): JSX.Element {
	return PDFObject.supportsPDFs && !props.forceIframe ? (
		<PdfViewerNative {...omit(props, "forceIframe")} />
	) : (
		<PdfViewerMozillaPdfJs {...omit(props, "forceIframe")} />
	)
}
