/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import moment from "moment"
import day from "dayjs"
import API from "../../services/API"
import { SortByProperty } from "../../services/functions"
import API_Hiboutik from "../../services/API_Hiboutik"
import axios from "axios"
import { API_URL, USERS_API } from "../../config"

const DataTable = ({
	target,
	refresh,
	queryValue,
	scheduleStatus,
	columns,
	history,
	onRowClick,
	initialData,
	toPatient,
	periode = null,
	setter,
	dataSetter,
	warehouseId,
}) => {
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({ position: ["bottomLeft"] })
	const [loading, setLoading] = useState(false)
	const [stocks, setStocks] = useState()

	const getProductStockData = async () => {
		const res = await API_Hiboutik.getAllProductsStocks()
		setStocks(res)
	}

	useEffect(() => {
		if (initialData) {
			setData(initialData)
		}
		if (target === "products") {
			getProductStockData()
		}
	}, [])

	const fetch = async (
		params = {
			page: "1",
			sortField: "",
			sortOrder: "ascend",
		}
	) => {
		setLoading(true)
		const nextPagination = { ...pagination }
		nextPagination.current = params.page || 1
		if (target === "patients") {
			if (params.sortField === "") {
				params.sortField = "lastSchedulesDateOf"
			} else if (params.sortField === "schedules") {
				params.sortField = "lastSchedulesDateOf"
			}
			const sortOrder = params.sortOrder === "ascend" ? "asc" : "desc"
			const data = await API.custom(
				queryValue && queryValue !== ""
					? `/${target}?page=${nextPagination.current}${
							scheduleStatus ? `&lastScheduleStatus=${scheduleStatus}` : ""
					  }&lastName=${queryValue}&order[${params.sortField}]=${sortOrder}`
					: `/${target}?page=${nextPagination.current}${
							scheduleStatus ? `&lastScheduleStatus=${scheduleStatus}` : ""
					  }&order[${params.sortField}]=${sortOrder}`
			)

			nextPagination.total = data["hydra:totalItems"]
			nextPagination.showTotal = (total, range) =>
				`${range[0]}-${range[1]} de ${total} ${target === "referrals" ? "parrainages" : target}`
			setLoading(false)
			setData(data["hydra:member"].slice(0, 5))
			setPagination((old) => ({ ...old, ...nextPagination }))
		} else if (target === "sous-traitants") {
			const response = await API.findAll("SUBCONTRACTORS_API")
			nextPagination.total = response["hydra:totalItems"]
			setLoading(false)
			setData(response.reverse())
			setPagination((old) => ({ ...old, ...nextPagination }))

			//REQUETE COMPLIANCE DOCUMENTS
		} else if (target === "agreements") {
			const response = (await axios.get(USERS_API + "/verify_agreements")).data
			nextPagination.total = response["hydra:totalItems"]
			setLoading(false)
			setData(
				response["hydra:member"].map((agreement) => {
					const [documentType, documentAgreements] = Object.entries(agreement)[0]
					return {
						documentType,
						isAccepted: documentAgreements?.isAccepted,
						isAvailable: documentAgreements?.isAvailable,
					}
				})
			)
			// REQUETE VENTES
		} else if (target === "sales") {
			try {
				const today = moment()
				let response = []
				if (periode?.dayStart && periode?.dayEnd) {
					const sales = []
					let month = periode.monthStart
					let year = periode.yearStart

					while (
						periode.monthEnd === 12
							? month !== 1
							: month !== periode.monthEnd + 1 || year !== periode.yearEnd
					) {
						const tmpSale = await API_Hiboutik.getAllSales(year, month, null, warehouseId)
						tmpSale.forEach((sale) => {
							day(sale.created_at).isAfter(day(periode.fullDateStart)) &&
								day(sale.created_at).isBefore(day(periode.fullDateEnd)) &&
								sales.push(sale)
						})
						month++
						if (month === 13) {
							month = 1
							year++
						}
					}
					response = sales
				} else if (periode) {
					response = await API_Hiboutik.getAllSales(
						periode.year,
						periode.month,
						periode.date && periode.date,
						warehouseId
					)
				} else {
					response = await API_Hiboutik.getAllSales(today.year(), today.month(), today.date(), warehouseId)
				}

				nextPagination.total = response["hydra:totalItems"] || response?.length

				// SETTER AVANT separateDiffereTypes POUR CONSERVER
				// L'ID DES DIFFERES DANS LES EXPORTS
				setter && setter(response)

				const separateDiffereTypes = async (sales) => {
					const salesCopy = [...sales]
					const result = [...sales]
					for (let sale of salesCopy) {
						let res = [sale]
						if (sale.payment === "DIV") {
							res = await API_Hiboutik.getSale(sale.sale_id)
							res.payment_details.forEach((payment) => {
								result.push({
									sale_id: res.sale_id,
									customer_id: res.customer_id,
									payment: payment.payment_type,
									completed_at: res.completed_at,
									total: payment.payment_amount,
								})
							})
							result.splice(sales.indexOf(sale), 1)
						}
					}
					return result
				}

				let res = await separateDiffereTypes(response)

				let customersIds = new Set(),
					customersDetail = [],
					salesIds = new Set()
				//salesDetail = []

				for (const sale of res) {
					customersIds.add(sale.customer_id)
					salesIds.add(sale.sale_id)
				}

				if (customersIds.size) {
					await axios
						.get(API_URL + "/search/patients/by_list/customer_id/" + btoa([...customersIds].join(",")))
						.then((r) => {
							customersDetail = [...r.data]
						})
						.catch((e) => console.error(e))
				}

				// if (salesIds.size) {
				// 	await axios
				// 		.get(API_URL + "/search/get_seller/" + btoa([...salesIds].join(",")))
				// 		.then((r) => {
				// 			salesDetail = [...r.data]
				// 		})
				// 		.catch((e) => console.error(e))
				// }

				res = res.map((value) => {
					const details = customersDetail.find((f) => +f.hiboutik_customer_id === +value.customer_id)
					//const sellerDetail = salesDetail.find((f) => +f.numero === +value.sale_id)
					value.patient = `${details?.first_name || "Inconnu"} ${details?.last_name || ""}`
					value.patientId = details?.id
					//value.sellerName = sellerDetail ? `${sellerDetail?.first_name} ${sellerDetail?.last_name}` : ""
					return value
				})

				setData(res)
				setLoading(false)
				setPagination((old) => ({ ...old, ...nextPagination }))
			} catch (err) {
				console.error(err)
			}
		} else if (target === "open-sales") {
			const response = await API_Hiboutik.getOpenSales()

			nextPagination.total = response["hydra:totalItems"]

			setLoading(false)
			setData(response)
			setPagination((old) => ({ ...old, ...nextPagination }))
		} else if (target === "couverturesAMO") {
			const result = await API.custom(`/patient_social_securities?patient=${queryValue}`)
			nextPagination.total = result["hydra:totalItems"]
			nextPagination.last = result["hydra:view"]["hydra:last"]

			setLoading(false)
			setData(result["hydra:member"])
			setPagination(false)
		} else if (target === "couverturesAMC") {
			const result = await API.custom(`/patient_insurances?patient=${queryValue}`)
			nextPagination.total = result["hydra:totalItems"]
			nextPagination.last = result["hydra:view"]["hydra:last"]

			setLoading(false)
			setData(result["hydra:member"])
			setPagination(false)
		} else if (target === "personalization") {
			setData(dataSetter)

			setLoading(false)
			setPagination((old) => ({ ...old, ...nextPagination }))
		} else {
			const ndata = await API.custom(`/${target}?order[id]=DESC&page=${nextPagination.current}`)
			nextPagination.total = ndata["hydra:totalItems"]
			nextPagination.showTotal = (total, range) =>
				`${range[0]}-${range[1]} de ${total} ${target === "referrals" ? "parrainages" : target}`
			setLoading(false)
			setData(ndata["hydra:member"])
			setPagination((old) => ({ ...old, ...nextPagination }))
		}
	}

	useEffect(() => {
		if (refresh.needRefresh) {
			fetch()
			refresh.setNeedRefresh(false)
		}
	}, [refresh.needRefresh, queryValue])

	const mergeArrayWithObject = (arr, obj) => arr && arr.map((t) => (t?.product_id === obj?.product_id ? obj : t))

	const defineStocks = () => {
		return stocks.map((stock) => {
			let temp = data.find((e) => e.product_id === stock.product_id)
			temp && (temp["stock_available"] = parseInt(stock.stock_available))
			const newData = mergeArrayWithObject(data, temp)

			return newData
		})
	}

	useEffect(() => {
		data?.length && stocks && defineStocks()
	}, [data])

	const handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...pagination }
		pager.current = pagination.current || 1
		setPagination((old) => ({
			...old,
			...pager,
		}))
		fetch({
			page: pagination.current || 1,
			sortField: sorter.field,
			sortOrder: sorter.order || "ascend",
			...filters,
		})
	}

	useEffect(() => {
		fetch()
	}, [periode, target])

	useEffect(() => {
		if (refresh.needRefresh) {
			fetch()
			refresh.setNeedRefresh(false)
		}
	}, [refresh.needRefresh, queryValue])

	return (
		<Table
			locale={{
				emptyText: (
					<>
						<br />
						<i className="fad fa-user-alt-slash fa-4x" />
						<br />
						<br />
						{target ? (
							target === "patients" ? (
								<span>Pas encore de patient</span>
							) : target === "products" ? (
								<span>Pas encore de produit</span>
							) : target === "sales" ? (
								<span>Pas encore de vente</span>
							) : target === "couverturesAMO" ? (
								<span>Pas encore de couverture AMO</span>
							) : target === "couverturesAMC" ? (
								<span>Pas encore de couverture AMC</span>
							) : (
								<span>Pas encore de gamme produit</span>
							)
						) : (
							<span>Pas encore de contenu</span>
						)}
					</>
				),
			}}
			rowClassName="cursor-pointer"
			columns={columns}
			rowKey={(record) => {
				const index = data.indexOf(record)
				const id =
					record.product_id ||
					record.supplier_id ||
					record.category_id ||
					record.sale_id ||
					record.brand_id ||
					record.id
				return `${id}-${index}`
			}}
			dataSource={data}
			pagination={pagination}
			loading={loading}
			onChange={handleTableChange}
			onRow={(record, rowIndex) => {
				return {
					onClick: () => {
						if (target === "patients" && toPatient) {
							history.push("/fiche-patient/" + record.id)
						}

						if (!onRowClick) return

						switch (target) {
							case "sales":
							case "patients":
							case "sous-traitants":
							case "couverturesAMO":
							case "couverturesAMC":
							case "agreements":
								onRowClick(record)
								break
							default:
								onRowClick(record.id, rowIndex)
								break
						}
					},
				}
			}}
		/>
	)
}

DataTable.defaultProps = {
	initialData: null,
	toPatient: true,
}
DataTable.propTypes = {
	target: PropTypes.string.isRequired,
	refresh: PropTypes.object,
	queryValue: PropTypes.string,
	scheduleStatus: PropTypes.string,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	history: PropTypes.object,
	onRowClick: PropTypes.func,
	initialData: PropTypes.array,
	toPatient: PropTypes.bool,
}

export default withRouter(DataTable)
