import { Inventory, Laboratory } from "@audiowizard/common"
import { Table } from "antd"
import useCustomTitle from "components/Hooks/useTitle"
import { Badge } from "components/utils/Badge"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import uuid from "react-uuid"
import API from "services/API"
import DeleteLogisticForm from "../Shared/DeleteLogisticFormButton"
import Filters from "./Filters"
import ModalCreateNewInventory from "./Modal.CreateNewInventory"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import ButtonRounded from "components/Buttons/ButtonRounded"

export type FiltersType = {
	laboratory?: Laboratory
	isValidated?: boolean
	label?: string
}

const InventoryList = (): JSX.Element => {
	useCustomTitle("Stock | Inventaires")

	const { laboratory } = useContext(AuthContext)
	const queryClient = useQueryClient()
	const history = useHistory()

	const [page, setPage] = useState<number>(1)
	const [numberOfInventory, setNumberOfInventory] = useState<number>(0)

	const [createModal, setCreateModal] = useState<boolean>(false)

	const [filters, setFilters] = useState<FiltersType>({ laboratory })

	const { data: inventories, isLoading } = useQuery(
		["INVENTORY_LIST_LAB1", page, filters],
		async () => {
			let queryFilter = ""

			if (filters.laboratory?.id) queryFilter += `&laboratory=${filters.laboratory.id}`
			if (filters?.isValidated != null) queryFilter += `&validatedAt[null]=${!filters?.isValidated}`

			const result = await API.findAll<Inventory[]>(
				"INVENTORY_API",
				`?page=${page}&order[createdAt]=DESC` + queryFilter,
				true
			)

			setNumberOfInventory(result?.["hydra:totalItems"])
			return (result?.["hydra:member"] ?? []).map((m) => ({ ...m, key: uuid(), inventoryState: "NONE" }))
		},
		{ keepPreviousData: false, cacheTime: 0 }
	)

	const columns = [
		{
			title: "Date",
			dataIndex: "createdAt",
			render: (createdAt: Date) => dayjs(createdAt).format("DD/MM/YY, HH[h]mm"),
			className: "w-15",
		},
		{
			title: "Dernière modification",
			dataIndex: "modifiedAt",
			render: (modifiedAt: Date) => dayjs(modifiedAt).format("DD/MM/YY, HH[h]mm"),
			className: "w-15",
		},
		{
			title: "Laboratoire",
			dataIndex: "laboratory",
			render: (laboratory: Laboratory) => laboratory.label,
			className: "w-15",
		},
		{
			title: "Type",
			dataIndex: "type",
			render: (type: string) => {
				if (type === "ALL") return "Tout"
				if (type === "NO_DEPOT") return "Ferme"
				if (type === "ONLY_DEPOT") return "Dépôt"
			},
			className: "w-10",
		},
		{ title: "Label", dataIndex: "label", className: "font-weight-bold" },

		{
			title: "État",
			dataIndex: "validatedAt",
			render: (validatedAt: Date | null) => {
				if (validatedAt) {
					return <Badge text="Terminé" color="primary" />
				}
				return <Badge text="En attente" color="warning" />
			},
			className: "w-10",
		},
		{
			title: "Date de validation",
			dataIndex: "validatedAt",
			render: (validatedAt: Date) => validatedAt && dayjs(validatedAt).format("DD/MM/YY, HH[h]mm"),
			className: "w-15",
		},
		{
			title: "-",
			dataIndex: "validatedAt",

			render: (validatedAt: Date, inventory: Inventory) =>
				!validatedAt &&
				!inventory.hiboutikSaleId &&
				!inventory.hiboutikInventoryInputId && (
					<DeleteLogisticForm
						logisticForm={inventory}
						withLabel={false}
						onFinish={() => {
							queryClient.invalidateQueries(["INVENTORY_LIST_LAB1"])
						}}
					/>
				),
			align: "center",
		},
	]

	return (
		<>
			<Filters setFilters={setFilters} filters={filters} />
			<SectionHeader title="Liste des inventaires" />
			<ButtonRounded
				icon="fa-plus"
				color="primary-outlined"
				type="button"
				className="mb-3"
				onClick={() => {
					setCreateModal(true)
				}}>
				Nouvel inventaire
			</ButtonRounded>
			<Table
				dataSource={inventories}
				//@ts-ignore
				columns={columns}
				loading={isLoading}
				pagination={{
					total: numberOfInventory,
					current: page,
					onChange: (page: number) => setPage(page),
				}}
				onRow={(record) => {
					return {
						onClick: (event) => {
							const target = event.target as HTMLElement

							if (target?.tagName !== "TD") return
							history.push("/mon-stock/inventaire/" + record.id)
						},
					}
				}}
				rowClassName="cursor-pointer"
			/>
			<ModalCreateNewInventory isOpen={createModal} setOpen={setCreateModal} />
		</>
	)
}

export default InventoryList
