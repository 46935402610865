import "../../assets/scss/Modals/DocumentationModal.scss"

type DocumentationModalProps = {
	documentationUrl: string
}

export function DocumentationModalData({ documentationUrl }: DocumentationModalProps): JSX.Element {
	return (
		<div className="documentation-container">
			{!documentationUrl ? (
				<div className="loading-documentation">
					{/* <Spinner /> */}
					<div className="overlay-loading-aw">
						<div className="overlay-loading-logo" />
						<div className="overlay-loading-text">Recherche de la documentation...</div>
					</div>
				</div>
			) : (
				<>
					<div className="d-flex justify-content-center  my-2">
						<a
							className="btn btn-outline-primary"
							href={documentationUrl}
							target="_blank"
							rel="noreferrer noopener">
							<i className="fad fa-external-link mx-1" />
							Ouvrir dans un nouvel onglet
						</a>
					</div>

					<div className="documentation-data">
						<iframe
							className="document-iframe"
							src={documentationUrl}
							title="informations"
							frameBorder="0"
							width="100%"
							height="100%"
						/>
					</div>
				</>
			)}
		</div>
	)
}
