import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import { HiboutikProductEntity, LogisticProduct, PatientEquipment, TransferProduct } from "@audiowizard/common"
import { Table } from "antd"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import ReactTooltip from "react-tooltip"
import uuid from "react-uuid"
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import API from "services/API"
import { getAvailableBrands, getAvailableCategories, getAvailableSuppliers } from "../Shared/Utils"
import {
	AvailableSerialNumberType,
	extractAvailableSerialnumber,
	extractAvailableWithoutSerialnumber,
} from "../StockUtils"

type Filters = {
	supplierId: null | number
	categoryId: null | number
	brandId: null | number
	model: null | string
	serialNumber: null | string
}

interface customAvailableStock extends AvailableSerialNumberType {
	productToAdd: boolean
	quantityToAdd: number
}

interface IModalAddProducts {
	isOpen: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	setSelectedProduct: Dispatch<SetStateAction<Record<string, any>[]>>
	currentProducts: TransferProduct[]
	warehouseId: number | undefined
}

const ModalAddProducts = ({
	isOpen,
	setOpen,
	setSelectedProduct,
	currentProducts,
	warehouseId,
}: IModalAddProducts): JSX.Element => {
	const [availableProduct, setAvailableProduct] = useState<customAvailableStock[]>([])

	const [filters, setFilters] = useState<Filters>({
		supplierId: null,
		categoryId: null,
		brandId: null,
		model: null,
		serialNumber: null,
	})

	const searchCorresponding = (product: AvailableSerialNumberType): boolean => {
		const existing = currentProducts.filter((f) => {
			if (f.productId === product.id && f.serialNumber === product.serialNumber) return true
			return false
		})

		return existing.length === 0
	}

	const getCurrentTransferQuantity = (product: customAvailableStock): null | number => {
		if (product.serialNumber) return null

		const currentProductInTransfer = currentProducts.find((available) => available.productId === product.id)

		return currentProductInTransfer?.quantity ?? null
	}

	const { data: stockAvailable, isLoading } = useQuery(
		["STOCK_AVAILABLE_TRANSFER_MODAL", warehouseId],
		async () => {
			if (!warehouseId) {
				return []
			}
			const result = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${warehouseId}`
			)

			const withoutSn = extractAvailableWithoutSerialnumber(result)
			const withSn = extractAvailableSerialnumber(result)
			const quantityOnly = result.filter((f: any) => f.stockManagement && !f.stockAvailable?.length)

			const tmp = [...withSn, ...withoutSn, ...quantityOnly]

			return tmp
		},
		{ staleTime: 0, cacheTime: 0 }
	)

	useEffect(() => {
		setAvailableProduct(
			(stockAvailable ?? [])?.map((stock, index) => ({
				...stock,
				key: uuid(),
				arrayKeyIndex: index,
				quantityToAdd: getCurrentTransferQuantity(stock),
				productToAdd: getCurrentTransferQuantity(stock) ? true : false,
			}))
		)
	}, [stockAvailable, currentProducts])

	useEffect(() => {
		if (!isOpen) return

		setAvailableProduct(availableProduct.map((p) => ({ ...p, productToAdd: false })))
	}, [isOpen])

	const columns = [
		{
			title: "-",
			dataIndex: "supplierName",
			className: "col-2",
			render: (supplierName: string | null, product: HiboutikProductEntity) => (
				<>
					{product.categoryName ?? <span style={{ opacity: "0.70" }}>Pas de catégorie</span>}
					<br />
					{supplierName ?? <span style={{ opacity: "0.70" }}>Pas de fournisseur</span>}
				</>
			),
		},
		{
			className: "col-4",
			title: "Modèle",
			dataIndex: "model",
			render: (model: string, product: Record<string, any>) => (
				<>
					<div className="font-weight-bold">{model}</div>
					<div>{product.sizeName ?? <span style={{ opacity: "0.70" }}>Pas de déclinaison</span>}</div>
				</>
			),
		},
		{
			className: "col-2",
			title: "Numéro de série",
			dataIndex: "serialNumber",
			render: (serialNumber: string) => (
				<>{serialNumber ?? <span style={{ opacity: "0.75" }}>Sans numéro de série</span>}</>
			),
		},
		{
			className: "col-1",
			title: "Dépôt",
			dataIndex: "logisticProduct",
			align: "center",
			render: (logisticProduct: LogisticProduct) => {
				return logisticProduct?.isDepot ? (
					<CheckCircleFilled style={{ fontSize: "22px", color: "var(--primary)" }} />
				) : (
					<CloseCircleFilled style={{ fontSize: "22px", color: "var(--warning-light)" }} />
				)
			},
		},
		{
			title: "En essai / en SAV",
			className: "col-1",
			dataIndex: "patientEquipment",
			align: "center",
			render: (patientEquipment: PatientEquipment) => {
				const getLabel: { [key: string]: string } = {
					ESSAI: "En essai sur",
					SAV: "Dans un SAV pour",
				}

				return (
					<>
						<ReactTooltip type="warning" />
						{patientEquipment?.status && ["ESSAI", "SAV"].includes(patientEquipment.status) ? (
							<CheckCircleFilled
								style={{ fontSize: "22px", color: "var(--warning-light)" }}
								data-tip={`${getLabel[patientEquipment.status]} ${
									patientEquipment.patient?.firstName
								} ${patientEquipment.patient?.lastName}`}
							/>
						) : (
							<CloseCircleFilled style={{ fontSize: "22px", color: "var(--secondary-dark)" }} />
						)}
					</>
				)
			},
		},
		{
			className: "col-1",
			title: "Quantité",
			dataIndex: "quantity",
			align: "center",
			render: (quantity: number, product: Record<string, any>) => (
				<span style={{ opacity: product.serialNumber ? ".5" : "1" }}>
					{quantity ?? product.quantityAvailable}
				</span>
			),
		},
		{
			className: "col-1 ",
			align: "center",
			title: "Ajouter",
			render: (_void: void, product: Record<string, any>) => {
				const quantity = product.quantity ?? product.quantityAvailable
				const tmp = [...availableProduct]

				if (product.serialNumber) {
					return (
						<Button
							color="primary"
							outline={!product.productToAdd}
							size="sm"
							block
							onClick={() => {
								if (quantity > 1 && !product.serialNumber) {
								} else {
									tmp[product.arrayKeyIndex].productToAdd = !tmp[product.arrayKeyIndex].productToAdd
								}
								setAvailableProduct(tmp)
							}}>
							{product.productToAdd ? "Retirer" : "Ajouter"}
						</Button>
					)
				} else {
					return (
						<Input
							type="number"
							defaultValue={0}
							value={product.quantityToAdd ?? 0}
							min={0}
							max={quantity}
							bsSize="sm"
							className={
								product.productToAdd
									? "bg-primary text-white text-center font-weight-bold"
									: "border border-primary border-1 text-primary text-center"
							}
							onChange={(evt) => {
								let value = +evt.target.value

								if (value > quantity) {
									value = quantity
								} else if (value < 0) {
									value = 0
								}

								tmp[product.arrayKeyIndex].productToAdd = value > 0
								tmp[product.arrayKeyIndex].quantityToAdd = value

								setAvailableProduct(tmp)
							}}
						/>
					)
				}
			},
		},
	]

	const productToAddCount = (availableProduct ?? [])
		?.filter((f: any) => f.productToAdd)
		.reduce((prev, curr) => prev + (curr.quantityToAdd ?? 1), 0)

	const handleValidate = (): void => {
		setSelectedProduct(availableProduct.filter((product) => product.productToAdd))
		setOpen(false)
	}

	const SelectFilter = ({
		data,
		currentValue,
		selectedKey,
	}: {
		data: any[]
		currentValue: any
		selectedKey: any
	}): JSX.Element => {
		return (
			<select
				className="form-control"
				value={currentValue ?? "null"}
				onChange={(evt) => {
					const value = evt.target.value
					setFilters((old: any) => ({ ...old, [selectedKey]: value === "null" ? null : value }))
				}}>
				<option value="null">Tout voir</option>
				{data.map((value, key) => (
					<option value={value.id} key={key}>
						{value.label}
					</option>
				))}
			</select>
		)
	}

	const availableSupplier = useMemo(() => getAvailableSuppliers(availableProduct ?? []), [availableProduct])
	const availableCategories = useMemo(() => getAvailableCategories(availableProduct ?? []), [availableProduct])
	const availableBrands = useMemo(() => getAvailableBrands(availableProduct ?? []), [availableProduct])

	const filteredDisplayData = useMemo(() => {
		let tmp = [...(availableProduct ?? [])]

		tmp = tmp.filter((product) => {
			return searchCorresponding(product)
		})

		if (filters.serialNumber) {
			tmp = tmp.filter((product) =>
				product.serialNumber?.toLowerCase().includes(filters.serialNumber!.toLowerCase())
			)
		}

		if (filters.model) {
			tmp = tmp.filter((product) => product.model.toLowerCase().includes(filters.model!.toLowerCase()))
		}

		if (filters.supplierId) tmp = tmp.filter((product) => product.supplierId === +filters.supplierId!)
		if (filters.categoryId) tmp = tmp.filter((product) => product.categoryId === +filters.categoryId!)
		if (filters.brandId) tmp = tmp.filter((product) => product.brandId === +filters.brandId!)

		return tmp
	}, [availableProduct, filters])

	return (
		<>
			<Modal isOpen={isOpen} size="xl" className="modal-loading">
				<ModalHeader>Ajouter des produits</ModalHeader>
				<ModalBody>
					<div className="cardtabs-subtitle">Filtres</div>
					<div className="row">
						<div className="col">
							<label>Catégorie</label>
							<SelectFilter
								data={availableCategories}
								selectedKey="categoryId"
								currentValue={filters.categoryId}
							/>
						</div>
						<div className="col">
							<label>Fournisseur</label>
							<SelectFilter
								data={availableSupplier}
								selectedKey="supplierId"
								currentValue={filters.supplierId}
							/>
						</div>
						<div className="col">
							<label>Marque</label>
							<SelectFilter data={availableBrands} selectedKey="brandId" currentValue={filters.brandId} />
						</div>
						<div className="col">
							<label>Modèle</label>
							<input
								type="text"
								className="form-control"
								placeholder="Audeo"
								value={filters.model ?? ""}
								onChange={(evt) => {
									const model = evt.target.value
									setFilters((old: any) => ({ ...old, model }))
								}}
							/>
						</div>
						<div className="col">
							<label>Numéro de série</label>
							<input
								type="text"
								className="form-control"
								placeholder="324B21"
								value={filters.serialNumber ?? ""}
								onChange={(evt) => {
									const serialNumber = evt.target.value
									setFilters((old: any) => ({ ...old, serialNumber }))
								}}
							/>
						</div>
					</div>
					<div className="cardtabs-subtitle">Produits</div>
					<Table
						size="small"
						dataSource={filteredDisplayData}
						//@ts-ignore
						columns={columns}
						pagination={{ pageSize: 6 }}
						disabled={isLoading}
						loading={isLoading}
						style={{ minHeight: "465px" }}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="danger" outline onClick={() => setOpen(false)}>
						Annuler
					</Button>
					<Button
						color="primary"
						style={{ minWidth: "200px" }}
						disabled={!productToAddCount}
						onClick={handleValidate}>
						Ajouter {productToAddCount} produits
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default ModalAddProducts
