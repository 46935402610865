import { LeftBar } from "awui"
import { useHistory } from "react-router-dom"
import "../../awui/LeftBar/LeftBar.scss"
import FrillWidget from "components/frill/FrillWidget"
import useHasRole from "components/Hooks/useHasRole"

const { Link, Separator } = LeftBar

const AccountManagementLeftBar = (): JSX.Element => {
	const history = useHistory()
	const isManager = useHasRole("ROLE_MANAGER")
	const isManagerFranchised = useHasRole("ROLE_MANAGER_FRANCHISED")

	return (
		<LeftBar>
			<Separator />
			<Link to="/gestion-compte/profil/informations" icon="user" label="Profil" />

			{(isManager || isManagerFranchised) && (
				<Link to="/gestion-compte/entreprise/informations" icon="building" label="Entreprise" />
			)}

			<Link to="/gestion-compte/laboratoires/planning" icon="hospital" label="Laboratoire" />

			{(isManager || isManagerFranchised) && (
				<Link to="/gestion-compte/equipe/gestion" icon="users" label="Équipe" />
			)}

			{(isManager || isManagerFranchised) && (
				<Link to="/gestion-compte/abonnement/choix-offre" icon="handshake" label="Abonnements" />
			)}

			<Link to="/gestion-compte/documents/liste-documents" icon="file-contract" label="Documents" />
			<Link to="/gestion-compte/invitations/mes-invitations" icon="mail-bulk" label="Invitations" />
			<Link to="/gestion-compte/partenaires-wizard" icon="hands-helping" label="Partenaires" />

			<Separator />
			<button onClick={() => history.push("/")} className="leftbar__link leftbar__link--collapser">
				<span className="leftbar__link__icon fad fa-fw fa-long-arrow-left" />
				<span className="leftbar__link__label">Quitter</span>
			</button>
			<FrillWidget />
		</LeftBar>
	)
}

export default AccountManagementLeftBar
