export const MarketingRoutePrefix = "/marketing"

export const MarketingRoutes = {
	NouveauPatient: `${MarketingRoutePrefix}/nouveau-patient`,
	FideliserPatient: `${MarketingRoutePrefix}/fideliser-patient`,
	CommunicationMagique: `${MarketingRoutePrefix}/communication-magique`,
	PipeMarketing: `${MarketingRoutePrefix}/pipe-marketing`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(MarketingRoutePrefix, "")
}
