import { lazy } from "react"
import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, SavRoutePrefix, SavRoutes } from "./routes"

const NouveauSAV = lazy(() => import("../../pages/sav/NouveauSAV"))
const ListeSAV = lazy(() => import("../../pages/sav/ListeSAV"))

function SavRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={SavRoutePrefix}>
			<CardItem name="SAV" url={noPrefix(SavRoutes.List)} component={ListeSAV} />
			<CardItem name="Nouveau SAV" url={noPrefix(SavRoutes.New)} component={NouveauSAV} onlyVisibleOnExactRoute />
			<CardItem
				name="SAV"
				url={noPrefix(SavRoutes.Edit)}
				component={NouveauSAV}
				useSlug="numero"
				onlyVisibleOnExactRoute
			/>
		</CardWithTabs>
	)
}

export default SavRouter
