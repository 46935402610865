/* eslint-disable react-hooks/exhaustive-deps */
import BryntumAgenda from "./BryntumAgenda"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import AuthContext from "contexts/AuthContext"
import API from "services/API"
import { Schedule } from "@audiowizard/common"
import { useQueryClient } from "react-query"
import { EvtData } from "./helpers/AgendaTypes"

interface AgendaModalProps {
	setShow: Dispatch<SetStateAction<boolean>>
	setSelectedDate?: (d: Date[]) => void
	setSelectedLaboratory?: (l: number) => void
	setSelectedUser?: (u: number) => void
	defaultStartDate?: Date
	appointmentFromScheduleSelector?: EvtData
	patientId?: number
	setSelectedSchedule?: Dispatch<SetStateAction<EvtData>>
	fromDashboard?: boolean
	fromAgenda?: boolean
}

export default function AgendaModal({
	setShow,
	setSelectedDate,
	setSelectedLaboratory,
	setSelectedUser,
	defaultStartDate,
	appointmentFromScheduleSelector,
	setSelectedSchedule,
	fromDashboard = false,
}: AgendaModalProps): JSX.Element {
	const [tempDates, setTempDates] = useState<Date[]>([])
	const { patient, setPatient } = useContext(AuthContext)

	const queryClient = useQueryClient()

	return (
		<div className="modal-agenda bg-gradient-primary">
			<div className={"container full-screen"}>
				<div className="title ">
					Agenda
					<span
						className="close-btn"
						onClick={async () => {
							// On change la date à la fermeture de l’agenda sinon cela fait une boucle entre l’agenda et le dashboard
							if (fromDashboard && tempDates.length && setSelectedDate) {
								setSelectedDate(tempDates)
							}
							if (patient && patient["@id"]) {
								queryClient.invalidateQueries(["SCHEDULES_API"])
								queryClient.invalidateQueries("PATIENTS_NOTES_AND_SCHEDULES_API")
								// rafraichir rdv du patient
								const schedulesFromPatient = await API.find<{ schedules: Schedule[] }>(
									"PATIENTS_API",
									patient["@id"].split("/")[2]
								)
								setPatient((old) => ({ ...old, schedules: schedulesFromPatient.schedules }))
							}
							setShow(false)
						}}>
						fermer
					</span>
				</div>
				<div className="content">
					<BryntumAgenda
						fromDashboard={fromDashboard}
						defaultStartDate={defaultStartDate}
						appointmentFromScheduleSelector={appointmentFromScheduleSelector}
						setSelectedDate={(dates) => {
							if (!fromDashboard && setSelectedDate) {
								setSelectedDate(dates)
							} else {
								setTempDates(dates)
							}
						}}
						setSelectedLaboratory={setSelectedLaboratory}
						setSelectedUser={setSelectedUser}
						setShow={setShow}
						setSelectedSchedule={setSelectedSchedule}
					/>
				</div>
			</div>
		</div>
	)
}
