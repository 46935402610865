import {
	CheckOutlined,
	CloseOutlined,
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	MenuOutlined,
} from "@ant-design/icons"
import { QuotePack } from "@audiowizard/common"
import { Button as AntdButton, Popconfirm, Select, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import axios from "axios"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { QUOTE_PACKS_API } from "config"
import React, { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { SortableContainer as ReactSortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import { toast } from "react-toastify"
import { Badge, Col } from "reactstrap"
import API from "services/API"
import { partialSearch } from "services/functions"
import { AnnexesListModal, AnnexesPickerModal } from "./AnnexesModal"
import "./QuotationPackList.scss"

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />)

type QuotePackDeleteButtonProps = {
	quotePack: QuotePack
}
function QuotePackDeleteButton({ quotePack }: QuotePackDeleteButtonProps): JSX.Element {
	const queryClient = useQueryClient()

	const { mutate: deletePack, isLoading } = useMutation((iri: string) => API.delete(iri), {
		onSuccess: () => queryClient.invalidateQueries("QUOTE_PACKS_API"),
		onError: () => {
			toast.error("Erreur lors de la suppression du pack.")
		},
	})
	return (
		<Popconfirm
			placement="top"
			title={"Supprimer le pack ?"}
			onConfirm={() => deletePack(quotePack["@id"])}
			okType="danger"
			okText="Supprimer"
			cancelText="Annuler">
			<Tooltip title="Supprimer" trigger="hover">
				<AntdButton danger loading={isLoading} icon={<DeleteOutlined />} />
			</Tooltip>
		</Popconfirm>
	)
}

const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainer = ReactSortableContainer((props: any) => <tbody {...props} />)

type QuotePackWithIndexAndKey = QuotePack & {
	index: number
	key: string
}

const QuotationPackList: React.FC = () => {
	const queryClient = useQueryClient()
	const history = useHistory()
	const [annexListModalOpen, setAnnexListModalOpen] = useState(false)
	const [annexPickerModal, setAnnexPickerModal] = useState<{ open: boolean; quotePack: QuotePack | null }>({
		open: false,
		quotePack: null,
	})
	const [quotePacks, setQuotePack] = useState<QuotePackWithIndexAndKey[]>([])
	const { data: fetchedPacks, isLoading } = useQuery<QuotePackWithIndexAndKey[]>(
		["QUOTE_PACKS_API", { pagination: false }],
		async () => {
			// Fetch every page
			let page = 1
			let totalItems = Infinity
			const packs = []

			while (packs.length < totalItems) {
				const response = await API.findAll<QuotePackWithIndexAndKey[]>("QUOTE_PACKS_API", `?page=${page}`, true)

				page++
				totalItems = response["hydra:totalItems"]
				packs.push(...response["hydra:member"])
			}
			return packs
		},
		{
			staleTime: Infinity, // to avoid re-fetching twice
			select: (quotePacks: QuotePack[]) => quotePacks.map((q, index) => ({ ...q, index, key: q["@id"] })), // Add index and key properties
		}
	)
	useEffect(() => {
		if (fetchedPacks == null) return

		setQuotePack(fetchedPacks)
	}, [fetchedPacks])

	const { mutate: updateOrder, isLoading: isLoadingUpdateOrder } = useMutation(
		async (orderedPacks: QuotePack[]) => {
			const newOrder = orderedPacks.map((d) => d["@id"])
			await axios.put(QUOTE_PACKS_API + "/order", newOrder)
		},
		{
			onSuccess: () => queryClient.invalidateQueries(["QUOTE_PACKS_API"]),
			onError: () => {
				toast.error("Erreur lors de la mise à jour des packs.")
			},
		}
	)

	const columns: ColumnsType<QuotePack> = useMemo(
		() => [
			{
				title: "Trier",
				dataIndex: "sort",
				width: 30,
				className: "drag-visible text-center",
				render: () => <DragHandle />,
			},
			{
				title: "Nom du pack",
				dataIndex: "label",
				className: "drag-visible",
			},
			{
				title: "Annexes",
				dataIndex: "annexes",
				className: "text-center drag-hidden",
				render: (_, record) => (
					<ButtonRounded
						icon="fa-file-medical"
						color="primary-outlined"
						onClick={() => setAnnexPickerModal({ open: true, quotePack: record })}>
						Éditer les annexes
					</ButtonRounded>
				),
			},
			{
				title: "Couleur",
				dataIndex: "color",
				align: "center",
				width: "2rem",
				className: "drag-hidden",
				render: (color: string) => (
					<Badge
						className="d-inline-block"
						style={{ backgroundColor: color, width: "1rem", height: "1rem" }}
					/>
				),
			},
			{
				title: "Pack par défaut",
				dataIndex: "isDefault",
				align: "center",
				width: "9rem",
				className: "drag-hidden",
				render: (isDefault: boolean) =>
					isDefault ? <CheckOutlined className="text-primary" /> : <CloseOutlined className="text-danger" />,
			},
			{
				title: "Actions",
				dataIndex: "actions",
				className: "text-center drag-hidden",

				render: (text, record) => (
					<Space>
						<Tooltip title="Dupliquer" trigger="hover">
							<AntdButton
								icon={<CopyOutlined />}
								onClick={() => history.push("/parametres/packs/dupliquer/" + record?.id)}
							/>
						</Tooltip>

						<Tooltip title="Modifier" trigger="hover">
							<AntdButton
								icon={<EditOutlined />}
								className="text-warning border-warning"
								onClick={() => history.push("/parametres/packs/edition/" + record?.id)}
							/>
						</Tooltip>
						<QuotePackDeleteButton quotePack={record} />
					</Space>
				),
				width: 100,
			},
		],
		[]
	)

	const handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
		if (oldIndex === newIndex) return

		const orderedPacks = [...quotePacks]
		;[orderedPacks[oldIndex], orderedPacks[newIndex]] = [orderedPacks[newIndex], orderedPacks[oldIndex]] // swap
		setQuotePack(orderedPacks)

		updateOrder(orderedPacks)
	}

	const { mutate: toggleDefault, isLoading: toggleLoading } = useMutation(
		async (args: { packIri: string; isDefault: boolean }) => {
			await API.updateWithIri(args.packIri, { isDefault: args.isDefault })
		},
		{
			onSuccess: () => queryClient.invalidateQueries(["QUOTE_PACKS_API"]),
			onError: () => {
				toast.error("Impossible de changer le pack par défaut.")
			},
		}
	)

	const defaultPack = useMemo<QuotePackWithIndexAndKey | null>(() => {
		return quotePacks.find((pack) => pack.isDefault) ?? null
	}, [quotePacks])

	return (
		<Col>
			<SectionHeader title="Editer vos packs">
				<>
					<ButtonRounded
						className="mr-3"
						icon="fa-plus"
						type="button"
						color="primary-outlined"
						onClick={() => history.push("/parametres/packs/creation")}>
						Créer un pack
					</ButtonRounded>
					<ButtonRounded
						className="ml-3"
						icon="fa-file-medical"
						type="button"
						color="primary-outlined"
						onClick={() => setAnnexListModalOpen(true)}>
						Gestion des annexes
					</ButtonRounded>
				</>
			</SectionHeader>
			<Space direction="vertical" className="p-2 w-100">
				<div className="d-flex flex-row">
					<div className="d-flex flex-column">
						<label>Pack par défaut</label>
						<Select
							options={[
								{ label: "Devis libre", value: null },
								...quotePacks.map((pack) => ({ label: pack.label, value: pack["@id"] })),
							]}
							value={defaultPack?.["@id"] ?? null}
							size="large"
							style={{ width: "400px" }}
							showSearch
							onChange={(value) => {
								if (!value && defaultPack)
									toggleDefault({ packIri: defaultPack["@id"], isDefault: false })
								else toggleDefault({ packIri: value, isDefault: true })
							}}
							allowClear
							onClear={() => {
								if (defaultPack) toggleDefault({ packIri: defaultPack["@id"], isDefault: false })
							}}
							filterOption={(inputValue, option) => partialSearch(option!.label!, inputValue)}
							loading={toggleLoading}
							disabled={toggleLoading}
						/>
					</div>
				</div>
				<Table
					dataSource={quotePacks}
					columns={columns}
					rowKey="index"
					pagination={false}
					loading={isLoading || isLoadingUpdateOrder}
					components={{
						body: {
							wrapper: (props: any) => (
								<SortableContainer
									useDragHandle
									disableAutoscroll
									helperClass="row-dragging"
									onSortEnd={handleSortEnd}
									{...props}
								/>
							),
							row: ({ className, style, ...props }: any) => {
								// function findIndex base on Table rowKey props and should always be a right array index
								const index = quotePacks.findIndex((q) => q.index === props["data-row-key"])
								return <SortableItem index={index} {...props} />
							},
						},
					}}
				/>

				<AnnexesListModal open={annexListModalOpen} onClose={() => setAnnexListModalOpen(false)} />
				<AnnexesPickerModal
					open={annexPickerModal.open}
					onClose={() => setAnnexPickerModal({ open: false, quotePack: null })}
					quotePack={annexPickerModal.quotePack}
					openAnnexList={() => setAnnexListModalOpen(true)}
				/>
			</Space>
		</Col>
	)
}
export default QuotationPackList
