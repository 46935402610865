import { InventoryProduct, TransferProduct } from "@audiowizard/common"
import { AnonymousFunction } from "constants/types/anonymousFunction"
import { Dispatch, SetStateAction } from "react"
import { toast } from "react-toastify"
import API from "services/API"

interface UnaivalableProductsAlertAction {
	labelAlert: string
	products: InventoryProduct[] | TransferProduct[]
	setLoading: Dispatch<SetStateAction<boolean>>
	onFinish: AnonymousFunction
	hasUnaivalableProducts: boolean
}

const ClearUnaivalableProductsButton = ({
	labelAlert,
	products,
	setLoading,
	onFinish,
	hasUnaivalableProducts,
}: UnaivalableProductsAlertAction): JSX.Element => {
	if (!hasUnaivalableProducts) {
		return <></>
	}

	return (
		<div className="alert alert-danger mt-3 mb-3">
			<div>
				{labelAlert} contient des produits qui ne sont plus disponible. Veuillez les retirer pour pouvoir
				continuer.
			</div>
			<div>
				<button
					type="button"
					className="btn btn-danger btn-sm mt-2"
					onClick={async () => {
						//@ts-ignore
						const notAvailableProducts = products.filter((product) => product.notAvailableAnymore)

						try {
							setLoading(true)

							for (const product of notAvailableProducts) {
								await API.delete(product["@id"])
							}
							setLoading(false)
							onFinish()
						} catch (_void) {
							toast.error("Impossible de supprimer les produits non disponible..")
						}
					}}>
					Retirer les produits non disponible
				</button>
			</div>
		</div>
	)
}

export default ClearUnaivalableProductsButton
