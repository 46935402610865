import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { LaboratoiresRoutePrefix, LaboratoiresRoutes, noPrefix } from "./routes"

import useCustomTitle from "components/Hooks/useTitle"
import PresenceLaboratoire from "pages/mon-compte/PresenceLaboratoire"
import MesLaboratoires from "pages/mon-compte/MesLaboratoires"
import CrudAgenda from "pages/mon-compte/Agenda/CrudAgenda"

function LaboratoiresRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Laboratoires")
	return (
		<CardWithTabs urlprefix={LaboratoiresRoutePrefix}>
			<CardItem
				name="Les plannings"
				component={PresenceLaboratoire}
				url={noPrefix(LaboratoiresRoutes.Planning)}
			/>
			<CardItem
				name="Les laboratoires"
				component={MesLaboratoires}
				url={noPrefix(LaboratoiresRoutes.ListeLaboratoires)}
			/>
			<CardItem name="Les agendas" component={CrudAgenda} url={noPrefix(LaboratoiresRoutes.Agenda)} />
		</CardWithTabs>
	)
}

export default LaboratoiresRouter
