import ButtonRounded from "../../../../components/Buttons/ButtonRounded"
import "./UserInsuranceNetworkButton.scss"

interface Props {
	isPresent?: boolean
}

export default function UserInsuranceNetworkButton({ isPresent = false }: Props) {
	return (
		<ButtonRounded color={isPresent ? "primary-outlined" : "secondary-outlined"} className={"btn-network"}>
			{isPresent ? "Éditer" : "Ajouter vos informations"}
		</ButtonRounded>
	)
}
