import React from "react"
import { Link } from "react-router-dom"
import { getIdFromIri } from "services/functions"

type SerialNumberResultsProps = {
	product: any
	onClick: () => void
}

const SerialNumber = ({ product }: { product: any }): JSX.Element => {
	if (product.type === "history") {
		return (
			<div className="">
				<del className="font-weight-bold">{product.oldSerialNumber}</del>
				{" → "}
				<span className="font-weight-bold">{product.serialNumber}</span>
			</div>
		)
	}

	if (product.type === "patientEquipment") {
		return <span className="font-weight-bold">{product.patientEquipment?.serialNumber}</span>
	}

	return <span className="font-weight-bold">{product.serialNumber}</span>
}

const PatientEquipment = ({ product, onClick }: { product: any; onClick: () => void }): JSX.Element | null => {
	const patientEquipment = product.patientEquipment
	const patient = patientEquipment?.patient

	const label: { [key: string]: string } = {
		VENDU: "Vendu à",
		ESSAI: "En essai sur",
		SAV: "En SAV sur",
	}

	return (
		<Link to={"/fiche-patient/" + getIdFromIri(patient["@id"])} onClick={onClick}>
			<i className="mr-1 fad fa-hospital-user" />
			{label?.[patientEquipment.status] ?? patientEquipment.status} {patient?.lastName} {patient?.firstName}
		</Link>
	)
}

const InventoryOrder = ({ onClick, logistic }: { onClick: () => void; logistic: any }): JSX.Element | null => {
	return (
		<Link to={"/bon-de-livraison/" + getIdFromIri(logistic["@id"])} onClick={onClick}>
			<i className="mr-1 fad fa-clipboard" />
			{logistic.label}
		</Link>
	)
}

const ReturnForm = ({ product, onClick }: { product: any; onClick: () => void }): JSX.Element | null => {
	return (
		<Link
			to={"/mon-stock/retour/" + getIdFromIri(product.logisticProduct.productReturnForm["@id"])}
			onClick={onClick}>
			<i className="mr-1 fad fa-clock" />
			{product.logisticProduct.productReturnForm.label}
		</Link>
	)
}

export const SerialNumberResults = ({ product, onClick }: SerialNumberResultsProps): JSX.Element => {
	const logistic = product?.logisticProduct?.logistic ?? product?.patientEquipment?.logisticProduct?.logistic

	return (
		<div className="item flex-column">
			<div>
				<span className="mr-1">
					<i className="fad fa-barcode" />
				</span>
				<span className="mr-1">{product?.model}</span>
				{product?.sizeName && <span className="mr-1">({product?.sizeName?.trim()})</span>}
				{product.type === "available" && <span>(en stock)</span>}
			</div>
			<div>
				<SerialNumber product={product} />
			</div>
			{product.patientEquipment && (
				<div>
					<PatientEquipment product={product} onClick={onClick} />
				</div>
			)}
			{logistic && (
				<div>
					<InventoryOrder logistic={logistic} onClick={onClick} />
				</div>
			)}
			{product?.logisticProduct?.productReturnForm && (
				<div>
					<ReturnForm product={product} onClick={onClick} />
				</div>
			)}
		</div>
	)
}
