import { User } from "@audiowizard/common"
import ButtonRounded from "components/Buttons/ButtonRounded"
import UserRegistrationStatusSelect, {
	AudioStatus,
} from "components/forms/UserRegistrationStatusSelect/UserRegistrationStatusSelect"
import { Dispatch, SetStateAction, useState } from "react"
import { toast } from "react-toastify"
import { Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from "reactstrap"
import API from "services/API"

interface ModalSelectUserRegistrationStatusProps {
	isOpen: boolean
	user: User
	setUser: Dispatch<SetStateAction<User>>
	onClose: () => void
}

const ModalSelectUserRegistrationStatus = ({
	isOpen,
	user,
	setUser,
	onClose,
}: ModalSelectUserRegistrationStatusProps): JSX.Element => {
	const [registrationStatus, setRegistrationStatus] = useState<AudioStatus>()

	const handleSubmit = async (): Promise<void> => {
		if (registrationStatus) {
			try {
				await API.patch("USERS_API", user.id as number, { registrationStatus })
				setUser((oldUser) => ({ ...oldUser, registrationStatus }))
				onClose()
			} catch (error) {
				console.error(error)
				toast.error("Erreur lors de l’enregistrement du status")
			}
		}
	}

	return (
		<Modal isOpen={isOpen} centered>
			<ModalHeader>Veuillez sélectionner votre status</ModalHeader>
			<ModalBody>
				<Row>
					<Col sm={12}>
						<UserRegistrationStatusSelect
							registrationStatus={registrationStatus}
							roles={user.roles}
							errorRegistrationStatus={""}
							handleChangeRegistrationStatus={(status: AudioStatus) => setRegistrationStatus(status)}
						/>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<ButtonRounded color="primary" onClick={handleSubmit}>
					Sélectionner
				</ButtonRounded>
			</ModalFooter>
		</Modal>
	)
}

export default ModalSelectUserRegistrationStatus
