import { lazy } from "react"
import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, StatsRoutePrefix, StatsRoutes } from "./routes"

const Stats = lazy(() => import("../../pages/Stats/Index"))
const Statistiques = lazy(() => import("../../pages/statistiques/Index"))

function StatsRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={StatsRoutePrefix}>
			<CardItem name="Statistiques métier" url={noPrefix(StatsRoutes.Metiers)} component={Statistiques} />
			<CardItem name="Rapports financiers" url={noPrefix(StatsRoutes.Financiers)} component={Stats} />
		</CardWithTabs>
	)
}

export default StatsRouter
