import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { UserInsuranceNetworkRefModel } from "../../../../services/requests/http/userInsuranceNetworks/userInsurance.model"
import FieldWithError from "../../../../components/forms/FieldWithError"
import { SyntheticEvent, useEffect, useState } from "react"
import useCreateUserInsuranceCredentials from "../../../../services/requests/http/userInsuranceCredentials/Hooks/useCreateUserInsuranceCredentials"
import useEditUserInsuranceCredentials from "../../../../services/requests/http/userInsuranceCredentials/Hooks/useEditUserInsuranceCredentials"
import useDeleteUserInsuranceCredentials from "../../../../services/requests/http/userInsuranceCredentials/Hooks/useDeleteUserInsuranceCredentials"

interface Props {
	credentials: UserInsuranceNetworkRefModel | null
	onClose: () => void
}

export default function UserInsuranceCredentialModal({ credentials, onClose }: Props) {
	const [login, setLogin] = useState(credentials?.login ?? "")
	const [password, setPassword] = useState("")

	const { mutateAsync: create, isLoading: isCreating } = useCreateUserInsuranceCredentials()
	const { mutateAsync: edit, isLoading: isEditing } = useEditUserInsuranceCredentials()
	const { mutateAsync: remove, isLoading: isRemoving } = useDeleteUserInsuranceCredentials()

	const disableVoter = isRemoving || isCreating || isEditing || password === "" || login === ""
	const isLoading = isCreating || isEditing

	const onRemove = () => {
		if (!credentials?.id) return

		remove(credentials.id).then(onClose)
	}

	useEffect(() => {
		if (credentials) {
			setLogin(credentials.login ?? "")
			setPassword("")
		}
	}, [credentials])

	const onSubmit = (ev: SyntheticEvent) => {
		ev.preventDefault()

		if (!credentials) return

		if (credentials.id) {
			edit({
				login,
				password,
				id: credentials.id,
			}).then(onClose)
		} else {
			create({
				login,
				password,
				network: credentials.iri,
			}).then(onClose)
		}
	}

	return (
		<Modal
			isOpen={Boolean(credentials)}
			toggle={onClose}
			className="insurance-modal"
			backdrop="static"
			size="lg"
			keyboard={true}>
			<ModalHeader>
				<div className="row">
					<div className="col-sm-11">Vos identifiants réseau {credentials?.network}</div>
					<div className="col-sm-1">
						<div
							className="icon-sm position-absolute"
							style={{ right: "16px", cursor: "pointer", color: "#ff5630" }}
							onClick={onClose}>
							<i className="fad fa-times-circle" />
						</div>
					</div>
				</div>
			</ModalHeader>
			<form onSubmit={onSubmit}>
				<ModalBody>
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<FieldWithError
								required
								name="login"
								value={login}
								onChange={(ev) => setLogin(ev.target.value)}
								label="Identifiant"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<FieldWithError
								name="password"
								label="Mot de passe"
								placeholder={credentials?.login ? "*********" : "Mot de passe"}
								type="password"
								value={password}
								onChange={(ev) => setPassword(ev.target.value)}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						outline
						color="danger"
						onClick={onRemove}
						disabled={isLoading || isRemoving || !credentials?.id}
						loading={isLoading}>
						Supprimer
					</Button>
					<Button outline color="primary" type="submit" loading={isLoading} disabled={disableVoter}>
						Valider
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	)
}
