import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, MarketingRoutePrefix, MarketingRoutes } from "./routes"

function StatsRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={MarketingRoutePrefix}>
			<CardItem
				name="Obtenir des nouveaux patient"
				url={noPrefix(MarketingRoutes.NouveauPatient)}
				component={() => <>À venir prochainement... </>}
			/>
			<CardItem
				name="Fidéliser mes patients"
				url={noPrefix(MarketingRoutes.FideliserPatient)}
				component={() => <>À venir prochainement... </>}
			/>
			<CardItem
				name="Communication magique"
				url={noPrefix(MarketingRoutes.CommunicationMagique)}
				component={() => <>À venir prochainement... </>}
			/>
			<CardItem
				name="Création de pipe marketing"
				url={noPrefix(MarketingRoutes.PipeMarketing)}
				component={() => <>À venir prochainement... </>}
			/>
		</CardWithTabs>
	)
}

export default StatsRouter
