import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Form, InputNumber, Row } from "antd"
import Title from "antd/lib/typography/Title"
import { useCategoriesQuery } from "components/Hooks/commonQueries"
import { AdminQuotePackOffer, AdminQuotePackProduct } from "features/edition-devis/admin/AdministrationInterface"
import _ from "lodash"
import { useMemo } from "react"
import { formatCurrency } from "services/functions"
import BoldList from "../components/BoldList"
import CurrencyInput from "../components/CurrencyInput"
import DiscountInput from "../components/DiscountInput"
import HiboutikProductSelect, { HiboutikCategoryName } from "../components/HiboutikProductSelect"
import AdministrationCollapse from "./AdministrationCollapse"

const emptyAdminQuotePackProduct: AdminQuotePackProduct = {
	"@id": null,
	productId: null,
	model: null,
	brandName: null,
	price: 0,
	quantity: 1,
	discountAmount: 0,
	discountScale: "€",
	isActive: true,
	isDefault: false,
}

type AdminPackProductRowProps = {
	productCategories?: HiboutikCategoryName[]
	showQuantity?: boolean
	disabledDefault?: boolean
	value: AdminQuotePackProduct
	onChange: (value: AdminQuotePackProduct) => void
	onDelete: () => void
}
function AdminPackProductRow({
	productCategories,
	showQuantity = false,
	disabledDefault = false,
	value,
	onChange,
	onDelete,
}: AdminPackProductRowProps): JSX.Element {
	const productError = value.productId == null
	const discountedPrice =
		value.price -
		(value.discountScale === "€" ? value.discountAmount : _.round((value.discountAmount / 100) * value.price, 2)) // Calculate flat discount from percentage)

	return (
		<Row gutter={16} className="my-2">
			<Col span={8}>
				<Form.Item
					validateStatus={productError ? "error" : undefined}
					help={productError ? "Veuillez sélectionner une référence." : undefined}>
					<HiboutikProductSelect
						className="w-100"
						placeholder="Sélectionner une référence"
						categories={productCategories}
						value={
							value.productId == null
								? undefined
								: ({ id: value.productId, model: value.model, brandName: value.brandName } as any)
						}
						onChange={(product) =>
							onChange({
								...value,
								productId: product.id,
								model: product.model,
								brandName: product.brandName,
								price: product.price,
							})
						}
					/>
				</Form.Item>
			</Col>
			<Col span={4}>
				<CurrencyInput
					className="w-100"
					value={value.price}
					onChange={(price) => onChange({ ...value, price: price ?? 0 })}
				/>
			</Col>
			<Col span={4}>
				<Form.Item
					help={
						<>
							Prix après remise : <strong>{formatCurrency(discountedPrice)}</strong>
						</>
					}>
					<DiscountInput
						className="w-100"
						value={{ amount: value.discountAmount, scale: value.discountScale }}
						price={value.price}
						onChange={(discount) =>
							onChange({ ...value, discountAmount: discount.amount!, discountScale: discount.scale! })
						}
					/>
				</Form.Item>
			</Col>
			{showQuantity && (
				<Col span={2}>
					<InputNumber
						className="w-100"
						min={1}
						value={value.quantity}
						onChange={(quantity) => onChange({ ...value, quantity: quantity ?? 0 })}
					/>
				</Col>
			)}
			<Col span={2} offset={showQuantity ? 0 : 2}>
				<Title level={5}>
					<Checkbox
						disabled={disabledDefault}
						checked={value.isDefault}
						onChange={(e) => onChange({ ...value, isDefault: e.target.checked })}>
						Par défaut
					</Checkbox>
				</Title>
			</Col>
			<Col span={2}>
				<Title level={5}>
					<Checkbox
						checked={value.isActive}
						onChange={(e) => onChange({ ...value, isActive: e.target.checked })}>
						Activer
					</Checkbox>
				</Title>
			</Col>
			<Col span={2}>
				<Button danger onClick={onDelete}>
					<DeleteOutlined />
				</Button>
			</Col>
		</Row>
	)
}

type AdminPackProductsFormProps = {
	productCategories?: HiboutikCategoryName[]
	canHaveMultipleDefaults?: boolean
	showQuantity?: boolean

	value: AdminQuotePackProduct[]
	onChange: (value: AdminQuotePackProduct[]) => void
}
function AdminPackProductsForm({
	productCategories,
	canHaveMultipleDefaults = false,
	showQuantity = false,
	value,
	onChange,
}: AdminPackProductsFormProps): JSX.Element {
	const handleProductUpdate = (product: AdminQuotePackProduct, index: number): void => {
		value[index] = product
		onChange([...value])
	}
	const handleProductDelete = (index: number): void => {
		value.splice(index, 1)
		onChange([...value])
	}

	const hasDefault = useMemo(() => value.some((p) => p.isDefault), [value])

	return (
		<section className="p-4">
			{productCategories != null && productCategories.length > 0 && (
				<p>
					Catégorie(s) des produits : <BoldList items={productCategories} />
				</p>
			)}
			<Row gutter={16}>
				<Col span={8}>
					<Title level={5} className="text-nowrap">
						Référence
					</Title>
				</Col>
				<Col span={4}>
					<Title level={5} className="text-nowrap">
						Prix
					</Title>
				</Col>
				<Col span={4}>
					<Title level={5} className="text-nowrap">
						Remise
					</Title>
				</Col>
				{showQuantity && (
					<Col span={2}>
						<Title level={5} className="text-nowrap">
							Quantité
						</Title>
					</Col>
				)}
				<Col span={2} offset={showQuantity ? 0 : 2}>
					<Title level={5} className="text-nowrap">
						Par défaut
					</Title>
				</Col>
				<Col span={2}>
					<Title level={5} className="text-nowrap">
						Activer
					</Title>
				</Col>
				<Col span={2}>
					<Title level={5} className="text-nowrap">
						Supprimer
					</Title>
				</Col>
			</Row>
			{value.map((product, i) => (
				<AdminPackProductRow
					key={i}
					productCategories={productCategories}
					showQuantity={showQuantity}
					disabledDefault={!product.isDefault && !canHaveMultipleDefaults && hasDefault} // Disable default checkbox if can't have multiple defaults and one other is already checked
					value={product}
					onChange={(product) => handleProductUpdate(product, i)}
					onDelete={() => handleProductDelete(i)}
				/>
			))}
			<Button icon={<PlusOutlined />} onClick={() => onChange([...value, emptyAdminQuotePackProduct])}>
				Ajouter
			</Button>
		</section>
	)
}

export const deviceHiboutikCategories: HiboutikCategoryName[] = [
	"Appareils auditifs",
	"Implant",
	"Ancrage osseux",
	"Ancrage osseux avec SN",
]
export const earphoneHiboutikCategories: HiboutikCategoryName[] = ["Ecouteur", "Ecouteur avec SN", "Micro-tubes"]
export const earpieceHiboutikCategories: HiboutikCategoryName[] = ["Embout rembourse", "Embout avec SN", "Domes"]
export const accessoriesHiboutikCategories: HiboutikCategoryName[] = [
	"Accessoire",
	"Accessoires implants",
	"Autres",
	"Protection et prevention",
	"Protection et prevention avec SN",
	"Reparation",
]
export const optionHiboutikCategories: HiboutikCategoryName[] = ["Service"]
export const consumableHiboutikCategories: HiboutikCategoryName[] = [
	"Piles",
	"Entretien",
	"Filtres",
	"Piles implants",
	"Batteries implant",
]
export const insuranceHiboutikCategories: HiboutikCategoryName[] = ["Assurance", "Garantie"]
export const crosHiboutikCategories: HiboutikCategoryName[] = ["Cros"]

type AdminPackOfferFormProps = {
	value: AdminQuotePackOffer
	onChange: (value: AdminQuotePackOffer) => void
}
export default function AdminPackOfferForm({ value, onChange }: AdminPackOfferFormProps): JSX.Element {
	// find all categories that aren't already mapped and put them into consumableHiboutikCategories
	const { data: categories } = useCategoriesQuery()
	const unmappedCategories = useMemo(() => {
		const mappedCategories = [
			deviceHiboutikCategories,
			earphoneHiboutikCategories,
			earpieceHiboutikCategories,
			accessoriesHiboutikCategories,
			optionHiboutikCategories,
			consumableHiboutikCategories,
			insuranceHiboutikCategories,
			crosHiboutikCategories,
		].flat()

		const lowerDeburr = (str: string): string => _.deburr(str).toLowerCase()
		return (
			categories
				?.filter((c) => !mappedCategories.some((mc) => lowerDeburr(mc) === lowerDeburr(c.category_name)))
				.map((c) => c.category_name) ?? []
		)
	}, [categories]) as HiboutikCategoryName[]

	const consumablesAndUnmappedCategories = useMemo(() => {
		return [...unmappedCategories, ...consumableHiboutikCategories]
	}, [unmappedCategories, consumableHiboutikCategories])

	return (
		<AdministrationCollapse
			panels={[
				{
					header: "Appareils",
					key: 0,
					children: (
						<AdminPackProductsForm
							productCategories={deviceHiboutikCategories}
							value={value.devices}
							onChange={(devices) => onChange({ ...value, devices })}
						/>
					),
				},
				{
					header: "Écouteurs (Droite)",
					key: 1,
					children: (
						<AdminPackProductsForm
							productCategories={earphoneHiboutikCategories}
							value={value.earphones.right}
							onChange={(earphones) =>
								onChange({ ...value, earphones: { ...value.earphones, right: earphones } })
							}
						/>
					),
				},
				{
					header: "Écouteurs (Gauche)",
					key: 1,
					children: (
						<AdminPackProductsForm
							productCategories={earphoneHiboutikCategories}
							value={value.earphones.left}
							onChange={(earphones) =>
								onChange({ ...value, earphones: { ...value.earphones, left: earphones } })
							}
						/>
					),
				},
				{
					header: "Embouts",
					key: 2,
					children: (
						<AdminPackProductsForm
							productCategories={earpieceHiboutikCategories}
							value={value.earpieces}
							onChange={(earpieces) => onChange({ ...value, earpieces })}
						/>
					),
				},
				{
					header: "Cros",
					key: 3,
					children: (
						<AdminPackProductsForm
							productCategories={crosHiboutikCategories}
							value={value.cros}
							onChange={(cros) => onChange({ ...value, cros })}
						/>
					),
				},
				{
					header: "Accessoires",
					key: 4,
					children: (
						<AdminPackProductsForm
							productCategories={accessoriesHiboutikCategories}
							showQuantity
							canHaveMultipleDefaults
							value={value.accessories}
							onChange={(accessories) => onChange({ ...value, accessories })}
						/>
					),
				},
				{
					header: "Options",
					key: 5,
					children: (
						<AdminPackProductsForm
							productCategories={optionHiboutikCategories}
							showQuantity
							canHaveMultipleDefaults
							value={value.options}
							onChange={(options) => onChange({ ...value, options })}
						/>
					),
				},
				{
					header: "Consommables",
					key: 6,
					children: (
						<AdminPackProductsForm
							productCategories={consumablesAndUnmappedCategories}
							showQuantity
							canHaveMultipleDefaults
							value={value.consumables}
							onChange={(consumables) => onChange({ ...value, consumables })}
						/>
					),
				},
				{
					header: "Assurances",
					key: 7,
					children: (
						<AdminPackProductsForm
							productCategories={insuranceHiboutikCategories}
							value={value.insurances}
							onChange={(insurances) => onChange({ ...value, insurances })}
						/>
					),
				},
			]}
		/>
	)
}
