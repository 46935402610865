/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Select } from "antd"
import { DocumentType, Document } from "@audiowizard/common"
import { itemsDocumentsAvailable } from "datas/items/itemsDocumentsAvailable"
import { partialSearch } from "services/functions"
import dayjs from "dayjs"
import { last } from "lodash"
import { useEffect, useMemo, useState, useContext } from "react"
import * as React from "react"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap"
import { detectBrowserOS, OS } from "services/Utilities"
import "./Modal.DocumentUploader.scss"
import API from "../../../services/API"
import DocumentScanner from "./DocumentScanner"
import { embedExtensions, fileExtensionIcon, imageExtensions, supportedExtensions } from "./Extensions"
import PdfViewer from "components/utils/PdfViewer"
import AuthContext from "contexts/AuthContext"

type UploaderDocument = {
	label: string
	filename: string
	extension: string
	file: File
	base64: string
	patient: string // Iri
	type?: DocumentType
}

type DropzonePreviewProps = {
	document: UploaderDocument
}
function DropzonePreview({ document }: DropzonePreviewProps): JSX.Element {
	if (document.extension == null) return <></>

	if (embedExtensions.includes(document.extension)) {
		return (
			<div className="text-center mb-3">
				<PdfViewer src={document.base64} title={document.filename} />
			</div>
		)
	}
	if (imageExtensions.includes(document.extension)) {
		return (
			<div className="text-center mb-3">
				<img className="w-100" src={document.base64} alt={document.filename} />
			</div>
		)
	}

	const icon = fileExtensionIcon(document.extension)
	return (
		<span
			className="d-flex justify-content-center align-items-center h-100"
			key={icon} // key pour forcer react a update le DOM. Sinon Font-Awesome ne modifie pas l'icone
		>
			<i className={`fad fa-3x ${icon}`} />
		</span>
	)
}

type DocumentUploaderProps = {
	onClose: () => void
	isOpen: boolean
	refreshDocuments: (document?: string) => void
	/** patient["@id"] */
	patientIri: string
	documentToReplace?: Document
	documentType?: DocumentType
}
export default function DocumentUploader({
	onClose,
	isOpen,
	refreshDocuments,
	patientIri,
	documentToReplace,
	documentType,
}: DocumentUploaderProps): JSX.Element {
	const [busy, setBusy] = useState(false)
	const { patient } = useContext(AuthContext)
	const [document, setDocument] = useState({} as UploaderDocument)
	const os = useMemo(detectBrowserOS, [])

	const defaultDocument = documentType ? ({ type: documentType } as UploaderDocument) : ({} as UploaderDocument)

	useEffect(() => {
		setDocument(defaultDocument)
		setBusy(false)
	}, [])

	useEffect(() => {
		if (!documentToReplace) return
		setDocument((old) => ({ ...old, type: documentToReplace.type as DocumentType }))
	}, [documentToReplace])

	const { Option } = Select

	const handleClose = (): void => {
		setDocument(defaultDocument)
		setBusy(false)
		onClose()
	}

	function scanDocumentFilename(): string {
		return `${document.type}-${patient.lastName}-${patient.firstName}-${dayjs().format("DD-MM-YYYY-HH-mm-ss")}.pdf`
	}

	const validExtension = (extension: string): boolean => {
		return supportedExtensions.includes(extension)
	}

	const importFile = (file: File): Promise<void> => {
		return new Promise((resolve, reject) => {
			try {
				const name = file.name.replace(/[^a-zA-Z0-9-. ]/g, "")
				const splitted = name.split(".")
				const filename = splitted[0]
				const extension = last(splitted)?.toLowerCase()

				// Validation de l'extension

				// undefined, null ou string vide
				if (!extension) {
					toast.warn("Vérifiez le format de ce fichier, format non reconnu.")
					return
				}

				if (!validExtension(extension)) {
					toast.warn(`L'extension ".${extension}" n'est pas acceptée.`)
					return
				}

				// Lecture du fichier
				const reader = new FileReader()
				reader.onabort = () => {
					reject()
				}
				reader.onerror = () => {
					reject()
				}
				reader.onload = () => {
					const base64 = reader.result as string

					setDocument((old) => ({
						base64,
						extension,
						filename,
						patient: patientIri,
						label: filename,
						file,
						type: old.type,
					}))
				}
				reader.readAsDataURL(file)
			} catch (err) {
				console.error(err)
				toast.error("Erreur lors de l'import du fichier")
				reject()
			}
		})
	}

	const handleDropzone = (acceptedFiles: File[]): void => {
		for (const file of acceptedFiles) importFile(file)
	}

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (evt) => {
		evt.preventDefault()

		try {
			if (!document.base64 || !document.file) return
			setBusy(true)
			let documentIri = documentToReplace?.["@id"]

			if (documentToReplace?.id) {
				await API.updateDocument(documentToReplace.id, { ...document, patient: patientIri })
				toast.success("Document remplacé avec succès")
			} else {
				const { data: savedDocument } = await API.createDocument<Document>({ ...document, patient: patientIri })
				documentIri = savedDocument["@id"]
				toast.success("Document enregistré avec succès")
			}

			refreshDocuments(documentIri)
			onClose()
		} catch (_void) {
			toast.error("Erreur lors de l'enregistrement du document")
		} finally {
			setBusy(false)
		}
	}

	return (
		<Modal scrollable={true} isOpen={isOpen} size="lg">
			<ModalHeader>
				<div
					className="icon-sm position-absolute close-icon"
					style={{ right: "16px", cursor: "pointer" }}
					onClick={handleClose}>
					<i className="fas fa-times" />
				</div>
				<div className="modal-title d-flex align-items-begin">
					<div className="icon-sm  mr-1">
						<i className="fad fa-file-upload" />
					</div>
					Ajouter un nouveau document
				</div>
			</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit}>
					<div className="modal-documentuploader-droppreview">
						<div className="modal-documentuploader-dropzone">
							<Dropzone onDrop={handleDropzone}>
								{({ getRootProps, getInputProps }) => (
									<section>
										<div {...getRootProps()} className="dropzone my-3">
											<input {...getInputProps()} disabled={busy} />
											<p className="text-center">
												Glisser-déposer un fichier ou cliquer ici
												<br /> formats supportés :{" "}
												{supportedExtensions.map((ext) => `.${ext}`).join(", ")}
											</p>
										</div>
									</section>
								)}
							</Dropzone>
						</div>
						<div className="modal-documentuploader-preview">
							<DropzonePreview document={document} />
						</div>
					</div>

					{/* Le scanner ne marche qu'avec windows pour le moment*/}
					{os === OS.Windows && (
						<DocumentScanner
							onScanDone={(blob) =>
								importFile(new File([blob], scanDocumentFilename(), { type: blob.type }))
							}
						/>
					)}

					<h6 className="mt-3 mb-1">Changer le nom du fichier (optionnel) </h6>
					<input
						type="text"
						name="label"
						className="form-control"
						value={document?.label || ""}
						placeholder="(Optionnel)"
						onChange={(event) => {
							const label = event.target.value
							setDocument((old) => ({ ...old, label }))
						}}
						disabled={busy || !document.base64}
					/>
					<h6 className="mt-3 mb-1">Type de document</h6>
					<Select
						className="mt-3 mb-1 input-search-modal"
						size="large"
						value={documentType || document?.type}
						onSelect={(value: DocumentType) => setDocument((old) => ({ ...old, type: value }))}
						showSearch
						allowClear
						onClear={() => setDocument((old) => ({ ...old, type: "" as DocumentType }))}
						placeholder="Tapez pour rechercher ou sélectionnez avec le menu déroulant"
						optionFilterProp="children"
						disabled={documentType && true}
						filterOption={(search, option) => partialSearch(option!.label as string, search)}>
						{itemsDocumentsAvailable
							.filter((d) => d.manuallySelectable && (!documentType || d.value === documentType))
							.sort((a, b) => a.label.localeCompare(b.label))
							.map(({ label, value }) => (
								<Option key={value} label={label} value={value}>
									{label}
								</Option>
							))}
					</Select>

					{document.type != null && ["ORDONNANCE", "FEUILLE_SOIN"].includes(document.type) && (
						<Alert color="info">Le fichier va être optimisé pour la télétransmission.</Alert>
					)}
					<button
						type="submit"
						className="btn btn-primary btn-block mt-3"
						disabled={busy || !document.base64 || !document.type || !document.type.length}>
						{documentToReplace ? "Remplacer" : "Enregistrer"} le document
					</button>
				</form>
			</ModalBody>
		</Modal>
	)
}
