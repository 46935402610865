import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { Col } from "reactstrap"
import "./MesPartenaires.scss"
import PartnerCard from "./PartnerCard"
import { partners } from "./helper"

const MesPartenaires = (): JSX.Element => {
	return (
		<Col>
			<SectionHeader title="Les partenaires d'Audiowizard" />
			<div className="mx-8 partner-card-container">
				{partners.map((partner) => (
					<PartnerCard key={partner.label} partner={partner} />
				))}
			</div>
		</Col>
	)
}

export default MesPartenaires
