import { useQuery } from "react-query"
import API from "../../../../API"
import { Patient } from "@audiowizard/common"
import { PatientServiceKey } from "../patient.service"

export default function usePatients(all = false) {
	return useQuery({
		queryFn: () => API.findAll<Patient[]>("PATIENTS_API", all ? "?pagination=false" : undefined),
		queryKey: [PatientServiceKey],
	})
}
