/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "antd"
import AuthContext from "contexts/AuthContext"
import { useContext, useEffect, useRef, useState } from "react"
import API from "services/API"
import { SortAlphabetically } from "services/functions"

const { Option } = Select

function AsyncUserSelect({
	setData,
	className = "",
	value,
	disabled = false,
	noAssistant,
	onlyAudio,
	size = "medium",
	placeholder = "Sélectionner un audioprothésiste",
}) {
	const [usersList, setUsersList] = useState([])
	const [loading, setLoading] = useState(false)
	const { user } = useContext(AuthContext)
	let searchTimeout = useRef(null)

	const populateOptions = (patients) => {
		let options = []
		for (const pat of patients) {
			let name = `${pat.firstName} ${pat.lastName}`
			if (name.length >= 28) {
				name = `${name.slice(0, 28)}...`
			}

			options.push({
				detail: pat,
				value: pat.id,
				label: name,
			})
		}
		setUsersList(options)
	}

	async function handleSearch(p) {
		try {
			setLoading(true)

			const searchParams = new URLSearchParams()
			if (p != null) {
				searchParams.set("omnisearch", p)
			}
			let users = await API.findAll("USERS_API", `/with_laboratories?${searchParams}`)

			users = users.filter((_user) => {
				if (noAssistant && _user.roles.includes("ROLE_ASSISTANT")) {
					return false
				}

				if (onlyAudio && _user.registrationStatus === "OTHER") {
					return false
				}

				if (!_user.laboratories?.length) {
					return false
				}

				const userLabsIds = user.laboratories?.map((lab) => lab.id)
				return _user.laboratories.some((_userLab) => userLabsIds?.includes(_userLab.id))
			})

			SortAlphabetically(users, "firstName")
			populateOptions(users)
			setLoading(false)
		} catch (e) {
			console.error("=> Error : ", e)
		}
	}

	useEffect(() => {
		handleSearch(null)
	}, [])

	function handleSelect(p) {
		if (p && usersList?.[p]?.detail?.id) setData(usersList[p].detail)
	}

	return (
		<>
			<Select
				allowClear
				onClear={() => setData({})}
				filterOption={false}
				loading={loading}
				disabled={disabled}
				className={className}
				notFoundContent={"Aucun résultat"}
				showSearch
				placeholder={value?.firstName ? `${value?.firstName} ${value?.lastName}` : placeholder}
				size={size}
				onSearch={(e) => {
					clearTimeout(searchTimeout.current)
					searchTimeout.current = null
					searchTimeout.current = setTimeout(() => {
						handleSearch(e)
					}, 400)
				}}
				onChange={handleSelect}>
				{usersList.map((d, k) => (
					<Option key={k}>{d.label}</Option>
				))}
			</Select>
		</>
	)
}

export default AsyncUserSelect
