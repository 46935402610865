import { Document, Patient, DocumentSendMailRequestData } from "@audiowizard/common"
import { Spin, Switch } from "antd"
import cx from "classnames"
import AuthContext from "contexts/AuthContext"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import API from "services/API"
import "./SendMailDocument.scss"

/**
 * @returns Si le document peut-être envoyé par mail au patient.
 */
export function canBeSentByMail(document: Document): boolean {
	return document.patient != null
}

type DocumentEmailSelectorSwitchProps = {
	label: string
	noEmailErrorMessage?: string
	email?: string
	checked: boolean
	onChange: (value: boolean) => void
	onEmailChange: (value: string) => Promise<void>
}
/**
 * Switch avec input pour ajouter un email manquant.
 */
function DocumentEmailSelectorSwitch({
	label,
	noEmailErrorMessage,
	email,
	checked,
	onChange,
	onEmailChange,
}: DocumentEmailSelectorSwitchProps): JSX.Element {
	const [showInput, setShowInput] = useState(false)
	const [emailInput, setEmailInput] = useState(email ?? "")
	const [loading, setLoading] = useState(false)

	const hasEmail = email != null && email.length > 0

	const updateEmail = async (): Promise<void> => {
		try {
			setLoading(true)
			await onEmailChange(emailInput)
			setShowInput(false)
		} finally {
			setLoading(false)
		}
	}

	return (
		<article className="document-email-selector-switch-grid">
			<Switch className="area-switch" disabled={!hasEmail} checked={checked} onChange={onChange} />

			<span className="area-label">
				{label} {hasEmail && `(${email})`}
			</span>

			{!hasEmail && <span className="area-error text-danger">{noEmailErrorMessage}</span>}

			<Button
				className="area-toggle badge w-100"
				color={showInput ? "danger" : hasEmail ? "warning" : "primary"}
				title={showInput ? "Fermer" : hasEmail ? "Modifier l'adresse email" : "Ajouter une adresse email"}
				onClick={() => setShowInput(!showInput)}>
				<span
					key={String(showInput)} // key pour forcer react et Font Awseome à recharger l'icone
				>
					<i className={cx("fad", showInput ? "fa-times" : hasEmail ? "fa-edit" : "fa-plus")} />
				</span>
			</Button>

			{showInput && (
				<>
					<input
						name="email"
						type="email"
						className="area-input form-control form-control-sm"
						placeholder="Email"
						value={emailInput}
						onChange={(e) => setEmailInput(e.target.value)}
					/>

					<Button
						className="area-confirm badge w-100"
						color="primary"
						title="Valider l'adresse email"
						disabled={loading}
						onClick={updateEmail}>
						{loading ? (
							<Spinner color="inherit" size="sm" />
						) : (
							// Englobe le <i> pour pas crash react quand on enlève l'icone font-awesome
							<span>
								<i className="fad fa-check" />
							</span>
						)}
					</Button>
				</>
			)}
		</article>
	)
}

type DocumentEmailSelectorProps = {
	patient: Patient
	value: DocumentSendMailRequestData
	onChange: (value: DocumentSendMailRequestData) => void
	onPatientChange: (value: Patient) => void
}
/**
 * Sélecteur patient/attendant pour envoie de document par email.
 */
export function DocumentEmailSelector({
	patient,
	value,
	onChange,
	onPatientChange,
}: DocumentEmailSelectorProps): JSX.Element {
	const [fullPatient, setFullPatient] = useState<Patient>(patient) // Refetch patient pour avoir toute les infos sur l'attendant
	const [loading, setLoading] = useState(false)

	const attendant = fullPatient?.attendantRelation?.attendant

	const patientEmail = fullPatient?.email
	const attendantEmail = attendant?.email

	const patientHasEmail = patientEmail != null && patientEmail.length > 0
	const attendantHasEmail = attendantEmail != null && attendantEmail.length > 0

	const updatePatientEmail = async (email: string): Promise<void> => {
		try {
			await API.update("PATIENTS_API", fullPatient.id!, { email })

			onPatientChange({ ...patient, email })

			toast.success("Email du patient mis à jour avec succès")
		} catch (err) {
			console.error(err)
			toast.error("Erreur lors de la mise à jour de l'email du patient")
		}
	}
	const updateAttendantEmail = async (email: string): Promise<void> => {
		try {
			await API.update("ATTENDANT_API", fullPatient.attendantRelation!.attendant!.id!, { email })

			// Update patient.attendantRelation.attendant.email
			onPatientChange({
				...patient,
				attendantRelation: {
					...patient.attendantRelation,
					attendant: { ...patient.attendantRelation?.attendant, email },
				},
			} as Patient)

			toast.success("Email de l'accompagnant mis à jour avec succès")
		} catch (err) {
			console.error(err)
			toast.error("Erreur lors de la mise à jour de l'email du patient")
		}
	}

	// Fetch fullPatient
	useEffect(() => {
		const patientId = patient?.id
		if (patientId == null) return

		const fetchPatient = async (): Promise<void> => {
			setLoading(true)

			const patient = await API.find<Patient>("PATIENTS_API", patientId)
			setFullPatient(patient)

			setLoading(false)
		}
		fetchPatient()
	}, [patient])

	// Décoche si n'y a pas d'email
	useEffect(() => {
		const newValue = { ...value }
		if (!patientHasEmail && value.sendToPatient) {
			newValue.sendToPatient = false
		}
		if (!attendantHasEmail && value.sendToAttendant) {
			newValue.sendToAttendant = false
		}

		onChange(newValue)
	}, [patientHasEmail, attendantHasEmail])

	if (loading)
		return (
			<Col sm="6" className="text-center">
				<Spin spinning={loading} />
			</Col>
		)

	return (
		<>
			<Col xs="auto">
				<DocumentEmailSelectorSwitch
					label="Envoyer au patient"
					noEmailErrorMessage="Le patient n'a pas d'adresse email"
					email={patientEmail}
					checked={value.sendToPatient}
					onChange={(sendToPatient) => onChange({ ...value, sendToPatient })}
					onEmailChange={updatePatientEmail}
				/>
			</Col>

			{attendant != null && (
				<Col xs="auto">
					<DocumentEmailSelectorSwitch
						label="Envoyer à l'accompagnant du patient"
						noEmailErrorMessage="L'accompagnant n'a pas d'adresse email"
						email={attendantEmail}
						checked={value.sendToAttendant}
						onChange={(sendToAttendant) => onChange({ ...value, sendToAttendant })}
						onEmailChange={updateAttendantEmail}
					/>
				</Col>
			)}
		</>
	)
}

type DocumentEmailModalProps = {
	document: Document
	open: boolean
	onClose: () => void
	onDocumentChange: (value: Document) => void
}
export function DocumentEmailModal({
	document,
	open,
	onClose,
	onDocumentChange,
}: DocumentEmailModalProps): JSX.Element {
	const { patient, setPatient } = useContext(AuthContext)
	const [value, setValue] = useState<DocumentSendMailRequestData>({ sendToPatient: true, sendToAttendant: false })
	const [loading, setLoading] = useState(false)

	const noValueChecked = !value.sendToPatient && !value.sendToAttendant

	const sendMail = async (): Promise<void> => {
		setLoading(true)
		try {
			await API.documentSendMail(document.id!, value)

			toast.success("Email(s) envoyé(s) avec succès")
			onClose()

			setLoading(false)
		} catch (err) {
			console.error(err)
			toast.error("Erreur lors de l'envoie des emails")
		}
	}

	const handlePatientChange = (newPatient: Patient): void => {
		onDocumentChange({ ...document, patient: newPatient })

		// Met à jour le patient du context si il correspond
		if (patient?.["@id"] === newPatient["@id"]) {
			// Merge patient.attendantRelation.attendant
			setPatient({
				...patient,
				...newPatient,
				attendantRelation: {
					...patient.attendantRelation,
					...newPatient.attendantRelation,
					attendant: {
						...patient.attendantRelation?.attendant,
						...newPatient.attendantRelation?.attendant,
					},
				},
			} as Patient)
		}
	}

	return (
		<Modal isOpen={open} toggle={onClose} centered>
			<ModalHeader>Envoyer le document par email au patient</ModalHeader>
			<ModalBody>
				<DocumentEmailSelector
					patient={document.patient!}
					value={value}
					onChange={setValue}
					onPatientChange={handlePatientChange}
				/>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" disabled={loading || noValueChecked} onClick={sendMail}>
					Envoyer
					{loading && <Spinner color="inherit" size="sm" />}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
