import { HTMLProps } from "react"
import "./Panel.scss"

export default function Panel({ children, className, ...props }: HTMLProps<HTMLDivElement>) {
	return (
		<div className={`custom-panel ${className}`} {...props}>
			{children}
		</div>
	)
}
