import { lazy } from "react"
import { Switch } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import InvoiceBuilder from "../tmp/rebuild-invoices/InvoiceBuilder"
import PrivateRoute from "./PrivateRoute"

import StockRouter, { StockRouterPrefix } from "pages/Stock-Management/Router"
import { TableauDeBord } from "pages/TableauDeBord/TableauDeBord"
import AbonnementRouter from "./Abonnement"
import { AbonnementRoutePrefix } from "./Abonnement/routes"
import BonCommandeRouter from "./BonCommande"
import { BonCommandeRoutePrefix } from "./BonCommande/routes"
import BonLivraisonRouter from "./BonLivraison"
import { BonLivraisonRoutePrefix } from "./BonLivraison/routes"
import CaisseRouter from "./Caisse"
import { CaisseRoutePrefix } from "./Caisse/routes"
import CatalogueRouter from "./Catalogue"
import { CatalogueRoutePrefix } from "./Catalogue/routes"
import CommandesRouter from "./Commande"
import { CommandesRoutePrefix } from "./Commande/routes"
import ComptabiliteRouter from "./Comptabilite"
import { ComptabiliteRoutePrefix } from "./Comptabilite/routes"
import CaisseParamètresRouter from "./ComptabiliteParametres"
import { CaisseParamètresPrefix } from "./ComptabiliteParametres/routes"
import DocumentsRouter from "./Documents"
import { DocumentsRoutePrefix } from "./Documents/routes"
import EntrepriseRouter from "./Entreprise"
import { EntrepriseRoutePrefix } from "./Entreprise/routes"
import EquipeRouter from "./Equipe"
import { EquipeRoutePrefix } from "./Equipe/routes"
import InvitationsRouter from "./Invitations"
import { InvitationsRoutePrefix } from "./Invitations/routes"
import LaboratoiresRouter from "./Laboratoires"
import { LaboratoiresRoutePrefix } from "./Laboratoires/routes"
import MarketingRouter from "./Marketing"
import { MarketingRoutePrefix } from "./Marketing/routes"
import MarqueBlancheRouter from "./MarqueBlanche"
import { MarqueBlancheRoutePrefix } from "./MarqueBlanche/routes"
import ModelesRouter from "./Modeles"
import { ModelesRoutes } from "./Modeles/routes"
import MutuellesRouter from "./Mutuelles"
import { MutuellesRoutes } from "./Mutuelles/routes"
import PacksRouter from "./Packs"
import { Packsroutes } from "./Packs/routes"
import PaiementsRouter from "./Paiements"
import { PaiementsRoutePrefix } from "./Paiements/routes"
import ParametreAgendaRouter from "./ParametreAgenda"
import { ParametreAgendaRoutes } from "./ParametreAgenda/routes"
import PartenairesRouter from "./Partenaires"
import PersonnalisationRouter from "./Personnalisation"
import { PersonnalisationRoutePrefix } from "./Personnalisation/routes"
import PrescripteursRouter from "./Prescripteurs"
import { PrescripteursRoutes } from "./Prescripteurs/routes"
import ProfilRouter from "./Profil"
import { ProfilRoutePrefix } from "./Profil/routes"
import RappelsSMSRouter from "./RappelsSMS"
import { RappelsSMSRoutes } from "./RappelsSMS/routes"
import SavRouter from "./Sav"
import { SavRoutePrefix } from "./Sav/routes"
import StatsRouter from "./Stats"
import { StatsRoutePrefix } from "./Stats/routes"
import StatutsRouter from "./Statuts"
import { StatutsRoutes } from "./Statuts/routes"
import TeletransRouter from "./Teletrans"
import { TeletransRoutePrefix } from "./Teletrans/routes"
import VenteRouter from "./Vente"
import { VenteRoutePrefix } from "./Vente/routes"
import { GestionCompteRoutePrefix } from "./GestionCompte/routes"

const Settings = lazy(() => import("../pages/Settings/Settings"))
const HomePage = lazy(() => import("../pages/accueil/HomePage"))
const Courriers = lazy(() => import("../pages/courriers/Index"))
const PremiereVisite = lazy(() => import("../pages/etapes/0-premiere-visite/Index"))
const Visite = lazy(() => import("../pages/etapes/5-visite/Index"))
const SuiteVisite = lazy(() => import("../pages/etapes/0-suite-visite/Index"))
const Adaptation = lazy(() => import("../pages/etapes/1-adaptation/Index"))
const Essais = lazy(() => import("../pages/etapes/2-essais/Index"))
const Appareille = lazy(() => import("../pages/etapes/3-appareille/Index"))
const Relance = lazy(() => import("../pages/etapes/4-relance/Index"))
const FichePatient = lazy(() => import("../pages/fiche-patient/0-FichePatient"))
const Inscription = lazy(() => import("../pages/inscription/Index"))
const RendezVous = lazy(() => import("../pages/rendez-vous/Index"))
const SalleAttenteUI = lazy(() => import("../pages/salle-attente/Index"))
const Statistiques = lazy(() => import("../pages/statistiques/Index"))
const MonEquipe = lazy(() => import("../pages/mon-compte/mon-equipe/Index"))
const FeuillePret = lazy(() => import("../pages/feuille-pret/FeuillePret"))
const FeuilleSoin = lazy(() => import("../pages/feuille-soin/FeuilleSoin"))
const Vente = lazy(() => import("../pages/vente/Vente"))
const BillingView = lazy(() => import("features/sales/views/billing/BillingView"))
const PhoneNumberRedirection = lazy(() => import("../pages/fiche-patient/PhoneNumberRedirection"))
const RendezVousNote = lazy(() => import("../pages/rendez-vous/Notes"))
const ListeBL = lazy(() => import("../pages/bl/ListeBL"))
const ValeurMarchandeStock = lazy(() => import("../pages/bl/ValeurMarchandeStock"))
const Rgpd = lazy(() => import("../pages/etapes/0-premiere-visite/0-wizard-premiere-visite/0-0-ConsentemenRgpd"))
const MesPatients = lazy(() => import("../pages/MesPatients"))
const MesRendezvous = lazy(() => import("../pages/MesRendezvous"))
const MesMutuelles = lazy(() => import("../pages/mon-compte/MesMutuelles"))
const NouveauSAV = lazy(() => import("../pages/sav/NouveauSAV"))
const ListeSAV = lazy(() => import("../pages/sav/ListeSAV"))
const ListePersonnalisations = lazy(() => import("../pages/mon-compte/mes-personnalisations/ListePersonnalisations"))
const TemplateForm = lazy(() => import("../pages/templates/TemplateForm/TemplateForm"))
const BryntumAgenda = lazy(() => import("pages/Schedules/Agenda/BryntumAgenda"))
const Stats = lazy(() => import("../pages/Stats/Index"))
const NouveauBL = lazy(() => import("../pages/bl/NouveauBL"))
const NouveauBLAncienSysteme = lazy(() => import("../pages/bl/NouveauBL.OLD"))
const HistoriqueEquipements = lazy(() => import("../pages/Gestion-Stock/HistoriqueEquipements"))
const HistoriqueRendezvous = lazy(() => import("../pages/fiche-patient/HistoriqueRendezVous"))
const CaisseDepot = lazy(() => import("../pages/mon-compte/ma-caisse/Depot"))
const CaisseReassort = lazy(() => import("../pages/mon-compte/ma-caisse/ReassortCaisse"))
const CaisseValidation = lazy(() => import("../pages/mon-compte/ma-caisse/EspValidationCaisse"))
const CaisseHistorique = lazy(() => import("../pages/mon-compte/ma-caisse/EspHistoriqueCaisse"))
const ListePaiementsRecu = lazy(() => import("../pages/mon-compte/ma-caisse/PaiementRecu"))
const ListePaiementsTeletransmissionSuiviRecus = lazy(() => import("../pages/FSV/PaiementTeletransSuiviRecu"))
const ListeSuiviTeletransmissions = lazy(() => import("../pages/FSV/SuiviTeletransmission"))
const ExportComptable = lazy(() => import("../pages/mon-compte/comptabilite/ExportComptable"))
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfigurationExportComptable = lazy(() => import("../pages/mon-compte/comptabilite/ConfigurationExportComptable"))
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PersonnalisationExportComptable = lazy(() =>
	import("../pages/mon-compte/comptabilite/PersonnalisationExportComptable")
)
const GestionChequeCB = lazy(() => import("../pages/mon-compte/ma-caisse/GestionChequesCB"))
const ListeDocument = lazy(() => import("../pages/fiche-patient/Documents/Liste"))
const SignDocumentPage = lazy(() =>
	import("../pages/fiche-patient/Documents/SignDocument").then((module) => ({ default: module.SignDocumentPage }))
)
const FicheProduit = lazy(() => import("../pages/Gestion-Stock/FicheProduit"))
const SupplierInvoiceRouter = lazy(() => import("../pages/supplier-invoice/SupplierInvoiceRouter"))

const QuotationPackRouter = lazy(() => import("../features/edition-devis/quote/QuotationPackRouter"))
const PurchaseOrderPage = lazy(() => import("../pages/PurchaseOrder/PurchaseOrderPage"))
const PurchaseOrderEdit = lazy(() => import("../pages/PurchaseOrder/PurchaseOrderEdit"))

const UserRouter = () => {
	return (
		<Switch>
			<PrivateRoute path={TeletransRoutePrefix}>
				<TeletransRouter />
			</PrivateRoute>

			<PrivateRoute path={ComptabiliteRoutePrefix}>
				<ComptabiliteRouter />
			</PrivateRoute>

			<PrivateRoute path={PaiementsRoutePrefix}>
				<PaiementsRouter />
			</PrivateRoute>

			<PrivateRoute path={SavRoutePrefix}>
				<SavRouter />
			</PrivateRoute>

			<PrivateRoute path={StatsRoutePrefix}>
				<StatsRouter />
			</PrivateRoute>

			<PrivateRoute path={MarketingRoutePrefix}>
				<MarketingRouter />
			</PrivateRoute>

			<PrivateRoute path={CommandesRoutePrefix}>
				<CommandesRouter />
			</PrivateRoute>

			<PrivateRoute path={ProfilRoutePrefix}>
				<ProfilRouter />
			</PrivateRoute>

			<PrivateRoute path={VenteRoutePrefix}>
				<VenteRouter />
			</PrivateRoute>

			<PrivateRoute path={StockRouterPrefix}>
				<StockRouter />
			</PrivateRoute>

			<PrivateRoute path={BonCommandeRoutePrefix}>
				<BonCommandeRouter />
			</PrivateRoute>

			<PrivateRoute path={BonLivraisonRoutePrefix}>
				<BonLivraisonRouter />
			</PrivateRoute>

			{/*Gestion de compte : début */}
			<PrivateRoute path={ProfilRoutePrefix}>
				<ProfilRouter />
			</PrivateRoute>

			<PrivateRoute path={EntrepriseRoutePrefix}>
				<EntrepriseRouter />
			</PrivateRoute>

			<PrivateRoute path={LaboratoiresRoutePrefix}>
				<LaboratoiresRouter />
			</PrivateRoute>

			<PrivateRoute path={EquipeRoutePrefix}>
				<EquipeRouter />
			</PrivateRoute>

			<PrivateRoute path={AbonnementRoutePrefix}>
				<AbonnementRouter />
			</PrivateRoute>

			<PrivateRoute path={DocumentsRoutePrefix}>
				<DocumentsRouter />
			</PrivateRoute>

			<PrivateRoute path={InvitationsRoutePrefix}>
				<InvitationsRouter />
			</PrivateRoute>

			<PrivateRoute path={GestionCompteRoutePrefix}>
				<PartenairesRouter />
			</PrivateRoute>
			{/*Gestion de compte : fin */}

			{/*Parametres de l'application : début */}
			<PrivateRoute path={PersonnalisationRoutePrefix}>
				<PersonnalisationRouter />
			</PrivateRoute>

			<PrivateRoute path={MarqueBlancheRoutePrefix}>
				<MarqueBlancheRouter />
			</PrivateRoute>

			<PrivateRoute path={MutuellesRoutes.Mutuelles}>
				<MutuellesRouter />
			</PrivateRoute>

			<PrivateRoute path={PrescripteursRoutes.Prescripteurs}>
				<PrescripteursRouter />
			</PrivateRoute>

			<PrivateRoute path={ModelesRoutes.Modeles}>
				<ModelesRouter />
			</PrivateRoute>

			<PrivateRoute path={RappelsSMSRoutes.RappelsSMS}>
				<RappelsSMSRouter />
			</PrivateRoute>

			<PrivateRoute path={ParametreAgendaRoutes.ParametreAgenda}>
				<ParametreAgendaRouter />
			</PrivateRoute>

			<PrivateRoute path={StatutsRoutes.StatutsEtFiltres}>
				<StatutsRouter />
			</PrivateRoute>

			<PrivateRoute path={CatalogueRoutePrefix}>
				<CatalogueRouter />
			</PrivateRoute>

			<PrivateRoute path={Packsroutes.Packs}>
				<PacksRouter />
			</PrivateRoute>

			<PrivateRoute path={CaisseParamètresPrefix}>
				<CaisseParamètresRouter />
			</PrivateRoute>
			{/*Parametres de l'application : fin */}

			<PrivateRoute path={CaisseRoutePrefix}>
				<CaisseRouter />
			</PrivateRoute>
			<PrivateRoute path="/redirection-patient/phone/:phoneNumber" component={PhoneNumberRedirection} />

			<PrivateRoute path="/mon-compte/mes-personnalisations" component={ListePersonnalisations} />
			<PrivateRoute path="/parametres/:tab?" component={Settings} />
			<PrivateRoute path="/mon-compte/edition/gabarits/templates/:id" component={TemplateForm} />
			<PrivateRoute path="/mon-compte/edition/gabarits" component={TemplateForm} />
			<PrivateRoute path="/mon-compte/mon-equipe" component={MonEquipe} />
			<PrivateRoute path="/mon-compte/mes-mutuelles" component={MesMutuelles} />

			{/*<PrivateRoute path="/mon-compte/consultation/:user" component={TeleconsultationUser} />*/}

			{/* CAISSE ET COMPTABILITE *************/}
			<PrivateRoute path="/mon-compte/caisse/validation" component={CaisseValidation} />
			<PrivateRoute path="/mon-compte/caisse/depot" component={CaisseDepot} />
			<PrivateRoute path="/mon-compte/caisse/reassort" component={CaisseReassort} />
			<PrivateRoute path="/mon-compte/caisse/historique" component={CaisseHistorique} />
			<PrivateRoute path="/mon-compte/gestion-cheques" component={GestionChequeCB} />
			<PrivateRoute path="/mon-compte/gestion-carte-bancaire" component={GestionChequeCB} />
			<PrivateRoute path="/mon-compte/liste-paiements/:filter" component={ListePaiementsRecu} />
			<PrivateRoute path="/mon-compte/liste-paiements" component={ListePaiementsRecu} />
			<PrivateRoute
				path="/teletransmission/suivi-des-suivi"
				component={ListePaiementsTeletransmissionSuiviRecus}
			/>
			<PrivateRoute path="/teletransmission/suivi" component={ListeSuiviTeletransmissions} />
			<PrivateRoute
				path="/mon-compte/export-comptable/parametres/:personalizationId"
				component={ConfigurationExportComptable}
			/>
			<PrivateRoute
				path="/mon-compte/export-comptable/personnalisation"
				component={PersonnalisationExportComptable}
			/>
			<PrivateRoute path="/mon-compte/export-comptable" component={ExportComptable} />
			{/**************************************/}

			<PrivateRoute path="/inscription/" component={Inscription} />

			<PrivateRoute path="/rendez-vous/notes" component={RendezVousNote} />
			<PrivateRoute path="/devis/:id" component={PremiereVisite} />
			<PrivateRoute path="/premiere-visite/:id" component={PremiereVisite} />
			<PrivateRoute path="/visite/:id" component={Visite} />
			<PrivateRoute path="/suite-visite/:id" component={SuiteVisite} />
			<PrivateRoute path="/salle-attente/:id" component={SalleAttenteUI} allowPatientUI />
			<PrivateRoute path="/adaptation/:id" component={Adaptation} />
			<PrivateRoute path="/essais/:id" component={Essais} />
			<PrivateRoute path="/appareille/:id" component={Appareille} />
			<PrivateRoute path="/relance/:id" component={Relance} />
			<PrivateRoute path="/agenda" component={BryntumAgenda} />

			<PrivateRoute path="/documents/patient/:id">
				<ListeDocument documentListType="PATIENT" />
			</PrivateRoute>
			<PrivateRoute path="/documents/signer/:id" component={SignDocumentPage} />

			<PrivateRoute path="/fiche-patient/:id/historique-rendez-vous" component={HistoriqueRendezvous} />
			<PrivateRoute path="/fiche-patient/:id" component={FichePatient} />

			<PrivateRoute path="/facture-suivi/:id" component={BillingView} />
			<PrivateRoute path="/facture-client/:id" component={BillingView} />
			<PrivateRoute path="/facture-dynamic/:id" component={BillingView} />

			<PrivateRoute path="/valeur-marchande-stock" component={ValeurMarchandeStock} />

			{/* GESTION DU STOCK */}
			<PrivateRoute path="/stock/fiche-produit/:product_id" component={FicheProduit} />
			<PrivateRoute path="/historique-equipement/:patientEquipmentId?" component={HistoriqueEquipements} />
			<PrivateRoute path="/nouveau-bon-de-livraison" component={NouveauBL} />
			<PrivateRoute path="/bon-de-livraison/:id" component={NouveauBL} />
			<PrivateRoute path="/bon-de-livraison-ancien/:numero" component={NouveauBLAncienSysteme} />
			<PrivateRoute path="/bon-de-livraison" component={ListeBL} />
			<PrivateRoute path="/courriers/:id/templates/:templateId" component={Courriers} />
			<PrivateRoute path="/courriers/:id" component={Courriers} />
			<PrivateRoute path="/evenements/:id/:step?" component={RendezVous} />
			<PrivateRoute path="/statistiques" component={Statistiques} />
			<PrivateRoute path="/feuille-pret" component={FeuillePret} />
			<PrivateRoute path="/feuille-soin" component={FeuilleSoin} />
			<PrivateRoute path="/vente/:id" component={Vente} />
			<PrivateRoute path="/vente" component={Vente} />
			<PrivateRoute path="/mes-donnees-rgpd" component={Rgpd} />
			<PrivateRoute path="/mes-patients/:status" component={MesPatients} />
			<PrivateRoute path="/mes-patients/" component={MesPatients} />
			<PrivateRoute path="/tableau-de-bord" component={TableauDeBord} title="Tableau de bord" />
			<PrivateRoute path="/mes-rendezvous" component={MesRendezvous} />
			<PrivateRoute path="/sav/:numero" component={NouveauSAV} />
			<PrivateRoute path="/sav" component={ListeSAV} />
			<PrivateRoute path="/nouveau-sav" component={NouveauSAV} />
			<PrivateRoute path="/stats" component={Stats} />
			<PrivateRoute path="/rebuild/invoices" component={InvoiceBuilder} />
			<PrivateRoute path="/devis-pack" component={QuotationPackRouter} />

			<PrivateRoute path="/factures-fournisseurs" component={SupplierInvoiceRouter} />

			<PrivateRoute path="/bon-de-commande/nouveau" component={PurchaseOrderEdit} />
			<PrivateRoute path="/bon-de-commande/:id" component={PurchaseOrderEdit} />
			<PrivateRoute path="/bon-de-commande" component={PurchaseOrderPage} />

			<PrivateRoute path="/" component={HomePage} />
		</Switch>
	)
}

export default UserRouter
