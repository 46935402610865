import useCustomTitle from "../../../components/Hooks/useTitle"
import { Row, Table } from "antd"
import { UserInsuranceNetworkRefModel } from "../../../services/requests/http/userInsuranceNetworks/userInsurance.model"
import { ColumnsType } from "antd/lib/table"
import UserInsuranceNetworkButton from "./Components/UserInsuranceNetworkButton"
import useUserInsuranceCredentials from "../../../services/requests/http/userInsuranceCredentials/Hooks/useUserInsuranceCredentials"
import { useMemo, useState } from "react"
import UserInsuranceCredentialModal from "./Components/UserInsuranceCredentialModal"
import useUserInsuranceNetworks from "../../../services/requests/http/userInsuranceNetworks/Hooks/useUserInsuranceNetworks"
import { stringSorter } from "../../../services/functions"

const title = "Réseaux"

const userInsuranceColumns: ColumnsType<UserInsuranceNetworkRefModel> = [
	{
		title: "Nom",
		dataIndex: "network",
		sorter: (a, b) => stringSorter(a.network, b.network),
	},
	{
		title: "Identifiant",
		dataIndex: "login",
		sorter: (a, b) => stringSorter(a.login, b.login),
	},
	{
		title: "Mot de passe",
		dataIndex: "password",
	},
	{
		title: "Vos identifiants de connexion",
		render: (item: UserInsuranceNetworkRefModel) => <UserInsuranceNetworkButton isPresent={Boolean(item.login)} />,
		sorter: (a, b) => {
			// Sort non set in ASC first
			return (Boolean(a.login) ? 1 : 0) - (Boolean(b.login) ? 1 : 0)
		},
	},
]

export default function UserInsuranceNetworkPage() {
	useCustomTitle(title)

	const [selectedCredential, setSelectedCredential] = useState<UserInsuranceNetworkRefModel | null>(null)

	const { data: networks, isLoading, isRefetching } = useUserInsuranceNetworks()
	const { data: credentials } = useUserInsuranceCredentials()

	const federatedData: UserInsuranceNetworkRefModel[] = useMemo(() => {
		return (
			networks?.map((item) => {
				const candidate = credentials?.find((c) => c.network === item["@id"])

				let base = {
					iri: item["@id"],
					network: item.name,
					login: null,
					password: null,
				}

				if (!candidate) return base

				return {
					...base,
					id: candidate.id,
					login: candidate.login,
					password: "**********",
				}
			}) ?? []
		)
	}, [networks, credentials])

	return (
		<>
			<Row className="my-2 flex-grow-1">
				<Table
					loading={isLoading || isRefetching}
					columns={userInsuranceColumns}
					pagination={false}
					className="w-100"
					dataSource={federatedData || []}
					rowKey={(record) => record.iri}
					onRow={(record) => {
						return {
							style: {
								cursor: "pointer",
							},
							onClick: () => setSelectedCredential(record),
						}
					}}
				/>
			</Row>

			{
				<UserInsuranceCredentialModal
					credentials={selectedCredential}
					onClose={() => {
						setSelectedCredential(null)
					}}></UserInsuranceCredentialModal>
			}
		</>
	)
}
