import { DeleteOutlined } from "@ant-design/icons"
import { Inventory, Transfer } from "@audiowizard/common"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import { AnonymousFunction } from "constants/types/anonymousFunction"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import API from "services/API"

interface Props {
	logisticForm: Transfer | Inventory
	withLabel?: boolean
	label?: string
	redirect?: string
	onFinish?: AnonymousFunction
	disabled?: boolean
}

const DeleteLogisticForm = ({
	logisticForm,
	withLabel = false,
	label = "",
	redirect,
	onFinish,
	disabled,
}: Props): JSX.Element => {
	const history = useHistory()

	if (!logisticForm) {
		return <></>
	}

	const confirmDelete = async () => {
		const hasConfirmed: boolean = await confirmWithModal({
			title: "Supprimer l'inventaire en cours ?",
			okLabel: "Supprimer",
			cancelLabel: "Annuler",
		})

		if (!hasConfirmed) return

		try {
			await API.delete(logisticForm["@id"])
			redirect && history.push(redirect)
			onFinish?.()
		} catch (error) {
			toast.error("Impossible de supprimer")
		}
	}

	return (
		<>
			{!logisticForm.validatedAt && (
				<button
					type="button"
					className={"btn btn-block btn-sm " + (!withLabel ? "p-1 m-0" : "btn-outline-danger ")}
					disabled={disabled}
					onClick={confirmDelete}>
					{withLabel ? <>Supprimer {label}</> : <DeleteOutlined className="text-lg text-danger" />}
				</button>
			)}
		</>
	)
}

export default DeleteLogisticForm
