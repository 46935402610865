import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { lazy } from "react"
import { AbonnementRoutePrefix, AbonnementRoutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

const ChoixOffre = lazy(() => import("../../pages/mon-compte/ChoixOffre"))
const Facture = lazy(() => import("../../pages/mon-compte/Facture"))
const Parrainage = lazy(() => import("../../pages/mon-compte/MesParrainages"))

function AbonnementRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Abonnement")
	return (
		<CardWithTabs urlprefix={AbonnementRoutePrefix}>
			<CardItem
				name="Choix de l'offre"
				component={ChoixOffre}
				url={noPrefix(AbonnementRoutes.ChoixOffre)}
				withRole={["ROLE_MANAGER"]}
			/>
			<CardItem name="Parrainages" component={Parrainage} url={noPrefix(AbonnementRoutes.Parrainage)} />
			{/* <CardItem
				name="Services annexes"
				component={() => <>À venir prochainement</>}
				url={noPrefix(AbonnementRoutes.ServiceAnnexe)}
				withRole={["ROLE_MANAGER"]}
			/>
			<CardItem
				name="Postes réseaux sociaux"
				component={() => <>À venir prochainement</>}
				url={noPrefix(AbonnementRoutes.PosteReseauxSociaux)}
				withRole={["ROLE_MANAGER"]}
			/> */}
			<CardItem
				name="Factures"
				url={noPrefix(AbonnementRoutes.Facture)}
				component={Facture}
				withRole={["ROLE_MANAGER"]}
			/>
		</CardWithTabs>
	)
}

export default AbonnementRouter
