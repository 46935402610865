import { Design } from "react-email-editor"

export interface MergeTag {
	value?: string
	name: string
	tag?: string
	default?: any
	isEmpty?: boolean
	rules?: {
		repeat: {
			name: string
			before: string
			after: string
			context: string
		}
	}
	sample?: string
	mergeTags?: MergeTags
	context?: string
	label?: string
	format?: (c: any) => string | boolean
	formatHtml?: (c: any) => string | boolean
	allowLowerCase?: boolean
	helper?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MergeTags extends Record<string, MergeTag> {}

interface DataBaliseForMoustache {
	[key: string]: string
}

const getLabelSample = (m: MergeTag): string | boolean => {
	if (m.isEmpty) {
		return ""
	}

	return m.default || ""
}

const getValueFromStore = (keys: string[], store: Context): string => {
	if (!keys.length) {
		return ""
	}
	if (!store[keys[0]]) {
		return ""
	}
	if (keys.length > 1) {
		return getValueFromStore(keys.slice(1), store[keys[0]] as Context)
	}

	return store[keys[0]] as string
}

const buildBalise = (mergeTags: MergeTags, path = "", store?: Context): any => {
	for (const [key, mergeTag] of Object.entries(mergeTags)) {
		const currentPath = path ? path + "." + key : key
		let label = ""

		if (mergeTag.default && store && mergeTag.context) {
			const defaultValue = getValueFromStore(mergeTag.context.split("."), store)
			if (defaultValue) {
				mergeTag.default = defaultValue
				mergeTag.isEmpty = false
			} else {
				mergeTag.default = "non définie"
				mergeTag.isEmpty = true
			}
		}

		if (mergeTag.format && mergeTag.default) {
			label = mergeTag.format(mergeTag.default) as string
		} else {
			label = mergeTag.default || ""
		}

		if (mergeTag.value && !mergeTag.tag) {
			mergeTag.tag = mergeTag.value
			mergeTag.sample = label
			mergeTag.value = `<span data-key='${currentPath}' data-isValid='${
				mergeTag.isEmpty ? "data-is-empty" : "ok"
			}'>${label}</span>`
		}

		// dans le cas de la signature
		if (mergeTag.helper === "signature") {
			mergeTag.sample = "<span style='width: 150px; height: 50px; display:block; padding: 10px' >signature</span>"
		}
		if (mergeTag.mergeTags) {
			mergeTags[key].mergeTags = buildBalise(mergeTag.mergeTags, currentPath, store)
		}
	}
	return mergeTags
}

export const buildMergerTags = (mergeTags: MergeTags, store?: Context): MergeTags => {
	const customMergeTags = buildBalise(mergeTags, "", store)
	return customMergeTags
}

const getTagFromMergeTags = (
	key: string,
	mergeTags: MergeTags
): { tag: string; allowLowerCase: boolean; helper: string | undefined } => {
	const path = key.split(".")
	if (path.length > 1 && mergeTags && mergeTags[path[0]].mergeTags) {
		return getTagFromMergeTags(path.slice(1).join("."), mergeTags[path[0]].mergeTags!)
	}
	return (
		mergeTags && {
			tag: mergeTags[path[0]].tag!,
			allowLowerCase: mergeTags[path[0]].allowLowerCase || false,
			helper: mergeTags[path[0]].helper,
		}
	)
}

const replaceTagWithBaliseInText = (text: string, dataTagsForMustache: DataBaliseForMoustache): string => {
	let tempText = text
	tempText = tempText.replace(/\{toLowerCase /g, "{")
	tempText = tempText.replace(/\{signature /g, "{")
	for (const props in dataTagsForMustache) {
		const escapePropsForRegExp = props.replace(/\^/g, "\\^")
		tempText = tempText.replace(new RegExp(escapePropsForRegExp, "g"), dataTagsForMustache[props])
	}
	return tempText
}

// in the db the design is store with the balise {{tag}}
// to show a balise instead of the tag we have to replace all the tag by the balise
const replaceTagWithBaliseInDesign = (
	entity: any,
	dataBaliseForMoustache: DataBaliseForMoustache,
	data?: { logo?: string }
): any => {
	const tempEntity = entity
	if (Array.isArray(entity)) {
		return [...tempEntity.map((row: any) => replaceTagWithBaliseInDesign(row, dataBaliseForMoustache, data))]
	}
	if (typeof tempEntity === "object" && !Array.isArray(tempEntity) && tempEntity != null) {
		if (tempEntity.logo && data?.logo) {
			tempEntity.logo = {
				url: data.logo,
			}
		}
		if (tempEntity.text) {
			tempEntity.text = replaceTagWithBaliseInText(tempEntity.text, dataBaliseForMoustache)
			return tempEntity
		}
		if (tempEntity.html) {
			tempEntity.html = replaceTagWithBaliseInText(tempEntity.html, dataBaliseForMoustache)
			return tempEntity
		}
		for (const props in tempEntity) {
			tempEntity[props] = replaceTagWithBaliseInDesign(tempEntity[props], dataBaliseForMoustache, data)
		}
		return tempEntity
	}
	return tempEntity
}

// for templated with moustache we can only use a flat object,
// teh mergeTags object allow hierarchy so we have to flatter it
const buildDataBaliseForMoustache = (mergeTags: MergeTags): DataBaliseForMoustache => {
	const dataBaliseForMoustache: DataBaliseForMoustache = {}
	const parseMergeTag = (mergeTag: MergeTags): void => {
		if (typeof mergeTag === "object") {
			for (const props in mergeTag) {
				if (!mergeTag[props]) {
					continue
				}
				if (mergeTag[props].mergeTags) {
					parseMergeTag(mergeTag[props].mergeTags!)
				}
				if (mergeTag[props].value && typeof mergeTag[props].tag === "string") {
					dataBaliseForMoustache[mergeTag[props].tag!] = mergeTag[props].value!
				}
			}
		}
	}

	parseMergeTag(mergeTags)
	return dataBaliseForMoustache
}

export const addBaliseToTemplate = (design: Design, mergeTags: MergeTags, data?: { logo?: string }): any => {
	const dataBaliseForMoustache = buildDataBaliseForMoustache(mergeTags)
	return replaceTagWithBaliseInDesign(design, dataBaliseForMoustache, data)
}

// il faut remplacer le span par le tag
const templateFromNode = (node: Node, mergeTags: MergeTags, htmlDoc: Document): Node => {
	if (node.nodeType === Node.TEXT_NODE) {
		// Text node, return self value
		return node
	} else if (node.nodeType === Node.ELEMENT_NODE) {
		const el = node as HTMLElement
		if (el.dataset.key != null) {
			// Variable element (based on data-key attribute), reconstruct #() syntax
			const { tag, allowLowerCase, helper } = getTagFromMergeTags(el.dataset.key, mergeTags)
			if (allowLowerCase && el.previousSibling && !/\. ?$/.test(el.previousSibling.textContent || "")) {
				return htmlDoc.createTextNode(tag.replace(/{{(?![\^#])/, "{{toLowerCase "))
			}
			if (helper) {
				return htmlDoc.createTextNode(tag.replace(/{{{(?![\^#])/, `{{{${helper} `))
			}
			return htmlDoc.createTextNode(tag)
		} else {
			// Other element recurse through children nodes
			const tempChildren = [...el.children]
			let nbNodeReplaceByText = 0
			for (let i = 0; i < tempChildren.length; i++) {
				const nbOfChildren = el.children.length
				el.children[i - nbNodeReplaceByText].replaceWith(templateFromNode(tempChildren[i], mergeTags, htmlDoc))
				nbNodeReplaceByText += nbOfChildren !== el.children.length ? 1 : 0
			}
			return el
		}
	}
	return node
}

const baliseToTemplate = (htmlWithBalise: string, mergeTags: MergeTags): string => {
	const parser = new DOMParser()
	const htmlDoc = parser.parseFromString(htmlWithBalise, "text/html")
	return (templateFromNode(htmlDoc.body, mergeTags, htmlDoc) as HTMLElement).innerHTML
}

// Prepare the html for handlebar
export const baliseToTemplateHtml = (
	htmlWithBalise: string,
	mergeTags: MergeTags,
	css = "",
	keepBackgroundColor = false
): string => {
	const parser = new DOMParser()
	const serializer = new XMLSerializer()
	const htmlDoc = parser.parseFromString(htmlWithBalise, "text/html")
	const headerStyle = htmlDoc.head.getElementsByTagName("style")
	// insert css in the header to improve printing
	if (headerStyle && headerStyle[0]) {
		headerStyle[0].innerHTML = headerStyle[0].innerHTML.replace("@media (", "@media only screen (")
		if (css) {
			headerStyle[0].innerHTML += css
		}
		// fix temporaire suit anomalie librairie Unlayer
		headerStyle[0].innerHTML += `p {
			margin: 0
		}`
		headerStyle[0].innerHTML += "table, .u-row-container { page-break-inside: avoid;} "
	}

	// prepare the body
	if (htmlDoc.body) {
		if (keepBackgroundColor) {
			// @ts-ignore
			htmlDoc.body.style["-webkit-print-color-adjust"] = "exact"
		}
		htmlDoc.body.replaceWith(templateFromNode(htmlDoc.body, mergeTags, htmlDoc))
	}
	return serializer.serializeToString(htmlDoc)
}

const replaceBaliseWithTagInDesign = (entity: any, mergeTags: MergeTags): any => {
	const tempEntity = entity
	if (Array.isArray(entity)) {
		return tempEntity.map((row: any) => replaceBaliseWithTagInDesign(row, mergeTags))
	}
	if (typeof tempEntity === "object" && !Array.isArray(tempEntity) && tempEntity != null) {
		if (tempEntity.text) {
			tempEntity.text = baliseToTemplate(tempEntity.text, mergeTags)
			return tempEntity
		}
		if (tempEntity.html) {
			tempEntity.html = baliseToTemplate(tempEntity.html, mergeTags)
			return tempEntity
		}
		for (const props in tempEntity) {
			tempEntity[props] = replaceBaliseWithTagInDesign(tempEntity[props], mergeTags)
		}
		return tempEntity
	}
	return tempEntity
}

// before we save the design to the database we have to replace the balise "span" by the tag correspondant
export const restoreTagInDesign = (design: Design, mergeTags: MergeTags): any => {
	if (design && design.body && design.body.rows) {
		design.body.rows.map((row: any) => replaceBaliseWithTagInDesign(row, mergeTags))
	}

	return design
}

export type Context = {
	[key: string]: string | boolean | Context[] | Context
}

const setParams = (keys: string[], value: string | boolean | Context[], store: Context): void => {
	if (!keys.length) {
		return
	}

	if (keys.length > 1) {
		if (!store[keys[0]]) {
			store[keys[0]] = {}
		}
		setParams(keys.slice(1), value, store[keys[0]] as Context)
	} else {
		store[keys[0]] = value
	}
}

const getArraySample = (mergeTagArray: MergeTag): Context[] => {
	const sampleArray: Context[] = []
	if (mergeTagArray.mergeTags) {
		for (let i = 0, nbrTotalRow = Math.floor(Math.random() * 3) + 2; i < nbrTotalRow; i++) {
			sampleArray.push(createStoreFromMergeTags(mergeTagArray.mergeTags))
		}
	}
	return sampleArray
}

export const createStoreFromMergeTags = (mergeTags: MergeTags): Context => {
	const tempStore: Context = {}
	const parseMergeTags = (m: MergeTags): void => {
		for (const key in m) {
			if (m[key].rules) {
				if ((m[key].rules?.repeat, m[key].mergeTags)) {
					const pathToArray = m[key].rules?.repeat.context.split(".")
					if (pathToArray) {
						setParams(pathToArray, getArraySample(m[key]), tempStore)
					}
				}
			} else if (m[key].mergeTags) {
				parseMergeTags(m[key].mergeTags as MergeTags)
			}
			const path = m[key].context?.split(".")
			if (path) {
				setParams(path, getLabelSample(m[key]), tempStore)
			}
		}
	}

	parseMergeTags(mergeTags)

	return tempStore
}

const getTagFromMergeTag = (m: MergeTag): string => {
	let tag = ""
	if (m.rules?.repeat.before) {
		tag = m.rules?.repeat.before
	} else {
		tag = m.tag || ""
	}
	const getTag = tag.match(/{{2,3}[#^]?([^}]*)}{2,3}/)

	if (getTag && getTag[1]) {
		return getTag[1]
	}

	return ""
}

interface DataForTemplate {
	[key: string]: string | { [key: string]: string }[]
}

export const createDataForTemplateFromContext = (mergeTags: MergeTags, store: Context): DataForTemplate => {
	const tempData: DataForTemplate = {}
	const setData = (keys: string[], mergeTag: MergeTag, store: Context, mergeTags?: MergeTags): void => {
		if (!keys.length) {
			return
		}

		const tag = getTagFromMergeTag(mergeTag)
		if (keys.length > 1) {
			if (!store[keys[0]]) {
				store[keys[0]] = {}
			}
			setData(keys.slice(1), mergeTag, store[keys[0]] as Context, mergeTags)
		} else if (Array.isArray(store[keys[0]]) && mergeTags) {
			if (mergeTag.format && store[keys[0]]) {
				const value = mergeTag.format(store[keys[0]])
				setParams(tag.split("."), value as string, tempData)
			} else {
				tempData[tag] = [] as { [key: string]: string }[]
				;(store[keys[0]] as Context[]).forEach((item: Context) => {
					;(tempData[tag] as DataForTemplate[]).push(createDataForTemplateFromContext(mergeTags, item))
				})
			}
		} else {
			let value = store[keys[0]]
			if (mergeTag.format && value) {
				value = mergeTag.format(value)
			}
			setParams(tag.split("."), value as string, tempData)
		}
	}
	const parseMergeTags = (m: MergeTags): void => {
		for (const key in m) {
			if (m[key].rules) {
				if ((m[key].rules?.repeat, m[key].mergeTags)) {
					const pathToArray = m[key].rules?.repeat.context.split(".")
					if (pathToArray) {
						setData(pathToArray, m[key], store, m[key].mergeTags)
					}
				}
			} else if (m[key].mergeTags) {
				parseMergeTags(m[key].mergeTags as MergeTags)
			}
			const path = m[key].context?.split(".")
			if (path) {
				setData(path, m[key], store, mergeTags)
			}
		}
	}

	parseMergeTags(mergeTags)

	return tempData
}

// construct the object needed by unlayer to show the tags in the menu
export const cleanMergeTagsForEditor = (mergeTags: MergeTags): MergeTags => {
	const cleanMergeTag = (m: MergeTags): MergeTags => {
		const tempMergeTags: MergeTags = {}
		for (const key in m) {
			tempMergeTags[key] = {
				name: m[key].name,
				value: m[key].value,
				sample: m[key].sample,
				rules: m[key].rules,
			}
			if (m[key].mergeTags) {
				tempMergeTags[key].mergeTags = cleanMergeTag({ ...m[key].mergeTags } as MergeTags)
			}
		}
		return tempMergeTags
	}
	return cleanMergeTag({ ...mergeTags })
}

export const getBase64FromUrl = async (url?: string): Promise<string> => {
	if (!url) {
		return new Promise<string>(() => void 0)
	}
	try {
		const data = await fetch(url)
		const blob = await data.blob()
		return new Promise<string>((resolve) => {
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onloadend = () => {
				const base64data = reader.result as string
				resolve(base64data)
			}
		})
	} catch (err) {
		console.error("Erreur lors de la récupération de l’image")
		return new Promise<string>(() => void 0)
	}
}
