/* eslint-disable react-hooks/exhaustive-deps */
import { FileAddTwoTone, FilePdfTwoTone } from "@ant-design/icons"
import { Button, Input, Table, Tooltip } from "antd"
import ButtonRounded from "components/Buttons/ButtonRounded"
import useEffectAsync from "components/Hooks/useEffectAsync"
import useCustomTitle from "components/Hooks/useTitle"
import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import TableDateRangeFilter from "components/utils/TableDateRangeFilter"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { get, set } from "idb-keyval"
import DocumentUploader from "pages/fiche-patient/Documents/Modal.DocumentUploader"
import DocumentModal from "pages/fiche-patient/Documents/Modal.DocumentViewer"
import { useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { getIdFromIri } from "services/functions"
import API from "../../services/API"
import "./ListeBL.scss"

function ListeBonLivraison({ history }) {
	const { laboratory } = useContext(AuthContext)
	const [data, setData] = useState([])
	const [isDepot, setIsDepot] = useState(false)
	const [loading, setLoading] = useState(true)
	const [filters, setFilters] = useState({
		laboratory,
	})
	const timeoutFilter = useRef(null)

	const [previousSearchs, setPreviousSearchs] = useState({ bl: [], sn: [] })
	const [displayPreviousBlSearch, setDisplayPreviousBlSearch] = useState(false)
	const [displayPreviousSnSearch, setDisplayPreviousSnSearch] = useState(false)
	const [documentUploaderSettings, setDocumentUploaderSettings] = useState({
		isOpen: false,
		document: {},
		logisticId: null,
	})
	const [documentModalSettings, setDocumentModalSettings] = useState({
		isOpen: false,
		document: {},
	})
	const MAX_HISTORY_LENGTH = 5

	const queryClient = useQueryClient()

	const { mutate: updateLogisticWithDocument } = useMutation(
		async ({ annexId, logisticId }) => {
			if (!annexId || !logisticId) return
			await API.update("LOGISTIQUE_API", logisticId, { annex: annexId })
			getandSetBlList()
		},
		{
			onSuccess: async () => await queryClient.invalidateQueries("LOGISTIQUE_API"),
			onError: () => toast.error("L'ajout de document a échoué"),
		}
	)

	useEffectAsync(async () => {
		let previousResults = await get("previousSearchs")
		if (previousResults !== undefined) setPreviousSearchs(previousResults)
	}, [])

	const setSearchHistoryInIndexDb = (row) => {
		let tmp = { ...previousSearchs }
		if (!tmp.bl.some((bl) => bl.id === row.id)) tmp.bl.push({ id: row.id, number: row.numero })
		if (tmp.bl.length > MAX_HISTORY_LENGTH) tmp.bl.shift()

		// 3 being the minimum amount of chars for a sn search to be considered as savable in search history
		if (filters.serialNumber?.length > 3) {
			if (!tmp.sn.some((bl) => bl.id === row.id)) tmp.sn.push({ id: row.id, serialNumber: filters.serialNumber })
			if (tmp.sn.length > MAX_HISTORY_LENGTH) tmp.sn.shift()
		}
		set("previousSearchs", tmp)
	}

	const columns = [
		{
			title: "Numéro",
			dataIndex: "numero",
			render: (d, row) => {
				if (row.oldBL) {
					return <span className="badge badge-bl-secondary">{d}</span>
				} else {
					return <span className="badge badge-bl-primary">{d}</span>
				}
			},
		},
		{
			title: "Date de réception",
			dataIndex: "dateReceipt",
			sorter: (a, b) => dayjs(a.dateReceipt).unix() - dayjs(b.dateReceipt).unix(),
			render: (d) => <>{dayjs(d).format("DD-MM-YYYY")}</>,
			filterDropdown: TableDateRangeFilter,
			filteredValue: filters.dateReceipt,
		},
		{
			title: "Date d'édition",
			dataIndex: "dateEdition",
			render: (d) => <> {dayjs(d).format("DD-MM-YYYY")}</>,
			filterDropdown: TableDateRangeFilter,
			filteredValue: filters.dateEdition,
		},
		{
			title: "Date de fin de dépôt",
			dataIndex: "depotEndDate",
			render: (d) => (d ? <>{dayjs(d).format("DD-MM-YYYY")}</> : "N/A"),
			filterDropdown: TableDateRangeFilter,
			filteredValue: filters.depotEndDate,
		},
		{
			title: "Nombre de produits",
			dataIndex: "nb_products",
			render: (d, row) => {
				if (!row.oldBL) {
					return <>{row.logisticProductsCount}</>
				} else {
					return <>{d}</>
				}
			},
		},
		{
			title: "Status",
			dataIndex: "status",
		},
		{
			title: "Dépôt",
			dataIndex: "depot",
			render: (depot) => (depot ? "OUI" : "NON"),
		},
		{
			title: "Bon de Commande",
			dataIndex: "purchaseOrder",
			width: "150px",
			render: (purchaseOrder) =>
				purchaseOrder != null ? (
					<Tooltip placement="top" title="Voir le Bon de Commande">
						<Button
							size="small"
							className="text-primary border-primary"
							icon={<i className="fad fa-money-check" />}
							onClick={(e) => {
								e.stopPropagation() // prevent row click
								history.push(`/bon-de-commande/${purchaseOrder.id}/editer`)
							}}
						/>
					</Tooltip>
				) : (
					""
				),
		},
		{
			title: "Annexe",
			dataIndex: "annex",
			render: (annex, row) => {
				if (annex == null)
					return (
						<Tooltip placement="top" title="Ajouter une annexe">
							<FileAddTwoTone
								style={{ fontSize: "22px" }}
								onClick={() =>
									setDocumentUploaderSettings({
										isOpen: true,
										document: { id: getIdFromIri(annex) },
										logisticId: row.id,
									})
								}
							/>
						</Tooltip>
					)
				return (
					<Tooltip placement="top" title="Voir l'annexe">
						<FilePdfTwoTone
							twoToneColor="#52c41a"
							style={{ fontSize: "22px" }}
							onClick={() =>
								setDocumentModalSettings({
									isOpen: true,
									document: { id: getIdFromIri(annex) },
								})
							}
						/>
					</Tooltip>
				)
			},
		},
	]

	function onRowClick(row) {
		if (!row.oldBL) {
			history.push({
				pathname: `/bon-de-livraison/${row.id}`,
				state: { isEditing: true },
			})
		} else {
			history.push({
				pathname: `/bon-de-livraison-ancien/${row.id}`,
				state: { isEditing: true },
			})
		}
	}

	useCustomTitle("Bon de livraison")

	const getandSetBlList = async () => {
		try {
			setLoading(true)

			let filtersStr = ""

			if (filters?.name) filtersStr += "&label[]=" + filters.name
			if (filters?.serialNumber) filtersStr += "&logisticProducts.productSerialNumber[]=" + filters.serialNumber

			if (filters?.laboratory?.warehouseIdHiboutik)
				filtersStr += "&stockIdHiboutik[]=" + filters.laboratory.warehouseIdHiboutik

			if (filters?.dateReceipt?.length > 0) {
				filtersStr += `&dateReceipt[after]=${filters.dateReceipt[0]}&dateReceipt[before]=${filters.dateReceipt[1]}`
			}
			if (filters?.dateEdition?.length > 0) {
				filtersStr += `&dateEdition[after]=${filters.dateEdition[0]}&dateEdition[before]=${filters.dateEdition[1]}`
			}
			if (filters?.depotEndDate?.length > 0) {
				filtersStr += `&depotEndDate[after]=${filters.depotEndDate[0]}&depotEndDate[before]=${filters.depotEndDate[1]}`
			}

			const result = await API.findAll("LOGISTIQUE_API", "?type[]=BON_LIVRAISON" + filtersStr)

			const tmp = []
			if (result) {
				for (let i = result.length - 1; i >= 0; i--) {
					if (result[i].status) {
						const receivedProduct = result[i].products.filter((v) => v.received === true).length

						tmp.push({
							key: i,
							...result[i],
							nb_products: receivedProduct + " / " + result[i].products.length,
							oldBL: result[i].products.length,
						})
					}
				}
				setData(tmp)
			}
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getandSetBlList()
	}, [filters])

	return (
		<>
			<ButtonRounded
				icon="fa-plus"
				type="button"
				color="primary-outlined"
				className="ml-auto mb-2"
				onClick={() => history.push("/bon-de-livraison/nouveau")}>
				Nouveau bon de livraison
			</ButtonRounded>
			<br />

			<div className="row">
				<div className="col-3">
					Voir seulement les dépôts :{" "}
					<div className="btn-group">
						<button
							type="button"
							className={"btn btn-block " + (isDepot ? "btn-primary" : "btn-outline-primary")}
							onClick={() => setIsDepot(true)}>
							Oui
						</button>
						<button
							type="button"
							className={"btn btn-block " + (!isDepot ? "btn-primary" : "btn-outline-primary")}
							onClick={() => setIsDepot(false)}>
							Non
						</button>
					</div>
				</div>
				<div className="col">
					Rechercher par numéro/nom :
					<Input
						className="w-100 input-search"
						placeholder="BL00001"
						allowClear
						size="large"
						onChange={(evt) => {
							const value = evt.target.value
							clearTimeout(timeoutFilter.current)
							timeoutFilter.current = null
							timeoutFilter.current = setTimeout(() => {
								setFilters((old) => ({ ...old, name: value }))
							}, 500)
						}}
						onFocus={() => setDisplayPreviousBlSearch(true)}
						onBlur={() => setTimeout(() => setDisplayPreviousBlSearch(false), 100)} // timeout necessary otherwise clicks on following div won't trigger onClick method
					/>
					{!filters.name && displayPreviousBlSearch && previousSearchs.bl.length !== 0 && (
						<div className="previous-search">
							<span>
								<i className="fad fa-history" />
								<hr />
							</span>
							{previousSearchs.bl.map((r) => (
								<li key={r.number} onClick={() => onRowClick(r)}>
									{r.number}
								</li>
							))}
						</div>
					)}
				</div>
				<div className="col">
					Rechercher par SN :{" "}
					<Input
						className="w-100"
						placeholder="SN000010101"
						allowClear
						size="large"
						onChange={(evt) => {
							const value = evt.target.value
							clearTimeout(timeoutFilter.current)
							timeoutFilter.current = null
							timeoutFilter.current = setTimeout(() => {
								setFilters((old) => ({ ...old, serialNumber: value }))
							}, 500)
						}}
						onFocus={() => setDisplayPreviousSnSearch(true)}
						onBlur={() => setTimeout(() => setDisplayPreviousSnSearch(false), 100)} // timeout necessary otherwise clicks on following div won't trigger onClick method
					/>
					{!filters.serialNumber && displayPreviousSnSearch && previousSearchs.sn.length !== 0 && (
						<div className="previous-search">
							<span>
								<i className="fad fa-history" />
								<hr />
							</span>
							{previousSearchs.sn.map((r) => (
								<li key={r.serialNumber} onClick={() => onRowClick(r)}>
									{r.serialNumber}
								</li>
							))}
						</div>
					)}
				</div>
				<div className="col">
					Filtrer par laboratoire :{" "}
					<AsyncLaboratorySelect
						warehouseOnly={true}
						setData={(_, laboratory) => {
							setFilters((old) => ({ ...old, laboratory }))
						}}
						data={filters?.laboratory?.id}
						className="w-100"
						size="large"
						mode={null}
						placeholder="Sélectionner un laboratoire"
					/>
				</div>
			</div>

			<br />
			<Table
				loading={loading}
				pagination={{ position: ["bottomLeft"] }}
				dataSource={isDepot ? data.filter((d) => d.depot === true) : data}
				columns={columns}
				rowClassName="bl-table"
				onRow={(record) => {
					return {
						onClick: (event) => {
							const target = event.target
							if (target.tagName?.toLocaleLowerCase() === "path") return

							onRowClick(record)
							setSearchHistoryInIndexDb(record)
						},
					}
				}}
				onChange={(_, filters) => {
					setFilters((old) => ({ ...old, ...filters }))
				}}
			/>

			<DocumentUploader
				isOpen={documentUploaderSettings.isOpen}
				onClose={() => {
					setDocumentUploaderSettings((old) => ({ ...old, isOpen: false, document: {} }))
				}}
				documentType="BL_ANNEX"
				refreshDocuments={(annexId) => {
					updateLogisticWithDocument({ annexId, logisticId: documentUploaderSettings.logisticId })
					setFilters((old) => ({ ...old, refresh: true }))
				}}
			/>

			<DocumentModal
				isOpen={documentModalSettings.isOpen}
				onClose={() =>
					setDocumentModalSettings({
						isOpen: false,
						document: {},
					})
				}
				document={documentModalSettings.document}
				refreshDocuments={() => {
					setFilters((old) => ({ ...old, refresh: true }))
				}}
			/>
		</>
	)
}

export default withRouter(ListeBonLivraison)
