import { EuroOutlined, FileSearchOutlined } from "@ant-design/icons"
import { SupplierInvoiceProduct, SupplierInvoiceProductCreditStatus } from "@audiowizard/common"
import { Button, Space, Table, Tooltip } from "antd"
import { ColumnType } from "antd/lib/table"
import { SorterResult } from "antd/lib/table/interface"
import { useAllLaboratoriesQuery } from "components/Hooks/commonQueries"
import dayjs from "dayjs"
import { useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Badge } from "reactstrap"
import API from "services/API"
import { formatCurrency } from "services/functions"

const statusBadges = {
	NO_CREDIT: {
		label: "En attente d'avoir",
		color: "warning",
	},
	PENDING: {
		label: "Avoir non validé",
		color: "info",
	},
	VALIDATED: {
		label: "Avoir reçu",
		color: "primary",
	},
} as const

export default function CreditsTable(): JSX.Element {
	const history = useHistory()

	const [page, setPage] = useState(1)
	const [filters, setFilters] = useState<Record<string, any>>({})
	const [sort, setSort] = useState<{
		key: "supplierInvoice.receiptDate" | "supplierInvoiceProductCredit.supplierInvoice.receiptDate"
		order: "ascend" | "descend"
	}>({ key: "supplierInvoice.receiptDate", order: "descend" })

	// For filters
	const { data: laboratories } = useAllLaboratoriesQuery()

	const { data: productsRes, isLoading } = useQuery(
		["SUPPLIER_INVOICE_PRODUCTS_API", "/with_credit", { wantsCredit: true, page, filters, sort }],
		async () => {
			const searchParams = new URLSearchParams()
			searchParams.set("wantsCredit", "true")
			searchParams.set("page", page.toString())
			searchParams.set(`order[${sort.key}]`, sort.order === "ascend" ? "ASC" : "DESC")

			for (const [key, values] of Object.entries(filters)) {
				if (values == null) continue

				for (const v of values) searchParams.append(`${key}[]`, v)
			}

			return await API.findAll<SupplierInvoiceProduct[]>(
				"SUPPLIER_INVOICE_PRODUCTS_API",
				`/with_credit?${searchParams}`,
				true
			)
		},
		{
			onError: () => {
				toast.error("Erreur lors de la récupération des produits")
			},
		}
	)
	const products = productsRes?.["hydra:member"] ?? []
	const totalProducts = productsRes?.["hydra:totalItems"] ?? 0

	const columns: ColumnType<SupplierInvoiceProduct>[] = [
		{
			key: "supplierInvoice.laboratory",
			title: "Laboratoire",
			render: (_, p) => p.supplierInvoice!.laboratory?.label,
			filterSearch: true,
			filters: laboratories?.map((l) => ({
				value: l["@id"],
				text: l.label,
			})),
			filteredValue: filters.laboratory,
		},
		{
			key: "name",
			title: "Nom",
			render: (_, p) => p.product.model,
		},
		{
			key: "size",
			title: "Déclinaison",
			render: (_, p) => p.sizeName,
		},
		{
			key: "price-diff",
			title: "Différence de prix",
			render: (_, p) => formatCurrency(p.actualGrossPrice! - p.expectedGrossPrice),
		},
		{
			key: "amount",
			title: "Montant de l'avoir",
			render: (_, p) => {
				return p.supplierInvoiceProductCredit != null ? (
					formatCurrency(p.supplierInvoiceProductCredit.amount)
				) : (
					<small>Aucun avoir</small>
				)
			},
		},
		{
			dataIndex: "creditStatus",
			title: "Statut",
			render: (creditStatus: SupplierInvoiceProductCreditStatus) => {
				if (creditStatus == null) return ""

				return (
					<Badge color={statusBadges[creditStatus]?.color ?? "secondary"}>
						{statusBadges[creditStatus]?.label ?? "Inconnu"}
					</Badge>
				)
			},
			filters: Object.entries(statusBadges).map(([status, { color, label }]) => ({
				text: <Badge color={color}>{label}</Badge>,
				value: status,
			})),
			filteredValue: filters.status,
		},
		{
			key: "supplierInvoice.receiptDate",
			title: "Produit reçu le",
			render: (_, p) => dayjs(p.supplierInvoice!.receiptDate).format("LL"),
			sorter: true,
			sortOrder: sort.key === "supplierInvoice.receiptDate" ? sort.order : null,
		},
		{
			key: "supplierInvoiceProductCredit.supplierInvoice.receiptDate",
			title: "Avoir reçu le",
			render: (_, p) =>
				p.supplierInvoiceProductCredit != null
					? dayjs(p.supplierInvoiceProductCredit!.supplierInvoice!.receiptDate).format("LL")
					: "",
			sorter: true,
			sortOrder: sort.key === "supplierInvoiceProductCredit.supplierInvoice.receiptDate" ? sort.order : null,
		},
		{
			key: "actions",
			title: "Actions",
			render: (_, p) => (
				<Space wrap>
					<Tooltip title="Voir la facture du produit" trigger="hover">
						<Button
							className="text-info border-info"
							icon={<FileSearchOutlined />}
							onClick={() =>
								history.push(`/comptabilite/factures-fournisseur/edition/${p.supplierInvoice!.id}`)
							}
						/>
					</Tooltip>

					<Tooltip title="Voir la facture de l'avoir" trigger="hover">
						<Button
							className="text-info border-info"
							icon={<EuroOutlined />}
							disabled={p.supplierInvoiceProductCredit == null}
							onClick={() =>
								history.push(
									`/comptabilite/factures-fournisseur/edition/${
										p.supplierInvoiceProductCredit.supplierInvoice!.id
									}`
								)
							}
						/>
					</Tooltip>
				</Space>
			),
		},
	]

	return (
		<Table
			loading={isLoading}
			columns={columns}
			dataSource={products}
			pagination={{
				position: ["bottomLeft"],
				total: totalProducts,
				current: page,
				pageSize: 10,
				showSizeChanger: false,
			}}
			onChange={(pagination, filters, sorter) => {
				setPage(pagination.current!)

				setFilters(filters)

				sorter = sorter as SorterResult<SupplierInvoiceProduct>
				setSort({ key: sorter.columnKey, order: sorter.order } as any)
			}}
		/>
	)
}
