export const BonCommandeRoutePrefix = "/bon-de-commande"

export const BonCommandeRoutes = {
	List: `${BonCommandeRoutePrefix}`,
	New: `${BonCommandeRoutePrefix}/nouveau`,
	Edit: `${BonCommandeRoutePrefix}/:id/editer`,
	ListProduct: `${BonCommandeRoutePrefix}/produits`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(BonCommandeRoutePrefix, "")
}
