import React from "react"
import "./SelectWithIcon.scss"
type Option = {
	value: string
	label: string
}

interface SelectWithIconProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	options: Option[] | undefined
	icon: string
}

const SelectWithIcon = (props: SelectWithIconProps): JSX.Element => {
	const { options, icon, ...rest } = props
	return (
		<div className="select-with-icon">
			<div className="select-icon">
				<i className={`fad fa-fw fa-${icon}`} />
			</div>
			<select {...rest}>
				{props.options?.map((option, index) => (
					<option key={option.value + index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}

export default SelectWithIcon
