import { Modal, ModalBody } from "reactstrap"
import MagikHat from "assets/img/brand/audiowizard-magik.png"
import "./ServiceUpgradeModal.scss"

interface ServiceUpgradeModalProps {
	isOpen: boolean
	icon: string
	description: string
	price: string
	buttons: {
		primary: {
			/* In purpose of confirm action */
			text: string
			action: () => void
		}
		outlined: {
			/* In purpose of cancel action */
			text: string
			action: () => void
		}
	}
}

export default function ServiceUpgradeModal({
	isOpen,
	icon,
	description,
	price,
	buttons,
}: ServiceUpgradeModalProps): JSX.Element {
	return (
		<Modal isOpen={isOpen} toggle={buttons.outlined.action} centered>
			<ModalBody>
				<div className="service-upgrade-modal">
					<figure className="service-upgrade-modal__logo">
						<img src={MagikHat} alt="Logo audiowizard avec une étoile" />
						<figcaption>Encore plus de magie !</figcaption>
					</figure>
					<p className="service-upgrade-modal__description">
						<i className={`fad fa-${icon}`} />
						{description}
					</p>
					<div className="service-upgrade-modal__actions">
						<h3>{price}</h3>
						<button
							className="service-upgrade-modal__actions__btn--primary"
							onClick={buttons.primary.action}>
							{buttons.primary.text}
						</button>
						<button
							className="service-upgrade-modal__actions__btn--outlined"
							onClick={buttons.outlined.action}>
							{buttons.outlined.text}
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}
