export const CommandesRoutePrefix = "/consommables"

export const CommandesRoutes = {
	FournisseurAudio: `${CommandesRoutePrefix}/fournisseur-audio`,
	CommandePrint: `${CommandesRoutePrefix}/print`,
	MaterielNonMedical: `${CommandesRoutePrefix}/materiel-non-medical`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(CommandesRoutePrefix, "")
}
