import { notification } from "antd"
import axios from "axios"
import "config/intl" // Internationalization w/ i18next library
import preval from "preval.macro"
import { createRoot } from "react-dom/client"
import "./assets/css/antd.css"
import "./assets/css/purpose.css"
import "./assets/scss/main.scss"
import EntryPoint from "./components/containers/EntryPoint/EntryPoint"
import { SOCKET_REALM, SOCKET_RNS_IP } from "./config"
// import { get as keyvalGet, set as keyvalSet } from "idb-keyval"
import CacheSystem from "./services/CacheSystem/Cache.ts"
import RealtimeNotificationSystem from "./services/RealtimeSystem/Interceptors"

const BUILD_DATE = preval`module.exports = new Date();`
window.BUILD_DATE = BUILD_DATE

let socket = null,
	socket_rns = null

const options = {
	reconnection: true,
	reconnectionDelay: 5000,
	reconnectionDelayMax: 15000,
	reconnectionAttempts: Infinity,
	secure: true,
}

if (SOCKET_REALM === "local") {
	options.reconnection = false
}

socket_rns = global.io(SOCKET_RNS_IP, { ...options })

socket_rns.on("disconnect", () => {
	notification.error({
		message: "Connexion perdue",
		description: "La connexion au service de notification a été perdue",
		//top: 80,
		duration: 3,
		placement: "bottom",
	})
})

const axiosErrorHandler = async (error) => {
	return Promise.reject({ ...error })
}

const axiosSuccessHandler = async (success) => {
	try {
		RealtimeNotificationSystem.interceptResponse(success, socket_rns)

		success = await CacheSystem.axiosInterceptResponse(success)
	} catch (_void) {
	} finally {
		return success
	}
}

axios.interceptors.request.use(async (request) => {
	try {
		return await CacheSystem.axiosInterceptRequest(request)
	} catch (_error) {
		return request
	}
})

axios.interceptors.response.use(
	(response) => axiosSuccessHandler(response),
	(error) => axiosErrorHandler(error)
)

createRoot(document.getElementById("root")).render(<EntryPoint socket={socket} socket_rns={socket_rns} />)
