import { CoverageDemandState } from "../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import { Colors } from "./theme"

export default function getCoverageDemandColorAndText(state: CoverageDemandState): string {
	switch (state) {
		case CoverageDemandState.WAITING_INSERT:
		case CoverageDemandState.WAITING_TRANSFORM:
		case CoverageDemandState.WAITING_NETWORK:
		case CoverageDemandState.INSERTED:
			return Colors.infoColor
		case CoverageDemandState.DECLINED:
			return Colors.errorColor
		case CoverageDemandState.CANCELED:
			return Colors.disabled
		case CoverageDemandState.PAID:
		case CoverageDemandState.BANK_TRANSFER_ISSUED:
		case CoverageDemandState.INVOICED_NETWORK:
		case CoverageDemandState.VALIDATED:
			return Colors.primaryColor
		case CoverageDemandState.FUNCTIONAL_ERROR:
		case CoverageDemandState.ONGOING_ERROR:
		case CoverageDemandState.INSERT_ERROR:
		case CoverageDemandState.SYSTEM_ERROR:
			return Colors.warningColor
		default:
			return "transparent"
	}
}
