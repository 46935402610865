import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useContext, useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { Col, Row } from "reactstrap"
import FieldWithError from "../../components/forms/FieldWithError"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import { haveRole } from "../../services/functions"
import "./informationEntreprise.scss"
const InformationEntreprise = ({ history }) => {
	const { user, setUser } = useContext(AuthContext)

	const [company, setCompany] = useState({
		label: user?.company?.label || "",
		siret: user?.company?.siret || "",
		adress: user?.company?.adress || "",
		city: user?.company?.city || "",
		cpo: user?.company?.cpo || "",
		capitalStock: user?.company?.capitalStock || "",
		siren: user?.company?.siren || "",
		tva: user?.company?.tva || "",
		rcs: user?.company?.rcs || "",
	})
	const [logoFile, setLogoFile] = useState(null)
	const [logoUrl, setLogoUrl] = useState(user?.company?.logo)

	const [errors, setErrors] = useState({
		label: "",
		siret: "",
		adress: "",
		city: "",
		cpo: "",
		capitalStock: "",
		siren: "",
		tva: "",
		rcs: "",
		logo: "",
	})

	const resetError = () => {
		setErrors({
			label: "",
			siret: "",
			adress: "",
			city: "",
			cpo: "",
			capitalStock: "",
			siren: "",
			tva: "",
			rcs: "",
			logo: "",
		})
	}

	const handleChange = (event) => {
		const { target } = event
		let { name, value, files } = target
		let newValue = value

		if (name === "labelCompany") name = "label"

		if (name === "cpo" || name === "siret") {
			const lastChar = value.charAt(value.length - 1)
			if (lastChar && (lastChar < "0" || lastChar > "9")) {
				return
			}
		}

		if (name === "logo") {
			setLogoFile(files[0])

			// Libère la mémoire de l'ancien fichier uploadé
			URL.revokeObjectURL(logoUrl)
			setLogoUrl(URL.createObjectURL(files[0]))
		}

		setCompany((prevCompany) => {
			return {
				...prevCompany,
				[name]: newValue,
			}
		})
	}

	useEffect(() => {
		if (!haveRole(user, "ROLE_MANAGER")) {
			history.push("/")
			toast.error("Vous n'avez pas accès à cette page.")
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	// useEffect utilisé seulement lors du mount

	// Libère la mémoire utilisé par logoUrl
	useEffect(() => {
		return () => {
			URL.revokeObjectURL(logoUrl)
		}
	}, [logoUrl])

	const updateLogo = async () => {
		await API.updateCompanyLogo(user.company.id, logoFile)
	}

	const handleSubmit = async () => {
		const newErrors = {}

		if (company.cpo && company.cpo.length !== 5 && company.cpo.length !== 0) {
			newErrors.cpo = "Le code postal doit avoir 5 chiffres"
		}
		if (company.siret && company.siret.length !== 14 && company.siret.length !== 0) {
			newErrors.siret = "Le siret doit avoir 14 chiffres"
		}

		const nbErrors = Object.keys(newErrors).length
		if (nbErrors !== 0) {
			setErrors({
				...errors,
				...newErrors,
			})
		} else {
			try {
				let newData = {
					...company,
					siret: company.siret.length ? company.siret : null,
				}

				if (logoFile != null) {
					await updateLogo()
				}

				const response = await API.update("COMPANIES_API", user.company.id, newData)
				if (response.status === 200) {
					toast.success("Les informations ont été modifiées avec succès")
					resetError()
					setUser({
						...user,
						company: {
							...response.data,
							audioWizardContract: user.company.audioWizardContract, // Keep audioWizardContract object in context. PUT /companies only returns the IRI
						},
					})
				} else {
					toast.error("Une erreur s'est produite")
				}
			} catch ({ response }) {
				const { violations } = response.data
				if (violations) {
					violations.forEach(({ propertyPath, message }) => {
						newErrors[propertyPath] = message
					})
					setErrors({
						...errors,
						...newErrors,
					})
				}
			}
		}
	}

	return (
		<>
			<Col>
				<SectionHeader title="Informations de l'entreprise">
					<ButtonRounded color="primary-outlined" onClick={() => handleSubmit()} type="submit">
						Enregistrer les modifications
					</ButtonRounded>
				</SectionHeader>
				<Row>
					<Col md="6">
						<FieldWithError
							name="label"
							label="Nom"
							placeholder="Nom de l'entreprise"
							dataCy="company-input-label"
							onChange={(e) => handleChange(e)}
							error={errors.label}
							value={company.label}
						/>
					</Col>
					<Col md="6">
						<FieldWithError
							name="siret"
							label="SIRET"
							placeholder="SIRET de l'entreprise"
							dataCy="company-input-siret"
							onChange={(e) => handleChange(e)}
							value={company.siret}
							error={errors.siret}
							maxLength="14"
						/>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<FieldWithError
							name="siren"
							label="SIREN"
							placeholder="SIREN de l'entreprise"
							dataCy="company-input-siren"
							onChange={(e) => handleChange(e)}
							value={company.siren}
							error={errors.siren}
						/>
					</Col>

					<Col md="6">
						<FieldWithError
							name="capitalStock"
							label="Capital social"
							placeholder="Capital social de l'entreprise"
							dataCy="company-input-capital-social"
							onChange={(e) => handleChange(e)}
							value={company.capitalStock}
							error={errors.capitalStock}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<FieldWithError
							name="tva"
							label="TVA"
							placeholder="TVA de l'entreprise"
							dataCy="company-input-tva"
							onChange={(e) => handleChange(e)}
							value={company.tva}
							error={errors.tva}
						/>
					</Col>

					<Col md="6">
						<FieldWithError
							name="rcs"
							label="RCS"
							placeholder="RCS Paris  654 987 321"
							dataCy="company-input-rcs"
							onChange={(e) => handleChange(e)}
							value={company.rcs}
							error={errors.rcs}
						/>
					</Col>
				</Row>
			</Col>
			<Col className="mt-4">
				<SectionHeader title="Coordonnées de l'entreprise" />
				<Row>
					<Col md="4">
						<FieldWithError
							name="adress"
							label="Adresse"
							placeholder="Adresse de l'entreprise"
							dataCy="company-input-adress"
							onChange={(e) => handleChange(e)}
							value={company.adress}
							error={errors.adress}
						/>
					</Col>
					<Col md="4">
						<FieldWithError
							name="city"
							label="Ville"
							placeholder="Ville de l'entreprise"
							dataCy="company-input-city"
							onChange={(e) => handleChange(e)}
							value={company.city}
							error={errors.city}
						/>
					</Col>
					<Col md="4">
						<FieldWithError
							name="cpo"
							label="Code postal"
							placeholder="Code postal de l'entreprise"
							dataCy="company-input-cpo"
							onChange={(e) => handleChange(e)}
							maxLength="5"
							value={company.cpo}
							error={errors.cpo}
						/>
					</Col>
				</Row>
			</Col>
			<Col>
				<Row>
					<Col md="12">
						<div className="input-group mt-2 my-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupFileAddon01">
									Uploader votre logo
								</span>
							</div>
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input"
									id="inputGroupFile01"
									name="logo"
									label="LOGO"
									onChange={(e) => handleChange(e)}
									//value={company.logo}
									aria-describedby="inputGroupFileAddon01"
									accept="image/*"
								/>
								<label className="custom-file-label logoInputLabel" htmlFor="inputGroupFile01">
									Choisissez le fichier (100px x 100px)
								</label>
							</div>
						</div>
					</Col>
				</Row>
			</Col>
			{logoUrl && (
				<div className="d-flex justify-content-center">
					<img src={logoUrl} alt="blob" width="250px" height="250px" />
				</div>
			)}
		</>
	)
}

export default withRouter(InformationEntreprise)
