/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import dayjs from "dayjs"
import moments from "moment"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { SCHEDULES_API } from "../../../config"
import AuthContext, { UiDisplay } from "../../../contexts/AuthContext"
import API from "../../../services/API"
import { formatDatetimeForDB } from "../../../services/functions"
import AgendaModal from "../Agenda/ModalAgendaContainer"

import { Agenda, Patient } from "@audiowizard/common"
import { Editor } from "@tinymce/tinymce-react"
import { DateInput } from "components/forms/DateInput"
import { useIsApt } from "components/Hooks/useIsApt"
import { confirmWithModal } from "../../../components/effects/ConfirmModalFunction"
import AsyncLaboratorySelect from "../../../components/utils/AsyncLaboratorySelect"
import AsyncUserSelect from "../../../components/utils/AsyncUserSelectMultiple"
import { zipper } from "../doctolib.helpers"
import ScheduleAgendaSelect from "./Schedule.AgendaSelect"
import SchedulePatientSearch from "./Schedule.PatientSelect"
import ScheduleStatusSelect from "./Schedule.StatusSelect"
import ScheduleTypeSelect from "./Schedule.TypeSelect"
import { cleanEventFromLocalStorage } from "../Agenda/helpers/BryntumAgendaLocalStorage"
import { EvtData } from "../Agenda/helpers/AgendaTypes"

const checkIsDisabled = (agenda: Agenda | undefined | null, editMode: string | undefined): boolean => {
	return (agenda && !agenda.doctolibType) || !editMode ? false : true
}

interface ScheduleUserSelectProps {
	user?: number
	setSelectedUser: (b: number) => void
	allowedLaboratories: number[]
	loading: boolean
	isDoctolib: boolean
	selectedLaboratory?: number
}

const ScheduleUserSelect: React.FC<ScheduleUserSelectProps> = ({
	user,
	setSelectedUser,
	allowedLaboratories,
	loading,
	isDoctolib,
	selectedLaboratory,
}) => {
	return (
		<div className="schedule-type-selector">
			<AsyncUserSelect
				data={user}
				selectedLaboratory={selectedLaboratory}
				mode="simple"
				className="w-100"
				size={"large"}
				placeholder="Sélectionner un utilisateur..."
				allowClear={false}
				disabled={loading}
				setData={setSelectedUser}
				allowedLaboratories={allowedLaboratories}
				isDoctolib={isDoctolib}
				noAssistant={undefined}
				onClear={undefined}
				onChange={undefined}
			/>
		</div>
	)
}

interface ScheduleLaboratorySelectProps {
	laboratory?: number
	selectedUser?: number
	setSelectedLaboratory: (l: number) => void
	loading: boolean
	isDoctolib: boolean
}

const ScheduleLaboratorySelect: React.FC<ScheduleLaboratorySelectProps> = ({
	laboratory,
	selectedUser,
	setSelectedLaboratory,
	loading,
	isDoctolib,
}) => {
	return (
		<div className="schedule-type-selector">
			<AsyncLaboratorySelect
				// @ts-ignore need to ts AsyncLaboratorySelect
				pagination={false}
				allowClear={false}
				size={"large"}
				disabled={loading}
				data={laboratory}
				value={laboratory}
				className="w-100"
				setData={setSelectedLaboratory}
				isDoctolib={isDoctolib}
				selectedUser={selectedUser}
				onChange={(_, laboratory) => setSelectedLaboratory(laboratory)}
			/>
		</div>
	)
}

interface NewScheduleSelectorProp {
	selectedPatient?: Patient | undefined
	setShowModal: Dispatch<SetStateAction<UiDisplay>>
	editMode?: string
	needRefresh?: () => void
	newScheduleSaved?: (d: any) => void
	saveOk?: (b: boolean) => void
	fromNewSchedule?: boolean
	isRelance?: boolean
	history?: any
	isEditing?: boolean
	fromEndOfSchedule?: boolean
	onTeletransAction?: () => void
}

interface ScheduleForService {
	id?: any
	dateEnd: string
	dateOf: string
	status?: string
	state: string
	type: string
	preScheduleNote?: string
	user?: string
	laboratory?: string
	agenda?: string
	patient?: string | null
}

export default function NewScheduleSelector({
	selectedPatient,
	setShowModal,
	editMode,
	needRefresh,
	saveOk,
	fromNewSchedule,
	isRelance,
	history,
	isEditing,
	fromEndOfSchedule = false,
	onTeletransAction,
}: NewScheduleSelectorProp): JSX.Element {
	const isApt = useIsApt()
	const ctx = useContext(AuthContext)

	const [evtData, setEvtData] = useState<EvtData>({
		patient: ctx.patient,
		startDate: new Date(),
		startTime: "",
		state: "WAITING",
		isRelance: isRelance,
	})

	const [showModalAgenda, setModalAgenda] = useState(false)
	const [dateFromAgenda, setDateFromAgenda] = useState<Date[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const [selectedLaboratory, setSelectedLaboratory] = useState<number | undefined>()
	const [selectedUser, setSelectedUser] = useState<number | undefined>()
	const [initialPatientId, setInitialPatientId] = useState<string | undefined>()
	const [selectedAgenda, setSelectedAgenda] = useState<Agenda | null | undefined>(null)

	const [defaultStartDate, setStartDate] = useState<Date | undefined>()

	const [saving, setSaving] = useState(false)
	const [teletransBtnColor, setTeletransBtnColor] = useState("purple")

	const loadExistingSchedule = async (id: string): Promise<void> => {
		try {
			setLoading(true)
			const data: any = {}

			const result = await axios.get(SCHEDULES_API + "/" + id)

			// find all the informations of the selected agenda, specially the doctolibType
			const agenda = ctx.agendas.find((agenda) => agenda["@id"] === result.data.agenda)
			setSelectedAgenda(agenda)

			const startDate = dayjs(result.data.dateOf).toString()
			const startTime = dayjs(result.data.dateOf).format("HH:mm")

			let endDate = dayjs(result.data.dateOf).toString()
			let endTime = dayjs(result.data.dateOf).format("HH:mm")

			if (result?.data?.dateEnd) {
				endDate = dayjs(result.data.dateEnd).toString()
				endTime = dayjs(result.data.dateEnd).format("HH:mm")
			}

			if (result?.data?.patient) {
				data.patient = {
					id: result.data.patient.id,
					lastName: result.data.patient.lastName,
					firstName: result.data.patient.firstName,
				}
				setInitialPatientId(result.data.patient.id)
			}
			setEvtData({
				id: result?.data?.id,
				startDate: startDate,
				endDate: endDate,
				startTime: startTime,
				endTime: endTime,
				note: result.data.notes,
				preScheduleNote: result.data.preScheduleNote,
				status: result.data.status,
				state: result.data.state,
				type: result.data.type,
				agenda: result.data.agenda,
				...data,
			})
			if (result?.data?.user?.["@id"]) {
				const splitted = result.data.user["@id"].split("/users/")
				setSelectedUser(+splitted?.[splitted?.length - 1])
			} else {
				setSelectedUser(result?.data?.user?.id)
			}
			setSelectedLaboratory(+result?.data?.laboratory?.id)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const saveSchedule = async (): Promise<void> => {
		if (!evtData.endDate || !evtData.endTime || !evtData.startDate || !evtData.startTime) {
			toast.error("Veuillez renseigner une date et un horaire")
			return
		}
		if (!evtData.status && !editMode) {
			toast.error("Veuillez renseigner le type de rendez-vous")
			return
		}
		if (!evtData.id) {
			toast.error("Problème lors de la création")
			return
		}

		const startDate = new Date(evtData.startDate)
		const endDate = new Date(evtData.endDate)

		const endTime = evtData?.endTime && evtData.endTime.split(":")
		const startTime = evtData.startTime.split(":")
		evtData.status = evtData?.status?.toUpperCase()

		const date_of = new Date(
			startDate.getFullYear(),
			startDate.getMonth(),
			startDate.getDate(),
			parseInt(startTime[0], 10),
			parseInt(startTime[1], 10)
		)
		const end_of = new Date(
			endDate.getFullYear(),
			endDate.getMonth(),
			endDate.getDate(),
			parseInt(endTime[0], 10),
			parseInt(endTime[1], 10)
		)

		const data: ScheduleForService = {
			dateEnd: formatDatetimeForDB(end_of),
			dateOf: formatDatetimeForDB(date_of),
			status: evtData.status,
			state: evtData.state ?? "WAITING",
			type: evtData.type ?? "CLASSIQUE",
			preScheduleNote: evtData.preScheduleNote ?? undefined,
		}

		if (selectedUser) data.user = "/users/" + selectedUser
		if (selectedLaboratory) data.laboratory = "/laboratories/" + selectedLaboratory
		if (selectedAgenda) data.agenda = selectedAgenda["@id"]

		if (evtData.patient?.id) data.patient = "/patients/" + evtData.patient.id
		else data.patient = null

		const edit = async (): Promise<void> => {
			try {
				setSaving(true)
				if (data?.id) delete data.id

				if (selectedAgenda?.doctolibType != null) {
					await axios.put(SCHEDULES_API + "/" + evtData.id + "/doctolib_update", {
						state: data.state,
						preScheduleNote: data.preScheduleNote,
					})
				} else if (evtData.patient?.id !== initialPatientId) {
					await axios.delete(SCHEDULES_API + "/" + evtData.id)
					await axios.post(SCHEDULES_API, data)
				} else {
					await axios.put(SCHEDULES_API + "/" + evtData.id, data)
				}

				toast.success("Le rendez-vous a été modifié avec succès")
				if (typeof needRefresh === "function") needRefresh()
				if (typeof saveOk === "function") saveOk(false)
				setSaving(false)
				setShowModal((old) => ({ ...old, scheduleModal: false, selectedPatient: undefined }))
				// force reload event on opening agenda
				if (selectedAgenda) {
					cleanEventFromLocalStorage([selectedAgenda])
				}
			} catch (e) {
				toast.error("Une erreur est survenue durant la modification de l'évènement")
				console.error("=>", e)
				setSaving(false)
			}
		}
		const redirectToPatient = (): void => {
			if (ctx?.patient?.id && window?.location?.href?.includes(`/fiche-patient/${ctx.patient.id}`))
				history.push("/")
			else if (ctx?.patient?.id) history.push(`/fiche-patient/${ctx.patient.id}`)
			else history.push("/")
		}
		edit()
		if (isRelance) redirectToPatient()
	}

	function setDates(data: Date[]): void {
		if (data && data.length === 1) {
			setEvtData((old: EvtData) => ({
				...old,
				startDate: data[0],
				startTime: moments(data[0]).format("HH:mm"),
				endDate: data[0],
				endTime: moments(data[0]).add(15, "minute").format("HH:mm"),
			}))
		}
		if (data && data.length === 2) {
			setEvtData((old: EvtData) => ({
				...old,
				startDate: data[0],
				startTime: moments(data[0]).format("HH:mm"),
				endDate: data[1],
				endTime: moments(data[1]).format("HH:mm"),
			}))
		}
	}

	useEffect(() => {
		;(async function () {
			setLoading(true)
			if (editMode) {
				loadExistingSchedule(editMode)
			} else {
				setSelectedLaboratory(
					(ctx.patient.laboratory?.["@id"] && parseInt(ctx.patient.laboratory?.["@id"].split("/")[2], 10)) ||
						ctx.laboratory.id
				)
				setSelectedUser(ctx.user.id)
			}
			setLoading(false)
		})()
	}, [])

	useEffect(() => {
		;(async function () {
			// TODO: refac this function
			if (!editMode && !fromNewSchedule && selectedPatient?.id)
				setEvtData((old: EvtData) => ({ ...old, patient: selectedPatient }))
			if (!editMode && fromNewSchedule && ctx?.patient?.["@id"])
				setEvtData((old: EvtData) => ({ ...old, patient: ctx.patient }))
		})()
	}, [ctx?.patient, selectedPatient])

	useEffect(() => {
		setEvtData((old: any) => ({ ...old, user: selectedUser }))
		setEvtData((old: any) => ({ ...old, laboratory: selectedLaboratory }))
		setEvtData((old: any) => ({ ...old, agenda: selectedAgenda }))
	}, [selectedLaboratory, selectedUser, selectedAgenda])

	useEffect(() => {
		if (!selectedUser || !selectedLaboratory) return
		const agenda = ctx.agendas.find(
			(a) =>
				a.user?.["@id"].split("/")[2] === selectedUser.toString() &&
				a.laboratory?.["@id"].split("/")[2] === selectedLaboratory.toString()
		)
		// en création (editMode est vide) on propose de rediriger vers doctolib
		if (agenda && (!agenda.doctolibType || !editMode)) {
			setSelectedAgenda(agenda)
		}
	}, [selectedUser, selectedLaboratory])

	useEffect(() => {
		let _startDate = evtData?.startDate ?? new Date()

		if (evtData?.status === "APPAREILLE" && evtData?.type?.includes("OBLIGATOIRE_")) {
			const time = evtData.type.split("_")[1]
			const startDate = dayjs().add(parseInt(time, 10), "month")
			_startDate = startDate.toDate()
		}

		setStartDate(_startDate)
	}, [evtData])

	useEffect(() => {
		setDates(dateFromAgenda)
	}, [dateFromAgenda])

	const printStartDateAndTime = (): JSX.Element => {
		try {
			const mom = dayjs(evtData.startDate).format("ddd DD MMM YYYY")
			const mom_end = dayjs(evtData.endDate).format("ddd DD MMM YYYY")
			const dat = mom.split(" ")
			const dat_end = mom_end.split(" ")
			const s_time = evtData.startTime.split(":")
			const s_time_end = evtData.endTime?.split(":")
			const res = (
				<>
					Du <span className="dayDate">{dat[1]}</span> <span className="monthName">{dat[2]}</span> {dat[3]} à{" "}
					<span className="hour">{s_time[0]}</span>h<span className="minutes">{s_time[1]}</span> au{" "}
					<span className="dayDate">{dat_end[1]}</span> <span className="monthName">{dat_end[2]}</span>{" "}
					{dat_end[3]} à <span className="hour">{s_time_end?.[0]}</span>h
					<span className="minutes">{s_time_end?.[1]}</span>
				</>
			)
			return res
		} catch (e) {
			return <></>
		}
	}
	return (
		<>
			<div className="modal-agenda">
				<div className="container">
					<div className="title">
						{evtData.patient && selectedAgenda?.doctolibType != null
							? "Edition du status d'un RDV doctolib"
							: `${isEditing ? "Edition" : "Planification"} ${
									isRelance ? "d'une relance" : "d'un rendez-vous"
							  } `}
						{evtData.patient?.firstName && evtData.patient?.lastName
							? `pour ${evtData.patient?.firstName} ${evtData.patient?.lastName}`
							: ""}
					</div>
					<div className="content newSchedule">
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Patient</div>
							<div className="col">
								<SchedulePatientSearch
									disabled={
										loading || (!editMode && fromNewSchedule && evtData?.patient?.id ? true : false)
									}
									data={evtData}
									setData={setEvtData}
									selectedLaboratory={selectedLaboratory}
									selectedUser={selectedUser}
									isDoctolib={checkIsDisabled(selectedAgenda, editMode)}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">{isApt ? "État" : "Type *"}</div>
							<div className="col">
								<ScheduleTypeSelect
									data={evtData}
									setData={setEvtData}
									loading={loading}
									isDoctolib={checkIsDisabled(selectedAgenda, editMode)}
									placeholder={""}
									allowClear={false}
									generic={undefined}
									isRelance={isRelance}
									onScheduleTypeExport={(scheduleType) => setTeletransBtnColor(scheduleType?.color)}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">
								{ctx.user?.company?.id === 46 ? selectedAgenda && "Status principal" : "Status"}
							</div>
							<div className="col">
								<ScheduleStatusSelect data={evtData} setData={setEvtData} loading={loading} />
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Agenda</div>
							<div className="col">
								<ScheduleAgendaSelect
									data={selectedAgenda}
									setData={setSelectedAgenda}
									placeholder="Choisir un agenda"
									isDoctolib={selectedAgenda && selectedAgenda.doctolibType ? true : false}
								/>
							</div>
						</div>

						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Laboratoire</div>
							<div className="col">
								<ScheduleLaboratorySelect
									laboratory={selectedLaboratory}
									selectedUser={selectedUser}
									setSelectedLaboratory={setSelectedLaboratory}
									loading={loading}
									isDoctolib={checkIsDisabled(selectedAgenda, editMode)}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Utilisateur</div>
							<div className="col">
								<ScheduleUserSelect
									user={selectedUser}
									selectedLaboratory={selectedLaboratory}
									setSelectedUser={setSelectedUser}
									allowedLaboratories={selectedLaboratory ? [selectedLaboratory] : []}
									loading={loading}
									isDoctolib={checkIsDisabled(selectedAgenda, editMode)}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Date *</div>
							<DateInput
								id="relance-date"
								label=""
								style={{
									marginLeft: "10px",
									marginTop: "-30px",
									marginBottom: "0px !important",
									height: "40px",
								}}
								value={dayjs(defaultStartDate)}
								onChange={(date) => {
									if (date != null) {
										// si on change la date, la date de fin doit changé également, on considère que les rdv sont toujours sur journée
										// @ts-ignore
										setEvtData((old) => ({ ...old, startDate: date, endDate: date }))
									}
								}}
							/>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title"> Heure * </div>
							<div className="col">
								{selectedAgenda?.doctolibType ? (
									<button
										onClick={() => {
											if (!evtData.patient?.id) {
												toast.warn("Aucun patient sélectionné")
												return
											}
											if (defaultStartDate) {
												zipper.book({
													patient: evtData.patient,
													date: formatDatetimeForDB(defaultStartDate),
													// @ts-ignore
													type: evtData["@id"],
												})
											} else {
												zipper.book({
													patient: evtData.patient,
													date: formatDatetimeForDB(),
													// @ts-ignore
													type: evtData["@id"],
												})
											}
										}}
										type="button"
										disabled={loading}
										className="btn btn-outline-primary btn-block btn-sm">
										{evtData.startDate && evtData.endDate ? printStartDateAndTime() : "Doctolib"}
									</button>
								) : (
									<button
										onClick={() => {
											if (!evtData.status) {
												toast.error("Veuillez-sélectionner un type de rendez-vous")
												return
											}
											if (evtData.startDate && evtData.endDate) {
												setStartDate(evtData.startDate)
												setModalAgenda(true)
											} else {
												setModalAgenda(true)
											}
										}}
										type="button"
										disabled={loading}
										className="btn btn-outline-primary btn-block btn-sm">
										{evtData.startDate && evtData.endDate
											? printStartDateAndTime()
											: "Sélectionner une plage horaire sur l'agenda"}
									</button>
								)}
							</div>
						</div>

						<div className="evt-notes-container">
							<h5>Notes</h5>

							{!loading && (
								<Editor
									apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
									//disabled={loading}
									initialValue={""}
									init={{
										height: 200,
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ",
										language: "fr_FR",
										browser_spellcheck: true,
										contextmenu: false,
										content_style: "p { margin: 0.5em 0; } ", // reduce margin between p
									}}
									value={evtData.preScheduleNote || ""}
									onEditorChange={(text) => {
										setEvtData((old) => ({ ...old, preScheduleNote: text }))
									}}
								/>
							)}
						</div>

						<div className="evt-footer">
							{evtData.state !== "DONE" && evtData.id && selectedAgenda?.doctolibType == null && (
								<button
									className="btn btn-sm btn-danger"
									type="button"
									style={{ flexShrink: 2 }}
									onClick={async () => {
										try {
											const hasConfirmed = await confirmWithModal({
												text: (
													<>
														Voulez-vous vraiment supprimer ce rendez-vous ? Toute trace sera
														effacée,
														<strong> cette action est définitive et irréversible</strong>.
													</>
												),
											})
											if (!hasConfirmed) return

											setSaving(true)
											API.delete(`/schedules/${evtData.id}`)
											if (typeof needRefresh === "function") needRefresh()
											if (typeof setShowModal === "function") {
												setShowModal((old) => ({
													...old,
													scheduleModal: false,
													selectedPatient: undefined,
												}))
												window.location.reload()
											}
											if (selectedAgenda) cleanEventFromLocalStorage([selectedAgenda])
										} catch (error) {
											console.error(error)
										} finally {
											setSaving(false)
										}
									}}>
									Supprimer
								</button>
							)}

							<button
								type="button"
								disabled={loading || saving}
								className="btn btn-sm btn-warning"
								onClick={() => {
									setShowModal((old) => ({
										...old,
										scheduleModal: false,
										selectedPatient: undefined,
									}))
								}}>
								{evtData.id ? "Annuler les modifications" : "Annuler et fermer"}
							</button>
							<button
								type="button"
								className="btn btn-sm btn-primary"
								style={{ filter: saving ? "grayscale(100%)" : "none" }}
								onClick={() => {
									if (!saving) {
										saveSchedule()
									}
								}}
								disabled={
									loading || saving || (selectedAgenda && selectedAgenda.doctolibType ? true : false)
								}>
								{saving
									? "Enregistrement..."
									: `${
											evtData.id
												? "Enregistrer les modifications"
												: `Créer ${isRelance ? "la relance" : "le rendez-vous"}`
									  }`}
							</button>
							{isEditing && evtData.status === "APPAREILLE" && (
								<button
									className="btn btn-sm"
									style={{ background: teletransBtnColor, color: "white" }}
									onClick={onTeletransAction}>
									Télétransmission contrôle
								</button>
							)}
						</div>
					</div>
				</div>
			</div>

			{showModalAgenda && (
				<AgendaModal
					setSelectedDate={setDateFromAgenda}
					setShow={() => {
						setModalAgenda(false)
						setShowModal((old) => ({
							...old,
							scheduleModal: false,
							agendaModal: false,
							selectedPatient: undefined,
						}))

						if (fromEndOfSchedule && history) history.push(`/fiche-patient/${ctx.patient.id}`)
					}}
					setSelectedLaboratory={setSelectedLaboratory}
					setSelectedUser={setSelectedUser}
					defaultStartDate={defaultStartDate}
					appointmentFromScheduleSelector={evtData}
					setSelectedSchedule={setEvtData}
				/>
			)}
		</>
	)
}
