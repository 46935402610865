import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import ListePersonnalisations from "pages/mon-compte/mes-personnalisations/ListePersonnalisations"
import UserSettings from "pages/Settings/UserSettings"
import { noPrefix, PersonnalisationRoutePrefix, PersonnalisationRoutes } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

function PersonnalisationRouter(): JSX.Element {
	useCustomTitle("Paramètres | Personnalisation")
	return (
		<CardWithTabs urlprefix={PersonnalisationRoutePrefix}>
			<CardItem
				name="Options de l'application"
				component={UserSettings}
				url={noPrefix(PersonnalisationRoutes.Options)}
			/>
			<CardItem
				name="Personnalisation des templates"
				component={ListePersonnalisations}
				url={noPrefix(PersonnalisationRoutes.Templates)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default PersonnalisationRouter
