import { Document, Laboratory, User } from "@audiowizard/common"
import dayjs from "dayjs"
import { Dispatch, SetStateAction, useState } from "react"
import { CSVLink } from "react-csv"
import API_PDF from "services/API_PDF"
import API from "../../../services/API"
import { CashRecord, FullAccounting, typeAccounting } from "./moneyItems"
import { toast } from "react-toastify"
import { rounded } from "services/functions"
import { detailsEsp } from "./caisse.helpers"

const getData = (data: CashRecord): { label: string; value?: number; multiplier: number; total: number }[] => {
	const result: { label: string; value?: number; multiplier: number; total: number }[] = []

	Object.entries(data).forEach(([cashType, value]) => {
		if (cashType === "total") return
		if (!value) return
		const amount = (detailsEsp as any)?.[cashType] as string
		result.push({
			label: amount + " €",
			value: parseFloat(amount),
			multiplier: value,
			total: rounded(+amount * +value),
		})
	})
	result.sort((a, b) => (a.value || 0) - (b.value || 0))
	result.push({
		label: "Total",
		multiplier: result.reduce((acc, item) => +acc + +item.multiplier, 0),
		total: result.reduce((acc, item) => +acc + +item.total, 0),
	})
	return result
}

type DownloadExportProps = {
	previousData: FullAccounting
	data: FullAccounting
	user: User
	laboratory: Laboratory
	setDocument: Dispatch<SetStateAction<Document | undefined>>
	setModalViewerOpen: Dispatch<SetStateAction<boolean>>
	setModalUpload: Dispatch<SetStateAction<boolean>>
	setCurrentRecord: Dispatch<SetStateAction<FullAccounting | undefined>>
}

export const DownloadExport = ({
	previousData,
	data,
	user,
	laboratory,
	setDocument,
	setModalViewerOpen,
	setModalUpload,
	setCurrentRecord,
}: DownloadExportProps): JSX.Element => {
	const [disabled, setDisabled] = useState<boolean>(false)
	const csvData = data?.data?.cashfund ? getData(data?.data?.cashfund as CashRecord) : []

	const csvHeaders = [
		{ label: "Valeur", key: "label" },
		{ label: "Quantité", key: "multiplier" },
		{ label: "Total", key: "total" },
	]

	const downloadPdf = async ({ target }: { target: EventTarget }): Promise<void> => {
		setDisabled(true)

		const tmp = csvData.filter((f) => f.label !== "Total")
		const total = csvData.find((f) => f.label === "Total")

		const pdfData = {
			logo: laboratory?.subCompany?.logo,
			company: user.company,
			laboratory,
			dateStart: dayjs(previousData?.createdAt || data.createdAt).format("DD-MM-YY HH-mm"),
			dateEnd: dayjs(previousData?.createdAt || data.createdAt).format("DD-MM-YY HH-mm"),
			cashRegisterState: [...tmp],
			totalCashRegister: total?.total,
			comment: data.comment || "",
		}

		await API_PDF.downloadFromCarbonne(pdfData, "RESUME_FOND_DE_CAISSE")

		setDisabled(false)
	}

	return (
		<>
			<div className="btn-group w-100">
				{
					// @ts-ignore
					data.type !== typeAccounting.sortieEspece && (
						<>
							<CSVLink
								headers={csvHeaders}
								data={csvData}
								filename={`État De La Caisse Du ${dayjs(
									previousData?.createdAt || data.createdAt
								).format("DD-MM-YY HH-mm")} au ${dayjs(data.createdAt).format("DD-MM-YY HH-mm")} .csv`}
								separator={";"}
								className="btn btn-secondary btn-sm"
								// @ts-ignore le typage de la librairie ne correspond pas à l’objet obtenu
								onClick={(ev: MouseEvent) => {
									ev.preventDefault()
									const target = ev.target as unknown as Element

									if (target.getAttribute("disabled") === "disabled") return

									window.open(target.getAttribute("href") || "", "_self")
									target.setAttribute("disabled", "disabled")

									setTimeout(() => {
										target.removeAttribute("disabled")
									}, 1000)
								}}
								target="_blank">
								Télécharger l'état de caisse du{" "}
								{`${dayjs(previousData?.createdAt || data.createdAt).format(
									"DD-MM-YY HH-mm"
								)} au ${dayjs(data.createdAt).format("DD-MM-YY HH-mm")}`}{" "}
								en CSV
							</CSVLink>
							<button
								type="button"
								className="btn btn-secondary btn-sm"
								onClick={downloadPdf}
								disabled={disabled}>
								en PDF
							</button>
						</>
					)
				}
				{
					// should be corrected by updating audiowizard/common
					// @ts-ignore
					data.type === typeAccounting.sortieEspece && (
						<button
							type="button"
							className="btn btn-secondary btn-sm"
							onClick={async () => {
								if (data.receipt) {
									try {
										const result = await API.get<Document>(data.receipt)
										setDocument(result)
										setModalViewerOpen(true)
									} catch (err) {
										toast.error("Document non trouvé, veuillez contacter le support")
									}
								} else {
									setCurrentRecord(data)
									setModalUpload(true)
								}
							}}>
							{data.receipt ? "Justificatif" : "Ajouter un justificatif"}
						</button>
					)
				}
			</div>
		</>
	)
}
