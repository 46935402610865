import { useQuery } from "react-query"
import {
	userInsuranceCredentialsService,
	userInsuranceCredentialsServiceKey,
} from "../userInsuranceCredentials.service"

export default function useUserInsuranceCredentials() {
	return useQuery({
		queryFn: userInsuranceCredentialsService.list,
		queryKey: userInsuranceCredentialsServiceKey,
	})
}
