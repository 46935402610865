import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const DocumentsRoutePrefix = GestionCompteRoutePrefix + "/documents"

export const DocumentsRoutes = {
	listeDocuments: `${DocumentsRoutePrefix}/liste-documents`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(DocumentsRoutePrefix, "")
}
