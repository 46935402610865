import { getApiUrl } from "backend-routes"
import {
	userInsuranceNetworkApiBase,
} from "./services/requests/http/userInsuranceNetworks/userInsuranceNetwork.service"
import {
	userInsuranceCredentialsServiceBase,
} from "./services/requests/http/userInsuranceCredentials/userInsuranceCredentials.service"
import {
	userInsuranceCoverageDemandBase,
} from "./services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService"

export const API_URL = getApiUrl()

export const LOGIN_API = API_URL + "/login_check"
export const COMPANIES_API = API_URL + "/companies"
export const COMPANY_SETTINGS_API = API_URL + "/company_settings"
export const USER_SETTINGS_API = API_URL + "/user_settings"
export const COMPANY_DASHBOARD_COLUMN_API = API_URL + "/patients_dashboard_column_settings"
export const LABORATORIES_API = API_URL + "/laboratories"
export const LABORATORIES_SLOTS_API = API_URL + "/laboratories/available_slots"
export const SUB_COMPANIES_API = API_URL + "/sub_companies"
export const LABORATORY_ATTENDANCES_API = API_URL + "/laboratory_attendances"
export const PRESCRIBERS_API = API_URL + "/prescribers"
export const EQUIPMENTS_HISTORY = API_URL + "/equipment_histories"
export const PRESCRIBER_ADVICES_API = API_URL + "/prescriber_advices"
export const PATIENT_NOTES_API = API_URL + "/patient_notes"
export const PATIENT_EQUIPMENTS_API = API_URL + "/patient_equipments"
export const PATIENT_GENES_API = API_URL + "/patient_genes"
export const PATIENT_MEDICALS_API = API_URL + "/patient_medicals"
export const PATIENT_ORIGINS_API = API_URL + "/patient_origins"
export const PATIENT_LOOKING_FORS_API = API_URL + "/patient_looking_fors"
export const PATIENT_ACOUPHENES_API = API_URL + "/patient_acouphenes"
export const PATIENT_SECU_API = API_URL + "/patient_social_securities"
export const PATIENT_INSURANCE_API = API_URL + "/patient_insurances"
export const PATIENT_TAGS_API = API_URL + "/patient_tags"
export const ORIGINS_API = API_URL + "/origins"
export const PATIENTS_ORIGIN_DETAILS_API = API_URL + "/patient_origin_details"
export const PATIENT_SOURCES_API = API_URL + "/patient_sources"
export const PATIENTS_API = API_URL + "/patients"
export const PATIENT_DOCTOLIB_API = API_URL + "/doctolib_patients"
export const BASIC_MERGE_PATIENT_API = API_URL + "/patients/basic_merge"
export const PATIENTS_EXPORT_API = API_URL + "/patients/export_data"
export const PATIENTS_FULL_API = API_URL + "/patients/dashboard"
export const SCHEDULE_TYPE_GROUPS_API = API_URL + "/schedule_type_groups"
export const SCHEDULE_TYPES = API_URL + "/schedule_types"
export const PRODUCTS_API = API_URL + "/products"
export const PRODUCT_SEARCH_API = API_URL + "/store/products/search"
export const STOCK_AVAILABLE_API = API_URL + "/stock_available"
export const PRODUCT_WITH_STOCK_API = API_URL + "/products_with_stock_available"
export const WAREHOUSES_API = API_URL + "/warehouses"
export const SCHEDULES_API = API_URL + "/schedules"
export const EVENT_OTOSCOPIES_API = API_URL + "/event_otoscopies"
export const EVENT_PROSTHETICS_API = API_URL + "/event_prosthetics"
export const EVENT_WORKSHOPS_API = API_URL + "/event_workshops"
export const PRODUCT_RANGES_API = API_URL + "/product_ranges"
export const USERS_API = API_URL + "/users"
export const AGENDAS_API = API_URL + "/agendas"
export const REFERRALS_API = API_URL + "/referrals"
export const REMINDERS_API = API_URL + "/reminders"
export const MAIL_COMMERCIAL_API = API_URL + "/email-commercial"
export const MAIL_COMPTE_RENDU_ORL = API_URL + "/email-orl"
export const PASSWORD_RESET_API = API_URL + "/forgotten-password"
export const TODOS_API = API_URL + "/todos"
export const EVENT_EXPLANATIONS_API = API_URL + "/event_explanations"
export const PERSONALIZATIONS_API = API_URL + "/personalizations"
export const SALES_API = API_URL + "/sales"
export const INSURANCES_API = API_URL + "/insurances"
export const USER_INSURANCES_API = API_URL + "/user_insurances"
export const HEALTH_INSURANCES_API = API_URL + "/health_insurances"
export const LOGISTIQUE_API = API_URL + "/logistics"
export const LOGISTIC_PRODUCTS_API = API_URL + "/logistic_products"
export const SUPPLIER_INVOICES_API = API_URL + "/supplier_invoices"
export const SUPPLIER_RETROCESSION_INVOICES_API = API_URL + "/supplier_retrocession_invoices"
export const SUPPLIER_INVOICE_PRODUCTS_API = API_URL + "/supplier_invoice_products"
export const PRODUCT_RETURN_FORM_API = API_URL + "/product_return_forms"
export const INVITATIONS_API = API_URL + "/patient_invitations"
export const INVOICE_API = API_URL + "/hiboutik/invoice"
export const CREDITS_API = API_URL + "/credits"
export const SUBCONTRACTORS_API = API_URL + "/subcontractors"
export const SAV_API = API_URL + "/after_sales"
export const REMOTE_FILE_API = API_URL + "/remote-file"
export const REMOTE_FILE_CHECK_API = API_URL + "/remote-file-check"
export const ATTENDANT_API = API_URL + "/attendants"
export const PATIENT_ATTENDANT_RELATION_API = API_URL + "/patient_attendant_relations"
export const HISTORY_API = API_URL + "/histories"
export const PAYMENTS_API = API_URL + "/payments"
export const PAYMENT_TYPES_API = API_URL + "/payment_types"
export const FSV_WEBSERVICES_API = API_URL + "/fsv_webservice"
export const GED_WEBSERVICES_API = API_URL + "/ged_webservice"
export const FSV_GET_TOKEN_API = API_URL + "/get_token"
export const ACCOUNTING_API = API_URL + "/accountings"
export const EQUIPMENT_HISTORIES_API = API_URL + "/equipment_histories"
export const QUOTE_PACKS_API = API_URL + "/quote_packs"
export const QUOTE_PACKS_ANNEXES_API = API_URL + "/quote_pack_annexes"
export const QUOTE_PACK_PRODUCTS_API = API_URL + "/quote_pack_products"
export const AUDIO_WIZARD_DOCUMENTS_API = API_URL + "/audio_wizard_documents"
export const AUDIO_WIZARD_CONTRACTS_API = API_URL + "/audio_wizard_contracts"
export const AUDIO_WIZARD_SERVICES_API = API_URL + "/audio_wizard_services"
export const AUDIO_WIZARD_SUBSCRIPTIONS_API = API_URL + "/audio_wizard_subscriptions"
export const AUDIO_WIZARD_PRESTATIONS_API = API_URL + "/audio_wizard_prestations"
export const AUDIO_WIZARD_INVOICES_API = API_URL + "/audio_wizard_invoices"
export const CONFLUENCE_DOC_API = API_URL + "/confluence_pages"
export const CLIENT_VERSION_API = API_URL + "/get_client_version"
export const WHITE_LABELS_API = API_URL + "/white_labels"
export const SMS_REMINDER_SETTINGS_API = API_URL + "/sms_reminder_settings"
export const TEMPLATE = API_URL + "/templates"
export const COMMUNICATION_MESSAGES_API = API_URL + "/communication_messages"
export const PURCHASE_ORDERS_API = API_URL + "/purchase_orders"
export const PURCHASE_ORDER_PRODUCTS_API = API_URL + "/purchase_order_products"
export const STORE_API = API_URL + "/store/"
export const INVENTORY_API = API_URL + "/inventories"
export const INVENTORY_PRODUCTS_API = API_URL + "/inventory_products"
export const EXPORT_PERSONALIZATION = API_URL + "/export_personalizations"
export const EXPORT_PRODUCT_SETTING = API_URL + "/export_product_settings"
export const EXPORT_TAX_SETTING = API_URL + "/export_tax_settings"
export const EXPORT_PAYMENT_SETTING = API_URL + "/export_payment_settings"
export const EXPORT_ACCOUNTING_SETTING = API_URL + "/export_accounting_settings"
export const EXPORT_MOVEMENT_CASH_SETTING = API_URL + "/export_movement_cash_settings"
export const EXPORT_SETTING = API_URL + "/export_settings"
export const API_EXPORT_FEC_JDV = API_URL + "/mon-compte/export-comptable/fec/jdv"
export const API_EXPORT_FEC_ENCAISSEMENT = API_URL + "/mon-compte/export-comptable/fec/payments"
export const API_EXPORT_MOUVEMENT_CAISSE = API_URL + "/mon-compte/export-comptable/fec/mouvement-especes"
export const CANVAS_API = API_URL + "/canvases"
export const CANVAS_PERSONNALIZATIONS_API = API_URL + "/canvas_personnalizations"
export const CASH_OUTFLOW_REASON_API = API_URL + "/cash_outflow_reasons"
export const AFFILATION_DASHBOARD_API = API_URL + "/affiliations/dashboard"
export const AFFILIATIONS_API = API_URL + "/affiliations"
export const USER_INSURANCE_CREDENTIALS = API_URL + userInsuranceCredentialsServiceBase
export const USER_INSURANCE_COVERAGE_DEMAND = API_URL + userInsuranceCoverageDemandBase
export const USER_INSURANCE_COVERAGE_DEMAND_ACTION = `${API_URL}${userInsuranceCoverageDemandBase}/{id}/action`
export const USER_INSURANCE_COVERAGE_DEMAND_STATE = `${userInsuranceCoverageDemandBase}/{id}/state`
export const USER_INSURANCE_NETWORKS = API_URL + userInsuranceNetworkApiBase
export const USER_INSURANCE_COMPANIES = API_URL + "/user_insurance_companies"

export const TRANSFER_API = API_URL + "/transfers"
export const TRANSFER_PRODUCT_API = API_URL + "/transfer_products"
export const TRANSFER_RETROCESSION_INVOICES_API = API_URL + "/transfer_retrocession_invoices"

export const API_SANTE_URL =
	process.env.REACT_APP_SANTE_LOCAL === "true" ? "http://localhost:8001" : "https://production.api-annuaire-sante.fr"

export const PROFESSIONNEL_DE_SANTES = "/professionnel_de_santes"
export const ASSURANCES = "/assurances"
export const DOCUMENTS_API = API_URL + "/documents"
export const DOCUMENT_REMOTE_SIGNATURES_API = API_URL + "/document_remote_signatures"

export const REFRESH_TOKEN = API_URL + "/api/token/refresh"

export const AUDIOWIZARD_SCAN_SERVER_INSTALLER_DOWNLOAD =
	API_URL + "/softwares/audiowizard-scan-server/audiowizard-scan-server-win64-installer.exe"

export const SLACK_HOOK = "https://hooks.slack.com/services/TKDAZ80PJ/"
export const SLACK_HOOK_NOTIFICATIONS = SLACK_HOOK + "B011SBYG6R3/71Wv6L8P0xhZbdLx2duRwFnb"
export const SLACK_HOOK_PARRAINAGE = SLACK_HOOK + "B011T3TQNCA/GEmyu58mkF5maqCHLunrYvip"
export const SLACK_HOOK_LEADS = SLACK_HOOK + "B011N0931B7/1f8vkzFnq5oLh1gCT7kqrESd"
export const SLACK_TEST_HOOKS = SLACK_HOOK + "B012K62L7SL/oYE2TcDPuCTdTzU14hzRlEUe"

let SOCKET_RNS_IP = "rns.audiowizard.fr",
	SOCKET_IP = "supl.audiowizard.fr",
	SOCKET_REALM = "local",
	SOCKET_RNS_REALM = "local"

if (process.env.REACT_APP_DEV_LOCAL) {
	SOCKET_REALM = "local"
	SOCKET_IP = ":5000"
	SOCKET_RNS_REALM = "local"
	SOCKET_RNS_IP = "https://rns.audiowizard.fr" //":8098"
} else {
	if (document.location.href.includes("app.audiopourtous.fr")) {
		SOCKET_REALM = "prod"
		SOCKET_RNS_REALM = "prod"
	} else {
		SOCKET_REALM = document.location.hostname
		SOCKET_RNS_REALM = document.location.hostname
	}
	SOCKET_IP = "supl.audiowizard.fr"
	SOCKET_RNS_IP = "https://rns.audiowizard.fr"
}
export { SOCKET_REALM, SOCKET_IP, SOCKET_RNS_IP, SOCKET_RNS_REALM }
