export const ComptabiliteRoutePrefix = "/comptabilite"

export const ComptabiliteRoutes = {
	Exports: `${ComptabiliteRoutePrefix}/exports`,
	Paiements: `${ComptabiliteRoutePrefix}/paiements`,
	Sortie: `${ComptabiliteRoutePrefix}/sortie`,
	FacturesFournisseurs: `${ComptabiliteRoutePrefix}/factures-fournisseur`,
	FacturesFournisseursCreation: `${ComptabiliteRoutePrefix}/factures-fournisseur/creation`,
	FacturesFournisseursEdition: `${ComptabiliteRoutePrefix}/factures-fournisseur/edition/:id`,
	FacturesRetrocessions: `${ComptabiliteRoutePrefix}/retrocession-factures-fournisseur`,
	FacturesRetrocessionsCréation: `${ComptabiliteRoutePrefix}/retrocession-factures-fournisseur/creation`,
	FacturesRetrocessionsEdition: `${ComptabiliteRoutePrefix}/retrocession-factures-fournisseur/edition/:id`,
	Avoirs: `${ComptabiliteRoutePrefix}/avoirs`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(ComptabiliteRoutePrefix, "")
}
