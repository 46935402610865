/* eslint-disable react-hooks/exhaustive-deps */
import { DateInput } from "components/forms/DateInput"
import dayjs from "dayjs"
import { useContext, useEffect, useRef, useState } from "react"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import { formatDateForDB } from "../../../services/functions"

const BonLivraisonConfiguration = ({ data, setData, suppliers, brands, productList, busy, isTransfer }) => {
	const { user } = useContext(AuthContext)
	const [laboratories, setLaboratories] = useState([])
	const [tmpData, setTmpData] = useState({})

	const timeOut = useRef(null)

	useEffect(() => {
		setTmpData(data)
	}, [data])

	const handleChange = async (key, value, event = null) => {
		try {
			setTmpData((old) => ({ ...old, [key]: value }))
			clearTimeout(timeOut.current)
			timeOut.current = null

			/**
			 * le clear du composant (antd) emet un event "change"
			 * il faut donc faire une exception pour dateDepotEnd,
			 */
			const skipChangeEvents = ["dateEdition", "dateReceipt"]
			if (value) skipChangeEvents.push("depotEndDate")

			if (skipChangeEvents.includes(key) && event === "CHANGE") {
				return
			}

			const post = {}
			switch (key) {
				case "supplier":
					post.supplier = value
					break
				case "brand":
					post.brand = value
					break
				case "label":
					post.numero = value
					break
				case "dateEdition":
					post.dateEdition = formatDateForDB(value)
					break
				case "dateReceipt":
					post.dateReceipt = formatDateForDB(value)
					break
				case "laboratory":
					const laboratory = laboratories.find((f) => f?.["@id"] === value)
					post.stockIdHiboutik = laboratory?.warehouseIdHiboutik || -1
					break
				case "depotEndDate":
					post.depotEndDate = value ? formatDateForDB(value) : null
					break
				default:
					post[key] = value
					break
			}
			try {
				if (data.logistic_id !== -1) {
					await API.update("LOGISTIQUE_API", data.logistic_id, post)
				}

				switch (key) {
					case "laboratory":
						setData((old) => ({ ...old, [key]: value, warehouse_id: post.stockIdHiboutik }))
						break
					case "supplier":
						setData((old) => ({ ...old, [key]: value, supplier: value }))
						break
					case "brand":
						setData((old) => ({ ...old, [key]: value, brand: value }))
						break
					default:
						setData((old) => ({ ...old, [key]: value }))
						break
				}
			} catch (error) {
				setTmpData(data)
				console.error(error)
			}
		} catch (error) {
			setData(...data)
			console.error(error)
		}
	}

	const handleChangeTimeout = (key, value) => {
		setTmpData((old) => ({ ...old, [key]: value }))

		clearTimeout(timeOut.current)
		timeOut.current = null
		timeOut.current = setTimeout(() => {
			handleChange(key, value, "TIMEOUT")
		}, 800)
	}

	useEffect(() => {
		const tmp = user?.laboratories?.filter((f) => f.warehouseIdHiboutik)
		setLaboratories(tmp)
	}, [])

	return (
		<div className="p-1">
			<div className="row">
				<div className="col-12 col-lg ">
					<div className="row pr-2 pl-2  ">Laboratoire *</div>
					<div className="row p-2">
						<select
							disabled={busy || productList?.length}
							className={
								"form-control form-control-sm " +
								(tmpData.laboratory !== -1 ? "" : "border border-danger")
							}
							onChange={(e) => handleChange("laboratory", e.target.value)}
							value={tmpData.laboratory || ""}>
							{laboratories.map((laboratory, key) => (
								<option key={key} value={laboratory["@id"]}>
									{laboratory.label}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-lg">
					<div className="row pr-2 pl-2 ">Fournisseur *</div>
					<div className="row p-2">
						<select
							disabled={busy || (tmpData.supplier && productList?.length) || isTransfer}
							className={
								"form-control form-control-sm " +
								(tmpData.supplier === -1 && !isTransfer ? "border border-warning" : "")
							}
							onChange={(e) => handleChange("supplier", +e.target.value)}
							value={tmpData.supplier || ""}>
							<option value={-1}>...</option>
							{suppliers
								.filter((s) => s.supplier_enabled)
								.map((supplier, key) => (
									<option key={key} value={supplier.supplier_id}>
										{supplier.supplier_name}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className="col-12 col-lg">
					<div className="row pr-2 pl-2 ">Marque</div>
					<div className="row p-2">
						<select
							disabled={busy || (tmpData.brand && productList?.length) || isTransfer}
							className={"form-control form-control-sm "}
							onChange={(e) => handleChange("brand", +e.target.value)}
							value={tmpData.brand || ""}>
							<option value={-1}>...</option>
							{brands
								.filter((brand) => brand.brand_enabled)
								.map((brand, key) => (
									<option key={key} value={brand.brand_id}>
										{brand.brand_name}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className="col-12 col-lg">
					<div className="row pr-2 pl-2 ">Numéro/Nom BL *</div>
					<div className="row p-2">
						<input
							disabled={busy || isTransfer}
							onBlur={(e) => handleChange("label", e.target.value)}
							onChange={(e) => handleChangeTimeout("label", e.target.value)}
							type="text"
							className={"form-control form-control-sm " + (tmpData.label ? "" : "border border-danger")}
							placeholder="001"
							value={tmpData.label || ""}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-lg">
					<DateInput
						id="dateEdition"
						label="Date d'édition *"
						disabled={busy || isTransfer}
						className={
							"form-control form-control-sm " + (tmpData.dateEdition ? "" : "border border-danger")
						}
						allowClear={false}
						value={
							tmpData.dateEdition != null && tmpData.dateEdition.length > 0
								? dayjs(tmpData.dateEdition)
								: undefined
						}
						onBlur={(e, date) => handleChange("dateEdition", formatDateForDB(date), "BLUR")}
						onChange={(date) => handleChange("dateEdition", formatDateForDB(date), "CHANGE")}
					/>
				</div>
				<div className="col-12 col-lg">
					<DateInput
						id="dateReceipt"
						label="Date de réception *"
						disabled={busy || isTransfer}
						className={
							"form-control form-control-sm " + (tmpData.dateReceipt ? "" : "border border-danger")
						}
						allowClear={false}
						value={
							tmpData.dateReceipt != null && tmpData.dateReceipt.length > 0
								? dayjs(tmpData.dateReceipt)
								: undefined
						}
						onBlur={(e, date) => handleChange("dateReceipt", formatDateForDB(date), "BLUR")}
						onChange={(date) => handleChange("dateReceipt", formatDateForDB(date), "CHANGE")}
					/>
				</div>
				<div className="col-12 col-lg">
					<DateInput
						id="depotEndDate"
						label="Date fin dépôt"
						disabled={busy}
						className="form-control form-control-sm"
						value={tmpData.depotEndDate ? dayjs(tmpData.depotEndDate) : undefined}
						onBlur={(_, date) => handleChange("depotEndDate", date ? formatDateForDB(date) : null, "BLUR")}
						onChange={(date) => handleChange("depotEndDate", date ? formatDateForDB(date) : null, "CHANGE")}
					/>
				</div>
			</div>
		</div>
	)
}

export default BonLivraisonConfiguration
