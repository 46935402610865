/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import API_Hiboutik from "../../../services/API_Hiboutik"
import API_PDF from "../../../services/API_PDF"
import { formatDatetimeForDB, rounded } from "../../../services/functions"

import { InputNumber } from "antd"
import { DateInputOnly } from "components/forms/DateInput"
import { useQueryClient } from "react-query"
import { Col, Row } from "reactstrap"
import { getLastState } from "./caisse.helpers"
import { CashRecord, CashType, FullAccounting, LastState, moneyItems as items, typeAccounting } from "./moneyItems"
import ButtonRounded from "components/Buttons/ButtonRounded"
import useCustomTitle from "components/Hooks/useTitle"

interface Payment {
	payment: string
	currency: string
	amount: number
	completed_at: string
	due_date: string
	paid_date: string
	sale_id: number
	customer_id: number
}

const EspValidationCaisse = (): JSX.Element => {
	useCustomTitle("Caisse | Validation")
	const { laboratory, user } = useContext(AuthContext)
	const queryClient = useQueryClient()
	const [busy, setBusy] = useState(false)
	const [espValues, setEspValues] = useState<CashRecord>({})
	const [paymentsESP, setPaymentsESP] = useState(0)
	const [amountCashOutflowSinceLastValidation, setAmountCashOutflowSinceLastValidation] = useState(0)
	const [paymentsESPList, setPaymentsESPList] = useState<Payment[]>([])
	const [lastState, setLastState] = useState<Partial<LastState>>()
	const [currentState, setCurrentState] = useState<CashRecord>({})
	const [needRefresh, setNeedRefresh] = useState(true)
	const [getLastValidation, setLastValidation] = useState<Partial<FullAccounting> | undefined>()
	const [comment, setComment] = useState("")
	const [loading, setLoading] = useState(true)
	const [initFirstTime, setInitFirstTime] = useState<{ date: dayjs.Dayjs | null }>()

	const generatePdf = async (
		totalReceived: number,
		totalState: number,
		oldTotal: number,
		newTotal: number,
		paymentList: any[]
	): Promise<void> => {
		const paymentsRecieved = []
		const cashRegisterState = []

		// Réutilisation ancien code caisse
		const companyHeaders = {
			logo: laboratory?.subCompany?.logo ?? user?.company?.logo,
			companyName: laboratory?.legalLabel,
			laboratoryName: laboratory?.label,
			laboratoryAddress: laboratory?.adress,
			laboratoryCpo: laboratory?.cpo,
			laboratoryCity: laboratory?.city,
			laboratoryPhone: laboratory?.phone,
			laboratoryMail: laboratory?.email,
			laboratoryRCS: laboratory?.rcs,
			laboratorySiret: laboratory?.siret,
			laboratoryFiness: laboratory?.finess,
			laboratoryTVA: laboratory?.tva,
		}

		for (const payment of paymentList) {
			paymentsRecieved.push({
				date: payment.paid_date !== "0000-00-00" ? payment.paid_date : payment.completed_at,
				sale_id: payment.sale_id,
				total: +payment.amount,
			})
		}

		for (const esp in currentState) {
			if (esp === "total") {
				continue
			}
			const amount = items.find((f) => f.name === esp)
			if (amount) {
				cashRegisterState.push({
					type: "ESPECE",
					value: amount.label,
					multiplier: currentState[esp as CashType],
					total: rounded(+amount.value * +(currentState[esp as CashType] ?? 0)),
				})
			}
		}
		const dataForPdf = {
			date: dayjs().format("LL"),
			comment: comment,
			company: companyHeaders,
			oldCashFund: oldTotal,
			newCashFund: newTotal,
			paymentsRecieved,
			total: totalReceived,
			cashRegisterState,
			totalCashRegister: totalState,
		}

		await API_PDF.uploadFromCarboneToDb(dataForPdf, "VALIDATION_CAISSE", "pdf", {
			type: "CAISSE_VALIDATION",
		})
	}

	useEffect(() => {
		;(async function () {
			try {
				setLoading(true)
				const resultAccounting = await API.findAll<FullAccounting[]>(
					"ACCOUNTING_API",
					"?order[createdAt]=desc&laboratory=" + laboratory.id
				)
				let lastValidation: Partial<FullAccounting> | undefined = resultAccounting.find(
					(f) => f.type === typeAccounting.validationEspece
				)
				let lastData: Partial<LastState> | null
				setLastValidation(lastValidation)
				lastData = getLastState(resultAccounting)

				if (!lastValidation?.createdAt) {
					if (initFirstTime?.date) {
						const createdAt = formatDatetimeForDB(initFirstTime.date.startOf("day"))
						lastData = { ...lastData, createdAt }

						lastValidation = { createdAt }
						setLastValidation(lastValidation)
					}
				}
				if (!lastValidation?.createdAt) {
					return
				}

				// find last cashOutflow since last validation
				const lastCashOutflow = resultAccounting.filter(
					(a) =>
						dayjs(a.createdAt).isAfter(lastValidation?.createdAt) && a.type === typeAccounting.sortieEspece
				)
				setAmountCashOutflowSinceLastValidation(
					lastCashOutflow.reduce((total, acc) => total + (acc.amount ?? 0), 0)
				)
				// find cash payment since last validation
				let tmpPayments: Payment[] = []

				for (
					let d = new Date(new Date(lastValidation.createdAt).setDate(1));
					d.getTime() <= new Date().getTime();
					d.setMonth(d.getMonth() + 1)
				) {
					try {
						const res = await API_Hiboutik.getPaymentsReceived(
							{ year: d.getFullYear(), month: d.getMonth() + 1 },
							laboratory.warehouseIdHiboutik
						)

						for (const r of res) {
							for (const payment of r.payments) {
								if (payment?.completed_at === "0000-00-00 00:00:00") continue
								tmpPayments.push({
									...payment,
									payment: r.payment_type,
									currency: r.currency,
								})
							}
						}
					} catch (error) {
						console.error(error)
						try {
							const sales = await API_Hiboutik.getAllSales(
								d.getFullYear(),
								d.getMonth() + 1,
								null,
								laboratory.warehouseIdHiboutik
							)
							for (const sale of sales) {
								if (sale.payment === "ESP")
									tmpPayments.push({
										payment: sale.payment,
										currency: sale.currency,
										amount: sale.total,
										completed_at: sale.completed_at,
										due_date: "0000-00-00",
										paid_date: "0000-00-00",
										sale_id: sale.sale_id,
										customer_id: sale.customer_id,
									})
								else if (sale.payment === "DIV") {
									const detail = await API_Hiboutik.getSale(sale.sale_id)
									for (const payment of detail.payment_details) {
										if (payment.payment_type !== "ESP") continue
										tmpPayments.push({
											payment: payment.payment_type,
											currency: sale.currency,
											amount: payment.payment_amount,
											completed_at: sale.completed_at,
											due_date: payment.payment_date,
											paid_date: payment.payment_date_paid,
											sale_id: sale.sale_id,
											customer_id: sale.customer_id,
										})
									}
								}
							}
						} catch (error) {
							console.error(error)
						}
					}
				}

				tmpPayments = tmpPayments.filter((f) => {
					if (lastValidation?.createdAt) {
						return new Date(f.completed_at).getTime() > new Date(lastValidation.createdAt).getTime()
					}
					return true
				})

				if (!lastData) return
				setLastState(lastData)
				setEspValues(lastData.data?.cashfund || {})
				const findESP = tmpPayments.filter((f) => f.payment === "ESP")
				setPaymentsESPList(findESP)
				setPaymentsESP(findESP.reduce((acc, item) => +item.amount + acc, 0))
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		})()
	}, [needRefresh])

	useEffect(() => {
		const tmpData: CashRecord = {}
		const lastData: CashRecord = lastState?.data?.cashfund ?? {}

		for (const v in lastData) {
			tmpData[v as CashType] = -(lastData?.[v as CashType] ?? 0)
		}
		for (const v in espValues) {
			if (!tmpData[v as CashType]) {
				tmpData[v as CashType] = 0
			}
			;(tmpData[v as CashType] as number) += +(espValues?.[v as CashType] ?? 0)
		}

		setCurrentState(tmpData)
	}, [espValues])

	const calcEsp = (data: CashRecord): number => {
		let total = 0
		for (const esp in data) {
			if (esp === "total") {
				continue
			}
			const amount = items.find((f) => f.name === esp)
			total += +(amount?.value || 0) * +(data[esp as CashType] ?? 0)
		}
		return rounded(total)
	}

	const saveEsp = async (): Promise<void> => {
		try {
			setBusy(true)
			const data = {
				type: "VALIDATION_ESPECE",
				data: {
					cashfund: { ...espValues },
					state: { ...currentState },
				},
				expectedAmount: paymentsESP - amountCashOutflowSinceLastValidation,
				amount: calcEsp(currentState),
				comment,
				laboratory: laboratory["@id"],
			}
			await API.create("ACCOUNTING_API", data)
			await generatePdf(
				paymentsESP - amountCashOutflowSinceLastValidation,
				calcEsp(currentState),
				lastState?.data?.cashfund ? calcEsp(lastState?.data?.cashfund) || 0 : 0,
				calcEsp(espValues),
				paymentsESPList
			)
		} catch (error) {
			console.error(error)
		} finally {
			setComment("")
			setBusy(false)
			setNeedRefresh(!new Date())
			queryClient.invalidateQueries("ACCOUNTING_API")
		}
	}

	return (
		<div className="d-flex flex-column h-100 position-relative">
			{loading && (
				<div className="overlay-loading-aw">
					<div className="overlay-loading-logo" />
					<div className="overlay-loading-text">Chargement ...</div>
				</div>
			)}
			{!lastState?.createdAt && (
				<Row>
					<Col>
						<div className="alert alert-primary ">
							C'est la première fois que vous allez faire une validation de caisse. <br />
							<DateInputOnly
								className="form-control mb-1 mt-3"
								onChange={(date) => {
									if (date) {
										setInitFirstTime({ date })
									} else {
										setInitFirstTime(undefined)
									}
								}}
							/>
							<button
								type="button"
								className="btn btn-block btn-primary mt-2"
								disabled={!initFirstTime?.date}
								onClick={() => {
									setNeedRefresh(!new Date())
								}}>
								Déclarer à partir de la date sélectionnée
							</button>
						</div>
					</Col>
				</Row>
			)}
			{lastState?.createdAt && (
				<Row>
					<Col className="my-3" xs="12">
						<h6>
							Fond de caisse actuel :{" "}
							<strong style={{ fontSize: "18px" }}>
								{calcEsp(lastState.data?.cashfund ?? {}).toLocaleString("fr")} €
							</strong>
						</h6>
						<h6>
							Encaissé en espèce depuis la dernière validation (
							{dayjs(getLastValidation?.createdAt).format("DD/MM/YYYY, HH[h]mm")}
							):&nbsp;
							<strong style={{ fontSize: "18px" }}>
								{(paymentsESP - amountCashOutflowSinceLastValidation).toLocaleString("fr")} €
							</strong>
						</h6>
						<h6>
							Nouveau fond de caisse :{" "}
							<strong style={{ fontSize: "18px" }}>{calcEsp(espValues).toLocaleString("fr")} € </strong>
						</h6>

						<h6>
							Fond de caisse attendu :{" "}
							<strong style={{ fontSize: "18px" }}>
								{(
									+calcEsp(lastState.data?.cashfund ?? {}) +
									+paymentsESP -
									amountCashOutflowSinceLastValidation
								).toLocaleString("fr")}{" "}
								€
							</strong>
						</h6>
						<h6>
							Ecart avec fond de caisse attendu :{" "}
							<strong
								style={{
									fontSize: "18px",
									color:
										calcEsp(currentState) - paymentsESP + amountCashOutflowSinceLastValidation !== 0
											? "red"
											: "green",
								}}>
								{rounded(
									calcEsp(currentState) - paymentsESP + amountCashOutflowSinceLastValidation
								).toLocaleString("fr")}{" "}
								€
							</strong>
						</h6>
					</Col>

					<Col className="my-3" xs="12">
						<form className="row ">
							{items.map((i) => {
								return (
									<div key={`${i.id}-${i.name}`} className="col-md-6">
										<div className="form-group row d-flex justify-content-center align-items-center">
											<label
												htmlFor={i.name}
												className="col-1 col-md-3 d-flex flex-column align-items-center">
												{i.label}
											</label>
											<InputNumber
												type="number"
												className="col-5 col-md-8 align-items-center"
												min={0}
												placeholder="0"
												value={espValues?.[i.name]}
												onChange={(value) =>
													setEspValues((old) => ({ ...old, [i.name]: value, total: 0 }))
												}
											/>
											<div className="col-1 col-md-1">
												{+(currentState?.[i.name] || 0) >= 1
													? `+${currentState?.[i.name]}`
													: currentState?.[i.name]}
											</div>
										</div>
									</div>
								)
							})}
							<div className="col-md-12">
								<textarea
									className="form-control mb-3"
									placeholder="Ajouter un commentaire"
									value={comment}
									onChange={(e) => {
										const value = e.target.value
										setComment(value)
									}}
								/>
							</div>
							<div className="col-md-12">
								<ButtonRounded
									className="m-auto"
									color="primary-outlined"
									isDisabled={busy}
									onClick={saveEsp}
									type="button">
									Valider la caisse
								</ButtonRounded>
							</div>
						</form>
					</Col>
				</Row>
			)}
		</div>
	)
}

export default EspValidationCaisse
