import { Document, DocumentType } from "@audiowizard/common"
import cx from "classnames"
import useEffectAsync from "components/Hooks/useEffectAsync"
import PdfViewerMozilla from "components/utils/PdfViewer"
import { History } from "history"
import { isUndefined } from "lodash"
import { useContext, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useGenerateDocument } from "../../../features/sales/hooks/useGenerateDocument/useGenerateDocument"
import API from "../../../services/API"
import { updateDocumentPDF } from "../../../services/requests/http/document/updateDocumentPDF"
import { downloadFromUrl } from "../../../services/Utilities"
import { preload } from "../../../utils/http/preload"
import { docsExtensions, embedExtensions, fileExtensionIcon, imageExtensions } from "./Extensions"
import DocumentUploader from "./Modal.DocumentUploader"
import "./Modal.DocumentViewer.scss"
import { canBeSentByMail, DocumentEmailModal } from "./SendMailDocument"
import { canBeSigned } from "./SignDocument"
import StlViewer from "./StlViewer"
import { ButtonDocumentTooltip, isDocumentDisabled } from "components/Buttons/SmartButtonDocument"
import useCreateUserInsuranceCoverageDemand from "../../../services/requests/http/userInsuranceCoverageDemandService/Hooks/useCreateUserInsuranceCoverageDemand"
import {
	UserInsuranceCoverageDemandService,
	userInsuranceCoverageDemandServicePatientKey,
} from "../../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService"
import { CoverageDemandState } from "../../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import { useQueryClient } from "react-query"
import AuthContext from "../../../contexts/AuthContext"
import useUserInsuranceCoverageCapability from "../../../hooks/specific/useUserInsuranceCoverageCapability"
import { Tooltip } from "antd"

type DocumentViewProps = {
	document: Document
	onLoad: () => void
}

function DocumentView({ onLoad, ...props }: DocumentViewProps): JSX.Element {
	const [document, setDocument] = useState(props.document)
	const [loading, setLoading] = useState(false)
	const generateDocument = useGenerateDocument()
	const documentExistsRef = useRef(null) // if needs refresh, change it in useState

	useEffectAsync(async () => {
		if (
			!document.isMissingFile ||
			!["FACTURE_SUIVI_TELETRANS", "FACTURE", "FACTURE_DA", "FACTURE_CO", "FACTURE_OS"].includes(
				document.type as DocumentType
			)
		) {
			setDocument(props.document)
			return
		}

		// Regenerate invoice pdf if file doesn't exist
		setLoading(true)
		const stringifiedContent = await generateDocument(document) // Generates the document under a stringified HTML format
		const updatedDocDetails = await updateDocumentPDF(document.id!, { content: stringifiedContent }) // Updates the document PDF
		documentExistsRef.current = await preload(document.signature!, true)
		setDocument(updatedDocDetails?.data)
		setLoading(false)
	}, [props.document])

	if (loading) return <p>Chargement de l'aperçu...</p>
	else if (isUndefined(documentExistsRef.current)) return <p>Dysfonctionnement Hébergeur</p>
	else if (document?.extension == null || document?.signature == null) return <p>Fichier non disponible</p>

	// PDF -> <embed>
	if (embedExtensions.includes(document.extension)) {
		return (
			<PdfViewerMozilla
				className="document-iframe"
				title={document.label}
				src={document.signature}
				onLoad={onLoad}
			/>
		)
	}

	// Document Word/Excel/Libreoffice -> Iframe vers google docs
	if (docsExtensions.includes(document.extension)) {
		return (
			<iframe
				className="document-iframe"
				title={document.label}
				src={`https://docs.google.com/gview?url=${encodeURIComponent(document?.signature)}&embedded=true`}
				frameBorder="0"
				width="100%"
				onLoad={onLoad}
			/>
		)
	}

	// Image -> <img>
	if (imageExtensions.includes(document.extension)) {
		return (
			<img src={document?.signature} alt={document.filename} className="d-block my-0 mx-auto" onLoad={onLoad} />
		)
	}

	// STL -> STL Viewer
	if (document.extension === "stl") {
		const primaryColor = getComputedStyle(window.document.documentElement).getPropertyValue("--primary")

		const secondaryColor = getComputedStyle(window.document.documentElement).getPropertyValue("--info")

		let objectColor = primaryColor // couleur primary par défaut
		if (document.type === "SCAN_OREILLE_DROITE") {
			objectColor = "#ff5630" // couleur orreile droite (rouge)
		} else if (document.type === "SCAN_OREILLE_GAUCHE") {
			objectColor = secondaryColor // couleur orreile gauche (bleu)
		}

		return (
			<StlViewer
				className="document-iframe"
				defaultObjectColor={objectColor}
				src={document?.signature}
				onLoad={onLoad}
			/>
		)
	}

	onLoad()
	return <p>Impossible d'afficher un aperçu pour ce type de fichier.</p>
}

export const handleModify = async (
	history: History<unknown>,
	currentDoc: Document,
	simplified: Document | null | undefined
): Promise<void> => {
	let normalised
	const state = {
		patient: currentDoc.patient,
		existingQuote: {
			...currentDoc.config,
			id: currentDoc.id,
			"@id": currentDoc["@id"],
			createdAt: currentDoc.createdAt,
		},
	}

	if (currentDoc.type === "DEVIS_SIMPLIFIE") {
		const data = await API.findAll<Document[]>("DOCUMENTS_API", `?type=DEVIS&numero=${currentDoc.numero}`)
		if (data.length > 0 && data[0].id) {
			normalised = await API.find<Document>("DOCUMENTS_API", data[0].id)
		}
	}

	if (simplified) {
		state["existingQuote"]["simplifiedId"] = simplified.id
		state["existingQuote"]["simplified@id"] = simplified["@id"]
	} else if (normalised) {
		state["existingQuote"]["normalisedId"] = normalised.id
		state["existingQuote"]["normalised@id"] = normalised["@id"]
	}

	history.push({
		pathname:
			state["existingQuote"].editedQuotePack != null
				? "/devis-pack/edition" // new quote pack
				: "/devis/edition-devis", // old quote
		state: state,
	})
}

type DocumentModalProps = {
	document: Document
	onClose: () => void
	isOpen: boolean
	refreshDocuments: () => void
	onPrevious?: () => void
	onNext?: () => void
	hideRemove?: boolean
}
export default function DocumentModal({
	document,
	onClose,
	isOpen,
	refreshDocuments,
	onPrevious,
	onNext,
	hideRemove,
}: DocumentModalProps): JSX.Element {
	const history = useHistory()
	const client = useQueryClient()
	const { patient } = useContext(AuthContext)

	const [currentDoc, setCurrentDoc] = useState({} as Document)
	const [loading, setLoading] = useState(true)
	const [viewLoading, setViewLoading] = useState(true)
	const [busy, setBusy] = useState(false)
	const [label, setLabel] = useState("")
	const [refresh, setRefresh] = useState<any>(true)
	const viewLoadingRef = useRef(viewLoading)
	viewLoadingRef.current = viewLoading

	const [simplified, setSimplified] = useState<Document>()

	const [emailModal, setEmailModal] = useState(false)
	const [replaceDocument, setReplaceDocument] = useState<boolean | Record<string, any>>(false)
	const { canInsertDemand, showViolations } = useUserInsuranceCoverageCapability()

	const { mutateAsync: createUserInsuranceCoverageDemand, isLoading: isCreatingUserInsuranceDemand } =
		useCreateUserInsuranceCoverageDemand()

	useEffectAsync(async () => {
		if (!document.id || !refresh) return
		try {
			setLoading(true)
			setViewLoading(true)
			const result = await API.find<Document>("DOCUMENTS_API", document.id)

			//@ts-ignore
			setSimplified(result.type === "DEVIS" ? result.simplifiedQuote : undefined)
			setCurrentDoc(result)
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
			setViewLoading(false)
		}
		setRefresh(false)
	}, [document.id, refresh])

	useEffect(() => {
		if (!isOpen) return
		setRefresh(true)
	}, [isOpen, document])

	useEffect(() => {
		setLabel(currentDoc.label! || "")
	}, [currentDoc])

	const handleClose = (): void => {
		setLoading(true)
		setViewLoading(true)
		setCurrentDoc({} as Document)
		setBusy(false)

		onClose()
	}

	const handleDelete = async (): Promise<void> => {
		try {
			setBusy(true)
			await API.delete(currentDoc["@id"])
			toast.success(`Le document "${currentDoc?.label}" a été supprimé`)

			refreshDocuments()
			handleClose()
		} catch (error) {
			toast.error("Impossible de supprimer le document")
			console.error(error)
		} finally {
			setBusy(false)
		}
	}

	// Retry chargement de view
	useEffect(() => {
		if (!loading) {
			const timer = setTimeout(() => {
				if (viewLoadingRef.current) {
					setRefresh(new Date())
				}
			}, 5000)
			return () => clearTimeout(timer)
		}
	}, [loading])

	// Raccourci clavier pour document précédent/suivant
	useEffect(() => {
		if (onPrevious == null || onNext == null) return

		const keydownListener = (e: KeyboardEvent): void => {
			if (emailModal) return // Pour ne pas être en conflit quand DocumentEmailModal est ouvert

			if (e.key === "ArrowLeft") {
				onPrevious()
			} else if (e.key === "ArrowRight") {
				onNext()
			}
		}
		window.document.addEventListener("keydown", keydownListener)

		return () => {
			window.document.removeEventListener("keydown", keydownListener)
		}
	}, [onPrevious, onNext, emailModal])

	const handleCoverageDemand = async (deviceClass: 1 | 2) => {
		if (!patient) return

		if (!canInsertDemand) {
			showViolations()
			return
		}

		if (!patient.patientInsurances || patient.patientInsurances?.length === 0) {
			toast.error("La mutuelle du patient n’est pas affectée à un réseau ou à un nom national")
			return
		}

		const insurance = patient.patientInsurances[patient.patientInsurances.length - 1]

		if (!insurance.network) {
			toast.error("La mutuelle du patient n’est pas affectée à un réseau")
			return
		}

		if (!insurance.userInsuranceCompany) {
			toast.error("La mutuelle du patient n’est pas affectée à un nom national")
			return
		}

		if (!patient.mainUser) {
			toast.error("l’utilisateur n’a pas d’audioprothésiste")
			return
		}

		try {
			await toast.promise(
				async () => {
					if (!insurance?.network?.["@id"] || !insurance?.userInsuranceCompany?.["@id"]) return

					const { id } = await createUserInsuranceCoverageDemand({
						deviceClass,
						documents: [document["@id"]],
						patient: document.patient!["@id"],
						network: insurance.network["@id"],
						insuranceCompany: insurance.userInsuranceCompany["@id"],
						mainUserAtTime: patient.mainUser!["@id"],
					})

					const getState = async () => UserInsuranceCoverageDemandService.getState(id)

					// First call send to datamut the demand
					await getState()

					const checkPromise = async () =>
						new Promise((resolve, reject) => {
							let failsafe = 0
							const interval = setInterval(async () => {
								// Exit after 5 minutes
								if (failsafe > 15) {
									reject("timeout")
								}

								failsafe++

								const result = await getState()

								if ("state" in result) {
									if ("error" in result && result.error !== null) {
										clearInterval(interval)
										await client.invalidateQueries([userInsuranceCoverageDemandServicePatientKey])
										reject(result.error)
									}

									if (result.state === CoverageDemandState.WAITING_TRANSFORM) {
										clearInterval(interval)
										await client.invalidateQueries([userInsuranceCoverageDemandServicePatientKey])
										resolve(result)
									}
								}
							}, 5e3)
						})

					await checkPromise()
					close()
				},
				{
					pending: "Demande en cours...",
					success: "Votre demande a bien été prise en compte.",
				}
			)

			onClose()
		} catch (e) {
			toast.error("Une erreur s’est produite lors de l’envoi de la demande.")
		}
	}

	const headerIcon = fileExtensionIcon(currentDoc.extension)
	return (
		<>
			<Modal scrollable={true} isOpen={isOpen} size="xl" className="modal-documentviewer">
				<ModalHeader>
					<div className="modal-title d-flex align-items-baseline">
						<div
							className="icon-sm mr-1"
							key={headerIcon} // key pour forcer un update du DOM. Sinon Font-Awesome ne modifie pas l'icone
						>
							<i className={`fad ${headerIcon}`} />
						</div>
						<div className="input-group mr-1">
							<input
								className="form-control full-width"
								type="text"
								value={label}
								onChange={(e) => setLabel(e.target.value)}
							/>
							<div className="input-group-append modal-document-viewer extension">
								<span className="input-group-text">.{document.extension}</span>
							</div>
						</div>

						{/* Boutons document précédent/suivant */}
						{onPrevious != null && onNext != null && (
							<>
								<Button
									title="Voir le document précédent"
									color="primary"
									size="sm"
									className="modal-document-viewer__nav__button--prev"
									onClick={onPrevious}>
									<i className="fad fa-arrow-left" />
								</Button>
								<Button
									title="Voir le document suivant"
									color="primary"
									size="sm"
									className="modal-document-viewer__nav__button--next"
									onClick={onNext}>
									<i className="fad fa-arrow-right" />
								</Button>
							</>
						)}

						<div className="icon-sm mx-3 cursor-pointer close-icon" onClick={handleClose}>
							<i className="fas fa-times" />
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					{loading && (
						<div>
							<p>Chargement de l'aperçu...</p>

							{docsExtensions.includes(currentDoc.extension!) && (
								<p>Les fichiers {docsExtensions.join(", ")} peuvent être longs à charger.</p>
							)}
						</div>
					)}

					{!loading && (
						<div className="preview-container">
							<DocumentView document={currentDoc} onLoad={() => setViewLoading(false)} />
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<div className="modal-document-viewer">
						<div className="modal-document-viewer__nav">
							{/* Boutons devis simplifié */}
							{/*{simplified != null && (*/}
							{/*	<button*/}
							{/*		type="button"*/}
							{/*		className="btn btn-primary btn-sm modal-document-viewer__nav__button--simplified"*/}
							{/*		onClick={() => {*/}
							{/*			setCurrentDoc(simplified)*/}
							{/*			setSimplified(currentDoc)*/}
							{/*		}}>*/}
							{/*		{currentDoc.type === "DEVIS" ? "Devis simplifié" : "Devis normalisé"}*/}
							{/*	</button>*/}
							{/*)}*/}
							{/* Buttons go to billing page */}
							{["FACTURE", "FACTURE_DA", "FACTURE_CO", "FACTURE_OS"].includes(currentDoc.type!) && (
								<Link
									to={
										currentDoc.type === "FACTURE"
											? `/facture-client/${currentDoc.id}`
											: `/facture-dynamic/${currentDoc.id}`
									}
									title="Aller sur la page facture"
									className="btn btn-primary btn-sm modal-document-viewer__nav__button--page-facture">
									Page facture
								</Link>
							)}
						</div>

						<div className="modal-document-viewer__button--download">
							{canBeSigned(currentDoc) && (
								<Tooltip placement="top" title="Signer le document">
									<Link
										to={`/documents/signer/${currentDoc.id}`}
										className={cx("btn", "btn-outline-info", "btn-block", "icon-button", {
											disabled: busy || viewLoading || loading,
										})}>
										<em className="fad fa-file-signature" />
									</Link>
								</Tooltip>
							)}
							{canBeSentByMail(currentDoc) && (
								<Tooltip placement="top" title="Envoyer le document">
									<button
										type="button"
										className="btn btn-outline-info btn-block icon-button"
										disabled={busy || viewLoading || loading}
										onClick={() => setEmailModal(true)}>
										<em className="fad fa-envelope" />
									</button>
								</Tooltip>
							)}
							<Tooltip placement="top" title="Télécharger le fichier">
								<button
									type="button"
									className="btn btn-outline-info btn-block icon-button"
									onClick={() => {
										downloadFromUrl(currentDoc.signature)
									}}>
									<em className="fad fa-file-download" />
								</button>
							</Tooltip>
						</div>

						{currentDoc.type === "DEVIS" && (
							<>
								<button
									type="button"
									className="btn btn-outline-info btn-block btn-sm modal-document-viewer__button--pec1"
									disabled={
										isCreatingUserInsuranceDemand ||
										(currentDoc.config?.productLeftClass1 === undefined &&
											currentDoc.config?.productRightClass1 === undefined)
									}
									onClick={async () => handleCoverageDemand(1)}>
									Demander prise en charge mutuelle classe 1
								</button>

								<button
									type="button"
									className="btn btn-outline-info btn-block btn-sm modal-document-viewer__button--pec2"
									disabled={
										isCreatingUserInsuranceDemand ||
										(currentDoc.config?.productLeftClass2 === undefined &&
											currentDoc.config?.productRightClass2 === undefined)
									}
									onClick={async () => handleCoverageDemand(2)}>
									Demander prise en charge mutuelle classe 2
								</button>
							</>
						)}
						{!hideRemove && !currentDoc?.type?.includes("FACTURE") && (
							<button
								type="button"
								className="btn btn-outline-danger btn-sm modal-document-viewer__button--delete"
								disabled={
									busy ||
									viewLoading ||
									loading ||
									(currentDoc.coverageDemands && currentDoc.coverageDemands.length > 0)
								}
								onClick={handleDelete}>
								Supprimer
							</button>
						)}
						{["DEVIS", "DEVIS_SIMPLIFIE"].includes(currentDoc.type!) &&
							!currentDoc?.type?.includes("FACTURE") && (
								<ButtonDocumentTooltip
									document={currentDoc}
									className="modal-document-viewer__button--edit">
									<button
										type="button"
										className="btn btn-outline-warning btn-block btn-sm  modal-document-viewer__button--edit"
										style={isDocumentDisabled(currentDoc.config) ? { pointerEvents: "none" } : {}}
										disabled={
											busy || viewLoading || loading || isDocumentDisabled(currentDoc.config)
										}
										onClick={() => handleModify(history, currentDoc, simplified)}>
										Modifier
									</button>
								</ButtonDocumentTooltip>
							)}
						{!["DEVIS", "DEVIS_SIMPLIFIE"].includes(currentDoc.type!) &&
							!currentDoc?.type?.includes("FACTURE") && (
								<button
									type="button"
									className="btn btn-outline-info btn-block btn-sm  modal-document-viewer__button--replace"
									disabled={busy || viewLoading || loading}
									onClick={() => setReplaceDocument(document)}>
									Remplacer
								</button>
							)}

						{currentDoc.type === "DEVIS" && (
							<ButtonDocumentTooltip
								document={currentDoc}
								className="modal-document-viewer__button--command">
								<button
									type="button"
									className="btn btn-primary btn-sm btn-block modal-document-viewer__button--command"
									style={isDocumentDisabled(currentDoc.config) ? { pointerEvents: "none" } : {}}
									disabled={isDocumentDisabled(currentDoc.config)}
									onClick={() => {
										history.push(`/devis/commande-appareils?document=${currentDoc.id}`)
									}}>
									Choisir
								</button>
							</ButtonDocumentTooltip>
						)}
					</div>
				</ModalFooter>
			</Modal>

			<DocumentEmailModal
				document={currentDoc}
				open={emailModal}
				onClose={() => setEmailModal(false)}
				onDocumentChange={setCurrentDoc}
			/>

			<DocumentUploader
				onClose={() => setReplaceDocument(false)}
				isOpen={!!replaceDocument}
				refreshDocuments={() => {
					setRefresh(true)
					refreshDocuments()
				}}
				documentToReplace={simplified ? document : currentDoc}
				patientIri={simplified ? document?.patient?.["@id"] || "" : currentDoc?.patient?.["@id"] || ""}
			/>
		</>
	)
}
