import { OriginSpecial, Patient } from "@audiowizard/common"
import { AutoComplete, Input, Select } from "antd"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import CountryInput, { CountryCode } from "components/forms/CountryInput"
import { DateInput } from "components/forms/DateInput"
import LanguageInput, { LanguageCode } from "components/forms/LanguageInput"
import PhoneInput from "components/forms/PhoneInput"
import useHasRole from "components/Hooks/useHasRole"
import dayjs from "dayjs"
import { useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { formatDateForDB, getIdFromIri } from "services/functions"
import AuthContext from "../../../contexts/AuthContext"
import { styleAdresseInput } from "../../../datas/items/itemsAdressePatient"
import API from "../../../services/API"

interface PatientCreationModalProp {
	modalOpen: boolean
	setModalOpen: (b: boolean) => void
	selectedLaboratory?: number
	selectedUser?: number
	setPatient: (p: Patient) => void
}
interface PatientData {
	birthDate?: Date | null
	country?: CountryCode
	language?: LanguageCode
	firstName?: string
	lastName?: string
	laboratory?: string
	user?: string | null
	gender?: string
	adress?: string
	cpo?: string
	city?: string
	phone?: string
	phoneMobile?: string
	patientSource?: string
	origin?: string
	patientOriginDetail?: string
}
interface Address {
	value: string
	city: string
	cpo: string
	adresse: string
}

interface Source {
	"@id": string
	"@type": string
	id: number
	name: string
}

interface Origin {
	"@id": string
	"@type": string
	id: number
	name: string
	patientOriginDetails: OriginDetails[]
	special: OriginSpecial | null
}

interface OriginDetails {
	"@id": string
	"@type": string
	id: number
	name: string
}

export const PatientCreationModalDynamic: React.FC<PatientCreationModalProp> = ({
	modalOpen,
	setModalOpen,
	selectedLaboratory,
	selectedUser,
	setPatient,
}) => {
	const { laboratory } = useContext(AuthContext)
	const [data, setData] = useState<PatientData>({ birthDate: null })
	const [selectedAddress, setSelectedAddress] = useState("")
	const [patientAddress, setPatientAddress] = useState<Address[]>([])
	const [saving, setSaving] = useState(false)
	const timeout = useRef<NodeJS.Timeout>()

	const [sources, setSources] = useState<Source[]>()
	const [origins, setOrigins] = useState<Origin[]>()
	const [defaultOrigin, setDefaultOrigin] = useState<number>()
	const [defaultSource, setDefaultSource] = useState<string>("")
	const [disabledSave, setDisabledSave] = useState<boolean>(false)
	const [originsDetails, setOriginsDetails] = useState<OriginDetails[]>()

	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")

	useEffect(() => {
		setData({ country: "FR", language: "fr" })
		setSelectedAddress("")
		setPatientAddress([])
	}, [modalOpen])

	useEffect(() => {
		const getSources = async (): Promise<void> => {
			const sources = await API.findAll<Source[]>("PATIENT_SOURCES_API")
			setSources(sources)
		}
		getSources()
	}, [])

	useEffect(() => {
		const getOrigin = async (): Promise<void> => {
			const origins = await API.findAll<Origin[]>("ORIGINS_API")
			setOrigins(origins)
		}
		getOrigin()
	}, [])

	useEffect(() => {
		if ((isAffiliate || isAffiliateManager) && origins) {
			handleChange(origins[0]?.["@id"], "origin")
			setOriginsDetails(origins[0].patientOriginDetails)
			setDefaultOrigin(0)
		}
	}, [isAffiliate, isAffiliateManager, origins])

	useEffect(() => {
		if ((isAffiliate || isAffiliateManager) && sources) {
			handleChange(sources[0]?.["@id"], "patientSource")
			setDefaultSource(sources[0]?.["@id"])
		}
	}, [isAffiliate, isAffiliateManager, sources])

	useEffect(() => {
		if (modalOpen && (isAffiliate || isAffiliateManager)) {
			if (origins && origins.length === 0) {
				toast.error("Aucune origine définie pour l’affiliation")
				setDisabledSave(true)
			}
			if (sources && sources.length === 0) {
				toast.error("Aucune source définie pour l’affiliation")
				setDisabledSave(true)
			}
		}
	}, [isAffiliate, isAffiliateManager, modalOpen])

	const createPatient = async (): Promise<void> => {
		if (
			(data.firstName && data.firstName?.length < 3) ||
			(data.lastName && data.lastName?.length < 3) ||
			!data?.gender
		) {
			toast.warn("Veuillez remplir tous les champs obligatoires")
			return
		}

		try {
			// Verificatin de l'existence d'un patient avec les mêmes nom et prénom en BDD
			const patientAlreadyExistinDB = await API.findAll(
				"PATIENTS_API",
				`?lastName=${data.lastName}&firstName=${data.firstName}`
			)

			let continueCreation = true

			if (patientAlreadyExistinDB?.length !== 0) {
				continueCreation = await confirmWithModal({
					title: "Un patient avec ce nom existe déjà, voulez-vous continuer?",
				})
			}

			if (!continueCreation) return

			if (laboratory || selectedLaboratory) {
				setSaving(true)
				data.laboratory = selectedLaboratory ? "/laboratories/" + selectedLaboratory : laboratory["@id"]
				data.user = selectedUser ? "/users/" + selectedUser : null
				const result = await API.create<Patient>("PATIENTS_API", data)
				setPatient({ ...result.data, id: parseInt(getIdFromIri(result.data["@id"]) ?? "") })
				setModalOpen(false)
			}
		} catch (e: any) {
			toast.error(e?.response?.data["hydra:description"] ?? "Une erreur est survenue durant la création du patient")
			console.error(e)
		} finally {
			setSaving(false)
		}
	}

	const handleChange = (e: any, key: string): void => {
		setData((old) => ({ ...old, [key]: e }))
	}

	const handleAutoComplete = async (value: string): Promise<void> => {
		clearTimeout(timeout.current as NodeJS.Timeout)
		timeout.current = setTimeout(async () => {
			try {
				const fetchAddress = await API.getAddress(value)

				setPatientAddress(
					fetchAddress?.map((a) => ({
						value: a.properties.label,
						city: a.properties.city,
						cpo: a.properties.postcode,
						adresse: a.properties.name,
					}))
				)
			} catch (error) {
				console.error(error)
			} finally {
			}
		}, 350)
	}

	const handleSelect = (value: string, option: any): void => {
		setData((old) => ({ ...old, adress: option.adresse, city: option.city, cpo: option.cpo }))
	}

	return (
		<>
			<Modal isOpen={modalOpen} size={"lg"}>
				<ModalHeader>Ajouter un nouveau patient</ModalHeader>
				<ModalBody>
					<form>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label>Prénom *</label>
								<input
									type="text"
									className={"form-control" + (!data.firstName ? " border border-warning" : "")}
									placeholder=""
									onChange={(e) => {
										handleChange(e.target.value, "firstName")
									}}
									required
								/>
							</div>
							<div className="form-group col-md-6">
								<label>Nom *</label>
								<input
									type="text"
									className={"form-control" + (!data.lastName ? " border border-warning" : "")}
									placeholder=""
									onChange={(e) => {
										handleChange(e.target.value, "lastName")
									}}
									required
								/>
							</div>
						</div>

						<div className="form-row">
							<label className="w-100 ml-1" htmlFor={"completeAdress"}>
								Adresse complète
							</label>
							<AutoComplete
								className="w-100 pl-1 pr-2"
								popupClassName="dropdown-adress-autocomplete"
								id="completeAdress"
								options={patientAddress}
								onSearch={(value) => handleAutoComplete(value)}
								onChange={(value) => {
									setSelectedAddress(value)
								}}
								value={selectedAddress}
								onSelect={(value: string, option: any): void => {
									handleSelect(value, option)
									setSelectedAddress(`${option.adresse}, ${option.cpo}, ${option.city}`)
								}}
								filterOption={true}
								notFoundContent={
									selectedAddress.length >= 3 &&
									"Adresse non trouvée, veuillez renseigner les champs ci-dessous."
								}>
								<Input style={styleAdresseInput} placeholder="Indiquer l'adresse complète" />
							</AutoComplete>
						</div>
						<div className="form-row">
							<div className="form-group col-md-4">
								<label>Adresse</label>
								<input
									type="text"
									className="form-control"
									placeholder=""
									value={data?.adress || ""}
									onChange={(e) => {
										handleChange(e.target.value, "adress")
										setSelectedAddress(`${e.target.value}, ${data.cpo}, ${data.city}`)
									}}
								/>
							</div>
							<div className="form-group col-md-4">
								<label>Code postal</label>
								<input
									type="text"
									className="form-control"
									placeholder=""
									value={data?.cpo || ""}
									onChange={(e) => {
										handleChange(e.target.value, "cpo")
										setSelectedAddress(`${data.adress}, ${e.target.value}, ${data.city}`)
									}}
								/>
							</div>
							<div className="form-group col-md-4">
								<label>Ville</label>
								<input
									type="text"
									className="form-control"
									placeholder=""
									value={data?.city || ""}
									onChange={(e) => {
										handleChange(e.target.value, "city")
										setSelectedAddress(`${data.adress}, ${data.cpo}, ${e.target.value}`)
									}}
								/>
							</div>
						</div>

						<div className="form-row">
							<DateInput
								label="Date de naissance"
								groupClassName="col-md-6"
								value={data.birthDate != null ? dayjs(data.birthDate) : null}
								onChange={(date) =>
									handleChange(date != null ? formatDateForDB(date) : undefined, "birthDate")
								}
							/>
							<div className="form-group col-md-6">
								<label>Genre *</label> <br />
								<div
									className={"btn-group  btn-block" + (!data.gender ? " border border-warning" : "")}
									role="group"
									onClick={(e) => {
										// @ts-ignore
										handleChange(e.target.innerHTML.toUpperCase(), "gender")
									}}>
									<button
										type="button"
										className={
											"form-control btn btn-secondary " + (data?.gender === "HOMME" && "active")
										}>
										Homme
									</button>
									<button
										type="button"
										className={
											"form-control btn btn-secondary " + (data?.gender === "FEMME" && "active")
										}>
										Femme
									</button>
								</div>
							</div>
						</div>

						<div className="form-row">
							<div className="col-md-6">
								<label>Comment le patient a pris rendez-vous ?</label>
								<Select
									className="form-control w-100 removeantd-class antd-add-padding"
									defaultValue={defaultSource}
									disabled={isAffiliate || isAffiliateManager}
									onSelect={(e: string) => {
										handleChange(e, "patientSource")
									}}>
									{sources?.map((source) => {
										return (
											<Select.Option key={source["@id"]} value={source["@id"]}>
												{" "}
												{source.name}
											</Select.Option>
										)
									})}
								</Select>
							</div>
							<div className="col-md-6">
								<label>Origine</label>
								<Select
									className="form-control w-100 removeantd-class antd-add-padding"
									defaultValue={defaultOrigin}
									disabled={isAffiliate || isAffiliateManager}
									onSelect={(e: number | undefined) => {
										if (!origins || e === undefined) return
										const origin = origins[e]
										handleChange(origin["@id"], "origin")
										setOriginsDetails(origin.patientOriginDetails)
									}}
									options={origins?.map((origin, i) => ({
										value: i,
										label: origin.name,
									}))}
								/>
							</div>
							<div className="col-md-6">
								<label>Sous origine</label>
								<Select
									className="form-control w-100 removeantd-class antd-add-padding"
									onSelect={(e: string) => {
										handleChange(e, "patientOriginDetail")
									}}>
									{originsDetails?.map((originDetail) => {
										return (
											<Select.Option key={originDetail["@id"]} value={originDetail["@id"]}>
												{" "}
												{originDetail.name}
											</Select.Option>
										)
									})}
								</Select>
							</div>
						</div>

						<div className="form-row">
							<div className="col-md-6">
								<CountryInput
									id="country"
									label="Pays"
									value={data.country || "FR"}
									onChange={(value) => handleChange(value, "country")}
								/>
							</div>
							<div className="col-md-6">
								<LanguageInput
									id="language"
									label="Langue"
									value={data.language || "fr"}
									onChange={(value) => handleChange(value, "language")}
								/>
							</div>
						</div>

						<div className="form-row">
							<div className="col-md-4">
								<PhoneInput
									name="phoneMobile"
									label="Téléphone mobile"
									value={data.phoneMobile}
									onChange={(value) => handleChange(value, "phoneMobile")}
									required={true}
								/>
							</div>
							<div className="col-md-4">
								<PhoneInput
									name="phone"
									label="Téléphone fixe"
									value={data.phone}
									onChange={(value) => handleChange(value, "phone")}
								/>
							</div>
							<div className="form-group col-md-4">
								<label>Adresse email</label>
								<input
									type="email"
									className="form-control"
									placeholder="john.doe@mail.com"
									onChange={(e) => {
										handleChange(e.target.value, "email")
									}}
								/>
							</div>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-block btn-outline-danger"
						disabled={saving}
						onClick={() => {
							setModalOpen(false)
						}}>
						Annuler
					</button>
					<button
						type="button"
						className="btn btn-block btn-primary"
						disabled={saving || !data.firstName || !data.lastName || !data.gender || disabledSave}
						onClick={() => {
							createPatient()
						}}>
						Créer le patient
					</button>
				</ModalFooter>
			</Modal>
		</>
	)
}
