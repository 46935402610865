import { useHistory } from "react-router-dom"

export default function DetectMobil({ userAgent, regexPhone }) {
	const history = useHistory()
	const location = window.location
	if (!location.href.includes("/agenda") && regexPhone.test(userAgent)) {
		history.push({
			pathname: "/agenda",
		})
	}
	return <></>
}
