/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useContext } from "react"
import { Select } from "antd"
import API from "../../services/API"
import { AnonymousFunction } from "constants/types/anonymousFunction"
import { Laboratory } from "@audiowizard/common"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import AuthContext from "contexts/AuthContext"
import { toast } from "react-toastify"
import { useQuery } from "react-query"
import { partialSearch } from "services/functions"

const { Option } = Select

type AsyncLaboratorySelectProps = {
	setData?: AnonymousFunction
	data?: any | any[]
	className?: string
	size?: SizeType
	placeholder?: string
	mode?: "multiple" | "tags" | undefined
	disabled?: boolean
	onClear?: AnonymousFunction
	onChange?: AnonymousFunction
	allowClear?: boolean
	warehouseOnly?: boolean
	value?: Laboratory | Laboratory[] | number | undefined | null
	selectedUser?: number
	showAllCompanyLaboratory?: boolean
}

function AsyncLaboratorySelect({
	setData,
	data,
	value,
	className = "",
	size = "middle",
	mode,
	placeholder,
	disabled = false,
	onClear,
	onChange,
	allowClear = true,
	warehouseOnly = false,
	selectedUser = 0,
	showAllCompanyLaboratory = false,
}: AsyncLaboratorySelectProps): JSX.Element {
	const [dataList, setDataList] = useState<any[]>([])
	const [list, setList] = useState<Laboratory[]>([])
	const { user } = useContext(AuthContext)
	const { agendas } = useContext(AuthContext)

	const filters = useMemo(() => {
		let filters = ""

		if (!showAllCompanyLaboratory) filters += `&user=${user.id}`

		return filters
	}, [showAllCompanyLaboratory])

	const { data: laboratories, isFetching: loading } = useQuery(
		"LABORATORY_LIST",
		() => API.findAll<Laboratory[]>("LABORATORIES_API", `/list/?pagination=false${filters}`),
		{
			placeholderData: [],
		}
	)

	useEffect(() => {
		if (!laboratories || !laboratories.length) return

		let tmp = [...laboratories]

		if (warehouseOnly) tmp = tmp.filter((f) => f?.warehouseIdHiboutik)

		populateOptions(tmp)
		setList(tmp)
	}, [laboratories])

	const populateOptions = (data: any): void => {
		const options: any[] = []

		if (mode && ["multiple", "tags"].includes(mode)) {
			options.push({
				value: -1,
				label: "Tout (dé)sélectionner",
			})
		}

		for (const el of data) {
			options.push({
				detail: el,
				value: +el.id,
				label: el.label,
			})
		}

		setDataList(options)
	}

	const placeholderValue = useMemo(() => {
		if (placeholder) return placeholder

		if (mode === "multiple") {
			return "Sélectionner un ou des laboratoires"
		} else {
			return "Sélectionner un laboratoire"
		}
	}, [mode, placeholder])

	const verifyDoctolib = (laboratoryId: number): boolean => {
		const agenda = agendas.find(
			(agenda) =>
				agenda.user?.["@id"]?.split("/")[2] === selectedUser?.toString() &&
				agenda.laboratory?.["@id"]?.split("/")[2] === laboratoryId?.toString()
		)
		return agenda?.doctolibType != null
	}

	return (
		<>
			<Select
				mode={mode}
				allowClear={allowClear}
				filterOption={(searchString, option) => {
					const label = option?.children?.[0] as unknown as string
					if (!label) return true
					return partialSearch(label, searchString)
				}}
				showSearch={true}
				loading={loading}
				disabled={loading || disabled}
				className={className}
				notFoundContent={"Aucun laboratoire"}
				placeholder={placeholderValue}
				size={size}
				maxTagCount={2}
				defaultValue={data}
				value={(function () {
					if (mode === "multiple") {
						return [...(value ?? data ?? [])].map((v) => +v.id)
					} else {
						//@ts-ignore le temps de mettre à jour audiowizard-common
						return value?.id ?? value
					}
				})()}
				onClear={() => {
					setData?.([])
					onChange?.(null, [])
					onClear?.()
				}}
				onDeselect={(laboratoryId: number) => {
					if (!laboratoryId || laboratoryId === -1) return

					if (mode === "multiple" && (Array.isArray(data) || Array.isArray(value))) {
						const selectedLaboratories = [...(value ?? data ?? [])]
						const index = selectedLaboratories.findIndex((laboratory) => +laboratory.id === +laboratoryId)

						if (index !== -1) selectedLaboratories.splice(index, 1)

						setData?.(laboratoryId, selectedLaboratories)
						onChange?.(laboratoryId, selectedLaboratories)
					} else {
						setData?.(laboratoryId, dataList.find((f) => +f.value === +laboratoryId)?.detail ?? {})
						onChange?.(laboratoryId, undefined)
					}
				}}
				onSelect={(laboratoryId: number) => {
					if (laboratoryId === -1) {
						const selectedLaboratories = []

						if (!(value ?? data ?? []).length) {
							for (const laboratory of dataList) {
								if (!laboratory.detail) continue
								selectedLaboratories.push(laboratory.detail)
							}
						}

						onChange?.(-1, selectedLaboratories)
						setData?.(-1, selectedLaboratories)
						return
					}

					if (mode === "multiple") {
						const selectedLaboratories = [...(value ?? data ?? [])]
						const laboratory = list.find((f) => +f.id! === +laboratoryId)

						if (laboratory) selectedLaboratories.push({ ...laboratory })
						onChange?.(laboratoryId, selectedLaboratories)
						setData?.(laboratoryId, selectedLaboratories)
					} else {
						const details = dataList.find((laboratory) => +laboratory.value === +laboratoryId)

						if (!verifyDoctolib(laboratoryId)) {
							onChange?.(laboratoryId, details?.detail)
							setData?.(laboratoryId, details?.detail)
						} else {
							toast.error("Vous ne pouvez pas déplacer un RDV sur un agenda doctolib")
						}
					}
				}}>
				{dataList.map((d) => (
					<Option value={d.value} key={d.value}>
						{d.label}
						{verifyDoctolib(d?.detail?.id) && (
							<img
								alt="doctolib-logo"
								key={d.value}
								src="/static/img/doctolib/D_Doctolib_blue.png"
								style={{ width: 20, height: 20, marginTop: "-3px" }}
							/>
						)}
					</Option>
				))}
			</Select>
		</>
	)
}

export default AsyncLaboratorySelect
