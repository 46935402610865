/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch, SetStateAction, useEffect } from "react"
import API from "services/API"
import { ViewSettings, AgendaFilter } from "./Interfaces"
import { toast } from "react-toastify"
import _ from "lodash"
import { Laboratory, User } from "@audiowizard/common"
/**
 * Loading the saved settings of the agenda from the database.
 * @param user Object of currently connected user
 * @returns Return settings object from PersonnalizationEntity
 */

export const loadAgendaSettings = async (user: any): Promise<any> => {
	try {
		const result: any[] = await API.findAll("PERSONALIZATIONS_API", `?user=${user.id}&type=AGENDA_SETTINGS`)
		return result?.[0] || {}
	} catch (error) {
		console.error(error)
	}
}

/**
 * Function used to compare changes to skip useless saving
 * @param oldSettings
 * @param agendaFilter
 * @param viewSettings
 * @param displayedUsers
 * @param displayedLaboratories
 * @returns true if no changes have been detected
 */
export const compareChanges = (
	oldSettings: any,
	agendaFilter: AgendaFilter,
	viewSettings: ViewSettings,
	displayedUsers: any[],
	displayedLaboratories: any[],
	hideLeftbar: boolean,
	usersOrder: Record<string, number>
): boolean => {
	if (!_.isEqual(agendaFilter, oldSettings?.agendaFilter || {})) {
		return false
	}
	if (!_.isEqual(viewSettings, oldSettings?.viewSettings || {})) {
		return false
	}
	if (!_.isEqual(displayedUsers, oldSettings?.displayedUsers || {})) {
		return false
	}
	if (!_.isEqual(displayedLaboratories, oldSettings?.displayedLaboratories || {})) {
		return false
	}
	if (!_.isEqual(usersOrder, oldSettings?.userOrder || {})) {
		return false
	}
	if (oldSettings.hideLeftbar !== hideLeftbar) {
		return false
	}
	return true
}

/**
 *
 * @param user Object of currently connected user
 * @param agendaFilter  Current filters settings
 * @param viewSettings Current view settings
 * @param displayedUsers Current displayed users
 * @param displayedLaboratories Current displayed laboratories
 */

export const saveAgendaSettings = async (
	user: any,
	viewSettings: ViewSettings,
	agendaFilter: AgendaFilter,
	displayedUsers: User[],
	displayedLaboratories: any[],
	hideLeftbar: boolean,
	setSettingsSaved: (s: boolean) => void,
	usersOrder: Record<string, number>
): Promise<void> => {
	setSettingsSaved(false)
	const oldSettings = await loadAgendaSettings(user)
	const settingsLaboratory = displayedLaboratories.map((laboratory: any) => ({
		label: laboratory.name,
		id: laboratory.value,
		"@id": `/laboratory/${laboratory.value}`,
	}))
	const settingsUsers = displayedUsers.map((user) => _.pick(user, ["@id", "id", "firstName", "lastName"]))
	if (
		compareChanges(
			oldSettings?.settings,
			agendaFilter,
			viewSettings,
			settingsUsers,
			settingsLaboratory,
			hideLeftbar,
			usersOrder
		)
	) {
		setSettingsSaved(true)
		return
	}
	try {
		const settings = {
			agendaFilter,
			viewSettings: {
				cellDuration: viewSettings.cellDuration,
				viewMode: viewSettings.viewMode,
				viewWidth: viewSettings.viewWidth,
				hourHeight: viewSettings.hourHeight,
			},
			displayedUsers: settingsUsers,
			displayedLaboratories: settingsLaboratory,
			hideLeftbar,
			usersOrder,
		}
		if (oldSettings?.id) {
			await API.update("PERSONALIZATIONS_API", oldSettings.id, { settings })
		} else {
			await API.create("PERSONALIZATIONS_API", { settings, type: "AGENDA_SETTINGS" })
		}
		toast("Préférence enregistrées", {
			position: "bottom-left",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			className: "toastify-small-toast",
		})
	} catch (error) {
		console.error("saveAgendaSettings", error)
	} finally {
		setSettingsSaved(true)
	}
}

/**
 * Load the Agenda setting from the database
 * @param user Object of currently connected user
 * @param agendaFilter  Current filters settings
 * @param viewSettings Current view settings
 * @param displayedUsers Current displayed users
 * @param displayedLaboratories Current displayed laboratories
 * @param enableSaving Enable or disable loading of the selected users/laboratory.
 * 						Should be false if openning the Agenda from another view than the global Agenda
 */
export const useAgendaSettingLoader = (
	user: any,
	shouldLoadSetting: boolean,
	setViewSettings: any,
	setAgendaFilter: any,
	setDisplayedUsers: any,
	setUsersOrder: Dispatch<SetStateAction<Record<string, number> | undefined>>,
	setDisplayedLaboratories: any,
	setSettingsLoaded: Function,
	setHideLeftbar: Function,
	fromDashboard = false,
	resources: User[]
): void => {
	useEffect(() => {
		if (!resources) return
		let doNotUpdate = false
		if (fromDashboard) {
			setHideLeftbar(true)
			setTimeout(() => {
				setSettingsLoaded(true)
			}, 30)
			return
		}
		if (!shouldLoadSetting) {
			setHideLeftbar(true)
			setViewSettings((old: any) => ({
				...old,
				viewMode: "weekResources",
			}))

			setTimeout(() => {
				setSettingsLoaded(true)
			}, 30)
			return
		}
		loadAgendaSettings(user)
			.then((result) => {
				if (doNotUpdate) {
					return
				}
				const settings = result?.settings || null

				if (!settings) {
					setDisplayedUsers([user["@id"]])
					setDisplayedLaboratories(user.laboratories.map((l: Laboratory) => l.id))
					setSettingsLoaded(true)
					return
				}

				setAgendaFilter({ ...settings.agendaFilter })
				setViewSettings((old: any) => ({
					...old,
					cellDuration: settings?.viewSettings?.cellDuration || 30,
					viewMode: settings?.viewSettings?.viewMode || "Week",
					viewWidth: settings?.viewSettings?.viewWidth || 80,
					hourHeight: settings?.viewSettings?.hourHeight || 100,
				}))
				if (settings.displayedUsers && settings.displayedUsers.length > 0 && settings.displayedUsers[0].id) {
					settings.displayedUsers = settings.displayedUsers.map((user: User) => user["@id"])
				}
				if (
					settings.displayedLaboratories &&
					settings.displayedLaboratories.length > 0 &&
					settings.displayedLaboratories[0].id
				) {
					settings.displayedLaboratories = settings.displayedLaboratories.map(
						(laboratory: Laboratory) => laboratory.id
					)
				}
				setDisplayedUsers([...(settings.displayedUsers || [])])
				setDisplayedLaboratories([...(settings.displayedLaboratories || [])])

				const savedUserConfig = settings.usersOrder || {}

				setUsersOrder((usersOrder) => {
					if (!usersOrder) return usersOrder
					const users = Object.keys(usersOrder)
					users.sort((userA, userB) => usersOrder[userA] - usersOrder[userB])
					let i = Object.keys(savedUserConfig).length
					for (const user in users) {
						if (savedUserConfig[users[user]]) {
							usersOrder[users[user]] = savedUserConfig[users[user]]
						} else {
							usersOrder[users[user]] = i++
						}
					}
					return usersOrder
				})

				setHideLeftbar(settings.hideLeftbar)
				// needed to wait that all the states are up to date
				setTimeout(() => {
					setSettingsLoaded(true)
				}, 30)
			})
			.catch((err) => {
				toast("Problème lors du chargement de la personnalisation")
				console.log(err)
			})
		return () => {
			doNotUpdate = true
		}
	}, [resources])
}
