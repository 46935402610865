import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { QuotePack } from "@audiowizard/common"
import { Button } from "antd"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import PdfViewer from "components/utils/PdfViewer"
import _ from "lodash"
import DocumentScanner from "pages/fiche-patient/Documents/DocumentScanner"
import { useEffect, useMemo, useState } from "react"
import Dropzone from "react-dropzone"
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query"
import { toast } from "react-toastify"
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import API from "services/API"
import { detectBrowserOS, OS } from "services/Utilities"

export type QuotePacksAnnex = {
	"@id": string
	"@type": string
	id: number
	label: string
	quotePacks: string[]
	url: string
}

type NewQuotePackAnnex = {
	file: File | null
	label: string
	quotePacks: string[]
}

function useAllAnnexesQuery(): UseQueryResult<QuotePacksAnnex[]> {
	return useQuery<QuotePacksAnnex[]>(
		"QUOTE_PACKS_ANNEXES_API",
		async () => {
			let page = 1
			let totalItems = Infinity
			const annexes: QuotePacksAnnex[] = []

			while (annexes.length < totalItems) {
				const res = await API.findAll<QuotePacksAnnex[]>("QUOTE_PACKS_ANNEXES_API", `?page=${page}`, true)
				page++
				totalItems = res["hydra:totalItems"]
				annexes.push(...res["hydra:member"])
			}
			return annexes
		},
		{
			staleTime: Infinity, // to avoid re-fetching twice
		}
	)
}

type AnnexListItemProps = {
	annex: QuotePacksAnnex
}
function AnnexListItem({ annex }: AnnexListItemProps): JSX.Element {
	const queryClient = useQueryClient()

	const { mutate: deleteAnnex, isLoading } = useMutation(async () => await API.delete(annex["@id"]), {
		onSuccess: async () => {
			await queryClient.invalidateQueries("QUOTE_PACKS_API")
			await queryClient.invalidateQueries("QUOTE_PACKS_ANNEXES_API")
			toast.success("Annexe supprimée avec succès")
		},
		onError: () => {
			toast.error("Erreur lors de la suppression de l'annexe")
		},
	})

	const deleteAnnexWithConfirm = async (): Promise<void> => {
		const hasConfirmed = await confirmWithModal({ title: `Supprimer l'annexe ${annex?.label} ?` })
		if (!hasConfirmed) return

		deleteAnnex()
	}

	return (
		<Row className="my-1 py-1 border-bottom" tag="li">
			<Col md={10} className="text-break">
				{annex.label}
			</Col>
			<Col md={2}>
				<Button danger icon={<DeleteOutlined />} loading={isLoading} onClick={deleteAnnexWithConfirm} />
			</Col>
		</Row>
	)
}

function AnnexList(): JSX.Element {
	const { data: annexes } = useAllAnnexesQuery()

	return (
		<div className="d-flex flex-column align-items-center">
			<h3>Liste des annexes</h3>

			<ul className="list-unstyled" style={{ width: "70%" }}>
				{annexes?.map((annex) => (
					<AnnexListItem key={annex["@id"]} annex={annex} />
				))}
			</ul>
		</div>
	)
}

function NewAnnexForm(): JSX.Element {
	const queryClient = useQueryClient()
	const os = useMemo(detectBrowserOS, [])
	const [annexLabel, setAnnexLabel] = useState<string>("")
	const [file, setFile] = useState<File | null>(null)
	const [fileUrl, setFileUrl] = useState<string | null>(null)

	const { mutate: createQuotePackAnnex, isLoading } = useMutation(
		async (data: NewQuotePackAnnex) => {
			// data as any => in any case data.file is not null when creating annex
			if (data.file != null) await API.createQuotePackAnnex(data as any)
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("QUOTE_PACKS_API")
				await queryClient.invalidateQueries("QUOTE_PACKS_ANNEXES_API")
				setFile(null)
				setAnnexLabel("")
			},
			onError: () => {
				toast.error(`Erreur d'enregistrement de l'annexe ${annexLabel}`)
			},
		}
	)

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
		event.preventDefault()
		createQuotePackAnnex({
			file: file,
			label: annexLabel,
			quotePacks: [],
		})
	}

	useEffect(() => {
		if (file == null) return

		const fileUrl = URL.createObjectURL(file)
		setFileUrl(fileUrl)
		setAnnexLabel(file.name ?? "")

		return () => {
			URL.revokeObjectURL(fileUrl)
			setFileUrl(null)
		}
	}, [file])

	return (
		<>
			<h3 className="mt-4 d-flex justify-content-center">Ajouter une nouvelle annexe</h3>
			<form onSubmit={handleSubmit}>
				<div className="modal-documentuploader-droppreview">
					<div className="modal-documentuploader-dropzone">
						<Dropzone accept="application/pdf" onDrop={(files) => setFile(files[0])}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div {...getRootProps()} className="dropzone my-3">
										<input {...getInputProps()} />
										<p className="text-center">
											Glisser-déposer un fichier ou cliquer ici
											<br /> format supporté : .pdf
										</p>
									</div>
								</section>
							)}
						</Dropzone>
					</div>
					<div className="modal-documentuploader-preview">
						{fileUrl != null && <PdfViewer src={fileUrl} title="Annexe" />}
					</div>
				</div>

				{/* Le scanner ne marche qu'avec windows pour le moment*/}
				{os === OS.Windows && (
					<DocumentScanner
						onScanDone={(blob) => setFile(new File([blob], "annexe.pdf", { type: "application/pdf" }))}
					/>
				)}
				<h6 className="mt-3 mb-1">Changer le nom du fichier (optionnel) </h6>
				<input
					type="text"
					maxLength={150}
					name="label"
					className="form-control"
					value={annexLabel}
					placeholder="(Optionnel)"
					onChange={(e) => setAnnexLabel(e.target.value)}
				/>
				<button
					type="submit"
					className="btn btn-primary btn-block mt-3"
					disabled={isLoading || file == null || annexLabel == null || annexLabel.length === 0}>
					Enregistrer l'annexe
				</button>
			</form>
		</>
	)
}

type AnnexPickerProps = {
	quotePack?: QuotePack | null
	onSave: () => void
}
function AnnexPicker({ quotePack, onSave }: AnnexPickerProps): JSX.Element {
	const queryClient = useQueryClient()
	const [selectedAnnexeIris, setSelectedAnnexeIris] = useState<string[]>([])

	const { data: annexes } = useAllAnnexesQuery()

	const unSelectAnnex = (iri: string): void => {
		const newSelectedAnnexes = selectedAnnexeIris.filter((annexIri) => annexIri !== iri)
		setSelectedAnnexeIris(newSelectedAnnexes)
	}

	const selectAnnex = (iri: string): void => {
		const newSelectedAnnexes = [...selectedAnnexeIris, iri]
		setSelectedAnnexeIris(newSelectedAnnexes)
	}

	const { mutate: saveAnnexesForQuotePack, isLoading } = useMutation(
		async () => {
			await API.update("QUOTE_PACKS_API", quotePack!.id!, { quotePackAnnexes: selectedAnnexeIris })
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("QUOTE_PACKS_API")
				await queryClient.invalidateQueries("QUOTE_PACKS_ANNEXES_API")
				toast.success("Annexe(s) ajoutée(s) avec succès")

				onSave()
			},
			onError: () => {
				toast.error(`Erreur lors de la mise à jour du pack ${quotePack?.label}`)
			},
		}
	)

	useEffect(() => {
		if (quotePack == null || annexes == null) return

		const newSelectedAnnexes = annexes.filter((a) => a.quotePacks.includes(quotePack["@id"])).map((a) => a["@id"])
		setSelectedAnnexeIris(newSelectedAnnexes)
	}, [annexes, fetch])

	const [allSelected, allUnselected] = useMemo(
		() => _.partition(annexes, (a) => selectedAnnexeIris.includes(a["@id"])),
		[annexes, selectedAnnexeIris]
	)

	return (
		<div>
			<h3 className="d-flex justify-content-center mt-2 mb-4 ">Listes des annexes du pack {quotePack?.label}</h3>
			<div className="d-flex flex-row justify-content-between px-4">
				<ul id="selected" className="list-unstyled w-50">
					<h5>Sélectionnées</h5>
					{allSelected.map((annex) => (
						<Row key={annex["@id"]} tag="li">
							<Col md={10} className="d-flex justify-content-center align-items-center">
								<h6 className="text-primary text-break pt-2 mr-2" style={{ maxWidth: "80ch" }}>
									{annex.label}
								</h6>
							</Col>
							<Col md={2} className="d-flex justify-content-center align-items-center">
								<Button danger icon={<MinusOutlined />} onClick={() => unSelectAnnex(annex["@id"])} />
							</Col>
						</Row>
					))}
				</ul>
				<ul id="unSelected" className="list-unstyled w-50">
					<h5>Non sélectionnées</h5>

					{allUnselected.map((annex) => (
						<Row key={annex["@id"]} tag="li">
							<Col md={10} className="d-flex justify-content-center align-items-center">
								<h6 className="text-info text-break pt-2 mr-2" style={{ maxWidth: "80ch" }}>
									{annex.label}
								</h6>
							</Col>
							<Col md={2} className="d-flex justify-content-center align-items-center">
								<Button
									className="text-primary border-primary"
									icon={<PlusOutlined />}
									onClick={() => selectAnnex(annex["@id"])}
								/>
							</Col>
						</Row>
					))}
				</ul>
			</div>
			<div>
				<button
					type="button"
					className="btn btn-primary btn-block mt-3"
					disabled={isLoading}
					onClick={() => saveAnnexesForQuotePack()}>
					Enregistrer les modifications
				</button>
			</div>
		</div>
	)
}

type AnnexesPickerModalProps = {
	open: boolean
	onClose: () => void
	quotePack?: QuotePack | null
	openAnnexList: () => void
}
export function AnnexesPickerModal({ open, onClose, quotePack, openAnnexList }: AnnexesPickerModalProps): JSX.Element {
	return (
		<Modal isOpen={open} toggle={onClose} centered size="lg">
			<ModalHeader>
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-files-medical" /> {/* <-- ICON */}
					</div>

					<h6 className="mb-0">Éditer les annexes</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 d-flex justify-content-center align-items-center close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>

			<ModalBody>
				<button
					className="btn btn-primary btn-block"
					onClick={() => {
						onClose()
						openAnnexList()
					}}>
					<i className="fad fa-files-medical" />
					&nbsp;Gestion des annexes
				</button>

				<AnnexPicker quotePack={quotePack} onSave={onClose} />
			</ModalBody>
		</Modal>
	)
}

type AnnexesListModalProps = {
	open: boolean
	onClose: () => void
}
export function AnnexesListModal({ open, onClose }: AnnexesListModalProps): JSX.Element {
	return (
		<Modal isOpen={open} toggle={onClose} centered size="lg">
			<ModalHeader>
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-files-medical" /> {/* <-- ICON */}
					</div>

					<h6 className="mb-0">Gestion des annexes</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 d-flex justify-content-center align-items-center close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>

			<ModalBody>
				<AnnexList />
				<NewAnnexForm />
			</ModalBody>
		</Modal>
	)
}
