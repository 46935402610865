import { useContext, useMemo } from "react"
import { Select, Tag, Tooltip } from "antd"
import uuid from "react-uuid"
import AuthContext from "contexts/AuthContext"

const { Option } = Select

const SecondaryStatusCol = (isApt, scheduleTypes, handlePatientChange, editingKey, allTags, filterSS, queryfilters) => {
	const SecondaryStatusSelect = ({ secondaryStatus, record }) => {
		const { companySettings } = useContext(AuthContext)

		const scheduleType = useMemo(() => {
			const status = record["patient.nextSchedule"]?.status ?? record["patient.nextSchedule"] // sometimes an object, sometimes status directly as string. Fun.

			return scheduleTypes && status && scheduleTypes[status] ? scheduleTypes[status] : {}
		}, [scheduleTypes, record["patient.nextSchedule"]])
		const options = useMemo(() => {
			const tags = allTags ?? []

			// if filterTagsByScheduleStatus, only show tags that are assigned to patient's main schedule status
			if (companySettings.filterTagsByScheduleStatus) {
				return tags.filter((item) => {
					const allowedStatuses = filterSS[scheduleType?.customLabel || scheduleType?.label]
					return allowedStatuses?.includes(item.text)
				})
			} else {
				return tags
			}
		}, [companySettings.filterTagsByScheduleStatus, allTags, filterSS, scheduleType])

		return (
			<Tooltip
				overlayInnerStyle={{ width: "max-content" }}
				overlayStyle={{ whiteSpace: "break-spaces", width: "max-content", borderRadius: "5px" }}
				title={
					<div style={{ color: "black" }}>
						{record["patient.secondaryStatus"] && record["patient.secondaryStatus"].length > 0 ? (
							record["patient.secondaryStatus"].map((a, i, { length }) =>
								i + 1 !== length ? a + "\n" : a
							)
						) : (
							<></>
						)}
					</div>
				}
				trigger={
					editingKey !== record.key &&
					record["patient.secondaryStatus"] &&
					record["patient.secondaryStatus"].length > 0
						? "hover"
						: "none"
				}
				color="lightgrey">
				<Select
					mode={"multiple"}
					style={{ width: "250px" }}
					maxTagCount="responsive"
					placeholder={isApt ? "Sélectionner statut" : "Sélectionner tag"}
					defaultValue={secondaryStatus}
					onChange={(value) => handlePatientChange(value, record, "patient.secondaryStatus")}>
					{options.map((opt, index) => (
						<Option key={`${opt.text} ${index}`} value={opt.text}>
							{opt.text}
						</Option>
					))}
				</Select>
			</Tooltip>
		)
	}

	// Add "Aucun" filter
	const filters = [...(allTags ?? [])]
	filters.unshift({ "@id": "aucun", text: "Aucun", id: "null", scheduleTypes: [] })

	return {
		title: null,
		key: "secondaryStatus",
		dataIndex: "patient.secondaryStatus",
		width: "180px",
		filters: filters.map((s) => ({
			text: s.text,
			value: s.id,
		})),
		filteredValue: queryfilters?.secondaryStatus || null,
		filterSearch: true,
		render: (secondaryStatuses, record) => {
			if (editingKey === record.key)
				return <SecondaryStatusSelect secondaryStatus={secondaryStatuses} record={record} />
			if (!secondaryStatuses?.length) return <Tag>Aucun</Tag>
			return secondaryStatuses?.map((s) => <Tag key={s}>{s}</Tag>) // problème si on a plusieurs tags avec le même nom, key n’est pas unique
		},
		validate: (cond) =>
			!cond["patient.secondaryStatus"] || cond["patient.secondaryStatus"].length === 0
				? `Veuillez choisir au moins un ${isApt ? "statuts rendez-vous" : "tag"}.`
				: "",
	}
}

export default SecondaryStatusCol
