/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartPie from "./ChartPie"

const Attentes = () => {
	const [rendezVous, setRendezVous] = useState([])
	const [loading, setLoading] = useState(true)
	const labels = ["Première visite", "Suite visite", "Essai", "Livraison", "Contrôle"]
	const lbHashmap = ["VISITE", "SUITE_VISITE", "ESSAI", "ADAPTATION", "APPAREILLE"]

	const getRendezVous = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/rendez_vous")
			const data = [0, 0, 0, 0, 0]
			if (!result?.data?.message?.length) return setLoading(false)
			result?.data?.message.forEach((e) => {
				if (lbHashmap.includes(e.status)) data[lbHashmap.indexOf(e.status)] = parseInt(e.Total)
				else if (e.status === "ESSAIE") data[lbHashmap.indexOf("ESSAI")] = parseInt(e.Total)
			})
			setRendezVous(data)
			setLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getRendezVous()
	}, [])

	return (
		<>
			<h5 className="mb-4">Type de rendez-vous</h5>
			<ChartPie series={rendezVous} labels={labels} />
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
