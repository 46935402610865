import { Patient } from "@audiowizard/common"
import { findActiveAMx, findActiveAm } from "pages/FSV/utilsFSV"
import { useEffect, useState } from "react"

type tmpTeletransData = {
	secu: boolean
	mutl: boolean
	disabledSecu: boolean
	secuTierPayant: boolean
	mutlTierPayant: boolean
}

export function useGetTpSwitchStatus(
	tmpTeletransData: tmpTeletransData,
	selectedPatient: Patient,
	closedSaleDocument: any
): boolean[] | null[] {
	const [secuTierPayant, setSecuTierPayant] = useState(tmpTeletransData.secuTierPayant)
	const [mutlTierPayant, setMutlTierPayant] = useState(tmpTeletransData.mutlTierPayant)
	const isPregnant = useGetIsPregnant(selectedPatient)

	useEffect(() => {
		if (!tmpTeletransData.mutl && mutlTierPayant) setMutlTierPayant(false)
		else if (tmpTeletransData.mutl && !mutlTierPayant) setMutlTierPayant(true)

		if (tmpTeletransData.secuTierPayant || +closedSaleDocument?.config?.amount?.partSecu > 0 || isPregnant)
			setSecuTierPayant(true)
		else if (!tmpTeletransData.secuTierPayant || tmpTeletransData.disabledSecu) setSecuTierPayant(false)
	}, [tmpTeletransData, isPregnant, closedSaleDocument])

	if (!selectedPatient) return [null, null]
	return [secuTierPayant, mutlTierPayant]
}

export function useGetIsPregnant(selectedPatient: Patient): boolean | null {
	const [isPregnant, setIsPregnant] = useState<boolean>(false)

	useEffect(() => {
		getPregnancyStatus()
	}, [selectedPatient])

	const getPregnancyStatus = async (): Promise<void> => {
		if (!selectedPatient) {
			setIsPregnant(false)
			return
		}
		if (selectedPatient?.patientSocialSecurity?.length !== 0) {
			// @ts-ignore
			setIsPregnant(!!findActiveAm(selectedPatient)?.pregnancyDate)
		}
		// @ts-ignore
		setIsPregnant(!!(await findActiveAMx(selectedPatient))?.amo?.pregnancyDate)
	}

	return isPregnant
}
