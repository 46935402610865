import { Col, Form, Row } from "antd"
import { formatCurrency } from "services/functions"
import { formatTva } from "../caisse.helpers"
import { CashOutflowVat } from "../moneyItems"
import "./TaxLabelHistorique.scss"

interface TaxLabelHistoriqueProps {
	tax: CashOutflowVat
}

interface TaxLabelFieldProps {
	label: string
	value?: number
}

const TaxLabelField = ({ label, value }: TaxLabelFieldProps): JSX.Element => {
	return (
		<Form.Item
			label={label}
			style={{
				margin: 0,
			}}>
			<div className="ant-form-text calc-tax">{formatCurrency(value || 0)}</div>
		</Form.Item>
	)
}

const TaxLabelHistorique = ({ tax }: TaxLabelHistoriqueProps): JSX.Element => {
	return (
		<Col flex={1} className={"col-historique-tax-accounting"}>
			<Row>
				<Col span={24} className="text-center" style={{ fontWeight: "bold" }}>
					<span className={"header-tax"}>TVA {formatTva(tax.taxRate)}%</span>
				</Col>
			</Row>
			<TaxLabelField label={"HT :"} value={tax.taxFreeAmount} />
			<TaxLabelField label={"TVA :"} value={tax.vatAmount} />
			<TaxLabelField label={"TTC :"} value={tax.allTaxIncludeAmount} />
		</Col>
	)
}

export default TaxLabelHistorique
