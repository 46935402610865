import {
	CoverageDemandState,
	UserInsuranceCoverageDemandModel,
} from "../../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"

/**
 * Get next available states by network as Datamut doesn't send them on their API
 */
export default function getCoverageAvailableActions(network: string, actualState?: string) {
	if (!actualState) return []

	const onError = [StateObject.suppr_PEC, StateObject.suppr_erreur]
	const onBankIssued = [StateObject.solde]
	const onValidated = [StateObject.facture, StateObject.annul]

	const base: Record<string, AvailableActionInterface[]> = {
		[CoverageDemandState.BANK_TRANSFER_ISSUED]: onBankIssued,
		[CoverageDemandState.FUNCTIONAL_ERROR]: onError,
	}

	const kalixia: Record<string, AvailableActionInterface[]> = {
		[CoverageDemandState.WAITING_TRANSFORM]: [StateObject.transform],
		[CoverageDemandState.WAITING_NETWORK]: [StateObject.verif],
		[CoverageDemandState.VALIDATED]: onValidated,
		...base,
	}

	const itelis: Record<string, AvailableActionInterface[]> = {
		[CoverageDemandState.WAITING_NETWORK]: [StateObject.verif, StateObject.annul],
		[CoverageDemandState.VALIDATED]: onValidated,
		...base,
	}

	const carteBlanche: Record<string, AvailableActionInterface[]> = {
		[CoverageDemandState.WAITING_NETWORK]: [StateObject.verif],
		[CoverageDemandState.VALIDATED]: onValidated,
		...base,
	}

	const iSante: Record<string, AvailableActionInterface[]> = {
		[CoverageDemandState.WAITING_TRANSFORM]: [StateObject.transform],
		...base,
	}

	const seveane: Record<string, AvailableActionInterface[]> = {
		...base,
		[CoverageDemandState.VALIDATED]: onValidated,
	}

	switch (network) {
		case "VIAMEDIS":
		case "OXANTIS":
		case "SOLIMUT":
		case "EOVI":
		case "ADREA":
			return kalixia[actualState]
		case "ITELIS":
		case "TPPLUS":
		case "SANTECLAIR":
			return itelis[actualState]
		case "ISANTE":
			return iSante[actualState]
		case "ALMERYS":
			return base[actualState]
		case "SEVEANE":
			return seveane[actualState]
		case "CARTE BLANCHE":
			return carteBlanche[actualState]
		default:
			return []
	}
}

export interface AvailableActionInterface {
	label: string
	value: string
	color?: string
	side?: string
	condition?: (item: UserInsuranceCoverageDemandModel) => boolean
	conditionNotMetMessage?: string
}

const StateObject = {
	suppr_PEC: {
		label: "Supprimer PEC",
		value: "suppr_PEC",
		color: "danger-outlined",
		side: "left",
	},
	suppr_erreur: {
		label: "Supprimer erreur",
		value: "suppr_erreur",
		color: "danger-outlined",
		side: "left",
	},
	solde: {
		label: "Solder",
		value: "solde",
	},
	facture: {
		label: "Facturer réseau",
		value: "facture",
	},
	annul: {
		label: "Annuler",
		value: "annul",
		color: "danger-outlined",
		side: "left",
	},
	verif: {
		label: "Vérifier",
		value: "verif",
	},
	transform: {
		label: "Déclencher la demande de PEC",
		value: "transfo",
	},
}
