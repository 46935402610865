/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Override } from "@audiowizard/common"
import { Switch } from "antd"
import cx from "classnames"
import React, { ReactNode } from "react"

type InputGroup = {
	prepend?: { text: string } | { icon: string }
	append?: { text: string } | { icon: string }
}
function hasIcon(value: { text: string } | { icon: string }): value is { icon: string } {
	return (value as any).icon != null
}

type InputGroupWrapperProps = React.PropsWithChildren<{
	inputGroup?: InputGroup
	errorAndHelpTextElement: ReactNode
}>
const InputGroupWrapper = ({ inputGroup, errorAndHelpTextElement, children }: InputGroupWrapperProps): JSX.Element => {
	return inputGroup != null ? (
		<div className="input-group">
			{inputGroup.prepend != null && (
				<div className="input-group-prepend">
					{hasIcon(inputGroup.prepend) ? (
						<InputGroupIcon icon={inputGroup.prepend.icon} />
					) : (
						<InputGroupText text={inputGroup.prepend.text} />
					)}
				</div>
			)}

			{children}

			{inputGroup.append != null && (
				<div className="input-group-append">
					{hasIcon(inputGroup.append) ? (
						<InputGroupIcon icon={(inputGroup.append as { icon: string }).icon} />
					) : (
						<InputGroupText text={(inputGroup.append as { text: string }).text} />
					)}
				</div>
			)}

			{errorAndHelpTextElement}
		</div>
	) : (
		<>
			{children}
			{errorAndHelpTextElement}
		</>
	)
}

const InputGroupText = ({ text }: { text: string }): JSX.Element => <span className="input-group-text">{text}</span>
const InputGroupIcon = ({ icon }: { icon: string }): JSX.Element => (
	<span className="input-group-text input-group-icon">
		<i className={`fad fa-${icon}`} />
	</span>
)

type FieldWithErrorProps = Override<
	React.ComponentPropsWithoutRef<"input">,
	{
		id?: string
		name: string
		value?: string | number
		label?: string
		placeholder?: string
		error?: string
		helpText?: string
		className?: string
		size?: "sm" | "lg"
		labelClassName?: string
		setValue?: (value: string) => void
		setError?: (value: string) => void
		isDisabled?: boolean
		readOnly?: boolean
		inputGroup?: InputGroup
		dataCy?: string
		invalidMessage?: string
		inputStyle?: string
		toggle?: boolean
		isChecked?: boolean
		setIsChecked?: (arg: boolean) => void
	}
>

function FieldWithError(
	{
		id = "",
		name,
		label = "",
		value = "",
		onChange,
		placeholder = "",
		type = "text",
		pattern,
		error = "",
		helpText,
		className = "",
		labelClassName = "",
		size,
		required = false,
		min,
		max,
		minLength,
		maxLength,
		dataCy,
		setValue,
		setError,
		isDisabled = false,
		readOnly = false,
		invalidMessage,
		step,
		autoComplete,
		onBlur,
		inputGroup,
		inputStyle,
		toggle,
		isChecked,
		setIsChecked,
		...props
	}: FieldWithErrorProps,
	ref: React.Ref<HTMLInputElement>
): JSX.Element {
	const inputId = id || name

	const emptyOnFocus = (): void => {
		if (error) {
			if (typeof setError === "function") setError("")
			if (typeof setValue === "function") setValue("")
		}
	}

	const toggleElement = (
		<Switch
			size="small"
			checked={isChecked}
			onChange={setIsChecked}
			checkedChildren={<CheckOutlined style={{ display: "block" }} />}
			unCheckedChildren={<CloseOutlined style={{ display: "block" }} />}
		/>
	)

	const labelElement = (
		<label htmlFor={name} className={cx(labelClassName, type === "file" ? "custom-file-label" : "")}>
			{label || placeholder}
		</label>
	)

	const labelWithToggleElement = (
		<div>
			<label htmlFor={name} className={cx(labelClassName, type === "file" ? "custom-file-label mr-2" : "mr-2")}>
				{label || placeholder}
			</label>
			{toggleElement}
		</div>
	)

	const inputElement = (
		<input
			ref={ref}
			value={value == null ? "" : value}
			onChange={onChange}
			type={type}
			placeholder={placeholder || label}
			name={name}
			data-cy={dataCy}
			id={inputId}
			className={cx(type === "file" ? "custom-file-input" : "form-control", {
				"is-invalid": error,
				"form-control-sm": size === "sm",
				"form-control-lg": size === "lg",
			})}
			required={!!required}
			min={min}
			max={max}
			step={step}
			minLength={minLength}
			maxLength={maxLength}
			pattern={pattern ? pattern : undefined}
			onFocus={(e) => {
				emptyOnFocus()
				e.target.setCustomValidity("")
			}}
			onInvalid={(e) => {
				if (invalidMessage) (e.target as HTMLInputElement).setCustomValidity(invalidMessage)
			}}
			onInput={(e) => {
				;(e.target as HTMLInputElement).setCustomValidity("")
			}}
			disabled={isDisabled || false}
			readOnly={readOnly || false}
			autoComplete={autoComplete}
			onBlur={(e) => typeof onBlur === "function" && onBlur(e)}
			lang="fr"
			aria-describedby={helpText != null ? `${inputId}-help-text` : undefined} // pour helpText
			{...props}
		/>
	)

	const errorAndHelpTextElement = (
		<>
			{error && <p className="invalid-feedback">{error}</p>}
			{helpText && (
				<small id={`${inputId}-help-text`} className="form-text text-muted">
					{helpText}
				</small>
			)}
		</>
	)

	return (
		<div className={`w-100 form-group ${className}`}>
			{type !== "file" && (toggle ? labelWithToggleElement : labelElement)}
			{/* Englobe l'input avec un input-group si il faut mettre un prepend ou un append*/}
			<InputGroupWrapper inputGroup={inputGroup} errorAndHelpTextElement={errorAndHelpTextElement}>
				{type !== "file" ? (
					inputElement
				) : (
					<div className="custom-file">
						{labelElement}
						{inputElement}
					</div>
				)}
			</InputGroupWrapper>
		</div>
	)
}

export default React.forwardRef(FieldWithError)
