import {
	HiboutikBrand,
	HiboutikCategory,
	HiboutikProductEntity,
	HiboutikSupplier,
	Laboratory,
	Patient,
	Prescriber,
} from "@audiowizard/common"
import _ from "lodash"
import { HiboutikTag } from "pages/feuille-soin/types"
import { useQuery, UseQueryResult } from "react-query"
import API from "services/API"
import API_Hiboutik from "services/API_Hiboutik"

// Hiboutik
export function useBrandsQuery(): UseQueryResult<HiboutikBrand[]> {
	return useQuery<HiboutikBrand[]>("API_Hiboutik.getBrands", async () => await API_Hiboutik.getBrands(), {
		staleTime: Infinity,
		select: (data) => _.sortBy(data, "brand_name"),
	})
}

export function useSuppliersQuery(): UseQueryResult<HiboutikSupplier[]> {
	return useQuery<HiboutikSupplier[]>("API_Hiboutik.getSuppliers", async () => await API_Hiboutik.getSuppliers(), {
		staleTime: Infinity,
		select: (data) => _.sortBy(data, "supplier_name"),
	})
}

export function useCategoriesQuery(): UseQueryResult<HiboutikCategory[]> {
	return useQuery<HiboutikCategory[]>(
		"API_Hiboutik.getAllCategories",
		async () => await API_Hiboutik.getAllCategories(),
		{
			staleTime: Infinity,
			select: (data) => _.sortBy(data, "category_name"),
		}
	)
}

export function useAllProductsQuery(): UseQueryResult<HiboutikProductEntity[]> {
	return useQuery<HiboutikProductEntity[]>(
		"API_Hiboutik.getAllProducts",
		async () => await API.findAll("PRODUCTS_API", "?pagination=false"),
		{
			staleTime: Infinity,
			select: (data) => _.sortBy(data, "category_name"),
		}
	)
}

export function useTagsQuery(): UseQueryResult<HiboutikTag[]> {
	return useQuery<HiboutikTag[]>("API_Hiboutik.getAllTags", async () => await API_Hiboutik.getAllTags(), {
		staleTime: Infinity,
	})
}

// AW
export function useAllLaboratoriesQuery(): UseQueryResult<Laboratory[]> {
	return useQuery(
		["LABORATORIES_API", { pagination: false }],
		async () => await API.findAll<Laboratory[]>("LABORATORIES_API", "?pagination=false"),
		{
			staleTime: Infinity,
			select: (data) => _.sortBy(data, "label"),
		}
	)
}

export function useAllPrescribersQuery(): UseQueryResult<Prescriber[]> {
	return useQuery(
		["PRESCRIBERS_API", "?pagination=false"],
		async () => await API.findAll<Prescriber[]>("PRESCRIBERS_API", "?pagination=false"),
		{
			staleTime: Infinity,
		}
	)
}
