import {
	CoverageDemandStateInterface,
	UserInsuranceCoverageDemandFormModel,
	UserInsuranceCoverageDemandModel,
} from "./userInsuranceCoverageDemandService.model"
import API from "../../../API"
import { USER_INSURANCE_COVERAGE_DEMAND, USER_INSURANCE_COVERAGE_DEMAND_STATE } from "../../../../config"
import convertParams from "../../../../utils/convertParams"

export const userInsuranceCoverageDemandBase = "/user_insurance_coverage_demands"
export const userInsuranceCoverageDemandServiceKey = "UserInsuranceCoverageDemandService"
export const userInsuranceCoverageDemandServiceStateKey = "userInsuranceCoverageDemandServiceStateKey"
export const userInsuranceCoverageDemandServicePatientKey = "UserInsuranceCoverageDemandPatientService"

export const UserInsuranceCoverageDemandService = {
	create: async (payload: UserInsuranceCoverageDemandFormModel) => {
		const { data } = await API.create<UserInsuranceCoverageDemandModel>("USER_INSURANCE_COVERAGE_DEMAND", payload)
		return data
	},
	get: async (iri: string) => API.get<UserInsuranceCoverageDemandModel>(iri),
	list: async ({ params }: { params?: Record<string, any> }) => {
		return API.findAll<UserInsuranceCoverageDemandModel[]>(
			"USER_INSURANCE_COVERAGE_DEMAND",
			`?${convertParams(params ?? {})}`,
			true
		)
	},
	action: async (payload: { id: number; action: string }) =>
		API.doAction("USER_INSURANCE_COVERAGE_DEMAND_ACTION", payload.id, {
			...payload,
			id: undefined,
		}),
	getState: async (id: number) =>
		API.custom<CoverageDemandStateInterface>(USER_INSURANCE_COVERAGE_DEMAND_STATE.replace("{id}", `${id}`)),
	getForPatient: async (id: number | string) => {
		const result = await API.findAll<UserInsuranceCoverageDemandModel[]>(
			"USER_INSURANCE_COVERAGE_DEMAND",
			`?patient=${id}&limit=1000`,
			true
		)

		return result["hydra:member"]
	},
}
