/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"

const Select = ({
	id,
	name,
	groupClassName,
	defaultValue,
	value,
	error,
	label,
	onChange,
	children,
	dataCy,
	disabled = false,
}) => {
	const valueProps =
		value !== undefined
			? {
					value: value,
			  }
			: {
					defaultValue: defaultValue,
			  }
	return (
		<div className={`form-group ${groupClassName}`}>
			{label && <label htmlFor={name}>{label}</label>}
			<select
				disabled={disabled}
				name={name}
				id={id || name}
				className={"form-control" + (error && " is-invalid")}
				onChange={onChange}
				data-cy={dataCy}
				{...valueProps}>
				{children}
			</select>
			<p className="invalid-feedback">{error}</p>
		</div>
	)
}

Select.defaultProps = {
	error: "",
	groupClassName: "",
}

Select.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	groupClassName: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
	dataCy: PropTypes.string,
}

export default Select
