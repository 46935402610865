/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartPie from "./ChartPie"

const Attentes = () => {
	const [rendezVous, setRendezVous] = useState([])
	const [loading, setLoading] = useState(true)
	const labels = ["Réussie", "Echouée", "En attente"]

	const getRendezVous = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/relances")
			const data = []
			if (!result?.data?.message?.length) return setLoading(false)
			result?.data?.message.forEach((e) => {
				if (e.result === "0") data[1] = parseInt(e.Total)
				if (e.result === "1") data[0] = parseInt(e.Total)
				if (e.result == null) data[2] = parseInt(e.Total)
			})
			setRendezVous(data)
			setLoading(false)
		} catch (e) {
			setRendezVous([])

			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getRendezVous()
	}, [])

	return (
		<>
			<h5 className="mb-4">Statut des relances</h5>
			<ChartPie series={rendezVous} labels={labels} colors={["#78e08f", "#e55039", "#4a69bd"]} />
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
