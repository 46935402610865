import { getHumanReadableDate } from "../../../utils/date"
import TableDateRangeFilter from "../../../components/utils/TableDateRangeFilter"

const DPECCol = (queryFilters, sort) => {
	return {
		title: null,
		key: "dpec",
		dataIndex: "patient.dpec",
		width: "100px",
		render: (el) => {
			if (!el) return <></>

			return <>{getHumanReadableDate(el)}</>
		},
		filterDropdown: TableDateRangeFilter,
	}
}

export default DPECCol
