import { Space, Switch } from "antd"
import AuthContext from "contexts/AuthContext"
import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import { UserSetting } from "@audiowizard/common"
import API from "services/API"
import { toast } from "react-toastify"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { Col } from "reactstrap"
import useHasRole from "components/Hooks/useHasRole"

export default function UserSettings(): JSX.Element {
	const { user, setUser, companySettings } = useContext(AuthContext)
	const queryClient = useQueryClient()

	const isManager = useHasRole("ROLE_MANAGER")
	const isFranchisedManager = useHasRole("ROLE_MANAGER_FRANCHISED")

	const { mutate: updateUserSetting, isLoading: isLoadingUserSettings } = useMutation(
		async (data: Partial<UserSetting>) => API.update("USER_SETTINGS_API", user.userSetting!.id!, data),
		{
			onSuccess: (res) => setUser({ ...user, userSetting: res.data as UserSetting }),
			onError: () => {
				toast.error("Erreur lors de la mise à jour des paramètres.")
			},
		}
	)

	const { mutate: updateGeneralSettings, isLoading: isLoadingCompanySettings } = useMutation(
		async (data: Record<string, unknown>) => await API.update("COMPANY_SETTINGS_API", companySettings.id!, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("COMPANY_SETTINGS")
			},
			onError: () => {
				toast.error("Erreur lors de la mise à jour des paramètres.")
			},
		}
	)

	return (
		<Col>
			<SectionHeader title="Bons de commande" />
			<Space direction="vertical" className="w-100 ml-3 settings-status">
				<div className="row mt-2 align-items-center ">
					<Switch
						id="purchase-order-product-default-is-deposit"
						className="mr-2 ml-2"
						checkedChildren="Dépôt"
						unCheckedChildren="Ferme"
						disabled={isLoadingUserSettings}
						checked={user.userSetting!.purchaseOrderProductDefaultIsDeposit}
						onChange={(purchaseOrderProductDefaultIsDeposit) =>
							updateUserSetting({ purchaseOrderProductDefaultIsDeposit })
						}
					/>
					<label htmlFor="purchase-order-product-default-is-deposit" className="m-0">
						<strong>Bon de commande :</strong> produit <strong>Dépôt</strong> ou <strong>Ferme</strong> par
						défaut
					</label>
				</div>
			</Space>
			{(isManager || isFranchisedManager) && (
				<>
					<SectionHeader title="Tableau de bord" />
					<Space direction="vertical" className="w-100 ml-3 settings-status">
						<div className="row mt-2 align-items-center ">
							<Switch
								className="mr-2 ml-2"
								disabled={isLoadingCompanySettings}
								checked={!companySettings.createPatientOutsideDashboard}
								onChange={(checked: boolean) =>
									updateGeneralSettings({ createPatientOutsideDashboard: !checked })
								}
							/>
							Bloquer la création de patients en dehors du tableau de bord
						</div>
					</Space>
					<SectionHeader title="SAV" />
					<Space direction="vertical" className="w-100 ml-3 settings-status">
						<div className="row mt-2 align-items-center ">
							<Switch
								className="mr-2 ml-2"
								disabled={isLoadingCompanySettings}
								checked={!companySettings.serialNumberModificationAllowed}
								onChange={(checked: boolean) =>
									updateGeneralSettings({ serialNumberModificationAllowed: !checked })
								}
							/>
							Bloquer la modification de numéro de série
						</div>
					</Space>
					<SectionHeader title="Devis" />
					<Space direction="vertical" className="w-100 ml-3 settings-status">
						<div className="row mt-2 align-items-center ">
							<Switch
								className="mr-2 ml-2"
								disabled={isLoadingCompanySettings}
								checked={companySettings.simplifiedQuoteEnabled}
								onChange={(checked: boolean) =>
									updateGeneralSettings({ simplifiedQuoteEnabled: checked })
								}
							/>
							Enregistrer les devis simplifiés dans les documents
						</div>
					</Space>
				</>
			)}
		</Col>
	)
}
