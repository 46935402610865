import { HiboutikProductEntity } from "@audiowizard/common"
import { Link } from "react-router-dom"

type ProductsResultsProps = {
	product: HiboutikProductEntity
	onClick: () => void
}
export const ProductsResults = ({ product, onClick }: ProductsResultsProps): JSX.Element => {
	return (
		<Link to={"/mon-stock/fiche-produit/" + product.id} className="item" onClick={onClick}>
			<span className="mr-1">
				<i className="fad fa-box" />
			</span>
			<span>{product?.model}</span>
		</Link>
	)
}
