export const CaisseRoutePrefix = "/caisse"

export const CaisseRoutes = {
	Validation: `${CaisseRoutePrefix}/validation`,
	Dépot: `${CaisseRoutePrefix}/depot`,
	Réassort: `${CaisseRoutePrefix}/reassort`,
	Sortie: `${CaisseRoutePrefix}/sortie-caisse`,
	Historique: `${CaisseRoutePrefix}/historique`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(CaisseRoutePrefix, "")
}
