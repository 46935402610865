import {
	Agenda,
	AudioWizardService,
	CompanySetting,
	DoctorPrescriber,
	EventExplanation,
	EventOtoscopy,
	EventProsthetic,
	EventWorkshop,
	Laboratory,
	LaboratoryAttendance,
	OrlPrescriber,
	Patient,
	PatientAcouphene,
	PatientEquipment,
	PatientGene,
	PatientInsurance,
	PatientLookingFor,
	PatientMedical,
	PatientSocialSecurity,
	PrescriberAdvice,
	Referral,
	Reminder,
	Schedule,
	Template,
	User,
	UserInsurance,
} from "@audiowizard/common"
import { createContext, Dispatch, SetStateAction } from "react"

type ReactSetter<T> = Dispatch<SetStateAction<T>>

export type UiDisplay = {
	onboarding?: boolean
	gdprAgreement?: boolean
	missingMandate?: boolean
	agendaModal?: boolean
	scheduleModal?: boolean
	topBarHiboutikTokenAlert?: boolean
	hideSidebar?: boolean
	lectureCVModal?: boolean
	mode?: string
	selectedPatient?: Patient
}

export type ContextPatient = Patient & {
	advices?: PrescriberAdvice[]
	schedules?: Schedule[]
	patientInsurances?: PatientInsurance[]
	patientGenes?: PatientGene[]
	patientLookingFors?: PatientLookingFor[]
	patientAcouphenes?: PatientAcouphene[]
	patientSocialSecurity?: PatientSocialSecurity[]
}

export type CompanySettingsCol = {
	isRequired: boolean
	isVisible: boolean
	label: string
	nonFillableComponent: boolean
	isDefault: boolean
	displayOrder: number
	key: string
}

type ChangeDiff = {
	old: any
	new: any
}

export type PatientChange = Record<string, ChangeDiff>

/**
 * Pas tout le context est typé ici. Il y a des propriétés passé dans le provider dans context.js qui n'était pas indiqué ici.
 */
export type AuthContextType = {
	isAuthenticated: boolean
	setIsAuthenticated: ReactSetter<boolean>

	companySettings: CompanySetting
	setCompanySettings: ReactSetter<CompanySetting>

	user: User
	setUser: ReactSetter<User>

	services: AudioWizardService[]
	setServices: ReactSetter<AudioWizardService[]>

	subscriptions: AudioWizardService[]
	setSubscriptions: ReactSetter<AudioWizardService[]>

	laboratories: Laboratory[]
	setLaboratories: ReactSetter<Laboratory[]>

	laboratory: Laboratory
	setLaboratory: ReactSetter<Laboratory>

	laboratoryAttendances: LaboratoryAttendance[]
	setLaboratoryAttendances: ReactSetter<LaboratoryAttendance[]>

	loading: boolean
	setLoading: ReactSetter<boolean>

	productRanges: unknown
	setProductRanges: ReactSetter<unknown>

	patient: ContextPatient
	setPatient: ReactSetter<ContextPatient>

	advice: unknown
	setAdvice: ReactSetter<unknown>

	orls: OrlPrescriber[]
	setOrls: ReactSetter<OrlPrescriber[]>

	doctors: DoctorPrescriber[]
	setDoctors: ReactSetter<DoctorPrescriber[]>

	patientEquipment: PatientEquipment
	setPatientEquipment: ReactSetter<PatientEquipment>

	patientEquipments: PatientEquipment[]
	setPatientEquipments: ReactSetter<PatientEquipment[]>

	agendas: Agenda[]
	setAgendas: ReactSetter<Agenda[]>

	schedule: Schedule
	setSchedule: ReactSetter<Schedule & { origin?: string }>

	patientMedicals: PatientMedical[]
	setPatientMedicals: ReactSetter<PatientMedical[]>

	patientMedical: PatientMedical
	setPatientMedical: ReactSetter<PatientMedical>

	patientLookingFors: PatientLookingFor[]
	setPatientLookingFors: ReactSetter<PatientLookingFor[]>

	eventProsthetic: EventProsthetic
	setEventProsthetic: ReactSetter<EventProsthetic>

	eventProsthetics: EventProsthetic[]
	setEventProsthetics: ReactSetter<EventProsthetic[]>

	eventOtoscopies: EventOtoscopy[]
	setEventOtoscopies: ReactSetter<EventOtoscopy[]>

	eventOtoscopy: EventOtoscopy
	setEventOtoscopy: ReactSetter<EventOtoscopy>

	eventWorkshops: { GAUCHE: EventWorkshop; DROITE: EventWorkshop }
	setEventWorkshops: ReactSetter<{ GAUCHE: EventWorkshop; DROITE: EventWorkshop }>

	eventExplanations: EventExplanation[]
	setEventExplanations: ReactSetter<EventExplanation[]>

	patientGenes: PatientGene[]
	setPatientGenes: ReactSetter<PatientGene[]>

	referral: Referral
	setReferral: ReactSetter<Referral>

	isBlockingContext: boolean
	setIsBlockingContext: ReactSetter<boolean>

	reminder: Reminder
	setReminder: ReactSetter<Reminder>

	notesGeneratedText: string
	setNotesGeneratedText: ReactSetter<string>

	notesVisiteText: string
	setNotesVisiteText: ReactSetter<string>

	notesSettings: string
	setNotesSettings: ReactSetter<string>

	scheduleNotes: string
	setScheduleNotes: ReactSetter<string>

	patientUI: boolean
	setPatientUI: ReactSetter<boolean>

	mutuelle: unknown
	setMutuelle: ReactSetter<unknown>

	mutuelles: unknown[]
	setMutuelles: ReactSetter<unknown[]>

	uiDisplay: UiDisplay
	setUiDisplay: ReactSetter<UiDisplay>

	editing: boolean | string
	setEditing: ReactSetter<boolean | string>

	templates: Template[]
	setTemplates: ReactSetter<Template[]>

	hasDoctolib: boolean
	setHasDoctolib: ReactSetter<boolean>
	newQuote: any
	setNewQuote: ReactSetter<any>

	equipmentEditing: boolean
	setEquipmentEditing: ReactSetter<any>

	patientChange: PatientChange
	setPatientChange: ReactSetter<PatientChange>

	userInsurances: UserInsurance[]
	setUserInsurances: ReactSetter<UserInsurance[]>

	patientAcouphenes: PatientAcouphene[]
	setPatientAcouphenes: ReactSetter<PatientAcouphene[]>

	patientInsurances: PatientInsurance[]
	setPatientInsurances: ReactSetter<PatientInsurance[]>
}

function errorSetter(): never {
	throw new Error("Missing AuthContext.Provider")
}

const AuthContext = createContext<AuthContextType>({
	isAuthenticated: false,
	setIsAuthenticated: errorSetter,
	user: {},
	setUser: errorSetter,
	services: {},
	setServices: errorSetter,
	subscriptions: {},
	setSubscriptions: errorSetter,
	laboratories: [],
	setLaboratories: errorSetter,
	laboratory: {},
	setLaboratory: errorSetter,
	laboratoryAttendances: [],
	setLaboratoryAttendances: errorSetter,
	loading: false,
	setLoading: errorSetter,
	productRanges: [],
	setProductRanges: errorSetter,
	patient: {},
	setPatient: errorSetter,
	advice: {},
	setAdvice: errorSetter,
	orls: [],
	setOrls: errorSetter,
	doctors: [],
	setDoctors: errorSetter,
	patientEquipment: {},
	setPatientEquipment: errorSetter,
	patientEquipments: [],
	setPatientEquipments: errorSetter,
	schedule: {},
	setSchedule: errorSetter,
	patientMedicals: [],
	setPatientMedicals: errorSetter,
	patientMedical: {},
	setPatientMedical: errorSetter,
	patientLookingFors: [],
	setPatientLookingFors: errorSetter,
	eventProsthetic: {},
	setEventProsthetic: errorSetter,
	eventProsthetics: [],
	setEventProsthetics: errorSetter,
	eventOtoscopies: {},
	setEventOtoscopies: errorSetter,
	eventOtoscopy: [],
	setEventOtoscopy: errorSetter,
	eventWorkshops: {},
	setEventWorkshops: errorSetter,
	eventExplanations: [],
	setEventExplanations: errorSetter,
	patientGenes: [],
	setPatientGenes: errorSetter,
	referral: {},
	setReferral: errorSetter,
	isBlockingContext: false,
	setIsBlockingContext: errorSetter,
	reminder: {},
	setReminder: errorSetter,
	notesGeneratedText: "",
	setNotesGeneratedText: errorSetter,
	notesVisiteText: "",
	setNotesVisiteText: errorSetter,
	notesSettings: "",
	setNotesSettings: errorSetter,
	scheduleNotes: "",
	setScheduleNotes: errorSetter,
	patientUI: false,
	setPatientUI: errorSetter,
	mutuelle: {},
	setMutuelle: errorSetter,
	mutuelles: [],
	setMutuelles: errorSetter,
	uiDisplay: null,
	setUiDisplay: errorSetter,
	editing: false,
	setEditing: errorSetter,
	templates: [],
	setTemplates: errorSetter,
	newQuote: {},
	setNewQuote: errorSetter,
	patientChange: {},
	setPatientChange: errorSetter,
	userInsurances: [],
	setUserInsurances: errorSetter,
	patientAcouphenes: [],
	setPatientAcouphenes: errorSetter,
	patientInsurances: [],
	setPatientInsurances: errorSetter,
} as any)

export default AuthContext
