import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, CommandesRoutePrefix, CommandesRoutes } from "./routes"

function CommandeRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={CommandesRoutePrefix}>
			<CardItem
				name="Fournisseur audioprothésiste"
				url={noPrefix(CommandesRoutes.FournisseurAudio)}
				component={() => <>À venir prochainement... </>}
			/>
			<CardItem
				name="Print"
				url={noPrefix(CommandesRoutes.CommandePrint)}
				component={() => <>À venir prochainement... </>}
			/>
			<CardItem
				name="Matériel non médical"
				url={noPrefix(CommandesRoutes.MaterielNonMedical)}
				component={() => <>À venir prochainement... </>}
			/>
		</CardWithTabs>
	)
}

export default CommandeRouter
