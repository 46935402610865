/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"
import Chart from "react-apexcharts"

import { API_URL } from "../../config"

const Attentes = () => {
	const [loading, setLoading] = useState(true)
	const options = {
		series: [],
		chart: {
			type: "bar",
			stacked: true,
		},
		colors: ["#008FFB", "#FF4560"],
		plotOptions: {
			bar: {
				horizontal: true,
				barHeight: "80%",
			},
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
		},
		yaxis: {
			min: -50,
			max: 50,
		},
		xaxis: {
			categories: [],
			title: {
				text: "Pourcentages",
			},
			labels: {
				formatter: function (val) {
					return Math.abs(Math.round(val)) + "%"
				},
			},
		},
		noData: {
			text: "pas assez d'informations pour produire le graphique",
		},
	}
	const [agegender, setAgegender] = useState({})

	const getRendezVous = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/age_gender")
			const labels = []

			if (!result?.data?.message?.length) return setLoading(false)
			result?.data?.message.forEach((e) => {
				if (!labels.includes(e.range_age)) labels.push(e.range_age)
			})
			const male = new Array(labels?.length).fill(0)
			const female = new Array(labels?.length).fill(0)
			result?.data?.message.forEach((e) => {
				if (e.gender === "HOMME") {
					male[labels.indexOf(e.range_age)] = parseInt(e.total)
				} else {
					female[labels.indexOf(e.range_age)] = parseInt(e.total)
				}
			})
			let total = 0
			let min = 101
			let max = -1
			for (let i = 0, len = labels?.length; i < len; i++) {
				total += male[i] + female[i]
			}
			for (let i = 0, len = labels?.length; i < len; i++) {
				male[i] = -((male[i] * 100) / total)
				female[i] = (female[i] * 100) / total
				if (-male[i] > max) max = -male[i]
				if (female[i] > max) max = female[i]
				if (-male[i] < min) min = -male[i]
				if (female[i] < min) min = female[i]
				male[i] = male[i].toFixed(2)
				female[i] = female[i].toFixed(2)
			}
			options.series = [
				{
					name: "Hommes",
					data: male,
				},
				{
					name: "Femmes",
					data: female,
				},
			]
			options.yaxis.min = max > min ? -max : -min
			options.yaxis.max = max
			options.xaxis.categories = labels
			setAgegender(options)
			setLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getRendezVous()
	}, [])

	return (
		<>
			<h5 className="mb-4 title-stats">Classement par âge et genre</h5>
			{!loading && <Chart options={agegender} series={agegender.series} type="bar" width="100%" />}
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
