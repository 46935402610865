import { Tag } from "antd"
import { getFontColorForBackground } from "services/functions"

const LastScheduleStatusCol = (queryfilters, quickFilterData, scheduleTypes) => {
	const lastScheduleStatusRender = (lastSchedule) => {
		const scheduleType = scheduleTypes?.[lastSchedule] ?? {}
		return (
			<Tag
				className="tag"
				style={{
					backgroundColor: scheduleType?.color,
					color: scheduleType.label ? getFontColorForBackground(scheduleType?.color) : "grey",
					borderColor: scheduleType?.color,
					borderRadius: "5px",
				}}>
				{scheduleType.customLabel || scheduleType.label || "Aucun"}
			</Tag>
		)
	}

	const filters = Object.entries(scheduleTypes ?? {})
		.filter(
			([status]) =>
				quickFilterData.lastScheduleStatus == null || quickFilterData.lastScheduleStatus.includes(status) // if quick filter is set, only display quick filters statuses
		)
		.map(([status, scheduleType]) => ({
			text: scheduleType?.customLabel || scheduleType?.label,
			value: status,
		}))

	return {
		title: null,
		key: "lastScheduleStatus",
		dataIndex: "patient.lastScheduleStatus",
		width: "180px",
		render: lastScheduleStatusRender,
		filters,
		filteredValue: queryfilters?.lastScheduleStatus || null,
		filterSearch: true,
	}
}

export default LastScheduleStatusCol
