import { Select } from "antd"
import AuthContext from "contexts/AuthContext"
import { useContext, useEffect } from "react"
import { partialSearch } from "services/functions"
import API from "services/API"
import { findActiveLaboratoryFromUserId, warnAboutNoAttendance } from "./LaboratoryAttendanceUtils"

function CurrentLaboratorySelect(props: any): JSX.Element {
	const { laboratory, laboratories, setLaboratory } = useContext(AuthContext)
	const handleChange = (id: number): void => {
		if (id === -1) {
			warnAboutNoAttendance(laboratories[0], props.user.id)
			return setLaboratory(laboratories[0])
		}
		const lab = laboratories.find((lab) => lab.id === id)
		return lab ? setLaboratory(lab) : void 0
	}

	useEffect(() => {
		API.findAll("LABORATORY_ATTENDANCES_API", `?user=${props.user.id}`).then((LaboratoryAttendance) => {
			findActiveLaboratoryFromUserId(LaboratoryAttendance, props.user.id).then((laboratoryId) =>
				handleChange(laboratoryId)
			)
		})
	}, [])

	return (
		<div className="current-laboratory-select__wrapper searchbar-responsive mr-2">
			<i className="current-laboratory-select__wrapper__icon fad fa-hospital" />
			<Select
				showSearch
				filterOption={(input, option) => partialSearch(option?.label as string, input)}
				placeholder="Sélectionnez un laboratoire"
				className="current-laboratory-select__wrapper__select"
				value={laboratory?.id}
				onChange={handleChange}
				options={laboratories?.map((l) => ({
					value: l.id,
					label: `${l.label}`,
				}))}>
				{laboratories.map((l) => (
					<Select.Option key={l.id} value={l.id}>
						{l.label}
					</Select.Option>
				))}
			</Select>
		</div>
	)
}

export default CurrentLaboratorySelect
