import { Patient } from "@audiowizard/common"

const doctoLibSignin = (): boolean => {
	if (window.zipper("config")) return true
	window.open("https://pro.doctolib.fr/signin")
	return false
}

export const zipper = {
	book: ({ patient, date, type }: { patient: Patient; date?: string; type?: string }): void => {
		if (!doctoLibSignin()) return
		if (!patient || !patient.id) {
			return
		}
		const dataPatient = {
			pms_id: `${patient.id.toString()}^^^Audiowizard^PI`,
			first_name: patient.firstName,
			last_name: patient.lastName,
			birthdate: patient.birthDate,
			gender: patient.gender === "FEMME",
		}
		const consultation: { date?: string; visit_motive_external_id?: string } = {}
		if (date) {
			consultation.date = date
		}
		if (type) {
			consultation.visit_motive_external_id = type
		}

		if (date || type) {
			window.zipper("book", {
				...dataPatient,
				consultation,
			})
		} else {
			window.zipper("book", {
				...dataPatient,
			})
		}
	},
	openCalendar: (): void => {
		if (!doctoLibSignin()) return
		window.zipper("openCalendar")
	},
	openListView: (): void => {
		if (!doctoLibSignin()) return
		window.zipper("openListView")
	},
	openPatientHistorique: (patient: Patient): void => {
		if (!doctoLibSignin()) return
		window.zipper("openPatientHistoric", {
			pms_id: `${patient?.id?.toString()}^^^Audiowizard^PI`,
			first_name: patient.firstName,
			last_name: patient.lastName,
			birthdate: patient.birthDate,
			gender: patient.gender === "FEMME",
		})
	},
}
