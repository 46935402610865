/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartPie from "./ChartPie"

const Attentes = () => {
	const [rendezVous, setRendezVous] = useState([])
	const [loading, setLoading] = useState(true)
	const lbHashmap = []
	const [labels, setLabels] = useState([])
	const getRendezVous = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/patho_medicale")
			const data = []
			if (!result?.data?.message?.length) return setLoading(false)
			const tmplabels = []
			let total = 0

			result?.data?.message.forEach((e) => {
				lbHashmap.push(e.label)
				tmplabels.push(e.label)
				data.push(parseInt(e.total))
				total += parseInt(e.total)
			})
			const new_data = []
			const new_labels = []
			let new_other = 0
			let other_inc = 0
			for (let i = 0, len = data.length; i < len; i++) {
				const percent = (data[i] * 100) / total
				if (percent < 5) {
					new_other += percent
					other_inc++
				} else {
					new_data.push(percent)
					new_labels.push(tmplabels[i])
				}
			}
			if (other_inc) {
				new_data.push(new_other / other_inc)
				new_labels.push("Autres")
			}
			setRendezVous(new_data)
			setLabels(new_labels)
			setLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getRendezVous()
	}, [])

	return (
		<>
			<h5 className="mb-4">Pathologies médicales</h5>
			<ChartPie series={rendezVous} labels={labels} />
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
