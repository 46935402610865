import { Template } from "@audiowizard/common"

export enum TypeTemplates {
	NOTE = "NOTE",
	MAIL_REPORT = "MAIL_REPORT",
	ANNIVERSARY = "ANNIVERSARY",
	SAV = "SAV",
}

export const labelTypeTemplates: { [key: string]: string } = {
	[TypeTemplates.NOTE]: "type.note",
	[TypeTemplates.MAIL_REPORT]: "type.mailReport",
	[TypeTemplates.ANNIVERSARY]: "type.anniversary",
	[TypeTemplates.SAV]: "type.sav",
}

export interface TemplateForForm extends Template {
	unlayerId?: string
	edited?: boolean | null
	custom: boolean
	type?: string
	unlayerSetting?: string
	content?: string
	css?: string
}

export const tempDefaultOption: TemplateForForm[] = [
	{
		"@type": "Template",
		"@id": "",
		label: "Anniversaire",
		unlayerId: "122274",
		description: "Template d'email pour célébrer un anniversaire",
		type: TypeTemplates.ANNIVERSARY,
		custom: false,
		isDefault: false,
	},
	{
		"@type": "Template",
		"@id": "",
		label: "Happy Birthday",
		unlayerId: "128356",
		description: "Template d'email pour célébrer un anniversaire",
		type: TypeTemplates.ANNIVERSARY,
		custom: false,
		isDefault: false,
	},
	// {
	// 	"@type": "Template",
	// 	"@id": "",
	// 	label: "Nurtering",
	// 	unlayerId: "122111",
	// 	description: "Lettre de parrainage",
	// 	type: TypeTemplates.NOTE,
	// 	custom: false,
	// 	isDefault: false,
	// },
	{
		"@type": "Template",
		"@id": "",
		label: "Compte rendu d'appareillage",
		unlayerId: "138601",
		description: "Gabarit pour une lettre de compte rendu d'appareillage",
		type: TypeTemplates.MAIL_REPORT,
		custom: false,
		isDefault: false,
	},
	{
		"@type": "Template",
		"@id": "",
		label: "Demande de bilan",
		unlayerId: "137291",
		description: "Gabarit pour une lettre de demande de bilan",
		type: TypeTemplates.MAIL_REPORT,
		custom: false,
		isDefault: false,
	},
	{
		"@type": "Template",
		"@id": "",
		label: "compte rendu SAV",
		unlayerId: "130888",
		description: "Gabarit pour une lettre SAV",
		type: TypeTemplates.SAV,
		custom: false,
		isDefault: false,
	},
]
