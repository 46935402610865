import cx from "classnames"
import AuthContext from "contexts/AuthContext"
import React, { Children, useContext, useMemo } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import "./LeftBar.scss"

export interface LeftBarProps {
	tag: React.ElementType
	children: React.ReactNode
}

export interface LeftBarLinkProps {
	to: string
	icon: string
	label: string
	active?: boolean
	identifier?: string
	btnClassNames?: string
	onClick?: () => void
}

function LeftBar(props: LeftBarProps): JSX.Element {
	const { editing } = useContext(AuthContext)
	const { tag: Tag, children, ...rest } = props
	const [leftBarCollapse, setLeftBarCollapse] = React.useState<boolean>(
		localStorage.getItem("leftbar--collapse") === "true"
	)
	const { pathname } = useLocation()

	const leftBarClassNames = cx("leftbar", {
		"leftbar--collapse": localStorage.getItem("leftbar--collapse") === "true",
	})

	const isActive = (
		child: React.ReactElement<LeftBarLinkProps, string | React.JSXElementConstructor<any>>
	): boolean => {
		return editing
			? false
			: child.props.to === "/"
			? pathname === child.props.to /* all links start's with an "/" need strict compare */
			: pathname.startsWith("/parametres") || pathname.startsWith("/gestion-compte")
			? pathname.includes(child.props.to.split("/")[2])
			: pathname.startsWith("/" + child.props.to.split("/")[1])
	}

	const Links = useMemo(
		() =>
			Children.map(children, (child) => {
				if (!React.isValidElement<LeftBarLinkProps>(child) || child.props.identifier !== "Link") {
					return child
				}

				return React.cloneElement(child, {
					active: isActive(child),
				})
			}),
		[pathname]
	)

	const handleCollapse = (): void => {
		setLeftBarCollapse(!leftBarCollapse)

		localStorage.setItem("leftbar--collapse", leftBarCollapse ? "false" : "true")
	}
	return window.location.pathname.split("/").filter(Boolean).pop() !== "agenda" ? (
		<Tag {...rest} className={leftBarClassNames}>
			<HeaderRender pathname={pathname} />
			<button onClick={handleCollapse} className="leftbar__link leftbar__link--collapser">
				<span className="leftbar__link__icon fad fa-fw fa-exchange">exchange</span>
				<span className="leftbar__link__label">Réduire le menu</span>
			</button>
			{Links}
		</Tag>
	) : (
		<></>
	)
}

function Link(props: LeftBarLinkProps): JSX.Element {
	const { to, icon, label, active, onClick } = props

	const { setEditing } = useContext(AuthContext)

	const handleClick = (): void => {
		//for editing to false to prevent it to stay to true when if the user navigate in the middle of the edit process
		setEditing(false)
		onClick?.()
	}

	const btnClassNames = cx("leftbar__link", {
		"leftbar__link--active": active,
	})

	const iconClassNames = cx("leftbar__link__icon", {
		[`fad fa-fw fa-${icon}`]: icon,
	})

	return (
		<NavLink to={to} onClick={handleClick}>
			<button title={label} className={btnClassNames}>
				<span className={iconClassNames}>{icon}</span>
				<span className="leftbar__link__label">{label}</span>
			</button>
		</NavLink>
	)
}

function Separator(): JSX.Element {
	return <hr className="leftbar__separator" />
}

const HeaderRender = ({ pathname }: { pathname: string }): JSX.Element => {
	const history = useHistory()
	if (pathname.startsWith("/parametres"))
		return (
			<button className="leftbar__avatar my-2" onClick={() => history.push("/")}>
				<span className="fad fa-cog fa-fw">cog</span>
				<span className="leftbar__link__label">Paramètres</span>
			</button>
		)

	if (pathname.startsWith("/gestion-compte"))
		return (
			<button className="leftbar__avatar my-2" onClick={() => history.push("/")}>
				<span className="fad fa-briefcase fa-fw">briefcase</span>
				<span className="leftbar__link__label">Compte</span>
			</button>
		)
	else return <></>
}

LeftBar.Separator = Separator as React.FC
LeftBar.Link = Link as React.FC<LeftBarLinkProps>
LeftBar.Link.defaultProps = {
	to: "/#nolink",
	icon: "icons",
	label: "Link example label",
	active: false,
	identifier: "Link",
}

LeftBar.defaultProps = {
	tag: "div",
}

export default LeftBar
