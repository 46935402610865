import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, EntrepriseRoutePrefix, EntrepriseRoutes } from "./routes"

import SubCompanies from "../../pages/mon-compte/SubCompanies"
import InformationEntreprise from "../../pages/mon-compte/InformationEntreprise"
import SousTraitant from "../../pages/mon-compte/SousTraitant"
import useCustomTitle from "components/Hooks/useTitle"

function EntrepriseRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Entreprise")
	return (
		<CardWithTabs urlprefix={EntrepriseRoutePrefix}>
			<CardItem
				name="Infos de facturation"
				component={InformationEntreprise}
				url={noPrefix(EntrepriseRoutes.Informations)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
			<CardItem
				name="Sous-sociétés"
				component={SubCompanies}
				url={noPrefix(EntrepriseRoutes.SousSociete)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
			<CardItem
				name="Sous-traitants"
				component={SousTraitant}
				url={noPrefix(EntrepriseRoutes.SousTraitant)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default EntrepriseRouter
