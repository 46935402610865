import { HTMLProps } from "react"
import "./Typography.scss"

export default function Typography({ children, className, ...props }: HTMLProps<HTMLSpanElement>) {
	return (
		<span className={`aw-typography ${className}`} {...props}>
			{children}
		</span>
	)
}
