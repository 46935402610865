/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, DatePicker, notification, Pagination, Table } from "antd"
import TableDateRangeFilter from "components/utils/TableDateRangeFilter"
import axios from "axios"
import { DateInputOnly } from "components/forms/DateInput"
import useEffectAsync from "components/Hooks/useEffectAsync"
import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import dayjs from "dayjs"
import { isRefundMethod } from "pages/vente/Payments"
import { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import uuid from "react-uuid"
import { Alert, Col, Row } from "reactstrap"
import { IsObjectEmpty, rounded } from "services/functions"
import Modal from "../../../components/effects/ReactModal"
import AsyncPatientSelect from "../../../components/utils/AsyncPatientSelect"
import { API_URL } from "../../../config"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import API_Hiboutik from "../../../services/API_Hiboutik"
import VenteModal from "../VenteModal"
import { useFetchPaymentType } from "pages/Settings/paymentTypes/PaymentTypes.helpers"
import useCustomTitle from "components/Hooks/useTitle"

const { RangePicker } = DatePicker

const labelsState = {
	ENCAISSE_VENTE_ANNULE: "Encaissé",
	ENCAISSE: "Encaissé",
	DEPOSE: "Déposé",
	DEPOSE_VENTE_ANNULE: "Déposé",
	EN_ATTENTE: "En attente",
	EN_ATTENTE_VENTE_ANNULE: "En attente",
}

const ModalChangeCheckNumber = ({ modal, setModal, onValidate }) => {
	const [checkNumber, setCheckNumber] = useState("")

	useEffect(() => {
		setCheckNumber(modal?.checkNumber || "")
	}, [modal])

	return (
		<Modal
			title="Changer le numéro de chèque"
			isOpen={modal?.open || false}
			onClose={() => {
				setModal({})
			}}>
			<Alert color="primary">Entrez ci-dessous le numéro de chèque</Alert>
			<input
				className="form-control mb-3"
				value={checkNumber}
				onChange={(event) => {
					const value = event.target.value
					setCheckNumber(value)
				}}
			/>
			<button
				type="button"
				className="btn btn-primary btn-block"
				disabled={checkNumber.length < 7}
				onClick={() => onValidate(checkNumber)}>
				Enregistrer
			</button>
		</Modal>
	)
}

const ListePaiementRecu = ({ paymentState }) => {
	useCustomTitle(paymentState === "reçu" ? "Paiements | Reçus" : "Paiements | En attente")
	const { laboratory, userInsurances } = useContext(AuthContext)
	const [needRefresh, setNeedRefresh] = useState(false)
	const [loading, setLoading] = useState(true)
	const [paiements, setPaiements] = useState([])
	const [dataModalChangeCheckNumber, setModalChangeCheckNumber] = useState({})
	const [apiFilter, setApiFilter] = useState({ laboratory })
	const history = useHistory()
	const [saleModal, setSaleModal] = useState({ open: false })
	const [selectedPatientFilter, setSelectedPatientFilter] = useState({})
	const [montantResteDu, setMontantResteDu] = useState(-1)
	const [pagination, setPagination] = useState({ total: 0, current: 1 })
	const [saleDetailLoading, setSaleDetailLoading] = useState(0)

	const { paymentTypesRef } = useFetchPaymentType(laboratory.subCompany)

	const handleTeletransSuiviDivPayment = async (saleId, paidDate) => {
		try {
			const document = (await API.findAll("DOCUMENTS_API", `?numero=${saleId}&type=FACTURE_SUIVI_TELETRANS`))[
				"hydra:member"
			]?.[0]
			if (document && document.config?.ventes?.payment_details?.[0]) {
				// dans le cadre d’une télétransmission de suivi il ne devrait y avoir qu’un seul paiement
				document.config.ventes.payment_details[0].payment_date_paid = paidDate
				await API.update("DOCUMENTS_API", document.id, {
					edit: {
						payment_details: document.config.ventes.payment_details,
						status: "ENCAISSE_SUIVI_TELETRANS",
					},
				})
			}
		} catch (error) {
			console.error(error)
		}
	}

	const handleChangeDivPaymentState = async (paymentId, id, attribute, value, saleId = null) => {
		try {
			setLoading(true)
			if (paymentId === -1) return
			await API_Hiboutik.saleUpdateDiv(paymentId, attribute, value)
			notification.success({
				message: "Mise à jour du paiement",
				description: "Le paiement a été mis à jour",
				top: 124,
			})
			if (saleId) await handleTeletransSuiviDivPayment(saleId, value)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
			setNeedRefresh(true)
		}
	}

	const handleChangeCheckNumber = async (saleId, id, value) => {
		try {
			const sale = await API_Hiboutik.getSale(saleId)
			let comments = sale.comments || ""
			comments = comments.replace(/\[ Chèque numéro n°(.*) \]/, "")
			comments += `[ Chèque numéro n°${value} ]\n`
			await API_Hiboutik.saleAddComment(saleId, comments)
			notification.success({
				message: "Mise à jour du numéro",
				description: "Le numéro de chèque a été mis à jour",
				top: 124,
			})
		} catch (error) {
			console.error(error)
		} finally {
			setNeedRefresh(true)
		}
	}

	const fetchCustomerDetails = (...customerIds) => {
		return axios
			.get(`${API_URL}/search/patients/by_list/customer_id/${btoa(customerIds.join(","))}`)
			.then((res) => res.data)
	}

	const fetchSaleDetails = (...saleIds) => {
		return axios
			.get(`${API_URL}/documents/invoices/lookup/${btoa(saleIds.join(","))}`)
			.then((res) => res.data["hydra:member"])
	}

	const columns = [
		{
			title: "Type",
			dataIndex: "type",
			filters: Object.keys(paymentTypesRef)
				.filter((f) => !["MTL", "CRED"].includes(f))
				.map((v) => {
					return {
						text: paymentTypesRef[v]?.label,
						value: v,
					}
				}),
			width: "10%",
			render: (d) => {
				if (paymentTypesRef[d]) {
					return (
						<>
							<i className={"fad " + paymentTypesRef[d].icon} /> {paymentTypesRef[d].label}
						</>
					)
				}
			},
		},
		{
			title: "Date d'encaissement prévue",
			filterDropdown: TableDateRangeFilter,
			filteredValue: apiFilter.dueDate,
			dataIndex: "dueDate",
			width: "18%",
			render: (d, row) => {
				if (
					(row.salePaymentType === "DIV" && row.state.includes("DEPOSE")) ||
					row.state.includes("VENTE_ANNULE") ||
					row.type === "SMDP"
				) {
					return (
						<input
							alt="Vous ne pouvez pas modifier la date d'un chèque déjà déposé ou d'une vente annulée"
							title="Vous ne pouvez pas modifier la date d'un chèque déjà déposé ou d'une vente annulée"
							type="date"
							disabled={true}
							defaultValue={d ? dayjs(d).format("YYYY-MM-DD") : ""}
						/>
					)
				} else if (row.salePaymentType === "DIV") {
					return (
						<DateInputOnly
							allowClear={false}
							id="payment-date"
							title="Sélectionner une date prévue d'acquittement"
							defaultValue={dayjs()}
							value={d != null ? dayjs(d) : undefined}
							onChange={(date) => {
								handleChangeDivPaymentState(
									row.paymentId,
									row.id,
									"payment_date",
									date.format("YYYY-MM-DD")
								)
							}}
						/>
					)
				} else {
					return dayjs(d).format("DD/MM/YYYY")
				}
			},
		},
		{
			title: "Date d'acquittement",
			dataIndex: "paidDate",
			width: "22%",
			filterDropdown: paymentState === "recu" ? TableDateRangeFilter : null,
			filteredValue: apiFilter.paidDate,
			render: (d, row) => {
				if (
					(row.salePaymentType === "DIV" && row.state.includes("DEPOSE")) ||
					row.state.includes("VENTE_ANNULE")
				) {
					return (
						<input
							alt="Vous ne pouvez pas modifier la date d'acquittement d'un chèque déjà déposé, d'une vente annulée ou d'un type sans moyen de paiement"
							title="Vous ne pouvez pas modifier la date d'acquittement d'un chèque déjà déposé, d'une vente annulée ou d'un type sans moyen de paiement"
							type="date"
							disabled={true}
							defaultValue={d ? dayjs(d).format("YYYY-MM-DD") : ""}
						/>
					)
				} else if (row.type === "SMDP") {
					return null
				} else if (row.salePaymentType === "DIV") {
					const idForSuivi = row.state === "EN_ATTENTE" && row.amount === 0.01 && row.saleId
					return (
						<>
							<div className="d-flex flex-row justify-content-center align-items-center">
								<DateInputOnly
									allowClear={false}
									id="payment-date-paid"
									title="Sélectionner une date d'acquittement"
									disabledDate={(date) => date.isAfter(dayjs(), "day")} // Désactive les dates futures
									value={d != null ? dayjs(d) : undefined}
									onChange={(date) => {
										handleChangeDivPaymentState(
											row.paymentId,
											row.id,
											"payment_date_paid",
											date.format("YYYY-MM-DD"),
											idForSuivi
										)
									}}
								/>
								{d && (
									<div
										className="col text-danger "
										alt="Annuler l'acquittement"
										title="Annuler l'acquittement"
										onClick={() => {
											handleChangeDivPaymentState(
												row.paymentId,
												row.id,
												"payment_date_paid",
												"0000-00-00"
											)
										}}>
										<i className="fad fa-2x fa-minus-square cursor-pointer" />
									</div>
								)}
							</div>
						</>
					)
				} else {
					return dayjs(d).format("DD/MM/YYYY")
				}
			},
		},
		{
			title: "Vente",
			dataIndex: "saleId",
			width: "8%",
			render: (d, row) => {
				if (d) {
					return (
						<button
							className="btn btn-secondary btn-block p-2"
							type="button"
							disabled={saleDetailLoading !== 0}
							onClick={async () => {
								try {
									setSaleDetailLoading(d)
									const supl = {}
									const saleDetail = await API_Hiboutik.getSale(d)
									const patientDetail = await API.findCustom(
										"PATIENTS_API",
										`hiboutikCustomerId=${saleDetail.customer_id}`
									)
									if (
										patientDetail.length === 1 &&
										patientDetail[0].firstName === saleDetail.customers_first_name &&
										patientDetail[0].lastName === saleDetail.customers_last_name
									) {
										supl["patient"] = { ...(await API.find("PATIENTS_API", patientDetail[0].id)) }
									}
									setSaleModal({
										open: true,
										sale: saleDetail,
										patient: supl?.patient,
									})
								} catch (error) {
									console.error(error)
								} finally {
									setSaleDetailLoading(0)
								}
							}}>
							<span key={saleDetailLoading}>
								{saleDetailLoading === d ? (
									<i className={"fad fa-spinner-third fa-spin "} />
								) : (
									<>{"Détails" || row?.document?.invoiceNumber}</>
								)}
							</span>
						</button>
					)
				}
			},
		},
		{
			title: "Patient",
			dataIndex: "saleCustomerName",
			width: "15%",
			render: (d, row) => {
				if (row.patientId)
					return (
						<button
							className="btn btn-secondary btn-block p-2"
							type="button"
							onClick={() => {
								history.push(`/fiche-patient/${row.patientId}`)
							}}>
							{d}
						</button>
					)
			},
		},
		{
			title: "Mutuelle",
			dataIndex: "insurance",
			width: "15%",
			filters: userInsurances.map((ins) => ({
				value: ins.id,
				text: ins.label,
			})),
			filteredValue: apiFilter.insurance,
			filterSearch: true,
			render: (d) => {
				return <strong>{d}</strong>
			},
		},
		{
			title: "Montant",
			dataIndex: "amount",
			render: (d) => {
				return <strong>{d} €</strong>
			},
		},
		{
			title: "Numéro de chèque",
			dataIndex: "comment",
			width: "10%",
			render: (d, row) => {
				if (row.type === "CHE" && !row.state.includes("DEPOSE")) {
					return (
						<button
							className="btn btn-secondary btn-block p-2"
							type="button"
							onClick={() => {
								setModalChangeCheckNumber({
									open: true,
									data: row,
									checkNumber: d,
								})
							}}>
							{d}
						</button>
					)
				}
				if (row.type === "CHE" && row.state.includes("DEPOSE"))
					return (
						<button
							className="btn btn-secondary btn-block p-2"
							type="button"
							alt="Vous ne pouvez pas modifier le numéro d'un chèque déjà déposé !"
							title="Vous ne pouvez pas modifier le numéro d'un chèque déjà déposé !"
							disabled={true}>
							{d}
						</button>
					)
				return d
			},
		},
		{
			title: "Statut",
			dataIndex: "state",
			render: (d) => {
				return labelsState?.[d] || d
			},
		},
	]

	useEffect(() => {
		setPagination((old) => ({ ...old, current: 1 }))
		setNeedRefresh(true)
	}, [apiFilter, selectedPatientFilter])

	useEffect(() => {
		if (needRefresh === true) {
			;(async function () {
				try {
					setLoading(true)
					let customersIds = new Set(),
						customersDetail = [],
						saleIds = new Set(),
						saleDetails = [],
						result = []

					const searchParams = new URLSearchParams()
					searchParams.set("order[saleCompletedAt]", "desc")
					searchParams.set("order[paidDate]", "desc")
					searchParams.set("saleWarehouseId", apiFilter.laboratory.warehouseIdHiboutik || 1)
					searchParams.set("amount!", "0.01")

					if (apiFilter?.isSuivi) {
						searchParams.delete("amount!")
					}

					if (apiFilter?.filters?.type) {
						for (const type of apiFilter.filters.type) {
							searchParams.append("type[]", type)
						}
					}
					if (pagination.current) {
						searchParams.append("page", `${pagination.current}`)
					}

					// selectedPatientFilter can be {}
					if (selectedPatientFilter != null && !IsObjectEmpty(selectedPatientFilter)) {
						// if patient hiboutikCustomerId is null, search for non existant id -1 for filter to apply
						// if the value is non numeric, API Platform ignores the filter
						searchParams.append("saleCustomerId", `${selectedPatientFilter.hiboutikCustomerId ?? "-1"}`)
					}

					if (apiFilter.filters?.insurance) {
						searchParams.append("userInsurance[]", `${apiFilter.filters?.insurance}`)
					}

					if (apiFilter.filters?.paidDate) {
						searchParams.append("paidDate[after]", `${apiFilter.filters.paidDate[0]}`)
						searchParams.append("paidDate[before]", `${apiFilter.filters.paidDate[1]}`)
					}

					if (apiFilter.filters?.dueDate) {
						searchParams.append("dueDate[after]", `${apiFilter.filters.dueDate[0]}`)
						searchParams.append("dueDate[before]", `${apiFilter.filters.dueDate[1]}`)
					}

					if (apiFilter?.sale?.start && apiFilter?.sale?.end) {
						searchParams.append("saleCompletedAt[after]", `${apiFilter.sale.start}`)
						searchParams.append("saleCompletedAt[before]", `${apiFilter.sale.end}`)
					}

					if (paymentState) {
						if (paymentState === "attente") {
							searchParams.append("state[]", "EN_ATTENTE")
							if (apiFilter?.canceledSale) {
								searchParams.append("state[]", "EN_ATTENTE_VENTE_ANNULE")
							}
						}
						if (paymentState === "recu") {
							searchParams.append("state[]", "DEPOSE")
							searchParams.append("state[]", "ENCAISSE")
							searchParams.append("state[]", "VALIDE")

							if (apiFilter?.canceledSale) {
								searchParams.append("state[]", "DEPOSE_VENTE_ANNULE")
								searchParams.append("state[]", "ENCAISSE_VENTE_ANNULE")
								searchParams.append("state[]", "VALIDE_VENTE_ANNULE")
							}
						}
					}
					const data = await API.findAll("PAYMENTS_API", `?${searchParams}`, true)

					if (paymentState === "attente") {
						searchParams.set("pagination", false)
						const data = await API.findAll("PAYMENTS_API", `?${searchParams}`)
						const filteredPaymentRestantDu = data.reduce((acc, item) => item.amount + acc, 0)
						setMontantResteDu(filteredPaymentRestantDu)
					}

					result = [...result, ...data["hydra:member"]]
					for (const payment of result) {
						customersIds.add(payment.saleCustomerId)
						saleIds.add(payment.saleId)
					}
					if (customersIds.size) {
						try {
							customersDetail = await fetchCustomerDetails(...customersIds)
						} catch (error) {
							console.error(error)
						}
					}

					if (saleIds.size) {
						try {
							saleDetails = await fetchSaleDetails(...saleIds)
						} catch (error) {
							console.error(error)
						}
					}

					setPagination((old) => ({
						...old,
						total: data["hydra:totalItems"],
					}))

					result = result?.filter((f) => f?.amount >= 0 || isRefundMethod(f.type))

					const paiementsDataResolved = await Promise.all(
						result.map(async (value) => {
							const details = customersDetail.find(
								(f) => +f.hiboutik_customer_id === +value.saleCustomerId
							)
							const saleDetail = saleDetails.find((f) => +f.numero === +value.saleId)
							value.key = uuid()
							value.checked = false
							value.saleCustomerName = `${details?.first_name || "Inconnu"} ${details?.last_name || ""}`
							value.patientId = +details?.id
							value.document = { ...(saleDetail || {}) }

							if (["MTL", "MUTL", "CMU", "GU", "RBM"].includes(value.type)) {
								const doc = await API.findAll("DOCUMENTS_API", `?numero=${value.saleId}`)
								const insurance = doc[0]?.config?.patient?.full?.patientInsurances[0]?.label
								value.insurance = insurance ?? ""
							}

							return value
						})
					)

					setPaiements(paiementsDataResolved)
				} catch (e) {
					console.error(e)
				} finally {
					setNeedRefresh(false)
					setLoading(false)
				}
			})()
		}
	}, [needRefresh])

	// to not trigger on every user filter changes
	// from todo goto action, force paiements filter on unique paiement
	const initialMountedAndUpdateOnce = useRef(false)
	useEffectAsync(async () => {
		if (initialMountedAndUpdateOnce.current || paiements.length === 0 || !history.location.state?.payment) {
			return
		}

		setPaiements(paiements.filter((payment) => payment["@id"] === history.location.state.payment))
		initialMountedAndUpdateOnce.current = true
	}, [paiements])

	return (
		<>
			<VenteModal
				isOpen={saleModal.open}
				onClose={() => {
					setSaleModal({})
				}}
				setSale={() => {}}
				loading={false}
				patient={saleModal?.patient}
				patientEquipments={saleModal?.patient?.patientEquipments}
				sale={saleModal.sale || { line_items: [] }}
				getCredits={[]}
				setNeedRefresh={setNeedRefresh}
			/>

			<ModalChangeCheckNumber
				modal={dataModalChangeCheckNumber}
				setModal={setModalChangeCheckNumber}
				onValidate={async (checkNumber) => {
					const row = dataModalChangeCheckNumber?.data
					try {
						if (row.type === "CHE" && row.salePaymentType === "CHE") {
							await handleChangeCheckNumber(row.saleId, row.id, checkNumber)
						} else if (row.type === "CHE" && row.salePaymentType === "DIV") {
							await handleChangeDivPaymentState(row.paymentId, row.id, "comments", checkNumber)
						}
					} catch (e) {
						console.error(e)
					}
				}}
			/>
			<div className="row">
				<div className="col">
					<h6 className="mt-2 mb-2">Filtrer par patient (nom/prénom)</h6>
					<AsyncPatientSelect
						setData={setSelectedPatientFilter}
						className="mb-3 form-control w-100 removeantd-class antd-add-padding"
						laboratoryFilter={apiFilter.laboratory}
					/>

					<Checkbox
						disabled={!!apiFilter.type}
						checked={!!apiFilter.isSuivi}
						onChange={(e) => {
							if (e.target.checked) setApiFilter((old) => ({ ...old, isSuivi: true }))
							else setApiFilter((old) => ({ ...old, isSuivi: false }))
						}}>
						Afficher les paiements de suivi (0.01 €)
					</Checkbox>
				</div>
				<div className="col">
					<h6 className="mt-2 mb-2">Par laboratoire</h6>
					<AsyncLaboratorySelect
						data={apiFilter.laboratory?.id}
						setData={(_id, laboratory) => {
							setApiFilter((old) => ({ ...old, laboratory }))
						}}
						warehouseOnly
						allowClear={false}
						className="form-control w-100 removeantd-class antd-add-padding"
						size="large"
						mode="simple"
					/>
				</div>
				<div className="col">
					<h6 className="mt-2 mb-2">Filtrer par date de facture</h6>
					<RangePicker
						className="w-100  "
						style={{
							height: "calc(1.5em + 1.5rem + 2px)",
							boxShadow: "inset 0 1px 1px rgba(31, 45, 61, 0.075)",
							border: "1px solid #e0e6ed",
							borderRadius: ".25rem",
							padding: "0.75rem 1.25rem",
						}}
						allowClear={true}
						onChange={(e) => {
							if (e) {
								setApiFilter((old) => ({
									...old,
									sale: {
										start: `${e[0].year()}-${e[0].month() + 1}-${e[0].date()}T00:00:00`,
										end: `${e[1].year()}-${e[1].month() + 1}-${e[1].date()}T23:59:59`,
									},
								}))
							} else {
								setApiFilter((old) => ({ ...old, sale: null }))
							}
						}}
					/>
				</div>
			</div>
			<Row className="my-2 flex-grow-1">
				<Col>
					<h5 className="mb-3">
						<span className="align-middle">Liste des paiements</span>
						{paymentState === "attente" && montantResteDu !== -1 && (
							<span className="ml-3 align-middle badge badge-xs badge-pill badge-soft-info">
								Reste dû : {rounded(montantResteDu)} €
							</span>
						)}
					</h5>
					<Table
						columns={columns}
						dataSource={paiements}
						loading={loading}
						rowClassName={(record) => "tablerow row-" + record.state}
						pagination={false}
						onChange={(pagination, filters) => {
							setApiFilter((old) => ({ ...old, filters }))
						}}
					/>
				</Col>
			</Row>
			<Row className="my-2">
				<Col>
					<Pagination
						current={pagination.current}
						total={pagination.total}
						showSizeChanger={false}
						pageSize={10}
						onChange={(value) => {
							setPagination((old) => ({ ...old, current: value }))
							setNeedRefresh(true)
						}}
					/>
				</Col>
			</Row>
		</>
	)
}

export default ListePaiementRecu
