import { HiboutikBrand } from "@audiowizard/common"
import ButtonRounded from "components/Buttons/ButtonRounded"
import { useBrandsQuery } from "components/Hooks/commonQueries"
import useHasRole from "components/Hooks/useHasRole"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import Button from "components/utils/Button"
import { useState } from "react"
import { Col, Row } from "reactstrap"
import BrandsModal from "../Brands.modal"
import { BrandsTable } from "./CatalogueTables"

const Brands = (): JSX.Element => {
	const isManager = useHasRole("ROLE_MANAGER")

	const [brand, setBrand] = useState({})
	const [brandSearch, setBrandSearch] = useState("")
	const [isBrandModalOpen, setIsBrandModalOpen] = useState(false)

	const { data: brands } = useBrandsQuery()

	const handleBrandRowClick = (brand: HiboutikBrand): void => {
		setBrand(brand)
		document.getElementById("btn-modal-modifier-brand")?.click()
	}
	return (
		<Col>
			<SectionHeader title="Liste des marques">
				{isManager && (
					<ButtonRounded
						color="primary-outlined"
						type="button"
						icon="fa-plus"
						onClick={() => {
							setIsBrandModalOpen(true)
							setBrand({})
						}}>
						Ajouter une marque
					</ButtonRounded>
				)}
			</SectionHeader>
			<Row className="my-2">
				<Col md={8}>
					<input
						type="search"
						name="search"
						placeholder="Rechercher une marque"
						className="form-control"
						value={brandSearch}
						onChange={(e) => setBrandSearch(e.target.value)}
					/>
				</Col>
			</Row>
			<Row className="my-4">
				<Col md={12}>
					<div className="table-responsive">
						<BrandsTable nameSearchQuery={brandSearch} onRowClick={handleBrandRowClick} />
					</div>
				</Col>
			</Row>
			<Button
				id="btn-modal-modifier-brand"
				dataToggle="modal"
				dataTarget="#modal-brand"
				dataCy="modifier-une-marque-mes-preferences"
				className="d-none"
			/>
			<BrandsModal
				brandsList={brands}
				brand={brand}
				setBrand={setBrand}
				isOpen={isBrandModalOpen}
				onClose={() => setIsBrandModalOpen(false)}
			/>
		</Col>
	)
}

export default Brands
