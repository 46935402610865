// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		title: "Déclinaison",
		dataIndex: "sizeName",
		width: "220px",
		render: (sizeName: string): JSX.Element => {
			if (!sizeName) return <div style={{ opacity: "0.5" }}>Sans déclinaison</div>
			return <>{sizeName}</>
		},
	},
	{
		title: "Numéro de série / Quantité",
		dataIndex: "serialNumber",
		render: (d: string, row: Record<string, any>): JSX.Element => {
			if (!d) {
				return (
					<div>
						Sans numéro de série : <b>{row.quantity}</b>
					</div>
				)
			}

			return <span style={{ fontFamily: "Monospace" }}>{d}</span>
		},
	},
]
