import { Select } from "antd"

const { Option } = Select

interface ScheduleStatusSelectProp {
	data: any
	setData: (v: any) => void
	loading: boolean
}

const ScheduleStatusSelect: React.FC<ScheduleStatusSelectProp> = ({ data, setData, loading }) => {
	const handleChange = (state: any, option: any): void => {
		setData((old: any) => ({ ...old, state }))

		try {
			let classes = option?.children?.props?.children?.[0]?.props?.className
			const fontAwesomeSvg = document.querySelector("svg.antd-icon-option")
			if (!fontAwesomeSvg) return
			const classesSvg = fontAwesomeSvg.classList

			classes = classes.split(" ")
			classes = classes.find((f: string): boolean => f.includes("fa-"))
			fontAwesomeSvg.classList.remove(classesSvg[1])
			fontAwesomeSvg.classList.add(classes)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="schedule-type-selector">
			<Select
				size={"large"}
				showSearch
				disabled={loading}
				placeholder="Status du rendez-vous..."
				style={{ width: "100%" }}
				optionFilterProp="children"
				onChange={handleChange}
				value={data.state}>
				<Option value="WAITING">
					<span className="select-state-item text-info">
						<i className="fad fa-hourglass-start antd-icon-option" />
						En attente
					</span>
				</Option>
				<Option value="WAITING_ROOM">
					<span className="select-state-item text-primary font-weight-bold">
						<i className="fad fa-clinic-medical antd-icon-option" /> Arrivé
					</span>
				</Option>
				<Option value="IN_PROGRESS">
					<span className="select-state-item text-primary">
						<i className="fad fa-exclamation-circle antd-icon-option" /> En cours
					</span>
				</Option>
				<Option value="DONE">
					<span className="select-state-item text-primary">
						<i className="fad fa-check-circle antd-icon-option" /> Terminé
					</span>
				</Option>
				<Option value="CANCELLED">
					<span className="select-state-item text-warning">
						<i className="fad fa-times-circle antd-icon-option" /> Annulé
					</span>
				</Option>
				<Option value="MISSED">
					<span className="select-state-item text-danger">
						<i className="fad fa-exclamation-circle antd-icon-option" /> Manqué
					</span>
				</Option>
				<Option value="EXCUSED">
					<span className="select-state-item text-danger">
						<i className="fad fa-exclamation-circle antd-icon-option" /> Excusé
					</span>
				</Option>
			</Select>
		</div>
	)
}

export default ScheduleStatusSelect
