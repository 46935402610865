import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import ComplianceDocumentViewer from "pages/fiche-patient/Documents/Modal.ComplianceDocumentViewer"
import { useContext, useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { Col, Row } from "reactstrap"
import DataTable from "../../components/utils/DataTable"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import { haveRole } from "../../services/functions"
import SubcontractorsModal from "./Subcontractors.modal"

const getDocumentType = (documentType) => {
	switch (documentType) {
		case "CGU":
			return "Conditions générales d'utilisation"
		case "CGV":
			return "Conditions générales de vente"
		case "POLICY_PRIVACY":
			return "RGPD"
		default:
			return
	}
}

export const columnsSubcontractors = [
	{
		title: "Catégorie",
		dataIndex: "category",
		sorter: (a, b) => {
			a = a.label || ""
			b = b.label || ""
			return a.localeCompare(b)
		},
		width: "33%",
	},
	{
		title: "Nom du sous-traitant",
		dataIndex: "label",
		sorter: (a, b) => a.price - b.price,
		width: "33%",
	},
	{
		title: "Description",
		dataIndex: "description",
		sorter: (a, b) => a.price - b.price,
		width: "33%",
	},
]

export const columnsComplianceDocuments = [
	{
		title: "Type de document",
		dataIndex: "documentType",
		width: "50%",
		render: (documentType) => getDocumentType(documentType),
	},
	{
		title: "Signé",
		dataIndex: "isAccepted",
		width: "50%",
		render: (isAccepted) => (isAccepted ? "Oui" : "Non"),
	},
]

function SousTraitant({ history }) {
	const { user, subcontractors, setSubcontractors } = useContext(AuthContext)
	const [DocumentModalOpen, setDocumentModalOpen] = useState(false)
	const [subContractorModalOpen, setSubContractorModalOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const [subcontractor, setSubcontractor] = useState({
		category: "",
		label: "",
		description: "",
	})
	const [subcontractorsNeedRefresh, setSubcontractorsNeedRefresh] = useState(true)

	const [selectedComplianceDocument, setSelectedComplianceDocument] = useState([])

	useEffect(() => {
		if (!haveRole(user, "ROLE_MANAGER")) {
			history.push("/")
			toast.error("Vous n'avez pas accès à cette page.")
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	// useEffect utilisé seulement lors du mount

	useEffect(() => {
		const getSubcontractors = async () => {
			const response = await API.findAll("SUBCONTRACTORS_API")
			setSubcontractors(response)
		}
		getSubcontractors()
		// eslint-disable-next-line
	}, [])

	const handleSubcontractorRowClick = (subcontractor) => {
		setSubcontractor(subcontractor)
		setSubContractorModalOpen(true)
	}

	const handleComplianceDocument = async (ComplianceDocument) => {
		try {
			setIsLoading(true)

			const response = await API.findAll("AUDIO_WIZARD_DOCUMENTS_API")
			const document = response.find((doc) => doc.type === ComplianceDocument.documentType)

			if (!document.filePath) {
				toast.error(`Le document ${getDocumentType(ComplianceDocument.documentType)} n'est pas disponible`)
			} else {
				setSelectedComplianceDocument(document)
				setDocumentModalOpen(true)
			}

			setIsLoading(false)
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Col>
			<SectionHeader title="Sous-traitants">
				<ButtonRounded
					color="primary-outlined"
					onClick={() => {
						setSubcontractor({ category: subcontractors?.[0]?.category })
						setSubContractorModalOpen(true)
					}}
					icon="fa-plus"
					type="submit">
					Ajouter un sous-traitant
				</ButtonRounded>
			</SectionHeader>
			<Row>
				<Col md="12">
					<DataTable
						columns={columnsSubcontractors}
						target="sous-traitants"
						onRowClick={handleSubcontractorRowClick}
						refresh={{
							needRefresh: subcontractorsNeedRefresh,
							setNeedRefresh: setSubcontractorsNeedRefresh,
						}}
					/>
				</Col>
				<Col md="12">
					<SubcontractorsModal
						onClose={() => setSubContractorModalOpen(false)}
						isOpen={subContractorModalOpen}
						subcontractor={subcontractor}
						setSubcontractor={setSubcontractor}
						subcontractors={subcontractors}
						refreshSetter={setSubcontractorsNeedRefresh}
					/>
				</Col>
			</Row>
			<SectionHeader title="Conditions d'utilisation" />
			<Row>
				<Col md="12">
					<DataTable
						columns={columnsComplianceDocuments}
						target="agreements"
						refresh={{
							needRefresh: subcontractorsNeedRefresh,
							setNeedRefresh: setSubcontractorsNeedRefresh,
						}}
						onRowClick={handleComplianceDocument}
					/>
					<ComplianceDocumentViewer
						loading={isLoading}
						isOpen={DocumentModalOpen}
						viewOnly={true}
						document={selectedComplianceDocument}
						handleClose={() => setDocumentModalOpen(false)}
					/>
				</Col>
			</Row>
		</Col>
	)
}

export default withRouter(SousTraitant)
