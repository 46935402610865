import { Affiliation, Laboratory, User, UserRole } from "@audiowizard/common"
import { Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { SorterResult } from "antd/lib/table/interface"
import ButtonRounded from "components/Buttons/ButtonRounded"
import { useAllLaboratoriesQuery } from "components/Hooks/commonQueries"
import useHasRole from "components/Hooks/useHasRole"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { Badge, Col } from "reactstrap"
import API, { CollectionResponse } from "services/API"
import AuthContext from "../../../contexts/AuthContext"
import EditUserModal from "./EditUserModal"
import NewUserModal from "./NewUserModal"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { uniqBy } from "lodash"

export type TeamUser = User & { hasValidated: boolean }

type ListeEquipeProps = {
	affiliations: Affiliation[] | undefined
	isLoadingAffiliations: boolean
}

type UserFilters = {
	laboratories?: number[]
	lastName?: string[]
	firstName?: string[]
	email?: string[]
	roles?: UserRole[]
}

export const roleLabel: Record<UserRole, string> = {
	ROLE_USER: "Audioprothésiste",
	ROLE_ASSISTANT: "Assistant",
	ROLE_MANAGER: "Manager",
	ROLE_MANAGER_FRANCHISED: "Manager laboratoire",
	ROLE_AFFILIATE: "Affilié",
	ROLE_AFFILIATE_MANAGER: "Manager affilié",
	ROLE_ADMIN: "Administrateur AudioWizard",
}

export function ListeEquipe({ affiliations, isLoadingAffiliations }: ListeEquipeProps): JSX.Element {
	const [editUserModal, setEditUserModal] = useState<{ open: boolean; user: TeamUser | null }>({
		open: false,
		user: null,
	})
	const [page, setPage] = useState(1)
	const [filters, setFilters] = useState<null | UserFilters>(null)
	const [sort, setSort] = useState<SorterResult<TeamUser>>()
	const [allUsers, setAllUsers] = useState<User[]>()

	useEffectAsync(async () => {
		const users = (
			await API.findAll<User[]>(
				"USERS_API",
				"/with_laboratories?isArchived[]=0&isArchived[]=1&pagination=false",
				true
			)
		)["hydra:member"]
		setAllUsers(users)
	}, [])

	const { data: usersResponseOrNull, isLoading } = useQuery<CollectionResponse<TeamUser[]>>(
		["USERS_API", "with_laboratories", { filters, page, sort }],
		async () => {
			const searchParams = new URLSearchParams()
			searchParams.set("page", page.toString())

			searchParams.append("isArchived[]", "0")
			searchParams.append("isArchived[]", "1")

			if (sort?.order != null) {
				searchParams.append(`order[${sort?.column?.dataIndex}]`, sort.order === "descend" ? "desc" : "asc")
			}
			if (filters?.laboratories != null) {
				for (const id of filters?.laboratories) searchParams.append("laboratories[]", id.toString())
			}

			if (filters?.lastName != null) {
				for (const lastName of filters?.lastName) searchParams.append("lastName[]", lastName)
			}

			if (filters?.firstName != null) {
				for (const firstName of filters?.firstName) searchParams.append("firstName[]", firstName)
			}

			if (filters?.email != null) {
				for (const email of filters?.email) searchParams.append("email[]", email)
			}

			if (filters?.roles != null) {
				for (const role of filters?.roles) searchParams.append("roles[]", role)
			}
			return API.findAll("USERS_API", `/with_laboratories?${searchParams}`, true)
		},
		{
			onError: () => toast.error("Erreur lors de la récupération des utilisateurs."),
		}
	)
	const users = usersResponseOrNull?.["hydra:member"] ?? []
	const totalUsers = usersResponseOrNull?.["hydra:totalItems"] ?? 0

	// To show as filters
	const { data: laboratories, isLoading: isLoadingLaboratories } = useAllLaboratoriesQuery()

	const columns: ColumnsType<TeamUser> = [
		{
			title: "Nom",
			dataIndex: "lastName",
			width: "20%",
			ellipsis: true,
			sorter: (a, b) => a.lastName!.localeCompare(b.lastName!),
			filters: uniqBy(allUsers, "lastName")?.map((user) => ({ text: user.lastName!, value: user.lastName! })),
			filteredValue: filters?.lastName,
			filterSearch: true,
		},
		{
			title: "Prénom",
			dataIndex: "firstName",
			width: "20%",
			ellipsis: true,
			sorter: (a, b) => a.firstName!.localeCompare(b.firstName!),
			filters: uniqBy(allUsers, "firstName")?.map((user) => ({ text: user.firstName!, value: user.firstName! })),
			filteredValue: filters?.firstName,
			filterSearch: true,
		},
		{
			title: "Email",
			dataIndex: "email",
			width: "20%",
			ellipsis: true,
			sorter: (a, b) => a.email!.localeCompare(b.email!),
			filters: uniqBy(allUsers, "email")?.map((user) => ({ text: user.email!, value: user.email! })),
			filteredValue: filters?.email,
			filterSearch: true,
		},
		{
			title: "Rôle",
			dataIndex: "roles",
			width: "20%",
			ellipsis: true,
			filters: Object.keys(roleLabel).map((role) => ({ text: roleLabel[role as UserRole], value: role })),
			filteredValue: filters?.roles,
			filterSearch: true,
			render: (role, _user) => (
				<Badge color="light">
					{roleLabel[role as UserRole]} {_user.isArchived && <span>(Inactif)</span>}
				</Badge>
			),
		},
		{
			title: "Laboratoires",
			dataIndex: "laboratories",
			width: "20%",
			render: (laboratories: Laboratory[]) => (
				<ul className="list-unstyled">
					{laboratories.map((lab) => (
						<li key={lab.id} className="my-2">
							<Badge color="secondary">{lab.label}</Badge>
						</li>
					))}
				</ul>
			),
			filters: laboratories?.map((l) => ({ text: l.label!, value: l.id! })),
			filteredValue: filters?.laboratories,
			filterSearch: true,
		},
		{
			title: "Statut",
			dataIndex: "gdprAgreement",
			width: "15%",
			ellipsis: true,
			render: (gdprAgreement: boolean) =>
				gdprAgreement ? (
					<Badge color="primary">Compte validé</Badge>
				) : (
					<Badge color="warning">En attente</Badge>
				),
		},
		{
			title: "Confirmation d'inscription",
			dataIndex: "id",
			width: "20%",
			render: (_, user) => {
				if (user?.gdprAgreement) return <Badge color="primary">Inscription finalisée</Badge>
				else
					return (
						<Badge
							color="warning"
							onClick={async (event) => {
								event.stopPropagation()
								try {
									await API.update("USERS_API", user?.id + "/invite", {})
									toast.success(`Email de confirmation envoyé à ${user?.email}`)
								} catch (e) {
									toast.error("Erreur lors de l'envoi de l'email")
									console.log(e)
								}
							}}>
							Réinviter
						</Badge>
					)
			},
		},
	]

	return (
		<>
			<div className="table-responsive">
				<Table
					columns={columns}
					dataSource={users}
					rowKey={(record) => record.email!}
					pagination={{ position: ["bottomLeft"], total: totalUsers, current: page, showSizeChanger: false }}
					loading={isLoading || isLoadingLaboratories}
					rowClassName="cursor-pointer"
					onRow={(user) => ({ onClick: () => setEditUserModal({ open: true, user }) })}
					onChange={(pagination, filters, sorter) => {
						setPage(pagination.current!)
						setFilters(filters)
						setSort(sorter as SorterResult<TeamUser>)
					}}
				/>
			</div>
			<EditUserModal
				isLoadingAffiliations={isLoadingAffiliations}
				affiliations={affiliations}
				open={editUserModal.open}
				onClose={() => setEditUserModal({ open: false, user: null })}
				user={editUserModal.user}
			/>
		</>
	)
}

export default function MonEquipe(): JSX.Element {
	const { user } = useContext(AuthContext)
	const isManager = useHasRole("ROLE_MANAGER")
	const isFranchisedManager = useHasRole("ROLE_MANAGER_FRANCHISED")

	const { data: affiliations, isLoading: isLoadingAffiliations } = useQuery<Affiliation[]>(
		"AFFILIATIONS_API",
		async () => {
			return await API.findAll("AFFILIATIONS_API")
		},
		{ staleTime: Infinity }
	)

	const [newUserModalOpen, setNewUserModalOpen] = useState(false)

	useEffect(() => {
		if (!user?.company?.["@id"] == null) return

		if (isFranchisedManager || isManager) return

		window.location.href = "/"
	}, [user, isManager, isFranchisedManager])

	return (
		<Col>
			<SectionHeader title="Équipe">
				<ButtonRounded
					type="button"
					color="primary-outlined"
					icon="fa-plus"
					onClick={() => setNewUserModalOpen(true)}>
					Ajouter un utilisateur
				</ButtonRounded>
			</SectionHeader>

			<ListeEquipe affiliations={affiliations} isLoadingAffiliations={isLoadingAffiliations} />

			<NewUserModal
				isLoadingAffiliations={isLoadingAffiliations}
				affiliations={affiliations}
				open={newUserModalOpen}
				onClose={() => setNewUserModalOpen(false)}
			/>
		</Col>
	)
}
