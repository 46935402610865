import { SmsReminderSettings } from "@audiowizard/common"
import { Editor as TinyReactEditor } from "@tinymce/tinymce-react"
import { Switch } from "antd"
import ContextEditor from "components/containers/ContextEditor/ContextEditor"
import FieldWithError from "components/forms/FieldWithError"
import SpinnerAW from "components/utils/SpinnerAW"
import { LocaleNamespace } from "config/intl/helpers"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useTranslation } from "hooks/specific/useTranslation"
import _ from "lodash"
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { toast } from "react-toastify"
import { Col, Row } from "reactstrap"
import API from "services/API"
import { formatPhoneNumber } from "services/functions"
import "./SmsReminderSettingsEdit.scss"

const TEMPLATE_MAX_LENGTH = 500

const editorPaths = [
	"user.firstName",
	"user.lastName",
	"patient.firstName",
	"patient.lastName",
	"patient.title",
	"schedule.datetime",
	"schedule.date",
	"schedule.time",
	"schedule.hoursUntil",
	"laboratory.label",
	"laboratory.fullAddress",
	"laboratory.email",
	"laboratory.phone",
]

type SmsTemplateEditorProps = {
	error: boolean
	onChange: (value: string) => void
}
type SmsTemplateEditorRef = {
	// Content can only be set manually via ref to prevent caret from jumping back to the start at every change
	setContent: (value: string) => void
}
const SmsTemplateEditor = forwardRef<SmsTemplateEditorRef, SmsTemplateEditorProps>(function SmsTemplateEditor(
	{ error, onChange }: SmsTemplateEditorProps,
	ref
): JSX.Element {
	const t = useTranslation(LocaleNamespace.ReminderSMS)

	const [content, setContent] = useState("")
	const editorRef = useRef<TinyReactEditor>(null)

	const store = useMemo(
		() => ({
			custom: {
				user: {
					firstName: t("user.firstName"),
					lastName: t("user.lastName"),
				},
				patient: {
					title: t("patient.title"),
					firstName: t("patient.firstName"),
					lastName: t("patient.lastName"),
				},
				schedule: {
					datetime: t("schedule.datetime"),
					date: t("schedule.date"),
					time: t("schedule.time"),
					hoursUntil: t("schedule.hoursUntil"),
				},
				laboratory: {
					label: t("laboratory.label"),
					fullAddress: t("laboratory.fullAddress"),
					email: t("laboratory.email"),
					phone: t("laboratory.phone"),
				},
			},
			localeConfig: { ns: LocaleNamespace.ReminderSMS },
		}),
		[]
	)

	useImperativeHandle(ref, () => ({
		setContent: (content) => {
			setContent(content.replaceAll("\n", "<br />")) // \n -> <br />
		},
	}))

	/** Reconstruct template with #() variables syntax */
	const handleChange = (): void => {
		const doc = editorRef.current?.editor?.getDoc()
		if (doc == null) return

		const templateFromNode = (node: Node): string => {
			if (node.nodeType === Node.TEXT_NODE) {
				// Text node, return self value
				return node.nodeValue as string
			} else if (node.nodeType === Node.ELEMENT_NODE) {
				const el = node as HTMLElement
				if (el.dataset.key != null) {
					// Variable element (based on data-key attribute), reconstruct #() syntax
					return `#(${el.dataset.key})`
				} else if (el?.tagName === "BR") {
					// <br/> return a newline
					return "\n"
				} else {
					// Other element recurse through children nodes
					return [...el.childNodes].reduce((str, node) => str + templateFromNode(node), "")
				}
			}
			return ""
		}
		const template = templateFromNode(doc.body)

		onChange(template)
	}

	return (
		<ContextEditor
			ref={editorRef}
			className={error ? "border border-danger" : undefined}
			init={{
				toolbar: false,
				menubar: false,
				resize: false,
				height: "100%",
			}}
			paths={editorPaths}
			store={store}
			content={content}
			onChange={handleChange}
		/>
	)
})

type TemplatePreviewBubbleProps = {
	template: string
	hoursBeforeReminder: number
}
function TemplatePreviewBubble({ template, hoursBeforeReminder }: TemplatePreviewBubbleProps): JSX.Element {
	const { user, laboratory, laboratories } = useContext(AuthContext)

	const scheduleDateOf = useMemo(() => dayjs().add(hoursBeforeReminder, "hours"), [hoursBeforeReminder])

	const message = useMemo(() => {
		const lab = laboratory ?? laboratories[0]

		// Generated data to fill template for preview purposes
		const sampleData: Record<string, string> = {
			"user.firstName": user.firstName!,
			"user.lastName": user.lastName!,
			"patient.firstName": "John",
			"patient.lastName": "Doe",
			"patient.title": "M.",
			"schedule.datetime": scheduleDateOf.format("DD/MM/YYYY HH:mm"),
			"schedule.date": scheduleDateOf.format("DD/MM/YYYY"),
			"schedule.time": scheduleDateOf.format("HH:mm"),
			"schedule.hoursUntil": hoursBeforeReminder.toString(),
			"laboratory.label": lab.label!,
			"laboratory.fullAddress": `${lab.adress}, ${lab.cpo}, ${lab.city}`,
			"laboratory.email": lab.email ?? "laboratoire@email.exemple",
			"laboratory.phone": formatPhoneNumber(lab.phone ?? "+33601234567"),
		}

		// Fill template with sampleData
		return template.replace(/#\(([\w.]+)\)/g, (match, p1: string) => sampleData[p1])
	}, [user, template, scheduleDateOf])

	return (
		<article>
			<h6>Aperçu</h6>
			<p className="sms-template-preview-bubble">{message}</p>
		</article>
	)
}

export default function SmsReminderSettingsEdit(): JSX.Element {
	const { user } = useContext(AuthContext)

	const [senderName, setSenderName] = useState("")
	const [template, setTemplate] = useState("")
	const [hoursBeforeReminder, setHoursBeforeReminder] = useState(24)
	const [isEnabled, setIsEnabled] = useState(false)
	const [loading, setLoading] = useState(false)
	const editorRef = useRef<SmsTemplateEditorRef>(null)
	const updateTimeoutRef = useRef<number>(-1) // To debounce updateSettings
	const mountedRef = useRef(false) // To avoid running updateSettings on mount
	const fetchingRef = useRef(false) // To avoid running updateSettings on fetch

	const senderNameError = !/^[a-zA-Z0-9 -]{0,11}$/.test(senderName)

	useEffect(() => {
		const fetchSettings = async (): Promise<void> => {
			if (user.company?.smsReminderSettings?.id == null) return

			try {
				setLoading(true)
				fetchingRef.current = true

				const settings = await API.find<SmsReminderSettings>(
					"SMS_REMINDER_SETTINGS_API",
					user.company.smsReminderSettings.id
				)

				setSenderName(settings.senderName)
				setTemplate(settings.messageTemplate)
				editorRef.current?.setContent(settings.messageTemplate)
				setHoursBeforeReminder(settings.hoursBeforeReminder)
				setIsEnabled(settings.isEnabled)

				setLoading(false)
				fetchingRef.current = false
			} catch (err) {
				console.error(err)
				toast.error("Erreur lors de la récupération des paramètres")
			}
		}

		fetchSettings()
	}, [user])

	useEffect(() => {
		// Do not run this useEffect on mount
		if (!mountedRef.current) {
			mountedRef.current = true
			return
		}

		// Do not run this useEffect if fetching data
		if (fetchingRef.current) return
		// Do not run if error in senderName field
		if (senderNameError) return

		const updateSettings = async (): Promise<void> => {
			if (user.company?.smsReminderSettings?.id == null) return
			if (template.length > TEMPLATE_MAX_LENGTH) return // Do not update if template is too long

			try {
				await API.update("SMS_REMINDER_SETTINGS_API", user.company.smsReminderSettings.id, {
					senderName,
					messageTemplate: template,
					hoursBeforeReminder,
					isEnabled,
				})

				toast.success("Paramètres mise à jour")
			} catch (err) {
				console.error(err)
				toast.error("Erreur lors de la mise à jour des paramètres")
			}
		}

		clearTimeout(updateTimeoutRef.current)
		updateTimeoutRef.current = window.setTimeout(updateSettings, 3000) // 3 seconds debounce
	}, [user, template, hoursBeforeReminder, senderName, isEnabled])

	return (
		<>
			{loading && <SpinnerAW text="Chargement des paramètres..." />}
			Envoyer des rappels SMS aux patients avant leur rendez-vous :{" "}
			<Switch
				checked={isEnabled}
				onChange={setIsEnabled}
				checkedChildren="Activé"
				unCheckedChildren="Désactivé"
				disabled={
					!isEnabled && // Allow unchecking
					(!user.company?.audioWizardContract?.hasMandate || user.company?.audioWizardContract?.isFree)
				}
			/>
			{!user.company?.audioWizardContract?.hasMandate && (
				<p className="text-danger">
					Vous ne pouvez pas activer les rappels SMS tant que votre compte n'est pas complétement configuré.
				</p>
			)}
			{user.company?.audioWizardContract?.isFree && (
				<p className="text-danger">
					Vous ne pouvez pas activer les rappels SMS tant que votre contrat est gratuit.
				</p>
			)}
			<p>Vous pouvez consulter le détail des tarifs SMS dans la documentation.</p>
			<hr />
			<FieldWithError
				className="mx-auto"
				labelClassName="h5"
				label="Nom de l'expéditeur"
				name="senderName"
				type="text"
				maxLength={11}
				pattern="^[a-zA-Z0-9 -]{0,11}$"
				value={senderName}
				onChange={(e) => setSenderName(e.target.value)}
				// Show helpText if there is no error.
				helpText={
					!senderNameError
						? "Le nom de l'expéditeur est limité à 11 caractères. Uniquement des lettres sans accents, des chiffres, des espaces et des tirets."
						: undefined
				}
				error={
					senderNameError
						? "Le nom de l'expéditeur est limité à 11 caractères. Uniquement des lettres sans accents, des chiffres, des espaces et des tirets."
						: undefined
				}
			/>
			<hr />
			<label className="h5" htmlFor="hoursBeforeReminder">
				Fréquence de rappel
			</label>
			<p className="d-flex align-items-center">
				Prévenir les patients{" "}
				<input
					id="hoursBeforeReminder"
					className="form-control form-control-sm w-auto mx-1"
					type="number"
					min="1"
					max="48"
					value={hoursBeforeReminder}
					onChange={(e) => setHoursBeforeReminder(e.target.valueAsNumber)}
					onBlur={(e) => setHoursBeforeReminder(_.clamp(e.target.valueAsNumber, 1, 48))} // Force limit between 1 and 48
				/>{" "}
				heures avant leur rendez-vous
			</p>
			<hr />
			<h5>Message</h5>
			<Row className="align-items-center">
				<Col md={9} className="sms-template-editor-wrapper align-self-start mb-4">
					<p className={template.length > TEMPLATE_MAX_LENGTH ? "text-danger" : undefined}>
						Il y a une limite de {TEMPLATE_MAX_LENGTH} caractères sur les messages SMS. (
						<strong>{template.length}</strong>/{TEMPLATE_MAX_LENGTH}) (160 caractères par SMS)
					</p>
					<SmsTemplateEditor
						ref={editorRef}
						error={template.length > TEMPLATE_MAX_LENGTH}
						onChange={setTemplate}
					/>
				</Col>
				<Col md={3}>
					<TemplatePreviewBubble template={template} hoursBeforeReminder={hoursBeforeReminder} />
				</Col>
			</Row>
		</>
	)
}
