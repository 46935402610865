/** Ensemble des fonctions permettant l'appel à l'API et la mise à jour du LocalStorage,
 * les fonctions propres au localStorage sont définies dans le fichier BryntumAgendaLocalStorage
 */

import { Model } from "@bryntum/calendar"
import dayjs from "dayjs"
import { Dispatch, MutableRefObject, SetStateAction } from "react"
import { toast } from "react-toastify"
import _ from "lodash"
import API from "services/API"
import localStorage from "services/localStorage"
import { getTitleEvent } from "./BryntumAgenda.helper"
import { AWCalendar, RetEvents, Event, Agenda, EvtData } from "./AgendaTypes"
import {
	addEventToLocalStorage,
	removeEventFromLocalStorage,
	updateEventInLocalStorage,
} from "./BryntumAgendaLocalStorage"
import { colorFromStr } from "services/functions"

const setRecurrenceRule = (recurrenceRule: string): string | null => {
	if (recurrenceRule?.toUpperCase() === "FREQ=NONE") {
		return null
	}
	return recurrenceRule || null
}

export const getAllEvents = async (agenda: Agenda, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): Promise<Event[]> => {
	const listEvents = []
	let hydra = await API.findAll<Event[]>(
		"SCHEDULES_API",
		`?agenda=${agenda["@id"]}&dateOf[before]=${endDate.format("YYYY-MM-DD")}&dateOf[after]=${startDate.format(
			"YYYY-MM-DD"
		)}&exists[recurrenceRule]=false`,
		true
	)
	listEvents.push(...hydra["hydra:member"])

	while (hydra["hydra:view"]["hydra:next"]) {
		hydra = await API.custom(hydra["hydra:view"]["hydra:next"])
		listEvents.push(...hydra["hydra:member"])
	}

	return listEvents
}

// pour les rdvs récurrents il n’y a qu’un enregistrement en base à la date du premier rdv.
// il faut donc récupérer tous les rdvs récurrents
// alternativement il faudrait que coté back l’on puisse savoir quels sont les rdvs récurrents
// qui apparaissent sur la période.
export const getAllRecurrentEvents = async (agenda: Agenda): Promise<Event[]> => {
	const listEvents = []
	let hydra = await API.findAll<Event[]>(
		"SCHEDULES_API",
		`?agenda=${agenda["@id"]}&exists[recurrenceRule]=true`,
		true
	)
	listEvents.push(...hydra["hydra:member"])

	while (hydra["hydra:view"]["hydra:next"]) {
		hydra = await API.custom(hydra["hydra:view"]["hydra:next"])
		listEvents.push(...hydra["hydra:member"])
	}

	return listEvents
}

// This function is use every time there is a change in the EventStore or the ResourceStore
// We have to filter event to keep only the eventStore events
// When changes are found we update the database
// sometime the librairie trigger severals event successively, we store the change to only trigger one api call
export const onDataChange = async (
	data: { store: any; action: any; records: any; source: AWCalendar; changes: any },
	timeout: MutableRefObject<Record<string, NodeJS.Timeout>>,
	unsavedChange: MutableRefObject<Record<string, Record<string, { oldValue: any; value: any }>>>,
	setSelectedUser?: (d: number) => void,
	setSelectedLaboratory?: (l: number) => void,
	setSelectedDate?: (d: Date[]) => void,
	appointmentFromScheduleSelector?: { id?: number },
	setSelectedSchedule?: Dispatch<SetStateAction<EvtData>>,
	fromDashboard?: boolean
): Promise<void> => {
	const { store, action, records, source, changes } = data
	let dataEvent: Event | undefined
	let event: Event | undefined

	const calendar = source
	// If there isn't any record or that there are more than one
	if (
		!records ||
		records.length === 0 ||
		records.length > 1 ||
		(!records[0].data && !records[0].data.event) ||
		records[0].readOnly ||
		(records[0].isRemoved && action !== "remove") ||
		(records[0].isCreating && action !== "add")
	) {
		return
	}
	// Depending the trigger the event may be store directly in the records property or in the data
	if (records[0].data.event) {
		dataEvent = records[0].data.event.data
		event = records[0].data.event
	} else {
		dataEvent = records[0].data
		event = records[0]
	}

	dataEvent = dataEvent as Event
	// si c'est un objet autre qu'un événement ne rien faire
	if (dataEvent["@type"] && dataEvent["@type"] !== "Schedule") {
		return
	}

	const isNewEvent = /generated/.test(dataEvent.id.toString())

	// from the dashboard we don’t save the schedule here, but on the dashboard
	if (fromDashboard && isNewEvent) {
		if (setSelectedDate) {
			setSelectedDate([dataEvent.startDate, dataEvent.endDate])
		}
	}

	// When updating a event
	// /generated/.test(event.id.toString() permet de tester si bryntum génère un id pour l'event, si oui c'est un nouvel event
	if (action === "update" && !isNewEvent && dataEvent.agenda) {
		if (
			!(
				changes.notes ||
				changes.startDate ||
				changes.endDate ||
				(changes.agenda && changes.agenda.oldValue) ||
				(changes.state && changes.state.oldValue) ||
				changes.patientField ||
				changes.status ||
				changes.resourceId ||
				changes.recurrenceRule ||
				changes.newExceptionDate ||
				changes.isCreating
			)
		) {
			return
		}
		// timeout to avoid séquentiel request on the same event
		clearTimeout(timeout.current[dataEvent.id])
		// we save the changes on the event, some event time change, laboratory or user change may trigger the change of the agenda
		unsavedChange.current[dataEvent.id] = {
			...(unsavedChange.current[dataEvent.id] || {}),
			...changes,
		}
		timeout.current[dataEvent.id] = setTimeout(() => {
			;(async () => {
				if (!dataEvent || !event) return
				const finalChange = unsavedChange.current[dataEvent.id]

				if (finalChange.startDate) {
					calendar.date = finalChange.startDate.value
				}

				// if the user change the agenda need to be update
				if (finalChange.resourceId && !finalChange.agenda) {
					finalChange.agenda = {
						oldValue: _.cloneDeep(dataEvent.agenda),
						value: {},
					}
					dataEvent.agenda =
						calendar.extraData.agendas.find((agenda: Agenda) => {
							if (dataEvent && agenda.user && agenda.laboratory) {
								return (
									agenda.user["@id"].slice(7) === dataEvent.resourceId?.toString() &&
									agenda.laboratory["@id"].split("/")[2] === dataEvent.laboratoryId.toString()
								)
							}
							return false
						})?.["@id"] ?? ""
					if (dataEvent.agenda === "") {
						toast.error(
							"Sauvegarde Impossible, Aucun agenda trouvé pour pour l’utilisateur dans ce laboratoire"
						)
						return
					}
					finalChange.agenda.value = dataEvent.agenda
				}
				// we reset the unsavedChange to avoid effect on next update
				unsavedChange.current[dataEvent.id] = {}
				if (dataEvent.id) {
					const isDoctolib = calendar.extraData.agendas.find((agenda: Agenda) => {
						return agenda["@id"] === dataEvent?.agenda
					})?.doctolibType
					if (isDoctolib != null) {
						try {
							await API.updateDoctolibSchedule(dataEvent.id as string, {
								state: dataEvent.state,
								preScheduleNote: dataEvent.notes,
							})
							toast.success("Rendez-vous mis à jour")
						} catch (err) {
							toast.error("Erreur lors de la mise à jour du RDV")
						}
					} else {
						let exceptionDates: string[] | null = null
						if (dataEvent.exceptionDates) {
							exceptionDates = []
							Object.keys(dataEvent.exceptionDates).forEach((date) => {
								if (!exceptionDates) return
								exceptionDates.push(dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"))
							})
						}
						try {
							// to avoid change during the api call, we block update on the event
							event.set("readOnly", true)
							const res = await API.update("SCHEDULES_API", dataEvent.id, {
								dateOf: dataEvent.startDate,
								dateEnd: dataEvent.endDate,
								preScheduleNote: dataEvent.notes,
								patient: dataEvent.patientField ? "/patients/" + dataEvent.patientField : null,
								// type: event.type,
								status: dataEvent.status,
								state: dataEvent.state,
								agenda: dataEvent.agenda,
								recurrenceRule: setRecurrenceRule(dataEvent.recurrenceRule),
								exceptionDates: (dataEvent.recurrenceRule && exceptionDates) || null,
							})
							const data = res.data as any
							if (dataEvent) {
								const { color: eventColor, label: typeLabel } =
									calendar.extraData.typesStore.getTypeData(dataEvent.status || "")
								calendar.eventStore.getById(dataEvent.id).set({
									name: getTitleEvent(data),
									patientName: getTitleEvent(data),
									patient: data.patient,
									eventColor,
									typeLabel,
									readOnly: false,
									user: data.user,
									laboratory: data.laboratory,
									laboratoryId: data.laboratory.id,
									laboratoryIri: data.laboratory?.["@id"],
								})
								updateEventInLocalStorage(data, false, finalChange.agenda?.oldValue)
								// if we modifie the schedule from the modal we update the state of the schedule
								if (
									appointmentFromScheduleSelector &&
									dataEvent.id === appointmentFromScheduleSelector.id
								) {
									if (setSelectedDate) {
										setSelectedDate([dataEvent.startDate, dataEvent.endDate])
									}
									if (setSelectedLaboratory && dataEvent.laboratory.id) {
										setSelectedLaboratory(dataEvent.laboratory.id)
									}
									if (setSelectedUser && dataEvent.resourceId) {
										setSelectedUser(parseInt(dataEvent.resourceId.toString(), 10))
									}
								}
							}
						} catch (error) {
							console.error(error)
							event.set("readOnly", false)
						}
					}
				}
			})()
		}, 300)
	}

	const createEventFromModal = action === "update" && isNewEvent
	const addEventAfterSplitRecurrentEvent = action === "add" && isNewEvent && dataEvent.recurrenceRule

	// For new event (it is still an update because the event was created in the store before we open the modal)
	if (
		(createEventFromModal || addEventAfterSplitRecurrentEvent) &&
		dataEvent.agenda &&
		dataEvent.status &&
		dataEvent.state &&
		event
	) {
		// après la création d’un rdv, on met à jour l’id avec celui généré par l’api.
		// Cela évite de recréer un second rdv
		if (changes?.eventId) return
		// in the case of a duplication, the laboratoryId is already set, and may need to recalculate the agenda if the resource and the time changed
		// si l’agenda est modifié manuellement, c’est probablement le cas d’une modification de rdv recurrent
		if (dataEvent.laboratoryId && !changes?.agenda) {
			const newAgenda =
				calendar.extraData.agendas.find((agenda: Agenda) => {
					if (dataEvent && agenda.user && agenda.laboratory) {
						return (
							agenda.user["@id"].slice(7) === dataEvent.resourceId?.toString() &&
							agenda.laboratory["@id"] === dataEvent.laboratory?.["@id"]
						)
					}
					return false
				})?.["@id"] || ""
			if (newAgenda) {
				dataEvent.agenda = newAgenda
			}
		}
		event.set("readOnly", true)
		let exceptionDates: string[] | null = null
		if (dataEvent.exceptionDates) {
			exceptionDates = []
			Object.keys(dataEvent.exceptionDates).forEach((date) => {
				if (!exceptionDates) return
				exceptionDates.push(dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"))
			})
		}
		// timeout to avoid séquentiel request on the same event
		// Cela évite la duplication lors du déplacement d’événement récurrent
		clearTimeout(timeout.current[`create_${dataEvent.id}`])
		timeout.current[`create_${dataEvent.id}`] = setTimeout(() => {
			;(async () => {
				if (!dataEvent || !event) return
				try {
					const res = await API.create("SCHEDULES_API", {
						dateOf: dataEvent.startDate,
						dateEnd: dataEvent.endDate,
						preScheduleNote: dataEvent.notes,
						patient: dataEvent.patientField ? "/patients/" + dataEvent.patientField : null,
						// type: event.type,
						status: dataEvent.status,
						state: dataEvent.state,
						agenda: dataEvent.agenda,
						recurrenceRule: setRecurrenceRule(dataEvent.recurrenceRule),
						exceptionDates: (dataEvent.recurrenceRule && exceptionDates) || null,
					})
					const data = res.data as any
					if (dataEvent) {
						const { color: eventColor, label: typeLabel } = calendar.extraData.typesStore.getTypeData(
							dataEvent.status || ""
						)
						updateEventInLocalStorage(data, true)
						calendar.eventStore.getById(dataEvent.id).set({
							id: data.id,
							"@id": data["@id"],
							name: getTitleEvent(data),
							laboratory: data.laboratory,
							laboratoryId: data.laboratory.id,
							patientName: getTitleEvent(data),
							eventColor,
							typeLabel,
							patient: data.patient,
							user: data.user,
							readOnly: false,
							affiliation: data.patient?.user?.affiliation,
						})
						// if we modifie the schedule from the modal we update the state of the schedule
						if (appointmentFromScheduleSelector && !appointmentFromScheduleSelector.id) {
							appointmentFromScheduleSelector.id = data.id
							if (setSelectedDate) {
								setSelectedDate([dataEvent.startDate, dataEvent.endDate])
							}
							if (setSelectedLaboratory && dataEvent.laboratory.id) {
								setSelectedLaboratory(dataEvent.laboratory.id)
							}
							if (setSelectedUser && dataEvent.resourceId) {
								// resourceId is string or number und has to be number
								setSelectedUser(parseInt(dataEvent.resourceId.toString(), 10))
							}
							// si l'on vient de la modale en création de rdv il faut passer le nouveau id
							if (setSelectedSchedule && data.id) {
								setSelectedSchedule((old) => ({ ...old, id: data.id, "@id": data["@id"] }))
							}
						}
					}
				} catch (error) {
					const errorMessage = error?.response?.data?.["hydra:description"]
					toast.error(`Impossible de créer le rendez-vous, erreur: ${errorMessage}`)
					console.error(errorMessage)
					store.remove(dataEvent?.id)
					setTimeout(() => {
						calendar.refresh()
					}, 200)
					event.set("readOnly", false)
				}
			})()
		}, 200)
	}
	if (
		action === "remove" &&
		!/generated/.test(dataEvent.id.toString()) &&
		dataEvent.agenda &&
		data.store.$$name === "EventStore"
	) {
		if (dataEvent.id) {
			try {
				await API.delete(dataEvent["@id"])
				if (dataEvent) removeEventFromLocalStorage(dataEvent)
				toast.success("Rendez-vous supprimé")
			} catch (error) {
				console.error(error)
				toast.error("Erreur lors de la suppression du Rendez-vous")
			}
		}
	}
}

export const formatEvent = (e: Event, calendar: AWCalendar, agenda: Agenda): Event => {
	let title = ""

	if (e?.patient) title = `${e?.patient?.lastName} ${e?.patient?.firstName}`
	else title = "Sans patient"
	let { color: eventColor, label: typeLabel } = calendar.extraData.typesStore.getTypeData(e?.status || "")
	if (typeLabel === "") {
		console.error("Type de rdv non détecté: ", e?.status)
		console.error("Liste disponible:", calendar.extraData.typesStore.data)
		typeLabel = e.status
		eventColor = colorFromStr(e.status)
	}
	// @ts-ignore manque affiliation sur type user
	return {
		...e,
		agenda: agenda["@id"],
		laboratoryId: e?.laboratory?.id as number,
		startDate: dayjs(e.dateOf).toDate(),
		endDate: e?.dateEnd ? dayjs(e.dateEnd).toDate() : dayjs(e.dateOf).toDate(),
		patientField: e.patient ? e.patient.id : undefined,
		patientName: e.patient ? `${e.patient.lastName} ${e.patient.firstName}` : "",
		//resource: e.user.slice(7),
		resourceId: e.user["@id"]?.slice(7),
		name: title || "",
		notes: e.preScheduleNote,
		eventColor,
		typeLabel,
		exceptionDates: (e.recurrenceRule && e.exceptionDates) || null,
		affiliation: e.patient?.user?.affiliation,
	} as Event
}

const setEventInCalendar = (agenda: Agenda, schedules: Event[], calendar: AWCalendar): void => {
	if (!calendar.eventStore) return
	const newEvents = [
		...schedules
			.filter((e) => e.status !== "RELANCE" && !calendar.eventStore.getById(e.id))
			.map((e) => {
				return formatEvent(e, calendar, agenda)
			}),
	]
	schedules
		.filter((e: Event) => e.status !== "RELANCE" && calendar.eventStore.getById(e.id))
		.forEach((e: Event) => {
			calendar.eventStore.getById(e.id).set(formatEvent(e, calendar, agenda))
		})
	calendar.eventStore.add(newEvents, true)
}

const getSchedules = async (
	dateStart: Date,
	dateEnd: Date,
	agendas: Agenda[],
	calendar: any,
	setSchedulesLoaded: (b: boolean) => void,
	setSchedulesLoading: (b: boolean) => void
): Promise<void> => {
	try {
		const promises: Promise<RetEvents>[] = []
		if (agendas.length === 0 || !calendar.extraData) {
			console.warn("agenda est vide")
			if (setSchedulesLoaded) {
				setSchedulesLoaded(true)
			}
			if (setSchedulesLoading) {
				setSchedulesLoading(false)
			}
			return
		}
		agendas.forEach((agenda) => {
			if (!calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]]) {
				calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]] = {}
			}
			const fromStorage = localStorage.getWithExpiry(agenda["@id"])
			if (fromStorage) {
				const { alreadyLoaded, schedules } = fromStorage
				calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]] = alreadyLoaded || {}
				promises.push(
					new Promise((resolve, reject) => {
						setTimeout(
							() =>
								resolve({
									agenda,
									schedules,
								}),
							300
						)
					})
				)
			}
			// on récupère les rdvs réccurents pour les agendas
			if (!calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]]["recurrent"]) {
				promises.push(
					getAllRecurrentEvents(agenda).then((ret) => {
						calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]]["recurrent"] = true
						addEventToLocalStorage(agenda["@id"], {
							alreadyLoaded: calendar.extraData
								? calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]]
								: false,
							schedules: ret,
						})
						return {
							agenda,
							schedules: ret,
						}
					})
				)
			}

			// on récupère les rdvs simples en fonctions des dates
			const mDateStart = dayjs(dateStart)
			const mDateEnd = dayjs(dateEnd)
			let currentDateStart = mDateStart.clone()
			for (let date = mDateStart; date.isBefore(mDateEnd); date = date.add(1, "day")) {
				const dateToFetch = date.format("YYYY-MM-DD")
				const nextDay = date.clone().add(1, "days")

				const lastDayNotLoaded =
					!calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]][dateToFetch] &&
					nextDay.isSame(mDateEnd)
				const endOfRangeToLoad =
					calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]][dateToFetch] &&
					!currentDateStart.isSame(date)
				if (endOfRangeToLoad || lastDayNotLoaded) {
					promises.push(
						getAllEvents(agenda, currentDateStart, nextDay).then((ret) => {
							addEventToLocalStorage(agenda["@id"], {
								alreadyLoaded: calendar.extraData
									? calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]]
									: false,
								schedules: ret,
							})
							return {
								agenda,
								schedules: ret,
							}
						})
					)
				}

				if (!calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]][dateToFetch]) {
					calendar.extraData.agendaAlreadyLoaded.current[agenda["@id"]][dateToFetch] = true
				} else {
					currentDateStart = nextDay.clone()
				}
			}
		})
		Promise.all(promises).then((ret) => {
			ret.forEach(({ agenda, schedules }) => {
				setEventInCalendar(agenda, schedules, calendar)
				// what if the event got deleted
			})

			if (setSchedulesLoaded) {
				setSchedulesLoaded(true)
			}
			setTimeout(() => {
				setSchedulesLoading(false)
				if (!calendar.extraData) return
				calendar.refresh()
			}, 200)
		})
	} catch (e) {
		console.error(e)
	}
}

let loadTimeout: NodeJS.Timeout | null = null
let waitTimeout: NodeJS.Timeout | null = null

export const fetchSchedules = (
	{ dateEnd, dateStart }: { dateEnd: Date; dateStart: Date },
	calendar: AWCalendar,
	setSchedulesLoaded: (b: boolean) => void,
	setSchedulesLoading: (b: boolean) => void
): void => {
	if (waitTimeout) {
		clearTimeout(waitTimeout)
	}
	if (isNaN(dateEnd.getDate()) || isNaN(dateStart.getDate())) {
		waitTimeout = setTimeout(() => calendar?.trigger?.("fetchSchedules"), 100)
	}
	if (!calendar.extraData.allStoresLoaded) return

	// fetch the users selected
	setSchedulesLoading(true)
	const selectedUsers = (calendar.widgetMap.resourceFilter.value as Model[]).map(
		(resource: any) => resource.data["@id"]
	)
	const selectedLaboratories = (calendar.widgetMap.laboratoryFilter.value as Model[]).map((lab: any) =>
		lab.value.toString()
	)
	if (!calendar.extraData.agendas) return
	const selectedAgenda = calendar.extraData.agendas.filter((agenda: Agenda) => {
		return (
			selectedUsers.indexOf(agenda.user["@id"]) !== -1 &&
			selectedLaboratories.indexOf(agenda.laboratory["@id"].slice("/laboratories/".length)) !== -1
		)
	})
	if (!selectedAgenda) {
		return
	}

	// little timeout to avoid fetching the schedules if we change page rapidly
	if (loadTimeout) {
		clearTimeout(loadTimeout)
	}
	loadTimeout = setTimeout(() => {
		getSchedules(dateStart, dateEnd, selectedAgenda, calendar, setSchedulesLoaded, setSchedulesLoading)
	}, 300)
}
