import dayjs from "dayjs"
import _ from "lodash"
import { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useMutation } from "react-query"

import { PatientSocialSecurity, HiboutikProduct, HiboutikProductEntity } from "@audiowizard/common"
import API from "services/API"
import { toast } from "react-toastify"
import { particularSituationsSentences } from "pages/etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"

type Actes = {
	category: string
	patientEquipmentId: number
	product_id: number
	tags: { [energy: string]: string }
}

type TeletransPaymentHeaderProps = {
	tmpTeletransData: { disabledSecu: boolean; actes: Actes[] }
	patientPrescription: { prescriber: { lastName: string; firstName: string }; dateOfAdvice: Date; type: string }
	patientSocialSecurity: PatientSocialSecurity & { ALD: number }
	garantieFSV: { value: string; label: string; garantie: string; percent: number; regime: string }[]
	isBlind: boolean
	isPregnant: boolean
	isTagsMissing: boolean

	onChange: () => void
	setBusy: (arg: boolean) => void
}

type ModifiedHiboutikProductEntity = HiboutikProduct & { patientEquipmentId: number; uuid: string }

export default function TeletransPaymentHeader({
	tmpTeletransData,
	patientPrescription,
	patientSocialSecurity,
	garantieFSV,
	isBlind,
	isPregnant,
	isTagsMissing,
	onChange,
	setBusy,
}: TeletransPaymentHeaderProps): JSX.Element {
	const [isReadyToReload, setIsReadyToReload] = useState(false)

	const productsWithoutTags = useMemo(() => {
		if (!isTagsMissing) return null

		return _.uniqBy(
			(tmpTeletransData?.actes || []).filter((p) => p.category === "AUDITIF" && !p.tags?.energy),
			"product_id"
		) as unknown as ModifiedHiboutikProductEntity[]
	}, [isTagsMissing, tmpTeletransData.actes])

	const productList = useMemo(() => {
		if (!isTagsMissing || productsWithoutTags == null) return null

		return productsWithoutTags.map((p, i) => (
			<li key={p.uuid}>
				<Link
					to={`/mon-compte/catalogue/produit/${p.product_id}`}
					target="_blank"
					rel="noopener noreferrer"
					key={p.uuid}
					onClick={() => {
						i === productsWithoutTags.length - 1 && setIsReadyToReload(true)
					}}>
					{p.product_model}
				</Link>
			</li>
		))
	}, [productsWithoutTags, isTagsMissing])

	const getDates = (cover: PatientSocialSecurity): string | undefined => {
		if (cover?.startDate && cover?.endDate)
			return `${dayjs(cover?.startDate).format("DD/MM/YYYY")} au ${dayjs(cover?.endDate).format("DD/MM/YYYY")}`
		if (cover?.startDate && !cover?.endDate)
			return `${dayjs(cover?.startDate).format("DD/MM/YYYY")} sans date de fin`
		if (!cover?.startDate && cover?.endDate) return `Valable jusqu'au ${dayjs(cover?.endDate).format("DD/MM/YYYY")}`
		if (!cover?.startDate && !cover?.endDate) return "Pas de date de fin de validité"
	}

	const { mutateAsync: handleSubmit } = useMutation(
		async () => {
			if (productsWithoutTags == null) return
			setBusy(true)

			for (const product of productsWithoutTags) {
				const tags = (await API.find<HiboutikProductEntity>("PRODUCTS_API", product.product_id))?.tags

				const sameEquipments = tmpTeletransData.actes.filter((acte) => acte.product_id === product.product_id)

				for await (const eq of sameEquipments) {
					await API.update("PATIENT_EQUIPMENTS_API", eq.patientEquipmentId!, {
						customTags: tags,
					})
				}
			}
		},
		{
			onSuccess: async () => {
				setBusy(false)
				onChange()
			},
			onError: () => {
				setBusy(false)
				toast.error("Un problème est survenu lors de la mise à jour des tags des équipements.")
			},
		}
	)

	if (isTagsMissing && productsWithoutTags != null) {
		const ProductsToModify = (): JSX.Element => {
			if (productsWithoutTags.length === 0) return <></>

			return (
				<>
					<p>
						Veuillez cliquer sur les noms de produit pour être redirigés vers les pages catalogue
						pertinentes:
					</p>
					<div className="ml-4">{productList}</div>
					<br />
					<button className="btn btn-primary" disabled={!isReadyToReload} onClick={() => handleSubmit()}>
						Actualiser
					</button>
				</>
			)
		}

		return (
			<>
				<div className="alert alert-warning">
					Les produits ne peuvent pas être transmis, des informations des appareils auditifs sont manquantes.
					<br />
					Veuillez vérifier les paramètres produit (énergie, classe et nombre de canaux) dans votre catalogue.
				</div>

				<ProductsToModify />
			</>
		)
	}

	return (
		<div className="alert alert-primary">
			{tmpTeletransData.disabledSecu && (
				<>
					<div>
						Seule la télétransmission <strong>hors tiers-payant</strong> est possible pour la part sécu.
					</div>
					<hr />
				</>
			)}
			Dr. {patientPrescription?.prescriber?.lastName} {patientPrescription?.prescriber?.firstName} -{" "}
			{dayjs(patientPrescription?.dateOfAdvice).format("DD-MM-YYYY")} ({patientPrescription?.type})
			{Object.keys(patientSocialSecurity || {}).length !== 0 && (
				<>
					<div>
						<strong>Date de couverture AMO</strong> : {getDates(patientSocialSecurity)}
					</div>
					<div>
						<strong>Code d'exonération AMO</strong> :{" "}
						{garantieFSV.find((f) => f.value === patientSocialSecurity?.situationCode)?.label} {" | "}{" "}
						{garantieFSV.find((f) => f.value === patientSocialSecurity?.situationCode)?.percent} % audition
					</div>
					{isPregnant && (
						<>
							<hr />
							<div>{particularSituationsSentences.MATERNITY}</div>
						</>
					)}
					{isBlind && (
						<div>
							<hr />
							<strong>{particularSituationsSentences.BLINDNESS}</strong>
						</div>
					)}
					{patientSocialSecurity.ALD !== 0 ? (
						<div>
							<hr />
							<strong>
								Le patient bénéficie d'une ald, si elle est applicable à l'audioprothèse veuillez
								l'indiquer en cochant les cases ci-dessous
							</strong>
						</div>
					) : null}
				</>
			)}
		</div>
	)
}
