import React from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap"
import PropTypes from "prop-types"

interface PropChangModal {
	onConfirm?: () => void
	onClose?: () => void
	updateDetected: boolean
}

const ChangeModal: React.FC<PropChangModal> = ({ onConfirm, onClose, updateDetected }) => {
	return (
		<Modal isOpen={updateDetected} backdrop="static">
			<ModalHeader>
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-ear" /> {/* <-- ICON */}
					</div>

					<h6 className="mb-0">Modification non sauvegardée</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				<p>Des changements non sauvegardés vont être perdus.</p>
				<p>Souhaitez vous changer de modèle, les changements non sauvegardés seront perdus ?</p>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={onClose}>
					Annuler
				</Button>
				<Button color="primary" onClick={onConfirm}>
					Confirmer
				</Button>
			</ModalFooter>
		</Modal>
	)
}

ChangeModal.propTypes = {
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
	updateDetected: PropTypes.bool.isRequired,
}

export default ChangeModal
