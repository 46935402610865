import { Button, Card, Input, Spin } from "antd"
import dayjs from "dayjs"
import DOMPurify from "dompurify"
import { useEffect, useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import API from "../../services/API"

export function mergePatientAndScheduleNotes(patient) {
	const div = document.createElement("div") // used to remove html using innerHTML/innerHTML APIs. Ugly, I know

	const scheduleNotes = []
	for (const d of patient.schedules) {
		div.innerHTML = null

		if (d.preScheduleNote) {
			// removing html tag from note
			div.innerHTML = d.preScheduleNote
		}
		if (div.innerHTML)
			scheduleNotes.push({
				text: div.innerText,
				IsReminder: true,
				createdAt: d.dateOf,
				status: d.status,
				createdBy: {
					firstName: d.user?.firstName ?? "",
					lastName: d.user?.lastName ?? "",
				},
			})
	}
	const mergedNotes = (patient?.patientNotes ?? []).concat(scheduleNotes)
	mergedNotes.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
	return mergedNotes
}

export const TableauDeBordBlocNotes = ({ record, setNoteKey, tableData, test }) => {
	const queryClient = useQueryClient()
	const [input, setInput] = useState("")
	const inputNoteRef = useRef(null)
	const bottom = useRef(null)

	const { data: notes, isLoading } = useQuery(
		["PATIENTS_NOTES_AND_SCHEDULES_API", record["patient.id"]],
		async () => await API.fetchPatientNotesAndSchedules(record["patient.id"]),
		{
			select: (patient) => mergePatientAndScheduleNotes(patient),
		}
	)

	const { mutate: addNote } = useMutation((params) => API.create(params.route, params.data), {
		onSuccess: () => {
			toast.success("La note a été ajoutée !")
			return queryClient.invalidateQueries("PATIENTS_NOTES_AND_SCHEDULES_API")
		},
		onError: () => {
			toast.error("Erreur lors l'ajout de la note.")
		},
	})

	const handleNoteSubmit = async ({ key, type }) => {
		if (type === "keypress" && key !== "Enter") return

		const note = input
		setInput("")

		if (!note) return
		if (note.length >= 255) {
			toast.error("Error: La taille maximal autorisé est de 255 caractères. Actuellement : " + note.length)
			return
		}

		addNote({
			route: "PATIENT_NOTES_API",
			data: {
				IsReminder: true,
				text: note,
				patient: "/patients/" + record["patient.id"],
			},
		})
	}
	useEffect(() => {
		bottom.current.scrollIntoView({ behavior: "smooth" })
	}, [tableData])

	return (
		<div className="dashboard-bloc-notes">
			<Card
				title={`${record["patient.lastName"]} ${record["patient.firstName"]}`}
				className="dashboard-notes-card">
				<Button title="Fermer et sauvegarder" className="close-icon notes-close-icon" type="link" onClick={() => setNoteKey("")}>
					<i className="fas fa-times" />
				</Button>
				<div className="notes-container" id="chatList">
					{isLoading && <Spin />}

					{notes?.map((note, index) => {
						const isAssistant = note?.createdBy?.roles
							? note?.createdBy?.roles.includes("ROLE_ASSISTANT")
							: false
						const isAudio = note?.createdBy?.roles ? note?.createdBy?.roles.includes("ROLE_USER") : false
						return (
							<div tabIndex={index} key={index} className="dashboard-note">
								<div className="notes-container-header">
									{note.createdBy.firstName && note.createdBy.lastName ? (
										<span className="user-name">
											{note.createdBy.firstName +
												" " +
												note.createdBy.lastName +
												(isAssistant ? " - Assistant.e" : isAudio ? " - Audioprothésiste" : "")}
										</span>
									) : (
										<span />
									)}
									<span className={!isAssistant && !isAudio ? "date-note-center" : "date-note-right"}>
										{dayjs(note.createdAt).format("DD/MM/YY - hh:mm")}
									</span>
								</div>
								<div>
									<p
										className={isAssistant || isAudio ? "self" : "other"}
										style={{
											backgroundColor:
												note?.status && test && test[note.status]
													? test[note.status].color
													: isAssistant
														? "gray"
														: "lightgray",
											color: note?.status ? "white" : isAssistant || isAudio ? "white" : "black",
										}}
										dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note.text) }}
									/>
								</div>
							</div>
						)
					})}
					<div ref={bottom} />
				</div>
				<div className={"bloc-notes-footer"}>
					<Input
						ref={inputNoteRef}
						type="text"
						placeholder="Ajouter une note..."
						className="dashboard-note-input"
						onChange={(e) => setInput(e.target.value)}
						value={input}
						onKeyPress={handleNoteSubmit}
						autoFocus
					/>
					<Button type="link" onClick={handleNoteSubmit}>
						<i className="fad fa-plus fa-lg" />
					</Button>
				</div>
			</Card>
		</div>
	)
}
