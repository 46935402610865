/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "reactstrap"

import Modal from "./Modal"

const ConfirmModal = ({ id, text, onValid, onInvalid = () => void 0 }) => {
	return (
		<Modal id={"modal-confirmation" + (id || "")} size={"700"}>
			<div className="text-center">
				<p>{text}</p>
			</div>
			<Row className="text-center">
				<Col md={6}>
					<button
						className="m-2 btn btn-outline-danger btn-block"
						data-dismiss="modal"
						onClick={(event) => onInvalid(event)}
						aria-label="Close"
						aria-hidden="true"
						data-cy={`non${id}`}>
						Non
					</button>
				</Col>
				<Col md={6}>
					<button
						data-cy={`oui${id}`}
						data-dismiss="modal"
						onClick={(event) => onValid(event)}
						className="m-2 btn btn-outline-primary btn-block">
						Oui
					</button>
				</Col>
			</Row>
		</Modal>
	)
}

export default ConfirmModal
