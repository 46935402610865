/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, notification, Row, Select, Switch, Tag } from "antd"
import { documentTypeLabel } from "datas/items/itemsDocumentsAvailable"
import dayjs from "dayjs"
import getAccessoryCount from "pages/FSV/AccessoryCount"
import { Fragment, useContext, useEffect, useState, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import useEffectAsync from "../../components/Hooks/useEffectAsync"
import AuthContext from "../../contexts/AuthContext"
import { facturerSilent, transmettreFacture } from "../../pages/FSV/functionsFSV"
import API from "../../services/API"
import API_Hiboutik from "../../services/API_Hiboutik"
import { formatCurrency, getCustomTags, rounded } from "../../services/functions"
import { findActiveAMx, urlFacturation, urlTeletransmission } from "../FSV/utilsFSV"
import TeletransAttachment from "./Modal.TeletransAttachment"
import { calcTeletransAmount } from "./_Utility"
import { garanties as garantieFSV } from "pages/FSV/garantiesFSV"
import TeletransPaymentHeader from "./Component/TeletransPaymentHeader"
import { getProductLPPWithPatient } from "pages/feuille-soin/lpp/lppHandler"
import { modeTeletrans } from "pages/FSV/FSV.type"
import { useGetTpSwitchStatus } from "./teletransPaymentUtils"
import { toast } from "react-toastify"

const { Option, OptGroup } = Select

const ModalTeletransPayment = ({
	selectedProducts,
	selectedPatient,
	open,
	setOpen,
	teletransData,
	setTeletransData,
	busy,
	setBusy,
	error,
	setError,
	closedSaleDocument,
	setParentOpen,
	publicAmc,
	domTomPrices,
	isBlind,
	isPregnant,
}) => {
	const { user, laboratory } = useContext(AuthContext)

	const [paymentsList] = useState([])
	const [supldata, setSupldata] = useState({})
	const [patientSocialSecurity, setPatientSocialSecurity] = useState({})
	const [patientInsurances, setPatientInsurances] = useState({})
	const [products, setProducts] = useState([])
	const [tmpTeletransData, setTmpTeletransData] = useState({})
	const [isOpenPJ, setIsOpenPJ] = useState(false)
	const [loading, setLoading] = useState(false)
	const [patientPrescription, setPatientPrescription] = useState({})
	const [accessoryCountValues, setAccessoryCountValues] = useState([])
	const [reload, setReload] = useState(false)

	const history = useHistory()

	useEffectAsync(async () => {
		if (!open || !selectedPatient) return
		const activeAMx = await findActiveAMx(selectedPatient, false)
		setPatientSocialSecurity(activeAMx.amo)
		setPatientInsurances(activeAMx.amc)
		if (publicAmc) {
			setTmpTeletransData((old) => ({
				...old,
				secu: true,
				secuTierPayant: true,
				mutl: true,
				mutlTierPayant: true,
			}))
		} else if (!teletransData?.secu) {
			setTmpTeletransData((old) => ({
				...old,
				secu: true,
				secuTierPayant: true,
				mutl: false,
				mutlTierPayant: false,
			}))
		}
	}, [open, selectedPatient, selectedPatient, teletransData])

	useEffectAsync(async () => {
		const accessoryCount = await getAccessoryCount(selectedPatient, selectedProducts)
		setAccessoryCountValues(accessoryCount)
	}, [selectedPatient?.id, selectedPatient?.patientEquipments, selectedProducts])

	useEffect(() => {
		if (accessoryCountValues?.length !== 0) {
			setAccessoryCountValues([])
		}
	}, [selectedPatient.id])

	const [secuTierPayant, mutlTierPayant] = useGetTpSwitchStatus(tmpTeletransData, selectedPatient, closedSaleDocument)

	useEffectAsync(async () => {
		if (!open || !selectedProducts?.length) return

		const categories = await API_Hiboutik.getAllCategories()
		const tags = await API_Hiboutik.getAllTags()
		const suppliers = await API_Hiboutik.getSuppliers()

		setSupldata({ categories, tags, suppliers })

		if (reload) {
			;(async function handleReload() {
				const productWithTags = await Promise.all(
					[...selectedProducts].map(async (product) => {
						const updatedTags = (await API_Hiboutik.getProductById(product.product_id))?.[0]?.tags
						product.tags = [...updatedTags]

						return repairTagsAndClass(product, tags)
					})
				)
				if (productWithTags) {
					setProducts(productWithTags)
					updateActes(productWithTags)
				}
				setBusy(false)
			})()
		} else {
			setProducts([...selectedProducts].map((product) => repairTagsAndClass(product, tags)))
		}
		setTmpTeletransData((old) => ({ ...old, ...teletransData }))
	}, [open, selectedProducts, reload, teletransData])

	const isTagsMissing = useMemo(() => {
		if (!open || !products?.length) return false

		if (products.every((p) => p.category_name !== "Appareils auditifs")) return false
		if (products.filter((p) => p.category_name === "Appareils auditifs").every((p) => p.tags?.energy)) return false

		return true
	}, [open, products])

	useEffect(() => {
		if (error?.codes?.includes("FINESS_PRESCRIPTEUR_MANQUANT")) return
		if (
			patientPrescription?.prescriber &&
			(!patientPrescription.prescriber.finess ||
				!patientPrescription.prescriber.rpps ||
				patientPrescription.prescriber.rpps?.length === 0)
		) {
			setError(() => ({
				message: "Le prescripteur n'a pas de finess ou de rpps renseigné",
				codes: ["FINESS_PRESCRIPTEUR_MANQUANT"],
				customButton: {
					action: () =>
						history.push("/mon-compte/mes-prescripteurs", {
							redirectTo: window.location.pathname,
						}),
					label: "Ajouter les informations",
				},
				customButton2: {
					action: () => {
						setError({})
						setOpen(false)
						setTmpTeletransData({})
					},
					label: "Quitter",
				},
				hideOkButton: true,
			}))
		}
	}, [patientPrescription])

	const repairTagsAndClass = (product, tags) => {
		if (Array.isArray(product.tags)) product.tags = getCustomTags(product, tags)
		product.class = product.tags?.classe?.toString() || "2"
		return product
	}

	const getLpp = (category, product) => {
		const productDetails = getProductLPPWithPatient(category, product, supldata, selectedPatient)
		if (productDetails) return productDetails
		else {
			setBusy(true)
			console.error("Pas de lpp trouvé")
			return null
		}
	}

	const getAmoAmount = (product) => {
		if (+product.montantHonoraire === 0) return 0
		let baseAmount = isPregnant
			? +product.lpp.amount
			: rounded(
					product.lpp.amount *
						((garantieFSV.find((f) => f.value === patientSocialSecurity?.situationCode)?.percent || 100) /
							100)
			  )

		return product.product_price < baseAmount ? product.product_price : baseAmount
	}

	const getAmcAmount = (product) => {
		if (!product.montantAmo || !publicAmc || !publicAmc.getAmcAmounts) return null
		if (product.category === "PILE") return +product.montantHonoraire - +product.montantAmo
		const amounts = publicAmc?.getAmcAmounts(selectedPatient, product.montantAmo, product, domTomPrices)

		if (amounts) {
			return +product.class === 2 ? amounts.classTwo : amounts.classOne
		} else {
			return +product.class === 2 ? patientInsurances?.classTwoCover : patientInsurances?.classOneCover
		}
	}

	const getLppCategory = (product) => {
		if (!product?.category_name) return null
		if (product.category_name.includes("auditifs")) return "AUDITIF"
		if (product.category_name.includes("Piles")) return "PILE"
		return null
	}

	const handleAmountAMx = (product) => {
		product.uuid = uuidv4()

		if (product.discount > 0) product.montantHonoraire = +product.product_price - product.discount
		else product.montantHonoraire = +product.product_price

		if (closedSaleDocument) {
			const productFromPatientEquipment = selectedPatient?.patientEquipments?.find(
				(f) => f?.serialNumber === product?.serial_number
			)

			if (productFromPatientEquipment?.ear) {
				product.ear = productFromPatientEquipment.ear
			}
			if (productFromPatientEquipment?.id) {
				product.patientEquipmentId = productFromPatientEquipment.id
			}

			product.montantHonoraire = +product.item_unit_gross
			if (publicAmc) product.cmu = publicAmc
		}

		product.category = getLppCategory(product)
		product.lpp = getLpp(product.category, product)
		product.libelle = product.product_model
		product.qte = product.quantity

		if (!product?.lpp?.lpp) return product

		if (domTomPrices) {
			product.lpp.amount =
				product.product_price <= product.lpp.amount * 1.2 ? product.product_price : product.lpp.amount * 1.2
		}

		product.montantAmo = getAmoAmount(product)

		// We do not need amc amounts if they aren't from a cmu type of amc and if we have an sncf cover
		if (publicAmc) product.montantAmc = getAmcAmount(product)

		product.montantPP = +product.montantHonoraire - (+(product.montantAmc || 0) + +product.montantAmo)
		product.line_item_id = product?.id_sale_product_detail

		return product
	}

	const getAmounts = () => {
		const count = {
			classOne: products.filter((f) => +f.class === 1 && f.category_name.toUpperCase().includes("AUDITIF"))
				.length,
			classTwo: products.filter((f) => +f.class === 2 && f.category_name.toUpperCase().includes("AUDITIF"))
				.length,
		}

		const amount = {
			classOne: +patientInsurances?.classOneCover / (count.classOne || 1),
			classTwo: +patientInsurances?.classTwoCover / (count.classTwo || 1),
		}

		return amount
	}

	const updateActes = async (updatedProducts = null) => {
		// Si !selectedProducts.length, le useEffect qui est chargé de gérer le state de products ne se déclenche pas.
		// Nous devons donc gérer ici le cas où selectedProducts a été vidé.
		if (!selectedProducts.length) {
			setTmpTeletransData({})
			return
		}

		const actes = []
		const amount = getAmounts()

		for (const product of updatedProducts ?? products) {
			try {
				if (product.category_name?.toUpperCase()?.includes("AUDITIF") && !product.ear) {
					product.ear = JSON.parse(atob(product.product_comments))?.ear ?? null
				}
				const category = getLppCategory(product)
				if (!category || (category !== "AUDITIF" && category !== "PILE")) continue
				actes.push(handleAmountAMx(product, amount))
			} catch (error) {
				console.error(error)
			}
		}

		if (reload) setTmpTeletransData((old) => ({ ...old, actes, reload: true }))
		else setTmpTeletransData((old) => ({ ...old, actes }))
	}

	const handleMultiplePrescriptions = (advicesOfPatient, advicesOfClosedSale) => {
		let message = "Le patient a plusieurs prescriptions, veuillez sélectionner celle à utiliser :"

		if (
			(advicesOfPatient.length === 1 && !advicesOfClosedSale.length) ||
			(advicesOfClosedSale.length === 1 && !advicesOfPatient.length)
		) {
			message = "Veuillez confirmer si la prescription ci-dessous est celle à utiliser pour la télétransmission :"
		}

		const ShowLabel = ({ advice }) => {
			if (advice?.prescriber?.finess)
				return (
					<>
						{advice.prescriber?.lastName} {advice.prescriber?.firstName} -
						{dayjs(advice.dateOfAdvice).format("DD-MM-YYYY")} ({advice.type})
					</>
				)
			return (
				<>
					{advice.prescriber?.lastName} {advice.prescriber?.firstName} (FINESS manquant)
				</>
			)
		}

		if (isTagsMissing) return

		setError(() => ({
			title: "Sélectionner une prescription",
			hideOkButton: true,
			customButton: {
				label: "Sélectionner",
				action: () => {
					setError(null)
				},
			},
			customButton2: {
				label: "Annuler",
				color: "warning",
				action: () => {
					setError(null)
				},
			},
			message: (
				<div>
					<div className="mb-4">{message}</div>
					<Select
						name="advice"
						className="w-100"
						onChange={(e) => {
							let selectedPrescription
							if (e.includes("sale")) {
								selectedPrescription = advicesOfClosedSale.find((f) => +f.id === +e.split("-")[1])
							} else {
								selectedPrescription = advicesOfPatient.find((f) => +f.id === +e.split("-")[1])
							}
							if (selectedPrescription) setPatientPrescription(selectedPrescription)
						}}>
						{advicesOfClosedSale.length !== 0 && (
							<OptGroup label="Prescription de la vente">
								{advicesOfClosedSale.map((v) => (
									<Option
										key={`sale-${v?.id}`}
										value={`sale-${v?.id}`}
										disabled={!v?.prescriber?.finess}>
										<ShowLabel advice={v} />
									</Option>
								))}
							</OptGroup>
						)}
						{advicesOfPatient.length !== 0 && (
							<OptGroup label="Prescription du patient">
								{advicesOfPatient.map((v) => (
									<Option
										key={`patient-${v?.id}`}
										value={`patient-${v?.id}`}
										disabled={!v?.prescriber?.finess}>
										<ShowLabel advice={v} />
									</Option>
								))}
							</OptGroup>
						)}
					</Select>
				</div>
			),
		}))
	}

	useEffectAsync(async () => {
		if (
			!open ||
			!supldata?.categories ||
			!supldata?.tags ||
			!products.length ||
			!(patientInsurances?.id || patientSocialSecurity?.id)
		)
			return

		const refPEC = patientInsurances?.coverNumber
		const datePEC = patientInsurances?.coverDate?.slice(0, 10)
		const isSncfCover = ["04", "05"].includes(patientSocialSecurity.regime)

		try {
			updateActes()
			let advice = !closedSaleDocument ? selectedPatient?.advices?.[0] : {}
			let advicesOfPatient = selectedPatient?.advices
			let advicesOfClosedSale = []
			let missingPrescribersFinessId = new Set()
			let prescribersInfos = []

			if (closedSaleDocument) {
				if (closedSaleDocument?.config?.patient?.full?.advices?.[0]) {
					advice = closedSaleDocument.config.patient.full.advices[0]
					const res = await API.find("PRESCRIBERS_API", advice?.prescriber.id)
					advice.prescriber = res
				}

				if (closedSaleDocument?.config?.patient?.full?.advices)
					advicesOfClosedSale = closedSaleDocument.config.patient.full.advices
			}

			setPatientPrescription(advice)

			for (const ad of advicesOfClosedSale) {
				if (!ad?.prescriber?.finess) missingPrescribersFinessId.add(ad.prescriber.id)
			}

			if (advice?.prescriber?.id && !advice.prescriber?.finess)
				missingPrescribersFinessId.add(advice.prescriber.id)

			for (const prescribeId of missingPrescribersFinessId) {
				const res = await API.find("PRESCRIBERS_API", prescribeId)
				prescribersInfos.push(res)
			}

			for (const ad in advicesOfClosedSale) {
				const updatedData = prescribersInfos.find((f) => +f?.id === +advicesOfClosedSale[ad].prescriber.id)
				if (!updatedData) continue
				advicesOfClosedSale[ad].prescriber = updatedData
			}

			if (
				(closedSaleDocument && !advice?.prescriber?.finess) ||
				advicesOfClosedSale.length > 0 ||
				advicesOfPatient.length > 0
			)
				handleMultiplePrescriptions(advicesOfPatient, advicesOfClosedSale)

			setTmpTeletransData((old) => ({ ...old, refPEC, datePEC, isSncfCover }))
		} catch (error) {
			console.error(error)
		}
	}, [open, patientSocialSecurity, patientInsurances, supldata, products])

	const formatDateForFSV = (date) => (date ? dayjs(date).format("YYYYMMDD") : dayjs().format("YYYYMMDD"))

	const getTypeIdent = () => {
		if (patientPrescription?.prescriber?.rpps?.length === 9) return 0
		else if (patientPrescription?.prescriber?.cpo === "99") return 0
		else return 8
	}

	const facture = {
		idPatient: selectedPatient?.idFSV,
		dateFacturation: formatDateForFSV(),
		datePrescription: formatDateForFSV(patientPrescription?.dateOfAdvice),
		dateExecution: formatDateForFSV(),
		numNatPs: user?.adeli,
		numFiness: laboratory?.finess,
		isTpAmo: user.tpAmo,
		isTpAmc: false,
		prescripteur: {
			codeConditionExercice: "L",
			numIdFacPresc: patientPrescription?.prescriber?.finess?.slice(0, 8),
			numIdFacPrescCle: patientPrescription?.prescriber?.finess?.slice(8, 9),
			codeSpecialite: patientPrescription?.prescriber?.category === "ORL" ? "11" : "01",
			typeIdent: getTypeIdent(),
			rppsPresc: patientPrescription?.prescriber?.rpps?.slice(0, -1),
			rppsPrescCle: patientPrescription?.prescriber?.rpps?.slice(patientPrescription.prescriber.rpps.length - 1),
			adeli: patientPrescription?.prescriber?.adeli,
		},
	}

	const createFsvInvoice = async (idFacture) => {
		try {
			const resFacturation = await facturerSilent({
				idFacture: idFacture,
				finess: laboratory?.finess,
				numNatPs: user?.adeli,
				idCompany: user.company.id,
			})
			if (resFacturation) {
				return JSON.parse(resFacturation)
			}
		} catch (error) {
			console.error(error)
			return error
		}
	}

	const handleFsvFinish = async (idFacture) => {
		try {
			const fse = await createFsvInvoice(idFacture)
			if (!fse?.numFse) {
				setError({
					codes: ["ERREUR_CREATION_FSE"],
					message: fse?.error || "Erreur à la création de la FSE",
					hideOkButton: true,
					customButton: {
						action: async () => {
							setError(null)
							await handleFsvFinish(idFacture)
						},
						label: "Réessayer",
					},
					customButton2: {
						action: () => {
							setError(null)
							setOpen(false)
						},
						label: "Réesayer plus tard",
					},
				})
			} else {
				setError(null)
				await API.update("DOCUMENTS_API", closedSaleDocument?.id, {
					edit: {
						idFse: fse?.numFse || null,
						idDre: fse?.numDre || null,
						teletransData: tmpTeletransData || {},
					},
				})
				setOpen(false)
				if (closedSaleDocument) {
					setParentOpen(null)
				}
				notification.success({
					top: 80,
					message: `FSE n°${fse.numFse} créée !`,
					key: "FSE_RETURN_SUCCESS",
					description: (
						<>
							<div>
								Montant amo demandé: {fse.montantAmo}€
								<br />
								Montant total de la facture: {fse.montantTotal}€
							</div>
							<a
								href={urlTeletransmission(user.adeli, laboratory.finess, user.company.id)}
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn-block btn-sm btn-outline-primary cursor-pointer">
								Passer à la mise en lot et l'émission
							</a>
						</>
					),
					duration: 10,
				})
			}
		} catch (_void) {}
	}

	const getFsvCheck = async () => {
		try {
			setLoading(true)
			setBusy(true)

			if (publicAmc) {
				tmpTeletransData.mutlTierPayant = true
				facture["cmu"] = publicAmc
			}

			facture["isTpAmo"] = tmpTeletransData.secuTierPayant || false
			facture["isTpAmc"] = tmpTeletransData.mutlTierPayant || false
			facture["modeSecurisation"] = tmpTeletransData.modeSecurisation || modeTeletrans.securise

			// we send batteries only if the allowed number of batteries to be reimbursed is > 0
			const actes = (tmpTeletransData?.actes || []).filter((acte) => {
				if (acte.category !== "PILE") return true
				else if (acte.lpp.remaining > 0) {
					if (acte.qte > acte.lpp.remaining) acte.qte = acte.lpp.remaining
					return true
				}
				return false
			})

			if (actes.length === 0) {
				toast.warning("Aucun produit à télétransmettre")
				return
			}
			// looks random but it is necessary to avoid rejects
			if (tmpTeletransData.isSncfCover) facture.prescripteur.estPrescripteurSNCF = "false"

			const res = await transmettreFacture(
				facture,
				actes,
				tmpTeletransData.documentList,
				selectedPatient,
				user,
				laboratory
			)

			const total = calcTeletransAmount(tmpTeletransData)
			if (["C2S", "Camieg/Mutieg"].includes(publicAmc?.label)) {
				window.open(
					urlFacturation(user.adeli, laboratory.finess, res, selectedPatient.id, user.company.id),
					"_blank"
				)
				setTeletransData({ ...tmpTeletransData, total })
			} else if (closedSaleDocument) {
				try {
					await handleFsvFinish(res)
				} catch (error) {
					console.error(error)
				}
			} else if (res) {
				setTeletransData({ ...tmpTeletransData, total, idFacture: res })
			}
		} catch (error) {
			console.error(error)
		} finally {
			setOpen(false)
			setLoading(false)
			setBusy(false)
		}
	}

	// Render

	const NoEarHandle = ({ acte }) => {
		const attribEar = (ear) => {
			const tmp = tmpTeletransData.actes
			const id = tmpTeletransData.actes.findIndex((f) => f?.uuid === acte.uuid)
			if (id === -1) return

			tmp[id].ear = ear
			tmp[id] = handleAmountAMx(tmp[id], getAmounts())
			setTmpTeletransData((old) => ({ ...old, actes: tmp }))
		}

		return (
			<>
				<div className="col-2">Oreille manquante </div>
				<div className="col-3">
					<div className="btn-group w-100">
						<button type="button" onClick={() => attribEar("DROITE")} className="btn btn-sm btn-ear-right">
							Droite
						</button>
						<button type="button" onClick={() => attribEar("GAUCHE")} className="btn btn-sm btn-ear-left">
							Gauche
						</button>
					</div>
				</div>
			</>
		)
	}

	const ProductAmounts = ({ acte }) => {
		const quantity = acte.quantity <= acte.lpp?.remaining ? acte.quantity : acte.lpp?.remaining
		const maxAmount = (() => {
			if (!quantity || quantity === 1) return formatCurrency(acte.lpp?.amount)
			return `${formatCurrency(acte.lpp?.amount)} (total ${formatCurrency(acte.lpp?.amount * quantity)})`
		})()

		const amoAmount = (() => {
			const amount = acte.isAld ? acte.lpp?.amount : acte?.montantAmo

			if (!quantity || quantity === 1) return formatCurrency(amount)
			return `${formatCurrency(amount)} (total ${formatCurrency(amount * quantity)})`
		})()

		return (
			<>
				<div className="col-3">
					<label htmlFor="lpp">Lpp</label>
					<input type="number" className="form-control" id="lpp" disabled defaultValue={acte?.lpp?.lpp} />
				</div>
				<div className="col-3">
					<label htmlFor="remboursement-max">Remboursement max.</label>
					<input type="text" className="form-control" id="remboursement-max" disabled value={maxAmount} />
				</div>
				<div className="col-3">
					<label htmlFor="montant-amo">Montant AMO</label>
					<input
						type="text"
						className="form-control"
						placeholder="Montant sécu"
						id="montant-amo"
						disabled
						value={amoAmount}
					/>
				</div>{" "}
			</>
		)
	}

	const AmcAmounts = ({ actes }) => {
		if (!publicAmc || tmpTeletransData.isSncfCover) return <></>
		return actes ? (
			<>
				<div className="row mb-4 mt-4 align-items-center">
					<div className="col-3 font-weight-bold">{publicAmc.label}</div>
					{publicAmc.handled ? (
						<>
							<div className="col-1 font-weight-bold">
								<Switch
									disabled={paymentsList?.find(
										(f) => f.type === "MUTL" && f.comments !== "TELETRANSMISSION"
									)}
									checkedChildren="Oui"
									unCheckedChildren="Non"
									checked={tmpTeletransData?.mutl}
									onChange={(event) => setTmpTeletransData((old) => ({ ...old, mutl: event }))}
								/>
							</div>
							<div className="col-3">
								<Switch
									disabled={true}
									checkedChildren="Tiers Payant"
									unCheckedChildren="Hors tiers payant"
									checked={mutlTierPayant}
									onChange={(event) =>
										setTmpTeletransData((old) => ({
											...old,
											mutlTierPayant: event,
											secuTierPayant: event ? true : old.secuTierPayant,
											secu: event ? true : old.secu,
											mutl: event ? true : old.mutl,
										}))
									}
								/>
							</div>{" "}
						</>
					) : (
						<div className="col-4">
							<Switch
								disabled={true}
								unCheckedChildren="Part Mutuelle hors Télétransmission"
								checked={false}
							/>
						</div>
					)}
				</div>

				{actes
					.filter((f) => ["Appareils auditifs", "Piles"].includes(f?.category_name))
					.map((acte, key) => {
						const amcAmount = (() => {
							const quantity = acte.quantity <= acte.lpp?.remaining ? acte.quantity : acte.lpp?.remaining
							const amount = acte.montantAmc

							if (!quantity || quantity === 1) return formatCurrency(amount)
							return `${formatCurrency(amount)} (total ${formatCurrency(amount * quantity)})`
						})()

						return (
							<div key={key} className="row align-items-center mt-2 mb-2">
								<div className="col-3">
									{" "}
									{acte.ear?.[0] && (
										<Tag color={acte.ear === "DROITE" ? "red" : "blue"}>{acte.ear[0]}</Tag>
									)}
									{acte.libelle} (x{acte.qte}) {acte.serial_number ? `(${acte.serial_number})` : ""}
								</div>
								<Row>
									<div className="col-12">
										<label htmlFor="montant-pec">Montant AMC</label>
										<input
											type="text"
											id="montant-pec"
											className="form-control"
											placeholder="Montant AMC"
											value={amcAmount}
											disabled
										/>
									</div>
								</Row>
							</div>
						)
					})}
			</>
		) : null
	}

	const AldCheckbox = ({ acte }) => {
		if (patientSocialSecurity.ALD === 0) return <></>
		return (
			<Checkbox
				onChange={(e) => {
					let data = tmpTeletransData.actes
					const acteIndex = data.indexOf(
						tmpTeletransData.actes.find((tmpActe) => {
							if (acte.category === "AUDITIF") {
								return tmpActe.libelle === acte.libelle && tmpActe.ear === acte.ear
							}
							return tmpActe.uuid === acte.uuid
						})
					)
					const selectedActe = data[acteIndex]

					selectedActe["isAld"] = e.target.checked

					if (e.target.checked) {
						if (+selectedActe.lpp.amount > selectedActe.montantHonoraire) {
							selectedActe.montantAmo = selectedActe.montantHonoraire
						} else selectedActe.montantAmo = +selectedActe.lpp.amount
					} else {
						if (domTomPrices) {
							selectedActe.montantAmo = rounded(
								+selectedActe.lpp.amount *
									((garantieFSV.find((f) => f.value === patientSocialSecurity?.situationCode)
										?.percent || 100) /
										100) *
									1.2
							)
						}
						selectedActe.montantAmo = getAmoAmount(acte)
					}
					if (publicAmc) {
						if (selectedActe?.lpp?.age === ">20") {
							const baseCmuClassTwo = domTomPrices ? 960 : 800

							selectedActe.montantAmc = baseCmuClassTwo - selectedActe.montantAmo
						} else {
							selectedActe.montantAmc = selectedActe.montantHonoraire - selectedActe.montantAmo
						}
					}
					setTmpTeletransData((old) => ({
						...old,
						actes: data,
					}))
				}}
				checked={acte.isAld}>
				Prestation en ald
			</Checkbox>
		)
	}

	const returnBatteryInfo = (battery) => {
		return (
			(accessoryCountValues ?? []).find(
				(c) => c.battery === battery?.product_model.match(/(\d{2,3}(?!.*\d{2,3}))/)?.[0]
			) ?? { limit: 0, value: 0, battery: null }
		)
	}

	const calculTotalBatteriesInSale = (actes) => {
		const batteries = actes.filter((acte) => acte.category === "PILE" && acte.montantHonoraire && acte.lpp)
		let total = {}
		for (const battery of batteries) {
			const { battery: batteryType } = returnBatteryInfo(battery)
			if (!total[batteryType]) total[batteryType] = 0
			total[batteryType] += battery.qte
		}
		return total
	}

	const Batteries = () => {
		if (!tmpTeletransData?.actes || tmpTeletransData?.actes?.length === 0) return <></>
		const totalBatterieInSale = calculTotalBatteriesInSale(tmpTeletransData?.actes)
		let totalRemaining = []
		let alreadySoldInSale = []
		if (accessoryCountValues == null) return <></>
		return (tmpTeletransData?.actes || [])
			.filter((acte) => acte.category === "PILE" && acte.montantHonoraire && acte.lpp)
			.map((battery) => {
				const { limit, value, battery: batteryType } = returnBatteryInfo(battery)
				if (closedSaleDocument) {
					battery.count = {
						value: Math.max(value - (totalBatterieInSale[batteryType] || 0), 0),
						limit: limit,
					}
				}
				if (!battery.count) battery.count = { value, limit }

				if (!totalRemaining[batteryType] && totalRemaining[batteryType] !== 0)
					totalRemaining[batteryType] = limit - battery.count.value
				if (totalRemaining[batteryType] <= 0) {
					return (
						<div className="row align-items-center mt-2 mb-2" key={uuidv4()}>
							{battery.libelle} (x{battery.qte}){" "}
							{battery.serial_number ? `(${battery.serial_number})` : ""} -
							<span> Aucune pile n'est remboursable pour ce patient au vu des appareils actuels.</span>
						</div>
					)
				}

				if (!alreadySoldInSale[batteryType]) alreadySoldInSale[batteryType] = 0

				const reimbursed = battery.count.value + alreadySoldInSale[batteryType]
				const previouslySolde = alreadySoldInSale[batteryType]
				const remaining = totalRemaining[batteryType]
				const remainingAfterSale = remaining - battery.qte
				const surplus = battery.qte - remaining
				battery.lpp.remaining = remaining

				totalRemaining[batteryType] = remainingAfterSale
				alreadySoldInSale[batteryType] += battery.qte

				return (
					<div className="row align-items-center mt-2 mb-2" key={uuidv4()}>
						{battery.libelle} (x{battery.qte}) {battery.serial_number ? `(${battery.serial_number})` : ""} -{" "}
						<span className={remaining > 0 && remainingAfterSale > 0 ? "" : "text-danger"}>
							{remaining > 0 ? (
								<span>
									<strong>
										{reimbursed} {!!previouslySolde && `(${previouslySolde} dans cette vente) `} sur{" "}
										{limit}
									</strong>{" "}
									paquets de piles de ce type déjà remboursés, encore <strong>{remaining} </strong>
									remboursables.
								</span>
							) : (
								<span>
									<strong>
										{reimbursed} sur {limit}
									</strong>{" "}
									paquets de piles de ce type déjà remboursés.
								</span>
							)}
							{surplus >= 1 && (
								<span>
									<strong>{surplus}</strong>{" "}
									{surplus === 1 ? "paquet ne sera" : "paquets ne seront pas"} pas télétransmis.
								</span>
							)}
						</span>
						<Row className="col-12">
							<div className="col-3">
								<AldCheckbox acte={battery} />
							</div>
							<ProductAmounts acte={battery} />
						</Row>
					</div>
				)
			})
	}

	const Documents = () => {
		if (!tmpTeletransData.documentList?.length) return <></>
		const docList = tmpTeletransData.documentList.map((doc) => {
			return (
				<li key={doc.type}>
					<span className="font-weight-bold">{documentTypeLabel[doc.type] || doc.type} :</span>{" "}
					{doc.label ?? doc.filename}
				</li>
			)
		})
		return (
			<>
				<hr />
				<div className="font-weight-bold mb-2">Pièces jointes ajoutées</div>
				{docList}
			</>
		)
	}

	return (
		<Modal isOpen={open} className="modal-div" centered size="xl" backdrop="static">
			<ModalHeader>
				<div className="modal-custom-header">
					<div className="modal-custom-header">Télétransmission</div>
					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 mr-3 d-flex justify-content-center align-items-center close-icon"
						aria-label="Close"
						onClick={() => {
							setError(null)
							setOpen(false)
						}}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				{loading ? (
					<div className="overlay-loading-aw" style={{ left: "0" }}>
						<div className="overlay-loading-logo" />
						<div className="overlay-loading-text">Ajout des paiements...</div>
					</div>
				) : (
					<>
						<TeletransPaymentHeader
							tmpTeletransData={tmpTeletransData}
							patientPrescription={patientPrescription}
							patientSocialSecurity={patientSocialSecurity}
							garantieFSV={garantieFSV}
							isBlind={isBlind}
							isPregnant={isPregnant}
							isTagsMissing={isTagsMissing}
							onChange={() => setReload(true)}
							setBusy={setBusy}
						/>
						<div className="div-container-new">
							{patientSocialSecurity && !isTagsMissing && (
								<>
									<div className="row mb-4 mt-4 align-items-center">
										<div className="col-3 font-weight-bold">Sécurité Sociale</div>
										<div className="col-1 font-weight-bold">
											<Switch
												disabled={paymentsList?.some(
													(f) => f.type === "SECU" && f.comments !== "TELETRANSMISSION"
												)}
												checkedChildren="Oui"
												unCheckedChildren="Non"
												checked={tmpTeletransData?.secu}
												onChange={(event) =>
													setTmpTeletransData((old) => ({ ...old, secu: event }))
												}
											/>
										</div>
										<div className="col-3">
											<Switch
												disabled={
													paymentsList?.some(
														(f) => f.type === "SECU" && f.comments !== "TELETRANSMISSION"
													) ||
													tmpTeletransData.disabledSecu ||
													isPregnant
												}
												checkedChildren="Tiers Payant"
												unCheckedChildren="Hors tiers payant"
												checked={secuTierPayant}
												onChange={(event) =>
													setTmpTeletransData((old) => ({
														...old,
														secuTierPayant: event,
														secu: event ? true : old.secu,
														mutlTierPayant: !event ? false : old.mutlTierPayant,
														mutl: !event ? false : old.mutl,
													}))
												}
											/>
										</div>
									</div>

									{(tmpTeletransData?.actes || []).map((value, key) => {
										if (value.category !== "PILE") {
											return (
												<div key={key} className="row align-items-center mt-2 mb-2">
													<div className="col-3">
														<Tag color={value.ear === "DROITE" ? "red" : "blue"}>
															{value.ear?.[0]}
														</Tag>
														{value.libelle} (x{value.qte}){" "}
														{value.serial_number ? `(${value.serial_number})` : ""}
														<br />
														<AldCheckbox acte={value} />
													</div>
													{!value?.ear && value.category === "AUDITIF" ? (
														<NoEarHandle acte={value} />
													) : (
														<ProductAmounts acte={value} />
													)}
												</div>
											)
										}
										return <Fragment key={key} />
									})}
								</>
							)}

							<Batteries />
							<AmcAmounts actes={tmpTeletransData?.actes} />
							<Documents />

							<TeletransAttachment
								isOpen={isOpenPJ}
								setIsOpen={setIsOpenPJ}
								onClose={() => setIsOpenPJ(false)}
								cancelText="Supprimer"
								confirmText="Ajouter les documents"
								patient={selectedPatient}
								laboratory={laboratory}
								tmpTeletransData={tmpTeletransData}
								setTmpTeletransData={setTmpTeletransData}
							/>
						</div>
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<div className="col-12">
					<button
						type="button"
						className="btn btn-block btn-outline-info"
						onClick={() => setIsOpenPJ(true)}
						disabled={busy || isTagsMissing}>
						{tmpTeletransData.documentList?.length
							? "Modifier les pièces jointes"
							: "Ajouter des pièces jointes"}
					</button>
					<button
						type="button"
						className="btn btn-block btn-outline-primary"
						onClick={() => {
							if (
								tmpTeletransData.mutl &&
								(!tmpTeletransData.datePEC || !tmpTeletransData.refPEC) &&
								!publicAmc
							) {
								const codes = []
								if (!tmpTeletransData.datePEC) {
									codes.push("FSV_DATEPEC_MANQUANTE")
								}
								if (!tmpTeletransData.refPEC) {
									codes.push("FSV_REFPEC_MANQUANTE")
								}
								setError(() => ({
									message: "Veuillez renseigner la date et la référence de prise en charge",
									codes,
								}))
								return
							}
							if (tmpTeletransData.actes && (tmpTeletransData.mutl || tmpTeletransData.secu)) {
								getFsvCheck()
							} else {
								setOpen(false)
							}
						}}
						disabled={
							((tmpTeletransData.mutl || tmpTeletransData.secu) &&
								!tmpTeletransData.documentList?.length) ||
							busy ||
							isTagsMissing
						}>
						{(tmpTeletransData.mutl || tmpTeletransData.secu) && !tmpTeletransData.documentList?.length
							? "Veuillez ajouter les pièces jointes avant de continuer"
							: "Enregistrer et fermer"}
					</button>
				</div>
			</ModalFooter>
		</Modal>
	)
}

export default ModalTeletransPayment
