import { UserRole } from "@audiowizard/common"
import AuthContext from "contexts/AuthContext"
import { useContext } from "react"

export enum UserRoles {
	User = "ROLE_USER",
	Manager = "ROLE_MANAGER",
	ManagerFranchised = "ROLE_MANAGER_FRANCHISED",
	Assistant = "ROLE_ASSISTANT",
	Affiliate = "ROLE_AFFILIATE",
	AffiliateManager = "ROLE_AFFILIATE_MANAGER",
	Admin = "ROLE_ADMIN",
}

export default function useHasRole(givenRole: UserRole): boolean {
	const { user } = useContext(AuthContext)

	if (user?.roles == null) return false

	return user.roles.includes(givenRole)
}

export const hasOneOfThisRole = (userRoles: UserRole[], roles: UserRole[]): boolean => {
	if (!userRoles || userRoles == null) return false

	for (const role of roles) {
		if (userRoles.includes(role)) return true
	}

	return false
}
