import { DeleteOutlined } from "@ant-design/icons"
import { ScheduleType } from "@audiowizard/common"
import { Input, InputRef, Select, Tooltip } from "antd"
import cx from "classnames"
import { useIsApt } from "components/Hooks/useIsApt"
import { useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import API from "services/API"
import { partialSearch } from "services/functions"
import "./SecondaryStatus.scss"

interface SecondaryStatus {
	text?: string
	color?: string
	id?: number
	scheduleTypes?: string[]
	"@id"?: string
}

type SecondaryStatusSettingsComponentProps = {
	item: SecondaryStatus
	schedulesTypes: ScheduleType[]
	SPSelect: (scheduleTypesList: ScheduleType[] | undefined) => (JSX.Element | undefined)[]
}

const SecondaryStatusSettingsComponent = ({
	item,
	schedulesTypes,
	SPSelect,
}: SecondaryStatusSettingsComponentProps): JSX.Element => {
	const queryClient = useQueryClient()
	const isApt = useIsApt()
	const [isEditingStatus, setEditingStatus] = useState<SecondaryStatus>({ text: "", color: "", id: -1, "@id": "" })
	const [selectedSP, setSelectedSP] = useState<string[]>([])
	const [toSave, setToSave] = useState<boolean>(false)
	const ref = useRef<InputRef>(null)

	const { mutate: updateStatus, isLoading: isLoadingUpdateStatus } = useMutation(
		async (data: { newStatus: SecondaryStatus; id: number }) =>
			API.update("PATIENT_TAGS_API", data.id, data.newStatus),
		{
			onSuccess: () => queryClient.invalidateQueries(["PATIENT_TAGS_API"]),
			onError: () => {
				toast.error(`Erreur lors de la mise à jour des ${isApt ? "statuts secondaires" : "tags"}`)
			},
		}
	)

	const { mutate: deleteStatus, isLoading: isLoadingDeleteStatus } = useMutation((id: string) => API.delete(id), {
		onSuccess: () => queryClient.invalidateQueries(["PATIENT_TAGS_API"]),
		onError: () => {
			toast.error(`Erreur lors de la suppression des ${isApt ? "statuts secondaires" : "tags"}`)
		},
	})

	// mise en place car parfois le onBlur et le onChange sont simultanés, le selectedSP n’est alors pas à jour
	useEffect(() => {
		if (toSave && item.id) {
			updateStatus({ newStatus: { scheduleTypes: selectedSP }, id: item.id })
			setToSave(false)
		}
	}, [toSave, selectedSP, item])

	// sync item.scheduleTypes to selectedSP
	useEffect(() => {
		setSelectedSP(item.scheduleTypes ?? [])
	}, [item.scheduleTypes])

	return (
		<div
			key={item.id}
			color={"primary"}
			className={cx("col btn btn-outline-primary settings-status-card h-100", {
				disabled: isLoadingUpdateStatus || isLoadingDeleteStatus,
			})}>
			<div className="label-group">
				<div className="label">
					{isEditingStatus?.id === item.id ? (
						<Input
							type="text"
							ref={ref}
							defaultValue={item.text}
							bordered={false}
							onChange={(event) => {
								const value = event.target.value
								setEditingStatus((old) => ({ ...old, text: value }))
							}}
							onBlur={() => {
								if (isEditingStatus.text !== item.text)
									updateStatus({ newStatus: isEditingStatus, id: isEditingStatus.id! })
								setEditingStatus({ id: -1 })
							}}
						/>
					) : (
						<span
							onClick={() => {
								if (isEditingStatus.id !== -1) return
								setEditingStatus({
									text: item.text,
									id: item.id,
								})
								setTimeout(() => {
									if (ref.current == null) return

									ref.current.focus()
								}, 100)
							}}>
							{item.text}
						</span>
					)}
				</div>
				<DeleteOutlined
					style={{ cursor: "pointer" }}
					className="mt-auto  mb-auto"
					onClick={() => {
						if (!item["@id"]) return

						deleteStatus(item["@id"])
					}}
				/>
			</div>
			<div className="SPSelect">
				<Tooltip
					overlayInnerStyle={{ width: "max-content" }}
					overlayStyle={{
						whiteSpace: "break-spaces",
						width: "max-content",
						borderRadius: "5px",
					}}
					title={
						<div style={{ color: "black" }}>
							{item.scheduleTypes
								?.map((a: any) => schedulesTypes.find((item) => item["@id"] === a)?.label)
								.join("\n")}
						</div>
					}
					color="lightgrey">
					<Select
						showSearch
						placeholder="Statut principal"
						mode="multiple"
						maxTagCount="responsive"
						onChange={setSelectedSP}
						onBlur={() => setToSave(true)}
						bordered={false}
						style={{ width: "100%" }}
						value={selectedSP}
						filterOption={(search, option) => partialSearch(option!["data-label"], search)}>
						{SPSelect(schedulesTypes)}
					</Select>
				</Tooltip>
			</div>
		</div>
	)
}
export default SecondaryStatusSettingsComponent
