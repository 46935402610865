import { ReactNode } from "react"
import * as React from "react"
import { Link } from "react-router-dom"
import ToolTip from "react-tooltip"
import cx from "classnames"
import { hexColorSetLightness } from "utils/math/hexToHsl"

type LineProps = {
	icon: string
	iconClassname?: string
	text?: ReactNode
	type?: "FACTURE" | string
	tooltipText?: string
	outline?: boolean
	leftContent?: ReactNode
	badge?: ReactNode
	badgeColor?: string
	badgeColorInline?: string
	dataTip?: string
	modal?: boolean
	onClick?: React.ComponentPropsWithoutRef<"div">["onClick"]
	editable?: boolean
	iconButton?: string
	iconButtonColor?: string
	onEditButtonClick?: React.ComponentPropsWithoutRef<"button">["onClick"]
	dataCy?: string
	iconButton2Show?: boolean
	iconButton2?: string
	iconButtonColor2?: string
	iconButtonClick2?: React.ComponentPropsWithoutRef<"button">["onClick"]
	className?: string
	id?: string
	noTruncate?: boolean
	textClassname?: string
}

export default function Line({
	icon = "",
	iconClassname = "text-dark",
	text,
	type,
	tooltipText,
	outline = false,
	leftContent,
	badge = null,
	badgeColor = "info",
	badgeColorInline,
	dataTip,
	modal,
	onClick,
	onEditButtonClick,
	dataCy,
	editable,
	iconButton,
	iconButtonColor,
	iconButton2Show,
	iconButton2,
	iconButtonColor2,
	iconButtonClick2,
	className,
	id,
	noTruncate,
	textClassname = "text-black",
}: LineProps): JSX.Element {
	const Badge = (): JSX.Element => {
		let styled

		if (badgeColorInline) {
			styled = {
				color: "gray",
				backgroundColor: hexColorSetLightness(badgeColorInline, 90),
			}
		}

		const classnames = cx("badge float-left", {
			[`badge-soft-${badgeColor}`]: !badgeColorInline,
		})

		return (
			<span style={styled} className={classnames} data-tip={dataTip}>
				{icon && <i className={`fad fa-${icon} mr-2`} />}
				{badge}
			</span>
		)
	}

	const lineClassNames = cx("row align-items-center", className, {
		"cursor-pointer": modal,
	})
	return (
		<>
			<div
				className={lineClassNames}
				data-toggle={modal ? "modal" : null}
				data-target={modal ? modal : null}
				onClick={onClick}
				data-tip={tooltipText}
				data-cy={dataCy}
				id={id}>
				<div className={leftContent || badge ? "col" : "col-2"}>
					{leftContent ? (
						leftContent
					) : badge ? (
						<Badge />
					) : (
						<h6 className="text-sm mb-0">
							<i className={"fad fa-" + icon + " mr-2 " + iconClassname} />
						</h6>
					)}
				</div>
				<div
					className={"col " + (!noTruncate ? "text-truncate" : "")}
					style={{ width: "90%", textAlign: "right" }}>
					<span className={"text-sm " + textClassname}>
						{type?.includes("FACTURE") ? <Link to={`/facture-client/${id}`}>{text}</Link> : text}
					</span>
				</div>

				{type?.includes("FACTURE") ? (
					<div />
				) : (
					editable && (
						<button
							type="button"
							className={`icon-md icon-${iconButtonColor} rounded-circle border-0 m-auto`}
							aria-label="Edit"
							onClick={onEditButtonClick}>
							<span aria-hidden="true">
								<i className={"fad fa-" + iconButton + " color-" + iconButtonColor} />
							</span>
						</button>
					)
				)}
				{iconButton2Show && iconButton2 && iconButtonClick2 && (
					<button
						type="button"
						className={` ml-1 mr-1 icon-md icon-${iconButtonColor2} rounded-circle border-0 m-auto`}
						aria-label="Edit"
						onClick={iconButtonClick2}>
						<span aria-hidden="true">
							<i className={"fad fa-" + iconButton2 + " color-" + iconButtonColor2} />
						</span>
					</button>
				)}
			</div>
			{!outline && <hr className="my-3" />}
			<ToolTip />
		</>
	)
}
