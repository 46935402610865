import { InputNumber, InputNumberProps } from "antd"

type CurrencyInputProps = InputNumberProps<number>

export default function CurrencyInput({ onChange = () => undefined, ...props }: CurrencyInputProps): JSX.Element {
	return (
		<InputNumber<number>
			min={0}
			decimalSeparator=","
			precision={2}
			step={1}
			addonAfter="€"
			onChange={(price) => onChange(price ?? 0)}
			{...props}
		/>
	)
}
