import { Select } from "antd"
import dayjs from "dayjs"
import { useCallback, useMemo, useState } from "react"
import { useQuery } from "react-query"
import API from "../../services/API"

const { Option } = Select

function AsyncPatientSelect({
	setData,
	className = "",
	value = {},
	disabled = false,
	size = "medium",
	bootstrapStyle = false,
	laboratoryFilter = null,
}) {
	const [searchString, setSearchString] = useState("")
	let searchTimeout = null

	const loadPatients = useCallback(() => {
		const searchParams = new URLSearchParams()
		searchParams.append("order[id]", "desc")

		for (const word of searchString.trim().split(/\s+/)) {
			searchParams.append("omnisearch[]", word)
		}

		if (laboratoryFilter) {
			searchParams.append("laboratory[]", laboratoryFilter["@id"])
		}

		return API.findAll("PATIENTS_API", `?${searchParams}`)
	}, [searchString, laboratoryFilter])

	const { data: patientData, isLoading: isPatientDataLoading } = useQuery(
		["PATIENTS_API", searchString, laboratoryFilter],
		() => loadPatients(),
		{ staleTime: Infinity }
	)

	const patientList = useMemo(() => {
		if (isPatientDataLoading || !patientData) return []

		return patientData.map((patient) => {
			const age = patient.birthDate ? "(" + dayjs().diff(patient.birthDate, "years") + " ans)" : ""

			return {
				value: patient,
				label: `${patient.firstName} ${patient.lastName} ${age}`,
			}
		})
	}, [isPatientDataLoading, patientData])

	function searchPatient(e) {
		if (searchTimeout) {
			clearTimeout(searchTimeout)
			searchTimeout = null
		}

		searchTimeout = setTimeout(() => {
			setSearchString(e)
		}, 400)
	}

	function handlePatientSelection(patientId) {
		if (!patientId) return
		const patient = patientList.find((option) => option.value.id === patientId).value
		if (patient) setData(patient)
	}

	return (
		<>
			<Select
				allowClear
				onClear={() => setData({})}
				filterOption={false}
				loading={isPatientDataLoading}
				disabled={disabled}
				className={className}
				notFoundContent={"Aucun résultat"}
				showSearch
				size={size}
				placeholder={
					value?.firstName
						? `${value?.firstName} ${value?.lastName}`
						: "Par Nom, Prénom, adresse, e-mail ou téléphone"
				}
				popupClassName={bootstrapStyle ? "antd-bootstrapped-select" : ""}
				onSearch={(e) => searchPatient(e)}
				onChange={handlePatientSelection}>
				{patientList.map((opt) => (
					<Option key={opt.value.id} value={opt.value.id}>
						{opt.label}
					</Option>
				))}
			</Select>
		</>
	)
}

export default AsyncPatientSelect
