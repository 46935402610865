import {
	acquireScannedDocument,
	getScanProfiles,
	isScanServerActive,
	UnavailableProfileError,
	UnknownProfileError,
} from "@audiowizard/scan-client"
import { Select } from "antd"
import { AUDIOWIZARD_SCAN_SERVER_INSTALLER_DOWNLOAD } from "config"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Alert, Spinner } from "reactstrap"

type DocumentScannerProps = {
	onScanDone: (pdf: Blob) => void
}
export default function DocumentScanner({ onScanDone }: DocumentScannerProps): JSX.Element {
	const [isServerActive, setIsServerActive] = useState<boolean | null>(null) // null = loading
	const [profiles, setProfiles] = useState<string[] | null>(null) // null = loading
	const [selectedProfile, setSelectedProfiles] = useState<string | null>(null)
	const [isScanning, setIsScanning] = useState(false)

	useEffect(() => {
		;(async () => {
			setIsServerActive(await isScanServerActive())
		})()
	}, [])

	useEffect(() => {
		;(async () => {
			if (isServerActive) {
				try {
					const profiles = await getScanProfiles()
					setProfiles(profiles)
					setSelectedProfiles(profiles?.[0] ?? "")
				} catch (err) {
					console.error(err)
					toast.error("Erreur lors de la récupération des scanners")
				}
			}
		})()
	}, [isServerActive])

	const launchScan = async (): Promise<void> => {
		setIsScanning(true)
		try {
			const pdfBlob = await acquireScannedDocument(selectedProfile!)
			onScanDone(pdfBlob)
		} catch (err) {
			console.error(err)

			let message = "Erreur lors du scan."
			if (err instanceof UnavailableProfileError) {
				message = "Impossible de joindre le scanner. Vérifiez qu'il soit bien allumé, configuré et connecté."
			} else if (err instanceof UnknownProfileError) {
				message = "Le scanner séléctioné n'existe pas. Veuillez vérifier la configuration du serveur de scan."
			}

			toast.error(message)
		} finally {
			setIsScanning(false)
		}
	}

	const noProfiles = profiles != null && profiles.length === 0
	// Chargement du serveur de scan
	if (isServerActive == null)
		return (
			<div className="d-flex justify-content-center">
				<Spinner />
			</div>
		)

	// Si le serveur de scan ne tourne pas
	if (!isServerActive)
		return (
			<Alert color="info">
				Vous devez télécharger et lancer le serveur de scan audiowizard afin de pouvoir scanner des documents.
				<br />
				<a target="_blank" rel="noopener noreferrer" href={AUDIOWIZARD_SCAN_SERVER_INSTALLER_DOWNLOAD}>
					Télécharger
				</a>
			</Alert>
		)

	// Aucun profil configurer sur le serveur de scan
	if (noProfiles) return <Alert color="warning">Aucun profile de scan configuré</Alert>

	return (
		<>
			<div className="form-group">
				<label htmlFor="scanner-profile-select">Scanner</label>
				<Select
					id="scanner-profile-select"
					className="form-control w-100 removeantd-class antd-add-padding"
					loading={profiles == null}
					placeholder={profiles == null ? "Recherche de profiles..." : null}
					value={selectedProfile ?? ""}
					onChange={(value) => setSelectedProfiles(value)}>
					{profiles?.map((profile) => (
						<Select.Option key={profile} value={profile}>
							{profile}
						</Select.Option>
					))}
				</Select>
			</div>

			<button className="btn btn-block btn-primary" disabled={isScanning} onClick={launchScan}>
				<i className="fad fa-lg fa-print" />
				{isScanning ? (
					<>
						Scan en cours <Spinner />
					</>
				) : (
					"Scanner"
				)}
			</button>
		</>
	)
}
