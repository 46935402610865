/** Extension des classes de Bryntum (adaptée à notre utilisation)
 * cf commentaires précis pour chaque classe si besoin
 */

import { Entity, Laboratory, Patient, Schedule, ScheduleState, ScheduleTypeEnum, User } from "@audiowizard/common"
import {
	Button,
	Calendar,
	Combo,
	EventModel,
	ResourceFilter,
	ResourceView,
	Sidebar,
	Store,
	Widget,
	ButtonGroup,
	Slider,
} from "@bryntum/calendar"
import { Attendance } from "../Attendance"

export const DEFAULT_BACKGROUND_COLOR = 1621148

interface CreatedBy {
	"@id": string
	"@type": string
	firstName: string
	lastName: string
}

export interface Event extends EventModel {
	state: any
	"@id": string
	"@type": "Schedule"
	id: string | number
	startDate: Date
	endDate: Date
	agendaId?: string
	title?: string
	patient: Patient & { user: User }
	user: User
	patientField?: number
	patientName: string
	agenda: string
	name: string
	eventColor: string
	typeLabel: string
	laboratoryId: number
	notes: string
	type: string
	status: string
	laboratory: Laboratory
	dateOf: Date
	dateEnd: Date
	preScheduleNote: string
	reminder?: string
	data: Schedule
	createdBy: CreatedBy
	affiliation: string | null
}

/** Bryntum nomme Ressource les utilisateurs */
export interface Resource extends User {
	_id?: number
	name: string
	role?: string
	eventColor: string
}

export interface RetEvents {
	agenda: Agenda
	schedules: Event[]
}

/** Bryntum fonctionne avec des stores dans lesquels sont stockées les data utilisées dans les selects (combo)
 * AgendaStore permet d'étendre cette classe en ajoutant les méthodes "isDoctolib" et "getAgenda"
 */
export class AgendaStore extends Store {
	isDoctolib(user: User, laboratory: Laboratory): boolean {
		const agendas = this.data as unknown as Agenda[]
		return (
			agendas?.find((agenda) => {
				agenda = agenda as unknown as Agenda
				return agenda.user["@id"] === user["@id"] && agenda.laboratory["@id"] === laboratory["@id"]
			})?.isDoctolib || false
		)
	}
	getAgenda(user: User, laboratory: Laboratory): Agenda | undefined {
		return this.getAgendaByUserId(user["@id"], laboratory)
	}
	getAgendaByUserId(userIri: string, laboratory: Laboratory): Agenda | undefined {
		const agendas = this.data as unknown as Agenda[]
		return agendas?.find((agenda) => {
			agenda = agenda as unknown as Agenda
			return agenda.user["@id"] === userIri && agenda.laboratory["@id"] === laboratory["@id"]
		})
	}
}

export class TypeStore extends Store {
	getTypeData(eventStatus: string): { color: string; label: string; duration: number } {
		const typeSchedule = this.find((type: any) => type.scheduleStatus === eventStatus, true)
		if (typeSchedule) {
			return {
				color: (typeSchedule as unknown as { color: string }).color,
				label: (typeSchedule as unknown as { label: string }).label,
				duration: (typeSchedule as unknown as { duration: number }).duration,
			}
		}
		return { color: "#ffffff", label: "", duration: 30 }
	}
}

export class AWCalendar extends Calendar {
	extraData!: {
		attendances: Attendance
		agendas: Agenda[]
		agendaAlreadyLoaded: Record<string, any>
		typesStore: TypeStore
		agendaStore: AgendaStore
		appointmentFromScheduleSelector: any
		fromDashboard: boolean
		allStoresLoaded: boolean
		currentUser: User
		usersOrder: Record<string, number>
		isAffiliate: boolean
		schedulesLoading: boolean
	}
	isMoving: boolean | undefined
	widgetMap!: {
		laboratoryFilter: Combo
		resourceFilter: ResourceFilter
		saveView: Button
		refresh: Button
		resourceFilterFilter: ResourceFilter
		pdfExport: Button
		dayResources: ResourceView
		stateFilter: ButtonGroup
		viewWidth: Slider
		hourHeight: Slider
	}
	sidebar!: Sidebar & {
		collapsed: boolean
		collapse: () => void
	}
	activeView!: Widget & {
		endDate: Date
		startDate: Date
		modeName: string
		viewCache: (Widget & { visibleStartTime: string | number })[]
	}
	views!: (Widget & {
		visibleStartTime: string | number
		doRefresh?: () => void
		viewCache: (Widget & { visibleStartTime: string | number })[]
	})[]
	duplicate: EventModel | undefined
	hourHeight!: number
	modes!: any
}

export const stateLabels = {
	DONE: "Effectué",
	WAITING: "En attente",
	CANCELLED: "Annulé",
	MISSING: "Manqué",
	ARRIVAL: "Arrivé",
}

/** Entity est une classe propre à audiowizard */
export interface Agenda extends Entity {
	isDoctolib: boolean
	user: { "@id": string }
	laboratory: { "@id": string }
	label: string
	doctolibType?: string
	agendaId: string
}

export const TEMP_DASHBOARD = "TEMP_DASHBOARD"

export interface EvtData {
	patient?: Patient
	startDate: Date
	startTime: string
	endDate?: Date
	endTime?: string
	status?: string
	state?: ScheduleState
	fromAgenda?: boolean
	id?: number
	type?: ScheduleTypeEnum
	preScheduleNote?: string
	laboratory?: number
	user?: number
	isRelance: boolean | undefined
}
