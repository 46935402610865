const lppList = [
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "BIOTONE TECH",
		amount: "1400",
		lpp: "7343683",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "GN HEARING SAS",
		amount: "1400",
		lpp: "7340153",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "PRODITION",
		amount: "1400",
		lpp: "7326756",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "CDA",
		amount: "1400",
		lpp: "7326756",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "SIVANTOS",
		amount: "1400",
		lpp: "7336246",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "SONOVA FRANCE",
		amount: "1400",
		lpp: "7322563",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "STARKEY FRANCE",
		amount: "1400",
		lpp: "7316172",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "WIDEX",
		amount: "1400",
		lpp: "7330574",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "BIOTONE TECH",
		amount: "1400",
		lpp: "7343660",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "GN HEARING SAS",
		amount: "1400",
		lpp: "7340160",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "PRODITION",
		amount: "1400",
		lpp: "7326710",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "CDA",
		amount: "1400",
		lpp: "7326710",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "SIVANTOS",
		amount: "1400",
		lpp: "7336223",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "SONOVA FRANCE",
		amount: "1400",
		lpp: "7322505",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "STARKEY FRANCE",
		amount: "1400",
		lpp: "7316195",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "WIDEX",
		amount: "1400",
		lpp: "7330580",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "BIOTONE TECH",
		amount: "1400",
		lpp: "7343720",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "GN HEARING SAS",
		amount: "1400",
		lpp: "7340220",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "PRODITION",
		amount: "1400",
		lpp: "7326696",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "CDA",
		amount: "1400",
		lpp: "7326696",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "SIVANTOS",
		amount: "1400",
		lpp: "7336163",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "SONOVA FRANCE",
		amount: "1400",
		lpp: "7322853",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "STARKEY FRANCE",
		amount: "1400",
		lpp: "7316396",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: "<=20",
		fournisseur: "WIDEX",
		amount: "1400",
		lpp: "7330893",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "BIOTONE TECH",
		amount: "1400",
		lpp: "7343708",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "GN HEARING SAS",
		amount: "1400",
		lpp: "7340236",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "PRODITION",
		amount: "1400",
		lpp: "7326680",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "CDA",
		amount: "1400",
		lpp: "7326680",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "SIVANTOS",
		amount: "1400",
		lpp: "7336140",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "SONOVA FRANCE",
		amount: "1400",
		lpp: "7322729",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "STARKEY FRANCE",
		amount: "1400",
		lpp: "7316410",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: "<=20",
		fournisseur: "WIDEX",
		amount: "1400",
		lpp: "7330901",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "BIOTONE TECH",
		amount: "400",
		lpp: "7343690",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "GN HEARING SAS",
		amount: "400",
		lpp: "7340093",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "PRODITION",
		amount: "400",
		lpp: "7326727",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "CDA",
		amount: "400",
		lpp: "7326727",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "SIVANTOS",
		amount: "400",
		lpp: "7336200",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "SONOVA FRANCE",
		amount: "400",
		lpp: "7322675",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "STARKEY FRANCE",
		amount: "400",
		lpp: "7316048",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "SUPERVISION SAS",
		amount: "400",
		lpp: "7364521",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "WIDEX",
		amount: "400",
		lpp: "7330551",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "BIOTONE TECH",
		amount: "400",
		lpp: "7343714",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "GN HEARING SAS",
		amount: "400",
		lpp: "7340130",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "PRODITION",
		amount: "400",
		lpp: "7326704",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "CDA",
		amount: "400",
		lpp: "7326704",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "SIVANTOS",
		amount: "400",
		lpp: "7336186",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "SONOVA FRANCE",
		amount: "400",
		lpp: "7322907",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "BIOTONE TECH",
		amount: "400",
		lpp: "7343677",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "GN HEARING SAS",
		amount: "400",
		lpp: "7340124",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "PRODITION",
		amount: "400",
		lpp: "7326740",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "CDA",
		amount: "400",
		lpp: "7326740",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "SIVANTOS",
		amount: "400",
		lpp: "7336230",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "SONOVA FRANCE",
		amount: "400",
		lpp: "7322600",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "STARKEY FRANCE",
		amount: "400",
		lpp: "7316150",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "SUPERVISION SAS",
		amount: "400",
		lpp: "7364538",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "SIVANTOS",
		amount: "400",
		lpp: "7379971",
	},
	{
		categorie: "AUDITIF",
		classe: "1",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "WIDEX",
		amount: "400",
		lpp: "7330568",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "STARKEY FRANCE",
		amount: "400",
		lpp: "7316373",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "BIOTONE TECH",
		amount: "400",
		lpp: "7343737",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "PRODITION",
		amount: "400",
		lpp: "7326733",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "CDA",
		amount: "400",
		lpp: "7326733",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "SONOVA FRANCE",
		amount: "400",
		lpp: "7322936",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "STARKEY FRANCE",
		amount: "400",
		lpp: "7316350",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "WIDEX",
		amount: "400",
		lpp: "7330870",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "GAUCHE",
		age: ">20",
		fournisseur: "WIDEX",
		amount: "400",
		lpp: "7330887",
	},
	{
		categorie: "AUDITIF",
		classe: "2",
		oreille: "DROITE",
		age: ">20",
		fournisseur: "GN HEARING SAS",
		amount: "400",
		lpp: "7380129",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "WIDEX",
		amount: "1.5",
		lpp: "7380307",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "PRODITION",
		amount: "1.5",
		lpp: "7380135",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "GN HEARING SAS",
		amount: "1.5",
		lpp: "7340590",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "SIVANTOS",
		amount: "1.5",
		lpp: "7380075",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "ENERGIZER",
		amount: "1.5",
		lpp: "7380112",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "SONOVA FRANCE",
		amount: "1.5",
		lpp: "7323605",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "VARTA",
		amount: "1.5",
		lpp: "7310353",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 10-JAUNE OU EQUIVALENT (B/6)",
		fournisseur: "SWATCH GROUP",
		amount: "1.5",
		lpp: "7310198",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "WIDEX",
		amount: "1.5",
		lpp: "7380320",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "ENERGIZER",
		amount: "1.5",
		lpp: "7380164",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "PRODITION",
		amount: "1.5",
		lpp: "7380170",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "GN HEARING SAS",
		amount: "1.5",
		lpp: "7340615",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "SIVANTOS",
		amount: "1.5",
		lpp: "7380098",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "SONOVA FRANCE",
		amount: "1.5",
		lpp: "7323611",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "VARTA",
		amount: "1.5",
		lpp: "7310347",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 312-MARRON OU EQUIVALENT (B/6)",
		fournisseur: "SWATCH GROUP",
		amount: "1.5",
		lpp: "7310206",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "WIDEX",
		amount: "1.5",
		lpp: "7380313",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "GN HEARING SAS",
		amount: "1.5",
		lpp: "7340609",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "SIVANTOS",
		amount: "1.5",
		lpp: "7380081",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "SONOVA FRANCE",
		amount: "1.5",
		lpp: "7323456",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "ENERGIZER",
		amount: "1.5",
		lpp: "7380141",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "PRODITION",
		amount: "1.5",
		lpp: "7380158",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "VARTA",
		amount: "1.5",
		lpp: "7310330",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 13-ORANGE OU EQUIVALENT (B/6)",
		fournisseur: "SWATCH GROUP",
		amount: "1.5",
		lpp: "7310212",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "WIDEX",
		amount: "1.5",
		lpp: "7380336",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "GN HEARING SAS",
		amount: "1.5",
		lpp: "7340638",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "SIVANTOS",
		amount: "1.5",
		lpp: "7380106",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "SONOVA FRANCE",
		amount: "1.5",
		lpp: "7323597",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "ENERGIZER",
		amount: "1.5",
		lpp: "7380187",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "PRODITION",
		amount: "1.5",
		lpp: "7380193",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "VARTA",
		amount: "1.5",
		lpp: "7310301",
	},
	{
		categorie: "AUDITIF",
		classe: "PILES SANS MERCURE 675-BLEUE OU EQUIVALENT (B/6)",
		fournisseur: "SWATCH GROUP",
		amount: "1.5",
		lpp: "7310229",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, ECOUTEUR",
		fournisseur: "WIDEX",
		amount: "5.32",
		lpp: "7380253",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, ECOUTEUR",
		fournisseur: "STARKEY FRANCE",
		amount: "5.32",
		lpp: "7380342",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, ECOUTEUR",
		fournisseur: "PRODITION",
		amount: "5.32",
		lpp: "7380230",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, ECOUTEUR",
		fournisseur: "GN HEARING SAS",
		amount: "5.32",
		lpp: "7340874",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, ECOUTEUR",
		fournisseur: "SIVANTOS",
		amount: "5.32",
		lpp: "7380052",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, ECOUTEUR",
		fournisseur: "SONOVA FRANCE",
		amount: "5.32",
		lpp: "7337056",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "LDBE",
		age: "<2",
		amount: "53.36",
		lpp: "7380365",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "WIDEX",
		age: "<2",
		amount: "53.36",
		lpp: "7380276",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "PRODITION",
		age: "<2",
		amount: "53.36",
		lpp: "7380218",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "GN HEARING SAS",
		age: "<2",
		amount: "53.36",
		lpp: "7340839",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "SIVANTOS",
		age: "<2",
		amount: "53.36",
		lpp: "7380023",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "SONOVA FRANCE",
		age: "<2",
		amount: "53.36",
		lpp: "7323746",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "STARKEY FRANCE",
		age: "<2",
		amount: "53.36",
		lpp: "7316551",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "ATELIER EMBOUT",
		age: "<2",
		amount: "53.36",
		lpp: "7352430",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "MIDISON",
		age: "<2",
		amount: "53.36",
		lpp: "7310130",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "WIDEX",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7380299",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "PRODITION",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7380201",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "GN HEARING SAS",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7340868",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "LDBE",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7310005",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "SIVANTOS",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7380046",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "SONOVA FRANCE",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7323752",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "STARKEY FRANCE",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7316568",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "CHRONO",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7316597",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "ATELIER EMBOUT",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7373135",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "INTERSON PROTAC",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7310376",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "MIDISON",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7310324",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "INTERSON PROTAC",
		age: "<2",
		amount: "53.36",
		lpp: "7310382",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "INTERSON PROTAC",
		age: ">=20",
		amount: "4.91",
		lpp: "7310360",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "CHRONO",
		age: "<2",
		amount: "53.36",
		lpp: "7316605",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "CHRONO",
		age: ">=20",
		amount: "4.91",
		lpp: "7316611",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROT, ENTRET ET REP, >= 2 ANS & < 20 ANS OU CECITE, EMBOUT",
		fournisseur: "SAT",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7310643",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "SAT",
		age: ">=20",
		amount: "4.91",
		lpp: "7310650",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "SAT",
		age: "<2",
		amount: "53.36",
		lpp: "7310637",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "WIDEX",
		age: ">=20",
		amount: "4.91",
		lpp: "7380282",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "PRODITION",
		age: ">=20",
		amount: "4.91",
		lpp: "7380224",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "GN HEARING SAS",
		age: ">=20",
		amount: "4.91",
		lpp: "7340851",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "LDBE",
		age: ">=20",
		amount: "4.91",
		lpp: "7310235",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "SIVANTOS",
		age: ">=20",
		amount: "4.91",
		lpp: "7380030",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "SONOVA FRANCE",
		age: ">=20",
		amount: "4.91",
		lpp: "7323769",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "STARKEY FRANCE",
		age: ">=20",
		amount: "4.91",
		lpp: "7316574",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "ATELIER EMBOUT",
		age: ">=20",
		amount: "4.91",
		lpp: "7324846",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "SUR-MESURE",
		age: ">=20",
		amount: "4.91",
		lpp: "7310293",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, > OU = 20 ANS, EMBOUT",
		fournisseur: "MC USINAGES",
		age: ">=20",
		amount: "4.91",
		lpp: "7310614",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, >= 2 ANS & < 20, EMBOUT",
		fournisseur: "SUR-MESURE",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7310270",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, >= 2 ANS & < 20, EMBOUT",
		fournisseur: "MC USINAGES",
		age: ">=2 && <20",
		amount: "53.36",
		lpp: "7310620",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, >= 2 ANS & < 20, EMBOUT",
		fournisseur: "MIDISON",
		age: ">=20",
		amount: "4.91",
		lpp: "7310318",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, < 2 ANS, EMBOUT",
		fournisseur: "SUR-MESURE",
		age: "<2",
		amount: "53.36",
		lpp: "7310287",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, MICROPHONE",
		fournisseur: "SONOVA FRANCE",
		amount: "9.17",
		lpp: "7337091",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, MICROPHONE",
		fournisseur: "STARKEY FRANCE",
		amount: "9.17",
		lpp: "7380359",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, MICROPHONE",
		fournisseur: "PRODITION",
		amount: "9.17",
		lpp: "7380247",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, MICROPHONE",
		fournisseur: "GN HEARING SAS",
		amount: "9.17",
		lpp: "7340928",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, MICROPHONE",
		fournisseur: "SIVANTOS",
		amount: "9.17",
		lpp: "7380069",
	},
	{
		categorie: "AUDITIF",
		classe: "AUDIOPROTHESE, ENTRETIEN ET REPARATIONS, MICROPHONE",
		fournisseur: "WIDEX",
		amount: "9.17",
		lpp: "7380260",
	},
	{
		categorie: "PILES IMPLANTS",
		classe: "IMPLANT COCH OU TRONC CEREBRAL, PROCESSEUR, FORFAIT ANNUEL PILES JETABLES",
		amount: "120",
		lpp: "2325090",
	},
	{
		categorie: "BATTERIES IMPLANTS",
		classe: "IMPLANT COCH OU TRONC CEREBRAL, PROCESSEUR, CHARGEUR ET BATTERIES RECHARGEABLES",
		amount: "600",
		lpp: "2326941",
	},
	{
		categorie: "ACCESSOIRES IMPLANTS",
		classe: "IMPLANT COCH OU TRONC CEREBRAL, PROCESSEUR, FORFAIT ANNUEL ACCESSOIRES",
		amount: "100",
		lpp: "2350922",
	},
	{
		categorie: "ACCESSOIRES IMPLANTS AVEC SN",
		classe: "IMPLANT COCH OU TRONC CEREBRAL, PROCESSEUR, FORFAIT ANNUEL ACCESSOIRES",
		amount: "100",
		lpp: "2350922",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, COCHLEAR, BAHA 6 MAX, PROCESSEUR",
		fournisseur: "COCHLEAR",
		amount: "2996.46",
		lpp: "2342101",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, COCHLEAR, BAHA 5, PROCESSEUR",
		fournisseur: "COCHLEAR",
		amount: "2996.46",
		lpp: "2372786",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, COCHLEAR, BAHA 5 SUPER POWER, PROCESSEUR",
		fournisseur: "COCHLEAR",
		amount: "2996.46",
		lpp: "2300864",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, COCHLEAR, BAHA 5 POWER, PROCESSEUR",
		fournisseur: "COCHLEAR",
		amount: "2996.46",
		lpp: "2344873",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, PRODITION, PONTO 4, PROCESSEUR",
		fournisseur: "PRODITION",
		amount: "3198.23",
		lpp: "2390666",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, PRODITION, PONTO 5 MINI, PROCESSEUR",
		fournisseur: "PRODITION",
		amount: "2996.46",
		lpp: "2384708",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, PRODITION, PONTO 5 SUPER POWER, PROCESSEUR",
		fournisseur: "PRODITION",
		amount: "2996.46",
		lpp: "2344531",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "PROTHÈSE OSTÉO-INTÉGRÉE, PRODITION, PONTO BHX",
		fournisseur: "PRODITION",
		amount: "917.85",
		lpp: "3174858",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "SYSTEME ADHEAR",
		fournisseur: "MEDEL",
		amount: "2447.85",
		lpp: "2388474",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "IMPLANT OREILLE MOYENNE, AUDIOPROCESSEUR, MED-EL, VIBRANT SOUNDBRIDGE, SAMBA 2",
		fournisseur: "MEDEL",
		amount: "3100",
		lpp: "2303118",
	},
	{
		categorie: "ANCRAGE OSSEUX AVEC SN",
		classe:
			"IMPLANT ACTIF A CONDUCTION OSSEUSE, AUDIOPROCESSEUR," +
			" MED-EL, BONEBRIDGE REFERENCES PRISES EN CHARGE KIT" +
			" AUDIOPROCESSEUR" +
			" SAMBA 2 BB",
		fournisseur: "MEDEL",
		amount: "2996.46",
		lpp: "2398171",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "IMPLANT COCH OU TRONC CÉRÉBRAL, PROCESSEUR, COCHLEAR, NUCLEUS 7 MODÈLE CP1000",
		fournisseur: "COCHLEAR",
		amount: "6000",
		lpp: "3497544",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "IMPLANT COCH OU TRONC CÉRÉBRAL, PROCESSEUR, COCHLEAR, KANSO CP950",
		fournisseur: "COCHLEAR",
		amount: "6000",
		lpp: "3482809",
	},
	{
		categorie: "ANCRAGE OSSEUX",
		classe: "IMPLANT COCH OU TRONC CÉRÉBRAL, PROCESSEUR, MED-EL, RONDO 3",
		fournisseur: "COCHLEAR",
		amount: "6000",
		lpp: "3439044",
	},
	{
		categorie: "ENTRETIEN",
		classe: "ADHESIF SYSTEME ADHEAR",
		fournisseur: "MEDEL",
		amount: "32.97",
		lpp: "2337910",
	},
	{
		categorie: "ENTRETIEN",
		classe: "ADHESIF ADHEAR",
		fournisseur: "MEDEL",
		amount: "32.97",
		lpp: "2337910",
	},
	{
		categorie: "AUDITIF",
		classe: "FORFAIT PILES ADHEAR",
		fournisseur: "MEDEL",
		amount: "36.59",
		lpp: "2332893",
	},
]

export default lppList
