/* eslint-disable react-hooks/exhaustive-deps */
import { Select, SelectProps } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import cx from "classnames"

type SelectWithErrorProps<
	ValueType = any,
	OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
> = React.PropsWithChildren<
	SelectProps<ValueType, OptionType> & {
		groupClassName: string
		error?: string
		label?: string
		dataCy?: any
	}
>

const SelectWithError = <ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>({
	groupClassName,
	defaultValue,
	value,
	error,
	label,
	onChange,
	children,
	dataCy,
	disabled = false,
	className = "",
	options,
}: SelectWithErrorProps<ValueType, OptionType>): JSX.Element => {
	const valueProps =
		value !== undefined
			? {
					value: value,
			  }
			: {
					defaultValue: defaultValue,
			  }
	return (
		<div className={cx("form-group", groupClassName)}>
			{label && <label>{label}</label>}
			<Select
				disabled={disabled}
				className={cx("form-control", className, { "is-invalid": !!error })}
				onChange={onChange}
				data-cy={dataCy}
				options={options}
				{...valueProps}>
				{children}
			</Select>
			{error && <p className="invalid-feedback">{error}</p>}
		</div>
	)
}

export default SelectWithError
