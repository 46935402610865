import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import MesInvitations from "pages/mon-compte/mes-invitations"
import { InvitationsRoutePrefix, InvitationsRoutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

function InvitationsRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Invitations")
	return (
		<CardWithTabs urlprefix={InvitationsRoutePrefix}>
			<CardItem name="Invitations" component={MesInvitations} url={noPrefix(InvitationsRoutes.MesInvitations)} />
		</CardWithTabs>
	)
}

export default InvitationsRouter
