type Partners = {
	label: string
	description: string
	header: string
	logo: string
	color: string
	url: string
}

export const partners: Partners[] = [
	{
		label: "Mon Centre Auditif",
		description:
			"Prenez rendez-vous avec les autres centres auditifs proches de chez vous afin de réaliser un bilan auditif gratuit",
		header: "/static/img/partners/mon-centre-auditif.png",
		logo: "/static/img/partners/mon-centre-auditif-logo.png",
		color: "#4096F6",
		url: "https://mon-centre-auditif.com/",
	},
	{
		label: "Odiora",
		description:
			"Odiora, spécialiste des bijoux auditifs, vous fait découvrir comment porter ses appareils avec élégance",
		header: "/static/img/partners/odiora.png",
		logo: "/static/img/partners/odiora-logo.png",
		color: "#F15A4A",
		url: "https://odiora.fr/fr/",
	},
	{
		label: "Doctolib",
		description:
			"Trouvez rapidement un spécialiste près de chez vous et prenez rendez-vous gratuitement en ligne en quelques clics ",
		header: "/static/img/partners/doctolib.png",
		logo: "/static/img/partners/doctolib-logo.png",
		color: "#4096F6",
		url: "https://www.doctolib.fr/",
	},
	{
		label: "Audioprotect",
		description:
			"Assurance appareils auditifs. Offrez les meilleures garanties à vos clients. Assurance perte, vol, casse à partir de 15 € par an",
		header: "/static/img/partners/audioprotect.png",
		logo: "/static/img/partners/audioprotect-logo.png",
		color: "#00ADB5",
		url: "https://www.audioprotect.fr/",
	},
	{
		label: "Audibene",
		description:
			"Bénéficiez d’un accompagnement personnalisé avec des experts de l’audition et des audioprothésistes",
		header: "/static/img/partners/audibene.png",
		logo: "/static/img/partners/audibene-logo.png",
		color: "#4096F6",
		url: "https://www.audibene.fr/",
	},
	{
		label: "OuiVu",
		description:
			"OuiVu est la plateforme de prise de rendez-vous en ligne dédiée aux audioprothésistes et aux opticiens ",
		header: "/static/img/partners/ouivu.png",
		logo: "/static/img/partners/ouivu-logo.png",
		color: "#6AC9C8",
		url: "https://www.ouivu.fr/",
	},
	{
		label: "DataMut",
		description:
			"DataMut facilite le tiers payant en automatisant toutes les étapes des demandes de prise en charge auprès des réseaux de mutuelle",
		header: "/static/img/partners/datamut.png",
		logo: "/static/img/partners/datamut-logo.png",
		color: "#FEAC31",
		url: "https://www.datamut.com/",
	},
]

export const requirementsSDAdetails = {
	label: "SDA - Syndicat des Audioprothésistes",
	description:
		"Organisme professionnel représentatif des audioprothésistes auprès notamment des instances nationales en charge de la santé",
	header: "/static/img/partners/logo_SDA.svg",
	logo: "/static/img/partners/sda-logo.png",
	color: "#4096F6",
	url: "https://www.sdaudio.org/",
}
