/* eslint-disable react-hooks/exhaustive-deps */
import useHasRole from "components/Hooks/useHasRole"
import { useContext } from "react"
import AuthContext from "../../contexts/AuthContext"
import AffiliateTopBar from "./AffiliateTopBar"
import UserTopBar from "./UserTopBar"

const TopBar = () => {
	const { user, uiDisplay } = useContext(AuthContext)

	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManger = useHasRole("ROLE_AFFILIATE_MANAGER")

	const shouldBeHidden = !user?.gdprAgreement || uiDisplay?.onboarding

	if (shouldBeHidden) {
		return (
			<nav className="navbar navbar-horizontal navbar-expand-lg navbar-dark bg-gradient-primary ">
				<div className="container" />
			</nav>
		)
	} else if (isAffiliate || isAffiliateManger) {
		return <AffiliateTopBar />
	} else if (window.location.pathname.split("/").filter(Boolean).pop() !== "agenda") {
		return <UserTopBar />
	}
}

export default TopBar
