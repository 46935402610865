/* eslint-disable react-hooks/exhaustive-deps */
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useEffect, useState } from "react"
import { Col } from "reactstrap"
import DataTable from "../../../components/utils/DataTable"
import { itemsTemplates } from "../../../datas/items/itemsTemplates"
import API from "../../../services/API"
import API_PDF from "../../../services/API_PDF"
import ConfigurationModal from "./ConfigurationModal"
import UploadModal from "./Upload.modal"

function ListePersonnalisations() {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenConfiguration, setIsOpenConfiguration] = useState(false)
	const [refresh, setRefresh] = useState()
	const [chosenTemplate, setChosenTemplate] = useState()
	const [personalizations, setPersonalizations] = useState()

	const getPersonalizations = async () => {
		const data = await API.findAll("PERSONALIZATIONS_API")
		setPersonalizations(data?.[0])
	}

	useEffect(() => {
		getPersonalizations()
	}, [])

	const columns = [
		{
			title: "Nom du template",
			dataIndex: "label",
			sorter: (a, b) => a.key - b.key,
		},
		{
			title: "Télécharger le template par défaut",
			dataIndex: "id",
			sorter: (a, b) => a.key - b.key,
			render: (id, template) => {
				return (
					<div className="w-100 d-flex justify-content-center">
						<div className="btn btn-info" onClick={() => API_PDF.getCarboneTemplate(id, template.name)}>
							<i className="fad fa-file-download" />
						</div>
					</div>
				)
			},
		},
		{
			title: "Ajouter / Mettre à jour le template",
			sorter: (a, b) => a.key - b.key,
			render: (template) => {
				return (
					<div className="w-100 d-flex justify-content-center">
						<button
							className="btn btn-secondary"
							onClick={() => {
								setIsOpen(true)
								setChosenTemplate(template)
							}}>
							<i className="fad fa-plus" />
						</button>
					</div>
				)
			},
		},
		{
			title: "Variables et configuration",
			sorter: (a, b) => a.key - b.key,
			render: (template) => {
				return (
					<div className="w-100 d-flex justify-content-center">
						<button
							className="btn btn-primary"
							onClick={() => {
								setIsOpenConfiguration(true)
								setChosenTemplate(template)
							}}>
							<i className="fad fa-tools" />
						</button>
					</div>
				)
			},
		},
	]

	return (
		<Col>
			<SectionHeader title="Liste des templates" />
			<DataTable
				target="personalization"
				columns={columns}
				refresh={{
					needRefresh: refresh,
					setNeedRefresh: setRefresh,
				}}
				dataSetter={itemsTemplates}
			/>

			<UploadModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				chosenTemplate={chosenTemplate}
				setChosenTemplate={setChosenTemplate}
				setPersonalizations={setPersonalizations}
				personalizations={personalizations}
			/>
			<ConfigurationModal
				isOpen={isOpenConfiguration}
				setIsOpen={setIsOpenConfiguration}
				chosenTemplate={chosenTemplate}
				setChosenTemplate={setChosenTemplate}
				setPersonalizations={setPersonalizations}
				personalizations={personalizations}
			/>
		</Col>
	)
}

export default ListePersonnalisations
