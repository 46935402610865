import { Layout } from "awui"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import DoctolibZipper from "components/effects/DoctolibZipper"
import useHasRole from "components/Hooks/useHasRole"
import AffiliateLeftBar from "components/structure/AffiliateLeftBar"
import AffiliateTopBar from "components/structure/AffiliateTopBar"
import LeftBarRender from "components/structure/LeftBarHandler"
import { lazy, Suspense, useContext /*useMemo*/ } from "react"
import { BrowserRouter } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import TopBar from "../components/structure/TopBar"
import AuthContext from "../contexts/AuthContext"
import LectureCVModal from "../pages/FSV/LectureCVModal"
import AgendaModal from "../pages/Schedules/Agenda/ModalAgendaContainer"
import SchedulingModal from "../pages/Schedules/SchedulingModal/Modal.Scheduling"
import AffiliateRouter from "./AffiliateRouter"
import UserRouter from "./UserRouter"
import DetectMobil from "components/effects/DetectMobil"

const { Header, Content, Sidebar } = Layout
const Mandate = lazy(() => import("../pages/Mandate"))

const PrivateRouter = (): JSX.Element => {
	const { patientUI, uiDisplay, setUiDisplay } = useContext(AuthContext)

	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")
	// eslint-disable-next-line
	//const hasBasicService = useMemo(() => services?.find((s) => s.isMain)?.isActive, [services])

	//regex to detect if the user is on a mobile device
	const regexPhone =
		/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
	const userAgent = window.navigator.userAgent

	// Cache entièrement la leftbar et la topbar via le CSS (.fullscreen-agenda-hideleftbar)
	const shouldHideLeftBarAndTopBar = regexPhone.test(userAgent)

	// Cache entièrement la leftbar via le JSX
	const shouldHideLeftBar = shouldHideLeftBarAndTopBar || patientUI || uiDisplay.onboarding || uiDisplay.hideSidebar

	const shouldHideTopBar = shouldHideLeftBarAndTopBar

	return (
		//getUserConfirmation permet de demander confirmation de l'utilisateur s'il quitte une page où le composant Prompt est appellé
		<BrowserRouter
			getUserConfirmation={async (message, callback) => {
				const response = await confirmWithModal({
					title: " Attention vous risquez de perdre vos données !",
					text: message,
				})
				callback(response)
			}}>
			<DetectMobil userAgent={userAgent} regexPhone={regexPhone} />
			<DoctolibZipper />
			{uiDisplay?.agendaModal && (
				<AgendaModal setShow={() => setUiDisplay((old) => ({ ...old, agendaModal: false }))} />
			)}
			{uiDisplay?.lectureCVModal && (
				<LectureCVModal
					isOpen={uiDisplay?.lectureCVModal}
					mode={uiDisplay?.mode}
					onClose={() => setUiDisplay({ ...uiDisplay, lectureCVModal: false })}
				/>
			)}
			{uiDisplay?.scheduleModal && (
				<SchedulingModal
					selectedPatient={uiDisplay?.selectedPatient}
					fromNewSchedule={false}
					setShowModal={setUiDisplay}
				/>
			)}
			<Layout>
				<Header hide={shouldHideTopBar}>{isAffiliate ? <AffiliateTopBar /> : <TopBar />}</Header>
				<Sidebar hide={shouldHideLeftBar}>{isAffiliate ? <AffiliateLeftBar /> : <LeftBarRender />}</Sidebar>
				<Content>
					<Suspense fallback={<div className="bg-secondary w-100 h-100" />}>
						{/* Force la page Mandate si l'inscription est terminé, si la période d'essaie du service basic est */}
						{/* fini et qu'il n'y a pas de mandat gocardless actif user.gdprAgreement && */}
						{/* !user.company?.audioWizardContract?.hasMandate && !hasBasicService TODO: Remettre la condtion */}
						{/* une fois que les utilisateurs existants ont été migré */}
						{false ? <Mandate /> : isAffiliate || isAffiliateManager ? <AffiliateRouter /> : <UserRouter />}
					</Suspense>
				</Content>
			</Layout>
		</BrowserRouter>
	)
}

export default PrivateRouter
