import { Col, Form, InputNumber, Row } from "antd"
import { formatTva } from "../caisse.helpers"
import { CashOutflowVat } from "../moneyItems"
import "./TaxInputHistorique.scss"

interface TaxInputFieldProps {
	label: string
	value?: number
	onChange: (value: number) => void
}

const TaxInputField = ({ label, value, onChange }: TaxInputFieldProps): JSX.Element => {
	return (
		<Form.Item
			label={label}
			style={{
				margin: 0,
			}}>
			<InputNumber
				decimalSeparator={","}
				addonAfter="€"
				precision={2}
				value={value || undefined}
				onChange={onChange}
				min={0}
				placeholder={"Entrer un montant"}
			/>
		</Form.Item>
	)
}

interface TaxInputHistoriqueProps {
	tax: CashOutflowVat
	setValue: (tax: CashOutflowVat) => void
}

const TaxInputHistorique = ({ tax, setValue }: TaxInputHistoriqueProps): JSX.Element => {
	return (
		<Col flex={1} className={"col-historique-tax-accounting-input"}>
			<Row>
				<Col span={24} className="text-center" style={{ fontWeight: "bold" }}>
					<span className={"header-tax"}>TVA {formatTva(tax.taxRate)}%</span>
				</Col>
			</Row>

			<TaxInputField
				label={"HT :"}
				value={tax.taxFreeAmount}
				onChange={(value) =>
					setValue({
						...tax,
						taxFreeAmount: value,
					})
				}
			/>
			<TaxInputField
				label={"TVA :"}
				value={tax.vatAmount}
				onChange={(value) =>
					setValue({
						...tax,
						vatAmount: value,
					})
				}
			/>
			<TaxInputField
				label={"TTC :"}
				value={tax.allTaxIncludeAmount}
				onChange={(value) =>
					setValue({
						...tax,
						allTaxIncludeAmount: value,
					})
				}
			/>
		</Col>
	)
}

export default TaxInputHistorique
