import { Alert, AlertProps, Col, Row } from "antd"
import { MouseEventHandler } from "react"
import cx from "classnames"

interface AlertCustomProps {
	message: JSX.Element | string
	type?: AlertProps["type"]
	icon?: string
	onClick?: MouseEventHandler<HTMLSpanElement>
}

export const AlertCustom = ({ message, icon, onClick, type }: AlertCustomProps): JSX.Element => {
	return (
		<Alert
			className="mb-1"
			message={
				<Row wrap={false}>
					<Col>
						<div>{message}</div>
					</Col>
					{icon && (
						<Col>
							<span style={{ cursor: "pointer" }} onClick={onClick}>
								<i className={cx("float-right fa-2x fad", icon)} />
							</span>
						</Col>
					)}
				</Row>
			}
			type={type || "warning"}
		/>
	)
}
