/* eslint-disable react-hooks/exhaustive-deps */
import { Document, DocumentType, Patient } from "@audiowizard/common"
import { Select, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import cx from "classnames"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { documentTypeLabel } from "datas/items/itemsDocumentsAvailable"
import dayjs from "dayjs"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Col } from "reactstrap"
import { OS, detectBrowserOS } from "services/Utilities"
import { partialSearch } from "services/functions"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import { fileExtensionIcon } from "./Extensions"
import DocumentUploader from "./Modal.DocumentUploader"
import DocumentViewer, { handleModify } from "./Modal.DocumentViewer"

const userOnlyType = ["CAISSE_DEPOT", "CAISSE_VALIDATION", "CAISSE_REASSORT", "CAISSE_JUSTIFICATIF"]

const columns: ColumnsType<Document> = [
	{
		title: "Date de création",
		dataIndex: "createdAt",
		width: "20%",
		key: "createdAt",
		render: (r: string) => {
			return <>{dayjs(r).format("DD/MM/YYYY, HH:mm")}</>
		},
	},
	{
		title: "Patient",
		dataIndex: "patient",
		width: "20%",
		key: "patient",
		render: (_, doc) => {
			return <>{doc?.patient ? `${doc?.patient?.firstName} ${doc?.patient?.lastName}` : "---"}</>
		},
	},
	{
		title: " ",
		dataIndex: "extension",
		key: "extension",
		align: "center",
		render: (extension: string) => {
			return <i className={`fad fa-2x ${fileExtensionIcon(extension)}`} />
		},
	},
	{
		title: "Type",
		dataIndex: "type",
		key: "type",
		render: (r: DocumentType) => documentTypeLabel[r] || r,
	},
	{
		title: "Label",
		dataIndex: "label",
		key: "type",
		render: (label: string, doc) => {
			const dateStr = dayjs(doc.createdAt).format("DD/MM/YYYY, HH:mm")
			if (doc.type === "FACTURE") {
				return `Facture ${doc.invoiceNumber} du ${dateStr}`
			} else {
				return label ?? `${documentTypeLabel[doc.type!] || doc.type} du ${dateStr}}`
			}
		},
	},
]

type ListeDocumentProps = { documentListType: "USER" | "PATIENT" }
export default function ListeDocument({ documentListType = "USER" }: ListeDocumentProps): JSX.Element {
	const params = useParams<{ id?: string }>()

	const history = useHistory()
	const os = useMemo(detectBrowserOS, [])

	const { laboratory } = useContext(AuthContext)
	const [patient, setPatient] = useState({} as Patient)
	const [documents, setDocuments] = useState<Document[]>([])
	const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(-1)

	const [modalOpen, setModalOpen] = useState(false)
	const [modalUpload, setModalUpload] = useState(false)

	const [pagination, setPagination] = useState({ total: 1, current: 1 })
	const [loadingDocuments, setLoadingDocuments] = useState(true)
	const [loadingPatient, setLoadingPatient] = useState(true)
	const loading = loadingDocuments || loadingPatient
	const classNames = cx("p-4", { card: documentListType === "PATIENT" })

	const [typeFilter, setTypeFilter] = useState<DocumentType | undefined>(undefined)

	const handleOnPreviousDocument = (): void => {
		let newIndex = selectedDocumentIndex
		do {
			newIndex--
			// Wrap around array
			if (newIndex < 0) {
				newIndex = documents.length - 1
			}
		} while (documents[newIndex].signature == null) // Skip documents without file

		setSelectedDocumentIndex(newIndex)
	}
	const handleOnNextDocument = (): void => {
		let newIndex = selectedDocumentIndex
		do {
			newIndex++
			// Wrap around array
			if (newIndex >= documents.length) {
				newIndex = 0
			}
		} while (documents[newIndex].signature == null) // Skip documents without file

		setSelectedDocumentIndex(newIndex)
	}

	const fetchDocuments = useCallback(async (): Promise<void> => {
		try {
			const patientId = params.id

			const searchParams = new URLSearchParams()
			searchParams.append("page", pagination.current.toString())
			searchParams.append("order[createdAt]", "desc")

			if (documentListType === "PATIENT" && patientId) searchParams.append("patient", patientId.toString())
			if (documentListType === "USER") searchParams.append("laboratories", laboratory.id!.toString())

			if (typeFilter != null) searchParams.append("type", typeFilter)

			setLoadingDocuments(true)

			const data = await API.findAll<Document[]>("DOCUMENTS_API", `?${searchParams}`, true)

			setDocuments(
				data["hydra:member"]
					.filter((d) => d.type != null) // due to imports, some documents have no label and no type. They should not show up on table
					.map((v, key) => ({ ...v, key }))
			)
			setPagination((o) => ({ ...o, total: data["hydra:totalItems"] || 1 }))
		} catch (err) {
			console.error(err)
		} finally {
			setLoadingDocuments(false)
		}
	}, [laboratory, params.id, typeFilter, pagination.current])

	useEffect(() => {
		fetchDocuments()
	}, [fetchDocuments])

	useEffect(() => {
		const fetchPatient = async (): Promise<void> => {
			try {
				if (!params?.id) return

				setLoadingPatient(true)
				const patientId = params.id
				const patient = await API.find<Patient>("PATIENTS_API", patientId)
				setPatient(patient)
			} catch (err) {
				console.error(err)
			} finally {
				setLoadingPatient(false)
			}
		}
		fetchPatient()
	}, [params.id])

	return (
        <div className={classNames} style={{ minHeight: "100%" }}>
            <Col>
                <SectionHeader title="Liste des documents">
                    {!loading && (
                        <>
                            {documentListType === "PATIENT" && (
                                <>
                                    <ButtonRounded
                                        color="primary-outlined"
                                        onClick={() => history.push(`/fiche-patient/${patient?.id}`)}>
                                        {`${patient?.firstName} ${patient?.lastName}`}
                                    </ButtonRounded>

                                    <ButtonRounded
                                        color="primary-outlined"
                                        icon="fa-file-upload"
                                        onClick={() => setModalUpload(true)}
                                        type="button">
                                        Ajouter un nouveau document
                                    </ButtonRounded>
                                </>
                            )}
                        </>
                    )}
                </SectionHeader>
                <div>
                    <Select<DocumentType>
                        showSearch
                        className="mb-4 mt-2"
                        allowClear
                        loading={loadingDocuments}
                        size="large"
                        value={typeFilter}
                        onChange={setTypeFilter}
                        placeholder="Filtrer par type de document..."
                        filterOption={(search, option) => partialSearch(option!.label as string, search)}>
                        {Object.entries(documentTypeLabel)
                            .filter(([value]) => (documentListType === "PATIENT" ? !userOnlyType.includes(value) : 1))
                            .map(([value, label]) => (
                                <Select.Option key={value} label={label} value={value}>
                                    {label}
                                </Select.Option>
                            ))}
                    </Select>

					<Table
						columns={columns}
						dataSource={documents}
						pagination={{
							...pagination,
							position: ["bottomLeft"],
							pageSize: 10,
							showSizeChanger: false,
							onChange: (page) => setPagination((o) => ({ ...o, current: page })),
						}}
						loading={loading}
						className="cursor-pointer"
						onRow={(record, index) => ({
							onClick: (_event) => {
								if (!record.signature) {
									if (record.type?.includes("FACTURE")) history.push("/facture-client/" + record.id)
									else if (!record.filePath) {
										handleModify(history, record, null)
										return
									}
									history.push("/facture-client/" + record.id)
								}

								if (!record.extension) history.push("/facture-client/" + record.id)

								setSelectedDocumentIndex(index!)
								setModalOpen(true)
							},
						})}
					/>
				</div>

				<DocumentViewer
					onClose={() => setModalOpen(false)}
					isOpen={modalOpen}
					document={documents[selectedDocumentIndex] ?? {}} // {} pour ne avoir des erreurs undefined si aucun document choisi
					refreshDocuments={fetchDocuments}
					onPrevious={handleOnPreviousDocument}
					onNext={handleOnNextDocument}
				/>
				<DocumentUploader
					onClose={() => setModalUpload(false)}
					isOpen={modalUpload}
					refreshDocuments={fetchDocuments}
					patientIri={patient?.["@id"]}
				/>
			</Col>
		</div>
	)
}
