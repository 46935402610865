import { DeleteOutlined } from "@ant-design/icons"
import { Input, InputRef } from "antd"
import React, { useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import API from "services/API"
import cx from "classnames"

export interface CashOutflowReason {
	"@id": string
	label: string
	id: number
	laboratory?: string
}

interface CashOutflowReasonComponentProps {
	item: CashOutflowReason
}

const CashOutflowReasonComponent = ({ item }: CashOutflowReasonComponentProps): JSX.Element => {
	const queryClient = useQueryClient()
	const [editingReason, setEditingReason] = useState<CashOutflowReason>({ label: "", id: -1, "@id": "" })
	const ref = useRef<InputRef>(null)

	const { mutate: updateOutflowReason, isLoading: isLoadingOutflowReason } = useMutation(
		async (data: { newOutflowReason: CashOutflowReason; id: number }) =>
			API.update("CASH_OUTFLOW_REASON_API", data.id, data.newOutflowReason),
		{
			onSuccess: () => queryClient.invalidateQueries(["CASH_OUTFLOW_REASON_API"]),
			onError: () => {
				toast.error("Erreur lors de la mise à jour des motifs de sortie")
			},
		}
	)

	const { mutate: deleteOutflowReason, isLoading: isLoadingDeleteOutflowReason } = useMutation(
		(id: number) => API.update("CASH_OUTFLOW_REASON_API", id, { isArchived: true }),
		{
			onSuccess: () => queryClient.invalidateQueries(["CASH_OUTFLOW_REASON_API"]),
			onError: () => {
				toast.error("Erreur lors de la suppression des motifs de sortie")
			},
		}
	)

	return (
		<div
			className={cx("col btn btn-outline-primary outflow-reason-card h-100", {
				disabled: isLoadingOutflowReason || isLoadingDeleteOutflowReason,
			})}>
			<div className="label-group">
				<div className="label">
					{editingReason?.id === item.id ? (
						<Input
							type="text"
							ref={ref}
							defaultValue={item.label}
							bordered={false}
							onChange={(event) => {
								const value = event.target.value
								setEditingReason((old) => ({ ...old, label: value }))
							}}
							onBlur={() => {
								if (editingReason.label !== item.label)
									updateOutflowReason({ newOutflowReason: editingReason, id: editingReason.id })
								setEditingReason((old) => ({ ...old, id: -1 }))
							}}
						/>
					) : (
						<span
							onClick={() => {
								// id vaut -1 pendant la mise à jour back
								if (editingReason.id !== -1) return
								setEditingReason(item)
								setTimeout(() => {
									if (ref.current == null) return
									ref.current.focus()
								}, 100)
							}}>
							{item.label}
						</span>
					)}
				</div>
				<DeleteOutlined
					style={{ cursor: "pointer" }}
					className="mt-auto  mb-auto"
					onClick={() => {
						if (!item["@id"]) return

						deleteOutflowReason(item.id)
					}}
				/>
			</div>
		</div>
	)
}

export default CashOutflowReasonComponent
