import { useState, useEffect } from "react"
import { Select } from "antd"
import Fuse from "fuse.js"

const { Option } = Select

const AsyncSelectProductDropdown = ({ options, onChange, setOffset }) => {
	return (
		<div
			onScroll={(event) => {
				const el = event.target
				if (el.scrollHeight <= el.scrollTop + el.clientHeight) {
					setOffset((old) => old + 30)
				}
			}}
			className="custom-antd-dropdown bonlivraison-dropdown">
			{options.map((p, k) => (
				<div key={k} className="custom-antd-dropdown-item bonlivraison" onClick={() => onChange(p)}>
					<div className="category-name">{p.category_name}</div>
					<div className="supplier-name">{p.brand_name}</div>
					<div className="font-weight-bold product-name">{p.product_model}</div>
					{p.size_name && <div className="size-name">{p.size_name}</div>}
				</div>
			))}
		</div>
	)
}

const AsyncSelectProducts = ({
	selectedSupplier,
	sizeList,
	categoryList,
	supplierList,
	brandsList,
	setSelectedProduct,
	selectedProduct,
	disabled,
	products,
}) => {
	const [productList, setProductList] = useState([])
	const [loading, setLoading] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [filterResult, setFilterResult] = useState([])
	const [filterString, setFilterString] = useState("")
	const [offset, setOffset] = useState(0)

	const handleFilter = () => {
		if (!filterString.length) {
			setFilterResult([...productList.slice(0, 30 + offset)])
		} else {
			// Fuzzy match and sort
			// TODO: store the fuse in state to avoid recomputing it everytime
			const fuse = new Fuse(productList, {
				keys: ["product_model"],
			})
			const matches = fuse.search(filterString)

			setFilterResult([...matches.splice(0, 30 + offset)].map((m) => m.item))
		}
	}

	useEffect(() => selectedProduct.product_id && setDropdownOpen(false), [selectedProduct])
	useEffect(() => handleFilter(), [offset])
	useEffect(() => {
		setOffset(0)
		handleFilter()
	}, [filterString])

	const searchProduct = async () => {
		let productsTmp = [...products]
		const tmp = []

		for (const k in productsTmp) {
			const category = categoryList.find((f) => f.category_id === productsTmp[k].product_category) || {}
			const size = sizeList?.[productsTmp[k]?.product_size_type] || {}
			const supplier = supplierList?.find((f) => f.supplier_id === productsTmp[k].product_supplier) || {}
			const brand = brandsList?.find((f) => f.brand_id === productsTmp[k].product_brand) || {}

			productsTmp[k].supplier_name = supplier?.supplier_name
			productsTmp[k].category_name = category?.category_name
			productsTmp[k].size_type_name = size?.size_type_name
			productsTmp[k].brand_name = brand?.brand_name
			productsTmp[k].sizes_available = size?.sizes || []
			if (size.sizes)
				for (const key in size.sizes) {
					tmp.push({
						...productsTmp[k],
						size_name: size.sizes[key].size_name,
						size_id: size.sizes[key].size_id,
						product_size_id: size.sizes[key].size_id,
					})
				}
			else tmp.push({ ...productsTmp[k] })
		}
		setLoading(false)
		setProductList(tmp)
		setFilterResult(tmp.slice(0, 15))
	}

	useEffect(() => {
		if (sizeList && categoryList.length) {
			searchProduct()
		}
	}, [sizeList, categoryList, selectedSupplier, products])

	return (
		<Select
			onClick={() => setDropdownOpen(true)}
			onBlur={() => {
				setTimeout(() => {
					setDropdownOpen(false)
				}, 500)
			}}
			onSearch={(value) => {
				try {
					const el = document.querySelector(".bonlivraison-dropdown")
					el.scrollTo(0, 0)
				} catch (_void) {}
				setFilterString(value)
			}}
			showSearch
			filterOption={false}
			style={{ width: "100%" }}
			placeholder="Rechercher un produit..."
			value={
				selectedProduct?.product_id &&
				selectedProduct?.product_model + (selectedProduct?.size_name ? ` (${selectedProduct?.size_name})` : "")
			}
			loading={loading}
			disabled={disabled}
			open={dropdownOpen}
			dropdownRender={() => (
				<AsyncSelectProductDropdown
					options={filterResult}
					onChange={setSelectedProduct}
					setOffset={setOffset}
				/>
			)}>
			{filterResult.map((p, k) => (
				<Option key={k} details={p} value={k}>
					{p.product_model}
				</Option>
			))}
		</Select>
	)
}

export default AsyncSelectProducts
