import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { lazy } from "react"
import { ComptabiliteRoutePrefix, ComptabiliteRoutes, noPrefix } from "./routes"
import CreditsTable from "pages/supplier-invoice/CreditsTable"

const Exports = lazy(() => import("../../pages/mon-compte/comptabilite/ExportComptable"))
const SupplierInvoices = lazy(() => import("../../pages/supplier-invoice/invoice/SupplierInvoices"))
const RetrocessionInvoices = lazy(() => import("../../pages/supplier-invoice/retrocession/RetrocessionInvoices"))
const SupplierInvoiceEditPage = lazy(() => import("../../pages/supplier-invoice/invoice/SupplierInvoiceEdit"))
const RetrocessionEditPage = lazy(() => import("../../pages/supplier-invoice/retrocession/RetrocessionEdit"))

function ComptabiliteRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={ComptabiliteRoutePrefix}>
			<CardItem name="Exports" url={noPrefix(ComptabiliteRoutes.Exports)} exact={false} component={Exports} />
			<CardItem
				name="Factures fournisseurs"
				url={noPrefix(ComptabiliteRoutes.FacturesFournisseurs)}
				component={SupplierInvoices}
			/>
			<CardItem
				name="Facture fournisseur"
				useSlug="id"
				url={noPrefix(ComptabiliteRoutes.FacturesFournisseursEdition)}
				component={SupplierInvoiceEditPage}
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name="Créer une facture fournisseur"
				url={noPrefix(ComptabiliteRoutes.FacturesFournisseursCreation)}
				component={SupplierInvoiceEditPage}
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name="Rétrocession factures fournisseurs"
				url={noPrefix(ComptabiliteRoutes.FacturesRetrocessions)}
				component={RetrocessionInvoices}
			/>
			<CardItem
				name="Facture de rétrocession"
				useSlug="id"
				url={noPrefix(ComptabiliteRoutes.FacturesRetrocessionsEdition)}
				component={RetrocessionEditPage}
				onlyVisibleOnExactRoute
			/>

			<CardItem
				name="Créer une facture de rétrocession"
				url={noPrefix(ComptabiliteRoutes.FacturesRetrocessionsCréation)}
				component={RetrocessionEditPage}
				onlyVisibleOnExactRoute
			/>
			<CardItem name="Avoirs" url={noPrefix(ComptabiliteRoutes.Avoirs)} component={CreditsTable} />
		</CardWithTabs>
	)
}

export default ComptabiliteRouter
