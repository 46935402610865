import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { requirementsSDAdetails } from "./helper"
import { Card, CardBody, CardFooter } from "reactstrap"
import "./MesPartenaires.scss"

const RequirementsSDA = (): JSX.Element => {
	return (
		<>
			<SectionHeader title="Obligations - SDA" />
			<div className="partner-card-container mx-8">
				<Card className="partner-card_sda m-5 ml-auto mr-auto">
					<img
						src={requirementsSDAdetails.header}
						alt={requirementsSDAdetails.label}
						className="partner-card-header_sda"
					/>
					<CardBody tag="div" className="d-flex d-flex-column w-100">
						<div>
							<img
								src={requirementsSDAdetails.logo}
								alt={requirementsSDAdetails.label + "logo"}
								className="mr-3 sda-logo"
							/>
						</div>
						<div className="w-75">
							<h5>{requirementsSDAdetails.label}</h5>
							<p>{requirementsSDAdetails.description}</p>
						</div>
					</CardBody>
					<CardFooter className="border-0 d-flex justify-content-center">
						<a href={requirementsSDAdetails.url} target="_blank" rel="noopener noreferrer">
							<button
								className="partner-button-pill"
								style={{ backgroundColor: requirementsSDAdetails.color }}>
								<span className="text-center px-2 text-white">
									<u>En savoir plus</u>
								</span>
							</button>
						</a>
					</CardFooter>
				</Card>
			</div>
		</>
	)
}

export default RequirementsSDA
