import API from "../../services/API"
import API_Hiboutik from "../../services/API_Hiboutik"
import { createJson } from "../../services/scriptFactures"
import dayjs from "dayjs"

const getProductId = async (productName, categoryName) => {
	try {
		let product = {},
			categories = [],
			category_id = null

		// Searching for the product, if exists, return ID, if not, create
		product = await API_Hiboutik.searchProductByName(productName)
		if (product.length && product[0]?.product_model === productName) return product[0].product_id

		// Search for the category, if exists, get ID, if not, create
		categories = await API_Hiboutik.getAllCategories(categoryName)
		category_id =
			categories.find((f) => f.category_name === categoryName && f.category_enabled)?.category_id || null
		if (!category_id) {
			const res = await API_Hiboutik.createCategory()
			category_id = res.category_id
		}

		// Creating product with the correct category and return id
		product = await API_Hiboutik.createProduct({
			product_model: productName,
			product_price: 0.01,
			product_supply_price: 0,
			product_category: category_id,
			product_stock_management: 0,
			// ON NE PEUT PAS DEFINIR LE TAUX TVA AVEC CETTE METHODE
		})
		return product.product_id
	} catch (error) {
		console.error(error)
	}
}

export const createFactureDeSuivi = async (laboratory, patient, user, schedule, silent) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!laboratory || !patient || !user) return

			let customerIdHiboutik = patient.hiboutikCustomerId

			if (!customerIdHiboutik && patient["@id"]) {
				try {
					const result = await API.get(patient["@id"])
					customerIdHiboutik = result.hiboutikCustomerId
				} catch (error) {
					console.error(error)
				}
			}

			if (!customerIdHiboutik) {
				try {
					const responseHiboutik = await API_Hiboutik.customerCreate(patient?.firstName, patient?.lastName)
					await API.update("PATIENTS_API", patient.id, {
						hiboutikCustomerId: `${responseHiboutik?.data?.customers_id}`,
					})
					customerIdHiboutik = responseHiboutik?.data?.customers_id
				} catch (error) {
					console.error(error)
				}
			}
			let newSale = await API_Hiboutik.createSale("DIV", customerIdHiboutik, laboratory?.warehouseIdHiboutik)

			await API_Hiboutik.saleAddDivPayment(
				newSale.sale_id,
				"SECU",
				0.01,
				dayjs().add(1, "year").format("YYYY-MM-DD")
			)

			const productId = await getProductId("Suivi Annuel", "Suivi")

			if (productId) {
				await API_Hiboutik.addProductToSale(
					newSale,
					{
						product_id: productId,
						discount: 0,
						product_price: 0.01,
						serialNumber: undefined,
					},
					1,
					undefined
				)
			}
			await API_Hiboutik.saleEnd(newSale.sale_id)
			newSale = await API_Hiboutik.getSale(newSale.sale_id)

			const subCompany = await API.find("SUB_COMPANIES_API", laboratory.subCompany.id)
			const json_fact = createJson(newSale, patient, user, laboratory, subCompany)

			const document = await API.createDocument({
				filename: `facture_suivi_${json_fact?.ventes?.sale_id}_${dayjs().format("YYYYMMDDHHmmss")}`,
				label: `Facture télétransmission de contrôle : ${json_fact?.ventes?.sale_id}`,
				type: "FACTURE_SUIVI_TELETRANS",
				status: silent ? "EN_ATTENTE_SUIVI_TELETRANS" : "NON_TELETRANSMIS_SUIVI_TELETRANS",
				numero: newSale.sale_id,
				config: {
					...json_fact,
					scheduleId: schedule.id,
					idFse: silent?.numFse,
				},
				patient: patient?.["@id"],
				seller: user?.id ? `/users/${user?.id}` : null,
				laboratory: laboratory["@id"],
			})
			resolve(document)
		} catch (error) {
			console.error(error)
			reject(error)
			return error
		}
	})
}

export const modifyFactureDeSuivi = async (document, fse) => {
	return new Promise(async (resolve, reject) => {
		try {
			const updatedDoc = await API.update("DOCUMENTS_API", document.id, {
				edit: { idFse: fse.numFse, status: "EN_ATTENTE_SUIVI_TELETRANS" },
			})
			resolve(updatedDoc)
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})
}
