import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, CaisseRoutePrefix, CaisseRoutes } from "./routes"

import CaisseValidation from "pages/mon-compte/ma-caisse/EspValidationCaisse"
import CaisseDepot from "pages/mon-compte/ma-caisse/Depot"
import CaisseReassort from "pages/mon-compte/ma-caisse/ReassortCaisse"
import CaisseHistorique from "pages/mon-compte/ma-caisse/EspHistoriqueCaisse"
import SortieDeCaisseAchat from "pages/mon-compte/ma-caisse/SortieDeCaisseAchat"

function CaisseRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={CaisseRoutePrefix}>
			<CardItem name="Validation" url={noPrefix(CaisseRoutes.Validation)} component={CaisseValidation} />
			<CardItem name="Dépôt" url={noPrefix(CaisseRoutes.Dépot)} component={CaisseDepot} />
			<CardItem name="Réassort" url={noPrefix(CaisseRoutes.Réassort)} component={CaisseReassort} />
			<CardItem
				name="Sortie de caisse pour achat"
				url={noPrefix(CaisseRoutes.Sortie)}
				exact={false}
				component={SortieDeCaisseAchat}
			/>
			<CardItem name="Historique" url={noPrefix(CaisseRoutes.Historique)} component={CaisseHistorique} />
		</CardWithTabs>
	)
}

export default CaisseRouter
