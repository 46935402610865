import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import MesPartenaires from "pages/mon-compte/partenaires/MesPartenaires"
import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"
import { ParametresPartenairesRoutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"
import RequirementsSDA from "pages/mon-compte/partenaires/RequirementsSDA"

function PartenairesRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Partenaires")
	return (
		<CardWithTabs urlprefix={GestionCompteRoutePrefix}>
			<CardItem
				name="Les partenaires"
				component={MesPartenaires}
				url={noPrefix(ParametresPartenairesRoutes.Partenaires)}
			/>
			<CardItem
				name="Obligations | SDA"
				component={RequirementsSDA}
				url={noPrefix(ParametresPartenairesRoutes.ObligationsSDA)}
			/>
		</CardWithTabs>
	)
}

export default PartenairesRouter
