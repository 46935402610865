import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const EntrepriseRoutePrefix = GestionCompteRoutePrefix + "/entreprise"

export const EntrepriseRoutes = {
	Informations: `${EntrepriseRoutePrefix}/informations`,
	SousSociete: `${EntrepriseRoutePrefix}/sous-societes`,
	SousTraitant: `${EntrepriseRoutePrefix}/sous-traitants`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(EntrepriseRoutePrefix, "")
}
