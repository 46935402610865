import { CashItem, CashRecord, LastState } from "../moneyItems"
import { Col, InputNumber } from "antd"

interface CashInputProps {
	cashItem: CashItem
	espValues?: CashRecord
	lastState?: LastState
	onChange: (value: number) => void
	bypassValidation: boolean
}

const CashInput = ({ cashItem, espValues, lastState, onChange, bypassValidation }: CashInputProps): JSX.Element => {
	return (
		<Col className="col-md-6">
			<div className="form-group row d-flex justify-content-center align-items-center">
				<label htmlFor={cashItem.name} className="col-1 col-md-3 d-flex flex-column align-items-center">
					{cashItem.label}
				</label>
				<InputNumber
					type="number"
					className="col-5 col-md-8 align-items-center"
					min={0}
					placeholder="0"
					value={espValues?.[cashItem.name]}
					onChange={onChange}
					max={lastState?.change?.[cashItem.name]}
					disabled={!lastState?.change?.[cashItem.name] && !bypassValidation}
				/>
				{lastState?.createdAt && (
					<div className="col-1 col-md-1">{+(lastState?.change?.[cashItem.name] || 0)}</div>
				)}
			</div>
		</Col>
	)
}

export default CashInput
