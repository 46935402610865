import { RetweetOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { getIdFromIri } from "services/functions"

type productReturnFormsProps = {
	product: any
	onClick: () => void
}

const ProductReturnFormResults = ({ product, onClick }: productReturnFormsProps): JSX.Element => {
	return (
		<Link to={"/mon-stock/retour/" + getIdFromIri(product["@id"])} className="item" onClick={onClick}>
			<RetweetOutlined className="ml-3 mr-1 mt-1" /> {product.label}
		</Link>
	)
}

export default ProductReturnFormResults
