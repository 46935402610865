/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "antd"
import useHasRole from "components/Hooks/useHasRole"
import AuthContext from "contexts/AuthContext"
import { useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import API from "services/API"
import { SortAlphabetically } from "services/functions"

const { Option } = Select

function AsyncUserSelect({
	setData,
	data,
	className = "",
	disabled = false,
	noAssistant,
	size = "medium",
	placeholder = "Sélectionner un ou des utilisateurs",
	onClear,
	mode = "multiple",
	onChange,
	allowClear = true,
	allowedLaboratories,
	isDoctolib,
	selectedLaboratory,
}) {
	const [usersList, setUsersList] = useState([])
	const [list, setList] = useState([])
	const { agendas } = useContext(AuthContext)

	const [loading, setLoading] = useState(false)
	let searchTimeout = useRef(null)

	const verifyDoctolib = (userId) => {
		const agenda = agendas.find(
			(a) =>
				a.user["@id"].split("/")[2] === userId?.toString() &&
				a.laboratory["@id"].split("/")[2] === selectedLaboratory?.toString()
		)
		return agenda?.doctolibType != null
	}

	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")

	const populateOptions = (users) => {
		let options = []
		const sortedUsers = SortAlphabetically(users, "lastName")
		for (const user of sortedUsers) {
			let name = `${user.firstName} ${user.lastName}`
			if (name.length >= 28) {
				name = `${name.slice(0, 28)}...`
			}
			options.push({
				detail: user,
				value: +user.id,
				label: name,
			})
		}
		setUsersList(options)
	}

	async function handleSearch(p) {
		try {
			setLoading(true)

			const searchParams = new URLSearchParams()
			if (p != null) {
				searchParams.set("omnisearch", p)
			}
			if (Array.isArray(allowedLaboratories)) {
				for (const laboratoryId of allowedLaboratories) searchParams.append("laboratories[]", laboratoryId)
			}

			let users = await API.findAll("USERS_API", `?${searchParams}`)
			if (noAssistant) users = users.filter((f) => !f?.roles?.includes("ROLE_ASSISTANTE"))

			setList(users)
			SortAlphabetically(users, "firstName")
			populateOptions(users)
			setLoading(false)
		} catch (e) {
			console.error("=> Error : ", e)
		}
	}

	useEffect(() => {
		clearTimeout(searchTimeout.current)
		searchTimeout.current = null
		searchTimeout.current = setTimeout(() => {
			handleSearch("")
		}, 100)
	}, [allowedLaboratories])

	return (
		<div className="w-100">
			<Select
				maxTagCount={2}
				allowClear={allowClear}
				mode={mode}
				//onClear={() => setData({})}
				filterOption={false}
				loading={loading}
				disabled={disabled || isDoctolib}
				className={className}
				notFoundContent={"Aucun résultat"}
				showSearch
				placeholder={placeholder}
				size={size}
				onClear={onClear}
				onSearch={(e) => {
					clearTimeout(searchTimeout.current)
					searchTimeout.current = null
					searchTimeout.current = setTimeout(() => {
						handleSearch(e)
					}, 400)
				}}
				value={(function () {
					if (isAffiliate || isAffiliateManager) return placeholder
					if (mode === "multiple") return [...(data || [])].map((v) => +v.id)
					else return usersList?.find((option) => +option.value === +data)?.value
				})()}
				onSelect={(e) => {
					if (mode === "multiple") {
						if (verifyDoctolib(e)) return
						const tmp = [...(data || [])]
						const l = list.find((f) => +f.id === +e)
						if (l) tmp.push({ ...l })
						setData(tmp)
					}
				}}
				onDeselect={(e) => {
					if (mode === "multiple") {
						let tmp = [...data]
						const id = tmp.findIndex((f) => +f.id === +e)
						if (id !== -1) tmp.splice(id, 1)
						setData(tmp)
					}
				}}
				onChange={(e) => {
					if (typeof onChange === "function") onChange(e)
					if (typeof setData === "function") {
						if (!verifyDoctolib(e)) setData(e)
						else toast.error("Vous ne pouvez pas déplacer un RDV sur un agenda doctolib")
					}
				}}>
				{usersList.map((d) => {
					return (
						<Option value={d.value} key={d.value}>
							{d.label}{" "}
							{verifyDoctolib(d.detail.id) && (
								<img
									alt="doctolib-logo"
									key={d.value}
									src="/static/img/doctolib/D_Doctolib_blue.png"
									style={{ width: 20, height: 20, marginTop: "-3px" }}
								/>
							)}
						</Option>
					)
				})}
			</Select>
		</div>
	)
}

export default AsyncUserSelect
