import generatePicker from "antd/es/date-picker/generatePicker"
import { FilterDropdownProps } from "antd/lib/table/interface"
import dayjs, { Dayjs } from "dayjs"
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs"
import { formatDateForDB } from "services/functions"

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

/**
 *	Sets column's filter as a tuple of two date strings.
 *	First string is after date, second string is before date.
 *
 * @exemple
 * {
 * 		// Column definition
 *		filterDropdown: TableDateRangeFilter,
 * }
 */
export default function TableDateRangeFilter({
	setSelectedKeys,
	selectedKeys,
	confirm,
}: FilterDropdownProps): JSX.Element {
	const dates = [
		selectedKeys[0] ? dayjs(selectedKeys[0]) : null,
		selectedKeys[1] ? dayjs(selectedKeys[1]) : null,
	] as [Dayjs | null, Dayjs | null] // Avoid TS error on tuple

	return (
		<div className="p-2">
			<DatePicker.RangePicker
				allowClear
				format="L"
				value={dates}
				onChange={(value) => {
					if (value == null) {
						setSelectedKeys([])
						confirm()
					} else {
						setSelectedKeys([
							value[0] ? formatDateForDB(value[0]) : "",
							value[1] ? formatDateForDB(value[1]) : "",
						])
						confirm()
					}
				}}
			/>
		</div>
	)
}
