import {
	UserInsuranceCoverageDemandDocumentModel,
	UserInsuranceCoverageDemandModel,
} from "../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import "./CoverageDemandDocModal.scss"
import { ColumnsType } from "antd/lib/table"
import { getHumanReadableDate } from "../../utils/date"
import Panel from "../Panel/Panel"
import { Table } from "antd"
import PDF from "../../assets/img/icons/pdf.png"
import download from "../../assets/img/icons/download.png"
import search from "../../assets/img/icons/search.png"
import envelope from "../../assets/img/icons/envelope.png"
import useDocument from "../../services/requests/http/document/hooks/useDocument"
import { useState } from "react"
import { DocumentEmailModal } from "../../pages/fiche-patient/Documents/SendMailDocument"
import { downloadFromUrl } from "../../services/Utilities"
import DocumentModal from "../../pages/fiche-patient/Documents/Modal.DocumentViewer"

interface Props {
	item?: UserInsuranceCoverageDemandModel
	onClose: () => void
}

export default function CoverageDemandDocModal({ item, onClose }: Props) {
	const [selectedDocument, setSelectedDocument] = useState<string>()
	const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false)
	const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false)

	const { data, isLoading } = useDocument(selectedDocument)

	if (!item) {
		return <></>
	}

	const onPreview = (id: string) => {
		setSelectedDocument(id)
		setPreviewModalOpen(true)
	}

	const onEmail = (id: string) => {
		setSelectedDocument(id)
		setEmailModalOpen(true)
	}

	return (
		<Modal
			isOpen={Boolean(item)}
			toggle={onClose}
			className="coverage-demand-doc-modal"
			backdrop="static"
			size="lg"
			centered
			keyboard={true}>
			{item && (
				<>
					<ModalHeader>
						<div className="row">
							<div className="col-sm-11">
								<span className={"modal-title"}>Vos documents</span>
							</div>
							<div className="col-sm-1">
								<div
									className="icon-sm position-absolute"
									style={{ right: "16px", cursor: "pointer", color: "#ff5630" }}
									onClick={onClose}>
									<i className="fad fa-times-circle" />
								</div>
							</div>
						</div>
					</ModalHeader>

					<ModalBody>
						<div className="container">
							<div className="row">
								<Panel className="w-100">
									<Table
										columns={columns(onPreview, onEmail)}
										className="w-100"
										dataSource={item.documents || []}
										pagination={false}
										rowKey={(record) => record.id}
									/>
								</Panel>
							</div>
						</div>
					</ModalBody>

					{!isLoading && data && (
						<div className="preview-container">
							<DocumentModal
								document={data}
								onClose={() => {
									setPreviewModalOpen(false)
									setSelectedDocument(undefined)
								}}
								isOpen={previewModalOpen}
								refreshDocuments={() => {}}
							/>

							<DocumentEmailModal
								document={data}
								open={emailModalOpen}
								onClose={() => {
									setEmailModalOpen(false)
									setSelectedDocument(undefined)
								}}
								onDocumentChange={() => {}}
							/>
						</div>
					)}
				</>
			)}
		</Modal>
	)
}

const columns: (
	onPreview: (id: string) => void,
	onEmail: (id: string) => void
) => ColumnsType<UserInsuranceCoverageDemandDocumentModel> = (onPreview, onEmail) => [
	{
		title: "Date de création",
		dataIndex: "createdAt",
		render: getHumanReadableDate,
	},
	{
		title: "Type",
		render: () => <img src={PDF} alt="pdf" width={15} />,
	},
	{
		title: "Description",
		dataIndex: "label",
	},
	{
		title: "Actions",
		render: (_, record) => (
			<div className={"actions"}>
				<a
					className={"action-button search-doc"}
					onClick={() => {
						onPreview(record.id)
					}}>
					<img src={search} alt={"Voir"} />
				</a>
				<a className={"action-button download-doc"} onClick={() => downloadFromUrl(record.signature)}>
					<img src={download} alt={"Télécharger"} />
				</a>
				<a
					className={"action-button mail-doc"}
					onClick={() => {
						onEmail(record.id)
					}}>
					<img src={envelope} alt={"Envoyer par email"} />
				</a>
			</div>
		),
	},
]
