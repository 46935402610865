import { Laboratory, SubCompany } from "@audiowizard/common"
import { Select } from "antd"
import AdressInput, { CompleteAdress } from "components/forms/AdressInput"
import TimezoneInput from "components/forms/TimezoneInput"
import * as React from "react"
import { ChangeEvent, ComponentPropsWithoutRef, MouseEvent, useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import API from "services/API"
import FieldWithError from "../../../components/forms/FieldWithError"
import PhoneInput from "../../../components/forms/PhoneInput"
import AuthContext from "../../../contexts/AuthContext"
import cx from "classnames"
import CountryInput, { CountryCode } from "components/forms/CountryInput"
import ButtonRounded from "components/Buttons/ButtonRounded"
import { LaboratoryModalFieldInfo } from "./LaboratoryModal.model"

type SubCompanySelectProps = {
	disabled: boolean
	value?: SubCompany | null | undefined
	onChange: (value: SubCompany) => void
}

function SubCompanySelect({ disabled, value, onChange }: SubCompanySelectProps): JSX.Element {
	const { data: subCompanies, isLoading } = useQuery<SubCompany[]>(
		["SUB_COMPANIES_API", { pagination: false }],
		async () => await API.findAll<SubCompany[]>("SUB_COMPANIES_API", "?pagination=false")
	)

	return (
		<div className="form-group">
			<label>Sous-société</label>
			<Select
				className={cx("form-control removeantd-class antd-add-padding", { "is-invalid": value == null })}
				loading={isLoading}
				options={subCompanies?.map((sub) => ({ label: sub.legalLabel, value: sub["@id"] }))}
				disabled={disabled}
				value={value?.["@id"]}
				onChange={(iri) => onChange(subCompanies!.find((sub) => sub["@id"] === iri)!)}
			/>

			{!disabled && (
				<p className="m-0 text-sm">
					<span className="text-warning">
						Vous ne pourrez pas modifier la sous-société associée par la suite
					</span>
					<br />

					<Link to="/mon-compte/mon-entreprise">
						Vous pouvez ajouter et modifier les sous-sociétés sur la page "Mon Entreprise"
					</Link>
				</p>
			)}
		</div>
	)
}

type LaboratoiresModalProps = {
	handleSubmitLaboratory: (e: MouseEvent<HTMLButtonElement>, value: Laboratory) => void
	id: string
	title: string
	editLaboratory: Laboratory
	errors: Record<string, string>
	setErrors: (errors: Record<string, string>) => void
	onClose: ComponentPropsWithoutRef<typeof Modal>["onClose"]
	isOpen: boolean
}

export default function LaboratoryModal({
	handleSubmitLaboratory,
	id,
	title,
	editLaboratory,
	setErrors,
	errors,
	onClose,
	isOpen,
}: LaboratoiresModalProps): JSX.Element {
	const { user } = useContext(AuthContext)
	const [laboratory, setLaboratory] = useState<Laboratory>({} as Laboratory)
	// Avoid doing this sort of thing because it leads to many *many* nested ifs to handle special cases
	const fields: LaboratoryModalFieldInfo[] = [
		{
			name: "city",
			label: "Ville",
			type: "text",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			value: laboratory.city,
			error: errors.city,
			setError: (err) => setErrors({ ...errors, city: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "adress",
			label: "Adresse",
			type: "text",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			value: laboratory.adress,
			error: errors.adress,
			setError: (err) => setErrors({ ...errors, adress: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "cpo",
			label: "Code Postal",
			type: "text",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			value: laboratory.cpo,
			error: errors.cpo,
			setError: (err) => setErrors({ ...errors, cpo: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "phone",
			label: "Téléphone",
			type: "phone",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			value: laboratory.phone,
			error: errors.phone,
			setError: (err) => setErrors({ ...errors, phone: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "email",
			label: "Email",
			type: "text",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			value: laboratory.email,
			error: errors.email,
			setError: (err) => setErrors({ ...errors, email: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "finess",
			label: "FINESS",
			type: "text",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			value: laboratory.finess,
			error: errors.finess,
			setError: (err) => setErrors({ ...errors, finess: err }),
			lg: 12,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "agendaColor",
			label: "Couleur agenda",
			type: "color",
			minLength: undefined,
			maxLength: undefined,
			pattern: "",
			// @ts-ignore
			value: laboratory.agendaColor,
			error: errors.agendaColor,
			setError: (err) => setErrors({ ...errors, agendaColor: err }),
			lg: 12,
			activeTab: "1",
			canBeDisabled: false,
		},
		{
			name: "datamutLogin",
			label: "Identifiant",
			type: "text",
			value: laboratory.datamutCredential?.login ?? "",
			error: errors.datamutUser,
			setError: (err) => setErrors({ ...errors, finess: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
			minLength: 1,
			maxLength: undefined,
			pattern: undefined,
			title: "Gestion Automatisée des Prises en Charge Mutuelle",
		},
		{
			name: "datamutPassword",
			label: "Mot de passe",
			type: "password",
			value: laboratory.datamutCredential?.password ?? "",
			placeholder: laboratory.datamutCredential?.password ? "*******" : "Mot de passe",
			error: errors.datamutUser,
			setError: (err) => setErrors({ ...errors, finess: err }),
			lg: 6,
			activeTab: "1",
			canBeDisabled: false,
			minLength: 1,
			maxLength: undefined,
			pattern: "",
		},
	]

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = e.currentTarget

		const base = {
			...laboratory,
			company: user!.company!["@id"] as any, // as any pour L'iri
		}

		if (name === "datamutLogin") {
			setLaboratory({
				...base,
				datamutCredential: {
					["@id"]: editLaboratory.datamutCredential?.["@id"]
						? editLaboratory.datamutCredential["@id"]
						: undefined,
					login: value,
					password: laboratory.datamutCredential?.password,
				},
			})
			return
		}

		if (name === "datamutPassword") {
			setLaboratory({
				...base,
				datamutCredential: {
					["@id"]: editLaboratory.datamutCredential?.["@id"]
						? editLaboratory.datamutCredential["@id"]
						: undefined,
					login: laboratory.datamutCredential?.login,
					password: value,
				},
			})
			return
		}

		setLaboratory({
			...base,
			[name]: value,
		})
	}

	const handleChangeCompleteAdress = (
		values: CompleteAdress,
		adressName: string,
		cpoName: string,
		cityName: string
	): void => {
		const res: Laboratory = {
			...laboratory,
			[adressName]: values.adress,
			[cpoName]: values.cpo,
			[cityName]: values.city,
		}

		setLaboratory(res)
	}

	useEffect(() => {
		setLaboratory(editLaboratory)
	}, [editLaboratory])

	const standardAdressComponent = (
		adressName: string,
		adressValue: string,
		cpoName: string,
		cpoValue: string,
		cityName: string,
		cityValue: string,
		disable: boolean
	): JSX.Element => {
		return (
			<Row className="mb-2">
				<Col lg={12}>
					<AdressInput
						name="completeAdress"
						value={{
							adress: adressValue!,
							cpo: cpoValue!,
							city: cityValue!,
						}}
						disabled={disable}
						onChange={(values) => handleChangeCompleteAdress(values, adressName, cpoName, cityName)}
					/>
				</Col>
				<Col lg={6}>
					<CountryInput
						id="country"
						label="Pays"
						groupClassName="w-100"
						value={laboratory.country as CountryCode}
						onChange={(country) => setLaboratory((oldVal) => ({ ...oldVal, country }))}
					/>
				</Col>
				{fields.map((field) => (
					<>
						{field.title && (
							<Col xs={12}>
								<p>{field.title}</p>
							</Col>
						)}
						<Col key={field.name} lg={field.lg}>
							{field.type === "phone" ? (
								<PhoneInput
									id={`${field.name}-${id}`}
									name={field.name}
									label={field.label}
									className="w-100"
									error={field.error}
									value={laboratory.phone!}
									onChange={(phone) => setLaboratory({ ...laboratory, phone })}
								/>
							) : (
								<FieldWithError
									id={`${field.name}-${id}`}
									name={field.name}
									label={field.label}
									type={field.type}
									minLength={field.minLength}
									maxLength={field.maxLength}
									pattern={field.pattern}
									value={field.value}
									className="mr-2"
									onChange={handleChange}
									error={field.error}
									setError={field.setError}
									placeholder={field.placeholder}
									disabled={field.canBeDisabled ? disable : false}
								/>
							)}
						</Col>
					</>
				))}
			</Row>
		)
	}
	return (
		<Modal id={id} isOpen={isOpen} toggle={onClose} centered size="lg" className="modal-div">
			<ModalHeader>
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-plus" />
					</div>

					<h6 className="mb-0">{title}</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				<SubCompanySelect
					disabled={laboratory.id != null} // disabled if editing
					value={laboratory.subCompany}
					onChange={(subCompany) =>
						setLaboratory({
							...laboratory,
							subCompany,
						})
					}
				/>

				<Row>
					<Col lg={6}>
						<FieldWithError
							id={`label-${id}`}
							name="label"
							label=" Nom du laboratoire"
							value={laboratory.label}
							className="mr-2"
							onChange={handleChange}
							error={errors.label}
							setError={(err) => setErrors({ ...errors, label: err })}
							required={true}
						/>
					</Col>
					<Col lg={6}>
						<TimezoneInput
							id={`timezone-${id}`}
							label="Fuseau horaire"
							groupClassName="form-group w-100 mr-2"
							size="large"
							allowClear={false}
							value={laboratory.timezone}
							onChange={(timezone) => setLaboratory({ ...laboratory, timezone })}
							error={errors.timezone}
						/>
					</Col>
				</Row>

				{standardAdressComponent(
					"adress",
					laboratory.adress || "",
					"cpo",
					laboratory.cpo || "",
					"city",
					laboratory.city || "",
					false
				)}
			</ModalBody>
			<ModalFooter>
				<ButtonRounded
					type="button"
					color="primary-outlined"
					isDisabled={laboratory.subCompany == null}
					onClick={(e) => handleSubmitLaboratory(e, laboratory)}>
					{laboratory.id ? "Modifier" : "Ajouter"}
				</ButtonRounded>
			</ModalFooter>
		</Modal>
	)
}
