/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { API_URL } from "../../../config"
import axios from "axios"
import { Select } from "antd"
import getSchedulesType, { FrontScheduleType } from "../getScheduleType"
import dayjs from "dayjs"
import getPatientClass from "hooks/specific/usePatientClass"
import { EquipmentClass } from "constants/patient/equipment"
import { ScheduleTypeEnum } from "@audiowizard/common"
import { IsObjectEmpty } from "services/functions"

const { Option } = Select

interface CustomSchedule {
	"@id"?: string
	status?: string
	type?: string
	startDate?: Date
	endTime?: string
	endDate?: Date
}

interface ScheduleTypeSelectProp {
	data?: any
	setData: (data: any) => void
	loading: boolean
	placeholder: string
	allowClear: boolean
	generic: any
	disabled?: boolean
	isDoctolib: boolean
	isRelance: boolean | undefined
	onScheduleTypeExport?: (scheduleType: Record<string, any>) => void
}

const ScheduleTypeSelect: React.FC<ScheduleTypeSelectProp> = ({
	data,
	setData,
	loading,
	placeholder,
	allowClear,
	generic,
	disabled,
	isDoctolib,
	isRelance,
	onScheduleTypeExport,
}) => {
	const [nbEssai, setNbEssai] = useState(0)
	const [scheduleTypes, setSchedulesType] = useState<Record<string, FrontScheduleType>>({})
	const [patientClass, patientHasCmu] = getPatientClass(data?.patient)

	const patientCategory = useMemo(() => {
		let categoryKeyForReference

		switch (patientClass) {
			case EquipmentClass.FirstClass:
				categoryKeyForReference = "class1"
				break
			case EquipmentClass.SecondClass:
				categoryKeyForReference = "class2"
				break
			default:
				categoryKeyForReference = ""
				break
		}

		if (patientHasCmu) {
			categoryKeyForReference += "Cmu"
		}

		return categoryKeyForReference
	}, [patientClass, patientHasCmu])

	useEffect(() => {
		;(async function () {
			const types = await getSchedulesType()
			setSchedulesType(types)
		})()
	}, [])

	useEffect(() => {
		if (IsObjectEmpty(scheduleTypes) || !data?.status) return

		onScheduleTypeExport?.(scheduleTypes[data.status])
	}, [scheduleTypes, data?.status])

	// Récupération de l'état des rendez-vous du patient : nombre de rdv essai éffectué, nb de rdv de controle obligatoire
	useEffect(() => {
		;(async function () {
			if (generic) {
				return
			}
			try {
				if (data?.patient?.id) {
					const results = await axios.get(
						API_URL + "/schedules/list?status=ESSAI&patient_id=" + data?.patient?.id
					)
					setNbEssai(results.data["hydra:member"].length)
				} else setNbEssai(0)
			} catch (e) {
				console.error(e)
			}
		})()
	}, [data?.patient])

	const handleChange = (value: ScheduleTypeEnum): void => {
		const tmp: CustomSchedule = {
			startDate: data?.fromAgenda ? data?.startDate : null,
			endDate: data?.fromAgenda ? data?.startDate : null,
		}

		if (!value) {
			setData({})
			return
		}
		const selectValue = value as keyof typeof scheduleTypes
		tmp["@id"] = scheduleTypes?.[value as keyof typeof scheduleTypes]["@id"]
		tmp.status = value
		tmp.type = "CLASSIQUE"
		if (value.includes("OBLIGATOIRE_")) {
			tmp.status = "APPAREILLE"
			tmp.type = value
		}

		const nextScheduleDelay = scheduleTypes?.[selectValue].scheduleInterval[patientCategory]

		if (!data?.fromAgenda && nextScheduleDelay) {
			tmp.startDate = dayjs().add(nextScheduleDelay, "month").toDate()
		}

		if (data?.startDate && data?.endDate) {
			const time_range_value = scheduleTypes?.[selectValue]?.time || null

			if (time_range_value) {
				const new_end_date = dayjs(data.startDate).add(time_range_value, "minute")
				tmp.endDate = new_end_date.toDate()
				tmp.endTime = new_end_date.format("HH:mm")
			}
		}

		setData((old: any) => ({ ...old, ...tmp }))
	}

	return (
		<div className="schedule-type-selector">
			<Select
				size={"large"}
				showSearch
				allowClear={allowClear}
				placeholder={placeholder || "Type de rendez-vous..."}
				style={{ width: "100%" }}
				value={
					data?.status === "APPAREILLE" && data?.type?.includes("OBLIGATOIRE_") ? data?.type : data?.status
				}
				onChange={(value) => {
					handleChange(value)
				}}
				disabled={loading || isDoctolib || disabled}
				filterOption={(input, option) => {
					if (option?.value) {
						const label = scheduleTypes?.[option.value as keyof typeof scheduleTypes]
						return (
							(label?.customLabel || label.label)
								?.toLocaleLowerCase()
								?.includes(input.toLocaleLowerCase()) || false
						)
					}
					return false
				}}>
				{Object.entries(scheduleTypes)
					.filter(([key, value]: [string, FrontScheduleType]) => !value?.hidden)
					.filter(([key, value]: [string, FrontScheduleType]) => (isRelance ? value.isRelance : true))
					.map(([key, value], k) => (
						<Option value={key} key={k}>
							<div className="schedule-line-option">
								<div className="option-color" style={{ backgroundColor: value.color }} />
								<div className="option-label">
									{value?.customLabel || value.label}
									{!generic && key === "ESSAI" ? ` numéro ${nbEssai + 1}` : ""}{" "}
									{!data?.fromAgenda && value.scheduleInterval[patientCategory] && (
										<small>
											<b>
												( rendez-vous théorique dans {value.scheduleInterval[patientCategory]}{" "}
												mois )
											</b>
										</small>
									)}
								</div>
							</div>
						</Option>
					))}
			</Select>
		</div>
	)
}

export default ScheduleTypeSelect
