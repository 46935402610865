import { ColumnsType } from "antd/lib/table"
import {
	HumanReadableDemandState,
	UserInsuranceCoverageDemandModel,
} from "../../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import dayjs from "dayjs"
import { dateTimeFormat } from "../../../utils/date"
import CoverageDemandBadge from "../../../components/CoverageDemandBadge/CoverageDemandBadge"
import Search from "../../../assets/img/icons/search.png"
import SearchDoc from "../../../assets/img/icons/searchDoc.png"
import { Laboratory, Patient, User } from "@audiowizard/common"
import { Link } from "react-router-dom"
import { UserInsuranceNetworkModel } from "../../../services/requests/http/userInsuranceNetworks/userInsurance.model"

export const CoverageDemandsListColumns: (
	onSee: (id: UserInsuranceCoverageDemandModel) => void,
	onDoc: (id: UserInsuranceCoverageDemandModel) => void,
	patients?: Patient[],
	team?: User[],
	laboratories?: Laboratory[],
	networks?: UserInsuranceNetworkModel[],
	filters?: Record<string, string[]>
) => ColumnsType<UserInsuranceCoverageDemandModel> = (
	onSee,
	onDoc,
	patients,
	team,
	laboratories,
	networks,
	filters
) => [
	{
		title: "Id",
		dataIndex: "id",
		sorter: true,
	},
	{
		title: "Date",
		dataIndex: "createdAt",
		render: (el) => dayjs(el).format(dateTimeFormat),
		sorter: true,
	},
	{
		title: "Par",
		dataIndex: "createdBy",
		filteredValue: filters?.createdBy,
		filters: team?.map((u) => ({
			text: `${u.firstName} ${u.lastName}`,
			value: u.id!,
		})),
		sorter: true,
		render: (el) => el.fullName,
		filterSearch: true,
	},
	{
		title: "Pour",
		dataIndex: "mainUserAtTime",
		filteredValue: filters?.mainUserAtTime,
		filters: team?.map((u) => ({
			text: `${u.firstName} ${u.lastName}`,
			value: u.id!,
		})),
		sorter: true,
		render: (el) => el.fullName,
		filterSearch: true,
	},
	{
		title: "Laboratoire",
		dataIndex: "laboratory",
		filters: laboratories?.map((l) => ({
			text: l.label,
			value: l.id!,
		})),
		filteredValue: filters?.laboratory,
		filterSearch: true,
		render: (el) => el.label,
		sorter: true,
	},
	{
		title: "Classe",
		sorter: true,
		dataIndex: "deviceClass",
	},
	{
		title: "Patient",
		dataIndex: "patient",
		filteredValue: filters?.patient,
		filterSearch: true,
		filters: patients?.map((p) => ({
			text: `${p.firstName} ${p.lastName}`,
			value: p.id!,
		})),
		sorter: true,
		render: (el) => (
			<Link to={"/fiche-patient/" + el.id} className="dropdown-item">
				<div className={"patient-name"}>{el.fullName}</div>
			</Link>
		),
	},
	{
		title: "Réseau",
		dataIndex: "network",
		filteredValue: filters?.network,
		filters: networks?.map((u) => ({
			text: u.name,
			value: u.id!,
		})),
		filterSearch: true,
		sorter: true,
		render: (el) => el.name,
	},
	{
		title: "Id réseau",
		dataIndex: "datamutId",
		sorter: true,
	},
	{
		title: "État",
		dataIndex: "state",
		filteredValue: filters?.state,
		filterSearch: true,
		filters: Object.entries(HumanReadableDemandState).map(([key, value]) => ({
			text: value,
			value: key,
		})),
		render: (el) => <CoverageDemandBadge state={el} />,
	},
	{
		title: "Action",
		dataIndex: "id",
		render: (_, record) => (
			<div className={"coverage-demands-list-actions"}>
				<a className={"action-button search"} onClick={() => onSee(record)}>
					<img src={Search} alt={"Voir la demande"} />
				</a>

				<a className={"action-button search-doc"} onClick={() => onDoc(record)}>
					<img src={SearchDoc} alt={"Voir les documents associés"} />
				</a>
			</div>
		),
	},
]
