import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap"
import PropTypes from "prop-types"
import { Row, Col, Select } from "antd"
import { capitalizeFirstLetter } from "utils/types/primitives/String/capitalizeFirstLetter"
import { labelTypeTemplates } from "./custom/listDefaultOptions"
import { useTranslation } from "react-i18next"
import { LocaleNamespace } from "config/intl/helpers"
import cx from "classnames"

interface PropChangModal {
	onConfirm: (s: string) => void
	onClose: () => void
	showCreateTemplateModale: boolean
}

const NewTemplateModal: React.FC<PropChangModal> = ({ onConfirm, onClose, showCreateTemplateModale }) => {
	const { t } = useTranslation(LocaleNamespace.Template)
	const [templateType, setTemplateType] = useState("")
	const [errorTemplateType, setErrorTemplateType] = useState("")
	const classes: string = cx("form-control w-100 removeantd-class antd-add-padding")

	const options = []

	for (const option in labelTypeTemplates) {
		options.push({
			value: option,
			label: <div className={"edition-select__custom-option"}>{t(labelTypeTemplates[option])}</div>,
		})
	}

	const handleChange = (selectedValue: string): void => {
		if (selectedValue === "") {
			setErrorTemplateType("Choix du type de modèle obligatoire.")
		}
		setErrorTemplateType("")
		setTemplateType(selectedValue)
	}

	const valideForm = (): boolean => {
		if (templateType === "") {
			setErrorTemplateType("Choix du type de modèle obligatoire.")
			return false
		}
		return true
	}
	return (
		<Modal isOpen={showCreateTemplateModale} backdrop="static">
			<ModalHeader>
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-ear" /> {/* <-- ICON */}
					</div>

					<h6 className="mb-0">Création d'un nouveau modèle vide</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				<form>
					<div className={"template-form__content"}>
						<Row>
							<Col span={18} className="pl-4">
								<label>{capitalizeFirstLetter(t("form.input.templateType.label"))}</label>
								<Select
									className={(classes || "") + (errorTemplateType ? " is-invalid" : "")}
									value={
										labelTypeTemplates[templateType] &&
										capitalizeFirstLetter(t(labelTypeTemplates[templateType]))
									}
									options={options}
									onChange={handleChange}
									placeholder={capitalizeFirstLetter(t("form.input.templateType.placeholder"))}
								/>
								{errorTemplateType && (
									<p
										className="invalid-feedback d-block" // display block pour forcer l'élément à s'afficher, sinon il est masqué car l'input n'est pas à côté dans le DOM
									>
										{errorTemplateType}
									</p>
								)}
							</Col>
						</Row>
					</div>
				</form>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={onClose}>
					Annuler
				</Button>
				<Button
					color="primary"
					onClick={() => {
						if (!valideForm()) return
						onConfirm(templateType)
					}}>
					Confirmer
				</Button>
			</ModalFooter>
		</Modal>
	)
}

NewTemplateModal.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	showCreateTemplateModale: PropTypes.bool.isRequired,
}

export default NewTemplateModal
