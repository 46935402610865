import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { RappelsSMSRoutes, noPrefix } from "./routes"
import { ParametreRoutePrefix } from "router/Personnalisation/routes"
import RappelSms from "pages/mon-compte/rappel-sms/SmsReminder"
import useCustomTitle from "components/Hooks/useTitle"

function RappelsSMSRouter(): JSX.Element {
	useCustomTitle("Paramètres | Rappels SMS")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem
				name="Personnalisation des rappels"
				component={RappelSms}
				url={noPrefix(RappelsSMSRoutes.RappelsSMS)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default RappelsSMSRouter
