export const SavRoutePrefix = "/sav"

export const SavRoutes = {
	List: `${SavRoutePrefix}`,
	New: `${SavRoutePrefix}/nouveau`,
	Edit: `${SavRoutePrefix}/:numero/editer`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(SavRoutePrefix, "")
}
