/* eslint-disable react-hooks/rules-of-hooks */
/** l'EventToolTip est une Popup qui apparaît lors du clic sur un event
 * eventToolTipFeature est un object qui permet de configurer cette Popup.
 * Cet objet est appelé comme props dans le composant BryntumCalendar du fichier du même nom.
 * Il est possible de customiser les outils qui apparaissent dans le listener "beforeShow"
 */
import { Patient } from "@audiowizard/common"
import { EventModel, EventTip, Popup, Tooltip } from "@bryntum/calendar"
import dayjs from "dayjs"
import { parsePhoneNumber } from "libphonenumber-js/min"
import { toast } from "react-toastify"
import API from "services/API"
import { Agenda, AWCalendar, Event } from "./AgendaTypes"
import { AWEventModel, AWEventTooltip } from "./AWEventModel"
import { isNotEditable } from "./BryntumAgenda.helper"

const eventTooltipFeature = {
	showOn: "hover",
	hoverDelay: 500,
	titleRenderer: (eventRecord: AWEventModel, tip: AWEventTooltip): any => {
		if (!tip?.clicked) {
			return ""
		}
		return `${
			eventRecord?.state === "WAITING_ROOM" || eventRecord?.state === "DONE"
				? "<i class='fad fa-solid fa-check' style='color: white;'></i>"
				: ""
		} 
		${
			eventRecord?.state === "MISSED" || eventRecord?.state === "CANCELLED"
				? "<i class='fad fa-times-circle text-danger'></i>"
				: ""
		}
		${eventRecord.name}`
	},
	recurrenceConfirmation: {
		minWidth: 800,
	},
	// minWidth: "550px",
	tools: {
		edit: {
			weight: 1000,
		},
		patientArrived: {
			cls: "b-fa-solid b-fa-check",
			weight: 900,
			tooltip: "Rendez-vous arrivé",
			async handler(event: AWEventModel, panel: AWEventTooltip): Promise<void> {
				// @ts-ignore
				panel.eventRecord.set("state", "WAITING_ROOM")
			},
		},
		patientFolder: {
			cls: "b-fad b-fa-user",
			weight: 800,
			tooltip: "Accéder à la fiche patient",
			handler: (e: PointerEvent, { eventRecord }: { eventRecord: AWEventModel }): void => {
				// @ts-ignore
				const id = eventRecord.patient["@id"].split("/")[2]
				const location = window.location.origin
				window.open(`${location}/fiche-patient/${id}`, "fiche-patient")
			},
		},
		patientAddress: {
			cls: "b-fa-solid b-fa-location-dot",
			weight: 700,
			tooltip: "Copier l'adresse",
			handler: async (e: PointerEvent, { eventRecord }: { eventRecord: AWEventModel }): Promise<void> => {
				try {
					if (eventRecord.patient?.id) {
						const res = await API.find<Patient>("PATIENTS_API", eventRecord.patient.id)
						const address = res.address + " " + res.cpo + " " + res.city
						await navigator.clipboard.writeText(address)
						toast.success("adresse copiée")
					} else {
						toast.warn("Aucun patient associé a ce rendez-vous")
					}
				} catch (err) {
					toast.error("erreur lors de la récupération de l'adresse")
				}
			},
		},
		callPatient: {
			cls: "b-fa-solid b-fa-phone-flip",
			weight: 600,
			tooltip: "Appeler",
			handler: async (e: PointerEvent, { eventRecord }: { eventRecord: AWEventModel }): Promise<void> => {
				const patient = eventRecord.patient
				if (!patient) {
					toast.warn("Aucun patient associé a ce rendez-vous")
					return
				}
				if (!patient.phoneMobile && !patient.phone) {
					toast.error("Il n'existe pas de téléphone pour ce patient")
					return
				}
				let phone
				if (!patient.phoneMobile || !patient.phone) {
					phone = patient.phoneMobile ?? patient.phone
					const a = document.createElement("a")
					a.href = `tel:${phone}`
					a.click()
				} else {
					new Popup({
						title: "Sélectionner un numéro",
						modal: true,
						closeAction: "destroy",
						closable: true,
						header: true,
						items: {
							choices: {
								type: "combo",
								editable: false,
								inlinePicker: true,
								listeners: {
									paint() {
										// @ts-ignore
										this.chipView.hidden = true
									},
								},
								items: [
									{
										value: parsePhoneNumber(patient.phoneMobile).number,
										text: `Mobile -- : ${parsePhoneNumber(patient.phoneMobile).formatNational()}`,
									},
									{
										value: patient.phone ? parsePhoneNumber(patient.phone).number : "",
										text: `Fixe ----- : ${
											patient.phone ? `${parsePhoneNumber(patient.phone).formatNational()}` : ""
										}`,
									},
								],
								onSelect(e: { record: { data: { value: any } } }) {
									const phone = e.record.data.value
									const a = document.createElement("a")
									a.href = `tel:${phone}`
									a.click()
									// @ts-ignore
									this.up().close()
								},
							},
						},
					})
				}
			},
		},
		mailPatient: {
			tooltip: "Copier l'adresse mail",
			cls: "b-fad b-fa-at",
			weight: 500,
			handler: async (e: PointerEvent, { eventRecord }: { eventRecord: AWEventModel }): Promise<void> => {
				try {
					if (eventRecord.patient?.id) {
						const res = await API.find<Patient>("PATIENTS_API", eventRecord.patient.id)
						if (!res.email) {
							toast.error("Aucun email renseigné pour ce patient")
							return
						}
						await navigator.clipboard.writeText(res.email)
						toast.success("mail copié")
					} else {
						toast.error("Aucun patient associé à ce rendez-vous")
					}
				} catch (err) {
					toast.error("erreur lors de la récupération du mail")
				}
			},
		},
		dragEvent: {
			cls: "b-fa-solid b-fa-arrows-up-down-left-right",
			weight: 400,
			tooltip: "Déplacer",
			handler(e: PointerEvent, event: EventTip): void {
				// @ts-ignore
				const calendar = this.up("calendar") as AWCalendar
				calendar.isMoving = true
				;(this as unknown as Tooltip).close()
			},
		},
		duplicate: {
			cls: "b-fa-solid b-fa-clone",
			weight: 300,
			tooltip: "Dupliquer",
			handler(e: PointerEvent, { eventRecord }: { eventRecord: AWEventModel }): void {
				const clone = eventRecord.copy() as EventModel
				//@ts-ignore
				const calendar = this.up("calendar") as AWCalendar
				calendar.duplicate = clone
				;(this as unknown as Tooltip).close()
			},
		},
		start: {
			cls: "b-fa-solid b-fa-play",
			weight: 200,
			tooltip: "Commencer le rendez-vous",
			async handler(e: PointerEvent, { eventRecord }: { eventRecord: AWEventModel }): Promise<void> {
				// on crée un listener sur le calendar afin d'avoir accès aux élément react et donc à AuthContext
				// @ts-ignore up est male définie dans la librairie
				const calendar = this.up("calendar") as AWCalendar
				calendar.trigger("handleStartSchedule", { eventRecord })
			},
		},
		delete: {
			cls: "b-fa-solid b-fa-trash-can",
			weight: 100,
			tooltip: "Supprimer",
			async handler(event: any, panel: EventTip): Promise<boolean> {
				// @ts-ignore library not complet
				const calendar = this.up("calendar") as AWCalendar
				// @ts-ignore library not complet
				calendar.removeEvents([panel.eventRecord])
				return false
			},
		},
	},
	listeners: {
		// a la sortie du rdv, il faut réinitialiser le tooltip pour que lors du prochain hover
		// seul le commentaire apparaît
		pointerout(e: { source: AWEventTooltip }) {
			if (e.source.clicked) {
				e.source.clicked = false
			}
		},
		beforeShow(e: { source: any; type: string }): boolean | void {
			// @ts-ignore

			const btnStateHidden = {
				delete: false,
				dragEvent: false,
				patientArrived: false,
				patientFolder: false,
				callPatient: false,
				mailPatient: false,
				patientAddress: false,
				edit: false,
				duplicate: false,
				start: false,
			}
			// dans le cas d’un hover il ne faut pas afficher les actions
			if (!e.source.clicked) {
				if (!e.source.eventRecord?.notes) return false
				for (const btn in btnStateHidden) {
					e.source.widgetMap[btn].hidden = true
				}
				return
			}
			// @ts-ignore, type de la bibliothèque incomplete
			const calendar = this.owner as AWCalendar

			const isDoctolib = calendar.extraData.agendas.find((agenda: Agenda) => {
				return agenda["@id"] === e?.source?.eventRecord?.agenda
			})?.doctolibType

			if (isDoctolib) {
				btnStateHidden.delete = true
				btnStateHidden.dragEvent = true
			}

			//verify if the user is an affiliate and if he's not the creator of the selected schedule

			const isNotPatientCreator = isNotEditable(
				e.source.eventRecord,
				calendar.extraData.currentUser,
				calendar.extraData.isAffiliate
			)

			//remove the option buttons if that's the case
			if (isNotPatientCreator) {
				btnStateHidden.delete = true
				btnStateHidden.dragEvent = true
				btnStateHidden.patientArrived = true
				btnStateHidden.patientFolder = true
				btnStateHidden.callPatient = true
				btnStateHidden.mailPatient = true
				btnStateHidden.patientAddress = true
				btnStateHidden.edit = true
				btnStateHidden.duplicate = true
				btnStateHidden.start = true
			}

			btnStateHidden.patientArrived = !isNotPatientCreator ? e.source.eventRecord.state === "WAITING_ROOM" : true

			if (calendar.extraData.isAffiliate) {
				btnStateHidden.patientArrived = true
				btnStateHidden.start = true
			}

			if (e.source.eventRecord.patient == null || e.source.eventRecord.patient === undefined) {
				btnStateHidden.patientFolder = true
				btnStateHidden.callPatient = true
				btnStateHidden.mailPatient = true
				btnStateHidden.patientAddress = true
				btnStateHidden.patientArrived = true
				btnStateHidden.duplicate = true
				btnStateHidden.start = true
			}

			if (calendar.extraData.fromDashboard) {
				btnStateHidden.edit = true
			}

			for (const btn in btnStateHidden) {
				e.source.widgetMap[btn].hidden = btnStateHidden[btn as keyof typeof btnStateHidden]
			}
		},
	},
	renderer({ eventRecord, tip }: { eventRecord: Event; tip: AWEventTooltip }): string {
		function returnCalendar(tip: any): AWCalendar | undefined {
			if (tip.owner.extraData) {
				return tip.owner as AWCalendar
			}
			if (tip.owner.calendar) {
				return tip.owner.calendar as AWCalendar
			}
			if (tip.owner.grid) {
				return tip.owner.grid.calendar as AWCalendar
			}
		}
		const calendar = returnCalendar(tip)
		if (!calendar) {
			console.error("Impossible de trouver le calendrier")
			return ""
		}
		const isNotPatientCreator = isNotEditable(
			eventRecord,
			calendar.extraData.currentUser,
			calendar.extraData.isAffiliate
		)

		// dans le cas d’un hover on n’affiche que le commentaire
		if (!tip.clicked) {
			tip.fullToolTip = false
			return (
				(!isNotPatientCreator &&
					`
					${eventRecord.notes}
			`) ||
				""
			)
		}

		tip.fullToolTip = true
		const startDate = dayjs(eventRecord.startDate)
		const endDate = dayjs(eventRecord.endDate)

		const dayjsDuration = dayjs.duration(endDate.diff(startDate))
		// formate la durée et supprime les valeur nulles
		const duration = dayjsDuration
			.format("D[d] H[h] m[min]")
			.replace(/\b0d\b/, "")
			.replace(/\b0h\b/, "")
			.replace(/\b0min\b/, "")

		const headTooltip = `${
			eventRecord.name
				? `<div style="font-weight: bold;"><i class="fad fa-user"></i>  ${eventRecord?.name?.toLocaleUpperCase()}</div>`
				: ""
		}
								<div><i class="fa-solid fa-calendar"></i>  ${startDate.format("dddd DD MMM (HH:mm")} - ${endDate.format(
			"HH:mm)"
		)} : <div style="font-weight: bold; margin-bottom:5px"><i class="fad fa-hourglass"></i>  ${duration} </div>`

		if (isNotPatientCreator) {
			return headTooltip
		}
		return `${headTooltip}
				<div style="font-weight: bold; padding-bottom: 2px;">${eventRecord.typeLabel?.toUpperCase()} ${
			eventRecord.state === "DONE" || eventRecord.state === "WAITING_ROOM"
				? "<i class='fad fa-solid fa-check text-info'></i>"
				: ""
		}</div>
                ${
					eventRecord.patient
						? `<div>${
								eventRecord.patient.phone
									? `Téléphone : ${parsePhoneNumber(eventRecord.patient.phone).formatNational()}`
									: ""
						  }</div>
                <div> ${
					eventRecord.patient.phoneMobile
						? `Mobile : ${parsePhoneNumber(eventRecord.patient.phoneMobile).formatNational()}`
						: ""
				}</div>`
						: ""
				}
                <div>${eventRecord.notes && eventRecord.notes != null ? eventRecord.notes : ""}</div>
				${
					eventRecord.createdBy?.firstName
						? `<div style="font-weight: light; font-style: italic; margin-top: 2px;">Créé par ${eventRecord.createdBy?.lastName} ${eventRecord.createdBy?.firstName}</div>`
						: ""
				}
            </div>
            `
	},
	// surcharge de la fonction du framework pour gérer le double tooltip sur click et hover
	// autrement lors du passage entre deux rdv proche le tooltip avec les actions disparaît
	internalOnPointerOver(event: any) {
		const me = this as unknown as AWEventTooltip,
			// @ts-ignore
			{ forElement, forSelector, activeTarget } = me
		let newTarget // Respect our owner's wish to not show when it's disabled

		// @ts-ignore
		if (me.disabled || (me.owner && !me.owner.showTooltipWhenDisabled && me.owner.disabled)) {
			return
		} // If the mouse moves over this tooltip, it is theoretically a mouseout of its
		// forElement, but allowOver lets us tolerate this ane remain visible.

		if (me.allowOver && me.element.contains(event.target)) {
			return
		} // There's been a mouseover. If we have a forSelector, we have to check
		// if it's an enter of a matching child

		if (forSelector) {
			let _event$relatedTarget

			// Moving inside a forSelector matching element
			if (
				activeTarget != null &&
				activeTarget !== void 0 &&
				activeTarget.contains(event.target) &&
				activeTarget.contains(event.relatedTarget)
			) {
				return
			}

			newTarget = event.target.closest(forSelector) // Mouseovers while within a target do nothing

			if (
				newTarget &&
				((_event$relatedTarget = event.relatedTarget) == null || _event$relatedTarget === void 0
					? void 0
					: _event$relatedTarget.closest(forSelector)) === newTarget
			) {
				return
			}

			// test ajouter par rapport au framework
			// résout le problème: le tooltip se ferme si l’on veut cliquer sur une action, et qu’il y a un autre événement juste au dessus
			// sans cela on detect le hover sur le nouveau rdv et le tooltip se ferme pour afficher la note du nouveau rdv
			if (me.isVisible && me.fullToolTip && newTarget && activeTarget && newTarget !== activeTarget) {
				return
			}
			// fin ajout par rapport au framework
		} // There's no forSelector, so check if we moved from outside the target
		else if (!forElement.contains(event.relatedTarget)) {
			newTarget = forElement
		} // Mouseover caused by moving from child to child inside the target
		else {
			return
		} // If pointer entered the target or a forSelector child, then show.

		if (newTarget) {
			me.handleForElementOver(event, newTarget)
		} // If over a non-forSelector child, behave as in forElement out
		else if (activeTarget) {
			me.handleForElementOut()
		}
	},
}

export default eventTooltipFeature
