import { Override, QuotePack, QuotePackProduct, QuotePackProductClass, QuotePackProductType } from "@audiowizard/common"
import { Button, Card, Spin } from "antd"
import { AdminQuotePack, AdminQuotePackOffer, AdminQuotePackProduct } from "./AdministrationInterface"
import _ from "lodash"
import React, { ReactNode, useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import API from "services/API"
import AdminPackForm from "./AdminPackForm"
import AdminPackOfferForm from "./AdminPackOfferForm"
import "./AdministrationCard.scss"

const defaultAdminPack: AdminQuotePack = {
	label: "",
	color: getComputedStyle(document.documentElement).getPropertyValue("--primary"),
	isDefault: false,
	isC2S: false,
	defaultClass: "FIRST_CLASS",
	products: {
		firstClass: {
			devices: [],
			earphones: { left: [], right: [] },
			earpieces: [],
			accessories: [],
			options: [],
			consumables: [],
			insurances: [],
			cros: [],
		},
		secondClass: {
			devices: [],
			earphones: { left: [], right: [] },
			earpieces: [],
			accessories: [],
			options: [],
			consumables: [],
			insurances: [],
			cros: [],
		},
	},
}
function convertQuotePackToAdminQuotePack(quotePack: QuotePack): AdminQuotePack {
	const newPack: AdminQuotePack = _.cloneDeep(defaultAdminPack)
	newPack.label = quotePack.label!
	newPack.color = quotePack.color!
	newPack.defaultClass = quotePack.defaultClass
	newPack.isDefault = quotePack.isDefault!

	for (const quotePackProduct of quotePack.quotePackProducts!) {
		const adminProduct: AdminQuotePackProduct = {
			"@id": quotePackProduct["@id"],
			productId: quotePackProduct.productId,
			model: quotePackProduct.reference,
			brandName: quotePackProduct.brandName,
			price: quotePackProduct.price,
			quantity: quotePackProduct.quantity,
			discountAmount: quotePackProduct.discountAmount,
			discountScale: quotePackProduct.discountScale,
			isActive: quotePackProduct.isActive,
			isDefault: quotePackProduct.isDefault,
		}

		// Based on class and type, put product in right nested property
		const newPackProductsOffer =
			quotePackProduct.class === "FIRST_CLASS" ? newPack.products.firstClass : newPack.products.secondClass
		switch (quotePackProduct.type) {
			case "DEVICE":
				newPackProductsOffer.devices.push(adminProduct)
				break
			case "EARPHONE":
				if (quotePackProduct.side === "LEFT") {
					newPackProductsOffer.earphones.left.push(adminProduct)
				} else {
					newPackProductsOffer.earphones.right.push(adminProduct)
				}
				break
			case "EARPIECE":
				newPackProductsOffer.earpieces.push(adminProduct)
				break
			case "ACCESSORY":
				newPackProductsOffer.accessories.push(adminProduct)
				break
			case "OPTION":
				newPackProductsOffer.options.push(adminProduct)
				break
			case "CONSUMABLE":
				newPackProductsOffer.consumables.push(adminProduct)
				break
			case "INSURANCE":
				newPackProductsOffer.insurances.push(adminProduct)
				break
			case "CROS":
				newPackProductsOffer.cros.push(adminProduct)
				break
		}
	}

	return newPack
}
function convertAdminProductsToQuotePackProducts(
	adminProducts: AdminQuotePackProduct[],
	class_: QuotePackProductClass,
	type: QuotePackProductType,
	side: "LEFT" | "RIGHT" | null = null
): Partial<QuotePackProduct>[] {
	return adminProducts.map((ap) => ({
		class: class_,
		type,
		side,
		"@id": ap["@id"] ?? undefined,
		productId: ap.productId!,
		price: ap.price,
		discountAmount: ap.discountAmount,
		discountScale: ap.discountScale,
		quantity: ap.quantity,
		isDefault: ap.isDefault,
		isActive: ap.isActive,
	}))
}
function convertAdminQuotePackOfferToQuotePackProducts(
	offer: AdminQuotePackOffer,
	class_: QuotePackProductClass
): Partial<QuotePackProduct>[] {
	return [
		...convertAdminProductsToQuotePackProducts(offer.devices, class_, "DEVICE"),
		...convertAdminProductsToQuotePackProducts(offer.earphones.left, class_, "EARPHONE", "LEFT"),
		...convertAdminProductsToQuotePackProducts(offer.earphones.right, class_, "EARPHONE", "RIGHT"),
		...convertAdminProductsToQuotePackProducts(offer.earpieces, class_, "EARPIECE"),
		...convertAdminProductsToQuotePackProducts(offer.accessories, class_, "ACCESSORY"),
		...convertAdminProductsToQuotePackProducts(offer.options, class_, "OPTION"),
		...convertAdminProductsToQuotePackProducts(offer.consumables, class_, "CONSUMABLE"),
		...convertAdminProductsToQuotePackProducts(offer.insurances, class_, "INSURANCE"),
		...convertAdminProductsToQuotePackProducts(offer.cros, class_, "CROS"),
	]
}
function convertAdminQuotePackToQuotePack(adminPack: AdminQuotePack): Omit<QuotePack, "@id" | "@type" | "id"> {
	const newQuotePack = {
		label: adminPack.label,
		color: adminPack.color,
		defaultClass: adminPack.defaultClass,
		isC2S: adminPack.isC2S,
		isDefault: adminPack.isDefault,
		quotePackProducts: [] as Partial<QuotePackProduct>[],
	}

	newQuotePack.quotePackProducts.push(
		...convertAdminQuotePackOfferToQuotePackProducts(adminPack.products.firstClass, "FIRST_CLASS"),
		...convertAdminQuotePackOfferToQuotePackProducts(adminPack.products.secondClass, "SECOND_CLASS")
	)

	return newQuotePack as QuotePack
}

type TabKey = "PACK" | "FIRST_CLASS_PRODUCTS" | "SECOND_CLASS_PRODUCTS"
const tabList: { key: TabKey; tab: ReactNode }[] = [
	{
		key: "PACK",
		tab: "Informations générales",
	},
	{
		key: "FIRST_CLASS_PRODUCTS",
		tab: "Équipement de classe 1",
	},
	{
		key: "SECOND_CLASS_PRODUCTS",
		tab: "Équipement de classe 2",
	},
]

type AdministrationCardProps = {
	loading?: boolean
	idToUpdate?: string
	pack: AdminQuotePack
	onPackChange: (value: AdminQuotePack) => void
}
function AdministrationCard({ loading = false, idToUpdate, pack, onPackChange }: AdministrationCardProps): JSX.Element {
	const queryClient = useQueryClient()
	const history = useHistory()

	const [selectedTabKey, setSelectedTabKey] = useState<TabKey>(tabList[0].key)

	const { mutate: updatePack, isLoading: mutateIsLoading } = useMutation(
		async () => {
			const quotePackData = convertAdminQuotePackToQuotePack(pack)

			if (idToUpdate == null) {
				await API.create("QUOTE_PACKS_API", quotePackData)
			} else {
				await API.update("QUOTE_PACKS_API", idToUpdate, quotePackData)
			}
		},
		{
			onSuccess: () => {
				queryClient.removeQueries("QUOTE_PACKS_API")

				toast.success("Pack sauvegardé avec succès.")
				history.push("/parametres/packs")
			},
			onError: (error) => {
				console.error("error!", error)
				toast.error("Erreur lors de la sauvegarde du pack.")
			},
		}
	)

	const isQuotePackEmptyOfProducts = (products: {
		firstClass: AdminQuotePackOffer
		secondClass: AdminQuotePackOffer
	}): boolean => {
		return (
			products.firstClass.devices.length === 0 &&
			products.secondClass.devices.length === 0 &&
			// earphones
			products.firstClass.earphones.left.length === 0 &&
			products.firstClass.earphones.right.length === 0 &&
			products.secondClass.earphones.left.length === 0 &&
			products.secondClass.earphones.right.length === 0 &&
			// earpieces
			products.firstClass.earpieces.length === 0 &&
			products.secondClass.earpieces.length === 0 &&
			// accessories
			products.firstClass.accessories.length === 0 &&
			products.secondClass.accessories.length === 0 &&
			// options
			products.firstClass.options.length === 0 &&
			products.secondClass.options.length === 0 &&
			// insurances
			products.firstClass.insurances.length === 0 &&
			products.secondClass.insurances.length === 0 &&
			// cros
			products.firstClass.cros.length === 0 &&
			products.secondClass.cros.length === 0
		)
	}

	// Check if every admin product has an hiboutik id and that there is a pack label
	const validPack = useMemo(() => {
		const everyProductHasId = (products: AdminQuotePackProduct[]): boolean =>
			products.every((p) => p.productId != null)

		if (isQuotePackEmptyOfProducts(pack.products)) return false

		return (
			pack.label.length > 0 &&
			// devices
			everyProductHasId(pack.products.firstClass.devices) &&
			everyProductHasId(pack.products.secondClass.devices) &&
			// earphones
			everyProductHasId(pack.products.firstClass.earphones.left) &&
			everyProductHasId(pack.products.firstClass.earphones.right) &&
			everyProductHasId(pack.products.secondClass.earphones.left) &&
			everyProductHasId(pack.products.secondClass.earphones.right) &&
			// earpieces
			everyProductHasId(pack.products.firstClass.earpieces) &&
			everyProductHasId(pack.products.secondClass.earpieces) &&
			// accessories
			everyProductHasId(pack.products.firstClass.accessories) &&
			everyProductHasId(pack.products.secondClass.accessories) &&
			// options
			everyProductHasId(pack.products.firstClass.options) &&
			everyProductHasId(pack.products.secondClass.options) &&
			// insurances
			everyProductHasId(pack.products.firstClass.insurances) &&
			everyProductHasId(pack.products.secondClass.insurances) &&
			// cros
			everyProductHasId(pack.products.firstClass.cros) &&
			everyProductHasId(pack.products.secondClass.cros)
		)
	}, [pack])

	return (
		<Card
			className="administration-card w-100 h-100"
			tabList={tabList}
			activeTabKey={selectedTabKey}
			onTabChange={(key) => setSelectedTabKey(key as TabKey)}
			tabBarExtraContent={
				<Button type="primary" loading={mutateIsLoading} disabled={!validPack} onClick={() => updatePack()}>
					Enregistrer
				</Button>
			}>
			{loading ? (
				<div className="d-flex justify-content-center">
					<Spin tip="Chargement du pack..." />
				</div>
			) : (
				<>
					{selectedTabKey === "PACK" && <AdminPackForm value={pack} onChange={onPackChange} />}
					{selectedTabKey === "FIRST_CLASS_PRODUCTS" && (
						<AdminPackOfferForm
							value={pack.products.firstClass}
							onChange={(firstClass) =>
								onPackChange({ ...pack, products: { ...pack.products, firstClass } })
							}
						/>
					)}
					{selectedTabKey === "SECOND_CLASS_PRODUCTS" && (
						<AdminPackOfferForm
							value={pack.products.secondClass}
							onChange={(secondClass) =>
								onPackChange({ ...pack, products: { ...pack.products, secondClass } })
							}
						/>
					)}
				</>
			)}
		</Card>
	)
}

export function NewQuotePackAdministrationPage(): JSX.Element {
	const [pack, setPack] = useState<AdminQuotePack>(() => _.cloneDeep(defaultAdminPack))

	return <AdministrationCard pack={pack} onPackChange={setPack} />
}

export function EditQuotePackAdministrationPage(): JSX.Element {
	const { id } = useParams<{ id: string }>()
	const [pack, setPack] = useState<AdminQuotePack>(() => _.cloneDeep(defaultAdminPack))

	const { data: fetchedPack, isLoading } = useQuery<QuotePack>(
		["QUOTE_PACKS_API", id],
		() => API.find("QUOTE_PACKS_API", id),
		{
			staleTime: Infinity, // to avoid re-fetching twice
			onError: (err) => {
				toast.error("Erreur lors de la récupération du pack.")
			},
		}
	)
	useEffect(() => {
		if (fetchedPack == null) return

		setPack(convertQuotePackToAdminQuotePack(fetchedPack))
	}, [fetchedPack])

	return <AdministrationCard loading={isLoading} idToUpdate={id} pack={pack} onPackChange={setPack} />
}

export function CopyQuotePackAdministrationPage(): JSX.Element {
	const { id } = useParams<{ id: string }>()
	const [pack, setPack] = useState<AdminQuotePack>(() => _.cloneDeep(defaultAdminPack))

	const { data: fetchedPack, isLoading } = useQuery<QuotePack>(
		["QUOTE_PACKS_API", id],
		() => API.find("QUOTE_PACKS_API", id),
		{
			staleTime: Infinity, // to avoid re-fetching twice
			onError: (err) => {
				toast.error("Erreur lors de la récupération du pack à dupliquer.")
			},
		}
	)
	useEffect(() => {
		if (fetchedPack == null) return

		// must cast otherwise TS is anoying
		const packCast = fetchedPack as Override<Partial<QuotePack>, { quotePackProducts: Partial<QuotePackProduct>[] }>

		packCast.label += " - Copie"

		// remove all id and @id, to create new rows for this pack
		packCast["@id"] = undefined
		packCast.id = undefined
		for (const p of packCast.quotePackProducts) {
			p["@id"] = undefined
			p.id = undefined
		}
		setPack(convertQuotePackToAdminQuotePack(fetchedPack))
	}, [fetchedPack])

	// no "idToUpdate", new pack will be created
	return <AdministrationCard loading={isLoading} pack={pack} onPackChange={setPack} />
}
