import { Override } from "@audiowizard/common"
import { InputNumber, InputNumberProps, Select } from "antd"

type Scale = "%" | "€"

type Discount = {
	amount: number
	scale: Scale
}

type DiscountInputProps = Override<
	InputNumberProps<number>,
	{
		value?: Discount
		price: number
		onChange?: (value: Discount) => void
	}
>
export default function DiscountInput({
	value = { amount: 0, scale: "€" },
	price = 0,
	onChange = () => undefined,
	...props
}: DiscountInputProps): JSX.Element {
	const maxInput = value.scale === "%" ? 100 : price

	return (
		<InputNumber
			min={0}
			max={maxInput}
			decimalSeparator=","
			precision={value.scale === "%" ? 0 : 2}
			step={1}
			value={value.amount}
			onChange={(amount: number | null) => onChange({ ...value, amount: amount ?? 0 })}
			addonAfter={
				<Select
					value={value.scale}
					onChange={(scale) =>
						onChange({
							scale,
							amount: scale === "€" ? value.amount : Math.min(value.amount, 100), // Clamp to 100%
						})
					}>
					<Select.Option value="€">€</Select.Option>
					<Select.Option value="%">%</Select.Option>
				</Select>
			}
			{...props}
		/>
	)
}
