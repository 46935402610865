import useHasRole from "components/Hooks/useHasRole"
import useHasServices from "components/Hooks/useHasServices"
import { ServiceRef } from "components/hoc/withRequiredServices"
import SpinnerAW from "components/utils/SpinnerAW"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { hasTeletransService } from "pages/FSV/utilsFSV"
import AgendaModal from "pages/Schedules/Agenda/ModalAgendaContainer"
import { zipper } from "pages/Schedules/doctolib.helpers"
import { roleLabel } from "pages/mon-compte/mon-equipe/Index"
import PatientUIToggleModal from "pages/salle-attente/PatientUIToggleModal"
import { useContext, useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Alert, Button } from "reactstrap"
import API from "services/API"
import CurrentLaboratorySelect from "./CurrentLaboratorySelect"
import DropDownMenu from "./DropDownMenu"
import Spotlight from "./Spotlight/Spotlight"
import "./topBar.scss"

const MissingMandateAlert = () => {
	const { user, services } = useContext(AuthContext)
	const [loading, setLoading] = useState(false)
	const isManager = useHasRole("ROLE_MANAGER")

	const hasMandate = user?.company?.audioWizardContract?.hasMandate
	const freePeriodDateEnd = useMemo(
		() => dayjs(services.find((s) => s.isMain && s.type === "SUBSCRIPTION").freePeriodDateEnd),
		[services]
	)

	const redirectToGocardless = async () => {
		setLoading(true)

		try {
			const res = await API.goCardlessMandateNew(user?.company?.audioWizardContract?.id)

			window.location = res.data.redirectFlowUrl
		} finally {
			setLoading(false)
		}
	}

	if (hasMandate) return ""
	if (loading) return <SpinnerAW text="Redirection vers GoCardless..." />

	return (
		<Alert color="warning" data-cy="no-mandate-alert" className="text-center w-100 m-0" fade={false}>
			{isManager ? (
				<span className="lead">
					Pour compléter la mise à jour vous devez saisir votre IBAN.{" "}
					<a
						href="https://www.youtube.com/watch?v=ES-azMQYPK0"
						rel="noopener noreferrer"
						target="_blank"
						className="text-primary">
						Découvrez pourquoi
					</a>
				</span>
			) : (
				<span className="lead">Contactez-votre responsable pour terminer la mise à jour</span>
			)}
			{freePeriodDateEnd.isValid() && (
				<span className="lead">
					Vous devez le faire avant le :{" "}
					<time dateTime={freePeriodDateEnd.format("YYYY-MM-DD")} className="font-weight-bold">
						{freePeriodDateEnd.format("L")}
					</time>
					.
				</span>
			)}{" "}
			{isManager && (
				<Button color="link" size="sm" onClick={redirectToGocardless} data-cy="redirect-to-gocardless">
					<span className="lead text-info alert-link">Saisir mon IBAN</span>
				</Button>
			)}
		</Alert>
	)
}

const UserTopBar = () => {
	const history = useHistory()
	const { user, patientUI, setUiDisplay, uiDisplay, setPatient, subscriptions, hasDoctolib } = useContext(AuthContext)
	const marqueBlancheServiceStatus = useHasServices(ServiceRef.MarqueBlanche)

	const [showChooseDoctolibAgendaView, setShowChooseDoctolibAgendaView] = useState(false)
	const [showAgendaModal, setShowAgendaModal] = useState(false)
	const [patientUIModal, setPatientUIModal] = useState(false)
	const isManager = useHasRole("ROLE_MANAGER")

	let visitedPatients = window.localStorage.getItem("visitedPatients")

	const openCrispDoc = (event) => {
		event.preventDefault()
		const openBtn = document.querySelector("#crisp-chatbox a[aria-label~=chat")
		const helpDeskBtn = document.querySelector("#crisp-chatbox a[data-mode=helpdesk")

		if (openBtn.dataset.visible === "true") {
			return openBtn?.click()
		}

		return helpDeskBtn?.click()
	}
	return (
		<>
			<MissingMandateAlert />
			{uiDisplay.topBarHiboutikTokenAlert && (
				<Alert color="warning" data-cy="no-hibtoken-alert" className="text-center w-100 m-0">
					Votre compte réclame une intervention du support pour pouvoir accéder à la partie vente
				</Alert>
			)}
			<nav className="navbar navbar-horizontal navbar-expand-lg navbar-dark bg-gradient-primary">
				<div className="container-fluid d-flex flew-row navbar-responsive ml-4 mr-4">
					<Link className="navbar-brand" to={patientUI ? history?.location?.pathname : "/"}>
						{marqueBlancheServiceStatus && user?.company?.whiteLabel?.isActive ? (
							<>
								<img
									alt={`Logo ${user?.company?.label}`}
									src={user?.company?.whiteLabel?.logo}
									onClick={() => history.push("/")}
									className="cursor-pointer"
								/>

								<div href="#!" title="Accueil" className="nav-link reset-initial text-white mx-2 py-3">
									<img
										alt="Logo AudioWizard"
										src="/static/img/icons/LOGO-AUDIO-WIZARD.svg"
										className="img-white button-row-logo cursor-pointer"
										onClick={() => history.push("/")}
									/>
								</div>
							</>
						) : (
							<>
								<img
									alt="Logo AudioWizard"
									src="/static/img/brand/audowizard-logo-white4.png"
									className="left-logo cursor-pointer"
									onClick={() => history.push("/")}
								/>
								<div href="#!" title="Accueil" className="nav-link reset-initial text-white mx-2 py-3">
									<img
										alt="Logo AudioWizard"
										src="/static/img/icons/LOGO-AUDIO-WIZARD.svg"
										className="img-white button-row-logo cursor-pointer"
										onClick={() => history.push("/")}
									/>
								</div>
							</>
						)}
					</Link>
					{!patientUI && (
						<div className="d-flex searchbar-container">
							<CurrentLaboratorySelect user={user} />
							<Spotlight />
						</div>
					)}

					<div className="d-flex flex-row align-items-center justify-content-center button-row">
						{!patientUI && (
							<>
								<div className="d-flex align-items-center">
									<a
										href="#!"
										title="Accueil"
										className="nav-link reset-initial text-white mx-2 py-3">
										<img
											alt="Logo AudioWizard"
											src="/static/img/icons/LOGO-AUDIO-WIZARD.svg"
											className="img-white left-logo-icon cursor-pointer"
											onClick={() => history.push("/")}
										/>
									</a>
									{visitedPatients && (
										<a
											href="#!"
											title="Dernier patient visité"
											className="nav-link reset-initial text-white mx-2 py-3"
											onClick={(e) => {
												e.preventDefault()
												const patients = JSON.parse(visitedPatients)
												history.push(`/fiche-patient/${patients[0]}`)
											}}>
											<i
												className="fad fa-fw fa-user cursor-pointer"
												style={{ fontSize: "2rem", verticalAlign: "middle" }}
											/>
										</a>
									)}
									<a
										href="#!"
										title="Lire une carte vitale"
										className="reset-initial text-white mx-2 py-3 mt-1"
										onClick={(e) => {
											if (hasTeletransService(subscriptions)) {
												setUiDisplay((old) => ({
													...old,
													lectureCVModal: true,
													mode: "CV",
												}))
												e.preventDefault()
											} else history.push(isManager ? "/mon-compte/mon-abonnement" : "/")
										}}>
										<i
											className="fad fa-fw fa-address-card cursor-pointer"
											style={{ fontSize: "2rem", verticalAlign: "middle" }}
										/>
									</a>
									<a
										href="#!"
										title="Ouvrir l'agenda"
										className="reset-initial text-white mx-2 py-3"
										onContextMenu={(e) => {
											e.preventDefault()
										}}
										onMouseDown={(e) => {
											if (![1, 2].includes(e.button)) return
											e.preventDefault()
											window.open(
												"/agenda",
												"Agenda AudioWizard",
												"scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,\n" +
													"width=1280,fontSize=720,left=0,top=0"
											)
										}}
										onClick={(e) => {
											e.preventDefault()
											setUiDisplay((old) => ({ ...old, agendaModal: true }))
										}}>
										<i
											className="fad fa-fw fa-calendar cursor-pointer"
											style={{ fontSize: "2rem", verticalAlign: "middle" }}
										/>
									</a>
									<a
										href="#!"
										title="Créer un nouveau rendez-vous"
										className="reset-initial text-white mx-2 py-3"
										onClick={(e) => {
											setUiDisplay((old) => ({ ...old, scheduleModal: true }))
											e.preventDefault()
										}}>
										<i
											className="fad fa-fw fa-calendar-plus cursor-pointer"
											style={{ fontSize: "2rem", verticalAlign: "middle" }}
										/>
									</a>
									{hasDoctolib && (
										<a
											href="#!"
											title="Accéder à l'agenda Doctolib"
											className="nav-link pr-lg-0 text-white cursor-pointer"
											onClick={() =>
												setShowChooseDoctolibAgendaView(!showChooseDoctolibAgendaView)
											}
											tabIndex={1}
											onBlur={() => {
												setShowChooseDoctolibAgendaView(false)
											}}>
											<img
												className="cursor-pointer"
												alt="doctolib_logo"
												src="/static/img/doctolib/D_Doctolib_white.png"
												style={{ width: 40, height: 40 }}
											/>
											{showChooseDoctolibAgendaView && (
												<div className="position-absolute mt-2 bg-white shadow rounded">
													<ul className="list-group" style={{ color: "#0596DE" }}>
														<li
															onClick={() => {
																zipper.openListView()
															}}
															className="list-group-item">
															<i
																className="fa-solid fa-list mr-2"
																style={{ color: "#0596DE" }}
															/>
															Vue Liste
														</li>
														<li
															onClick={() => {
																zipper.openCalendar()
															}}
															className="list-group-item">
															<i
																className="fa-solid fa-calendar mr-2"
																style={{ color: "#0596DE" }}
															/>
															Vue Semaine
														</li>
													</ul>
												</div>
											)}
										</a>
									)}
									<a
										href="#!"
										title="Accéder à la vente rapide"
										className="reset-initial text-white mx-2 py-3"
										onClick={() => {
											if (!window.location.href.includes("fiche-patient")) setPatient({})
											history.push("/ventes/nouvelle")
										}}>
										<i
											className="fad fa-fw fa-cart-plus cursor-pointer"
											style={{ fontSize: "2rem", verticalAlign: "middle" }}
										/>
									</a>
									<a
										href="#!"
										title="Ouvir l'aide"
										className="reset-initial text-white mx-2 py-3"
										onClick={openCrispDoc}>
										<i
											className="fad fa-fw fa-info-circle cursor-pointer"
											style={{ fontSize: "2rem", verticalAlign: "middle" }}
										/>
									</a>
								</div>
								<div className="nav-item dropdown dropdown-animate mx-2">
									<button
										data-toggle="dropdown"
										className="btn btn-neutral lh-100 px-4 py-1 dropdownMenu-button m-0 user-button">
										<span style={{ fontSize: "1rem", fontWeight: "700" }}>
											M{user.gender === "HOMME" ? "." : <sup>me</sup>} {user.lastName}
										</span>
										<br />
										<span className="text-muted" style={{ fontSize: "0.8rem" }}>
											{roleLabel[user.roles]}
											{user.roles.includes("ROLE_ASSISTANT") && user.gender === "FEMME"
												? "e"
												: ""}
										</span>
									</button>
									<DropDownMenu />
								</div>
							</>
						)}

						{!user.roles.includes("ROLE_FSV") && (
							<div className="custom-control custom-switch ml-3 d-none d-sm-block">
								<input
									type="checkbox"
									className="topbar-custom-control-input"
									id="customSwitch1"
									checked={patientUI}
									onChange={() => setPatientUIModal(true)}
								/>
								<label
									className="custom-control-label text-white"
									htmlFor="customSwitch1"
									data-cy="salle-d-attente-toggle"
								/>
							</div>
						)}
					</div>
				</div>
			</nav>
			{showAgendaModal && <AgendaModal createScheduleState={"FOR_USER"} setShow={setShowAgendaModal} />}
			<PatientUIToggleModal open={patientUIModal} onClose={() => setPatientUIModal(false)} />
		</>
	)
}

export default UserTopBar
