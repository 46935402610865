import {
	CoverageDemandState,
	HumanReadableDemandState,
	UserInsuranceCoverageDemandModel,
} from "../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { getHumanReadableDate } from "../../utils/date"
import Panel from "../Panel/Panel"
import PanelSectionTitle from "../Panel/PanelSectionTitle"
import CoveragePanelLine from "../../pages/CoverageDemand/Components/CoveragePanelLine"
import Separator from "../Separator/Separator"
import "./CoverageDemandModal.scss"
import { Fragment, useCallback, useContext, useMemo, useState } from "react"
import useDocument from "../../services/requests/http/document/hooks/useDocument"
import { formatCurrency } from "../../services/functions"
import { ColumnsType } from "antd/lib/table"
import { Table, Tooltip } from "antd"
import { compact } from "lodash"
import ButtonRounded from "../Buttons/ButtonRounded"
import getCoverageAvailableActions, {
	AvailableActionInterface,
} from "../../pages/CoverageDemand/Utils/getCoverageAvailableActions"
import useUserInsuranceCoverageDemandAction from "../../services/requests/http/userInsuranceCoverageDemandService/Hooks/useUserInsuranceCoverageDemandAction"
import { toast } from "react-toastify"
import useUserInsuranceCoverageDemandState from "../../services/requests/http/userInsuranceCoverageDemandService/Hooks/useUserInsuranceCoverageDemandState"
import DocumentViewer from "../../pages/fiche-patient/Documents/Modal.DocumentViewer"
import CoverageDemandAmount from "./CoverageDemandAmount"
import { useFetchPaymentType } from "../../pages/Settings/paymentTypes/PaymentTypes.helpers"
import AuthContext from "../../contexts/AuthContext"
import usePaymentsForInvoice from "../../services/requests/http/payments/Hooks/usePaymentsForInvoice"

interface Props {
	item?: UserInsuranceCoverageDemandModel
	onClose: () => void
}

export default function CoverageDemandModal({ item, onClose }: Props) {
	const quote = useMemo(() => {
		return item?.documents.find(({ type }) => type === "DEVIS")
	}, [item?.documents])

	const { data, isLoading, isRefetching } = useDocument(quote?.id)
	const { mutateAsync, isLoading: isMutating } = useUserInsuranceCoverageDemandAction()

	const { laboratory } = useContext(AuthContext)
	const { paymentTypesRef } = useFetchPaymentType(laboratory.subCompany)

	const { data: demandState } = useUserInsuranceCoverageDemandState(
		item?.id,
		[CoverageDemandState.CANCELED, CoverageDemandState.DECLINED, CoverageDemandState.PAID].includes(
			item?.state ?? CoverageDemandState.WAITING_INSERT
		)
			? undefined
			: 15e3
	)

	const [modalOpen, setModalOpen] = useState(false)
	const [modalDocumentId, setModalDocumentId] = useState<string | undefined>()
	const { data: modalDoc, refetch: refetchModalDoc } = useDocument(modalDocumentId)
	const { data: payments } = usePaymentsForInvoice({
		invoiceId: item?.documents.find((el) => el.type === "FACTURE")?.numero ?? undefined,
	})

	const availableActions = useMemo(() => {
		if (!item) return null

		return getCoverageAvailableActions(item.network.name, demandState?.state)
	}, [item, demandState?.state])

	const RenderAction = useCallback(
		(el: AvailableActionInterface) =>
			item ? (
				<ButtonRounded
					key={el.value}
					color={el.color}
					isDisabled={isMutating || item.lastAction === el.value || !(el.condition?.(item) ?? true)}
					onClick={() => {
						mutateAsync({
							id: item.id,
							action: el.value,
						})
							.then(() => {
								toast.success("Votre demande a bien été prise en compte")
							})
							.catch((e) => {
								toast.error("Impossible de prendre en compte le changement.")
								console.error(e)
							})
					}}>
					<Tooltip placement="top" title={!el.condition?.(item) ? el.conditionNotMetMessage : undefined}>
						{el.label}
					</Tooltip>
				</ButtonRounded>
			) : (
				<></>
			),
		[isMutating, item]
	)

	const total = useMemo(() => {
		if (!item || !data) return null

		return item.deviceClass === 1
			? data.config.costLeftClass1 + data.config.costRightClass1
			: data.config.costLeftClass2 + data.config.costRightClass2
	}, [data, item])

	const totalInsurance = useMemo(() => {
		if (!demandState || !demandState.amoAmount) return null

		return demandState.amoAmount + (demandState?.amcAmount ?? 0)
	}, [demandState])

	const products = useMemo(() => {
		if (!data || !item) return []

		if (item.deviceClass === 1) {
			return compact([
				data.config.productLeftClass1
					? {
							id: `${data.config.productLeftClass1.product_id}-left`,
							name: data.config.productLeftClass1.product_model,
							discount: data.config.productLeftClass1.discount,
							total: data.config.costLeftClass1 - data.config.productLeftClass1.discount,
					  }
					: undefined,
				data.config.productRightClass1
					? {
							id: `${data.config.productRightClass1.product_id}-right`,
							name: data.config.productRightClass1.product_model,
							discount: data.config.productRightClass1.discount,
							total: data.config.costRightClass1 - data.config.productRightClass1.discount,
					  }
					: undefined,
			])
		} else {
			return compact([
				data.config.productLeftClass2
					? {
							id: `${data.config.productLeftClass2.product_id}-left`,
							name: data.config.productLeftClass2.product_model,
							discount: data.config.productLeftClass2.discount,
							total: data.config.costLeftClass2 - data.config.productLeftClass2.discount,
					  }
					: undefined,
				data.config.productRightClass2
					? {
							id: `${data.config.productRightClass2.product_id}-right`,
							name: data.config.productRightClass2.product_model,
							discount: data.config.productRightClass2.discount,
							total: data.config.costRightClass2 - data.config.productRightClass2.discount,
					  }
					: undefined,
			])
		}
	}, [data, item])

	if (!item) {
		return <></>
	}

	return (
		<Modal
			isOpen={Boolean(item)}
			toggle={onClose}
			className="coverage-demand-modal"
			backdrop="static"
			size="lg"
			centered
			keyboard={true}>
			{item && (
				<>
					<ModalHeader>
						<div className="row">
							<div className="col-sm-11">
								<span className={"modal-title"}>
									Aperçu de la PEC de {item.patient.fullName} du{" "}
									{getHumanReadableDate(item.createdAt)}
								</span>
							</div>
							<div className="col-sm-1">
								<div
									className="icon-sm position-absolute"
									style={{ right: "16px", cursor: "pointer", color: "#ff5630" }}
									onClick={onClose}>
									<i className="fad fa-times-circle" />
								</div>
							</div>
						</div>
					</ModalHeader>

					<ModalBody>
						<div className="container">
							<div className="row mb-4">
								<div className="col-md-6">
									<PanelSectionTitle>Informations</PanelSectionTitle>
									<Separator />

									<Panel>
										<CoveragePanelLine title={"Par"} value={item.createdBy.fullName} />

										{item.documents.map((d) => (
											<CoveragePanelLine
												title={d.label}
												value={d["@id"]}
												key={d.id}
												onClick={() => {
													setModalDocumentId(d.id)
													setModalOpen(true)
												}}
											/>
										))}
									</Panel>
								</div>

								<div className="col-md-6">
									<PanelSectionTitle>Remboursements</PanelSectionTitle>

									<Separator />

									<Panel>
										<CoveragePanelLine title={"Numéro PEC"} value={item.id} />
										{demandState && (
											<CoveragePanelLine
												title={"État PEC"}
												value={HumanReadableDemandState[demandState.state]}
											/>
										)}
										<CoveragePanelLine title={"Classe simulée"} value={item.deviceClass} />
										{data && !totalInsurance && (
											<CoveragePanelLine
												title={"Montant de la simulation"}
												value={formatCurrency(total)}
											/>
										)}
										{totalInsurance && (
											<>
												<CoveragePanelLine
													title={"Montant pris en charge"}
													value={formatCurrency(totalInsurance)}
												/>
											</>
										)}
										{data && demandState?.userDueAmount && (
											<CoveragePanelLine
												title={"Reste à charge"}
												value={formatCurrency(demandState.userDueAmount)}
											/>
										)}
									</Panel>
								</div>
							</div>

							<div className="row">
								<Panel className="w-100">
									<div className="row mb-4">
										<Table
											loading={isLoading || isRefetching}
											columns={columns}
											className="w-100"
											dataSource={products || []}
											pagination={false}
											rowKey={(record) => record.id}
										/>
									</div>

									<div className="row">
										{demandState?.amoAmount && (
											<div className="col-md-3 mb-2">
												<CoverageDemandAmount
													title={"Sécurité sociale"}
													price={demandState.amoAmount}
												/>
											</div>
										)}
										{demandState?.amcAmount && (
											<div className="col-md-3 mb-2">
												<CoverageDemandAmount
													title={"Mutuelle"}
													price={demandState.amcAmount}
												/>
											</div>
										)}

										{paymentTypesRef &&
											payments?.["hydra:member"].map((el) => {
												if (!el.type || !(el.type in paymentTypesRef)) {
													return <Fragment key={el.id}></Fragment>
												}

												return (
													<div className="col-md-3 mb-2" key={el.id}>
														<CoverageDemandAmount
															// @ts-ignore (typescript doesn't detect the condition "in" in that case)
															title={paymentTypesRef[el.type].label}
															price={el.amount as number}
														/>
													</div>
												)
											})}
									</div>
								</Panel>
							</div>
						</div>
					</ModalBody>

					<ModalFooter className={"footer-buttons"}>
						<div className="left-buttons">
							{/*<ButtonRounded color={"secondary"} onClick={() => {}}>*/}
							{/*	Détails moyens de paiements*/}
							{/*</ButtonRounded>*/}
							{availableActions?.filter((el) => el.side === "left").map(RenderAction)}
						</div>

						<div className="right-buttons">
							{[
								CoverageDemandState.DECLINED,
								CoverageDemandState.WAITING_INSERT,
								CoverageDemandState.WAITING_TRANSFORM,
								CoverageDemandState.VALIDATED,
								CoverageDemandState.CANCELED,
								CoverageDemandState.INSERTED,
							].includes(item.state) && (
								<ButtonRounded
									color={"primary-outlined"}
									isDisabled={!item || item.documents.length === 0}
									onClick={() => {
										setModalDocumentId(item?.documents[0].id)
										setModalOpen(true)
									}}>
									Voir le devis
								</ButtonRounded>
							)}

							{availableActions?.filter((el) => !el.side || el.side === "right").map(RenderAction)}
						</div>
					</ModalFooter>
				</>
			)}

			{modalDoc && (
				<DocumentViewer
					onClose={() => {
						setModalDocumentId(undefined)
						setModalOpen(false)
					}}
					isOpen={modalOpen}
					document={modalDoc}
					refreshDocuments={refetchModalDoc}
				/>
			)}
		</Modal>
	)
}

const columns: ColumnsType<{ id: string; name: string; discount: number; total: number }> = [
	{
		title: "Produit",
		dataIndex: "name",
		width: "70%",
	},
	{
		title: "Total remise",
		dataIndex: "discount",
		render: formatCurrency,
	},
	{
		title: "Total TTC",
		dataIndex: "total",
		render: formatCurrency,
	},
]
