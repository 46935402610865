import API_Hiboutik from "./API_Hiboutik"
import API from "./API"
import calcul from "../components/facture/ServiceFacturation"
import { parsed } from "./functions"
import dayjs from "dayjs"

const ALLOWED_PATIENT_KEYS = [
	"id",
	"lastName",
	"firstName",
	"email",
	"adress",
	"cpo",
	"complement",
	"city",
	"birthDate",
	"patientInsurances",
	"patientSocialSecurity",
	"patientMedicals",
	"advices",
	"idSecu",
	"idFsv",
]

const filterObjectKeys = (input) => {
	return Object.keys(input)
		.filter((key) => ALLOWED_PATIENT_KEYS.includes(key))
		.reduce((object, key) => ({ ...object, [key]: input[key] }), {})
}

const filterUnecessaryLineItemData = (lineItems) => {
	return lineItems.map((item) => {
		if (item?.stock_available) delete item.stock_available
		return item
	})
}

export const createJson = (sale, patient, user, laboratory, subCompany) => {
	patient = filterObjectKeys(patient)

	const prescriberName = patient?.advices?.[0]?.prescriber
		? patient?.advices?.[0]?.prescriber?.firstName + " " + patient?.advices?.[0]?.prescriber?.lastName
		: patient?.mainOrl
		? patient?.mainOrl?.firstName + " " + patient?.mainOrl?.lastName
		: patient?.mainDoctor
		? patient?.mainDoctor?.firstName + " " + patient?.mainDoctor?.lastName
		: ""

	const jsonConfig = {
		laboratoire: {
			legalLabel: laboratory?.legalLabel ?? patient.laboratory?.legalLabel,
			legalAdress: laboratory?.legalAdress ?? patient.laboratory?.legalAdress,
			legalCpo: laboratory?.legalCpo ?? patient.laboratory?.legalCpo,
			legalCity: laboratory?.legalCity ?? patient.laboraty?.legalCity,
			label: laboratory?.label ?? patient.laboratory?.label ?? "",
			adresse: laboratory?.adress ?? patient.laboratory?.adress ?? "",
			ville: laboratory?.city ?? patient.laboratory?.city ?? "",
			code_postal: laboratory?.cpo ?? patient.laboratory?.cpo ?? "",
			email: laboratory?.email ?? patient.laboratory?.email ?? "",
			telephone: laboratory?.phone ?? patient.laboratory?.phone ?? "",
			finess: laboratory?.finess ?? patient.laboratory?.finess ?? "",
			tva: laboratory?.tva ?? patient.laboratory?.tva ?? "",
			rcs: laboratory?.rcs ?? patient.laboratory?.rcs ?? "",
			siret: laboratory?.siret ?? patient.laboratory?.siret ?? "",
			id: laboratory?.id,
			mediator: laboratory?.subCompany?.mediator ?? "",
		},
		subCompany: {
			id: subCompany?.id,
			logo: subCompany?.logo,
		},
		company: {
			label: user?.company?.label ?? "",
			rcs: user?.company?.rcs ?? "",
			siret: user?.company?.siret ?? "",
			siren: user?.company?.siren ?? "",
			tva: user?.company?.tva ?? "",
			capitalStock: user?.company?.capitalStock ?? "",
			id: user?.company?.id,
		},
		prescriber: patient?.advices?.[0]?.prescriber || patient?.mainOrl || patient?.mainDoctor,
		prescriberName,
		patient: {
			id: patient?.id ?? "",
			slug: patient?.["@id"] ?? null,
			customer_id: sale?.customer_id ?? "",
			nom: patient?.lastName ?? "",
			prenom: patient?.firstName ?? "",
			email: patient?.email ?? "",
			adresse: patient?.adress ?? "",
			code_postal: patient?.cpo ?? "",
			ville: patient?.city ?? "",
			complement: patient.complement ?? "",
			secu: patient?.idSecu ?? "",
			mainOrl: patient?.mainOrl ?? "",
			mainDoctor: patient?.mainDoctor ?? "",
			full: patient,
		},
		ventes: {
			complete_date_jour: sale?.completed_at_date_dd ?? "",
			complete_date_mois: sale?.completed_at_date_mm ?? "",
			complete_date_annee: sale?.completed_at_date_yyyy ?? "",
			complete_date: sale?.completed_at || dayjs().format("YYYY-MM-DD"),
			achats: filterUnecessaryLineItemData(sale?.line_items ?? []),
			paiement: sale?.payment ?? "",
			total_net: parsed(sale?.sale_total_net) ?? 0,
			total_brut: parsed(sale?.sale_total_gross) ?? 0,
			taxes: sale?.taxes ?? [],
			payment_details: sale?.payment_details ?? [],
			sale_id: sale?.sale_id ?? null,
			comments: sale?.comments || "",
			unique_sale_id: sale?.unique_sale_id,
		},
		amount: {},
	}
	const { totalAmountHT, totalAmountTTC, arrayAmount, partMut, restCharge, partSecu, totalAmountTVA } =
		calcul(jsonConfig)
	Object.assign(jsonConfig.amount, {
		totalAmountHT,
		totalAmountTTC,
		arrayAmount,
		partMut,
		restCharge,
		partSecu,
		totalAmountTVA,
	})

	return jsonConfig
}

export const saveDatabase = async (json) => {
	await API.createDocument({
		filename: "facture_vente",
		label: `Facture vente client : ${json?.ventes?.sale_id}`,
		type: "FACTURE",
		status: "VENDU",
		numero: json?.ventes?.sale_id,
		config: json,
		patient: json?.patient?.slug,
		laboratory: json?.laboratoire?.id ? "/laboratories/" + json.laboratoire.id : null,
	})
}

export const getCustomer = async (salesMonth, alreadySaved, user, laboratory, setGenOldInvoices) => {
	for (const sales of salesMonth) {
		if (sales.length) {
			for (const sale of sales) {
				if (!alreadySaved.includes(sale?.sale_id)) {
					let theSale = null
					let theCustomer = null
					let thePatient = []
					try {
						theSale = await API_Hiboutik.getSale(sale?.sale_id)
					} catch (e) {
						console.error(e)
					}
					try {
						if (theSale) theCustomer = await API_Hiboutik.getCustomer(theSale?.customer_id)
					} catch (e) {
						console.error(e)
					}
					try {
						if (theCustomer)
							thePatient = await API.findFiltered(
								"PATIENTS_API",
								"hiboutikCustomerId",
								theCustomer[0]?.customers_id
							)
					} catch (e) {
						console.error(e)
					}
					const jsonConfig = createJson(theSale, thePatient[0], user, laboratory)
					await saveDatabase(jsonConfig)

					setGenOldInvoices((old) => ({ ...old, current: old.current + 1 }))
				}
			}
		}
	}
}

export const getAllInvoices = async (user, laboratory, setGenOldInvoices) => {
	const year = 2020
	const months = [8, 9, 10, 11]
	let salesMonth = []
	let alreadySaved = []
	let totalSales = 0

	for (const month of months) {
		const response = await API_Hiboutik.getAllSales(year, month)
		salesMonth.push(response)
		totalSales += response.length
	}
	const docs = await API.findCustom("DOCUMENTS_API", "type=FACTURE&pagination=false")
	for (const doc of docs) {
		if (doc?.numero) alreadySaved.push(doc?.numero)
	}
	setGenOldInvoices({
		total: Math.abs(alreadySaved.length - totalSales),
		current: 0,
	})
	await getCustomer(salesMonth, alreadySaved, user, laboratory, setGenOldInvoices)
	setGenOldInvoices(null)
}

export const revertConfigToDatabaseCorrectKeys = (jsonConfig) => {
	const laboratory = {
		legalLabel: jsonConfig?.laboratoire?.legalLabel ?? "",
		legalAdress: jsonConfig?.laboratoire?.legalAdress ?? "",
		legalCpo: jsonConfig?.laboratoire?.legalCpo ?? "",
		legalCity: jsonConfig?.laboratoire?.legalCity ?? "",
		label: jsonConfig?.laboratoire?.label ?? "",
		adress: jsonConfig?.laboratoire?.adresse ?? "",
		city: jsonConfig?.laboratoire?.ville ?? "",
		cpo: jsonConfig?.laboratoire?.code_postal ?? "",
		email: jsonConfig?.laboratoire?.email ?? "",
		phone: jsonConfig?.laboratoire?.telephone ?? "",
		finess: jsonConfig?.laboratoire?.finess ?? "",
		tva: jsonConfig?.laboratoire?.tva ?? "",
		rcs: jsonConfig?.laboratoire?.rcs ?? "",
		siret: jsonConfig?.laboratoire?.siret ?? "",
		mediator: jsonConfig?.laboratoire?.mediator ?? "",
	}
	const patient = jsonConfig?.patient?.full
	const company = jsonConfig?.company
	return { laboratory, patient, company }
}
