import AuthContext from "contexts/AuthContext"
import { useContext } from "react"

const Filters = ({ filters, setFilters }: { filters: Record<string, any>; setFilters: any }): JSX.Element => {
	const { user } = useContext(AuthContext)

	return (
		<>
			<div className="row align-items-end">
				<div className="col">
					<label htmlFor="cselect-view-filter">Afficher uniquement les produits</label>
					<select
						id="select-view-filter"
						className="form-control form-control-sm"
						defaultValue={"onlyDeposit"}
						onChange={(event) => {
							const value = event.target.value
							const data = {
								onlyMissed: false,
								onlyDeposit: false,
							}

							if (value === "onlyMissed") {
								data.onlyMissed = true
							} else if (value === "onlyDeposit") {
								data.onlyDeposit = true
							}
							setFilters((old: any) => ({
								...old,
								...data,
							}))
						}}>
						<option value="onlyDeposit">Voir tous les appareils en dépôts</option>
						<option value="onlyMissed">Voir les dépôts dépassé uniquement</option>
						<option value="noDeposit">Voir les appareils en ferme</option>
					</select>
				</div>
				<div className="col">
					<label htmlFor="cselect-view-filter">Afficher uniquement les produits disponibles</label>
					<select
						id="select-view-filter"
						className="form-control form-control-sm"
						value={filters.onlyAvailable ? "yes" : "no"}
						onChange={(event) => {
							const value = event.target.value

							setFilters((old: any) => ({
								...old,
								onlyAvailable: value === "yes" ? true : false,
							}))
						}}>
						<option value="yes">Oui</option>
						<option value="no">Non</option>
					</select>
				</div>

				<div className="col">
					<label>Par numéro de série</label>
					<input
						onChange={(evt) => {
							const value = evt.target.value

							setFilters((old: any) => ({ ...old, serialNumber: value }))
						}}
						placeholder="SN098765"
						value={filters.serialNumber ?? ""}
						className="form-control form-control-sm"
					/>
				</div>

				<div className="col">
					<label>Par laboratoire</label>

					<select
						onChange={(evt) => {
							const value = evt.target.value
							setFilters((old: any) => ({ ...old, warehouseId: value }))
						}}
						value={filters.warehouseId}
						className="form-control form-control-sm">
						{(user.laboratories ?? [])
							.filter((f) => f.warehouseIdHiboutik)
							.map((v, k) => (
								<option value={v.warehouseIdHiboutik} key={k}>
									{v.label}
								</option>
							))}
					</select>
				</div>
			</div>
		</>
	)
}

export default Filters
