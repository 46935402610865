import { Select } from "antd"
import { useCategoriesQuery } from "components/Hooks/commonQueries"
import { useMemo } from "react"
import { partialSearch } from "services/functions"

type AsyncCategorySelectProps = {
	disabled?: boolean
	searchValue?: string
	onSelect: (arg: number) => void
	onClear: () => void
	onDeselect?: () => void
}

export default function AsyncHiboutikCategorySelect({
	disabled,
	searchValue,
	onSelect,
	onClear,
	onDeselect,
}: AsyncCategorySelectProps): JSX.Element {
	const { data: categories, isFetching } = useCategoriesQuery()

	const options = useMemo(() => {
		if (isFetching || !categories) return []

		return categories.map((cat) => ({
			label: cat.category_name,
			value: cat.category_id,
		}))
	}, [categories])

	return (
		<Select
			placeholder="Sélectionner une catégorie"
			options={options}
			style={{ width: "100%" }}
			disabled={disabled}
			allowClear={true}
			onSelect={onSelect}
			onClear={onClear}
			onDeselect={onDeselect}
			searchValue={searchValue}
			showSearch
			filterOption={(input, option) => {
				if (input.length === 0) return false
				return partialSearch(option!.label as string, input)
			}}
		/>
	)
}
