import { PDFFont } from "pdf-lib"

// Filter out characters that are not supported by the font
export const filterCharSet = (s: string, font: PDFFont): string => {
	const charSet = font.getCharacterSet()

	for (let i = 0; i < s.length; i++) {
		if (s[i] && !charSet.includes(s.charCodeAt(i))) s = replaceChar(s, "?", i)
	}

	s = s.replace(/[\uE000-\uF8FF]/g, "?")
	s = s.replace(/[^\w\s!?{}()\-;:"'*@#$%&+=]/g, "?")
	return s
}

// Get the width of a string at a given font size
export const getSafeWidthOfTextAtSize = (s: string, font: PDFFont, size: number): number => {
	return font.widthOfTextAtSize(filterCharSet(s, font), size)
}

const replaceChar = (s: string, char: string, index: number): string => {
	return s.substring(0, index) + char + s.substring(index + 1)
}

/**
 * Emulation of the PHP spaceship operator <=>
 * Useful, for example, for sorting an array of strings case
 */
export const spaceship = (a: string, b: string): number => {
	if (a < b) {
		return -1
	}
	if (a > b) {
		return 1
	}
	return 0
}
