export const PaiementsRoutePrefix = "/paiements"

export const PaiementsRoutes = {
	Reçus: `${PaiementsRoutePrefix}/reçu`,
	EnAttente: `${PaiementsRoutePrefix}/attente`,
	Cheques: `${PaiementsRoutePrefix}/cheques`,
	CarteBancaire: `${PaiementsRoutePrefix}/carte-bancaire`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(PaiementsRoutePrefix, "")
}
