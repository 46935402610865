/* eslint-disable @typescript-eslint/no-use-before-define */
import {
	ExportOutlined,
	FilterOutlined,
	SearchOutlined,
	SettingFilled,
	SettingOutlined,
	StopOutlined,
	UserAddOutlined,
} from "@ant-design/icons"
import { Button, Input } from "antd"
import useHasRole from "components/Hooks/useHasRole"
import useSavedState from "components/Hooks/useSavedState"
import dayjs from "dayjs"
import _ from "lodash"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { getScheduleLabel, haveRole } from "services/functions"
import AuthContext from "../../contexts/AuthContext"
import { garanties } from "../../pages/FSV/garantiesFSV"
import API from "../../services/API"
import { ExportSpecialSPFilter } from "./Utils"

export function TableFilters({
	scheduleTypes = [],
	scheduleRelanceList = [],
	patientsCount,
	quickFilter,
	queryfilters,
	resetQueryFiltersAndSort,
	setCurrentRecord,
	setTableData,
	setIsAddingLead,
	edit,
	tableData,
	isAddingLead,
	setName,
	tableSettingsVisible,
	setTableSettingsVisible,
	originsInitial,
}) {
	const queryClient = useQueryClient()
	const { user } = useContext(AuthContext)
	const [exportData, setExportData] = useState([])
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [filename, setFilename] = useState("")
	const csvLink = useRef()

	let nameTimeout = null

	const isManager = useHasRole("ROLE_MANAGER")
	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")
	const isFranchisedManager = useHasRole("ROLE_MANAGER_FRANCHISED")
	//pas touche l'ordre CF pour sylvain
	const originHeader = [
		{ label: "Centre", key: "laboratory" },
		{ label: "Id", key: "patientId" },
		{ label: "Utilisateur", key: "user" },
		{ label: "Audioprothesite", key: "audioprosthetist" },
		{ label: "Genre", key: "gender" },
		{ label: "Nom", key: "lastName" },
		{ label: "Prenom", key: "firstName" },
		{ label: "Date SP", key: "nextScheduleDateOf" },
		{ label: "Statut Principal", key: "nextScheduleStatus" },
		{ label: "Statut secondaire", key: "patientTags" },
		{ label: "notes", key: "patientNotes" },
		{ label: "Source", key: "patientSource" },
		{ label: "Origine", key: "origin" },
		{ label: "Detail origine", key: "patientOriginDetail" },
		{ label: "Appareillable", key: "isDeviceable" },
		{ label: "CL", key: "class" },
		{ label: "Pack", key: "packName" },
		{ label: "Ref App Droit", key: "hearingAidProductModelRight" },
		{ label: "Ref App Gauche", key: "hearingAidProductModelLeft" },
		{ label: "Marque Appareil Droit", key: "hearingAidBrandNameRight" },
		{ label: "Marque Appareil Gauche", key: "hearingAidBrandNameLeft" },
		{ label: "Accessoire 1", key: "accessoryProductModelOne" },
		{ label: "Accessoire 2", key: "accessoryProductModelTwo" },
		{ label: "Accessoire 3", key: "accessoryProductModelThree" },
		{ label: "Accessoire 4", key: "accessoryProductModelFour" },
		{ label: "Accessoire 5", key: "accessoryProductModelFive" },
		{ label: "Accessoire 6", key: "accessoryProductModelSix" },
		{ label: "Accessoire 7", key: "accessoryProductModelSeven" },
		{ label: "Accessoire 8", key: "accessoryProductModelEight" },
		{ label: "Assurance", key: "insuranceNames" },
		{ label: "PR TTC", key: "quotationTotalAmountInclTax" },
		{ label: "PR HT", key: "quotationTotalAmountExclTax" },
		{ label: "Date previsionnel (adaptation + 20 jours)", key: "expectedDate" },
		{ label: "Facture", key: "lastInvoiceNumber" },
		{ label: "Date facture", key: "lastInvoiceDate" },
		{ label: "HT", key: "lastInvoiceTotalAmountExclTax" },
		{ label: "TTC", key: "lastInvoiceTotalAmountInclTax" },
		{ label: "Med prescipteur", key: "prescriberDoctorCategory" },
		{ label: "Nom médecin", key: "prescriberDoctorLastname" },
		{ label: "Prénom médecin", key: "prescriberDoctorFirstname" },
		{ label: "PEC Secu", key: "pecSecu" },
		{ label: "Réseau Mutuelle", key: "mutualNetwork" },
		{ label: "PEC 1", key: "socialSecurityRefund" },
		{ label: "PEC 2", key: "mutualRefund" },
		{ label: "RAC", key: "outOfPocket" },
		{ label: "DATE DE NAISSANCE", key: "birthday" },
		{ label: "Age", key: "age" },
		{ label: "Profession", key: "profession" },
		{ label: "Tel", key: "phoneNumber" },
		{ label: "Mail", key: "email" },
		{ label: "Adresse", key: "address" },
		{ label: "Ville", key: "city" },
		{ label: "CP", key: "zipCode" },
		{ label: "Pays", key: "country" },
		{ label: "ORL", key: "mainOrl" },
		{ label: "Prescripteur", key: "lastPrescriber" },
		{ label: "Décédé", key: "isDead" },
	]

	const newPatient = {
		key: "0",
		"patient.firstName": "",
		"patient.id": "1234",
		"patient.lastName": "",
		"patient.nextScheduleStatus": "PREMIERE_VISITE",
		"patient.DStatus": "",
		"patient.notes": [],
		"patient.phone": "",
		"patient.source": "",
		"schedule.nextDateOf": "",
		"patient.gender": "",
		"user.id": "",
		"user.firstName": "",
		"user.lastName": "",
		"patient.scheduleDate": "",
		"patient.selectedPatientInsurances": "",
		"patient.scheduleTime": { start: "", end: "" },
		"patient.fileCompleted": { status: false, data: [] },
		"patient.patientInsurances": {
			amcId: "0",
			label: "Non-renseigné",
			isTP: true,
			isC2S: false,
			isMutnum: false,
			isRNM: false,
			startDate: null,
			endDate: null,
			coverDate: null,
			coverDateInquiry: null,
			classOneCover: null,
			classTwoCover: null,
			patient: "/patients/3",
			userInsurance: "/user_insurances/1",
		},
	}

	const [exportHeader, setExportHeader] = useState(originHeader)
	const [selectedFilter, setSelectedFilter] = useSavedState("dashboardQuickSelectedFilter", -1)
	const [exporting, setExporting] = useState(false)

	const filterButtonData = useMemo(() => {
		const [relanceScheduleTypes, notRelanceScheduleTypes] = _.partition(
			Object.entries(scheduleTypes),
			([, scheduleType]) => scheduleType.isRelance
		)

		return [
			{
				name: "RDV",
				filter: {
					label: "nextScheduleStatus",
					value: notRelanceScheduleTypes.map((x) => x[0]),
				},
			},
			{
				name: "À relancer",
				filter: {
					label: "nextScheduleStatus",
					value: relanceScheduleTypes.map((x) => x[0]),
				},
			},
			{
				name: "Incomplet",
				filter: { label: "fileCompleted", value: false },
			},
		]
	}, [scheduleRelanceList, scheduleTypes])

	const showSearch = () => {
		setIsSearchVisible(!isSearchVisible)
	}

	let exportTimeout = null

	const handleSearchNameChange = useCallback(
		(e) => {
			if (nameTimeout) {
				clearTimeout(nameTimeout)
				nameTimeout = null
			}
			e.persist()
			nameTimeout = setTimeout(() => {
				setName(e.target.value)
			}, 500)
		},
		[nameTimeout]
	)

	const selectedButton = (index, filter) => {
		setSelectedFilter(selectedFilter === index ? -1 : index)
		if (queryfilters != null && queryfilters?.laboratory != null && queryfilters.laboratory.length > 0)
			queryClient.invalidateQueries("PATIENTS_API")
		quickFilter(filter, selectedFilter === index || index === -1)
	}
	const getExportData = async () => {
		setExporting(true)
		const res = await handleExport()
		if (res.data == null) {
			toast.error("Erreur lors de l'export des données")
			return
		}
		setExportData(res.data)
		const tmpHeader = originHeader
		for (let i = 1; i <= res.sp_count; i++) {
			tmpHeader.push({ label: "SP N°" + i, key: "SP N°" + i })
			tmpHeader.push({ label: "DATE SP N°" + i, key: "DATE SP N°" + i })
			tmpHeader.push({ label: "STATUS SP N°" + i, key: "STATUS SP N°" + i })
		}
		let invoicesList = []
		for (let i = 1; i <= res.maxHearingAidsInvoicesNumber; i++) {
			invoicesList.push({ label: "Numéro de facture AA N°" + i, key: "Numéro de facture AA N°" + i })
			invoicesList.push({ label: "Date AA N°" + i, key: "Date AA N°" + i })
			invoicesList.push({ label: "HT AA N°" + i, key: "HT AA N°" + i })
		}
		for (let i = 1; i <= res.maxOthersInvoicesNumber; i++) {
			invoicesList.push({ label: "Numéro de facture Autre N°" + i, key: "Numéro de facture Autre N°" + i })
			invoicesList.push({ label: "Date Autre N°" + i, key: "Date Autre N°" + i })
			invoicesList.push({ label: "HT Autre N°" + i, key: "HT Autre N°" + i })
		}
		tmpHeader.splice.apply(
			tmpHeader,
			[tmpHeader.findIndex((item) => item.key === "lastInvoiceTotalAmountInclTax") + 1, 0].concat(invoicesList)
		)
		setExportHeader(tmpHeader)
	}

	const handleExport = async () => {
		try {
			// Fetch every page
			let page = 1
			let totalItems = Infinity
			const rows = []

			while (rows.length < totalItems) {
				const response = await API.findAll("PATIENTS_EXPORT_API", `?page=${page}&maxItems=100`, true)
				page++
				totalItems = response["hydra:totalItems"]
				rows.push(...response["hydra:member"])
			}

			let max = 0
			setFilename("Tableau_de_bord_" + dayjs().format("YYYY_MM_DD_HH_mm") + ".csv")

			for (let i = 0; i < rows.length; i++) {
				const item = rows[i]
				if (item.SP) {
					let essaiNB = 0

					for (let j = 0; j < item.SP.length; j++) {
						const data = item.SP[j]

						let label = getScheduleLabel(scheduleTypes, data)
						if (data === "ESSAI") label += " N°" + ++essaiNB

						rows[i]["SP N°" + (j + 1)] = label
						rows[i]["DATE SP N°" + (j + 1)] = item.DateSP[j]
						rows[i]["STATUS SP N°" + (j + 1)] = item.stateSP[j]
						if (j > max) max = j
					}
				}

				if (item.invoicesList?.InvoicesAAList) {
					const reversed = item.invoicesList?.InvoicesAAList.reverse()
					for (let j = 0; j < reversed.length; j++) {
						const data = reversed[j]

						rows[i]["Numéro de facture AA N°" + (j + 1)] = data.invoiceNumber
						rows[i]["Date AA N°" + (j + 1)] = data.createdAt
						rows[i]["HT AA N°" + (j + 1)] = data.totalAmountExclTax
					}
				}
				if (item?.invoicesList?.InvoicesOtherList) {
					const reversed = item.invoicesList?.InvoicesOtherList.reverse()
					for (let j = 0; j < reversed.length; j++) {
						const data = reversed[j]

						rows[i]["Numéro de facture Autre N°" + (j + 1)] = data.invoiceNumber
						rows[i]["Date Autre N°" + (j + 1)] = data.createdAt
						rows[i]["HT Autre N°" + (j + 1)] = data.totalAmountExclTax
					}
				}

				rows[i]["isDead"] = item.isDead ? "Oui" : "Non"

				rows[i]["pecSecu"] = 400 * (garanties.find((f) => f.value === rows[i]["situationCode"])?.percent / 100)

				let tmp = item.SP.map((data) => ({ status: data })).reverse()
				tmp = item.DateSP.reverse().map((data, i) => {
					tmp[i]["dateOf"] = data
					return tmp[i]
				})

				rows[i]["patientNotes"] = (
					rows[i]["patientNotes"] +
					rows[i]["preScheduleNotes"] +
					rows[i]["scheduleNotes"]
				).replace(/["';]+/g, "")

				const saveSP = ExportSpecialSPFilter(
					rows[i]["nextScheduleStatus"],
					tmp,
					1,
					scheduleTypes,
					scheduleRelanceList
				)
				if (saveSP.dateOf != null) rows[i]["nextScheduleDateOf"] = saveSP.dateOf

				if (saveSP.status == null || saveSP.status === "")
					rows[i]["nextScheduleStatus"] = getScheduleLabel(scheduleTypes, rows[i]["nextScheduleStatus"])
				else rows[i]["nextScheduleStatus"] = getScheduleLabel(scheduleTypes, saveSP.status)
			}

			return {
				data: rows,
				sp_count: max,
				maxHearingAidsInvoicesNumber: 15,
				maxOthersInvoicesNumber: 20,
			}
		} catch (error) {
			console.error(error)
			setExporting(false)
		}
		return { data: null, sp_count: 0, maxHearingAidsInvoicesNumber: 0, maxOthersInvoicesNumber: 0 }
	}

	// When a lead is Added
	const handleAddLead = () => {
		if (isAddingLead) return

		let refUser = { firstName: "", lastName: "", "@id": "" }
		if (haveRole(user, "ROLE_USER") || haveRole(user, "ROLE_MANAGER")) refUser = user
		newPatient["user.firstName"] = refUser.firstName
		newPatient["user.id"] = refUser["@id"]
		newPatient["user.lastName"] = refUser.lastName
		if (isAffiliate || isAffiliateManager) {
			newPatient["patient.source"] = user.affiliation?.code
			newPatient["patient.origin"] = originsInitial?.[0]
		}
		setCurrentRecord(newPatient)
		setTableData([newPatient, ...tableData])
		setIsAddingLead(true)
		edit(newPatient)
	}

	useEffect(() => {
		if (!exporting) return
		if (exportTimeout) {
			clearTimeout(exportTimeout)
			exportTimeout = null
		}
		exportTimeout = setTimeout(() => {
			csvLink.current.link.click()
			setExporting(false)
		}, 1000)
	}, [exportHeader])

	return (
		<>
			<div className="dashboard-patient-filter">
				<div className="dashboard-filter-button-group">
					{!tableSettingsVisible && ( // Hide filters when in settings view
						<>
							{filterButtonData.map((item, index) => (
								<Button
									style={
										selectedFilter === index
											? {
													fontSize: "small",
													borderRadius: "8px",
											  }
											: {
													fontSize: "small",
													borderRadius: "8px",
													borderColor: "#ecf1f3",
													backgroundColor: "#ecf1f3",
											  }
									}
									type={selectedFilter === index ? "primary" : "outline-primary"}
									key={item.name}
									className="dashboard-quick-filter"
									onClick={() => selectedButton(index, item.filter)}>
									<strong>{item.name}</strong>
								</Button>
							))}
							<Button
								style={
									selectedFilter === -1
										? {
												fontSize: "small",
												borderRadius: "8px",
										  }
										: {
												fontSize: "small",
												borderRadius: "8px",
												borderColor: "#ecf1f3",
												backgroundColor: "#ecf1f3",
										  }
								}
								type={selectedFilter === -1 ? "primary" : "outline-primary"}
								className="dashboard-quick-filter"
								onClick={() => selectedButton(-1, null)}>
								<strong>Tout afficher</strong>
							</Button>
						</>
					)}
				</div>

				<p className="dashboard-actions dashboard-actions-text">{patientsCount} Patients</p>

				<Button
					title="Rechercher"
					className="text-primary"
					icon={<SearchOutlined />}
					size="large"
					type="text"
					onClick={showSearch}
					disabled={tableSettingsVisible}
				/>
				{isSearchVisible && !tableSettingsVisible && (
					<div className="dashboard-patient-filter-search">
						<Input placeholder="Nom : John Doe" onChange={handleSearchNameChange} autoFocus />
					</div>
				)}

				<Button
					title="Réinitialiser les filtres"
					className="text-primary"
					type="text"
					style={{ width: "40px", height: "40px" }}
					icon={
						<>
							<StopOutlined
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									zIndex: 1,
								}}
							/>
							<FilterOutlined
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									fontSize: "12px",
									margin: "0",
									zIndex: 0,
								}}
							/>
						</>
					}
					onClick={() => {
						selectedButton(-1, null) // reset quick filter
						setIsSearchVisible(false) // hide and reset search input
						setName("") // reset search value
						resetQueryFiltersAndSort()
					}}
				/>

				<Button
					title="Ajouter un patient"
					className="text-primary"
					icon={<UserAddOutlined />}
					size="large"
					type="text"
					onClick={handleAddLead}
					disabled={tableSettingsVisible}
				/>

				{isManager && (
					<Button
						title={
							tableSettingsVisible
								? "Fermer les paramètres du tableau de bord"
								: "Afficher les paramètres du tableau de bord"
						}
						className="text-primary"
						icon={tableSettingsVisible ? <SettingFilled /> : <SettingOutlined />}
						size="large"
						type="text"
						onClick={() => setTableSettingsVisible(!tableSettingsVisible)}
					/>
				)}

				{(isManager || isFranchisedManager) && user.userSetting.exportDashboardEnabled && (
					<Button
						title="Exporter les données patients"
						className="text-primary"
						icon={<ExportOutlined />}
						size="large"
						type="text"
						loading={exporting}
						onClick={getExportData}
					/>
				)}

				<CSVLink
					className="dashboard-export"
					data={exportData}
					asyncOnClick={true}
					headers={exportHeader}
					separator={";"}
					ref={csvLink}
					filename={filename}
				/>
			</div>
		</>
	)
}
