import { Editor as TinyMCEEditor } from "tinymce"
import { EditorProps } from "./Editor"
import { AdditionalToolbarButtons, ContextMenu } from "./Editor.types"
import { ToolbarButtonType } from "./Editor.enums"

/**
 * Get default plugins
 * @param {Array<string>} additionalPlugins
 * @param {Array<string>} ignoredPlugins
 * @return {string}
 */
export function getAllowedPlugins(additionalPlugins: string[] = [], ignoredPlugins: string[] = []): string {
	const plugins = [
		"advlist",
		"autolink",
		"lists",
		"link",
		"image",
		"charmap",
		"print",
		"preview",
		"anchor",
		"searchreplace",
		"visualblocks",
		"code",
		"fullscreen",
		"insertdatetime",
		"media",
		"table",
		"paste",
		"code",
		"help",
		"wordcount",
		"pagebreak",
		...additionalPlugins,
	]

	if (ignoredPlugins.length) plugins.filter((plugin) => !ignoredPlugins.includes(plugin))

	return plugins.join(" ")
}

/**
 * Build additional toolbar menu buttons
 * @param additionalButtons
 * @param {boolean} savingButton - add saving button id
 */
export function getAdditionalToolbarMenuButtonsIds(
	additionalButtons: AdditionalToolbarButtons,
	savingButton: any = false
): string[] {
	// todo
	const menuButtons = typeof additionalButtons === "function" ? additionalButtons() : additionalButtons // function is used to pass the editor at top level
	const buttons = menuButtons.map((additionalButton) => additionalButton.id)

	if (savingButton) buttons.push("save")

	return buttons
}

/**
 * Get default toolbar menu buttons
 * @param additionalButtons
 * @param ignoredButtons
 * @return {string}
 */
export function getAllowedToolbarMenuButtonsIds(
	additionalButtons: string[] = [],
	ignoredButtons: string[] = []
): string {
	const buttons = [
		"undo redo",
		"formatselect",
		"bold italic backcolor",
		"alignleft aligncenter alignright alignjustify",
		"bullist numlist outdent indent",
		"removeformat",
		"help",
		...additionalButtons,
	]

	if (ignoredButtons.length) buttons.filter((button) => !ignoredButtons.includes(button))

	return buttons.join(" | ") // pipe is used to group buttons
}

/**
 * Return a string that cointains the ids of allowed context menus
 * @param contextMenus
 */
export function getAllowedContextMenus(contextMenus: ContextMenu = []): string {
	return contextMenus!
		.map((contextMenu: any) => (typeof contextMenu === "string" ? contextMenu : contextMenu?.id))
		.join(" ")
}

/**
 * Build context menus
 * @param editor
 * @param contextMenus
 */
export function registerContextMenus(
	editor: TinyMCEEditor,
	contextMenus: Array<{ id: string; spec: any } | string>
): void {
	const { registry } = editor?.ui

	for (const contextMenu of contextMenus) {
		if (typeof contextMenu === "string") continue
		registry.addContextMenu(contextMenu.id, contextMenu.spec)
	}
}

/**
 * Build menu buttons
 * @param editor
 * @param buttons
 */
export function registerMenuButtons(editor: TinyMCEEditor, buttons: AdditionalToolbarButtons): void {
	if (!buttons.length) return

	const { registry } = editor?.ui

	const menuButtons = typeof buttons === "function" ? buttons(editor) : buttons // function is used to pass the editor at top level

	for (const menuButton of menuButtons) {
		// @ts-ignore
		const method: string = {
			[ToolbarButtonType.Basic]: "addButton",
			[ToolbarButtonType.Toogle]: "addToggleButton",
			[ToolbarButtonType.Menu]: "addMenuButton",
			[ToolbarButtonType.Split]: "addSplitButton",
		}[(menuButton.type as string) || ToolbarButtonType.Basic]
		// @ts-ignore
		registry[method](menuButton.id, menuButton.spec)
	}
}

/**
 * Register saving button
 * @param editor
 * @param savingCallback
 */
export function registerSavingButton(editor: TinyMCEEditor, savingCallback: EditorProps["onSave"]): void {
	if (!savingCallback) return

	editor.ui.registry.addButton("saving-button", {
		text: "Enregistrer dans mes documents", // TODO: intl
		onAction: function () {
			const content: string = editor.getContent()
			savingCallback(content)
		},
	})
}

/**
 * Setup the editor at the initialization
 * TODO: manage item and context w/o id
 * @param editor
 * @param contextMenus
 * @param menuButtons
 * @param savingCallback
 */
export function setupEditor(
	editor: TinyMCEEditor,
	contextMenus: ContextMenu = [],
	menuButtons: AdditionalToolbarButtons,
	savingCallback?: EditorProps["onSave"]
): void {
	const menus = []

	for (const contextMenu of contextMenus) {
		if (typeof contextMenu === "string") continue

		menus.push({
			id: contextMenu.id,
			spec: {
				update: function () {
					return contextMenu.items
				},
			},
		})
	}

	editor.on("LoadContent", function (e) {
		editor.settings.loaded = true
	})

	registerContextMenus(editor, menus)
	registerMenuButtons(editor, menuButtons)
	registerSavingButton(editor, savingCallback)
}
