import { Modal, ModalBody, ModalHeader } from "reactstrap"
import ChartBar from "./ChartBar"

type OriginesAllModalProps = {
	isOpen: boolean
	setIsOpen: (arg0: boolean) => void
	origins: any
}

export default function OriginesAllModal({ isOpen, setIsOpen, origins }: OriginesAllModalProps): JSX.Element {
	return (
		<Modal isOpen={isOpen} size="lg">
			<ModalHeader toggle={() => setIsOpen(false)}>Classement des origines</ModalHeader>
			<ModalBody>{origins && <ChartBar series={[{ name: "Origines", data: origins }]} />}</ModalBody>
		</Modal>
	)
}
