export const VenteRoutePrefix = "/ventes"

export const VenteRoutes = {
	VenteAttente: `${VenteRoutePrefix}/attentes`,
	VenteTerminee: `${VenteRoutePrefix}/terminees`,
	NouvelleVente: `${VenteRoutePrefix}/nouvelle`,
	EditVente: `${VenteRoutePrefix}/:id/edit`,
	Facture: `${VenteRoutePrefix}/facture/:id`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(VenteRoutePrefix, "")
}
