import { Affiliation, Gender, UserRole } from "@audiowizard/common"
import { Select } from "antd"
import { AxiosError } from "axios"
import { useAllLaboratoriesQuery } from "components/Hooks/commonQueries"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap"
import { partialSearch } from "services/functions"
import FieldWithError from "../../../components/forms/FieldWithError"
import API from "../../../services/API"
import UserRegistrationStatusSelect, {
	AudioStatus,
} from "components/forms/UserRegistrationStatusSelect/UserRegistrationStatusSelect"

type NewUserData = {
	gender: Gender
	roles: [UserRole]
	laboratories: string[]
	firstName: string
	lastName: string
	email: string
	registrationStatus?: AudioStatus
	affiliation?: string
}

const defaultValues: NewUserData = {
	gender: "HOMME",
	roles: ["ROLE_USER"],
	laboratories: [],
	firstName: "",
	lastName: "",
	email: "",
	registrationStatus: AudioStatus.Other,
	affiliation: undefined,
}

type NewUserModalProps = {
	open: boolean
	onClose: () => void
	isLoadingAffiliations: boolean
	affiliations: Affiliation[] | undefined
}
function NewUserModal({ open, onClose, affiliations, isLoadingAffiliations }: NewUserModalProps): JSX.Element {
	const queryClient = useQueryClient()

	const [values, setValues] = useState<NewUserData>(defaultValues)
	const [errors, setErrors] = useState<Record<string, string>>({})

	const { data: laboratories, isLoading: isLoadingLaboratories } = useAllLaboratoriesQuery()

	const handleOnClose = (): void => {
		setErrors({})
		onClose()
	}

	const { mutate: createUser, isLoading } = useMutation(() => API.create("USERS_API", values), {
		onSuccess: async () => {
			await queryClient.invalidateQueries("USERS_API")

			// Reset state
			setValues(defaultValues)
			setErrors({})

			onClose()
			toast.success(`Votre invitation à bien été envoyé${values.email ? ` à ${values.email}` : "."}`)
		},
		onError: (err?: AxiosError) => {
			// Check if this is a validation error
			if (err?.response?.status !== 422) {
				toast.error("Erreur lors de l'envoie de l'ajout du nouvel utilisateur.")
				return
			}

			// Parse validation errors
			const violations = err.response.data.violations as {
				propertyPath: string
				message: string
			}[]

			const errorsByField = violations.reduce<Record<string, string>>((obj, { propertyPath, message }) => {
				obj[propertyPath] = message
				return obj
			}, {})
			setErrors(errorsByField)
		},
	})

	return (
		<Modal isOpen={open} toggle={onClose} centered size="lg">
			<form
				onSubmit={(e) => {
					e.preventDefault()
					createUser()
				}}>
				<ModalHeader tag="div">
					<div className="d-flex align-items-center">
						<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
							<i className="fad fa-user" />
						</div>

						<h6 className="mb-0">Ajouter un nouvel utilisateur</h6>

						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
							title="Fermer"
							onClick={handleOnClose}>
							<i className="fas fa-times" />
						</button>
					</div>
				</ModalHeader>

				<ModalBody>
					<Row>
						<Col sm={4}>
							<div className="w-100 form-group">
								<label htmlFor="gender">Genre *</label>
								<Select
									defaultValue="HOMME"
									value={values.gender || "HOMME"}
									className="form-control w-100 removeantd-class antd-add-padding"
									onChange={(gender: Gender) => setValues({ ...values, gender })}>
									<Select.Option value="HOMME">Monsieur</Select.Option>
									<Select.Option value="FEMME">Madame</Select.Option>
								</Select>
							</div>
						</Col>
						<Col sm={4}>
							<FieldWithError
								id="firstName"
								name="firstName"
								label="Prénom *"
								type="text"
								invalidMessage="Le prénom est obligatoire"
								required={true}
								onChange={(e) => setValues({ ...values, firstName: e.target.value })}
								value={values.firstName || ""}
								error={errors.firstName}
							/>
						</Col>
						<Col sm={4}>
							<FieldWithError
								id="lastName"
								name="lastName"
								label="Nom *"
								type="text"
								invalidMessage="Le nom est obligatoire"
								required={true}
								onChange={(e) => setValues({ ...values, lastName: e.target.value })}
								value={values.lastName || ""}
								error={errors.lastName}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<FieldWithError
								id="email"
								name="email"
								type="email"
								label="Addresse email *"
								required={true}
								onChange={(e) => setValues({ ...values, email: e.target.value })}
								value={values.email || ""}
								error={errors.email}
							/>
						</Col>
						<Col sm={6}>
							<div className="w-100 form-group">
								<label htmlFor="gender">Rôle *</label>
								<Select
									className="form-control w-100 removeantd-class antd-add-padding"
									defaultValue="ROLE_USER"
									value={values.roles[0]}
									onChange={(role: UserRole) => setValues({ ...values, roles: [role] })}>
									<Select.Option value="ROLE_MANAGER">Manager</Select.Option>
									<Select.Option value="ROLE_MANAGER_FRANCHISED">Manager laboratoire</Select.Option>
									<Select.Option value="ROLE_USER">Audioprothésiste</Select.Option>
									<Select.Option value="ROLE_ASSISTANT">Assistant(e)</Select.Option>
									<Select.Option value="ROLE_AFFILIATE">Affilié</Select.Option>
								</Select>
							</div>
						</Col>
						{values.roles.includes("ROLE_AFFILIATE") && (
							<>
								<Col sm={6}>
									<div className="w-100 form-group">
										<label htmlFor="gender">Affiliation *</label>
										<Select
											className="form-control w-100 removeantd-class antd-add-padding"
											loading={isLoadingAffiliations}
											value={values.affiliation}
											onChange={(affiliation) => {
												setValues({ ...values, affiliation: affiliation })
											}}>
											{affiliations?.map((affiliation) => (
												<Select.Option key={affiliation.id} value={affiliation?.["@id"]}>
													{affiliation.label}
												</Select.Option>
											))}
										</Select>
									</div>
								</Col>
								<Col sm={6}>
									<p className="text-warning mt-4" style={{ fontSize: "0.835rem" }}>
										Le partenaire n'a pas accès à vos patients ni au reste de vos informations. Il
										peut simplement ajouter des patients et des rendez-vous.
									</p>
								</Col>
							</>
						)}
					</Row>

					<Row>
						<Col sm={6}>
							<div className="w-100 form-group">
								<label htmlFor="select-laboratory">Laboratoires associés *</label>
								<Select
									showSearch
									filterOption={(input, option) => partialSearch(option?.label as string, input)}
									id="select-laboratory"
									className="w-100"
									size="large"
									mode="multiple"
									placeholder="Merci de sélectionner les laboratoires associés à cet utilisateur"
									loading={isLoadingLaboratories}
									value={values.laboratories}
									onChange={(laboratories) => setValues({ ...values, laboratories })}
									options={laboratories?.map((lab) => {
										return {
											label: lab?.label,
											value: lab?.["@id"],
										}
									})}
								/>
							</div>
						</Col>
						<Col sm={6}>
							<UserRegistrationStatusSelect
								registrationStatus={values.registrationStatus}
								errorRegistrationStatus={errors.registrationStatus}
								roles={values.roles}
								handleChangeRegistrationStatus={(status: AudioStatus) =>
									setValues((oldValues) => ({ ...oldValues, registrationStatus: status }))
								}
							/>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter>
					<Button color="primary" type="submit" disabled={isLoading}>
						{isLoading && <Spinner size="sm" />} Ajouter
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	)
}

export default NewUserModal
