import dayjs from "dayjs"
import { Link } from "react-router-dom"

const statusLabels = {
	ESSAI: {
		color: "info",
		label: "Essai",
	},
	SAV: {
		color: "warning",
		label: "SAV",
	},
	VENDU: {
		color: "primary",
		label: "Vendu",
	},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		title: "Patient",
		dataIndex: "patient",
		width: "220px",
		render: (d) => (
			<Link to={"/fiche-patient/" + d.id}>
				<button type="button" className="btn btn-block btn-sm btn-secondary pt-2 pb-2 text-left">
					{d.firstName} {d.lastName}
				</button>
			</Link>
		),
	},
	{
		title: "Produit",
		dataIndex: "productJsonHiboutik",
		width: "220px",
		render: (d) => {
			const sizeName = d?.product_size_name ?? d?.customSizeSelected?.size_name

			return (
				<>
					<b>{d?.product_model}</b>
					<br />
					{sizeName ? sizeName : <span style={{ opacity: "0.5" }}>Pas de déclinaison </span>}
				</>
			)
		},
	},
	{
		title: "Date",
		width: "140px",
		dataIndex: "createdAt",
		render: (d, row) => (
			<>
				<div className="row">
					<div className="col-1">
						<i className="fad fa-plus" />
					</div>
					<div className="col">{dayjs(d).format("DD/MM/YYYY")}</div>
				</div>
				{row?.datePurchase && (
					<div className="row">
						<div className="col-1">
							<i className="fad fa-shopping-cart" />
						</div>
						<div className="col">{dayjs(row?.datePurchase).format("DD/MM/YYYY")}</div>
					</div>
				)}
			</>
		),
	},
	{
		title: "Numéro de série",
		dataIndex: "serialNumber",
		render: (d) => {
			if (!d) return <span style={{ opacity: "0.5" }}>Pas de numéro de série</span>

			return <span style={{ fontFamily: "Monospace" }}>{d}</span>
		},
	},
	{
		width: "140px",
		title: "État",
		dataIndex: "status",
		align: "center",
		render: (d) => (
			<span className={"badge  badge-" + (statusLabels?.[d]?.color || "secondary")}>
				{statusLabels?.[d]?.label || d}
			</span>
		),
		sorter: (a, b) => a.status.localeCompare(b.status),
		defaultSortOrder: "ascend",
		onFilter: (value, record) => record.status.indexOf(value) === 0,
	},
]
