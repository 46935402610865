import moment from "moment"
import { MergeTag, MergeTags } from "../UnlayerEditor.helper"
import dayjs from "dayjs"
import { TypeTemplates } from "pages/templates/TemplateForm/custom/listDefaultOptions"
import { formatPhoneNumber, StringUpperCFirst } from "services/functions"
import { PatientInsurance, PatientMedical, PatientSocialSecurity, Subcontractor } from "@audiowizard/common"
import { medicalReadableLabels } from "datas/items/itemsPerte"
import { findActiveAm } from "pages/FSV/utilsFSV"
import organismesAMO from "pages/FSV/organismesAMO.json"
import API from "services/API"
import { AudioStatus } from "components/forms/UserRegistrationStatusSelect/UserRegistrationStatusSelect"

const subContractorsConf = async (): Promise<Record<string, MergeTag>> => {
	const subContractors = await API.findAll<Subcontractor[]>("SUBCONTRACTORS_API")
	const subContractorsConf: Record<string, MergeTag> = {}

	for (const [index, subContractor] of subContractors.entries()) {
		const key = `subContractor${index}`

		subContractorsConf[key] = {
			name: `sous traitant ${index + 1}`,
			mergeTags: {
				[`${key}Label`]: {
					name: `nom du sous traitant ${index + 1}`,
					value: `{{${key}Label}}`,
					default: subContractor,
					context: `subContractors.${index}`,
					format: (subContractor): string => subContractor.label,
				},
				[`${key}Description`]: {
					name: `description du sous traitant ${index + 1}`,
					value: `{{${key}Description}}`,
					default: subContractor,
					context: `subContractors.${index}`,
					format: (subContractor): string => subContractor.description,
				},
			},
		}
	}
	return subContractorsConf
}

const frenchWordingByGender = (
	keyPrefix: string,
	pathToGender: string,
	pathLabel: string
): Record<string, MergeTag> => {
	return {
		[`${keyPrefix}MMme`]: {
			name: `m/mme (${pathLabel})`,
			value: `{{{${keyPrefix}MMme}}}`, // triple bracket for raw html
			default: "FEMME",
			context: pathToGender,
			format: (gender: string): string => (gender === "HOMME" ? "M." : "M<sup>me</sup>"),
		},
		[`${keyPrefix}MonsieurMadame`]: {
			name: `monsieur/madame (${pathLabel})`,
			value: `{{${keyPrefix}MonsieurMadame}}`,
			default: "FEMME",
			context: pathToGender,
			format: (gender: string): string => (gender === "HOMME" ? "Monsieur" : "Madame"),
		},
		[`${keyPrefix}CherChere`]: {
			name: `cher/chère (${pathLabel})`,
			value: `{{${keyPrefix}CherChere}}`,
			context: pathToGender,
			default: "FEMME",
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Cher" : "Chère"),
		},
		[`${keyPrefix}NeNee`]: {
			name: `né/née (${pathLabel})`,
			value: `{{${keyPrefix}NeNee}}`,
			context: pathToGender,
			default: "FEMME",
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Né" : "Née"),
		},
		[`${keyPrefix}CeCette`]: {
			name: `ce/cette (${pathLabel})`,
			value: `{{${keyPrefix}CeCette}}`,
			default: "FEMME",
			context: pathToGender,
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Ce" : "Cette"),
		},
		[`${keyPrefix}IlElle`]: {
			name: `il/elle (${pathLabel})`,
			value: `{{${keyPrefix}IlElle}}`,
			default: "FEMME",
			context: pathToGender,
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Il" : "Elle"),
		},
		[`${keyPrefix}CeluiCelle`]: {
			name: `celui/celle (${pathLabel})`,
			value: `{{${keyPrefix}CeluiCelle}}`,
			default: "FEMME",
			context: pathToGender,
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Celui" : "Celle"),
		},
		[`${keyPrefix}LuiElle`]: {
			name: `lui/elle (${pathLabel})`,
			value: `{{${keyPrefix}LuiElle}}`,
			default: "FEMME",
			context: pathToGender,
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Lui" : "Elle"),
		},
		[`${keyPrefix}LeLa`]: {
			name: `le/la (${pathLabel})`,
			value: `{{${keyPrefix}LeLa}}`,
			default: "FEMME",
			context: pathToGender,
			allowLowerCase: true,
			format: (gender: string): string => (gender === "HOMME" ? "Le" : "La"),
		},
	}
}

const defaultMergeTag = async (t: (s: string) => string): Promise<MergeTags> => ({
	// use to test loops, no business use

	patient: {
		name: `${t("context.patient.__selfTranslation")}`,
		mergeTags: {
			...frenchWordingByGender("patient", "patient.gender", "patient"),
			patientSoussigne: {
				name: "soussigné/soussignée",
				value: "{{patientSoussigne}}",
				default: "FEMME",
				context: "patient.gender",
				allowLowerCase: true,
				format: (gender: string): string => (gender === "HOMME" ? "Soussigné" : "Soussignée"),
			},
			patientAccord: {
				name: "patient/patiente",
				value: "{{patientAccord}}",
				default: "FEMME",
				context: "patient.gender",
				allowLowerCase: true,
				format: (gender: string): string => (gender === "HOMME" ? "Patient" : "Patiente"),
			},
			patientLastName: {
				name: `${t("context.patient.lastName")}`,
				value: "{{patientLastName}}",
				default: "Blanchet",
				context: "patient.lastName",
			},
			patientFirstName: {
				name: `${t("context.patient.firstName")}`,
				value: "{{patientFirstName}}",
				default: "Françoise",
				context: "patient.firstName",
			},
			patientAge: {
				name: `${t("context.patient.age")}`,
				value: "{{agePatient}}",
				default: "1956-08-23",
				context: "patient.birthDate",
				format: (birthDate: string): string => {
					return birthDate === "non définie" ? birthDate : moment().diff(birthDate, "years").toString()
				},
			},
			patientBirthDate: {
				name: `${t("context.patient.birthDate")}`,
				value: "{{birthDate}}",
				default: "1956-08-23",
				context: "patient.birthDate",
				format: (birthDate: string): string => dayjs(birthDate).format("DD/MM/YYYY"),
			},
			patientAdresse: {
				name: "adresse",
				value: "{{patientAdresse}}",
				default: "Rue de la République",
				context: "patient.adress",
				allowLowerCase: true,
			},
			patientCp: {
				name: "code postal",
				value: "{{patientCp}}",
				default: "69000",
				context: "patient.cpo",
				allowLowerCase: true,
			},
			patientVille: {
				name: "ville",
				value: "{{patientVille}}",
				default: "Lyon",
				context: "patient.city",
				allowLowerCase: true,
			},
			patientSecu: {
				name: "numéro de sécurité sociale du patient",
				value: "{{patientSecu}}",
				default: "119390487531038",
				context: "patient.idSecu",
			},
			patientSecuName: {
				name: "caisse sécurité sociale du patient",
				value: "{{patientSecuName}}",
				context: "patient.patientSocialSecurity",
				default: "caisse",
				format: (patientSocialSecurity: any): any => {
					const activeAmo = findActiveAm(patientSocialSecurity) as PatientSocialSecurity
					const structure = `${activeAmo?.regime}${activeAmo?.fundManager}`
					const organisme = organismesAMO.find((e) => `${e.regime}${e.caisseGestionnaire}` === structure)
					return organisme?.libelle ?? "CAISSE"
				},
			},
			patientMutuelle: {
				name: "mutuelle du patient",
				value: "{{patientMutuelle}}",
				context: "patient.patientInsurances",
				default: "mutuelle",
				format: (patientInsurances: any): any => {
					const activeAmc = findActiveAm(patientInsurances) as PatientInsurance
					return activeAmc?.label ?? "MUTUELLE"
				},
			},
			patientPhone: {
				name: "téléphone fixe du patient",
				value: "{{patientPhone}}",
				default: "0100000000",
				context: "patient.phone",
				format: (phone: string) => (phone !== "non définie" ? formatPhoneNumber(phone) : phone),
			},
			patientPhoneMobile: {
				name: "téléphone portable du patient",
				value: "{{patientPhoneMobile}}",
				default: "0600000000",
				context: "patient.phoneMobile",
				format: (phone: string) => (phone !== "non définie" ? formatPhoneNumber(phone) : phone),
			},
			patientEmail: {
				name: "adresse email du patient",
				value: "{{patientEmail}}",
				default: "françoise.blanchet@audiowizard.fr",
				context: "patient.email",
			},
			patientPerteGauche: {
				name: "perte gauche du patient",
				value: "{{patientPerteGauche}}",
				default: [{ label: "PERTE PERCEPTION", level: 1, ear: "GAUCHE", type: "PERTE" }],
				context: "patient.patientMedicals",
				format: (medicals: PatientMedical[]) => {
					const perteGauche = medicals.find((medical) => medical.type === "PERTE" && medical.ear === "GAUCHE")
					if (!perteGauche) return "non définie"

					const label =
						medicalReadableLabels.labels[perteGauche.label as keyof typeof medicalReadableLabels.labels]
					const level =
						medicalReadableLabels.levels[perteGauche.level as keyof typeof medicalReadableLabels.levels]
					const ear = medicalReadableLabels.ear[perteGauche.ear as keyof typeof medicalReadableLabels.ear]

					return `${StringUpperCFirst(label)} ${level?.toLowerCase() ?? ""} ${ear?.toLowerCase()}`
				},
			},
			patientPerteDroite: {
				name: "perte droite du patient",
				value: "{{patientPerteDroite}}",
				default: [{ label: "PERTE PERCEPTION", level: 1, ear: "DROITE", type: "PERTE" }],
				context: "patient.patientMedicals",
				format: (medicals: PatientMedical[]) => {
					const perteDroite = medicals.find((medical) => medical.type === "PERTE" && medical.ear === "DROITE")
					if (!perteDroite) return "non définie"

					const label =
						medicalReadableLabels.labels[perteDroite.label as keyof typeof medicalReadableLabels.labels]
					const level =
						medicalReadableLabels.levels[perteDroite.level as keyof typeof medicalReadableLabels.levels]
					const ear = medicalReadableLabels.ear[perteDroite.ear as keyof typeof medicalReadableLabels.ear]

					return `${StringUpperCFirst(label)} ${level?.toLowerCase() ?? ""} ${ear?.toLowerCase()}`
				},
			},
		},
	},
	user: {
		name: `${t("context.user.__selfTranslation")}`,
		mergeTags: {
			...frenchWordingByGender("user", "user.gender", "utilisateur"),
			userSoussigne: {
				name: "soussigné/soussignée",
				value: "{{userSoussigne}}",
				default: "FEMME",
				context: "user.gender",
				allowLowerCase: true,
				format: (gender: string): string => (gender === "HOMME" ? "Soussigné" : "Soussignée"),
			},
			userLastName: {
				name: `${t("context.user.lastName")}`,
				value: "{{userLastName}}",
				default: "Doe",
				context: "user.lastName",
			},
			userFirstName: {
				name: `${t("context.user.firstName")}`,
				value: "{{userFirstName}}",
				default: "John",
				context: "user.firstName",
			},
			userSignature: {
				name: "signature de l'utilisateur",
				value: "{{{userSignature}}}", // triple bracket for raw html
				default: "https://via.placeholder.com/150x50",
				context: "userSignatureUrl",
				helper: "signature",
			},
			company: {
				name: `${t("context.user.company.__selfTranslation")}`,
				mergeTags: {
					companyName: {
						name: `${t("context.user.company.label")}`,
						value: "{{company.companyName}}",
						default: "Inc Audio",
						context: "user.company.label",
					},
				},
			},
		},
	},
	divers: {
		name: "autre",
		mergeTags: {
			date: {
				name: `${t("custom_store.date.today")}`,
				value: "{{today}}",
				default: moment().format("YYYY-MM-DD"),
				context: "todayDate",
				format: (date: string): string => {
					return date === "non définie" ? date : moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
				},
			},
			year: {
				name: `${t("custom_store.date.year")}`,
				value: "{{year}}",
				default: moment().format("YYYY"),
				context: "todayDate",
				format: (date: string): string => {
					return date === "non définie" ? date : moment(date, "YYYY-MM-DD").format("YYYY")
				},
			},
		},
	},
	subContractors: {
		name: "sous traitants",
		mergeTags: {
			...(await subContractorsConf()),
		},
	},
})

const SAVMergeTag = (t: (s: string) => string): MergeTags => ({
	user: {
		name: `${t("context.user.__selfTranslation")}`,
		mergeTags: {
			company: {
				name: `${t("context.user.company.__selfTranslation")}`,
				mergeTags: {
					companyName: {
						name: `${t("context.user.company.label")}`,
						value: "{{company.companyName}}",
						default: "Inc Audio",
						context: "user.company.label",
					},
					laboratoryName: {
						name: `${t("context.user.company.laboratoryName")}`,
						value: "{{company.laboratoryName}}",
						default: "Lyon",
						context: "user.company.laboratoryName",
					},
					laboratoryAdress: {
						name: `${t("context.user.company.adresse")}`,
						value: "{{company.laboratoryAdress}}",
						default: "55 bdv Aliende",
						context: "user.company.laboratoryAdress",
					},
					laboratoryCpo: {
						name: `${t("context.user.company.cpo")}`,
						value: "{{company.laboratoryCpo}}",
						default: "45678",
						context: "user.company.laboratoryCpo",
					},
					laboratoryCity: {
						name: `${t("context.user.company.city")}`,
						value: "{{company.laboratoryCity}}",
						default: "Rouen",
						context: "user.company.laboratoryCity",
					},
					laboratoryPhone: {
						name: `${t("context.user.company.phone")}`,
						value: "{{company.laboratoryPhone}}",
						default: "56 47 38 29 10",
						context: "user.company.laboratoryPhone",
					},
					laboratoryMail: {
						name: `${t("context.user.company.mail")}`,
						value: "{{company.laboratoryMail}}",
						default: "sample@sample.com",
						context: "user.company.laboratoryMail",
					},
				},
			},
		},
	},
	details: {
		name: "details",
		mergeTags: {
			savNumber: {
				name: "numéro de SAV",
				value: "{{details.savNumber}}",
				default: "1293848",
				context: "details.savNumber",
			},
			sentDate: {
				name: "date d'envoie",
				value: "{{details.sentDate}}",
				default: "02/02/2022",
				context: "details.sentDate",
			},
			model: {
				name: "model",
				value: "{{details.model}}",
				default: "MODEL",
				context: "details.model",
			},
			manufacturer: {
				name: "marque",
				value: "{{details.manufacturer}}",
				default: "MANUFACTURER",
				context: "details.manufacturer",
			},
			serialNumber: {
				name: "numéro de série",
				value: "{{details.serialNumber}}",
				default: "XF 190",
				context: "details.serialNumber",
			},
			ear: {
				name: "oreille",
				value: "{{details.ear}}",
				default: "LEFT/RIGHT",
				allowLowerCase: true,
				context: "details.ear",
			},
			purchasedDate: {
				name: "date d'achat",
				value: "{{details.purchasedDate}}",
				default: "20/05/2021",
				context: "details.purchasedDate",
			},
			warranty: {
				name: "garantie",
				value: "{{details.warranty}}",
				default: "MODEL",
				allowLowerCase: true,
				context: "details.warranty",
			},
			breakdowns: {
				name: "pannes",
				value: "{{details.breakdowns}}",
				default: "Ne fonctionne plus",
				allowLowerCase: true,
				context: "details.breakdowns",
			},
			repairs: {
				name: "réparations",
				value: "{{details.repairs}}",
				default: "Réparation",
				allowLowerCase: true,
				context: "details.repairs",
			},
			returnedDate: {
				name: "date de retour",
				value: "{{details.returnedDate}}",
				default: "02/04/2022",
				context: "details.returnDate",
			},
			breakdownsComment: {
				name: "commentaires des pannes",
				value: "{{details.breakdownsComment}}",
				default: "commentaires",
				allowLowerCase: true,
				context: "details.breakdownsComment",
			},
			repairsComment: {
				name: "commentaires des réparations",
				value: "{{details.repairsComment}}",
				default: "commentaires",
				allowLowerCase: true,
				context: "details.repairsComment",
			},
			repairPriceWithoutTax: {
				name: "prix réparation HT",
				value: "{{details.repairPriceWithoutTax}}",
				default: "200 €",
				context: "details.repairPriceWithoutTax",
			},
			repairPriceWithTax: {
				name: "prix des réparation TTC",
				value: "{{details.repairPriceWithTax}}",
				default: "220 €",
				context: "details.returnDate",
			},
		},
	},
})

const patientDefaultMergeTag = (t: (s: string) => string): MergeTags => ({
	mainUser: {
		name: "audioprothésiste",
		mergeTags: {
			mainUserLastname: {
				name: "nom de l'audioprothésiste",
				value: "{{mainUserLastName}}",
				context: "patient.mainUser.lastName",
				default: "Doe",
			},
			mainUserFirstname: {
				name: "prénom de l'audioprothésiste",
				value: "{{mainUserFirstname}}",
				context: "patient.mainUser.firstName",
				default: "John",
			},
			mainUserEmail: {
				name: "email de l'audioprothésiste",
				value: "{{mainUserEmail}}",
				context: "patient.mainUser.email",
				default: "john.doe@email.fr",
			},
			userIsDE: {
				name: "statut D.E.",
				value: "{{mainUserRegistrationStatus}}",
				default: AudioStatus.StateRegistered,
				context: "patient.mainUser.registrationStatus",
				format: (registrationStatus: AudioStatus) =>
					registrationStatus === AudioStatus.StateRegistered ? "Audioprothésiste D.E." : "Audioprothésiste",
			},
		},
	},
	mainOrl: {
		name: "orl",
		mergeTags: {
			...frenchWordingByGender("mainOrl", "patient.mainOrl.gender", "orl"),
			mainOrlDr: {
				name: "dr (orl)",
				value: "{{mainOrlDr}}",
				context: "patient.mainOrl.gender",
				default: "DR",
				allowLowerCase: true,
				format: () => "DR",
			},
			mainOrlDoctor: {
				name: "docteur (orl)",
				value: "{{mainOrlDoctor}}",
				context: "patient.mainOrl.gender",
				default: "Docteur",
				allowLowerCase: true,
				format: () => "Docteur",
			},
			mainOrlFirstName: {
				name: "prénom de l'orl",
				value: "{{orl.firstName}}",
				default: "Mannon",
				context: "patient.mainOrl.firstName",
			},
			mainOrlLastName: {
				name: "nom de l'orl",
				value: "{{orl.lastName}}",
				default: "Marty",
				context: "patient.mainOrl.lastName",
			},
			mainOrlAddress: {
				name: "address de l'orl",
				value: "{{orl.address}}",
				default: "33 rue des Oliviers",
				context: "patient.mainOrl.adress",
			},
			mainOrlCodePostal: {
				name: "code postal de l'orl",
				value: "{{orl.cpo}}",
				default: "59000",
				context: "patient.mainOrl.cpo",
			},
			mainOrlCity: {
				name: "ville de l'orl",
				value: "{{orl.city}}",
				default: "Lille",
				context: "patient.mainOrl.city",
			},
			mainOrlPhone: {
				name: "numéro de téléphone de l'orl",
				value: "{{mainOrlPhone}}",
				default: "0100000000",
				context: "patient.mainOrl.phone",
				format: (phone: string) => (phone !== "non définie" ? formatPhoneNumber(phone) : phone),
			},
			mainOrlFiness: {
				name: "finess de l'orl",
				value: "{{mainOrlFiness}}",
				default: "111111111",
				context: "patient.mainOrl.finess",
			},
			mainOrlRpps: {
				name: "rpps de l'orl",
				value: "{{mainOrlRpps}}",
				default: "222222222222",
				context: "patient.mainOrl.rpps",
			},
			perscriberAdeli: {
				name: "adeli de l'orl",
				value: "{{mainOrlAdeli}}",
				default: "333333333",
				context: "patient.mainOrl.adeli",
			},
		},
	},
	mainDoctor: {
		name: "généraliste",
		mergeTags: {
			...frenchWordingByGender("mainDoctor", "patient.mainDoctor.gender", "généraliste"),
			mainDoctorDr: {
				name: "dr (généraliste)",
				value: "{{mainDoctorDr}}",
				context: "patient.mainDoctor.gender",
				default: "DR",
				allowLowerCase: true,
				format: () => "DR",
			},
			mainDoctorDoctor: {
				name: "docteur (généraliste)",
				value: "{{mainDoctorDoctor}}",
				context: "patient.mainDoctor.gender",
				default: "Docteur",
				allowLowerCase: true,
				format: () => "Docteur",
			},
			mainDoctorFirstName: {
				name: "prénom du généraliste",
				value: "{{mainDoctorFirstName}}",
				default: "Mannon",
				context: "patient.mainDoctor.firstName",
			},
			mainDoctorLastName: {
				name: "nom du généraliste",
				value: "{{mainDoctorLastName}}",
				default: "Marty",
				context: "patient.mainDoctor.lastName",
			},
			mainDoctorAddress: {
				name: "address du généraliste",
				value: "{{mainDoctorAddress}}",
				default: "33 rue des Oliviers",
				context: "patient.mainDoctor.adress",
			},
			mainDoctorCodePostal: {
				name: "code postal du généraliste",
				value: "{{mainDoctorCodePostal}}",
				default: "59000",
				context: "patient.mainDoctor.cpo",
			},
			mainDoctorCity: {
				name: "ville du généraliste",
				value: "{{mainDoctorCity}}",
				default: "Lille",
				context: "patient.mainDoctor.city",
			},
			mainDoctorPhone: {
				name: "numéro de téléphone du généraliste",
				value: "{{mainDoctorPhone}}",
				default: "0100000000",
				context: "patient.mainDoctor.phone",
				format: (phone: string) => (phone !== "non définie" ? formatPhoneNumber(phone) : phone),
			},
			mainDoctorFiness: {
				name: "finess du généraliste",
				value: "{{mainDoctorFiness}}",
				default: "111111111",
				context: "patient.mainDoctor.finess",
			},
			mainDoctorRpps: {
				name: "rpps du généraliste",
				value: "{{mainDoctorRpps}}",
				default: "222222222222",
				context: "patient.mainDoctor.rpps",
			},
			mainDoctorAdeli: {
				name: "adeli du généraliste",
				value: "{{mainDoctorAdeli}}",
				default: "333333333",
				context: "patient.mainDoctor.adeli",
			},
		},
	},
	prescriber: {
		name: `${t("context.advice.prescriber.__selfTranslation")}`,
		mergeTags: {
			...frenchWordingByGender("prescriber", "advice.prescriber.gender", "prescripteur"),
			prescriberDr: {
				name: "dr (presripteur)",
				value: "{{prescriberDr}}",
				context: "advice.prescriber.gender",
				default: "DR",
				allowLowerCase: true,
				format: () => "DR",
			},
			prescriberDoctor: {
				name: "docteur (prescripteur)",
				value: "{{prescriberDoctor}}",
				context: "advice.prescriber.gender",
				default: "Docteur",
				allowLowerCase: true,
				format: () => "Docteur",
			},
			prescriberFirstName: {
				name: `${t("context.advice.prescriber.firstName")}`,
				value: "{{prescriberFirstName}}",
				default: "Mannon",
				context: "advice.prescriber.firstName",
			},
			prescriberLastName: {
				name: `${t("context.advice.prescriber.lastName")}`,
				value: "{{prescriberLastName}}",
				default: "Marty",
				context: "advice.prescriber.lastName",
			},
			prescriberAddress: {
				name: `${t("context.advice.prescriber.adress")}`,
				value: "{{prescriberAddress}}",
				default: "33 rue des Oliviers",
				context: "advice.prescriber.adress",
			},
			prescriberCodePostal: {
				name: `${t("context.advice.prescriber.cpo")}`,
				value: "{{prescriberCodePostal}}",
				default: "59000",
				context: "advice.prescriber.cpo",
			},
			prescriberCity: {
				name: `${t("context.advice.prescriber.city")}`,
				value: "{{prescriberCity}}",
				default: "Lille",
				context: "advice.prescriber.city",
			},
			prescriberPhone: {
				name: "numéro de téléphone du prescripteur",
				value: "{{prescriberPhone}}",
				default: "0100000000",
				context: "advice.prescriber.phone",
				format: (phone: string) => (phone !== "non définie" ? formatPhoneNumber(phone) : phone),
			},
			prescriberFiness: {
				name: "finess du prescripteur",
				value: "{{prescriberFiness}}",
				default: "111111111",
				context: "advice.prescriber.finess",
			},
			prescriberRpps: {
				name: "rpps du prescripteur",
				value: "{{prescriberRpps}}",
				default: "222222222222",
				context: "advice.prescriber.rpps",
			},
			perscriberAdeli: {
				name: "adeli du prescripteur",
				value: "{{prescriberAdeli}}",
				default: "333333333",
				context: "advice.prescriber.adeli",
			},
		},
	},
	advice: {
		name: `${t("context.advice.__selfTranslation")}`,
		mergeTags: {
			adviceDate: {
				name: `${t("context.advice.dateOfAdvice")}`,
				value: "{{dateOfAdvice}}",
				default: "2022-01-11",
				context: "advice.dateOfAdvice",
				format: (date: string): string => {
					return date === "non définie" ? date : moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
				},
			},
			adviceType: {
				name: `${t("context.advice.typeOfAdvice")}`,
				value: "{{typeOfAdvice}}",
				default: "RENOUVELLEMENT",
				allowLowerCase: true,
				context: "advice.type",
			},
		},
	},
	equipment: {
		name: `${t("context.patient.patientEquipments.__selfTranslation")}`,

		mergeTags: {
			wearTime: {
				name: "temps porté",
				value: "{{equipmentWearTime}}",
				default: [{ hours: 8 }],
				context: "patient.schedules",
				format: (schedules: { hours: number; dateOf: string }[] | string) => {
					if (typeof schedules === "string") return schedules // "non définie"

					const scopy = schedules
						.filter((s) => s.hours !== 0)
						.sort((a, b) => {
							const at = new Date(a.dateOf).getTime()
							const bt = new Date(b.dateOf).getTime()

							return bt - at
						})
					return scopy[0]?.hours.toString() ?? ""
				},
			},
			right: {
				name: "droite",
				mergeTags: {
					equipmentRightType: {
						name: "type de l'equipment droit",
						value: "{{equipmentRightType}}",
						default: "Écouteur déporté",
						context: "equipments.right.type",
						allowLowerCase: true,
					},
					equipmentRightBrand: {
						name: "marque de l'equipment droit",
						value: "{{equipmentRightBrand}}",
						default: "Phonak",
						context: "equipments.right.brand",
					},
					equipmentRightModel: {
						name: "nom de l'equipment droit",
						value: "{{equipmentRightModel}}",
						default: "Nom",
						context: "equipments.right.model",
					},
					equipmentRightSerialNumber: {
						name: "Numéro de série de l'equipment droite",
						value: "{{equipmentRightSerialNumber}}",
						default: "0000-0000",
						allowLowerCase: true,
						context: "equipments.right.serialNumber",
					},
					equipmentRightClass: {
						name: `${t("context.patient.patientEquipments.class")} droit`,
						value: "{{equipmentRightClass}}",
						default: "1",
						allowLowerCase: true,
						context: "equipments.right.classe",
					},
					equipmentRightReference: {
						name: "gamme de l'equipment droit",
						value: "{{equipmentRightReference}}",
						default: "Entrée de gamme",
						allowLowerCase: true,
						context: "equipments.right.gamme",
					},
					equipmentRightDatePurchase: {
						name: "date de vente",
						value: "{{equipmentRightDatePurchase}}",
						default: "1956-08-23",
						allowLowerCase: true,
						context: "equipments.right.datePurchase",
						format: (datePurchase: string): string => dayjs(datePurchase).format("DD/MM/YYYY"),
					},
				},
			},
			left: {
				name: "gauche",
				mergeTags: {
					equipmentLeftType: {
						name: "type de l'equipment gauche",
						value: "{{equipmentLeftType}}",
						default: "TYPE",
						allowLowerCase: true,
						context: "equipments.left.type",
					},
					equipmentLeftBrand: {
						name: "marque de l'equipment gauche",
						value: "{{equipmentLeftBrand}}",
						default: "MARQUE",
						context: "equipments.left.brand",
					},
					equipmentLeftModel: {
						name: "nom de l'equipment gauche",
						value: "{{equipmentLeftModel}}",
						default: "Nom",
						context: "equipments.left.model",
					},
					equipmentLeftSerialNumber: {
						name: "Numéro de série de l'equipment gauche",
						value: "{{equipmentLeftSerialNumber}}",
						default: "0000-0000",
						allowLowerCase: true,
						context: "equipments.left.serialNumber",
					},
					equipmentLeftClass: {
						name: `${t("context.patient.patientEquipments.class")} gauche`,
						value: "{{equipmentLeftClass}}",
						default: "CLASSE",
						allowLowerCase: true,
						context: "equipments.left.classe",
					},
					equipmentLeftReference: {
						name: "gamme de l'equipment gauche",
						value: "{{equipmentLeftReference}}",
						default: "GAMME",
						allowLowerCase: true,
						context: "equipments.left.gamme",
					},
					equipmentleftDatePurchase: {
						name: "date de vente",
						value: "{{equipmentleftDatePurchase}}",
						default: "1956-08-23",
						allowLowerCase: true,
						context: "equipments.left.datePurchase",
						format: (datePurchase: string): string => dayjs(datePurchase).format("DD/MM/YYYY"),
					},
				},
			},
		},
	},
	divers: {
		name: "autre",
		mergeTags: {
			billNumber: {
				name: "Numéro de facture",
				value: "{{billNumber}}",
				default: "0000-0000",
				context: "billNumber", //TODO: put real context
			},
		},
	},
	laboratory: {
		name: `${t("context.patient.laboratory.__selfTranslation")}`,
		mergeTags: {
			laboratoryName: {
				name: `${t("context.patient.laboratory.label")}`,
				value: "{{company.laboratoryName}}",
				default: "Huet SAS",
				context: "patient.laboratory.label",
			},
			laboratoryAddress: {
				name: `${t("context.patient.laboratory.adress")}`,
				value: "{{company.laboratoryAddress}}",
				default: "44 Rue des peupliers",
				context: "patient.laboratory.adress",
			},
			laboratoryCodePostal: {
				name: `${t("context.patient.laboratory.cpo")}`,
				value: "{{company.laboratoryCpo}}",
				default: "59000",
				context: "patient.laboratory.cpo",
			},
			laboratoryCity: {
				name: `${t("context.patient.laboratory.city")}`,
				value: "{{company.laboratoryCity}}",
				default: "Lille",
				context: "patient.laboratory.city",
			},
			laboratoryPhone: {
				name: `${t("context.patient.laboratory.phone")}`,
				value: "{{company.laboratoryPhone}}",
				default: "0300000000",
				context: "patient.laboratory.phone",
				format: (phone: string) => (phone !== "non définie" ? formatPhoneNumber(phone) : phone),
			},
			laboratoryEmail: {
				name: `${t("context.patient.laboratory.email")}`,
				value: "{{company.laboratoryEmail}}",
				default: "test@tuie.fr",
				context: "patient.laboratory.email",
			},
		},
	},
	patientLookingFor: {
		name: `${t("context.patient.patientLookingFors.__selfTranslation")}`,
		rules: {
			repeat: {
				name: "Repeat for Each LookingFor",
				before: "{{#lookingFors}}",
				after: "{{/lookingFors}}",
				context: "patient.lookingFors",
			},
		},
		mergeTags: {
			value: {
				name: `${t("context.patient.patientLookingFors.lookingFor")}`,
				value: "{{lookingFor}}",
				default: "Attente du patient",
				allowLowerCase: true,
				context: "value",
			},
		},
	},
})

export const mergeTags = {
	defaultMergeTag,
	[TypeTemplates.MAIL_REPORT]: patientDefaultMergeTag,
	[TypeTemplates.NOTE]: patientDefaultMergeTag,
	[TypeTemplates.SAV]: SAVMergeTag,
}
