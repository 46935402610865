import { useContext, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { Alert } from "reactstrap"
import AuthContext from "../../../contexts/AuthContext"
import { uniq } from "lodash"

const handledCategories = [
	// Please do not change these category names without checking boutik témoin corresponding category
	"APPAREILS AUDITIFS",
	"IMPLANTS",
	"PILES",
	"ECOUTEUR",
	"ECOUTEUR AVEC SN",
	"EMBOUT REMBOURSE",
	"EMBOUT AVEC SN",
	"ENTRETIEN ADHEAR",
	"ENTRETIEN",
	"ANCRAGE OSSEUX",
	"ANCRAGE OSSEUX AVEC SN",
	"PILES IMPLANTS",
	"BATTERIES IMPLANTS",
	// On production we have these two categories for some reason,
	"ACCESSOIRES IMPLANTS",
	"ACCESSOIRE IMPLANTS",
	"ACCESSOIRES IMPLANTS AVEC SN",
]

const Buttons = ({ json, seller, hasPrescription }) => {
	const history = useHistory()
	const sales = json?.ventes?.achats || []

	const categoriesList = useMemo(() => {
		if (sales == null || (/contre vente/i.test(json.ventes?.comments) && Math.sign(json.ventes?.total_brut) === -1))
			return []

		const categories = sales
			.filter(
				(item) =>
					item?.category_name != null && handledCategories.includes(item?.category_name.toUpperCase().trim())
			)
			.map((j) => j.category_name.trim())

		return uniq(categories?.sort())
	}, [sales])

	if (!sales.length || !categoriesList.length)
		return <>Aucun produit sur la facture ne permet la génération d'une feuille de soins</>

	return (
		<>
			Cliquez sur l'un des boutons suivant pour générer une feuille de soin
			<div className="row p-2 ">
				{categoriesList?.map((cat) => {
					const disabled = cat.toUpperCase() === "APPAREILS AUDITIFS" && !hasPrescription

					return (
						<button
							key={cat}
							type="button"
							className={"col btn btn-primary btn-block mt-4"}
							disabled={disabled}
							onClick={() => {
								history.push("/feuille-soin", {
									company: json?.company || {},
									laboratory: json?.laboratoire || {},
									patient: json?.patient?.full || {},
									sale: json?.ventes || {},
									seller,
									type: cat.toUpperCase(),
								})
							}}>
							{cat}
						</button>
					)
				})}
			</div>
		</>
	)
}

const BoutonGenerationFeuilleSoin = ({ patientDetails, json, seller, isBillingImport }) => {
	const history = useHistory()
	const { setPatient, setEditing } = useContext(AuthContext)
	const hasPrescription = patientDetails?.advices?.[0]

	if (isBillingImport)
		return (
			<Alert color="primary">Les factures importées ne permettent pas la génération d'une feuille de soins</Alert>
		)

	return (
		<>
			{!hasPrescription && (
				<Alert color="warning" className="font-weight-bold">
					Pour générer une feuille de soin <strong>autre que pour les piles et les écouteurs</strong>, vous
					devez assigner un prescripteur à{" "}
					<Link to={`/fiche-patient/${patientDetails?.id}`}>
						{patientDetails?.firstName} {patientDetails?.lastName}
					</Link>
					.
					<br />
					<button
						type="button"
						onClick={() => {
							setEditing(true)
							setPatient(patientDetails)
							history.push("/premiere-visite/prescripteur", { redirectTo: window.location.pathname })
						}}
						className="mt-4 btn btn-block btn-warning">
						Cliquez ici pour ajouter un prescripteur
					</button>
				</Alert>
			)}
			<Alert color="primary">
				<Buttons json={json} seller={seller} hasPrescription={hasPrescription} />
			</Alert>
		</>
	)
}

export default BoutonGenerationFeuilleSoin
