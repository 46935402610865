import {
	CommunicationMessage,
	CommunicationMessageError,
	CommunicationMessageStatus,
	CommunicationMessageSmsEncoding,
	Schedule,
} from "@audiowizard/common"
import { Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import { LocaleNamespace } from "config/intl/helpers"
import dayjs from "dayjs"
import { useTranslation } from "hooks/specific/useTranslation"
import { useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Badge } from "reactstrap"
import API from "services/API"
import { formatPhoneNumber } from "services/functions"
import "./CommunicationMessageTable.scss"

const statusBadgeColor: Record<CommunicationMessageStatus, string> = {
	PENDING: "info",
	SENT: "primary",
	ERROR: "danger",
}

type StatusBadgeProps = {
	status: CommunicationMessageStatus
	error?: CommunicationMessageError
}
function StatusBadge({ status, error }: StatusBadgeProps): JSX.Element {
	const t = useTranslation(LocaleNamespace.CommunicationMessage)

	return (
		<>
			<Badge color={statusBadgeColor[status]} className="communication-message-table-status-badge">
				{t(`status.${status}`)}
			</Badge>
			{error != null && (
				<>
					{" - "}
					<Badge color="warning" className="communication-message-table-status-badge">
						{t(`error.${error}`)}
					</Badge>
				</>
			)}
		</>
	)
}

export default function CommunicationMessageTable(): JSX.Element {
	const [statusFilters, setStatusFilters] = useState<CommunicationMessageStatus[]>([])
	const [finishedAtSortOrder, setFinishedAtSortOrder] = useState<"ascend" | "descend">("descend")
	const [page, setPage] = useState(1)

	const t = useTranslation(LocaleNamespace.CommunicationMessage)

	const columns: ColumnsType<CommunicationMessage> = [
		{
			title: "État",
			dataIndex: "status",
			filters: [
				{
					text: (
						<Badge color="info" className="communication-message-table-status-badge">
							{t("status.PENDING")}
						</Badge>
					),
					value: "PENDING",
				},
				{
					text: (
						<Badge color="primary" className="communication-message-table-status-badge">
							{t("status.SENT")}
						</Badge>
					),
					value: "SENT",
				},
				{
					text: (
						<Badge color="danger" className="communication-message-table-status-badge">
							{t("status.ERROR")}
						</Badge>
					),
					value: "ERROR",
				},
			],
			filteredValue: statusFilters,
			render: (status: CommunicationMessageStatus, message) => (
				<StatusBadge status={status} error={message.error} />
			),
		},
		{
			title: "Patient",
			dataIndex: "schedule",
			render: (schedule?: Schedule) =>
				schedule != null ? (
					<Link to={`/fiche-patient/${schedule.patient?.id}`}>
						{schedule.patient?.firstName} {schedule.patient?.lastName}
					</Link>
				) : (
					<p className="text-danger">Le rendez-vous a été supprimé.</p>
				),
		},
		{
			title: "Numéro",
			dataIndex: "phoneNumber",
			render: (phoneNumber: string, message) => (
				<>
					{formatPhoneNumber(phoneNumber)}
					{message.schedule != null &&
						![message?.schedule?.patient?.phone, message?.schedule?.patient?.phoneMobile].includes(
							phoneNumber
						) && <p className="text-info">Le numéro du patient actuel est différent.</p>}
				</>
			),
		},
		{
			title: "Message",
			dataIndex: "message",
			ellipsis: { showTitle: true },
			render: (message: string) => message,
		},
		{
			title: (
				<>
					Encodage{" "}
					<Tooltip
						placement="top"
						title={
							<p className="m-0">
								L'encodage Simple (GSM-7) ne peut envoyer que des lettres sans accents, certaines
								lettres accentués, chiffres et ponctuations.
								<br />
								L'encodage Complet (UCS-2) peut envoyer n'importe quel caractère (Lettres avec accents,
								chiffres, ponctuations, emojis...).
							</p>
						}>
						<span>
							{/* span is needed, otherwise tooltip doesn't show */}
							<i className="fad fa-question-circle" />
						</span>
					</Tooltip>
				</>
			),
			dataIndex: "smsMessageEncoding",
			render: (smsMessageEncoding: CommunicationMessageSmsEncoding) =>
				smsMessageEncoding === "GSM-7" ? "Simple (GSM-7)" : "Complet (UCS-2)",
		},
		{
			title: (
				<>
					Unité(s) SMS{" "}
					<Tooltip
						placement="top"
						title={
							<p className="m-0">
								Le nombre d'unités envoyées par un SMS dépend de la taille du message et de l'encodage
								utilisé. <br />
								Avec l'encodage Simple (GSM-7), une unité peut contenir environ 153 caractères. <br />
								Avec l'encodage Complet (UCS-2), une unité peut contenir environ 67 caractères.{" "}
							</p>
						}>
						<span>
							{/* span is needed, otherwise tooltip doesn't show */}
							<i className="fad fa-question-circle" />
						</span>
					</Tooltip>
				</>
			),
			dataIndex: "smsSegmentCount",
			render: (smsSegmentCount: number) => smsSegmentCount,
		},
		{
			title: "Envoyé le",
			dataIndex: "finishedAt",
			sorter: true,
			sortDirections: ["ascend", "descend", "ascend"], // This prevents null sort order. finishedAt should always have a sort order.
			sortOrder: finishedAtSortOrder,
			render: (finishedAt?: string) => (finishedAt != null ? dayjs(finishedAt).format("LL à LT") : "--"),
		},
	]

	const { data: messagesData, isLoading } = useQuery<{
		"hydra:member": CommunicationMessage[]
		"hydra:totalItems": number
	}>(
		["COMMUNICATION_MESSAGES_API", { type: "SMS", page, statusFilters, finishedAtSortOrder }],
		async () => {
			const searchParams = new URLSearchParams()
			searchParams.set("page", page.toString())
			searchParams.set("type", "SMS")

			for (const status of statusFilters) {
				searchParams.append("status[]", status)
			}

			searchParams.set("order[finishedAt]", finishedAtSortOrder === "ascend" ? "asc" : "desc")

			return await API.findAll<CommunicationMessage[]>("COMMUNICATION_MESSAGES_API", `?${searchParams}`, true)
		},
		{
			onError: () => toast.error("Erreur lors de la récupération de l'historique des messages"),
		}
	)
	const messages = messagesData?.["hydra:member"] ?? []
	const totalMessages = messagesData?.["hydra:totalItems"] ?? 0

	return (
		<Table
			columns={columns}
			dataSource={messages}
			pagination={{
				position: ["bottomLeft"],
				showSizeChanger: false,
				pageSize: 10,
				total: totalMessages,
				current: page,
			}}
			loading={isLoading}
			onChange={(pagination, filters, sorter) => {
				setPage(pagination.current as number)
				setStatusFilters((filters.status as CommunicationMessageStatus[] | null) ?? [])
				if (!(sorter instanceof Array) && sorter.field === "finishedAt") {
					setFinishedAtSortOrder(sorter.order!)
				}
			}}
		/>
	)
}
