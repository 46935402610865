import { Button, ButtonConfig } from "@bryntum/calendar"
import { AWCalendar } from "./AgendaTypes"
import { cleanEventFromLocalStorage } from "./BryntumAgendaLocalStorage"

export default class ButtonRefresh extends Button {
	static get $name(): string {
		return "ButtonRefresh"
	}

	static get type(): string {
		return "buttonrefresh"
	}

	static get configurable(): Partial<ButtonConfig> {
		return {
			text: "L{Refresh}",
			cls: "topBarActionButton",
			tooltip: "Rafraîchir",
			onClick() {
				// @ts-ignore
				const calendar = this.up("calendar") as AWCalendar
				cleanEventFromLocalStorage(calendar.extraData.agendas)
				calendar.extraData.agendaAlreadyLoaded.current = {}
				calendar.eventStore.removeAll()
				// @ts-ignore type de la bibliothèque incomplet
				calendar.eventStore.globalOccurrences.clear()
				calendar.trigger("fetchSchedules")
			},
		}
	}
}
