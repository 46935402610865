import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const ProfilRoutePrefix = GestionCompteRoutePrefix + "/profil"

export const ProfilRoutes = {
	Informations: `${ProfilRoutePrefix}/informations`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(ProfilRoutePrefix, "")
}
