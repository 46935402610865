/* eslint-disable react/no-children-prop */
import { Switch } from "antd"
import axios from "axios"
import cx from "classnames"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import useEffectAsync from "components/Hooks/useEffectAsync"
import _ from "lodash"
import { clearAllHiboutikCache } from "pages/Stock-Management/Shared/Utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import FieldWithError from "../../../components/forms/FieldWithError"
import Select from "../../../components/forms/Select"
import Button from "../../../components/utils/Button"
import { REMOTE_FILE_CHECK_API } from "../../../config"
import API_Hiboutik from "../../../services/API_Hiboutik"

const tagNames = {
	type: "Type",
	energy: "Alimentation",
	classe: "Classe",
	nombreCanaux: "Nombre de canaux",
	bobine: "Bobine",
	bluetooth: "Bluetooth",
	reducteurAcouphene: "Réducteur d'acouphène",
	telecommande: "Télécommande",
	antiLarsen: "Anti-Larsen",
	puissant: "Puissant",
	tv: "TV",
	chargeur: "Chargeur",
}

const getProductDifferences = (o1, o2) => {
	let k,
		kDiff,
		diff = {}
	for (k in o1) {
		if (!o1.hasOwnProperty(k)) {
		} else if (typeof o1[k] !== "object" || typeof o2[k] !== "object") {
			if (!(k in o2) || o1[k] !== o2[k]) {
				diff[k] = o2[k]
			}
		} else if (kDiff === getProductDifferences(o1[k], o2[k])) {
			diff[k] = kDiff
		}
	}
	for (k in o2) {
		if (o2.hasOwnProperty(k) && !(k in o1)) {
			diff[k] = o2[k]
		}
	}
	for (k in diff) {
		if (diff.hasOwnProperty(k)) {
			return diff
		}
	}
	return false
}

const Product = ({ match }) => {
	const id = match.params.id
	const queryClient = useQueryClient()

	// When this is set to true, all data required to display the page has been fetched
	const [loading, setLoading] = useState(true)
	const [productLoading, setIsProductLoading] = useState(false)

	// State to be filled during loading
	const [taxes, setTaxes] = useState([])
	const [taxOptions, setTaxOptions] = useState([])
	const [brands, setBrands] = useState([])
	const [categories, setCategories] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [tags, setTags] = useState([])
	const [tagsCanaux, setTagsCanaux] = useState()
	const [canauxTagCategoryId, setCanauxTagCategoryId] = useState()
	const [declinaisons, setDeclinaisons] = useState([])
	const [declinaisonStock, setDeclinaisonStock] = useState()
	const [specificDeclinaisons, setSpecificDeclinaisons] = useState([])
	const [sizes, setSizes] = useState({})

	const [product, setProduct] = useState({})
	// Product object being edited
	const [newProduct, setNewProduct] = useState({})
	// Product has been modified
	const [modified, setModified] = useState(false)

	const [errors, setErrors] = useState({})

	const history = useHistory()

	const classeCategoryId = useMemo(() => tags?.find((category) => category.tag_cat === "classe")?.tag_cat_id, [tags]) // tag_id pour le tag "classe"
	const gammeCategoryId = useMemo(() => tags?.find((category) => category.tag_cat === "gamme")?.tag_cat_id, [tags]) // tag_id pour le tag "gamme"
	const isClass1 = useMemo(
		() => newProduct?.tags?.find((t) => t.tag_cat === classeCategoryId)?.tag_label === "1",
		[newProduct, classeCategoryId]
	) // Si la tag classe 1 est coché

	/********************************
		INITIALIZATION
	*********************************/

	// will probably be deprecated with stock refacto
	const getHiboutikData = async () => {
		try {
			// Taxes
			const taxes = await API_Hiboutik.getAllTaxes()
			setTaxes(taxes)
			setTaxOptions(taxes.map((d) => ({ value: d.tax_id, label: d.tax_name })))

			// Categories
			const categories = await API_Hiboutik.getCategory()
			setCategories(categories.filter((cat) => cat.category_enabled === 1))

			// Tags
			const allProductsTags = await API_Hiboutik.getAllProductTags()
			setTags(allProductsTags)
			const tagsCanaux = allProductsTags.find((t) => t.tag_cat === "nombreCanaux")?.tag_details || []
			setTagsCanaux(tagsCanaux)
			const canauxCategoryId = allProductsTags.find((t) => t.tag_cat === "nombreCanaux").tag_cat_id
			setCanauxTagCategoryId(canauxCategoryId)

			// Brands
			setBrands(await API_Hiboutik.getBrands())

			// Suppliers
			setSuppliers(await API_Hiboutik.getSuppliers())

			// Sizes
			const sizes = (await API_Hiboutik.getAllSizes())?.data
			const declinaisonsOptions = Object.values(sizes)
			/* add the "Sans couleurs" option to all the sizes */
			declinaisonsOptions.unshift({ size_type_id: 0, size_type_name: "Sans couleurs", sizes: {} })
			setDeclinaisons(declinaisonsOptions)
			setSizes(sizes)

			return { sizes }
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	// will probably be deprecated with stock refacto
	const getStockForProduct = async () => {
		try {
			setDeclinaisonStock(await API_Hiboutik.getProductStockAvailable(id))
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	const getProduct = async () => {
		try {
			setIsProductLoading(true)
			const product = await API_Hiboutik.getProductById(id)

			setProduct(product[0])
			setNewProduct(product[0])
			setIsProductLoading(false)
			return product[0]
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	const setDetaultProductData = () => {
		const initialProduct = {
			product_model: "",
			product_category: 1,
			product_vat: 3,
			product_price: "0.00",
			product_sale_price: "0.00",
			product_buy_price: "0.00",
			product_supply_price: "0.00",
			product_brand: 1,
			product_supplier: 1,
			product_stock_management: 0,
			product_size_type: 0,
			tags: [],
		}
		setProduct(initialProduct)
		setNewProduct(initialProduct)
	}

	useEffectAsync(async () => {
		try {
			setLoading(true)
			const { sizes } = await getHiboutikData()

			if (match.params.id) {
				await getStockForProduct()
				const product = await getProduct()
				setSpecificDeclinaisons(Object.values(sizes[product.product_size_type]?.sizes ?? []))
			} else {
				setDetaultProductData()
				setSpecificDeclinaisons([])
				setDeclinaisonStock([])
			}
			setLoading(false)
		} catch (error) {
			console.error(error)
		}
	}, [match.params.id])

	/********************************
		UTILS
	*********************************/

	const getSizeName = (sizeTypeId) => {
		const name = sizes[sizeTypeId]?.size_type_name
		if (!name) {
			return sizeTypeId
		}
		return name.replace(/([A-Z])/g, " $1").replace(/couleur/i, "")
	}

	const getCategory = (categoryId) => {
		return categories.find((cat) => cat.category_id === categoryId)
	}

	const isCategory = (categoryId, categoryName) => {
		const productCategory = getCategory(categoryId)

		return productCategory?.category_name?.includes(categoryName) ?? false
	}

	//Mutations for CRUD methods on the product

	const { mutateAsync: createProduct } = useMutation(
		async () => {
			const product = await API_Hiboutik.createProduct(newProduct)
			await API_Hiboutik.postProductTags(
				product.product_id,
				newProduct.tags.map((tag) => tag.tag_id)
			)
			await clearAllHiboutikCache()
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("PRODUCTS_API")
				toast.success("Le produit " + newProduct.product_model + " a bien été ajouté")
				history.push("/parametres/catalogues/produits")
			},
			onError: () => {
				toast.error("Erreur lors de l'ajout du produit.")
			},
		}
	)

	const { mutateAsync: updateProduct } = useMutation(
		async () => {
			const differences = getProductDifferences(product, newProduct)
			for (const [key, value] of Object.entries(differences)) {
				if (!["class", "product_sale_price", "product_buy_price"].includes(key)) {
					await API_Hiboutik.updateProduct(product.product_id, key, value)
				}
			}
			const tagsToKeep = []
			const tagsToRemove = []
			const tagsToAdd = []
			for (let tag of product.tags) {
				const eq = newProduct.tags.find((t) => t.tag_id === tag.tag_id && t.tag_label === tag.tag_label)
				if (!eq) {
					tagsToRemove.push(tag.tag_id)
				} else {
					tagsToKeep.push(tag.tag_id)
				}
			}
			for (let finalTag of newProduct.tags) {
				if (!tagsToKeep.includes(finalTag.tag_id) && finalTag.tag_id !== -1) tagsToAdd.push(finalTag.tag_id)
			}
			tagsToRemove.forEach(async (t) => {
				await API_Hiboutik.deleteProductTags(newProduct.product_id, t)
			})
			await API_Hiboutik.postProductTags(newProduct.product_id, tagsToAdd)
			await clearAllHiboutikCache()
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("PRODUCTS_API")
				toast.info("Le produit a été modifié avec succés")
				history.push("/parametres/catalogues/produits")
			},
			onError: () => {
				toast.error("Une erreur est survenue lors de la sauvegarde du produit")
			},
		}
	)

	const { mutateAsync: deleteProduct } = useMutation(
		async () => {
			const productModel = match.params.id ? product.product_model : newProduct.product_model
			const hasConfirmed = await confirmWithModal({
				title: "Supprimer",
				text: (
					<p>
						Vous êtes sur le point de <strong className="text-danger">supprimer</strong> le produit{" "}
						{productModel}. Êtes-vous sûr ?
					</p>
				),
			})
			if (!hasConfirmed) return { isCanceled: true }
			await API_Hiboutik.updateProduct(product.product_id, "product_arch", 1)
			await clearAllHiboutikCache()
		},
		{
			onSuccess: async (data) => {
				if (data?.isCanceled) return

				await queryClient.invalidateQueries("PRODUCTS_API")
				toast.success("Le produit a bien été supprimé")
				history.push("/parametres/catalogues/produits")
			},
			onError: () => {
				toast.error("Une erreur est survenue lors de la suppression de produit")
			},
		}
	)

	// Conversion function between HT and TTC
	const convertPrice = useCallback(
		(value, currentType, product_vat) => {
			const appropriateTaxes = taxes.find((t) => t.tax_id === product_vat)
			if (appropriateTaxes) {
				let taxe = parseFloat(appropriateTaxes.tax_value)
				const floatValue = parseFloat(value)
				let newValue = 0
				if (currentType === "ttc") {
					newValue = floatValue / (1 + taxe)
				}
				if (currentType === "ht") {
					newValue = floatValue * (1 + taxe)
				}
				return newValue.toFixed(2)
			}
		},
		[taxes]
	)

	// Vérification du chargement des données et initialisation des éléments calculés
	useEffect(() => {
		if (
			loading &&
			taxes &&
			taxOptions &&
			brands &&
			categories &&
			suppliers &&
			tags &&
			tagsCanaux &&
			declinaisons &&
			declinaisonStock &&
			specificDeclinaisons &&
			product &&
			newProduct &&
			canauxTagCategoryId
		) {
			setLoading(false)
			setProduct({
				...product,
				product_buy_price: convertPrice(product.product_supply_price, "ht", product.product_vat),
				product_sale_price: convertPrice(product.product_price, "ttc", product.product_vat),
			})
			setNewProduct({
				...newProduct,
				product_buy_price: convertPrice(newProduct.product_supply_price, "ht", newProduct.product_vat),
				product_sale_price: convertPrice(newProduct.product_price, "ttc", newProduct.product_vat),
			})
		}
	}, [
		loading,
		taxes,
		taxOptions,
		brands,
		categories,
		suppliers,
		tags,
		tagsCanaux,
		declinaisons,
		declinaisonStock,
		specificDeclinaisons,
		product,
		newProduct,
		canauxTagCategoryId,
		convertPrice,
	])

	// Vérification de la différence avec le produit enregistré
	useEffect(() => {
		if (JSON.stringify(product) !== JSON.stringify(newProduct)) {
			setModified(true)
		} else {
			setModified(false)
		}
	}, [product, newProduct])

	// Change handler for product fields
	const handleChange = (e, checked, switchName) => {
		// handler for fileds controlled with a Switch
		if (typeof checked === "boolean") {
			setNewProduct({
				...newProduct,
				[switchName]: checked ? 1 : 0,
			})
			return
		}

		//handler for other product fields
		const { value, name } = e.target
		switch (name) {
			case "product_buy_price":
				setNewProduct({
					...newProduct,
					product_supply_price: convertPrice(value, "ttc", newProduct.product_vat),
					[name]: value,
				})
				break
			case "product_sale_price":
				setNewProduct({
					...newProduct,
					product_price: convertPrice(value, "ht", newProduct.product_vat),
					[name]: value,
				})
				break
			case "product_price":
				setNewProduct({
					...newProduct,
					product_sale_price: convertPrice(value, "ttc", newProduct.product_vat),
					[name]: value,
				})
				break
			case "product_supply_price":
				setNewProduct({
					...newProduct,
					product_buy_price: convertPrice(value, "ht", newProduct.product_vat),
					[name]: value,
				})
				break
			case "product_vat":
				const newVat = parseInt(value)
				setNewProduct({
					...newProduct,
					[name]: newVat,
					product_buy_price: convertPrice(newProduct.product_supply_price, "ht", newVat),
					product_sale_price: convertPrice(newProduct.product_price, "ttc", newVat),
				})
				break
			case "product_category":
				const newCategory = parseInt(value)
				setNewProduct({
					...newProduct,
					product_category: newCategory,
					product_size_type: isCategory(newCategory, "Appareils auditifs") ? 1 : 0,
					product_price: 0,
					product_supply_price: 0,
				})
				break
			case "product_brand":
			case "product_supplier":
				setNewProduct({
					...newProduct,
					[name]: parseInt(value),
				})
				break
			case "product_size_type":
				const num = parseInt(value)
				setNewProduct({
					...newProduct,
					[name]: num,
				})
				break
			case "nombreCanaux":
				const tagId = parseInt(value)
				const otherTags = newProduct.tags.filter((t) => t.tag_cat !== canauxTagCategoryId)
				const newTagName = tagsCanaux.find((t) => t.tag_id === tagId)?.tag
				setNewProduct({
					...newProduct,
					tags: newTagName
						? [...otherTags, { tag_cat: canauxTagCategoryId, tag_id: tagId, tag_label: newTagName }]
						: [...otherTags],
				})
				break
			default:
				setNewProduct({
					...newProduct,
					[name]: value,
				})
		}
	}

	const formatField = (e) => {
		const { name, value } = e.target

		switch (name) {
			case "product_buy_price":
				setNewProduct({
					...newProduct,
					product_supply_price: convertPrice(value, "ttc", newProduct.product_vat),
					[name]: Number(value).toFixed(2),
				})
				break
			case "product_sale_price":
				setNewProduct({
					...newProduct,
					product_price: convertPrice(value, "ht", newProduct.product_vat),
					[name]: Number(value).toFixed(2),
				})
				break
			case "product_price":
				setNewProduct({
					...newProduct,
					product_sale_price: convertPrice(value, "ttc", newProduct.product_vat),
					[name]: Number(value).toFixed(2),
				})
				break
			case "product_supply_price":
				setNewProduct({
					...newProduct,
					product_buy_price: convertPrice(value, "ht", newProduct.product_vat),
					[name]: Number(value).toFixed(2),
				})
				break
			case "product_desc":
				if (value.length === 0) {
					return
				}

				;(async () => {
					try {
						const res = await axios.post(REMOTE_FILE_CHECK_API, { url: value })
						if (!res.data.valid) {
							setErrors((prev) => ({
								...prev,
								product_desc: "Veuillez entrer un lien valide (https://exemple.com/exemple.pdf)",
							}))
						}
					} catch (err) {
						console.error(err)
					}
				})()
				break
			default:
				break
		}
	}

	// Change handler for product tags other than "nombreCanaux"
	const handleTagChange = (category, tag) => {
		let otherCategoriesTags = newProduct.tags.filter((t) => t.tag_cat !== category.tag_cat_id)
		const newTag = { tag_cat: category.tag_cat_id, tag_id: tag.tag_id, tag_label: tag.tag }
		if (category.tag_details.length === 1 && newProduct.tags.find((t) => t.tag_cat === category.tag_cat_id)) {
			setNewProduct({ ...newProduct, tags: [...otherCategoriesTags] })
		} else {
			// Si classe 1, supprimer le tag gamme
			if (isClass1 && gammeCategoryId) {
				otherCategoriesTags = otherCategoriesTags.filter((t) => t.tag_cat_id !== gammeCategoryId)
			}

			setNewProduct({
				...newProduct,
				tags: [...otherCategoriesTags, newTag],
			})
		}
	}

	// Actions
	const save = async () => {
		const hasConfirmed = await confirmWithModal({
			title: "Enregistrer",
			text: (
				<>
					<p>{`Vous êtes sur le point de ${match.params.id ? "modifier" : "créer"} le produit "${
						match.params.id ? product.product_model : newProduct.product_model
					}".`}</p>
					<p>
						<strong>{newProduct.product_stock_management ? "Géré en stock" : "Non géré en stock"}</strong>
					</p>
					<p>
						<strong>{taxes.find((t) => newProduct.product_vat === t.tax_id).tax_name}</strong>
					</p>
				</>
			),
		})
		if (!hasConfirmed) return

		if (!newProduct.product_model || newProduct.product_model.trim().length === 0) {
			toast.error("Merci de renseigner le nom du produit")
			return
		}

		if (match.params.id) {
			try {
				updateProduct()
			} catch (e) {
				console.error(e)
			}
		} else {
			const typeTagIds = tags.find((category) => category.tag_cat === "type").tag_details.map((tag) => tag.tag_id)
			const energyTagIds = tags
				.find((category) => category.tag_cat === "energy")
				.tag_details.map((tag) => tag.tag_id)
			const classeTagIds = tags
				.find((category) => category.tag_cat === "classe")
				.tag_details.map((tag) => tag.tag_id)

			delete newProduct.product_buy_price
			delete newProduct.product_sale_price
			const category = getCategory(newProduct.product_category)
			const categoryToCompare = _.deburr(category.category_name.toLowerCase())
			if (categoryToCompare !== "appareils auditifs" && categoryToCompare !== "accessoire") {
				newProduct.tags = []
				delete newProduct.product_desc
			} else if (categoryToCompare === "appareils auditifs") {
				if (!newProduct.tags.find((tag) => typeTagIds.includes(tag.tag_id))) {
					toast.error("Merci de choisir un type dans les options par défaut")
					return
				} else if (!newProduct.tags.find((tag) => energyTagIds.includes(tag.tag_id))) {
					toast.error("Merci de choisir une alimentation dans les options par défaut")
					return
				} else if (!newProduct.tags.find((tag) => classeTagIds.includes(tag.tag_id))) {
					toast.error("Merci de choisir une classe dans les options par défaut")
					return
				}
			}
			try {
				await createProduct()
			} catch (e) {
				console.error(e)
			}
		}
	}

	const reinit = async () => {
		const hasConfirmed = await confirmWithModal({
			title: "Réinitialiser",
			text: "Vous etes sur le point de réinitialiser le produit à son dernier état sauvegardé. Êtes-vous sûr ?",
		})
		if (!hasConfirmed) return

		setNewProduct(product)
		toast.warning("Les modifications on été annulées avec succes")
	}

	const cancel = async () => {
		const hasConfirmed = await confirmWithModal({
			title: "Annuler",
			text: `Vous êtes sur le point d'annuler la ${
				match.params.id ? "modification" : "création"
			} du produit et de revenir sur la page catalogue. Êtes-vous sûr ?`,
		})
		if (!hasConfirmed) return

		history.push("/parametres/catalogues/produits")
	}

	/** Archive le produit sur hiboutik. Agit comme une sorte de suppression. */

	const [title, setTitle] = useState()

	useEffect(() => {
		if (loading) setTitle("Chargement ...")
		if (id) setTitle(`Modification du produit ${product.product_model ?? "..."}`)
		else setTitle("Ajout d'un nouveau produit")
	}, [product])

	return (
		<>
			{!loading || !productLoading ? (
				<>
					<h2>{title}</h2>
					<div className="product">
						<div className="col-sm-12">
							<h4>Informations du produit</h4>
						</div>
						<div className="row align-items-baseline">
							<div className="col-sm-12">
								<Switch
									checked={newProduct.product_stock_management === 1}
									onClick={(checked, e) => handleChange(e, checked, "product_stock_management")}
									checkedChildren={"Produit géré en stock"}
									unCheckedChildren={"Produit non géré en stock"}
								/>
							</div>
							<div className="col-sm-6">
								<FieldWithError
									name="product_model"
									label="Nom du produit"
									required
									value={newProduct.product_model}
									dataCy={id ? "d-nom-produit-input" : "nom-produit-input"}
									onChange={handleChange}
									className="m-0"
								/>
							</div>
							<div className="col-sm-6">
								{id ? (
									<FieldWithError
										name="product_category"
										label="Catégorie de produit"
										value={
											categories.find((cat) => cat.category_id === newProduct.product_category)
												?.category_name
										}
										dataCy={id ? "d-type-produit-input" : "type-produit-input"}
										onChange={handleChange}
										required
										isDisabled
										className="m-0"
									/>
								) : (
									<Select
										value={newProduct.product_category}
										onChange={handleChange}
										name="product_category"
										label="Catégorie"
										children={categories.map((cat, i) => (
											<option key={"new-product-category-" + i} value={cat.category_id}>
												{cat.category_name}
											</option>
										))}
									/>
								)}
							</div>
							<div className="col-sm-12">
								<Select
									name="product_vat"
									label="TVA"
									value={newProduct.product_vat}
									onChange={handleChange}
									children={taxOptions.map((opt, i) => (
										<option key={`productTva-${i}`} value={opt.value}>
											{opt.label}
										</option>
									))}
								/>
							</div>
							<div className="col-sm-6">
								<FieldWithError
									name="product_supply_price"
									label="Prix d'achat HT"
									type="number"
									min="0"
									onBlur={formatField}
									value={newProduct.product_supply_price}
									dataCy={id ? "d-prix-ttc-produit-input" : "prix-ttc-produit-input"}
									onChange={handleChange}
									required
									className="m-0"
								/>
							</div>
							<div className="col-sm-6">
								<FieldWithError
									name="product_buy_price"
									label="Prix d'achat TTC"
									type="number"
									min="0"
									onBlur={formatField}
									value={newProduct.product_buy_price}
									dataCy={id ? "d-prix-ht-produit-input" : "nom-prix-ht-produit-input"}
									onChange={handleChange}
									required
									className="m-0"
								/>
							</div>
							<div className="col-sm-6">
								<FieldWithError
									name="product_sale_price"
									label="Prix de vente HT"
									type="number"
									min="0"
									onBlur={formatField}
									value={newProduct.product_sale_price}
									dataCy={id ? "d-prix-ttc-produit-input" : "prix-ttc-produit-input"}
									onChange={handleChange}
									required
									className="m-0"
								/>
							</div>
							<div className="col-sm-6">
								<FieldWithError
									name="product_price"
									label="Prix de vente TTC"
									type="number"
									min="0"
									onBlur={formatField}
									value={newProduct.product_price}
									dataCy={id ? "d-prix-ht-produit-input" : "nom-prix-ht-produit-input"}
									onChange={handleChange}
									required
									className="m-0"
								/>
							</div>
							{newProduct.product_category === 9 ? null : (
								<>
									<div className="col-sm-6">
										<Select
											label="Marque"
											name="product_brand"
											value={newProduct.product_category === 9 ? "-" : newProduct.product_brand}
											children={brands.map((brand, i) => (
												<option
													key={`${id ? "" : "new-"}product-brand${i}`}
													value={brand.brand_id}>
													{brand.brand_name}
												</option>
											))}
											onChange={handleChange}
										/>
									</div>
									<div className="col-sm-6">
										<Select
											label="Fournisseur"
											name="product_supplier"
											value={newProduct.product_supplier}
											children={suppliers.map((supplier, i) => (
												<option
													key={`${!id ? "new-" : ""}product-supplier${i}`}
													value={supplier.supplier_id}>
													{supplier.supplier_name}
												</option>
											))}
											onChange={handleChange}
										/>
									</div>
								</>
							)}
						</div>
						{isCategory(newProduct.product_category, "Appareils auditifs") && (
							<>
								<div className="col-sm-12 mt-3">
									<h4>Couleurs</h4>
								</div>
								<div className="row align-items-baseline">
									{id ? (
										(specificDeclinaisons ?? []).map((declinaison) => {
											const stock = declinaisonStock.find(
												(stock) => stock.product_size === declinaison.size_id
											)
											return (
												<div className="col-sm-12" key={declinaison.size_id}>
													<span>
														<label htmlFor={declinaison.d_label}>
															{`${declinaison.size_name} `}
															<span style={{ position: "absolute", right: 0 }}>
																{stock?.stock_available > 0
																	? `${stock.stock_available} unités disponibles`
																	: "non disponible"}
															</span>
														</label>
													</span>
												</div>
											)
										})
									) : (
										<>
											<div className="col-sm-12">
												<Select
													name="product_size_type"
													onChange={handleChange}
													children={declinaisons.map((declinaison, i) => (
														<option
															key={`new-declinaison-${i}`}
															value={declinaison.size_type_id}>
															{getSizeName(declinaison.size_type_id) ||
																declinaison.size_type_name}
														</option>
													))}
													value={newProduct.product_size_type}
												/>
											</div>
										</>
									)}
								</div>
								<div className="row align-items-baseline">
									<div className="col-sm-12 mt-3">
										<h4>Options par défaut</h4>
									</div>
									{tags
										.filter(
											(tagCategory) =>
												tagCategory.tag_cat !== "nombreCanaux" &&
												tagCategory.tag_details.length > 1
										)
										.map((tagCategory) => {
											return (
												<div key={tagCategory.tag_cat}>
													<label className="w-100 m-3">
														{tagCategory.tag_cat !== "nombreCanaux" &&
															tagNames[tagCategory.tag_cat]}
													</label>
													<div className="col-sm-12 d-flex flex-wrap">
														{(tagCategory.tag_details || []).map((tag) => {
															const active = newProduct.tags.some(
																(t) => t.tag_label === tag.tag
															)
															return (
																<button
																	key={tag.tag_id}
																	className={cx("m-2 btn btn-outline-primary", {
																		active: active,
																	})}
																	onClick={() => handleTagChange(tagCategory, tag)}
																	disabled={
																		isClass1 &&
																		tagCategory.tag_cat_id === gammeCategoryId
																	}>
																	{tag.tag}
																</button>
															)
														})}
													</div>
												</div>
											)
										})}
								</div>
								<div className="row align-items-baseline">
									<div className="col-sm-12 mt-3">
										<h4>Caractéristiques</h4>
									</div>
								</div>
								<div className="row align-items-baseline" id="caracteristiques-content">
									<div className="col-sm-12">
										<Select
											name="nombreCanaux"
											label="Nombre de canaux"
											value={
												newProduct.tags.find((t) =>
													tagsCanaux.find((tag) => tag.tag_id === t.tag_id)
												)?.tag_id || -1
											}
											onChange={handleChange}
											children={[...tagsCanaux, { tag: "Non renseigné", tag_id: -1 }].map(
												(tag) => (
													<option key={`nombre-de-canaux-${tag.tag_id}`} value={tag.tag_id}>
														{tag.tag}
													</option>
												)
											)}
										/>
									</div>
									<div className="col-sm-12">
										{tags
											.filter(
												(tagCategory) =>
													tagCategory.tag_details.length === 1 &&
													tagCategory.tag_cat !== "tv" &&
													tagCategory.tag_cat !== "chargeur"
											)
											.map((tagCategory) => {
												const active = newProduct.tags.some(
													(t) => t.tag_cat === tagCategory.tag_cat_id
												)
												const tag = tagCategory.tag_details[0]
												if (
													tagCategory.tag_cat !== "tv" &&
													tagCategory.tag_cat !== "chargeur"
												) {
													return (
														<button
															key={tagCategory.tag_cat_id}
															className={cx("m-2 btn btn-outline-primary", {
																active: active,
															})}
															onClick={() => handleTagChange(tagCategory, tag)}>
															{tagNames[tagCategory.tag_cat] ?? tagCategory.tag_cat}
														</button>
													)
												} else return null
											})}
									</div>
								</div>
								<div className="row align-items-baseline">
									<div className="col-sm-12 mt-3">
										<h4>Fiche technique</h4>
									</div>
								</div>
								<div className="col-sm-12">
									<FieldWithError
										name="product_desc"
										type="url"
										error={errors.product_desc}
										setError={(e) => setErrors((prev) => ({ ...prev, product_desc: e }))}
										placeholder="https://marqueaudio/produits/model548665/fiche-technique.pdf"
										label="URL de la fiche technique"
										value={newProduct.product_desc}
										dataCy={id ? "d-fiche-technique-produit-input" : "nom-prix-ht-produit-input"}
										onChange={handleChange}
										onBlur={formatField}
										className="m-0"
									/>
								</div>
							</>
						)}
						{isCategory(newProduct.product_category, "Accessoire") && (
							<>
								<div className="row align-items-baseline">
									<div className="col-sm-12 mt-3">
										<h4>Caractéristiques</h4>
									</div>
								</div>
								<div className="col-sm-12 mt-3 mb-4">
									{tags
										.filter(
											(tagCategory) =>
												tagCategory.tag_details.length === 1 &&
												(tagCategory.tag_cat === "tv" || tagCategory.tag_cat === "chargeur")
										)
										.map((tagCategory) => {
											const active = newProduct.tags.some(
												(t) => t.tag_cat === tagCategory.tag_cat_id
											)
											const tag = tagCategory.tag_details[0]
											if (tagCategory.tag_cat === "tv" || tagCategory.tag_cat === "chargeur") {
												return (
													<button
														key={tagCategory.tag_cat_id}
														className={cx("m-2 btn btn-outline-primary", {
															active: active,
														})}
														onClick={() => handleTagChange(tagCategory, tag)}>
														{tagNames[tagCategory.tag_cat] ?? tagCategory.tag_cat}
													</button>
												)
											} else return null
										})}
								</div>
							</>
						)}
						<div className="row align-items-baseline justify-content-stretch mt-3">
							<div className="col-3">
								<Button className="btn btn-danger w-100" onClick={deleteProduct}>
									<i className="fad fa-trash-alt" /> Supprimer
								</Button>
							</div>
							<div className="col-3">
								<Button className="btn btn-outline-danger w-100" onClick={cancel}>
									Retour
								</Button>
							</div>
							<div className="col-3">
								<Button disabled={!modified} className="btn btn-outline-warning w-100" onClick={reinit}>
									Réinitialiser
								</Button>
							</div>
							<div className="col-3">
								<Button disabled={!modified} className="btn btn-outline-primary w-100" onClick={save}>
									Enregistrer
								</Button>
							</div>
						</div>
					</div>
				</>
			) : (
				<div>{/* TODO : spinner chargement */}</div>
			)}
		</>
	)
}

export default Product
