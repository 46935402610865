import { Select } from "antd"
import { useMemo } from "react"
import { partialSearch } from "services/functions"
import { CustomSelectDropDown } from "../Utils"

function OriginSelect({
	record,
	originsInitial,
	handlePatientChange,
	setOriginDetailsInitial,
	setOriginDetails,
	setOrigin,
	isManager,
	origin,
}) {
	const allOrigins = useMemo(() => [...(originsInitial ?? []), ...origin], [originsInitial, origin])

	return (
		<Select
			showSearch
			allowClear
			style={{ width: "100%", minWidth: "120px" }}
			placeholder="Sélectionner source"
			value={record["patient.origin"]?.name}
			dropdownRender={(body) => (
				<CustomSelectDropDown
					isManager={isManager}
					handleDropDownClick={(name) => {
						if (!origin.some((item) => item.name === name)) {
							setOrigin([...origin, { name }])
							handlePatientChange({ name }, record, "patient.origin")
							handlePatientChange(null, record, "patient.originDetail")
							setOriginDetailsInitial([])
						}
					}}>
					{body}
				</CustomSelectDropDown>
			)}
			onChange={(value) => {
				if (value == null) {
					handlePatientChange(null, record, "patient.origin")
					handlePatientChange(null, record, "patient.originDetail")

					setOriginDetailsInitial([])
				} else {
					const origin = allOrigins.find((o) => o.id === value)
					handlePatientChange(origin, record, "patient.origin")
					handlePatientChange(null, record, "patient.originDetail")

					setOriginDetailsInitial(origin.patientOriginDetails ?? [])
				}

				setOriginDetails([])
			}}
			filterOption={(input, option) => partialSearch(option.label, input)}
			options={allOrigins.map((o) => ({
				key: o.id,
				label: o.name,
				value: o.id,
			}))}
		/>
	)
}

const OriginCol = (
	originsInitial,
	handlePatientChange,
	editingKey,
	setOriginDetailsInitial,
	setOriginDetails,
	setOrigin,
	queryfilters,
	isManager,
	origin
) => {
	const OriginRender = (currentOrigin, record) => {
		return editingKey === record.key ? (
			<OriginSelect
				record={record}
				originsInitial={originsInitial}
				handlePatientChange={handlePatientChange}
				setOriginDetailsInitial={setOriginDetailsInitial}
				setOriginDetails={setOriginDetails}
				setOrigin={setOrigin}
				isManager={isManager}
				origin={origin}
			/>
		) : (
			<p style={{ textAlign: "center", margin: 0 }}>{currentOrigin?.name}</p>
		)
	}
	return {
		title: null,
		key: "origin",
		dataIndex: "patient.origin",
		with: "100px",
		render: OriginRender,
		filters: originsInitial?.map((origin) => ({
			key: origin.id,
			text: origin.name,
			value: origin.name,
		})),
		filteredValue: queryfilters?.origin || null,
		filterSearch: true,
		validate: (cond) =>
			!cond["patient.origin"] || cond["patient.origin"] === ""
				? "Vous n'avez choisi pas d'origine."
				: "",
	}
}

export default OriginCol
