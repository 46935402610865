/**
 * Remove undefined, null and empty arrays,
 * it will be hidden when false
 * @param el
 */
export default function filterBlankOrUndef<T>(el: T) {
	const primary = Boolean(el)
	if (!primary) {
		return primary
	}

	if (Array.isArray(el)) {
		return el.length > 0
	}

	return true
}
