/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import { useEffect, useState, Fragment, useMemo } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Input } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { useDropzone } from "react-dropzone"
import Select from "react-select"
import { toast } from "react-toastify"
import Line from "../../components/utils/Line"
import { sendDocument } from "../../pages/FSV/functionsFSV"
import API from "../../services/API"
import { downloadFromUrl } from "../../services/Utilities"
import axios from "axios"
import { REMOTE_FILE_API } from "../../config"
import uuid from "react-uuid"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { modeTeletrans } from "pages/FSV/FSV.type"

const TeletransAttachment = ({
	isOpen,
	setIsOpen,
	onClose,
	confirmText,
	patient,
	laboratory,
	tmpTeletransData,
	setTmpTeletransData,
}) => {
	const [facturationType, setFacturationType] = useState(modeTeletrans.securise)
	const [availableDocuments, setAvailableDocuments] = useState([])
	const [requiredDocuments, setRequiredDocuments] = useState([])
	const [documentList, setDocumentList] = useState([])
	const [gedDocumentsId, setGedDocumentsId] = useState([])
	const [busy, setBusy] = useState(false)
	const [loading, setLoading] = useState(false)

	const [facturationModes, setFacturationModes] = useState([
		{ value: modeTeletrans.securise, label: "Sesam Vitale", pj: [{ value: "ORDONNANCE", label: "Ordonnance" }] },
		{
			value: modeTeletrans.degrade,
			label: "Dégradé",
			pj: [
				{ value: "ORDONNANCE", label: "Ordonnance" },
				{ value: "FEUILLE_SOIN", label: "Feuille de soins" },
			],
		},
	])

	useMemo(() => {
		if (patient.patientMedicals.some((med) => med.label === "CECITE")) {
			let facturationModesCopy = facturationModes
			for (const mode of facturationModesCopy) {
				mode.pj = [...mode.pj, { value: "OTHER", label: "Ordonnance cécité" }]
			}
			setFacturationModes(facturationModesCopy)
		}
	}, [patient.patientMedicals])

	const hasDoubleTypes = requiredDocuments
		.map((v) => documentList.filter((f) => f.type === v.value).length)
		.some((e) => e >= 2)

	const importConvertedFile = async (file) => {
		const reader = new FileReader()
		const name = file.name.replace(/[^a-zA-Z0-9-._]/g, "")
		const filename = name.split(".")[0]
		const label = file.label || filename

		const isUnsupportedFormat = (document) => {
			if (document.type && document.type !== "application/pdf") {
				toast.warn(`L'extension ".${document.type}" n'est pas supportée.`)
			} else if (document.name && !document.type) {
				toast.warn("Vérifiez le format de ce fichier, format non reconnu.")
			} else {
				return true
			}
		}

		if (isUnsupportedFormat(file)) {
			reader.onabort = () => console.error("file reading was aborted")
			reader.onerror = () => console.error("file reading has failed")
			reader.onload = () => {
				const binary = reader.result
				setDocumentList((old) => [
					...old,
					{
						uid: uuid(),
						binary,
						extension: "pdf",
						filename,
						label,
						patient: patient["@id"],
						type: requiredDocuments.find((f) => ![...documentList.map((v) => v.type)].includes(f.value))
							?.value,
						file,
					},
				])
			}
			reader.readAsDataURL(file)
		}
	}

	useEffect(() => {
		if (!isOpen) return
		setDocumentList(tmpTeletransData.documentList || [])
	}, [tmpTeletransData.documentList, isOpen])

	const confirm = async () => {
		if (documentList.length && requiredDocuments.length !== documentList.length) {
			const facturationMode = facturationModes.filter((mode) => mode.value === facturationType)[0].label
			toast.error(
				`${requiredDocuments.length} documents sont nécessaires à la télétransmission en mode ${facturationMode}`
			)
			return
		}
		let lastDocumentTried = null

		try {
			setLoading(true)
			if (!documentList.length)
				setTmpTeletransData({ ...tmpTeletransData, modeSecurisation: modeTeletrans.securise })
			for (const doc of documentList) {
				if ((tmpTeletransData?.documentList || [])?.find((f) => f.uid === doc._uid)) continue
				lastDocumentTried = doc
				await sendDocument(laboratory, doc, patient, setGedDocumentsId)
			}
			let toDelete = (tmpTeletransData?.documentList || [])
				.map((v) => v._uid)
				.filter((f) => {
					return !documentList.find((dlf) => dlf.uid === f)
				})
			if (toDelete.length) {
				tmpTeletransData.documentList = tmpTeletransData?.documentList.filter((f) => !toDelete.includes(f._uid))
			}

			setLoading(false)
			setIsOpen(false)
		} catch (error) {
			toast.error("Impossible d'envoyer le document " + lastDocumentTried?.filename)
			setLoading(false)
			console.error(error)
		}
	}

	useEffectAsync(async () => {
		try {
			setBusy(true)
			const res = await API.findAll(
				"DOCUMENTS_API",
				`?patient=${patient.id}&type[]=ORDONNANCE&type[]=FEUILLE_SOIN&pagination=false`
			)
			setAvailableDocuments(res)
		} catch (error) {
		} finally {
			setBusy(false)
		}
	}, [])

	useEffect(() => {
		if (gedDocumentsId.length === 0) return
		setTmpTeletransData({ ...tmpTeletransData, documentList: gedDocumentsId })
	}, [gedDocumentsId])

	const onDropMethod = (acceptedFiles) => {
		for (const i in acceptedFiles) {
			importConvertedFile(acceptedFiles[i])
			if (i >= documentList.length >= (facturationType === modeTeletrans.degrade ? 3 : 2)) break
		}
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles) => {
			if (acceptedFiles) onDropMethod(acceptedFiles)
		},
	})

	useEffect(() => {
		setTmpTeletransData({ ...tmpTeletransData, modeSecurisation: facturationType })
		setRequiredDocuments(facturationModes.find((mode) => mode.value === facturationType)?.pj)
	}, [facturationType])

	const handleChange = (value, key, index) => {
		let tmpList = [...documentList]
		tmpList?.[index]?.hasOwnProperty(key) && (tmpList[index][key] = value)
		setDocumentList(tmpList)
	}

	const dragAndDrop = () => (
		<div className="col-12">
			<div {...getRootProps()} className={"dropzone"}>
				<input
					name="file"
					type="file"
					{...getInputProps()}
					disabled={documentList.length >= (facturationType === modeTeletrans.degrade ? 3 : 2)}
				/>
				{isDragActive ? (
					<p>Relacher ici</p>
				) : (
					<p className="text-center">
						Glisser-déposer un fichier ou cliquer ici
						<br />
						Formats supportés : .pdf
					</p>
				)}
			</div>
		</div>
	)

	const getBase64FromExistingDocument = async (document) => {
		if (document?.extension !== "pdf") {
			toast.error(
				`Le document est en format .${document?.extension}, le format .pdf est requis pour la sécurité sociale.`
			)
			return
		}
		const docData = await API.find("DOCUMENTS_API", document.id)
		setBusy(true)
		if (docData) {
			try {
				const result = await axios.post(
					REMOTE_FILE_API,
					{
						url: docData.signature,
					},
					{ responseType: "arraybuffer" }
				)

				const reader = new FileReader()
				reader.readAsDataURL(new Blob([result.data], { type: "application/pdf" }))
				reader.onload = () => {
					const binary = reader.result

					setDocumentList((old) => [
						...old,
						{
							uid: uuid(),
							binary,
							extension: "pdf",
							filename: docData.filename,
							label: docData.label,
							patient: patient["@id"],
							type: requiredDocuments.find((f) => ![...documentList.map((v) => v.type)].includes(f.value))
								?.value,
						},
					])
				}
			} catch (error) {
				console.error(error)
			}
		}
		setBusy(false)
	}

	const existingDocuments = (type) => {
		if (requiredDocuments.length === 0) return
		if (!availableDocuments.length === 0) return
		const fileLimit = documentList.length >= (facturationType === modeTeletrans.degrade ? 3 : 2)

		const res = availableDocuments?.filter((doc) => type.value === doc.type)
		return res.map((doc) => (
			<Line
				type={doc?.type}
				numero={doc.numero}
				key={doc.id}
				text={
					<span className="cursor-pointer" style={{ opacity: fileLimit ? 0.5 : 1 }}>
						{doc.label}
					</span>
				}
				editable
				onEditButtonClick={async () => {
					if (fileLimit) return

					let tmpRequired = [...requiredDocuments]
					let tmpFiltered = tmpRequired.find((tmp) => tmp.value === doc.type)
					tmpFiltered.set = true
					setRequiredDocuments(tmpRequired)
					await getBase64FromExistingDocument(doc)
				}}
				iconButtonClick2={async () => {
					if (fileLimit) return

					let tmpRequired = [...requiredDocuments]
					let tmpFiltered = tmpRequired.find((tmp) => tmp.value === doc.type)
					tmpFiltered.set = true
					setRequiredDocuments(tmpRequired)
					const docData = await API.find("DOCUMENTS_API", doc.id)
					downloadFromUrl(docData.signature)
				}}
				iconButton2Show={true}
				icon="file-pdf"
				iconButton={documentList.includes(doc) ? "check-circle" : "plus"}
				iconButtonColor={documentList.includes(doc) ? "danger" : "primary"}
				iconButton2={documentList.includes(doc) ? "check-circle" : "arrow-down"}
				iconButtonColor2={documentList.includes(doc) ? "danger" : "primary"}
			/>
		))
	}

	return (
		<Modal
			isOpen={isOpen}
			className="modal-div"
			centered
			size={
				availableDocuments?.filter((value) => value.type === "FEUILLE_SOIN" || value.type === "ORDONNANCE")
					.length
					? "lg"
					: "s"
			}
			backdrop="static"
			onCancel={() => onClose()}>
			<ModalHeader>
				<div className="modal-custom-header">
					<div className="modal-custom-header">Ajout des pièces jointes</div>
					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 mr-3 d-flex justify-content-center align-items-center close-icon"
						aria-label="Close"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				{loading ? (
					<div className="overlay-loading-aw" style={{ left: "0" }}>
						<div className="overlay-loading-logo" />
						<div className="overlay-loading-text">Ajout des pièces jointes...</div>
					</div>
				) : (
					<div>
						<div className="row col-sm-12 align-items-baseline">
							<div className="col-sm-5">
								<p>Je facture en mode</p>
							</div>
							<div className="col-sm-7">
								<Select
									options={facturationModes}
									defaultValue={facturationModes.filter((mode) => mode.value === facturationType)}
									name={"mode-billing"}
									onChange={(event) => {
										setFacturationType(event.value)
									}}
								/>
							</div>
						</div>

						<div>
							{requiredDocuments?.every((doc) => doc.set) ? (
								<div className="alert alert-primary alert-dismissible fade show" role="alert">
									Toutes les pièces jointes nécessaires sont renseignées
								</div>
							) : (
								<div className="alert alert-warning alert-dismissible fade show" role="alert">
									Pièces jointes nécessaires à la demande de remboursement:
									<p>
										{requiredDocuments?.map(
											(doc, i) =>
												!doc.set &&
												`${doc.label}${i !== requiredDocuments.length - 1 ? ", " : ""}`
										)}
									</p>
								</div>
							)}

							{hasDoubleTypes && (
								<div
									className="alert alert-danger alert-dismissible fade show font-weight-bold"
									role="alert">
									Attention, vous avez des documents en doublons, vous devez avoir un document par
									type unique.
								</div>
							)}

							<div className="col-sm-12">
								<div className="row">
									<div className="col">{dragAndDrop()}</div>
									{availableDocuments?.filter(
										(value) => value.type === "FEUILLE_SOIN" || value.type === "ORDONNANCE"
									).length > 0 && (
											<div className="col-5">
												<div>
													Documents de {patient.firstName} {patient.lastName}
													<div>
														{requiredDocuments &&
															requiredDocuments.map((type) => {
																return availableDocuments?.filter(
																	(value) => value.type === type.value
																).length > 0 ? (
																	<Fragment key={type.label}>
																		<h6>{type.label}</h6>
																		{existingDocuments(type)}
																	</Fragment>
																) : null
															})}
													</div>
												</div>
											</div>
										)}
								</div>
								<div>
									<div className="row">
										<div className="col">
											{documentList.map((document, key) => (
												<div className="row col align-items-baseline mb-2" key={key}>
													<div className="col-sm-5">
														<Input
															size="large"
															value={document?.label}
															onChange={(e) => handleChange(e.target.value, "label", key)}
															disabled={document.added}
														/>
													</div>
													<div className="col-sm-5">
														<Select
															options={requiredDocuments.sort((a, b) =>
																a.value.localeCompare(b.value)
															)}
															value={requiredDocuments.find(
																(doc) => doc.value === document?.type
															)}
															name={`select-type-${document?.type}`}
															onChange={(e) => handleChange(e.value, "type", key)}
															isDisabled={document.added}
														/>
													</div>
													<div className="col-sm-2 d-flex">
														<button
															className="btn btn-outline-danger btn-sm"
															type="button"
															onClick={() => {
																const tmp = [...documentList]
																const id = tmp.findIndex((f) => f?.uid === document.uid)
																let tmpRequired = [...requiredDocuments]
																let tmpFiltered = tmpRequired.find(
																	(tmp) => tmp.value === document.type
																)
																if (tmpFiltered) tmpFiltered.set = false
																id !== -1 && tmp.splice(id, 1)
																setDocumentList(tmp)
																setTmpTeletransData({
																	...tmpTeletransData,
																	documentList: tmp,
																})
															}}>
															<CloseOutlined className="danger" />
														</button>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<div>
					<button
						type="button"
						className="btn btn-block btn-outline-primary"
						onClick={() => confirm()}
						disabled={hasDoubleTypes || loading || busy}>
						{documentList.length ? confirmText : "Fermer"}
					</button>
				</div>
			</ModalFooter>
		</Modal>
	)
}

export default TeletransAttachment
