import { Dispatch, useEffect, useState } from "react"

/** Loads and saves state in localStorage.
 * Note: Will save undefined as null
 */
export default function useSavedState<T>(key: string, defaultValue: T): [T, Dispatch<T>] {
	const [value, setValue] = useState(() => {
		const saved = localStorage.getItem(key)
		if (saved != null && saved !== "") return JSON.parse(saved)

		return defaultValue
	})

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value ?? null)) // "undefined" is not valid JSON, so turn undefined into null
	}, [key, value])

	return [value, setValue]
}
