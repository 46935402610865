/** Resource représente les User. Il existe un filter par défaut qui a été customisé ici, il est utilisé dans la configuration générale.
 * Dans le fichier BryntumAgendaConfig
 */
import { User } from "@audiowizard/common"
import { ResourceFilter, StringHelper } from "@bryntum/calendar"
import { getIdFromIri } from "services/functions"
import { AWCalendar, Resource } from "./AgendaTypes"
import { setStartTimeAndWorkingDays } from "./BryntumAgenda.helper"
export class MyResourceFilter extends ResourceFilter {
	resourceFilter: any
	eventStore: any
	static get $name(): string {
		return "MyResourceFilter"
	}

	static get type(): string {
		return "myresourcefilter"
	}

	static get configurable(): any {
		return {
			listeners: {
				onchange(data: any) {
					for (const key in data.source.element.children) {
						const item = data.source.element.children[key]
						if (typeof item === "object" && item.role === "option") {
							data.source.element.children[key].draggable = true
						}
					}
				},
				navigate(data: any) {
					const { event } = data
					if (
						event.type === "click" &&
						(event.target.parentElement.classList.contains("move-user") ||
							event.target.classList.contains("move-user"))
					) {
						event.stopImmediatePropagation()
						event.preventDefault()
						// @ts-ignore, up not describe in librairie
						this.up("calendar").trigger("orderUsers", data)
						return false
					}
				},
			},
			itemTpl: (record: Resource) =>
				`<div class="item-resource-filter">
					<div class="item-resource-label">${StringHelper.encodeHtml(record.name || "")} </div>
					<div class="chevron"><div class="move-user chevron-resource-agenda-up" data-user=${
						record["@id"]
					} ><i class="fa-sharp fa-solid fa-chevron-up"></i></span></div><div class="move-user chevron-resource-agenda-down" data-user=${
					record["@id"]
				} ><i class="fa-sharp fa-solid fa-chevron-down"></i></div></div></div>`,
		}
	}

	// PRIVATE FUNCTION OVERRIDDEN
	initFilter(): void {
		const { eventStore } = this

		if (eventStore.resourceStore.count) {
			this.detachListeners("project")
			// @ts-ignore up not define by the library
			const calendar = this.up("calendar") as AWCalendar
			calendar.trigger("userStoreIsReady")
			calendar.resourceStore.addSorter((userA: any, userB: any) => {
				return (userA.data.order ?? 999) - (userB.data.order ?? 999)
			})
		}
		//this.applyFilter()
	}

	// PRIVATE FUNCTION OVERRIDDEN
	onSelectionChange({ source: selected }: { source: any }): void {
		const applyFirstFilter = !this.resourceFilter,
			disabled = selected.count === this.eventStore.resourceStore.count

		// @ts-ignore
		// eslint-disable-next-line prefer-rest-params
		super.onSelectionChange(...arguments)

		// REQUIRED TO APPLY FIRST TIME BECAUSE FIRST FILTER IS APPLIED SILENTLY BY DEFAULT
		if (applyFirstFilter && this.resourceFilter) {
			// Filter disabled if all resources selected
			this.resourceFilter.disabled = disabled
			// Have the client EventStore refresh its filtering but after a small delay so the List UI updates immediately.
		}
		// @ts-ignore
		this.applyFilter()
		//this.show()
		this.trigger("onchange", { records: selected })
		// @ts-ignore
		this.up("calendar").trigger("fetchSchedules")
		// @ts-ignore
		const calendar = this.up("calendar") as AWCalendar
		// without the timeout, the Sunday isn’t correctly set
		setTimeout(() => {
			setStartTimeAndWorkingDays(
				calendar,
				selected.map((user: User) => user["@id"])
			)
		}, 100)

		const alreadySelectedLaboratories = calendar.widgetMap.laboratoryFilter.records.map(
			// @ts-ignore
			(record) => record.data.value
		)

		const selectedLaboratories: number[] = []

		for (const user of selected.allValues) {
			for (const lab of user.data.laboratories) {
				const laboratoryId = getIdFromIri(lab["@id"])

				if (laboratoryId && !selectedLaboratories.includes(+laboratoryId))
					selectedLaboratories.push(+laboratoryId)
			}
		}

		const laboratoryStore = calendar.widgetMap.laboratoryFilter.store
		// @ts-ignore
		calendar.widgetMap.laboratoryFilter.value = laboratoryStore.allRecords.filter(
			(lab: any) =>
				alreadySelectedLaboratories.includes(lab.data.value) || selectedLaboratories.includes(lab.data.value)
		)
	}
}
