import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import ListeDocument from "pages/fiche-patient/Documents/Liste"
import { DocumentsRoutePrefix, DocumentsRoutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

function DocumentsRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Documents")
	return (
		<CardWithTabs urlprefix={DocumentsRoutePrefix}>
			<CardItem name="Documents" component={ListeDocument} url={noPrefix(DocumentsRoutes.listeDocuments)} />
		</CardWithTabs>
	)
}

export default DocumentsRouter
