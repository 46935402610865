import { Override } from "@audiowizard/common"
import { Select, SelectProps } from "antd"
import _ from "lodash"
import React, { ReactNode, useMemo } from "react"
import { partialSearch } from "services/functions"

export type ObjectSelectProps<T> = Override<
	SelectProps<string | number>,
	{
		value?: T
		onChange?: (value: T) => void
		options: T[]
		optionToLabel: (option: T) => ReactNode
		optionToKey?: (option: T) => string | number
		optionExtraData?: (option: T) => Record<`data-${Lowercase<string>}`, unknown>
	}
>
export default function ObjectSelect<T>({
	value,
	onChange = () => undefined,
	options,
	optionToLabel,
	optionToKey = (o: any) => o?.["@id"],
	optionExtraData = () => ({}),
	...props
}: ObjectSelectProps<T>): JSX.Element {
	// Add value as option if not present
	const optionsAndValue = useMemo(() => {
		if (value == null) return options

		const valueKey = optionToKey(value)
		if (!options.some((o) => optionToKey(o) === valueKey)) {
			return [...options, value]
		} else {
			return options
		}
	}, [options, value])

	const optionsByKey = useMemo(() => _.keyBy(optionsAndValue, optionToKey), [optionsAndValue])

	return (
		<Select
			showSearch
			filterOption={(search, option) =>
				typeof option?.label === "string" ? partialSearch(option.label ?? "", search) : true
			}
			value={value == null ? "" : optionToKey(value)} // null -> ""
			onChange={(value) => onChange(optionsByKey[value])}
			options={optionsAndValue.map((o) => ({
				label: optionToLabel(o),
				value: optionToKey(o),
				...optionExtraData(o),
			}))}
			{...props}
		/>
	)
}
