export const medicalReadableLabels = {
	levels: {
		0: "Aucune",
		1: "Légère",
		2: "Moyenne",
		3: "Sévère",
		4: "Profonde",
	},
	ear: {
		GAUCHE: "Gauche",
		DROITE: "Droite",
	},
	labels: {
		"PERTE PERCEPTION": "Perte perception",
		"PERTE TRANSMISSION": "Perte transmission",
		"PERTE MIXTE": "Perte mixte",
		COPHOSE: "Cophose",
	},
}

export const itemsPerte = [
	{
		id: 1,
		label: "Perception gauche",
		ear: "GAUCHE",
		value: "PERTE PERCEPTION",
		modal: true,
	},
	{
		id: 2,
		label: "Transmission gauche",
		ear: "GAUCHE",
		value: "PERTE TRANSMISSION",
		modal: true,
	},
	{
		id: 3,
		label: "Mixte gauche",
		ear: "GAUCHE",
		value: "PERTE MIXTE",
		modal: true,
	},
	{
		id: 4,
		label: "Cophose gauche",
		ear: "GAUCHE",
		value: "COPHOSE",
		modal: false,
	},
	{
		id: 5,
		label: "Perception droite",
		ear: "DROITE",
		value: "PERTE PERCEPTION",
		modal: true,
	},
	{
		id: 6,
		label: "Transmission droite",
		ear: "DROITE",
		value: "PERTE TRANSMISSION",
		modal: true,
	},
	{
		id: 7,
		label: "Mixte droite",
		ear: "DROITE",
		value: "PERTE MIXTE",
		modal: true,
	},
	{
		id: 8,
		label: "Cophose droite",
		ear: "DROITE",
		value: "COPHOSE",
		modal: false,
	},
]
