/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import { Fragment, useState, useEffect } from "react"
import Loader from "react-promise-loader"

import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import API from "../../services/API"
import { StringUpperCFirst } from "../../services/functions"
import { PROFESSIONNEL_DE_SANTES, ASSURANCES } from "../../config"
import useInterval from "./useInterval"

const OmniSearchSante = ({
	id,
	target,
	placeholderFirstName,
	placeholderLastName,
	placeholderMutuelleName,
	titleTextSearch,
	ressources,
	onClick,
	setRessources,
	button,
	defaultRessource,
}) => {
	const [searchedElement, setSearchedElement] = useState({
		lastName: "",
		firstName: "",
		mutuelleName: "",
	})

	const [saved, setSaved] = useState({
		lastName: "",
		firstName: "",
		mutuelleName: "",
	})

	const [matchingDefaults, setMatchingDefaults] = useState([])
	const [filteredResults, setFilteredResults] = useState([])

	useEffect(() => {
		const patterns = {
			firstName:
				searchedElement.firstName.length > 0
					? new RegExp(".*" + searchedElement.firstName.split("").join(".*") + ".*", "i")
					: null,
			lastName:
				searchedElement.lastName.length > 0
					? new RegExp(".*" + searchedElement.lastName.split("").join(".*") + ".*", "i")
					: null,
		}
		setMatchingDefaults(
			defaultRessource?.filter((e) => {
				if (patterns.firstName && !patterns.firstName.test(e.firstName)) {
					return false
				}
				if (patterns.lastName && !patterns.lastName.test(e.lastName)) {
					return false
				}
				return true
			}) || []
		)
	}, [defaultRessource, searchedElement])

	useEffect(() => {
		setFilteredResults(
			ressources
				?.filter((e) => {
					if (defaultRessource && defaultRessource.find((d) => d.rpps === e.identificationNationalePP)) {
						return false
					}
					return true
				})
				.slice(0, 10 - matchingDefaults.length) || []
		)
	}, [ressources, defaultRessource])

	const searchMade =
		searchedElement.lastName.length > 0 ||
		searchedElement.firstName.length > 0 ||
		searchedElement.mutuelleName.length > 0

	const handleChange = ({ currentTarget }) => {
		const { name, value } = currentTarget
		setSearchedElement({
			...searchedElement,
			[name]: value.trim(),
		})
	}

	const handleChangeSearchElement = () => {
		const { lastName, firstName, mutuelleName } = searchedElement

		if (searchedElement !== saved) {
			setSaved(searchedElement)
			let params = ""
			let endpoint = PROFESSIONNEL_DE_SANTES

			if (target === "doctors") {
				params =
					"?libelleSavoirFaire[]=Qualifié en Médecine Générale&libelleSavoirFaire[]=Médecine Générale&libelleSavoirFaire[]=Médecine du travail&libelleSavoirFaire[]=Pédiatrie&libelleSavoirFaire[]=Santé publique et médecine sociale&libelleSavoirFaire[]=Spécialiste en Médecine Générale&libelleSavoirFaire[]=Médecine interne" +
					(lastName !== "" ? `&nom=${lastName}` : "") +
					(firstName !== "" ? `&prenom=${firstName}` : "")
			} else if (target === "mutuelles") {
				params = mutuelleName !== "" ? `?nom=${mutuelleName}` : ""
				endpoint = ASSURANCES
			} else if (target === "orls") {
				params =
					"?libelleSavoirFaire[]=Oto-rhino-laryngologie&libelleSavoirFaire[]=O.R.L et chirurgie cervico faciale&libelleSavoirFaire[]=Qualifié en Médecine Générale&libelleSavoirFaire[]=Médecine Générale&libelleSavoirFaire[]=Médecine du travail&libelleSavoirFaire[]=Pédiatrie&libelleSavoirFaire[]=Santé publique et médecine sociale&libelleSavoirFaire[]=Spécialiste en Médecine Générale&libelleSavoirFaire[]=Médecine interne" +
					(lastName !== "" ? `&nom=${lastName}` : "") +
					(firstName !== "" ? `&prenom=${firstName}` : "")
			} else if (target === "prescribers") {
				params =
					"?libelleSavoirFaire[]=Qualifié en Médecine Générale&libelleSavoirFaire[]=Médecine Générale&libelleSavoirFaire[]=Médecine du travail&libelleSavoirFaire[]=Pédiatrie&libelleSavoirFaire[]=Santé publique et médecine sociale&libelleSavoirFaire[]=Spécialiste en Médecine Générale&libelleSavoirFaire[]=Médecine interne&libelleSavoirFaire[]=Oto-rhino-laryngologie&libelleSavoirFaire[]=O.R.L et chirurgie cervico faciale" +
					(lastName !== "" ? `&nom=${lastName}` : "") +
					(firstName !== "" ? `&prenom=${firstName}` : "")
			}

			trackPromise(
				API.sante(endpoint, params || "").then((data) => {
					setRessources(data["hydra:member"])
				})
			)
		}
	}

	const prescriberText = (p) => {
		let text = ""
		text = `${p.nom}${p.prenom ? " " + p.prenom : ""}`
		if (
			p.numeroVoie ||
			p.libelleTypeVoie ||
			p.libelleVoie ||
			p.libelleCommune ||
			p.bureauCedex ||
			p.libelleCommune
		) {
			text += " -"
		}
		if (p.numeroVoie || p.libelleTypeVoie || p.libelleVoie) {
			text += ` ${p.numeroVoie ? p.numeroVoie + " " : ""}${
				p.libelleTypeVoie ? StringUpperCFirst(p.libelleTypeVoie) + " " : ""
			}${p.libelleVoie ? StringUpperCFirst(p.libelleVoie) + " " : ""}`
		}
		if (p.bureauCedex || p.codePostal || p.libelleCommune) {
			if (p.numeroVoie || p.libelleTypeVoie || p.libelleVoie) {
				text += ","
			}
			if (p.bureauCedex) {
				text += ` ${p.bureauCedex.toUpperCase()}`
			} else {
				text += `${p.codePostal ? " " + p.codePostal : ""}${
					p.libelleCommune ? " " + p.libelleCommune.toUpperCase() : ""
				}`
			}
		}
		return text
	}

	const defaultPrescriberText = (p) => {
		let text = `${p.lastName} ${p.firstName || ""}`
		if (p.city || p.cpo || p.adress) {
			text += " - "
			if (p.adress) {
				text += `${p.adress}${p.cpo || p.city ? ", " : ""}`
			}
			if (p.cpo) {
				text += `${p.cpo} `
			}
			if (p.city) {
				text += p.city
			}
		}
		return text
	}

	useInterval(handleChangeSearchElement, 3000)

	useEffect(() => {
		setSearchedElement({
			lastName: "",
			firstName: "",
			mutuelleName: "",
		})
		setSaved({
			lastName: "",
			firstName: "",
			mutuelleName: "",
		})
	}, [target])

	return (
		<div id={id} className="omnisearch">
			<div className="container">
				<form className="omnisearch-form" autoComplete="off">
					<div className="form-group">
						<div className="input-group input-group-merge">
							{id === "mutuelleSearch" ? (
								<input
									autoComplete="off"
									id="input-omnisearch-mutuellename"
									defaultValue={searchedElement.mutuelleName}
									onChange={handleChange}
									name="mutuelleName"
									type="text"
									className="form-control"
									placeholder={placeholderMutuelleName}
								/>
							) : (
								<>
									<input
										autoComplete="off"
										id="input-omnisearch-firstname"
										defaultValue={searchedElement.firstName}
										onChange={handleChange}
										name="firstName"
										type="text"
										className="form-control"
										placeholder={placeholderFirstName}
									/>
									<input
										autoComplete="off"
										id="input-omnisearch-lastname"
										defaultValue={searchedElement.lastName}
										onChange={handleChange}
										name="lastName"
										type="text"
										className="form-control"
										placeholder={placeholderLastName}
									/>
								</>
							)}
							<div className="input-group-append">
								<span className="input-group-text">
									<button
										id="omnisearch-close"
										className="btn btn-sm"
										data-action="omnisearch-close"
										data-target={"#" + id}>
										<i className="fad fa-search fa-2x" />
									</button>
								</span>
							</div>
						</div>
					</div>
				</form>
				<div className="omnisearch-suggestions">
					<h6 className="heading">{titleTextSearch}</h6>
					<div className="row">
						<div className="col-sm-12">
							<ul className="list-unstyled mb-0" data-cy={"orlSearch"}>
								{matchingDefaults.map((element, key) => (
									<Fragment key={key}>
										{key <= 10 && (
											<li>
												<button
													type="button"
													className="btn btn-link p-0 text-info"
													data-action="omnisearch-close"
													data-target={"#" + id}
													title={defaultPrescriberText(element)}
													onClick={() => onClick(element, "NATURAL")}>
													{id === "mutuelleSearch"
														? element.name
														: defaultPrescriberText(element)}
												</button>
											</li>
										)}
									</Fragment>
								))}
								{searchMade &&
									filteredResults.map((element, key) => (
										<Fragment key={key}>
											<li>
												<button
													type="button"
													className="btn btn-link p-0 text-truncate text-nowrap mw-100"
													data-action="omnisearch-close"
													data-target={"#" + id}
													title={
														id === "mutuelleSearch" ? element.nom : prescriberText(element)
													}
													onClick={() => onClick(element, "API")}>
													{id === "mutuelleSearch" ? element.nom : prescriberText(element)}
												</button>
											</li>
										</Fragment>
									))}
								{matchingDefaults.length === 0 && filteredResults.length === 0 && searchMade && (
									<li>{button}</li>
								)}
							</ul>
						</div>
						<Loader
							promiseTracker={usePromiseTracker}
							color={"#47c6a9"}
							background={"rgba(24,171,188,.1)"}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

OmniSearchSante.defaultProps = {
	placeholderFirstName: "Prénom",
	placeholderLastName: "Nom",
	placeholderMutuelleName: "Nom de la mutuelle",
}

OmniSearchSante.propTypes = {
	id: PropTypes.string.isRequired,
	placeholderFirstName: PropTypes.string,
	placeholderLastName: PropTypes.string,
	handleClickElement: PropTypes.func,
	titleTextSearch: PropTypes.string.isRequired,
	setElement: PropTypes.object,
}

export default OmniSearchSante
