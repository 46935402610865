/* eslint-disable react-hooks/exhaustive-deps */
import ButtonRounded from "components/Buttons/ButtonRounded"
import useHasRole from "components/Hooks/useHasRole"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { Col, Row } from "reactstrap"
import API from "services/API"
import DataTable from "../../components/utils/DataTable"
import AuthContext from "../../contexts/AuthContext"
import { formatPhoneNumber } from "../../services/functions"
import handleChangesGeneric from "./ChangeHandler"
import LaboratoryModal from "./laboratory-modal/LaboratoryModal"

const MesLaboratoires = () => {
	const { laboratories, setLaboratories, setLaboratory } = useContext(AuthContext)
	const location = useLocation()
	const isManager = useHasRole("ROLE_MANAGER")

	const [selectedLab, setSelectedLab] = useState({})
	const [modalTitle, setModalTitle] = useState("")
	const [isOpen, setIsOpen] = useState(false)

	const [errors, setErrors] = useState({})

	const columns = [
		{
			title: "Nom",
			dataIndex: "label",
			sorter: (a, b) => {
				a = a.label || ""
				b = b.label || ""
				return a.localeCompare(b)
			},
			width: "15%",
		},
		{
			title: "Adresse",
			dataIndex: "adress",
			sorter: (a, b) => {
				a = a.adress || ""
				b = b.adress || ""
				return a.localeCompare(b)
			},
			width: "20%",
		},
		{
			title: "Code Postal",
			dataIndex: "cpo",
			sorter: (a, b) => {
				a = a.cpo || ""
				b = b.cpo || ""
				return a.localeCompare(b)
			},
			width: "15%",
		},
		{
			title: "Ville",
			dataIndex: "city",
			sorter: (a, b) => {
				a = a.city || ""
				b = b.city || ""
				return a.localeCompare(b)
			},
			width: "15%",
		},
		{
			title: "Telephone",
			dataIndex: "phone",
			sorter: (a, b) => {
				a = a.phone || ""
				b = b.phone || ""
				return a.localeCompare(b)
			},
			render: (phone) => {
				return (
					<span className="badge badge-info badge-pill">
						{phone != null ? formatPhoneNumber(phone) : "Aucun numéro"}
					</span>
				)
			},
			width: "15%",
		},
		{
			title: "Email",
			dataIndex: "email",
			sorter: (a, b) => {
				a = a.email || ""
				b = b.email || ""
				return a.localeCompare(b)
			},
			width: "20%",
		},
	]

	const [needRefresh, setNeedRefresh] = useState(true)

	const handleClickSetCurrentLaboratory = async (id) => {
		const thisLaboratory =
			laboratories.find((selectedLab) => selectedLab.id === id) ?? (await API.find("LABORATORIES_API", id)) // Fetch lab if not context
		setSelectedLab(thisLaboratory)
		setModalTitle("Modifier le laboratoire " + thisLaboratory.label)
		setIsOpen(true)
	}

	const handleClickAddLaboratory = () => {
		setIsOpen(true)
		setSelectedLab({
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		})
		setModalTitle("Ajouter un laboratoire")
	}

	const newLaboratoryBtnRef = useRef()

	useEffect(() => {
		if (!location.state?.openModal) return
		newLaboratoryBtnRef.current.click()
	}, [])

	const handleChanges = (e, lab) => {
		e.preventDefault()

		const tmpLaboratory = {
			label: lab.label,
			timezone: lab.timezone,
			adress: lab.adress,
			cpo: lab.cpo,
			city: lab.city,
			phone: lab.phone || null,
			email: lab.email,
			finess: lab.finess,
			country: lab.country,
			subCompany: lab.subCompany["@id"],
			agendaColor: lab.agendaColor,
			datamutCredential: lab.datamutCredential?.password ? lab.datamutCredential : undefined,
		}

		handleChangesGeneric(
			{
				target: "LABORATORIES_API",
				currentRessource: lab,
				contextRessource: laboratories,
				setContextRessource: setLaboratories,
				updateObject: tmpLaboratory,
			},
			function () {
				setLaboratory(lab)
				setIsOpen(false)
				setNeedRefresh(true)
				toast.success("Opération réussie !")
			},
			function (error) {
				setErrors({ ...errors, ...error })
			}
		)
	}

	return (
		<>
			<Col>
				<SectionHeader title="Liste des laboratoires">
					{isManager && (
						<ButtonRounded
							type="button"
							color="primary-outlined"
							icon="fa-plus"
							onClick={handleClickAddLaboratory}>
							Ajouter un laboratoire
						</ButtonRounded>
					)}
				</SectionHeader>

				{laboratories.length === 0 ? (
					<div className="col-12 text-center">
						<h2>
							Aucun laboratoire enregistré
							<span role="img" aria-label="wizards">
								🧙‍♂️🧙‍♀️
							</span>
						</h2>
					</div>
				) : (
					<Row>
						<div className="col-12">
							<DataTable
								columns={columns}
								target="laboratories"
								initialData={laboratories}
								onRowClick={handleClickSetCurrentLaboratory}
								refresh={{ needRefresh, setNeedRefresh }}
							/>
						</div>
					</Row>
				)}
			</Col>

			<LaboratoryModal
				id={"laboratory-modal"}
				onClose={() => {
					setIsOpen(false)
					setErrors({})
				}}
				title={modalTitle}
				handleSubmitLaboratory={handleChanges}
				editLaboratory={selectedLab}
				setLaboratory={setSelectedLab}
				setErrors={setErrors}
				errors={errors}
				isOpen={isOpen}
			/>
		</>
	)
}

export default MesLaboratoires
