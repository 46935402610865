import getSchedulesType from "./getScheduleType"
import { History } from "history"
import { Patient, Schedule } from "@audiowizard/common"

export const handleScheduleType = async (
	scheduleType: string,
	ctx: { patient: Patient; setSchedule: (s: (o: Schedule) => Schedule) => void; setEditing: (b: boolean) => void },
	history: History,
	setConfirmModal: (m: any) => void,
	setShow?: ((show: boolean) => void) | undefined
): Promise<boolean> => {
	try {
		const customScheduleType = await getSchedulesType()
		const scheduleData = customScheduleType[scheduleType] || null

		if (!scheduleData?.action) {
			return false
		}
		ctx.setSchedule((old) => ({ ...old, detail: scheduleData }))

		let removeModalAgenda = true

		switch (scheduleData?.action) {
			case "AUCUNE":
				history.push("/fiche-patient/" + ctx.patient.id, { showFinEvenementModal: true })
				break
			case "VISITE":
				history.push("/devis-pack/edition")
				break
			case "NOTES":
				history.push("/rendez-vous/notes")
				break
			case "SUITE_VISITE":
				ctx.setEditing(false)
				history.push("/premiere-visite/genes")
				break
			case "CONTROLE":
			case "ESSAI":
			case "APPAREILLE":
			case "FACTURATION":
				history.push("/evenements/genes")
				break
			case "PREMIERE_VISITE":
				ctx.setEditing(false)
				history.push("/premiere-visite/origine")
				break
			case "ADAPTATION":
				let count = 0
				for (const e of ctx.patient?.patientEquipments ?? []) {
					if (e.status === "ESSAI") count++
				}

				if (!count || !ctx.patient?.patientEquipments?.length) {
					removeModalAgenda = false
					setConfirmModal((o: any) => ({
						...o,
						show: true,
						title: "Attention !",
						content:
							"Le patient n'a pas d'appareil en essai, êtes-vous sur de vouloir démarrer le rendez-vous ?",
						btnConfirm: "Démarrer le rendez-vous",
						btnCancel: "Annuler",
						fctConfirm: () => {
							history.push("/evenements/genes")
							if (setShow) setShow(false)
						},
					}))
				} else {
					history.push("/evenements/genes")
				}

				break
			default:
				return false
		}
		if (removeModalAgenda && setShow) {
			setShow(false)
		}
		return true
	} catch (error) {
		console.error(error)
		return false
	}
}
