/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartBar from "./ChartBar"
import API from "services/API"
import { useQuery } from "react-query"
import OriginesAllModal from "./OriginesAllModal"
import useEffectAsync from "components/Hooks/useEffectAsync"

const Attentes = () => {
	const [origines, setOrigines] = useState([])
	const [loading, setLoading] = useState(true)
	const [displayAllOriginsModal, setDisplayAllOriginsModal] = useState(false)

	const { data: originsHashMap, isFetching: isOriginsFetching } = useQuery(
		"ORIGINS_API",
		async () => await API.findAll("ORIGINS_API")
	)

	const DISPLAY_LIMIT = 10

	const getOriginsStats = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/origines")
			const data = []
			const originList = originsHashMap.map((e) => e.name)

			if (!result?.data?.message?.length) return setLoading(false)
			result?.data?.message.forEach((e) => {
				const id = originList.indexOf(e.value)
				if (id >= 0)
					data.push({
						x: originList[id],
						y: parseInt(e.total),
					})
			})
			data.sort(function (a, b) {
				return b.y - a.y
			})
			setOrigines(data)
			setLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	useEffectAsync(async () => {
		if (isOriginsFetching) return
		await getOriginsStats()
	}, [isOriginsFetching])

	return (
		<>
			<h5 className="mb-4">Classement des origines</h5>
			{/* we limit the display of origines to the 10 most important, otherwise the chart gets messy  */}
			<ChartBar series={[{ name: "Origines", data: origines.slice(0, DISPLAY_LIMIT) }]} />
			{loading ? (
				<div className="chart_loading">
					<div className="spinner-border" role="status">
						{" "}
					</div>
				</div>
			) : (
				<>
					{origines.length > DISPLAY_LIMIT && (
						<span className="cursor-pointer" onClick={() => setDisplayAllOriginsModal(true)}>
							Voir toutes les origines ({origines.length} au total)
						</span>
					)}

					<OriginesAllModal
						isOpen={displayAllOriginsModal}
						origins={origines}
						setIsOpen={setDisplayAllOriginsModal}
					/>
				</>
			)}
		</>
	)
}

export default Attentes
