import {
	CheckCircleFilled,
	CheckCircleTwoTone,
	CloseCircleFilled,
	CloseCircleTwoTone,
	DeleteTwoTone,
} from "@ant-design/icons"
import {
	HiboutikProductEntity,
	Inventory as InventoryType,
	InventoryProduct,
	PatientEquipment,
} from "@audiowizard/common"
import { Editor } from "@tinymce/tinymce-react"
import { Row, Col, notification, Table } from "antd"
import axios from "axios"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import LoadingModal from "components/effects/ModalLoading"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { API_URL, INVENTORY_PRODUCTS_API } from "config"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { ChangeEvent, MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { useMutation } from "react-query"
import { useRouteMatch } from "react-router-dom"
import { toast } from "react-toastify"
import uuid from "react-uuid"
import API from "services/API"
import useStockAvailable from "../Hooks/useStockAvailable"
import { AsyncProductSelect } from "../Shared/AsyncProductSelect"
import { AsyncSizeSelect } from "../Shared/AsyncSizeSelect"
import DeleteLogisticForm from "../Shared/DeleteLogisticFormButton"
import { isProductStillAvailable } from "../StockUtils"
import ClearUnaivalableProductsButton from "./ClearUnaivalableProducts"
import { exportToCsv } from "./ExportCsv"
import "./inventoryStyle.scss"

const SN_CATEGORIES = ["APPAREILS AUDITIFS", "ACCESSOIRE"]

export type Override<T1, T2> = Omit<T1, keyof T2> & T2

type InventoryProductCustom = Override<
	InventoryProduct,
	{
		notAvailableAnymore: boolean
		inventoryProductLocalIndex: number
		key: string
		model: string
		sizeName: string
		supplierName?: string
		brandName?: string
		categoryName?: string
		price?: number
		supplyPrice?: number
		patientEquipment: PatientEquipment[]
	}
>

const Inventory = (): JSX.Element => {
	const { laboratory } = useContext(AuthContext)

	const routeMatch = useRouteMatch()

	const [inventoryConfig, setInventoryConfig] = useState<Record<string, any>>({
		laboratory: laboratory["@id"],
	})
	const [inventoryProducts, setInventoryProducts] = useState<InventoryProductCustom[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const keysValue = useRef<string>("")
	const keyTimeout = useRef<NodeJS.Timeout | undefined>()
	const commentTimeout = useRef<NodeJS.Timeout | undefined>()

	const [csvConfig, setCsvConfig] = useState<Record<string, any>>({ loading: false })
	const csvLinkRef = useRef()

	const productCatalogRef = useRef<any[]>([])
	const patientEquipmentsRef = useRef<any[]>([])

	const timeoutChange: MutableRefObject<NodeJS.Timeout | null> = useRef<NodeJS.Timeout | null>(null)

	const stockAvailableHook = useStockAvailable(inventoryConfig.laboratory?.warehouseIdHiboutik)

	const { mutate: deleteInventoryProduct, isLoading: isDeleting } = useMutation(
		async (iri: string) => {
			await API.delete(iri)
			return iri
		},
		{
			onSuccess: (iri: string) => {
				const index = inventoryProducts.findIndex((product) => product["@id"] === iri)
				const tmp = [...inventoryProducts]
				tmp.splice(index, 1)
				setInventoryProducts(tmp)
			},
		}
	)

	const { mutate: updateInventoryProduct, isLoading: isUpdating } = useMutation(
		async (data: Record<string, any>) => {
			if (data.key === "newQuantity") {
				data.value = +data.value
			}

			await API.updateWithIri(data.iri, { [data.key]: data.value })

			return data
		},
		{
			onSuccess: (data) => {
				const tmp = [...inventoryProducts]
				//@ts-ignore\
				tmp[data.inventoryProductLocalIndex][data.key] = data.value
				setInventoryProducts(tmp)
			},
			onError: (_: void, data) => {
				if (data.key === "newQuantity") {
					const tmp = [...inventoryProducts]
					//@ts-ignore\
					tmp[data.inventoryProductLocalIndex][data.key] =
						tmp[data.inventoryProductLocalIndex].originalQuantity
					setInventoryProducts(tmp)
				}
			},
		}
	)

	const { mutate: validateInventory, isLoading: isValidating } = useMutation(
		async () => {
			await axios.put(`${API_URL}${inventoryConfig["@id"]}/validate`)
		},
		{
			onSuccess: () => {
				loadExistingInventory(inventoryConfig.id)
				loadInventoryConfig(inventoryConfig.id)
			},
			onError: (error) => {
				loadExistingInventory(inventoryConfig.id)
				loadInventoryConfig(inventoryConfig.id)
				confirmWithModal({
					text: <h5>Impossible de mettre à jour le stock sur certain produits</h5>,
					hideCancel: true,
					okLabel: "Fermer",
				})
			},
		}
	)

	const { mutate: editInventoryConfig } = useMutation(
		async (data: Record<string, any>) => {
			await API.updateWithIri(inventoryConfig["@id"], data)
		},
		{
			onError: () => {
				toast.error("Erreur lors de l'enregistrement des modifications")
			},
		}
	)

	const handleKeyboardGlobalEvent = (event: KeyboardEvent): void => {
		const key = event.key
		const focusedElement = document.activeElement

		if (focusedElement?.tagName !== "BODY") return

		const changeState = (): void => {
			const index = inventoryProducts.findIndex(
				(f) => f.productSerialNumber?.toLowerCase() === keysValue.current.toLowerCase() && f.state !== "OK"
			)
			if (index !== -1) {
				updateInventoryProduct({
					iri: inventoryProducts[index]["@id"],
					key: "state",
					value: "OK",
					inventoryProductLocalIndex: index,
				})
			} else {
				notification.warn({
					top: 62,
					message: "Oops !",
					description: `Le numéro de série ${keysValue.current} n'a pas été trouvé dans l'inventaire`,
				})
			}
			keysValue.current = ""
		}

		if (key === "Enter" || event.keyCode === 13) {
			changeState()
			return
		}

		clearTimeout(keyTimeout.current as NodeJS.Timeout)
		keyTimeout.current = setTimeout(() => {
			changeState()
		}, 350)

		keysValue.current += key
	}

	const createInventoryProductObject = (
		catalog: any[],
		patientEquipments: PatientEquipment[],
		product: Record<string, any>
	): InventoryProductCustom => {
		const correspondingProduct: HiboutikProductEntity = catalog.find((p: any) => +p.id === +product.productId)
		const correspondingEquipment = patientEquipments.filter(
			(pe: any) =>
				+pe.productIdHiboutik === +product.productId &&
				+pe.sizeIdHiboutik === +product.productSizeId &&
				pe.serialNumber === product.productSerialNumber
		)

		return {
			...product,
			key: uuid(),
			model: correspondingProduct?.model ?? "Inconnu",
			sizeName: correspondingProduct?.sizeDetails?.find((s: any) => s.sizeId === product.productSizeId)?.sizeName,
			supplierName: correspondingProduct?.supplierName ?? "Fournisseur non renseigné",
			brandName: correspondingProduct?.brandName ?? "Marque non renseignée",
			categoryName: correspondingProduct?.categoryName ?? "Catégorie non renseignée",
			patientEquipment: correspondingEquipment,
			supplyPrice: correspondingProduct?.supplyPrice,
			price: correspondingProduct?.price,
		} as InventoryProductCustom
	}

	const loadExistingInventory = async (inventoryId: number): Promise<void> => {
		try {
			setLoading(true)
			const inventoryProduct = await API.findAll<InventoryProduct[]>(
				"INVENTORY_PRODUCTS_API",
				`?inventory=${inventoryId}`
			)
			const productCatalog = await API.findAll<HiboutikProductEntity[]>("PRODUCTS_API", "?pagination=false")
			const patientEquipments = await API.findAll<PatientEquipment[]>(
				"PATIENT_EQUIPMENTS_API",
				"?status[]=SAV&status[]=ESSAI"
			)

			productCatalogRef.current = productCatalog
			patientEquipmentsRef.current = patientEquipments

			if (!inventoryConfig.validatedAt) {
				for (const i in inventoryProduct) {
					const product = inventoryProduct[i]

					//@ts-ignore
					inventoryProduct[i].notAvailableAnymore = isProductStillAvailable(stockAvailableHook.data, product)
				}

				//@ts-ignore
				inventoryProduct.push({ ghostProduct: true, key: uuid() })
			}

			setInventoryProducts(
				inventoryProduct.map((product) =>
					createInventoryProductObject(productCatalog, patientEquipments, product)
				)
			)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const loadInventoryConfig = async (inventoryId: number): Promise<void> => {
		try {
			//@ts-ignore
			const inventory = await API.find<InventoryType>("INVENTORY_API", inventoryId)

			setInventoryConfig(inventory)
		} catch (error) {
			console.error(error)
		}
	}

	useEffectAsync(async () => {
		//@ts-ignore
		loadInventoryConfig(routeMatch?.params?.id)
	}, [routeMatch?.params])

	useEffect(() => {
		if (!Object.hasOwn(inventoryConfig, "validatedAt")) return

		//@ts-ignore
		if (routeMatch?.params?.id && !stockAvailableHook.isLoading) {
			//@ts-ignore
			loadExistingInventory(routeMatch?.params?.id)
		}
	}, [stockAvailableHook.isLoading, inventoryConfig.validatedAt])

	useEffect(() => {
		document.addEventListener("keydown", handleKeyboardGlobalEvent)
		return () => {
			document.removeEventListener("keydown", handleKeyboardGlobalEvent)
		}
	}, [])

	const hasUnaivalableProducts: boolean = useMemo(
		() => inventoryProducts.find((product) => product.notAvailableAnymore) != null,
		[inventoryProducts]
	)

	const isAllProductsHandled: boolean = useMemo(
		() => inventoryProducts.some((p) => p.state === "NONE"),
		[inventoryProducts]
	)

	const handleChange = (
		evt: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
		record: Record<string, any>,
		key: string
	): void => {
		let value: number | string = evt.target.value

		const index = inventoryProducts.findIndex((f) => f.key === record.key)
		if (index === -1) {
			return
		}
		if (key === "newQuantity") {
			clearTimeout(timeoutChange.current as NodeJS.Timeout)
			const tmp = [...inventoryProducts]

			value = Math.abs(+value)

			//@ts-ignore
			tmp[index][key] = value
			setInventoryProducts(tmp)
			timeoutChange.current = setTimeout(() => {
				updateInventoryProduct({
					iri: record["@id"],
					key,
					value,
					inventoryProductLocalIndex: index,
				})
			}, 300)
		} else {
			updateInventoryProduct({
				iri: record["@id"],
				key,
				value,
				inventoryProductLocalIndex: index,
			})
		}
	}

	const changeAllState = async (state: string): Promise<void> => {
		try {
			await axios.post(`${INVENTORY_PRODUCTS_API}/batch_change_state`, {
				inventory: inventoryConfig["@id"],
				state,
			})
			const tmp = [...inventoryProducts]
			for (const key in tmp) {
				tmp[key].state = state
				tmp[key].newQuantity = tmp[key].originalQuantity
			}
			setInventoryProducts(tmp)
		} catch (error) {
			console.error(error)
		}
	}

	const editProductByKey = (key: number, data: any): void => {
		const index = inventoryProducts.findIndex((product: any) => product.key === key)
		const tmp = [...inventoryProducts]
		tmp[index] = { ...tmp[index], ...data }
		setInventoryProducts(tmp)
	}

	const handleAddNewProduct = async (newProduct: any): Promise<void> => {
		try {
			const result = await API.create("INVENTORY_PRODUCTS_API", {
				inventory: inventoryConfig["@id"],
				productId: newProduct.product_id,
				productSizeId: newProduct.size_id,
				productSerialNumber: newProduct.productSerialNumber,
				state: "NEW",
				originalQuantity: 0,
				newQuantity: newProduct.newQuantity ? +newProduct.newQuantity : 1,
				done: false,
			})

			const index = inventoryProducts.findIndex((p) => p.key === newProduct.key)
			const tmp = [...inventoryProducts]
			tmp.splice(index, 1)

			const resultingProduct = [
				createInventoryProductObject(
					productCatalogRef.current,
					patientEquipmentsRef.current,
					result?.data as Record<string, any>
				),
				{ ghostProduct: true, key: uuid() },
			]

			//@ts-ignore
			setInventoryProducts([...tmp, ...resultingProduct])
		} catch (error) {
			console.error(error)
		}
	}

	const tableColumns = [
		{
			dataIndex: "done",
			render: (done: boolean, record: Record<string, any>) => {
				if (done) return <CheckCircleTwoTone style={{ fontSize: "20px" }} />
				if (record.error) return <CloseCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#ff0000" />
				return null
			},
		},
		{
			title: "Catégorie et fournisseur",
			dataIndex: "productId",
			className: "col-1 font-weight-bold text-truncate",
			render: (_: void, product: InventoryProductCustom) => {
				return (
					<>
						<div>
							{product?.categoryName ? (
								product?.categoryName
							) : (
								<span style={{ opacity: "0.65" }}>Pas de catégorie renseignée</span>
							)}
						</div>
						<div>
							{product?.supplierName ? (
								product?.supplierName
							) : (
								<span style={{ opacity: "0.65" }}>Pas de fournisseur renseignée</span>
							)}
						</div>
					</>
				)
			},
		},
		{
			title: "Produit",
			dataIndex: "model",
			className: "col-3 font-weight-bold",
			render: (model: string, record: Record<string, any>) => {
				if (record.ghostProduct) {
					return (
						<AsyncProductSelect
							getSuplDetail
							stockManagementOnly
							setSelectedProduct={(product) => {
								editProductByKey(record.key, product)
							}}
						/>
					)
				}

				return (
					<>
						<div
							style={{
								fontWeight: "bold",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}>
							{model}
						</div>
						<div style={{ opacity: "0.75" }}>{record.sizeName ?? "Pas de déclinaison"}</div>
					</>
				)
			},
		},
		{
			title: "En dépôt",
			dataIndex: "logisticProduct",
			className: "col-1 ",
			align: "center",
			render: (logisticProduct: any) => {
				return logisticProduct?.isDepot ? (
					<CheckCircleFilled style={{ fontSize: "22px", color: "var(--warning)" }} />
				) : (
					<CloseCircleFilled style={{ fontSize: "22px", color: "var(--secondary-dark)" }} />
				)
			},
		},
		{
			title: "Appareillé",
			dataIndex: "patientEquipment",
			className: "col-2",
			render: (equipment: Record<string, any>, record: Record<string, any>) => {
				if (record.ghostProduct) {
					return (
						<AsyncSizeSelect
							productSizeType={record.product_size_type}
							disabled={!record.product_size_type}
							setSelectedSize={(size) => {
								editProductByKey(record.key, size)
							}}
						/>
					)
				}

				if (!equipment || equipment.length === 0) {
					return <span style={{ opacity: 0.5 }}>-</span>
				}

				return (
					<div className="text-warning">
						{equipment.length === 1 && (
							<div>
								En {equipment[0]?.status?.toLowerCase()} sur {equipment[0].patient?.firstName}{" "}
								{equipment[0].patient?.lastName}{" "}
							</div>
						)}
						{equipment.length > 1 && (
							<div>
								{equipment.filter((e: any) => e.status === "ESSAI").length}en essai
								{equipment.filter((e: any) => e.status === "SAV").length} en SAV
							</div>
						)}
					</div>
				)
			},
		},
		{
			title: "Quantité",
			dataIndex: "originalQuantity",
			className: "col-1",
			align: "center",
			render: (originalQuantity: number, record: Record<string, any>) => {
				if (record.ghostProduct) {
					return (
						<input
							placeholder="Quantité"
							className="form-control form-control-sm"
							disabled={
								(record.product_size_type && record.size_id === undefined) ||
								!record.category_name ||
								SN_CATEGORIES.includes(record.category_name?.toUpperCase())
							}
							value={record.newQuantity ?? 1}
							onChange={(evt) => {
								let newQuantity = +evt.target.value ?? 0

								if (!Number.isInteger(newQuantity)) {
									newQuantity = 0
								}

								editProductByKey(record.key, { newQuantity: Math.abs(newQuantity) })
							}}
						/>
					)
				}

				if (record.productSerialNumber) return <span style={{ opacity: 0.5 }}>-</span>
				else if (record.state === "NEW") return record.newQuantity
				else return originalQuantity
			},
		},
		{
			title: "Numéro de série",
			dataIndex: "productSerialNumber",
			className: "col-2 font-monospace",
			render: (productSerialNumber: string, record: Record<string, any>) => {
				if (record.ghostProduct) {
					return (
						<input
							placeholder="Numéro de série"
							className="form-control form-control-sm"
							value={record.productSerialNumber ?? ""}
							disabled={
								(record.product_size_type && record.size_id === undefined) ||
								!record.category_name ||
								!SN_CATEGORIES.includes(record.category_name?.toUpperCase())
							}
							onChange={(evt) => {
								const value = evt.target.value
								editProductByKey(record.key, { productSerialNumber: value })
							}}
						/>
					)
				}

				if (productSerialNumber) {
					return productSerialNumber
				} else {
					return <span style={{ opacity: 0.5 }}>Pas de numéro de série</span>
				}
			},
		},
		{
			title: "Action",
			className: "col-3",
			render: (_: void, record: Record<string, any>) => {
				if (record?.notAvailableAnymore) return <></>
				if (record.ghostProduct) {
					return (
						<button
							disabled={(!record.productSerialNumber && !record.newQuantity) || record.done || isDeleting}
							type="button"
							className="btn btn-primary btn-sm btn-block"
							onClick={() => handleAddNewProduct(record)}>
							Ajouter
						</button>
					)
				}

				if (record.state === "NEW") {
					return (
						<div className="w-100 text-right">
							<button
								disabled={isDeleting || record.done}
								className="btn btn-sm btn-outline-danger btn-block"
								onClick={() => {
									deleteInventoryProduct(record["@id"])
								}}>
								<DeleteTwoTone className="text-lg " twoToneColor="#ff3333" />
							</button>
						</div>
					)
				}

				if (record.originalQuantity && !record.productSerialNumber) {
					return (
						<Row>
							<Col span={24} xxl={{ span: 12 }}>
								<select
									disabled={
										isUpdating ||
										record.done ||
										(record.newQuantity !== record.originalQuantity &&
											record.state === "DIFFERENCE")
									}
									className="form-control form-control-sm"
									value={record.state}
									onChange={(evt) => handleChange(evt, record, "state")}>
									<option value="NONE" disabled>
										État
									</option>
									<option value="OK">Ok</option>
									<option value="DIFFERENCE">Stock réel :</option>
								</select>
							</Col>
							<Col span={24} xxl={{ span: 12 }}>
								<input
									disabled={isUpdating || record.state !== "DIFFERENCE" || record.done}
									className="form-control form-control-sm"
									type="number"
									value={record.newQuantity ?? record.originalQuantity}
									onChange={(evt) => handleChange(evt, record, "newQuantity")}
								/>
							</Col>
						</Row>
					)
				} else {
					return (
						<>
							<select
								disabled={isUpdating || record.done}
								className="form-control form-control-sm"
								onChange={(evt) => handleChange(evt, record, "state")}
								value={record.state}>
								<option value="NONE" disabled>
									État
								</option>
								<option value="OK">En stock</option>
								<option value="DIFFERENCE">Manquant</option>
							</select>
						</>
					)
				}
			},
		},
	]

	const sortedProducts = useMemo(() => {
		const tmp = [...inventoryProducts]

		const ghostProduct = { ...tmp[tmp.length - 1] }
		tmp.splice(tmp.length - 1, 1)

		tmp.sort(
			(a, b) =>
				(a.brandName ?? "")?.localeCompare(b.brandName ?? "") ||
				(a.categoryName ?? "").localeCompare(b.categoryName ?? "") ||
				(a.model ?? "").localeCompare(b.model ?? "")
		)

		tmp.push(ghostProduct)

		return tmp
	}, [inventoryProducts])

	return (
		<>
			<div className="cardtabs-subtitle">Commentaire</div>
			<Editor
				apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
				disabled={inventoryConfig.validatedAt}
				initialValue={undefined}
				init={{
					height: 200,
					menubar: false,
					plugins: [
						"advlist autolink lists link image charmap print preview anchor",
						"searchreplace visualblocks code fullscreen",
						"insertdatetime media table paste",
					],
					toolbar:
						"undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat ",
				}}
				value={inventoryConfig.comment || ""}
				onEditorChange={(text) => {
					setInventoryConfig((old) => ({ ...old, comment: text }))

					clearTimeout(commentTimeout.current as NodeJS.Timeout)
					commentTimeout.current = setTimeout(() => {
						editInventoryConfig({ comment: text })
					}, 300)
				}}
			/>

			<div className="cardtabs-subtitle">Liste des produits</div>
			<div className="btn-group float-right mb-3">
				<button
					disabled={inventoryConfig.validatedAt}
					type="button"
					className="btn btn-sm btn-outline-info"
					onClick={() => changeAllState("OK")}>
					Tout définir comme présent
				</button>
				<button
					disabled={inventoryConfig.validatedAt}
					type="button"
					className="btn btn-sm btn-outline-info"
					onClick={() => changeAllState("DIFFERENCE")}>
					Tout définir comme manquant
				</button>
				<button
					disabled={inventoryConfig.validatedAt}
					type="button"
					className="btn btn-sm btn-outline-info"
					onClick={() => changeAllState("NONE")}>
					Réinitialiser
				</button>
			</div>

			<ClearUnaivalableProductsButton
				labelAlert="Cet inventaire"
				hasUnaivalableProducts={hasUnaivalableProducts}
				products={inventoryProducts}
				setLoading={setLoading}
				onFinish={() => {
					//@ts-ignore
					loadInventoryConfig(routeMatch?.params?.id)
					//@ts-ignore
					loadExistingInventory(routeMatch?.params?.id)
				}}
			/>

			<Table
				size={"small"}
				dataSource={sortedProducts}
				//@ts-ignore
				columns={tableColumns}
				pagination={false}
				rowClassName={(record: Record<string, any>) => {
					const classes = []

					if (record?.notAvailableAnymore) return "bg-danger"

					if (record.done) classes.push("state-done")
					if (record.state === "OK") classes.push("state-ok")
					else if (record.state === "DIFFERENCE") classes.push("state-difference")
					return classes.join(" ")
				}}
			/>

			<div className="cardtabs-subtitle">Export</div>
			<button
				type="button"
				className="btn btn-secondary mt-2 btn-block"
				onClick={() => exportToCsv(sortedProducts, setCsvConfig, csvLinkRef)}>
				Exporter l'inventaire en CSV
			</button>

			<div className="cardtabs-subtitle">Enregistrer</div>
			{!isAllProductsHandled && !inventoryConfig.validatedAt && (
				<button
					type="button"
					className="btn btn-primary mt-2 btn-block"
					disabled={isUpdating || isValidating || hasUnaivalableProducts}
					onClick={() => validateInventory()}>
					Finaliser l'inventaire
				</button>
			)}

			{isAllProductsHandled && (
				<div className="alert alert-danger mt-2 ">
					<span style={{ fontSize: "18px", fontWeight: "bold" }}>
						Vous ne pouvez finaliser l'inventaire tant que tous les produits n'ont pas été traités
					</span>
				</div>
			)}

			{inventoryConfig.validatedAt && (
				<div className="alert alert-primary mt-2 ">
					<span style={{ fontSize: "18px", fontWeight: "bold" }}>
						L'inventaire à été validé le{" "}
						{dayjs(inventoryConfig.validatedAt).format("dddd DD MMMM YYYY à HH[h]mm")}
					</span>
				</div>
			)}

			<DeleteLogisticForm
				logisticForm={inventoryConfig as InventoryType}
				withLabel={true}
				label={"l'inventaire"}
				redirect={"/mon-stock/inventaire"}
			/>

			<LoadingModal
				isOpen={isValidating || loading || stockAvailableHook.isLoading}
				message={isValidating ? "Enregistrement de l'inventaire..." : "Chargement de l'inventaire..."}
			/>

			{csvConfig.data && (
				<CSVLink
					// @ts-ignore
					ref={csvLinkRef}
					data={csvConfig.data}
					headers={csvConfig.headers}
					separator={";"}
					filename={csvConfig.filename}
					className="hidden"
					target="_blank"
				/>
			)}
		</>
	)
}

export default Inventory
