import { formatCurrency } from "../../services/functions"
import "./CoverageDemandAmount.scss"

interface Props {
	title: string
	price: number
}
export default function CoverageDemandAmount({ title, price }: Props) {
	return (
		<span className="coverage-demand-amount mr-2">
			<span className="title">{title}</span> <span className="price">{formatCurrency(price)}</span>
		</span>
	)
}
