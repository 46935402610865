/* eslint-disable react-hooks/exhaustive-deps */
import Chart from "react-apexcharts"
import fr from "apexcharts/dist/locales/fr.json"

const ChartBar = ({ series, colors, vertical }) => {
	const data = {
		options: {
			chart: {
				locales: [fr],
				defaultLocale: "fr",
				height: "100%",
				stacked: false,
				type: "bar",
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: !vertical,
				},
			},
			xaxis: {
				labels: { show: true },
			},
			yaxis: {
				labels: { show: true },
			},
			noData: {
				text: "pas assez d'informations pour produire le graphique",
			},
		},
	}
	data.options.legend = {
		show: true,
	}
	if (colors) data.options.colors = colors

	return (
		<>
			<Chart options={data.options} series={series} type="bar" width="100%" />
		</>
	)
}

ChartBar.defaultProps = {
	vertical: false,
	colors: null,
}

export default ChartBar
