import { Tooltip } from "antd"

const FileCompletedCol = (queryfilters) => {
	const fileCompletedRender = (status, patient) => {
		return status ? (
			<div className="dashboard-file-status file-complete">Complet</div>
		) : (
			<Tooltip
				overlayInnerStyle={{ width: "max-content" }}
				overlayStyle={{ whiteSpace: "break-spaces", width: "max-content" }}
				title={patient?.["patient.fileCompleted"]?.data ?? ""}
				color="orange">
				<div className="dashboard-file-status">Incomplet</div>
			</Tooltip>
		)
	}

	return {
		title: null,
		key: "fileCompleted",
		dataIndex: ["patient.fileCompleted", "status"],
		width: "85px",
		render: (d, row) => fileCompletedRender(d, row),
		filters: [
			{
				text: "Complet",
				value: true,
			},
			{
				text: "Incomplet",
				value: false,
			},
		],
		filteredValue: queryfilters?.fileCompleted || null,
		onFilter: (value, record) => record["patient.fileCompleted"].status === value,
	}
}

export default FileCompletedCol
