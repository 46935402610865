import { useLocation } from "react-router-dom"
import SettingsLeftBar from "./SettingsLeftBar"
import UserLeftBar from "./UserLeftBar"
import AccountManagementLeftBar from "./AccountManagementLeftBar"

const LeftBarRender = (): JSX.Element => {
	const location = useLocation()
	if (location.pathname.includes("parametres")) return <SettingsLeftBar />
	if (location.pathname.includes("gestion-compte")) return <AccountManagementLeftBar />
	else return <UserLeftBar />
}

export default LeftBarRender
