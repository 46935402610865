export const imageExtensions = ["png", "jpg", "jpeg", "svg"]
export const embedExtensions = ["pdf"]
export const textDocExtensions = ["docx", "doc", "odt", "nhax"]
export const spreadsheetExtensions = ["xlsx", "xsl"]
export const docsExtensions = [textDocExtensions, spreadsheetExtensions].flat()
export const modelExtensions = ["stl"]

export const supportedExtensions = [imageExtensions, embedExtensions, docsExtensions, modelExtensions].flat()

const icons: Record<string, string> = {
	pdf: "file-pdf",
}
for (const ext of imageExtensions) icons[ext] = "image"
for (const ext of textDocExtensions) icons[ext] = "file-alt"
for (const ext of spreadsheetExtensions) icons[ext] = "file-spreadsheet"
for (const ext of modelExtensions) icons[ext] = "cube"

/**
 * @returns Une icone Font-Awesome associé à l'extension. Si l'extension est undefined ou n'a pas d'icone associé, une icone de base de renvoyé.
 * @example fileExtensionIcon("pdf") === "fa-file-pdf"
 * @example fileExtensionIcon("pdf", false) === "file" // sans prefix
 * @example fileExtensionIcon("png") === "fa-image"
 * @example fileExtensionIcon(undefined) === "fa-file"
 * @example fileExtensionIcon("extension-qui-n'existe-pas") === "fa-file"
 */
export function fileExtensionIcon(extension?: string, faPrefix = true): string {
	const prefix = faPrefix ? "fa-" : ""
	if (extension == null) return `${prefix}file`

	return `${prefix}${icons[extension] ?? "file"}`
}
