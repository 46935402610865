import withRequiredServices, { ServiceRef } from "components/hoc/withRequiredServices"
import { Switch } from "antd"
import AuthContext from "contexts/AuthContext"
import React, { useContext, useRef, useState } from "react"
import { toast } from "react-toastify"
import { Col, Row } from "reactstrap"
import API from "services/API"
import "./MarqueBlanche.scss"
import { WhiteLabel } from "@audiowizard/common"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import ButtonRounded from "components/Buttons/ButtonRounded"

const supportedMimeTypes = ["image/png", "image/jpeg", "image/gif"]

const logoDimensionsLimit = {
	height: 55,
	width: 380,
}

const MarqueBlanche = (): JSX.Element => {
	const { user, setUser } = useContext(AuthContext)

	const primaryColorPickerRef = useRef<HTMLInputElement | null>(null)
	const secondaryColorPickerRef = useRef<HTMLInputElement | null>(null)

	const whiteLabel = user.company?.whiteLabel

	const [primaryColor, setPrimaryColor] = useState(whiteLabel?.primaryColor)
	const [secondaryColor, setSecondaryColor] = useState(whiteLabel?.secondaryColor)
	const [isActive, setIsActive] = useState(whiteLabel?.isActive)
	const [logoUrl, setLogoUrl] = useState(whiteLabel?.logo)
	const [logoFile, setLogoFile] = useState<File | null>(null)

	const handleLogo = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const logo = event.target.files?.item(0)

		if (logo == null) {
			throw new TypeError(
				`
					Expected to find value of type 
					"${File.name}" but found "null"
				`
			)
		}

		if (supportedMimeTypes.includes(logo.type) === false) {
			toast.error(
				`
					L'extension du logo n'est pas supportée, 
					${supportedMimeTypes.join(", ")} sont acceptées
				`
			)
			return
		}

		const logoUrl = URL.createObjectURL(logo)
		const image = new Image()

		image.addEventListener("load", () => {
			if (image.width > logoDimensionsLimit.width || image.height > logoDimensionsLimit.height) {
				URL.revokeObjectURL(logoUrl)
				toast.error(
					<>
						Le logo ne respecte pas les dimensions requises: <br />
						largeur {"<"} {logoDimensionsLimit.width} <br />
						hauteur {"<"} {logoDimensionsLimit.height}
					</>
				)
				return
			}

			setLogoFile(logo)
			setLogoUrl(logoUrl)
		})

		image.src = logoUrl
	}

	const save = async (): Promise<void> => {
		if (whiteLabel?.id == null) {
			throw new TypeError(
				`
					Expected to find value of type 
					"${Number.name}" but found "null"
				`
			)
		} else if (primaryColor == null || secondaryColor == null) {
			throw new TypeError(
				`
					Expected to find value of type 
					"${String.name}" but found "null"
				`
			)
		}

		const requestPromises = [
			API.update("WHITE_LABELS_API", whiteLabel?.id, {
				primaryColor: primaryColor,
				secondaryColor: secondaryColor,
				isActive: isActive,
			}),
		]

		if (logoFile != null) {
			requestPromises.push(API.updateWhiteLabelLogo(whiteLabel?.id, logoFile))
		}

		const responses = await Promise.all(requestPromises)
		const lastUpdated = responses.pop()

		setUser({
			...user,
			company: {
				...user.company!,
				whiteLabel: {
					...(lastUpdated?.data as WhiteLabel),
				},
			},
		})

		if (logoUrl != null) {
			URL.revokeObjectURL(logoUrl)
		}
	}

	return (
		<Col>
			<Row>
				<Col md="12">
					<SectionHeader title="Logo" />
					<div className="input-group mt-2 my-3">
						<div className="input-group-prepend">
							<span className="input-group-text" id="inputGroupFileAddon01">
								Uploader votre logo
							</span>
						</div>

						<div className="custom-file">
							<input type="file" className="custom-file-input" accept="image/*" onChange={handleLogo} />

							<label className="custom-file-label" htmlFor="inputGroupFile01">
								Choisissez le fichier ({logoDimensionsLimit.width}x{logoDimensionsLimit.height} pixels )
							</label>
						</div>
					</div>
				</Col>
				<Col md="12">
					{logoUrl && (
						<div id="logo-preview" className="marque-blanche-bg-transparency-motif mt-2 my-3">
							<img
								src={logoUrl}
								width={logoDimensionsLimit.width + "px"}
								height={logoDimensionsLimit.height + "px"}
								alt="Logo"
							/>
						</div>
					)}
				</Col>
				<Col md="12">
					<SectionHeader title="Couleurs" />
					<div className="row ml-0 mt-2 my-3 p-settings">
						<div
							className="col schedules-type cursor-pointer"
							onClick={() => {
								primaryColorPickerRef.current?.click()
							}}
							style={{
								borderColor: primaryColor || "#00000033",
								minWidth: "max-content",
							}}>
							<div
								className="label"
								style={{
									marginBottom: "unset",
									width: "100%",
								}}>
								Primaire
							</div>

							<div
								className="color"
								style={{
									backgroundColor: primaryColor || "#00000033",
								}}>
								<div className="color-picker-icon">
									<i className="fad fa-tint" />
								</div>

								<input
									type="color"
									ref={primaryColorPickerRef}
									onChange={(event) => setPrimaryColor(event.target.value)}
								/>
							</div>
						</div>

						<div
							className="col schedules-type cursor-pointer"
							onClick={() => {
								secondaryColorPickerRef.current?.click()
							}}
							style={{
								borderColor: secondaryColor || "#00000033",
								minWidth: "max-content",
							}}>
							<div
								className="label"
								style={{
									marginBottom: "unset",
									width: "100%",
								}}>
								Secondaire
							</div>

							<div
								className="color"
								style={{
									backgroundColor: secondaryColor || "#00000033",
								}}>
								<div className="color-picker-icon">
									<i className="fad fa-tint" />
								</div>

								<input
									type="color"
									ref={secondaryColorPickerRef}
									onChange={(event) => setSecondaryColor(event.target.value)}
								/>
							</div>
						</div>
					</div>
				</Col>
				<Col md="12">
					<SectionHeader title="Configuration" />
					<div className="mt-2 my-3">
						<Switch className="mr-2" checked={isActive} onChange={setIsActive} />
						Activer ou désactiver la fonctionnalité.
					</div>
				</Col>
				<div className="mt-2 my-3 ml-auto pr-2">
					<ButtonRounded color="primary-outlined" onClick={save}>
						Enregistrer
					</ButtonRounded>
				</div>
			</Row>
		</Col>
	)
}

export default withRequiredServices(MarqueBlanche)(ServiceRef.MarqueBlanche)
