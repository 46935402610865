/** Configuration du calendar, utilisé comme props dans le composant BryntumAgenda.  */
import dayjs from "dayjs"

import { Laboratory, Override } from "@audiowizard/common"
import {
	CalendarConfig,
	DomConfig,
	LocaleManager,
	PdfExport,
	PdfExportConfig,
	Widget,
	DayView,
	ResourceView,
} from "@bryntum/calendar"
import bankHoliday from "../../../datas/bankHoliday2025.json"

import { locale } from "./helpers/BryntumAgenda.custom.locale.Fr"
import { getFontColorForBackground, hexToRGB } from "services/functions"
import { detectPhone, getPercentage, onRenderView } from "./helpers/BryntumAgenda.helper"
import { AWCalendar, Event, TEMP_DASHBOARD } from "./helpers/AgendaTypes"
import { MyResourceFilter } from "./helpers/MyResourceFilter"
import { MyTbar } from "./helpers/MyTbar"
import { MySideBar } from "./helpers/MySideBar"

// Fichier de configuration pour le calendar

MyResourceFilter.initClass()
MyTbar.initClass()
MySideBar.initClass()

const bankHolidays = (Object.keys(bankHoliday) as Array<keyof typeof bankHoliday>).reduce((obj, key) => {
	return {
		...obj,
		[key]: bankHoliday[key],
	}
}, {})

LocaleManager.throwOnMissingLocale = true
LocaleManager.registerLocale("Fr", {
	desc: "French",
	locale: locale,
})
LocaleManager.applyLocale("Fr")

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dayHeaderRenderer = (dayHeaderDomConfig: any): void => {
	if (bankHolidays.hasOwnProperty(dayHeaderDomConfig.dataset.headerDate)) {
		dayHeaderDomConfig.className["b-highlight-day"] = 1
		dayHeaderDomConfig.children.push(
			`${bankHoliday[dayHeaderDomConfig.dataset.headerDate as keyof typeof bankHoliday]}`
		)
	}
	if (detectPhone()) {
		dayHeaderDomConfig.className["b-phone-view"] = 1
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dayWeekHeaderRenderer = (dayHeaderDomConfig: any): void => {
	if (bankHolidays.hasOwnProperty(dayHeaderDomConfig.dataset.headerDate)) {
		dayHeaderDomConfig.className["b-highlight-day"] = 1
		dayHeaderDomConfig.children.push(
			`${bankHoliday[dayHeaderDomConfig.dataset.headerDate as keyof typeof bankHoliday]}`
		)
	}
	if (detectPhone()) {
		dayHeaderDomConfig.className["b-cal-cell-header-phone"] = 1
		dayHeaderDomConfig.className["b-cal-cell-header"] = false
	}
}

// render of the colonne for the a day in the dayly and weekly view
const dayCellRenderer = (
	domConfig: DomConfig,
	cellData: { events: any[]; dayTime: { timeEnd: number; timeStart: number }; day: number },
	dayView: any
): any => {
	const calendar = dayView.up("calendar") as AWCalendar
	const gradient: string[] = []
	const doctolib: string[] = []
	const label: string[] = []
	const background: string[] = []
	// @ts-ignore
	if (bankHolidays.hasOwnProperty((domConfig?.dataset as { date: string }).date)) {
		domConfig.style = "background:linear-gradient(rgba(150,150,150,0.2) 0% 100%)"
		return domConfig
	}
	if (calendar.extraData.attendances) {
		let lastEnd = cellData.dayTime.timeStart
		const activeLaboratory = calendar.widgetMap.laboratoryFilter.value as unknown as { value: number }[]
		calendar.extraData.attendances
			.fetchZonesForUser(
				dayView.resource["@id"],
				cellData.day,
				activeLaboratory.map((lab: { value: number }) => lab.value)
			)
			.forEach((event: { startTime: string; endTime: string; color: string; laboratory: Laboratory }) => {
				const isDoctolib = event.laboratory
					? calendar.extraData.agendaStore.isDoctolib(dayView.resource, event.laboratory)
					: false
				let startTime = dayjs(event.startTime, "HH:mm").valueOf() - dayjs().startOf("day").valueOf()
				let endTime = dayjs(event.endTime, "HH:mm").valueOf() - dayjs().startOf("day").valueOf()
				startTime = startTime > lastEnd ? startTime : lastEnd
				endTime = endTime > cellData.dayTime.timeEnd ? cellData.dayTime.timeEnd : endTime
				lastEnd = endTime
				const startPercent = getPercentage(startTime, cellData.dayTime.timeStart, cellData.dayTime.timeEnd)
				const endPercent = getPercentage(endTime, cellData.dayTime.timeStart, cellData.dayTime.timeEnd)
				gradient.push(
					`${
						event.color ? hexToRGB(event.color, "0.6") : "rgba(234,235,240,0.2)"
					} ${startPercent}% ${endPercent}%`
				)

				if (event.laboratory && event.laboratory.label) {
					const svgUrlEncode = encodeURI(
						`data:image/svg+xml;utf16,<svg xmlns='http://www.w3.org/2000/svg' version="1.1" height='30px' width='100%'><text x='0' y='25' fill='white' font-size='1em'>${event.laboratory.label}</text></svg>`
					)

					label.push(
						`no-repeat 5px calc(${startPercent}% + ${Math.round(
							(startPercent / 100) * 30
						)}px) / 100% url("${svgUrlEncode}")`
					)
				}
				if (isDoctolib) {
					doctolib.push(
						`no-repeat 50% calc(${(startPercent + endPercent) / 2}% + ${Math.round(
							(startPercent / 100) * 50
						)}px - 25px) / 50px url("/static/img/doctolib/D_Doctolib_white.png")`
					)
				}
			})
	}
	if (doctolib.length > 0) {
		background.push(doctolib.join(", "))
	}
	background.push(`linear-gradient(${gradient.join(", ")})`)
	domConfig.style = `background:${background.join(", ")}`

	domConfig.style += ", rgba(240,240,240,0.2);"
	return domConfig
}

const hatchEventBackground = (eventRecord: any): string => {
	const eventColorRGB = hexToRGB(eventRecord.eventColor, "1")
	const eventColorRGBless = hexToRGB(eventRecord.eventColor, "0.6")

	const newClass = document.createElement("style")
	newClass.innerHTML = `
	.eventIsDone${eventRecord.id
		.toString()
		.replace(
			/:/g,
			"_"
		)} > div {background: linear-gradient(45deg, ${eventColorRGB} 20%, ${eventColorRGBless} 20% 40%, ${eventColorRGB} 40% 60%, ${eventColorRGBless} 60% 80%, ${eventColorRGB} 80% 100% ) !important}
		.eventIsDone${eventRecord.id} > div > div {background-color: rgba(255, 255, 255, ${
		eventRecord.state === "DONE" ? "0.35" : "0.7"
	}) !important}
		`
	document.body.appendChild(newClass)
	return `eventIsDone${eventRecord.id.toString().replace(/:/g, "_")}`
}

const scheduleRenderer = ({
	eventRecord,
	renderData,
	calendar,
	view,
}: {
	eventRecord: any
	renderData: any
	calendar: AWCalendar
	view: "day" | "week"
}): string | false => {
	let patientId
	if (!eventRecord.meta.isCreating && eventRecord.data.patient) {
		patientId = eventRecord.data.patient["@id"].split("/")[2]
	}

	if (
		renderData &&
		renderData.cls &&
		calendar.extraData.appointmentFromScheduleSelector &&
		(calendar.extraData.appointmentFromScheduleSelector.id === eventRecord.id ||
			(eventRecord.id.endsWith && eventRecord.id.endsWith(TEMP_DASHBOARD))) // id could be numeric or string
	) {
		renderData.cls["selected-event"] = 1
	}

	if (renderData && renderData.cls) {
		const typeToHash = ["DONE", "CANCELLED", "MISSED", "EXCUSED"]
		if (typeToHash.indexOf(eventRecord.state) >= 0) {
			renderData.cls[`${hatchEventBackground(eventRecord)}`] = 1
		}
		if (eventRecord.state === "WAITING_ROOM") {
			renderData.cls["arrived-event"] = 1
		}
		renderData.style.color = "black"
	}

	if (view === "week" && detectPhone()) {
		return `
			<div class="b-schedule-week-phone"> ${eventRecord?.patient?.lastName} </div>
		`
	}

	if (eventRecord.readOnly && eventRecord.laboratory) {
		return `
						<div class="laboratory-name" style="background-color:${eventRecord.agendaColor}">${eventRecord.laboratory.label}</div>
						`
	}
	const startDate = dayjs(eventRecord.startDate)
	const endDate = dayjs(eventRecord.endDate)
	const duration = endDate.diff(startDate, "minutes", false)

	return `
						<div class="event-renderer" style="color:${getFontColorForBackground(eventRecord.eventColor)};">
							<div class="event-renderer-data">
								<div class="event-renderer-time-patient">
									<span id="event-renderer-date"> ${dayjs(eventRecord.startDate).format("HH:mm")} - ${dayjs(eventRecord.endDate).format(
		"HH:mm"
	)} </span>
									<span class="b-event-name" style="fontsize:smaller !important;" > 
										${eventRecord?.name ?? ""}
										${
											eventRecord?.state === "WAITING_ROOM" || eventRecord?.state === "DONE"
												? "<i class='fa-solid fa-check text-info'></i>"
												: ""
										}
										${
											eventRecord?.state === "MISSED" || eventRecord?.state === "CANCELLED"
												? "<i class='fad fa-times-circle text-danger'></i>"
												: ""
										}
									</span>
								</div>
								${duration >= 20 ? `<span class="b-event-name" style="font-weight: bold;" >${eventRecord?.typeLabel ?? ""}</span>` : ""}
							</div>
							<div class="event-renderer-link">
								${eventRecord["@id"] ? `<span onclick="openPatientFolder(${patientId})"> <i class="fad fa-user"></i> </span>` : ""}
							</div>
						</div>
						
					`
}

const onViewCreate = ({
	source,
}: {
	source: Override<ResourceView, { calendar: AWCalendar; view: DayView }>
}): void => {
	if (source.calendar.extraData.attendances) {
		source.eachView((view: any) => {
			view.updateNonWorkingDays({
				0: !source.calendar.extraData.attendances.showSunday[view.resource["@id"]],
				6: false,
			})
			view.nonWorkingDays = {
				0: !source.calendar.extraData.attendances.showSunday[view.resource["@id"]],
				6: false,
			}
		})
	}
	if (source.calendar.extraData.fromDashboard) {
		// @ts-ignore library not complete
		source.view.autoCreate = {
			gesture: "click",
		}
	} else {
		// @ts-ignore library not complete
		source.view.autoCreate = {
			gesture: "dblclick",
		}
	}
}

export const calendarConfig: CalendarConfig = {
	tbar: {
		type: "myTbar",
	},
	sidebar: {
		type: "mySideBar",
	},

	date: new Date(),
	// Modes are the views available in the Calendar.
	// An object is used to configure the view.
	modes: {
		// Let's not show the default views
		day: null,
		week: null,
		month: null,
		year: null,

		// Render an icon showing number of invitees (editable in the event editor)

		// Mode name can be anything if it contains a "type" property.
		dayResources: {
			type: "resource",
			title: "Jour",

			resourceWidth: `${detectPhone() ? "" : "30em"}`,
			hideNonWorkingDays: false,
			visibleStartTime: 8,
			dayStartTime: "00:00",
			dayEndTime: "24:00",

			view: {
				type: "dayview",
				hourHeight: 100,
				// These two settings decide what time span is rendered

				// Scroll to 8am initially
				increment: "5 minutes",
				tools: {
					close: {
						cls: "b-fa b-fa-times",
						tooltip: "Fermer cet agenda",
						handler(): void {
							// @ts-ignore
							const calendar = this.up("calendar"),
								resourceFilter = calendar.widgetMap.resourceFilter,
								// @ts-ignore
								resource = this.resource

							resourceFilter.selected.remove(resource)
						},
					},
				},
				strips: {
					resourceInfo: {
						type: "widget",
						dock: "header",
						html(): string | void {
							if (!detectPhone()) return

							return `<div class="custom-day-cell custom-day-current">${dayjs().format("D")}</div>`
						},
					},
				},
				dayHeaderRenderer: dayHeaderRenderer,
				dayCellRenderer(
					domConfig: DomConfig,
					cellData: { events: Event[]; dayTime: { timeEnd: number; timeStart: number }; day: number }
				): any {
					return dayCellRenderer(domConfig, cellData, this)
				},

				eventRenderer({ eventRecord, renderData }: { eventRecord: any; renderData: any }): string | false {
					// @ts-ignore
					const calendar = this.up("calendar") as AWCalendar
					const view = "day"
					return scheduleRenderer({ eventRecord, renderData, calendar, view })
				},
				onPaint() {
					const view = this as unknown as DayView
					onRenderView(view.owner as unknown as { viewCache: Widget[] }, view.id)
				},
				onRefresh() {
					const view = this as unknown as DayView
					if (view.scrollable.y === 0) {
						onRenderView(view.owner as unknown as { viewCache: Widget[] }, view.id)
					}
				},
			},
			onRefresh() {
				const view = this as unknown as ResourceView
				if (view.items && (view.items as Widget[])[0].scrollable?.y === 0) {
					onRenderView(view as unknown as { viewCache: Widget[] })
				}
			},

			meta: (resource: any): Laboratory => {
				return resource.laboratory
			},
			onViewCreate(source: any) {
				onViewCreate(source)
			},
		},
		weekResources: {
			// Type has the final say over which view type is created
			type: "resource",
			title: "Semaine",
			// Specify how wide each resource panel should be
			resourceWidth: "75vw",
			hideNonWorkingDays: true,
			dayStartTime: "00:00",
			dayEndTime: "24:00",
			visibleStartTime: 8,
			// This is a config object for the subviews
			view: {
				// par default il faut mettre a false, autrement le dimanche n’est jamais affiché
				// quelque soit la configuration.
				nonWorkingDays: {
					0: false, // Sunday
					6: false, // Saturday
				},
				// par default le dimanche apparaît au début tableau
				weekStartDay: 1,
				hourHeight: 100,
				// Dock an additional widget at the end of the header
				increment: "5 minutes",
				strips: {
					// A simple widget showing location, temperature and a weather icon for each resource
					resourceInfo: {
						type: "widget",
						dock: "header",
						cls: "b-resource-location",
					},
				},
				// Show a close icon to filter out the resource
				tools: {
					close: {
						cls: "b-fa b-fa-times",
						tooltip: "Fermer cet agenda",
						handler(): void {
							// @ts-ignore
							const calendar = this.up("calendar"),
								resourceFilter = calendar.widgetMap.resourceFilter,
								// @ts-ignore
								resource = this.resource

							resourceFilter.selected.remove(resource)
						},
					},
				},

				dayHeaderRenderer: dayWeekHeaderRenderer,
				dayCellRenderer(
					domConfig: DomConfig,
					cellData: { events: any[]; dayTime: { timeEnd: number; timeStart: number }; day: number }
				): any {
					return dayCellRenderer(domConfig, cellData, this)
				},
				eventRenderer({ eventRecord, renderData }: { eventRecord: any; renderData: any }): string | false {
					// @ts-ignore
					const calendar = this.up("calendar") as AWCalendar
					const view = "week"
					return scheduleRenderer({ eventRecord, renderData, calendar, view })
				},
				onPaint(event: any) {
					// nécessaire pour ne pas repositionner le calendrier lors de la sélection de rdv
					if (!event.source.owner.containsFocus) {
						const view = this as unknown as DayView
						onRenderView(view.owner as unknown as { viewCache: Widget[] }, view.id)
					}
				},
				onRefresh() {
					const view = this as unknown as DayView
					if (view.scrollable.y === 0) {
						onRenderView(view.owner as unknown as { viewCache: Widget[] }, view.id)
					}
				},
			},
			onRefresh() {
				const view = this as unknown as ResourceView
				if (view.items && (view.items as Widget[])[0].scrollable?.y === 0) {
					onRenderView(view as unknown as { viewCache: Widget[] })
				}
			},
			onViewCreate(source: any) {
				onViewCreate(source)
			},

			// Info to display below a resource name
			meta: (resource: any): Laboratory => {
				return resource.laboratory
			},
		},
		monthResources: {
			type: "resource",
			title: "Mois",
			resourceWidth: "30em",
			hideNonWorkingDays: false,
			view: {
				type: "monthview",
				increment: "5 minutes",
				tools: {
					close: {
						cls: "b-fa b-fa-times",
						tooltip: "Fermer cet agenda",
						handler(): void {
							// @ts-ignore
							const calendar = this.up("calendar"),
								resourceFilter = calendar.widgetMap.resourceFilter,
								// @ts-ignore
								resource = this.resource

							resourceFilter.selected.remove(resource)
						},
					},
				},
				eventRenderer: ({ eventRecord, renderData }: { eventRecord: any; renderData: any }): string => {
					if (eventRecord.readOnly && eventRecord.laboratory) {
						return `
						<div class="laboratory-name" style="background-color:${eventRecord.agendaColor}">${eventRecord.laboratory.label}</div>
						`
					}
					if (eventRecord.recurrence) {
						return `
						<span class="b-event-name" >${eventRecord.typeLabel}</span>
						
					`
					}
					return `
						<span class="b-event-name" >${eventRecord.name}</span>
						
					`
				},
				dayCellRenderer(cellData: any) {
					if (bankHolidays.hasOwnProperty(cellData.key)) {
						cellData.cls["hackathon-dayoff"] = true

						cellData.headerStyle.fontWeight = "bold"

						// Mutate day cell information
						cellData.isNonWorking = true

						return `${bankHolidays[cellData.key as keyof typeof bankHolidays]}`
					}
				},
			},

			meta: (resource: any): Laboratory => {
				return resource.laboratory
			},
		},
		agenda: {
			index: 5,
			range: "week",
			eventRenderer({ eventRecord, renderData }: { eventRecord: any; renderData: any }): string | false {
				let patientId
				if (!eventRecord.meta.isCreating && eventRecord.data.patient) {
					patientId = eventRecord.data.patient["@id"].split("/")[2]
				}
				// @ts-ignore
				const calendar = this.up("calendar") as AWCalendar
				if (
					renderData &&
					renderData.cls &&
					calendar.extraData.appointmentFromScheduleSelector &&
					calendar.extraData.appointmentFromScheduleSelector.id === eventRecord.id
				) {
					renderData.cls["selected-event"] = 1
				}

				return `
						<div class="agenda-event-renderer">

							<div class="agenda-event-renderer-label-nom"> 
								${eventRecord?.patient?.lastName ?? ""} ${eventRecord?.patient?.firstName ?? ""}
								${eventRecord?.state === "WAITING_ROOM" ? "<i class='fad fa-solid fa-check text-info'></i>" : ""}
							</div>

							<div class="agenda-event-renderer-label-type">${eventRecord?.status?.split("_").join(" ") ?? ""}</div>

							<div class="agenda-event-renderer-link">
								${eventRecord["@id"] ? `<span onclick="openPatientFolder(${patientId})"> <i class="fad fa-user"></i> </span>` : ""}
							</div>
						</div>
						
					`
			},
			onBeforeShow() {
				const forbiddenRange = ["listRangeDecadeItem", "listRangeYearItem"]
				forbiddenRange.forEach((range) => {
					// @ts-ignore
					delete this.listRangeMenu.items[range]
				})
				return true
			},
		},
		list: {
			range: "day",
			tbar: {
				height: "4em",
				items: {
					selectRange: {
						type: "radiogroup",
						label: "Période :",
						name: "selectRange",
						cls: "select-range-list",
						options: {
							day: "jour",
							week: "semaine",
							month: "mois",
						},
						onChange({ value }: { value: string }) {
							// @ts-ignore librayrie doesn’t define up
							this.up("EventList").range = value
						},
					},
				},
			},
			columns: [
				{
					text: "Patient",
					field: "name",
					minWidth: 200,
					editor: false,
				},
				{
					text: "Type",
					field: "typeLabel",
					autoWidth: true,
					align: "left",
					editor: false,
					groupable: false,
				},
				{
					field: "startDate",
					autoWidth: true,
					editor: false,
					groupable: false,
				},
				{
					field: "endDate",
					autoWidth: true,
					editor: false,
					groupable: false,
				},
				{
					text: "Statut",
					field: "state",
					renderer({ record, grid }: { record: Event; grid: any }): string {
						if (grid.calendar.extraData.stateStore.getById(record.state)) {
							return grid.calendar.extraData.stateStore.getById(record.state).state
						}
						return "inconnue"
					},
					align: "left",
					autoWidth: true,
					editor: false,
					groupable: false,
				},
				{
					text: "Utilisateur",
					renderer({ record }: { record: Event }): string {
						return `${record.user.firstName} ${record.user.lastName}`
					},
					field: "user",
					autoWidth: true,
					editor: false,
					groupable: false,
				},
				{
					text: "Laboratoire",
					renderer({ record }: { record: Event }): string {
						return record.laboratory.label || ""
					},
					autoWidth: true,
					field: "laboratory",
					editor: false,
					groupable: false,
				},
			],
			features: {
				sort: {
					multiSort: false,
				},
				stripe: true,
				headerMenu: {
					items: {
						groupRemove: false,
						filter: false,
						sortAsc: false,
						sortDesc: false,
					},
				},
				pdfExport: {
					onExportDialogExport: async function (_config: PdfExportConfig) {
						const pdfExport = this as unknown as PdfExport
						const { client, pagesPerRequest } = this as unknown as {
							client: Widget
							pagesPerRequest: number
						}

						// @ts-ignore
						const config = pdfExport.buildExportConfig(_config)
						if (
							client.trigger("beforePdfExport", {
								config,
							}) !== false
						) {
							// @ts-ignore
							client.mask(this.exportMask)

							try {
								// @ts-ignore
								const exporter = this.getExporter(config.exporterConfig)

								if (pagesPerRequest === 0) {
									const pages = await exporter.export(config)
									/**
									 * Fires when export progress changes
									 * @event exportStep
									 * @param {Number} progress Current progress, 0-100
									 * @param {String} text Optional text to show
									 */
									// @ts-ignore
									this.owner.calendar.trigger("print", pages)
								}
								// pages here is an array of objects: [{ html : '<html>...</html' }]
							} catch (e) {
								console.error(e)
							}
						}
					},
				},
			},
			onBeforeShow() {
				const forbiddenRange = ["listRangeDecadeItem", "listRangeYearItem"]
				forbiddenRange.forEach((range) => {
					// @ts-ignore
					delete this.listRangeMenu.items[range]
				})
				// @ts-ignore librairy doesn’t type everything in object
				this.tbar.widgetMap.selectRange_day.checked = true
				return true
			},
		},
	},
	// Called as the showUnassigned and resourceFilterFilter onChange handler
	// @ts-ignore
	onFilterCriteriaChange(): void {
		// No params means just re-evaluate the filter.
		// @ts-ignore
		this.eventStore.filter()
	},
}
