import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import { Dispatch, SetStateAction } from "react"
import { Laboratory } from "@audiowizard/common"
import { Select } from "antd"

const { Option } = Select

export type FiltersType = {
	senderLaboratory: Laboratory | null
	recipientLaboratory: Laboratory | null
	generateInvoice: boolean | null
}

type FiltersProps = {
	filters: FiltersType
	setFilters: Dispatch<SetStateAction<FiltersType>>
}

const retrocessionOptionValue: { [key: string]: boolean | null } = {
	all: null,
	true: true,
	false: false,
}

const Filters = ({ filters, setFilters }: FiltersProps): JSX.Element => {
	return (
		<div className="row">
			<div className="col">
				<label>Émetteur</label>
				<AsyncLaboratorySelect
					className="w-100"
					onChange={(_, laboratory) => {
						setFilters((old) => ({ ...old, senderLaboratory: laboratory }))
					}}
				/>
			</div>

			<div className="col">
				<label>Destinataire</label>
				<AsyncLaboratorySelect
					value={filters?.recipientLaboratory}
					className="w-100"
					onChange={(_, laboratory) => {
						setFilters((old) => ({ ...old, recipientLaboratory: laboratory }))
					}}
				/>
			</div>

			<div className="col">
				<label>Avec facture de rétrocession</label>
				<Select
					size="middle"
					className="w-100"
					defaultValue="all"
					onChange={(value: string) => {
						setFilters((old) => ({ ...old, generateInvoice: retrocessionOptionValue[value] }))
					}}>
					<Option value={"all"}>Tout voir</Option>
					<Option value="true">Avec</Option>
					<Option value="false">Sans</Option>
				</Select>
			</div>
		</div>
	)
}

export default Filters
