import TableDateRangeFilter from "components/utils/TableDateRangeFilter"
import dayjs from "dayjs"
import { capitalizeFirstLetter } from "utils/types/primitives/String/capitalizeFirstLetter"

const LastScheduleDateCol = (editingKey, isAddingLead, queryfilters, sort) => {
	const isToday = (date) => dayjs().isSame(dayjs(date), "day")
	const isTomorrow = (date) => dayjs().add(1, "day").isSame(dayjs(date), "day")

	const lastScheduleDateOfRender = (date, record) => {
		if (isAddingLead && editingKey === record.key) {
			date = record["patient.scheduleDate"]
		}
		if (date)
			return (
				<span>
					{isToday(date)
						? "Aujourd'hui" + dayjs(date).format(" à HH:mm")
						: isTomorrow(date)
						? "Demain" + dayjs(date).format(" à HH:mm")
						: capitalizeFirstLetter(dayjs(date).format("dddd DD/MM/YYYY à HH:mm"))}
				</span>
			)
		return <p className="m-0"> Aucun rendez-vous </p>
	}

	return {
		title: null,
		key: "lastScheduleDateOf",
		dataIndex: "patient.lastScheduleDateOf",
		width: "210px",
		sorter: !isAddingLead && editingKey === "",
		sortOrder: sort?.field === "patient.lastScheduleDateOf" ? sort.order : null,
		filterDropdown: TableDateRangeFilter,
		filteredValue: queryfilters.lastScheduleDateOf,
		render: lastScheduleDateOfRender,
	}
}

export default LastScheduleDateCol
