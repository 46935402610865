import { HiboutikProductEntity } from "@audiowizard/common"

export type ProductFromSalesToConvertType = HiboutikProductEntity & {
	serialNumber?: string
	sizeId: number
	sizeName: string
	quantity: number
}

export type PaymentTypeTags =
	| "CB"
	| "CHE"
	| "ESP"
	| "VIR"
	| "DIV"
	| "SECU"
	| "MTL"
	| "MUTL"
	| "SMDP"
	| "RBC"
	| "RBV"
	| "RBE"
	| "RBO"
	| "RBM"
	| "RBS"
	| "CMU"
	| "GU"
	| "CRED"
	| "WEB"
	| "FINAN"
	| "SUIVI"
	| "BDCT"
	| "RMCS"
	| "FLOA"
	| "FIDL"
	| "FRANF"
	| "VFLOA"
	| "FFLOA"
	| "FAUDI"
	| "VAUDI"

const paymentTypeLabel: Record<Partial<PaymentTypeTags>, { label: string; icon: string }> = {
	CB: { label: "Carte Bancaire", icon: "fa-credit-card" },
	CHE: { label: "Chèque", icon: "fa-money-check-edit" },
	CRED: { label: "Crédit client", icon: "fa-sack" },
	DIV: { label: "Différé / Multi-paiements", icon: "fa-sack" },
	ESP: { label: "Espèce", icon: "fa-money-bill" },
	MTL: { label: "Mutuelle", icon: "fa-sack" },
	MUTL: { label: "Mutuelle", icon: "fa-sack" },
	CMU: { label: "Mutuelle - C2S/CMU", icon: "fa-sack" },
	GU: { label: "Mutuelle - Gestion unique", icon: "fa-sack" },
	RBC: { label: "Remboursement chèque", icon: "fa-gift-card" },
	RBV: { label: "Remboursement virement", icon: "fa-gift-card" },
	RBE: { label: "Remboursement espèce", icon: "fa-gift-card" },
	RBO: { label: "Remboursement organisme", icon: "fa-gift-card" },
	RBM: { label: "Remboursement mutuelle", icon: "fa-gift-card" },
	RBS: { label: "Remboursement sécu", icon: "fa-gift-card" },
	SMDP: { label: "Sans moyen de paiement", icon: "fa-ban" },
	SECU: { label: "Sécurité Sociale", icon: "fa-sack" },
	SUIVI: { label: "Sécurité Sociale - Contrôle", icon: "fa-sack" },
	VIR: { label: "Virement", icon: "fa-university" },
	BDCT: { label: "Bon d'achat", icon: "fa-ticket-simple" },
	WEB: { label: "Web", icon: "fa-sack" },
	FINAN: { label: "Financement", icon: "fa-sack" },
	RMCS: { label: "Remise commerciale", icon: "fa-badge-percent" },
	FLOA: { label: "FLOA", icon: "FLOA_icon" },
	FIDL: { label: "Point fidélité", icon: "fa-gift" },
	FRANF: { label: "Franfinance", icon: "fa-franc-sign" },
	FAUDI: { label: "Frais Audioprotect", icon: "fa-franc-sign" },
	VAUDI: { label: "Virement Audioprotect", icon: "fa-gift-card" },
	FFLOA: { label: "Frais Floa", icon: "fa-franc-sign" },
	VFLOA: { label: "Virement Floa", icon: "fa-gift-card" },
}

const refundPaymentMethods: PaymentTypeTags[] = ["RBC", "RBV", "RBE", "RBM", "RBS", "RBO"]
const defaultSinglePaymentMethods: PaymentTypeTags[] = ["CB", "CHE", "VIR", "ESP", "SMDP"]
const defaultDeferredPaymentMethods: PaymentTypeTags[] = ["CB", "SECU", "CHE", "VIR", "ESP", "CMU", "MUTL"]

const getCategoryForEquipments = (category: string): string => {
	return category.toLocaleUpperCase()
}

const categoryThatRequireSerialnumber = [
	"APPAREILS AUDITIFS",
	"AUDITIF",
	"ACCESSOIRE",
	"ACCESSOIRES",
	"EMBOUT AVEC SN",
	"ECOUTEUR AVEC SN",
	"ACCESSORIES",
	"ANCRAGE OSSEUX AVEC SN",
	"ACCESSOIRES IMPLANTS AVEC SN",
	"CROS",
]

const categoryToAddInPatientEquipments = [
	"APPAREILS AUDITIFS",
	"AUDITIF",
	"ACCESSOIRE",
	"ACCESSOIRES",
	"ECOUTEUR",
	"ECOUTEURS",
	"EMBOUT AVEC SN",
	"ECOUTEUR AVEC SN",
	"ANCRAGE OSSEUX AVEC SN",
	"ACCESSOIRES IMPLANTS AVEC SN",
	"EMBOUT",
	"EMBOUTS",
	"ACCESSORIES",
	"EMBOUT REMBOURSE",
	"CROS",
]

const getCategory = (name: string): string => {
	if (name === "Appareils auditifs".toLocaleUpperCase()) return "AUDITIF"
	else if (name === "Piles".toLocaleUpperCase()) return "PILES"
	else if (name === "Accessoire".toLocaleUpperCase()) return "ACCESSOIRE"
	else if (name === "Ecouteur".toLocaleUpperCase()) return "ECOUTEURS"
	else if (name === "Ecouteurs avec numéros de série".toLocaleUpperCase()) return "ECOUTEURS"
	else if (name === "Embout".toLocaleUpperCase() || name.toLocaleUpperCase().includes("EMBOUT")) return "EMBOUTS"
	else return name
}

const getQuantity = (product: any): number => {
	if (product.category === "PILE" && !product.lpp.remaining) return 0
	return +product.qte
}

const calcTeletransAmount = (teletransData: Record<string, any>): Record<string, any> => {
	let totalAmc = 0
	let totalAmo = 0

	if (teletransData.secu) {
		totalAmo = teletransData.actes.reduce((acc: number, item: Record<string, any>) => {
			const quantity = getQuantity(item)
			return (+item?.montantAmo || 0) * quantity + acc
		}, 0)
	}
	if (teletransData.mutl) {
		totalAmc = teletransData.actes.reduce((acc: number, item: Record<string, any>) => {
			const quantity = getQuantity(item)
			return (+item?.montantAmc || 0) * quantity + acc
		}, 0)
	}

	return { totalAmc, totalAmo, total: +totalAmc + +totalAmo }
}

const convertToOldDataSystem = (product: ProductFromSalesToConvertType): Record<string, any> => {
	return {
		product_model: product.model,
		product_price: product.price,
		product_category: product.categoryId,
		category_name: product.categoryName,
		stock_available: product?.quantity ?? product.quantityAvailable ?? 0,
		product_stock_management: product.stockManagement,
		product_id: product.id,
		product_serial_number: product?.serialNumber,
		size_id: product?.sizeId,
		product_size_id: product?.sizeId,
		size_name: product?.sizeName,
		supplier_name: product?.supplierName,
	}
}

export {
	paymentTypeLabel,
	getCategory,
	getCategoryForEquipments,
	categoryToAddInPatientEquipments,
	calcTeletransAmount,
	categoryThatRequireSerialnumber,
	refundPaymentMethods,
	defaultSinglePaymentMethods,
	defaultDeferredPaymentMethods,
	convertToOldDataSystem,
}
