import { FileTextOutlined } from "@ant-design/icons"
import { Transfer, TransferProduct } from "@audiowizard/common"
import dayjs from "dayjs"
import { useRef, useState } from "react"
import { CSVLink } from "react-csv"
import ReactTooltip from "react-tooltip"
import API from "services/API"

interface Props {
	transfer: Transfer
}

const csvHeaders = [
	{
		label: "Produit",
		key: "model",
	},
	{
		label: "Déclinaison",
		key: "sizeName",
	},
	{
		label: "Quantité",
		key: "quantity",
	},
	{
		label: "Numéro de série",
		key: "serialNumber",
	},
	{
		label: "Prix d'achat (HT)",
		key: "price",
	},
]

const ExportCsvButton = ({ transfer }: Props): JSX.Element => {
	const [csvConfig, setCsvConfig] = useState<Record<string, any>>({ loading: false })
	const csvLinkRef = useRef()

	const getData = async (transfer: Transfer): Promise<void> => {
		try {
			const products = await API.findAll<TransferProduct[]>(
				"TRANSFER_PRODUCT_API",
				`?transfer=${transfer["@id"]}&pagination=false`
			)

			setCsvConfig({
				loading: false,
				data: products.map((product) => ({
					...product,
					sizeName: product.sizeName ?? "Pas de déclinaison",
					serialNumber: product.serialNumber ?? "Pas de numéro de série",
				})),
				//@ts-ignore
				filename: `Transfert du ${dayjs(transfer.createdAt).format("MM/DD/YYYY")}`,
			})

			setTimeout(() => {
				// @ts-ignore
				csvLinkRef.current?.link.click()
			}, 200)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<button type="button" className={"btn btn-block btn-sm p-1 m-0"} onClick={() => getData(transfer)}>
				<ReactTooltip type="success" effect="solid" />

				<FileTextOutlined className="text-lg text-primary" data-tip="Exporter en .csv" />
			</button>

			{csvConfig.data && (
				<CSVLink
					// @ts-ignore
					ref={csvLinkRef}
					data={csvConfig.data}
					headers={csvHeaders}
					separator={";"}
					filename={csvConfig.filename}
					className="hidden"
					target="_blank"
				/>
			)}
		</>
	)
}

export default ExportCsvButton
