import { Form } from "antd"
import { PhoneInputOnly } from "components/forms/PhoneInput"
import { formatPhoneNumber } from "services/functions"

const PhoneMobileCol = (editingKey) => {
	function phoneMobileRender(phoneMobile, record) {
		return editingKey === record.key ? (
			<Form.Item
				name={"patient.phoneMobile"}
				style={{
					margin: 0,
				}}>
				<PhoneInputOnly inputStyle={{ width: "120px", fontSize: "14px", padding: "5px" }} />
			</Form.Item>
		) : (
			<span className="mx-2">{formatPhoneNumber(phoneMobile).replace(/\s/g, "")}</span>
		)
	}

	return {
		title: null,
		key: "phoneMobile",
		dataIndex: "patient.phoneMobile",
		width: "150px",
		render: phoneMobileRender,
	}
}

export default PhoneMobileCol
