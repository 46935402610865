import { ParametreRoutePrefix } from "router/Personnalisation/routes"

export const CatalogueRoutePrefix = ParametreRoutePrefix + "/catalogues"

export const CatalogueRoutes = {
	Produit: `${CatalogueRoutePrefix}/produit/:id`,
	NouveauProduit: `${CatalogueRoutePrefix}/nouveau-produit`,
	Produits: `${CatalogueRoutePrefix}/produits`,
	VérificationProduits: `${CatalogueRoutePrefix}/verification-produits`,
	Marques: `${CatalogueRoutePrefix}/marques`,
	Fournisseurs: `${CatalogueRoutePrefix}/fournisseurs`,
	Catégories: `${CatalogueRoutePrefix}/categories`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(CatalogueRoutePrefix, "")
}
