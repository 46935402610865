import { Typography } from "antd"
import { useContext } from "react"
import AuthContext from "../../contexts/AuthContext"

export const TableActions = ({ record, editingKey, edit, setNoteKey, setCurrentRecord }) => {
	const { setUiDisplay } = useContext(AuthContext)
	return (
		<div className="dashboard-table-actions">
			<Typography.Link
				title="Editer"
				className="text-primary"
				disabled={editingKey !== ""}
				onClick={() => edit(record)}>
				<i className="fad fa-edit fa-lg mx-1" />
			</Typography.Link>
			<Typography.Link
				title="Ajouter rendez-vous"
				className="text-primary"
				disabled={editingKey !== ""}
				onClick={() => {
					setUiDisplay((old) => ({
						...old,
						scheduleModal: true,
						selectedPatient: {
							id: record["patient.id"],
							firstName: record["patient.firstName"],
							lastName: record["patient.lastName"],
						},
					}))
				}}>
				<i className="fad fa-calendar fa-lg mx-1" />
			</Typography.Link>
			<Typography.Link
				title="Ouvrir bloc notes"
				className="text-primary"
				onClick={() => {
					setCurrentRecord(record)
					setNoteKey(record.key)
				}}>
				<i className="fad fa-file-alt fa-lg mx-1" />
			</Typography.Link>
		</div>
	)
}
