import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { Col } from "reactstrap"
import CommunicationMessagesHistory from "./CommunicationMessageTable"
import SmsReminderSettingsEdit from "./SmsReminderSettingsEdit"

function SmsReminder(): JSX.Element {
	return (
		<Col>
			<SectionHeader title="Editer vos rappels SMS" />
			<SmsReminderSettingsEdit />

			<SectionHeader title="Historique de SMS" className="mt-7" />
			<CommunicationMessagesHistory />
		</Col>
	)
}
export default SmsReminder
