import { Select } from "antd"
import { useMemo } from "react"
import uuid from "react-uuid"
import { partialSearch } from "services/functions"
import { CustomSelectDropDown } from "../Utils"

function SourceSelect({
	sourceD,
	sourceDInitial,
	record,
	isManager,
	setSourceD,
	handlePatientChange,
}) {
	const options = useMemo(() => {
		if (!sourceDInitial && !sourceD) return []
		const allSources = [...sourceDInitial, ...sourceD]
		return allSources.map((src) => ({
			key: uuid(),
			value: src.name,
			label: src.name,
		}))
	}, [sourceD, sourceDInitial])

	return (
		<Select
			allowClear
			showSearch
			style={{ width: "100%", minWidth: "120px" }}
			placeholder="Sélectionner source"
			defaultValue={record["patient.source"]}
			dropdownRender={(body) => (
				<CustomSelectDropDown
					isManager={isManager}
					handleDropDownClick={(name) => {
						if (!sourceD.some((item) => item.name === name)) {
							setSourceD([...sourceD, { name: name }])
							handlePatientChange(name, record, "patient.source")
						}
					}}>
					{body}
				</CustomSelectDropDown>
			)}
			onChange={(value) => handlePatientChange(value, record, "patient.source")}
			filterOption={(input, option) => partialSearch(option.label, input)}
			options={options}
		/>
	)
}

const SourceCol = (
	handlePatientChange,
	editingKey,
	sourceD,
	setSourceD,
	queryfilters,
	isManager,
	sourceDInitial
) => {
	const sourceRender = (source, record) => {
		return editingKey === record.key ? (
			<SourceSelect
				sourceD={sourceD}
				sourceDInitial={sourceDInitial}
				record={record}
				isManager={isManager}
				setSourceD={setSourceD}
				handlePatientChange={handlePatientChange}
			/>
		) : (
			<p style={{ textAlign: "center", margin: 0 }}>{source}</p>
		)
	}

	return {
		title: null,
		key: "source",
		dataIndex: "patient.source",
		with: "100px",
		render: sourceRender,
		filters: sourceDInitial?.map((origin) => ({
			text: origin.name,
			value: origin.name,
		})),
		filteredValue: queryfilters?.source || null,
		filterSearch: true,
		validate: (cond) =>
			!cond["patient.source"] || cond["patient.source"] === ""
				? "Veuillez choisir une source."
				: "",
	}
}

export default SourceCol
