import { frenchDate } from "services/functions"

export default function DernierAppareillage(sort) {
	return {
		key: "lastEquipmentDatePurchase",
		dataIndex: "patient.lastEquipmentDatePurchase",
		render: (date) => {
			if (!date) return "Aucune date"
			return frenchDate(date)
		},
		sorter: true,
		sortOrder: sort?.field === "patient.lastEquipmentDatePurchase" ? sort.order : null,
	}
}
