import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const ParametresPartenairesRoutes = {
	Partenaires: `${GestionCompteRoutePrefix}/partenaires-wizard`,
	ObligationsSDA: `${GestionCompteRoutePrefix}/obligations-sda`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(GestionCompteRoutePrefix, "")
}
