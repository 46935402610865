import { AnonymousFunction } from "constants/types/anonymousFunction"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import PdfViewerMozilla from "components/utils/PdfViewer"
import { downloadFromUrl } from "services/Utilities"

export interface ModalFileViewerProps {
	isOpen: boolean
	onClose?: AnonymousFunction
	title: string
	signature?: string | null
}

const ModalFileViewer = ({ isOpen, onClose, title, signature }: ModalFileViewerProps): JSX.Element => {
	return (
		<>
			<Modal scrollable={true} isOpen={isOpen} size="xl">
				<ModalHeader>
					<div className=" d-flex justify-content-between align-content-center close-icon">
						<div>{title}</div>
						<div className="icon-sm mx-3 cursor-pointer text-danger" onClick={() => onClose?.()}>
							<i className="fas fa-times" />
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<PdfViewerMozilla
						className="document-iframe"
						title={title}
						src={signature!}
					// onLoad={onLoad}
					/>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-primary btn-sm "
						onClick={() => {
							//@ts-ignore
							downloadFromUrl(signature!, "facture-retrocession-transfert", "pdf")
						}}>
						Télécharger
					</button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default ModalFileViewer
