import { Input, Form } from "antd"

const FirstNameCol = (editingKey, isAddingLead, sort) => {
	const lastNameRender = (firstName, record) => {
		return editingKey === record.key ? (
			<Form.Item
				name={"patient.firstName"}
				style={{
					margin: 0,
				}}>
				<Input style={{ maxWidth: "100px" }} />
			</Form.Item>
		) : (
			<strong className="mx-2"> {firstName} </strong>
		)
	}
	return {
		title: null,
		key: "firstName",
		dataIndex: "patient.firstName",
		width: "100px",
		fixed: "left",
		render: lastNameRender,
		sorter: !isAddingLead,
		sortOrder: sort?.field === "patient.firstName" ? sort.order : null,
		validate: (cond) =>
			!cond["patient.firstName"] || cond["patient.firstName"] === ""
				? "Veuillez renseigner le prénom du patient."
				: "",
	}
}

export default FirstNameCol
