import { LaboratoryAttendanceGenerated, Patient, ScheduleType, User } from "@audiowizard/common"
import { BryntumCalendar } from "@bryntum/calendar-react"
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import API from "services/API"

import {
	Button,
	DayView,
	EventModel,
	EventStore,
	Grid,
	MessageDialog,
	Model,
	ModelConfig,
	ResourceStore,
	ResourceView,
	SchedulerDatePicker,
	Store,
} from "@bryntum/calendar"

import { useQuery, useQueryClient } from "react-query"
import "./BryntumAgenda.scss"

import AuthContext from "contexts/AuthContext"
import useNotifierSystem from "services/RealtimeSystem/Hook"
import { Attendance } from "./Attendance"
import { calendarConfig } from "./BryntumAgendaConfig"
import { saveAgendaSettings, useAgendaSettingLoader } from "./CustomSettings"
import { Agenda, AgendaStore, AWCalendar, Event, Resource, TypeStore, EvtData } from "./helpers/AgendaTypes"
import eventTooltipFeature from "./helpers/AWEventToolTips"
import { fetchSchedules, formatEvent, onDataChange } from "./helpers/BryntumAgenda.api"
import {
	beforeDragEnd,
	calculateEndDate,
	createTemporaryEvent,
	detectPhone,
	getColor,
	isNotEditable,
	selectScheduleFromScheduleSelector,
	setChangeDay,
	setLaboratoriesFilters,
	updateHourHeight,
	setStartTimeAndWorkingDays,
} from "./helpers/BryntumAgenda.helper"

import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { handleScheduleType } from "../SchedulesTypes"
import { PatientCreationModalDynamic } from "../SchedulingModal/PatientCreationModalDynamic"
import ScheduleConfirmModal from "../SchedulingModal/Schedule.confirmModal"
import EventEditor from "./helpers/EventEditor"
import { SortAlphabetically } from "services/functions"
import { cleanEventFromLocalStorage } from "./helpers/BryntumAgendaLocalStorage"
import { AWEventModel, AWEventTooltip } from "./helpers/AWEventModel"
import useHasRole from "components/Hooks/useHasRole"
import { toast } from "react-toastify"
import usePatient from "services/hooks/usePatient"

interface BryntumAgendaProps {
	defaultStartDate?: Date
	appointmentFromScheduleSelector?: EvtData
	setSelectedDate?: (d: Date[]) => void
	setSelectedUser?: (u: number) => void
	setSelectedLaboratory?: (l: number) => void
	setShow?: (show: boolean) => void
	setSelectedSchedule?: Dispatch<SetStateAction<EvtData>>
	fromDashboard?: boolean
}

const BryntumAgenda: React.FC<BryntumAgendaProps> = ({
	defaultStartDate,
	appointmentFromScheduleSelector,
	setSelectedDate,
	setSelectedLaboratory,
	setSelectedUser,
	setShow,
	setSelectedSchedule,
	fromDashboard = false,
}) => {
	const calendarRef = useRef<BryntumCalendar>(null)
	const history = useHistory()
	const [resources, setResources] = useState<Resource[]>([])
	const agendaAlreadyLoaded = useRef<Record<string, Record<string, boolean>>>({})
	const timeout = useRef<Record<string, NodeJS.Timeout>>({})
	const unsavedChange = useRef<Record<string, Record<string, { oldValue: any; value: any }>>>({})
	const {
		agendas,
		user,
		laboratories,
		patient: currentPatient,

		setSchedule,
		setEditing,
	} = useContext(AuthContext)
	const { setPatientInContext } = usePatient()
	const [attendances, setAttendances] = useState<Attendance>()
	const [viewSettings, setViewSettings] = useState({
		cellDuration: 30,
		viewMode: "",
		hourHeight: 100,
		viewWidth: 80,
	})
	const [displayedLaboratories, setDisplayedLaboratories] = useState<number[]>([])
	const [displayedUsers, setDisplayedUsers] = useState<string[]>([])
	const [usersOrder, setUsersOrder] = useState<Record<string, number>>()
	const [settingsLoaded, setSettingsLoaded] = useState(false)
	const [agendasLoaded, setAgendasLoaded] = useState(false)
	const [schedulesLoaded, setSchedulesLoaded] = useState(false)
	const [schedulesLoading, setSchedulesLoading] = useState(false)
	const [settingsSaved, setSettingsSaved] = useState(true)
	const [htmlToPrint, setHtmlToPrint] = useState<string>("")
	const [agendaFilter, setAgendaFilter] = useState({
		birthday: true, // not used
		missed: true,
		cancelled: true,
		done: true,
		ferie: true, // not used in the bryntum agenda
	})

	const [showEventEditor, setShowEventEditor] = useState(false)
	const [eventRecord, setEventRecord] = useState<Model | null>(null)
	const [eventStore, setEventStore] = useState<EventStore>()
	const [resourceStore, setResourceStore] = useState<ResourceStore>()
	const [userStoreIsReady, setUserStoreIsReady] = useState<boolean>(false)
	const [scheduleTypeLoaded, setScheduleTypeLoaded] = useState<boolean>(false)
	const [attendancesLoaded, setAttendancesLoaded] = useState<boolean>(false)

	// modale use for schedule of type adaptation if no equipment
	const [confirmModal, setConfirmModal] = useState({
		show: false,
		title: "",
		content: <></>,
		btnConfirm: "Oui",
		btnCancel: "Annuler",
		result: false,
		fctConfirm: () => {
			// do nothing
		},
		fctCancel: () => {
			// do nothing
		},
	})
	const [defaultPatient, setDefaultPatient] = useState<Patient>()
	const [openPatientModal, setOpenPatientModal] = useState(false)
	const queryClient = useQueryClient()

	const currentAgenda = useRef({
		"@id": null,
		userIri: null,
		laboratoryIri: null,
	})
	const [hideLeftBar, setHideLeftBar] = useState(false)

	// @ts-ignore need comom update
	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	// @ts-ignore need comon update
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")

	const { data: typesList } = useQuery<ScheduleType[]>(
		["SCHEDULE_TYPES", "All"],
		async () => await API.findAll<ScheduleType[]>("SCHEDULE_TYPES"),
		{ staleTime: Infinity }
	)

	const { data: users } = useQuery<User[]>(
		"USERS_API",
		async () => {
			let page = 1
			let totalItems = Infinity
			const users = []

			while (users.length < totalItems) {
				const res = await API.findAll<User[]>("USERS_API", `/with_laboratories?page=${page}`, true)
				page++
				totalItems = res["hydra:totalItems"]
				users.push(...res["hydra:member"])
			}
			SortAlphabetically(users, "lastName")
			return users
		},
		{ staleTime: Infinity }
	)

	useEffect(() => {
		if (agendasLoaded && attendancesLoaded && scheduleTypeLoaded) {
			calendarRef?.current?.instance.trigger("fetchSchedules")
		}
	}, [agendasLoaded, attendancesLoaded, scheduleTypeLoaded])

	// calendarRef sert pour l'ensemble des filtres sur les events ou resources

	// Bryntum Calendar use stores to manage Data : a store for one kind of data
	const states = [
		{
			id: "WAITING",
			state: "En attente",
			icon: "fad fa-hourglass-start text-info",
		},
		{
			id: "WAITING_ROOM",
			state: "Arrivé",
			icon: "b-fa b-fa-clinic-medical text-primary",
		},
		{
			id: "IN_PROGRESS",
			state: "En cours",
			icon: "b-fa b-fa-clinic-medical text-primary",
		},
		{
			id: "DONE",
			state: "Terminé",
			icon: "fad fa-check-circle text-primary",
		},
		{
			id: "CANCELLED",
			state: "Annulé",
			icon: "fad fa-times-circle text-warning",
		},
		{
			id: "MISSED",
			state: "Manqué",
			icon: "fad fa-exclamation-circle text-danger",
		},
		{
			id: "EXCUSED",
			state: "Excusé",
			icon: "fad fa-exclamation-circle text-danger",
		},
	]

	// Store for the schedules type, use in combo
	const typesStore = useMemo<TypeStore>(() => {
		const newTypeStore = new TypeStore({
			tree: true,
			data: typesList
				? (typesList.map((type: ScheduleType) => {
						return {
							typeId: type["@id"],
							label: type.label,
							scheduleStatus: type.scheduleStatus,
							color: getColor(type),
							duration: type.duration,
							textDuration: (type.duration || 0) * 60,
							scheduleInterval: type.scheduleInterval,
						}
				  }) as unknown as Partial<ModelConfig>[])
				: [],
			onRefresh: ({ data }: { type: string; data: any[] }) => {
				if (data?.length > 0) {
					setScheduleTypeLoaded(true)
				}
			},
		})
		newTypeStore.sort({ field: "label", useLocalSort: true })
		return newTypeStore
	}, [typesList])

	const stateStore = useMemo<Store>(
		() =>
			new Store({
				tree: true,
				data: states as unknown as [],
			}),
		[states]
	)

	const agendaStore = useMemo<AgendaStore>(
		() =>
			new AgendaStore({
				tree: true,
				data: agendas
					? (agendas.map((agenda) => {
							return {
								agendaId: agenda["@id"],
								label: agenda.label,
								user: agenda.user,
								isDoctolib: agenda.doctolibType != null,
								laboratory: agenda.laboratory,
								// @ts-ignore
								color: agenda.laboratory?.agendaColor,
							}
					  }) as unknown as Partial<ModelConfig>[])
					: [],
				onRefresh: ({ data }: { type: string; data: any[] }) => {
					if (data?.length > 0) {
						setAgendasLoaded(true)
					}
				},
			}),
		[agendas]
	)

	const extractOrderForUsers = (users: Resource[]): Record<string, number> => {
		const defaultUsersOrder: Record<string, number> = {}
		let i = 0
		for (const user in users) {
			defaultUsersOrder[users[user]["@id"]] = i++
		}
		return defaultUsersOrder
	}

	// initialise the resource for the calendar
	const initiateRessources = async (users: User[]): Promise<void> => {
		if (!users.length) return setResources([])

		const usersForRessources: Resource[] = users
			.filter((_user) => {
				if (!_user.laboratories?.length) return false
				const userLabsIds = user.laboratories?.map((lab) => lab.id)
				return _user.laboratories.some((_userLab) => userLabsIds?.includes(_userLab.id))
			})
			.map((user) => {
				return {
					...user,
					_id: user.id,
					name: `${user.firstName} ${user.lastName}`,
					role: user.roles?.join(" "),
					eventColor: "#128f76",
					laboratories: user.laboratories,
					affiliation: user.affiliation,
				}
			})

		if (users.length < 7) {
			;(calendarRef?.current?.instance as AWCalendar).widgetMap.resourceFilterFilter.hide(true)
		}

		if (usersForRessources.length) {
			SortAlphabetically(usersForRessources, "lastName")
		}
		setUsersOrder(extractOrderForUsers(usersForRessources))
		setResources(usersForRessources)
	}

	async function handleSchedule(schedule: Event): Promise<void> {
		const fullPatient = await API.get<Patient>(schedule.patient["@id"])
		if (schedule?.status === "RELANCE" && !schedule?.reminder) {
			history.push("/relance/action-relance")
		} else if (
			!(await handleScheduleType(
				schedule?.status,
				{ patient: fullPatient, setSchedule, setEditing },
				history,
				setConfirmModal,
				setShow
			))
		) {
			console.error(`Le type de rendez-vous (${schedule?.status}) n'est pas dans la liste.`)
		}
	}

	const eventListeners = useMemo(
		() => ({
			catchAll(data: {
				type: string
				eventElement: HTMLElement
				eventRecord: AWEventModel
				store: ResourceStore
			}) {
				const { type, store } = data
				const calendar = this as unknown as AWCalendar
				// @ts-ignore bryntum n’est pas complet
				if (type === "datachange" && calendar.extraData.allStoresLoaded && store?.isResourceStore) {
					// si la vue active change, il faut recharger les schedules
					// résout problème après nettoyage du cache
					calendar.trigger("fetchSchedules")
				}
				if (type === "eventclick") {
					calendar.isMoving = false
					calendar.duplicate = undefined
				}
			},
			// when click on the calendar, use to move or duplicate events
			async scheduleclick({ source, date }: { source: any; date: Date }) {
				const calendar = this as unknown as AWCalendar
				// check if the time selected is on a DoctolibAgenda
				// if month view, keep the actual time of the schedule
				while (calendar.extraData.schedulesLoading) {
					document.body.style.cursor = "wait"
					await new Promise((resolve) => setTimeout(resolve, 200))
				}
				document.body.style.cursor = ""
				const setNewTime = (source: any): Date => {
					const newStartDate =
						source.type === "monthview"
							? setChangeDay(date, dayjs(calendar.selectedEvents[0].startDate).toDate())
							: date
					return newStartDate
				}
				// in some case the same schedule is selected twice
				const checkSelectEvents = (selectedEvents: EventModel[]): boolean => {
					if (!selectedEvents) return false
					if (selectedEvents.length === 1) return true
					// if the same event is selected twice
					if (selectedEvents.length === 2 && selectedEvents[1].id === selectedEvents[0].id) return true
					return false
				}

				if (!calendar.isMoving && !calendar.duplicate) {
					return
				}
				if (checkSelectEvents(calendar.selectedEvents) && (calendar.isMoving || calendar.duplicate)) {
					const newStartDate = setNewTime(source)
					if (!newStartDate) {
						toast.error("Agenda non trouvé")
						return
					}
					const shouldMove = beforeDragEnd(
						{
							user: source.resource as unknown as User,
							source: calendar,
							eventRecord: calendar.selectedEvents[0] as Event,
							newStartDate,
						},
						calendar.extraData.agendaStore
					)

					if (shouldMove) {
						const updateDragEvent = (eventRecord: EventModel, callback: () => void): void => {
							if (calendar.selectedEvents[0].isOccurrence || calendar.selectedEvents[0].isRecurring) {
								// @ts-ignore propriété non décrite dans la bibliothèque : recurrenceConfirmation
								calendar.features.eventTooltip.recurrenceConfirmation.confirm({
									actionType: "update",
									eventRecord,
									changerFn() {
										callback()
									},

									cancelFn() {
										return
									},
								})
								return
							}

							callback()
						}
						if (calendar.isMoving) {
							const res = await MessageDialog.confirm({
								title: "Déplacement d’un rendez-vous",
								message: `Confirmer le déplacement du rendez-vous au ${dayjs(newStartDate).format(
									"dddd DD/MM/YYYY à HH:mm"
								)}?`,
								okButton: "Déplacer",
								cancelButton: "Annuler",
							})
							if (res === MessageDialog.okButton) {
								updateDragEvent(calendar.selectedEvents[0], () => {
									if (!calendar.selectedEvents[0]) return
									calendar.selectedEvents[0].set({
										startDate: newStartDate,
										resourceId: source.resource.id,
										endDate: calculateEndDate(newStartDate, calendar.selectedEvents[0].durationMS),
									})
									calendar.clearEventSelection()
									calendar.isMoving = false
								})
							}
							if (res === MessageDialog.cancelButton) {
								calendar.clearEventSelection()
								calendar.isMoving = false
							}
						}
						if (calendar.duplicate) {
							updateDragEvent(calendar.selectedEvents[0], () => {
								if (!calendar.duplicate) return
								calendar.duplicate.set({
									startDate: newStartDate,
									resourceId: source.resource.id,
									endDate: calculateEndDate(newStartDate, calendar.selectedEvents[0].durationMS),
									duration: undefined,
								})
								calendar.eventStore.add(calendar.duplicate)
								calendar.clearEventSelection()
								calendar.duplicate = undefined
							})
						}
					}
				}
			},
			fetchSchedules() {
				fetchSchedules(
					{
						dateEnd: (calendarRef?.current?.instance as AWCalendar).activeView.endDate,
						dateStart: (calendarRef?.current?.instance as AWCalendar).activeView.startDate,
					},
					this as unknown as AWCalendar,
					setSchedulesLoaded,
					setSchedulesLoading
				)
			},
			saveView() {
				const selectedUsers = (calendarRef?.current?.instance as AWCalendar).widgetMap.resourceFilter
					?.value as unknown as User[]
				const selectedLaboratories = (calendarRef?.current?.instance as AWCalendar).widgetMap.laboratoryFilter
					?.value as any[]
				const calendar = this as unknown as AWCalendar
				const currentAgendaFilter = { ...agendaFilter }
				for (const [key] of Object.entries(currentAgendaFilter)) {
					const idButton = ("btn-" + key) as keyof typeof calendar.widgetMap.stateFilter.widgetMap
					if (!calendar.widgetMap.stateFilter.widgetMap[idButton]) continue
					const button = calendar.widgetMap.stateFilter.widgetMap[idButton] as Button
					currentAgendaFilter[key as keyof typeof currentAgendaFilter] = button.pressed
				}
				saveAgendaSettings(
					user,
					{
						cellDuration: 30,
						viewMode: (calendarRef?.current?.instance as AWCalendar).activeView.modeName,
						// @ts-ignore library not complet
						viewWidth: calendar.getWidgetById("viewWidth").value,
						// @ts-ignore library not complet
						hourHeight: calendar.getWidgetById("hourHeight").value,
					},
					currentAgendaFilter,
					selectedUsers,
					selectedLaboratories,
					(calendarRef?.current?.instance as AWCalendar).sidebar.collapsed,
					setSettingsSaved,
					(calendarRef?.current?.instance as AWCalendar).extraData.usersOrder || {}
				)
			},
			// trigger when a event is started from the tooltip
			async handleStartSchedule({ eventRecord }: { eventRecord: Event }) {
				if (!setShow) return
				const today = dayjs().format("DD-MM-YYYY")
				const date = dayjs(eventRecord.data.dateOf).format("DD-MM-YYYY")
				const dateDiff = dayjs(dayjs().format("YYYY-MM-DD HH")).diff(
					dayjs(eventRecord.data.dateOf).format("YYYY-MM-DD HH"),
					"minute"
				)
				if (eventRecord.data.patient?.["@id"]) {
					const patient = await API.get<Patient>(eventRecord.data.patient["@id"])
					setPatientInContext(patient)
				}
				if (today === date) {
					setSchedule(eventRecord.data)
					await handleSchedule(eventRecord)
				} else {
					if (dateDiff > 0) {
						const res = await MessageDialog.confirm({
							title: "Commencer un rendez-vous",
							message: "Vous allez commencer un rendez-vous en retard, êtes vous sûr?",
							okButton: "Commencer",
							cancelButton: "Annuler",
						})
						if (res === MessageDialog.okButton) {
							setSchedule(eventRecord.data)
							await handleSchedule(eventRecord)
						}
					} else {
						const res = await MessageDialog.confirm({
							title: "Commencer un rendez-vous",
							message: "Vous allez commencer un rendez-vous en avance, êtes vous sûr?",
							okButton: "Commencer",
							cancelButton: "Annuler",
						})
						if (res === MessageDialog.okButton) {
							setSchedule(eventRecord.data)
							await handleSchedule(eventRecord)
						}
					}
				}
			},
			print(pages: { html: string }[]) {
				if (pages[0].html !== htmlToPrint) {
					setHtmlToPrint(pages[0].html)
				} else {
					onIframeLoaded()
				}
				const calendar = this as unknown as AWCalendar
				calendar.activeView.unmask()
			},
			beforeEventEdit({ eventRecord }: { eventRecord: Event }) {
				// on utilise une modale react custom, on renvoie donc false pour ne pas ouvrir la modale de bryntum
				// dans le cas d’un rendez-vous lors d’une création de patient depuis le dashboard, on utilise un rdv temporaire, le rdv étant créé après la création du patient
				const calendar = this as unknown as AWCalendar
				if (!calendar.extraData.fromDashboard) {
					if (
						eventRecord["@id"] &&
						isNotEditable(eventRecord, calendar.extraData.currentUser, calendar.extraData.isAffiliate)
					)
						return false
					showEditor(eventRecord)
				} else {
					if (calendar.eventStore && eventRecord.resource != null) {
						createTemporaryEvent({
							calendar,
							eventRecord,
							appointmentFromScheduleSelector,
						})
					}
				}
				// si on était en cours de déplacement d’un rdv, on annule le déplacement
				calendar.isMoving = false
				return false
			},
			userStoreIsReady(): void {
				setUserStoreIsReady(true)
			},
			orderUsers({ event }: { event: any }) {
				const calendar = this as unknown as AWCalendar
				const target = event.target.classList.contains("move-user") ? event.target : event.target.parentElement
				const userIri = target.dataset.user
				const direction = target.classList.contains("chevron-resource-agenda-up") ? "up" : "down"
				if (!userIri) return
				if (!calendar.extraData.usersOrder) return undefined
				const currentOrder = calendar.extraData.usersOrder[userIri]
				const increment = direction === "up" ? -1 : 1
				for (const user in calendar.extraData.usersOrder) {
					if (calendar.extraData.usersOrder[user] === currentOrder + increment) {
						calendar.extraData.usersOrder[user] = currentOrder
						break
					}
				}
				calendar.extraData.usersOrder[userIri] = currentOrder + increment
				setUsersOrder(calendar.extraData.usersOrder)
				if (calendar.extraData.usersOrder) {
					calendar.resourceStore.traverse((user: any) => {
						user.set("order", calendar.extraData.usersOrder[user["@id"]])
					})
					// @ts-ignore fonctionne sans paramètre
					calendar.resourceStore.sort()
				}
				// @ts-ignore, non définie dans la bibliothèque
				calendar.widgetMap.resourceFilter.refresh()
			},
		}),
		[]
	)

	useEffect(() => {
		// attendre le chargement des schedules types pour charger
		if (!typesList) return
		if (users && users.length > 0) initiateRessources(users)
	}, [users, typesList])

	useEffect(() => {
		// if no laboratories no need to do anything
		let doNotUpdate = false
		// @ts-ignore
		if (!laboratories || laboratories.length === 0 || !laboratories[0].agendaColor) {
			return
		}
		const searchParams = new URLSearchParams()

		if (Array.isArray(laboratories)) {
			for (const laboratory of laboratories) {
				searchParams.append("laboratories[]", laboratory["@id"])
			}
		}

		API.findAll("LABORATORY_ATTENDANCES_API", `?${searchParams}`).then((data) => {
			if (!doNotUpdate) {
				setAttendances(new Attendance(data as LaboratoryAttendanceGenerated[], laboratories))
				setAttendancesLoaded(true)
			}
		})

		setLaboratoriesFilters(calendarRef, laboratories)

		return () => {
			doNotUpdate = true
			// @ts-ignore
			if (calendarRef && calendarRef.current && calendarRef.current.instance.widgetMap.laboratoryFilter) {
				// @ts-ignore
				;(calendarRef.current.instance as AWCalendar).widgetMap.laboratoryFilter.removeListener("select")
			}
		}
	}, [laboratories])

	// initial fetch
	useEffect(() => {
		if (
			!calendarRef?.current?.instance ||
			!agendas ||
			!agendasLoaded ||
			!scheduleTypeLoaded ||
			!attendancesLoaded ||
			!userStoreIsReady ||
			// @ts-ignore bryntum incomplet
			!isNaN(calendarRef?.current?.instance.activeView.startDate)
		)
			return
		const calendar = calendarRef.current.instance as AWCalendar
		setSchedulesLoaded(false)
		fetchSchedules(
			{
				dateEnd: (calendarRef?.current?.instance as AWCalendar).activeView.endDate,
				dateStart: (calendarRef?.current?.instance as AWCalendar).activeView.startDate,
			},
			calendar,
			setSchedulesLoaded,
			setSchedulesLoading
		)
	}, [settingsLoaded, agendas, agendasLoaded, attendancesLoaded, scheduleTypeLoaded, userStoreIsReady])

	// use to show the spinner on the refresh button
	useEffect(() => {
		if (!calendarRef?.current?.instance) return
		const calendar = calendarRef.current.instance as AWCalendar

		if (schedulesLoading) {
			calendar.widgetMap.refresh.icon = "spinner-border spinner-border-sm"
			calendar.widgetMap.refresh.disabled = true
		} else {
			calendar.widgetMap.refresh.icon = ""
			calendar.widgetMap.refresh.disabled = false
		}
	}, [schedulesLoading])

	// select the schedule if we come from a schedule modale
	useEffect(() => {
		if (!calendarRef?.current?.instance) return
		const calendar = calendarRef.current.instance as AWCalendar
		selectScheduleFromScheduleSelector({
			schedulesLoaded,
			appointmentFromScheduleSelector,
			settingsLoaded,
			displayedUsers,
			setDisplayedUsers,
			setSchedulesLoaded,
			displayedLaboratories,
			setDisplayedLaboratories,
			calendar,
		})
	}, [schedulesLoaded, settingsLoaded])

	// once the settings are loaded we can select the users from the settings
	useEffect(() => {
		if (calendarRef.current?.instance && settingsLoaded && userStoreIsReady && usersOrder) {
			const calendar = calendarRef.current.instance as AWCalendar
			calendar.widgetMap.resourceFilter.select(
				calendar.widgetMap.resourceFilter?.store.allRecords?.filter(
					(resource: Model) => displayedUsers.indexOf((resource as unknown as User)["@id"]) !== -1
				)
			)
			calendar.resourceStore.traverse((user: any) => {
				user.set("order", usersOrder[user["@id"]])
			})
			// @ts-ignore, fonctionne sans paramètre
			calendar.resourceStore.sort()
			// @ts-ignore, non définie dans la bibliothèque
			calendar.widgetMap.resourceFilter.refresh()
		}
	}, [displayedUsers, settingsLoaded, userStoreIsReady, usersOrder])

	// once the filters are loaded we set the filters in sideBar
	useEffect(() => {
		if (calendarRef.current?.instance && settingsLoaded && agendaFilter && schedulesLoaded) {
			const calendar = calendarRef.current.instance as AWCalendar
			for (const [key, value] of Object.entries(agendaFilter)) {
				const idButton = ("btn-" + key) as keyof typeof calendar.widgetMap.stateFilter.widgetMap
				if (!calendar.widgetMap.stateFilter.widgetMap[idButton]) continue
				const button = calendar.widgetMap.stateFilter.widgetMap[idButton] as Button
				if (value) {
					button.toggle(true)
				} else {
					button.toggle(false)
				}
			}
		}
	}, [agendaFilter, settingsLoaded, schedulesLoaded])

	// once the settings are loaded we can select the laboratories to display
	useEffect(() => {
		if (calendarRef.current?.instance && settingsLoaded && laboratories && laboratories.length > 0) {
			const calendar = calendarRef.current.instance as AWCalendar
			calendar.widgetMap.laboratoryFilter.value = (
				calendar.widgetMap.laboratoryFilter?.store as Store
			).allRecords?.filter((laboratory: any) => displayedLaboratories.indexOf(laboratory.value) !== -1)
			calendar.widgetMap.laboratoryFilter.trigger("onSelect", calendar.widgetMap.laboratoryFilter.records)
		}
	}, [displayedLaboratories, settingsLoaded, laboratories])

	// once the settings are loaded we can open or not the sideBar
	useEffect(() => {
		if (calendarRef.current?.instance && settingsLoaded) {
			if (hideLeftBar) {
				;(calendarRef.current.instance as AWCalendar).sidebar.collapse()
			}
		}
	}, [hideLeftBar, settingsLoaded])

	// once the settings are loaded we select the view to show
	useEffect(() => {
		if (settingsLoaded && viewSettings.viewMode !== "" && calendarRef && calendarRef.current) {
			const calendar = calendarRef.current.instance as AWCalendar
			calendar.views.find((view: any) => view.modeName === viewSettings.viewMode)?.show()
			// @ts-ignore library not complet
			calendar.getWidgetById("hourHeight").value = viewSettings.hourHeight
			updateHourHeight(calendar, viewSettings.hourHeight)
			// @ts-ignore library not complet
			calendar.getWidgetById("viewWidth").value = viewSettings.viewWidth
			calendar.eachView((v: ResourceView) => (v.resourceWidth = viewSettings.viewWidth + "em"))
			calendar.refresh()
		}
	}, [viewSettings, settingsLoaded])

	// while the settings are saved we disabled the button
	useEffect(() => {
		if (calendarRef && calendarRef.current) {
			const calendar = calendarRef.current.instance as AWCalendar
			if (settingsSaved) {
				calendar.widgetMap.saveView.disabled = false
			} else {
				calendar.widgetMap.saveView.disabled = true
			}
		}
	}, [settingsSaved])

	// ajuste l’heure d’affichage du calendrier en fonction des horaires de travail
	useEffect(() => {
		if (!attendances || !displayedUsers || displayedUsers.length === 0) return

		// @ts-ignore
		const calendar = calendarRef.current.instance as AWCalendar
		setStartTimeAndWorkingDays(calendar, displayedUsers)
	}, [attendances, displayedUsers])

	useAgendaSettingLoader(
		user,
		!appointmentFromScheduleSelector,
		setViewSettings,
		setAgendaFilter,
		setDisplayedUsers,
		setUsersOrder,
		setDisplayedLaboratories,
		setSettingsLoaded,
		setHideLeftBar,
		fromDashboard,
		resources
	)

	useNotifierSystem(
		(type: string, data: any) => {
			;(async (type: string, data: any) => {
				const calendar = calendarRef?.current?.instance as AWCalendar
				if (!calendar) return
				// si le laboratoire n’est pas géré par l’user, ne pas ajouter le rdv
				if (data?.data?.laboratory && !user?.laboratories?.find((l) => l.id === data?.data?.laboratory)) return
				if (data.method === "delete") {
					calendar.eventStore.remove(data.data.id, true)
				}
				if (data.method === "post") {
					try {
						const event = await API.get<Event>("/schedules/" + data.data.id)
						// const event = e as Event
						const agenda = calendar.extraData.agendas.find((agenda: Agenda) => {
							if (event && agenda.user && agenda.laboratory) {
								return agenda["@id"] === event.agenda
							}
							return false
						})
						if (agenda) {
							calendar.eventStore.add(formatEvent(event, calendar, agenda), true)
						}
					} catch (err) {
						console.error(err)
					}
				}
				if (data.method === "put") {
					const oldEvent = calendar.eventStore.getById(data.data.id) as Event
					setTimeout(() => {
						;(async () => {
							try {
								const event = await API.get<Event>("/schedules/" + data.data.id)
								const agenda = calendar.extraData.agendas.find((agenda: Agenda) => {
									if (event && agenda.user && agenda.laboratory) {
										return agenda["@id"] === event.agenda
									}
									return false
								})
								// pour être sur que l’event se redessine il faut le supprimer et le recréer
								if (oldEvent) {
									calendar.eventStore.remove(data.data.id, true)
								}
								if (agenda) {
									// attendre que l’événement est bien supprimé
									setTimeout(() => {
										calendar.eventStore.add(formatEvent(event, calendar, agenda), true)
									}, 30)
								}
							} catch (err) {
								console.error(err)
							}
						})()
					}, 300)
				}
				setTimeout(() => {
					calendar.refresh()
				}, 300)
			})(type, data)
		},
		["schedule"]
	)

	// print the content of the hidden iframe
	const onIframeLoaded = (): void => {
		if (htmlToPrint) {
			const id = "printIframe"
			const iframe = document.getElementById(id) as any
			const iframeWindow = iframe.contentWindow || iframe
			iframe.focus()
			iframeWindow.print()
		}
	}

	// get store from the calendar to use in the event editor
	useEffect(() => {
		if (!calendarRef.current) return
		const { eventStore, resourceStore } = calendarRef.current.instance
		const { datePicker } = calendarRef.current.instance

		datePicker.addListener("selectionChange", {
			fn: (e: { selection: string; source: SchedulerDatePicker; type: string; userAction: boolean }) => {
				if (!calendarRef.current) return
				if (!detectPhone()) return
				const isCurrentDate = dayjs().isSame(dayjs(e.selection[0]), "day")
				const dayResources = (calendarRef.current.instance as AWCalendar).widgetMap.dayResources

				for (const key of Object.keys(dayResources.widgetMap)) {
					if (key.includes("b-resourceview-1-resourcedayview")) {
						//@ts-ignore
						dayResources.widgetMap[key].widgetMap.resourceInfo.html = `<div class="custom-day-cell ${
							isCurrentDate ? "custom-day-current" : ""
						}">${dayjs(e.selection[0]).format("D")}</div>`
					}
				}
			},
		})

		setEventStore(eventStore)
		setResourceStore(resourceStore)
	}, [calendarRef])

	// set event to be use in the event editor
	const showEditor = useCallback((eventRecord: any): void => {
		setEventRecord(eventRecord)
		setShowEventEditor(true)
	}, [])

	// unset the event use by the editor
	const hideEditor = useCallback(() => {
		// If isCreating is still true, user clicked cancel
		if (!eventRecord || !eventStore) return
		if (eventRecord.isCreating) {
			eventStore.remove(eventRecord)
			eventRecord.isCreating = false
		}
		setEventRecord(null)
		setShowEventEditor(false)
		setDefaultPatient(undefined)
		typesStore.clearFilters()
	}, [eventRecord, eventStore])

	const handleOnBeforeDelete = useCallback(
		({ context, eventRecords, source }: { context: any; eventRecords: EventModel[]; source: any }): boolean => {
			// bryntum use context.finalize for async validation
			// depending on the choice in the modale we continue or not
			// return false cancel the action
			const [eventRecord] = eventRecords
			if (eventRecord.isOccurrence || eventRecord.isRecurring) {
				source.features.eventTooltip.recurrenceConfirmation.confirm({
					actionType: "delete",
					eventRecord,

					changerFn() {
						// @ts-ignore library not complet
						eventRecord.eventStore.recurringEvents?.delete(eventRecord)
						context.finalize(true)
					},

					cancelFn() {
						context.finalize(false)
					},
				})
			} else {
				MessageDialog.confirm({
					title: "Supprimer un rendez-vous",
					message: "Supprimer le rendez-vous ?",
					okButton: {
						text: "Supprimer",
						id: "okButtonDelete",
					},
					cancelButton: {
						text: "Annuler",
						id: "cancelButtonDelete",
					},
				}).then((res) => {
					context.finalize(res === MessageDialog.okButton)
				})
			}
			return false
		},
		[]
	)

	const handleBeforeDragEnd = ({
		drag,
		source,
		eventRecord,
	}: {
		drag: any
		source: AWCalendar
		eventRecord: Event
	}): boolean => {
		return beforeDragEnd({ user: drag?.target?.resource as unknown as User, source, eventRecord }, agendaStore)
	}

	const handleRemoveEvent = useCallback((eventRecord: EventModel): void => {
		// @ts-ignore
		calendarRef.current?.instance.removeEvents([eventRecord])
	}, [])

	return (
		<>
			<BryntumCalendar
				{...calendarConfig}
				ref={calendarRef}
				hidden={!(settingsLoaded && agendasLoaded)}
				date={defaultStartDate}
				resources={resources}
				onDataChange={(data: any): void => {
					queryClient.invalidateQueries("PATIENTS_NOTES_AND_SCHEDULES_API")
					queryClient.invalidateQueries(["SCHEDULES_API"])
					onDataChange(
						data,
						timeout,
						unsavedChange,
						setSelectedUser,
						setSelectedLaboratory,
						setSelectedDate,
						appointmentFromScheduleSelector,
						setSelectedSchedule,
						fromDashboard
					)
				}}
				eventTooltipFeature={eventTooltipFeature}
				listeners={eventListeners}
				extraData={{
					attendances,
					agendas,
					agendaAlreadyLoaded,
					schedulesLoading,
					typesStore,
					agendaStore,
					appointmentFromScheduleSelector,
					stateStore,
					fromDashboard,
					allStoresLoaded: agendasLoaded && attendancesLoaded && scheduleTypeLoaded,
					usersOrder,
					currentUser: user,
					isAffiliate: isAffiliate || isAffiliateManager,
				}}
				onBeforeDragResizeEnd={handleBeforeDragEnd}
				onBeforeDragMoveEnd={handleBeforeDragEnd}
				onBeforeEventDelete={handleOnBeforeDelete}
				onDateRangeChange={({ new: { endDate, startDate } }: { new: { endDate: Date; startDate: Date } }) => {
					if (!agendasLoaded || !attendancesLoaded || !scheduleTypeLoaded) return
					fetchSchedules(
						{ dateEnd: endDate, dateStart: startDate },
						calendarRef?.current?.instance as AWCalendar,
						setSchedulesLoaded,
						setSchedulesLoading
					)
				}}
				onActiveItemChange={({ activeItem }: { activeItem: Grid | DayView }) => {
					const calendar = calendarRef?.current?.instance as AWCalendar
					// @ts-ignore
					calendar.widgetMap.pdfExport.hidden = !(activeItem.features && activeItem.features.pdfExport)

					const modeThatCanChangeHeight = ["weekResources", "dayResources"]
					const modeThatCanChangeWidth = ["weekResources", "dayResources", "monthResources"]
					// @ts-ignore modeType not define in librairie
					calendar.widgetMap.hourHeight.hidden = !modeThatCanChangeHeight.includes(activeItem.modeType)
					// @ts-ignore modeType not define in librairie
					calendar.widgetMap.viewWidth.hidden = !modeThatCanChangeWidth.includes(activeItem.modeType)
				}}
				onBeforeDestroy={({ source: calendar }: { source: AWCalendar }) => {
					// @ts-ignore
					if (fromDashboard && calendar.extraData?.agendas) {
						// fromDashboard le rdv n’est pas encore créer, is le localeStorage n’est pas nettoyée le rdv n’est pas présent lors du retour sur l’agenda
						cleanEventFromLocalStorage(calendar.extraData?.agendas)
					}
				}}
				// au click sur un rdv
				// on surcharge le tooltip pour gérer à la fois le hover et le click,
				// pour le click on doit attribuer le tooltip manuellement,
				// dans certains cas, rdv proche après un click,
				// le hover ne se déclenche pas pour ne pas fermer le tooltip inopinément
				// le rdv n’est pas alors associé au tooltip
				onEventClick={(data: { eventElement: any; eventRecord: AWEventModel }) => {
					const { eventElement, eventRecord } = data
					const tooltip = calendarRef?.current?.instance.features.eventTooltip.tooltip as AWEventTooltip
					tooltip.clicked = true
					tooltip.activeTarget = eventElement
					tooltip.eventRecord = eventRecord
					tooltip.showBy(eventElement)
					// @ts-ignore - type de la bibliothèque incomplète
					tooltip?.refreshContent()
				}}
			/>

			<EventEditor
				isOpen={showEventEditor}
				closePopup={hideEditor}
				eventRecord={eventRecord}
				eventStore={eventStore}
				resourceStore={resourceStore}
				typesStore={typesStore}
				agendaStore={agendaStore}
				stateStore={stateStore}
				appointmentFromScheduleSelector={appointmentFromScheduleSelector}
				attendances={attendances}
				activePatient={currentPatient}
				currentAgenda={currentAgenda}
				setOpenPatientModal={setOpenPatientModal}
				setDefaultPatient={setDefaultPatient}
				defaultPatient={defaultPatient}
				removeEvent={handleRemoveEvent}
			/>
			<PatientCreationModalDynamic
				modalOpen={openPatientModal}
				setModalOpen={setOpenPatientModal}
				setPatient={setDefaultPatient}
			/>

			<ScheduleConfirmModal confirmModal={confirmModal} setConfirmModal={setConfirmModal} />
			<iframe
				title="print"
				srcDoc={htmlToPrint}
				id="printIframe"
				width={"100%"}
				onLoad={onIframeLoaded}
				style={{ display: "none" }}
			/>
		</>
	)
}

export default BryntumAgenda
