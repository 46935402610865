import React, { ReactNode } from "react"
import cx from "classnames"
import { Collapse } from "antd"
import { CollapseProps } from "antd/lib/collapse/Collapse"
import { CollapsePanelProps } from "antd/lib/collapse/CollapsePanel"
import "./AdministrationCollapse.scss"
import { range } from "lodash"

type AdministrationCollapsePanelProps = (CollapsePanelProps & { children: ReactNode; key?: string | number })[]

type AdministrationCollapseProps = CollapseProps & {
	className?: string
	panels?: AdministrationCollapsePanelProps
}

/**
 * AdministrationCollapse Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param {AdministrationCollapsePanelProps} panels
 * @param {CollapseProps} props
 * @return {React.FC<AdministrationCollapseProps>}
 */
const AdministrationCollapse: React.FC<AdministrationCollapseProps> = ({ className, panels = [], ...props }) => {
	const classes: string = cx("administration-collapse", className)

	return (
		<Collapse defaultActiveKey={range(panels?.length)} ghost {...props} className={classes}>
			{panels.map(({ children, ...panelProps }, i: number) => (
				<Collapse.Panel {...panelProps} key={i}>
					{children}
				</Collapse.Panel>
			))}
		</Collapse>
	)
}

export default AdministrationCollapse
