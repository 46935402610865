import { SyncOutlined } from "@ant-design/icons"
import { Laboratory, Transfer, TransferRetrocessionInvoice } from "@audiowizard/common"
import { Switch } from "antd"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import useEffectAsync from "components/Hooks/useEffectAsync"
import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import AuthContext from "contexts/AuthContext"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import API from "services/API"

type InventorySettingsType = {
	senderLaboratory: Laboratory
	recipientLaboratory: Laboratory
	retrocessionInvoice: boolean
	initialInvoiceNumber: number
}

const ModalCreateNewTransfer = ({
	isOpen,
	setOpen,
}: {
	isOpen: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}): JSX.Element => {
	const { laboratory } = useContext(AuthContext)
	const history = useHistory()
	const [newTransferOptions, setNewTransferOptions] = useState<InventorySettingsType>({
		senderLaboratory: laboratory,
		recipientLaboratory: laboratory,
		retrocessionInvoice: false,
		initialInvoiceNumber: 0,
	})

	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		setNewTransferOptions({
			senderLaboratory: laboratory,
			recipientLaboratory: laboratory,
			retrocessionInvoice: false,
			initialInvoiceNumber: 0,
		})
	}, [isOpen])

	const createNewTransfer = async (): Promise<void> => {
		try {
			setIsLoading(true)

			const created = await API.create<Transfer>("TRANSFER_API", {
				laboratory: laboratory["@id"],
				recipientLaboratory: newTransferOptions.recipientLaboratory["@id"],
				senderLaboratory: newTransferOptions.senderLaboratory["@id"],
				generateInvoice: newTransferOptions.retrocessionInvoice,
			})

			if (newTransferOptions.initialInvoiceNumber > 0) {
				await API.create<TransferRetrocessionInvoice>("TRANSFER_RETROCESSION_INVOICES_API", {
					transfer: created?.data["@id"],
					invoiceNumber: newTransferOptions.initialInvoiceNumber,
				})
			}

			history.push("/mon-stock/transfert/" + created?.data.id)
		} catch (error: any) {
			const errorMessage = error?.response?.data?.["hydra:description"]

			if (errorMessage) toast.error(errorMessage)

			console.error(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		const { recipientLaboratory: recipient, senderLaboratory: sender } = newTransferOptions

		if (!recipient?.subCompany || !sender?.subCompany) return

		if (recipient.subCompany?.id !== sender.subCompany?.id) {
			setNewTransferOptions((old) => ({ ...old, retrocessionInvoice: true }))
		}
	}, [newTransferOptions.recipientLaboratory, newTransferOptions.senderLaboratory])

	useEffectAsync(async () => {
		if (!newTransferOptions.retrocessionInvoice) return

		setIsLoading(true)

		try {
			const invoices = await API.findAll(
				"TRANSFER_RETROCESSION_INVOICES_API",
				`?transfer.senderLaboratory=${newTransferOptions.senderLaboratory.id}&awsS3Path[null]=0`
			)

			if (invoices.length) return

			setNewTransferOptions((old) => ({ ...old, initialInvoiceNumber: 1 }))

			const confirm = await confirmWithModal({
				title: "Première facture de rétrocession",
				textAlign: "left",
				okLabel: "Confirmer",
				cancelLabel: "Annuler",
				text: (
					<>
						Ce transfert va générer la <strong>première</strong> facture de rétrocession pour le laboratoire{" "}
						<strong>{newTransferOptions.senderLaboratory.label}</strong>.
						<br />
						<br />
						Vous pouvez choisir à partir de quel numéro commencera votre incrémentation.
						<br />
						<label htmlFor="inputInvoiceNumber" />
						<input
							className="form-control form-control-sm"
							type="number"
							defaultValue={1}
							min={1}
							onChange={(event) => {
								if (event.target.value && +event.target.value <= 0) {
									setNewTransferOptions((old) => ({ ...old, initialInvoiceNumber: 1 }))
									event.target.value = "1"
									return
								}

								setNewTransferOptions((old) => ({ ...old, initialInvoiceNumber: +event.target.value }))
							}}
							id="inputInvoiceNumber"
						/>
					</>
				),
			})

			if (!confirm) {
				setNewTransferOptions((old) => ({ ...old, retrocessionInvoice: false, initialInvoiceNumber: 0 }))
				toast.warn("Annulation, la facture de rétrocession ne sera pas généré")
				return
			}
		} catch (error) {
			console.error(error)
			toast.error("Impossible d'activer la génération de facture de rétrocession.")
			setNewTransferOptions((old) => ({ ...old, retrocessionInvoice: false }))
		} finally {
			setIsLoading(false)
		}
	}, [newTransferOptions.retrocessionInvoice, newTransferOptions.senderLaboratory])

	return (
		<>
			<Modal isOpen={isOpen} centered size="lg" className="modal-loading">
				<ModalHeader>Création d'un nouveau transfert</ModalHeader>
				<ModalBody>
					<div className="cardtabs-subtitle">Configuration du transfert</div>

					<div>
						<Switch
							id="retrocession-switch"
							checked={newTransferOptions.retrocessionInvoice}
							onChange={(checked) => {
								setNewTransferOptions((old) => ({ ...old, retrocessionInvoice: checked }))
							}}
						/>
						<label htmlFor="retrocession-switch" className="ml-2">
							Générer une facture de rétrocession
							{newTransferOptions.initialInvoiceNumber > 0
								? `, démarrer l'incrémentation à partir de ${newTransferOptions.initialInvoiceNumber}`
								: ""}
						</label>
					</div>

					<div className="cardtabs-subtitle">Laboratoire d'origine</div>

					<AsyncLaboratorySelect
						className="w-100"
						onChange={(_, laboratory) => {
							setNewTransferOptions((old) => ({
								...old,
								senderLaboratory: laboratory,
							}))
						}}
					/>
					<div className="cardtabs-subtitle">Laboratoire de destination</div>

					<AsyncLaboratorySelect
						className="w-100"
						onChange={(_, laboratory) => {
							setNewTransferOptions((old) => ({
								...old,
								recipientLaboratory: laboratory,
							}))
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-outline-danger"
						onClick={() => setOpen(false)}
						disabled={isLoading}>
						Annuler
					</button>
					<button
						type="button"
						className="btn btn-primary"
						style={{ minWidth: "256px" }}
						onClick={() => createNewTransfer()}
						disabled={
							isLoading ||
							newTransferOptions.recipientLaboratory.id === newTransferOptions.senderLaboratory.id
						}>
						{isLoading ? <SyncOutlined spin /> : "Nouveau transfert"}
					</button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default ModalCreateNewTransfer
