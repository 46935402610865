import React, { useContext, useState, useEffect, useRef } from "react"
import { Form, Input, InputRef } from "antd"
import PropTypes from "prop-types"
import { EditableContext } from "./EditableTable"

interface Item {
	key: string
	name: string
	age: string
	address: string
}

interface EditableCellProps {
	title: React.ReactNode
	editable?: boolean
	children: React.ReactNode
	dataIndex: keyof Item
	record: Item
	freezeRows: Record<string, boolean>
	handleSave?: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	freezeRows,
	...restProps
}) => {
	const [editing, setEditing] = useState(false)
	const inputRef = useRef<InputRef>(null)
	const form = useContext(EditableContext)!

	useEffect(() => {
		if (editing) {
			inputRef.current!.focus()
		}
	}, [editing])

	const toggleEdit = (): void => {
		if (!freezeRows[record.key]) {
			setEditing(!editing)
			form.setFieldsValue({ [dataIndex]: record[dataIndex] })
		}
	}

	const save = async (): Promise<void> => {
		try {
			const values = await form.validateFields()
			toggleEdit()
			if (Object.keys(values).length && handleSave) {
				await handleSave({ ...record, ...values })
			}
		} catch (error) {
			console.error(error)
		}
	}

	let childNode = children

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} est obligatoire`,
					},
				]}>
				<Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder="xxxxxx" />
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingRight: 24,
					minHeight: "20px",
					width: "100%",
					opacity: freezeRows[record.key] ? 0.5 : 1,
				}}
				onClick={toggleEdit}>
				{children || "xxxxxx"}
			</div>
		)
	}

	return <td {...restProps}>{childNode}</td>
}

EditableCell.propTypes = {
	title: PropTypes.node,
	editable: PropTypes.bool,
	children: PropTypes.node,
	dataIndex: PropTypes.any,
	record: PropTypes.any,
	handleSave: PropTypes.func,
	freezeRows: PropTypes.any,
}

export default EditableCell
