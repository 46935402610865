import { ParametreRoutePrefix } from "router/Personnalisation/routes"

export const MarqueBlancheRoutePrefix = ParametreRoutePrefix + "/marque-blanche"

export const MarqueBlancheRoutes = {
	MarqueBlanche: `${MarqueBlancheRoutePrefix}`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(MarqueBlancheRoutePrefix, "")
}
