import axios, { AxiosError, AxiosResponse } from "axios"
import { MergeTags } from "components/containers/UnlayerEditor/UnlayerEditor.helper"
import API from "services/API"
import { TemplateForForm } from "./custom/listDefaultOptions"
import { API_URL } from "../../../config"
import { Template } from "@audiowizard/common"
import { MergeTag } from "react-email-editor"

export interface TemplateService extends Template {
	type?: string
	unlayerSetting?: string
	content?: string
}

export async function getTemplate(id: string): Promise<TemplateService | undefined> {
	try {
		const fullTemplate = await API.find<Template>("TEMPLATE", id.replace(/\/.*\/(.*)/, "$1"))
		return fullTemplate
	} catch (error) {}
}

const setErrors = (error: any): Record<string, string> | undefined => {
	const { violations }: { violations: { propertyPath: string; message: string }[] } = (error as AxiosError).response!.data // prettier-ignore
	if (!violations) return
	const apiErrors = violations.reduce((obj, { propertyPath, message }) => {
		obj[propertyPath] = message
		return obj
	}, {} as Record<string, string>)
	return apiErrors
}

export async function createTemplate(
	selectTemplate: TemplateForForm
): Promise<AxiosResponse<Template, any> | Record<string, string> | undefined> {
	try {
		const newTemplate = await API.create<Template>("TEMPLATE", {
			label: selectTemplate.label,
			type: selectTemplate.type,
			description: selectTemplate.description,
			content: selectTemplate.content,
			isDefault: selectTemplate.isDefault,
			unlayerSetting: selectTemplate.unlayerSetting,
		})
		return newTemplate
	} catch (error: any) {
		return setErrors(error)
	}
}
export async function updateTemplate(
	selectTemplate: TemplateForForm
): Promise<AxiosResponse<Template, any> | Record<string, string> | undefined> {
	try {
		if (selectTemplate.unlayerId) {
			const updateTemplate = await API.update<Template>(
				"TEMPLATE",
				selectTemplate.unlayerId.replace(/\/.*\/(.*)/, "$1"),
				{
					label: selectTemplate.label,
					type: selectTemplate.type,
					description: selectTemplate.description,
					content: selectTemplate.content,
					isDefault: selectTemplate.isDefault,
					unlayerSetting: selectTemplate.unlayerSetting,
				}
			)

			return updateTemplate
		}
	} catch (error) {
		return setErrors(error)
	}
}

export async function deleteTemplate(selectTemplate: string): Promise<unknown | undefined> {
	try {
		const response = await API.delete(selectTemplate)
		return response
	} catch (error) {}
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: MergeTag | MergeTags): any {
	return item && typeof item === "object" && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export function mergeDeep(target: MergeTag | MergeTags, source: MergeTag | MergeTags): MergeTag | MergeTags {
	if (isObject(target) && isObject(source)) {
		for (const [key, value] of Object.entries(source)) {
			if (isObject(value)) {
				if (!(key in target)) Object.assign(target, { [key]: {} })
				mergeDeep((target as any)[key], value)
			} else {
				Object.assign(target, { [key]: value })
			}
		}
	}

	return target
}

/**
 * If an other template is already the default for this type, he should lose his default status
 * @param actualTemplate
 * @param templates
 * @returns
 */
export async function updateDefault(
	actualTemplate: TemplateForForm,
	templates: TemplateForForm[]
): Promise<boolean | TemplateForForm[]> {
	if (!actualTemplate.isDefault) {
		return true
	}
	const defaultTemplate = templates.find((template: TemplateForForm) => {
		return template.isDefault && template.type === actualTemplate.type
	})
	// do nothing if the default status didn't change
	if (defaultTemplate && defaultTemplate.id !== actualTemplate.id) {
		templates = [
			...templates.map((t) => {
				if (t.id !== defaultTemplate.id) {
					return t
				}
				return {
					...t,
					isDefault: false,
				}
			}),
		]
		try {
			await axios.put(API_URL + defaultTemplate.id + "/removeDefault", {})
			return templates
		} catch (error) {
			console.error("Modification du modèle par default impossible.")
			return false
		}
	}
	return true
}
