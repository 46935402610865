/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col } from "reactstrap"
import { Checkbox, AutoComplete } from "antd"
import Select from "react-select"

import API from "../../services/API"
import FieldWithError from "../../components/forms/FieldWithError"
import PhoneInput from "../../components/forms/PhoneInput"
import AuthContext from "../../contexts/AuthContext"
import { c2sAmcs } from "datas/teletrans"

const MutuelleModal = ({ mutuelle, id, title, setMutuelle, setNeedRefresh }) => {
	const { user, setUserInsurances } = useContext(AuthContext)
	const [updated, setUpdated] = useState(false)
	const [errors, setErrors] = useState([])
	const [isTP, setIsTP] = useState(true)
	const [isC2S, setIsC2S] = useState(false)
	const [isLinkedToAmo, setIsLinkedToAmo] = useState(false)
	const [mutuelleAddress, setMutuelleAddress] = useState([])
	const [selectedAddress, setSelectedAddress] = useState("")
	const [busy, setBusy] = useState(false)

	useEffect(() => {
		if (!mutuelle) return
		setIsTP(mutuelle.isTP)
		if (!mutuelle?.isC2S) return
		setIsC2S(mutuelle.isC2S)
	}, [mutuelle])

	const close = () => {
		setMutuelle(false)
		setIsC2S(false)
		setUpdated(false)
		setBusy(false)
		setErrors([])
	}

	const handleAutoComplete = async (value) => {
		const fetchAddress = await API.getAddress(value)

		setMutuelleAddress(
			fetchAddress?.map((a) => ({
				value: a.properties?.label,
				city: a.properties?.city,
				cpo: a.properties?.postcode,
				addresse: a.properties?.name,
			}))
		)
	}

	const createMutuelle = async () => {
		setBusy(true)
		if (!updated) {
			close()
			return
		}
		// Vérifier qu'il ne manque pas d'infos/ pas d'erreurs
		let tmpErrors = []
		for (const key in mutuelle) {
			if (["address", "city", "postalCode"].includes(key)) continue
			if (mutuelle[key] === "" || mutuelle[key] == null) {
				tmpErrors.push(key)
			}
			if (key === "websiteUrl") {
				try {
					// if the url is not valid, it will throw an error that we catch and use to inform the user
					new URL(mutuelle["websiteUrl"])
				} catch (_) {
					tmpErrors.push(key)
				}
			}
		}

		setErrors(tmpErrors)
		if (tmpErrors.length === 0) {
			try {
				await API.create("USER_INSURANCES_API", { ...mutuelle, company: user.company["@id"] })
				const myInsurances = await API.findAll("USER_INSURANCES_API", `?companies=${user.company["@id"]}`)
				setUserInsurances(myInsurances)
				setNeedRefresh(true)
				close()
			} catch (error) {
				console.error(error)
			} finally {
				setBusy(false)
			}
		} else setBusy(false)
	}

	const deleteMutuelle = async () => {
		try {
			await API.delete(mutuelle["@id"])
			setNeedRefresh(true)
			close()
		} catch (error) {
			console.error(error)
		}
	}

	const updateMutuelle = async () => {
		setBusy(true)
		await API.update("USER_INSURANCES_API", mutuelle.id, { ...mutuelle, company: user.company["@id"] })
		setNeedRefresh(true)
		close()
	}

	const handleChange = (event, element, setElement) => {
		setUpdated(true)
		const value = event.target.value
		setElement({ ...element, [event.target.name]: value })
	}

	const cmuOptions = c2sAmcs.map((amc) => {
		return {
			value: amc,
			label: amc,
		}
	})

	return (
		<Modal
			isOpen={mutuelle ? true : false}
			toggle={close}
			className="insurance-modal"
			backdrop="static"
			size="lg"
			keyboard={true}>
			<ModalHeader>
				<div className="row">
					<div className="col-sm-11">{title || "Nouvelle mutuelle"}</div>
					<div className="col-sm-1">
						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
							title="Fermer"
							onClick={close}>
							<i className="fas fa-times" />
						</button>
					</div>
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="row align-items-center">
					<div className="col-sm-9">
						<FieldWithError
							id={"label-" + id}
							name="label"
							label="Nom de la mutuelle"
							value={mutuelle && mutuelle.label}
							onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
							error={errors?.find((el) => el === "label") ? "Merci d'entrer un nom de mutuelle" : ""}
						/>
					</div>
					<div className="col-3 font-weight-bold mt-4">
						<Checkbox
							onChange={(e) => {
								setIsTP(e.target.checked)
								setUpdated(true)
								setMutuelle((old) => ({ ...old, isTP: e.target.checked }))
							}}
							checked={isTP}>
							Tiers-payant
						</Checkbox>
						<Checkbox
							onChange={(e) => {
								setIsC2S(e.target.checked)
								setUpdated(true)
								setMutuelle((old) => ({ ...old, isC2S: e.target.checked }))
							}}
							checked={isC2S}
							className="ml-0">
							CMU
						</Checkbox>
						<Checkbox
							onChange={(e) => {
								setIsLinkedToAmo(e.target.checked)
								setUpdated(true)
								setMutuelle((old) => ({
									...old,
									isMutnum: e.target.checked,
								}))
							}}
							checked={isLinkedToAmo}
							className="ml-0"
							key={`gestion-unique-${isLinkedToAmo}`}>
							Gestion unique
						</Checkbox>
					</div>
				</div>
				<div className="row">
					<Col className="col-12 px-1 d-flex flex-column justify-content-between align-items-start">
						<AutoComplete
							className="w-100 px-1"
							id="completeAdress"
							options={mutuelleAddress}
							onSearch={(value) => handleAutoComplete(value)}
							onChange={(value) => {
								setSelectedAddress(value)
							}}
							value={selectedAddress}
							onSelect={(value, option) => {
								setMutuelle({
									...mutuelle,
									address: option.adresse,
									postalCode: option.cpo,
									city: option.city,
								})
							}}
							filterOption={mutuelleAddress?.value}
							notFoundContent={
								selectedAddress.length >= 3 &&
								"Adresse non trouvée, veuillez renseigner les champs ci-dessous."
							}>
							<FieldWithError
								id={"fullAddress"}
								name="address"
								label="Adresse complète"
								value={mutuelle && mutuelle.address}
								onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
							/>
						</AutoComplete>
					</Col>
				</div>
				<div className="row">
					<div className="col-sm-4">
						<FieldWithError
							id={"address-" + id}
							name="address"
							label="Addresse"
							value={mutuelle && mutuelle.address}
							onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
						/>
					</div>
					<div className="col-sm-4">
						<FieldWithError
							id={"postalCode-" + id}
							name="postalCode"
							label="Code Postal"
							type="number"
							value={mutuelle && mutuelle.postalCode}
							onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
						/>
					</div>
					<div className="col-sm-4">
						<FieldWithError
							id={"city-" + id}
							name="city"
							label="Ville"
							value={mutuelle && mutuelle.city}
							onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
						/>
					</div>
				</div>
				<div className="row">
					{isC2S ? (
						<div className="col-sm-6 m-2">
							<label htmlFor="amcId">Numéro C2S/CMU</label>
							<Select
								options={cmuOptions}
								defaultValue={cmuOptions.find((op) => op.value === mutuelle?.amcId)}
								id={"amc-" + id}
								name="amcId"
								label="Numéro AMC"
								key={id}
								onChange={(event) => {
									setUpdated(true)
									setMutuelle({ ...mutuelle, amcId: event.value })
								}}
							/>
						</div>
					) : (
						<div className="col-sm-6">
							<FieldWithError
								id={"amc-" + id}
								name="amcId"
								label="Numéro AMC"
								maxLength={10}
								value={mutuelle && mutuelle.amcId}
								onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
								error={
									errors?.find((el) => el === "amcId") ? "Merci d'entrer un numéro d'AMC valide" : ""
								}
							/>
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-sm-6">
						<PhoneInput
							id={"phone-" + id}
							name="phone"
							label="Numéro de contact"
							type="phoneNumber"
							placeholder="Numéro"
							value={mutuelle && mutuelle.phoneNumber}
							onChange={(value) => {
								let event = { target: { value: value, name: "phoneNumber" } }
								handleChange(event, mutuelle, setMutuelle)
							}}
						/>
					</div>
					<div className="col-sm-6">
						<FieldWithError
							id={"email-" + id}
							name="email"
							label="Email de contact"
							type="email"
							value={mutuelle && mutuelle.email}
							onChange={(event) => {
								handleChange(event, mutuelle, setMutuelle)
							}}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-9">
						<FieldWithError
							name="websiteUrl"
							type="websiteUrl"
							placeholder="https://ma-mutuelle/espace-pro/login"
							label="url de la mutuelle"
							onChange={(event) => handleChange(event, mutuelle, setMutuelle)}
							value={mutuelle && mutuelle.websiteUrl}
							className="m-0"
							error={
								errors.find((el) => el === "websiteUrl") &&
								"Le lien fourni n'est pas valide, veillez à ce qu'il respecte le format 'https://ma-mutuelle.com'"
							}
						/>
					</div>
					<div className="col-sm-2 d-flex justify-content-end align-items-end">
						<a
							href={mutuelle?.websiteUrl ? mutuelle?.websiteUrl : ""}
							target="_blank"
							rel="noopener noreferrer">
							<button className="btn btn-primary" disabled={!mutuelle.websiteUrl}>
								Accéder
							</button>
						</a>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button outline color="danger" onClick={deleteMutuelle}>
					Supprimer
				</Button>
				<Button
					outline
					color="primary"
					onClick={mutuelle.id ? (updated ? updateMutuelle : close) : createMutuelle}
					disabled={busy}>
					{updated ? "Confirmer" : "Fermer"}
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default MutuelleModal
