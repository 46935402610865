import { CSSProperties } from "react"

type BadgeProps = {
	color: string
	text: string | number
	style?: CSSProperties
}

export function Badge({ color, text, style }: BadgeProps): JSX.Element {
	if (!text) return <></>

	return (
		<span className={"d-block w-100 pt-2 pb-2 text-sm text-capitalize new-badge new-badge-" + color} style={style}>
			{text}
		</span>
	)
}
