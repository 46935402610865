import { Row, Table, TablePaginationConfig } from "antd"
import useUserInsuranceCoverageDemands from "../../services/requests/http/userInsuranceCoverageDemandService/Hooks/useUserInsuranceCoverageDemands"
import {
	HumanReadableDemandState,
	UserInsuranceCoverageDemandModel,
} from "../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"
import CoverageDemandModal from "../../components/CoverageDemandModal/CoverageDemandModal"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import useTitle from "../../components/Hooks/useTitle"
import RangePicker from "../../components/forms/RangePicker"
import fr_FR from "antd/lib/locale-provider/fr_FR"
import "./CoverageDemandListPage.scss"
import { CoverageDemandsListColumns } from "./Components/CoverageDemandsListColumns"
import CoverageDemandDocModal from "../../components/CoverageDemandDocModal/CoverageDemandDocModal"
import usePatients from "../../services/requests/http/patient/Hooks/usePatients"
import useLaboratoryTeam from "../../services/requests/http/users/Hooks/useLaboratoryTeam"
import AuthContext from "../../contexts/AuthContext"
import useUserInsuranceNetworks from "../../services/requests/http/userInsuranceNetworks/Hooks/useUserInsuranceNetworks"
import { compact } from "lodash"
import AntFilterValueTag from "./Components/AntFilterValueTag"
import { formatDateForDB, frenchDate } from "../../services/functions"
import { FilterValue } from "antd/lib/table/interface"
import filterBlankOrUndef from "../../utils/filterBlankOrUndef"

export default function CoverageDemandListPage() {
	useTitle("Demandes de Prise En Charge des mutuelles")

	const [selectedItem, setSelectedItem] = useState<UserInsuranceCoverageDemandModel | undefined>(undefined)
	const [selectedDocToSee, setSelectedDocToSee] = useState<UserInsuranceCoverageDemandModel | undefined>(undefined)
	const [pickerValue, setPickerValue] = useState<any>()

	const { laboratories } = useContext(AuthContext)
	const { data: patients } = usePatients(true)
	const { data: team } = useLaboratoryTeam()

	const [apiFilters, setApiFilters] = useState<Record<string, any>>({})

	const [pagination, setPagination] = useState({
		total: 0,
		current: 1,
		pageSize: 10,
		showSizeChanger: false,
	})

	const { data: networks } = useUserInsuranceNetworks()

	const { data, isLoading, isRefetching, refetch } = useUserInsuranceCoverageDemands({
		params: {
			...apiFilters,
			start: undefined,
			end: undefined,
			"createdAt[after]": apiFilters.start,
			"createdAt[before]": apiFilters.end,
			page: pagination.current,
			perPage: pagination.pageSize,
		},
	})

	const onTableChange = useCallback(
		(_: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorters: any) => {
			setApiFilters((old) => ({ ...old, ...filters }))

			if (sorters.column) {
				let sortKey = sorters.column.dataIndex

				switch (sortKey) {
					case "patient":
						sortKey = "patient.lastName"
						break
					case "network":
						sortKey = "network.name"
						break
					case "createdBy":
						sortKey = "createdBy.lastName"
						break
					case "laboratory":
						sortKey = "laboratory.label"
						break
					case "mainUserAtTime":
						sortKey = "mainUserAtTime.lastName"
						break
					default:
						break
				}

				setApiFilters((old) => ({
					...old,
					order: {
						key: sortKey,
						value: sorters.order === "ascend" ? "asc" : "desc",
					},
				}))
			} else {
				setApiFilters((old) => ({
					...old,
					order: undefined,
				}))
			}
		},
		[]
	)

	useEffect(() => {
		refetch()
	}, [apiFilters])

	useEffect(() => {
		if (data) {
			setPagination((v) => ({ ...v, total: data["hydra:totalItems"] }))
		}
	}, [data])

	const readableAPIFilters = useMemo(
		() =>
			compact(
				Object.entries(apiFilters).map(([k, v]) => {
					if (v === undefined || v === null) return v

					switch (k) {
						case "start":
							return { label: "Début", value: frenchDate(v), key: k }
						case "end":
							return { label: "Fin", value: frenchDate(v), key: k }
						case "laboratory":
							return {
								label: "Laboratoire",
								key: k,
								value: v?.map(
									(el: number) =>
										laboratories.find((candidate) => candidate.id === el)?.label ?? undefined
								),
							}
						case "network":
							return {
								label: "Réseau",
								key: k,
								value: v?.map(
									(el: number) =>
										networks?.find((candidate) => candidate.id === el)?.name ?? undefined
								),
							}
						case "patient":
							return {
								label: "Patient",
								key: k,
								value: v.map((el: number) => {
									const found = patients?.find((candidate) => candidate.id === el)
									if (!found) return undefined

									return `${found.firstName} ${found.lastName}`
								}),
							}
						case "mainUserAtTime":
							return {
								label: "Pour",
								key: k,
								value: v.map((el: number) => {
									const found = team?.find((candidate) => candidate.id === el)
									if (!found) return undefined

									return `${found.firstName} ${found.lastName}`
								}),
							}
						case "createdBy":
							return {
								label: "Par",
								key: k,
								value: v.map((el: number) => {
									const found = team?.find((candidate) => candidate.id === el)
									if (!found) return undefined

									return `${found.firstName} ${found.lastName}`
								}),
							}
						case "state":
							return {
								label: "État",
								key: k,
								value: v.map((el: string) => HumanReadableDemandState[el]),
							}
						default:
							return undefined
					}
				})
			),
		[apiFilters, laboratories, team, networks, patients]
	)

	const columns = useMemo(
		() =>
			CoverageDemandsListColumns(
				setSelectedItem,
				setSelectedDocToSee,
				patients,
				team,
				laboratories,
				networks,
				apiFilters
			),
		[patients, team, laboratories, networks, apiFilters]
	)

	return (
		<div className={"coverage-demand-list-page"}>
			<div className="col">
				<h1>Demandes de Prise En Charge des mutuelles</h1>
				<div className="line" />
			</div>

			<div className="col col-5 mb-4">
				<div className="mb-2">Période</div>
				<RangePicker
					locale={fr_FR.DatePicker}
					format={frenchDate}
					className="w-100"
					style={{
						height: "calc(1.5em + 1.5rem + 2px)",
						boxShadow: "inset 0 1px 1px rgba(31, 45, 61, 0.075)",
						border: "1px solid #e0e6ed",
						borderRadius: ".25rem",
						padding: "0.75rem 1.25rem",
					}}
					allowClear={true}
					value={pickerValue}
					onChange={(e) => {
						setPickerValue(e)
						if (e && e.length >= 2) {
							setApiFilters((old) => ({
								...old,
								start: e[0] ? `${formatDateForDB(e[0])}T00:00:00` : undefined,
								end: e[1] ? `${formatDateForDB(e[1])}T23:59:59` : undefined,
							}))
						} else {
							setApiFilters((old) => ({ ...old, start: undefined, end: undefined }))
						}
					}}
				/>
			</div>

			<div className="col row mb-4">
				{readableAPIFilters
					.filter((el) => filterBlankOrUndef(el.value))
					.map((el) => (
						<div className="ant-tag" key={el.label}>
							<AntFilterValueTag
								label={el.label}
								value={el.value}
								onRemove={() => {
									if (["start", "end"].includes(el.key)) {
										setApiFilters((old) => ({ ...old, start: undefined, end: undefined }))
										setPickerValue(undefined)
										return
									}

									setApiFilters((old) => ({ ...old, [el.key]: [] }))
								}}
							/>
						</div>
					))}
			</div>

			<div className="col">
				<Row className="my-2 flex-grow-1">
					<Table
						loading={isLoading || isRefetching}
						columns={columns}
						className="w-100"
						dataSource={data?.["hydra:member"] || []}
						onChange={onTableChange}
						pagination={{
							...pagination,
							position: ["bottomLeft"],
							onChange: (value) => {
								setPagination((old) => ({ ...old, current: value }))
							},
						}}
						rowKey={(record) => record.id}
					/>
				</Row>
			</div>

			<CoverageDemandModal item={selectedItem} onClose={() => setSelectedItem(undefined)} />
			<CoverageDemandDocModal item={selectedDocToSee} onClose={() => setSelectedDocToSee(undefined)} />
		</div>
	)
}
