import {
	EquipmentHistory,
	HiboutikProductEntity,
	Patient,
	PatientEquipment,
	ProductReturnForm,
} from "@audiowizard/common"
import AuthContext from "contexts/AuthContext"
import { AvailableSerialNumberType, extractAvailableSerialnumber } from "pages/Stock-Management/StockUtils"
import { useContext, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import API from "services/API"
import { removeSpecialChars } from "services/functions"
import { PatientResults } from "./PatientResults"
import ProductReturnFormResults from "./ProductReturnFormResults"
import { ProductsResults } from "./ProductsResults"
import { SerialNumberResults } from "./SerialNumberResults"
import "./Spotlight.scss"
import useHasRole from "components/Hooks/useHasRole"

type SearchResultsType = {
	[key: string]: any
	patients?: Patient[]
	serialNumbers?: any[]
	products?: any[]
	productReturnForms?: any[]
}

const CategoryName: { [key: string]: any } = {
	patients: "Patients",
	serialNumbers: "Numéros de série",
	products: "Produits",
	productReturnForms: "Bons de retour",
}

type DisplayResultProps = {
	category: string
	result: any
	onClick: () => void
	isAffiliate: boolean
}
const DisplayResult = ({ category, result, onClick, isAffiliate }: DisplayResultProps): JSX.Element => {
	if (category === "patients") return <PatientResults patient={result} onClick={onClick} />
	else if (category === "serialNumbers" && !isAffiliate) {
		return <SerialNumberResults product={result} onClick={onClick} />
	} else if (category === "products" && !isAffiliate) return <ProductsResults product={result} onClick={onClick} />
	else if (category === "productReturnForms" && !isAffiliate) {
		return <ProductReturnFormResults product={result} onClick={onClick} />
	} else return <></>
}

/**
 * A REFAIRE AU NIVEAU DU BACKEND
 */

const Spotlight = (): JSX.Element => {
	const queryClient = useQueryClient()
	const { laboratory } = useContext(AuthContext)

	const [searchValue, setSearchValue] = useState<string>("")
	const [querySearch, setQuerySearch] = useState<string>("")
	const [placeholder, setPlaceHolder] = useState("")
	const [resultPanelOpen, setResultPanelOpen] = useState(false)

	const timeoutSearch = useRef<NodeJS.Timeout | null>(null)
	const inputSearchRef = useRef<HTMLInputElement>(null)
	const containerRef = useRef<HTMLDivElement>(null) // to check for outside clicks
	const resultPanelRef = useRef<HTMLDivElement>(null) // to check for outside clicks
	const userHasTypedRef = useRef(false) // if the last setSearchValue came from the user typing in the input or set by some code
	const isAffiliate = useHasRole("ROLE_AFFILIATE")

	const searchPatients = async (search: string): Promise<Patient[]> => {
		const searchParams = []

		for (const word of search.split(/\s+/)) {
			searchParams.push(word)
		}

		return await API.findAll<Patient[]>(
			"PATIENTS_API",
			`?omnisearch[]=${searchParams.join("&omnisearch[]=")}&itemsPerPage=15`
		)
	}

	const searchProductForms = async (search: string): Promise<ProductReturnForm[]> => {
		const params = `?omnisearch=${search}`
		return await API.findAll<ProductReturnForm[]>("PRODUCT_RETURN_FORM_API", params)
	}
	const searchPatientEquipment = async (search: string): Promise<PatientEquipment[]> => {
		return await API.findAll<PatientEquipment[]>("PATIENT_EQUIPMENTS_API", `?serialNumber=${search}`)
	}

	const searchEquipmentHistory = async (search: string): Promise<any[]> => {
		return (
			await API.findAll<EquipmentHistory[]>(
				"EQUIPMENTS_HISTORY",
				`/spotlight?serialNumber=${search}&itemsPerPage=30&order[createdAt]=desc`
			)
		).filter((history: any) => history.newSerialNumber && history.patientEquipment)
	}

	const { data: productCatalog, isLoading: catalogLoading } = useQuery(
		"SPOTLIGHT_PRODUCT_CATALOG",
		async () => {
			return await API.findAll<HiboutikProductEntity[]>("PRODUCTS_API", "?pagination=false")
		},
		{
			cacheTime: 160,
		}
	)

	const { data: availableSerialNumber, isLoading: serialNumberLoading } = useQuery(
		["SPOTLIGHT_AVAILABLE_SERIALNUMBER", laboratory?.warehouseIdHiboutik],
		async () => {
			const stockAvailable = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${laboratory?.warehouseIdHiboutik ?? 1}`
			)
			return extractAvailableSerialnumber(stockAvailable) as AvailableSerialNumberType[]
		}
	)

	// TODO: A typer après release (remplacer les any)
	const getProductDetailFromCatalog = (
		catalog: HiboutikProductEntity[],
		producId: number | undefined,
		sizeId: null | number | undefined
	): Record<string, any> => {
		if (!producId) {
			return {}
		}
		const productDetail = catalog.find((catalog) => +catalog.id === +producId)
		let sizeDetail: any = {}
		if (sizeId && sizeId !== 0) {
			//@ts-ignore Temporarily ignore
			sizeDetail = productDetail?.sizeDetails?.find((size: any) => +size.sizeId === +sizeId)
		}
		return { ...productDetail, ...sizeDetail }
	}

	const getSerialNumbers = (
		productCatalog: HiboutikProductEntity[],
		serialNumbers: AvailableSerialNumberType[],
		patientEquipment: PatientEquipment[],
		equipmentHistory: EquipmentHistory[]
	): any[] => {
		let resultSerialNumbers: any[] = []

		if (serialNumbers.length) {
			resultSerialNumbers = [
				...resultSerialNumbers,
				...serialNumbers.map((sn: any) => ({ ...sn, type: "available" })),
			]
		}

		if (equipmentHistory.length) {
			const history = equipmentHistory[0]
			resultSerialNumbers.push({
				type: "history",
				oldSerialNumber: history.serialNumber,
				serialNumber: history.patientEquipment?.serialNumber,
				patient: history.patient,
				patientEquipment: history.patientEquipment,
				logisticProduct: history.logisticProduct,
				...getProductDetailFromCatalog(
					productCatalog,
					history.patientEquipment?.productIdHiboutik,
					history.patientEquipment?.sizeIdHiboutik
				),
			})
		}

		if (patientEquipment.length) {
			const result = patientEquipment
				.filter((equipment: PatientEquipment) => {
					return !resultSerialNumbers.some((result) => result?.patientEquipment?.["@id"] === equipment["@id"])
				})
				.map((equipment: PatientEquipment) => ({
					type: "patientEquipment",
					...getProductDetailFromCatalog(
						productCatalog,
						equipment?.productIdHiboutik,
						equipment?.sizeIdHiboutik
					),
					patientEquipment: equipment,
				}))
			resultSerialNumbers = [...resultSerialNumbers, ...result]
		}

		return resultSerialNumbers
	}

	const compareValue = (stra?: string, strb?: string): boolean => {
		if (!stra && !strb) return true
		if (!stra || !strb) return false
		return removeSpecialChars(stra).toLowerCase().includes(removeSpecialChars(strb).toLowerCase())
	}

	const { data: searchResults, isLoading: searchLoading } = useQuery(
		["SPOTLIGHT_SEARCH", querySearch],
		async (): Promise<SearchResultsType> => {
			if (!searchValue.length) return {}

			const patients = await searchPatients(querySearch)
			const productReturnForms = await searchProductForms(querySearch)

			const patientEquipment = await searchPatientEquipment(querySearch)
			const equipmentHistory = await searchEquipmentHistory(querySearch)

			const serialNumbersAvailable = availableSerialNumber
				?.filter((product) => compareValue(product.serialNumber, querySearch))
				.slice(0, 5)

			const products = productCatalog
				?.filter((product: any) => compareValue(product.model, querySearch))
				.slice(0, 5)

			const serialNumbers = getSerialNumbers(
				productCatalog ?? [],
				serialNumbersAvailable ?? [],
				patientEquipment,
				equipmentHistory
			)
			if (isAffiliate) return { patients }
			return { patients, serialNumbers, products, productReturnForms }
		}
	)

	useEffect(() => {
		// Only update search results if user has typed
		// if the input is cleared from clicking a link, keep the previous results displayed
		if (!userHasTypedRef.current) return
		userHasTypedRef.current = false

		clearTimeout(timeoutSearch.current as NodeJS.Timeout)
		timeoutSearch.current = setTimeout(() => {
			setQuerySearch(searchValue)
		}, 200)
	}, [searchValue])

	// detect clicks outside to close panel
	useEffect(() => {
		// no need to check for outside clicks if panel is closed
		if (!resultPanelOpen) return

		const isInRect = (x: number, y: number, rect: DOMRect): boolean =>
			x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom

		const listener = (e: MouseEvent): void => {
			if (containerRef.current == null || resultPanelRef.current == null) return

			const clickInContainer = isInRect(e.x, e.y, containerRef.current.getBoundingClientRect())
			const clickInResultPanel = isInRect(e.x, e.y, resultPanelRef.current.getBoundingClientRect())
			setResultPanelOpen(clickInContainer || clickInResultPanel)
		}
		document.addEventListener("click", listener)

		return () => {
			document.removeEventListener("click", listener)
		}
	}, [resultPanelOpen])

	useEffect(() => {
		isAffiliate
			? setPlaceHolder("Rechercher un patient...")
			: setPlaceHolder("Rechercher patient, produit, numéro de série...")
	}, [])

	const isSearchEmpty = Object.values(searchResults ?? {}).every((category) => !category.length)

	return (
		<div className="spotlight searchbar-responsive ml-2" ref={containerRef}>
			<div className="spotlight__search" onClick={() => inputSearchRef.current?.focus()}>
				<i className="spotlight__search__icon fad fa-search" />
				<input
					ref={inputSearchRef}
					disabled={catalogLoading || serialNumberLoading}
					autoComplete="off"
					className="spotlight__search__input"
					type="text"
					placeholder={placeholder}
					value={searchValue}
					onChange={(event) => {
						userHasTypedRef.current = true
						setSearchValue(event.target.value ?? "")
					}}
					onFocus={(event) => {
						setResultPanelOpen(true)
						queryClient.invalidateQueries("SPOTLIGHT_AVAILABLE_SERIALNUMBER")
						event.target.select()
					}}
				/>
			</div>

			<div
				className="spotlight__result"
				ref={resultPanelRef}
				style={{
					height: resultPanelOpen ? "50vh" : "0",
					overflow: resultPanelOpen ? "auto" : "hidden",
				}}>
				<div
					className="spotlight__result__wrapper"
					style={{
						overflow: resultPanelOpen ? "auto" : "hidden",
					}}>
					{searchLoading && <span>Recherche...</span>}
					{isSearchEmpty && !searchLoading && <span>Aucun résultat</span>}

					{Object.keys(searchResults ?? {})
						.filter((cat) => searchResults![cat].length)
						.map((cat: string, keyCat: number) => {
							return (
								<div className="spotlight__result__group" key={keyCat}>
									<div className="spotlight__result__group__category-name">
										{CategoryName[cat] ?? cat}
									</div>
									{searchResults![cat].map((result: any, resultKey: number) => (
										<DisplayResult
											isAffiliate={isAffiliate}
											key={resultKey}
											category={cat}
											result={result}
											onClick={() => {
												setResultPanelOpen(false)
												setSearchValue("") // clear input on click
											}}
										/>
									))}
								</div>
							)
						})}
				</div>
			</div>
		</div>
	)
}

export default Spotlight
