import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useState } from "react"
import { Col, Row } from "reactstrap"
import { CategoriesTable } from "./CatalogueTables"

const Categories = (): JSX.Element => {
	const [categorySearch, setCategorySearch] = useState("")

	return (
		<Col>
			<SectionHeader title="Liste des catégories produits" />
			<Row className="my-2">
				<Col md={8}>
					<input
						type="search"
						name="search"
						placeholder="Rechercher une catégorie"
						className="form-control"
						value={categorySearch}
						onChange={(e) => setCategorySearch(e.target.value)}
					/>
				</Col>
			</Row>
			<Row className="my-4">
				<Col md={12}>
					<div className="table-responsive">
						<CategoriesTable nameSearchQuery={categorySearch} />
					</div>
				</Col>
			</Row>
		</Col>
	)
}

export default Categories
