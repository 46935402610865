import Typography from "../../../components/Typography/Typography"
import "./CoveragePanelLine.scss"

interface Props {
	title: string
	value: string | number
	onClick?: () => void
}

export default function CoveragePanelLine({ title, value, onClick }: Props) {
	return (
		<div className={"coverage-panel-line"} onClick={onClick} style={onClick && { cursor: "pointer" }}>
			<div className={"line-container"}>
				<div className={"title-container"}>
					<Typography>{title}</Typography>
				</div>
				<div className={"value-container"}>
					<Typography>{value}</Typography>
				</div>
			</div>
		</div>
	)
}
