import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import useCustomTitle from "components/Hooks/useTitle"
import CashOutflowReasonSetting from "pages/Settings/CashOutflowReason/CashOutflowReasonSetting"
import PaymentTypes from "pages/Settings/paymentTypes/PaymentTypes"
import { CaisseParamètresPrefix, CaisseParamètresRoutes, noPrefix } from "./routes"

function CaisseParamètresRouter(): JSX.Element {
	useCustomTitle("Paramètres | Caisse")
	return (
		<CardWithTabs urlprefix={CaisseParamètresPrefix}>
			<CardItem
				name="Mode de paiements"
				component={PaymentTypes}
				url={noPrefix(CaisseParamètresRoutes.Paiements)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
			<CardItem
				name="Sorties de caisse pour achat"
				component={CashOutflowReasonSetting}
				url={noPrefix(CaisseParamètresRoutes.SortieDeCaisse)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default CaisseParamètresRouter
