/** Mise à jour du localStorage : CRUD sur les event (en lien avec le fichier BryntumAgenda.api) */

import { Event } from "./AgendaTypes"
import localStorage from "services/localStorage"
import _ from "lodash"
import API from "services/API"

export const addEventToLocalStorageByIri = (scheduleIri: string): void => {
	setTimeout(async () => {
		const event = await API.get<Event>(scheduleIri)
		addEventToLocalStorage(event.agenda, { schedules: [event], alreadyLoaded: undefined })
	}, 500)
}

export const addEventToLocalStorage = (
	agendaIri: string,
	{ alreadyLoaded, schedules }: { alreadyLoaded: any; schedules: Event[] }
): void => {
	let fromStorage = localStorage.getWithExpiry(agendaIri)
	if (!fromStorage) {
		fromStorage = {
			alreadyLoaded: [],
			schedules: [],
		}
	}
	localStorage.setWithExpiry(
		agendaIri,
		{
			alreadyLoaded: alreadyLoaded || fromStorage.alreadyLoaded,
			schedules: _.unionBy(schedules, fromStorage.schedules, "@id"),
		},
		3600000 // 60 * 60 * 1000 renouvellement des données toutes les heures
	)
	const schedulesFromStorage = localStorage.getWithExpiry("schedules")
	const schedulesAgenda: Record<string, string> = {}
	for (const schedule of schedules) {
		schedulesAgenda[schedule["@id"]] = schedule.agenda
	}
	localStorage.setWithExpiry(
		"schedules",
		{ ...schedulesFromStorage, ...schedulesAgenda },
		3600000 // 60 * 60 * 1000 renouvellement des données toutes les heures
	)
}

export const updateEventInLocalStorageByIri = async (scheduleIri: string): Promise<void> => {
	const schedulesFromStorage = localStorage.getWithExpiry("schedules")
	const currentAgenda = schedulesFromStorage?.[scheduleIri]
	if (!currentAgenda) return
	setTimeout(async () => {
		const event = await API.get<Event>(scheduleIri)
		updateEventInLocalStorage(event, false, currentAgenda !== event.agenda ? currentAgenda : undefined)
	}, 500)
}

export const updateEventInLocalStorage = (schedule: Event, add: boolean, oldAgenda?: string): void => {
	let storageForAgenda = localStorage.get(schedule.agenda)
	let newSchedules = []
	if (!storageForAgenda || !storageForAgenda.value) {
		storageForAgenda = {
			value: {
				schedules: [],
			},
		}
	}
	if (add || oldAgenda) {
		newSchedules = [...storageForAgenda.value.schedules, schedule]
	} else {
		newSchedules = storageForAgenda.value.schedules.map((sched: Event) => {
			if (sched.id !== schedule.id) {
				return sched
			} else {
				return schedule
			}
		})
	}

	localStorage.set(schedule.agenda, {
		...storageForAgenda,
		value: {
			...storageForAgenda.value,
			schedules: newSchedules,
		},
	})

	// if agenda changed, remove event from old agenda
	if (oldAgenda) {
		const storageForAgenda = localStorage.get(oldAgenda)
		if (storageForAgenda && storageForAgenda.value) {
			localStorage.set(oldAgenda, {
				...storageForAgenda,
				value: {
					...storageForAgenda.value,
					schedules: storageForAgenda.value.schedules.filter((sched: Event) => sched.id !== schedule.id),
				},
			})
		}
		const schedulesFromStorage = localStorage.getWithExpiry("schedules")

		localStorage.setWithExpiry(
			"schedules",
			{ ...schedulesFromStorage, [schedule["@id"]]: schedule.agenda },
			3600000 // 60 * 60 * 1000 renouvellement des données toutes les heures
		)
	}
}

export const removeEventFromLocalStorage = async (schedule: Event | string): Promise<void> => {
	let agenda = ""
	let scheduleId = ""
	if (typeof schedule === "string") {
		const schedulesFromStorage = localStorage.getWithExpiry("schedules")
		agenda = schedulesFromStorage[schedule]
		scheduleId = schedule.split("/")[2]
	} else {
		agenda = schedule.agenda
		scheduleId = schedule.id.toString()
	}
	const storageForAgenda = localStorage.get(agenda)
	if (!storageForAgenda || !storageForAgenda.value) {
		return
	}
	localStorage.set(agenda, {
		...storageForAgenda,
		value: {
			...storageForAgenda.value,
			schedules: storageForAgenda.value.schedules.filter((sched: Event) => sched.id.toString() !== scheduleId),
		},
	})
}

export const cleanEventFromLocalStorage = (agendas: { "@id": string }[]): void => {
	agendas.forEach((agenda) => {
		localStorage.del(agenda["@id"])
	})
}
