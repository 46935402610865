/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartBar from "./ChartBar"

const Attentes = () => {
	const [origines, setOrigines] = useState([])
	const [loading, setLoading] = useState(true)
	const [nodata, setNodata] = useState(false)

	const getRendezVous = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/orls")
			const data = []

			if (!result?.data?.message?.length) {
				setNodata(true)
				return setLoading(false)
			}
			result?.data?.message.forEach((e) => {
				data.push({
					x: e.first_name[0].toUpperCase() + e.first_name.slice(1) + " " + e.last_name.toUpperCase(),
					y: parseInt(e.total),
				})
			})
			data.sort(function (a, b) {
				return b.y - a.y
			})

			setOrigines(data)
			setLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getRendezVous()
	}, [])

	return (
		<>
			<h5 className="mb-4">Classement des ORL</h5>
			<ChartBar series={[{ name: "Origines", data: origines }]} labels={[]} />
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
			{nodata && (
				<>
					<div className="chart_loading">Aucune donnée disponible</div>
				</>
			)}
		</>
	)
}

export default Attentes
