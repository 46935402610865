import { UserOutlined } from "@ant-design/icons"
import { Patient } from "@audiowizard/common"
import dayjs from "dayjs"
import { Link, useHistory } from "react-router-dom"

type PatientResultsProps = {
	patient: Patient
	onClick: () => void
}
export const PatientResults = ({ patient, onClick }: PatientResultsProps): JSX.Element => {
	const history = useHistory()

	return (
		<Link
			to={"/fiche-patient/" + patient.id}
			className="item"
			onClick={() => {
				onClick?.()

				history.push("/fiche-patient/" + patient.id)
			}}>
			<div className="d-flex align-items-start">
				<UserOutlined className="ml-3 mr-1 mt-1" />
				<div>
					<span className="font-weight-bold">{patient?.lastName}</span> {patient?.firstName}
					{patient?.birthDate && <span>, {dayjs().diff(patient.birthDate, "year")} ans</span>},{" "}
					<span>{patient?.laboratory?.label}</span>
				</div>
			</div>
		</Link>
	)
}
