import PropTypes from "prop-types"

import BoostrapModal from "reactstrap/lib/Modal"
import { ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

const Modal = ({
	isOpen,
	title,
	onClose,
	onCancel,
	onConfirm,
	cancelDisabled,
	confirmText,
	confirmDisabled,
	children,
	cancelText,
	icon,
	className,
	loading,
	buttonType,
	bigButtons,
	size,
	additionalAction,
}) => {
	return (
		<BoostrapModal
			backdrop={onClose ? true : "static"}
			className={className}
			centered
			size={size}
			isOpen={isOpen}
			toggle={onClose}>
			<ModalHeader className="w-100" tag="div">
				<div className="d-flex flex-row align-items-center justify-content-between">
					<div className="d-flex flex-row align-items-center">
						<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
							<i className={`fad fa-${icon}`} />
						</div>
						<div>
							<h6 className="mb-0">{title}</h6>
						</div>
					</div>
					{onClose && (
						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 ml-3 close-icon"
							aria-label="Close"
							onClick={onClose}>
							<i className="fas fa-times" />
						</button>
					)}
				</div>
			</ModalHeader>
			<ModalBody>{children}</ModalBody>
			{(onConfirm || onCancel) && (
				<ModalFooter>
					<div className="d-flex flex-row-reverse align-items-center justify-content-between w-100">
						{onConfirm && (
							<button
								className={`btn btn-sm btn-${buttonType ? buttonType : "secondary"} ${confirmDisabled && "disabled"
									} ${bigButtons ? "w-50 m-1 p-3" : ""}`}
								disabled={confirmDisabled || loading}
								onClick={onConfirm}>
								{loading ? <Spinner size="sm" color="black" /> : confirmText}
							</button>
						)}
						{additionalAction && (
							<button {...additionalAction.attributes}>{additionalAction.childNode}</button>
						)}
						{onCancel && (
							<button
								className={`btn btn-sm btn-danger ${bigButtons ? "w-50 m-1 p-3" : ""} ${cancelDisabled && "disabled"
									}`}
								onClick={onCancel}>
								{cancelText}
							</button>
						)}
					</div>
				</ModalFooter>
			)}
		</BoostrapModal>
	)
}

Modal.defaultProps = {
	title: "",
	cancelText: "Annuler",
	confirmText: "Confirmer",
	disableOk: false,
	icon: "user",
	loading: false,
	buttonType: "",
	bigButtons: false,
	size: undefined,
	cancelDisabled: undefined,
	className: undefined,
}

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	title: PropTypes.string,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
	cancelText: PropTypes.string,
	confirmText: PropTypes.string,
	confirmDisabled: PropTypes.bool,
	icon: PropTypes.string,
	loading: PropTypes.bool,
	buttonType: PropTypes.string,
	bigButtons: PropTypes.bool,
	additionalAction: PropTypes.object,
}

export default Modal
