import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { getIdFromIri } from "services/functions"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		title: "Inventaire",
		dataIndex: "inventory",
		width: "400px",
		render: (inventory) => {
			return (
				<Link to={"/mon-stock/inventaire/" + getIdFromIri(inventory?.["@id"])}>
					<button type="button" className="btn btn-block btn-sm btn-secondary pt-1 pb-1 text-left">
						{inventory?.label}
					</button>
				</Link>
			)
		},
	},
	{
		title: "Numéro de série",
		dataIndex: "productSerialNumber",
		render: (serialNumber) => (
			<>
				{serialNumber ? (
					<span style={{ fontFamily: "Monospace" }}>{serialNumber}</span>
				) : (
					<span style={{ opacity: "0.5" }}>Sans numéro de série</span>
				)}
			</>
		),
	},
	{
		title: "Quantité d'origine",
		dataIndex: "originalQuantity",
		align: "center",
		width: "100px",
	},
	{
		title: "Nouvelle quantité",
		dataIndex: "newQuantity",
		align: "center",
		width: "100px",
		render: (quantity, inventoryProduct) => {
			if (inventoryProduct.productSerialNumber) return "0"
			return quantity
		},
	},
	{
		title: "Date de validation",
		dataIndex: "inventory",
		width: "140px",
		render: (inventory) =>
			inventory?.validatedAt ? dayjs(inventory?.validatedAt).format("DD/MM/YY HH:mm") : <span>Non finalisé</span>,
	},
]
