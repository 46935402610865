import dayjs from "dayjs"

import { Laboratory, LaboratoryAttendanceGenerated } from "@audiowizard/common"
import { AgendaStore } from "./helpers/AgendaTypes"

const dayOfWeek = ["DIMANCHE", "LUNDI", "MARDI", "MERCREDI", "JEUDI", "VENDREDI", "SAMEDI"]

export class Attendance {
	startDay = "00:00"
	endDay = "24:00"
	attendances: Record<string, Record<string, { laboratory: Laboratory; timeEnd: string; timeStart: string }[]>>
	// keep track of the start of day for each users
	startTimes: Record<string, number>
	showSunday: Record<string, boolean>
	constructor(attendances: LaboratoryAttendanceGenerated[], laboratories: Laboratory[]) {
		this.attendances = {}
		this.startTimes = {}
		this.showSunday = {}
		const agendaColors = {}
		// @ts-ignore need to update common file with agendaColor
		laboratories.forEach((lab) => (agendaColors[lab["@id"]] = lab.agendaColor))
		attendances.forEach((attendance) => {
			// ce n'est pas une attendance valide, inutile de procéder les données
			const userId = attendance.user?.["@id"]
			if (!userId) {
				return
			}
			if (!this.attendances[userId]) {
				this.attendances[userId] = {
					LUNDI: [],
					MARDI: [],
					MERCREDI: [],
					JEUDI: [],
					VENDREDI: [],
					SAMEDI: [],
					DIMANCHE: [],
				}
			}
			if (attendance.day === "DIMANCHE") {
				this.showSunday[userId] = true
			}
			if (!this.startTimes[userId]) {
				this.startTimes[userId] = dayjs(attendance.timeStart, "HH:mm").valueOf()
			} else {
				this.startTimes[userId] = Math.min(
					this.startTimes[userId],
					dayjs(attendance.timeStart, "HH:mm").valueOf()
				)
			}
			if (attendance.day && attendance.laboratory) {
				this.attendances[userId][attendance.day].push({
					// @ts-ignore need to update common file
					laboratory: { ...attendance.laboratory, agendaColor: agendaColors[attendance.laboratory["@id"]] },
					timeStart: attendance.timeStart,
					timeEnd: attendance.timeEnd,
				})
			}
		})
	}

	getDay(day: string): string {
		return (
			{
				LUNDI: "MO",
				MARDI: "TU",
				MERCREDI: "WE",
				JEUDI: "TH",
				VENDREDI: "FR",
				SAMEDI: "SA",
				DIMANCHE: "SU",
			}[day] || "X"
		)
	}

	// get the attendance for a user on a specific day
	fetchZonesForUser(user: string, day: number, laboratories: number[]): any[] {
		const disabledTimes: any[] = []
		const disabledTime = {
			startTime: this.startDay,
			endTime: this.endDay,
		}

		const attendanceForDay = this.attendances[user]
			? this.attendances[user][dayOfWeek[day]].sort((att1, att2) => (att1.timeStart < att2.timeStart ? -1 : 1))
			: []

		attendanceForDay.forEach((att) => {
			if (laboratories.indexOf(parseInt(att.laboratory["@id"].split("/")[2], 10)) !== -1) {
				disabledTimes.push({
					...disabledTime,
					startTime: disabledTime.startTime,
					endTime: att.timeStart,
				})
				disabledTimes.push({
					...disabledTime,
					startTime: att.timeStart,
					endTime: att.timeEnd,
					laboratory: att.laboratory,
					// @ts-ignore need to update common file
					color: att.laboratory.agendaColor,
				})
				disabledTime.startTime = att.timeEnd
			}
		})

		disabledTimes.push(disabledTime)

		return disabledTimes
	}

	/**
	 * @description get the user Laboratory for a specific date
	 * @param user
	 * @param startTime
	 * @returns
	 */
	getLaboratory(user: string, startTime: Date): Laboratory | null {
		const day = dayOfWeek[dayjs(startTime).day()]
		if (this.attendances[user]?.[day]) {
			const attendance = this.attendances[user][day].find(
				(att) =>
					att.timeStart <= dayjs(startTime).format("HH:mm") && att.timeEnd > dayjs(startTime).format("HH:mm")
			)

			if (attendance) {
				return attendance.laboratory
			}
		}

		return null
	}

	/**
	 * @description get the user Laboratory for a specific date
	 * @param user
	 * @param startTime
	 * @returns
	 */
	getLaboratories(
		user: string,
		startTime: Date,
		agendaStore: AgendaStore
	): { data: Laboratory; multiple: false } | { data: Laboratory[]; multiple: true } | { data: null; multiple: null } {
		const day = dayOfWeek[dayjs(startTime).day()]
		if (this.attendances[user]?.[day]) {
			const attendances = this.attendances[user][day].filter(
				(att) =>
					att.timeStart <= dayjs(startTime).format("HH:mm") &&
					att.timeEnd > dayjs(startTime).format("HH:mm") &&
					agendaStore.getAgendaByUserId(user, att.laboratory)
			)

			if (attendances?.length === 1) {
				return { data: attendances[0].laboratory, multiple: false }
			}

			if (attendances?.length > 1) {
				return { data: attendances.map((a) => a.laboratory), multiple: true }
			}
		}

		return { data: null, multiple: null }
	}

	/**
	 * @description obtenir l’heure de début de journée
	 * @param users
	 */
	getConfigViewFromAttendance(users: string[]): string {
		let startTime = dayjs("24:00", "HH:mm").valueOf()
		for (const user of users) {
			startTime = Math.min(startTime, this.startTimes[user] || dayjs("08:00", "HH:mm").valueOf())
		}

		return dayjs(startTime).format("HH:mm")
	}
}
