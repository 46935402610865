import API from "../../services/API"

export default async function handleChangesGeneric(data, onSuccess, onError) {
	const { currentRessource, contextRessource, setContextRessource, updateObject, target } = data
	let tmp = updateObject

	if (!currentRessource || !contextRessource || typeof setContextRessource !== "function" || !target) {
		console.error("handleChangesGeneric(): Dear dev, please provide all arguments")
		return null
	}

	if (typeof updateObject !== "object") tmp = currentRessource

	try {
		const updatedState = [...contextRessource]
		let result = null
		if (currentRessource.id) {
			result = await API.update(target, currentRessource.id, tmp)

			const index = contextRessource.findIndex((v) => v.id === currentRessource.id)
			if (index !== -1) {
				updatedState[index] = result.data
			}
		} else {
			result = await API.create(target, tmp)
			updatedState.push(result.data)
		}

		if (typeof onSuccess === "function") onSuccess(result)
		setContextRessource(updatedState)
	} catch ({ response }) {
		const { data } = response
		const { violations } = data
		let errors = {}

		if (violations) {
			violations.forEach(({ propertyPath, message }) => (errors[propertyPath] = message))
			if (typeof onError === "function") onError(errors)
		} else {
			if (typeof onError === "function") onError(response)
		}
	}
}
