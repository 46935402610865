/* eslint-disable react-hooks/exhaustive-deps */
import "./Schedule.PatientSelect.scss"
import { Select } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import API from "../../../services/API"

import { Patient } from "@audiowizard/common"
import PatientClassBadge from "pages/fiche-patient/PatientClassBadge"
import AuthContext from "../../../contexts/AuthContext"
import { PatientCreationModalDynamic } from "./PatientCreationModalDynamic"
import useHasRole from "components/Hooks/useHasRole"
import { MAX_PATIENTS, getListPatient } from "../Agenda/helpers/EventEditor"

const { Option, OptGroup } = Select

interface SchedulePatientSearchProps {
	data: any
	setData: (d: any) => void
	selectedLaboratory?: number
	selectedUser?: number
	disabled: boolean
	isDoctolib: boolean
}

const SchedulePatientSearch: React.FC<SchedulePatientSearchProps> = ({
	data,
	setData,
	selectedLaboratory,
	selectedUser,
	disabled,
	isDoctolib,
}) => {
	const { companySettings } = useContext(AuthContext)
	const [modalCreation, setModalCreation] = useState(false)
	const [loading, setLoading] = useState(false)
	const [patientList, setPatientList] = useState<Patient[]>([])
	const [busy, setBusy] = useState(false)

	const isAffiliate = useHasRole("ROLE_AFFILIATE")
	const isAffiliateManager = useHasRole("ROLE_AFFILIATE_MANAGER")

	const timeout = useRef<NodeJS.Timeout>()

	useEffect(() => {
		API.findAll<Patient[]>("PATIENTS_API", "?pagination=true").then((r) => {
			setPatientList(r)
		})
	}, [])

	const searchPatient = async (value: string): Promise<void> => {
		try {
			setLoading(true)

			const patients = await getListPatient(value)

			setPatientList(patients)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleSelect = async (value: string): Promise<void> => {
		if (value === "new") {
			setModalCreation(true)
			return
		}
		try {
			setLoading(true)
			setBusy(true)
			if (!value) {
				setData((old: any) => ({ ...old, patient: null }))
				return
			}
			const patient = await API.find("PATIENTS_API", value)
			setData((old: any) => ({ ...old, patient }))
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
			setBusy(false)
		}
	}

	return (
		<div className="schedule-type-selector">
			<Select
				size={"large"}
				showSearch
				disabled={disabled || busy || isDoctolib}
				loading={loading}
				allowClear
				placeholder="Rechercher un patient..."
				style={{ width: "100%" }}
				filterOption={false}
				value={data?.patient?.id}
				onSearch={(value) => {
					if (!value.length) return
					clearTimeout(timeout.current as NodeJS.Timeout)
					timeout.current = setTimeout(() => {
						searchPatient(value)
					}, 350)
				}}
				onClear={() => {
					setData((old: any) => ({ ...old, patient: null }))
				}}
				onChange={(value) => handleSelect(value)}>
				{companySettings.createPatientOutsideDashboard && !isAffiliate && !isAffiliateManager && (
					<Option value="new">
						{/* wrap <i> in <span> to prevent react from crashing when updating DOM*/}
						<span>
							<i className="fad fa-user-plus" />
						</span>
						Créer un nouveau patient
					</Option>
				)}
				<OptGroup label="Patients">
					{/* If current value is not present in options list, add it */}
					{data?.patient?.id != null && !patientList.some((p) => p.id === data?.patient?.id) && (
						<Option value={data?.patient?.id}>
							{data.patient.firstName} {data.patient.lastName}
							<PatientClassBadge patient={data.patient} />
						</Option>
					)}

					{patientList.map((v) => (
						<Option value={v.id} key={v.id}>
							{v.firstName} {v.lastName}
							<span className="patient-select-laboratory-option">{v.laboratory?.label || ""}</span>
							{v.id === data?.patient?.id && <PatientClassBadge patient={data.patient} />}
						</Option>
					))}
				</OptGroup>
			</Select>
			<PatientCreationModalDynamic
				setPatient={(patient) => {
					if (patient) setData((old: any) => ({ ...old, patient }))
				}}
				modalOpen={modalCreation}
				setModalOpen={setModalCreation}
				selectedLaboratory={selectedLaboratory}
				selectedUser={selectedUser}
			/>
		</div>
	)
}

export default SchedulePatientSearch
