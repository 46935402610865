import { useMutation, useQueryClient } from "react-query"
import {
	UserInsuranceCoverageDemandService,
	userInsuranceCoverageDemandServiceKey,
	userInsuranceCoverageDemandServicePatientKey,
} from "../userInsuranceCoverageDemandService"
import axios from "axios"
import { toast } from "react-toastify"

export default function useCreateUserInsuranceCoverageDemand() {
	const client = useQueryClient()

	return useMutation({
		mutationFn: UserInsuranceCoverageDemandService.create,
		onSuccess: async () =>
			Promise.allSettled([
				client.invalidateQueries([userInsuranceCoverageDemandServiceKey]),
				client.invalidateQueries([userInsuranceCoverageDemandServicePatientKey]),
			]),
		onError: (e) => {
			if (axios.isAxiosError(e) && e.response?.status === 403) {
				toast.error(
					"Impossible de vous identifier auprès de Datamut, veuillez vérifier vos identifiants réseau et laboratoire."
				)
			} else {
				toast.error("Une erreur s’est produite lors de la récupération de l’état de la PEC.")
			}
		},
	})
}
