export const ParametreRoutePrefix = "/parametres"

export const PersonnalisationRoutePrefix = ParametreRoutePrefix + "/personnalisation"

export const PersonnalisationRoutes = {
	Templates: `${PersonnalisationRoutePrefix}/templates`,
	Options: `${PersonnalisationRoutePrefix}/options`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(PersonnalisationRoutePrefix, "")
}
