import { User, UserRole } from "@audiowizard/common"
import SelectWithError from "../SelectWithError"
import { useEffect, useState } from "react"
import { UserRoles, hasOneOfThisRole } from "components/Hooks/useHasRole"

export enum AudioStatus {
	StateRegistered = "STATE_GRADUATE",
	ForeignRegistered = "GRADUATED_ANOTHER_COUNTRY",
	Other = "OTHER",
}

export const rolesAudio: UserRole[] = [UserRoles.User, UserRoles.Manager, UserRoles.ManagerFranchised]

const status = {
	[AudioStatus.StateRegistered]: {
		value: AudioStatus.StateRegistered,
		label: "Audioprothésiste Diplômé d'État (D.E.)",
	},
	[AudioStatus.ForeignRegistered]: {
		value: AudioStatus.ForeignRegistered,
		label: "Audioprothésiste diplômé d'un autre pays étranger",
	},
	[AudioStatus.Other]: { value: AudioStatus.Other, label: "Autre" },
}

interface UserRegistrationStatusSelectProps {
	registrationStatus?: AudioStatus
	errorRegistrationStatus?: string
	disabled?: boolean
	roles?: UserRole[]
	handleChangeRegistrationStatus: ((value: AudioStatus) => void) | undefined
}

export const isStateRegistered = (user: User): boolean => {
	// @ts-ignore common not up to date
	return user.registrationStatus === AudioStatus.StateRegistered
}

export const labelStatusRegistration = (user: User): string => {
	if (isStateRegistered(user)) return "D.E "
	return ""
}

const UserRegistrationStatusSelect = ({
	registrationStatus,
	errorRegistrationStatus,
	disabled,
	roles,
	handleChangeRegistrationStatus,
}: UserRegistrationStatusSelectProps): JSX.Element => {
	const [isAudio, setIsAudio] = useState(true)

	useEffect(() => {
		setIsAudio(hasOneOfThisRole(roles ?? [], rolesAudio))
	}, [roles])

	useEffect(() => {
		if (!isAudio && handleChangeRegistrationStatus) handleChangeRegistrationStatus(AudioStatus.Other)
	}, [isAudio])

	return (
		<SelectWithError<AudioStatus>
			label="Statut *"
			className="w-100 removeantd-class antd-add-padding"
			defaultValue={null}
			value={registrationStatus}
			placeholder="Statut D.E de l’audioprothésiste"
			onChange={handleChangeRegistrationStatus}
			options={[status.STATE_GRADUATE, status.GRADUATED_ANOTHER_COUNTRY, status.OTHER]}
			disabled={!isAudio || disabled}
			groupClassName={""}
			error={errorRegistrationStatus}
		/>
	)
}

export default UserRegistrationStatusSelect
