/* eslint-disable react-hooks/exhaustive-deps */
import Chart from "react-apexcharts"
import fr from "apexcharts/dist/locales/fr.json"

const ChartPie = ({ series, labels, colors, legends }) => {
	const data = {
		options: {
			chart: {
				locales: [fr],
				defaultLocale: "fr",
			},
			pie: {
				expandOnClick: false,
				height: "200px",
			},
			noData: {
				text: "pas assez d'informations pour produire le graphique",
			},
		},
	}

	if (colors) data.options.colors = colors
	if (labels) data.options.labels = labels

	if (!legends) {
		data.options.legend = {
			show: true,
			position: "bottom",
			horizontalAlign: "center",
		}
	}

	return (
		<>
			<Chart options={data.options} series={series} type="pie" width="100%" />
		</>
	)
}

ChartPie.defaultProps = {
	legends: null,
	labels: null,
	colors: null,
}

export default ChartPie
