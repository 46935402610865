import { AxiosResponse } from "axios"
import AuthContext from "contexts/AuthContext"
import Handlebars from "handlebars"
import fileDownload from "js-file-download"
import PropTypes from "prop-types"
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react"
import EmailEditor, { Design, HtmlExport, UnlayerOptions } from "react-email-editor"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { generateTemplate } from "services/requests/http/templates/generatePDF"
import ModalEmptyTags from "./ModalEmptyTags"
import {
	Context,
	MergeTags,
	addBaliseToTemplate,
	baliseToTemplateHtml,
	buildMergerTags,
	cleanMergeTagsForEditor,
	createDataForTemplateFromContext,
	createStoreFromMergeTags,
	getBase64FromUrl,
	restoreTagInDesign,
} from "./UnlayerEditor.helper"

export async function generatePDF(data: {
	label: string
	type: string
	content: string
}): Promise<AxiosResponse | undefined> {
	try {
		const pdf = await generateTemplate(data, { responseType: "blob" })
		return pdf
	} catch (e) {
		console.error("Erreur generation du dpf")
		return undefined
	}
}

export async function generatePDFWithOption(data: {
	label: string
	type: string
	content: string
}): Promise<AxiosResponse | undefined> {
	const pdf = await generateTemplate(JSON.stringify({ content: data, options: { landscape: true } }), {
		responseType: "blob",
	})
	return pdf
}

Handlebars.registerHelper("toLowerCase", function (value: string) {
	if (!value) return ""
	return new Handlebars.SafeString(value.toString()?.toLowerCase())
})

Handlebars.registerHelper("signature", function (value: string) {
	const alt = value === "non définie" ? value : "Signature audioprothésiste"
	return new Handlebars.SafeString(`<img width='150' src='${value}' alt='${alt}'/>`)
})

const UnlayerEditor = React.forwardRef<EmailEditor, any>(
	(
		{
			template,
			onSave,
			mergeTags,
			labelTemplate,
			onChange,
			forceUpdate,
			actions,
			store,
			showSaveButton,
			onDuplicate,
			onEditorLoad,
			resetChange,
			css,
		},
		ref
	) => {
		// const { t } = useTranslation(LocaleNamespace.Context)
		const { user, laboratory } = useContext(AuthContext)
		const [loaded, setLoaded] = useState(false)
		const [preparedMergeTags, _setPreparedMergeTags] = useState({})
		const preparedMergeTagsRef = useRef<MergeTags>({})
		const [storeFromMergeTags, _setStoreFromMergeTags] = useState<Context>({})
		const [isSaving, setIsSaving] = useState(false)
		const [isExporting, setIsExporting] = useState(false)
		const [isDoingAction, setIsDoingAction] = useState(-1)
		const [isDuplicate, setIsDuplicate] = useState(false)
		const [htmlToPrint, setHtmlToPrint] = useState<string>("")
		const alreadyUseBaliseRef = React.useRef(false)
		const [showAlertEmptyTags, setShowAlertEmptyTags] = useState(false)
		const [rowHtml, setRowHtml] = useState("")
		const [actualAction, setActualAction] = useState("none")
		const [indexAction, setIndexAction] = useState(-1)
		let editorRef = useRef<EmailEditor>()

		const storeFromMergeTagsRef = useRef<Context>({})
		const setStoreFromMergeTags = (store: Context): void => {
			storeFromMergeTagsRef.current = store
			_setStoreFromMergeTags(store)
		}

		const setPreparedMergeTags = (m: MergeTags): void => {
			preparedMergeTagsRef.current = m
			_setPreparedMergeTags(m)
		}

		const { data: data64Logo } = useQuery("logo", () =>
			getBase64FromUrl(laboratory.subCompany?.logo ?? user?.company?.logo)
		)
		useEffect(() => {
			if (loaded && template && preparedMergeTags) {
				editorRef?.current?.loadDesign(
					addBaliseToTemplate(JSON.parse(template), preparedMergeTagsRef.current, {
						logo: data64Logo,
					})
				)

				alreadyUseBaliseRef.current = true
				return
			}
			alreadyUseBaliseRef.current = false
		}, [template, preparedMergeTags, forceUpdate, data64Logo])

		useEffect(() => {
			let enhanceMergeTags: MergeTags
			if (store) {
				enhanceMergeTags = buildMergerTags(mergeTags, store)
			} else {
				enhanceMergeTags = buildMergerTags(mergeTags)
			}
			setPreparedMergeTags(enhanceMergeTags)
			setStoreFromMergeTags(createStoreFromMergeTags(enhanceMergeTags))
			// @ts-ignore
			editorRef?.current?.editor?.setMergeTags(cleanMergeTagsForEditor(enhanceMergeTags))
		}, [mergeTags, store])

		const designUpdate = (): void => {
			onChange()
		}

		const onLoad = (): void => {
			setLoaded(true)
		}

		const onReady = async (): Promise<void> => {
			// editor is ready
			if (template) {
				editorRef?.current?.loadDesign(
					addBaliseToTemplate(JSON.parse(template), preparedMergeTags, { logo: data64Logo })
				)
				alreadyUseBaliseRef.current = true
			}
			// @ts-ignore
			editorRef?.current?.addEventListener("design:loaded", ({ design }: { design: Design }): void => {
				if (!alreadyUseBaliseRef.current) {
					editorRef?.current?.exportHtml(({ design }: HtmlExport) => {
						editorRef?.current?.loadDesign(
							addBaliseToTemplate(design, preparedMergeTagsRef.current, { logo: data64Logo })
						)
					})
					alreadyUseBaliseRef.current = true
				}
			})
			editorRef?.current?.addEventListener("design:updated", designUpdate)

			// @ts-ignore
			// editorRef?.current?.editor?.setBodyValues({
			// 	contentWidth: "100%",
			// })
			editorRef?.current?.registerCallback(
				// @ts-ignore
				"previewHtml",
				// les balises dans le html ont déjà été remplacé, mais le html a été échappé
				// pour le melle il faut remettre la chaîne correctement
				function (params: { html: string }, done: ({ html }: { html: string }) => void) {
					const data = createDataForTemplateFromContext(
						preparedMergeTagsRef.current,
						storeFromMergeTagsRef.current
					)
					const improveHtml = params.html
						.replace(/&lt;/g, "<")
						.replace(/&gt;/g, ">")
						.replace(/&quot;/g, '"')

					const result = Handlebars.compile(
						baliseToTemplateHtml(improveHtml, preparedMergeTagsRef.current, css)
					)(data)

					done({
						html: result, // you can pass your custom html here
					})
				}
			)

			onEditorLoad?.()
		}

		const handleExportPDF = (html?: string): void => {
			html = html || rowHtml
			const dataInGeneratePDF = {
				label: "PDF",
				type: labelTemplate,
				content: JSON.stringify({
					html: Handlebars.compile(baliseToTemplateHtml(html, preparedMergeTags, css, true))(
						createDataForTemplateFromContext(preparedMergeTags, storeFromMergeTags)
					),
					options: { margin: { top: 0, bottom: 0, left: 0, right: 0 } },
				}),
			}
			try {
				generatePDF(dataInGeneratePDF).then((res) => {
					if (res) {
						fileDownload(
							res.data,
							`${dataInGeneratePDF.label}_${dataInGeneratePDF.type}_${Date.now()}.pdf`,
							res.headers["content-type"]
						)
					}
					setIsExporting(false)
				})
			} catch (e) {
				toast.error("Erreur lors de la génération du PDF")
				setIsExporting(false)
			}
			setShowAlertEmptyTags(false)
		}

		const exportPDF = (): void => {
			editorRef?.current?.exportHtml((data: HtmlExport) => {
				setIsExporting(true)
				const { html } = data
				if (html) {
					const hasEmptyTag = html.search(/data-is-empty/)
					if (hasEmptyTag !== -1) {
						setRowHtml(html)
						setActualAction("exportPDF")
						setShowAlertEmptyTags(true)
					} else {
						handleExportPDF(html)
					}
				}
			})
		}

		const handleAction = async (html?: string, index?: number): Promise<void> => {
			html = html || rowHtml
			index = index !== -1 && index !== undefined ? index : indexAction
			setShowAlertEmptyTags(false)
			if (index !== undefined) {
				try {
					await actions[index].function(
						Handlebars.compile(baliseToTemplateHtml(html, preparedMergeTags, css, true))(
							createDataForTemplateFromContext(preparedMergeTags, storeFromMergeTags)
						),
						() => {
							return void 0
						}
					)
				} catch (error) {
					console.error("actionExport", error)
					toast.error("Problème lors de l'action")
				} finally {
					setIsDoingAction(-1)
				}
			} else {
				setIsDoingAction(-1)
			}
		}

		const actionExport = async (index: number): Promise<void> => {
			// on retourne directement le html
			if (actions[index].export) {
				editorRef?.current?.exportHtml((data: HtmlExport) => {
					setIsDoingAction(index)
					// can return un object design too
					const { html } = data
					if (html) {
						const hasEmptyTag = html.search(/data-is-empty/)
						if (hasEmptyTag !== -1) {
							setRowHtml(html)
							setActualAction("action")
							setIndexAction(index)
							setShowAlertEmptyTags(true)
						} else {
							handleAction(html, index)
						}
					} else {
						setIsDoingAction(-1)
					}
				})
				return
			}
			// on retourne les données à insérer dans le template
			if (actions[index].withData) {
				actions[index].function(createDataForTemplateFromContext(preparedMergeTags, storeFromMergeTags), () => {
					return void 0
				})
				return
			}

			actions[index].function()
		}

		const saveTemplate = (): void => {
			editorRef?.current?.exportHtml(({ design, html }: HtmlExport) => {
				setIsSaving(true)
				onSave(
					restoreTagInDesign(design, preparedMergeTags),
					baliseToTemplateHtml(html, preparedMergeTags, css),
					() => {
						setIsSaving(false)
					}
				)
			})
		}

		const duplicateTemplate = (): void => {
			editorRef?.current?.exportHtml(({ design, html }: HtmlExport) => {
				setIsDuplicate(true)
				onDuplicate(
					restoreTagInDesign(design, preparedMergeTags),
					baliseToTemplateHtml(html, preparedMergeTags, css),
					() => {
						setIsDuplicate(false)
					}
				)
			})
		}

		const handlePrint = (html?: string): void => {
			html = html || rowHtml
			const newHtmlToPrint = Handlebars.compile(baliseToTemplateHtml(html, preparedMergeTags, css, true))(
				createDataForTemplateFromContext(preparedMergeTags, storeFromMergeTags)
			)
			// if the html didn't change the onLoad event isn't trigger, we have to call the event directly
			if (newHtmlToPrint !== htmlToPrint) {
				setHtmlToPrint(newHtmlToPrint)
			} else {
				onIframeLoaded()
			}
			setShowAlertEmptyTags(false)
			setActualAction("none")
		}

		const print = (): void => {
			editorRef?.current?.exportHtml(({ html }: HtmlExport) => {
				const hasEmptyTag = html.search(/data-is-empty/)
				if (hasEmptyTag !== -1) {
					setRowHtml(html)
					setActualAction("print")
					setShowAlertEmptyTags(true)
				} else {
					handlePrint(html)
				}
			})
		}

		// default options charged at init
		const optionEditor: UnlayerOptions = {
			locale: "fr-FR",
			projectId: 58458,
			safeHtml: false,
			smartMergeTags: false,
			// @ts-ignore nous customisons les merges tags, il y a une différence avec la définition de la bibliothèque
			mergeTags: cleanMergeTagsForEditor(preparedMergeTags),
			mergeTagsConfig: {
				sort: false,
			},
			translations: {
				"fr-FR": {
					"labels.merge_tags": "Ajouter une variable",
				},
			},
			tools: {
				"custom#Logo": {
					// @ts-ignore does not seem to be defined in the library
					properties: {
						logo:
							(laboratory?.subCompany?.logo ?? user?.company?.logo) ||
							"https://via.placeholder.com/500x100?text=IMAGE",
					},
				},
			},
		}

		// print the content of the hidden iframe
		const onIframeLoaded = (): void => {
			if (htmlToPrint) {
				const id = "printIframe"
				const iframe = document.getElementById(id) as any
				const iframeWindow = iframe.contentWindow || iframe
				iframe.focus()
				iframeWindow.print()
			}
		}

		return (
			<div>
				<div className="mb-3">
					{showSaveButton && (
						<>
							<button className="btn btn-outline-primary" onClick={saveTemplate} type="button">
								{isSaving && (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									/>
								)}
								{!isSaving && "Sauvegarder"}
							</button>
							<button className="btn btn-outline-primary" onClick={duplicateTemplate} type="button">
								{isDuplicate && (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									/>
								)}
								{!isDuplicate && "Dupliquer"}
							</button>
						</>
					)}
					<button className="btn btn-outline-info" onClick={exportPDF} type="button">
						{isExporting && (
							<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
						)}
						{!isExporting && "Export PDF"}
					</button>
					<button className="btn btn-outline-info" onClick={print} type="button">
						Imprimer
					</button>
					{actions &&
						actions.map((action: { label: string }, index: number) => {
							return (
								<button
									key={`action-${index}`}
									className="btn btn-outline-info"
									onClick={() => actionExport(index)}
									type="button">
									{isDoingAction === index && (
										<span
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										/>
									)}
									{isDoingAction !== index && action.label}
								</button>
							)
						})}
				</div>

				{/* @ts-ignore */}
				<EmailEditor
					ref={(node: any): void => {
						if (node && node != null) {
							if (typeof ref === "function") {
								// @ts-ignore
								editorRef = ref(node)
							} else if (ref) {
								;(ref as MutableRefObject<EmailEditor>).current = node
								editorRef.current = node
							}
						}
					}}
					onLoad={onLoad}
					onReady={onReady}
					options={optionEditor as UnlayerOptions}
					minHeight="800px"
				/>

				<iframe
					title="print"
					srcDoc={htmlToPrint}
					id="printIframe"
					width={"100%"}
					onLoad={onIframeLoaded}
					style={{ display: "none" }}
				/>

				<ModalEmptyTags
					emptyTags={showAlertEmptyTags}
					onConfirm={() => {
						if (actualAction === "print") handlePrint()
						if (actualAction === "exportPDF") handleExportPDF()
						if (actualAction === "action") handleAction()
					}}
					onClose={() => {
						setShowAlertEmptyTags(false)
						setIsExporting(false)
					}}
				/>
			</div>
		)
	}
)

UnlayerEditor.propTypes = {
	template: PropTypes.string,
	onSave: PropTypes.func,
	onDuplicate: PropTypes.func,
	mergeTags: PropTypes.object,
	labelTemplate: PropTypes.string,
	onChange: PropTypes.func,
	forceUpdate: PropTypes.number,
	actions: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string, function: PropTypes.func, export: PropTypes.bool })
	),
	store: PropTypes.object,
	showSaveButton: PropTypes.bool,
	resetChange: PropTypes.func,
	css: PropTypes.string,
}

export default UnlayerEditor
