import { Override } from "@audiowizard/common"
import { AutoComplete } from "antd"
import { CSSProperties, forwardRef, useCallback, useEffect, useRef, useState } from "react"
import * as React from "react"
import API from "services/API"

export type CompleteAdress = {
	adress: string
	cpo: string
	city: string
}

type AdressInputProps = Override<
	React.ComponentPropsWithoutRef<typeof AutoComplete>,
	{
		id?: string
		name?: string
		label?: string
		placeholder?: string
		error?: string
		helpText?: string

		groupClassName?: string
		labelClassName?: string
		inputClassName?: string

		/** Ne doit pas changer entre render, sinon cause la perte de focus de l'input. */
		groupStyle?: CSSProperties
		/** Ne doit pas changer entre render, sinon cause la perte de focus de l'input. */
		labelStyle?: CSSProperties
		/** Ne doit pas changer entre render, sinon cause la perte de focus de l'input. */
		inputStyle?: CSSProperties

		value: CompleteAdress
		onChange: (value: CompleteAdress) => unknown
	}
>

/**
 * Input adress complète, avec auto-complétion API.
 * Ce composant renvoi l'adresse, le code postal et la ville.
 */
export default function AdressInput({
	id,
	name,
	label = "Adresse complète",
	placeholder = "Indiquer l'adresse complète",
	error,
	helpText,
	groupClassName = "",
	labelClassName = "",
	inputClassName = "",
	groupStyle,
	labelStyle,
	inputStyle,
	value,
	onChange,
	...props
}: AdressInputProps): JSX.Element {
	const [adresses, setAdresses] = useState<{ value: string; city: string; cpo: string; adress: string }[]>([])
	const [autoCompleteValue, setAutoCompleteValue] = useState("")
	const timeout: any = useRef(null)

	useEffect(() => {
		setAutoCompleteValue(
			[value.adress, value.cpo, value.city]
				.filter((val) => val?.length > 0) // Enlève les valeurs undefined, null ou string vide
				.join(", ")
		)
	}, [value.adress, value.city, value.cpo])

	const handleAutoComplete = async (search: string): Promise<void> => {
		// If we make an API call with less than 3 characters the api call return an error
		if (search.length < 3) return
		clearTimeout(timeout.current)

		timeout.current = setTimeout(async () => {
			const fetchAddress = await API.getAddress(search)
			setAdresses(
				fetchAddress?.map((a: any) => ({
					value: a.properties.label,
					city: a.properties.city,
					cpo: a.properties.postcode,
					adress: a.properties.name,
				}))
			)
		}, 350)
	}

	const handleSelectCompleteAdress = (option: any): void => {
		setAutoCompleteValue([option.adress, option.cpo, option.city].join(", "))

		onChange({
			adress: option.adress,
			cpo: option.cpo,
			city: option.city,
		})
	}

	const inputId = id ?? name

	const InputComponent = useCallback(
		forwardRef(function InputComponent(props: any, ref: any) {
			return (
				<input
					{...props}
					ref={ref}
					className={`${props.className} form-control ${inputClassName}`}
					style={inputStyle}
					name={name}
					placeholder={placeholder}
				/>
			)
		}),
		[inputClassName, inputStyle, name, placeholder]
	)

	return (
		<div className={`form-group ${groupClassName}`} style={groupStyle}>
			<label htmlFor={inputId} className={labelClassName} style={labelStyle}>
				{label}
			</label>

			<AutoComplete<any>
				className="w-100"
				id={inputId}
				options={adresses as any}
				value={autoCompleteValue}
				onChange={setAutoCompleteValue}
				onSearch={handleAutoComplete}
				onSelect={(value: any, option: any) => handleSelectCompleteAdress(option)}
				notFoundContent={
					autoCompleteValue.length >= 3 && "Adresse non trouvée, veuillez renseigner les champs ci-dessous."
				}
				aria-describedby={helpText != null ? `${inputId}-help-text` : undefined} // pour helpText
				{...props}>
				<InputComponent />
			</AutoComplete>

			{error && (
				<p
					className="invalid-feedback d-block" // display block pour forcer l'élément à s'afficher, sinon il est masqué car l'input n'est pas à côté dans le DOM
				>
					{error}
				</p>
			)}

			{helpText && (
				<small id={`${inputId}-help-text`} className="form-text text-muted">
					{helpText}
				</small>
			)}
		</div>
	)
}
