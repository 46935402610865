export const BonLivraisonRoutePrefix = "/bon-de-livraison"

export const BonLivraisonRoutes = {
	List: `${BonLivraisonRoutePrefix}`,
	NewEdit: `${BonLivraisonRoutePrefix}/:id`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(BonLivraisonRoutePrefix, "")
}
