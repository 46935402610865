import { useQuery } from "react-query"
import {
	UserInsuranceCoverageDemandService,
	userInsuranceCoverageDemandServiceKey,
} from "../userInsuranceCoverageDemandService"

export default function useUserInsuranceCoverageDemands({ params }: { params?: Record<string, any> }) {
	return useQuery({
		queryFn: () =>
			UserInsuranceCoverageDemandService.list({
				params,
			}),
		queryKey: [userInsuranceCoverageDemandServiceKey, params],
	})
}
