/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartArea from "./ChartArea"

const Attentes = () => {
	const [totalPatient, setTotalPatient] = useState([])
	const [loading, setLoading] = useState(true)

	const getTotalPatient = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/patient")
			if (!result?.data?.message?.length) return setLoading(false)
			const Data = []
			//const DataAppareille = []
			result?.data?.message.forEach((e) => {
				Data.push({
					x: e.e_date,
					y: e.cumulative_patient,
				})
			})
			// result_appareille.data.message.forEach((e) => {
			// 	DataAppareille.push({
			// 		x: e.e_date,
			// 		y: e.cumulative_patient,
			// 	})
			// })
			const d = [
				{
					name: "Nombre de patient",
					data: Data,
				},
				//{
				//name: "Appareillé",
				//data: DataAppareille,
				//},
			]
			setTotalPatient(d)
			setLoading(false)
		} catch (e) {
			console.error(e)
			setTotalPatient([])
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getTotalPatient()
	}, [])

	return (
		<>
			<h5 className="mb-4">Nombre de patients</h5>
			<ChartArea
				series={totalPatient}
				labels={[
					"Prix",
					"Robustesse",
					"Discrétion",
					"Acouphène",
					"Connectivité",
					"Manipulable",
					"Rechargeable",
				]}
				colors={["#78e08f", "#38ada9", "#079992"]}
			/>
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
