/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react"
import ModaleRechercheProduit from "../ModalSearchProduct/ModalSearchProduct"
import useCustomTitle from "components/Hooks/useTitle"
import useEffectAsync from "components/Hooks/useEffectAsync"
import API from "services/API"
import Details from "./Details"
import { Select, Table } from "antd"
import { useHistory, useRouteMatch } from "react-router-dom"
import dayjs from "dayjs"
import "../style.scss"
import { DeleteTwoTone } from "@ant-design/icons"
import uuid from "react-uuid"
import { HiboutikProductEntity, LogisticProduct, ProductReturnForm } from "@audiowizard/common"
import "./style.scss"
import AuthContext from "contexts/AuthContext"
import { toast } from "react-toastify"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"

const { Option } = Select

type LogisticProductCustom = {
	action?: string
} & LogisticProduct

const ReturnForm = (): JSX.Element => {
	const { laboratory } = useContext(AuthContext)

	const history = useHistory()

	const isEditing = useRouteMatch("/mon-stock/retour/:id")
	const [products, setProducts] = useState<any[]>([])
	const [needRefresh, setNeedRefresh] = useState<boolean>(true)
	const [busy, setBusy] = useState<boolean>(false)
	const [isLoading, setLoading] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [modalReturnData, setModalReturnData] = useState<any>([])

	const [returnFormData, setReturnFormData] = useState<ProductReturnForm>({
		logisticProducts: [],
		label: undefined,
		notes: undefined,
		id: undefined,
		"@id": "",
		"@type": "ProductReturnForm",
	})

	useCustomTitle(isEditing ? "Modifier un retour" : "Nouveau Retour")

	const columns = [
		{
			title: "Fournisseur",
			dataIndex: "productId",
			render: (d: number) => {
				const label = products.find((f) => f.id === d)?.supplierName ?? `Inconnu (${d})`
				return label
			},
		},
		{
			title: "Produit",
			dataIndex: "productId",
			render: (id: number, lp: LogisticProduct) => {
				const label = products.find((f) => +f.id === +id)?.model ?? `Inconnu (${id})`

				const sizeName =
					products.find((f) => +f.id === +id)?.sizeDetails?.find((f: any) => +f.sizeId === +lp.productSizeId!)
						?.sizeName ?? `Inconnu (${id})`

				return (
					<>
						<div
							style={{
								fontWeight: "bold",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
								width: "255px",
							}}>
							{label}
						</div>
						<div style={{ opacity: "0.75" }}>{lp?.productSizeId ? sizeName : "Pas de déclinaison"}</div>
					</>
				)
			},
			width: "255px",
		},
		{
			title: "Numéro de série",
			dataIndex: "productSerialNumber",
			render: (sn: string) => <span style={{ fontFamily: "monospace", fontWeight: "bold" }}>{sn}</span>,
		},
		{
			title: "Statut",
			render: (_void: void, row: LogisticProduct) => {
				const childrens: JSX.Element[] = []
				if (row.isDepot) {
					childrens.push(<div>En Dépôt</div>)
				} else {
					childrens.push(<div>En ferme</div>)
				}
				if (row?.depotDateEnd ? dayjs(row?.depotDateEnd).isBefore(dayjs()) : false) {
					childrens.push(<div>Date de dépôt dépassé</div>)
				}
				if (row.isReturned) {
					childrens.push(<div>Renvoyé</div>)
				}
				if (row.isCreditRequested && !row.isCreditReceived) {
					childrens.push(<div>En attente d'avoir</div>)
				}
				if (row.isCreditReceived) {
					childrens.push(<div>Avoir reçu</div>)
				}

				return childrens
			},
		},
		{
			title: "Action",
			width: "256px",
			render: (_void: void, row: LogisticProductCustom) => {
				if (row.isTransferred) {
					return <span className="font-weight-bold">Le produit est dans un transfert.</span>
				}

				const childrens: JSX.Element[] = [
					<Option value="NONE" key={uuid()}>
						Ne rien faire
					</Option>,
				]

				const pushOption = (value: string, label: string): void => {
					childrens.push(
						<Option value={value} key={uuid()}>
							{label}
						</Option>
					)
				}

				if (row.isCreditRequested && !row.isCreditReceived) {
					pushOption("isCreditReceived", "Avoir reçu")
				}
				if (!row.isReturned) {
					pushOption("isReturned", "Renvoyer")
					pushOption("isCreditRequested", "Renvoyer pour avoir")
				} else {
					pushOption("putBackStock", "Remettre en stock")
				}

				if (!row.isDepot && (row.depotDateEnd || row.depotDate)) {
					pushOption("isDepot", "Repasser en dépôt")
				}

				return (
					<Select
						disabled={row.isTransferred}
						className="w-100"
						value={row.action ?? "NONE"}
						labelInValue={false}
						onChange={(value) => {
							const id = returnFormData.logisticProducts?.findIndex((f) => f.id === row.id)
							if (id === -1) return
							const tmp = returnFormData.logisticProducts as LogisticProductCustom[]
							tmp![id!].action = value
							setReturnFormData((old) => ({ ...old, products: tmp }))
						}}>
						{childrens}
					</Select>
				)
			},
		},
		{
			title: "",
			dataIndex: "",
			width: "20px",
			render: (_: void, row: LogisticProduct) => {
				if (!row.inventoryInputDetailId || row.isReturned || row.isCreditRequested) return null
				return (
					<div
						className="cursor-pointer"
						onClick={async () => {
							try {
								setBusy(true)
								await API.update<LogisticProduct>("LOGISTIC_PRODUCTS_API", row.id!, {
									productReturnForm: null,
								})
								setNeedRefresh(true)
							} catch (error) {
								console.error(error)
							} finally {
								setBusy(false)
							}
						}}>
						<DeleteTwoTone className="text-lg" twoToneColor="#ee3333" />
					</div>
				)
			},
		},
	]

	useEffectAsync(async () => {
		try {
			setLoading(true)
			const result = await API.findAll<HiboutikProductEntity[]>("PRODUCTS_API", "?pagination=false")
			setProducts(result)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}, [])

	useEffectAsync(async () => {
		if ((!isEditing && !returnFormData?.id) || !needRefresh || !products?.length) return
		setNeedRefresh(false)

		//@ts-ignore
		const returnFormId = isEditing?.params?.id ?? returnFormData?.id

		try {
			setLoading(true)

			const result = await API.find<ProductReturnForm>("PRODUCT_RETURN_FORM_API", returnFormId)

			setReturnFormData({
				...result,
				logisticProducts: result.logisticProducts?.map((product: LogisticProductCustom) => {
					const productDetails = { ...(products.find((p) => +p.id === +product.productId!) ?? {}) }
					delete productDetails["@id"]
					delete productDetails.id

					return { ...product, key: uuid(), ...productDetails }
				}),
			})

			setLoading(false)
		} catch (error) {
			console.error(error)
		} finally {
		}
	}, [products, isEditing, needRefresh, returnFormData?.id])

	useEffectAsync(async () => {
		setBusy(true)
		try {
			let returnFormIri = returnFormData?.["@id"] || null

			if (!returnFormData.id && modalReturnData.length > 0) {
				const res = await API.create<ProductReturnForm>("PRODUCT_RETURN_FORM_API", {
					label: "",
				})
				setReturnFormData((old: ProductReturnForm) => ({
					...old,
					id: res.data.id,
					"@id": res.data["@id"],
					logisticProducts: [],
				}))
				returnFormIri = res.data["@id"]
				window.history.pushState("", "", "/mon-stock/retour/" + res.data.id)
			}
			for (const product of modalReturnData) {
				if (product?.logisticProduct?.["@id"]) {
					await API.updateWithIri(product?.logisticProduct?.["@id"], {
						productReturnForm: returnFormIri,
					})
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setBusy(false)
			setNeedRefresh(true)
		}
	}, [modalReturnData])

	const differentSupplier = (returnFormData.logisticProducts ?? [])?.every((currentProduct: LogisticProduct) => {
		const currentSupplier = products.find((p) => +p.id === +currentProduct.productId!)?.supplierId

		const currentSupplierCount = returnFormData.logisticProducts!.filter((product: LogisticProduct) => {
			const supplier = products.find((p) => +p.id === +product.productId!)?.supplierId
			return supplier === currentSupplier
		})

		return currentSupplierCount.length !== returnFormData.logisticProducts?.length
	})

	const ValidateReturnForm = async (): Promise<void> => {
		if (!returnFormData.label) {
			toast.error("Veuillez renseigner un nom pour le retour")
			return
		}
		setBusy(true)
		const products = returnFormData.logisticProducts?.filter((f: LogisticProductCustom) => f.action) ?? []
		let hasError = false
		for (const product of products as LogisticProductCustom[]) {
			const productUpdateSupl: Record<string, any> = {}

			productUpdateSupl[product.action!] = true

			if (product.action === "putBackStock") {
				productUpdateSupl.inventoryInputDetailId = 0
				productUpdateSupl.isReturned = false
			}

			try {
				await API.update("LOGISTIC_PRODUCTS_API", product.id!, { ...productUpdateSupl })
			} catch (error) {
				console.error(error)
				toast.error(`Impossible d'appliquer les changements sur le produit (${product?.productSerialNumber})`)
				hasError = true
			}
		}
		if (!hasError) {
			history.push("/mon-stock/retours")
		}
		setBusy(false)
		setNeedRefresh(true)
	}

	return (
		<>
			<SectionHeader title="Information du retour et enregistrement" />
			<Details busy={busy} formData={returnFormData} setFormData={setReturnFormData} />
			<SectionHeader title="Liste des produits" />
			<div className="row mb-3">
				<button
					disabled={busy}
					type="button"
					className="btn btn-outline-primary col"
					onClick={() => setShowModal(true)}>
					Ajouter un ou des produit(s) à renvoyer
				</button>
				<div className="col-1" />
				<button
					type="button"
					className="btn btn-warning col"
					disabled={
						busy ||
						!returnFormData?.logisticProducts?.some(
							(f: LogisticProductCustom) => f.action && f.action !== "NONE"
						)
					}
					onClick={() => {
						const tmp: any[] =
							returnFormData?.logisticProducts?.map((o) => ({ ...o, action: "NONE" })) || []
						setReturnFormData((old) => ({ ...old, logisticProducts: tmp }))
					}}>
					Remettre à zéro les actions
				</button>
				<button
					type="button"
					className="btn btn-primary col"
					onClick={ValidateReturnForm}
					disabled={
						busy ||
						!returnFormData?.logisticProducts?.some(
							(f: LogisticProductCustom) => f.action && f.action !== "NONE"
						)
					}>
					Valider le retour
				</button>
			</div>
			{!!differentSupplier && returnFormData?.logisticProducts && returnFormData.logisticProducts?.length > 0 && (
				<div className="alert alert-danger">
					<strong>Attention</strong>
					<br />
					Un ou des produits dans ce bon de livraison n'appartiennent pas au même fournisseur
				</div>
			)}
			<Table
				loading={busy || isLoading}
				dataSource={returnFormData.logisticProducts || []}
				columns={columns as any}
				rowClassName={(row) => {
					if (row.isTransferred) {
						return "is-transferred"
					}
					return ""
				}}
			/>
			<ModaleRechercheProduit
				//@ts-ignore
				defaultSupplier={!differentSupplier ? returnFormData.logisticProducts?.[0]?.supplierId : null}
				laboratory={laboratory}
				visible={showModal}
				setVisible={setShowModal}
				setSelectedProducts={setModalReturnData}
			/>
		</>
	)
}

export default ReturnForm
