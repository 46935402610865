/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import { Checkbox, Select, Space, Switch } from "antd"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import AuthContext from "contexts/AuthContext"
import _ from "lodash"
import { getColor, hexToDec } from "pages/Schedules/getScheduleType"
import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, Row } from "reactstrap"
import { colorFromStr, colorToHex, partialSearch, SortAlphabetically } from "services/functions"
import API from "../../../services/API"
import "./ScheduleTypes.scss"

const { Option } = Select

const actions = [
	{ label: "Aucune action", key: "AUCUNE" },
	{ label: "Première visite", key: "PREMIERE_VISITE" },
	{ label: "Suite visite", key: "SUITE_VISITE" },
	{ label: "Adaptation", key: "ADAPTATION" },
	{ label: "Essai", key: "ESSAI" },
	{ label: "Devis", key: "VISITE" },
	// This one is volontary, to keep compatibility with possible database inconsistencies.
	// It will not show up in the modal select
	{ label: "Facturation", key: "FACTURATION" },
	{ label: "Contrôle", key: "CONTROLE" },
	{ label: "Contrôle", key: "APPAREILLE" },
	{ label: "Notes", key: "NOTES" },
]

const NewSettingModal = ({ selectedSetting, setSelectedSetting, isOpen, setOpen }) => {
	const queryClient = useQueryClient()
	const inputBackgroundColorPicker = useRef()
	const { hasDoctolib } = useContext(AuthContext)

	const defaultValue = {
		backgroundColor: "#18bc9c",
		duration: 30,
		action: "AUCUNE",
		label: "",
		scheduleInterval: {
			class1: null,
			class2: null,
			class1Cmu: null,
			class2Cmu: null,
		},
		isSynchroDoctolib: false,
	}
	const [setting, setSetting] = useState(defaultValue)

	useEffect(() => {
		if (!isOpen) return
		setSetting(defaultValue)
		if (!selectedSetting?.["@id"]) return
		const backgroundColor =
			selectedSetting?.backgroundColor >= 0
				? colorToHex(selectedSetting.backgroundColor)
				: setting.backgroundColor

		setSetting((old) => ({ ...old, ...selectedSetting, backgroundColor }))
	}, [selectedSetting, isOpen])

	const handleChange = (evt) => {
		const key = evt.target.name
		let value = evt.target.value

		if (key === "duration") {
			value = value.split(":").reduce((acc, time) => 60 * acc + +time)
		}

		setSetting((old) => ({ ...old, [key]: value }))
	}

	const handleChangeIsSynchroDoctolib = (value) => {
		setSetting((old) => ({ ...old, isSynchroDoctolib: value }))
	}

	const { mutate: handleSettingAPI, isLoading } = useMutation(
		async () => {
			setting.backgroundColor = parseInt(setting.backgroundColor.toString().substring(1), 16)

			if (setting?.["@id"]) {
				await API.updateWithIri(setting?.["@id"], { ...setting, patientTags: undefined })
			} else {
				await API.create("SCHEDULE_TYPES", setting)
			}
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(["SCHEDULE_TYPES"])
				setOpen(false)
				setSelectedSetting({})
				setSetting(defaultValue)
			},
			onError: (e) => {
				if (e.response.data?.["hydra:description"]) {
					toast.error(e.response.data?.["hydra:description"])
				} else {
					toast.error("Impossible d'enregistrer les modifications")
				}
			},
		}
	)

	const convertDurationToHtmlTime = () => {
		const hours = parseInt(setting.duration / 60)
		const minutes = parseInt(setting.duration % 60)

		const result = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
		return result
	}

	const handleScheduleChange = (event) => {
		const { value, name } = event.target

		setSetting({
			...setting,
			scheduleInterval: {
				...setting.scheduleInterval,
				[name]: +value,
			},
		})
	}

	const toggleScheduleInterval = (event) => {
		const { checked, name } = event.target

		setSetting({
			...setting,
			scheduleInterval: {
				...setting.scheduleInterval,
				[name]: checked ? 0 : null,
			},
		})
	}

	return (
		<Modal isOpen={isOpen} centered size="md" backdrop="static">
			<ModalBody>
				<label>Nom</label>
				<Input
					placeholder="Nom du rendez-vous"
					className="mb-2"
					name="label"
					value={setting.label}
					onChange={handleChange}
				/>
				<label>Action</label>
				<Input type="select" className="mb-2" value={setting.action} name="action" onChange={handleChange}>
					{actions
						.filter((k) => k.key !== "CONTROLE")
						.map((v, k) => (
							<option key={k} value={v.key}>
								{v.label}
							</option>
						))}
				</Input>
				<label>Durée (en heure:minutes)</label>
				<Input
					type="time"
					name="duration"
					onChange={handleChange}
					placeholder="Durée (minutes)"
					className="mb-2 p-2"
					min={"00:00"}
					max={"07:00"}
					value={convertDurationToHtmlTime()}
				/>
				<div
					className="type-preview mb-2"
					style={{ backgroundColor: setting.backgroundColor }}
					onClick={() => {
						try {
							inputBackgroundColorPicker.current.click()
						} catch (e) {
							console.error(e)
						}
					}}>
					<span className="name">John Doe</span>
					<span className="timerange">10:00 - 12:00</span>
				</div>
				{hasDoctolib && (
					<div>
						<Switch
							checked={setting.isSynchroDoctolib}
							onChange={handleChangeIsSynchroDoctolib}
							checkedChildren=""
							unCheckedChildren=""
						/>
						<label className="pl-1">Synchronisation Doctolib</label>
					</div>
				)}

				<Input
					innerRef={inputBackgroundColorPicker}
					type="color"
					className="hidden-color-picker"
					value={setting.backgroundColor}
					name="backgroundColor"
					onChange={handleChange}
				/>
				{
					<Row>
						<Col md="12">
							<label>Délai avant le prochain rendez-vous (en mois)</label>
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<Checkbox
											name="class1"
											onChange={toggleScheduleInterval}
											checked={setting.scheduleInterval.class1 != null}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									min={0}
									name="class1"
									type="number"
									value={setting.scheduleInterval.class1 ?? ""}
									onChange={handleScheduleChange}
									disabled={setting.scheduleInterval.class1 == null}
								/>
								<InputGroupAddon addonType="append">Classe 1</InputGroupAddon>
							</InputGroup>
						</Col>
						<Col md="12">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<Checkbox
											name="class2"
											onChange={toggleScheduleInterval}
											checked={setting.scheduleInterval.class2 != null}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									min="0"
									name="class2"
									type="number"
									value={setting.scheduleInterval.class2 ?? ""}
									onChange={handleScheduleChange}
									disabled={setting.scheduleInterval.class2 == null}
								/>
								<InputGroupAddon addonType="append">Classe 2</InputGroupAddon>
							</InputGroup>
						</Col>
						<Col md="12">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<Checkbox
											name="class1Cmu"
											onChange={toggleScheduleInterval}
											checked={setting.scheduleInterval.class1Cmu != null}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									min="0"
									name="class1Cmu"
									type="number"
									value={setting.scheduleInterval.class1Cmu ?? ""}
									onChange={handleScheduleChange}
									disabled={setting.scheduleInterval.class1Cmu == null}
								/>
								<InputGroupAddon addonType="append">Classe 1 et CMU</InputGroupAddon>
							</InputGroup>
						</Col>
						<Col md="12">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<Checkbox
											name="class2Cmu"
											onChange={toggleScheduleInterval}
											checked={setting.scheduleInterval.class2Cmu != null}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									min="0"
									name="class2Cmu"
									type="number"
									value={setting.scheduleInterval.class2Cmu ?? ""}
									onChange={handleScheduleChange}
									disabled={setting.scheduleInterval.class2Cmu == null}
								/>
								<InputGroupAddon addonType="append">Classe 2 et CMU</InputGroupAddon>
							</InputGroup>
						</Col>
					</Row>
				}
			</ModalBody>
			<ModalFooter>
				<ButtonRounded
					color="danger-outlined"
					disabled={isLoading}
					onClick={() => {
						setOpen(false)
						setSelectedSetting({})
						setSetting(defaultValue)
					}}>
					Annuler
				</ButtonRounded>
				<ButtonRounded
					color="primary-outlined"
					isDisabled={isLoading || setting?.label?.length < 1}
					onClick={() => {
						handleSettingAPI()
					}}>
					{selectedSetting?.["@id"] ? "Modifier" : "Ajouter"}
				</ButtonRounded>
			</ModalFooter>
		</Modal>
	)
}

const RelanceModal = ({ scheduleTypes, isOpen, closeModal, handleSettingAPI }) => {
	const [selectedScheduleTypes, setSelectedScheduleTypes] = useState([])
	const [currentRelanceRdv, setCurrentRelanceRdv] = useState([])
	const [removedRelance, setRemovedRelance] = useState([])
	const [newRelance, setNewRelance] = useState([])

	useEffect(() => {
		if (scheduleTypes == null) return

		const relance = scheduleTypes.filter((value) => value.isRelance).map((value) => value["@id"])
		setSelectedScheduleTypes(relance)
		setCurrentRelanceRdv(relance)
	}, [scheduleTypes])

	useEffect(() => {
		setRemovedRelance(_.difference(currentRelanceRdv, selectedScheduleTypes))
		setNewRelance(_.difference(selectedScheduleTypes, currentRelanceRdv))
	}, [selectedScheduleTypes])

	return (
		<Modal isOpen={isOpen} centered backdrop="static">
			<ModalBody>
				<label>Sélectionnez les rendez-vous de Relance</label>
				<Select
					showSearch
					mode="multiple"
					placeholder="Statut principal"
					filterOption={(search, option) => partialSearch(option["data-label"], search)}
					onChange={setSelectedScheduleTypes}
					style={{ width: "100%", marginTop: "4px" }}
					size="large"
					value={selectedScheduleTypes}>
					{(scheduleTypes || []).map((v, k) => (
						<Option
							data-label={v.label} // must with "data-" to avoid conflits with html attributes
							value={v["@id"]}
							key={k}
							className="option-content">
							<div className="settings-status-card-flex schedule-line-option">
								<div
									className="option-color"
									style={{ backgroundColor: getColor(v.backgroundColor, v.scheduleStatus) }}
								/>
								<div className="option-label">{v.label}</div>
							</div>
						</Option>
					))}
				</Select>
			</ModalBody>
			<ModalFooter>
				<ButtonRounded
					color="danger-outlined"
					onClick={() => {
						closeModal()
					}}>
					Annuler
				</ButtonRounded>
				<ButtonRounded
					color="primary"
					disabled={newRelance.length === 0 && removedRelance.length === 0}
					onClick={() => {
						handleSettingAPI({ newRelance: newRelance, removedRelance: removedRelance })
						closeModal()
					}}>
					Valider
				</ButtonRounded>
			</ModalFooter>
		</Modal>
	)
}

const ScheduleTypeSetting = () => {
	const [displayNewModal, setDisplayNewModal] = useState(false)
	const [displayRelanceModal, setDisplayRelanceModal] = useState(false)
	const [selectedSetting, setSelectedSetting] = useState({})
	const [relance, setRelance] = useState([])
	const queryClient = useQueryClient()

	const { isLoading, data } = useQuery(["SCHEDULE_TYPES", "setting"], async () =>
		(await API.findAll("SCHEDULE_TYPES")).map((type) => ({
			...type,

			backgroundColor:
				type.backgroundColor === 1621148 ? hexToDec(colorFromStr(type.scheduleStatus)) : type.backgroundColor,
		}))
	)

	const { mutate: handleDelete } = useMutation(
		async (scheduleTypeIri) => {
			await API.delete(scheduleTypeIri)
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("SCHEDULE_TYPES")
			},
			onError: (e) => {
				if (e.response.data?.["hydra:description"]) {
					toast.error(e.response.data?.["hydra:description"])
				}
			},
		}
	)

	const { mutate: handleSynchroDoctolib } = useMutation(
		async ({ isSynchroDoctolib, scheduleType }) => {
			await API.updateWithIri(scheduleType?.["@id"], { isSynchroDoctolib })
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("SCHEDULE_TYPES")
			},
			onError: (e) => {
				if (e.response.data?.["hydra:description"]) {
					toast.error(e.response.data?.["hydra:description"])
				}
			},
		}
	)

	const { mutate: handleSettingAPI } = useMutation(
		async ({ newRelance, removedRelance }) => {
			await Promise.all(newRelance.map(async (iri) => API.updateWithIri(iri, { isRelance: true })))
			await Promise.all(removedRelance.map(async (iri) => API.updateWithIri(iri, { isRelance: false })))
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("SCHEDULE_TYPES")
			},
			onError: () => {
				toast.success("Échec de la mise à jour")
			},
		}
	)

	useEffect(() => {
		if (!data) return
		setRelance(data.filter((f) => f?.isRelance))
		SortAlphabetically(data, "label")
	}, [data])

	return (
		<Col>
			<SectionHeader title="Liste des types de rendez-vous">
				<ButtonRounded
					icon="fa-plus"
					type="button"
					color="primary-outlined"
					onClick={() => setDisplayNewModal(true)}>
					Ajouter un type
				</ButtonRounded>
			</SectionHeader>
			<Space direction="vertical">
				<NewSettingModal
					isOpen={displayNewModal}
					setOpen={setDisplayNewModal}
					selectedSetting={selectedSetting}
					setSelectedSetting={setSelectedSetting}
				/>
				<RelanceModal
					scheduleTypes={data}
					isOpen={displayRelanceModal}
					closeModal={() => setDisplayRelanceModal(false)}
					handleSettingAPI={handleSettingAPI}
				/>
				{isLoading ? "Chargement..." : ""}
				<div className="row p-settings mt-1">
					{(data ?? [])
						.filter((f) => !f[1]?.hidden)
						.map((v, k) => (
							<Fragment key={k}>
								<div
									onClick={(event) => {
										if (["svg", "path"].includes(event.target?.tagName?.toLowerCase())) {
											return
										}
										setSelectedSetting(v)
										setDisplayNewModal(true)
									}}
									className={"col schedules-type cursor-pointer "}
									style={{
										borderColor: colorToHex(v.backgroundColor, v.scheduleStatus),
										boxShadow: `2px 2px 5px 1px ${colorToHex(
											v.backgroundColor,
											v.scheduleStatus
										)}33`,
									}}>
									<div className="label">
										<span>{v.label}</span>
									</div>
									<div className="action">
										{actions.find((a) => a.key === v?.action)?.label ?? v?.action}
									</div>
									<div className="timerange">
										<span>
											{parseInt(v.duration / 60) ? (
												<>{String(parseInt(v.duration / 60))}h </>
											) : (
												""
											)}

											{parseInt(v.duration % 60) ? <>{String(parseInt(v.duration % 60))}m</> : ""}
										</span>
									</div>
									<div
										className="color cursor-pointer"
										style={{
											backgroundColor: colorToHex(v.backgroundColor, v.scheduleStatus),
										}}>
										<div className="color-picker-icon">
											<i className="fad fa-tint" />
										</div>
										<input type="color" id={"color-picker-" + k} />
									</div>
									<div className="switch-synchro" onClick={(event) => event.stopPropagation()}>
										<Switch
											checked={v.isSynchroDoctolib}
											onChange={(isSynchroDoctolib) => {
												handleSynchroDoctolib({ isSynchroDoctolib, scheduleType: v })
											}}
											checkedChildren={
												<img
													alt="doctolib-logo"
													src="/static/img/doctolib/D_Doctolib_white.png"
													style={{ width: 18, height: 18, paddingTop: 4 }}
												/>
											}
											unCheckedChildren={<CloseOutlined />}
											size="small"
										/>
									</div>
									<div
										className="delete cursor-pointer"
										onClick={() => {
											handleDelete(v["@id"])
										}}>
										<DeleteOutlined />
									</div>
								</div>
							</Fragment>
						))}
				</div>
				<SectionHeader title="Liste des rendez-vous de relance">
					<ButtonRounded type="button" color="primary-outlined" onClick={() => setDisplayRelanceModal(true)}>
						Modifier
					</ButtonRounded>
				</SectionHeader>
				<Row className="p-settings mt-1">
					{relance.map((v) => (
						<Col
							className={"schedules-type"}
							key={v.label}
							style={{
								borderColor: colorToHex(v.backgroundColor, v.scheduleStatus),
								boxShadow: `2px 2px 5px 1px ${colorToHex(v.backgroundColor, v.scheduleStatus)}33`,
							}}>
							<div className="label">
								<span>{v.label}</span>
							</div>
							<div
								className="color"
								style={{ backgroundColor: colorToHex(v.backgroundColor, v.scheduleStatus) }}
							/>
						</Col>
					))}
				</Row>
			</Space>
		</Col>
	)
}

export default ScheduleTypeSetting
