import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const AbonnementRoutePrefix = GestionCompteRoutePrefix + "/abonnement"

export const AbonnementRoutes = {
	ChoixOffre: `${AbonnementRoutePrefix}/choix-offre`,
	Parrainage: `${AbonnementRoutePrefix}/parrainages`,
	// ServiceAnnexe: `${AbonnementRoutePrefix}/services-annexes`,
	// PosteReseauxSociaux: `${AbonnementRoutePrefix}/postes-reseaux-sociaux`,
	Facture: `${AbonnementRoutePrefix}/factures`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(AbonnementRoutePrefix, "")
}
