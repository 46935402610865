import { Select } from "antd"
import { useMemo } from "react"
import { useBrandsQuery } from "components/Hooks/commonQueries"
import { partialSearch } from "services/functions"

type AsyncHiboutikBrandsSelectProps = {
	allowClear?: boolean
	disabled?: boolean
	placeholder?: string
	searchValue?: string
	onChange?: () => void
	onClear?: () => void
	onDeselect?: () => void
	onSelect: (_: any, option: any) => void
}

export default function AsyncHiboutikBrandsSelect({
	allowClear = true,
	disabled,
	placeholder = "Sélectionner une marque",
	searchValue,
	onChange,
	onClear,
	onDeselect,
	onSelect,
}: AsyncHiboutikBrandsSelectProps): JSX.Element {
	const { data: brands, isFetching } = useBrandsQuery()

	const options = useMemo(() => {
		if (isFetching || brands == null) return []

		return brands.map((brand) => ({ detail: brand, value: brand.brand_id, label: brand.brand_name }))
	}, [brands])

	return (
		<Select
			allowClear={allowClear}
			disabled={disabled}
			options={options}
			placeholder={placeholder}
			searchValue={searchValue}
			style={{ width: "100%" }}
			showSearch
			optionFilterProp="children"
			filterOption={(input, option) => {
				if (input.length < 2) return false
				return partialSearch(option!.label as string, input)
			}}
			onChange={onChange}
			onClear={onClear}
			onDeselect={onDeselect}
			onSelect={onSelect}
		/>
	)
}
