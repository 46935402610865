/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons"
import { Switch } from "antd"
import { DateInputOnly } from "components/forms/DateInput"
import dayjs from "dayjs"
import { garanties as garantiesFSV } from "pages/FSV/garantiesFSV"
import { useFetchPaymentType } from "pages/Settings/paymentTypes/PaymentTypes.helpers"
import { useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from "reactstrap"
import AuthContext from "../../contexts/AuthContext"
import API_Hiboutik from "../../services/API_Hiboutik"
import { formatCurrency, formatDateForDB, frenchDate, rounded } from "../../services/functions"
import { findActiveAMx } from "../FSV/utilsFSV"
import InsuranceBadge from "./Component/InsuranceBadge"
import ModalTeletrans from "./Modal.TeletransPayment"
import { isRefundMethod, recalculateSMDP } from "./Payments"
import { calcTeletransAmount, refundPaymentMethods } from "./_Utility"
import { revertConfigToDatabaseCorrectKeys } from "services/scriptFactures"
import API from "services/API"
import { useGenerateDocument } from "features/sales/hooks/useGenerateDocument/useGenerateDocument"
import { updateDocumentPDF } from "services/requests/http/document/updateDocumentPDF"
import { useGetIsPregnant } from "./teletransPaymentUtils"
import { particularSituationsSentences } from "pages/etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"

const PaymentList = ({ data, handleChangeStatus, handleDeleteDiv, busy, updateApiPayments, laboratory }) => {
	const { paymentTypesRef } = useFetchPaymentType(laboratory.subCompany)

	return (
		<>
			{data.map((v, k) => (
				<div
					key={k}
					className={
						"form-row" +
						(v?.update ? " flag-update" : "") +
						(v?.delete ? " flag-delete" : "") +
						(!v?.payment_detail_id ? " flag-new" : "")
					}>
					<div className="col-2">
						{v.date !== "0000-00-00" && (
							<input
								className="form-control form-control-sm"
								type="text"
								disabled={true}
								value={frenchDate(v.date)}
							/>
						)}
					</div>
					<div className="col-4">
						<input
							className="form-control form-control-sm"
							type="text"
							disabled={true}
							value={
								(paymentTypesRef?.[v.type]?.label || "Inconnu") +
								(v?.comments ? ` (${v?.comments})` : "")
							}
						/>
					</div>
					<div className="col-3">
						<input
							className={"form-control form-control-sm" + (v?.amount < 0 ? " border border-warning" : "")}
							value={v?.amount ? formatCurrency(rounded(v?.amount)) : ""}
							placeholder="Montant"
							disabled={true}
						/>
					</div>
					<div className="col-2">
						<Switch
							checkedChildren="Acquitté"
							checked={v?.done}
							disabled={
								updateApiPayments ||
								v.lock ||
								(v?.payment_detail_id && v?.payment_paid !== "0000-00-00") ||
								["MTL", "SECU", "MUTL", "CMU"].includes(v.type)
							}
							unCheckedChildren="En attente"
							onClick={() => {
								handleChangeStatus(v, k)
							}}
						/>
					</div>
					<div className="col-1">
						{!v.lock && (
							<button
								type="button"
								disabled={v.lock || busy}
								className="remove"
								onClick={() => handleDeleteDiv(v, k)}>
								<i className="fad fa-minus-square" />
							</button>
						)}
					</div>
				</div>
			))}
		</>
	)
}

const ModalDivPayment = ({
	busy,
	setBusy,
	error,
	setError,
	sale,
	setSale,
	modal,
	setModal,
	selectedPatient,
	selectedProducts,
	teletransData,
	setTeletransData,
	updateApiPayments,
	setHasSaved,
	fromAfterSale = false,
	publicAmc,
	domTomPrices,
	patientAmc,
	hasBothEars,
	laboratory,
	closedSaleDocument,
	setNeedRefresh,
}) => {
	const ctx = useContext(AuthContext)
	const [modalTeletrans, setModalTeletrans] = useState(false)
	const [paymentsList, setPaymentsList] = useState([])
	const [newData, setNewData] = useState({
		type: "CB",
		done: false,
		comments: "",
		payment_paid: "0000-00-00",
	})
	const [totalPayments, setTotalPayments] = useState(0.0)
	const [patientAmo, setPatientAmo] = useState()
	const [, setPatientAmc] = useState()
	const [patientPrescription, setPatientPrescription] = useState()
	const [adviceFlag, setAdviceFlag] = useState(false)
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [isBlind, setIsBlind] = useState(false)

	const generateDocument = useGenerateDocument()
	const history = useHistory()
	const toggle = () => setTooltipOpen(!tooltipOpen)

	const { paymentTypes } = useFetchPaymentType(laboratory?.subCompany)
	const isPregnant = useGetIsPregnant(selectedPatient)

	useEffect(() => {
		if (!teletransData || !paymentTypes || !paymentTypes.length) return
		;(async function () {
			const { totalAmc, totalAmo } = calcTeletransAmount(teletransData)
			let tmpPaymentsList = [...paymentsList]
			const MUTL = paymentTypes?.find((f) => f.key === "MUTL") ? "MUTL" : "MTL"

			const idSecu = tmpPaymentsList.findIndex((f) => f.type === "SECU" && f?.payment_detail_id >= 1)
			const idMutl = tmpPaymentsList.findIndex((f) => f.type === MUTL && f?.payment_detail_id >= 1)

			if (!teletransData?.mutlTierPayant && idMutl !== -1) {
				try {
					await API_Hiboutik.saleDeleteDiv(tmpPaymentsList[idMutl]?.payment_detail_id)
					tmpPaymentsList.splice(idMutl, 1)
				} catch (error) {
					console.error(error)
				}
			}

			if (!teletransData?.secuTierPayant && idSecu !== -1) {
				try {
					await API_Hiboutik.saleDeleteDiv(tmpPaymentsList[idSecu]?.payment_detail_id)
					tmpPaymentsList.splice(idSecu, 1)
				} catch (error) {
					console.error(error)
				}
			}
			if (idSecu !== -1 || idMutl !== -1) {
				setPaymentsList(tmpPaymentsList)
			}

			if (!teletransData.actes || (!teletransData.mutl && !teletransData.secu)) {
				return
			}
			let newPayments = []

			const dueDate = dayjs().add("1", "month").format("YYYY-MM-DD")

			if (idSecu !== -1) {
				await API_Hiboutik.saleDeleteDiv(tmpPaymentsList[idSecu]?.payment_detail_id)
				await API_Hiboutik.saleAddDivPayment(sale?.sale_id, "SECU", totalAmo, dueDate)
				tmpPaymentsList[idSecu].amount = totalAmo
			} else if (teletransData.secu && teletransData.secuTierPayant) {
				try {
					const res = await API_Hiboutik.saleAddDivPayment(sale?.sale_id, "SECU", totalAmo, dueDate)
					newPayments.push({
						type: "SECU",
						tmp_div: false,
						done: false,
						date: dueDate,
						comments: "Télétransmission",
						amount: totalAmo,
						payment_detail_id: res.data.payment_detail_id,
					})
				} catch (error) {
					console.error(error)
				}
			}

			if (idMutl !== -1) {
				await API_Hiboutik.saleDeleteDiv(tmpPaymentsList[idMutl]?.payment_detail_id)
				await API_Hiboutik.saleAddDivPayment(sale?.sale_id, MUTL, totalAmc, dueDate)
				tmpPaymentsList[idMutl].amount = totalAmc
			} else if (!publicAmc && teletransData.mutl && teletransData.mutlTierPayant) {
				try {
					const res = await API_Hiboutik.saleAddDivPayment(sale?.sale_id, MUTL, totalAmc, dueDate)
					newPayments.push({
						type: MUTL,
						tmp_div: false,
						done: false,
						date: dueDate,
						comments: "Télétransmission",
						amount: totalAmc,
						payment_detail_id: res.data.payment_detail_id,
					})
				} catch (error) {
					console.error(error)
				}
			} else if (publicAmc?.handled && teletransData.mutl && teletransData.mutlTierPayant && totalAmc) {
				if (paymentsList.find((f) => f.type === "CMU")) return
				const res = await API_Hiboutik.saleAddDivPayment(sale?.sale_id, "CMU", totalAmc, dueDate)
				newPayments.push({
					type: "CMU",
					tmp_div: false,
					done: false,
					date: dueDate,
					comments: "Télétransmission",
					amount: totalAmc,
					payment_detail_id: res.data.payment_detail_id,
				})
			} else if (!publicAmc?.handled && teletransData.mutl && teletransData.mutlTierPayant && totalAmc) {
				if (paymentsList.find((f) => f.type === "GU")) return
				const res = await API_Hiboutik.saleAddDivPayment(sale?.sale_id, MUTL, totalAmc, dueDate)
				newPayments.push({
					type: "GU",
					tmp_div: false,
					done: false,
					date: dueDate,
					comments: "Sans télétransmission",
					amount: totalAmc,
					payment_detail_id: res.data.payment_detail_id,
				})
			}
			tmpPaymentsList = [...tmpPaymentsList, ...newPayments]
			setPaymentsList(tmpPaymentsList)
			setSale((o) => ({ ...o, payment_details: [...tmpPaymentsList] }))
		})()
	}, [teletransData, paymentTypes])

	const initFromSaleData = async () => {
		let payments = []
		if (modal && sale?.payment_type === "DIV") {
			try {
				payments = [...sale.payment_details?.filter((e) => !["DIV"].includes(e?.type))]
				payments = payments.map((f, k) => {
					delete f.delete
					f.key = k
					return f
				})
				setPaymentsList(payments)
				setNewData({
					type: "CB",
					done: false,
				})
			} catch (error) {
				console.error(error)
			}
		}

		// If audioprothestics aren't class 1, we can't use cmu calculation
		// const isSaleForClass2Products = !!selectedProducts.filter((p) => +p.class === 2).length

		const activeAMx = await findActiveAMx(selectedPatient, true)
		setPatientAmo(activeAMx.amo)
		setPatientAmc(activeAMx.amc)
		if (selectedPatient?.patientMedicals.some((med) => med.label === "CECITE")) setIsBlind(true)

		setPatientPrescription(selectedPatient?.advices?.[0])
		return payments
	}

	useEffect(() => {
		if (modal) initFromSaleData()
	}, [modal, sale?.payment_details, sale?.amount_left])

	useEffect(() => {
		let total = 0.0

		for (const div of paymentsList) {
			if (div?.tmp_div) total += rounded(div.amount || 0)
		}

		setTotalPayments(total)
	}, [paymentsList])

	const divErrorHandler = (error) => {
		const details = error?.response?.data?.details

		for (const err in details) {
			if (details[err].includes("You can only add payments to a DIV sale")) {
				setError((o) => ({
					...o,
					message: "Impossible d'ajouter  ojwnmg,mf.,.v.",
					codes: ["SALE_NOT_DIV"],
				}))
			}
			if (details[err].includes("Please provide a valid payment")) {
				setError((o) => ({
					...o,
					message: "",
					codes: ["DIV_TYPE_INVALID"],
				}))
			}
		}
	}

	const updateNewPayment = (data) => {
		if (["MTL", "SECU", "MUTL", "CMU"].includes(data?.type)) {
			data.date = formatDateForDB(dayjs().add(1, "y"))
			data.done = false
			data.payment_paid = "0000-00-00"
		} else if (data?.type === "ESP" && fromAfterSale) {
			data.date = formatDateForDB(dayjs())
			data.payment_paid = data.date
			data.done = true
		}
		setNewData(data)
	}

	const handleAdd = async () => {
		if (!newData?.date || !newData?.amount) return
		if (newData?.type === "CHE" && !newData?.comments) return
		setPaymentsList((o) => [...o, { ...newData, tmp_div: true }])
		setNewData({
			type: "CB",
			done: false,
			comments: "",
		})
	}

	const handleSubmit = async (closeModal) => {
		const failedDivs = []
		const successDivs = []
		const paymentTmp = [...paymentsList]
		if (typeof setHasSaved === "function") setHasSaved(false)

		try {
			setBusy(true)
			// Gestion des paiements
			for (const payment of paymentsList) {
				const index = paymentsList.indexOf(payment)
				if (payment?.payment_detail_id) {
					if (payment?.delete) {
						await API_Hiboutik.saleDeleteDiv(payment?.payment_detail_id).then(() => {
							paymentTmp[index].deleted = true
						})
					}
					if (payment?.update) {
						try {
							await API_Hiboutik.saleUpdateDiv(
								payment?.payment_detail_id,
								"payment_date_paid",
								dayjs().format("YYYY-MM-DD")
							)
							paymentTmp[index].update = false
							paymentTmp[index].done = true
							paymentTmp[index].payment_paid = dayjs().format("YYYY-MM-DD")
						} catch (error) {
							console.error(error)
						}
					}
				} else {
					const formated_payment_date = dayjs(payment.date).format("YYYY-MM-DD")
					let date = payment.date
					let payment_date = null

					if (
						formated_payment_date === sale?.created_at_date ||
						formated_payment_date === dayjs().format("YYYY-MM-DD") ||
						payment.done === true
					)
						payment_date = dayjs().format("YYYY-MM-DD")
					await API_Hiboutik.saleAddDivPayment(sale?.sale_id, payment.type, payment.amount, date)
						.then(async (res) => {
							if (payment?.comments)
								await API_Hiboutik.saleUpdateDiv(
									res?.data?.payment_detail_id,
									"comments",
									payment?.comments
								)
							if (payment_date)
								await API_Hiboutik.saleUpdateDiv(
									res?.data?.payment_detail_id,
									"payment_date_paid",
									payment_date
								)
							paymentTmp[index].payment_detail_id = res?.data?.payment_detail_id
							successDivs.push({
								...payment,
								date: date,
								payment_date_paid: payment_date,
								payment_detail_id: res?.data?.payment_detail_id,
								comments: payment?.comments,
							})
						})
						.catch((error) => {
							paymentTmp[index].deleted = true
							failedDivs.push({ ...payment, error: error })
						})
				}
			}

			let result = paymentTmp.filter((e) => e?.deleted !== true)
			result = result.map((v) => {
				delete v?.tmp_div
				return v
			})

			result = await recalculateSMDP(result, sale.sale_id, sale.amount_total)

			if (typeof setHasSaved === "function") setHasSaved(true)
			setSale((o) => ({ ...o, payment_details: [...result] }))
			setPaymentsList(result)

			if (result && closedSaleDocument) {
				// updating of invoice

				const dataFromConfig = revertConfigToDatabaseCorrectKeys(closedSaleDocument.config)
				const user = await API.get(closedSaleDocument.user[0]["@id"])
				const paymentsUpdated = (await API_Hiboutik.getSale(sale.sale_id))?.payment_details

				const stringifiedContent = await generateDocument(
					user,
					dataFromConfig.laboratory,
					dataFromConfig.patient,
					{
						...closedSaleDocument,
						config: {
							...closedSaleDocument.config,
							ventes: { ...closedSaleDocument.config.ventes, payment_details: paymentsUpdated },
						},
					}
				) // Generates the document under a stringified HTML format

				await updateDocumentPDF(closedSaleDocument?.id, {
					content: stringifiedContent,
				}) // Updates the document PDF
			}

			if (failedDivs.length) {
				for (const failed of failedDivs) {
					console.error(failed)
					// paymentTmp = paymentTmp.filter(e => e.amount !== failed.amount && e.type !== failed.type && e.date !== failed.date)
				}
				setError(null)
			}
			if (closeModal) setModal(false)
		} catch (error) {
			console.error(error)
			divErrorHandler(error)
		} finally {
			setBusy(false)
		}
	}

	const handleChangeStatus = async (v) => {
		let tmp = [...paymentsList]
		const index = paymentsList.findIndex(
			(e) => e.type === v?.type && e.amount === v?.amount && e.payment_detail_id === v?.payment_detail_id
		)
		if (!v?.done) {
			tmp[index].done = true
			tmp[index].update = true
			setPaymentsList(tmp)
			return
		}
		if (v?.done && !tmp[index].updated) {
			tmp[index].done = false
			tmp[index].update = false
			setPaymentsList(tmp)
			return
		}
	}

	const handleDeleteDiv = async (payment, index) => {
		try {
			setBusy(true)
			let tmp = [...paymentsList]
			if (payment?.payment_detail_id) {
				tmp[index].delete = !tmp[index]?.delete
			} else {
				tmp.splice(index, 1)
			}
			setPaymentsList(tmp)
			setBusy(false)
		} catch (error) {
			setBusy(false)
			console.error(error)
		}
	}

	const activateTeletrans = (ignoreAdviceFlag = false) => {
		const advice =
			selectedPatient?.advices?.sort((a, b) => new Date(b.dateOfAdvice) - new Date(a.dateOfAdvice)) || {}
		let errors = []
		if (!ctx.user.adeli) errors.push("ADELI_UTILISATEUR_MANQUANT")
		if (!ctx.laboratory.finess) errors.push("FINESS_UTILISATEUR_MANQUANT")
		if (!advice[0]) errors.push("PRESCRIPTION")
		if (!advice?.[0]?.prescriber?.rpps || !advice?.[0]?.prescriber?.finess)
			errors.push("RPPS_FINESS_PRESCRIPTEUR_MANQUANTS")
		if (advice.length > 1 && !adviceFlag && !ignoreAdviceFlag) errors.push("MULTIPLES_PRESCRIPTIONS")

		if (errors.length === 0) setModalTeletrans(true)
		else {
			if (errors.length === 2 && errors.includes(["ADELI_UTILISATEUR_MANQUANT", "FINESS_UTILISATEUR_MANQUANT"])) {
				setError((o) => ({
					...o,
					message: "Pour télétransmettre veuillez renseigner les numéros adeli et finess de l'utilisateur",
					codes: ["INFOS_USER_MANQUANTES"],
					customButton: {
						action: () => history.push("/mon-compte/mes-informations"),
						label: "Ajouter les informations",
					},
				}))
			} else if (errors.length > 1) {
				setError((o) => ({
					...o,
					message: "Plusieurs informations sont manquantes pour pouvoir télétransmettre",
					codes: [...errors],
					customButton:
						errors.find((err) => err === "RPPS_FINESS_PRESCRIPTEUR_MANQUANTS") && advice[0]
							? {
									action: () =>
										history.push("/mon-compte/mes-prescripteurs", {
											redirectTo: window.location.pathname,
											prescriber: advice[0].prescriber,
										}),
									label: "Ajouter les informations",
							  }
							: null,
				}))
			} else if (errors.length === 1 && errors[0] === "MULTIPLES_PRESCRIPTIONS") {
				setError((o) => ({
					...o,
					title: "Sélectionner une prescription",
					hideOkButton: true,
					customButton: {
						label: "Sélectionner",
						action: () => {
							setError(null)
							activateTeletrans(true)
						},
					},
					customButton2: {
						label: "Annuler",
						color: "warning",
						action: () => {
							setError(null)
						},
					},
					message: (
						<div>
							<div className="mb-4">
								Le patient a plusieurs prescriptions, veuillez sélectionner celle à utiliser :
							</div>
							<Select
								name="advice"
								defaultValue={{
									label: `DR.${patientPrescription.prescriber.lastName} - ${dayjs(
										patientPrescription.dateOfAdvice
									).format("DD-MM-YYYY")} (${patientPrescription.type})`,
									value: patientPrescription,
								}}
								onChange={(e) => {
									setPatientPrescription(e.value)
									setAdviceFlag(true)
								}}
								options={advice.map((ad) => {
									return {
										label: `DR.${ad.prescriber.lastName} - ${dayjs(ad.dateOfAdvice).format(
											"DD-MM-YYYY"
										)} (${ad.type})`,
										value: ad,
									}
								})}
							/>
						</div>
					),
				}))
			} else if (errors.length === 1 && errors[0] === "PRESCRIPION_MANQUANTE") {
				setError((o) => ({
					...o,
					message:
						"Le patient a plusieurs prescriptions. Veuillez sélectionner la prescription à utiliser pour accéder à la télétransmission.",
					codes: ["PRESCRIPION_MANQUANTE"],
					customButton: {
						action: () => {
							history.push("/mon-compte/mes-prescripteurs", {
								redirectTo: window.location.pathname,
							})
						},
						label: "Ajouter la prescription",
					},
				}))
			} else if (errors.length === 1 && errors.includes("RPPS_FINESS_PRESCRIPTEUR_MANQUANTS")) {
				setError((o) => ({
					...o,
					message:
						"Le numéro rpps ou finess du prescripteur est manquant. Veuillez l'ajouter pour accéder à la télétransmission",
					codes: errors,
					customButton: {
						action: () =>
							history.push("/mon-compte/mes-prescripteurs", {
								redirectTo: window.location.pathname,
								prescriber: advice.prescriber,
							}),
						label: "Ajouter les informations",
					},
				}))
			} else if (errors.length === 1 && errors.includes("FINESS_UTILISATEUR_MANQUANT")) {
				setError((o) => ({
					...o,
					message:
						"Le numéro finess de l'audioprothésiste est manquant. Veuillez l'ajouter pour accéder à la télétransmission",
					codes: errors,
					customButton: {
						action: () => history.push("/mon-compte/mes-informations"),
						label: "Ajouter les informations",
					},
				}))
			}
		}
	}

	const totalAmount = useMemo(() => {
		const totalPaid = paymentsList.filter((f) => f.type !== "SMDP").reduce((prev, curr) => +prev + +curr.amount, 0)
		const totalRefund = paymentsList
			.filter((f) => isRefundMethod(f.type))
			.reduce((prev, curr) => +prev + +curr.amount, 0)
		const amountLeft = rounded(sale.amount_total - totalPaid, 2)

		return { totalPaid, totalRefund, amountLeft }
	}, [paymentsList])

	const filterPaymentMethodFunction = (element) => {
		let filteredPayment = []
		if (!publicAmc) {
			filteredPayment.push("CMU")
		}
		if (!fromAfterSale) {
			filteredPayment = filteredPayment.concat(refundPaymentMethods)
		}
		if (teletransData?.secu) {
			filteredPayment.push("SECU")
		}
		if (teletransData?.mutl) {
			filteredPayment = filteredPayment.concat(["MUTL", "MTL"])
		}

		return element.isDeferredPayment && !filteredPayment.includes(element.key.trim())
	}

	const createSmdp = async (payments) => {
		try {
			const result = await recalculateSMDP(payments, sale.sale_id, sale.amount_total)
			setSale((o) => ({ ...o, payment_details: [...result] }))
			setPaymentsList(result)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<ModalTeletrans
				open={modalTeletrans}
				setOpen={setModalTeletrans}
				teletransData={teletransData}
				setTeletransData={setTeletransData}
				sale={sale}
				setSale={setSale}
				selectedPatient={selectedPatient}
				selectedProducts={selectedProducts}
				paymentsList={paymentsList}
				error={error}
				setError={setError}
				busy={busy}
				setBusy={setBusy}
				patientPrescription={patientPrescription}
				publicAmc={publicAmc}
				domTomPrices={domTomPrices}
				garantiesFSV={garantiesFSV}
				isBlind={isBlind}
				isPregnant={isPregnant}
			/>
			<Modal isOpen={modal} className="modal-div" centered size="xl" backdrop="static">
				<ModalHeader className="modal-custom-header" tag="div">
					<div className="d-flex justify-content-between">
						<div>
							{patientAmc &&
								selectedProducts.filter((p) => p.category_name === "Appareils auditifs").length !==
									0 && (
									<InsuranceBadge
										selectedProducts={selectedProducts}
										patientAmc={patientAmc}
										hasBothEars={hasBothEars}
									/>
								)}
						</div>

						<button
							type="button"
							className=" icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
							title="Fermer"
							disabled={busy}
							onClick={async () => {
								setError(null)
								const payments = await initFromSaleData()
								await createSmdp(payments)
								setModal(false)
							}}>
							<i className="fas fa-times" />
						</button>
					</div>
					<div className="d-flex justify-content-between mt-3">
						{patientAmo && (
							<div className="alert-primary alert m-0">
								<strong>Code d'exonération AMO</strong> :{" "}
								{garantiesFSV.find((f) => f.value === patientAmo?.situationCode)?.label} {" | "}{" "}
								{garantiesFSV.find((f) => f.value === patientAmo?.situationCode)?.percent} % audition.
								{patientAmo.ald !== 0 && (
									<p>
										Le patient bénéficie d'une ald, si elle est applicable à l'audioprothèse
										veuillez l'indiquer <strong>au moment de télétransmettre.</strong>
									</p>
								)}
								{isPregnant && (
									<>
										<hr />
										<p>{particularSituationsSentences.MATERNITY}</p>
									</>
								)}
								{isBlind && (
									<div>
										<hr />
										<strong>{particularSituationsSentences.BLINDNESS}</strong>
									</div>
								)}
							</div>
						)}
						<div className="mr-4 ml-4">
							<div
								className={
									"modal-amountleft btn p-3 " +
									(totalAmount.amountLeft ? " btn-danger" : "btn-outline-primary")
								}>
								<span className="label">Reste à payer :</span>
								<strong>{" " + totalAmount.amountLeft.toLocaleString("FR")} €</strong>
								<br />

								<span className="label">Total remboursement :</span>
								<strong>{" " + totalAmount.totalRefund.toLocaleString("FR")} €</strong>
							</div>
							<div className="modal-amountleft btn">
								<button
									type="button"
									className={
										"btn btn-block " +
										(teletransData?.mutl || teletransData?.secu
											? "btn-primary"
											: "btn-outline-primary")
									}
									onClick={() => activateTeletrans()}
									disabled={!patientAmo}>
									{/* <span href="#" > is ugly but needed for the tooltip to work properly (if deleted, the tooltip will crash the page) */}
									<span href="#" id={!patientAmo ? "btn-teletransmission" : ""}>
										Télétransmission
									</span>
								</button>
								{!patientAmo ? (
									<Tooltip
										placement="top"
										isOpen={tooltipOpen}
										target="btn-teletransmission"
										toggle={toggle}>
										Le patient n'a pas de couverture amo
									</Tooltip>
								) : null}
							</div>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<div className="div-container-new">
						<h5>Ajouter un moyen de paiement</h5>
						<div className="form-row">
							<div className="col-3">
								<select
									className="form-control"
									value={newData?.type || "CB"}
									placeholder="Méthode"
									disabled={busy}
									onChange={(e) => {
										e.persist()
										updateNewPayment({
											...newData,
											amount: null,
											type: e.target.value,
											done: false,
										})
									}}>
									{paymentTypes &&
										paymentTypes.filter(filterPaymentMethodFunction).map((v, k) => (
											<option key={k} value={v.key}>
												{v.label ?? v.key}
											</option>
										))}
								</select>
							</div>
							<div className="col-3">
								<DateInputOnly
									className={"form-control " + (!newData?.date ? " border-1 border-warning" : "")}
									allowClear={false}
									placeholder="Date"
									value={newData?.date ? dayjs(formatDateForDB(newData.date)) : null}
									disabled={
										["MTL", "CMU"].includes(newData?.type) ||
										busy ||
										(newData?.type === "ESP" && fromAfterSale)
									}
									onChange={(date) =>
										setNewData((old) => ({
											...old,
											date: date.format("YYYY-MM-DD"),
											done: date.isToday(),
										}))
									}
								/>
							</div>
							<div className="col-3">
								<div
									className="btn-max-left-amount"
									onClick={() => {
										setNewData((o) => ({ ...o, amount: totalAmount.amountLeft }))
									}}>
									max €
								</div>
								<input
									className={"form-control" + (!newData?.amount ? " border-1 border-warning" : "")}
									value={newData?.amount || ""}
									type="number"
									placeholder="Montant"
									step="0.01"
									// min="0"
									onChange={(e) => {
										let value = e?.target?.value

										if (!fromAfterSale) {
											if (rounded(value) >= sale?.amount_left - totalPayments)
												value = rounded(sale?.amount_left - totalPayments)

											value = Math.abs(value)
										} else {
											if (refundPaymentMethods.includes(newData.type.trim())) {
												// 12 * -1 = -12 (positive to negative)
												if (value > 0) value *= -1
											} else {
												// -12 * -1 = 12 (negative to positive)
												if (value < 0) value *= -1
											}
										}
										setNewData((o) => ({ ...o, amount: value }))
									}}
									disabled={busy}
								/>
							</div>
							<div className="col-2">
								<Switch
									checkedChildren="Acquitté"
									checked={newData?.done || false}
									unCheckedChildren="En attente"
									disabled={
										(newData.date &&
											dayjs().format("YYYY-MM-DD") ===
												dayjs(newData.date).format("YYYY-MM-DD")) ||
										["MTL", "SECU", "CMU"].includes(newData?.type) ||
										busy
									}
									onChange={(e) => {
										setNewData((o) => ({ ...o, done: e }))
									}}
								/>
							</div>
							<div className="col-1">
								<button type="button" className="add" disabled={busy} onClick={handleAdd}>
									<i className="fad fa-plus-square" />
								</button>
							</div>
						</div>
						{fromAfterSale && newData.type === "ESP" && (
							<div>
								<div className="alert alert-warning">
									Vous ajoutez un paiement en espèce post-vente.
									<br />
									Ce paiement sera acquitté automatiquement au jour actuel.
								</div>
							</div>
						)}

						<div className="form-row">
							<input
								className={
									"form-control" +
									(!newData?.comments && ["CHE", "RBC"].includes(newData?.type?.trim())
										? " border-1 border-warning"
										: "")
								}
								type="text"
								placeholder="Numéro de chèque"
								value={newData?.comments || ""}
								onChange={(e) => {
									const value = e?.target?.value
									setNewData((o) => ({ ...o, comments: value }))
								}}
								disabled={!["CHE", "RBC"].includes(newData?.type?.trim())}
							/>
						</div>
					</div>
					<div className="div-container-new">
						<h5>Liste </h5>

						{teletransData?.actes && teletransData?.secu && !teletransData?.secuTierPayant && (
							<Alert color="info">
								La télétransmission hors tiers-payant est activée avec la demande de remboursement
								suivante: <br />- Sécurité Sociale: {formatCurrency(teletransData.total?.totalAmo ?? 0)}
							</Alert>
						)}

						{paymentsList.length ? (
							<PaymentList
								data={paymentsList}
								setData={setPaymentsList}
								handleChangeStatus={handleChangeStatus}
								busy={busy}
								handleDeleteDiv={handleDeleteDiv}
								updateApiPayments={updateApiPayments}
								laboratory={laboratory}
							/>
						) : (
							<Alert color="info">
								Aucun paiement dans la liste. <br />
								Utilisez les champs au dessus pour ajouter des méthodes de paiements.
							</Alert>
						)}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="warning"
						className="modal-div-footer-btn "
						outline
						onClick={() => {
							setError(null)
							initFromSaleData()
						}}
						disabled={busy}>
						Annuler les modifications
					</Button>
					<Button
						color="primary"
						className="modal-div-footer-btn"
						onClick={async () => {
							if (!paymentsList.filter((f) => f?.update || f?.delete || !f?.payment_detail_id).length) {
								const payments = await initFromSaleData()
								await createSmdp(payments)
								setModal(false)
								setError(null)
							} else {
								setError(null)
								await handleSubmit(false)
								typeof setNeedRefresh === "function" && setNeedRefresh(true)
							}
						}}
						disabled={
							busy || !paymentsList.filter((f) => f?.update || f?.delete || !f?.payment_detail_id).length
						}>
						{busy ? <LoadingOutlined /> : "Enregistrer"}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default ModalDivPayment
