/* eslint-disable react-hooks/exhaustive-deps */
import { UserRole } from "@audiowizard/common"
import AuthContext from "contexts/AuthContext"
import React, { useMemo, ReactElement, useContext } from "react"
import { Route, RouteProps, NavLink, useLocation, matchPath, match } from "react-router-dom"
import "./style.scss"

export type CardItemProps = {
	name: string
	url: string
	match?: match | null
	useSlug?: string /* if route has slugs, determine which param use in name ending */
	component: RouteProps["component"]
	icon?: string
	exact?: boolean
	withRole?: UserRole[]
	onlyVisibleOnExactRoute?: boolean
	disabled?: boolean
}

export type CardWithTabsProps = {
	urlprefix: string
	className?: string
	children: ReactElement<CardItemProps> | ReactElement<CardItemProps>[]
}

export const CardItem: (props: CardItemProps) => JSX.Element | null = () => null

export const CardWithTabs = ({ urlprefix, children, className }: CardWithTabsProps): JSX.Element | null => {
	const location = useLocation()
	const { user } = useContext(AuthContext)

	const isVisible = (data: CardItemProps): boolean => {
		const match = matchPath(location.pathname, { path: urlprefix + data.url })
		if (!data.onlyVisibleOnExactRoute || match) return true
		return false
	}

	const tabs: CardItemProps[] = useMemo(() => {
		return React.Children.map(children, ({ props }) => {
			const match = matchPath<{ [key: string]: string }>(location.pathname, { path: urlprefix + props.url })
			const rename = match && props.useSlug ? props.name + " / " + match.params[props.useSlug] : props.name
			return {
				name: rename,
				url: props.url,
				match: match,
				component: props.component,
				icon: props.icon,
				exact: props.exact ?? true,
				withRole: props.withRole,
				onlyVisibleOnExactRoute: props.onlyVisibleOnExactRoute ?? false,
				disabled: props.disabled,
			}
		})
	}, [location.pathname])

	const tabswithRole = useMemo(() => {
		return tabs.filter((tab) => {
			if (!tab.withRole) return true
			return tab.withRole.some((role) => user.roles?.includes(role))
		})
	}, [tabs])

	return (
		<div className={`card card-fluid bg-transparent overflow-hidden ${className}`}>
			<div className="card-header d-flex justify-content-between align-items-center p-0 CardWithTabs">
				<div className="card-header-tab-container">
					{tabswithRole.map((v, key) => (
						<NavLink
							strict
							key={key}
							to={v.match?.url ?? urlprefix + v.url}
							title={v.name}
							exact={v.exact}
							activeClassName="card-header-tab-item-active"
							className={
								"card-header-tab-item " +
								(isVisible(v) ? "d-inline-block " : "d-none ") +
								(v.disabled ? " disabled " : "")
							}>
							{v.icon && <i className={"mx-auto fad " + v.icon} />}
							<span className="d-none d-xl-inline tab-title ml-2 mr-2">{v.name}</span>
						</NavLink>
					))}
				</div>
			</div>
			<div className="card-body card-tab-body bg-white position-relative">
				{tabswithRole.map((v, key) => (
					<Route exact={v.exact} path={urlprefix + v.url} key={key} component={v.component} />
				))}
			</div>
		</div>
	)
}
