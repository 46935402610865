/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import PropTypes from "prop-types"
import { useState } from "react"
import { toast } from "react-toastify"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import FieldWithError from "../../../components/forms/FieldWithError"
import { API_URL } from "../../../config"
import { HandleChange } from "../../../services/functions"

const NouvelleInvitation = ({ id, title, ressource, setRessource, isOpen, setIsOpen }) => {
	const defaultValue = {
		email: "",
	}
	const [value, setValues] = useState({ ...defaultValue })
	const [sending, setSending] = useState(false)

	const handleInvitation = async (e) => {
		e.preventDefault()

		if (sending) return

		setSending(true)
		try {
			const response = await axios.post(API_URL + "/patient/invite", value)

			const newInvitation = [...ressource.patientInvitations]
			newInvitation.push({
				email: value.email,
				data: {},
				firstName: "-",
				lastName: "-",
				sentAt: response?.data?.message?.sentAt?.date || "",
				confirmedAt: "",
				id: response?.data?.message?.id || undefined,
			})
			setRessource({ ...ressource, patientInvitations: newInvitation })
			toast.success("Une invitation a été envoyée")
		} catch (error) {
			if (error?.response?.data?.message) {
				toast.error(error.response.data.message)
			} else if (error.response.data.status === 500) {
				toast.error("L'envoi de l'invitation a échoué, veuillez utiliser une adresse email valide")
			}
		} finally {
			setSending(false)
			setValues({ ...defaultValue })
			setIsOpen(false)
		}
	}

	return (
		<Modal isOpen={isOpen} centered>
			<ModalHeader>{title}</ModalHeader>
			<ModalBody>
				<div className="row">
					<div className="col">
						<FieldWithError
							id={"email-" + id}
							name="email"
							type="email"
							label="Adresse email *"
							required={true}
							onChange={(event) => HandleChange(event, value, setValues)}
							value={value.email}
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-primary" disabled={sending || !value.email} onClick={handleInvitation}>
					Confirmer
				</button>
				<button className="btn btn-danger" disabled={sending} onClick={() => setIsOpen(false)}>
					Annuler
				</button>
			</ModalFooter>
		</Modal>
	)
}

NouvelleInvitation.propTypes = {
	handleSubmitDoctor: PropTypes.func,
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default NouvelleInvitation
