/**
 * Get a cookie's key's given name value
 * @param name
 * @returns value of the cookie's key's given name
 */
export const getCookie = (name: string): string | undefined => {
	const cookiesArray = document.cookie.split("; ")

	for (const cookie of cookiesArray) {
		const [cookieName, cookieValue] = cookie.split("=")
		if (cookieName === name) return cookieValue
	}

	return undefined
}

/**
 * Create a cookie's key with a given name and value or update the value of an
 * existing given cookie's name
 * @param name
 * @param value
 */
export const setCookie = (name: string, value: string): void => {
	value = `-${value}-`
	const existingValue = getCookie(name)

	const updatedValue = existingValue ? `${existingValue}${value}` : value

	document.cookie = `${name}=${updatedValue}; path=/`
}

/**
 * Remove a given cookie's key's value
 * @param name
 * @param value
 */
export const removeFromCookie = (name: string, value: string): void => {
	value = `-${value}-`
	const existingValue = getCookie(name)

	if (!existingValue || !getCookie(name)?.includes(value)) return
	const updatedValue = existingValue.replace(value, "")

	document.cookie = `${name}=${updatedValue}; path=/`
}
