import { Select } from "antd"
import { SelectProps } from "antd/lib/select"
import { partialSearch } from "services/functions"
import { Override } from "@audiowizard/common"

export const LanguageCodeLabels = {
	ab: "Abkhaze",
	ae: "Avestique",
	af: "Afrikaans",
	ak: "Akan",
	am: "Amharique",
	an: "Aragonais",
	ar: "Arabe",
	as: "Assamais",
	av: "Avar",
	ay: "Aymara",
	az: "Azéri",
	ba: "Bachkir",
	be: "Biélorusse",
	bg: "Bulgare",
	bh: "Bihari",
	bi: "Bichelamar",
	bm: "Bambara",
	bn: "Bengali",
	bo: "Tibétain",
	br: "Breton",
	bs: "Bosnien",
	ca: "Catalan",
	ce: "Tchétchène",
	ch: "Chamorro",
	co: "Corse",
	cr: "Cri",
	cs: "Tchèque",
	cu: "Vieux-slave",
	cv: "Tchouvache",
	cy: "Gallois",
	da: "Danois",
	de: "Allemand",
	dv: "Maldivien",
	dz: "Dzongkha",
	ee: "Ewe",
	el: "Grec moderne",
	en: "Anglais",
	eo: "Espéranto",
	es: "Espagnol",
	et: "Estonien",
	eu: "Basque",
	fa: "Persan",
	ff: "Peul",
	fi: "Finnois",
	fj: "Fidjien",
	fo: "Féroïen",
	fr: "Français",
	fy: "Frison occidental",
	ga: "Irlandais",
	gd: "Écossais",
	gl: "Galicien",
	gn: "Guarani",
	gu: "Gujarati",
	gv: "Mannois",
	ha: "Haoussa",
	he: "Hébreu",
	hi: "Hindi",
	ho: "Hiri motu",
	hr: "Croate",
	ht: "Créole haïtien",
	hu: "Hongrois",
	hy: "Arménien",
	hz: "Héréro",
	ia: "Interlingua",
	id: "Indonésien",
	ie: "Occidental",
	ig: "Igbo",
	ii: "Yi",
	ik: "Inupiak",
	io: "Ido",
	is: "Islandais",
	it: "Italien",
	iu: "Inuktitut",
	ja: "Japonais",
	jv: "Javanais",
	ka: "Géorgien",
	kg: "Kikongo",
	ki: "Kikuyu",
	kj: "Kuanyama",
	kk: "Kazakh",
	kl: "Groenlandais",
	km: "Khmer",
	kn: "Kannada",
	ko: "Coréen",
	kr: "Kanouri",
	ks: "Cachemiri",
	ku: "Kurde",
	kv: "Komi",
	kw: "Cornique",
	ky: "Kirghiz",
	la: "Latin",
	lb: "Luxembourgeois",
	lg: "Ganda",
	li: "Limbourgeois",
	ln: "Lingala",
	lo: "Lao",
	lt: "Lituanien",
	lu: "Luba",
	lv: "Letton",
	mg: "Malgache",
	mh: "Marshallais",
	mi: "Maori de Nouvelle-Zélande",
	mk: "Macédonien",
	ml: "Malayalam",
	mn: "Mongol",
	mo: "Moldave",
	mr: "Marathi",
	ms: "Malais",
	mt: "Maltais",
	my: "Birman",
	na: "Nauruan",
	nb: "Norvégien Bokmål",
	nd: "Sindebele",
	ne: "Népalais",
	ng: "Ndonga",
	nl: "Néerlandais",
	nn: "Norvégien Nynorsk",
	no: "Norvégien",
	nr: "Nrebele",
	nv: "Navajo",
	ny: "Chichewa",
	oc: "Occitan",
	oj: "Ojibwé",
	om: "Oromo",
	or: "Oriya",
	os: "Ossète",
	pa: "Pendjabi",
	pi: "Pali",
	pl: "Polonais",
	ps: "Pachto",
	pt: "Portugais",
	qu: "Quechua",
	rc: "Créole Réunionnais",
	rm: "Romanche",
	rn: "Kirundi",
	ro: "Roumain",
	ru: "Russe",
	rw: "Kinyarwanda",
	sa: "Sanskrit",
	sc: "Sarde",
	sd: "Sindhi",
	se: "Same du Nord",
	sg: "Sango",
	sh: "Serbo-croate",
	si: "Cingalais",
	sk: "Slovaque",
	sl: "Slovène",
	sm: "Samoan",
	sn: "Shona",
	so: "Somali",
	sq: "Albanais",
	sr: "Serbe",
	ss: "Swati",
	st: "Sotho du Sud",
	su: "Soundanais",
	sv: "Suédois",
	sw: "Swahili",
	ta: "Tamoul",
	te: "Télougou",
	tg: "Tadjik",
	th: "Thaï",
	ti: "Tigrigna",
	tk: "Turkmène",
	tl: "Tagalog",
	tn: "Tswana",
	to: "Tongien",
	tr: "Turc",
	ts: "Tsonga",
	tt: "Tatar",
	tw: "Twi",
	ty: "Tahitien",
	ug: "Ouïghour",
	uk: "Ukrainien",
	ur: "Ourdou",
	uz: "Ouzbek",
	ve: "Venda",
	vi: "Vietnamien",
	vo: "Volapük",
	wa: "Wallon",
	wo: "Wolof",
	xh: "Xhosa",
	yi: "Yiddish",
	yo: "Yoruba",
	za: "Zhuang",
	zh: "Chinois",
	zu: "Zoulou",
}
// Trié par label
const languageCodeLabelsSorted = {
	fr: "Français",
	...Object.fromEntries(
		Object.entries(LanguageCodeLabels)
			.filter(([code]) => code !== "fr")
			.sort(([, a], [, b]) => a.localeCompare(b))
	),
}

/**
 * Code ISO 639-1
 */
export type LanguageCode = keyof typeof LanguageCodeLabels

type LanguageInputProps = Override<
	SelectProps<LanguageCode>,
	{
		id: string
		label: string

		groupClassName?: string
		labelClassName?: string
		selectClassName?: string

		value: LanguageCode
		onChange: (value: LanguageCode) => any
	}
>

export default function LanguageInput({
	id,
	label,
	groupClassName = "",
	labelClassName = "",
	selectClassName = "",
	value,
	onChange,
	...props
}: LanguageInputProps): JSX.Element {
	return (
		<div className={`form-group ${groupClassName}`}>
			<label htmlFor={id} className={labelClassName}>
				{label}
			</label>
			<Select
				id={id}
				className={`form-control w-100 removeantd-class antd-add-padding ${selectClassName}`}
				showSearch
				filterOption={(search, option) => partialSearch(option!.label as string, search)}
				value={value}
				onChange={onChange}
				{...props}>
				{Object.entries(languageCodeLabelsSorted).map(([code, label]) => (
					<Select.Option key={code} label={label} value={code}>
						{label}
					</Select.Option>
				))}
			</Select>
		</div>
	)
}
