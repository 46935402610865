/**
 * Used for the styling the variable span element
 */
export const ReportingEditorVarStringifiedStyle = `

	p {
		display: inline;
	}

	*[data-tooltip] {
		position: relative;
		border-bottom: dotted 1px #000;
	  }

	*[data-tooltip]:hover:before {
	content: attr(data-tooltip);
	background-color: #000;
	color: #fff;
	bottom: 2em;
	font-size: 0.8em;
	padding: 0.3em;
	position: absolute;
	white-space: nowrap;
	z-index: 1;
	}

	.mce-content-body [contentEditable=false] {
		cursor: pointer;
	}

	.reporting-template__variable {
		margin: 3px 0;
		padding: 4px 6px;
		background-color: #f9f9f9;
		border-radius: 3px;
		box-shadow: 0px 1px 1px 1px #0000001c;
		display: inline-block;
		justify-content: center;
		align-items: center;
		font-size: 0.9em;
		border: 1px solid #dadada;
	}


	.reporting-template__variable-icon {
		height: 1em;
		margin-right: 4px;
	}
	
	.reporting-template__variable-label {
		color: #a7a7a7;
		margin-right: 4px;
	}
	
	.reporting-template__variable-label::before {
		content: attr(data-label);
	}
	
	.reporting-template__variable-label::after {
		content: ":";
	}
	
	.reporting-template__variable-value {
		color: #484848;
	}
	
	.reporting-template__variable-value::before {
		content: attr(data-value);
	}
	
	@media print {
		.reporting-template__variable {
			border: 0px;
			oultine: 0px;
			box-shadow: none;
			background-color: transparent;
			padding: 0px;
			margin: 0px;
		}

		.break-before {
			breakBefore: "page"
		}

		.reporting-template__variable-value [data-mce-selected] {
			oultine: 0px;
			border: 0px;
			box-shadow: none;
		}

		.mce-content-body table[data-mce-selected] {
			outline: 0px;
			border: 0px;
			box-shadow: none;
		}

		.mce-content-body [contentEditable=false][data-mce-selected] {
			outline: 0px;
			border: 0px;
			box-shadow: none;
		}

		.mce-content-body div.mce-resizehandle {
			display:none;
		}
		
		
		.reporting-template__variable[data-empty="true"] {
			display: none;
		}
		
		.reporting-template__variable-icon {
			display: none;
		}

		.reporting-template__variable-label-container {
			display: none;	
		}
	
		.reporting-template__variable-label {
			display: none;	
		}
		
		.reporting-template__variable-value {
			all: unset;
		}
		
		.reporting-template__variable-value::before {
			display: none;
		}


	}
`
