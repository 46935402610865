import useConstant from "components/Hooks/useConstant"
import React, { useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { Material } from "three"
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls"
import { STLLoader } from "three/examples/jsm/loaders/STLLoader"
import "./StlViewer.scss"

// Adapté depuis https://github.com/yatheeshraju/react-stl-file-viewer
class Viewer {
	private scene: THREE.Scene
	private camera: THREE.PerspectiveCamera
	private renderer: THREE.WebGLRenderer
	private controls: TrackballControls

	private grid: THREE.GridHelper
	private hemiLight: THREE.HemisphereLight

	private object?: THREE.Mesh
	private objectMaterial: THREE.MeshPhongMaterial

	public constructor() {
		this.scene = new THREE.Scene()
		this.scene.background = new THREE.Color(0xffffff)
		this.scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000)

		this.camera = new THREE.PerspectiveCamera()
		this.camera.position.set(0, 200, 200)

		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
			preserveDrawingBuffer: true,
		})
		this.renderer.setPixelRatio(window.devicePixelRatio)

		this.controls = new TrackballControls(this.camera, this.renderer.domElement)
		this.controls.maxDistance = 2000

		// Grid
		this.grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000)
		;(this.grid.material as Material).opacity = 0.2
		;(this.grid.material as Material).transparent = true
		this.scene.add(this.grid)

		// Light
		this.hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444)
		this.hemiLight.position.set(0, 200, 0)
		this.scene.add(this.hemiLight)

		// Material
		this.objectMaterial = new THREE.MeshPhongMaterial({
			specular: 0x111111,
			shininess: 200,
		})
	}

	public render(): void {
		this.controls.update()
		this.renderer.render(this.scene, this.camera)
	}

	public async loadStl(src: string, onProgress?: (event: ProgressEvent) => void): Promise<void> {
		const loader = new STLLoader()
		const geometry = await loader.loadAsync(src, onProgress)

		this.object = new THREE.Mesh(geometry, this.objectMaterial)

		// Agrandi le modèle si sa hauteur (axe Z) est inférieur à 100
		this.object.geometry.computeBoundingBox()
		const size = this.object.geometry.boundingBox!.getSize(new THREE.Vector3())
		if (size.z < 100) {
			const ratio = 100 / size.z
			this.object.scale.set(ratio, ratio, ratio)
		}

		this.object.position.set(0, 0, 0)
		this.object.rotation.set(-Math.PI / 2, 0, 0)

		this.scene.add(this.object)
	}

	public setObjectColor(color: number | string): void {
		this.objectMaterial.color.set(color)
	}

	public setSize(width: number, height: number): void {
		this.camera.aspect = width / height
		this.camera.updateProjectionMatrix()

		this.renderer.setSize(width, height)

		this.controls.handleResize()
	}

	public setContainer(container: HTMLElement): void {
		container.appendChild(this.renderer.domElement)
	}

	public dispose(): void {
		this.renderer.dispose()
		this.controls?.dispose()

		for (const obj of this.scene.children) {
			;(obj as any).geometry?.dispose()
			;(obj as any).material?.dispose()
		}
	}
}

type StlViewerProps = {
	className?: string
	defaultObjectColor: string
	src: string

	onLoad: () => void
}
export default function StlViewer({ className = "", defaultObjectColor, src, onLoad }: StlViewerProps): JSX.Element {
	const containerRef = useRef<HTMLDivElement>(null)
	const animationFrameRef = useRef(-1)
	const [objectColor, setObjectColor] = useState(defaultObjectColor)
	const [loading, setLoading] = useState(false)
	const [loadingPercentage, setLoadingPercentage] = useState(0)

	const viewer = useConstant(() => new Viewer())

	useEffect(() => {
		if (containerRef.current == null) return

		viewer.setContainer(containerRef.current)

		const animate = (): void => {
			viewer.render()
			animationFrameRef.current = requestAnimationFrame(animate)
		}
		animate()

		return () => {
			cancelAnimationFrame(animationFrameRef.current)
			viewer.dispose()
		}
	}, [])

	useEffect(() => {
		const loadStl = async (): Promise<void> => {
			setLoading(true)
			await viewer.loadStl(src, (e) => {
				setLoadingPercentage(Math.round((e.loaded / e.total) * 100))
			})
			setLoading(false)

			onLoad()
		}
		loadStl()
	}, [src])

	useEffect(() => {
		viewer.setObjectColor(objectColor)
	}, [objectColor])

	useEffect(() => {
		const resizeCanvas = (): void => {
			const { width, height } = containerRef.current!.getBoundingClientRect()
			viewer.setSize(width, height)
		}
		resizeCanvas()

		window.addEventListener("resize", resizeCanvas)

		return () => {
			window.removeEventListener("resize", resizeCanvas)
		}
	}, [])

	return (
		<div className={`stl-viewer-container ${className}`} ref={containerRef}>
			{loading && <span className="stl-viewer-loading-text">Chargement ({loadingPercentage}%)...</span>}
			<input
				className="stl-viewer-color-input form-control form-control-sm"
				type="color"
				title="Modifier la couleur du modèle"
				value={objectColor}
				onChange={(e) => setObjectColor(e.target.value)}
			/>
		</div>
	)
}
