import { Popconfirm } from "antd"

import { TableActions } from "./../TableActions"

const ActionControlerCol = (cancel, isEditing, editingKey, isAddingLead, edit, setNoteKey, setCurrentRecord, save) => {
	const actionsRender = (operation, record) => {
		return (
			<>
				{isEditing(record) ? (
					<span style={{ maxWidth: "150px" }}>
						<button
							className="btn btn-link"
							title="Enregistrer"
							onClick={() => save(record.key)}
							style={{ marginRight: 8, padding: 0 }}>
							<i className="fad fa-save fa-lg mx-1" />
						</button>
						<Popconfirm
							overlayClassName="dashboard-popconfirm"
							cancelText="Oui"
							cancelButtonProps={{ type: "primary", danger: true }}
							okText="Non"
							okType="default"
							title={isAddingLead ? "Confirmer la suppression ?" : "Voulez-vous annuler ?"}
							onCancel={() => cancel(record.key)}>
							<button className="btn btn-link" title="Annuler" style={{ marginRight: 8, padding: 0 }}>
								<i color="red" className="fad fa-times fa-lg mx-1" />
							</button>
						</Popconfirm>
					</span>
				) : (
					<TableActions
						record={record}
						editingKey={editingKey}
						edit={edit}
						setNoteKey={setNoteKey}
						setCurrentRecord={setCurrentRecord}
					/>
				)}
			</>
		)
	}
	return {
		title: "Actions",
		dataIndex: "operation",
		key: "action",
		width: "170px",
		fixed: "right",
		render: (operation, record) => actionsRender(operation, record),
	}
}

export default ActionControlerCol
