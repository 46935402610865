/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Table } from "antd"
import { useContext, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"

import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import MutuelleModal from "./Mutuelle.modal"

const MesMutuelles = () => {
	const history = useHistory()
	const location = useLocation()

	const { mutuelles, setMutuelles, user } = useContext(AuthContext)

	const [needRefresh, setNeedRefresh] = useState(true)
	const [mutuelle, setMutuelle] = useState(false)
	const [sharedMutuelles, setSharedMutuelles] = useState([])
	const [loading, setLoading] = useState(true)
	const [pagination, setPagination] = useState({ current: 1, total: 0 })
	const [insuranceSearch, setInsuranceSearch] = useState("")

	const successBadge = () => (
		<h6>
			<span className={"badge badge-soft-primary float-left"}>
				<i className="fad fa-check fa-2x" aria-hidden="true" />
			</span>
		</h6>
	)

	const columns = [
		{
			title: "Nom",
			dataIndex: "label",
			sorter: (a, b) => {
				a = a.label || ""
				b = b.label || ""
				return a.localeCompare(b)
			},
			render: (a) => <strong>{a}</strong>,
			width: "20%",
		},
		{
			title: "Numéro d'AMC",
			dataIndex: "amcId",
			sorter: (a, b) => {
				a = a.address || ""
				b = b.address || ""
				return a.localeCompare(b)
			},
			width: "20%",
		},
		{
			title: "Adresse",
			dataIndex: "address",
			sorter: (a, b) => {
				a = a.address || ""
				b = b.address || ""
				return a.localeCompare(b)
			},
			render: (adress) => <span>{adress || "non renseigné"}</span>,
			width: "20%",
		},
		{
			title: "Ville",
			dataIndex: "city",
			sorter: (a, b) => {
				a = a.city || ""
				b = b.city || ""
				return a.localeCompare(b)
			},
			render: (city) => <span>{city || "non renseigné"}</span>,
			width: "20%",
		},
		{
			title: "Tiers-payant",
			dataIndex: "isTP",
			sorter: (a, b) => {
				a = a.siren || ""
				b = b.siren || ""
				return a.localeCompare(b)
			},
			render: (val) => (val ? successBadge() : "Hors tiers payant"),
			width: "20%",
		},
	]

	const handleRowClick = (id) => {
		const mutuelle = mutuelles.find((mutuelle) => {
			return mutuelle.id === id
		})
		setMutuelle(mutuelle)
	}

	const handleAddMutuelle = (event) => {
		event.preventDefault()
	}

	const getData = async (apiFilter) => {
		try {
			setLoading(true)
			let search = `?companies=${user.company["@id"]}&page=${pagination.current}`
			if (apiFilter) search = `?companies=${user.company["@id"]}&label=${apiFilter}`
			const res = await API.findAll("USER_INSURANCES_API", search, true)

			const sharedRes = await API.findAll("HEALTH_INSURANCES_API")
			setPagination((old) => ({ ...old, total: res["hydra:totalItems"] }))

			setSharedMutuelles(sharedRes)
			setMutuelles(res["hydra:member"])
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [pagination.current])

	useEffect(() => {
		if (!location.state) return
		if (location.state.name) setMutuelle(mutuelles.find((m) => m.name === location.state?.name))
	}, [location])

	useEffect(() => {
		if (needRefresh) {
			getData()
			setNeedRefresh(false)
		}
	}, [needRefresh])

	return (
		<Col>
			<SectionHeader title="Liste des mutuelles">
				{history?.location?.state?.redirectTo && history?.location?.state?.redirectToLabel ? (
					<ButtonRounded
						color="primary-outlined"
						type="button"
						onClick={() => history.push(history.location.state.redirectTo)}>
						{history.location.state.redirectToLabel}
					</ButtonRounded>
				) : null}
				<ButtonRounded
					color="primary-outlined"
					type="button"
					onClick={() =>
						setMutuelle({
							label: "",
							amcId: "",
							address: "",
							city: "",
							postalCode: "",
							isTP: true,
						})
					}>
					Ajouter une mutuelle
				</ButtonRounded>
			</SectionHeader>
			<Row className="mb-4">
				{sharedMutuelles
					? sharedMutuelles.map((mut) => {
							return (
								<ButtonRounded
									size="small"
									className="mx-2"
									color="primary-outlined"
									key={mut.id}
									disabled={mutuelles.find((m) => m.amcId === mut.amcId) ? true : false}
									onClick={async () => {
										try {
											setLoading(true)
											delete mut["@id"]
											delete mut["@type"]
											delete mut["id"]
											const res = await API.create("USER_INSURANCES_API", {
												...mut,
												company: user.company["@id"],
											})
											setMutuelles((prev) => [...prev, res.data])
										} catch (error) {
											console.error(error)
											toast.error("Erreur à l'ajout de la mutuelle")
										} finally {
											setLoading(false)
										}
									}}>
									Ajouter la {mut.label}
								</ButtonRounded>
							)
					  })
					: null}
			</Row>
			<Row className="my-2">
				<input
					type="search"
					name="insuranceSearch"
					placeholder="Rechercher une mutuelle"
					className="form-control"
					value={insuranceSearch}
					onChange={(e) => {
						setInsuranceSearch(e.target.value)
						getData(e.target.value)
					}}
				/>
			</Row>

			<Row className="my-2 flex-grow-1">
				<Table
					loading={loading}
					columns={columns}
					pagination={false}
					className="w-100"
					dataSource={mutuelles || []}
					rowKey={(record) => record.id}
					onRow={(record, index) => {
						return {
							onClick: async () => {
								handleRowClick(record.id, index)
							},
						}
					}}
				/>
			</Row>
			<Row className="my-2">
				<Pagination
					style={{ marginTop: "16px" }}
					current={pagination.current}
					total={pagination.total}
					onChange={(e) => setPagination((old) => ({ ...old, current: e }))}
				/>
			</Row>

			<MutuelleModal
				handleSubmitMutuelle={handleAddMutuelle}
				mutuelle={mutuelle}
				title={mutuelle.label}
				setMutuelle={setMutuelle}
				needRefresh={needRefresh}
				setNeedRefresh={() => setNeedRefresh(true)}
				errors
			/>
		</Col>
	)
}

export default MesMutuelles
