import { CSSProperties, forwardRef } from "react"
import * as React from "react"
import PhoneInput from "react-phone-number-input/max"
import fr from "react-phone-number-input/locale/fr.json"
import "react-phone-number-input/style.css"
import { Override } from "@audiowizard/common"
import cx from "classnames"

type InputComponentProps = {
	inputId: string
	name: string
	required: boolean
	inputClassName?: string
	inputStyle?: CSSProperties
}
const InputComponent = forwardRef<HTMLInputElement, InputComponentProps>(function InputComponent(
	{ inputId, name, required, inputClassName, inputStyle, ...props }: InputComponentProps,
	ref
) {
	return (
		<input
			{...props}
			ref={ref}
			className={inputClassName}
			style={inputStyle}
			id={inputId}
			name={name}
			required={required}
		/>
	)
})

type PhoneInputOnlyProps = Override<
	React.ComponentPropsWithoutRef<typeof PhoneInput>,
	{
		name: string
		placeholder?: string
		required?: boolean
		inputId: string
		inputClassName?: string
		inputStyle?: CSSProperties
		international?: boolean
	}
>
export function PhoneInputOnly({
	name,
	placeholder,
	value,
	onChange,
	required = false,
	inputId,
	inputClassName = "form-control",
	inputStyle,
	international = false,
	...props
}: PhoneInputOnlyProps): JSX.Element {
	return (
		<PhoneInput
			limitMaxLength
			defaultCountry="FR"
			labels={fr}
			international={international}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			{...props}
			inputComponent={InputComponent as any} // as any pour éviter des erreurs au niveau des props
			numberInputProps={{ inputId, required, inputClassName, inputStyle }}
			name={name} // name est passé à InputComponent par les props de react-phone-number-input
		/>
	)
}

type CustomPhoneInputProps = Override<
	Omit<PhoneInputOnlyProps, "inputId">,
	{
		id?: string
		label?: string
		error?: string
		className?: string
		style?: CSSProperties

		// For whatever reason, Omit stopped working for PhoneInputOnlyProps. So I have to retype used properties here, fun!
		name: string
		placeholder?: string
		required?: boolean
		inputClassName?: string
		inputStyle?: CSSProperties
		international?: boolean
		value?: string
		onChange: (value?: string) => void
	}
>
function CustomPhoneInput(
	props: CustomPhoneInputProps // Don't deconstruct to keep all props for spread
): JSX.Element {
	const inputId = props.id ?? props.name

	return (
		<div className={cx("form-group custom-phone-input", props.className)} style={props.style}>
			<label htmlFor={inputId}>{props.label || props.placeholder}</label>
			<PhoneInputOnly inputId={inputId} {...props} />
			{props.error && <p className="invalid-feedback d-block">{props.error}</p>}
		</div>
	)
}

export default CustomPhoneInput
