import { Select } from "antd"

import AuthContext from "contexts/AuthContext"
import { useContext } from "react"

const { Option } = Select

interface ScheduleAgendaSelectProp {
	data: any
	setData: (d: any) => void
	placeholder: string
	isDoctolib: boolean
}

const ScheduleAgendaSelect: React.FC<ScheduleAgendaSelectProp> = ({ data, setData, placeholder, isDoctolib }) => {
	const { agendas } = useContext(AuthContext)
	const handleChange = (agendaIri: string): void => {
		const newSelectedAgenda = agendas.find((a) => a["@id"] === agendaIri)
		setData(newSelectedAgenda)
	}

	return (
		<div>
			<Select
				size={"large"}
				disabled={true}
				placeholder={placeholder}
				style={{ width: "100%" }}
				optionFilterProp="children"
				onChange={handleChange}
				value={data ? data["@id"] : 0}>
				<Option key={0} value={0}>
					Sélectionner un agenda
				</Option>
				{agendas.map((agenda) => {
					return (
						<Option key={agenda["@id"]} value={agenda["@id"]}>
							{agenda.label}
							{isDoctolib && (
								<span className="float-right">
									<img
										alt="doctolib_logo"
										src="/static/img/doctolib/D_Doctolib_blue.png"
										style={{ width: 40, height: 40 }}
									/>
								</span>
							)}
						</Option>
					)
				})}
			</Select>
		</div>
	)
}

export default ScheduleAgendaSelect
