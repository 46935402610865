import "./AntFilterValueTag.scss"

interface Props<T> {
	label?: string
	value?: T | T[]
	onRemove?: () => void
}
export default function AntFilterValueTag<T>({ label, value, onRemove }: Props<T>) {
	if (Array.isArray(value) && value.length === 0) return <></>

	return (
		<div className="ant-filter-value-tag" onClick={onRemove}>
			<span className="tag-label">{label} :&nbsp;</span>
			<span className="tag-values">
				{typeof value === "string" && <>{value}</>}
				{Array.isArray(value) && <>{value[0]}</>}
			</span>
			{Array.isArray(value) && value.length > 1 && <span className="more">{`+${value.length - 1}`}</span>}
			<div className="remove">X</div>
		</div>
	)
}
