import {
	Document,
	DocumentRemoteSignature,
	DocumentRemoteSignatureCreateResponse,
	DocumentRemoteSignaturePublicResponse,
	DocumentRemoteSignatureSignatureData,
	DocumentSendMailRequestData,
	DocumentSignQuoteStep,
	User,
} from "@audiowizard/common"
import axios, { CancelTokenSource } from "axios"
import FieldWithError from "components/forms/FieldWithError"
import withRequiredServices, { ServiceRef } from "components/hoc/withRequiredServices"
import useHasRole from "components/Hooks/useHasRole"
import useHasServices from "components/Hooks/useHasServices"
import useMediaQuery from "components/Hooks/useMediaQuery"
import ServiceUpgradeModal from "components/ServiceUpgradeModal"
import Card from "components/utils/Card"
import PdfViewer from "components/utils/PdfViewer"
import SpinnerAW from "components/utils/SpinnerAW"
import { API_URL, DOCUMENT_REMOTE_SIGNATURES_API } from "config"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { Duration } from "dayjs/plugin/duration"
import _ from "lodash"
import { PDFDocument, PDFPage, rgb } from "pdf-lib"
import QRCode from "qrcode.react"
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import SignatureCanvas from "react-signature-canvas"
import { toast } from "react-toastify"
import { Alert, Button, Col, Container, Modal, Row, Spinner } from "reactstrap"
import { ModalBody } from "reactstrap/lib"
import API from "services/API"
import { arrayBufferToBase64, base64ToArrayBuffer, formatCurrency, sleep } from "services/functions"
import { canBeSentByMail, DocumentEmailSelector } from "./SendMailDocument"
import "./SignDocument.scss"

// Sign functions //

const CHECKMARK_SVG_PATH = "M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z"

export type QuoteSignExtra = {
	step: DocumentSignQuoteStep
	offer: "1.1" | "1.2"
	securityDepositAmount: number
	behalfUser: User | null
}

async function signQuoteTrial(
	pdf: PDFDocument,
	offer: QuoteSignExtra["offer"],
	securityDepositAmount: QuoteSignExtra["securityDepositAmount"],
	user: User,
	patientSignaturesPng: string | ArrayBuffer
): Promise<void> {
	const userSignature =
		user.signature != null
			? (await axios.get(API_URL + user.signature, { responseType: "arraybuffer" })).data
			: undefined

	const page = pdf.getPage(1)

	// Case offre
	page.drawSvgPath(CHECKMARK_SVG_PATH, {
		x: offer === "1.1" ? 221 /* case 1.1 */ : 362 /* case 1.2 */,
		y: 552,
		scale: 0.45,
		color: rgb(0, 0, 0),
	})

	// Montant du dépôt de garantie
	const amount = formatCurrency(securityDepositAmount).replaceAll("\u202F", " ") // Non breakable space crashes pdf-lib. Replace it with regular space
	page.drawText(amount, { x: 391, y: 557, size: 10 })

	// Signature audioprothésiste
	if (userSignature != null) {
		drawSignature(
			page,
			{
				x: 473,
				y: 539,
				width: 102,
				height: 20,
			},
			userSignature
		)
	}

	// Signature patient
	drawSignature(
		page,
		{
			x: 473,
			y: 561,
			width: 102,
			height: 20,
		},
		patientSignaturesPng
	)
}

async function signQuoteBilling(
	pdf: PDFDocument,
	offer: "1.1" | "1.2",
	user: User,
	patientSignaturesPng: string | ArrayBuffer
): Promise<void> {
	const userSignature =
		user.signature != null
			? (await axios.get(API_URL + user.signature, { responseType: "arraybuffer" })).data
			: undefined

	const page = pdf.getPage(1)

	// Case offre
	page.drawSvgPath(CHECKMARK_SVG_PATH, {
		x: offer === "1.1" ? 247 /* case 1.1 */ : 399 /* case 1.2 */,
		y: 185,
		scale: 0.45,
		color: rgb(0, 0, 0),
	})

	// Signature audioprothésiste
	if (userSignature != null) {
		drawSignature(
			page,
			{
				x: 470,
				y: 167,
				width: 102,
				height: 20,
			},
			userSignature
		)
	}

	// bon pour accord
	page.drawText("bon pour accord", {
		x: 145,
		y: 132,
		size: 10,
	})
	// date
	page.drawText(dayjs().format("DD/MM/YYYY"), {
		x: 145,
		y: 112,
		size: 10,
	})

	// Signature patient
	drawSignature(
		page,
		{
			x: 227,
			y: 112,
			width: 168,
			height: 28,
		},
		patientSignaturesPng
	)
}

/** Signe un devis normalisé. Ajoute la signature du patient, de l'audioprothésiste, la date et coche l'offre. */
export async function signQuote(
	pdf: PDFDocument,
	extra: QuoteSignExtra,
	user: User,
	patientSignaturesPng: string | ArrayBuffer
): Promise<void> {
	if (extra.step === "TRIAL") {
		await signQuoteTrial(pdf, extra.offer, extra.securityDepositAmount, user, patientSignaturesPng)
	} else {
		await signQuoteBilling(pdf, extra.offer, user, patientSignaturesPng)
	}
}

/** Signe une feuille de pret. Ajoute la signature du patient et de l'audioprothésiste */
export async function signFeuillePret(
	pdf: PDFDocument,
	user: User,
	patientSignaturesPng: string | ArrayBuffer
): Promise<void> {
	const userSignature =
		user.signature != null
			? (await axios.get(API_URL + user.signature, { responseType: "arraybuffer" })).data
			: undefined

	const page = pdf.getPage(0)

	// Signature audioprothésiste
	if (userSignature != null) {
		drawSignature(
			page,
			{
				x: 85,
				y: 100,
				width: 200,
				height: 70,
			},
			userSignature
		)
	}

	// Signature patient
	drawSignature(
		page,
		{
			x: 308,
			y: 100,
			width: 200,
			height: 70,
		},
		patientSignaturesPng
	)
}

async function drawSignature(
	page: PDFPage,
	rect: {
		x: number
		y: number
		width: number
		height: number
	},
	signaturePng: string | ArrayBuffer
): Promise<void> {
	const image = await page.doc.embedPng(signaturePng)

	const { width, height } = image.scaleToFit(rect.width, rect.height)
	page.drawImage(image, { x: rect.x, y: rect.y, width, height })
}

/**
 * @returns Si le document peut-être signé ou non. C'est basé sur le type de document de signatureBoxes, si le document à déjà une signature ou non et si le document est associé à un patient.
 */
export function canBeSigned(document: Document): boolean {
	if (document.type === "PRET" && !document.hasSignature) return true
	// Can only sign quote if at least one step isn't already signed
	if (
		document.type === "DEVIS" &&
		(document.hasSignatureQuoteSteps == null || document.hasSignatureQuoteSteps.length < 2)
	)
		return true

	return false
}

// Components //

type QuoteSignExtraFieldsProps = {
	quote?: any
	disabledSteps?: DocumentSignQuoteStep[]
	value: QuoteSignExtra
	onChange: (value: QuoteSignExtra) => void
}
export function QuoteSignExtraFields({
	quote,
	disabledSteps = [],
	value,
	onChange,
}: QuoteSignExtraFieldsProps): JSX.Element {
	const totalCostClass1 = useMemo(() => {
		if (quote == null) return 0

		const totalAccesories = ((): number => {
			if (quote.accessoriesClasse1 == null) return 0

			const sum = _.sumBy(
				quote.accessoriesClasse1,
				(accessory: { customPrice?: number; product_price: number | string }) =>
					Number(accessory.customPrice ?? accessory.product_price)
			)
			return _.round(sum, 2)
		})()

		return _.round(totalAccesories + (quote.costRightClass1 ?? 0) + (quote.costLeftClass1 ?? 0), 2)
	}, [quote])

	const totalCostClass2 = useMemo(() => {
		if (quote == null) return 0

		const totalAccesories = ((): number => {
			if (quote.accessoriesClasse2 == null) return 0

			const sum = _.sumBy(
				quote.accessoriesClasse2,
				(accessory: { customPrice?: number; product_price: number | string; discount?: number | string }) =>
					Number(accessory.customPrice ?? accessory.product_price) - Number(accessory.discount ?? 0)
			)
			return _.round(sum, 2)
		})()

		return _.round(
			totalAccesories +
				((quote.costLeftClass2 ?? 0) - (quote.discountedCostLeftClass2 ?? 0)) +
				((quote.costRightClass2 ?? 0) - (quote.discountedCostRightClass2 ?? 0)),
			2
		)
	}, [quote])

	// Update securityDepositAmount when offer changes
	useEffect(() => {
		if (value.offer === "1.1") {
			onChange({ ...value, securityDepositAmount: totalCostClass1 })
		} else {
			onChange({ ...value, securityDepositAmount: totalCostClass2 })
		}
	}, [value.offer, totalCostClass1, totalCostClass2])

	// Uncheck if step is disabled
	useEffect(() => {
		if (disabledSteps.includes(value.step)) {
			onChange({ ...value, step: value.step === "TRIAL" ? "BILLING" : "TRIAL" })
		}
	}, [value.step, disabledSteps])

	return (
		<section className="quote-extra-fields p-2">
			<fieldset role="radiogroup">
				<Button
					role="radio"
					aria-checked={value.step === "TRIAL"}
					className="step-trial"
					color={value.step === "TRIAL" ? "primary" : "outline-primary"}
					disabled={disabledSteps.includes("TRIAL")}
					onClick={() => onChange({ ...value, step: "TRIAL" })}>
					Essai
				</Button>

				<Button
					role="radio"
					aria-checked={value.step === "BILLING"}
					className="step-billing"
					color={value.step === "BILLING" ? "primary" : "outline-primary"}
					disabled={disabledSteps.includes("BILLING")}
					onClick={() => onChange({ ...value, step: "BILLING" })}>
					Facturation
				</Button>
			</fieldset>

			<fieldset role="radiogroup">
				<Button
					role="radio"
					aria-checked={value.offer === "1.1"}
					className="offer-1\.1"
					color={value.offer === "1.1" ? "info" : "outline-info"}
					onClick={() => onChange({ ...value, offer: "1.1" })}>
					Offre 100% Santé (1.1)
				</Button>

				<Button
					role="radio"
					aria-checked={value.offer === "1.2"}
					className="offer-1\.2"
					color={value.offer === "1.2" ? "info" : "outline-info"}
					onClick={() => onChange({ ...value, offer: "1.2" })}>
					Autre offre (1.2)
				</Button>
			</fieldset>

			{value.step === "TRIAL" && (
				<FieldWithError
					className="security-deposit-amount mx-auto" // Override m-2
					label="Montant du dépôt de garantie pour le matériel confié pendant les essais"
					name="securityDepositAmount"
					type="number"
					min={0}
					value={value.securityDepositAmount}
					onChange={(e) => onChange({ ...value, securityDepositAmount: e.target.valueAsNumber })}
				/>
			)}
		</section>
	)
}

type SignatureInputProps = {
	loading?: boolean
	onConfirm: (signaturePngDataUrl: string) => void
}
/**
 * Canvas de signature responsive.
 */
function SignatureInput({ loading = false, onConfirm }: SignatureInputProps): JSX.Element {
	const [isEmpty, setIsEmpty] = useState(true)
	const signRef = useRef<SignatureCanvas | null>()

	const clear = (): void => {
		signRef.current?.clear()
		setIsEmpty(true)
	}

	/**
	 * Redimension du canvas en fonction de l'écran
	 * Il faut modifier la valeur `width` du canvas pour ne pas casser la librairie de dessin
	 */
	const resizeCanvas = useCallback((): void => {
		if (signRef.current == null) return

		const signData = signRef.current.toData()

		const canvas = signRef.current.getCanvas()

		// Récupère la largeur calculé depuis le css
		const canvasStyle = window.getComputedStyle(canvas)
		const widthPx = canvasStyle.getPropertyValue("width")
		const heightPx = canvasStyle.getPropertyValue("height")
		// Enlève le "px" pour n'avoir que le nombre
		const newWidth = Number(widthPx.substring(0, widthPx.length - 2))
		const newHeight = Number(heightPx.substring(0, heightPx.length - 2))

		canvas.width = newWidth
		canvas.height = newHeight

		signRef.current.clear() // Clear pour bien redimensionner le canvas depuis la lib
		signRef.current.fromData(signData)
	}, [signRef.current])

	useLayoutEffect(() => {
		resizeCanvas()
	}, [resizeCanvas])

	useLayoutEffect(() => {
		window.addEventListener("resize", resizeCanvas)

		return () => {
			window.removeEventListener("resize", resizeCanvas)
		}
	}, [resizeCanvas])

	return (
		<div className="d-flex flex-column align-items-center">
			<span id="signature-canvas-help">Champ de signature</span>
			<SignatureCanvas
				ref={(ref) => {
					signRef.current = ref
				}}
				canvasProps={{
					"aria-describedby": "signature-canvas-help",
					className: "border border-primary my-2",

					style: {
						aspectRatio: "71 / 14",
						width: "min(710px, 100vw)",
					},
					width: " 710px",
					height: "140px",
				}}
				onBegin={() => setIsEmpty(false)}
			/>
			<div className="d-flex justify-content-center my-1">
				<button
					className="btn btn-danger btn-sm"
					title="Effacer la signature"
					disabled={isEmpty || loading}
					onClick={clear}>
					<i className="fad fa-eraser" />
				</button>

				<button
					className="btn btn-primary btn-sm"
					title="Valider la signature"
					disabled={isEmpty || loading}
					onClick={() => onConfirm(signRef.current!.toDataURL("png"))}>
					{loading ? (
						<Spinner color="inherit" size="sm" />
					) : (
						// Doit être envelopper pour ne pas planter react quand on enlève l'icone font-awesome
						<span>
							<i className="fad fa-check" />
						</span>
					)}
				</button>
			</div>
		</div>
	)
}

// SignZones

type SignZoneLocalProps = {
	onSign: (signaturePngDataUrl: string) => void
}
/**
 * Zone pour signer directement dans l'interface.
 */
function SignZoneLocal({ onSign }: SignZoneLocalProps): JSX.Element {
	return <SignatureInput onConfirm={onSign} />
}

type SignZoneRemoteProps = {
	document?: Document
	onSign: (signaturePngDataUrl: string) => void
}
/**
 * Zone pour signer indirectement depuis un appareil externe.
 * Un QR code est affiché avec un lien publique pour signer.
 * Utilise l'entité DocumentRemoteSignature pour communiquer entre le navigateur et l'appareil externe.
 */
function SignZoneRemote({ document, onSign }: SignZoneRemoteProps): JSX.Element {
	const [token, setToken] = useState<string>()
	const [expiresAt, setExpiresAt] = useState<dayjs.Dayjs>()
	const [expiresDuration, setExpiresDuration] = useState<Duration>()
	const [wrappingKeys, setWrappingKeys] = useState<CryptoKeyPair>()
	const [loading, setLoading] = useState(false)
	const cancelTokenSourceRef = useRef<CancelTokenSource>()

	const createDocumentRemoteSignature = useCallback(async (): Promise<void> => {
		try {
			setLoading(true)

			const keys = await window.crypto.subtle.generateKey(
				{
					name: "RSA-OAEP",
					modulusLength: 4096,
					publicExponent: new Uint8Array([1, 0, 1]),
					hash: "SHA-256",
				},
				true,
				["wrapKey", "unwrapKey"]
			)
			setWrappingKeys(keys)
			const wrappingJwk = await window.crypto.subtle.exportKey("jwk", keys.publicKey!)

			const data: any = { wrappingJwk }
			if (document != null) {
				data["document"] = document["@id"]
			}
			const res = await API.create<DocumentRemoteSignatureCreateResponse>("DOCUMENT_REMOTE_SIGNATURES_API", data)
			setToken(res.data.token)
			setExpiresAt(dayjs(res.data.expiresAt))

			setLoading(false)
		} catch (err) {
			console.error(err)
			toast.error("Erreur lors de la création du lien de signature pour mobile")
		}
	}, [])

	useEffect(() => {
		createDocumentRemoteSignature()
	}, [createDocumentRemoteSignature])

	useEffect(() => {
		if (token == null) return

		// Requêtes periodiques pour voir si la signature a été envoyé sur la DB
		const checkHasSignature = async (): Promise<void> => {
			let drs: DocumentRemoteSignature = {
				"@id": "",
				wrappedSignatureJwkBase64: null,
				signatureIvBase64: null,
				signaturePngDataUrl: null,
			}

			cancelTokenSourceRef.current = axios.CancelToken.source()
			try {
				// Boucle + sleep à la place de setInterval car setInterval ne peut pas await
				while (
					drs.wrappedSignatureJwkBase64 == null ||
					drs.signatureIvBase64 == null ||
					drs.signaturePngDataUrl == null
				) {
					await sleep(5000) // 5 secondes

					drs = await API.find<DocumentRemoteSignature>("DOCUMENT_REMOTE_SIGNATURES_API", token, {
						cancelToken: cancelTokenSourceRef.current.token,
					})
				}

				// drs.wrappedSignatureJwkBase64 a été encrypté (wrapped) avec la clé public de wrappingKeys. Il faut utiliser la clé privé pour la décrypter.
				const signatureKey = await window.crypto.subtle.unwrapKey(
					"jwk",
					base64ToArrayBuffer(drs.wrappedSignatureJwkBase64),
					wrappingKeys!.privateKey!,
					{
						name: "RSA-OAEP",
					},
					{ name: "AES-GCM" },
					false,
					["decrypt"]
				)

				// On décrypte la signature, et on décode l'ArrayBuffer en string
				const endcodedSignaturePngDataUrl = await window.crypto.subtle.decrypt(
					{ name: "AES-GCM", iv: base64ToArrayBuffer(drs.signatureIvBase64) },
					signatureKey,
					base64ToArrayBuffer(drs.signaturePngDataUrl)
				)
				const signaturePngDataUrl = new TextDecoder().decode(endcodedSignaturePngDataUrl)

				await API.delete(drs["@id"])
				onSign(signaturePngDataUrl)
			} catch (err) {
				// Ignore les erreurs
				console.error(err)
			}
		}
		checkHasSignature()

		return () => {
			cancelTokenSourceRef.current?.cancel("SignZoneRemote-checkHasSignature-cancel")
		}
	}, [token, wrappingKeys, onSign])

	useEffect(() => {
		if (expiresAt == null) return

		const interval = setInterval(() => {
			setExpiresDuration(dayjs.duration(expiresAt.diff(dayjs())))
		}, 1000)
		setExpiresDuration(dayjs.duration(expiresAt.diff(dayjs())))

		return () => {
			clearInterval(interval)
		}
	}, [expiresAt])

	if (loading) return <Spinner color="inherit" />

	const url = `${window.location.protocol}//${window.location.host}/documents/signer/remote/${token}`
	const isExpired = expiresDuration != null && expiresDuration.asMilliseconds() <= 0

	return (
		<>
			{isExpired ? (
				<>
					<p className="text-danger font-weight-bold">Le lien est expiré.</p>
					<Button color="primary" onClick={createDocumentRemoteSignature}>
						Générer un nouveau lien
					</Button>
				</>
			) : (
				<>
					<p>Scanner le QR code avec votre appareil mobile. Vous pouvez aussi y accédez depuis le lien.</p>
					<p>
						Le lien expire dans <strong>{expiresDuration?.format("mm:ss")}</strong>.
					</p>
					<p>
						Les données de la signature sont chiffrées de bout en bout. <i className="fad fa-lock" />
					</p>
					<QRCode value={url} />
					<a href={url} target="_blank" rel="noopener noreferrer" className="text-break">
						{url}
					</a>
				</>
			)}
		</>
	)
}

enum SignZoneType {
	/** Signature directement sur la page */
	Local,
	/** Signature via appareil mobile. */
	Remote,
}

type SignZoneProps = {
	document?: Document
	onSign: (signaturePngDataUrl: string) => void
}
/**
 * Avec un selecteur de zone de signature.
 */
function SignZone({ document, onSign }: SignZoneProps): JSX.Element {
	const [signZoneType, setSignZoneType] = useState<SignZoneType>(SignZoneType.Remote)

	return (
		<div className="d-flex flex-column align-items-center">
			<Row className="m-2">
				<Col md={6}>
					<Button
						aria-expanded={signZoneType === SignZoneType.Local}
						color={signZoneType === SignZoneType.Local ? "primary" : "outline-primary"}
						onClick={() => setSignZoneType(SignZoneType.Local)}>
						Signer sur PC <i className="fad fa-desktop" />
					</Button>
				</Col>
				<Col md={6}>
					<Button
						aria-expanded={signZoneType === SignZoneType.Remote}
						className="text-nowrap"
						color={signZoneType === SignZoneType.Remote ? "primary" : "outline-primary"}
						onClick={() => setSignZoneType(SignZoneType.Remote)}>
						Signer sur Mobile/Tablette <i className="fad fa-mobile" />
					</Button>
				</Col>
			</Row>

			{signZoneType === SignZoneType.Local ? (
				<SignZoneLocal onSign={onSign} />
			) : (
				<SignZoneRemote document={document} onSign={onSign} />
			)}
		</div>
	)
}

// Pages + Modal

type SignModalProps = React.PropsWithChildren<{
	/** Affiche une Alert danger si l'utilistaeur n'a pas de signature */
	showNoUserSignatureAlert?: boolean
	open: boolean
	onClose: () => void
	onSign: (signaturePngDataUrl: string) => void
	document?: Document
}>
/**
 * Modal avec champ de signature. Permet d'obtenir une signature qu'on peut rajouter sur un pdf non enregistré en DB.
 */
export function SignModal({
	showNoUserSignatureAlert = true,
	open,
	onClose,
	onSign,
	children,
	document,
}: SignModalProps): JSX.Element {
	const { user, services } = useContext(AuthContext)
	const history = useHistory()
	const isManager = useHasRole("ROLE_MANAGER")
	const hasSignatureService = useHasServices(ServiceRef.Signature)

	useEffect(() => {
		// Ferme la modal si on n'a pas l'abonnement requis
		if (open && !hasSignatureService && !isManager) {
			toast.error("Il vous manque le service Signature afin de pouvoir signer des documents depuis l'interface.")
			requestAnimationFrame(onClose) // Dans le render suivant sinon la page freeze
		}
	}, [open, hasSignatureService, onClose])

	const servicePrice = useMemo(() => {
		if (hasSignatureService || !isManager) return
		const service = services.find((s) => s.ref === ServiceRef.Signature)!
		return service?.companySubscription?.price ?? service?.defaultPrice ?? NaN
	}, [])

	if (!hasSignatureService && isManager) {
		return (
			<ServiceUpgradeModal
				isOpen={open}
				buttons={{
					primary: {
						text: "Oui, continuer avec la signature",
						action: () =>
							history.push("/mon-compte/mon-abonnement", {
								openSummaryName: ServiceRef.Signature,
							}),
					},
					outlined: {
						text: "Non merci",
						action: onClose,
					},
				}}
				description="Débloquez les signatures de document directement depuis l'application."
				icon="file-signature"
				price={`${formatCurrency(servicePrice!)} / mois par laboratoire`}
			/>
		)
	}
	return (
		<Modal isOpen={open} toggle={onClose} size="lg" centered>
			<ModalBody className="d-flex flex-column align-items-center">
				{showNoUserSignatureAlert && user.signature == null && (
					<Alert color="danger">
						Vous n'avez pas de signature. Vous pouvez la rajouter sur la page{" "}
						<Link to="/mon-compte/mes-informations">Mes Informations</Link>.
					</Alert>
				)}

				{children}

				<SignZone
					document={document}
					onSign={(signaturePngDataUrl) => {
						onSign(signaturePngDataUrl)
						onClose()
					}}
				/>
			</ModalBody>
		</Modal>
	)
}

/**
 * Page pour signer un document avec le choix de SignZone.
 * Se base sur l'id du document depuis l'url.
 */
function SignDocumentPageBase(): JSX.Element {
	const { id } = useParams<{ id: string }>()
	const history = useHistory()
	const { user, patient } = useContext(AuthContext)

	const [document, setDocument] = useState<Document>()
	const [pdfArrayBuffer, setPdfArrayBuffer] = useState<ArrayBuffer>()
	const [pdfBase64, setPdfBase64] = useState<string>()
	const [loadingText, setLoadingText] = useState<string | null>(null)

	const [sendMailValue, setSendMailValue] = useState<DocumentSendMailRequestData>({
		sendToPatient: true,
		sendToAttendant: false,
	})
	// Pour le devis normalisé
	const [quoteSignExtra, setQuoteSignExtra] = useState<QuoteSignExtra>({
		step: "TRIAL",
		offer: "1.1",
		securityDepositAmount: 0,
		behalfUser: null,
	})
	const quoteSignExtraRef = useRef<QuoteSignExtra>(quoteSignExtra) // Copy into ref, to avoid stale data issues when called from checkHasSignature
	useEffect(() => {
		quoteSignExtraRef.current = quoteSignExtra
	}, [quoteSignExtra])

	// Fetch le document
	useEffect(() => {
		const fetchDocument = async (): Promise<void> => {
			setLoadingText("Chargement du document...")
			try {
				const document = await API.find<Document>("DOCUMENTS_API", Number(id))
				setDocument(document)

				setQuoteSignExtra({
					...quoteSignExtra,
					behalfUser: document.config?.user ?? patient.mainUser ?? user,
				})

				setLoadingText(null)
			} catch (err) {
				console.error(err)

				toast.error("Impossible de récupérer le document")
			}
		}

		fetchDocument()
	}, [id])

	// Fetch les données du PDF
	useEffect(() => {
		const fetchPdfArrayBuffer = async (): Promise<void> => {
			if (document?.signature == null) return

			setLoadingText("Chargement du pdf...")
			try {
				const documentArrayBuffer = await fetch(document.signature!).then((res) => res.arrayBuffer())
				setPdfArrayBuffer(documentArrayBuffer)

				setLoadingText(null)
			} catch (err) {
				console.error(err)

				toast.error("Impossible de récupérer le pdf")
			}
		}

		fetchPdfArrayBuffer()
	}, [document?.signature])

	// Lis les données du PDF en base64
	useEffect(() => {
		if (pdfArrayBuffer == null) return

		const reader = new FileReader()
		reader.onload = () => {
			setPdfBase64(reader.result as string)
		}
		reader.readAsDataURL(new Blob([pdfArrayBuffer], { type: "application/pdf" }))
	}, [pdfArrayBuffer])

	const addSignatureAndCreateNewDocument = async (patientSignature: string): Promise<void> => {
		if (document == null || pdfArrayBuffer == null) return

		setLoadingText("Génération du pdf signé...")

		try {
			const filename = `${document.filename}-signé`
			const label = `Signé : ${document.label}`

			const pdf = await PDFDocument.load(pdfArrayBuffer)

			// Ajout de la signature en fonction du type de document
			if (document.type === "DEVIS") await signQuote(pdf, quoteSignExtraRef.current, user, patientSignature)
			else if (document.type === "PRET") await signFeuillePret(pdf, user, patientSignature)
			else throw new Error("Impossible de signer ce type de document")

			// Création du File
			const pdfBytes = await pdf.save()
			const file = new File([pdfBytes], `${filename}.pdf`, {
				type: "application/pdf",
			})

			// Upload du nouveau document
			const signedDocument: Record<string, unknown> = {
				...document,
				patient: document.patient?.["@id"] /* Patient -> Iri */,
				filename,
				label,
				file,
				hasSignature: true,
			}

			if (document.type === "DEVIS") {
				// Add new step to previous hasSignatureQuoteSteps
				signedDocument.hasSignatureQuoteSteps = [
					...(document.hasSignatureQuoteSteps ?? []),
					quoteSignExtra.step,
				]
			}
			// expects an iri, not an object. Relation is OneToOne, so can't have two full quotes with the same simplified quote
			signedDocument.simplifiedQuote = null

			setLoadingText("Envoie du nouveau document signé...")
			const res = await API.createDocument<Document>(signedDocument)
			toast.success(`Document signé avec succèss ! (${label})`)

			// Envoie du document par email
			const signedDocumentId = res.data.id!

			// Au moins un coché
			if (sendMailValue.sendToPatient || sendMailValue.sendToAttendant) {
				setLoadingText("Envoie du/des email(s)...")
				await API.documentSendMail(signedDocumentId, sendMailValue)
				toast.success("Email(s) envoyé(s) avec succès")
			}

			history.goBack()
		} catch (err) {
			console.error(err)
			toast.error("Erreur lors de la signature du document")
		} finally {
			setLoadingText(null)
		}
	}

	if (loadingText != null) return <SpinnerAW text={loadingText} />

	if (document == null || pdfArrayBuffer == null || pdfBase64 == null) return <></>

	return (
		<Card header="Signer un document" icon="signature" isFullHeight>
			<div className="d-flex flex-column align-items-center">
				<h1 className="h3">{document.label}</h1>
				<PdfViewer
					src={pdfBase64}
					// A4
					width={210 * (96.0 / 25.4)} // 210mm
					height={296 * (96.0 / 25.4)} // 296mm
				/>
				{canBeSentByMail(document) && (
					<DocumentEmailSelector
						patient={document.patient!}
						value={sendMailValue}
						onChange={setSendMailValue}
						onPatientChange={(patient) => setDocument({ ...document, patient })}
					/>
				)}

				{user.signature == null && (
					<Alert color="danger">
						Vous n'avez pas de signature. Vous pouvez la rajouter sur la page{" "}
						<Link to="/mon-compte/mes-informations">Mes Informations</Link>.
					</Alert>
				)}

				{document.type === "DEVIS" && (
					<QuoteSignExtraFields
						quote={document.config}
						disabledSteps={document.hasSignatureQuoteSteps ?? undefined}
						value={quoteSignExtra}
						onChange={setQuoteSignExtra}
					/>
				)}

				<SignZone document={document} onSign={addSignatureAndCreateNewDocument} />
			</div>
		</Card>
	)
}
export const SignDocumentPage = withRequiredServices(SignDocumentPageBase)(ServiceRef.Signature)

/**
 * Page publique responsive pour dessiner et envoyé une signature depuis un appareil externe.
 * Se base sur le token du document depuis l'url.
 */
export function SignDocumentRemotePage(): JSX.Element {
	const { token } = useParams<{ token: string }>()
	const [label, setLabel] = useState<string | null>(null)
	const [pdfUrl, setPdfUrl] = useState<string | null>(null)
	const [wrappingKey, setWrappingKey] = useState<CryptoKey>()
	const [fetchLoading, setFetchLoading] = useState(false) // Chargement document
	const [sendLoading, setSendLoading] = useState(false) // Chargement envoie de la signature
	const [sent, setSent] = useState(false) // Signature envoyé
	const [error, setError] = useState<string | undefined>()

	const portrait = useMediaQuery("(orientation: portrait)")

	useEffect(() => {
		const fetchDocumentRemoteSignature = async (): Promise<void> => {
			try {
				setFetchLoading(true)
				// axios manuellement car on n'est pas authentifié et API n'est pas initialisé correctement
				const res = await axios.get<DocumentRemoteSignaturePublicResponse>(
					`${DOCUMENT_REMOTE_SIGNATURES_API}/${token}/public`
				)

				setLabel(res.data.label)
				setPdfUrl(res.data.pdfUrl)
				setWrappingKey(
					await window.crypto.subtle.importKey(
						"jwk",
						res.data.wrappingJwk,
						{ name: "RSA-OAEP", hash: "SHA-256" },
						false,
						["wrapKey"]
					)
				)
			} catch (err) {
				console.error(err)
				setError("Impossible de récupérer le document. Le lien de signature est peut-être expiré.")
			} finally {
				setFetchLoading(false)
			}
		}

		fetchDocumentRemoteSignature()
	}, [token])

	const sendSignature = async (signaturePngDataUrl: string): Promise<void> => {
		try {
			setSendLoading(true)

			// signatureKey est la clé utilisé pour encrypté la signature.
			const signatureKey = await window.crypto.subtle.generateKey(
				{
					name: "AES-GCM",
					length: 256,
				},
				true,
				["encrypt", "decrypt"]
			)

			const iv = window.crypto.getRandomValues(new Uint8Array(12))
			const encryptedSignature = await window.crypto.subtle.encrypt(
				{
					name: "AES-GCM",
					iv,
				},
				signatureKey,
				new TextEncoder().encode(signaturePngDataUrl)
			)

			// signatureKey est encrypté (wrapped) avec la clé public wrappingKey.
			const wrappedSignatureJwk = await window.crypto.subtle.wrapKey("jwk", signatureKey, wrappingKey!, {
				name: "RSA-OAEP",
			})

			const data: DocumentRemoteSignatureSignatureData = {
				wrappedSignatureJwkBase64: arrayBufferToBase64(wrappedSignatureJwk),
				signatureIvBase64: arrayBufferToBase64(iv),
				signaturePngDataUrl: arrayBufferToBase64(encryptedSignature),
			}

			// axios manuellement car on n'est pas authentifié et API n'est pas initialisé correctement
			await axios.put(`${DOCUMENT_REMOTE_SIGNATURES_API}/${token}/signature`, data)
			setSent(true)
		} catch (err) {
			console.error(err)
			setError("Impossible d'envoyer la signature. Le lien de signature est peut-être expiré.")
		} finally {
			setSendLoading(false)
		}
	}

	if (fetchLoading) {
		return (
			<div className="d-flex justify-content-center align-items-center vh-100">
				<Spinner color="inherit" size="lg" />
			</div>
		)
	}

	if (error != null) {
		return (
			<div className="d-flex justify-content-center align-items-center vh-100">
				<Alert color="danger">{error}</Alert>
			</div>
		)
	}

	if (sent) {
		return (
			<div className="d-flex justify-content-center align-items-center vh-100">
				<Alert color="primary">Signature envoyé à l'application. Vous pouvez fermer cette onglet.</Alert>
			</div>
		)
	}

	return (
		<Container className="d-flex flex-column align-items-center p-2">
			{portrait && (
				<Alert color="warning" className="text-center">
					<p>Il est recommandé d'orienter votre appareil en mode paysage.</p>
					<p>
						<i className="mx-2 fad fa-2x fa-mobile " />
						<i className="mx-2 fad fa-2x fa-undo fa-flip-horizontal " />
						<i className="mx-2 fad fa-2x fa-mobile fa-rotate-90 " />
					</p>
				</Alert>
			)}

			{label != null && <h1 className="h3 text-center">{label}</h1>}
			{pdfUrl != null && (
				<div
					className="embed-responsive embed-responsive-1by1"
					style={{
						maxWidth: "210mm", // A4
					}}>
					<PdfViewer className="embed-responsive-item" src={pdfUrl} />
				</div>
			)}
			<SignatureInput loading={sendLoading} onConfirm={sendSignature} />
		</Container>
	)
}
