/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"

import moment from "moment"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Modal as ErrorModal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import Modal from "../../components/effects/ReactModal"
import AsyncUserSelect from "../../components/utils/AsyncUserSelect"
import AuthContext from "../../contexts/AuthContext"
import { refundPaymentMethods } from "../../pages/vente/_Utility"
import API from "../../services/API"
import API_Hiboutik from "../../services/API_Hiboutik"

import { rounded } from "../../services/functions"
import ModalDivPayments from "../vente/Modal.DivPayment"

import axios from "axios"
import cx from "classnames"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { PAYMENTS_API } from "config"
import { findActiveAMx, getTpStatus, urlTeletransmission } from "pages/FSV/utilsFSV"
import { saleCancel } from "pages/vente/cancelSale"
import ReactTooltip from "react-tooltip"
import ModalTeletransPayment from "../vente/Modal.TeletransPayment"
// import { createJson } from "services/scriptFactures"
import useHasRole from "components/Hooks/useHasRole"
import { amcLabels } from "pages/etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"
import { createJson } from "services/scriptFactures"
import { useFetchPaymentType } from "pages/Settings/paymentTypes/PaymentTypes.helpers"
import _ from "lodash"
import { useQueryClient } from "react-query"
import { domTomLabsIds } from "datas/teletrans"
import { useGetIsPregnant } from "pages/vente/teletransPaymentUtils"

const ForcePaymentRefresh = ({ saleId }) => {
	const [busy, setBusy] = useState(false)

	const handleRefresh = async (event) => {
		try {
			setBusy(true)
			event.preventDefault()
			await axios.post(`${PAYMENTS_API}/force_sync_with_sale`, {
				saleId,
			})
			toast.success("Le cache des paiements à été vidé avec succès")
		} catch (error) {
			console.error(error?.response?.data?.["hydra:description"])
			let errStr = "Impossible de mettre à jour la liste des paiements"

			if (error?.response?.data?.["hydra:description"]) {
				errStr += ` : ${error?.response?.data?.["hydra:description"]}`
			}

			toast.error(errStr)
		} finally {
			setBusy(false)
		}
	}

	return (
		<div>
			<ReactTooltip place="bottom" type="dark" effect="solid" />

			<a
				disabled={busy}
				href="#void"
				data-tip="Forcer la mise à jour des paiements s'ils n'apparaissent pas dans la liste des paiements reçu / en attente"
				className="text-sm text-primary"
				onClick={handleRefresh}>
				{busy ? "- Mise à jour des paiements en cours..." : "- Forcer la mise à jour des paiements"}
			</a>
		</div>
	)
}

const ErrorModalTeletrans = ({ error, setError }) => {
	return (
		<ErrorModal isOpen={error?.message ? true : false} className="error-modal" backdrop="static">
			<ModalHeader>{error?.title ? error.title : "Une erreur est survenue"}</ModalHeader>
			<ModalBody>
				{error?.message}
				<br />
				<br />
				{error?.codes?.map((v, k) => (
					<React.Fragment key={k}>
						<pre>{v}</pre>
					</React.Fragment>
				))}
			</ModalBody>
			<ModalFooter>
				{error?.customButton ? (
					<>
						<Button
							outline
							color="primary"
							onClick={async () => await error?.customButton?.action?.()}
							disabled={error?.customButton?.disabled}>
							{error?.customButton?.label}
						</Button>
						{!error?.hideOkButton && (
							<Button color="warning" onClick={() => setError(null)} disabled={error?.isDisabled}>
								OK
							</Button>
						)}
					</>
				) : (
					<Button color="warning" onClick={() => setError(null)} disabled={error?.isDisabled}>
						OK
					</Button>
				)}
				{error?.customButton2 && (
					<>
						<Button
							outline
							color="primary"
							disabled={error?.customButton2?.disabled}
							onClick={async () => await error?.customButton2?.action?.()}>
							{error?.customButton2?.label}
						</Button>
					</>
				)}
			</ModalFooter>
		</ErrorModal>
	)
}

const ModalChangeSeller = ({ document, modal, setModal, onClose }) => {
	const [busy, setBusy] = useState(false)
	const [selected, setSelected] = useState({})

	return (
		<Modal
			isOpen={modal}
			centered
			size="md"
			backdrop="static"
			title="Changer le vendeur"
			onClose={() => {
				setModal(false)
			}}>
			<AsyncUserSelect className="w-100" value={document?.seller || document?.user?.[0]} setData={setSelected} />

			<div className="d-flex flex-row justify-content-center align-items-center mt-4">
				<button
					type="button"
					className="btn flex-grow-1 btn-outline-danger"
					disabled={busy}
					onClick={() => {
						setModal(false)
					}}>
					Annuler
				</button>
				<button
					type="button"
					className="btn flex-grow-1 btn-primary"
					disabled={!selected?.id || busy}
					onClick={async () => {
						try {
							setBusy(true)
							await API.update("DOCUMENTS_API", document.id, {
								seller: `/users/${selected.id}`,
							})
							setSelected({})
							setModal(false)
							onClose(true)
						} catch (error) {
							console.error(error)
						} finally {
							setBusy(false)
						}
					}}>
					Enregistrer
				</button>
			</div>
		</Modal>
	)
}

const DisplayTeletransButtons = ({
	document,
	teletransActive,
	fseToDelete,
	setFseToDelete,
	socialSecurity,
	setModalTeletransPayment,
	setTeletransData,
	manualFseNumber,
	setNeedRefresh,
}) => {
	const [advices, setAdvices] = useState({})
	const [activeAMx, setActiveAMx] = useState({ amo: null, amc: null })
	const [loading, setLoading] = useState(true)

	const payments = document.config.ventes.payment_details
	const isTiersPayant = useMemo(() => payments.some((p) => p.payment_type === "SECU"), [payments])
	const isPregnant = useGetIsPregnant(document?.config?.patient?.full)

	useEffectAsync(async () => {
		try {
			if (!teletransActive && document?.config?.idFse && !fseToDelete) {
				setFseToDelete(true)
				return
			}
			if (!teletransActive) return
			setLoading(true)
			const res = await API.find("PATIENTS_API", document?.patient?.id)
			const amx = await findActiveAMx(res, false)
			setActiveAMx(amx)
			setAdvices([...(res?.advices || []), ...(document?.config?.patient?.full?.advices || [])])
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}, [document, teletransActive, socialSecurity, fseToDelete])

	if (manualFseNumber)
		return (
			<>
				<div className="d-flex flex-row justify-content-center align-items-center mt-4">
					<button
						type="button"
						className="btn flex-grow-1 w-100 btn-primary"
						onClick={async () => {
							await API.update("DOCUMENTS_API", document.id, {
								edit: {
									idFse: manualFseNumber,
								},
							})
							setNeedRefresh(true)
						}}>
						Associer la fse {manualFseNumber} à cette vente
					</button>
				</div>
			</>
		)

	// If teletrans is not active and no fse are available to delete, don't show any buttons or informations
	if (!teletransActive && !fseToDelete) return <></>

	if (loading)
		return <div className="alert alert-warning">Vérification de l'éligibilité à la télétransmission...</div>

	if (!fseToDelete && (!advices.length || !socialSecurity?.length || !activeAMx.amo)) {
		return (
			<div className="alert alert-warning mt-4">
				<strong>Télétransmission impossible pour les raisons suivantes : </strong>
				{!socialSecurity?.length && <div>- Couverture AMO manquante</div>}
				{!advices.length && <div>- Prescription manquante</div>}
				{!activeAMx.amo && <div>- Aucune couverture SS valide</div>}
			</div>
		)
	}

	if (fseToDelete) return <></>

	if (!isTiersPayant && !isPregnant)
		return (
			<>
				<div className="d-flex flex-row justify-content-center align-items-center mt-4">
					<button
						type="button"
						className="btn flex-grow-1 w-100 btn-info"
						onClick={() => {
							setTeletransData({ secu: true, secuTierPayant: false, disabledSecu: true })
							setModalTeletransPayment(true)
						}}>
						Télétransmettre la part sécu en mode hors tiers-payant
					</button>
				</div>
			</>
		)
	if (!loading)
		return (
			<>
				<div className="d-flex flex-row justify-content-center align-items-center mt-4">
					<button
						type="button"
						className="btn flex-grow-1 w-100 btn-info"
						onClick={() => setModalTeletransPayment(true)}>
						Télétransmettre la part sécu
					</button>
				</div>
			</>
		)
}

const VenteModal = ({ sale, setSale, isOpen, setIsOpen, onClose, loading, patient, setNeedRefresh, needRefresh }) => {
	const { user, laboratory } = useContext(AuthContext)
	const queryClient = useQueryClient()
	const [cancelIsDisabled, setCancelIsDisabled] = useState(false)
	const history = useHistory()
	const [cancelOptionsModal, setCancelOptionsModal] = useState(false)
	const [modalChangeSeller, setModalChangeSeller] = useState(false)
	const [document, setDocument] = useState(null)
	const [saleDetail, setSaleDetail] = useState({})
	const [modalChangeDiv, setModalChangeDiv] = useState(false)
	const [divHasSaved, setDivHasSaved] = useState(false)
	const [teletransActive, setTeletransActive] = useState(false)
	const [modalTeletransPayment, setModalTeletransPayment] = useState(false)
	const [publicAmc, setPublicAmc] = useState(false)
	const [manualFseNumber, setManualFseNumber] = useState(false)
	const [error, setError] = useState(null)
	const [teletransData, setTeletransData] = useState({})
	const [fseToDelete, setFseToDelete] = useState(false)
	const [needRefreshOnClose, setNeedRefreshOnClose] = useState(false)

	const isManager = useHasRole("ROLE_MANAGER")
	const isFranchisedManager = useHasRole("ROLE_MANAGER_FRANCHISED")
	const isAffiliate = useHasRole("ROLE_AFFILIATE")

	const [busy, setBusy] = useState(false)

	const { paymentTypesRef } = useFetchPaymentType(laboratory.subCompany)

	const socialSecurity = patient?.patientSocialSecurity
	const domTomPrices = domTomLabsIds.includes(laboratory.id)

	const isPregnant = useGetIsPregnant(patient)

	useEffect(() => {
		let total_div = 0.0
		if (saleDetail?.payment_details?.length) {
			for (const div of saleDetail?.payment_details) {
				total_div += rounded(div.amount || 0)
			}
		}
		setSaleDetail((o) => ({ ...o, amount_left: rounded(sale?.total - total_div) }))
	}, [saleDetail?.payment_details, sale?.total])

	useEffect(() => {
		;(async function () {
			if (!divHasSaved || !sale?.sale_id) return
			setDivHasSaved(false)
			const saleUpdated = await API_Hiboutik.getSale(sale.sale_id)
			let diff = false

			if ((saleUpdated.payment_details || []).length !== (sale.payment_details || []).length) diff = true
			for (const payment of sale.payment_details || []) {
				if (
					!(saleUpdated.payment_details || []).find(
						(f) => +f.payment_detail_id === +payment.payment_detail_id
					)
				)
					diff = true
			}
			for (const payment of saleUpdated?.payment_details || []) {
				if (!(sale.payment_details || []).find((f) => +f.payment_detail_id === +payment.payment_detail_id))
					diff = true
			}

			if (diff) {
				await API.update("DOCUMENTS_API", document.id, {
					edit: {
						payment: saleUpdated.payment,
						payment_details: [...(saleUpdated.payment_details || [])],
						comments: saleUpdated.comments,
					},
				})

				setSale(saleUpdated)
				if (typeof setNeedRefresh === "function") setNeedRefresh(true)
			}
		})()
	}, [divHasSaved, saleDetail, sale])

	useEffectAsync(async () => {
		if (publicAmc || !isOpen) return

		let amc = document?.config?.ventes?.isCmu

		if (amc == null && window.location.href.includes("fiche-patient")) amc = (await findActiveAMx(patient))?.amc

		if (amc) {
			const amcAttachedToAmo = amcLabels.find((e) => e.id === (amc.amcId ?? amc.id))
			setPublicAmc(amcAttachedToAmo)
		}
	}, [sale, document, patient, isOpen])

	useEffect(() => {
		if (isOpen && sale?.sale_id) {
			;(async function () {
				try {
					setDocument(null)
					let type = "FACTURE"

					if (
						sale?.payment_details?.find(
							(p) => p?.payment_type === "SECU" && Math.abs(+p?.payment_amount) === 0.01
						)
					) {
						type = "FACTURE_SUIVI_TELETRANS"
					}

					let res = await API.findCustom("DOCUMENTS_API", "type=" + type + "&numero=" + sale.sale_id)

					res = res.filter((f) => f.patient)
					if (!res.length) {
						toast.error(
							<div>
								Une erreur est survenue pendant l'accès à la facture.
								<br />
								Veuillez contacter le support en précisant le code suivant:
								<br />
								<span className="text-muted">SALE_ID: {sale.sale_id}</span>
							</div>
						)
					}

					if (res.length === 1) {
						setDocument(res[0])
						let config = res[0]?.config

						if (
							config == null ||
							(Array.isArray(config) && config.length === 0) ||
							Object.keys(config).length === 0
						) {
							const saleLaboratories = await API.findAll(
								"LABORATORIES_API",
								`?warehouseIdHiboutik=${sale.store_id}`
							)
							const salePatients = await API.findAll(
								"PATIENTS_API",
								`?hiboutikCustomerId=${sale.customer_id}`
							)
							const saleLaboratory = await API.get(saleLaboratories[0]["@id"])
							const salePatient = await API.get(salePatients[0]["@id"])
							const subCompany = await API.find("SUB_COMPANIES_API", saleLaboratory.subCompany.id)

							config = createJson(sale, salePatient, user, saleLaboratory, subCompany)
							await API.updateWithIri(res[0]["@id"], {
								config,
							})
						}
						findIfTeletransShouldBeActive(res[0])
					}

					if (res.length === 2) setDocument(res[0])

					const paymentsApi = await API.findAll("PAYMENTS_API", `?saleId=${sale?.sale_id}&pagination=false`)

					if (sale?.payment === "DIV") {
						const payment_details = []
						const payment_types = await API_Hiboutik.getPaymentsTypes(laboratory.warehouseIdHiboutik)

						for (const detail of sale?.payment_details) {
							const paymentFromAPI = paymentsApi.find((f) => +f.paymentId === +detail?.payment_detail_id)
							payment_details.push({
								amount: rounded(detail?.payment_amount),
								type: detail?.payment_type,
								payment_detail_id: detail?.payment_detail_id,
								done: detail?.payment_date_paid !== "0000-00-00" ? true : false,
								date: detail?.payment_date,
								payment_paid: detail?.payment_date_paid,
								comments: detail?.comments,
								lock:
									paymentFromAPI?.state === "DEPOSE" ||
									paymentFromAPI?.state?.includes("ANNULE") ||
									(!refundPaymentMethods.includes(detail?.payment_type.trim()) &&
										detail?.payment_amount < 0)
										? true
										: false,
								apiPaymentId: paymentFromAPI?.id,
							})
						}
						setSaleDetail({
							...sale,
							amount_total: sale?.sale_total_gross,
							payment_type: "DIV",
							payment_details,
							payment_types: payment_types.data,
						})
					}
				} catch (error) {
					console.error(error)
				}
			})()
		}
	}, [isOpen, sale, needRefresh])

	const totalPaymentsAmount = React.useMemo(
		() => sale?.payment_details?.reduce((prev, curr) => +curr.payment_amount + +prev, 0),
		[sale]
	)

	const title = useMemo(() => {
		if (loading || !isOpen) return

		const getCustomerName = () => {
			if (sale && sale.customer_id) {
				const trimmedFirstName = sale.customers_first_name.trim()
				const trimmedLastName = sale.customers_last_name.trim()

				if (trimmedFirstName !== "" && trimmedLastName !== "") {
					return `(${trimmedLastName} ${trimmedFirstName})`
				}
			}
			return ""
		}

		const sellerString = (function () {
			if (document?.seller?.firstName && document?.user[0]?.firstName) {
				return ` (Vendu par ${document.user[0].firstName} ${document.user[0].lastName} pour ${document.seller.firstName} 
					${document.seller.lastName})`
			} else if (document?.user[0]?.firstName) {
				return ` (Vendu par ${document.user[0].firstName} ${document.user[0].lastName})`
			}
			return ""
		})()

		if (document?.status === "CONTRE-VENTE") {
			return `Contre-vente numéro ${document?.invoiceNumber || sale.sale_id}`
		} else if (!sale?.sale_id) {
			return "Chargement de la vente..."
		} else {
			return (
				`Vente ${sale ? `numero ${document?.invoiceNumber || sale.sale_id}` : ""} ${getCustomerName()}` +
				sellerString
			)
		}
	}, [document, loading, sale, isOpen])

	if (!isOpen) return <></>

	const getSaleDate = () => {
		const date = moment(sale.completed_at).locale("fr")
		return date.format("D MMMM")
	}

	const getDate = (rawDate) => {
		const date = moment(rawDate).locale("fr")
		return date.format("LL")
	}

	const cancelSale = async (removePatientEquipments) => {
		setCancelIsDisabled(true)
		const promiseSaleCancel = saleCancel(sale, document, removePatientEquipments)

		toast.promise(promiseSaleCancel, {
			pending: "Annulation en cours, veuillez patienter...",
			success: "Facture annulée avec succès !",
			error: "Erreur lors de l'annulation",
		})

		await promiseSaleCancel
		await queryClient.invalidateQueries("PATIENT_EQUIPMENTS_API")
		await queryClient.invalidateQueries("PATIENTS_API/battery_count")

		if (document.config.idFse) {
			toast.error(
				`Attention la FSE ${document.config.idFse} a été créée, si elle a été mise en lot et émise, veuillez contacter la CPAM pour l'en informer, sinon, vous pouvez supprimer la FSE ${document.config.idFse} dans FSV`
			)
		}

		setCancelIsDisabled(false)
		setCancelOptionsModal(false)

		onClose(true)

		typeof setNeedRefresh === "function" && setNeedRefresh(true)
	}

	const findIfTeletransShouldBeActive = (doc) => {
		if (doc.status === "CONTRE-VENTE" || doc?.config?.idFse) {
			setTeletransActive(false)
			return
		}
		if (sale.payment_details.length) setTeletransActive(true)
		return
	}

	const LoadingOrErrorDisplay = () => {
		if (!sale?.sale_id && !loading) return <>Un problème est survenu lors du chargement de la vente.</>
		return (
			<div className="w-100 text-center">
				<Spinner />
			</div>
		)
	}

	return (
		<>
			<Modal
				size="lg"
				centered
				title={loading ? "Chargement..." : title}
				isOpen={isOpen || false}
				onClose={() => {
					if (needRefreshOnClose) {
						onClose(true)
					} else {
						onClose()
					}
				}}
				icon="cart-arrow-down">
				<div className="p-2">
					{sale && !loading ? (
						<div className="d-flex flex-column">
							{sale.payment_details ? (
								<div className="d-flex flex-row justify-content-center flex-wrap align-items-center mb-2">
									{sale.payment_details.map((payment) => (
										<div
											key={payment.payment_detail_id}
											className={cx(
												"rounded flex-grow-1 w-30 d-flex flex-column justify-content-center align-items-center px-2 mx-1 my-1",
												{
													"bg-primary": payment.payment_date_paid !== "0000-00-00",
													"bg-warning": payment.payment_date_paid === "0000-00-00",
												}
											)}>
											<p className="m-0 text-white text-sm">
												{paymentTypesRef?.[payment.payment_type]?.label || payment.payment_type}
											</p>
											<p className="m-0 text-white font-weight-bold ">
												{payment.payment_amount} €
											</p>
											<p className="m-0 text-white text-sm">
												{payment.payment_date_paid !== "0000-00-00"
													? "Paiement acquitté"
													: getDate(payment.payment_date)}
											</p>
										</div>
									))}
								</div>
							) : (
								<div className="d-flex flex-row justify-content-center flex-wrap align-items-center mb-2">
									<div
										key={sale.sale_id}
										className={cx(
											"rounded flex-grow-1 w-30 d-flex flex-column justify-content-center align-items-center px-2 mx-1 my-1",
											{
												"bg-primary": sale.completed_at !== "0000-00-00",
												"bg-warning": sale.completed_at === "0000-00-00",
											}
										)}>
										<p className="m-0 text-white text-sm">
											{paymentTypesRef?.[sale.payment]?.label || sale.payment}
										</p>
										<p className="m-0 text-white font-weight-bold ">{sale.total} €</p>
										<p className="m-0 text-white text-sm">
											{sale.completed_at !== "0000-00-00"
												? "Paiement acquitté"
												: getDate(sale.completed_at)}
										</p>
									</div>
								</div>
							)}

							{["FACTURE_SUIVI_TELETRANS", "FACTURE"].includes(document?.type) && (
								<ForcePaymentRefresh saleId={sale.sale_id} />
							)}

							{sale.payment === "DIV" && _.round(+sale.total, 2) > _.round(+totalPaymentsAmount, 2) && (
								<div className="alert alert-danger">
									Reste à charge : <strong>{_.round(+sale.total - +totalPaymentsAmount, 2)} €</strong>
								</div>
							)}
							{sale.payment === "DIV" && +sale.total < +totalPaymentsAmount && (
								<div className="alert alert-info">
									Montant à rembourser : <strong>{+totalPaymentsAmount - +sale.total} €</strong>
								</div>
							)}
							<div className="d-flex flex-row justify-content-between align-items-center mb-2">
								<h6>Vente du {getSaleDate()}</h6>
								<span className="modal-total-price">{sale.total}€</span>
							</div>
							<h6>Produits: </h6>
							<div className="previous-sale-item-list d-flex flex-column mb-2">
								{sale.line_items.map((item, index) => (
									<Fragment key={index}>
										<div className="d-flex my-1 flex-row align-items-center justify-content-between ">
											<div className="m-0">
												{item.quantity}x {item.product_model}{" "}
												{item.size_name ? `- ${item.size_name}` : ""}{" "}
												{item.serial_number ? `(${item.serial_number})` : ""}
											</div>
											<p className="font-weight-bold text-dark m-0">
												{item.quantity * item.product_price} €
											</p>
										</div>
										{index !== sale.line_items.length - 1 && <hr className="my-1 border bg-dark" />}
									</Fragment>
								))}
							</div>
							{document?.config?.idFse && (
								<>
									<h6>Télétransmission: </h6>
									<div className="d-flex flex-row justify-content-center align-items-center">
										<div className="flex-grow-1 w-70">
											FSE:{" "}
											<span className="font-weight-bold ml-1">{document?.config?.idFse}</span>
											<br />
											Remboursement:{" "}
											<span className="font-weight-bold ml-1">
												{getTpStatus(document.config)}
											</span>
										</div>
										<button
											type="button"
											className="btn flex-grow-1 w-30 btn-danger"
											onClick={async () => {
												const hasConfirmed = await confirmWithModal({
													title: "Attention: cette action est irréversible",
													text: (
														<div>
															<p>
																Si la fse existante <strong>a été émise</strong> et que
																vous n'avez pas eu de retour de la CPAM, veuillez les
																contacter avant de créer une nouvelle fse.{" "}
															</p>
															<p>
																Au contraire, si une fse a été créée et{" "}
																<strong>n'a pas été émise</strong>, veuillez la
																supprimer sur la vue{" "}
																{
																	<a
																		href={urlTeletransmission(
																			user.adeli,
																			laboratory.finess,
																			user.company.id
																		)}
																		target="_blank"
																		rel="noopener noreferrer">
																		<span>Mes télétransmissions</span>
																	</a>
																}{" "}
																avant d'en créer une nouvelle.
															</p>
															<p>Voulez-vous continuer ?</p>
														</div>
													),
												})
												if (hasConfirmed) {
													await API.update("DOCUMENTS_API", document.id, {
														edit: {
															idFse: false,
															idDre: false,
														},
													})
													setTeletransActive(true)
													setFseToDelete(false)
													setNeedRefresh(true)
												}
											}}>
											Dissocier la fse
										</button>
									</div>
								</>
							)}
							{publicAmc ? (
								<div className="d-flex flex-row justify-content-between align-items-center mb-4">
									<div className="col-sm-5 pl-0">
										<h6>Vente CMU, numéro de FSE:</h6>
									</div>
									<div className="col-sm-7">
										<input
											type="number"
											className="form-control mt-3"
											disabled={document?.config?.idFse}
											placeholder="Numéro de fse pour le remboursement cmu"
											onChange={(event) => setManualFseNumber(event.target.value)}
											value={document?.config?.idFse || null}
										/>
									</div>
								</div>
							) : null}
							{document && (
								<>
									<div className="d-flex flex-row justify-content-center align-items-center mt-4">
										{!sale.line_items.find((el) => el.credit_note || el.credit_note_id !== 0) &&
											sale.payment === "DIV" &&
											document.status !== "CONTRE-VENTE" &&
											document.type === "FACTURE" && (
												<button
													type="button"
													className="btn flex-grow-1 w-70 btn-sm btn-outline-primary"
													onClick={() => setModalChangeDiv(true)}
													disabled={isAffiliate && "disabled"}>
													Changer les méthodes de paiement
												</button>
											)}
										{document.type === "FACTURE" && (
											<button
												type="button"
												className="btn flex-grow-1 w-30 btn-sm btn-outline-primary"
												onClick={() => setModalChangeSeller(true)}>
												Modifier le vendeur
											</button>
										)}
									</div>
									<div className="d-flex flex-row justify-content-center align-items-center mt-4">
										<button
											type="button"
											className="btn  flex-grow-1 w-70 btn-primary"
											disabled={cancelIsDisabled}
											onClick={() => history.push(`/ventes/facture/${document.id}`)}>
											Voir la facture
										</button>
										{!sale.line_items.find((el) => el.credit_note || el.credit_note_id !== 0) &&
											(isManager || isFranchisedManager) && (
												<button
													type="button"
													className="btn flex-grow-1 w-30  btn-warning"
													disabled={cancelIsDisabled}
													onClick={() => {
														if (document.type === "FACTURE") {
															setCancelOptionsModal(true)
														} else {
															cancelSale(false)
														}
													}}>
													{document.type === "FACTURE"
														? "Annuler la vente"
														: "Annuler le suivi"}
												</button>
											)}
									</div>
								</>
							)}

							{document?.type === "FACTURE" && (
								<DisplayTeletransButtons
									document={document}
									teletransActive={teletransActive}
									setTeletransActive={setTeletransActive}
									fseToDelete={fseToDelete}
									setFseToDelete={setFseToDelete}
									socialSecurity={socialSecurity}
									setModalTeletransPayment={setModalTeletransPayment}
									setTeletransData={setTeletransData}
									userInfo={{ user, laboratory }}
									manualFseNumber={manualFseNumber}
									setNeedRefresh={setNeedRefresh}
								/>
							)}
						</div>
					) : (
						<LoadingOrErrorDisplay />
					)}
				</div>
			</Modal>

			<ErrorModalTeletrans error={error} setError={setError} />

			{modalChangeDiv && (
				<ModalDivPayments
					selectedPatient={patient}
					busy={busy}
					setBusy={setBusy}
					setError={() => {}}
					sale={saleDetail}
					setSale={setSaleDetail}
					modal={modalChangeDiv}
					setModal={setModalChangeDiv}
					paymentType={saleDetail?.payment_types || []}
					updateApiPayments={true}
					setHasSaved={setDivHasSaved}
					fromAfterSale={true}
					publicAmc={publicAmc}
					laboratory={laboratory}
					closedSaleDocument={document}
					setNeedRefresh={setNeedRefreshOnClose}
				/>
			)}

			{modalTeletransPayment && (
				<ModalTeletransPayment
					selectedProducts={sale?.line_items}
					selectedPatient={patient}
					open={modalTeletransPayment}
					setOpen={setModalTeletransPayment}
					setParentOpen={setIsOpen}
					teletransData={teletransData}
					setTeletransData={() => {}}
					busy={busy}
					setBusy={setBusy}
					error={error}
					setError={setError}
					closedSaleDocument={document}
					publicAmc={publicAmc}
					domTomPrices={domTomPrices}
					isPregnant={isPregnant}
				/>
			)}

			<ModalChangeSeller
				document={document}
				modal={modalChangeSeller}
				setModal={setModalChangeSeller}
				onClose={onClose}
			/>
			<Modal
				isOpen={cancelOptionsModal}
				title="Que souhaitez-vous faire ?"
				onClose={() => setCancelOptionsModal(false)}>
				<button
					type="button"
					disabled={cancelIsDisabled}
					className={"btn btn-primary btn-block"}
					onClick={() => cancelSale(true)}>
					Remettre les appareils en stock
				</button>
				<button
					type="button"
					disabled={cancelIsDisabled}
					className={"btn btn-primary btn-block"}
					onClick={() => cancelSale(false)}>
					Remettre les appareils en essai sur le patient
				</button>
			</Modal>
		</>
	)
}

export default VenteModal
