import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import Brands from "pages/mon-compte/catalogue/Brands"
import Categories from "pages/mon-compte/catalogue/Categories"
import Product from "pages/mon-compte/catalogue/Product"
import Products from "pages/mon-compte/catalogue/Products"
import Suppliers from "pages/mon-compte/catalogue/Suppliers"
import { useLocation } from "react-router-dom"
import { CatalogueRoutePrefix, CatalogueRoutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"
import VerificationCatalogue from "pages/mon-compte/catalogue/VerificationCatalogue"

function CatalogueRouter(): JSX.Element {
	const location = useLocation()
	useCustomTitle("Paramètres | Catalogues")
	console.log(noPrefix(CatalogueRoutes.Produits))
	return (
		<CardWithTabs urlprefix={CatalogueRoutePrefix}>
			{/produit\/\d+/.test(location.pathname) ? (
				<CardItem name="Produit" component={Product} url={noPrefix(CatalogueRoutes.Produit)} useSlug="id" />
			) : location.pathname.includes(`${CatalogueRoutePrefix}/nouveau-produit`) ? (
				<CardItem name="Produits" component={Product} url={noPrefix(CatalogueRoutes.NouveauProduit)} />
			) : (
				<CardItem name="Produits" component={Products} url={noPrefix(CatalogueRoutes.Produits)} />
			)}
			<CardItem
				name="Vérification des produits"
				component={VerificationCatalogue}
				url={noPrefix(CatalogueRoutes.VérificationProduits)}
				onlyVisibleOnExactRoute
			/>
			<CardItem name="Marques" component={Brands} url={noPrefix(CatalogueRoutes.Marques)} />
			<CardItem name="Fournisseurs" component={Suppliers} url={noPrefix(CatalogueRoutes.Fournisseurs)} />
			<CardItem name="Catégories" component={Categories} url={noPrefix(CatalogueRoutes.Catégories)} />
		</CardWithTabs>
	)
}

export default CatalogueRouter
