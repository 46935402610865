/* eslint-disable react-hooks/exhaustive-deps */
import * as Sentry from "@sentry/react"
import { ConfigProvider } from "antd"
import antdFR from "antd/lib/locale/fr_FR"
import axios from "axios"
import { hasOneOfThisRole } from "components/Hooks/useHasRole"
import useHasServices from "components/Hooks/useHasServices"
import { rolesAudio } from "components/forms/UserRegistrationStatusSelect/UserRegistrationStatusSelect"
import { ServiceRef } from "components/hoc/withRequiredServices"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isToday from "dayjs/plugin/isToday"
import localizedFormat from "dayjs/plugin/localizedFormat"
import utc from "dayjs/plugin/utc"
import weekOfYear from "dayjs/plugin/weekOfYear"
import applyAntdTheme, { setExtraPurpose } from "hooks/specific/applyAntdTheme"
import _ from "lodash"
import moment from "moment"
import "moment/locale/fr"
import { etatJuxtaLink } from "pages/FSV/functionsFSV"
import ComplianceDocumentViewer from "pages/fiche-patient/Documents/Modal.ComplianceDocumentViewer"
import ModalSelectUserRegistrationStatus from "pages/mon-compte/mon-equipe/ModalSelectUserRegistrationStatus"
import { lazy, Suspense, useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "@bryntum/calendar/calendar.stockholm.css"
import { checkConnection } from "services/RealtimeSystem/Interceptors"
import { handleNotifier } from "services/RealtimeSystem/Notifier"
import { v5 as uuidv5 } from "uuid"
import { ConfirmModalContainer } from "./components/effects/ConfirmModalFunction"
import { AUDIO_WIZARD_DOCUMENTS_API, SOCKET_REALM, SOCKET_RNS_REALM, USERS_API } from "./config"
import AuthContext from "./contexts/AuthContext"
import PrivateRouter from "./router/PrivateRouter"
import API from "./services/API"
import hiboutik from "./services/API_Hiboutik"
import hiboutikRework from "./services/API_Hiboutik_Rework"
import * as AuthApi from "./services/AuthApi"
import { socketDispatcherOff, socketDispatcherOn } from "./services/RealtimeSystem/Dispatcher"
import useNotifierSystem from "./services/RealtimeSystem/Hook"
import { haveRole, setCurrentAttendance, SortAlphabetically } from "./services/functions"
import hiboutikUpdate from "./services/hiboutikUpdate"
import localStorage from "./services/localStorage"

// reinit localstorage from binary base64 to simple json stringified
// delete this after a while
if (!window.localStorage.getItem("localStorageRestarted")) {
	window.localStorage.clear()
	window.localStorage.setItem("localStorageRestarted", true)
}

const RemoteUI = lazy(() => import("./pages/salle-attente/Remote"))
const SignDocumentRemotePage = lazy(() =>
	import("./pages/fiche-patient/Documents/SignDocument").then((module) => ({
		default: module.SignDocumentRemotePage,
	}))
)
const ChoixMotDePasseOublie = lazy(() => import("./pages/ChoixMotDePasseOublie"))
const InscriptionUser = lazy(() => import("./pages/inscription/0-PublicUser"))
const Login = lazy(() => import("./pages/Login"))
const MotDePasseOublie = lazy(() => import("./pages/MotDePasseOublie"))
const accountCreation = lazy(() => import("./pages/account-creation"))
//const TeleconsultationPatient = lazy(() => import("./pages/teleconsultation/Patient"))

// Config dayjs
dayjs.locale("fr")
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isToday)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(weekOfYear)
dayjs.extend(duration)

moment.locale("fr")

// this file is broken, he mounts and demounts even if the user is already loaded and this is a workaround to prevent multiple API call
let verifyAgreementMountControl = true

export const defaultAntdThemeVars = {
	primaryColor: "#18bc9c",
	infoColor: "#18ABBC",
	successColor: "#18bc9c",
	warningColor: "#ffab00",
	errorColor: "#ff5630",
}

// would have been better to use company id, but user might not be authenticated yet
const isAptUrl = document.location.href.includes("app.audiopourtous.fr")
export const AW_LOADING_LOGO = isAptUrl
	? "/static/img/brand/logo-chapeau-audiowizard-couleur-apt.png"
	: "/static/img/brand/logo-chapeau-audiowizard-couleur.png"
export const defaultPurposeThemeVars = {
	primaryColor: "#18bc9c",
	primaryHalfTrans: "#18bc9c80",
	primaryGradient: "#18abbc",
	secondaryColor: "#18ABBC",
	primaryDark: "#128f76",
	primaryLight: "#24e3be",
	secondaryDark: "#008da6",
	secondaryLight: "#0ddaff",
	loadingLogo: `url("${AW_LOADING_LOGO}")`,
	loadingLogoAnimated: isAptUrl
		? 'url("/static/img/brand/animated-logo-apt.gif")'
		: 'url("/static/img/brand/animated-logo.gif")',
}

const App = ({ socket, socket_rns }) => {
	const ctx = useContext(AuthContext)
	const { user } = useContext(AuthContext)
	const [loading, setLoading] = useState(false)
	const [loadedUserData, setLoadedUserData] = useState(false)
	const [loadingProgress, setLoadingProgress] = useState({ done: 0, total: 0, text: "" })
	const serviceStatus = useHasServices(ServiceRef.MarqueBlanche)

	const [complianceDocuments, setComplianceDocuments] = useState([])
	const [documentIndex, setDocumentIndex] = useState(0)
	const [openDocumentModal, setOpenDocumentModal] = useState(false)
	const [modalLoading, setModalLoading] = useState(false)
	const [needRegistrationStatus, setNeedRegistrationStatus] = useState(false)

	// User Agreements
	const userAgreementsNext = async (document) => {
		await axios.post(
			USERS_API + "/validate_agreement",
			{ isAgreementValidate: true, audioWizardDocument: document["@id"] },
			{ headers: { "content-type": "application/ld+json" } }
		)
		setDocumentIndex(documentIndex + 1)
	}

	useEffect(() => {
		const verifyUserAgreements = async () => {
			setModalLoading(true)
			try {
				const userAgreements = (await axios.get(USERS_API + "/verify_agreements")).data["hydra:member"]
				const documents = []
				const unacceptedAgreements = userAgreements
					.map((agreement) => {
						const [documentType, documentAgreements] = Object.entries(agreement)[0]
						return {
							documentType: documentType,
							isAccepted: documentAgreements?.isAccepted,
							isAvailable: documentAgreements?.isAvailable,
						}
					})
					.filter((agreement) => agreement.isAccepted === false && agreement.isAvailable === true)
				for (const unacceptedAgreement of unacceptedAgreements) {
					const document = (
						await axios.post(
							AUDIO_WIZARD_DOCUMENTS_API + "/latest_agreement",
							{ type: unacceptedAgreement.documentType },
							{ headers: { "content-type": "application/ld+json" } }
						)
					).data
					documents.push(document)
				}
				if (documents.length !== 0) setOpenDocumentModal(true)
				setComplianceDocuments(documents)
				setModalLoading(false)
			} catch (e) {
				console.error(e)
			}
		}

		if (verifyAgreementMountControl && user.gdprAgreement && user?.id && haveRole(user, "ROLE_MANAGER")) {
			verifyUserAgreements()
			verifyAgreementMountControl = false
		}
	}, [user])

	// Context Persistance
	const contextSavePersistance = async (context, exclusion) => {
		try {
			const tmp = {}

			for (const [key, val] of Object.entries(context)) {
				if (!key.includes("set") && typeof val !== "function" && !exclusion.includes(key)) {
					tmp[key] = _.clone(val)
				}
			}
			for (const [key, val] of Object.entries(tmp)) {
				if (val?.documents) delete tmp[key].documents
				if (val?.todos) delete tmp[key].todos
				if (val?.orls) delete tmp[key].orls
				if (val?.doctors) delete tmp[key].doctors
				if (val && Array.isArray(val) && !val.length) delete tmp[key]
				if (val && typeof val === "object" && !Array.isArray(val) && !Object.keys(val).length) delete tmp[key]
				if (val == null) delete tmp[key]
			}

			window.sessionStorage.setItem("APPCTX", JSON.stringify(tmp))
		} catch (e) {
			console.error("contextSavePersistance", e)
		}
	}

	const contextLoadPersistance = (context, userData, exclusion) => {
		try {
			let fromLocal = window.sessionStorage.getItem("APPCTX")
			fromLocal = JSON.parse(fromLocal)
			if (fromLocal?.user?.id !== userData?.id) {
				window.sessionStorage.removeItem("APPCTX")
				return
			}
			for (const [key, value] of Object.entries(fromLocal)) {
				if (!exclusion.includes(key)) {
					for (const ctkey of Object.keys(context)) {
						if (`set${key}`.toLocaleLowerCase() === ctkey.toLocaleLowerCase()) {
							context[ctkey](value)
							break
						}
					}
				}
			}
		} catch (e) {
			console.error("contextLoadPersistance", e)
		}
	}

	useEffect(() => {
		if (!loading && ctx.isAuthenticated && loadedUserData)
			contextSavePersistance(ctx, [
				"isAuthenticated",
				"uiDisplay",
				"laboratory",
				"laboratoryAttendances",
				"laboratories",
			])
	}, [ctx])

	// Load Agendas
	const { data: agendas } = useQuery(
		"AGENDAS_API",
		async () => {
			let page = 1
			let totalItems = Infinity
			const agendas = []

			while (agendas.length < totalItems) {
				const res = await API.findAll("AGENDAS_API", `?page=${page}`, true)
				page++
				totalItems = res["hydra:totalItems"]
				agendas.push(...res["hydra:member"])
			}
			return agendas
		},
		{
			staleTime: Infinity,
			enabled: !!ctx.isAuthenticated /* can be undefined for some reason*/,
		}
	)

	useEffect(() => {
		ctx.setAgendas(agendas)
		const hasDoctolibAgenda = agendas ? agendas.some((agenda) => agenda.doctolibType != null) : false
		ctx.setHasDoctolib(hasDoctolibAgenda)
	}, [agendas])

	// Set agendaColor on context laboratories
	useEffect(() => {
		if (agendas?.length > 0 && ctx.laboratories?.length > 0 && ctx.laboratories[0]?.agendaColor == null) {
			ctx.setLaboratories(
				ctx.laboratories.map((lab) => ({
					...lab,
					agendaColor:
						agendas?.find((agenda) => agenda.laboratory?.["@id"] === lab["@id"])?.laboratory?.agendaColor ||
						"",
				}))
			)
		}
	}, [agendas, ctx.laboratories])

	// Load Company Settings
	const { data: companySettings } = useQuery(
		"COMPANY_SETTINGS",
		async () => await API.findAll("COMPANY_SETTINGS_API", "/me"),
		{
			staleTime: Infinity,
			enabled: !!ctx.isAuthenticated /* can be undefined for some reason*/ && ctx.user.company != null,
		}
	)
	useEffect(() => {
		if (companySettings == null) return

		ctx.setCompanySettings(companySettings)
	}, [companySettings])

	// Load white label theme
	useEffect(() => {
		if (!serviceStatus || ctx.user.company?.whiteLabel == null) {
			return
		}

		if (!ctx.user.company?.whiteLabel?.isActive) {
			ConfigProvider.config({ theme: defaultAntdThemeVars })
			setExtraPurpose(defaultPurposeThemeVars)

			return
		}

		applyAntdTheme(
			{
				primaryColor: ctx.user.company?.whiteLabel?.primaryColor,
				secondaryColor: ctx.user.company?.whiteLabel?.secondaryColor,
			},
			ctx.user.company?.whiteLabel?.logo
		)
	}, [serviceStatus, ctx.user?.company?.whiteLabel])

	useNotifierSystem(handleNotifier, ["schedule"])

	useEffect(() => {
		socketDispatcherOff(socket_rns)
		AuthApi.initAuth(ctx, setLoading)
	}, [])

	useEffect(() => {
		const loadUserdata = async () => {
			const initHiboutik = (user) => {
				try {
					const token = user.company?.hiboutikAccount?.token
					const subDomain = user.company?.hiboutikAccount?.subDomain

					window.localStorage.setItem("aGlib3V0b2tlbg", token)
					window.localStorage.setItem("aGlib3V0aWtfYWNjb3VudG5hbWU", subDomain)

					if (user?.company?.tokenPdf) window.localStorage.setItem("dG9rZW5QZGY=", user.company.tokenPdf)

					if (!token || !subDomain) {
						ctx.setUiDisplay((old) => ({ ...old, topBarHiboutikTokenAlert: true }))
					}

					hiboutik.init(subDomain, token, user?.company?.tokenPdf)
					hiboutikRework.init(subDomain, token)
				} catch (e) {
					console.error("initHiboutik", e)
				}
			}

			// Progress indication
			let lastId = 1
			let progressDone = 0
			let progressTotal = 0
			let progressTexts = []

			const updateProgress = () => {
				setLoadingProgress({
					done: progressDone,
					total: progressTotal,
					text: progressTexts.map((p) => p.text).join("\n"),
				})
			}
			const startProgress = (text) => {
				const id = lastId++
				progressTexts.push({ id, text })
				progressTotal++

				updateProgress()

				return id
			}
			const endProgress = (id) => {
				progressTexts = progressTexts.filter((p) => p.id !== id)
				progressDone++
				updateProgress()
			}

			setLoading(true)

			try {
				// remove sockets
				try {
					socket.off("audio")
					socket_rns.off("wizard")
				} catch (_void) {}

				// fetch logged in user data
				const userDataProgressId = startProgress("Chargement des données utilisateur")
				let userData = await AuthApi.fetchUser()
				ctx.setUiDisplay((old) => ({ ...old, onboarding: false }))
				if (!userData["@id"]) throw Error()
				if (!userData.gdprAgreement) {
					// if the user hasn't finished registratuibn redirect to the registration wizard
					ctx.setUiDisplay((old) => ({ ...old, onboarding: true }))

					if (!document.location.pathname.startsWith("/inscription"))
						window.location = "/inscription/user/coordonnees"
				}
				if (!userData?.personalization?.length) {
					try {
						await API.create("PERSONALIZATIONS_API", {
							user: userData["@id"],
							settings: [],
							templates: [],
						})
					} catch (err) {}
				}
				if (userData?.laboratories.length) {
					const laboratoriesSorted = SortAlphabetically(userData?.laboratories, "label")
					let userDataCopy = { ...userData, ...laboratoriesSorted }
					userData = userDataCopy
				}
				initHiboutik(userData)
				contextLoadPersistance(ctx, userData, [])
				ctx.setUser(userData)

				endProgress(userDataProgressId)

				if (!userData.registrationStatus && hasOneOfThisRole(userData.roles, rolesAudio)) {
					setNeedRegistrationStatus(true)
				}

				// fetch in parallel
				const tasks = []
				const addTask = (text, fn) => {
					const progressId = startProgress(text)
					tasks.push(fn().then(() => endProgress(progressId)))
				}

				addTask("Chargement des services", async () =>
					ctx.setServices(await API.findAll("AUDIO_WIZARD_SERVICES_API"))
				)
				addTask("Chargement des abonnements", async () => {
					const subscriptions = await API.findAll("AUDIO_WIZARD_SUBSCRIPTIONS_API")
					ctx.setSubscriptions(subscriptions)
					if (process.env.REACT_APP_NO_JUXTA !== "true") {
						etatJuxtaLink(subscriptions)
					}
				})

				addTask("Chargement des ORLs et Médecins Généralistes", async () => {
					const prescribers = await API.findAll("PRESCRIBERS_API", "?pagination=false&order[id]=DESC")
					ctx.setOrls(prescribers.filter((p) => p.category === "ORL"))
					ctx.setDoctors(prescribers.filter((p) => p.category === "DOCTOR"))
				})

				addTask("Chargement des modèles", async () => {
					const templates = await API.findAll("TEMPLATE", undefined, true).then(async (data) => {
						const templates = data["hydra:member"]
						// if there are no templates hydra:view is not there
						if (!data["hydra:view"]) return templates || []
						let next = data["hydra:view"]["hydra:next"]
						while (next) {
							const hydra = await API.custom(next)
							templates.push(...hydra["hydra:member"])

							next = hydra["hydra:view"]["hydra:next"]
						}
						return templates || []
					})
					ctx.setTemplates(templates)
				})

				addTask("Chargement des produits", async () => {
					let isError
					try {
						isError = await hiboutikUpdate.update(userData)
						if (!isError) {
							hiboutikUpdate.updateWebhook("", "SUCCESS", 0)
						}
					} catch (err) {
						hiboutikUpdate.updateWebhook(err, "FAILURE: algo", 1)
					} finally {
						// L'utilisateur n'a pas de company lors de l'inscription
						if (userData.company != null && isError !== 2) {
							hiboutikUpdate.setShouldUpdate(userData, false)
						}
					}
				})

				addTask("Chargement des laboratoires", async () => {
					const fetchAndLoadLaboratoryAttendences = async (userData) => {
						if (!("laboratories" in userData) || document.location.href.includes("/inscription/")) return
						try {
							const attendances = await API.findAll("LABORATORY_ATTENDANCES_API", `?user=${userData?.id}`)
							ctx.setLaboratoryAttendances(attendances)
							ctx.setLaboratory({})
							return attendances
						} catch (e) {
							console.error(e)
						}
					}

					const attendances = await fetchAndLoadLaboratoryAttendences(userData)
					const laboratoriesList = await API.findAll(
						"LABORATORIES_API",
						`?user=${userData?.id}&pagination=false`
					)
					ctx.setLaboratories(laboratoriesList)
					// attendances est undefined si on est en inscription, on fait ce check pour ne avoir un erreur sur le .filter et rester connecté
					if (attendances != null) {
						setCurrentAttendance(
							attendances,
							ctx.laboratory,
							ctx.setLaboratory,
							laboratoriesList,
							!ctx.user.gdprAgreement
						)
					}
				})

				addTask("Chargement des assurances", async () => {
					// userData.company est undefined pendant l'inscription
					if (userData.company?.["@id"] != null) {
						const userInsurancesList = await API.findAll(
							"USER_INSURANCES_API",
							`?companies=${userData.company["@id"]}`
						)
						ctx.setUserInsurances(userInsurancesList)
					}
				})

				await Promise.all(tasks)

				if (!process.env?.REACT_APP_DEV_LOCAL) {
					// Crisp config to identify user and add his informations to the crisp profile
					window.CRISP_TOKEN_ID = uuidv5(userData?.email, process.env.REACT_APP_SALT_CRISP)
					window.$crisp.push(["do", "session:reset"])
					window.$crisp.push([
						"on",
						"chat:opened",
						() => {
							window.$crisp.push([
								"set",
								"user:nickname",
								[`${userData?.lastName} ${userData?.firstName}`],
							])
							window.$crisp.push(["set", "user:email", [userData?.email]])
							userData?.phone && window.$crisp.push(["set", "user:phone", [`${userData?.phone}`]])
							window.$crisp.push(["set", "user:company", [userData?.company?.label]])
						},
					])

					Sentry.init({
						dsn: "https://70479a65df88f9da3985ac142f8ecfa0@o4505764589207552.ingest.sentry.io/4505765337497600",
						environment: "prod",
						integrations: [
							new Sentry.BrowserTracing({
								tracePropagationTargets: ["localhost", "https://api.audiowizard.fr"],
							}),
							new Sentry.Replay(),
						],
						tracesSampleRate: 0.1,
						replaysSessionSampleRate: 0.0,
						replaysOnErrorSampleRate: 1.0,
					})
					Sentry.setUser({ email: userData?.email })
				}

				socketDispatcherOn(socket_rns)
				try {
					socket_rns.emit("keepalive", {
						realm: SOCKET_RNS_REALM,
						user_id: userData?.id,
						company_id: userData?.company?.id,
					})
					setInterval(async () => {
						await checkConnection(socket_rns)
						socket_rns.emit("keepalive", {
							realm: SOCKET_RNS_REALM,
							user_id: userData?.id,
							company_id: userData?.company?.id,
						})
						console.debug("[SOCKET_RNS] Sending keepalive")
					}, 60000)
					window.onfocus = async function () {
						await checkConnection(socket_rns)
					}
					if (socket) {
						socket.emit("wizard", {
							realm: SOCKET_REALM,
							user: userData.id,
							lastName: userData.lastName,
							firstName: userData.firstName,
							buildDate: window?.BUILD_DATE,
						})
						socket.on("audio", async () => {
							socket.emit("wizard", {
								realm: SOCKET_REALM,
								user: userData.id,
								lastName: userData.lastName,
								firstName: userData.firstName,
								buildDate: window?.BUILD_DATE,
							})
						})
					}
				} catch (e) {
					console.error(e)
				}

				setLoadedUserData(true)
				setLoading(false)
			} catch (e) {
				console.error(e)

				setLoading(false)
				ctx.setIsAuthenticated(false)
			}
		}

		if (ctx.isAuthenticated && !loading) {
			loadUserdata()
		}
	}, [ctx.isAuthenticated])

	// Persist current laboratory to localStorage
	useEffect(() => {
		const iri = ctx.laboratory?.["@id"]
		if (iri == null) return

		window.localStorage.setItem("currentLaboratoryIri", iri)
	}, [ctx.laboratory])

	useEffect(() => {
		if (document.location.pathname.includes("/consentement-rgpd")) {
			localStorage.update("appcache", {
				waitingRoomData: null,
				firstVisit: true,
			})
		}
	}, [document.location.pathname])

	return (
		<ConfigProvider locale={antdFR}>
			{loading && (
				<>
					<div className="container h-100 d-flex justify-content-center">
						<div className="remoteUI-Loading loading-app-general" style={{ filter: "blur(.5px)" }}>
							<div className="app-loading-bar">
								<div style={{ width: (loadingProgress.done * 100) / loadingProgress.total + "%" }} />
							</div>
						</div>
						<h6 style={{ position: "absolute", top: "60%", color: "#8492a6", whiteSpace: "pre" }}>
							{loadingProgress.text}
						</h6>
					</div>
				</>
			)}
			{!loading && ctx.isAuthenticated && <PrivateRouter />}
			{!loading && !ctx.isAuthenticated && (
				<BrowserRouter>
					<Suspense fallback={<>...</>}>
						<Switch>
							<Route path="/inscription/:token?" component={InscriptionUser} />
							<Route path="/se-connecter" component={Login} />
							<Route path="/mot-de-passe-oublie" component={MotDePasseOublie} />
							<Route path="/choix-mot-de-passe-oublie/:token" component={ChoixMotDePasseOublie} />
							<Route path="/account-creation/:token" component={accountCreation} />
							<Route path="/salle-attente/invitation-:id" component={RemoteUI} />
							<Route path="/remote/salle-attente/:id" component={RemoteUI} />
							<Route path="/documents/signer/remote/:token" component={SignDocumentRemotePage} />
							<Route path="/" component={Login} />
						</Switch>
					</Suspense>
				</BrowserRouter>
			)}
			<ToastContainer theme="light" position={toast.POSITION.TOP_CENTER} style={{ top: "42px" }} />
			<ConfirmModalContainer />

			<ModalSelectUserRegistrationStatus
				isOpen={needRegistrationStatus}
				registrationStatus={ctx.user?.registrationStatus}
				user={ctx.user}
				setUser={ctx.setUser}
				onClose={() => setNeedRegistrationStatus(false)}
			/>

			{complianceDocuments && documentIndex < complianceDocuments.length && (
				<ComplianceDocumentViewer
					//Open the compliance document modal if there is unsigned documents
					maxIndex={complianceDocuments.length}
					index={documentIndex}
					document={complianceDocuments[documentIndex]}
					isOpen={openDocumentModal}
					handleNext={() => userAgreementsNext(complianceDocuments[documentIndex])}
					loading={modalLoading}
				/>
			)}
		</ConfigProvider>
	)
}

export default App
