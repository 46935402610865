import { useMutation, useQueryClient } from "react-query"
import {
	UserInsuranceCoverageDemandService,
	userInsuranceCoverageDemandServiceKey,
	userInsuranceCoverageDemandServicePatientKey,
	userInsuranceCoverageDemandServiceStateKey,
} from "../userInsuranceCoverageDemandService"

export default function useUserInsuranceCoverageDemandAction() {
	const client = useQueryClient()

	return useMutation({
		mutationFn: UserInsuranceCoverageDemandService.action,
		onSuccess: async () =>
			Promise.allSettled([
				client.invalidateQueries([userInsuranceCoverageDemandServiceKey]),
				client.invalidateQueries([userInsuranceCoverageDemandServicePatientKey]),
				client.invalidateQueries([userInsuranceCoverageDemandServiceStateKey]),
			]),
	})
}
