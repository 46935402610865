import { Select, Table } from "antd"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import useHasRole from "components/Hooks/useHasRole"
import Button from "components/utils/Button"
import AuthContext from "contexts/AuthContext"
import { Fragment, useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Col, Input, Modal, ModalBody, ModalHeader } from "reactstrap"
import API from "services/API"
import { partialSearch } from "services/functions"

function CreateAgendaModal({ open, onClose, users, laboratories, agenda }) {
	const [selectedUser, setSelectedUser] = useState(0)
	const [selectedLaboratory, setSelectedLaboratory] = useState(0)
	const [label, setLabel] = useState("")
	const [doctolibType, setDoctolibType] = useState(0)

	const queryClientAgenda = useQueryClient()

	const verifyField = () => {
		if (selectedUser === 0 || selectedLaboratory === 0) {
			toast.error("Veuillez choisir un utilisateur et un laboratoire")
			return false
		} else if (label === "") {
			toast.error("Veuillez entrer un titre pour cet agenda")
			return false
		}
		return true
	}

	const createAgenda = async () => {
		if (!verifyField()) return
		try {
			await API.create("AGENDAS_API", {
				laboratory: selectedLaboratory,
				user: selectedUser,
				label,
				doctolibType: doctolibType !== 0 ? doctolibType : null,
			})
			toast.success(`Agenda ${label} créé`)
			//this request has been initiated into app.js
			queryClientAgenda.invalidateQueries(["AGENDAS_API"])
			setLabel("")
			setSelectedLaboratory(0)
			setSelectedUser(0)
			setDoctolibType(0)
			// close Modal
			onClose()
		} catch (err) {
			console.log(err)
			if (err.response.status === 422) {
				toast.error("Cet utilisateur possède déjà un agenda dans ce laboratoire")
			} else {
				toast.error("Erreur lors de l'enregistrement de l'agenda")
			}
		}
	}

	const updateAgenda = async () => {
		if (!verifyField()) return
		try {
			await API.update("AGENDAS_API", agenda["@id"].split("/")[2], {
				label,
				doctolibType: doctolibType !== 0 ? doctolibType : null,
			})
			toast.success("Modifications sauvegardées")
			queryClientAgenda.invalidateQueries(["AGENDAS_API"])
			setLabel("")
			setSelectedLaboratory(0)
			setSelectedUser(0)
			setDoctolibType(0)
			onClose()
		} catch (err) {
			toast.error("Erreur lors de la modification de l'agenda")
		}
	}

	const closeModal = () => {
		setLabel("")
		setSelectedLaboratory(0)
		setSelectedUser(0)
		setDoctolibType(0)
		onClose()
	}

	useEffect(() => {
		if (agenda == null) return
		setSelectedUser(agenda.user["@id"])
		setSelectedLaboratory(agenda.laboratory["@id"])
		setLabel(agenda.label)
		setDoctolibType(agenda.doctolibType ?? 0)
	}, [agenda])

	return (
		<Fragment>
			<Modal isOpen={open} toggle={closeModal} size="xl" centered>
				<ModalHeader>
					<div className="d-flex flex-row ">
						<div className="modal-title d-flex align-items-begin">
							<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
								<i className="fad fa-plus" />
							</div>
							{agenda != null ? `Modifier l'agenda ${agenda.label} ` : "Ajouter un nouvel agenda"}
						</div>
						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
							title="Fermer"
							onClick={onClose}>
							<i className="fas fa-times" />
						</button>
					</div>
				</ModalHeader>
				<ModalBody>
					<div className="">
						<form className="d-flex flex-column form-group">
							<label htmlFor="user" className="mb-4">
								Utilisateur
								<Select
									id="user"
									className="form-control removeantd-class antd-add-padding mr-2"
									showSearch
									filterOption={(search, option) => partialSearch(option.label, search)}
									value={selectedUser}
									disabled={agenda != null}
									onChange={setSelectedUser}
									options={[
										{ value: 0, label: "Utilisateur" },
										...users?.map((u) => ({
											key: u["@id"],
											value: u["@id"],
											label: `${u.lastName} ${u.firstName}`,
										})),
									]}
								/>
							</label>
							<label htmlFor="laboratory" className="mb-4">
								Laboratoire
								<Select
									id="laboratory"
									className="form-control removeantd-class antd-add-padding mr-2"
									showSearch
									filterOption={(search, option) => partialSearch(option.label, search)}
									value={selectedLaboratory}
									onChange={setSelectedLaboratory}
									disabled={agenda != null}
									options={[
										{ value: 0, label: "Laboratoire" },
										...laboratories?.map((l) => ({
											key: l["@id"],
											value: l["@id"],
											label: l.label,
										})),
									]}
								/>
							</label>
							<label htmlFor="agenda" className="mb-4">
								Titre de l'agenda
								<Input
									id="agenda"
									className="mr-2"
									placeholder="Titre de l'agenda"
									value={label}
									onChange={(e) => setLabel(e.target.value)}
								/>
							</label>
							<label htmlFor="doctolibType" className="mb-4">
								Type Doctolib
								<Select
									disabled={true}
									id="doctolibType"
									className="form-control removeantd-class antd-add-padding mr-2"
									value={doctolibType}
									onChange={setDoctolibType}
									placeholder="Type Doctolib (optionnel)"
									options={[
										{ value: 0, label: "Non Doctolib", key: "0" },
										{ value: "Agenda", label: "Agenda praticien ", key: "1" },
										{
											value: "StandaloneResourceAgenda",
											label: "Ressource sans praticien",
											key: "2",
										},
									]}
								/>
							</label>
							<ButtonRounded
								onClick={agenda != null ? updateAgenda : createAgenda}
								type="button"
								color="primary-outlined"
								className="ml-auto">
								Enregistrer
							</ButtonRounded>
						</form>
					</div>
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

function DeleteAgendaModal({ agenda, open, onClose, queryClientAgenda }) {
	const deleteAgenda = async () => {
		try {
			await API.delete(agenda["@id"])
			queryClientAgenda.invalidateQueries(["AGENDAS_API"])
			toast.success("Suppression de l'agenda réussie")
			onClose()
		} catch (err) {
			toast.error("Erreur lors de la suppression de l'agenda")
			onClose()
		}
	}
	return (
		<Modal isOpen={open} toggle={onClose}>
			<ModalHeader>Supprimer l'agenda : {agenda.label} ? </ModalHeader>
			<ModalBody className="d-flex justify-content-around">
				<Button className="btn btn-primary" onClick={onClose}>
					Annuler
				</Button>
				<Button className="btn btn-danger" onClick={deleteAgenda}>
					Supprimer
				</Button>
			</ModalBody>
		</Modal>
	)
}

export default function CrudAgenda() {
	const queryClientAgenda = useQueryClient()
	const { agendas } = useContext(AuthContext)
	const [users, setUsers] = useState([])
	const [laboratories, setLaboratories] = useState([])
	const [selectedUser, setSelectedUser] = useState(0)
	const [selectedLaboratory, setSelectedLaboratory] = useState(0)
	const [showAgendaModal, setShowAgendaModal] = useState({
		open: false,
		agenda: null,
	})
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [selectedAgendaToDelete, setSelectedAgendaToDelete] = useState(null)

	const isManager = useHasRole("ROLE_MANAGER")

	const columns = [
		{
			title: "Utilisateur",
			key: "user",
			render: ({ user }) => (
				<div key={user["@id"]}>
					{user.lastName} {user.firstName}
				</div>
			),
		},
		{
			title: "Laboratoire",
			key: "laboratory",
			render: ({ laboratory }) => <div key={laboratory["@id"]}>{laboratory.label}</div>,
		},
		{
			title: "Agenda",
			key: "@id",
			dataIndex: "label",
		},
		{
			title: "Type Doctolib",
			key: "doctolibType",
			render: ({ doctolibType }) => {
				switch (doctolibType) {
					case "Agenda":
						return "Agenda praticien"
					case "StandaloneResourceAgenda":
						return "Ressource sans praticien"
					default:
						return ""
				}
			},
		},
		{
			title: "Actions",
			key: "actions",
			render: (row) => {
				return (
					<div key={row.agenda}>
						{isManager ? (
							<>
								<span onClick={() => setShowAgendaModal({ open: true, agenda: row })}>
									<i
										style={{ cursor: "pointer", width: "24px", height: "24px", marginLeft: "6px" }}
										className="fad fa-calendar-edit"
									/>
								</span>
								<span
									onClick={() => {
										setSelectedAgendaToDelete(row)
										setShowDeleteModal(true)
									}}>
									<i
										style={{ cursor: "pointer", width: "24px", height: "24px", marginLeft: "6px" }}
										className="fad fa-trash-alt"
									/>
								</span>{" "}
							</>
						) : (
							<p>Manager Seulement</p>
						)}
					</div>
				)
			},
		},
	]

	useEffect(() => {
		const fetchUsers = async () => {
			const res = await API.findAll("USERS_API", "?pagination=false")
			setUsers(res)
		}
		fetchUsers()
	}, [])

	useEffect(() => {
		const fetchLabs = async () => {
			const res = await API.findAll("LABORATORIES_API", "?pagination=false")
			setLaboratories(res)
		}
		fetchLabs()
	}, [])
	useEffect(() => {
		if (agendas == null || users == null) return
	}, [agendas, users])

	return (
		<Col>
			<SectionHeader title="Liste des agendas">
				{isManager && (
					<ButtonRounded
						color="primary-outlined"
						icon="fa-plus"
						onClick={() => setShowAgendaModal({ open: true, agenda: null })}>
						Ajouter un agenda
					</ButtonRounded>
				)}
			</SectionHeader>
			<h5>Filtrer les agendas</h5>
			<div className="d-flex flex-row my-3">
				<Select
					className="form-control removeantd-class antd-add-padding w-25 mr-2"
					showSearch
					filterOption={(search, option) => partialSearch(option.label, search)}
					value={selectedUser}
					onChange={setSelectedUser}
					options={[
						{ value: 0, label: "Utilisateur" },
						...users?.map((u) => ({
							key: u["@id"],
							value: u["@id"],
							label: `${u.lastName} ${u.firstName}`,
						})),
					]}
				/>
				<Select
					className="form-control removeantd-class antd-add-padding w-25 mr-2"
					showSearch
					filterOption={(search, option) => partialSearch(option.label, search)}
					value={selectedLaboratory}
					onChange={setSelectedLaboratory}
					options={[
						{ value: 0, label: "Laboratoire" },
						...laboratories?.map((laboratory) => ({
							key: laboratory["@id"],
							value: laboratory["@id"],
							label: laboratory.label,
						})),
					]}
				/>
			</div>
			{agendas != null && (
				<Table
					dataSource={agendas
						.filter(
							(agenda) =>
								(selectedUser === 0 || agenda.user["@id"] === selectedUser) &&
								(selectedLaboratory === 0 || agenda.laboratory["@id"] === selectedLaboratory)
						)
						.map((agenda) => ({
							...agenda,
							key: agenda["@id"],
						}))}
					columns={columns}
				/>
			)}

			<CreateAgendaModal
				open={showAgendaModal.open}
				onClose={() => setShowAgendaModal({ open: false, agenda: null })}
				users={users}
				laboratories={laboratories}
				agenda={showAgendaModal.agenda}
			/>
			{selectedAgendaToDelete != null && (
				<DeleteAgendaModal
					queryClientAgenda={queryClientAgenda}
					agenda={selectedAgendaToDelete}
					open={showDeleteModal}
					onClose={() => setShowDeleteModal(false)}
				/>
			)}
		</Col>
	)
}
