import { Override } from "@audiowizard/common"
import generatePicker from "antd/es/date-picker/generatePicker"
import cx from "classnames"
import dayjs, { Dayjs } from "dayjs"
import IMask, { InputMask } from "imask/esm/imask"
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs"
import React, { useEffect, useRef } from "react"

const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)
type AntdDatePickerProps = React.ComponentPropsWithoutRef<typeof AntdDatePicker>

type DateInputOnlyProps = Override<
	AntdDatePickerProps,
	{
		format?: string
		hasError?: boolean
		// Fix un peu dégeux pour <BonLivraisonConfiguration>
		// Passe value dans le onBlur
		onBlur?: (
			e: Parameters<NonNullable<AntdDatePickerProps["onBlur"]>>[0], // Premier argument de onBlur de AntdDatePicker
			value: Dayjs | null | undefined
		) => void
	}
>

/** Sélecteur de date uniquement (SANS label, form-group et erreur) */
export function DateInputOnly({
	className = "form-control",
	format = "DD/MM/YYYY", // Format FR par défaut
	hasError = false,
	allowClear = true,
	onFocus,
	onBlur,
	...props
}: DateInputOnlyProps): JSX.Element {
	// Le composant DatePicker de antd ne donne pas accès à l'élément <input> du DOM. On ne peut le récupérer que depuis les événements onFocus, onBlur...
	// La lib IMask est monté à l'input au focus et enlever au blur. Le warning "Element value was changed outside of mask." peut être ignoré
	// Pour synchroniser la saisie avec le composant, il faut manuellement appeler onChange quand on vide la saisie ou qu'on la remplie
	// Il faut donc aussi vérifier que la date saisie est valide ou que la prop allowClear autorise les valeurs null
	// C'est moche mais antd ne permet pas d'utiliser un composant input custom :(
	const maskRef = useRef<InputMask<any>>()

	const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
		const mask = format.replace(/[ymdhsza]/gi, "0") // Remplace les lettres format de date par des 0 pour le mask ("DD/MM/YYYY" -> '00/00/0000")
		if (maskRef.current) {
			maskRef.current.destroy()
		}
		maskRef.current = IMask(e.target, { mask, lazy: false })
		maskRef.current.on("accept", () => {
			if (maskRef.current == null) return

			maskRef.current.updateValue()

			// Si on vide la saisie, mettre à null si allowClear le permet
			if (maskRef.current.unmaskedValue.length === 0 && allowClear) {
				props.onChange?.(null, "")
			}
		})
		maskRef.current.on("complete", () => {
			maskRef.current?.updateValue()

			// Quand on remplie le champ complétement
			const newDate = dayjs(e.target.value, format)

			// Vérifie que la date est valide et qu'elle n'est pas disabled par la prop disabledDate
			if (newDate.isValid() && (props.disabledDate == null || !props.disabledDate(newDate))) {
				props.onChange?.(newDate, e.target.value)
			}
		})

		onFocus?.(e)
	}
	const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
		maskRef.current?.destroy()

		onBlur?.(e, props.value)
	}

	useEffect(() => {
		// si updatevalue exécute avant que la valeur soit propagé vers le masque, il y a des conflits.
		const timeout = setTimeout(() => {
			try {
				maskRef.current?.updateValue()
			} catch {
				// Parfois throw sur le unmount, on peut ignorer l'erreur
			}
		}, 100)
		return () => clearTimeout(timeout)
	}, [props.value])

	return (
		<AntdDatePicker
			className={cx(className, { "is-invalid": hasError })}
			format={format}
			allowClear={allowClear}
			onFocus={handleFocus}
			onBlur={handleBlur}
			{...props}
		/>
	)
}

type DatePickerProps = DateInputOnlyProps & {
	label: string

	groupClassName?: string
	labelClassName?: string

	error?: string
}
/** Sélecteur de date (AVEC label, form-group et erreur) */
export function DateInput({
	id,
	name,
	label,
	groupClassName = "",
	labelClassName,
	error,
	...props
}: DatePickerProps): JSX.Element {
	const inputId = id ?? name
	return (
		<div className={`form-group ${groupClassName}`}>
			<label htmlFor={inputId} className={labelClassName}>
				{label}
			</label>

			<DateInputOnly id={inputId} name={name} hasError={error != null && error.length > 0} {...props} />

			{error && (
				<p
					className="invalid-feedback d-block" // display block pour forcer l'élément à s'afficher, sinon il est masqué car l'input n'est pas à côté dans le DOM
				>
					{error}
				</p>
			)}
		</div>
	)
}
