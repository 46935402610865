/* eslint-disable react-hooks/exhaustive-deps */
import Chart from "react-apexcharts"
import fr from "apexcharts/dist/locales/fr.json"

const ChartArea = ({ series }) => {
	const options = {
		series: series,
		chart: {
			locales: [fr],
			defaultLocale: "fr",
			type: "area",
			height: 200,
			zoom: {
				enabled: true,
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			type: "datetime",
		},
		stroke: {
			curve: "straight",
		},
		legend: {
			horizontalAlign: "left",
		},
		noData: {
			text: "pas assez d'informations pour produire le graphique",
		},
	}
	return (
		<>
			<Chart options={options} series={options.series} type="area" width="100%" />
		</>
	)
}
ChartArea.defaultProps = {
	legends: null,
	labels: null,
	colors: null,
}

export default ChartArea
