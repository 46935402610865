import { Select } from "antd"
import { useMemo } from "react"
import { partialSearch } from "services/functions"

function UserSelect({ users, record, onChange }) {
	const options = useMemo(() => {
		if (users == null) return []

		if (record["patient.laboratory"] != null) {
			users = users?.filter((x) => x.laboratories.some((item) => item.id === record["patient.laboratory"]))
		}

		return users?.map((u) => ({
			value: u.id,
			label: `${u.firstName} ${u.lastName}`,
		}))
	}, [users, record["patient.laboratory"]])

	return (
		<Select
			showSearch
			style={{ width: "100%" }}
			placeholder="Sélectionner un utilisateur"
			defaultValue={`${record["user.firstName"]} ${record["user.lastName"]}`}
			onChange={onChange}
			filterOption={(input, option) => partialSearch(option.label, input)}
			options={options}
		/>
	)
}

const MainUserCol = (myTeam, editingKey, handleUserChange, queryfilters) => {
	const userRender = (user, record) => {
		return editingKey === record.key ? (
			<UserSelect users={myTeam} record={record} onChange={(value) => handleUserChange(value, record)} />
		) : (
			<strong className="mx-2">
				{" "}
				{record["user.firstName"]} {record["user.lastName"]}{" "}
			</strong>
		)
	}

	return {
		title: null,
		key: "user",
		dataIndex: "patient.user",
		render: userRender,
		filters: myTeam?.map((user) => ({
			text: `${user.firstName} ${user.lastName}`,
			value: user.id,
		})),
		filteredValue: queryfilters?.user || null,
		filterSearch: true,
		validate: (cond) =>
			!cond["user.firstName"] || cond["user.firstName"] === ""
				? "Veuillez renseigner l'audioprothésiste."
				: "",
	}
}

export default MainUserCol
