/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import cx from "classnames"

const CardHeader = ({ header, cta = false, sticky = false }) => {
	return (
		<div className={cx("card", { "position-sticky top-n3 zindex-100": sticky })}>
			<div className="card-header border-0">
				<h3 className="mb-0">
					{header}
					{cta && <span className="float-right">{cta}</span>}
				</h3>
			</div>
		</div>
	)
}

CardHeader.propTypes = {
	header: PropTypes.node.isRequired,
	cta: PropTypes.string,
}

export default CardHeader
