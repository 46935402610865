import { useQuery } from "react-query"
import { PaymentsService, paymentsServiceKey } from "../payments.service"

export default function usePaymentsForInvoice({ invoiceId }: { invoiceId?: string | number }) {
	return useQuery({
		queryFn: () => PaymentsService.get(`saleId=${invoiceId}`),
		queryKey: [paymentsServiceKey, invoiceId],
		enabled: Boolean(invoiceId),
	})
}
