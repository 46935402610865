/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react"
import { createFactureDeSuivi } from "./FacturationSuivi"
import { Modal, Button as ButtonModal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Alert, Col, Row, notification, Tooltip } from "antd"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import API from "../../services/API"
import { isTeletransInvoiceNotDuplicate, urlTeletransmission, isPatientCoverSafe } from "./utilsFSV"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { handleTeletransSuivi, patientAdrSilent } from "./functionsFSV"
import FsvApi from "./api"
import { modeTeletrans } from "./FSV.type"
import { Schedule } from "@audiowizard/common"
import { useFindLastAdviseWithError } from "./TeletransmissionsDeSuivi/UseTeletransmissionsDeSuvi"
import CheckErrorTeletransmissionDeSuivi from "./TeletransmissionsDeSuivi/CheckErrorTeleransmissionDeSuivi"

const BusyLoader = ({ adriCall, message }: { adriCall: boolean; message?: string }): JSX.Element => (
	<div className="overlay-loading-aw" style={{ left: "0", position: "relative" }}>
		<div className="overlay-loading-logo" />
		<div className="overlay-loading-text">{message || "Création de la FSE de contrôle..."}</div>
		{adriCall && (
			<>
				<div className="overlay-loading-text">La couverture ne permet pas le mode Sesam Vital</div>
				<div className="overlay-loading-text">Appel ADRi en cours...</div>
			</>
		)}
	</div>
)
interface ModalTeletransmissionSuiviProps {
	chooseTeletrans: any
	setChooseTeletrans: any
	handleNavLink: any
	schedule: Schedule
}

const ModalTeletransmissionSuivi = ({
	chooseTeletrans,
	setChooseTeletrans,
	handleNavLink,
	schedule,
}: ModalTeletransmissionSuiviProps): JSX.Element => {
	const [error, setError] = useState<any[]>([])
	const { patient, laboratory, user } = useContext(AuthContext)
	const [busy, setBusy] = useState(false)
	const [toLater, setToLater] = useState(false)
	const [adriCall, setAdriCall] = useState(false)
	const [allowSV, setAllowSV] = useState(true)
	const [allowDegrade, setAllowDegrade] = useState(true)

	const queryClient = useQueryClient()

	const [advice, errorsAdvices] = useFindLastAdviseWithError({
		isOpen: chooseTeletrans,
		patient,
		laboratory,
		noDuplicateCheck: true,
	})

	useEffect(() => {
		setError((old) => [...old, ...errorsAdvices])
	}, [errorsAdvices])

	const handleLater = async (event: any): Promise<void> => {
		try {
			setBusy(true)
			setToLater(true)

			if (await isTeletransInvoiceNotDuplicate(patient.id, true)) {
				await API.create("TODOS_API", {
					label: `Télétransmission de contrôle pour ${patient.firstName} ${patient.lastName}`,
					status: "TODO",
					target: "ALL",
					laboratories: [laboratory["@id"]],
					patient: patient["@id"],
					type: "AUTRE",
				})
				await queryClient.invalidateQueries("TODOS_API")

				await createFactureDeSuivi(laboratory, patient, user, schedule, null)
				queryClient.invalidateQueries(["DOCUMENT_API", patient.id, patient.id?.toString()])
				handleNavLink(event, { link: "/evenements/nombres-heures" })
			} else {
				setError((old) => [
					...old,
					"Une télétransmission de suivi en attente d'émission existe déjà pour ce patient",
				])
				setBusy(false)
				setToLater(false)
			}
		} catch (error) {
			setBusy(false)
			setToLater(false)
			console.error(error)
			toast.error("Impossible de créer la facturation de suivi")
		}
	}

	const handleSendTeletrans = async (mode: modeTeletrans): Promise<void> => {
		try {
			setBusy(true)

			// check if cover is secured
			const isSesamVitalePossible = await isPatientCoverSafe(patient)

			// if not call adri
			if (!isSesamVitalePossible) {
				setAdriCall(true)

				const adriResult = await patientAdrSilent({
					idFSV: patient.idFSV,
					finess: laboratory.finess,
					numNatPs: user.adeli,
					idCompany: user.company?.id,
				})
				if (adriResult.error) throw adriResult
			}

			const fse = await handleTeletransSuivi(
				user,
				{
					patient: { ...patient },
					config: {
						prescriber: advice?.prescriber,
					},
				},
				schedule,
				laboratory,
				undefined,
				mode
			)

			if (fse?.numFse) {
				if (mode === modeTeletrans.securise) {
					const updatedTeletrans = await FsvApi.consulterFacture(fse.idFacture, laboratory.finess || "")
					const modeTeletransPasse = FsvApi.recursivelySearchForKey(updatedTeletrans, "modeFacture")
					if (modeTeletransPasse !== "SV") {
						toast.error(
							"La télétransmission est passé en mode dégradé, veuillez consulter FSV pour le rectifier"
						)
					}
				}
				notification.open({
					top: 80,
					message: `FSE n°${fse.numFse} créée !`,
					key: "FSE_RETURN_SUCCESS",
					description: (
						<>
							<div>
								Fse de contrôle créée
								<br />
								Montant amo demandé: {fse.montantAmo}€
							</div>
							<a
								href={urlTeletransmission(user.adeli, laboratory.finess, user.company?.id)}
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn-block btn-sm btn-outline-primary cursor-pointer">
								Passer à la mise en lot et l'émission
							</a>
						</>
					),
					duration: 10,
				})
				handleNavLink(null, {
					link: "/evenements/nombres-heures",
				})
			} else {
				setBusy(false)
				setError((old) => [
					...old,
					"Une télétransmission de suivi en attente d'émission existe déjà pour ce patient",
				])
			}
		} catch (error) {
			console.error(error)
			toast.error("Erreur lors de la télétransmission de suivi")
		} finally {
			setBusy(false)
			setAdriCall(false)
		}
	}

	return (
		<Modal isOpen={chooseTeletrans} className="error-modal" backdrop="static">
			<ModalHeader>
				<div className="modal-custom-header">
					<div className="modal-custom-header">Confirmation</div>
					<span
						style={{ cursor: "pointer", padding: "4px 8px" }}
						className="close-icon"
						onClick={() => {
							setError([])
							setChooseTeletrans(false)
						}}>
						<i className="fas fa-times" />
					</span>
				</div>
			</ModalHeader>
			{busy ? (
				<ModalBody>
					<BusyLoader adriCall={adriCall} message={toLater ? "Préparation de la facture" : ""} />
				</ModalBody>
			) : (
				<>
					<ModalBody>
						<div>Souhaitez-vous réaliser la télétransmission de contrôle ?</div>
						{advice?.prescriber && (
							<>
								<div className="col-sm-12 mt-4  d-inline-block">
									<div className="alert alert-secondary p-1" role="alert">
										<div>Prescription utilisée :</div>
										<div>
											Prescripteur :
											<strong>
												{advice.prescriber?.gender === "HOMME" ? " M." : " Mme."}{" "}
												{advice.prescriber?.lastName} {advice.prescriber?.firstName}
											</strong>
										</div>
										<div>
											Date de prescription:{" "}
											<strong>{dayjs(advice?.dateOfAdvice).format("DD/MM/YYYY")}</strong>
										</div>
									</div>
								</div>
								{laboratory?.finess ? (
									<div className="col-sm-12 mt-1  d-inline-block">
										<div className="alert alert-secondary p-1" role="alert">
											<div>Laboratoire utilisé :</div>
											<div>
												<strong>{laboratory.label}</strong>
											</div>
											<div>
												Finess: <strong>{laboratory.finess}</strong>
											</div>
										</div>
									</div>
								) : null}
							</>
						)}
						<CheckErrorTeletransmissionDeSuivi
							isOpen={chooseTeletrans}
							patient={patient}
							allowTeletransSV={(allow) => setAllowSV(allow)}
							allowTeletransDegrade={(allow) => setAllowDegrade(allow)}
						/>

						{error?.length > 0 && (
							<div className="col-sm-12 mt-4 mb-4 d-inline-block">
								{!(error.length === 1 && !patient.idFSV) && (
									<div>Problèmes à corriger pour pouvoir réaliser la télétransmission :</div>
								)}

								{error.map((v, k) => (
									<Alert key={k} message={v} type="error" className="mb-1" />
								))}
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						<div className="w-100">
							<Row>
								<Col span={12} className="p-1">
									<ButtonModal
										color="secondary"
										disabled={!!error.length || busy || !allowDegrade}
										block
										onClick={() => handleSendTeletrans(modeTeletrans.degrade)}>
										Mode dégradé
									</ButtonModal>
								</Col>
								<Col span={12} className="p-1">
									<ButtonModal
										color="secondary"
										title="Désactivé si SV non disponible"
										disabled={!!error.length || busy || !allowSV}
										block
										onClick={() => handleSendTeletrans(modeTeletrans.securise)}>
										Mode Sésam-Vitale
									</ButtonModal>
								</Col>
							</Row>
							<Row>
								<Col span={12} className="p-1">
									<ButtonModal
										color="danger"
										disabled={busy}
										block
										onClick={() => {
											setError([])
											setChooseTeletrans(false)
											handleNavLink(null, {
												link: "/evenements/nombres-heures",
											})
										}}>
										Non
									</ButtonModal>
								</Col>
								<Col span={12} className="p-1">
									<Tooltip title="En cliquant sur plus tard, une facture à 1 ct sera générée automatiquement et vous pourrez faire la télétransmission par la suite, depuis le poste de l'accueil par exemple">
										<ButtonModal
											color="warning"
											disabled={
												busy || (!(error.length === 1 && !patient.idFSV) && !!error.length)
											}
											block
											onClick={(e) => handleLater(e)}>
											Plus tard
										</ButtonModal>
									</Tooltip>
								</Col>
							</Row>
						</div>
					</ModalFooter>
				</>
			)}
		</Modal>
	)
}

export default ModalTeletransmissionSuivi
