import { Editor as TinyReactEditor, IAllProps as TinyReactEditorProps } from "@tinymce/tinymce-react"
import cx from "classnames"
import { forwardRef, useMemo } from "react"
import { Editor as TinyMCEEditor } from "tinymce"
import {
	getAdditionalToolbarMenuButtonsIds,
	getAllowedContextMenus,
	getAllowedPlugins,
	getAllowedToolbarMenuButtonsIds,
	setupEditor,
} from "./Editor.helpers"
import "./Editor.scss"
import { AdditionalToolbarButtons, ContextMenu } from "./Editor.types"

export type EditorProps = {
	className?: string
	contextMenus?: ContextMenu
	content?: string
	onChange?: (innerHTML: string, editor: TinyMCEEditor) => void
	onSave?: (content: string) => void
	plugins?: {
		additional?: string[]
		ignored?: string[]
	}
	readonly?: boolean
	style?: string
	/** Override tinymce init settings. See https://www.tiny.cloud/docs/configure/ */
	init?: Required<TinyReactEditorProps>["init"]
	toolbarMenuButtons?: {
		additional?: AdditionalToolbarButtons
		ignored?: string[]
	}
}

/**
 * TinyMCE WYSIWYG Editor Wrapper
 */
const Editor = forwardRef<TinyReactEditor, EditorProps>(
	(
		{
			className,
			content,
			onChange,
			readonly = false,
			contextMenus,
			onSave,
			plugins: pluginsProps,
			style,
			init: initProp,
			toolbarMenuButtons: toolbarMenuButtonsProps,
		}: EditorProps,
		ref
	) => {
		// Nested default props
		const plugins = { additional: [], ignored: [], ...pluginsProps }
		const toolbarMenuButtons = { additional: [], ignored: [], ...toolbarMenuButtonsProps }

		const classes: string = cx(
			"editor",
			"justify-content-center",
			"editor-decouverte",
			{ "mb-4": readonly },
			className
		)

		/**
		 * Init the configuration to provide for TinyMCE editor
		 */
		const init = useMemo(() => {
			const additionalPluginsIds = [...plugins.additional]
			if (onSave) {
				additionalPluginsIds.push("save")
			}
			const additionalMenuButtonsIds = getAdditionalToolbarMenuButtonsIds(toolbarMenuButtons.additional, onSave)
			const allowedPlugins = getAllowedPlugins(additionalPluginsIds, plugins?.ignored)
			const allowedToolbarMenuButtons = getAllowedToolbarMenuButtonsIds(additionalMenuButtonsIds)
			const allowedContextMenus = getAllowedContextMenus(contextMenus)

			const setup = (editor: TinyMCEEditor): void => {
				setupEditor(editor, contextMenus, toolbarMenuButtons.additional)
			}

			return {
				forced_root_block: "",
				force_br_newlines: true,
				force_p_newlines: true,
				width: "100%",
				height: "100%",
				content_style: style,
				plugins: allowedPlugins,
				toolbar: allowedToolbarMenuButtons,
				contextmenu: allowedContextMenus,
				language: "fr_FR", // TODO: intl
				save_onsavecallback: onSave,
				setup,
				valid_children: "+body[style]", // https://www.tiny.cloud/docs/configure/content-filtering/#valid_children
				...initProp,
			}
		}, [contextMenus, onSave, plugins, style, toolbarMenuButtons, initProp])

		return (
			<div className={classes}>
				<TinyReactEditor
					ref={ref}
					apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
					value={content}
					onEditorChange={onChange}
					init={init}
				/>
			</div>
		)
	}
)

export default Editor
