import CoverageDemandBadge from "../../../components/CoverageDemandBadge/CoverageDemandBadge"
import { HumanReadableDemandState } from "../../../services/requests/http/userInsuranceCoverageDemandService/userInsuranceCoverageDemandService.model"

const PECCol = (queryfilters) => {
	return {
		title: null,
		key: "pec",
		dataIndex: "patient.pec",
		width: "100px",
		filterSearch: true,
		render: (el) => {
			if (!el) return <></>

			return <CoverageDemandBadge state={el} />
		},
		filters: Object.entries(HumanReadableDemandState).map(([key, value]) => ({
			text: value,
			value: key,
		})),
		filteredValue: queryfilters?.pec || null,
	}
}

export default PECCol
