import { Patient, PatientEquipment } from "@audiowizard/common"
import _ from "lodash"
import { batteryLimit } from "pages/feuille-soin/lpp/handler"
import API from "services/API"
import API_Hiboutik from "services/API_Hiboutik"
import { updateTagsFromPatientEquipment } from "utils/equipment/updateTagsEquipment"

type NewObj = Record<string, any>

type LimitObj = Record<number, number>

type BatteryCount =
	| "Rechargeable"
	| {
			battery: string
			batteryFull: string
			value: number
			limit: number
			remaining: number
	  }

interface EquipmentForAccessoryCount {
	category?: string
	status?: string
	customTags?: Record<string, any> // for patient equipment
	productJsonHiboutik?: Record<string, any>
	ear?: string
	createdAt?: string
	id?: number
	tags?: Record<string, any> // for equipment in sale
}

export default async function accessoryCount(
	patient: Patient,
	equipmentSale: EquipmentForAccessoryCount[]
): Promise<BatteryCount[] | null> {
	const energy: NewObj = {}
	const finalCount: BatteryCount[] = []

	equipmentSale = equipmentSale ?? []

	if (!patient?.patientEquipments?.length) return finalCount

	const allTagsFromHibou = await API_Hiboutik.getAllTags()

	try {
		const patientEquipments: EquipmentForAccessoryCount[] = await getPatientEquipmentsForBatteryCounter(
			patient?.patientEquipments,
			allTagsFromHibou,
			true
		)
		const equipments = _.concat(
			equipmentSale.filter((eq) => !!eq.tags?.energy),
			patientEquipments.filter(
				(pe) => pe.category === "AUDITIF" && ["VENDU", "EXTERIEUR"].includes(pe.status ?? "")
			)
		)
		for (const eq of equipments) {
			const tags = allTagsFromHibou.find((f: Record<string, any>) => f.tag_cat.includes("energy")).tag_details
			const eqEnergyTag = eq.customTags?.energy || eq.tags?.energy
			const eqTagsFromPatientEquipment = eq.productJsonHiboutik?.tags

			const batteryFromEq = tags.find((t: Record<string, any>) => {
				if (eqEnergyTag) return eqEnergyTag === t.tag

				// same as in Equipments.jsx: this prevents crashing on ".find" if eqTagsFromPatientEquipment (eq.productJsonHiboutik?.tags) is not an array
				if (!Array.isArray(eqTagsFromPatientEquipment)) {
					return eqTagsFromPatientEquipment.energy === t.tag || eqTagsFromPatientEquipment.id === t.tag_id
				} else {
					return eqTagsFromPatientEquipment.some(
						(productTag: Record<string, any>) =>
							productTag.energy === t.tag || productTag.tag_id === t.tag_id // return the ones with equal id
					)
				}
			})

			if (!eqEnergyTag && !batteryFromEq) continue

			if (batteryFromEq?.tag.toUpperCase() === "RECHARGEABLE") {
				finalCount.push("Rechargeable")
				continue
			}
			const batteryType = batteryFromEq.tag.match(/(\d{2,3}$)/)[0]

			if (!energy[batteryType]) {
				energy[batteryType] = (batteryLimit as LimitObj)[batteryType]
			} else {
				energy[batteryType] += (batteryLimit as LimitObj)[batteryType]
			}
		}

		const batteryCount: NewObj = await API.find("PATIENTS_API", `${patient.id}/battery_count`)

		for (const entry of Object.entries(batteryCount)) {
			let name = entry[0]
			const count = entry[1]

			if (count == null || typeof count !== "number") continue
			name = name.match(/(\d+)/)![0]

			const limit = energy[name] ?? 0

			finalCount.push({
				battery: name,
				batteryFull: `piles ${name}`,
				value: count,
				limit,
				remaining: limit - count,
			})
		}
		return finalCount
	} catch (error: any) {
		console.error(error)
		return []
	}
}

/**
 * @returns Les équipements auditifs à considérer pour le compte des piles
 */
export async function getPatientEquipmentsForBatteryCounter(
	patientEquipments: PatientEquipment[],
	tags: any,
	needToUpdate?: boolean
): Promise<PatientEquipment[]> {
	if (patientEquipments == null) return []

	const patientEquipmentsForBatteryCounter = []

	const filteredPE: PatientEquipment[] = []

	for (const patientEquipment of patientEquipments) {
		if (
			patientEquipment.category !== "AUDITIF" ||
			!["VENDU", "EXTERIEUR", "SAV"].includes(patientEquipment.status!)
		)
			continue
		const customTags = await updateTagsFromPatientEquipment(patientEquipment, tags, needToUpdate)

		if (customTags?.energy) filteredPE.push({ ...patientEquipment, customTags })
	}

	filteredPE.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime())

	const equipmentGauche = filteredPE.find((pe) => pe.ear === "GAUCHE")
	if (equipmentGauche) patientEquipmentsForBatteryCounter.push(equipmentGauche)

	const equipmentDroite = filteredPE.find((pe) => pe.ear === "DROITE")
	if (equipmentDroite) patientEquipmentsForBatteryCounter.push(equipmentDroite)

	return patientEquipmentsForBatteryCounter
}
