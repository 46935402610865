import { HiboutikProductEntity } from "@audiowizard/common"
import axios from "axios"
import { API_URL } from "config"
import { AvailableSerialNumberType } from "../StockUtils"

export type AvailableDataType = {
	id: number
	label: string
}

export const normalizeString = (str: string): string => {
	return str
		.toLowerCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
}

const getUniqueByKey = (data: any, idKey: string, labelKey: string): AvailableDataType[] => {
	const tmp: any[] = []

	for (const product of data) {
		if (!product?.[idKey] || !product?.[labelKey]) continue

		if (tmp.find((f) => +f.id === +product?.[idKey])) continue
		tmp.push({
			id: product?.[idKey],
			label: product?.[labelKey],
		})
	}
	return tmp
}

export const getAvailableCategories = (
	data: HiboutikProductEntity[] | AvailableSerialNumberType[]
): AvailableDataType[] => {
	return getUniqueByKey(data, "categoryId", "categoryName")
}

export const getAvailableSuppliers = (
	data: HiboutikProductEntity[] | AvailableSerialNumberType[]
): AvailableDataType[] => {
	return getUniqueByKey(data, "supplierId", "supplierName")
}
export const getAvailableBrands = (
	data: HiboutikProductEntity[] | AvailableSerialNumberType[]
): AvailableDataType[] => {
	return getUniqueByKey(data, "brandId", "brandName")
}

export const clearAllHiboutikCache = async (): Promise<void> => {
	try {
		await axios.post(`${API_URL}/products/clear_all_cache`)
	} catch (error) {
		console.error(error)
	}
}
