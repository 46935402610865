import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import {
	CopyQuotePackAdministrationPage,
	EditQuotePackAdministrationPage,
	NewQuotePackAdministrationPage,
} from "features/edition-devis/admin/AdministrationCard"
import PersonnalisationPack from "pages/Settings/QuotationPackList/QuotationPackList"
import { ParametreRoutePrefix } from "router/Personnalisation/routes"
import { Packsroutes, noPrefix } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

function PacksRouter(): JSX.Element {
	useCustomTitle("Paramètres | Packs")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem
				name="Personnalisation des produits"
				component={PersonnalisationPack}
				url={noPrefix(Packsroutes.Packs)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
			<CardItem
				name="Création de pack"
				component={NewQuotePackAdministrationPage}
				url={noPrefix(Packsroutes.CréationPack)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
				onlyVisibleOnExactRoute
			/>

			<CardItem
				name="Edition de pack"
				component={EditQuotePackAdministrationPage}
				url="/packs/edition/:id"
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name="Dupliquer un pack"
				component={CopyQuotePackAdministrationPage}
				url="/packs/dupliquer/:id"
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
				onlyVisibleOnExactRoute
			/>
		</CardWithTabs>
	)
}

export default PacksRouter
