import { useCallback, useContext, useMemo } from "react"
import AuthContext from "../../contexts/AuthContext"
import { toast } from "react-toastify"

export default function useUserInsuranceCoverageCapability() {
	const { patient, user } = useContext(AuthContext)

	const showViolations = useCallback(() => {
		const base = "Le patient doit avoir {{need}} pour demander une PEC."
		const baseUser = "Vous devez avoir {{need}} pour demander une PEC."
		const warn = (message: string) => toast.warning(base.replace("{{need}}", message))
		const warnUser = (message: string) => toast.warning(baseUser.replace("{{need}}", message))

		if (!patient.birthDate) warn("une date de naissance")
		if (!patient.birthRank) warn("un rang de naissance")
		if (!patient.idSecu) warn("un numéro de sécurité sociale")
		if (patient.advices?.length === 0) warn("une prescription renseignée")
		if (patient.patientInsurances?.length === 0) warn("une complémentaire santé")
		if (!patient.mainUser) warn("un audioprothésiste désigné")
		if (!user.adeli) warnUser("un numéro RPPS renseigné dans vos paramètres")
		if (
			patient.patientInsurances &&
			patient.patientInsurances?.length > 0 &&
			!patient.patientInsurances[0].memberShipNumber
		)
			warn("un numéro de contrat complémentaire santé")
		if (
			patient.patientInsurances &&
			patient.patientInsurances.length > 0 &&
			patient.patientInsurances[0].network === null
		)
			warn("un réseau associé à la complémentaire santé")
	}, [patient, user])

	const canInsertDemand = useMemo(() => {
		return (
			patient.birthRank !== null &&
			patient.birthDate !== null &&
			user.adeli &&
			patient &&
			patient.advices &&
			patient.advices.length > 0 &&
			patient.idSecu &&
			patient.patientInsurances &&
			patient.patientInsurances.length > 0 &&
			patient.patientInsurances[0].network &&
			patient.patientInsurances[0].memberShipNumber &&
			patient.patientInsurances[0].userInsuranceCompany &&
			patient.mainUser
		)
	}, [patient, user])

	return { canInsertDemand, showViolations }
}
