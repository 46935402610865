import { SubCompany } from "@audiowizard/common"
import { Table } from "antd"
import { ColumnType } from "antd/lib/table"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useState } from "react"
import { useQuery } from "react-query"
import { Col } from "reactstrap"
import API, { CollectionResponse } from "services/API"
import { formatCurrency } from "services/functions"
import SubCompanyModal from "./SubCompanyModal"

const columns: ColumnType<SubCompany>[] = [
	{
		dataIndex: "legalLabel",
		title: "Raison sociale",
	},
	{
		key: "fullAddress",
		title: "Adresse",
		render: (_, sub) => `${sub.legalAddress}, ${sub.legalZipCode}, ${sub.legalCity}`,
	},
	{
		dataIndex: "siret",
		title: "SIRET",
	},
	{
		dataIndex: "rcs",
		title: "Ville RCS",
	},
	{
		dataIndex: "vatNumber",
		title: "TVA",
	},
	{
		dataIndex: "capitalStock",
		title: "Capital social",
		render: formatCurrency,
	},
]

type SubCompaniesTableProps = {
	onRowClick: (row: SubCompany) => void
}
export function SubCompaniesTable({ onRowClick }: SubCompaniesTableProps): JSX.Element {
	const [page, setPage] = useState(1)

	const { data, isLoading } = useQuery<CollectionResponse<SubCompany[]>>(
		["SUB_COMPANIES_API", { page }],
		async () => await API.findAll<SubCompany[]>("SUB_COMPANIES_API", `?page=${page}`, true)
	)
	const subCompanies = data?.["hydra:member"] ?? []
	const totalSubCompanies = data?.["hydra:totalItems"] ?? 0

	return (
		<Table
			loading={isLoading}
			dataSource={[...subCompanies.map((c) => ({ ...c, key: c["@id"] }))]}
			columns={columns}
			rowClassName="cursor-pointer"
			onRow={(subCompany) => ({ onClick: () => onRowClick(subCompany) })}
			pagination={{ position: ["bottomLeft"], total: totalSubCompanies, current: page, onChange: setPage }}
		/>
	)
}

export default function SubCompanies(): JSX.Element {
	const [modal, setModal] = useState<{ open: boolean; subCompany: SubCompany | null }>({
		open: false,
		subCompany: null,
	})

	return (
		<Col>
			<SectionHeader title="Sous-sociétés">
				<ButtonRounded
					color="primary-outlined"
					onClick={() => setModal({ open: true, subCompany: null })}
					icon="fa-plus"
					type="submit">
					Ajouter une Sous-société
				</ButtonRounded>
			</SectionHeader>
			<SubCompaniesTable onRowClick={(subCompany) => setModal({ open: true, subCompany })} />
			<SubCompanyModal
				open={modal.open}
				onClose={() => setModal({ open: false, subCompany: null })}
				subCompany={modal.subCompany}
			/>
		</Col>
	)
}
