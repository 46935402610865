import { CSSProperties, ReactNode, useState } from "react"
import * as React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import { DocumentationModalData } from "./DocumentationModalData"
import DocumentationModal from "components/effects/DocumentationModal"

type CardProps = React.PropsWithChildren<{
	className?: string
	header?: ReactNode
	customHeader?: ReactNode
	/** @deprecated Use children instead (`<Card> Content here </Card>`) */
	body?: ReactNode
	link?: string
	label?: string
	hasHeader?: boolean
	fluid?: boolean
	icon?: string
	classNameCardHeader?: string
	visite?: boolean
	evenement?: boolean
	cta?: ReactNode
	cardStyle?: CSSProperties
	dataCy?: string
	documentationUrl?: string
	isFullHeight?: boolean
}>
function Card({
	className,
	header,
	customHeader,
	body,
	children,
	link,
	label,
	hasHeader = true,
	fluid = true,
	icon,
	classNameCardHeader = "card-header d-flex justify-content-between align-items-center",
	visite = false,
	evenement = false,
	cta = false,
	dataCy,
	cardStyle,
	documentationUrl,
	isFullHeight = false,
}: CardProps): JSX.Element {
	const content = body ?? children

	const [isDocumentationModalOpen, setIsDocumentationModalOpen] = useState(false)

	const classes: string = cx("card", { "card-fluid": fluid }, className)

	if (visite) {
		return (
			<div className={classes} style={cardStyle}>
				<div className="card-header">{header}</div>
				<div className="card-body mb-0">{content}</div>
			</div>
		)
	}
	if (evenement) {
		return (
			<div className={classes} style={cardStyle}>
				<div className="card-header card-header-evenement d-flex justify-content-between">
					<h4 className="mb-0 my-auto">{header}</h4>
					{cta}
				</div>
				<div className="card-body">
					<div className="mb-0">{content}</div>
				</div>
			</div>
		)
	} else if (link && label) {
		return (
			<div className={classes} style={cardStyle}>
				<div className="card-header">
					<h3 className="mb-0">{header}</h3>
				</div>
				<div className="card-body">
					<div className="mb-0">{content}</div>
					<Link to={link}>{label}</Link>
				</div>
			</div>
		)
	}
	return (
		<>
			<div className={classes} style={cardStyle}>
				{hasHeader && (
					<div className={classNameCardHeader}>
						<>
							{header ? (
								<h4 className="mb-0" data-cy={dataCy}>
									{header}
								</h4>
							) : (
								customHeader
							)}
							{cta}
						</>
						{icon && (
							<div>
								<div className="actions mb-0">
									<a href="#!" className="action-item">
										<i className={"fad fa-" + icon + " mr-1 fa-2x text-primary"} />
									</a>
								</div>
							</div>
						)}
						{documentationUrl && ( //  info-circle fa-3x=
							<div>
								<div title="Informations" className="actions mb-0">
									<button
										type="button"
										onClick={() => setIsDocumentationModalOpen(true)}
										className="action-item">
										<i className={"fad fa-info-circle mr-1 fa-3x text-primary"} />
									</button>
								</div>
							</div>
						)}
					</div>
				)}
				<div className="card-body">
					<div className={`mb-0 ${isFullHeight ? "h-100" : ""}`}>{content}</div>
				</div>
			</div>
			{documentationUrl && (
				<DocumentationModal
					title="Informations"
					icon="info-circle"
					isOpen={isDocumentationModalOpen}
					onClose={() => setIsDocumentationModalOpen(false)}>
					<DocumentationModalData documentationUrl={documentationUrl} />
				</DocumentationModal>
			)}
		</>
	)
}

Card.defaultProps = {}

export default Card
