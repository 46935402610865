import { Override } from "@audiowizard/common"
import { Select, SelectProps } from "antd"
import cx from "classnames"
import React, { useMemo } from "react"
import { partialSearch } from "services/functions"
import spacetime from "spacetime"
import soft from "timezone-soft"

// Taken here https://github.com/ndom91/react-timezone-select and adapted to fit audiowizard

const allTimezones = {
	"Pacific/Midway": "Midway Island, Samoa",
	"Pacific/Honolulu": "Hawaii",
	"America/Juneau": "Alaska",
	"America/Boise": "Mountain Time",
	"America/Dawson": "Dawson, Yukon",
	"America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
	"America/Phoenix": "Arizona",
	"America/Chicago": "Central Time",
	"America/Regina": "Saskatchewan",
	"America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
	"America/Belize": "Central America",
	"America/Detroit": "Eastern Time",
	"America/Bogota": "Bogota, Lima, Quito",
	"America/Caracas": "Caracas, La Paz",
	"America/Santiago": "Santiago",
	"America/St_Johns": "Newfoundland and Labrador",
	"America/Sao_Paulo": "Brasilia",
	"America/Tijuana": "Tijuana",
	"America/Montevideo": "Montevideo",
	"America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
	"America/Godthab": "Greenland",
	"America/Los_Angeles": "Pacific Time",
	"Atlantic/Azores": "Azores",
	"Atlantic/Cape_Verde": "Cape Verde Islands",
	GMT: "UTC",
	"Europe/London": "Edinburgh, London",
	"Europe/Dublin": "Dublin",
	"Europe/Lisbon": "Lisbon",
	"Africa/Casablanca": "Casablanca, Monrovia",
	"Atlantic/Canary": "Canary Islands",
	"Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
	"Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
	"Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
	"Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
	"Africa/Algiers": "West Central Africa",
	"Europe/Bucharest": "Bucharest",
	"Africa/Cairo": "Cairo",
	"Europe/Helsinki": "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
	"Europe/Athens": "Athens, Istanbul, Minsk",
	"Asia/Jerusalem": "Jerusalem",
	"Africa/Harare": "Harare, Pretoria",
	"Europe/Moscow": "Moscow, St. Petersburg, Volgograd",
	"Asia/Kuwait": "Kuwait, Riyadh",
	"Africa/Nairobi": "Nairobi",
	"Asia/Baghdad": "Baghdad",
	"Asia/Tehran": "Tehran",
	"Asia/Dubai": "Abu Dhabi, Muscat",
	"Asia/Baku": "Baku, Tbilisi, Yerevan",
	"Asia/Kabul": "Kabul",
	"Asia/Yekaterinburg": "Ekaterinburg",
	"Asia/Karachi": "Islamabad, Karachi, Tashkent",
	"Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
	"Asia/Kathmandu": "Kathmandu",
	"Asia/Dhaka": "Astana, Dhaka",
	"Asia/Colombo": "Sri Jayawardenepura",
	"Asia/Almaty": "Almaty, Novosibirsk",
	"Asia/Rangoon": "Yangon Rangoon",
	"Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
	"Asia/Krasnoyarsk": "Krasnoyarsk",
	"Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
	"Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
	"Asia/Taipei": "Taipei",
	"Australia/Perth": "Perth",
	"Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
	"Asia/Seoul": "Seoul",
	"Asia/Tokyo": "Osaka, Sapporo, Tokyo",
	"Asia/Yakutsk": "Yakutsk",
	"Australia/Darwin": "Darwin",
	"Australia/Adelaide": "Adelaide",
	"Australia/Sydney": "Canberra, Melbourne, Sydney",
	"Australia/Brisbane": "Brisbane",
	"Australia/Hobart": "Hobart",
	"Asia/Vladivostok": "Vladivostok",
	"Pacific/Guam": "Guam, Port Moresby",
	"Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
	"Asia/Kamchatka": "Kamchatka, Marshall Islands",
	"Pacific/Fiji": "Fiji Islands",
	"Pacific/Auckland": "Auckland, Wellington",
	"Pacific/Tongatapu": "Nuku'alofa",
}

type TimezoneOption = {
	value: string
	label: string
	abbrev?: string
	altName?: string
	offset?: number
}

type TimezoneInputProps = Override<
	SelectProps<string>,
	{
		id: string
		label: string
		error?: string

		groupClassName?: string
		labelClassName?: string
		selectClassName?: string

		labelStyle?: "original" | "altName" | "abbrev"
	}
>
export default function TimezoneInput({
	id,
	label,
	error,
	groupClassName = "",
	labelClassName = "",
	selectClassName = "",
	labelStyle = "original",
	value,
	...props
}: TimezoneInputProps): JSX.Element {
	const options = useMemo(
		() =>
			Object.entries(allTimezones)
				.map((zone) => {
					const now = spacetime.now(zone[0])
					const tz = now.timezone()
					const tzStrings = soft(zone[0])

					let label = ""

					//@ts-ignore
					const abbr = now.isDST() ? tzStrings[0].daylight?.abbr : tzStrings[0].standard?.abbr
					const altName = now.isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name

					const min = tz.current.offset * 60
					const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? "00" : Math.abs(min % 60))
					const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${zone[1]}`

					switch (labelStyle) {
						case "original":
							label = prefix
							break
						case "altName":
							label = `${prefix} ${altName?.length ? `(${altName})` : ""}`
							break
						case "abbrev":
							label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ""}`
							break
						default:
							label = `${prefix}`
					}

					return {
						value: tz.name,
						label: label,
						offset: tz.current.offset,
						abbrev: abbr,
						altName: altName,
					}
				})
				.sort((a, b) => a.offset - b.offset),
		[labelStyle]
	)

	const findFuzzyTz = (zone: string): TimezoneOption => {
		let currentTime = spacetime.now("GMT")
		currentTime = spacetime.now(zone)

		return options
			.filter((tz: TimezoneOption) => tz.offset === currentTime.timezone().current.offset)
			.map((tz: TimezoneOption) => {
				let score = 0
				if (
					currentTime.timezones[tz.value.toLowerCase()] &&
					!!currentTime.timezones[tz.value.toLowerCase()].dst === currentTime.timezone().hasDst
				) {
					if (tz.value.toLowerCase().indexOf(currentTime.tz.substr(currentTime.tz.indexOf("/") + 1)) !== -1) {
						score += 8
					}
					if (tz.label.toLowerCase().indexOf(currentTime.tz.substr(currentTime.tz.indexOf("/") + 1)) !== -1) {
						score += 4
					}
					if (tz.value.toLowerCase().indexOf(currentTime.tz.substr(0, currentTime.tz.indexOf("/")))) {
						score += 2
					}
					score += 1
				} else if (tz.value === "GMT") {
					score += 1
				}
				return { tz, score }
			})
			.sort((a, b) => b.score - a.score)
			.map(({ tz }) => tz)[0]
	}

	const parseTimezone = (zone: string): string => {
		return (
			options.find((tz) => tz.value === zone)?.value ?? (zone.indexOf("/") !== -1 ? findFuzzyTz(zone).value : "")
		)
	}

	return (
		<div className={cx("form-group", groupClassName)}>
			<label htmlFor={id} className={labelClassName}>
				{label}
			</label>
			<Select
				id={id}
				className={cx(
					"form-control w-100 removeantd-class antd-ad-d-padding",
					{ "is-invalid": error },
					selectClassName
				)}
				showSearch
				filterOption={(search, option) => partialSearch(option!.label as string, search)}
				options={options.map(({ label, value }) => ({ label, value }))}
				value={value != null ? parseTimezone(value) : ""}
				{...props}
			/>

			{error && (
				<p
					className="invalid-feedback d-block" // display block pour forcer l'élément à s'afficher, sinon il est masqué car l'input n'est pas à côté dans le DOM
				>
					{error}
				</p>
			)}
		</div>
	)
}
