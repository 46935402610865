import dayjs from "dayjs"
import IsoWeek from "dayjs/plugin/isoWeek"
import advancedFormat from "dayjs/plugin/advancedFormat"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { toast } from "react-toastify"
import { Laboratory } from "@audiowizard/common"
import { getCookie, removeFromCookie, setCookie } from "../../services/CacheSystem/Cookies"

dayjs.extend(IsoWeek)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
require("dayjs/locale/fr")

interface LaboratoryAttendance {
	"@id": string
	"@type": string
	id: number
	day: string
	timeStart: string
	timeEnd: string
	laboratory: Laboratory
}

/**
 * Get the numeric value of a French day
 * @param frenchDay
 * @returns number
 */
const getDayNumericValue = (frenchDay: string): number => {
	if (!frenchDay || frenchDay === "") return -1

	const frenchDaysOrder: Record<string, number> = {
		LUNDI: 1,
		MARDI: 2,
		MERCREDI: 3,
		JEUDI: 4,
		VENDREDI: 5,
		SAMEDI: 6,
		DIMANCHE: 7,
	}

	return frenchDaysOrder[frenchDay] || -1
}

/**
 * Return a bool that indicates if the attendance as past based on a week analysis
 * @param attendance
 * @param currentDate
 * @returns boolean
 */
const attendanceAsPast = (attendance: LaboratoryAttendance, currentDate: Date): boolean => {
	return getDayNumericValue(attendance?.day || "") < dayjs().isoWeekday()
		? true
		: attendance?.timeEnd < currentDate.toTimeString()
}

/**
 * Compare two attendance and return the one that is the closest to the current day
 * @param attendance1
 * @param attendance2
 * @returns LaboratoryAttendance
 */
const compareAttendance = (
	attendance1: LaboratoryAttendance,
	attendance2: LaboratoryAttendance
): LaboratoryAttendance => {
	const attendance1Day: number = getDayNumericValue(attendance1?.day || "")
	const attendance2Day: number = getDayNumericValue(attendance2?.day || "")

	if (attendance1Day > attendance2Day) {
		return attendance2
	} else if (attendance1Day < attendance2Day) {
		return attendance1
	}

	return attendance1?.timeStart < attendance2?.timeStart ? attendance1 : attendance2
}

/**
 * Warn the user about no attendance and tell him that he is considered on
 * the default laboratory and keep track of the users that have been warned
 * using warnAboutNoAttendanceUsersIds cookie, containing the ids of the users
 * that have been warned.
 * @param laboratory
 * @param userId
 */
export const warnAboutNoAttendance = (laboratory: Laboratory | undefined, userId: number): void => {
	const warnedUsersIds = getCookie("warnedAboutNoAttendanceUsersIds")

	if (warnedUsersIds?.includes(`-${userId.toString()}-`)) return

	toast.warn(`Aucune présence sur les laboratoires, vous êtes considéré par défaut sur ${laboratory?.label}`)
	setCookie("warnedAboutNoAttendanceUsersIds", userId.toString())
}

/**
 * Find the laboratory that the user is currently attending
 * @param LaboratoryAttendance
 * @param userId
 * @returns laboratory.id
 */
export const findActiveLaboratoryFromUserId = async (LaboratoryAttendance: any[], userId: number): Promise<number> => {
	try {
		const currentDate: Date = new Date()

		for (const attendance of LaboratoryAttendance) {
			if (
				dayjs().locale("fr").format("dddd").toUpperCase() === attendance?.day &&
				dayjs(currentDate).isBetween(
					`${dayjs(currentDate).format("YYYY-MM-DD")} ${attendance?.timeStart}`,
					`${dayjs(currentDate).format("YYYY-MM-DD")} ${attendance?.timeEnd}`
				)
			) {
				removeFromCookie("warnedAboutNoAttendanceUsersIds", userId.toString())
				return attendance.laboratory
			}
		}

		/* No attendance ongoing atm, looking for the next one in the week (attendance after the current day) */
		let closestAttendance: LaboratoryAttendance | null = null
		for (const attendance of LaboratoryAttendance) {
			if (!closestAttendance) {
				closestAttendance = !attendanceAsPast(attendance, currentDate) ? attendance : null
				continue
			}
			closestAttendance = !attendanceAsPast(attendance, currentDate)
				? compareAttendance(closestAttendance, attendance)
				: closestAttendance
		}

		/* No attendances found for the end of the week, now looking for the next week (attendance before the current day) */
		for (const attendance of LaboratoryAttendance) {
			if (!closestAttendance) {
				closestAttendance = attendance
				continue
			}
			closestAttendance = compareAttendance(closestAttendance, attendance)
		}

		if (closestAttendance) {
			warnAboutNoAttendance(closestAttendance.laboratory, userId)
			return closestAttendance.laboratory.id || -1
		}

		/* No attendance found at all, return undefined */
		return -1
	} catch (error) {
		console.error("Error occurred:", error)
		return -1
	}
}
