import { LeftBar } from "awui"
import { useHistory } from "react-router-dom"
import "../../awui/LeftBar/LeftBar.scss"
import FrillWidget from "components/frill/FrillWidget"
import useHasRole from "components/Hooks/useHasRole"

const { Link, Separator } = LeftBar

const SettingsLeftBar = (): JSX.Element => {
	const history = useHistory()

	const isManager = useHasRole("ROLE_MANAGER")
	const isFranchisedManager = useHasRole("ROLE_MANAGER_FRANCHISED")

	return (
		<LeftBar>
			<Separator />
			<Link to="/parametres/personnalisation/options" icon="cogs" label="Personnalisation" />
			{(isManager || isFranchisedManager) && (
				<Link to="/parametres/marque-blanche" icon="tag" label="Marque blanche" />
			)}
			<Link to="/parametres/mutuelles" icon="hand-holding" label="Mutuelles" />
			<Link to="/parametres/prescripteurs" icon="user-nurse" label="Prescripteurs" />
			<Link to="/parametres/modeles" icon="paste" label="Modèles" />
			{(isManager || isFranchisedManager) && (
				<Link to="/parametres/rappels-sms" icon="sms" label="Rappels SMS" />
			)}
			{(isManager || isFranchisedManager) && (
				<Link to="/parametres/types-rendez-vous" icon="calendar-alt" label="Type de rdv" />
			)}
			{(isManager || isFranchisedManager) && (
				<Link to="/parametres/statuts" icon="filter" label="Statuts et filtres" />
			)}

			<Link to="/parametres/catalogues/produits" icon="book-medical" label="Catalogues" />
			{(isManager || isFranchisedManager) && (
				<Link to="/parametres/packs" icon="box" label="Packs" />
			)}
			{(isManager || isFranchisedManager) && (
				<Link to="/parametres/caisse/paiements" icon="sack-dollar" label="Caisse" />
			)}
			<Separator />
			<button onClick={() => history.push("/")} className="leftbar__link leftbar__link--collapser">
				<span className="leftbar__link__icon fad fa-fw fa-long-arrow-left" />
				<span className="leftbar__link__label">Quitter</span>
			</button>
			<FrillWidget />
		</LeftBar>
	)
}

export default SettingsLeftBar
