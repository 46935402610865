import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { PrescripteursRoutes, noPrefix } from "./routes"

import useCustomTitle from "components/Hooks/useTitle"
import Prescripteurs from "../../pages/mon-compte/MesPrescripteurs"
import { ParametreRoutePrefix } from "router/Parametre/routes"

function PrescripteursRouter(): JSX.Element {
	useCustomTitle("Paramètres | Prescripteurs")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem
				name="Prescripteurs"
				url={noPrefix(PrescripteursRoutes.Prescripteurs)}
				component={Prescripteurs}
			/>
		</CardWithTabs>
	)
}

export default PrescripteursRouter
