import { useEffect } from "react"
import { useMutation } from "react-query"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import API from "services/API"
import LoadingModal from "./ModalLoading"

export default function DoctolibZipper() {
	let history = useHistory()

	const { mutate: getPatient, isLoading } = useMutation(
		async (patient) => {
			const patientWithDoctolibId = await API.find("PATIENT_DOCTOLIB_API", patient.doctolib_id)
			// if (patientWithDoctolibId.status === 200) {
			// 	history.push({
			// 		pathname: `/fiche-patient/${patientWithDoctolibId.id}`,
			// 	})
			// }
			return patientWithDoctolibId
		},
		{
			retry: 1,
			retryDelay: 5000,
			onError: (err) => {
				console.log(err)
				toast.error("Le patient n'est pas encore créé, veuillez-réessayer plus tard")
			},
			onSuccess(patientWithDoctolibId) {
				history.push({
					pathname: `/fiche-patient/${patientWithDoctolibId.id}`,
				})
			},
		}
	)

	const redirectToPatient = async (patient) => {
		const pmsId = patient?.pms_id?.split("^")
		if (patient?.pms_id != null && pmsId[3] !== "Doctolib") {
			const patientId = pmsId[0]
			try {
				const patientInDb = await API.find("PATIENTS_API", patientId)
				history.push({
					pathname: `/fiche-patient/${patientInDb.id}`,
				})
			} catch (err) {
				toast.error("Le patient ne semble pas exister, veuillez réessayer")
			}
		} else {
			getPatient(patient)
		}
	}

	useEffect(() => {
		window.zipper("onOpenPatient", async function (patient) {
			await redirectToPatient(patient)
		})

		window.zipper("onInitPatient", async function (patient) {
			await redirectToPatient(patient)
		})
	}, [])
	return <LoadingModal isOpen={isLoading} message="Création du dossier patient en cours..." />
}
