import API from "../../../API"
import { USER_INSURANCE_CREDENTIALS } from "../../../../config"
import { UserInsuranceCredentialsModel } from "./userInsuranceCredentials.model"

export const userInsuranceCredentialsServiceBase = "/user_insurance_credentials"
export const userInsuranceCredentialsServiceKey = "USER_INSURANCE_CREDENTIALS"

export const userInsuranceCredentialsService = {
	list: async () => await API.findAll<UserInsuranceCredentialsModel[]>("USER_INSURANCE_CREDENTIALS"),
	create: async (data: { login: string; password: string; network: string }) => {
		const { status } = await API.create("USER_INSURANCE_CREDENTIALS", data)
		return status >= 200 && status < 300
	},
	update: async ({ id, login, password }: { login: string; password: string; id: number }) => {
		const { status } = await API.update("USER_INSURANCE_CREDENTIALS", id, { login, password })
		return status >= 200 && status < 300
	},
	delete: async (id: number) => {
		const { status } = await API.delete(`${userInsuranceCredentialsServiceBase}/${id}`)
		return status >= 200 && status < 300
	},
}
