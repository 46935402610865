/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, notification, Switch } from "antd"
import axios from "axios"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import useCustomTitle from "components/Hooks/useTitle"
import { useContext, useEffect, useMemo, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import uuid from "react-uuid"
import { Col, Row } from "reactstrap"
import CardHeader from "../../components/utils/CardHeader"
import { LOGISTIC_PRODUCTS_API, LOGISTIQUE_API } from "../../config"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import API_Hiboutik from "../../services/API_Hiboutik"
import { formatDateForDB, getIdFromIri } from "../../services/functions"
import BonLivraisonConfiguration from "./Components/Configuration"
import { createTodoForLogistic } from "./Components/PatientEquipmentSelect"
import Product from "./Components/ProductList"
import AsyncSelectProducts from "./Components/ProductSelect"
import { lockState } from "./Utilities"
import DocumentUploader from "pages/fiche-patient/Documents/Modal.DocumentUploader"
import { toast } from "react-toastify"
import DocumentModal from "pages/fiche-patient/Documents/Modal.DocumentViewer"

const NouveauBL = ({ match }) => {
	useCustomTitle("Bon de livraison | Nouveau")

	const { user, laboratory } = useContext(AuthContext)
	const [initLoading, setInitLoading] = useState(true)
	const [selectedProduct, setSelectedProduct] = useState({})
	const [productList, setProductList] = useState([])
	const [productsBusy, setProductsBusy] = useState({}) // Stores error state from <Product> components
	const [productsError, setProductsError] = useState({})
	const [loading, setLoading] = useState(false)
	const [busy, setBusy] = useState(false)
	const [ascendingFilter, setAscendingFilter] = useState(false)
	const [addAnnexModal, setAddAnnexModal] = useState(false)
	const [modifyAnnexModal, setModifyAnnexModal] = useState(false)

	const history = useHistory()
	const queryClient = useQueryClient()
	const defaultData = {
		label: "",
		dateEdition: "",
		dateReceipt: "",
		dateDepot: "",
		supplier: -1,
		brand: -1,
		laboratory: -1,
		warehouse_id: -1,
		inventory_input_id: -1,
		logistic_id: -1,
		logistic_iri: "",
	}
	const [data, setData] = useState(defaultData)

	const [bl, setBl] = useState({
		suppliers: [],
		categories: [],
		sizes: [],
		products: [],
		brands: [],
	})

	// Check if it at least one product has an error
	const productHasError = useMemo(() => Object.values(productsError).some((e) => e /*=== true*/), [productsError])
	const isTransfer = useMemo(() => data.label?.toUpperCase().includes("TRANSFERT"), [data])

	const { mutate: updateLogisticWithDocument } = useMutation(
		async (doc) => {
			if (!data?.logistic_id) return null

			await API.update("LOGISTIQUE_API", data.logistic_id, { annex: doc })
			return doc
		},
		{
			onSuccess: async (doc) => {
				setData((old) => ({ ...old, annex: doc }))
				await queryClient.invalidateQueries("LOGISTIQUE_API")
			},
			onError: () => toast.error("L'ajout de document a échoué"),
		}
	)

	const handleHiboutikError = (error) => {
		const errorCode = error?.response?.data?.code
		const errorDesc = error?.response?.data?.error_description
		const errorDetails = error?.response?.data?.details

		if (errorDetails?.quantity && errorCode === 6) {
			notification.error({
				message: "Erreur",
				description: (
					<>
						Impossible de mettre à jour la quantité ou le status du produit.
						<br />
						Le produit à probablement déjà été vendu.
					</>
				),
			})
		} else if (errorDetails?.inventory_input_detail_id && errorCode === 5) {
			notification.error({
				message: "Erreur",
				description: <>Impossible de mettre à jour le produit, veuillez réessayer.</>,
			})
		} else {
			notification.error({
				message: "Erreur",
				description: (
					<>
						Une erreur inconnu s'est produite.
						<br />
						Référence : {errorCode} {errorDesc}
					</>
				),
			})
		}
		throw error
	}

	const deleteInvalidProduct = async () => {
		try {
			if (!data.inventory_input_id || !productList.length) {
				return
			}
			const res = await API_Hiboutik.inventoryOrderDetail(data.inventory_input_id)
			const tmp = [...productList].filter((f) => f.inventory_input_detail_id)

			const hibdata = res.data || []
			if (hibdata.length === tmp.length) {
				return
			}
			console.error("Synchronization error detected.")
			const received = [...tmp].map((v) => v.inventory_input_detail_id)
			const doubles = hibdata
				.filter((f) => !received.includes(f.inventory_input_detail_id))
				.map((v) => v.inventory_input_detail_id)

			for (const inventory_input_detail_id of doubles) {
				try {
					await API_Hiboutik.inventoryInputDetailUpdate(inventory_input_detail_id, "quantity", "0").catch(
						handleHiboutikError
					)
				} catch (error) {
					console.error(error)
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		const supl = {}

		// selectedProduct.category_name may be undefined, so explicitely check for null/undefined
		if (!selectedProduct.hasOwnProperty("with_serial_number") && selectedProduct.category_name != null) {
			if (
				[
					"EMBOUT AVEC SN",
					"ECOUTEUR AVEC SN",
					"APPAREILS AUDITIFS",
					"ACCESSOIRES",
					"ACCESSOIRE",
					"AUDITIF",
					"ANCRAGE OSSEUX AVEC SN",
					"PROTECTION ET PREVENTION AVEC SN",
					"ACCESSOIRES IMPLANTS AVEC SN",
					"AUTRES AVEC SN",
					"CROS",
				].includes(selectedProduct.category_name.toUpperCase())
			) {
				supl.with_serial_number = true
				supl.lock_serial_quantity = true
			} else {
				supl.with_serial_number = false
				supl.lock_serial_quantity = true
			}
			setSelectedProduct((old) => ({ ...old, ...supl }))
		}
		if (!selectedProduct.hasOwnProperty("quantity")) {
			supl.quantity = 1
			setSelectedProduct((old) => ({ ...old, ...supl }))
		}
		if (!selectedProduct.hasOwnProperty("received")) {
			supl.received = 0
			setSelectedProduct((old) => ({ ...old, ...supl }))
		}
		if (!selectedProduct.hasOwnProperty("logistic_product_id")) {
			supl.logistic_product_id = -1
			setSelectedProduct((old) => ({ ...old, ...supl }))
		}
	}, [selectedProduct])

	const init = async () => {
		try {
			setInitLoading(true)

			const brands = await API_Hiboutik.getBrands()
			const suppliers = await API_Hiboutik.getSuppliers()
			const categories = await API_Hiboutik.getCategory()
			const sizes = await API_Hiboutik.getAllSizes()
			const products = await API_Hiboutik.getAllProducts()
			const afterSaleItems = await API.findAll("SAV_API", "?status[]=EN ATTENTE REPARATION&status[]=RÉPARÉ")

			const nonClosedSalesList = await API_Hiboutik.getOpenSales(laboratory.warehouseIdHiboutik || 1)
			const nonClosedSalesProducts = []

			for (const sale of nonClosedSalesList) {
				try {
					if (+sale.total) {
						const detail = await API_Hiboutik.getSale(sale.sale_id)

						for (const product of detail.line_items) {
							nonClosedSalesProducts.push({ ...product })
						}
					}
				} catch (error) {
					console.error(error)
				}
			}

			setBl({
				suppliers,
				categories,
				sizes: sizes?.data,
				nonClosedSalesProducts,
				afterSaleItems,
				brands,
				products,
			})

			return { suppliers, categories, sizes, nonClosedSalesProducts, afterSaleItems }
		} catch (error) {
			console.error(error)
		} finally {
			setInitLoading(false)
		}
	}

	const filteredProducts = useMemo(() => {
		return bl.products.filter(
			(product) =>
				(data.brand !== -1 ? product.product_brand === data.brand : true) &&
				(data.supplier !== -1 ? product.product_supplier === data.supplier : true)
		)
	}, [bl.products, data.brand, data.supplier])

	useEffect(() => {
		;(async function () {
			const bl = await init()

			setLoading(true)
			if (match?.params?.id === "nouveau") {
				if (laboratory && laboratory.warehouseIdHiboutik) {
					setData({
						...defaultData,
						laboratory: laboratory["@id"],
						warehouse_id: +laboratory.warehouseIdHiboutik,
					})
				} else {
					setData(defaultData)
				}
			} else if (/\d+/.test(match?.params?.id)) {
				const tmp = []
				const result = await API.find("LOGISTIQUE_API", match?.params?.id)

				setData({
					...defaultData,
					label: result?.numero,
					dateEdition: result.dateEdition,
					dateReceipt: result.dateReceipt,
					depotEndDate: result?.depotEndDate,
					supplier: result?.supplier ?? -1,
					brand: result?.brand ?? -1,
					laboratory: user?.laboratories?.find((f) => f.warehouseIdHiboutik === result?.stockIdHiboutik)?.[
						"@id"
					],
					warehouse_id: +result.stockIdHiboutik,
					inventory_input_id: result.stockOrderIdHiboutik,
					logistic_id: result.id,
					status: result.status,
					depot: result.depot,
					annex: result.annex,
				})

				for (const p of result.logisticProducts) {
					let lock = 0x0

					const reservedClosedSale = bl.nonClosedSalesProducts.find(
						(f) =>
							f.product_id === p.productId &&
							(p.productSizeId ? f.product_size === p.productSizeId : 1) &&
							(p.type === "WITH_SERIAL_NUMBER" ? f.serial_number === p.productSerialNumber : 1)
					)
					const inAfterSale = bl.afterSaleItems.find(
						(f) =>
							f?.informations?.productId === p.productId &&
							f?.informations?.serialNumber === p?.productSerialNumber
					)
					if (inAfterSale) {
						if (inAfterSale.status === "RÉPARÉ") {
							lock |= lockState.SAV_DONE
						} else {
							lock |= lockState.SAV
						}
					}
					if (reservedClosedSale) {
						lock |= lockState.VENTE_ATTENTE
					}
					if (p.state === "RECEIVED_SOLD" || p.isSold) {
						lock |= lockState.VENDU
					}
					if (
						p?.depotState?.includes("RENVOYE") ||
						p?.depotState?.includes("AVOIR_RECU") ||
						p.isReturned ||
						p.isReturnedViaInventory
					) {
						lock |= lockState.RENVOYE
					}
					if (p?.isTransferred) {
						lock |= lockState.TRANSFERRED
					}
					tmp.push({
						lock,
						...p,
						product_size_id: p?.productSizeId,
						product_serial_number: p?.productSerialNumber,
						quantity: p?.productQuantity,
						state: p?.state,
						type: p?.type,
						inventory_input_detail_id: p?.inventoryInputDetailId ?? null,
						with_serial_number: p?.type === "WITH_SERIAL_NUMBER" ? true : false,
						received: p?.state?.includes("RECEIVED") || p?.state?.includes("RENVOYE") ? true : false,
						product_id: p?.productId,
						logistic_product_id: p?.id,
						logistic_product_iri: p?.["@id"],
						depotState: p?.depotState,
						depotDateEnd: p?.depotDateEnd,
						isReturned: p.isReturned,

						isSold: p.isSold,
						isCreditRequested: p.isCreditRequested,
						isCreditReceived: p.isCreditReceived,
						original_state: {
							product_serial_number: p?.productSerialNumber,
							quantity: p?.productQuantity,
							state: p?.state,
							type: p?.type,
						},
						isReturnedViaInventory: p.isReturnedViaInventory,
						patientEquipment: p.patientEquipment,
						key: uuid(),
					})
				}
				setProductList(tmp)
			}
			setLoading(false)
		})()
	}, [match])

	const updateOrCreate = (logistic_id, product) => {
		return new Promise(async (resolve, reject) => {
			try {
				let logistic_product_id = product?.logistic_product_id || -1
				const dataPost = {
					logistic: `/logistics/${logistic_id}`,
					inventoryInputDetailId: product?.inventory_input_detail_id ?? null,
					productId: product.product_id,
					productSizeId: product.size_id || product.product_size_id,
					productQuantity: +product.quantity,
					productSerialNumber: product.product_serial_number,
					depotDateEnd: product.depotState && product.depotDateEnd,
					type: product?.with_serial_number ? "WITH_SERIAL_NUMBER" : "QUANTITY",
					laboratory: data.laboratory || null,
					isDepot: product.depotState ? true : false,
				}
				if (product.logistic_product_id !== -1) {
					await API.update("LOGISTIC_PRODUCTS_API", product.logistic_product_id, dataPost)
				} else {
					const res = await API.create("LOGISTIC_PRODUCTS_API", dataPost)
					logistic_product_id = res?.data?.id
				}
				resolve(logistic_product_id)
				return
			} catch (error) {
				console.error(error)
				reject(error)
				return error
			}
		})
	}

	useEffect(() => {
		;(async function () {
			if (!data || data.logistic_id === -1) {
				return
			}
			const allReceived = productList.length === productList.filter((f) => f.received).length
			// deprecated depotState
			const hasDepot = productList.filter((f) => f.depotState === "EN_DEPOT" || f.isDepot).length > 0
			const updateObject = {}

			if (hasDepot && !data.depot) {
				updateObject.depot = true
			}
			if (!hasDepot && data.depot) {
				updateObject.depot = false
			}
			if (allReceived && data.status !== "VALIDE") {
				updateObject.status = "VALIDE"
			}
			if (!allReceived && data.status === "VALIDE") {
				updateObject.status = "RELIQUAT"
			}
			try {
				if (updateObject.depot || updateObject.status) {
					await API.update("LOGISTIQUE_API", data.logistic_id, updateObject)
					setData((old) => ({ ...old, ...updateObject }))
				}
			} catch (error) {
				console.error()
			}
		})()
	}, [productList])

	const addProduct = async (product, res_inventory_input_id = null, res_logistic_id = null) => {
		let inventory_input_id = res_inventory_input_id || data.inventory_input_id || -1
		let logistic_id = res_logistic_id || data.logistic_id || -1

		if (!product.product_id) {
			return
		}
		product.patientEquipment = []

		try {
			if (inventory_input_id === -1) {
				const res = await API_Hiboutik.inventoryOrderCreate(data.label, data.warehouse_id, data.supplier).catch(
					handleHiboutikError
				)
				inventory_input_id = res.data.inventory_input_id
				setData((old) => ({ ...old, inventory_input_id }))
			}
			if (logistic_id === -1) {
				const res = await API.create("LOGISTIQUE_API", {
					numero: data.label,
					type: "BON_LIVRAISON",
					status: "RELIQUAT",
					stockOrderIdHiboutik: inventory_input_id,
					stockIdHiboutik: data.warehouse_id,
					dateEdition: data.dateEdition,
					dateReceipt: data.dateReceipt,
					depotEndDate: data?.depotEndDate,
					supplier: data.supplier !== -1 ? data.supplier : null,
					brand: data.brand !== -1 ? data.brand : null,
				})
				logistic_id = res.data.id
				window.history.pushState("", "", "/bon-de-livraison/" + logistic_id)
				setData((old) => ({ ...old, logistic_id }))
			}
			const logistic_product_id = await updateOrCreate(logistic_id, product)
			setProductList((old) => [...old, { ...product, logistic_product_id, key: uuid() }])
			return { inventory_input_id, logistic_id }
		} catch (error) {
			console.error(error)
		}
	}

	const addToHiboutik = async (inventory_input_id, product) => {
		return API_Hiboutik.inventoryOrderAddProduct(
			inventory_input_id,
			product.product_id,
			product.product_size_id || product.size_id,
			product.quantity,
			product.product_serial_number
		)
	}

	const updateProduct = async (type, index, value) => {
		return new Promise(async (resolve, reject) => {
			const products_tmp = [...productList]
			let product = products_tmp[index]
			let inventory_input_detail_id = product?.inventory_input_detail_id ?? null

			setProductsBusy((old) => ({ ...old, [index]: true }))
			try {
				if (type === "RECEIVED_STATE") {
					if (!inventory_input_detail_id && value === "DELETE") {
						if (product?.logistic_product_id >= 0) {
							await axios.delete(LOGISTIC_PRODUCTS_API + "/" + product.logistic_product_id)
							products_tmp.splice(index, 1)
							setProductList(products_tmp)

							// When deleting product, remove error it's state
							delete productsError[index]
							setProductsError({ ...productsError })

							return
						}
					} else if (!inventory_input_detail_id && value) {
						const result_hiboutik = await addToHiboutik(data.inventory_input_id, product).catch(
							handleHiboutikError
						)
						products_tmp[index].inventory_input_detail_id = result_hiboutik.data.inventory_input_detail_id
						products_tmp[index].received = true
						products_tmp[index].state = "RECEIVED"
						await API_Hiboutik.validateInventoryOrder(data.inventory_input_id).catch(handleHiboutikError)

						// Update associated patient equipment
						for (const pe of products_tmp[index].patientEquipment) {
							await API.update("PATIENT_EQUIPMENTS_API", pe.id, {
								status: "ESSAI",
								warehouseIdHiboutik: data.warehouse_id,
							})

							// Send Todo
							const productDetails = bl.products.find(
								(p) => p.product_id === products_tmp[index].product_id
							)
							await createTodoForLogistic(
								pe.patient["@id"],
								data.laboratory,
								productDetails.product_model,
								productDetails.sizes?.[products_tmp[index].product_size_id]?.size_name
							)
						}
					} else {
						await API_Hiboutik.inventoryInputDetailUpdate(inventory_input_detail_id, "quantity", "0").catch(
							handleHiboutikError
						)
						products_tmp[index].inventory_input_detail_id = null
						products_tmp[index].depotState = null
						products_tmp[index].received = false
						products_tmp[index].state = "WAITING"

						// Update associated patient equipment
						for (const pe of products_tmp[index].patientEquipment) {
							await API.update("PATIENT_EQUIPMENTS_API", pe.id, {
								status: "ATTENTE",
								warehouseIdHiboutik: null,
							})
						}
					}
				}

				if (type === "SERIAL_NUMBER") {
					if (inventory_input_detail_id)
						await API_Hiboutik.inventoryInputDetailUpdate(
							inventory_input_detail_id,
							"product_serial_number",
							value
						).catch(handleHiboutikError)
					products_tmp[index].product_serial_number = value
				}

				if (type === "QUANTITY") {
					if (inventory_input_detail_id)
						await API_Hiboutik.inventoryInputDetailUpdate(
							inventory_input_detail_id,
							"quantity",
							value
						).catch(handleHiboutikError)
					products_tmp[index].quantity = value
				}

				if (type === "DEPOT") {
					if (!products_tmp[index].depotDateEnd) {
						products_tmp[index].depotDateEnd = data.depotEndDate
					}
					if (!value) {
						products_tmp[index].depotDateEnd = null
						products_tmp[index].depotDateStart = null
					}
					if (value) {
						products_tmp[index].depotDateStart = formatDateForDB()
					}
					products_tmp[index].depotState = value ? "EN_DEPOT" : null
					products_tmp[index].isDepot = value ? true : false
				}
				if (type === "DEPOT_DATE") {
					products_tmp[index].depotDateEnd = formatDateForDB(value)
				}
				products_tmp[index].logistic_product_id = await updateOrCreate(data.logistic_id, products_tmp[index])

				setProductList([...products_tmp])
				resolve()
				return
			} catch (error) {
				console.error(error)
				reject()
				return
			} finally {
				setProductsBusy((old) => ({ ...old, [index]: false }))
			}
		})
	}

	const sortProducts = () => {
		if (productList.length === 0 || bl.products.length === 0) return

		const productListCopy = [...productList]
		for (const product of productListCopy) {
			const productModel = bl.products.find((p) => p.product_id === product.product_id)?.product_model
			product.product_model = productModel
		}

		productListCopy.sort(
			(a, b) =>
				a.product_model.localeCompare(b.product_model, "en", { sensitivity: "base" }) *
				(ascendingFilter ? 1 : -1)
		)

		setProductList(productListCopy)
		setAscendingFilter(!ascendingFilter)
	}

	return (
		<>
			{(loading || initLoading) && (
				<div className="overlay-loading-aw">
					<div className="overlay-loading-logo" />
					<div className="overlay-loading-text">
						Chargement {loading ? "du bon de livraison..." : "du catalogue de produits..."}...
					</div>
				</div>
			)}
			<CardHeader
				header={
					<Row className="d-flex align-items-center">
						<Col className="d-flex justify-content-start">
							<i className="fad fa-file mr-4 text-primary align-self-center" />
							{match?.params?.id === "nouveau" ? "Nouveau" : "Edition d'un"} bon de livraison
						</Col>
						<div className="header-nouveau-bl">
							<button
								type="button"
								className={
									"btn " +
									(productList.filter((f) => f.received).length ? "btn-outline-danger" : "btn-danger")
								}
								disabled={busy || productList.filter((f) => f.received).length || !productList.length}
								onClick={async () => {
									if (!productList.filter((f) => f.received).length) {
										try {
											for (const product of productList) {
												await axios.delete(
													LOGISTIC_PRODUCTS_API + "/" + product.logistic_product_id
												)
											}
											await axios.delete(LOGISTIQUE_API + "/" + data.logistic_id)

											await queryClient.invalidateQueries("PURCHASE_ORDERS_API") // there's a relation between purchase order and logistic
										} catch (error) {
											alert("Une erreur est survenue pendant la suppression du bon de livraison")
											console.error(error)
										} finally {
											history.push("/bon-de-livraison")
										}
									}
								}}>
								Supprimer le BL
							</button>
							<button
								type="button"
								className="btn btn-primary"
								key={busy}
								disabled={
									Object.values(productsBusy || {})?.find((f) => f === true) ||
									busy ||
									productHasError ||
									!data.dateReceipt ||
									!data.dateEdition ||
									!data.label ||
									data.warehouse_id === -1 ||
									data.supplier === -1 ||
									data.logistic_id === -1
								}
								onClick={async (e) => {
									e.target.disabled = true
									setBusy(true)
									await deleteInvalidProduct()
									setTimeout(() => {
										history.push("/bon-de-livraison")
									}, 1250)
								}}>
								{busy && <i className={"fad fa-spinner-third fa-spin "} />} Valider le BL
							</button>
							<button
								type="button"
								className="btn btn-info"
								onClick={() => (data?.annex ? setModifyAnnexModal(true) : setAddAnnexModal(true))}
								disabled={productList.length === 0}>
								{data?.annex ? "Modifier l'annexe" : "Ajouter une annexe"}
							</button>
						</div>
					</Row>
				}
			/>

			<div className="card mt-4 p-4 ">
				<BonLivraisonConfiguration
					data={data}
					setData={setData}
					suppliers={bl.suppliers}
					brands={bl.brands}
					productList={productList}
					busy={busy}
					isTransfer={isTransfer}
				/>
			</div>

			<DocumentUploader
				onClose={() => setAddAnnexModal(false)}
				isOpen={addAnnexModal}
				documentType="BL_ANNEX"
				refreshDocuments={(document) => updateLogisticWithDocument(document)}
			/>

			<DocumentModal
				onClose={() => setModifyAnnexModal(false)}
				isOpen={modifyAnnexModal}
				document={{ id: getIdFromIri(data?.annex) }}
				refreshDocuments={(document) => updateLogisticWithDocument(document)}
			/>

			{
				<div className="card mt-4 p-4 bl-add-product">
					{" "}
					{isTransfer ? (
						<Alert
							style={{ fontSize: "18px" }}
							type="warning"
							message="Ceci est un bon de livraison généré automatiquement suite au transfert de stock. Aucun
							produit ne peut être ajouté manuellement."
							showIcon
						/>
					) : (
						<>
							<h6>Sélectionner un produit à ajouter</h6>
							{(data.supplier === -1 ||
								data.warehouse_id === -1 ||
								!data.label ||
								!data.dateReceipt ||
								!data.dateEdition) && (
								<div className="my-2">
									<Alert
										type="error"
										className="text-center"
										message="Vous devez compléter les informations avant de pouvoir ajouter un produit"
										showIcon
									/>
								</div>
							)}
							{filteredProducts.length === 0 && (
								<Alert
									type="info"
									className="text-center"
									message={"Liste de produits vide pour le combo marque / fournisseur sélectionné"}
									showIcon
								/>
							)}
							<div>
								<div className="row">
									<div className="col">
										<label>Produit</label>
										<AsyncSelectProducts
											products={filteredProducts}
											sizeList={bl.sizes}
											categoryList={bl.categories}
											supplierList={bl.suppliers}
											brandsList={bl.brands}
											setSelectedProduct={setSelectedProduct}
											selectedProduct={selectedProduct}
											disabled={
												busy ||
												data.supplier === -1 ||
												data.warehouse_id === -1 ||
												!data.label ||
												!data.dateReceipt ||
												!data.dateEdition
											}
										/>
									</div>
									<div className="col-2">
										<label>&nbsp;</label>
										<Switch
											checkedChildren="numéro de série"
											unCheckedChildren="numéro de série"
											checked={selectedProduct.with_serial_number}
											disabled={true}
										/>
									</div>
									<div className="col-2">
										<label>Quantité à ajouter</label>
										<input
											type="number"
											className="form-control form-control-sm"
											style={{ height: "32px" }}
											value={selectedProduct.quantity || 0}
											onChange={(e) => {
												const value = e.target.value
												setSelectedProduct((old) => ({ ...old, quantity: parseInt(value) }))
											}}
											disabled={
												busy ||
												!(
													data.supplier &&
													data.warehouse_id &&
													data.label &&
													data.dateReceipt &&
													data.dateEdition
												)
											}
										/>
									</div>
									<div className="col-1">
										<label>&nbsp;</label>
										<button
											type="button"
											className="btn btn-block btn-outline-primary p-0"
											style={{ height: "32px" }}
											disabled={
												busy ||
												!(
													data.supplier &&
													data.warehouse_id &&
													data.label &&
													data.dateReceipt &&
													data.dateEdition
												)
											}
											onClick={async () => {
												try {
													if (selectedProduct.quantity >= 100) {
														const hasConfirmed = await confirmWithModal({
															title: "Attention",
															textAlign: "left",
															text: (
																<span>
																	Êtes-vous vraiment sûr de vouloir ajouter{" "}
																	<strong>{selectedProduct.quantity}</strong>{" "}
																	{selectedProduct.product_model} ?
																</span>
															),
														})
														if (!hasConfirmed) {
															return
														}
													}
													if (selectedProduct.with_serial_number) {
														let inventory_input_id = null,
															logistic_id = null

														for (let i = 0; i < (selectedProduct.quantity || 1); i++) {
															let {
																inventory_input_id: tmp_inventory_input_id,
																logistic_id: tmp_logistic_id,
															} = await addProduct(
																{ ...selectedProduct, quantity: 1 },
																inventory_input_id,
																logistic_id
															)
															inventory_input_id = tmp_inventory_input_id
															logistic_id = tmp_logistic_id
														}
													} else await addProduct({ ...selectedProduct })
													setSelectedProduct({})
												} catch (error) {
													console.error(error)
												}
											}}>
											<i className="fad fa-plus" />
										</button>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			}
			<div className="card p-4 mt-4">
				<div className="bl-item">
					<div className="row  align-items-center">
						<div className="col-4">
							<span className="cursor-pointer" onClick={() => sortProducts()}>
								<i className="fad fa-sort" />
							</span>{" "}
							Produit
						</div>
						<div className="col-2">Numéro de série ou quantité</div>
						<div className="col-2">Patient</div>
						<div className="col-1" style={{ maxWidth: "170px" }}>
							<Switch
								checkedChildren="tout déréceptionner"
								unCheckedChildren="tout réceptionner"
								disabled={busy}
								style={{ width: "100%" }}
								checked={productList.filter((f) => f.received).length === productList.length}
								onChange={async (state) => {
									setBusy(true)
									for (const key in productList) {
										try {
											if (
												(productList[key].with_serial_number &&
													!productList[key].product_serial_number) ||
												(state && productList[key].received) ||
												(!state && !productList[key].received) ||
												productList[key]?.lock
											) {
												continue
											}

											if (!state && productList[key].patientEquipment?.length > 0) continue
											await updateProduct("RECEIVED_STATE", key, state)
										} catch (error) {
											console.error(error)
										}
									}
									setBusy(false)
								}}
							/>
						</div>
						<div className="col-1" style={{ maxWidth: "170px" }}>
							<Switch
								checkedChildren="tout ferme"
								unCheckedChildren="tout dépôt"
								style={{ width: "100%" }}
								disabled={busy || !data.depotEndDate}
								checked={
									productList.filter(
										(f) => f.received && !f.lock && (f.depotState === "EN_DEPOT" || f.isDepot)
									).length === productList.filter((f) => f.received && !f.lock).length
								}
								onChange={async (state) => {
									setBusy(true)
									for (const key in productList) {
										try {
											if (
												!productList[key].with_serial_number ||
												!productList[key].received ||
												(productList[key].received &&
													(productList[key].depotState === "EN_DEPOT" ||
														productList[key].isDepot) &&
													state) ||
												(productList[key].received &&
													!productList[key].depotState &&
													!productList[key].isDepot &&
													!state) ||
												productList[key].lock
											) {
												continue
											}
											await updateProduct("DEPOT", key, state)
										} catch (error) {
											console.error(error)
										}
									}
									setBusy(false)
								}}
							/>
						</div>
						<div className="col-1 " style={{ maxWidth: "70px" }}>
							action
						</div>
					</div>
				</div>

				{!initLoading &&
					productList.map((product, index) => (
						<Product
							product={product}
							index={index}
							busy={busy}
							key={product.key}
							productList={productList}
							setProductList={setProductList}
							updateFunction={updateProduct}
							setProductsBusy={setProductsBusy}
							productBusy={productsBusy[index]}
							data={bl}
							configuration={data}
							onChange={(product) => {
								productList[index] = product
								setProductList([...productList])
							}}
							onError={(error) => setProductsError({ ...productsError, [index]: error })}
						/>
					))}
			</div>
		</>
	)
}

export default NouveauBL
