import { Patient } from "@audiowizard/common"
import AuthContext from "contexts/AuthContext"
import { useContext } from "react"

interface UsePatient {
	updatePatientChange: (paramName: string, newValue: any) => void
	setPatientInContext: (patient: Patient) => void
}

const usePatient = (): UsePatient => {
	const {
		patient,
		setPatient,
		patientChange,
		setPatientChange,
		setPatientGenes,
		setPatientMedicals,
		setPatientLookingFors,
		setPatientAcouphenes,
		setPatientInsurances,
		setEventOtoscopies,
	} = useContext(AuthContext)

	const updatePatientChange = (paramName: string, newValue: any): void => {
		let changeStatus = patientChange[paramName]
		if (!changeStatus) {
			changeStatus = {
				old: patient[paramName as keyof Patient],
				new: undefined,
			}
		}
		changeStatus.new = newValue
		setPatientChange((old) => ({ ...old, [paramName]: changeStatus }))
		setPatient((old) => ({ ...old, [paramName]: newValue }))
	}

	const setPatientInContext = (patient: Patient): void => {
		setPatient(patient)
		setPatientChange({})
		setPatientGenes(patient?.patientGenes ?? [])
		setPatientMedicals(patient?.patientMedicals ?? [])
		setPatientLookingFors(patient?.patientLookingFors ?? [])
		setPatientAcouphenes(patient?.patientAcouphenes ?? [])
		setPatientInsurances(patient?.patientInsurances ?? [])
		setEventOtoscopies([])
	}

	return { updatePatientChange, setPatientInContext }
}

export default usePatient
