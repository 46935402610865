import { Select } from "antd"
import { SelectProps } from "antd/lib/select"
// country-flag-icons n'a pas de definitions typescript
// @ts-ignore
import Flags from "country-flag-icons/react/3x2"
import { partialSearch } from "services/functions"
import { Override } from "@audiowizard/common"

export const CountryCodeLabels = {
	AF: "Afghanistan",
	AX: "Îles Åland",
	AL: "Albanie",
	DZ: "Algérie",
	AS: "Samoa américaines",
	AD: "Andorre",
	AO: "Angola",
	AI: "Anguilla",
	AQ: "Antarctique",
	AG: "Antigua-et-Barbuda",
	AR: "Argentine",
	AM: "Arménie",
	AW: "Aruba",
	AU: "Australie",
	AT: "Autriche",
	AZ: "Azerbaïdjan",
	BS: "Bahamas",
	BH: "Bahreïn",
	BD: "Bangladesh",
	BB: "Barbade",
	BY: "Biélorussie",
	BE: "Belgique",
	BZ: "Belize",
	BJ: "Bénin",
	BM: "Bermudes",
	BT: "Bhoutan",
	BO: "Bolivie",
	BA: "Bosnie-Herzégovine",
	BW: "Botswana",
	BV: "Île Bouvet",
	BR: "Brésil",
	VG: "British Virgin Islands",
	IO: "Territoire britannique de l’Océan Indien",
	BN: "Brunei Darussalam",
	BG: "Bulgarie",
	BF: "Burkina Faso",
	BI: "Burundi",
	KH: "Cambodge",
	CM: "Cameroun",
	CA: "Canada",
	CV: "Cap-Vert",
	KY: "Iles Cayman",
	CF: "République centrafricaine",
	TD: "Tchad",
	CL: "Chili",
	CN: "Chine",
	HK: "Hong Kong",
	MO: "Macao",
	CX: "Île Christmas",
	CC: "Îles Cocos",
	CO: "Colombie",
	KM: "Comores",
	CG: "République du Congo",
	CD: "République démocratique du Congo",
	CK: "Îles Cook",
	CR: "Costa Rica",
	CI: "Côte d’Ivoire",
	HR: "Croatie",
	CU: "Cuba",
	CY: "Chypre",
	CZ: "République tchèque",
	DK: "Danemark",
	DJ: "Djibouti",
	DM: "Dominique",
	DO: "République dominicaine",
	EC: "Équateur",
	EG: "Égypte",
	SV: "Salvador",
	GQ: "Guinée équatoriale",
	ER: "Érythrée",
	EE: "Estonie",
	ET: "Éthiopie",
	FK: "Îles Falkland",
	FO: "Îles Féroé",
	FJ: "Fidji",
	FI: "Finlande",
	FR: "France",
	GF: "Guyane française",
	PF: "Polynésie française",
	TF: "Terres australes et antarctiques françaises",
	GA: "Gabon",
	GM: "Gambie",
	GE: "Géorgie",
	DE: "Allemagne",
	GH: "Ghana",
	GI: "Gibraltar",
	GR: "Grèce",
	GL: "Groenland",
	GD: "Grenade",
	GP: "Guadeloupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernesey",
	GN: "Guinée",
	GW: "Guinée-Bissau",
	GY: "Guyane",
	HT: "Haïti",
	HM: "Îles Heard-et-MacDonald",
	VA: "Saint-Siège (Vatican)",
	HN: "Honduras",
	HU: "Hongrie",
	IS: "Islande",
	IN: "Inde",
	ID: "Indonésie",
	IR: "Iran",
	IQ: "Irak",
	IE: "Irlande",
	IM: "Ile de Man",
	IL: "Israël",
	IT: "Italie",
	JM: "Jamaïque",
	JP: "Japon",
	JE: "Jersey",
	JO: "Jordanie",
	KZ: "Kazakhstan",
	KE: "Kenya",
	KI: "Kiribati",
	KP: "Corée du Nord",
	KR: "Corée du Sud",
	KW: "Koweït",
	KG: "Kirghizistan",
	LA: "Laos",
	LV: "Lettonie",
	LB: "Liban",
	LS: "Lesotho",
	LR: "Libéria",
	LY: "Libye",
	LI: "Liechtenstein",
	LT: "Lituanie",
	LU: "Luxembourg",
	MK: "Macédoine",
	MG: "Madagascar",
	MW: "Malawi",
	MY: "Malaisie",
	MV: "Maldives",
	ML: "Mali",
	MT: "Malte",
	MH: "Îles Marshall",
	MQ: "Martinique",
	MR: "Mauritanie",
	MU: "Maurice",
	YT: "Mayotte",
	MX: "Mexique",
	FM: "Micronésie",
	MD: "Moldavie",
	MC: "Monaco",
	MN: "Mongolie",
	ME: "Monténégro",
	MS: "Montserrat",
	MA: "Maroc",
	MZ: "Mozambique",
	MM: "Myanmar",
	NA: "Namibie",
	NR: "Nauru",
	NP: "Népal",
	NL: "Pays-Bas",
	NC: "Nouvelle-Calédonie",
	NZ: "Nouvelle-Zélande",
	NI: "Nicaragua",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	NF: "Île Norfolk",
	MP: "Îles Mariannes du Nord",
	NO: "Norvège",
	OM: "Oman",
	PK: "Pakistan",
	PW: "Palau",
	PS: "Palestine",
	PA: "Panama",
	PG: "Papouasie-Nouvelle-Guinée",
	PY: "Paraguay",
	PE: "Pérou",
	PH: "Philippines",
	PN: "Pitcairn",
	PL: "Pologne",
	PT: "Portugal",
	PR: "Puerto Rico",
	QA: "Qatar",
	RE: "Réunion",
	RO: "Roumanie",
	RU: "Russie",
	RW: "Rwanda",
	BL: "Saint-Barthélemy",
	SH: "Sainte-Hélène",
	KN: "Saint-Kitts-et-Nevis",
	LC: "Sainte-Lucie",
	MF: "Saint-Martin (partie française)",
	SX: "Saint-Martin (partie néerlandaise)",
	PM: "Saint-Pierre-et-Miquelon",
	VC: "Saint-Vincent-et-les Grenadines",
	WS: "Samoa",
	SM: "Saint-Marin",
	ST: "Sao Tomé-et-Principe",
	SA: "Arabie Saoudite",
	SN: "Sénégal",
	RS: "Serbie",
	SC: "Seychelles",
	SL: "Sierra Leone",
	SG: "Singapour",
	SK: "Slovaquie",
	SI: "Slovénie",
	SB: "Îles Salomon",
	SO: "Somalie",
	ZA: "Afrique du Sud",
	GS: "Géorgie du Sud et les îles Sandwich du Sud",
	SS: "Sud-Soudan",
	ES: "Espagne",
	LK: "Sri Lanka",
	SD: "Soudan",
	SR: "Suriname",
	SJ: "Svalbard et Jan Mayen",
	SZ: "Eswatini",
	SE: "Suède",
	CH: "Suisse",
	SY: "Syrie",
	TW: "Taiwan",
	TJ: "Tadjikistan",
	TZ: "Tanzanie",
	TH: "Thaïlande",
	TL: "Timor-Leste",
	TG: "Togo",
	TK: "Tokelau",
	TO: "Tonga",
	TT: "Trinité-et-Tobago",
	TN: "Tunisie",
	TR: "Turquie",
	TM: "Turkménistan",
	TC: "Îles Turques-et-Caïques",
	TV: "Tuvalu",
	UG: "Ouganda",
	UA: "Ukraine",
	AE: "Émirats Arabes Unis",
	GB: "Royaume-Uni",
	US: "États-Unis",
	UM: "Îles mineures éloignées des États-Unis",
	UY: "Uruguay",
	UZ: "Ouzbékistan",
	VU: "Vanuatu",
	VE: "Venezuela",
	VN: "Viêt Nam",
	VI: "Îles Vierges américaines",
	WF: "Wallis-et-Futuna",
	EH: "Sahara occidental",
	YE: "Yémen",
	ZM: "Zambie",
	ZW: "Zimbabwe",
}
// Trié par label
const countryCodeLabelsSorted = {
	FR: "France",
	...Object.fromEntries(
		Object.entries(CountryCodeLabels)
			.filter(([code]) => code !== "FR")
			.sort(([, a], [, b]) => a.localeCompare(b))
	),
}

/**
 * Code ISO 3166-1 alpha-2
 */
export type CountryCode = keyof typeof CountryCodeLabels

type CountryInputProps = Override<
	SelectProps<CountryCode>,
	{
		id: string
		label: string

		groupClassName?: string
		labelClassName?: string
		selectClassName?: string

		value: CountryCode
		onChange: (value: CountryCode) => any
	}
>

export default function CountryInput({
	id,
	label,
	groupClassName = "",
	labelClassName = "",
	selectClassName = "",
	value,
	onChange,
	...props
}: CountryInputProps): JSX.Element {
	return (
		<div className={`form-group ${groupClassName}`}>
			<label htmlFor={id} className={labelClassName}>
				{label}
			</label>
			<Select
				id={id}
				className={`form-control w-100 removeantd-class antd-add-padding ${selectClassName}`}
				showSearch
				filterOption={(search, option) => partialSearch(option!.label as string, search)}
				value={value}
				onChange={onChange}
				{...props}>
				{Object.entries(countryCodeLabelsSorted).map(([code, label]) => {
					const FlagComponent = Flags[code]

					return (
						<Select.Option key={code} label={label} value={code}>
							{FlagComponent != null && <FlagComponent title={label} className="country-input-flag" />}{" "}
							{label}
						</Select.Option>
					)
				})}
			</Select>
		</div>
	)
}
