import { Document } from "@audiowizard/common"
import { Tooltip } from "antd"

type SmartButtonDocumentProps = React.PropsWithChildren<{
	document: Document
	key?: string
	className?: string
}>

export const isDocumentDisabled = (config: any): boolean => !config || (Array.isArray(config) && config.length === 0)

// to work properly the button should have the style and option disabled
// style={isDocumentDisabled(document.config) ? { pointerEvents: "none" } : {}}
// disabled={isDocumentDisabled(document.config)}>

export const ButtonDocumentTooltip = ({
	document,
	children,
	key,
	className,
}: SmartButtonDocumentProps): JSX.Element => {
	return (
		<Tooltip
			key={key}
			className={className}
			title={
				isDocumentDisabled(document?.config) ? (
					<>
						<div>Détails du devis manquant, le devis n’est pas éditable.</div>
						<div>Possible devis issu d’une migration ou d’une importation.</div>
					</>
				) : (
					""
				)
			}>
			<div>{children}</div>
		</Tooltip>
	)
}
