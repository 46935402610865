import * as React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Select } from "antd"
import cx from "classnames"
import { LocaleNamespace } from "config/intl/helpers"
import { capitalizeFirstLetter } from "utils/types/primitives/String/capitalizeFirstLetter"
import "./EditionSelect.scss"

interface Options {
	label: React.ReactNode
	value: string
}

export type PropsEditionSelect = {
	className?: string
	options: Options[]
	onChange: ((value: string) => void) | undefined
	value: string
}

/**
 * EditionSelect Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param options
 * @constructor
 * @return {React.FC<PropsEditionSelect>}
 */
const EditionSelect: React.FC<PropsEditionSelect> = ({ className, options, onChange, value }) => {
	const { t } = useTranslation(LocaleNamespace.Common)
	const classes: string = cx("form-control w-100 removeantd-class antd-add-padding", className)
	const placeholder = `${capitalizeFirstLetter(t("select"))}...`

	return (
		<Select
			placeholder={placeholder}
			onChange={onChange}
			options={options}
			style={{ width: "100%" }}
			className={classes}
			value={value}
		/>
	)
}

EditionSelect.defaultProps = {
	options: [],
}

EditionSelect.propTypes = {
	className: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.node.isRequired,
			value: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	onChange: PropTypes.func,
	value: PropTypes.string.isRequired,
}

export default EditionSelect
