import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { HiboutikProductEntity, Transfer as TransferType, TransferProduct } from "@audiowizard/common"
import { Table } from "antd"
import axios from "axios"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { TRANSFER_API } from "config"
import { useEffect, useMemo, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useHistory, useRouteMatch } from "react-router-dom"
import { toast } from "react-toastify"
import API from "services/API"
import useStockAvailable from "../Hooks/useStockAvailable"
import ClearUnaivalableProductsButton from "../Inventory/ClearUnaivalableProducts"
import DeleteLogisticForm from "../Shared/DeleteLogisticFormButton"
import { isProductStillAvailable } from "../StockUtils"
import ModalAddProducts from "./Modal.AddProducts"
import "./style.scss"

type MatchParams = {
	id: number
}

const Transfer = (): JSX.Element => {
	const queryClient = useQueryClient()

	const match = useRouteMatch()
	const history = useHistory()
	const params = match.params as MatchParams

	const [showModal, setShowModal] = useState(false)
	const [addedProduct, setAddedProduct] = useState<Record<string, any>[]>([])
	const [loading, setLoading] = useState(false)
	const [transferProducts, setTransferProducts] = useState<any[]>([])

	const timeoutRef = useRef<NodeJS.Timeout>(null)
	const [hasPendingAction, setHasPendingAction] = useState(false)

	const iriToDelete = useRef<string | null>(null)

	const { data: productCatalog, isLoading: loadingCatalog } = useQuery(
		"PRODUCT_CATALOG",
		async () => await API.findAll<HiboutikProductEntity[]>("PRODUCTS_API")
	)

	const getPdfPreview = async (): Promise<string> => {
		return await API.custom<string>(`/transfers/${params.id}/preview_pdf`)
	}

	const { data: transfer, isLoading: loadingTransfer } = useQuery(
		["TRANSFER", params.id],
		async () => await API.find<TransferType>("TRANSFER_API", params.id),
		{
			onSuccess: () => {
				refetchTransferProducts()
			},
		}
	)

	const { isLoading: loadingTransferProduct, refetch: refetchTransferProducts } = useQuery(
		["TRANSFER_PRODUCTS", params.id, productCatalog?.length],
		async () => {
			const products = await API.findAll<TransferProduct[]>(
				"TRANSFER_PRODUCT_API",
				`?transfer=${params.id}&pagination=false?order[createdAt]=desc`
			)

			return products.map((product, _arrayIndex: number) => {
				const productDetail = productCatalog?.find((cproduct) => +cproduct.id === +product.productId)

				const sizeName = productDetail?.sizeDetails?.find(
					(csize) => csize.sizeId === product.productSizeId
				)?.sizeName

				return {
					...productDetail,
					sizeName,
					...product,
					key: product["@id"],
					_arrayIndex,
				}
			})
		},
		{
			enabled: false,
			onSuccess: (products: any[]) => {
				if (!transfer?.validatedAt) {
					for (const i in products) {
						const product = products[i]

						//@ts-ignore
						products[i].notAvailableAnymore = isProductStillAvailable(stockAvailableHook.data, product)
					}
				}

				setTransferProducts(products)
			},
			onError: (error) => {
				toast.error("Impossible de récupérer la liste des produits à transférer")
				console.error(error)
			},
		}
	)

	const stockAvailableHook = useStockAvailable(transfer?.senderLaboratory?.warehouseIdHiboutik)

	const hasAlreadyProductWithQuantity = (product: Record<string, any>): null | string => {
		const correspondingProduct = transferProducts.find(
			(transferProduct) => transferProduct.productId === product.id && !transferProduct.serialNumber
		)

		return correspondingProduct?.["@id"] ?? null
	}

	useEffectAsync(async () => {
		try {
			setLoading(true)
			for (const product of addedProduct) {
				const alreadyExistingProductIri = hasAlreadyProductWithQuantity(product)

				if (!product.serialNumber && alreadyExistingProductIri) {
					await API.updateWithIri(alreadyExistingProductIri, { quantity: product.quantityToAdd })
					continue
				}

				await API.create("TRANSFER_PRODUCT_API", {
					transfer: transfer!["@id"],
					productId: product.id,
					productSizeId: product.sizeId ?? null,
					serialNumber: product.serialNumber ?? null,
					quantity: product.quantityToAdd ?? 1,
					oldLogisticProduct: product.logisticProduct?.["@id"],
					price: product.supplyPrice ?? 0,
					inventoryInputDetailId: product?.inventoryInputDetailId,
					sizeName: product?.sizeName,
					sellingPrice: product?.price,
					tax: product.taxValue,
					model: product.model,
				})
			}
			queryClient.invalidateQueries("TRANSFER")
			refetchTransferProducts()
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}, [addedProduct])

	const isLoading = loading || loadingTransfer || loadingTransferProduct || loadingCatalog

	useEffect(() => {
		if (!transfer?.["@id"]) {
			return
		}
		iriToDelete.current = transferProducts.length === 0 ? transfer["@id"] : null
	}, [transferProducts, transfer])

	useEffect(() => {
		if (loadingCatalog || stockAvailableHook.isLoading) return

		if (!productCatalog?.length) {
			queryClient.invalidateQueries("PRODUCT_CATALOG")
			return
		}

		queryClient.invalidateQueries("TRANSFER")
	}, [productCatalog, loadingCatalog])

	useEffect(() => {
		return () => {
			if (iriToDelete.current) {
				API.delete(iriToDelete.current)
					.then(() => {
						queryClient.invalidateQueries("TRANSFERS_LIST")
					})
					.catch((error) => console.error(error))
			}
		}
	}, [])

	const columns = [
		{
			title: "Modèle",
			dataIndex: "model",
			className: "col-6",
			render: (model: string, product: Record<string, any>) => {
				return (
					<>
						<div className="font-weight-bold">{model ?? "Produit sans nom"}</div>
						<div style={{ opacity: "0.75" }}>{product.sizeName ?? "Pas de déclinaison"}</div>
					</>
				)
			},
		},
		{
			title: "Numéro de série",
			dataIndex: "serialNumber",
			className: "col-2",
			render: (serialNumber: string) => {
				return serialNumber ?? <span style={{ opacity: "0.75" }}>Sans numéro de série</span>
			},
		},
		{
			className: "col-1",
			align: "center",
			title: "Quantité",
			dataIndex: "quantity",
			render: (quantity: number) => {
				return quantity
			},
		},
		{
			title: "Prix unitaire (ht)",
			className: transfer?.generateInvoice ? "" : "d-none",
			dataIndex: "price",
			render: (price: number, row: Record<string, any>) => {
				return (
					<input
						type="number"
						className="form-control form-control-sm"
						value={price}
						disabled={!!transfer?.validatedAt}
						step="0.01"
						onBlur={async (event) => {
							setHasPendingAction(true)
							const value = event.target.value

							event.target.disabled = true
							clearTimeout(timeoutRef.current as NodeJS.Timeout)
							await API.updateWithIri(row["@id"], { price: +value })
							event.target.disabled = false
							setHasPendingAction(false)
						}}
						onChange={(event) => {
							const value = event.target.value

							if (!/\d?./.test(value)) {
								event.preventDefault()
								return
							}

							const tmp = [...transferProducts]

							tmp[row._arrayIndex].price = value

							setHasPendingAction(true)
							clearTimeout(timeoutRef.current as NodeJS.Timeout)
							//@ts-ignore
							timeoutRef.current = setTimeout(async () => {
								event.target.disabled = true
								await API.updateWithIri(row["@id"], { price: +value })
								event.target.disabled = false
								setHasPendingAction(false)
							}, 1000)

							setTransferProducts(tmp)
						}}
					/>
				)
			},
		},
		{
			align: "center",
			title: "-",
			className: "w-10",
			render: (_void: void, product: Record<string, any>) => {
				if (transfer?.validatedAt) {
					return null
				}

				return (
					<DeleteOutlined
						className="text-lg text-danger"
						style={{ cursor: hasPendingAction ? "not-allowed" : "pointer" }}
						onClick={async () => {
							try {
								if (hasPendingAction) {
									return
								}

								await API.delete(product["@id"])
								refetchTransferProducts()
							} catch (error) {
								console.error(error)
							}
						}}
					/>
				)
			},
		},
	]

	const hasUnaivalableProducts: boolean = useMemo(
		() => !!transferProducts.find((product) => product.notAvailableAnymore),
		[transferProducts]
	)

	return (
		<>
			<div className="cardtabs-subtitle">
				Transfert de "{transfer?.senderLaboratory?.label}" à "{transfer?.recipientLaboratory?.label}"
			</div>

			{!transfer?.validatedAt && (
				<button
					type="button"
					onClick={() => setShowModal(true)}
					className="btn btn-primary btn-sm mb-3"
					disabled={!!transfer?.validatedAt || loading}>
					<PlusOutlined /> Ajouter un ou des produits
				</button>
			)}

			{transfer?.validatedAt && (
				<div className="alert alert-primary mt-3 mb-3">Ce transfert à déjà été effectué.</div>
			)}

			<ClearUnaivalableProductsButton
				labelAlert="Ce transfert"
				hasUnaivalableProducts={hasUnaivalableProducts}
				products={transferProducts}
				setLoading={setLoading}
				//@ts-ignore
				onFinish={() => refetchTransferProducts()}
			/>

			<Table
				//@ts-ignore
				columns={columns}
				dataSource={transferProducts as any[]}
				pagination={false}
				loading={isLoading || stockAvailableHook.isLoading || loadingCatalog}
				size="small"
				rowClassName={(row) => (row.notAvailableAnymore ? " not-available " : "")}
			/>

			<button
				type="button"
				className={"btn btn-sm btn-primary btn-block " + (transfer?.validatedAt ? "d-none" : "")}
				disabled={
					!!transfer?.validatedAt ||
					transferProducts.length === 0 ||
					hasUnaivalableProducts ||
					hasPendingAction
				}
				onClick={async (event) => {
					let pdfPreview = null
					setHasPendingAction(true)

					if (transfer?.generateInvoice) {
						try {
							pdfPreview = await getPdfPreview()
						} catch (error) {
							console.error(error)
							toast.error("Impossible de générer l'aperçu de la facture")
						}
					}

					try {
						const confirm = await confirmWithModal({
							title: "Confirmer le transfert",
							text: (
								<>
									Êtes-vous sûr de vouloir valider le transfert{" "}
									{transfer!.generateInvoice ? "et générer une facture de rétrocession " : ""} ?<br />
									Cette action est <strong>irréversible</strong>.
									{pdfPreview && transfer?.generateInvoice && (
										<>
											<h6 style={{ marginTop: "20px" }}>Aperçu de la facture</h6>
											<iframe
												title="Aperçu facture de retrocession"
												key={pdfPreview}
												src={`data:application/pdf;base64,${pdfPreview as string}`}
												width={500}
												height={500}
											/>
										</>
									)}
								</>
							),
						})
						if (!confirm) {
							setHasPendingAction(false)
							return
						}

						await axios.post(`${TRANSFER_API}/validate`, { transfer: transfer?.["@id"] })

						toast.success("Transfert validé avec succès !")
						queryClient.invalidateQueries("TRANSFER")
						history.push("/mon-stock/transfert")
					} catch (error) {
						console.error(error)
					}
				}}>
				{transferProducts.length === 0 ? "Aucun produit à transférer" : "Valider le transfert "}
			</button>

			<DeleteLogisticForm
				disabled={hasPendingAction}
				logisticForm={transfer!}
				withLabel={true}
				label={"le transfert"}
				redirect="/mon-stock/transfert"
			/>

			<ModalAddProducts
				isOpen={showModal}
				setOpen={setShowModal}
				setSelectedProduct={setAddedProduct}
				currentProducts={transferProducts ?? []}
				warehouseId={transfer?.senderLaboratory?.warehouseIdHiboutik}
			/>
		</>
	)
}

export default Transfer
