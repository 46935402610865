import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { ModelesRoutes, noPrefix } from "./routes"
import { ParametreRoutePrefix } from "router/Personnalisation/routes"
import Modele from "pages/templates/TemplateForm/TemplateForm"
import useCustomTitle from "components/Hooks/useTitle"

function ModelesRouter(): JSX.Element {
	useCustomTitle("Paramètres | Modèles")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem name="Personnalisation des modèles" component={Modele} url={noPrefix(ModelesRoutes.Modeles)} />
		</CardWithTabs>
	)
}

export default ModelesRouter
