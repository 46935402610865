import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { lazy } from "react"
import { noPrefix, BonCommandeRoutePrefix, BonCommandeRoutes } from "./routes"

const ProductsTable = lazy(() => import("pages/PurchaseOrder/ProductsTable"))
const PurchaseOrderPage = lazy(() => import("pages/PurchaseOrder/PurchaseOrderPage"))
const PurchaseOrderEdit = lazy(() => import("pages/PurchaseOrder/PurchaseOrderEdit"))

function BonCommandeRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={BonCommandeRoutePrefix}>
			<CardItem name="Bon de commande" url={noPrefix(BonCommandeRoutes.List)} component={PurchaseOrderPage} />
			<CardItem name="Produits" url={noPrefix(BonCommandeRoutes.ListProduct)} component={ProductsTable} />
			<CardItem
				name="Nouveau"
				url={noPrefix(BonCommandeRoutes.New)}
				component={PurchaseOrderEdit}
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name="BC"
				url={noPrefix(BonCommandeRoutes.Edit)}
				component={PurchaseOrderEdit}
				onlyVisibleOnExactRoute
				useSlug="id"
			/>
		</CardWithTabs>
	)
}

export default BonCommandeRouter
