import { HiboutikProductEntity } from "@audiowizard/common"
import { useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import API from "services/API"
import { extractAvailableSerialnumber, extractAvailableWithoutSerialnumber } from "../StockUtils"

type StockAvailable = {
	data: any[]
	isLoading: boolean
}

const useStockAvailable = (warehouseId?: number): StockAvailable => {
	const queryClient = useQueryClient()

	const { data, isFetching } = useQuery(
		["STOCK_AVAILABLE_API", warehouseId],
		async () => {
			if (!warehouseId) return []

			const result = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${warehouseId}`
			)

			const withoutSn = extractAvailableWithoutSerialnumber(result)
			const withSn = extractAvailableSerialnumber(result)
			const quantityOnly = result.filter((f: any) => f.stockManagement && !f.stockAvailable?.length)

			const tmp = [...withSn, ...withoutSn, ...quantityOnly]

			return tmp
		},
		{
			initialData: [],
		}
	)

	useEffect(() => {
		if (!warehouseId) return

		queryClient.invalidateQueries("STOCK_AVAILABLE_API")

		return () => {
			queryClient.cancelQueries("STOCK_AVAILABLE_API")
		}
	}, [warehouseId])

	return { data: data ?? [], isLoading: isFetching }
}

export default useStockAvailable
