import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import FieldWithError from "../../components/forms/FieldWithError"
import API_Hiboutik from "../../services/API_Hiboutik"
import { HandleChange } from "../../services/functions"

const BrandsModal = ({ brand, setBrand, brandsList, isOpen, onClose }) => {
	const queryClient = useQueryClient()

	const { mutate: updateBrand, isLoading } = useMutation(
		async () => {
			if (!brand.brand_id) {
				await API_Hiboutik.createBrand(brand)
				toast.success("La marque " + brand.brand_name + " a bien été ajouté")
			} else {
				await API_Hiboutik.updateBrand(brand, brand.brand_id)
				toast.success("La marque " + brand.brand_name + " a bien été modifiée")
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("API_Hiboutik.getBrands")
				onClose()
			},
			onError: (err) => {
				console.error(err)
				toast.error("Erreur lors de l'ajout de la marque.")
			},
		}
	)

	const brandExist = () => {
		return brandsList.some((existingBrand) => existingBrand.brand_name === brand.brand_name)
	}

	return (
		<Modal isOpen={isOpen} size="lg" toggle={onClose} className="modal-custom-header" centered>
			<ModalHeader>{brand && (brand.brand_id ? "Modifier la marque " : "Ajouter une marque")}</ModalHeader>
			<ModalBody>
				<Row>
					<Col md={12}>
						<FieldWithError
							id="brand-name"
							name="brand_name"
							label="Nom de la marque"
							value={brand ? brand.brand_name : ""}
							dataCy={brand && brand.brand_id ? "modifier-nom-marque-input" : "nom-marque-input"}
							onChange={(event) => HandleChange(event, brand, setBrand)}
							required={true}
						/>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Row>
					<Col>
						<Button
							color="outline-info"
							type="button"
							className="btn btn-outline-info"
							onClick={() => onClose()}
							disabled={isLoading}>
							Annuler
						</Button>
						<Button
							color="primary"
							type="button"
							className="btn btn-primary pr-6 pl-6"
							onClick={() => {
								if (brandExist()) {
									toast.error("La marque que vous essayez d'ajouter existe déjà.")
									return
								}
								updateBrand()
							}}
							disabled={isLoading}>
							Enregistrer
						</Button>
					</Col>
				</Row>
			</ModalFooter>
		</Modal>
	)
}

export default BrandsModal
