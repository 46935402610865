export default function convertParams(input: Record<string, any>): string {
	let toReturn = ""

	Object.entries(input).forEach(([key, value]) => {
		if (value === null || value === undefined) return

		if (key === "order") {
			toReturn += `&order[${value.key}]=${value.value}`
			return
		}

		if (Array.isArray(value)) {
			value.forEach((el) => {
				toReturn += `&${key}[]=${el}`
			})
			return
		}

		toReturn += `&${key}=${value}`
	})

	return toReturn.substring(1)
}
