import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import MarqueBlanche from "pages/mon-compte/MarqueBlanche"
import { noPrefix, MarqueBlancheRoutePrefix, MarqueBlancheRoutes } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

function MarqueBlancheRouter(): JSX.Element {
	useCustomTitle("Paramètres | Marque blanche")
	return (
		<CardWithTabs urlprefix={MarqueBlancheRoutePrefix}>
			<CardItem
				name="Personnalisation de l'interface"
				component={MarqueBlanche}
				url={noPrefix(MarqueBlancheRoutes.MarqueBlanche)}
				withRole={["ROLE_MANAGER", "ROLE_MANAGER_FRANCHISED"]}
			/>
		</CardWithTabs>
	)
}

export default MarqueBlancheRouter
