import { SyncOutlined } from "@ant-design/icons"
import { useMemo } from "react"
import { Modal, ModalBody } from "reactstrap"
import { rounded } from "utils/types/primitives/Number/globals"
import "./ModalLoading.scss"

interface Props {
	isOpen: boolean
	message: string

	subMessage?: string
	progressTotal?: number
	progressCurrent?: number
}

const LoadingModal = ({ isOpen, message, progressTotal, progressCurrent, subMessage }: Props): JSX.Element => {
	const progressPercent = useMemo(() => {
		if (!progressCurrent || !progressTotal) return 0

		return rounded((progressCurrent / progressTotal) * 100, 0)
	}, [progressCurrent])

	return (
		<Modal isOpen={isOpen} centered size="sm" className="modal-loading">
			<ModalBody className="modal-loading-body">
				<div className="modal-loading-message">{message}</div>

				<div className="modal-loading-spinner">
					<SyncOutlined spin />
				</div>

				{progressTotal && (
					<div className="progress-container">
						<div style={{ width: `${progressPercent}%` }} />
					</div>
				)}

				{subMessage && (
					<>
						<div className="submessage">{subMessage}</div>
						<div style={{ paddingBottom: "42px" }} />
					</>
				)}

				<div className="modal-loading-info">
					Merci de ne pas fermer la page, cette opération peut prendre un certain temps
				</div>
			</ModalBody>
		</Modal>
	)
}

export default LoadingModal
