import dayjs from "dayjs"
import { toast } from "react-toastify"
import API_Hiboutik from "services/API_Hiboutik"
import { rounded } from "services/functions"
import { defaultSinglePaymentMethods, PaymentTypeTags, refundPaymentMethods } from "./_Utility"

const errorNotification = (text: string): void => {
	toast.error(text, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		className: "mt-2",
	})
}

/**
 * Check whether or not the current payment method is refund
 * @param method
 * @returns true if refurnd method
 */
export const isRefundMethod = (method: string): boolean => {
	return refundPaymentMethods.includes(method.trim() as PaymentTypeTags)
}

/**
 * Delete all DIVs payment from the sale
 * @param paymentDetails List of div payments (with their corresponding payment_detail_id)
 * @returns The updated list of div payments
 */
export const deleteDivPayments = async (paymentDetails: Record<string, any>[]): Promise<Record<string, any>[]> => {
	const deletedId: number[] = []

	for (const payment of paymentDetails ?? []) {
		try {
			if (!payment?.payment_detail_id) continue
			await API_Hiboutik.deleteSalePaymentDiv(payment.payment_detail_id)
			deletedId.push(payment.payment_detail_id)
		} catch (error) {
			errorNotification(`Impossible de supprimer le paiement ${payment.type}`)
			console.error(error)
		}
	}

	return paymentDetails?.filter((e: Record<string, any>) => !deletedId.includes(e.payment_detail_id))
}

/**
 * Delete SMDP payment method from the sale
 * @param paymentDetails List of div payments (with their corresponding payment_detail_id)
 * @returns The updated list of div payments
 */
export const deleteSMDPPayment = async (paymentDetails: Record<string, any>[]): Promise<Record<string, any>[]> => {
	return deleteDivPayments(paymentDetails?.filter((e) => e.type === "SMDP"))
}

export const createSinglePaymentObject = (
	paymentId: number | null,
	type: string,
	amount: number,
	comment: string | null,
	date: string,
	datePaid: string
): Record<string, any> => {
	return {
		type,
		amount: amount,
		comments: comment,
		date,
		payment_detail_id: paymentId ? paymentId : "-1",
		payment_paid: datePaid ?? "0000-00-00",
	}
}

export const setSinglePaymentMode = async (
	saleData: Record<string, any>,
	type: string,
	amount: number,
	comment: string
): Promise<Record<string, any>[] | undefined> => {
	if (!saleData.sale_id || saleData?.sale_id === -1) {
		console.error("[PAYMENTS68] Cannot set payment method, missing sale_id.")
		return undefined
	}

	try {
		const date = dayjs().format("YYYY-MM-DD")
		const payment = createSinglePaymentObject(null, type, amount, comment, date, date)

		await deleteDivPayments(saleData?.payment_details)
		const result = await API_Hiboutik.saleAddDivPayment(
			saleData.sale_id,
			payment.type,
			payment.amount,
			payment.date
		)
		if (comment) await API_Hiboutik.saleUpdateDiv(result.data.payment_detail_id, "comments", comment)
		payment.payment_detail_id = result?.data?.payment_detail_id

		return [payment]
	} catch (error) {
		errorNotification(`Impossible de changer le moyen de paiement en ${type}`)
		console.error(error)
	}
}

export const addDivPayment = async (
	saleId: number,
	type: string,
	amount: number,
	comment: string | null,
	date: string,
	datePaid: string
): Promise<void> => {
	try {
		const p = createSinglePaymentObject(null, type, amount, comment, date, datePaid)

		const result = await API_Hiboutik.saleAddDivPayment(saleId, p.type, p.amount, p.date)
		p.payment_detail_id = result?.data?.payment_detail_id
	} catch (error) {
		console.error(error)
		errorNotification(`Impossible d'ajouter le moyen de paiement ${type}`)
	}
}

export const isSaleSinglePayment = (paymentDetails: Record<string, any>[]): boolean => {
	if (paymentDetails.length === 1 && defaultSinglePaymentMethods.includes(paymentDetails[0].type)) return true
	return false
}

export const updateSinglePayment = async (saleData: Record<string, any>): Promise<void> => {
	try {
		if (saleData?.paymentMode === "MULTIPLE" || saleData?.payment_details.length !== 1) return
		const payment = saleData.payment_details[0]
		const result = (
			await setSinglePaymentMode(saleData, payment.type, saleData.amount_total, payment.comments)
		)?.[0]

		if (
			result?.payment_detail_id &&
			(result?.type !== "SMDP" || (result?.type === "SMDP" && saleData.amount_total === 0))
		) {
			await API_Hiboutik.saleUpdateDiv(
				result?.payment_detail_id,
				"payment_date_paid",
				dayjs().format("YYYY-MM-DD")
			)
		}
	} catch (error) {
		throw error
	}
}

export const recalculateSMDP = async (
	paymentDetails: Record<string, any>[],
	saleId: number,
	totalSale: number
): Promise<Record<string, any>[]> => {
	let payments = [...(paymentDetails ?? [])]

	try {
		const paymentSMDP = payments.filter((e) => e.type === "SMDP")

		const paymentTotal = payments
			.filter((e) => e.type !== "SMDP" && !isRefundMethod(e.type))
			.reduce((prev, curr) => +curr.amount + +prev, 0)

		const paymentDiff = rounded(totalSale - paymentTotal, 2)

		// ne pas modifier le moyen de paiement si il ne change pas (résout problème de modification intempestif d’un paiement annulé)
		if (paymentSMDP.length === 1 && paymentSMDP[0].amount === paymentDiff) {
			return payments
		}
		if (paymentSMDP) {
			payments = payments.filter((e) => e.type !== "SMDP")
			for (const payment of paymentSMDP) {
				await API_Hiboutik.saleDeleteDiv(payment.payment_detail_id)
			}
		}
		if (paymentDiff > 0) {
			const paymentObject = createSinglePaymentObject(
				null,
				"SMDP",
				paymentDiff,
				"Restant dû",
				dayjs().format("YYYY-MM-DD"),
				""
			)
			const result = await API_Hiboutik.saleAddDivPayment(
				saleId,
				paymentObject.type,
				paymentObject.amount,
				paymentObject.date
			)
			paymentObject.payment_detail_id = result?.data?.payment_detail_id
			await API_Hiboutik.saleUpdateDiv(paymentObject.payment_detail_id, "comments", paymentObject.comments)
			payments.push(paymentObject)
		}
	} catch (error) {
		console.error(error)
		errorNotification("Impossible de mettre à jour le restant dû")
	}
	return payments
}
