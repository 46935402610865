import { HiboutikProductEntity, Laboratory } from "@audiowizard/common"
import BootstrapTable, { ColumnDataType } from "components/BootstrapTable"
import useEffectAsync from "components/Hooks/useEffectAsync"
import useCustomTitle from "components/Hooks/useTitle"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import AuthContext from "contexts/AuthContext"
import { useContext, useState } from "react"
import API from "services/API"
import { rounded } from "services/functions"

const columns: ColumnDataType[] = [
	{
		title: "Label",
		dataIndex: "name",
	},
	{
		title: "Quantité en stock",
		dataIndex: "stockAvailable",
	},
	{
		title: "Prix d'achat HT",
		dataIndex: "buyingHt",
		render: (d) => rounded(d).toFixed(2),
	},
	{
		title: "Valeur marchande HT",
		dataIndex: "sellingHt",
		render: (d) => rounded(d).toFixed(2),
	},
	{
		title: "Valeur marchande TTC",
		dataIndex: "sellingTtc",
		render: (d) => rounded(d).toFixed(2),
	},
]

const MarketValue = (): JSX.Element => {
	useCustomTitle("Stock | Valeur marchande")
	const { laboratory } = useContext(AuthContext)

	const [currentLaboratory, setCurrentLaboratory] = useState<Laboratory>(laboratory)

	const [data, setData] = useState<any[]>([])
	const [dataTaxes, setDataTaxes] = useState<any[]>([])
	const [loading, setLoading] = useState(true)

	useEffectAsync(async () => {
		setLoading(true)
		try {
			const categories: any = {}
			const taxes: any = {}

			const result = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${currentLaboratory?.warehouseIdHiboutik}`
			)

			for (const product of result) {
				const taxName = `Taux ${product.taxValue! * 100}%`
				if (!+product.quantityAvailable!) continue

				if (!taxes[taxName]) {
					taxes[taxName] = {
						tax_value: product.taxValue,
						stockAvailable: 0,
						sellingHt: 0,
						sellingTtc: 0,
						buyingHt: 0,
					}
				}

				taxes[taxName].stockAvailable += +product.quantityAvailable!
				taxes[taxName].sellingTtc += +product.price! * +product.quantityAvailable!
				taxes[taxName].sellingHt +=
					+product.price! * (1 / (1 + +product.taxValue!)) * +product.quantityAvailable!
				taxes[taxName].buyingHt += +product.supplyPrice! * +product.quantityAvailable!

				if (!categories[product.categoryName!]) {
					categories[product.categoryName!] = {
						stockAvailable: 0,
						sellingHt: 0,
						sellingTtc: 0,
						buyingHt: 0,
					}
				}

				categories[product.categoryName!].stockAvailable += +product.quantityAvailable!
				categories[product.categoryName!].sellingTtc += +product.price! * +product.quantityAvailable!
				categories[product.categoryName!].sellingHt +=
					+product.price! * (1 / (1 + +product.taxValue!)) * +product.quantityAvailable!
				categories[product.categoryName!].buyingHt += +product.supplyPrice! * +product.quantityAvailable!
			}

			const valuesCategories = Object.keys(categories).map((m) => ({ ...categories[m], name: m }))
			const valuesTaxes = Object.keys(taxes).map((m) => ({ ...taxes[m], name: m }))

			valuesCategories.sort((a: any, b: any) => b.sellingTtc - a.sellingTtc)
			valuesTaxes.sort((a: any, b: any) => b.sellingTtc - a.sellingTtc)

			setData(valuesCategories)
			setDataTaxes(valuesTaxes)
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
		}
	}, [currentLaboratory])

	return (
		<>
			<SectionHeader title="Sélection du laboratoire" />
			<AsyncLaboratorySelect
				onChange={(_, laboratory) => {
					setCurrentLaboratory(laboratory)
				}}
				value={currentLaboratory}
				className="w-100"
				allowClear={false}
			/>
			<SectionHeader title="Par catégorie de produit" />
			<BootstrapTable loading={loading} columns={columns} data={data} />
			<SectionHeader title="Par taux de TVA" />
			<BootstrapTable loading={loading} columns={columns} data={dataTaxes} />
		</>
	)
}

export default MarketValue
