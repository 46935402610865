/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd"
import moment from "moment"
import { useContext, useState } from "react"
import { toast } from "react-toastify"

import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import { useHistory } from "react-router-dom"
import { Col } from "reactstrap"
import ConfirmModal from "../../../components/effects/ConfirmModal"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import NouvelleInvitation from "./NouvelleInvitation"

const MesInvitations = () => {
	const { user, setUser } = useContext(AuthContext)
	const [currentInvitationId, setCurrentInvitationId] = useState(null)
	const [openInvitationModal, setOpenInvitationModal] = useState(false)
	const history = useHistory()

	const redirectPatient = (id) => {
		if (id) {
			history.push("/fiche-patient/" + id)
		}
	}

	const handleDelete = (id) => {
		if (id) {
			document.querySelector("#btn-deleteUser").click()
			setCurrentInvitationId(id)
		}
	}

	const columns = [
		{
			title: "Nom",
			dataIndex: "data",
			sorter: true,
			width: "15%",
			ellipsis: true,
			render: (d) => (
				<span style={{ cursor: "pointer" }} onClick={() => redirectPatient(d?.id)}>
					{" "}
					{d?.firstName || "-"}{" "}
				</span>
			),
		},
		{
			title: "Prénom",
			dataIndex: "data",
			width: "15%",
			ellipsis: true,
			render: (d) => (
				<span style={{ cursor: "pointer" }} onClick={() => redirectPatient(d?.id)}>
					{" "}
					{d?.lastName || "-"}
				</span>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			width: "20%",
			ellipsis: true,
		},
		{
			title: "Envoyé à",
			dataIndex: "sentAt",
			width: "20%",
			ellipsis: true,
			defaultSortOrder: "descend",
			sorter: (a, b) => {
				return moment(a.sentAt).unix() - moment(b.sentAt).unix()
			},
			render: (d) => <span className={"badge badge-light"}> {moment(d).format("YYYY/MM/DD HH:mm:ss")}</span>,
		},
		{
			title: "Statut",
			dataIndex: "completedAt",
			width: "20%",
			ellipsis: true,
			render: (d) => (
				<span className={"badge badge-" + (d ? "primary" : "warning")}>
					{" "}
					{d ? "fiche complète" : "en attente"}
				</span>
			),
		},
		{
			width: "10%",
			ellipsis: true,
			render: (d) => (
				<span onClick={() => handleDelete(d?.id)} className={"badge badge-danger cursor-pointer"}>
					{" "}
					{"Supprimer"}
				</span>
			),
		},
	]

	const handleConfirmDelete = async () => {
		const idToDelete = currentInvitationId

		try {
			const response = await API.delete(`/patient_invitations/${idToDelete}`)
			if (response.status === 204) {
				const invitations = [...user.patientInvitations]
				const invitationsFiltered = invitations.filter((invite) => invite.id !== idToDelete)

				setUser({
					...user,
					patientInvitations: invitationsFiltered,
				})

				toast.success("L'invitation a été supprimée")
			} else {
				toast.error("Impossible de supprimer l'invitation")
			}
		} catch (err) {
			toast.error("Une erreur s'est produite lors de la suppression")
			console.error(err)
		}
	}

	return (
		<Col>
			<SectionHeader title="Liste des invitations">
				<ButtonRounded
					type="button"
					icon="fa-plus"
					color="primary-outlined"
					onClick={() => setOpenInvitationModal(true)}>
					Ajouter un patient
				</ButtonRounded>
			</SectionHeader>
			<div className="table-responsive">
				<Table
					columns={columns}
					dataSource={user.patientInvitations}
					rowKey={(record) => record.email}
					pagination={{ position: "bottomCenter" }}
				/>
			</div>
			<button
				id="btn-deleteUser"
				className="d-none"
				data-toggle="modal"
				data-target="#modal-confirmation-deleteUser"
			/>
			<NouvelleInvitation
				id="modal-addUser"
				title="Inviter un nouveau patient"
				ressource={user}
				setRessource={setUser}
				isOpen={openInvitationModal}
				setIsOpen={setOpenInvitationModal}
			/>
			<ConfirmModal
				id="-deleteUser"
				text="Êtes-vous sûr de vouloir supprimer cette invitation ?"
				onValid={handleConfirmDelete}
			/>
		</Col>
	)
}

export default MesInvitations
