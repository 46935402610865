/**
 * Popup Component
 */
import React, {
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import "./EventEditor.scss"

import { Patient, PatientInsurance, User } from "@audiowizard/common"
import { Combo, Store, RecurrenceModel, EventModel } from "@bryntum/calendar"
import {
	BryntumButton,
	BryntumCombo,
	BryntumDateTimeField,
	BryntumResourceCombo,
	BryntumContainer,
} from "@bryntum/calendar-react"
import { Editor } from "@tinymce/tinymce-react"
import { Alert } from "antd"
import { AxiosResponse } from "axios"
import { EquipmentClass } from "constants/patient/equipment"
import dayjs from "dayjs"
import usePatientClass from "hooks/specific/usePatientClass"
import _ from "lodash"
import ModalTeletransmissionSuivi from "pages/FSV/Modal.TeletransmissionSuivi"
import { findActiveAm } from "pages/FSV/utilsFSV"
import { zipper } from "pages/Schedules/doctolib.helpers"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import API from "services/API"
import { setTimeout } from "timers"
import { Attendance } from "../Attendance"
import { Agenda, AgendaStore, TypeStore } from "./AgendaTypes"
import { clearInput, setDuration, setDurationInMinute } from "./BryntumAgenda.helper"
import BryntumRecurrenceField, { configRecurringContainer } from "./BryntumRecurrenceField"

BryntumRecurrenceField.initClass()

export const MAX_PATIENTS = 30

const primaryFilter = {
	value: "",
	filterBy(record: PatientForStore): boolean {
		if (this.value == null) {
			return true
		}
		const values = _.deburr(this.value.toLowerCase()).split(" ")

		// Match typed value with forename or surname
		return values.every((value) => _.deburr(record.patientName.toLowerCase()).includes(value))
	},
}

export const getListPatient = async (
	partialName: string,
	patientSearchControllerRef?: React.MutableRefObject<AbortController | undefined>
): Promise<Patient[]> => {
	const res = await API.findAll<Patient[]>("PATIENTS_API", `/searchByName?searchName=${partialName}`, true, {
		signal: patientSearchControllerRef?.current?.signal,
	})

	const patients = res["hydra:member"]
	const totalItems = res["hydra:totalItems"]

	if (patients.length === MAX_PATIENTS) {
		let isUnique = true
		let page = 2
		while (isUnique && patients.length !== totalItems) {
			const firstPatient = patients[0]
			for (const i in patients) {
				if (firstPatient.lastName !== patients[i].lastName) {
					isUnique = false
					break
				}
			}
			if (isUnique) {
				const res = await await API.findAll<Patient[]>(
					"PATIENTS_API",
					`/searchByName?searchName=${partialName}&page=${page}`,
					true,
					{
						signal: patientSearchControllerRef?.current?.signal,
					}
				)
				patients.push(...res["hydra:member"])
				page++
			}
		}
	}

	return patients
}

// we make a temporary copy of the event, not to generate api call before we validate
interface Record {
	name: string
	startDate: Date
	endDate: Date
	state: string
	resourceId: number
	status: string
	color: string
	agenda: string
	patientField?: number | null // si uniquement undefined la suppression du patient n’est pas prise en compte
	notes: string
	duration: number
	laboratoryIri: string
}

interface EditEventProps {
	eventRecord: any
	eventStore: any
	typesStore: TypeStore
	resourceStore: any
	closePopup: () => void
	agendaStore: AgendaStore
	stateStore: any
	appointmentFromScheduleSelector: any
	attendances?: Attendance
	activePatient: Patient
	isOpen: boolean
	currentAgenda: MutableRefObject<{
		"@id": null | string
		userIri: null | string
		laboratoryIri: null | string
	}>
	defaultPatient: Patient | undefined
	setDefaultPatient: Dispatch<SetStateAction<Patient | undefined>>
	setOpenPatientModal: Dispatch<SetStateAction<boolean>>
	removeEvent: (eventRecord: any) => void
}

interface RelanceResponse {
	id: number
	patient: {
		lastName: string
		firstName: string
	}
}

const defaultRecord = {
	name: "",
	startDate: new Date(),
	endDate: new Date(),
	state: "",
	resourceId: 0,
	status: "",
	color: "",
	agenda: "",
	notes: "",
	duration: 1,
	patient: null,
	patientField: undefined,
	laboratoryIri: "",
}

interface PatientForStore {
	patientId: number
	patientName: string
	laboratoryIri: string
	laboratoryLabel: string
}

const setPatientToPatientStore = (patient: Patient): PatientForStore => ({
	patientId: patient.id || 0,
	patientName: `${patient.lastName || ""} ${patient.firstName || ""}`,
	laboratoryIri: patient.laboratory?.["@id"] || "",
	laboratoryLabel: patient.laboratory?.label || "",
})

const EventEditor: React.FC<EditEventProps> = ({
	eventRecord,
	eventStore,
	typesStore,
	resourceStore,
	closePopup,
	agendaStore,
	stateStore,
	appointmentFromScheduleSelector,
	attendances,
	activePatient,
	isOpen,
	currentAgenda,
	defaultPatient,
	setDefaultPatient,
	setOpenPatientModal,
	removeEvent,
}) => {
	/**
	 * Constructor (initializes state and binds handlers)
	 * @param {Object} props
	 */
	const [record, setRecord] = useState<Record>(defaultRecord)
	const [lastSearchPatient, setLastSearchPatient] = useState("")
	const [isDoctolib, setIsDoctolib] = useState(false)
	const [onlyDoctolibAgenda, setOnlyDoctolibAgenda] = useState(false)
	const patientCombo = useRef<BryntumCombo>(null)
	const typeCombo = useRef<BryntumCombo>(null)
	const teletransBtnRef = useRef<BryntumButton>(null)
	const [openTeletrans, setOpenTeletrans] = useState(false)
	const [patientStatus, setPatientStatus] = useState<{
		patientClass?: number
		patientHasCmu?: boolean
		inactiveCmu?: boolean | false
	}>({})
	const [patientLaboratoryIri, setPatientLaboratoryIri] = useState<string>()
	const [shouldLoadPatient, setShouldLoadPatient] = useState<boolean>(false)

	// for recurring events
	const recurrenceFieldRef = useRef<any>(null)
	const [recurrence, setRecurrence] = useState<RecurrenceModel | undefined>()

	const patientStore = useMemo<Store>(
		() =>
			new Store({
				tree: true,
				data: [],
				reapplySortersOnAdd: true,
				listeners: {
					add: ({ source }: { source: Store }) => {
						source.sort("", true, false, false)
					},
				},
			}),
		[]
	)

	// charge a few patients
	const setPatientStore = async (userStore: Store): Promise<void> => {
		userStore.removeAll()
		const patients = await API.findAll<Patient[]>("PATIENTS_API")
		userStore.add(
			patients ? patients.map((patient: Patient): PatientForStore => setPatientToPatientStore(patient)) : []
		)
		return undefined
	}

	useEffect(() => {
		if (!teletransBtnRef.current || record.status !== "APPAREILLE" || eventRecord?.isCreating) return
		teletransBtnRef.current.element.style.setProperty("--b-teletrans-color", record.color)
	}, [teletransBtnRef.current, record.status, eventRecord?.isCreating])

	// Extract values used in the editor and keep them in state
	useEffect(() => {
		if (!eventRecord) {
			setRecord(defaultRecord)
			return
		}

		// permet de modifier l’event sans que sauvegarde systématique
		// on sauvegarde uniquement à la validation
		eventRecord.isCreating = true

		setRecord((old) => ({
			...old,
			name: eventRecord.name,
			patientField: eventRecord.patientField,
			startDate: eventRecord.startDate,
			endDate: eventRecord.endDate,
			resourceId: eventRecord.resource?.id || undefined,
			state: eventRecord.state || "WAITING",
			status: eventRecord.status || "",
			agenda: eventRecord.agenda,
			notes: eventRecord.notes || "",
			duration: setDurationInMinute(eventRecord.duration, eventRecord.durationUnit),
		}))

		setRecurrence(eventRecord.recurrence)

		// if the event already has a patient we use this one
		// it will be set with the currentPatient
		if (eventRecord.patient) {
			setDefaultPatient(eventRecord.patient)
			return
		}

		// if we comme from the schedule Selector, for a new event, we use the patient set in this modal
		if (appointmentFromScheduleSelector && !eventRecord["@id"]) {
			setDefaultPatient(appointmentFromScheduleSelector.patient)
			return
		}

		// if we come from the patient page, we use the courant patient to initialise the field
		if (activePatient && activePatient.id && !eventRecord["@id"]) {
			setDefaultPatient(activePatient)
			return
		}

		setShouldLoadPatient(true)
	}, [eventRecord])

	useEffect(() => {
		// s’il y a déjà un patient inutile de préremplir le select
		if (record.patientField || !record.laboratoryIri || !shouldLoadPatient) return
		// ne default patient was found, we use the 10 first patients
		setPatientStore(patientStore).then(() => {
			// once the combo is set, we can clean it, otherwise the last choice is selected
			setTimeout(() => {
				setRecord((old) => ({ ...old, patientField: undefined }))
				if (patientCombo.current?.instance) {
					// @ts-ignore
					patientCombo.current.instance.value = null
				}
			}, 100)
		})
	}, [shouldLoadPatient])

	useEffect(() => {
		patientStore.clearSorters()
		patientStore.addSorter((patient_a: PatientForStore, patient_b: PatientForStore) => {
			return (
				(patient_b.laboratoryIri === record.laboratoryIri ? 1 : 0) -
				(patient_a.laboratoryIri === record.laboratoryIri ? 1 : 0)
			)
		})
	}, [record.laboratoryIri])

	// if a default patient is found, we set the patientStore with one value, and set the combo with this patient
	useEffect(() => {
		// a l’ouverture on reset the defaultPatient
		if (!isOpen) return
		patientStore.removeAll()
		if (!defaultPatient) return
		patientStore.add([
			{
				patientId: defaultPatient.id || undefined,
				patientName: `${defaultPatient.lastName} ${defaultPatient.firstName}`,
				laboratoryIri: defaultPatient.laboratory?.["@id"],
				laboratoryLabel: defaultPatient.laboratory?.label || "",
			},
		])
		// il faut attendre que le store ce charge, autrement le choix n’est pas sélectionné
		setTimeout(() => {
			setRecord((old) => ({ ...old, patientField: defaultPatient.id }))
			if (patientCombo.current?.instance) {
				// @ts-ignoresetRecord
				patientCombo.current.instance.value = defaultPatient.id || null
			}
		}, 100)
	}, [defaultPatient, isOpen])

	// when a patient is selected, his status is determined, after than, his class is show in the patient field
	useEffect(() => {
		if (!patientCombo.current?.instance) return
		// @ts-ignore
		const input = patientCombo.current.instance.input
		const { patientClass, patientHasCmu, inactiveCmu } = patientStatus

		while (input.parentNode.children.length > 3) {
			input.parentNode.removeChild(input.parentNode.children[1])
		}
		const elem = document.createElement("span")
		const classList = ["ml-3", "badge", "badge-patient-schedule", "badge-pill"]
		if (patientClass === EquipmentClass.FirstClass) {
			elem.innerHTML += "Classe 1"
			classList.push("badge-soft-info")
		}
		if (patientClass === EquipmentClass.SecondClass) {
			elem.innerHTML += "Classe 2"
			classList.push("badge-soft-primary")
		}
		elem.classList.add(...classList)
		input.after(elem)

		if (patientHasCmu) {
			const badgeCmu = document.createElement("span")
			badgeCmu.classList.add(...["ml-3", "badge", "badge-patient-schedule", "badge-pill", "badge-soft-warning"])
			badgeCmu.innerHTML += `C2S ${inactiveCmu ? " - expirée" : ""}`
			input.after(badgeCmu)
		}

		let categoryKeyForReference = ""

		switch (patientClass) {
			case EquipmentClass.FirstClass:
				categoryKeyForReference = "class1"
				break
			case EquipmentClass.SecondClass:
				categoryKeyForReference = "class2"
				break
			default:
				break
		}

		if (patientHasCmu) {
			categoryKeyForReference += "Cmu"
		}
		if (typeCombo.current?.instance) {
			// @ts-ignore
			typeCombo.current.instance.patientCategory = categoryKeyForReference
		}
	}, [patientStatus])

	// if we already selected a status in the schedule modal, we propose this status by default
	useEffect(() => {
		if (!eventRecord) return

		let status = ""
		if (eventRecord.status) {
			status = eventRecord.status
		} else if (/generated/.test(eventRecord.id) && appointmentFromScheduleSelector?.status) {
			status = appointmentFromScheduleSelector?.status
		}

		setRecord((old) => ({ ...old, status: status }))
	}, [eventRecord, appointmentFromScheduleSelector])

	// if the event is on a Doctolib agenda, the agenda can't be change, only one agenda is propose
	// otherwise we propose all the agenda that aren't doctolib
	// if there isn't any agenda not doctolib, nothing will be propose
	useEffect(() => {
		if (!eventRecord) return
		agendaStore.clearFilters()
		const isDoctolib = (agendaStore.allRecords as unknown as Agenda[]).find((agenda: Agenda) => {
			return agenda.agendaId === eventRecord.agenda
		})?.isDoctolib
		if (!isDoctolib) {
			agendaStore.filter({
				filters: (agenda: Agenda) =>
					agenda.user["@id"].slice("/users/".length) === eventRecord?.resourceId?.toString() &&
					!agenda.isDoctolib,
				replace: true,
			})
			if (agendaStore.records.length === 0) {
				agendaStore.filter({
					filters: (agenda: Agenda) =>
						agenda.user["@id"].slice("/users/".length) === eventRecord?.resourceId?.toString(),
					replace: true,
				})
				if (agendaStore.records.length === 0) {
					toast.warning("Impossible de créer un rendez-vous! Aucun agenda trouvé pour cet utilisateur.")
				} else {
					setOnlyDoctolibAgenda(true)
					return
				}
			}
		} else {
			agendaStore.filter({
				filters: (agenda: Agenda) =>
					agenda.user["@id"].slice("/users/".length) === eventRecord?.resourceId?.toString(),
				replace: true,
			})
		}
		setOnlyDoctolibAgenda(false)
	}, [eventRecord])

	// for a new event, there isn't any agenda set yet, we use the attendances to determine the agenda at time selected
	useEffect(() => {
		if (!eventRecord) return
		if (!eventRecord.agenda) {
			const laboratoryAttendance = attendances?.getLaboratory(eventRecord.resource["@id"], eventRecord.startDate)
			if (laboratoryAttendance) {
				const agenda = (agendaStore.allRecords as unknown as Agenda[]).find(
					(agenda: Agenda) =>
						agenda.laboratory["@id"] === laboratoryAttendance["@id"] &&
						agenda.user["@id"].slice("/users/".length) === eventRecord?.resourceId?.toString()
				)
				if (agenda) {
					setRecord((old) => ({ ...old, agenda: agenda.agendaId, laboratoryIri: agenda.laboratory["@id"] }))
					setIsDoctolib(agenda.isDoctolib)
					return
				}
			}
			setIsDoctolib(false)
		} else {
			const agenda = (agendaStore.allRecords as unknown as Agenda[]).find(
				(agenda: Agenda) => agenda.agendaId === eventRecord.agenda
			) as Agenda

			setIsDoctolib(agenda?.isDoctolib || false)
			setRecord((old) => ({ ...old, laboratoryIri: agenda.laboratory["@id"] }))

			// if the event is on a doctolib agenda it can't be cancelled
			if (agenda?.isDoctolib) {
				stateStore.filter((item: any) => item.data.id !== "CANCELLED")
			}
		}
	}, [eventRecord, agendaStore, attendances])

	const { data: currentPatient } = useQuery(["PATIENTS_API", record.patientField, record.laboratoryIri], () => {
		if (!record.patientField) {
			return undefined
		}
		// @ts-ignore typescript do not recognise the enable record.patientField never undefined
		return API.find<Patient>("PATIENTS_API", record.patientField).then((patient) => {
			return patient as Patient & { patientInsurances: PatientInsurance[] }
		})
	})

	const [patientClass, patientHasCmu] = usePatientClass(currentPatient)

	useEffect(() => {
		if (!currentPatient || currentPatient.id !== record.patientField) return
		if (defaultPatient?.["@id"] !== currentPatient?.["@id"]) setDefaultPatient(currentPatient)
		let inactiveCmu = false
		if (patientHasCmu && currentPatient) {
			inactiveCmu = (findActiveAm(currentPatient.patientInsurances) && true) || false
		}
		setPatientStatus({ patientClass, patientHasCmu, inactiveCmu })
		// s'il n’y a pas d’agenda sélectionné, on choisie l’agenda en fonction du labo du patient
		if (!record?.agenda && currentPatient && currentPatient?.laboratory) {
			const agenda = (agendaStore.allRecords as unknown as Agenda[]).find(
				(agenda: Agenda) =>
					agenda.laboratory["@id"] === currentPatient.laboratory?.["@id"] &&
					agenda.user["@id"].slice("/users/".length) === record?.resourceId?.toString()
			)
			if (agenda) {
				setRecord((old) => ({ ...old, agenda: agenda.agendaId, laboratoryIri: agenda.laboratory["@id"] }))
				setIsDoctolib(agenda.isDoctolib)
			}
		}
	}, [patientClass, patientHasCmu, currentPatient, record?.agenda, record?.resourceId])

	useEffect(() => {
		if (currentPatient) {
			setRecurrence(undefined)
		}
	}, [currentPatient])

	// when a patient is selected, we fetch the data for this patient to determine his class

	const verifyField = (record: { agenda: string; status: string; state: string }): boolean => {
		return record.agenda !== "" && record.status !== "" && record.state !== ""
	}

	const onCloseModal = useCallback(() => {
		if (eventRecord["@id"]) {
			eventRecord.isCreating = false
		}
		setRecurrence(undefined)
		setShouldLoadPatient(false)
		setRecord(defaultRecord)
		closePopup()
	}, [closePopup])

	const saveClickHandler = async (): Promise<void> => {
		const finalize = async (confirm: boolean, wasRecurrence: boolean): Promise<void> => {
			if (confirm) {
				// Update the eventRecord using the default setters

				const keyToIgnore = ["duration", "durationUnit"]
				const updateField = {}
				const patientHasChanged = eventRecord.patientField && eventRecord.patientField !== record.patientField
				for (const key in record) {
					// @ts-ignore
					if (!keyToIgnore.includes(key)) updateField[key] = record[key]
				}
				// il faut supprimer la duration autrement il recalcule l’heure de fin en fonction de la durée.
				if (wasRecurrence) {
					eventRecord.set("duration", undefined)
				}
				eventRecord.set(updateField)

				// s’il y a un patient ignorer la récurrence
				if (record.patientField || !recurrence || recurrence.frequency === "none") {
					await eventRecord.set("recurrenceRule", "")
				}
				// s’il n’y avait pas de récurrence avant
				// si c’est toujours une récurrence
				// on applique la récurrence en court
				else if (!wasRecurrence || eventRecord.recurrence) {
					await eventRecord.set("recurrence", recurrence)
				}

				// Add the eventRecord to the eventStore if it is not already there
				if (!eventRecord.eventStore) {
					eventStore.add(eventRecord)
				}
				if (patientHasChanged) {
					const clone = eventRecord.copy() as EventModel
					clone.isCreating = false
					eventStore.remove(eventRecord)
					eventStore.add(clone)
				} else {
					eventRecord.set("isCreating", false)
				}

				// create Todo if isRelance
				if (appointmentFromScheduleSelector?.isRelance) {
					const agenda = agendaStore.data.filter((a: any) => a.agendaId === eventRecord.data.agenda)
					// @ts-ignore
					const laboratory = agenda[0]?.laboratory["@id"]
					try {
						const relance: AxiosResponse<RelanceResponse> = await API.create("TODOS_API", {
							label: "Effectuer la relance",
							target: "ALL",
							laboratories: [laboratory],
							patient: `/patients/${eventRecord.data.patientField}`,
							realisationDate: eventRecord.data.dateOf,
							type: "RELANCE",
							status: "TODO",
						})
						await API.update("TODOS_API", relance.data.id, {
							label: `Relancer ${relance.data.patient.lastName} ${relance.data.patient.firstName}`,
						})
						toast.success("Relance créée")
					} catch (err) {
						console.error(err)
						toast.error("Erreur lors de la création de la relance")
					}
				}

				onCloseModal()
			}
		}
		if (!verifyField(record)) {
			toast.error("Veuillez renseigner tous les champs obligatoires")
		} else {
			// si c’est un rdv récurrent il faut demander si la modification s’applique à tous les rdv, ou uniquement celui en cours
			if ((!eventRecord.hasGeneratedId && eventRecord.isRecurring) || eventRecord.isOccurrence) {
				// si on a ajouter un patient, il n’est plus possible de faire un rdv multiple,
				// seule la création d’un rdv unique est possible
				if (record.patientField) {
					recurrenceFieldRef.current.instance.widgetMap.recurrenceField.recurrenceConfirmation.widgetMap.changeMultipleButton.hidden =
						true
				}
				recurrenceFieldRef.current.instance.widgetMap.recurrenceField.recurrenceConfirmation.confirm({
					actionType: "update",
					eventRecord,

					changerFn() {
						finalize(true, true)
					},

					cancelFn() {
						finalize(false, true)
					},
				})
			} else {
				eventRecord.beginBatch()
				finalize(true, false)
				eventRecord.endBatch()
			}
		}
	}

	const deleteClickHandler = async (): Promise<void> => {
		eventRecord.isCreating = false
		removeEvent(eventRecord)
		setShouldLoadPatient(false)
		closePopup()
	}

	const setInputCombo = (input: HTMLInputElement, color: string, clearable: boolean): void => {
		if (!input || !input.parentNode) return
		const nbChildrens = 2 + (clearable ? 1 : 0)
		if (input.parentNode.children.length > nbChildrens) {
			input.parentNode.removeChild(input.parentNode.children[0])
		}
		const elem = document.createElement("span")
		elem.innerHTML += "X"
		elem.style.backgroundColor = color
		elem.style.color = color
		elem.style.width = "20px"
		elem.style.height = "20px"
		elem.style.marginLeft = "8px"
		elem.style.borderRadius = "6px"
		input.before(elem)
	}

	const setInputStatus = (input: HTMLInputElement, iconName: string): void => {
		if (!input || !input.parentNode) return
		if (input.parentNode.children.length > 3) {
			input.parentNode.removeChild(input.parentNode.children[0])
		}
		const elem = document.createElement("span")
		const icon = document.createElement("i")
		icon.className = iconName
		icon.classList.add("b-icon-combo-type")
		elem.appendChild(icon)
		input.before(elem)
	}

	const patientSearchControllerRef = useRef<AbortController>()

	const onInput = useCallback(
		_.debounce(async ({ value, source }: { value: string; source: any }) => {
			patientSearchControllerRef.current?.abort()
			patientSearchControllerRef.current = new AbortController()
			// ne pas faire de requête tant qu'il n'y a pas plus de 2 caractères de saisie
			if (value.length < 1) {
				source.items = []
				setLastSearchPatient("")
				return
			}
			// dans le cas ou l’on a déjà fait une recherche et que le nombre retourné était moins de 10,
			// cela veut dire que toute nouvelle recherche incluant les caractère de la précédente recherche ne va pas renvoyer de nouveau patient
			if (
				patientStore.allRecords.length < MAX_PATIENTS &&
				value.indexOf(lastSearchPatient) >= 0 &&
				lastSearchPatient !== ""
			) {
				return
			}
			try {
				source.picker.element.dataset.emptyText = "Recherche ..."

				const patients = await getListPatient(value, patientSearchControllerRef)

				const listPatients = patients.map((patient: Patient) => setPatientToPatientStore(patient))

				patientStore.removeAll()
				patientStore.add([...listPatients])
			} catch (err) {
				source.picker.element.dataset.emptyText = "Aucun résultat"
			}
			setLastSearchPatient(value)
		}, 300),
		[lastSearchPatient, patientStore]
	)

	return (
		<Modal isOpen={isOpen} size="lg">
			<ModalHeader>{record.name}&nbsp;</ModalHeader>
			<ModalBody>
				<div className="b-panel-content b-popup-content b-eventeditor-content b-box-center b-widget-scroller b-resize-monitored b-content-element b-auto-container b-flex-row">
					<BryntumCombo
						cls={"event-editor-fields"}
						{...{
							required: false,
							weight: 1,
							type: "combo",
							label: "patient",
							valueField: "patientId",
							displayField: "patientName",
							//filterOperator: "*",
							clearable: true,
							class: "b-field-inner",
						}}
						primaryFilter={primaryFilter}
						ref={patientCombo}
						// @ts-ignore
						value={record.patientField}
						disabled={isDoctolib || onlyDoctolibAgenda}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
						store={patientStore}
						labelCls={"b-label"}
						onInput={onInput}
						listItemTpl={useCallback(
							(patient: any) => `
									<div class="custom-patient-combo">
										<span>${patient.patientName}</span>
										<span class="custom-patient-combo-laboratory">${patient.laboratoryLabel}</span>
									</div>
								`,
							[]
						)}
						onSelect={({ record: patient }: { record: PatientForStore }) => {
							setShouldLoadPatient(true)
							if (patient) {
								setRecord((old) => ({
									...old,
									patientField: patient.patientId,
									name: patient.patientName,
								}))
								setPatientLaboratoryIri(patient.laboratoryIri)
							} else {
								setRecord((old) => ({ ...old, patientField: null, name: "" }))
								setPatientLaboratoryIri("")
							}
						}}
						onClear={({ source }: { source: any }) => {
							setShouldLoadPatient(true)
							clearInput(source.input, 1)
							setPatientStore(patientStore)
							setRecord((old) => ({ ...old, patientField: null, name: "" }))
							setPatientLaboratoryIri("")
						}}
					/>
					{patientLaboratoryIri && record.laboratoryIri !== patientLaboratoryIri && (
						<div className="alert-wrong-laboratory">
							<Alert type="warning" message="Patient n’est pas dans le laboratoire associé à l’agenda" />
						</div>
					)}
					<BryntumButton
						{...{
							type: "button",
							id: "addPatientButton",
							weight: 2,
							icon: "fa-solid fa-user-plus",
							text: "Ajouter un patient",
							color: "primary",
							onClick() {
								setOpenPatientModal(true)
							},
						}}
						hidden={isDoctolib || onlyDoctolibAgenda}
					/>
					<BryntumCombo
						cls={"event-editor-fields"}
						{...{
							required: true,
							editable: true,
							weight: 2,
							type: "combo",
							store: typesStore,
							label: "type",
							valueField: "scheduleStatus",
							displayField: "label",
							name: "status",
							clearable: true,
						}}
						ref={typeCombo}
						listItemTpl={useCallback((_record: any) => {
							// @ts-ignore
							const patientCategory = typeCombo.current?.instance.patientCategory
							return `
								<div>
									<span style="width:40px; height:40px; background-color:${_record.color}; color:${
								_record.color
							}; margin-right: 5px; border-radius: 6px;" >XX</span>
									<span>${_record.label}</span>
									${
										_record.scheduleInterval[patientCategory]
											? `<small>
									<b>
										( rendez-vous théorique dans ${_record.scheduleInterval[patientCategory]}
										mois )
									</b>
								</small>`
											: ""
									}
								</div>
							`
						}, [])}
						onClear={({ source }: { source: Combo }) => {
							// @ts-ignore
							clearInput(source.input, 0)
						}}
						value={record.status}
						onPaint={(event: any) => {
							if (event.source.record) {
								setInputCombo(event.source.input, event.source.record.color, event.source.clearable)
							}
						}}
						onChange={(event: any) => {
							const input = event.source.input
							if (input.parentNode.children.length > 3) {
								input.parentNode.removeChild(input.parentNode.children[0])
							}
							if (event.source.record) {
								setInputCombo(event.source.input, event.source.record.color, event.source.clearable)
							}
						}}
						onSelect={(event: any) => {
							if (event.record) {
								// @ts-ignore

								setRecord((old) => ({
									...old,
									status: event.record.scheduleStatus,
									color: event.record.color,
								}))
							}
							if (event.record && event.userAction) {
								const endDate = dayjs(record.startDate).add(event.record.duration, "m").toDate()
								const duration = setDuration(event.record.duration, "m")
								setRecord((old) => ({
									...old,
									endDate: endDate,
									duration: duration,
									durationUnit: "m",
								}))
							}
						}}
						disabled={isDoctolib}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
					/>
					<BryntumResourceCombo
						cls={"event-editor-fields"}
						{...{
							label: "Utilisateur",
							disabled: true,
							weight: 3,
							store: resourceStore,
							filterOperator: "*",
							clearable: true,
							id: "utilisateur",
							multiSelect: false,
							valueField: "id",
							displayField: "name",
						}}
						onPaint={(event: any) => {
							if (!event.source.record) return
							let data: User
							if (Array.isArray(event.source.record)) {
								data = event.source.record[0].data
							} else {
								data = event.source.record.data
							}
							// @ts-ignore
							setInputCombo(event.source.input, data.eventColor, event.source.clearable)
						}}
						onChange={(event: any) => {
							const input = event.source.input
							if (input.parentNode.children.length > 3) {
								input.parentNode.removeChild(input.parentNode.children[0])
							}
							if (!event.source.record) return
							let data: User
							if (Array.isArray(event.source.record)) {
								data = event.source.record[0].data
							} else {
								data = event.source.record.data
							}
							// @ts-ignore
							setInputCombo(event.source.input, data.eventColor, event.source.clearable)
						}}
						onSelect={(e: any) => {
							//@ts-ignore
							let data: User
							if (!e.record) return
							if (Array.isArray(e.record)) {
								data = e.record[0].data
							} else {
								data = e.record.data
							}
							// pour les rdv doctolib il ne devrait pas être possible de changer l’utilisateur
							if (!isDoctolib) {
								//@ts-ignore
								agendaStore.clearFilters()
								agendaStore.filter({
									//@ts-ignore
									filters: (agenda: Agenda) =>
										agenda?.user["@id"] === data["@id"] && !agenda?.isDoctolib,
									replace: true,
								})
								const resourceId = e.record.id
								if (agendaStore.count === 1) {
									setRecord((old) => ({
										...old,
										agenda: (agendaStore.first as unknown as Agenda).agendaId,
										resourceId,
									}))
								} else {
									setRecord((old) => ({ ...old, agenda: "", resourceId, laboratoryIri: "" }))
								}
							}
						}}
						// @ts-ignore
						value={record.resourceId}
						listItemTpl={useCallback(
							(record: any) => `
					<div>
						<span style="width:40px; height:40px; background-color:${record.eventColor}; color:${record.eventColor}; margin-right: 5px; border-radius: 6px;" >XX</span>
						<span>${record.name}</span>
					</div>
				`,
							[]
						)}
						disabled={isDoctolib}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
					/>
					<BryntumCombo
						cls={"event-editor-fields"}
						{...{
							required: true,
							weight: 4,
							editable: false,
							type: "combo",
							store: agendaStore,
							label: "Agenda",
							valueField: "agendaId",
							displayField: "label",
							name: "agenda",
							onPaint: (event: any) => {
								if (event.source.record) {
									setInputCombo(event.source.input, event.source.record.color, event.source.clearable)
								}
							},

							onSelect: ({ source, record }: { source: Combo; record: any }) => {
								if (record) {
									// @ts-ignore
									setRecord((old) => ({
										...old,
										agenda: record.agendaId,
										laboratoryIri: record.laboratory["@id"],
									}))
								} else {
									setRecord((old) => ({ ...old, agenda: "", laboratoryIri: "" }))
								}
							},
							onChange: ({ source }: { source: Combo }) => {
								// @ts-ignore
								const input = source.input
								if (input.parentNode.children.length > 2) {
									input.parentNode.removeChild(input.parentNode.children[0])
								}
								if (source.record) {
									//@ts-ignore
									setInputCombo(source.input, source.record.color, source.clearable)
								}
							},
						}}
						listItemTpl={useCallback(
							(record: any) => `
					<div>
						<span style="width:40px; height:40px; background-color:${record.color}; color:${record.color}; margin-right: 5px; border-radius: 6px;" >XX</span>
						<span>${record.label}</span>
					</div>
				`,
							[]
						)}
						value={record.agenda}
						disabled={isDoctolib}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
					/>
					<BryntumCombo
						cls={"event-editor-fields"}
						{...{
							required: true,
							editable: false,
							weight: 5,
							type: "combo",
							store: stateStore,
							label: "statut",

							valueField: "id",
							displayField: "state",
							name: "state",
							filterOperator: "*",
							clearable: true,
							onPaint: ({ source }: { source: Combo }) => {
								if (source.record) {
									// @ts-ignore
									setInputStatus(source.input, source.record.icon)
								}
							},
							onChange: ({ source }: { source: Combo }) => {
								// @ts-ignore
								const input = source.input
								if (input.parentNode.children.length > 3) {
									input.parentNode.removeChild(input.parentNode.children[0])
								}
								if (source.record) {
									//@ts-ignore
									setInputStatus(source.input, source.record.icon)
								}
							},

							onSelect: ({ source, record }: { source: Combo; record: any }) => {
								if (record) {
									// @ts-ignore
									const state = record.id
									setRecord((old) => ({ ...old, state: state }))
								} else {
									setRecord((old) => ({ ...old, state: "" }))
								}
							},
							onClear: ({ source }: { source: Combo }) => {
								// @ts-ignore
								clearInput(source.input, 0)
								setRecord((old) => ({ ...old, state: "" }))
							},
						}}
						listItemTpl={useCallback(
							(record: any) => `
					<div class="custom-status-combo">
						<span><i class="${record.icon}"></i></span>
						<span>${record.state}</span>
					</div>
				`,
							[]
						)}
						value={record?.state || "WAITING"}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
					/>
					<BryntumDateTimeField
						cls={"event-editor-fields"}
						id="startTimeField"
						{...{
							label: "Début",
							step: {
								magnitude: 5,
								unit: "m",
							},
						}}
						onChange={(e: any) => {
							if (e.userAction) {
								if (record.status != null) {
									// @ts-ignore
									const endDate = dayjs(e.source.value).add(record.duration, "m").toDate()
									setRecord((old) => ({
										...old,
										endDate: endDate,
										startDate: e.source.value,
									}))
								}
							}
						}}
						value={record.startDate}
						disabled={isDoctolib}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
					/>
					<BryntumDateTimeField
						cls={"event-editor-fields"}
						id="endTimeField"
						{...{
							label: "Fin",
							step: {
								magnitude: 5,
								unit: "m",
							},
							onFocusIn(e: any) {
								const endTimeField = e.toWidget
								endTimeField._step = {
									magnitude: 5,
									unit: "m",
								}
							},
						}}
						onChange={(e: any) => {
							setRecord((old) => ({ ...old, endDate: e.source.value }))
						}}
						value={record.endDate}
						disabled={isDoctolib}
						hidden={(isDoctolib && eventRecord?.hasGeneratedId) || onlyDoctolibAgenda}
					/>
					<BryntumContainer
						ref={recurrenceFieldRef}
						hidden={isDoctolib}
						{...configRecurringContainer}
						disabled={!!currentPatient}
						extraData={{ eventRecord, setRecurrence, recurrence }}
					/>
					{!isDoctolib && (
						<div
							id="schedule-notes"
							className="b-widget b-field b-datetimefield b-label-before b-has-label b-outer b-visible-scrollbar b-chrome event-editor-fields">
							<label className="b-label b-align-start b-label-notes">Notes</label>
							<div className="b-field-inner">
								<Editor
									apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
									init={{
										height: 200,
										width: "100%",
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ",
										language: "fr_FR",
										browser_spellcheck: true,
										contextmenu: false,
										content_style: "p { margin: 0.5em 0; } ", // reduce margin between p
									}}
									value={record.notes || ""}
									onEditorChange={(text) => {
										setRecord((old) => ({ ...old, notes: text }))
									}}
								/>
							</div>
						</div>
					)}
					{isDoctolib && (
						<>
							<div className="d-doctolib">
								Cette horaire ce trouve sur un agenda doctolib. Veuillez-passer par
								{
									<span
										className="b-doctolib"
										onClick={() => {
											if (activePatient?.id) {
												zipper.openPatientHistorique(activePatient)
											} else {
												return zipper.openListView()
											}
										}}>
										Doctolib
									</span>
								}
								pour créer ou modifier un rendez-vous.
							</div>
							<BryntumButton
								{...{
									color: "b-blue",
									id: "doctolibButton",
								}}
								text={
									activePatient.id
										? `RDV Doctolib pour ${activePatient.gender === "FEMME" ? "Mme" : "Mr."} ${
												activePatient.lastName
										  }`
										: "Vue liste Doctolib"
								}
								hidden={!isDoctolib}
								onClick={() => {
									if (activePatient?.id) {
										zipper.openPatientHistorique(activePatient)
									} else {
										return zipper.openListView()
									}
								}}
							/>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				<BryntumButton onClick={onCloseModal} text="Annuler" id="cancelEventButton" />
				<BryntumButton
					onClick={deleteClickHandler}
					color="b-red"
					text="Supprimer"
					// suppression non proposé:
					// - rdv doctolib
					// - nouveau rdv (les occurrences de rdv ont également une id créer par le calendrier)
					hidden={isDoctolib || (eventRecord?.hasGeneratedId && !eventRecord?.recurrence)}
					id="deleteEventButton"
				/>

				<BryntumButton
					text="Confirmer"
					onClick={saveClickHandler}
					id="confirmEventButton"
					hidden={onlyDoctolibAgenda}
				/>
				{record.status === "APPAREILLE" && eventRecord?.isCreating === false && (
					<>
						<BryntumButton
							ref={teletransBtnRef}
							text="Télétransmission contrôle"
							color="b-teletrans"
							onClick={() => setOpenTeletrans(true)}
						/>

						<ModalTeletransmissionSuivi
							chooseTeletrans={openTeletrans}
							setChooseTeletrans={setOpenTeletrans}
							handleNavLink={() => setOpenTeletrans(false)}
							schedule={eventRecord?.data}
						/>
					</>
				)}
			</ModalFooter>
		</Modal>
	)
}

export default EventEditor
