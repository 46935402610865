import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import BillingView from "features/sales/views/billing/BillingView"
import { lazy } from "react"
import { VenteRoutePrefix, VenteRoutes, noPrefix } from "./routes"

const MesVentesEnAttente = lazy(() => import("pages/mon-compte/MesVentesEnAttente"))
const MesVentes = lazy(() => import("pages/mon-compte/MesVentes"))
const Vente = lazy(() => import("pages/vente/Vente"))

function VenteRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={VenteRoutePrefix}>
			<CardItem name="Terminées" url={noPrefix(VenteRoutes.VenteTerminee)} component={MesVentes} exact={true} />
			<CardItem
				name="En attente"
				url={noPrefix(VenteRoutes.VenteAttente)}
				component={MesVentesEnAttente}
				exact={true}
			/>
			<CardItem
				name="Nouvelle"
				url={noPrefix(VenteRoutes.NouvelleVente)}
				component={Vente}
				onlyVisibleOnExactRoute
			/>

			<CardItem
				name="Vente"
				useSlug="id"
				url={noPrefix(VenteRoutes.EditVente)}
				component={Vente}
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name="Facture"
				useSlug="id"
				url={noPrefix(VenteRoutes.Facture)}
				component={BillingView}
				onlyVisibleOnExactRoute
			/>
		</CardWithTabs>
	)
}

export default VenteRouter
