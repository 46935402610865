import { Select } from "antd"

const { Option } = Select

const GenderCol = (handlePatientChange, editingKey) => {
	const genderRender = (gender, record) => {
		return editingKey === record.key ? (
			<Select
				style={{ width: "100%", maxWidth: "100px" }}
				placeholder="Sélectionner genre"
				value={record["patient.gender"]}
				onChange={(value) => handlePatientChange(value, record, "patient.gender")}>
				<Option key="HOMME" value="HOMME">
					H
				</Option>
				<Option key="FEMME" value="FEMME">
					F
				</Option>
			</Select>
		) : (
			<strong className="mx-2"> {gender === "HOMME" ? "H" : "F"} </strong>
		)
	}

	return {
		title: null,
		key: "gender",
		dataIndex: "patient.gender",
		width: "15px",
		render: genderRender,
		filters: [
			{
				text: "HOMME",
				value: "HOMME",
			},
			{
				text: "FEMME",
				value: "FEMME",
			},
		],
		validate: (cond) =>
			!cond["patient.gender"] || cond["patient.gender"] === ""
				? "Veuillez renseigner le genre."
				: "",
	}
}

export default GenderCol
