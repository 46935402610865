import { Company, DocumentSendMailRequestData, Patient, SupplierInvoice, User } from "@audiowizard/common"
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import _ from "lodash"
import { Canvas, CreateCanvasPayload, UpdateCanvasPayload } from "pages/feuille-soin/types"
import { QuotePacksAnnex } from "pages/Settings/QuotationPackList/AnnexesModal"
import * as ApiRoutes from "../config"
import * as AuthApi from "./AuthApi"

export type ApiTarget = keyof typeof ApiRoutes

export type CollectionResponse<T extends unknown[]> = {
	"hydra:member": T
	"hydra:totalItems": number
	"hydra:view": {
		"hydra:next": string
	}
}

function findAll<T extends unknown[]>(
	target: ApiTarget,
	condition?: string,
	raw?: false,
	config?: AxiosRequestConfig
): Promise<T>
function findAll<T extends unknown[]>(
	target: ApiTarget,
	condition?: string,
	raw?: true,
	config?: AxiosRequestConfig
): Promise<CollectionResponse<T>>
async function findAll<T extends unknown[]>(
	target: ApiTarget,
	condition?: string,
	raw = false,
	config?: AxiosRequestConfig
): Promise<T> {
	const response = await axios.get(condition ? ApiRoutes[target] + condition : ApiRoutes[target], config)

	if (raw === true) {
		return response.data
	} else {
		return response.data["hydra:member"] ? response.data["hydra:member"] : response.data
	}
}

async function countAll(target: ApiTarget, condition?: string): Promise<number> {
	const response = await axios.get(condition ? ApiRoutes[target] + condition : ApiRoutes[target])

	return response.data["hydra:totalItems"] ? +response.data["hydra:totalItems"] : 0
}

// TARGET = PATIENTS_API
async function custom<T>(target: string, options?: AxiosRequestConfig): Promise<T> {
	const response = await axios.get(ApiRoutes["API_URL"] + target, options)

	return response.data
}

const sante = async <T>(endpoint: string, params: string): Promise<T> => {
	params = params?.toString()
	return AuthApi.getTokenHealthcare().then(async (token) => {
		let paramsWithSlash = params
		if (params?.length && params.charAt(0) !== "?") {
			paramsWithSlash = `/${params}`
		}
		const response = await axios.get(ApiRoutes["API_SANTE_URL"] + endpoint + paramsWithSlash, {
			headers: { Authorization: "Bearer " + token },
		})

		return await response.data
	})
}

// TARGET = PATIENTS_API
async function mail<T>(target: ApiTarget, data: unknown): Promise<T> {
	const response = await axios.post(ApiRoutes[target], data)

	return await response.data
}

//findFiltered("PATIENT_MEDICALS", "patient", patient['@id'])
/**
 * @deprecated Utilisez `API.findAll(target, "?field=parameter")` à la place
 */
async function findFiltered<T>(target: ApiTarget, field: string, parameter: string): Promise<T> {
	const response = await axios.get(ApiRoutes[target] + "?" + field + "=" + parameter)

	return await response.data["hydra:member"]
}

/**
 * @deprecated Utilisez `API.findAll(target, "?field=parameter")` à la place
 */
async function findCustom<T>(target: ApiTarget, parameter = null): Promise<T> {
	const response = await axios.get(ApiRoutes[target] + "?" + parameter)

	return await response.data["hydra:member"]
}

async function find<T>(target: ApiTarget, id: number | string, config?: AxiosRequestConfig): Promise<T> {
	const response = await axios.get(ApiRoutes[target] + "/" + id, config)

	return await response.data
}

async function update<T>(target: ApiTarget, id: number | string, data: unknown): Promise<AxiosResponse<T>> {
	const response = await axios.put(ApiRoutes[target] + "/" + id, data, {
		headers: { "content-type": "application/ld+json" },
	})

	return response
}

async function patch<T>(target: ApiTarget, id: number | string, data: unknown): Promise<AxiosResponse<T>> {
	const response = await axios.patch(ApiRoutes[target] + "/" + id, data, {
		headers: { "content-type": "application/ld+json" },
	})

	return response
}

async function create<T>(target: ApiTarget, data: unknown): Promise<AxiosResponse<T>> {
	const response = await axios.post(ApiRoutes[target], data, { headers: { "content-type": "application/ld+json" } })

	return response
}

async function updateDocument<T>(id: number, document: { [key: string]: any }): Promise<AxiosResponse<T>> {
	const data = new FormData()

	if (document.file)
		data.append("file", document.file, `${_.deburr(document.filename.replaceAll(" ", "-"))}.${document.extension}`)
	if (document.filename) data.append("filename", document.filename)
	if (document.company) data.append("company", document.company)
	if (document.laboratory) data.append("laboratory", document.laboratory)
	if (document.laboratories) data.append("laboratories", document.laboratories)
	if (document.config) data.append("config", JSON.stringify(document.config))
	if (document.label) data.append("label", document.label)
	if (document.extension) data.append("extension", document.extension)
	if (document.type) data.append("type", document.type)
	if (document.status) data.append("status", document.status)
	if (document.patient) {
		// Get Iri if document.patient is Patient entity
		const patientIri = typeof document.patient === "string" ? document.patient : document.patient["@id"]
		data.append("patient", patientIri)
	}
	if (document.numero) data.append("numero", document.numero)
	if (document.seller) data.append("seller", document.seller)
	if (document.hasSignature) data.append("hasSignature", document.hasSignature)
	if (document.hasSignature) data.append("hasSignatureQuoteSteps", JSON.stringify(document.hasSignatureQuoteSteps))
	if (document.simplifiedQuote) data.append("simplifiedQuote", document.simplifiedQuote)

	// This form-data force an override on Symfony's side to treat POST request as PUT request.
	// This is needed because PUT multipart/form-data are not handled the same way as POST.
	data.append("_method", "put")

	const response = await axios.post(`${ApiRoutes.DOCUMENTS_API}/${id}/update_file`, data)

	return response
}

async function updateDoctolibSchedule<T>(
	eventId: string,
	data: { state?: string; notes?: string; preScheduleNote?: string }
): Promise<AxiosResponse<T>> {
	const res = await axios.put(`${ApiRoutes.SCHEDULES_API}/${eventId}/doctolib_update`, {
		state: data.state || undefined,
		notes: data.notes,
		preScheduleNote: data.preScheduleNote,
	})
	return res
}

async function createDocument<T>(document: { [key: string]: any }): Promise<AxiosResponse<T>> {
	const data = new FormData()

	document.file &&
		data.append("file", document.file, `${_.deburr(document.filename.replaceAll(" ", "-"))}.${document.extension}`)

	document.filename && data.append("filename", document.filename)
	document.company && data.append("company", document.company)
	document.laboratory && data.append("laboratory", document.laboratory)
	document.laboratories && data.append("laboratories", JSON.stringify(document.laboratories))
	document.config && data.append("config", JSON.stringify(document.config))
	document.label && data.append("label", document.label)
	document.extension && data.append("extension", document.extension)
	document.type && data.append("type", document.type)
	document.status && data.append("status", document.status)
	document.patient && data.append("patient", document.patient)
	document.numero && data.append("numero", document.numero)
	document.seller && data.append("seller", document.seller)
	document.hasSignature && data.append("hasSignature", document.hasSignature)
	document.hasSignatureQuoteSteps &&
		data.append("hasSignatureQuoteSteps", JSON.stringify(document.hasSignatureQuoteSteps))
	document.simplifiedQuote && data.append("simplifiedQuote", document.simplifiedQuote)

	const response = await axios.post(ApiRoutes.DOCUMENTS_API, data)

	return response
}

async function documentSendMail(documentId: number, sendMailValue: DocumentSendMailRequestData): Promise<void> {
	await axios.post(`${ApiRoutes["DOCUMENTS_API"]}/${documentId}/send_mail`, sendMailValue) // axios directement pour faire un POST sur une route custom
}

async function templateSendMail(templateIri: string, dataContext: unknown): Promise<void> {
	await axios.post(`${ApiRoutes["API_URL"]}${templateIri}/send_mail`, dataContext || {}) // axios directement pour faire un POST sur une route custom
}

async function deleteRessource<T>(target: string): Promise<AxiosResponse<T>> {
	return await axios.delete(ApiRoutes["API_URL"] + target)
}

async function remove<T>(target: ApiTarget, id: number | string): Promise<T | AxiosResponse<unknown>> {
	return axios.delete(ApiRoutes[target] + "/" + id)
}

type QuotePackAnnex = {
	file: File | string
	label: string
	quotePacks: string[]
}

async function createQuotePackAnnex<T>(annex: QuotePackAnnex): Promise<AxiosResponse<T>> {
	const data = new FormData()
	data.append("file", annex.file)
	data.append("label", annex.label)
	data.append("quotePacks", JSON.stringify(annex.quotePacks))

	const response = await axios.post(ApiRoutes.QUOTE_PACKS_ANNEXES_API, data)
	return response
}

async function updateQuotePackAnnex<T>(
	id: number,
	annex: { label: string; quotePacks: string[] }
): Promise<AxiosResponse<T>> {
	const data = new FormData()
	data.append("label", annex.label)
	data.append("quotePacks", JSON.stringify(annex.quotePacks))

	const response = await axios.post(ApiRoutes.QUOTE_PACKS_ANNEXES_API + "/" + id.toString(), data)
	return response
}

async function getQuotePackAnnexes(quotePackIri: string): Promise<QuotePacksAnnex[]> {
	if (quotePackIri == null) return [] // ALL_HIBOUTIK_PRODUCTS quote pack

	let page = 1
	let totalItems = Infinity
	const annexes: QuotePacksAnnex[] = []

	while (annexes.length < totalItems) {
		const res = await API.findAll<QuotePacksAnnex[]>(
			"QUOTE_PACKS_ANNEXES_API",
			`?quotePacks=${quotePackIri}page=${page}`,
			true
		)
		page++
		totalItems = res["hydra:totalItems"]
		annexes.push(...res["hydra:member"])
	}
	return annexes
}

async function getOneQuotePackAnnex<T>(annexIri: string): Promise<AxiosResponse<T>> {
	const res = await axios.get(ApiRoutes.API_URL + annexIri)
	return res.data
}

export interface APIAdresseGouvFr {
	type: string
	geometry: {
		type: string
		coordinates: number[]
	}
	properties: {
		label: string
		score: number
		id: string
		type: string
		name: string
		postcode: string
		citycode: string
		x: number
		y: number
		population?: number
		city: string
		context: string
		importance: number
	}
}

async function getAddress(value: string): Promise<APIAdresseGouvFr[]> {
	if (value.trim().length < 3) return []
	const fetchAddress = await fetch(
		`https://api-adresse.data.gouv.fr/search/?q=${value.trim().replace(" ", "+")}&type=housenumber&autocomplete=1`,
		{
			method: "GET",
			redirect: "follow",
		}
	)
		.then((response) => response.json())
		.then((result) => result.features)
		.catch((error) => console.error("error", error))

	return fetchAddress
}

async function goCardlessMandateNew(
	audioWizardContractId?: number
): Promise<AxiosResponse<{ redirectFlowUrl: string }>> {
	// Fix rapide, pour récupérer l'audiowizardContractId si il est pas dispo depuis le context
	if (audioWizardContractId == null) {
		audioWizardContractId = (await AuthApi.fetchUser())!.company!.audioWizardContract!.id!
	}

	return await axios.post(`${ApiRoutes["AUDIO_WIZARD_CONTRACTS_API"]}/${audioWizardContractId}/mandate/new`, {}) // On doit envoyer un object vide pour ne pas avoir une erreur de api platform
}
async function goCardlessMandateValidate(
	audioWizardContractId: number,
	redirectFlowId: string,
	services: string[] = []
): Promise<void> {
	await axios.post(`${ApiRoutes["AUDIO_WIZARD_CONTRACTS_API"]}/${audioWizardContractId}/mandate/validate`, {
		redirectFlowId,
		services,
	})
}

function updateCanvasPersonalizations(canvasId: number, data: UpdateCanvasPayload): Promise<Canvas> {
	const formData = new FormData()
	formData.append("scope", data.scope)

	for (let i = 0; i < data.canvasPersonnalizations.length; i++) {
		const shape = data.canvasPersonnalizations[i]

		formData.append(`canvasPersonnalizations[${i}][type]`, shape.type)
		formData.append(`canvasPersonnalizations[${i}][value]`, shape.key)
		formData.append(`canvasPersonnalizations[${i}][positionFromTop]`, shape.top.toString())
		formData.append(`canvasPersonnalizations[${i}][positionFromLeft]`, shape.left.toString())
		formData.append(`canvasPersonnalizations[${i}][scaleX]`, shape.scaleX.toString())
		formData.append(`canvasPersonnalizations[${i}][scaleY]`, shape.scaleY.toString())
		formData.append(`canvasPersonnalizations[${i}][angle]`, shape.angle.toString())
		formData.append(`canvasPersonnalizations[${i}][isHidden]`, shape.hidden ? "1" : "0")
	}

	return axios.post(`${ApiRoutes.CANVAS_API}/${canvasId}/update`, formData)
}

function createCanvasPersonalizations(data: CreateCanvasPayload): Promise<Canvas> {
	const formData = new FormData()
	formData.append("scope", data.scope)
	formData.append("label", data.label)
	formData.append("laboratory", data.laboratory)

	for (let i = 0; i < data.canvasPersonnalizations.length; i++) {
		const shape = data.canvasPersonnalizations[i]

		formData.append(`canvasPersonnalizations[${i}][type]`, shape.type)
		formData.append(`canvasPersonnalizations[${i}][value]`, shape.key)
		formData.append(`canvasPersonnalizations[${i}][positionFromTop]`, shape.top.toString())
		formData.append(`canvasPersonnalizations[${i}][positionFromLeft]`, shape.left.toString())
		formData.append(`canvasPersonnalizations[${i}][scaleX]`, shape.scaleX.toString())
		formData.append(`canvasPersonnalizations[${i}][scaleY]`, shape.scaleY.toString())
		formData.append(`canvasPersonnalizations[${i}][angle]`, shape.angle.toString())
		formData.append(`canvasPersonnalizations[${i}][isHidden]`, shape.hidden ? "1" : "0")
	}

	return axios.post(ApiRoutes.CANVAS_API, formData)
}

function updateCompanyLogo(companyId: number, file: File): Promise<AxiosResponse<Company>> {
	const data = new FormData()
	data.append("logo", file)
	return axios.post(`${ApiRoutes.COMPANIES_API}/${companyId}/logo`, data)
}

function updateSubCompany(subCompanyId: number, data: FormData): Promise<AxiosResponse<Company>> {
	return axios.post(`${ApiRoutes.SUB_COMPANIES_API}/${subCompanyId}`, data)
}
function updateUserSignature(userId: number, file: File): Promise<AxiosResponse<User>> {
	const data = new FormData()
	data.append("signature", file)

	return axios.post(`${ApiRoutes.USERS_API}/${userId}/signature`, data)
}
function updateSupplierInvoiceFile(invoiceId: number, file: File): Promise<AxiosResponse<SupplierInvoice>> {
	const data = new FormData()
	data.append("file", file)

	return axios.post(`${ApiRoutes.SUPPLIER_INVOICES_API}/${invoiceId}/scan_upload`, data)
}

function updateWhiteLabelLogo(whiteLabelId: number, logo: File): Promise<AxiosResponse> {
	const data = new FormData()
	data.append("logo", logo)

	return axios.post(`${ApiRoutes.WHITE_LABELS_API}/${whiteLabelId}/logo`, data)
}

// Iri api utility

async function updateWithIri<T>(iri: string, data: Record<string, any>): Promise<AxiosResponse<T>> {
	return await axios.put(ApiRoutes.API_URL + iri, data)
}

async function get<T>(iri: string, query?: string): Promise<T> {
	const response = await axios.get(ApiRoutes.API_URL + iri + (query ?? ""))

	return response.data["hydra:member"] ?? response.data
}

const getHiboutik = async <T>(endpoint: string, id: number | null = null): Promise<T> => {
	let path = endpoint

	if (id) {
		path += `/${id}`
	}
	const response = await axios.get(ApiRoutes.API_URL + "/store/" + path)

	return response?.data
}

async function fetchPatientNotesAndSchedules(patientId: number | string): Promise<Patient> {
	const data = await API.find<Patient>("PATIENTS_API", `${patientId}/notes_and_schedules`)

	// patient route only returns 5 schedules, manually fetch every schedule
	let page = 1
	let totalItems = Infinity
	const schedules = []

	while (schedules.length < totalItems) {
		const response = await API.findAll(
			"SCHEDULES_API",
			`?patient.id=${patientId}&order[dateOf]=desc&page=${page}`,
			true
		)

		page++
		totalItems = +response["hydra:totalItems"]
		schedules.push(...response["hydra:member"])

		if (!response["hydra:member"]?.length) break
	}

	// @ts-expect-error: TODO: type "schedules" on Patient
	data.schedules = schedules

	return data
}

async function getCurrentLocalisationMeteoData(city: string): Promise<any> {
	const options = {
		headers: {
			"X-RapidAPI-Key": "478a51a3c3mshdc4ece3a1b331c9p18fd5ajsn6e69efc88b75",
			"X-RapidAPI-Host": "weatherapi-com.p.rapidapi.com",
		},
	}

	const response = await axios.get(`https://weatherapi-com.p.rapidapi.com/current.json?q=${city}&lang=fr`, options)

	return response.data
}

async function fetchPatientByName(partialName: string, searchString: string): Promise<Patient[]> {
	const response = await API.findAll<Patient[]>(
		"PATIENTS_API",
		`/searchByName?searchName=${partialName}` + (searchString ? `&${searchString}` : "")
	)
	return response
}

async function doAction<T>(target: ApiTarget, id: number | string, data: unknown): Promise<AxiosResponse<T>> {
	return axios.post(ApiRoutes[target]?.replace("{id}", `${id}`), data, {
		headers: { "content-type": "application/ld+json" },
	})
}

const API = {
	findAll,
	countAll,
	delete: deleteRessource,
	remove,
	find,
	update,
	updateWithIri,
	patch,
	create,
	findFiltered,
	findCustom,
	custom,
	mail,
	sante,
	getAddress,
	createDocument,
	updateDocument,
	documentSendMail,
	templateSendMail,
	goCardlessMandateNew,
	goCardlessMandateValidate,
	updateCompanyLogo,
	updateSubCompany,
	updateUserSignature,
	updateSupplierInvoiceFile,
	updateWhiteLabelLogo,
	updateDoctolibSchedule,
	updateCanvasPersonalizations,
	createCanvasPersonalizations,
	get,
	createQuotePackAnnex,
	updateQuotePackAnnex,
	getQuotePackAnnexes,
	getOneQuotePackAnnex,
	getHiboutik,
	fetchPatientNotesAndSchedules,
	getCurrentLocalisationMeteoData,
	fetchPatientByName,
	doAction,
}
export default API
