import { PropsWithChildren } from "react"

type SectionHeaderProps = PropsWithChildren<{
	title?: string
	className?: string
}>

const SectionHeader = ({ children, title, className }: SectionHeaderProps): JSX.Element => {
	return (
		<div className={`cardtabs-subtitle my-3 ${className}`}>
			<div className="d-flex justify-content-between  align-items-end">
				<h4>{title}</h4>
				<div className="d-flex flex-row text-break">{children}</div>
			</div>
		</div>
	)
}

export default SectionHeader
