export enum LocaleNamespace {
	Common = "common", // relatives to common text like button submit
	Context = "context", // relatives to data stored in context
	Pages = "pages",
	AfterSale = "scope_afterSale",
	AccountExport = "scope_accountExport",
	CashValidation = "scope_cashValidation",
	Reminder = "scope_reminder",
	ReminderSMS = "scope_reminderSMS",
	Diagnostic = "scope_diagnostic",
	SimpleQuote = "scope_simpleQuote",
	PrescriberReport = "scope_prescriberReport",
	Invoice = "scope_invoice",
	DashboardMessageBox = "scope_dashboardMessageBox",
	CommunicationMessage = "scope_communicationMessage",
	ReturnForm = "scope_returnForm",
	Template = "scope_template",
}

export const fallbackLng = "fr"
