import { Laboratory, Patient, User } from "@audiowizard/common"
import {
	adriErrors,
	replaceAmcWithAdriAmc,
	replaceAmoWithAdriAmo,
} from "pages/etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"
import { toast } from "react-toastify"
import { IsObjectEmpty } from "services/functions"
import { consulterClient, patientAdrSilent, transmettrePatient } from "./functionsFSV"
import API_FSV from "./api"
import dayjs from "dayjs"
import { hasTeletransService } from "./utilsFSV"
import API from "services/API"

export const parseAdriData = async (adriData: any, patient: Patient): Promise<boolean> => {
	let newAmo = API_FSV.recursivelySearchForKey(adriData, "couvertureAMO")
	const newAmc = API_FSV.recursivelySearchForKey(adriData, "couvertureAMC")

	if ((!newAmo && !newAmc) || (IsObjectEmpty(newAmo) && IsObjectEmpty(newAmc))) {
		console.error("Pas de couvertures à ajouter au dossier")
		return false
	}
	if (Object.keys(newAmo)[0] === "0") {
		newAmo = Object.values(newAmo)
	}
	if (newAmo) await replaceAmoWithAdriAmo(newAmo, patient)
	if (newAmc) await replaceAmcWithAdriAmc(newAmc, patient)
	return true
}

export const isBirthdayAlignedWithSsn = (patient: Patient): boolean => {
	// Month returned by dayjs has January as 0 => December as 11. So we add 1
	const birthMonth = dayjs(patient?.birthDate).get("month") + 1
	const birthYear = +dayjs(patient?.birthDate).get("year")?.toString().slice(2, 4)
	if (!patient.idSecu) return false
	const birthYearSsn = +patient.idSecu.slice(1, 3)
	const birthMonthSsn = +patient.idSecu.slice(3, 5)

	if (birthMonth === birthMonthSsn && birthYear === birthYearSsn) return true
	return false
}

export const getAdriDataSilent = async ({
	user,
	laboratory,
	patient,
	subscriptions,
}: {
	user: User
	laboratory: Laboratory
	patient: Patient
	subscriptions: any
}): Promise<{ patient: Patient | null; adriData: any }> => {
	try {
		let patientForAdri: Patient | null = patient
		// si le patient n’est pas déjà dans FSV, on crée une nouvelle entrée
		if (!patientForAdri.idFSV || patientForAdri.idFSV == null)
			patientForAdri = await createInFsv({ user, laboratory, patient, subscriptions })
		// s’il n’y a pas de patient ici, c’est que la création a échoué
		if (!patientForAdri) {
			toast.error(adriErrors.USER)
			return { patient: null, adriData: null }
		}

		// mettre à jour FSV avec l’Adri
		const adriResult = await patientAdrSilent({
			idFSV: patientForAdri.idFSV,
			finess: laboratory.finess,
			numNatPs: user.adeli,
			idCompany: user.company?.id,
		})
		if (adriResult.error) throw adriResult

		// récupérer les données à jour
		const adriData = await consulterClient(patientForAdri.idFSV)

		// mettre à jour les données AW
		await parseAdriData(adriData, patient)
		return { patient: patientForAdri, adriData: API_FSV.recursivelySearchForKey(adriData, "individu") }
	} catch (error: any) {
		console.error(error)

		if (error?.code) toast.error(adriErrors[error.code as keyof typeof adriErrors] ?? adriErrors.DEFAULT)
		else if (!isBirthdayAlignedWithSsn(patient)) {
			toast.error(adriErrors.BIRTHDAY)
		} else toast.error(adriErrors.DEFAULT)
		return { patient: null, adriData: null }
	}
}

export const createInFsv = async ({
	user,
	laboratory,
	patient,
	subscriptions,
}: {
	user: User
	laboratory: Laboratory
	patient: Patient
	subscriptions: any
}): Promise<Patient | null> => {
	// on test si la user à se service télétransmission
	if (!hasTeletransService(subscriptions)) return null
	try {
		if (!patient.id) return null
		// on ajoute le patient dans FSV, puis l’on ajoute l’idFSV chew AW
		const newPatientInFsv = await transmettrePatient({ user, laboratory }, patient)
		if (newPatientInFsv?.error) {
			return null
		} else {
			await API.update("PATIENTS_API", patient.id, { idFSV: newPatientInFsv })
			return { ...patient, idFSV: newPatientInFsv }
		}
	} catch (error) {
		console.error(error)
		return null
	}
}
