import { toast } from "react-toastify"
import { flatten } from "lodash"
import dayjs from "dayjs"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import { fetchDocuments } from "services/requests/http/document/get/fetchDocuments"
import { GlobalClient } from "services/clients/axios/globalClient"
import { updateDocumentPDF } from "services/requests/http/document/updateDocumentPDF"
import API_Hiboutik from "services/API_Hiboutik"
import { getDocument } from "services/requests/http/document/getDocument"
import { parsed } from "services/functions"
import { revertConfigToDatabaseCorrectKeys } from "services/scriptFactures"
import API from "services/API"

const makeZipName = (periode, monthLabels, selectedDate) => {
	if (periode === "TODAY") return `Factures_${dayjs().format("DD-MM-YYYY")}`
	if (periode === "MONTH" || periode === "LAST_MONTH")
		return `Factures_${monthLabels[selectedDate.startDate.month - 1]}-${selectedDate.startDate.year}`
	if (periode === "CUSTOM")
		return `Factures_${selectedDate.startDate.day.toString().padStart(2, "0")}${selectedDate.startDate.month
			.toString()
			.padStart(2, "0")}${selectedDate.startDate.year}-${selectedDate.endDate.day
			.toString()
			.padStart(2, "0")}${selectedDate.endDate.month.toString().padStart(2, "0")}${selectedDate.endDate.year}`
}

const exportInvoices = async (
	dateObj,
	periode,
	selectedDate,
	monthLabels,
	generateInvoice,
	setRatio,
	detailedPaiements
) => {
	// Update invoice function
	async function updateInvoice(user, laboratory, patient, details, detailedPaiements) {
		const stringifiedContent = await generateInvoice(user, laboratory, patient, details, null, detailedPaiements) // Generates the document under a stringified HTML format
		const { data } = await updateDocumentPDF(details?.id, { content: stringifiedContent }) // Updates the document PDF
		return data
	}

	const zip = new JSZip()
	const folder = zip.folder(makeZipName(periode, monthLabels, selectedDate))

	let invoices = await Promise.all(
		["FACTURE", "FACTURE_SUIVI_TELETRANS", "FACTURE_DA", "FACTURE_CO", "FACTURE_OS"].map((type) =>
			fetchDocuments({
				...(dateObj ? { "createdAt[before]": dayjs(dateObj.dateEnd).format("YYYY-MM-DDT23:59:59") } : {}),
				...(dateObj ? { "createdAt[after]": dayjs(dateObj.dateStart).format("YYYY-MM-DDT00:00:00") } : {}),
				pagination: false,
				type,
			})
		)
	)
	invoices = flatten(invoices)

	if (!invoices?.length) {
		toast.warn("⚠️ Pas de factures à exporter")
		return
	}

	// Quick fix for ratio
	setRatio({ nominator: 0, denominator: invoices?.length })

	for (let invoice of invoices) {
		const t = await getDocument(invoice?.id)
		invoice = t?.data
		const { numero, config } = invoice

		// Regenerate invoice
		const dataFromDocument = revertConfigToDatabaseCorrectKeys(invoice.config)
		const user = await API.get(invoice.user[0]["@id"])

		invoice = await updateInvoice(
			user,
			dataFromDocument.laboratory,
			dataFromDocument.patient,
			invoice,
			detailedPaiements
		)

		// !Warning: quickfix 🤢 data break end of 08/21 😰
		if (numero && config?.amount) {
			const { payment_details } = await API_Hiboutik.getSale(invoice?.numero).catch(() => ({})) // catch error (don't throw to upper catch) and returns empty {} (quickfix)

			if (payment_details) {
				invoice.config["amount"] = (function (config, paymentDetails) {
					let insuranceShare = 0,
						socialSecurityShare = 0,
						expenseRest = +config["amount"]["totalAmountTTC"]
					for (const paymentDetail of paymentDetails) {
						const { payment_type, payment_amount } = paymentDetail
						// Get float value from string received
						const parsedPaymentAmount = +payment_amount
						// Calculates the new rest of the expenses
						expenseRest -= parsedPaymentAmount
						// Calculates the new balance of the insurance share and the social security share
						switch (payment_type) {
							case "MUTL":
								insuranceShare += parsedPaymentAmount
								break
							case "SECU":
								socialSecurityShare += parsedPaymentAmount
								break
							default:
								break
						}
					}
					return {
						...config["amount"],
						partMut: parsed(insuranceShare),
						partSecu: parsed(socialSecurityShare),
						restCharge: parsed(expenseRest),
					}
				})(config, payment_details)

				invoice = await updateInvoice(
					user,
					dataFromDocument.laboratory,
					dataFromDocument.patient,
					invoice,
					detailedPaiements
				)
			}
		}

		let { data: document } = await GlobalClient.get(invoice?.signature, { responseType: "arraybuffer" }).catch(
			() => ({})
		) // catch error (don't throw to upper catch) and returns empty {} (quickfix)

		// !Warning: quickfix 🤢 data break end of 08/21 😰
		// If no document stored in external service
		if (!document) {
			invoice = await updateInvoice(
				user,
				dataFromDocument.laboratory,
				dataFromDocument.patient,
				invoice,
				detailedPaiements
			)
			document = await GlobalClient.get(invoice?.signature)?.data
		}

		setRatio((prev) => ({ ...prev, nominator: prev.nominator + 1 }))

		folder.file(`${invoice?.filename}.pdf`, document)
	}

	// Download ZIP
	zip.generateAsync({ type: "blob" })
		.then((blob) => {
			saveAs(blob, `${Date.now()}-FACTURES`)
		})
		.finally(() => {
			setRatio({ nominator: null, denominator: null })
		})
}

export default exportInvoices
