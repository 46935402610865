import { GestionCompteRoutePrefix } from "router/GestionCompte/routes"

export const LaboratoiresRoutePrefix = GestionCompteRoutePrefix + "/laboratoires"

export const LaboratoiresRoutes = {
	Planning: `${LaboratoiresRoutePrefix}/planning`,
	ListeLaboratoires: `${LaboratoiresRoutePrefix}/liste-laboratoires`,
	Agenda: `${LaboratoiresRoutePrefix}/agendas`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(LaboratoiresRoutePrefix, "")
}
