import cx from "classnames"
import "./ButtonRounded.scss"

type buttonRoundedProps = {
	children: string | JSX.Element | JSX.Element[] | any
	onClick?: React.MouseEventHandler<HTMLButtonElement>
	color?: string
	size?: string
	isDisabled?: boolean
	icon?: string
	type?: "button" | "submit" | "reset"
	className?: string
}

const ButtonRounded = ({
	children,
	onClick,
	color = "primary",
	isDisabled,
	icon,
	type,
	className = "",
	size,
}: buttonRoundedProps): JSX.Element => {
	const classNames = cx(
		"btn-rounded",
		{ "primary ": color === "primary" },
		{ "info ": color === "info" },
		{ "primary outlined ": color === "primary-outlined" },
		{ "secondary ": color === "secondary" },
		{ "secondary outlined ": color === "secondary-outlined" },
		{ "danger ": color === "danger" },
		{ "danger outlined ": color === "danger-outlined" },
		{ "small ": size === "small" },
		{ "primary-dark ": color === "primary-dark" }
	)

	return (
		<button onClick={onClick} className={classNames + className} disabled={isDisabled} type={type}>
			{icon && <i className={`fas ${icon} mr-2`} />}
			<span>{children}</span>
		</button>
	)
}

export default ButtonRounded
