/* eslint-disable react-hooks/exhaustive-deps */
import { ServiceRef } from "components/hoc/withRequiredServices"
import useHasServices from "components/Hooks/useHasServices"
import PatientUIToggleModal from "pages/salle-attente/PatientUIToggleModal"
import { useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import AuthContext from "../../contexts/AuthContext"
import DropDownAffiliate from "./AffiliateDropDown"
import Spotlight from "./Spotlight/Spotlight"
import { zipper } from "pages/Schedules/doctolib.helpers"

const AffiliateTopBar = () => {
	const history = useHistory()

	const { user, laboratory, patientUI, setUiDisplay, hasDoctolib } = useContext(AuthContext)

	const marqueBlancheServiceStatus = useHasServices(ServiceRef.MarqueBlanche)

	const [patientUIModal, setPatientUIModal] = useState(false)
	const [showChooseDoctolibAgendaView, setShowChooseDoctolibAgendaView] = useState(false)

	let visitedPatients = window.localStorage.getItem("visitedPatients")

	return (
		<>
			<nav className="navbar navbar-horizontal navbar-expand-lg navbar-dark bg-gradient-primary">
				<div className="container">
					<Link className="navbar-brand" to={patientUI ? history.location.pathname : "/"}>
						{marqueBlancheServiceStatus && user.company?.whiteLabel?.isActive ? (
							<img alt={`Logo ${user.company.label}`} src={user.company?.whiteLabel?.logo} />
						) : (
							<>
								<img
									alt="Logo AudioWizard"
									src="/static/img/brand/audowizard-logo-white4.png"
									className="d-none d-lg-block"
								/>
								<img
									alt="Logo AudioWizard"
									src="/static/img/icons/LOGO-AUDIO-WIZARD.svg"
									className="img-white d-lg-none"
								/>
							</>
						)}
					</Link>
					<Spotlight />
					<div className="navbar-nav flex-row align-items-center" id="navbar-primary">
						{!patientUI && (
							<>
								{visitedPatients && (
									<a
										title="Dernier patient visité"
										className="nav-link pr-lg-0 text-white"
										href="#!"
										onClick={(e) => {
											e.preventDefault()
											const patients = JSON.parse(visitedPatients)
											history.push(`/fiche-patient/${patients[0]}`)
										}}>
										<i style={{ fontSize: "2rem" }} className="fad fa-user" />
									</a>
								)}

								<div
									title="Ouvrir l'agenda"
									className="nav-link pr-lg-0 text-white cursor-pointer"
									onContextMenu={(e) => {
										e.preventDefault()
									}}
									onMouseDown={(e) => {
										if (![1, 2].includes(e.button)) return
										e.preventDefault()
										window.open(
											"/agenda",
											"Agenda AudioWizard",
											"scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,\n" +
												"width=1280,height=720,left=0,top=0"
										)
									}}
									onClick={(e) => {
										e.preventDefault()
										setUiDisplay((old) => ({ ...old, agendaModal: true }))
									}}>
									<i style={{ fontSize: "2rem" }} className="fad fa-calendar" />
								</div>

								{hasDoctolib && (
									<a
										href="#!"
										title="Accéder à l'agenda Doctolib"
										className="nav-link pr-lg-0 text-white cursor-pointer"
										onClick={() => setShowChooseDoctolibAgendaView(!showChooseDoctolibAgendaView)}
										tabIndex={1}
										onBlur={() => {
											setShowChooseDoctolibAgendaView(false)
										}}>
										<img
											className="cursor-pointer"
											alt="doctolib_logo"
											src="/static/img/doctolib/D_Doctolib_white.png"
											style={{ width: 40, height: 40 }}
										/>
										{showChooseDoctolibAgendaView && (
											<div className="position-absolute mt-2 bg-white shadow rounded">
												<ul className="list-group" style={{ color: "#0596DE" }}>
													<li
														onClick={() => {
															zipper.openListView()
														}}
														className="list-group-item">
														<i
															className="fa-solid fa-list mr-2"
															style={{ color: "#0596DE" }}
														/>
														Vue Liste
													</li>
													<li
														onClick={() => {
															zipper.openCalendar()
														}}
														className="list-group-item">
														<i
															className="fa-solid fa-calendar mr-2"
															style={{ color: "#0596DE" }}
														/>
														Vue Semaine
													</li>
												</ul>
											</div>
										)}
									</a>
								)}

								<ul className="navbar-nav ml-lg-auto ">
									<li className="nav-item dropdown dropdown-animate custom-topbar-pill">
										<a
											className="nav-link pr-lg-0"
											href="#!"
											role="button"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											data-cy="topbar-dropdown">
											<div className="media media-pill d-flex flex-column align-items-center justify-content-center">
												{user && (
													<>
														<div style={{ fontWeight: "bold" }}>
															{user.firstName} {user.lastName}
														</div>
														<div
															className="d-none d-sm-block"
															style={{
																fontSize: "12px",
																overflowY: "hidden",
																textOverflow: "ellipsis",
																marginTop: "-6px",
															}}>
															{laboratory.error ? "Aucun laboratoire" : laboratory.label}
														</div>
													</>
												)}
											</div>
										</a>

										<DropDownAffiliate />
									</li>
								</ul>
							</>
						)}
					</div>
				</div>
			</nav>

			<PatientUIToggleModal open={patientUIModal} onClose={() => setPatientUIModal(false)} />
		</>
	)
}

export default AffiliateTopBar
