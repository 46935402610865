import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import FieldWithError from "../../components/forms/FieldWithError"
import API from "../../services/API"
import API_Hiboutik from "../../services/API_Hiboutik"
import { HandleChange } from "../../services/functions"
import ButtonRounded from "components/Buttons/ButtonRounded"

const SuppliersModal = ({ supplier, setSupplier, refreshSetter, isOpen, onClose }) => {
	const [busy, setBusy] = useState(false)
	const [allowSuppression, setAllowSuppression] = useState(true)
	const [modifiedProperties, setModifiedProperties] = useState([])
	const [isCheckingProducts, setIsCheckingProducts] = useState(false)
	const [isWarningOpen, setIsWarningOpen] = useState(false)

	const editSupplier = async (supplierId) => {
		try {
			for (const property of modifiedProperties) {
				if (property === "supplier_name" && !supplier.supplier_id) {
					continue
				}
				await API_Hiboutik.updateSupplier(supplierId, property, supplier[property])
			}
			onClose()
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	useEffect(() => {
		if (!supplier?.supplier_id) return

		setIsCheckingProducts(true)

		const getAllProductsFromSupplierId = async (supplierId) => {
			return (await API.findAll("PRODUCT_SEARCH_API", `?product_supplier=${supplierId}`)) ?? []
		}

		const supplierHasProduct = async () => {
			const products = await getAllProductsFromSupplierId(supplier.supplier_id)

			return products.length > 0
		}

		supplierHasProduct()
			.then((hasProduct) => {
				setAllowSuppression(!hasProduct)
			})
			.finally(() => setIsCheckingProducts(false))
	}, [supplier])

	const handleSubmitAddSupplier = async () => {
		try {
			setBusy(true)
			if (!supplier.supplier_id) {
				const createdSupplier = await API_Hiboutik.createSupplier(supplier.supplier_name)
				await editSupplier(createdSupplier.supplier_id)
			} else {
				await editSupplier(supplier.supplier_id)
			}
		} catch (error) {
			console.error(error)
			toast.error("Une erreur s'est produite, veuillez réessayer")
		} finally {
			refreshSetter && refreshSetter(true)

			setBusy(false)
			setSupplier(null)
			onClose()
		}
	}

	const handleDeleteSupplier = async () => {
		try {
			setBusy(true)
			await API_Hiboutik.deleteSupplier(supplier.supplier_id)
		} catch (error) {
			console.error(error)
			toast.error("Une erreur s'est produite, veuillez réessayer")
		} finally {
			setBusy(false)
			refreshSetter && refreshSetter(true)
			setSupplier(null)
			onClose()
		}
	}

	const handleDisabledSuppression = () => {
		if (!allowSuppression && !isWarningOpen) {
			setIsWarningOpen(true)
			toast.warn("Ce fournisseur vous fournit des articles présents dans votre catalogue", {
				onClose: () => setIsWarningOpen(false),
			})
		}
	}

	const handleChange = (event) => {
		const name = event.target.name

		if (!modifiedProperties.includes(name)) {
			setModifiedProperties((old) => [...old, name])
		}
		HandleChange(event, supplier, setSupplier)
	}

	return (
		<>
			<Modal isOpen={isOpen} size="lg" centered>
				<ModalHeader>
					<div className="d-flex align-items-center">
						<h6 className="mb-0">
							{supplier?.supplier_id ? "Modifier le fournisseur " : "Ajouter un fournisseur"}
						</h6>
						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
							title="Fermer"
							onClick={onClose}>
							<i className="fas fa-times" />
						</button>
					</div>
				</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-12">
							<FieldWithError
								id="supplier-name"
								name="supplier_name"
								label="Nom du fournisseur"
								value={supplier?.supplier_name}
								onChange={handleChange}
								required={true}
								disabled={busy}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<FieldWithError
								id="supplier-email"
								name="supplier_email"
								label="Email"
								value={supplier?.supplier_email}
								onChange={handleChange}
								disabled={busy}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<FieldWithError
								id="supplier-address"
								name="supplier_address"
								label="Adresse"
								value={supplier?.supplier_address}
								onChange={handleChange}
								disabled={busy}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							{supplier?.supplier_id && (
								<div className="btn" style={{ padding: 0 }} onMouseOver={handleDisabledSuppression}>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={handleDeleteSupplier}
										disabled={busy || !allowSuppression || isCheckingProducts}>
										Supprimer
									</button>
								</div>
							)}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="row">
						{supplier?.supplier_id && (
							<ButtonRounded
								type="button"
								color="danger-outlined"
								onClick={handleDeleteSupplier}
								disabled={busy}>
								Supprimer
							</ButtonRounded>
						)}

						<ButtonRounded
							color="primary-outlined"
							type="button"
							onClick={handleSubmitAddSupplier}
							disabled={busy}>
							Enregistrer
						</ButtonRounded>
					</div>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default SuppliersModal
