/* eslint-disable quotes */
import axios from "axios"
import API from "./API"
import { formatDatetimeForDB } from "./functions"
import { itemsTemplates } from "../datas/items/itemsTemplates"
import { blobTo, downloadBase64 } from "./Utilities"

let carboneTemplateUrl = process.env.REACT_APP_CARBONE_DEFAULT_TEMPLATE_URL
let carboneRenderUrl = process.env.REACT_APP_CARBONE_RENDER_TEMPLATE_URL
let carboneToken = process.env.REACT_APP_CARBONE_TOKEN

const mimes = {
	doc: " application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	xls: "application/vnd.ms-excel",
	xlsx: " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	odt: "application/odt",
}
/**
 * @type {function} getCarboneTemplate
 * @param {string} templateId
 * @param {string} templateName
 * @description Cette fonction permet d'obtenir un template déjà publié, en format ODT
 */
const getCarboneTemplate = (templateId, templateName) => {
	axios({
		url: carboneTemplateUrl + templateId,
		method: "GET",
		responseType: "blob",
		headers: {
			Authorization: "Bearer " + carboneToken,
			"carbone-version": "2",
		},
	})
		.then((res) => {
			const timeStamp = Date.now()
			const regex = /(?:\.([^.]+))?$/
			let extension = "odt"
			extension = regex.exec(res?.headers?.["content-disposition"])?.[1]
			if (extension[extension.length - 1] === '"') extension = extension.slice(0, -1)

			const blob = new Blob([res.data], {
				type: mimes?.[extension] || "application/odt",
			})
			const link = document.createElement("a")
			link.href = window.URL.createObjectURL(blob)
			link.download = `${timeStamp}-${templateName}.${extension}`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})
		.catch((err) => {
			console.error(err)
		})
}

/**
 * @type {function} postNewCarboneTemplate
 * @param {Object} document
 * @param {Object} template
 * @param {function} setter
 * @description Cette fonction permet de poster un nouveau template sur Carbone
 * et va remplacer (PUT) la version du template enregistrée dans l'entité Personalization
 */
const postNewCarboneTemplate = async (document, template, setter) => {
	const data = new FormData()
	data.append("payload", document.label)
	data.append("template", document.file)

	let getPersonalizations = await API.findAll("PERSONALIZATIONS_API")
	getPersonalizations = getPersonalizations?.[0]

	axios({
		url: carboneTemplateUrl,
		method: "POST",
		data: data,
		headers: {
			Authorization: `Bearer ${carboneToken}`,
		},
	})
		.then(async (res) => {
			let alreadyInDb = []
			for (const object in getPersonalizations.templates) {
				if (getPersonalizations.templates[object].name === template.name) {
					const filterTemplates = getPersonalizations.templates.filter((t) => t.name !== template.name)
					await API.update("PERSONALIZATIONS_API", getPersonalizations.id, {
						templates: [
							...filterTemplates,
							{
								...getPersonalizations.templates[object],
								templateId: res.data.data.templateId,
								modified_at: formatDatetimeForDB(),
							},
						],
					})
					alreadyInDb.push(getPersonalizations.templates[object])
				}
			}
			if (!alreadyInDb.length)
				await API.update("PERSONALIZATIONS_API", getPersonalizations.id, {
					templates: [
						...getPersonalizations.templates,
						{
							...template,
							templateId: res.data.data.templateId,
							created_at: formatDatetimeForDB(),
							modified_at: formatDatetimeForDB(),
						},
					],
				})
		})
		.then(async () => {
			getPersonalizations = await API.findAll("PERSONALIZATIONS_API")
			setter(getPersonalizations)
		})
		.catch(async (error) => {
			console.error(error.response)
		})
}
/**
 * @type {function} getTemplateByType
 * @param {String} type
 * @description Récupère l'id du template custom (en checkant du côté des personnalisation)
 * ou par défaut en fonction du type de document.
 */
const getTemplateByType = async (type = null) => {
	try {
		if (!type) return
		const defaultTemplate = itemsTemplates?.find((f) => f.type === type)?.id
		if (!defaultTemplate) return null

		const data = await API.findAll("PERSONALIZATIONS_API")
		if (!data || !data?.[0] || !data[0]?.templates) return defaultTemplate

		const customTemplate = data[0]?.templates.find((f) => f.type === type)?.templateId
		return customTemplate ?? defaultTemplate
	} catch (e) {
		console.error(e)
	}
}
/**
 * @type {function} getCarboneBinary
 * @param {Object} data
 * @param {String} type
 * @description Fait un appel à getTemplateByType(), puis fait les requêtes sur l'api carbone
 * pour convertir le template et générer le pdf.
 */
const getCarboneBinary = async (data, type) => {
	return new Promise(async (resolve, reject) => {
		try {
			const templateId = await getTemplateByType(type)

			const result = await axios({
				url: carboneRenderUrl + templateId,
				method: "POST",
				data: {
					data,
					convertTo: {
						formatName: "pdf",
					},
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + carboneToken,
					"carbone-version": "2",
				},
			})
			if (!result.data.success) return null

			const binaryResult = await axios({
				url: carboneRenderUrl + result.data.data.renderId,
				method: "GET",
				responseType: "blob",
				headers: {
					Authorization: "Bearer " + carboneToken,
					"carbone-version": "2",
				},
			})
			resolve(binaryResult.data)
			return binaryResult.data
		} catch (e) {
			console.error(e)
			reject(e)
		}
	})
}

/**
 * @type {function} downloadFromCarbonne
 * @param {Object} data
 * @param {String} type
 * @param {String} ext
 * @description Lance le téléchargement d'un pdf depuis l'api carbonne
 * Fait des appels aux fonctions suivante, dans l'ordre :  getCarboneBinary(), blobTo(), downloadBase64()
 */
const downloadFromCarbonne = async (data, type, ext = "pdf") => {
	try {
		const blob = await getCarboneBinary(data, type)
		const base64 = await blobTo(blob, "blob", "application/pdf")
		const timestamp = Date.now()
		downloadBase64(base64, `${timestamp}-${type ? type : ""}`, ext)
	} catch (e) {
		console.error(e)
	}
}

async function uploadFromCarboneToDb(data, type, ext = "pdf", infos = {}) {
	try {
		const blob = await getCarboneBinary(data, type)
		const base64 = await blobTo(blob, "blob", "application/pdf")
		const timestamp = Date.now()
		downloadBase64(base64, `${timestamp}-${type ? type : ""}`, ext)

		const document = {
			file: base64,
			filename: `${timestamp}-${type ? type : ""}`,
			extension: ext,
			patient: infos.patient || null,
			laboratory: infos.laboratory || null,
			company: infos.company || null,
			label: infos.label || null,
			type: infos.type || null,
			status: infos.status || null,
			numero: infos.numero || null,
		}
		await API.createDocument(document)
	} catch (e) {
		console.error(e)
	}
}

/**
 * @type {function} removeCarboneTemplate
 * @param {Number} templateId
 * @param {string} templateName
 * @param {function} setter
 * @description Cette fonction permet la suppression d'un template sur Carbone puis la suppression de ce
 * template dans notre table Personalizations
 */

const removeCarboneTemplate = async (templateId, templateName, setter) => {
	let getPersonalizations = await API.findAll("PERSONALIZATIONS_API")
	getPersonalizations = getPersonalizations?.[0]
	axios({
		url: carboneTemplateUrl + templateId,
		method: "DELETE",
		headers: {
			Authorization: "Bearer " + carboneToken,
			"carbone-version": "2",
		},
	})
		.then(async (response) => {
			const filterTemplates = getPersonalizations.templates.filter((t) => t.name !== templateName)
			await API.update("PERSONALIZATIONS_API", getPersonalizations.id, {
				templates: [...filterTemplates],
			})
			getPersonalizations = await API.findAll("PERSONALIZATIONS_API")
			setter(getPersonalizations)
			return response.data
		})
		.catch((error) => {
			console.error(error)
		})
}

const deleteTemplate = async (templateId) => {
	return axios({
		url: carboneTemplateUrl + templateId,
		method: "DELETE",
		headers: {
			Authorization: "Bearer " + carboneToken,
			"carbone-version": "2",
		},
	})
}

const ApiPdf = {
	getCarboneTemplate,
	postNewCarboneTemplate,
	removeCarboneTemplate,
	getCarboneBinary,
	downloadFromCarbonne,
	uploadFromCarboneToDb,
	deleteTemplate,
}

export default ApiPdf
