import { Laboratory, SubCompany } from "@audiowizard/common"
import AuthContext from "contexts/AuthContext"
import {
	defaultDeferredPaymentMethods,
	defaultSinglePaymentMethods,
	paymentTypeLabel,
	PaymentTypeTags,
} from "pages/vente/_Utility"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import API from "services/API"
import API_Hiboutik from "services/API_Hiboutik"
import { HiboutikPaymentType } from "./PaymentTypes"

export interface PaymentType {
	"@id": string
	id: number
	hiboutikReference: PaymentTypeTags
	isDirectPayment: boolean
	isDeferredPayment: boolean
	label: string
	icon: string
}

export type PaymentTypePayload = Omit<PaymentType, "@id" | "id">
export interface PaymentTypeForTable extends PaymentTypePayload {
	key: string
	"@id"?: string
	id?: number
}

export type PaymentTypesReference = Record<PaymentTypeTags, PaymentTypeForTable> | Record<string, never>

const fabricsPaymentTypes = (
	hiboutikPaymentTypes: HiboutikPaymentType[],
	customPaymentTypes: PaymentType[]
): { newPaymentTypes: PaymentTypeForTable[]; newPaymentTypesRef: PaymentTypesReference } => {
	const customReference = customPaymentTypes.reduce(
		(c, row) => ({
			...c,
			[row.hiboutikReference]: row,
		}),
		{}
	) as Record<PaymentTypeTags, PaymentType>

	const newPaymentTypes: PaymentTypeForTable[] = hiboutikPaymentTypes.map((paymentType) => {
		let customSettings: Partial<PaymentTypeForTable> = {}
		if (customReference[paymentType.payment_type]) {
			customSettings = {
				isDirectPayment: customReference[paymentType.payment_type].isDirectPayment,
				isDeferredPayment: customReference[paymentType.payment_type].isDeferredPayment,
				label: customReference[paymentType.payment_type].label,
				"@id": customReference[paymentType.payment_type]["@id"],
				id: customReference[paymentType.payment_type].id,
			}
		}
		return {
			key: paymentType.payment_type,
			hiboutikReference: paymentType.payment_type,
			icon: paymentTypeLabel[paymentType.payment_type]?.icon,
			isDirectPayment: defaultSinglePaymentMethods.includes(paymentType.payment_type),
			isDeferredPayment: defaultDeferredPaymentMethods.includes(paymentType.payment_type),
			label: paymentTypeLabel[paymentType.payment_type]?.label || paymentType.payment_type,
			...customSettings,
		}
	})

	const newPaymentTypesRef = newPaymentTypes.reduce(
		(a, v) => ({
			...a,
			[v.key]: v,
		}),
		{}
	) as PaymentTypesReference

	newPaymentTypesRef["DIV"] = {
		key: "DIV",
		label: "Différé / Multi-paiements",
		icon: "fa-sack",
		hiboutikReference: "DIV",
		isDirectPayment: false,
		isDeferredPayment: false,
	}

	return { newPaymentTypes, newPaymentTypesRef }
}

export const fetchPaymentTypes = async (
	laboratoryId: number,
	laboratories: Laboratory[]
): Promise<PaymentTypesReference | void> => {
	try {
		const selectedLaboratory = laboratories.find((l) => l.id === laboratoryId)
		const hiboutikPaymentTypes = await API_Hiboutik.getPaymentsTypes(selectedLaboratory?.warehouseIdHiboutik).then(
			(ret) => ret.data as HiboutikPaymentType[]
		)
		const customPaymentTypes = await API.findAll<PaymentType[]>(
			"PAYMENT_TYPES_API",
			`?subCompany=${selectedLaboratory?.subCompany?.["@id"]}`
		)

		const { newPaymentTypesRef } = fabricsPaymentTypes(hiboutikPaymentTypes, customPaymentTypes)
		return newPaymentTypesRef
	} catch (err) {
		console.error(err)
	}
}

// necessary because hook is not always usable
export const useFetchPaymentType = (
	subCompany?: SubCompany
): {
	paymentTypes: PaymentTypeForTable[]
	remove: () => void
	paymentTypesRef: PaymentTypesReference
} => {
	const [paymentTypes, setPaymentTypes] = useState<PaymentTypeForTable[]>([])
	const [paymentTypesRef, setPaymentTypesRef] = useState<PaymentTypesReference>({})
	const [laboratory, setLaboratory] = useState<Laboratory>()
	const { laboratories } = useContext(AuthContext)

	useEffect(() => {
		if (!laboratories || !subCompany) return
		const laboratoryFromSubCompany = laboratories.find((l) => l.subCompany?.["@id"] === subCompany["@id"])
		setLaboratory(laboratoryFromSubCompany)
	}, [subCompany, laboratories])

	const { data: hiboutikPaymentTypes, isLoading: isLoadingHiboutik } = useQuery<HiboutikPaymentType[]>(
		["payment_type", { warehouseId: laboratory?.warehouseIdHiboutik }],
		() => {
			if (!laboratory?.warehouseIdHiboutik) return []
			return API_Hiboutik.getPaymentsTypes(laboratory?.warehouseIdHiboutik).then(
				(ret) => ret.data as HiboutikPaymentType[]
			)
		},
		{ staleTime: 3600 * 1000 * 24 } // one day
	)

	const {
		data: customPaymentTypes,
		isLoading: isLoadingCustom,
		isSuccess: isSuccessCustom,
		remove,
	} = useQuery<PaymentType[]>(
		["payment_type", { laboratoryId: laboratory?.["@id"] }],
		() => {
			return API.findAll<PaymentType[]>("PAYMENT_TYPES_API", `?subCompany=${subCompany?.["@id"]}`)
		},
		{ staleTime: 3600 * 1000 * 24 } // one day
	)

	useEffect(() => {
		if (isLoadingCustom || isLoadingHiboutik || !hiboutikPaymentTypes || !customPaymentTypes || !isSuccessCustom) {
			setPaymentTypes([])
			return
		}
		const { newPaymentTypes, newPaymentTypesRef } = fabricsPaymentTypes(hiboutikPaymentTypes, customPaymentTypes)
		setPaymentTypes(newPaymentTypes)
		setPaymentTypesRef(newPaymentTypesRef)
	}, [hiboutikPaymentTypes, customPaymentTypes, isLoadingCustom, isLoadingHiboutik, isSuccessCustom])

	return { paymentTypes, remove, paymentTypesRef }
}
