// ----------------------------------------------
// TEMPLATE FOR : pages/vente/billing/Facturation.jsx
// ----------------------------------------------

import ReactDOMServer from "react-dom/server"
import moment from "moment"
import { parsed } from "services/functions"
import { Status as InvoiceStatus } from "constants/invoice/status"
import { LocaleNamespace } from "config/intl/helpers"
import { UseTranslationOptions } from "react-i18next"

function buildUnAcquittedPart(store: any, t: any): JSX.Element | undefined {
	if (store?.invoice?.data?.ventes?.paiement !== "DIV")
		return (
			<p>
				<strong>Type de règlement :</strong> {store?.invoice?.data?.ventes?.paiement}
			</p>
		)
	else if (Array.isArray(store?.invoice?.data?.ventes?.payment_details)) {
		return (
			<div style={{ display: "flex", flexDirection: "column", width: "40%" }}>
				{store?.invoice?.data?.ventes?.payment_details.map((detail: any, id: number) => (
					<div key={id} style={{ marginTop: "1rem" }}>
						<span style={{ fontSize: "0.9em" }}>
							<strong>{t(`${LocaleNamespace.Invoice}:invoice.sentences.payment type`)} :</strong>{" "}
							{
								//@ts-ignore
								store?.paymentTypes[detail?.payment_type]?.label ?? detail?.payment_type
							}
						</span>
						<br />
						{detail?.payment_type === "MUTL" &&
							store?.invoice?.data?.patient?.full?.patientInsurances?.length > 0 && (
								<>
									<span>
										<strong>Mutuelle : </strong>
										{store?.invoice?.data?.patient?.full?.patientInsurances?.[0]?.label}
									</span>
									<br />
								</>
							)}
						<span style={{ fontSize: "0.9em" }}>
							<strong>{t(`${LocaleNamespace.Invoice}:invoice.sentences.payment amount`)} :</strong>{" "}
							{detail?.payment_amount + " €"}{" "}
						</span>
						<br />
						<span style={{ fontSize: "0.9em" }}>
							{store?.invoice?.data?.status !== InvoiceStatus.Acquitted && (
								<strong>
									{detail?.payment_date === "0000-00-00"
										? `${t(`${LocaleNamespace.Invoice}:invoice.sentences.paid`)}.`
										: `${t(
												`${LocaleNamespace.Invoice}:invoice.sentences.expected collection date`
										  )} : ${detail?.payment_date && moment(detail?.payment_date).format("L")}`}
								</strong>
							)}
						</span>
					</div>
				))}
			</div>
		)
	}
}

export const InvoiceTemplate = (
	store: { [key: string]: any },
	t: (path: string, options?: UseTranslationOptions, prePath?: string) => string
): string => {
	const warrantyType: { [key: string]: string } = {
		"APPAREILS AUDITIFS": "4",
		ACCESSOIRE: "2",
		ECOUTEUR: "2",
		"ECOUTEUR AVEC SN": "2",
		"EMBOUT REMBOURSE": "2",
		"EMBOUT AVEC SN": "2",
	}

	const categoryForWarranty = [
		"APPAREILS AUDITIFS",
		"ACCESSOIRE",
		"ECOUTEUR AVEC SN",
		"ECOUTEUR",
		"EMBOUT REMBOURSE",
		"EMBOUT AVEC SN",
	]

	return ReactDOMServer.renderToStaticMarkup(
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `
            @media print {
                .no-print { display: none !important; }
            }
            html {
                -webkit-print-color-adjust: exact;
								font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
								font-size: 11pt;

              }
			table, th, td {
				padding: 5px 10px;
				border: 1px solid #afaeae;
				border-collapse: collapse;
				font-size: 0.9em;
			}
			div, tr {
				break-inside: avoid;
			}
            `,
				}}
			/>
			{/* Header */}
			<>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						{store?.invoice?.data?.subCompany?.logo && (
							<p style={{ textAlign: "left" }}>
								<img
									src={store?.invoice?.data?.subCompany?.logo}
									alt={t("billing.__editor.context-menu.Company logo on the sales invoice")}
									width="150"
									style={{ objectFit: "contain", height: "auto" }}
								/>
							</p>
						)}
					</div>
					<div style={{ textAlign: "right" }}>
						<h3>
							<strong>
								{t(`${LocaleNamespace.Common}:commonWords.invoice id`)}
								{store?.invoice?.data?.invoiceNumber}
							</strong>
						</h3>
						<span>
							({store?.invoice?.data?.invoiceId || "--"}-{store?.invoice?.data?.documentId || "--"})
						</span>
						<br />
						<span>
							<strong>{t(`${LocaleNamespace.Common}:commonWords.date`)} :</strong>{" "}
							{store?.invoice?.data?.ventes?.complete_date &&
								moment(store?.invoice?.data?.ventes?.complete_date).format("L")}
						</span>
					</div>
				</div>
				<br />
			</>
			{/* Laboratory and patient information */}
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<ul style={{ listStyle: "none", padding: "unset", margin: "0" }}>
					<div style={{ borderBottom: "1px solid grey", fontSize: "9.0pt" }}>Laboratoire</div>
					{store?.context.laboratory?.label && (
						<li>
							<strong> #(context.laboratory.label)</strong>
						</li>
					)}
					{store?.context.laboratory?.adress && <li>#(context.laboratory.adress)</li>}
					{store?.context.laboratory?.city && <li>#(context.laboratory.cpo) #(context.laboratory.city)</li>}
					{store?.context.laboratory?.email && (
						<li>
							<strong>Email :</strong> #(context.laboratory.email)
						</li>
					)}
					{store?.context.laboratory?.phone && (
						<li>
							<strong>Tél. :</strong> {store?.context.laboratory?.phone.replace("+33", "0")}
						</li>
					)}
				</ul>

				<div style={{ display: "flex", gap: "8px" }}>
					{store?.invoice?.data?.prescriber && (
						<ul
							style={{
								listStyle: "none",
								padding: "unset",
								textAlign: "right",
								margin: "0",
								fontSize: "10pt",
							}}>
							<div style={{ borderBottom: "1px solid grey", fontSize: "9.0pt" }}>Prescripteur</div>
							<li>
								<strong>
									#(invoice.data.prescriber.lastName) #(invoice.data.prescriber.firstName)
								</strong>
							</li>
							{(store.invoice.data.prescriber?.rpps || store.invoice.data.prescriber?.adeli) && (
								<>
									{store.invoice.data.prescriber?.rpps ? (
										<>#(invoice.data.prescriber.rpps)</>
									) : (
										<>#(invoice.data.prescriber.adeli)</>
									)}
								</>
							)}
							{store.invoice.data.prescriber?.adress && <li>#(invoice.data.prescriber.adress)</li>}
							{store.invoice.data.prescriber?.cpo && (
								<li>#(invoice.data.prescriber.cpo) #(invoice.data.prescriber.city)</li>
							)}
							{store.invoice.data.prescriber?.phone && <li>#(invoice.data.prescriber.phone)</li>}
							{store.invoice.data.prescriber?.email && <li>#(invoice.data.prescriber.email)</li>}
						</ul>
					)}

					<ul style={{ listStyle: "none", padding: "unset", textAlign: "right", margin: "0" }}>
						<div style={{ borderBottom: "1px solid grey", fontSize: "9.0pt" }}>Patient</div>
						{store?.invoice?.data?.patient?.nom && (
							<li>
								<strong>#(invoice.data.patient.nom) #(invoice.data.patient.prenom)</strong>
							</li>
						)}
						{store?.invoice?.data?.patient?.adresse && (
							<li>#(invoice.data.patient.adresse) #(invoice.data.patient.complement)</li>
						)}
						{store?.invoice?.data?.patient?.code_postal && (
							<li>#(invoice.data.patient.code_postal) #(invoice.data.patient.ville)</li>
						)}
						{store?.invoice?.data?.patient?.secu && (
							<li>
								{t(`${LocaleNamespace.Common}:commonWords.insurance id`)}: #(invoice.data.patient.secu)
							</li>
						)}
						{store.invoice.data?.patient?.full?.birthDate && (
							<li>Date de naissance: #(invoice.data.patient.full.birthDate)</li>
						)}
						{store.invoice.data?.patient?.full?.advices?.[0]?.dateOfAdvice && (
							<li>Date de la prescription: #(invoice.data.patient.full.advices[0].dateOfAdvice)</li>
						)}
					</ul>
				</div>
			</div>
			{store?.invoice?.data?.status === "ACQUITTE" && (
				<div
					style={{
						padding: "0.7rem",
						fontSize: "0.9em",
						border: "1px solid black",
						margin: "auto",
						width: "30%",
						textAlign: "center",
					}}>
					{t(`${LocaleNamespace.Invoice}:invoice.sentences.paid invoice`)}
				</div>
			)}
			<br />
			{/* Summary of the sale */}
			<table contentEditable={false} className="reporting-template__variable" style={{ width: "100%" }}>
				<thead>
					<tr style={{ backgroundColor: "#ececec" }}>
						{[
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.model`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.serial number`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.quantity`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.unit price without taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.VAT`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.unit discount without taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.unit discount with taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount without taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount with taxes`)}`,
						].map((title) => (
							<th key={title}>{title}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{store?.invoice?.data?.ventes?.achats?.map((achat: any) => {
						return (
							<tr key={achat?.serial_number}>
								<td style={{ textAlign: "center" }}>
									<strong>{achat?.product_model}</strong> {achat?.size_name ?? ""}
									{!!achat?.lpp && (
										<>
											<br /> lpp: {achat?.lpp}
										</>
									)}
								</td>
								<td style={{ textAlign: "center" }}>
									<strong>{achat?.serial_number} </strong>
									{categoryForWarranty.includes(achat?.category_name?.toUpperCase()) && (
										<>
											<br />
											<span style={{ fontSize: "0.9em" }}>
												Garantie {warrantyType[achat.category_name.toUpperCase()]} ans{" "}
												{achat?.brand_name}
											</span>
										</>
									)}
								</td>
								<td style={{ textAlign: "center" }}>{achat?.quantity}</td>
								<td style={{ textAlign: "center" }}>{achat?.item_unit_net_wo_discount}</td>
								<td style={{ textAlign: "center" }}>{achat?.tax_value}%</td>
								<td style={{ textAlign: "center" }}>{achat?.item_unit_discount_net ?? "--"}</td>
								<td style={{ textAlign: "center" }}>{achat?.discount ?? "--"}</td>
								<td style={{ textAlign: "center" }}>{achat?.item_total_net}</td>
								<td style={{ textAlign: "center" }}>{achat?.item_total_gross}</td>
							</tr>
						)
					})}
				</tbody>
				{store?.invoice?.data?.ventes?.comments && (
					<>
						<p>
							<strong>{t(`${LocaleNamespace.Common}:commonWords.comment`)} : </strong>
							<br />
						</p>
						<pre>{store?.invoice?.data?.ventes?.comments}</pre>
					</>
				)}
			</table>
			{store?.invoice?.data?.ventes?.achats?.find(
				(achat: any) =>
					achat.category_name?.toUpperCase().includes("APPAREILS AUDITIFS") ||
					achat.category_name?.toUpperCase().includes("ANCRAGE")
			) && (
				<p>
					* Les pièces seront disponibles 4 ans après l’arrêt de la commercialisation du produit.
					<br />
				</p>
			)}
			<br />
			{/* Summary of the transaction */}
			<div
				contentEditable={false}
				className="reporting-template__variable"
				style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
				{(store?.invoice?.data?.showPaymentMethods || store?.invoice?.data?.status !== "ACQUITTE") &&
					buildUnAcquittedPart(store, t)}

				{!!store?.invoice?.data.amount.totalAmountHT && (
					<table style={{ borderCollapse: "collapse", width: "60%", marginLeft: "1.5rem" }}>
						<thead>
							<tr style={{ backgroundColor: "#ececec" }}>
								{[
									"(*)",
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount without taxes`)}`,
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount with taxes`)}`,
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.VAT`)}`,
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.VAT amount`)}`,
								].map((title: string) => (
									<th key={title}>{title}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{store?.invoice?.data.amount.arrayAmount?.map((amount: any, index: number) => (
								<tr key={index}>
									<td style={{ textAlign: "center" }}>{amount[0]}</td>
									<td style={{ textAlign: "center" }}>{amount[1]}</td>
									<td style={{ textAlign: "center" }}>{amount[2]}</td>
									<td style={{ textAlign: "center" }}>{amount[3]}%</td>
									<td style={{ textAlign: "center" }}>{amount[4]}</td>
								</tr>
							))}
							<tr>
								<td>Total</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.totalAmountHT)}
								</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.totalAmountTTC)}
								</td>
								<td style={{ textAlign: "center" }}>-</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.totalAmountTVA)}
								</td>
							</tr>
							{parsed(store?.invoice?.data.amount.totalAmountHT) !== 0 && (
								<>
									<tr>
										<td colSpan={2}>
											{t(`${LocaleNamespace.Invoice}:invoice.sentences.insurance value`)}
										</td>
										<td style={{ textAlign: "center" }}>
											{store?.invoice?.data.amount.partSecu &&
												parsed(store?.invoice?.data.amount.partSecu)}
										</td>
									</tr>
									<tr>
										<td colSpan={2}>
											{t(`${LocaleNamespace.Invoice}:invoice.sentences.private insurance value`)}
										</td>
										<td style={{ textAlign: "center" }}>
											{store?.invoice?.data.amount.partMut &&
												parsed(store?.invoice?.data.amount.partMut)}
										</td>
									</tr>
								</>
							)}
							<tr>
								<td colSpan={2}>
									{t(`${LocaleNamespace.Invoice}:invoice.sentences.remain value after refund`)}
								</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.restCharge)}
								</td>
							</tr>
						</tbody>
					</table>
				)}
			</div>
			<br />
			{store?.invoice?.data?.ventes?.productInformations && (
				<>
					<table contentEditable={false} className="reporting-template__variable" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th colSpan={2}>Caractéristiques produit</th>
							</tr>
						</thead>
						<br />
						<tbody>
							{store?.invoice?.data?.ventes?.productInformations
								.filter((product: any) => product?.lpp?.categorie === "AUDITIF")
								.map((product: any, index: number) => {
									const typeToDisplay = product?.tags?.type
										? product?.tags?.type +
										  (product?.tags?.nombreCanaux
												? `, ${product?.tags?.nombreCanaux} canaux`
												: ", N/A")
										: "Non renseigné"
									let dispositifToDisplay = ""
									if (product?.tags?.bluetooth)
										dispositifToDisplay = dispositifToDisplay + "bluetooth"
									if (product?.tags?.telecommande)
										dispositifToDisplay =
											dispositifToDisplay === ""
												? "télécommande"
												: dispositifToDisplay + ", télécommande "

									return (
										<tr key={index}>
											<td style={{ textAlign: "center" }}>
												{product?.lpp?.oreille === "DROITE" && "Aide auditive droite"}
												{product?.lpp?.oreille === "GAUCHE" && "Aide auditive gauche"}
												<br />
												{product?.lpp?.lpp && `LPP: ${product.lpp.lpp}`}
											</td>
											<td>
												{product?.supplier &&
													`${product.supplier?.supplier_name ?? ""}, ${
														product.supplier?.supplier_address ?? ""
													}`}
												<br />
												{product?.productModel && product.productModel}
												<br />
												<br />
												{product?.tags.length !== 0 && (
													<>
														Caractéristiques essentielles, notamment :
														<br />
														{`- Type, nombre de canaux : ${typeToDisplay}`}
														<br />
														{`- Dispositifs de connectivité: ${dispositifToDisplay}`}
														<br />
														{`- Anti-larsen: ${product.tags?.antiLarsen ? "Oui" : "Non"}`}
														<br />
														{`- Réducteur d'acouphènes: ${
															product.tags?.reducteurAcouphene ? "Oui" : "Non"
														}`}
														<br />
														{`- Accessibilité de la boucle magnétique ou bobine T: ${
															product.tags?.bobine ? "Oui" : "Non"
														}`}
													</>
												)}
											</td>
										</tr>
									)
								})}
						</tbody>
					</table>
				</>
			)}

			<br />
			{/* Footer */}
			<footer>
				<span style={{ textAlign: "center", fontFamily: "Nunito, sans-serif", fontSize: "0.8em" }}>
					{store.context.laboratory.legalLabel}
					{store.context.laboratory.legalAdress && " - " + store.context.laboratory.legalAdress}
					{store.context.laboratory.legalCpo && ", " + store.context.laboratory.legalCpo}
					{store.context.laboratory.legalCity && ", " + store.context.laboratory.legalCity}
					<br />
					{store.context.laboratory.siret && "SIRET: " + store.context.laboratory.siret}
					{store.context.laboratory.tva && " - TVA: " + store.context.laboratory.tva}
					{store.context.laboratory.finess && " - FINESS: " + store.context.laboratory.finess}
					{" - CODE NAF: 4774Z"}
					{store.context.laboratory.capitalStock &&
						" - CAPITAL SOCIAL: " + store.context.laboratory.capitalStock + "€"}
					<br />
					{store.context.laboratory.mediator &&
						"Médiateur de la consommation: " + store.context.laboratory.mediator}
				</span>
			</footer>
		</>
	)
}
