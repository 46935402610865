import dayjs from "dayjs"
import { getProductLPP } from "./handler"
import { Patient, PatientSocialSecurity } from "@audiowizard/common"
import { findActiveAm } from "pages/FSV/utilsFSV"

interface LppInterface {
	categorie: string | undefined
	classe: string | undefined
	oreille: string | undefined
	age: string | undefined
	fournisseur: string | undefined
	amount: string | undefined
	lpp: string | undefined
}

export function getProductLPPWithPatient(
	type: string,
	product: any,
	suplData: any,
	patient: Patient
): LppInterface | null {
	let age = 0

	const isBlind = (patient?.patientMedicals ?? []).some((med) => med.label === "CECITE")
	const amo = findActiveAm(patient?.patientSocialSecurity) as PatientSocialSecurity
	const isMilitary = amo?.particularSituation === "MIL"

	if (isBlind || isMilitary) age = dayjs.duration({ years: 18 }).asDays()
	else if (patient.birthDate) age = dayjs().diff(patient.birthDate, "days")
	return getProductLPP(type, product, age, suplData) as LppInterface
}
