import { CalendarOutlined } from "@ant-design/icons"
import { Button, Select, Space, Spin, Tooltip } from "antd"
import { DateInput } from "components/forms/DateInput"
import TableDateRangeFilter from "components/utils/TableDateRangeFilter"
import dayjs from "dayjs"
import { capitalizeFirstLetter } from "utils/types/primitives/String/capitalizeFirstLetter"

const { Option } = Select

function isDisabled(editingKey, record, isAffiliate) {
	return (
		editingKey !== record.key ||
		!record["patient.laboratory"] ||
		(!isAffiliate && !record["user.firstName"]) ||
		!record["patient.nextSchedule"]
	)
}

function getTitle(record, isAffiliate) {
	let messages = []
	if (!record["patient.laboratory"]) {
		messages.push("Veuillez sélectionner un laboratoire")
	}
	if (!isAffiliate && !record["user.firstName"]) {
		// Affiliate users do not care about selecting the user (they have the available slots of all laboratory’s users)
		messages.push("Veuillez sélectionner un utilisateur")
	}
	if (!record["patient.nextSchedule"]) {
		messages.push("Veuillez sélectionner un statut pour le prochain rendez-vous")
	}
	return messages.join("\n")
}

function isToday(date) {
	return dayjs().isSame(dayjs(date), "day")
}

function isTomorrow(date) {
	return dayjs().add(1, "day").isSame(dayjs(date), "day")
}

function getVerboseDate(date) {
	if (isToday(date)) {
		return "Aujourd'hui à " + dayjs(date).format("HH:mm")
	}
	if (isTomorrow(date)) {
		return "Demain à " + dayjs(date).format("HH:mm")
	}
	return capitalizeFirstLetter(dayjs(date).format("dddd DD/MM/YYYY à HH:mm"))
}

/**
 * @param {{start: string; end: string; userId: number; userFullName: string}} slot
 * @param {boolean} isAffiliate
 * @returns {string}
 */
function getSlotLabel(slot, isAffiliate) {
	const start = dayjs(slot.start).format("HH:mm")
	const end = dayjs(slot.end).format("HH:mm")
	/**
	 * Affiliated users have the available slots of all the users of the selected laboratory,
	 * hence the audioprosthetist full-name is append to disambiguate.
	 */
	const userFullNameIfAffiliate = isAffiliate ? ` : ${slot.userFullName}` : ""
	return `${start} - ${end}${userFullNameIfAffiliate}`
}

function getScheduleRangeLabel(record) {
	const start = dayjs(record["patient.scheduleTime"].start).format("HH:mm")
	const end = dayjs(record["patient.scheduleTime"].end).format("HH:mm")
	return `${start} - ${end}`
}

/**
 * @param {{start: string; end: string; userId: number; userFullName: string}[]} availableSlots
 * @param {boolean} isAffiliate
 */
function getDropdownStyleMinWidth(availableSlots, isAffiliate) {
	const labelMaxLength = Math.max(...availableSlots.map((slot) => getSlotLabel(slot, isAffiliate).length))
	const minWidthEm = Math.round(0.67 * labelMaxLength)
	return `${minWidthEm}em`
}

const NextScheduleDateCol = (
	openModalAgenda,
	isScheduleMutating,
	handlePatientChange,
	editingKey,
	isAddingLead,
	handleDateChange,
	availableSlots,
	queryfilters,
	sort,
	isAffiliate
) => {
	const nextDateOf = (date, record) => {
		date = record[isAddingLead && editingKey === record.key ? "patient.scheduleDate" : "schedule.nextDateOf"]

		if (isAddingLead && editingKey === record.key) {
			if (!isScheduleMutating) {
				return (
					<Tooltip title={getTitle(record, isAffiliate)} color="red">
						<div className="d-flex">
							<DateInput
								format="DD/MM/YYYY"
								style={{ width: "120px", padding: "5px", height: "35px" }}
								groupClassName="m-0 d-flex"
								disabled={isDisabled(editingKey, record, isAffiliate)}
								value={date !== "" ? dayjs(date) : undefined}
								disabledDate={(current) => dayjs().subtract(1, "days").isAfter(current)}
								onChange={(date) => {
									handleDateChange(date, date ? date.format("YYYY-M-D") : null, record)
									handlePatientChange({ start: "", end: "" }, record, "patient.scheduleTime")
								}}
							/>
							{date && date !== "" && !record["patient.scheduleTime"].start && (
								<Select
									disabled={editingKey !== record.key}
									allowClear
									style={{ width: "120px" }}
									className="ml-2"
									dropdownStyle={{ minWidth: getDropdownStyleMinWidth(availableSlots, isAffiliate) }}
									onChange={(value) => {
										handlePatientChange(availableSlots[value], record, "patient.scheduleTime")
									}}
									defaultValue={() =>
										record["patient.scheduleTime"].start !== "" ? getScheduleRangeLabel(record) : []
									}>
									{availableSlots.map((slot, index) => {
										return (
											<Option key={`user-${slot.userId}-at-${slot.start}`} value={index}>
												{getSlotLabel(slot, isAffiliate)}
											</Option>
										)
									})}
								</Select>
							)}
							{record["patient.scheduleTime"].start && (
								<span style={{ whiteSpace: "nowrap", padding: "7px" }}>
									{getScheduleRangeLabel(record)}
								</span>
							)}
							<Button
								style={{
									alignItems: "center",
									borderRadius: "5px",
									paddingBottom: "5px",
								}}
								disabled={isDisabled(editingKey, record, isAffiliate)}
								className="btn-primary h-100 ml-2"
								onClick={openModalAgenda}>
								<CalendarOutlined />
							</Button>
						</div>
					</Tooltip>
				)
			}
			return (
				<Space className="ml-3" size="middle">
					<Spin size="large" />
				</Space>
			)
		}
		if (date) {
			return <span> {getVerboseDate(date)} </span>
		}
		return <p className="m-0"> Aucun rendez-vous </p>
	}

	return {
		title: null,
		key: "nextScheduleDateOf",
		dataIndex: isAddingLead ? "patient.scheduleDate" : "schedule.nextDateOf",
		width: "210px",
		render: nextDateOf,
		sorter: !isAddingLead && editingKey === "",
		sortOrder: sort?.field === "patient.scheduleDate" || sort?.field === "schedule.nextDateOf" ? sort.order : null,
		filterDropdown: TableDateRangeFilter,
		filteredValue: queryfilters.nextScheduleDateOf,
		validate: (cond) =>
			isAddingLead &&
			(!cond["patient.scheduleTime"] ||
				cond["patient.scheduleTime"].start === "" ||
				cond["patient.scheduleTime"].end === "")
				? "Veuillez choisir la date et l'heure du rendez-vous."
				: "",
	}
}
export default NextScheduleDateCol
