import { EyeOutlined, UserOutlined } from "@ant-design/icons"
import { HiboutikProductEntity, LogisticProduct, PatientEquipment } from "@audiowizard/common"
import { Badge } from "components/utils/Badge"
import dayjs from "dayjs"
import { Link } from "react-router-dom"

const ColumnEquipped = (catalog: HiboutikProductEntity[] | undefined, statusLabel: Record<string, string>): any[] => {
	if (catalog == null) return []
	return [
		{
			title: "Patient",
			dataIndex: "patient",
			render: (patient: Record<string, any>) => (
				<Link
					title="Voir la fiche patient"
					to={"/fiche-patient/" + patient.id}
					className="btn btn-secondary btn-sm m-0 w-100 text-left d-flex align-items-center">
					<UserOutlined className="mr-2" /> {patient.lastName} {patient.firstName}
				</Link>
			),
		},
		{
			title: "Nom",
			dataIndex: "productJsonHiboutik",
			render: (p: Record<string, any>) => {
				if (!p.product_id) return p.product_model
				return (
					<Link
						title="Voir la fiche produit"
						to={"fiche-produit/" + p.product_id}
						className="btn btn-secondary btn-sm m-0 w-100 text-left d-flex align-items-center">
						<EyeOutlined className="mr-2" /> {p.product_model}
					</Link>
				)
			},
		},
		{
			title: "Catégorie",
			dataIndex: "productJsonHiboutik",
			render: (_: any, equipment: PatientEquipment) => {
				const productData = catalog.find((product) => product.id === +equipment.productIdHiboutik!)
				return productData?.categoryName ?? ""
			},
		},
		{
			title: "Déclinaison",
			dataIndex: "productJsonHiboutik",
			render: (p: Record<string, any>, equipment: PatientEquipment) => {
				const sizeName = p.size_name ?? p.product_size_name
				if (sizeName) return sizeName
				else if (equipment?.sizeIdHiboutik != null && equipment?.sizeIdHiboutik !== 0) {
					const productData = catalog.find((product) => product.id === +equipment.productIdHiboutik!)
					const size = productData?.sizeDetails?.find(
						(size: any) => size.sizeId === equipment?.sizeIdHiboutik
					)

					return size?.sizeName ?? <span className="opacity-5">Vide</span>
				}
				return <span className="opacity-5">Pas de déclinaison</span>
			},
		},
		{
			title: "Numéro de série",
			dataIndex: "serialNumber",
		},
		{
			title: "Dépôt",
			dataIndex: "logisticProduct",
			render: (logisticProduct: LogisticProduct) => {
				if (logisticProduct?.isDepot) {
					return "Oui"
				} else if (!logisticProduct) {
					return <span className="opacity-5">Inconnu</span>
				}
				return "Non"
			},
		},
		{
			title: "Date de vente",
			dataIndex: "datePurchase",
			render: (purchaseDate: string) => {
				if (purchaseDate) return dayjs(purchaseDate).format("DD/MM/YYYY")
				return "Appareil non vendu"
			},
		},
		{
			title: "Statut",
			dataIndex: "status",
			render: (d: string) => {
				let color = "dark"
				switch (d) {
					case "VENDU":
						color = "primary"
						break
					case "ATTENTE":
						color = "warning"
						break
					case "SAV":
						color = "danger"
						break
					case "ESSAI":
						color = "info"
						break
				}
				return <Badge color={color} text={statusLabel?.[d] ?? d} />
			},
			width: "2%",
		},
	]
}
export default ColumnEquipped
