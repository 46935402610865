// These are the state-owned AMCs that we handle.
// List to be updated as we will handle more AMCs in time.
export const handledAmcs = ["88888888", "99999997", "92006089"]

export const c2sAmcs = ["88888888", "99999997"]

export const nonHandledButPublicAmcs = [
	"27000000",
	"31744217",
	"317442176",
	"35235886",
	"352358865",
	"40128530",
	"401285309",
	"40359626",
	"403596265",
	"408975415",
	"444518492",
	"449571256",
	"538518473",
	"77567196",
	"775671969",
	"784411134",
]

// METHODE TEMPORAIRE:
// Adaptation éventuelle des montants de remboursement pour la Réunion
// Si un centre est dans les DOM-TOM, ajouter l'id du labo concerné.
export const domTomLabsIds = [
	96, 98, 99, 119, 185, 199, 271, 272, 273, 274, 292, 303, 304, 306, 307, 444, 446, 455, 466,
]

export const twentyYearsInDays = 7305
export const twoYearsInDays = 730

export function ancrageOsseuxBaseSecuAmount(product) {
	if (product.supplier_name === "Medel") return 2447.85
	return 3198.23
}
