/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"

import { API_URL } from "../../config"
import ChartStackBar from "./ChartStackBar"

const Attentes = () => {
	const [lookingFor, setLookingFor] = useState([])
	const [loading, setLoading] = useState(true)

	const lfHashmap = ["PRIX", "ROBUSTE", "DISCRETION", "ACOUPHENE", "CONNECTIVITE", "MANIPULABLE", "RECHARGEABLE"]
	const getLookingFor = async () => {
		try {
			const result = await axios.get(API_URL + "/stats/looking_for")
			const Choices = [
				[0, 0, 0, 0, 0, 0, 0],
				[0, 0, 0, 0, 0, 0, 0],
				[0, 0, 0, 0, 0, 0, 0],
			]

			if (!result?.data?.message?.length) return setLoading(false)
			result?.data?.message.forEach((e) => {
				if (e.number < 3 && e.number >= 0) {
					Choices[e.number][lfHashmap.indexOf(e.value)] = parseInt(e.total)
				}
			})
			const FinalChoices = [
				{
					name: "Premier choix",
					data: Choices[0],
				},
				{
					name: "Second choix",
					data: Choices[1],
				},
				{
					name: "Troisième choix",
					data: Choices[2],
				},
			]
			setLookingFor(FinalChoices)
		} catch (e) {
			setLookingFor([])
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getLookingFor()
	}, [])

	return (
		<>
			<h5 className="mb-4">Les attentes</h5>
			<ChartStackBar
				series={lookingFor}
				labels={[
					"Prix",
					"Robustesse",
					"Discrétion",
					"Acouphène",
					"Connectivité",
					"Manipulable",
					"Rechargeable",
				]}
				colors={["#78e08f", "#38ada9", "#079992"]}
			/>
			{loading && (
				<>
					<div className="chart_loading">
						<div className="spinner-border" role="status">
							{" "}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default Attentes
