import { PatientEquipment } from "@audiowizard/common"
import { Select, Tag } from "antd"
import { useEffect, useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import API from "services/API"
import { formatDateForDB } from "services/functions"

/*
 * There is a relation between a logistic product and a patient equipment. But there are also other patient equipment properties that need to be updated
 * When an equipment is assigned and received, it's status changes to "ESSAI" and warehouseIdHiboutik is set to logistic's warehouseId. A todo must also be created
 * When an equipment is assigned but not received, it's status does NOT change and warehouseIdHiboutik is NOT set to logistic's warehouseId
 * When an equipment is unassigned, it's status changes to "ATTENTE", serialNumber and warehouseIdHiboutik properties are set to NULL
 *
 * These changes must be applied when assigning/unassigning patient equipments from logistic product AND when toggling received
 */

export async function createTodoForLogistic(
	patientIri: string,
	laboratoryIri: string,
	productName: string,
	productSize: string | undefined
): Promise<void> {
	try {
		await API.create("TODOS_API", {
			label: `Préparer ${productName} ${productSize ? `(${productSize})` : ""}`,
			status: "TODO",
			target: "ALL",
			laboratories: [laboratoryIri],
			patient: patientIri,
			type: "PREPARATION",
			realisationDate: formatDateForDB(new Date()),
		})
	} catch (error) {
		console.error(error)
	}
}

async function unassignPatientEquipment(id: number): Promise<void> {
	await API.update("PATIENT_EQUIPMENTS_API", id, {
		status: "ATTENTE",
		serialNumber: null,
		warehouseIdHiboutik: null,
		logisticProduct: null,
	})
}

type LabelSelectPatientProps = {
	value: PatientEquipment
}
function LabelSelectPatient({ value }: LabelSelectPatientProps): JSX.Element {
	return (
		<>
			{value.ear != null && value.ear !== "BILATERAL" && (
				<Tag color={value.ear === "DROITE" ? "red" : "blue"}>{value.ear?.[0]}</Tag>
			)}
			{`${value.patient!.firstName} ${value.patient!.lastName}`}
		</>
	)
}

type PatientEquipmentSelectProps = {
	product: {
		logistic_product_iri: string
		product_id: number
		product_size_id?: number
		quantity: number
		with_serial_number: boolean
		product_serial_number?: string
	}
	detail: {
		product_model: string
		sizes: Record<number, { size_name: string }>
	}
	laboratoryIri: string
	warehouseId: number
	received: boolean
	disabled: boolean
	hidden: boolean
	value: PatientEquipment[]
	onChange: (value: PatientEquipment[]) => void
	onLoadingChange: (value: boolean) => void
}
export default function PatientEquipmentSelect({
	product,
	detail,
	laboratoryIri,
	warehouseId,
	received,
	disabled,
	hidden,
	value,
	onChange,
	onLoadingChange,
}: PatientEquipmentSelectProps): JSX.Element {
	const queryClient = useQueryClient()

	const { data: equipmentsEssai, isLoading: isLoadingEquipmentsEssai } = useQuery(
		["PATIENT_EQUIPMENTS_API", { status: ["ATTENTE", "ESSAI"], "exists[logisticProduct]": false }],
		async ({ signal }) =>
			await API.findAll<PatientEquipment[]>(
				"PATIENT_EQUIPMENTS_API",
				"?status[]=ATTENTE&status[]=ESSAI&exists[logisticProduct]=false",
				false,
				{
					signal,
				}
			)
	)

	const { mutate: addPatientEquipment, isLoading: isLoadingAdd } = useMutation(
		async (id: number) => {
			// replace old one, if serial number
			if (product.with_serial_number && value[0] != null) {
				await unassignPatientEquipment(value[0].id!)
			}

			// If received, update the equipment to be in ESSAI and add todo
			// If not, only update the logisticProduct relation and serial number
			let patientEquipment
			if (received) {
				const res = await API.update<PatientEquipment>("PATIENT_EQUIPMENTS_API", id, {
					status: "ESSAI",
					serialNumber: product.product_serial_number,
					warehouseIdHiboutik: warehouseId,
					logisticProduct: product.logistic_product_iri,
				})
				patientEquipment = res.data

				await createTodoForLogistic(
					patientEquipment.patient!["@id"],
					laboratoryIri,
					detail.product_model,
					detail.sizes?.[product.product_size_id!]?.size_name
				)
			} else {
				const res = await API.update<PatientEquipment>("PATIENT_EQUIPMENTS_API", id, {
					logisticProduct: product.logistic_product_iri,
					serialNumber: product.product_serial_number,
				})
				patientEquipment = res.data
			}

			if (product.with_serial_number) {
				// serial number, one option
				onChange([patientEquipment])
			} else {
				// quantity multiple options
				onChange([...value, patientEquipment])
			}
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("PATIENT_EQUIPMENTS_API")
				await queryClient.invalidateQueries("PURCHASE_ORDERS_API") // can affect relation
			},
		}
	)
	const { mutate: removePatientEquipment, isLoading: isLoadingRemove } = useMutation(
		async (id: number) => {
			await unassignPatientEquipment(id)

			if (product.with_serial_number) {
				// serial number, one option
				onChange([])
			} else {
				// quantity multiple options
				onChange(value.filter((s) => s.id !== id))
			}
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("PATIENT_EQUIPMENTS_API")
				await queryClient.invalidateQueries("PURCHASE_ORDERS_API") // can affect relation
			},
		}
	)
	const { mutate: clearPatientEquipments, isLoading: isLoadingClear } = useMutation(
		async () => {
			await Promise.all(value.map((pe) => unassignPatientEquipment(pe.id!)))

			onChange([])
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("PATIENT_EQUIPMENTS_API")
				await queryClient.invalidateQueries("PURCHASE_ORDERS_API") // can affect relation
			},
		}
	)

	const isLoading = isLoadingAdd || isLoadingRemove || isLoadingClear
	useEffect(() => {
		onLoadingChange(isLoading)
	}, [isLoading])

	const awaitingPatientEquipments = useMemo(() => {
		if (equipmentsEssai == null) return []

		return equipmentsEssai.filter(
			(f) =>
				f.productIdHiboutik === product.product_id &&
				(product.product_size_id ? f.sizeIdHiboutik === product.product_size_id : true) &&
				f.status === "ATTENTE"
		)
	}, [equipmentsEssai])

	const options = useMemo(() => {
		const options = awaitingPatientEquipments.map((pe) => ({
			value: pe.id!,
			label: <LabelSelectPatient value={pe} />,
		}))

		// add selected if not present in options already
		for (const s of value) {
			if (options.some((o) => o.value === s.id)) continue

			options.push({
				value: s.id!,
				label: <LabelSelectPatient value={s} />,
			})
		}

		return options
	}, [value, awaitingPatientEquipments])

	return (
		<Select
			className="form-control removeantd-class"
			style={{ display: hidden ? "none" : "block" }}
			disabled={disabled || isLoading}
			loading={isLoadingEquipmentsEssai}
			allowClear
			showSearch
			placeholder="Aucun"
			mode={product.with_serial_number ? undefined : "multiple"} // mode undefined -> single select
			maxTagCount={1}
			value={
				product.with_serial_number
					? value[0]?.id ?? "" // null -> ""
					: value.map((s) => s.id!)
			}
			onSelect={(id: any) => {
				if (!product.with_serial_number && value.length >= product.quantity) {
					window.alert("Vous ne pouvez pas assigner plus de produit que de quantité")
				} else {
					addPatientEquipment(id as number)
				}
			}}
			onDeselect={(id: any) => {
				removePatientEquipment(id as number)
			}}
			onClear={clearPatientEquipments}
			options={options}
		/>
	)
}
