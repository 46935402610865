import { useContext, useEffect, useState } from "react"
import { consulterClient, isJuxtaLinkConnected, readCarteVitale } from "../functionsFSV"
import { AlertCustom } from "components/utils/AlertCustom"
import { Button as ButtonModal, Spinner } from "reactstrap"

import { Patient } from "@audiowizard/common"
import { findActiveAmFromFSV } from "../utilsFSV"
import FsvApi from "../api"
import { getAdriDataSilent } from "../getAdriDataUtil"
import AuthContext from "contexts/AuthContext"
import { Col, Row } from "antd"

interface Identity {
	nom: string
	prenom: string
}

interface CheckErrorTeletransmissionDeSuiviProps {
	isOpen: boolean
	patient: Patient
	allowTeletransSV: (allow: boolean) => void
	allowTeletransDegrade: (allow: boolean) => void
}

const CheckErrorTeletransmissionDeSuivi = ({
	isOpen,
	patient,
	allowTeletransSV,
	allowTeletransDegrade,
}: CheckErrorTeletransmissionDeSuiviProps): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const [loadingAdri, setLoadingAdri] = useState(false)
	const [carteVitalePresente, setCarteVitalePresente] = useState(false)
	const [isCartePatient, setIsCartePatient] = useState(true)
	const [checkSV, setCheckSV] = useState({
		isSVPossible: false,
		isLectureCarte: false,
		isLectureAdr: false,
		dataReady: false,
	})
	const [patientFromFSV, setPatientFromFSV] = useState<any>()
	const [patientsInCV, setPatientsInCV] = useState<Identity[]>()
	const [isJuxtaLinkReady, setIsJuxtaLinkReady] = useState(false)

	const { laboratory, user, subscriptions } = useContext(AuthContext)

	const handleUpdateADRI = async (): Promise<void> => {
		try {
			setLoadingAdri(true)
			const { adriData } = await getAdriDataSilent({ user, laboratory, patient, subscriptions })
			if (adriData) {
				setPatientFromFSV(adriData)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoadingAdri(false)
		}
	}

	const readCV = async (): Promise<void> => {
		try {
			setLoading(true)
			const { result }: { result: Identity[] } = await readCarteVitale()
			// vérifie que la carte et bien celle du patient en court
			const isCartePatient = result.some(
				(p: any) => p.prenom === patient.firstName?.toUpperCase() && p.nom === patient.lastName?.toUpperCase()
			)
			setIsCartePatient(isCartePatient)
			setPatientsInCV(result)
			setCarteVitalePresente(true)
		} catch (error) {
			error && console.error(error)
			setCarteVitalePresente(false)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!patient.idFSV) return
		consulterClient(patient.idFSV).then((client) => {
			const tmpPatient = FsvApi.recursivelySearchForKey(client, "individu")
			setPatientFromFSV(tmpPatient)
		})
	}, [patient])

	useEffect(() => {
		if (!patientFromFSV) return
		const amo = findActiveAmFromFSV(patientFromFSV.couvertureAMO)

		setCheckSV({
			isLectureCarte: patientFromFSV.isLectureCarte === "true",
			isSVPossible: amo.isSVPossible === "true",
			isLectureAdr: amo.isLectureAdr === "true",
			dataReady: true,
		})
	}, [patientFromFSV])

	useEffect(() => {
		if (
			isJuxtaLinkReady &&
			checkSV.dataReady &&
			(!checkSV.isSVPossible || (!checkSV.isLectureCarte && !checkSV.isLectureAdr))
		) {
			handleUpdateADRI()
		}
	}, [checkSV.dataReady, patientFromFSV?.idPatient])

	useEffect(() => {
		const isJuxtaLink = isJuxtaLinkConnected()
		if (isOpen && isJuxtaLink) readCV()
		setIsJuxtaLinkReady(isJuxtaLink)
	}, [isOpen])

	useEffect(() => {
		allowTeletransDegrade(isJuxtaLinkReady)
		allowTeletransSV(isJuxtaLinkReady)
	}, [isJuxtaLinkReady])

	useEffect(() => {
		allowTeletransSV(
			carteVitalePresente && (checkSV.isLectureCarte || checkSV.isLectureAdr) && checkSV.isSVPossible && !loading
		)
	}, [carteVitalePresente, checkSV, loading])

	if (loading || loadingAdri) return <AlertCustom message={<div>... lecture Carte</div>} />

	if (!isJuxtaLinkReady)
		return <AlertCustom message={<div>JuxtaLink n’a pas été trouvé, la télétransmission n’est pas possible</div>} />

	return (
		<>
			{patient.idFSV && !checkSV.isLectureCarte && !checkSV.isLectureAdr && (
				<AlertCustom
					message={
						<>
							<div>
								Les données patient ne proviennent pas de le carte Vitale, le mode Sésam-Vitale n’est
								pas possible.
							</div>
							<div>Veuillez synchroniser les données avec la lecture de carte.</div>
						</>
					}
					type="warning"
				/>
			)}
			{!isCartePatient && patientsInCV && (
				<AlertCustom
					message={
						<>
							<div>Il semble que le patient ne soit pas associé à la carte dans le lecteur.</div>
							<Row>
								<Col span={8} className="text-right mr-2">
									Patient :
								</Col>
								<Col>
									{patient.lastName} {patient.firstName}
								</Col>
							</Row>
							<Row>
								<Col span={8} className="text-right mr-2">
									Patient{patientsInCV.length > 1 ? "s" : ""} en carte :{" "}
								</Col>
								<Col>
									{patientsInCV?.map((p) => (
										<div key={p.prenom}>
											{p.nom} {p.prenom}
										</div>
									))}
								</Col>
							</Row>
						</>
					}
					type={"warning"}
					onClick={() => readCV()}
					icon={"fa-sync"}
				/>
			)}
			{!carteVitalePresente && (
				<AlertCustom
					message={
						<>
							<div>Mode Sésam-Vitale impossible car la carte patient n’a pas été trouvée.</div>
							<div>Veuillez insérer la carte pour activer le mode Sésam-Vitale</div>
						</>
					}
					onClick={() => readCV()}
					icon={"fa-sync"}
					type="warning"
				/>
			)}
			{patient.idFSV && !checkSV.isSVPossible && (
				<AlertCustom
					message={
						<>
							<div>Télétransmission en mode Sésam-vitale impossible:</div>
							<ButtonModal size="sm" color="primary" onClick={handleUpdateADRI} disabled={loadingAdri}>
								Mettre à jour le client avec un appel ADRI {loadingAdri && <Spinner size="sm" />}
							</ButtonModal>
						</>
					}
				/>
			)}
		</>
	)
}

export default CheckErrorTeletransmissionDeSuivi
