import { lazy } from "react"
import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, ProfilRoutePrefix, ProfilRoutes } from "./routes"
import useCustomTitle from "components/Hooks/useTitle"

const Informations = lazy(() => import("../../pages/mon-compte/MesInformations"))

function ProfilRouter(): JSX.Element {
	useCustomTitle("Gestion de compte | Profil")

	return (
		<CardWithTabs urlprefix={ProfilRoutePrefix}>
			<CardItem
				name="Informations générales"
				url={noPrefix(ProfilRoutes.Informations)}
				component={Informations}
			/>
		</CardWithTabs>
	)
}

export default ProfilRouter
