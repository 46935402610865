export const itemsTemplates = [
	{
		id: "e7c6173179f02b8cf509581af05fb2103f8c25ccb0a001a6e939070f1f64f479",
		name: "devis-simplifie",
		label: "Devis simplifié",
		type: "DEVIS",
	},
	{
		id: "292250705c765559510e537fd73db3f94ee063227adfdaca33aadd1e15570c1e",
		name: "ca-par-categorie-produits",
		label: "Chiffre d'affaires par catégorie de produits",
		type: "CA_PRODUITS",
	},
	{
		id: "fb0492ece9c5823a9ad1d529f1c09cb609ecdfce809e9b6742a82e80cee6f054",
		name: "encaissements-journee",
		label: "Détails des encaissements par jour",
		type: "ENCAISSEMENT_JOUR",
	},
	{
		id: "3d007f9f5cc8d341d848043af37eddbca09e22a4f0760dd25e65c7847bf807fa",
		name: "ca-par-type-paiement",
		label: "Chiffre d'affaire par type de paiements",
		type: "CA_PAYMENTS",
	},
	{
		id: "be3757d4435eb634cb0f130fde858f7a7154528f81e1e9870b59afb7ecc41686",
		name: "feuille-caisse",
		label: "Feuille de caisse",
		type: "FEUILLE_CAISSE",
	},
	{
		id: "859e902aa5076d57b144cddec718ead78b6e6dd0185c4fb7e46ee6b3da0d14cb",
		name: "journal-caisse",
		label: "Journal de caisse",
		type: "JOURNAL_CAISSE",
	},
	{
		id: "835475c0a21cbd86e531d9e5550092720c1f8d098d475183a47ab3b6bafde4f5",
		name: "journal-des-ventes",
		label: "Journal des ventes",
		type: "JOURNAL_VENTES",
	},
	{
		id: "f39073261577e089d9347ee735c0374e18e3d39fe7903e8057978d908bb968e8",
		name: "mouvements-caisse",
		label: "Mouvements de caisse",
		type: "MOUVEMENTS_CAISSE",
	},
	{
		id: "f442d345123315280193635b985f6bfcebe681696c1c7142d9189338133f84a6",
		name: "restes-dus",
		label: "Restes à encaisser",
		type: "RESTES_DUS",
	},
	{ id: "e15453ba7a6e9b570cf50f59717bed1667da7eaaa206b6114e1a9b56a518fc95", name: "sav", label: "SAV", type: "SAV" },
	{
		id: "be774f77c81765c5de4cfaaa952dfd00548fe6d8619cb7c324b74d3b7e1207f7",
		name: "facture",
		label: "Facture",
		type: "FACTURE",
	},
	{
		id: "2f9bfb85b5bbe9b318df45cf733b8337c3e27f6271192cbedc48f3dc3624d1df",
		name: "depot_banque",
		label: "Bon de dépôt en banque",
		type: "DEPOT_BANQUE",
	},
	{
		id: "64169ad5b53af27801f9a6c0d6e80205d59c0b4e518e919f1285d150abdea286",
		name: "reassort_caisse",
		label: "Bon de réassort caisse",
		type: "REASSORT_CAISSE",
	},
	{
		id: "5b30a83ad5af5360825092064711db6bfb11096f9a5007d726687fb0529b17f6",
		name: "validation_caisse",
		label: "Bon de validation d'état de caisse",
		type: "VALIDATION_CAISSE",
	},
	{
		id: "6be38b95d5e59d7dc38f6f567a031a5b1318d4b60830353b60a30558f954448e",
		name: "sortie-caisse-pour-achat",
		label: "Bon de sortie de caisse pour achat",
		type: "SORTIE_CAISSE",
	},
	{
		id: "3bc8efdaa371d913284bb686c8b3961dc35b9efea6da8d33e139009adf757989",
		name: "bon_avoir",
		label: "Bon d'avoir sur produit",
		type: "BON_AVOIR",
	},
	{
		id: "02006601af716e7aec0b54c9799e3a80f7f057a2267daf548f5f6d25c6e17691",
		name: "fond_de_caisse",
		label: "Fond de caisse",
		type: "RESUME_FOND_DE_CAISSE",
	},
]
