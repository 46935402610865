import { Select, Tag } from "antd"
import { getFontColorForBackground } from "services/functions"

const NextScheduleStatusCol = (
	handlePatientChange,
	editingKey,
	isAddingLead,
	queryfilters,
	quickFilterData,
	scheduleTypes
) => {
	const nextScheduleStatusRender = (nextSchedule, record) => {
		const scheduleType = scheduleTypes?.[nextSchedule?.status] ?? {}

		return isAddingLead && editingKey === record.key ? (
			<Select
				showSearch
				className="w-100"
				placeholder="Sélectionner statuts"
				defaultValue={record["patient.nextSchedule"]?.status}
				dropdownStyle={{ overflow: "visible" }}
				onChange={(value) => {
					handlePatientChange(value, record, "patient.nextSchedule")

					handlePatientChange("", record, "patient.scheduleDate")
					handlePatientChange({ start: "", end: "" }, record, "patient.scheduleTime")
				}}>
				{Object.entries(scheduleTypes)
					.filter(([, scheduleType]) => !scheduleType.hidden)
					.map(([status, scheduleType]) => (
						<Select.Option key={status} value={status} className="option-content">
							<div className="schedule-line-option">
								<div className="option-color" style={{ backgroundColor: scheduleType.color }} />
								<div className="option-label">{scheduleType.customLabel || scheduleType.label}</div>
							</div>
						</Select.Option>
					))}
			</Select>
		) : (
			<Tag
				className="tag"
				style={{
					backgroundColor: scheduleType?.color,
					color: scheduleType.label ? getFontColorForBackground(scheduleType?.color) : "grey",
					borderColor: scheduleType?.color,
					borderRadius: "5px",
				}}>
				{scheduleType.customLabel || scheduleType.label || "Aucun"}
			</Tag>
		)
	}

	const filters = Object.entries(scheduleTypes ?? {})
		.filter(
			([status]) =>
				quickFilterData.nextScheduleStatus == null || quickFilterData.nextScheduleStatus.includes(status) // if quick filter is set, only display quick filters statuses
		)
		.map(([status, scheduleType]) => ({
			text: scheduleType?.customLabel || scheduleType?.label,
			value: status,
		}))

	// Only show "Aucun" filter when no status quick filter is selected
	if (quickFilterData.nextScheduleStatus == null) {
		filters.unshift({
			text: "Aucun",
			value: "__NO_NEXT_SCHEDULE__",
		})
	}

	return {
		title: null,
		key: "nextScheduleStatus",
		dataIndex: "patient.nextSchedule",
		width: "180px",
		render: nextScheduleStatusRender,
		filters,
		filteredValue: queryfilters?.nextScheduleStatus || null,
		filterSearch: true,
		validate: (cond) =>
			isAddingLead && (!cond["patient.nextSchedule"] || cond["patient.nextSchedule"] === "")
				? "Vous n'avez pas choisi le statut de prochain rendez-vous."
				: "",
	}
}

export default NextScheduleStatusCol
