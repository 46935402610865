import { Document } from "@audiowizard/common"
import Toggle from "components/Toggle/Toggle"
import { useEffect, useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import "./Modal.DocumentViewer.scss"

type ComplianceDocumentViewerProps = {
	isOpen: boolean
	document: Document
	handleClose?: () => void
	handleNext?: () => void
	loading: boolean
	viewOnly?: boolean
	index: number
	maxIndex: number
}

const ComplianceDocumentViewer = ({
	isOpen,
	handleClose,
	document,
	handleNext,
	loading,
	viewOnly = false,
	index,
	maxIndex,
}: ComplianceDocumentViewerProps): JSX.Element => {
	const [agreementToggle, setAgreementToggle] = useState(false)

	const labels = (value: string | undefined): string | undefined => {
		if (value?.includes("CGV")) return "CONDITIONS GENERALES DE VENTE"
		if (value?.includes("CGU")) return "CONDITIONS GENERALES D'UTILISATION"
		if (value?.includes("POLICY_PRIVACY")) return "RGPD"
	}

	useEffect(() => {
		setAgreementToggle(false)
	}, [index])

	return (
		<div>
			<Modal scrollable={true} isOpen={isOpen} size="xl" className="modal-documentviewer">
				<ModalHeader>
					<div className="modal-title d-flex align-items-baseline">
						<div className="input-group">
							<input className="form-control" type="text" value={labels(document.type)} disabled={true} />
						</div>
						{viewOnly && (
							<div className="icon-sm mx-3 cursor-pointer text-danger close-icon" onClick={handleClose}>
								<i className="fas fa-times" />
							</div>
						)}
					</div>
				</ModalHeader>
				<ModalBody>
					<div className="preview-container">
						{loading ? (
							<div>
								<p>Chargement de l'aperçu...</p>
							</div>
						) : (
							<iframe
								className="document-iframe"
								title={document?.label}
								src={document?.signature}
								frameBorder="0"
								width="100%"
							/>
						)}
					</div>
				</ModalBody>
				<ModalFooter className="justify-content-between">
					{/* toggle pour l'envoie du document par mail, en attente d'une route back pour le rajouter
					 <div className="mx-4 d-flex flex-column align-items-center">
						<Toggle
							isOn={emailToggle}
							handleToggle={() => setEmailToggle(!emailToggle)}
							onColor="#18bc9b"
						/>
						Recevoir par email <i className="fad fa-envelope" />
					</div> */}
					{!viewOnly && (
						<div>
							<Toggle
								isOn={agreementToggle}
								handleToggle={() => setAgreementToggle(!agreementToggle)}
								onColor="#18bc9b"
							/>
						</div>
					)}

					{!viewOnly && (
						<div>
							<button
								type="button"
								className="btn btn-primary btn-sm mt-3"
								onClick={handleNext}
								disabled={!agreementToggle}>
								J'ai lu et j'accepte {index + 1}/{maxIndex}
							</button>
						</div>
					)}
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default ComplianceDocumentViewer
