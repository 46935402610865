import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import { Link } from "react-router-dom"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		title: "Bon de Livraison",
		dataIndex: "logistic",
		width: "300px",
		render: (d) => (
			<>
				{d?.id ? (
					<Link to={"/bon-de-livraison/" + d?.id}>
						<button type="button" className="btn btn-block btn-sm btn-secondary pt-1 pb-1 text-left">
							{d.numero}
						</button>
					</Link>
				) : (
					"N/C"
				)}
			</>
		),
	},
	{
		title: "Quantité",
		dataIndex: "productQuantity",
		align: "center",
		width: "60px",
	},
	{
		title: "Numéro de série",
		dataIndex: "productSerialNumber",
		render: (d, row) => (
			<>
				{row.type === "WITH_SERIAL_NUMBER" ? (
					<span style={{ fontFamily: "Monospace" }}>{d || "Pas de numéro de série renseigné"}</span>
				) : (
					<span style={{ opacity: "0.5" }}>Sans numéro de série</span>
				)}
			</>
		),
	},
	{
		title: "En dépôt",
		dataIndex: "isDepot",
		align: "center",
		width: "80px",
		render: (isDepot) =>
			isDepot ? (
				<CheckCircleFilled style={{ fontSize: "22px", color: "var(--info)" }} />
			) : (
				<CloseCircleFilled style={{ fontSize: "22px", color: "var(--warning-light)" }} />
			),
	},
	{
		width: "80px",
		title: "Réceptionné",
		align: "center",
		dataIndex: "inventoryInputDetailId",
		render: (inventoryInputDetailId, row) =>
			inventoryInputDetailId || row.isTransferred ? (
				<CheckCircleFilled style={{ fontSize: "22px", color: "var(--info)" }} />
			) : (
				<CloseCircleFilled style={{ fontSize: "22px", color: "var(--warning-light)" }} />
			),
	},
	// {
	// 	title: "Status",
	// 	dataIndex: "state",
	// 	render: (_, row) => {
	// 		const states = []

	// 		if (row.isCreditRequested) states.push("Renvoyé avec demande d'avoir")
	// 		else if (row.isReturned) states.push("Renvoyé")
	// 		if (row.isReturnedViaInventory) states.push("Sorti via inventaire")
	// 		if (row.isSold) states.push("Vendu")

	// 		return <>{states}</>
	// 	},
	// },
]
