import React, { Fragment, useMemo } from "react"

type BoldListProps = {
	items: string[]
}
export default function BoldList({ items }: BoldListProps): JSX.Element {
	const parts: { type: "element" | "literal"; value: string }[] = useMemo(
		() =>
			// @ts-expect-error: Intl.ListFormat is not typed yet
			new Intl.ListFormat("fr", { style: "long", type: "conjunction" }).formatToParts(items),
		[items]
	)

	return (
		<>
			{parts.map((p, i) =>
				p.type === "element" ? <strong key={i}>{p.value}</strong> : <Fragment key={i}>{p.value}</Fragment>
			)}
		</>
	)
}
