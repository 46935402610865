// Based on https://docs.google.com/spreadsheets/d/1nU1BY8A8z12kmCu9i6wtg2NL4R9oZ4M3-zdyaDtT1Q4/edit#gid=0

export const productionUrl = [
	"app.audiowizard.fr",
	"app2.audiowizard.fr",
	"app3.audiowizard.fr",
	"doctolib.audiowizard.fr",
	"app.audiopourtous.fr",
	"app-audiowizard.vercel.app",
	"app.sonoly.fr",
	"https://audiowizard.netlify.app",
	"newapp.audiowizard.fr",
]

function getPP(location: string): string {
	// permet de se connecter en reseau local, test depuis une VM ou un appareil mobile.
	// ajouter la variable REACT_APP_LOCAL_IP aux variables d’env avec l’ip de l’ordinateur de dev
	if (process.env.REACT_APP_LOCAL_IP && location.includes(process.env.REACT_APP_LOCAL_IP)) {
		return `http://${process.env.REACT_APP_LOCAL_IP}:8000`
	}

	if (location.includes("https://cma-4363-fix-prescripteur-tdb--audiowizard.netlify.app/"))
		return "https://preprod6.audiowizard.fr"

	// Dev
	if (process.env.REACT_APP_DEV_LOCAL) return "http://localhost:8000"

	// Datamut feature branch 2023-09
	if (location.includes("datamut")) return "https://datamut.audiowizard.fr"

	if (location.includes("osmose-import")) return "https://api-demo.audiowizard.fr"

	// preprod 7
	if (location.includes("lva-api-270-merge-audio-column-into-slots")) return "https://preprod7.audiowizard.fr"

	// preprod 6
	if (location.includes("fjo-feat-5204-dashboard-settings--audiowizard")) {
		return "https://preprod10.audiowizard.fr"
	}
	// goroco
	if (location.includes("goroco")) return "https://goroco.audiowizard.fr"

	// Reserved preprod for Pastel, branch is "pastel-backend" (Sylvain - 2022-02-03)
	if (location.includes("pastel--audiowizard")) return "https://preprod.audiowizard.fr"

	// Reserved preprod for Main, branch is "main" (Florian)
	if (location.includes("main--audiowizard")) return "https://preprod3.audiowizard.fr"

	// Reserved preprod for AW Support, branch is "master" (Olivier - 2022-05-02)
	if (location.includes("audiowizard.netlify.app")) return "https://preprod3.audiowizard.fr"

	if (location.includes(".vercel.app")) return "https://preprod3.audiowizard.fr"

	return "https://preprod6.audiowizard.fr"
}

export function getApiUrl(): string {
	const location = document.location.href

	// Prod
	if (productionUrl.some((url) => location.includes(url))) return "https://api.audiowizard.fr"

	if (location.includes("demo.audiowizard.fr")) return "https://api-demo.audiowizard.fr"

	return getPP(location)
}
