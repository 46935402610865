import { LeftBar } from "awui"
import { useIsApt } from "components/Hooks/useIsApt"
import AuthContext, { ContextPatient } from "contexts/AuthContext"
import { useContext } from "react"
import FrillWidget from "components/frill/FrillWidget"

const { Link, Separator } = LeftBar

const UserLeftBar = (): JSX.Element => {
	const { companySettings, setPatient } = useContext(AuthContext)
	const isApt = useIsApt()

	return (
		<LeftBar>
			<Link to="/" icon="check-square" label="Tâches" />
			<Separator />
			{isApt && <Link to="/mes-rendezvous" icon="calendar-alt" label="Rendez-vous" />}
			{isApt && <Link to="/mes-patients" icon="user-md" label="Mes patients" />}
			{companySettings.createPatientOutsideDashboard && (
				<Link
					to="/premiere-visite/creation-patient"
					icon="user-plus"
					label="Nouveau patient"
					onClick={() =>
						// Reset the patient context to avoid any conflict because the patient context
						// Is also used to persist data when creating a new patient
						setPatient({} as ContextPatient)
					}
				/>
			)}
			<Link to="/tableau-de-bord" icon="users-medical" label="Tableau de bord" />
			<Separator />
			<Link to="/teletransmissions/controle" icon="sync-alt" label="Remboursements" />
			<Separator />
			<Link to="/bon-de-commande" icon="money-check" label="Bon de commande" />
			<Link to="/bon-de-livraison" icon="clipboard" label="Bon de livraison" />
			<Link to="/mon-stock/stock" icon="boxes" label="Stock" />
			<Link to="/sav" icon="headset" label="SAV" />
			<Separator />
			<Link to="/ventes/terminees" icon="shopping-basket" label="Ventes" />
			<Link to="/comptabilite/exports" icon="sack-dollar" label="Comptabilité" />
			<Link to="/caisse/validation" icon="cash-register" label="Caisse" />
			<Link to="/paiements/attente" icon="credit-card" label="Paiements" />
			<Separator />
			<Link to="/statistiques/metiers" icon="chart-line" label="Statistiques" />
			{/*
			 *Pages en cours de conception, à décommenter une fois que les composants sont créés (Les routeurs ont déjà étés faits)
			 */}
			{/* <Link to="/marketing/nouveau-patient" icon="plus" label="Marketing" /> */}
			{/* <Link to="/consommables/fournisseur-audio" icon="tasks" label="Consommables" /> */}

			{/*Load and render the Frill widget*/}
			<FrillWidget />
		</LeftBar>
	)
}

export default UserLeftBar
