import { Button, ButtonConfig } from "@bryntum/calendar"
import { AWCalendar } from "./AgendaTypes"

export default class ButtonSaveView extends Button {
	static get $name(): string {
		return "saveView"
	}

	static get type(): string {
		return "saveview"
	}

	static get configurable(): Partial<ButtonConfig> {
		return {
			cls: "topBarActionButton",
			text: "L{SaveView}",
			onClick() {
				// @ts-ignore
				const calendar = this.up("calendar") as AWCalendar
				calendar.trigger("saveView")
			},
		}
	}
}
