import _ from "lodash"
import { toast } from "react-toastify"
import lppList from "./list"
import { twentyYearsInDays, twoYearsInDays } from "datas/teletrans"

// pile BiOTONE => sivantos

/*
	Sonova
	Sivantos
	Biotone Technologie médicale
	Starkey
	Gn Hearing
	Bernafon
	Widex
	Prodition
*/

export const batteryLimit = {
	10: 10,
	312: 7,
	13: 5,
	675: 3,
}

const fournisseursWithLpp = [
	"GN HEARING",
	"MC USINAGES",
	"MEDEL",
	"PRODITION",
	"CDA",
	"SIVANTOS",
	"SONOVA",
	"STARKEY",
	"SUR-MESURE",
	"WIDEX",
]

const categoriesListWithLpp = [
	"ECOUTEUR",
	"ECOUTEUR AVEC SN",
	"EMBOUT REMBOURSE",
	"EMBOUT REMBOURSE AVEC SN",
	"ENTRETIEN ADHEAR",
	"EMBOUT AVEC SN",
	"ENTRETIEN",
	"REPARATION",
	"ANCRAGE OSSEUX",
	"ANCRAGE OSSEUX AVEC SN",
	"APPAREILS AUDITIFS",
	"PILES",
	"ACCESSOIRES IMPLANTS",
	"ACCESSOIRES IMPLANTS AVEC SN",
	"BATTERIES IMPLANTS",
	"PILES IMPLANTS",
]

const categoriesForReparation = ["MICROPHONE", "EMBOUT", "ECOUTEUR"]

function handleSanitize(lpp) {
	const tmp = { ...lpp }
	if (!tmp?.fournisseur) return tmp
	const supplier = tmp.fournisseur.toLocaleUpperCase()

	if (["BIOTONE TECH"].includes(supplier)) tmp.fournisseur = "Biotone Technologie médicale"
	return tmp
}

function getCorrectedLPPs() {
	const tmp = []
	const regexPile = new RegExp(/PILES *.+ (\d+)-*.+/)

	for (let lpp of lppList) {
		lpp = handleSanitize(lpp)
		if (
			lpp?.categorie === "AUDITIF" &&
			(lpp?.classe.includes("PILES") || lpp?.classe.includes("ECOUTEUR") || lpp?.classe.includes("MICROPHONE")) &&
			!lpp?.oreille
		) {
			let categorie = null
			let model = null

			categorie = partialFindInArray(lpp.classe, ["PILES", "ECOUTEUR", "MICROPHONE"])

			try {
				const matches = lpp?.classe.match(regexPile)
				model = matches.length === 2 ? matches[1] : null
			} catch (_void) {}
			tmp.push({
				categorie: categorie,
				description: lpp.classe,
				amount: lpp.amount,
				fournisseur: lpp.fournisseur,
				lpp: lpp.lpp,
				model,
				limit: batteryLimit[model] ?? null,
			})
		} else if (lpp?.categorie === "AUDITIF" && lpp?.classe.includes("EMBOUT")) {
			tmp.push({
				categorie: "EMBOUT",
				description: lpp.classe,
				amount: lpp.amount,
				fournisseur: lpp.fournisseur,
				lpp: lpp.lpp,
				age: lpp.age,
			})
		} else {
			tmp.push(lpp)
		}
	}
	return tmp
}

/**
 * Returns a data object for a product (including lpp and class). If the product is missing a necessary info,
 * a toast pops and an empty object is returned
 * @param {string} type
 * @param {object} product
 * @param {number} age
 * @param {object} suplData
 */

const energizerBatterySupplier = ["BIOTONE", "KRYS", "SCR ELECTRONIQUES", "CDA"]

const deburrUpperCase = (s) => {
	return _.deburr(s?.toUpperCase())
}

const safeCompare = (value, haystack) => {
	const deburrValue = _.deburr(value?.toUpperCase())

	if (Array.isArray(haystack)) {
		return haystack.some((comparedValue) => deburrValue.includes(_.deburr(comparedValue.toUpperCase())))
	}

	return deburrValue.includes(_.deburr(haystack?.toUpperCase()))
}

const partialFindInArray = (s, haystack) => {
	const deburrValue = _.deburr(s?.toUpperCase())

	return haystack.find((comparedValue) => deburrValue.includes(_.deburr(comparedValue.toUpperCase())))
}

const testProductIsEntretienOrReparation = (product) => {
	const productModel = deburrUpperCase(product.product_model)
	if (/ENTRETIEN/.test(productModel) || /REPARATION/.test(productModel)) return true
	const categorieName = deburrUpperCase(product.category_name)
	if (/ENTRETIEN/.test(categorieName) || /REPARATION/.test(categorieName)) return true
	return ["ENTRETIEN", "REPARATION"].includes(categorieName)
}

const getLppForEntretien = (product, lpps, ageKey) => {
	const supplierName =
		partialFindInArray(product.product_model, fournisseursWithLpp) || deburrUpperCase(product.supplier_name)
	const categorie =
		partialFindInArray(product.product_model, categoriesForReparation) || deburrUpperCase(product.category_name)
	if (supplierName && ageKey) {
		const preciseLpp = lpps.find(
			(lpp) =>
				lpp.fournisseur?.includes(supplierName) &&
				lpp.description?.includes("ENTRET") &&
				(!lpp.age || lpp.age === ageKey) &&
				lpp.description?.includes(categorie)
		)
		if (preciseLpp) return preciseLpp
	}

	return lpps.find((lpp) => lpp.classe?.includes(deburrUpperCase(product.product_model)))
}

const lpps = getCorrectedLPPs()

/**
 * Returns a data object for a product (including lpp and class). If the product is missing a necessary info,
 * a toast pops and an empty object is returned
 * @param {string} type
 * @param {object} product
 * @param {number} age age of patient in days
 * @param {object} suplData
 */
export function getProductLPP(type, product, age, suplData = {}) {
	if (!product?.product_id || !product?.product_model) return null
	let lpp = null

	if (product?.category_name) product.category_name = product.category_name.trim()

	// fill "product.category_name" if it's empty
	if (!product?.category_name && suplData?.categories && product?.product_category) {
		const category = suplData.categories.find((f) => f.category_id === product.product_category)
		product.category_name = category?.category_name ?? "Catégorie Inconnu"
	}

	const upperCasedCategoryName = product?.category_name?.toUpperCase() ?? ""

	// LPP is only available for some categories
	if (!categoriesListWithLpp.includes(upperCasedCategoryName)) return null

	if (!product?.class && suplData?.tags && product?.tags?.length) {
		const tag = suplData.tags.find((f) => f.tag_cat.includes("class"))
		const tag_class = tag.tag_details.find((t) =>
			product.tags.find(
				(p) => (p.tag_id ? +p.tag_id === +t.tag_id : 0) || (p.tag_cat ? +p.tag_cat === +t.tag_cat : 0)
			)
		)
		product.class = tag_class?.tag
	}
	if (!product?.supplier_name && suplData?.suppliers && product?.product_supplier) {
		const supplier = suplData.suppliers.find((f) => f.supplier_id === product?.product_supplier)
		product.supplier_name = supplier?.supplier_name ?? "Fournisseur Inconnu"
	}

	if (product?.supplier_name === "CDA") product.supplier_name = "PRODITION"

	if (["Appareils auditifs"].includes(product.category_name)) type = "AUDITIF"
	if (["Ancrage osseux", "Ancrage osseux avec SN"].includes(product.category_name)) type = "ANCRAGE OSSEUX"
	if (["Embout rembourse", "Embout avec SN"].includes(product.category_name)) type = "EMBOUT"
	if (["Ecouteur", "Ecouteur avec SN"].includes(product.category_name)) type = "ECOUTEUR"
	if (testProductIsEntretienOrReparation(product)) type = "ENTRETIEN"
	if (product.category_name === "Piles") type = "PILE"

	if (type === "AUDITIF" && product.cmu?.label === "C2S") {
		product.class = "2"
	}

	let ageKey = (function () {
		if (type === "EMBOUT" || type === "ENTRETIEN") {
			if (age !== 0 && age < twoYearsInDays) return "<2"
			if (age >= twoYearsInDays && age < twentyYearsInDays) return ">=2 && <20"
			if (age === 0 || age >= twentyYearsInDays) return ">=20"
		} else {
			if (age !== 0 && age <= twentyYearsInDays) return "<=20"
			if (age === 0 || age > twentyYearsInDays) return ">20"
		}
		return -1
	})()

	// Fix temporaire rapide, refacto en cours avec Olivier
	// ça arrive, promis
	const checkFournisseurTmpFix = (lpp) => {
		let fournisseursArr = new Set()
		const supplierName = product?.supplier_name

		if (
			type === "AUDITIF" &&
			_.deburr(product?.supplier_name?.toUpperCase()).includes("BIOTONE TECHNOLOGI") &&
			lpp?.fournisseur === "Biotone Technologie médicale"
		) {
			return true
		}

		for (const lpp of lpps) {
			if (safeCompare(lpp?.description, "PILES")) {
				fournisseursArr.add(lpp.fournisseur)
			}
		}

		if (type === "PILE") {
			if (safeCompare(supplierName, energizerBatterySupplier)) {
				return safeCompare(lpp?.fournisseur, "ENERGIZER")
			}
			if (safeCompare(supplierName, "PHONAK")) {
				return safeCompare(lpp?.fournisseur, "SONOVA")
			}
			if (safeCompare(supplierName, "NEWSON")) {
				return safeCompare(lpp?.fournisseur, "VARTA")
			}
			if (safeCompare(supplierName, "RENETA")) {
				return safeCompare(lpp?.fournisseur, "SWATCH GROUP")
			}
			if (safeCompare(supplierName, "RAYOVAC")) {
				return safeCompare(lpp?.fournisseur, "SWATCH GROUP")
			}
			for (const val of fournisseursArr) {
				if (safeCompare(supplierName, val)) {
					return safeCompare(lpp?.fournisseur, val)
				}
			}
		}

		return safeCompare(lpp?.fournisseur, product?.supplier_name)
	}

	// Temporaire, nous allons refacto Soon

	const findBattery = (f) => {
		// Medel battery has no power (10, 13, 312, 675) model, therefore we need this different comparison
		if (product.supplier_name.toUpperCase() === "MEDEL") return product.product_model.includes(f?.description)
		return product.product_model.includes(f?.model)
	}

	const isTailored = () => {
		let supplierName = product?.supplier_name?.toLowerCase()
		return (
			(product.product_model?.toLowerCase()?.includes("sur") &&
				product.product_model?.toLowerCase()?.includes("mesure")) ||
			!lpps.find((e) => e?.fournisseur?.toLowerCase()?.includes(supplierName))
		)
	}

	const EmboutSurMesure = (f) => {
		let supplierName = product?.supplier_name?.toLowerCase()
		if (isTailored()) {
			supplierName = "sur-mesure"
		}
		return f?.fournisseur?.toLowerCase().includes(supplierName)
	}

	if (upperCasedCategoryName.includes("IMPLANT")) {
		return lpps.find((f) => f.categorie === upperCasedCategoryName)
	} else if (type === "EMBOUT") {
		const foundLpp = lpps.find(
			(f) => f.categorie.includes(type?.toUpperCase()) && f?.age === ageKey && EmboutSurMesure(f)
		)
		if (foundLpp && isTailored()) {
			foundLpp.new_supplier_name = "SUR-MESURE"
			foundLpp.supplier_name = "SUR-MESURE"
		}
		return foundLpp
	} else if (["ANCRAGE OSSEUX"].includes(type)) {
		return lpps.find((lpp) => lpp.classe?.includes(product.product_model.toUpperCase()))
	} else if (["ENTRETIEN", "REPARATION"].includes(type)) {
		return getLppForEntretien(product, lpps, ageKey)
	} else {
		lpp = lpps.find(
			(f) =>
				type &&
				f &&
				f?.categorie?.includes(type?.toUpperCase()) &&
				checkFournisseurTmpFix(f) &&
				(type === "AUDITIF" ? +f?.classe === +product.class : 1) &&
				(type === "AUDITIF" ? f?.oreille === product.ear : 1) &&
				(type === "AUDITIF" ? f?.age === ageKey : 1) &&
				(type === "PILE" ? findBattery(f) : 1) &&
				(type === "ECOUTEUR"
					? f?.fournisseur?.toUpperCase().includes(product?.supplier_name?.toUpperCase())
					: 1)
		)
	}

	if (!lpp) {
		toast.error(`Pas de code lpp trouvé pour le produit ${product?.product_model} de ${product?.supplier_name}`, {
			toastId: product?.product_model,
		})
		console.error(
			"Debug LPP ",
			product?.category_name,
			product?.class,
			product.ear,
			product?.supplier_name,
			suplData
		)
	}

	// dans la modale de télétransmission il peut arriver que l’on modifie le montant (cas dom/tom),
	// le clone permet de ne pas modifier le montant de lppList
	return _.clone(lpp)
}
