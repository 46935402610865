export const Colors = {
	primaryColor: "#18bc9c",
	infoColor: "#18ABBC",
	successColor: "#18bc9c",
	warningColor: "#ffab00",
	errorColor: "#ff5630",
	disabled: "#93939C",
	textOnDark: "white",
	textOnLight: "#444",
}

export const borderRadius = 15

export const Spacing = {
	regular: 15,
	small: 12,
}

export const FontSize = {
	badge: 14,
}

export const FontFamilies = {
	standard: "'Nunito', sans-serif",
}
