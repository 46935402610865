/* eslint-disable react-hooks/exhaustive-deps */
import useCustomTitle from "components/Hooks/useTitle"
import AgeGender from "./AgeGender"
import Attentes from "./Attentes"
import BoucheAOreille from "./BoucheAOreille"
import ClassementORL from "./ClassementORL"
import NombrePatient from "./NombrePatient"
import Origines from "./Origines"
import PathoMedicale from "./PathoMedicale"
import PathoORL from "./PathoORL"
import PatientGenes from "./PatientGenes"
import RelanceStatus from "./RelanceStatus"
import TypeRendezVous from "./TypeRendezVous"

const Statistiques = () => {
	useCustomTitle("Statistiques | Statistiques métier")
	return (
		<div className="row statistiques-row">
			<div className="col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<NombrePatient />
					</div>
				</div>
			</div>

			<div className="col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<Attentes />
					</div>
				</div>
			</div>

			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<TypeRendezVous />
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<RelanceStatus />
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<Origines />
					</div>
				</div>
			</div>

			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<BoucheAOreille />
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<ClassementORL />
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<PatientGenes />
					</div>
				</div>
			</div>

			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<PathoMedicale />
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<PathoORL />
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-md-6 col-xs-12 p-0">
				<div className="card card-fluid m-1">
					<div className="card-body text-center d-flex flex-column">
						<AgeGender />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Statistiques
