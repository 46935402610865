import { ScheduleType } from "@audiowizard/common"
import { Input, Select, Space, Spin, Switch } from "antd"
import ButtonRounded from "components/Buttons/ButtonRounded"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import useHasRole from "components/Hooks/useHasRole"
import { useIsApt } from "components/Hooks/useIsApt"
import AuthContext from "contexts/AuthContext"
import { getColor } from "pages/Schedules/getScheduleType"
import { useContext, useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import API from "services/API"
import { partialSearch } from "services/functions"
import "./SecondaryStatus.scss"
import SecondaryStatusSettingsComponent from "./SecondaryStatusComponent"
import { Col } from "reactstrap"
const { Option } = Select

function FilterTagsSwitch(): JSX.Element {
	const queryClient = useQueryClient()
	const isApt = useIsApt()
	const { companySettings } = useContext(AuthContext)

	const { mutate: updateSettings, isLoading } = useMutation(
		async (data: Record<string, unknown>) => await API.update("COMPANY_SETTINGS_API", companySettings.id!, data),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("COMPANY_SETTINGS")
			},
			onError: () => {
				toast.error("Erreur lors de la mise à jour des paramètres.")
			},
		}
	)

	return (
		<label>
			<Switch
				id="filterSettingSwitch"
				className="mr-2 ml-2"
				disabled={isLoading}
				checked={companySettings.filterTagsByScheduleStatus}
				onChange={(checked: boolean) => updateSettings({ filterTagsByScheduleStatus: checked })}
			/>
			<strong>Tableau de bord :&nbsp;</strong>
			Filter les {isApt ? "statuts" : "tags"} par statut du prochain rendez-vous du patient
		</label>
	)
}

interface SecondaryStatus {
	text?: string
	color?: string
	id?: number
	scheduleTypes?: string[]
	"@id"?: string
}

const SPSelect = (scheduleTypesList: ScheduleType[] | undefined): (JSX.Element | undefined)[] => {
	if (!(scheduleTypesList instanceof Array)) return []

	return scheduleTypesList.map((v: ScheduleType, k) => {
		return (
			<Option
				data-label={v.label} // must with "data-" to avoid conflits with html attributes
				value={v["@id"]}
				key={k}
				className="option-content">
				<div className="settings-status-card-flex schedule-line-option">
					<div
						className="option-color"
						style={{ backgroundColor: getColor(v.backgroundColor || 0, v.scheduleStatus || "") }}
					/>
					<div className="option-label">{v?.label}</div>
				</div>
			</Option>
		)
	})
}

const SecondaryStatusSettings = (): JSX.Element => {
	const queryClient = useQueryClient()
	const isApt = useIsApt()
	const [name, setName] = useState("")
	const [filteredSP, setFilteredSP] = useState("")
	const [scheduleType, setScheduleType] = useState<string | undefined>("")
	const [value, setValue] = useState("")
	const [showAllSS, setShowAllSS] = useState(false)
	const [data, setData] = useState<SecondaryStatus[]>([])
	const isManager = useHasRole("ROLE_MANAGER")

	const { data: fetchedSS, isLoading } = useQuery<SecondaryStatus[]>(
		["PATIENT_TAGS_API"],
		() => API.findAll("PATIENT_TAGS_API"),
		{
			staleTime: Infinity,
		}
	)

	const { data: fetchedSchedulesTypesList } = useQuery<ScheduleType[]>(
		["SCHEDULE_TYPES"],
		() => API.findAll("SCHEDULE_TYPES"),
		{
			staleTime: Infinity,
		}
	)
	const schedulesTypes = useMemo(() => {
		if (fetchedSchedulesTypesList == null) return []

		return Object.values(fetchedSchedulesTypesList)
	}, [fetchedSchedulesTypesList])

	const { mutate: createStatus, isLoading: isLoadingCreateStatus } = useMutation(
		(newStatus: { text: string; color: string; scheduleTypes: string[] }) =>
			API.create("PATIENT_TAGS_API", newStatus),
		{
			onSuccess: () => queryClient.invalidateQueries(["PATIENT_TAGS_API"]),
			onError: () => {
				toast.error(`Erreur lors de la création des ${isApt ? "statuts secondaire" : "tags"}`)
			},
		}
	)

	useEffect(() => {
		if (fetchedSS == null) return
		setData(fetchedSS)
	}, [fetchedSS])

	useEffect(() => {
		if (fetchedSS != null && value === "" && filteredSP === "") {
			setData(fetchedSS)
			return
		}

		const valueToFilter = value !== "" ? value : filteredSP
		const filtered: SecondaryStatus[] = []
		fetchedSS?.forEach((currentValue) => {
			const data =
				value !== ""
					? currentValue.text?.toLowerCase()
					: currentValue.scheduleTypes?.map((val) => val.toLowerCase())
			if (data && data.indexOf(valueToFilter.toLowerCase()) >= 0) filtered.push(currentValue)
		})
		setData(filtered)
	}, [value, filteredSP])

	return (
		<Col>
			<SectionHeader title="Editer vos statuts et filtres" />
			<Space direction="vertical" className="w-100 settings-status">
				<FilterTagsSwitch />
				<div className="row mb-4">
					<div className="col-3">
						<input
							disabled={!isManager}
							type="text"
							className="form-control"
							placeholder={`Nom du ${isApt ? "Statut Secondaire" : "Tag"}`}
							name="label"
							onChange={(e) => setName(e.target.value)}
							value={name}
						/>
					</div>
					<div className="col-3">
						<Select
							showSearch
							placeholder="Statut principal"
							style={{ width: "100%", marginTop: "4px" }}
							size="large"
							onChange={(value) => setScheduleType(value)}
							value={scheduleType !== "" ? scheduleType : undefined}
							filterOption={(search, option) => partialSearch(option!["data-label"], search)}>
							{SPSelect(schedulesTypes)}
						</Select>
					</div>
					<div className="col-3">
						<ButtonRounded
							icon="fa-plus"
							type="button"
							color="primary-outlined"
							onClick={() => {
								if (!name || name === "" || !scheduleType || scheduleType === "") return

								createStatus({ text: name, color: "#911b80", scheduleTypes: [scheduleType] })
								setName("")
								setScheduleType("")
							}}>
							Ajouter
						</ButtonRounded>
					</div>
				</div>
				<SectionHeader title="Liste des filtres" />
				<div className="row mb-4 settings-status-filter">
					<div className="col-3">
						<Input
							type="text"
							className="form-control w-100 mr-5"
							placeholder={`Nom du ${isApt ? "Statut Secondaire" : "Tag"}`}
							name="label"
							onChange={(e) => setValue(e.target.value)}
							value={value}
						/>
					</div>
					<div className="col-3">
						<Select
							showSearch
							allowClear
							placeholder="Statut principal"
							style={{ width: "100%", marginTop: "4px" }}
							maxTagCount="responsive"
							onChange={(e) => setFilteredSP(e?.toString() ?? "")}
							size="large"
							filterOption={(search, option) => partialSearch(option!["data-label"], search)}>
							{SPSelect(schedulesTypes)}
						</Select>
					</div>
				</div>
				<div
					style={{ maxHeight: showAllSS ? "10000px" : "290px" }}
					className="pl-3 row settings-status-card-group">
					{!fetchedSS || isLoadingCreateStatus || isLoading ? (
						<Spin size="large" className="settings-statusLoader" />
					) : (
						data.map((item) => (
							<SecondaryStatusSettingsComponent
								key={item.id}
								item={item}
								schedulesTypes={schedulesTypes}
								SPSelect={SPSelect}
							/>
						))
					)}
				</div>
				{data && data.length > 16 && (
					<div className="settings-status-filter">
						<button className="ml-auto mr-auto  btn-primary btn" onClick={() => setShowAllSS(!showAllSS)}>
							<span>{showAllSS ? "Voir moins" : "Voir plus"}</span>
						</button>
					</div>
				)}
			</Space>
		</Col>
	)
}
export default SecondaryStatusSettings
