import axios from "axios"
import { garanties } from "./garantiesFSV"
import API_FSV from "./api"
import {
	parseCV,
	parseCPS,
	parseGed,
	GenerateJuxtaXML,
	hasTeletransService,
	findSpecialAmcRegimen,
	findActiveAMx,
	isTeletransInvoiceNotDuplicate,
} from "./utilsFSV"
import { FSV_WEBSERVICES_API, GED_WEBSERVICES_API, FSV_GET_TOKEN_API } from "../../config"
import { createFactureDeSuivi, modifyFactureDeSuivi } from "./FacturationSuivi"
import API from "../../services/API"
import { notification } from "antd"
import { confirmWithModal } from "../../components/effects/ConfirmModalFunction"
import { scorSuivi } from "./docSuiviBase64.json"
import { handledAmcs } from "datas/teletrans"
import parser from "xml-js"
import { toast } from "react-toastify"

import dayjs from "dayjs"
import { IsObjectEmpty, removeSpecialChars } from "services/functions"
import { modeTeletrans } from "./FSV.type"

const PROD_DOMAINS = ["audiowizard.fr", "app.audiopourtous.fr", "app.sonoly.fr"]

let lecteurDoubleFente = false
let port = false

export const soapParsed = (soap) => parser.xml2json(soap, { compact: false, spaces: 4 })
export const jsonParsed = (json) => parser.json2xml(json, { compact: true, ignoreComment: true, spaces: 4 })

const setDateForFSV = (date) => {
	if (!date) {
		return ""
	} else {
		return dayjs(date).format("YYYYMMDD")
	}
}

const today = dayjs().format("YYYYMMDD")

//
// MODE SILENT
//

const reLaunchJuxtaLinkMessage = (
	<>
		<p className="mt-2">
			Veuillez double-cliquer sur l'icône JuxtaLink sur le bureau, puis vous déconnecter et reconnecter sur{" "}
			<img
				src="/static/img/brand/favicon-audiowizard.ico"
				className="mb-1 mr-1"
				style={{ width: "5%" }}
				alt="AudioWizard"
			/>
			<strong>AudioWizard</strong> .
		</p>
	</>
)

const fsvMessagesForHumans = [
	{
		fsvMessage: "Le champ rppsPresc doit être exclusivement composé de dix caractères numériques",
		humanMessage: "Les données rpps du prescripteur sont erronées.",
	},
	{
		fsvMessage: "Le couple numéro de facturation / numéro RPPS est inconnu ",
		humanMessage:
			"Vos données rpps et finess sont inconnues pour FSV. Veuillez vérifier ces données manuellement ou relire la carte CPS.",
	},
	{
		fsvMessage: "La clé de Sécurité Sociale est invalide",
		humanMessage: "La clé du numéro de sécurité sociale du patient est invalide",
	},
	{
		fsvMessage: "Aucun statut trouvé pour le couple Finess/Numéro National.",
		humanMessage: (
			<p>
				Vérifiez si vos données rpps et finess dans <strong>Mes Informations</strong> sont bonnes. Dans le cas
				contraire, veuillez lire votre CPS.
			</p>
		),
	},
	{
		fsvMessage: "Impossible de trouver une AMO correspondant au contexte de facturation.",
		humanMessage: (
			<div>
				<p>
					L'amo n'existe pas ou les dates de l'amo ne couvrent pas toutes les dates de la facture (date de
					l'ordonnance, date de mise en essai et date de facturation).
				</p>
				<p>Veuillez faire un appel ADRi pour faire la télétransmission.</p>
				<p>Si le problème persiste veuillez contacter le support.</p>
			</div>
		),
	},
	{
		fsvMessage: "Le serveur de token ne répond pas (1100);Le serveur d'update ne répond pas (1200)",
		humanMessage: reLaunchJuxtaLinkMessage,
	},
	{
		fsvMessage: "Impossible de faire du hors TP AMC avec une convention TP.",
		humanMessage: (
			<p>
				Veuillez supprimer l'amc sur AudioWizard et recommencer la télétransmission. Si le problème persiste
				contactez le support.
			</p>
		),
	},
	{
		fsvMessage: "Impossible de retrouver le praticien à l'identifiant",
		humanMessage: (
			<div>
				<p>Le rpps et le finess envoyés dans la création de FSE ne sont pas les mêmes qu'en carte CPS.</p>
				<p>
					Veuillez relire la carte CPS dans <strong>Mes Informations</strong> et relancer la création de FSE.
				</p>
			</div>
		),
	},
]

const isErrorNoCV = (fsvError) => {
	return fsvError.code === "F011"
}

const fsvErrorCodes = [
	{
		code: "F003",
		text: (
			<>
				Le <strong>code CPS</strong> est erroné. Attention, au bout du troisième essai échoué, votre carte sera
				bloquée
			</>
		),
		action: () => window.localStorage.removeItem(btoa("CPSPWD")),
	},
	{
		code: "F011",
		text: (
			<>
				La <strong>carte vitale</strong> est absente du lecteur
			</>
		),
	},
	{
		code: "F001" || "61441",
		text: (
			<>
				La <strong>carte CPS</strong> est absente du lecteur
			</>
		),
		action: () => window.localStorage.removeItem(btoa("CPSPWD")),
	},
	{
		code: "F101",
		text: (
			<>
				Assurez-vous d'avoir inséré les cartes <strong>CPS et vitale</strong> dans le lecteur
			</>
		),
	},
	{
		code: "F023",
		text: <>La carte vitale insérée n'est pas la bonne, veuillez insérer la carte vitale du patient</>,
	},
	{
		code: "F024",
		text: <>La carte CPS dans le lecteur ne correspond pas aux informations du PS dans la facture</>,
		action: () => window.localStorage.removeItem(btoa("CPSPWD")),
	},
	{
		code: "61443",
		text: (
			<>
				Le code porteur CPS est invalide. Attention, au bout du troisième essai échoué, votre carte sera bloquée
			</>
		),
		action: () => window.localStorage.removeItem(btoa("CPSPWD")),
		hideConfirmButton: true,
	},
]

const handleJuxtaLinkError = async (
	response,
	okLabelText = null,
	hideCancelButton = false,
	hideConfirmButton = true
) => {
	const code = response?.Arguments?.code || response?.CodeRetour || response?.code || null
	const message = response?.message
	const knownMessage = fsvMessagesForHumans.find((msg) => message?.includes(msg.fsvMessage))
	const knownCode = fsvErrorCodes.find((err) => code === err.code)

	const error = {
		title: (
			<>
				<i className="fad fa-exclamation-circle" /> Erreur
			</>
		),
		text: (
			<>
				<div className="text-left">
					<div className="alert alert-secondary mb-1 mt-1 p-2">
						{decodeURIComponent(escape(response?.Message || response?.MessageRetour))}
					</div>
					{response?.libelleErreur && (
						<div className="alert alert-secondary mb-1 mt-1 p-2">
							{decodeURIComponent(escape(response?.libelleErreur))}
						</div>
					)}
					{response?.Arguments?.diagnostic && (
						<div className="alert alert-secondary mb-1 mt-1 p-2">
							{decodeURIComponent(escape(response?.Arguments?.diagnostic))}
						</div>
					)}
					<div className="alert alert-warning mt-3">
						<strong>
							Le code d'erreur suivant peut être transmis au support afin d'accélérer le dépannage
						</strong>
						<hr />
						<textarea className="juxtalink-error-textarea" defaultValue={btoa(JSON.stringify(response))} />
						<button
							type="button"
							className="btn btn-sm btn-block btn-warning"
							onClick={() => {
								document.querySelector(".juxtalink-error-textarea").select()
								document.execCommand("copy")
							}}>
							Copier le code d'erreur
						</button>
					</div>
				</div>
			</>
		),
		code,
	}

	if (knownCode) {
		error.text = knownCode.text
		if (knownCode.hideConfirmButton) hideConfirmButton = knownCode.hideConfirmButton
		if (knownCode.action) knownCode.action()
	} else if (knownMessage) {
		error.text = knownMessage.humanMessage
		hideCancelButton = false
	} else if (response?.url?.includes("localhost:false")) {
		error.title = (
			<>
				<i className="fad fa-exclamation-circle" /> JuxtaLink n'est pas joignable.
			</>
		)
		error.text = reLaunchJuxtaLinkMessage
	} else {
		hideConfirmButton = false
		error.title = (
			<>
				<i className="fad fa-exclamation-circle" /> Erreur inconnue
			</>
		)
	}

	return await confirmWithModal({
		title: error.title,
		text: error.text,
		okLabel: okLabelText || "Réessayer",
		cancelLabel: "Annuler",
		hideCancel: hideCancelButton,
		hideConfirm: hideConfirmButton,
	})
}

const getJuxtaLinkToken2 = async () => {
	try {
		const drcEndpoint = await axios.get(FSV_GET_TOKEN_API)
		return drcEndpoint?.data
	} catch (error) {
		console.error(error)
		return null
	}
}

const validePasswordPCS = (password) => {
	return password && password.length === 4
}

async function callJuxtaLink(token, fn, data = null) {
	const itemPassword = window.localStorage.getItem(btoa("CPSPWD"))
	// il faudrait garder en mémoire l’id de l’user avec le mot de passe, pour ne pas utiliser le mot de passe d’un autre utilisateur

	let password = itemPassword ? atob(itemPassword) : null
	let hasConfirmed = true
	// pour ne pas créer une fonction dans une boucle
	function setPassword(e) {
		password = e.target.value
	}

	if (!validePasswordPCS(password) && fn !== "ModePss")
		while (!password && hasConfirmed) {
			hasConfirmed = await confirmWithModal({
				title: "Mot de passe",
				text: (
					<div className="text-left">
						<div className="font-weight-bold mb-3">Veuillez entrer le mot de passe de votre carte CPS</div>
						<div>
							<input
								type="password"
								name="cpspwd"
								autoComplete="off"
								className="form-control input-sm"
								onChange={setPassword}
								placeholder="Mot de passe"
							/>
						</div>
						<div className="alert alert-warning mt-4">
							Attention,{" "}
							<b>votre carte sera automatiquement bloquée au bout de trois tentatives échouées</b>, vous
							devrez alors vous munir du code de déblocage afin d'en récupérer l'accès.
						</div>
					</div>
				),
				okLabel: "Continuer",
				cancelLabel: "Annuler",
			})
			if (hasConfirmed && !validePasswordPCS(password)) {
				toast.error("Le mot de passe de la carte CPS doit être de 4 caractères")
				password = ""
			}
			// sinon on peut annuler et la télétransmission se valide
			if (!hasConfirmed) password = ""
		}

	if (fn !== "ModePss" && (!password || password.length !== 4)) {
		throw new Error("Lecture impossible, mot de passe non renseigné ou de longueur invalide")
	}

	if (password && hasConfirmed) window.localStorage.setItem(btoa("CPSPWD"), btoa(password))

	let result = null
	let parameters = {
		modeHomologue: lecteurDoubleFente, // Type de lecteur, false pour PCSC
		presenceCps: true, // Pour test de présence de la CPS
		presenceVitale: true, // Pour test de présence de la Vitale
		codeCPS: password, // Code CPS
		formatdesortie: "json",
		data,
	}

	const url = (function () {
		if (data?.idCompany === 91) {
			return fn === "FacturerSilent"
				? process.env.REACT_APP_FSV_FACT_AULNAY
				: process.env.REACT_APP_FSV_ADR_AULNAY
		}
		if (PROD_DOMAINS.some((domain) => document.location.host.includes(domain))) {
			return fn === "FacturerSilent" ? process.env.REACT_APP_FSV_FACT_PROD : process.env.REACT_APP_FSV_ADR
		} else return fn === "FacturerSilent" ? process.env.REACT_APP_FSV_FACT_TEST : process.env.REACT_APP_FSV_ADR_TEST
	})()

	if (fn === "FacturerSilent") {
		console.log({ [fn]: data })
		parameters = {
			presenceCps: true,
			modeHomologue: lecteurDoubleFente,
			codeCPS: password,
			formatdesortie: "json",
			idFacture: data.idFacture,
			finess: data.finess,
			numNatPs: data.numNatPs,
			url,
		}
	} else if (fn === "patientAdrSilent") {
		console.log({ [fn]: data })
		parameters = {
			url,
			idPatient: data.idFSV,
			finess: data.finess,
			numNatPs: data.numNatPs,
			modeHomologue: lecteurDoubleFente, // Type de lecteur, false pour PCSC
			codeCPS: password, // Code CPS
		}
	}

	const parserSelector = (fn, data) => {
		const options = {
			LireCartePS: () => parseCPS(data),
			LireDroitsVitale: () => parseCV(data),
			FacturerSilent: () => {
				return data
			},
			patientAdrSilent: () => console.log(data),
			ModePss: () => data,
			TestPresenceCarte: () => data,
		}
		return options[fn]()
	}

	/**
	 * ! TEMPORARY PARSER FOR ADRi REPONSE !
	 * We are waiting for Juxta's help to solve this.
	 * @param {string} decodedResponse
	 */
	const patientAdrSilentParser = (decodedResponse, resolve, reject) => {
		if (decodedResponse.includes("diagnostic")) {
			reject(0)
		} else if (decodedResponse.includes("OK")) return resolve(decodedResponse)
		else toast.error("Le service ADRi est indisponible pour le moment.")
	}

	const callJuxta = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				token = await axios.get(FSV_GET_TOKEN_API)
				token = token?.data
			} catch (error) {
				console.error(error)
				reject(0)
			}
			global.$.juxtalink({
				host: `https://localhost:${port}`,
				plugin: "SSV",
				action: fn,
				versionmin: "1.8.0.3",
				token,
				parameters,
				success: async function (res) {
					console.log({ juxtaResDecoded: atob(res), fn: fn })
					if (fn === "patientAdrSilent") patientAdrSilentParser(atob(res), resolve, reject)
					else
						try {
							res = JSON.parse(atob(res))
							if (res?.Error === true || (res?.CodeRetour && res?.CodeRetour !== "0")) {
								if (!(await handleJuxtaLinkError(res))) reject(0)
								else reject(-1)
							} else {
								if (fn === "FacturerSilent") resolve(res.XmlRetour)
								if (fn === "TestPresenceCarte") {
									resolve({
										cartePsPresente: res.CartePsPresente,
										carteVitalePresente: res.CarteVitalePresente,
									})
								}
								resolve({ result: parserSelector(fn, res.XmlRetour), xml: res?.XmlRetour })
							}
						} catch (error) {
							console.error(error)
							alert("Another type of error happened")
						}
				},
				error: async function (res) {
					console.error("ALLO", res)
					if (!(await handleJuxtaLinkError(res))) reject(0)
					else reject(-1)
				},
			})
		})
	}

	const maxRetry = 3
	for (let retry = 0; retry < maxRetry; retry++) {
		try {
			result = await callJuxta(retry)
			return result
		} catch (error) {
			if (error === 0) throw result
		}
	}
}

/**
 * Recovers type of card reader (single or multiple cards)
 */
const getLecteurMode = async () => {
	let detectorResult

	try {
		const token2 = await getJuxtaLinkToken2()
		detectorResult = await callJuxtaLink(token2, "ModePss")
		lecteurDoubleFente = detectorResult?.result === "true" ? true : false
	} catch (error) {
		error && console.error(error)
		throw error
	}
}

const getJuxtaLinkToken = async (action, data) => {
	try {
		const drcEndpoint = await axios.get(FSV_GET_TOKEN_API)
		await getLecteurMode()
		return await callJuxtaLink(drcEndpoint.data, action, data)
	} catch (error) {
		error && console.error(error)
		throw error
	}
}

const patientAdrSilent = async (data) => {
	return await getJuxtaLinkToken("patientAdrSilent", data)
}

const readCarteVitale = async () => {
	return await getJuxtaLinkToken("LireDroitsVitale")
}

const readCPS = async () => {
	return await getJuxtaLinkToken("LireCartePS")
}

const facturerSilent = async (data) => {
	return await getJuxtaLinkToken("FacturerSilent", data)
}

const testPresenceCarte = async () => {
	return await getJuxtaLinkToken("TestPresenceCarte")
}

window._jqjsp2 = (a) => {
	window.dispatchEvent(new CustomEvent("juxta-response", { detail: atob(a) }))
}

window._nullcallback = () => void 0

const awaitJuxtaLinkResponse = async () => {
	return new Promise((resolve) => {
		window.addEventListener("juxta-response", function _listener(res) {
			resolve(res.detail)
			window.removeEventListener("juxta-response", _listener)
		})
	})
}

const getJuxtaLinkPort = async () => {
	const testReport = {
		data: [],
		add(record) {
			this.data.push(record)
		},
		get() {
			return this.data
		},
	}

	const testPort = (port) => {
		return new Promise((resolve, reject) => {
			const headElement = document.querySelector("body")
			const scriptElement = document.createElement("script")
			headElement.appendChild(scriptElement)
			scriptElement.src = `https://localhost:${port}?callback=_nullcallback`
			scriptElement.onload = function () {
				scriptElement.remove()
				resolve(port)
			}
			scriptElement.onerror = function () {
				scriptElement.remove()

				reject("Juxta is not started.")
			}
		})
	}

	try {
		await testPort(1234)

		testReport.add({
			port: 1234,
			status: "OK",
		})

		return 1234
	} catch (error) {
		testReport.add({
			port: 1234,
			status: error,
		})

		for (let i = 1230; i <= 1240; i++) {
			if (i === 1234) continue

			try {
				await testPort(i)

				testReport.add({
					port: i,
					status: "OK",
				})

				return i
			} catch (error) {
				testReport.add({
					port: i,
					status: error,
				})
			}
		}
		return false
	} finally {
		console.table(testReport.get())
	}
}

const etatJuxtaLink = async (subscriptions) => {
	if (!hasTeletransService(subscriptions)) return
	try {
		port = await getJuxtaLinkPort()
		if (port === "false") throw new Error("JuxtaLink n'a été détecté sur aucun port.")

		const token = await getJuxtaLinkToken2()

		const headElement = document.querySelector("body")
		const scriptElement = document.createElement("script")
		scriptElement.async = true
		headElement.appendChild(scriptElement)
		scriptElement.src = `https://localhost:${port}?action=etat&token=${token}&callback=_jqjsp2`

		scriptElement.onload = function () {
			notification.success({
				message: "Succès",
				description: "La connexion avec le service JuxtaLink est établie",
				top: 90,
			})
		}
		await awaitJuxtaLinkResponse()
		scriptElement.remove()
	} catch (error) {
		console.error(error)
	}
}

const transmettreXml = async (xml, user, laboratory, patientIndex, idFSV = null) => {
	const { adeli } = user
	const { finess } = laboratory
	const idPatient = idFSV

	const cutXml = (xml, regex) => {
		let obj = {}
		let match
		regex = new RegExp(regex)

		while ((match = regex.exec(xml))) {
			if (!obj[match[0]]) obj[match[0]] = []
			obj[match[0]].push(match.index)
		}

		return obj
	}

	// L'envoi des informations globales est nécessaire
	const headerStart = cutXml(xml, /<export>/g)["<export>"][0]
	const headerEnd = cutXml(xml, /<\/groupe102>/g)["</groupe102>"][0]
	// Chaque dossier patient est dans une paire de balises <groupe104></groupe104>
	const patientsFolderStart = cutXml(xml, /<groupe104>/g)["<groupe104>"]
	const patientsFolderEnd = cutXml(xml, /<\/groupe104>/g)["</groupe104>"]

	const xmlToBase64 = () => {
		let finalXml = ""
		let header = xml.slice(headerStart, headerEnd) + "</groupe102>"
		let body
		if (patientIndex === 0) {
			body = xml.slice(patientsFolderStart[patientIndex], patientsFolderEnd[patientIndex]) + "</groupe104>"
		} else {
			let assure = xml.slice(patientsFolderStart[0], patientsFolderEnd[0]) + "</groupe104>"
			let beneficiaire =
				xml.slice(patientsFolderStart[patientIndex], patientsFolderEnd[patientIndex]) + "</groupe104>"
			body = `${assure}${beneficiaire}`
		}
		finalXml = `${header}${body}</export>`

		const base64 = btoa(finalXml)

		return base64
	}

	let raw = `
	<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">
		<soapenv:Header/>
			<soapenv:Body>
				<jux:transmettreXMLPatient>
					<xsd:appelTransmettreXMLPatient>
						<xsd:fluxXml>${xmlToBase64()}</xsd:fluxXml>
						<xsd:finess>${finess}</xsd:finess>
						<xsd:numNat>${adeli}</xsd:numNat>
						${idPatient ? `<xsd:idtPat>${idPatient}</xsd:idtPat>` : ""}
					</xsd:appelTransmettreXMLPatient>
				</jux:transmettreXMLPatient>
			</soapenv:Body>
	</soapenv:Envelope>
	`

	return new Promise((resolve, reject) => {
		axios
			.post(FSV_WEBSERVICES_API, raw)
			.then((result) => result.data)
			.then((result) => {
				const res = API_FSV.parseXmlFile(result)
				API_FSV.checkForError(res)
				const idPatient = API_FSV.recursivelySearchForKey(res, "idPatient")
				resolve(Number(idPatient))
			})
			.catch((error) => {
				reject(error)
				handleJuxtaLinkError(error, "Fermer", true)
			})
	})
}

const getUserDataIfContextFails = async (user, laboratory) => {
	let finess = laboratory?.finess
	let adeli = user?.adeli

	if (!finess || !adeli) {
		try {
			adeli = (await API.find("USERS_API", user.id))?.adeli
			finess = (await API.find("LABORATORIES_API", laboratory.id))?.finess
		} catch (error) {
			console.error(error)
		}
	}
	return { adeli, finess }
}

// TRANSMETTRE PATIENT (POUR LA PROD POST CNDA)
const transmettrePatient = async (userInfo, patient, cmu = null) => {
	const { adeli, finess } = await getUserDataIfContextFails(userInfo.user, userInfo.laboratory)

	const newPatient = {
		numFacturation: finess,
		numRpps: adeli,
		nom: patient.nom || patient.lastName,
		prenom: patient.prenom || patient.firstName,
		dateNaissance: {
			jour: patient?.dateNaissance?.jour || patient?.birthDate?.slice(8, 10),
			mois: patient?.dateNaissance?.mois || patient?.birthDate?.slice(5, 7),
			annee: patient?.dateNaissance?.annee || patient?.birthDate?.slice(0, 4),
		},
		lunaire: false,
		numeroSS: patient.numeroSS || patient.idSecu?.slice(0, 13),
		cleNumeroSS: patient.cleNumeroSS || patient.idSecu?.slice(13, 15),
		rangNaissance: patient.rangNaissance || 1,
		sexe: patient.sexe || patient.gender.toLowerCase(),
		codeServiceAMO: cmu ? cmu : "0",
	}

	let raw = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">
    <soapenv:Header/>
    <soapenv:Body>
       <jux:transmettrePatient>
          <xsd:appelTransmettrePatient>
             <xsd:numFacturation>${newPatient.numFacturation}</xsd:numFacturation>
             <xsd:numRpps>${newPatient.numRpps}</xsd:numRpps>
             <xsd:idPatient>-1</xsd:idPatient>
             <xsd:idAssure>-1</xsd:idAssure>
             <xsd:nom>${newPatient.nom}</xsd:nom>
             <xsd:prenom>${newPatient.prenom}</xsd:prenom>
             <xsd:nomNaissance></xsd:nomNaissance>
             <xsd:dateNaissance>
                <xsd:jour>${newPatient.dateNaissance.jour}</xsd:jour>               
                <xsd:mois>${newPatient.dateNaissance.mois}</xsd:mois>              
                <xsd:annee>${newPatient.dateNaissance.annee}</xsd:annee>              
                <xsd:lunaire>${newPatient.lunaire}</xsd:lunaire>
                </xsd:dateNaissance>
             <xsd:numeroSS>${newPatient.numeroSS}</xsd:numeroSS>
             <xsd:cleNumeroSS>${newPatient.cleNumeroSS}</xsd:cleNumeroSS>
             <xsd:rangNaissance>${newPatient.rangNaissance}</xsd:rangNaissance>
             <xsd:sexe>${newPatient.sexe}</xsd:sexe>
             <xsd:declarationMedecinTraitant>nonRenseigne</xsd:declarationMedecinTraitant>
             <xsd:codeServiceAMO>0${newPatient.codeServiceAMO}</xsd:codeServiceAMO>
             <xsd:dateDebutDroit format="?"></xsd:dateDebutDroit>
             <xsd:dateFinDroit format="?"></xsd:dateFinDroit>
          </xsd:appelTransmettrePatient>
       </jux:transmettrePatient>
    </soapenv:Body>
 </soapenv:Envelope>`

	return new Promise((resolve, reject) => {
		axios
			.post(FSV_WEBSERVICES_API, raw)
			.then((result) => result.data)
			.then((result) => {
				const res = API_FSV.parseXmlFile(result)
				API_FSV.checkForError(res)
				const idPatient = API_FSV.recursivelySearchForKey(res, "idPatient")
				resolve(Number(idPatient))
			})
			.catch((error) => reject(error))
	})
}

const getIdFsvForPatient = async (patient, laboratory, user) => {
	const createPatient = await transmettrePatient({ laboratory, user }, patient)
	if (createPatient) {
		await API.update("PATIENTS_API", patient.id, {
			idFSV: createPatient,
		})
	}
	return createPatient
}

const fetchId = async (patient) => {
	const res = await API.find("PATIENTS_API", patient.id)
	return res.idFSV
}

const transmettreXmlPs = async (xml, finess) => {
	// fluxXml : Flux XML de la CPS à convertir en
	// codeProfil: Code profil du statut ('admin' pour administrateur ou 'ps' pour praticien)
	// caisseGestionnaire: Caisse gestionnaire du statut => 2 premiers chiffres du finess + "1" (si finess = 692682776, caisse gestionnaire = 691)
	// contratTarifaire: Contrat tarifaire du statut (enum) AUCUN =>
	// codeSpecialite: Code spécialité du PS (65 => audioprothésiste)

	const fluxXml = btoa(xml)
	const caisseGestionnaire = String(finess.slice(0, 2)) + "1"
	//const contratTarifaire = "AUCUN"

	try {
		const request = GenerateJuxtaXML("TransmettreXMLPs", {
			appelTransmettreXmlPs: {
				fluxXml,
				codeProfil: "ps",
				caisseGestionnaire,
				contratTarifaire: "Aucun",
				codeSpecialite: 65,
			},
		})
		await API_FSV.getResponse(request)
		return true
	} catch (error) {
		return false
	}
}

const transmettreAmo = async (user, laboratory, patient, amo, ald, cardData = null) => {
	try {
		if (patient.idFSV == null) patient.idFSV = await fetchId(patient)

		const situation = amo.situationCode
		const grandRegime = amo?.regime
		const caisseGestionnaire = amo?.fundManager
		const centreGestionnaire = amo?.systemManager
		const dateMaternite = setDateForFSV(amo?.pregnancyDate)
		const { adeli, finess } = await getUserDataIfContextFails(user, laboratory)

		const dataForFSV = {
			numFacturation: finess,
			numRpps: adeli,
			idPatient: patient.idFSV,
			mettreAJour: 0,
			tiersPayeurAmo: {
				grandRegime: grandRegime || cardData.AmoGrandRegime,
				caisseGestionnaire: caisseGestionnaire || cardData.AmoCaisseGestionnaire,
				centreGestionnaire: centreGestionnaire || cardData.AmoCentreGestionnaire,
			},
			dateDebutCouverture: amo.startDate
				? {
						format: "yyyyMMdd",
						value: setDateForFSV(amo.startDate),
				  }
				: {
						format: " ",
						value: " ",
				  },
			dateFinCouverture: amo.endDate
				? {
						format: "yyyyMMdd",
						value: setDateForFSV(amo.endDate),
				  }
				: {
						format: " ",
						value: " ",
				  },
			datePieceJustificative: {
				format: " ",
				value: " ",
			},
			pieceJustificative: 0,
			qualitePatient: `0${cardData?.qualitePatient || "0"}`,
			isTP: user.tpAmo || true,
			codeALD: ald,
			garantie: garanties.find((garantie) => garantie.value === situation).garantie,
			situation: amo?.particularSituation,
			codeServiceAMO: "00",
		}

		if (dateMaternite) {
			dataForFSV.dateMaternite = {
				format: "yyyyMMdd",
				value: dateMaternite,
			}
		}

		const request = GenerateJuxtaXML("transmettreAmo", {
			appelTransmettreAmo: dataForFSV,
		})
		await API_FSV.getResponse(request)
		return true
	} catch (error) {
		return false
	}
}

const transmettreAmc = async (patient, amc) => {
	if (!amc.amcId) return
	if (!handledAmcs.includes(amc.amcId)) return

	if (patient.idFSV == null) patient.idFSV = await fetchId(patient)

	const isMutnum = findSpecialAmcRegimen(amc, patient)

	const request = GenerateJuxtaXML("TransmettreAmc", {
		appelTransmettreAmc: {
			idPatient: patient.idFSV,
			rnm: isMutnum ? "" : amc.amcId,
			dateDebutDroit: amc.startDate
				? {
						format: "yyyyMMdd",
						value: setDateForFSV(amc.startDate),
				  }
				: {
						format: "",
						value: "",
				  },
			isTp: amc.isTP || false,
			libelle: "?",
			dateFinDroit: amc.endDate
				? {
						format: "yyyyMMdd",
						value: setDateForFSV(amc.endDate),
				  }
				: {
						format: "",
						value: "",
				  },
			indicateurTraitement: isMutnum ? "32109" : "01107",
			estGestionUnique: isMutnum ? "true" : "false",
			mutnum: isMutnum ? amc.amcId : "",
		},
	})
	await API_FSV.getResponse(request)
}

// SI UNE AMC DISPOSE DE PLUSIEURS CONVENTIONS, ON FAIT APPEL A consulterConventions()
// PUIS selectionnerConvention() POUR DETERMINER CELLE A APPLIQUER A L'AMC DU PATIENT
const consulterConventions = (patient) => {
	let myHeaders = new Headers()
	myHeaders.append("Content-Type", "text/xml")
	myHeaders.append("Access-Control-Allow-Origin", "*")

	// A tester ça ou remplacer par API.ConsulterConvention(patient, date)
	// const request = GenerateJuxtaXML("ConsulterConventions", {
	// 	appelConsulterConventions: {
	// 		idtPat: 0,
	// 		dateReference: {
	// 			format: "yyyyMMdd",
	// 			value: today,
	// 		},
	// 	},
	// })

	const raw = `
		<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">
			<soapenv:Header/>
			<soapenv:Body>
				<jux:ConsulterConventions>
					<xsd:appelConsulterConventions>
						<xsd:idtPat>${patient.idFSV}</xsd:idtPat>
						<xsd:dateReference format="yyyyMMdd">${today}</xsd:dateReference>
					</xsd:appelConsulterConventions>
				</jux:ConsulterConventions>
			</soapenv:Body>
		</soapenv:Envelope>
	`

	return new Promise((resolve, reject) => {
		axios
			.post(FSV_WEBSERVICES_API, raw)
			.then((result) => result.data)
			.then((result) => {
				const res = API_FSV.parseXmlFile(result)
				API_FSV.checkForError(res)
				const conventions = API_FSV.recursivelySearchForKey(res, "conventions")
				const conventionsArr = Object.keys(conventions).map((key) => {
					return conventions[key]
				})
				resolve(conventionsArr)
			})
			.catch((error) => reject(error))
	})
}

const selectionnerConvention = (patient, convention) => {
	let myHeaders = new Headers()
	myHeaders.append("Content-Type", "text/xml")
	myHeaders.append("Access-Control-Allow-Origin", "*")

	// Potentiel remplacement par API.selectionnerConvention(idFSV, idConvention, date)
	const raw = `
	<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">
   <soapenv:Header/>
   <soapenv:Body>
      <jux:selectionnerConvention>
         <xsd:appelSelectionnerConvention>
            <xsd:idtPat>${patient.idFSV}</xsd:idtPat>
            <xsd:idtConvention>${convention}</xsd:idtConvention>
            <xsd:dateReference format="yyyyMMdd">${today}</xsd:dateReference>
         </xsd:appelSelectionnerConvention>
      </jux:selectionnerConvention>
   </soapenv:Body>
</soapenv:Envelope>
	`

	return new Promise((resolve, reject) => {
		axios
			.post(FSV_WEBSERVICES_API, raw)
			.then((result) => result.data)
			.then((result) => {
				const res = API_FSV.parseXmlFile(result)
				API_FSV.checkForError(res)
			})
			.catch((error) => reject(error))
	})
}

// FIN MODE SILENT

// WEBSERVICES
// consulterClient
const consulterClient = async (idPatient) => {
	let xmlRequest = `
    <soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">   
        <soap:Header/>   
            <soap:Body>      
                <jux:ConsulterClient>                
                    <xsd:appelConsulterClient>            
                        <xsd:idPatient>${idPatient}</xsd:idPatient>         
                    </xsd:appelConsulterClient>      
                </jux:ConsulterClient>   
            </soap:Body>
    </soap:Envelope>`

	// return new Promise((resolve, reject) => {
	// 	axios
	// 		.post(FSV_WEBSERVICES_API, xmlRequest)
	// 		.then((result) => resolve(parseSoap(result.data)))
	// 		.catch((error) => reject(error))
	// })

	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.post(FSV_WEBSERVICES_API, xmlRequest)
			const res = API_FSV.parseXmlFile(result.data)
			API_FSV.checkForError(res)
			resolve(res)
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})

	// Potentiel remplacement par API.consulterClient(idPatient)
}

// consulterUtilisateur
const consulterUtilisateur = async (nom, prenom, numFacturation) => {
	let request = `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">
			<soap:Header/>
			<soap:Body>
				<jux:consulterUtilisateur>
					<xsd:appelConsulterUtilisateur>
						<xsd:Nom>${nom}</xsd:Nom>
						<xsd:Prenom>${prenom}</xsd:Prenom>
						<xsd:numFacturation>${numFacturation.slice(0, numFacturation.length - 1)}</xsd:numFacturation>
						<xsd:typeIdentifiant>8</xsd:typeIdentifiant>
						<xsd:numIdtNat></xsd:numIdtNat>
						<xsd:estArchive>false</xsd:estArchive>
					</xsd:appelConsulterUtilisateur>
				</jux:consulterUtilisateur>
			</soap:Body>
		</soap:Envelope>`

	return new Promise((resolve, reject) => {
		axios
			.post(FSV_WEBSERVICES_API, request)
			.then((result) => result.data)
			.then((result) => {
				const res = API_FSV.parseXmlFile(result)
				API_FSV.checkForError(res)
				const user = API_FSV.recursivelySearchForKey(res, "utilisateur")
				resolve(user)
			})
			.catch((error) => reject(error))
	})
}

const updatePatientinFsv = async (user, laboratory, patient, subscriptions) => {
	if (!hasTeletransService(subscriptions) || IsObjectEmpty(patient) || !patient.idFSV) return
	const { adeli, finess } = await getUserDataIfContextFails(user, laboratory)

	try {
		const request = GenerateJuxtaXML("transmettrePatient", {
			appelTransmettrePatient: {
				idPatient: patient.idFSV,
				numFacturation: finess,
				numRpps: adeli,
				nom: patient.lastName,
				prenom: patient.firstName,
				numeroSS: patient.idSecu.slice(0, 13),
				cleNumeroSS: patient.idSecu.slice(13),
				rangNaissance: 1,
				dateNaissance: {
					jour: patient.birthDate?.slice(8, 10),
					mois: patient.birthDate?.slice(5, 7),
					annee: patient.birthDate?.slice(0, 4),
					lunaire: false,
				},
				sexe: patient?.gender === "HOMME" ? "homme" : "femme",
			},
		})
		return await API_FSV.getResponse(request)
	} catch (error) {
		console.error(error)
	}
}

const repairPrescriberRpps = (prescriberRpps) => {
	if (prescriberRpps.substr(0, 1) === "8") return prescriberRpps.substr(1)
	else return prescriberRpps
}

const domaineGED = "FSV"

const scor = (documentList) => {
	if (!documentList) return
	const documentsToSends = []

	for (const doc of documentList) {
		documentsToSends.push("<xsd:listeDocuments>")
		documentsToSends.push(`<xsd:typeDocument>${doc.docType}</xsd:typeDocument>`)
		documentsToSends.push(`<xsd:idDocumentGed>${doc.docId}</xsd:idDocumentGed>`)
		documentsToSends.push("</xsd:listeDocuments>")
	}

	return ` 
		<xsd:scor>
               <xsd:loginGed>?</xsd:loginGed>
               <xsd:urlWbsGed>?</xsd:urlWbsGed>
               <xsd:passwordGed>?</xsd:passwordGed>
			   <xsd:idUtfMetier>1</xsd:idUtfMetier>
               <xsd:domaineGed>${domaineGED}</xsd:domaineGed>
               <xsd:idDossierGedPatient>21</xsd:idDossierGedPatient>
			   ${documentsToSends.join("")}
        </xsd:scor>
		`
}

const transmettreFacture = async (factureDetails, actes, documentList = null, selectedPatient, user, laboratory) => {
	let idFSV = factureDetails.idPatient
	if (!factureDetails.idPatient) {
		try {
			idFSV = await getIdFsvForPatient(selectedPatient, laboratory, user)
			await syncCouvertures(user, laboratory, selectedPatient, idFSV)
		} catch (error) {
			console.error(error)
			handleJuxtaLinkError(error, "Fermer", true, { factureDetails, actes, documentList })
			return
		}
	}

	const fsvPatientAmc = API_FSV.recursivelySearchForKey(await consulterClient(idFSV), "couvertureAMC")

	const convertEscapableCharacters = (str) => {
		return String(str).replace(/[^a-zA-Z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]/g, " ")
	}

	const getPrescriberData = () => {
		const data = { val: "", valKey: "" }
		if (factureDetails.prescripteur.typeIdent !== 0) {
			data.val = repairPrescriberRpps(factureDetails.prescripteur.rppsPresc)
			data.valKey = factureDetails.prescripteur.rppsPrescCle
		}
		if (factureDetails.prescripteur.hasOwnProperty("estPrescripteurSNCF"))
			data.estPrescripteurSNCF = factureDetails.prescripteur.estPrescripteurSNCF

		return data
	}

	const { adeli, finess } = await getUserDataIfContextFails(user, laboratory)

	const prescriber = getPrescriberData()

	const facture = {
		dateFacturation: factureDetails.dateFacturation,
		datePrescription: factureDetails.datePrescription,
		dateExecution: factureDetails.dateExecution,
		numNatPs: adeli,
		numFiness: finess,
		prescripteur: {
			codeConditionExercice: factureDetails.prescripteur.codeConditionExercice,
			numIdFacPresc: factureDetails.prescripteur.numIdFacPresc,
			numIdFacPrescCle: factureDetails.prescripteur.numIdFacPrescCle,
			codeSpecialite: factureDetails.prescripteur.codeSpecialite,
			typeIdent: factureDetails.prescripteur.typeIdent,
			numeroStructure: factureDetails.prescripteur.numeroStructure || "",
			rppsPresc: prescriber?.val,
			rppsPrescCle: prescriber?.valKey,
		},
		isTpAmo: factureDetails.isTpAmo,
		isTpAmc: factureDetails.isTpAmc,
		refPEC: actes.refPEC,
		datePEC: actes.datePEC,
		actes,
		qte: factureDetails.qte,
		libelle: factureDetails.libelle,
		formule: factureDetails.formule,
		modeSecurisation: factureDetails?.modeSecurisation,
		numero: 1,
	}

	// Some amcs require for the user to not send amc amounts. Still, they require for the isTpAmc key to be true.
	// That is what we handle in the next block of code.
	if (!handledAmcs.includes(factureDetails.cmu?.id) || factureDetails.publicAmc) {
		for (const acte of facture?.actes) {
			acte.cmuNoAmc = true
		}
	}

	if (fsvPatientAmc?.isGu && fsvPatientAmc?.isTp && !facture.isTpAmc) {
		facture.genererDreExceptionTag = true
	}

	let raw = `
		<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:jux="http://www.juxta.fr" xmlns:xsd="XsdWebServiceFSV.xsd">
			<soapenv:Header/>
				<soapenv:Body>    
                    <jux:TransmettreFacture>        
                        <xsd:appelTransmettreFacture>           
                            <xsd:identification>              
                                <xsd:idPatient>${idFSV}</xsd:idPatient>              
                                <xsd:source>AudioWizard</xsd:source>              
                                <xsd:datePrescription format="yyyyMMdd">${
									actes[0].datePrescription || facture.datePrescription
								}</xsd:datePrescription>              
                                <xsd:dateFacturation format="yyyyMMdd">${
									actes[0].dateFacturation || facture.dateFacturation
								}</xsd:dateFacturation>              
                                <xsd:dateReferenceAmc>FACT</xsd:dateReferenceAmc>              
                                <xsd:numNatPs>${facture.numNatPs}</xsd:numNatPs>              
                                <xsd:numFiness>${facture.numFiness}</xsd:numFiness>           
                                <xsd:prescripteur>
                                    <xsd:codeConditionExercice>${
										facture.prescripteur.codeConditionExercice
									}</xsd:codeConditionExercice>                 
                                    <xsd:numIdFacPresc>${
										facture.prescripteur.numIdFacPresc
									}</xsd:numIdFacPresc>                 
                                    <xsd:numIdFacPrescCle>${
										facture.prescripteur.numIdFacPrescCle
									}</xsd:numIdFacPrescCle>                 
                                    <xsd:codeSpecialite>${
										facture.prescripteur.codeSpecialite
									}</xsd:codeSpecialite>                 
                                    <xsd:typeIdent>${facture.prescripteur.typeIdent}</xsd:typeIdent>                 
                                    <xsd:rppsPresc>${facture.prescripteur.rppsPresc}</xsd:rppsPresc>                 
                                    <xsd:rppsPrescCle>${facture.prescripteur.rppsPrescCle}</xsd:rppsPrescCle>   
									${
										prescriber?.estPrescripteurSNCF
											? `<xsd:estPrescipteurSncf>${prescriber?.estPrescripteurSNCF}</xsd:estPrescipteurSncf>`
											: ""
									}               
                                </xsd:prescripteur>             
                                <xsd:ParcoursDeSoin> 
                                    <xsd:situationParcoursDeSoin>EXCLU</xsd:situationParcoursDeSoin> 
                                </xsd:ParcoursDeSoin>
								<xsd:modeSecurisation>${
									facture.modeSecurisation || modeTeletrans.securise
								}</xsd:modeSecurisation>                                           
                                <xsd:isTpAmo>${facture.isTpAmo ?? true}</xsd:isTpAmo>   
                                <xsd:isTpAmc>${facture.isTpAmc || false}</xsd:isTpAmc>
								${facture.genererDreExceptionTag ? "<xsd:genererDRE>false</xsd:genererDRE>" : ""}
                                ${facture.refPEC ? `<xsd:referencePEC>${facture.refPEC}</xsd:referencePEC>` : ""}
                            </xsd:identification>
                            ${actes.map(
								(acte, i) =>
									`<xsd:acte>              
                                    <xsd:codeActe>${acte.lpp.lpp || acte.lpp}</xsd:codeActe>              
                                    <xsd:qte>${acte.qte || 1}</xsd:qte>              
                                    <xsd:montantHonoraire>${acte.montantHonoraire}</xsd:montantHonoraire>              
                                    <xsd:libelle>${convertEscapableCharacters(
										acte.libelle || acte.libelleActe
									)}</xsd:libelle>              
                                    ${acte.montantAmo ? `<xsd:montantAmo>${acte.montantAmo}</xsd:montantAmo>` : ""}  
									${acte.montantAmc && !acte?.cmuNoAmc ? `<xsd:montantAmc>${acte.montantAmc}</xsd:montantAmc>` : ""}         
                                    <xsd:montantPP>0.0</xsd:montantPP>                            
                                    <xsd:dateExecution format="yyyyMMdd">${
										acte.dateExecution || facture.dateExecution
									}</xsd:dateExecution>              
                                    <xsd:numero>${i++}</xsd:numero>  
                                    ${
										acte.qualifDepense
											? `<xsd:qualifDepense>${acte.qualifDepense}</xsd:qualifDepense>`
											: ""
									}
									${acte.isAld ? `<xsd:isAld>${acte.isAld}</xsd:isAld>` : "<xsd:isAld>false</xsd:isAld>"}
                                </xsd:acte>`
							)}  
							${scor(documentList)}
                        </xsd:appelTransmettreFacture>     
                    </jux:TransmettreFacture>  
				</soapenv:Body>
			</soapenv:Envelope>`

	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.post(FSV_WEBSERVICES_API, raw)
			const res = API_FSV.parseXmlFile(result.data)
			API_FSV.checkForError(res)
			const idFacture = API_FSV.recursivelySearchForKey(res, "idFacture")
			resolve(idFacture)
		} catch (error) {
			console.error(error)
			handleJuxtaLinkError(error, "Fermer", true, { factureDetails, actes, documentList })
			reject(error)
		}
	})
}

const teletransDeSuivi = async (laboratory, user, patient, prescripteur, schedule, document, mode) => {
	if (!patient.idFSV) {
		let idFsv = await getIdFsvForPatient(patient, laboratory, user)
		patient = { ...patient, idFsv }
	}

	const { adeli, finess } = await getUserDataIfContextFails(user, laboratory)

	// patient.todos are no good as it has CLOSED todos as well
	const todos = await API.findAll("TODOS_API", `?patient=${patient["@id"]}&status!=CLOSED&itemsPerPage=10`)

	// we don't have clear todo types. So the only way to find the todos we need is this sad line:
	const latestTeletransTodo = (todos || []).find(
		(t) => removeSpecialChars(t.label).toLowerCase().includes("teletransmission de controle") && t.status === "TODO"
	)

	try {
		const dataFsv = await API_FSV.transmettreFactureSuivi(
			patient,
			adeli,
			finess,
			today,
			today,
			today,
			prescripteur,
			mode
		)
		const idFacture = API_FSV.recursivelySearchForKey(dataFsv, "idFacture")
		let silent = await facturerSilent({
			idFacture,
			finess,
			numNatPs: adeli,
			idCompany: user.company.id,
		})
		if (typeof silent === "string") {
			silent = JSON.parse(silent)
		}

		if (document.id) {
			await modifyFactureDeSuivi(document, silent)
		} else {
			await createFactureDeSuivi(laboratory, patient, user, schedule, silent)
		}

		if (latestTeletransTodo) {
			await API.update("TODOS_API", latestTeletransTodo.id, { status: "DONE" })
		}

		return { ...silent, idFacture }
	} catch (error) {
		console.error(error)
		throw error
	}
}

const supprimerAmo = async (patient) => {
	const request = GenerateJuxtaXML("supprimerAmoPatient", {
		appelSupprimerAmoPatient: {
			idPatient: patient.idFSV,
			supprimerTout: 1,
		},
	})
	return await API_FSV.getResponse(request)
}

const supprimerAmc = async (patient) => {
	const request = GenerateJuxtaXML("supprimerAmcPatient", {
		appelSupprimerAmcPatient: {
			idPatient: patient.idFSV,
			supprimerTout: 1,
		},
	})
	return await API_FSV.getResponse(request)
}

export const sendDocumentSuivi = async (mode) => {
	let suiOrdo = `<ged:codeType>OOR</ged:codeType>
				<ged:nomDoc>pj-sui-ordo</ged:nomDoc>`

	let suiFs = `<ged:codeType>OFS</ged:codeType>
								<ged:nomDoc>pj-sui-fs</ged:nomDoc>`

	const doc = scorSuivi.replace("data:application/pdf;base64,", "")

	try {
		let template = (
			data
		) => `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ged="http://www.juxta.fr/GED">
				<soap:Header/>
					<soap:Body>
						<ged:Ajout_Doc>
							<ged:loginGED>?</ged:loginGED>
							<ged:mdpGED>?</ged:mdpGED>
							<ged:codeDomaine>${domaineGED}</ged:codeDomaine>
							<ged:idtUtfExt>1</ged:idtUtfExt>
							<ged:idtDossier>21</ged:idtDossier>
							${data}
							<ged:Doc>${doc}</ged:Doc>
							<ged:extensionDoc>pdf</ged:extensionDoc>
						</ged:Ajout_Doc>
					</soap:Body>
				</soap:Envelope>`

		const resultOrdo = await axios.post(GED_WEBSERVICES_API, template(suiOrdo))
		const parsedOrdo = API_FSV.parseXmlFile(resultOrdo.data)
		const ordoId = API_FSV.recursivelySearchForKey(parsedOrdo, "ID_Doc")

		let documentList = `
		<xsd:typeDocument>ORDN</xsd:typeDocument>
		<xsd:idDocumentGed>${ordoId}</xsd:idDocumentGed>`

		if (mode === modeTeletrans.degrade) {
			const resultFs = await axios.post(GED_WEBSERVICES_API, template(suiFs))
			const parsedFs = API_FSV.parseXmlFile(resultFs.data)
			const fsId = API_FSV.recursivelySearchForKey(parsedFs, "ID_Doc")
			documentList += `</xsd:listeDocuments>
			<xsd:listeDocuments>
			<xsd:typeDocument>FSNC</xsd:typeDocument>
			<xsd:idDocumentGed>${fsId}</xsd:idDocumentGed>`
		}

		return documentList
	} catch (error) {
		console.error(error)
		throw error
	}
}

const sendDocument = async (laboratory, document, patient, setter) => {
	try {
		const docTypes = {
			FEUILLE_SOIN: "OFS",
			ORDONNANCE: "OOR",
			OTHER: "DIV",
		}

		const finess = laboratory.finess
		// const idtDossier
		const codeType = docTypes[document?.type]
		const codeTypeTranslateForScor = (codeType) => {
			if (!codeType) return
			if (codeType === "OOR") return "ORDN"
			if (codeType === "OFS") return "FSNC"
			if (codeType === "DIV") return "AUTP"
		}
		const nomDoc = document.filename
		const doc = document.binary.replace("data:application/pdf;base64,", "")
		const extensionDoc = document.extension || ".pdf"

		if (!document || !laboratory || !patient) return
		let raw = `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ged="http://www.juxta.fr/GED">
				<soap:Header/>
					<soap:Body>
						<ged:Ajout_Doc>
							<ged:loginGED>?</ged:loginGED>
							<ged:mdpGED>?</ged:mdpGED>
							<ged:codeDomaine>${domaineGED}</ged:codeDomaine>
							<ged:idtUtfExt>1</ged:idtUtfExt>
							<ged:idtDossier>${patient.idGed || 21}</ged:idtDossier>
							<ged:codeType>${codeType}</ged:codeType>
							<ged:nomDoc>${nomDoc}</ged:nomDoc>
							<ged:Doc>${doc}</ged:Doc>
							<ged:idtUtlExtAjoutDoc>${finess}</ged:idtUtlExtAjoutDoc>
							<ged:extensionDoc>${extensionDoc}</ged:extensionDoc>
						</ged:Ajout_Doc>
					</soap:Body>
			</soap:Envelope>`

		const result = await axios.post(GED_WEBSERVICES_API, raw)
		const parsed = API_FSV.parseXmlFile(result.data)
		API_FSV.checkForError(parsed)
		const { idDocument, codeSortie } = parseGed(result.data)
		if (codeSortie !== "1") throw new Error(`Erreur lors de l'ajout du document ${nomDoc} : ${codeSortie}`)
		setter((old) => [
			...old,
			{
				...document,
				docType: codeTypeTranslateForScor(codeType),
				docId: idDocument,
				_uid: document.uid,
			},
		])
	} catch (error) {
		console.error(error)
		throw error
	}

	// CHECK SI UN DOSSIER GED PATIENT EXISTE (clé IdGed dans l'entité patient)

	// SI OUI => AJOUT DOCUMENT
	// SI NON => AJOUT DOSSIER ( FONCTION AJOUT_DOSSIER() => il nous retourne un ID DOSSIER ) => AJOUT DOCUMENT

	// FONCTION ajout_utf => créé un utf pour le labo:
	// la balise <ged:ID_Utf_Ext></ged:ID_Utf_Ext> prend le finess
	// ce même finess va dans <ged:idtUtlExtAjoutDoc></ged:idtUtlExtAjoutDoc> de ajout_doc

	// GET: recup_doc
	// POST: ajout_doc
	// DEL: archive_doc
	// PUT: modif_doc

	// SI LE PS VEUT AJOUTER LES PJ IMMEDIATEMENT => BLOC scor DE transmettreFacture
	// SI LE PS VEUT AJOUTER LES PJ PLUS TARD => transmettreDocument

	// VOIR AVEC RAPHAEL LES PARAMETRAGES A FAIRE AVANT DE PASSER EN PROD
}

/**
 * Synchronises the patient's covers in AW with those in FSV
 *
 * @param {object} user Object du user
 * @param {object} laboratory Object du laboratoire
 * @param {object} patient Object du patient
 */

export const syncCouvertures = async (user, laboratory, patient = null, createdId = null) => {
	try {
		if (!patient) {
			console.error("Aucun patient n'a été fourni")
			throw new Error("Le patient n'a pas été fourni")
		}
		if (!patient?.idFSV && !createdId) {
			console.error("Le patient n'existe pas chez FSV")
			throw new Error("Le patient n'existe pas chez FSV")
		}
		if (createdId) patient.idFSV = createdId
		const activeAMx = await findActiveAMx(patient)
		if (activeAMx?.amo) {
			await supprimerAmo(patient)
			await transmettreAmo(user, laboratory, patient, activeAMx.amo, activeAMx.amo.ald, null)
		}
		if (activeAMx?.amc) {
			await supprimerAmc(patient)
			await transmettreAmc(patient, activeAMx.amc)
		}
	} catch (error) {
		console.error(error)
	}
}

export const handleTeletransSuivi = async (
	user,
	doc,
	schedule = {},
	laboratory,
	noDuplicateCheck = false,
	mode = modeTeletrans.securise
) => {
	const patient = await API.find("PATIENTS_API", doc.patient.id)
	let prescriber = doc?.config?.prescriber || patient.advices?.[0]?.prescriber

	if (prescriber?.id && (!prescriber?.finess || !prescriber?.rpps)) {
		prescriber = await API.find("PRESCRIBERS_API", prescriber.id)
	} else if (!prescriber?.id) {
		toast.error("Aucun prescripteur n'est associé au patient, vous ne pouvez pas faire la télétransmission")
		throw new Error("Patient prescriber is missing")
	}

	if (!laboratory?.finess) {
		toast.error(`Le numéro FINESS du laboratoire ${laboratory.label} est manquant`)
		throw new Error("Laboratory finess is missing")
	}

	if (noDuplicateCheck || (await isTeletransInvoiceNotDuplicate(patient.id, true))) {
		return await teletransDeSuivi(
			laboratory,
			{
				...user,
				numNatPs: user?.adeli,
				numFiness: laboratory?.finess,
			},
			patient,
			prescriber,
			schedule,
			doc,
			mode
		)
	} else return null
}

export const isJuxtaLinkConnected = () => {
	return !!port
}

export {
	patientAdrSilent,
	readCarteVitale,
	readCPS,
	transmettreAmo,
	transmettreAmc,
	transmettrePatient,
	updatePatientinFsv,
	transmettreFacture,
	transmettreXml,
	transmettreXmlPs,
	facturerSilent,
	supprimerAmo,
	supprimerAmc,
	sendDocument,
	consulterClient,
	consulterUtilisateur,
	consulterConventions,
	testPresenceCarte,
	selectionnerConvention,
	teletransDeSuivi,
	etatJuxtaLink,
	repairPrescriberRpps,
	getUserDataIfContextFails,
}
