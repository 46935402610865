/** Ensemble des fonctions utilisées dans différents fichiers */

import { Laboratory, ScheduleType, User } from "@audiowizard/common"
import { Localizable, DayView, Store, Widget } from "@bryntum/calendar"
import dayjs from "dayjs"
import { colorToHex, colorFromStr } from "services/functions"
import { MutableRefObject } from "react"
import { toast } from "react-toastify"
import { Event, AWCalendar, AgendaStore, TEMP_DASHBOARD, DEFAULT_BACKGROUND_COLOR, EvtData } from "./AgendaTypes"

export const getColor = (type: ScheduleType): string => {
	if (type.backgroundColor === DEFAULT_BACKGROUND_COLOR) {
		return colorFromStr(type.scheduleStatus || "")
	}
	return colorToHex(type.backgroundColor || 0)
}

// use to get a rounded value to show the attendance of the user, use to construct a gradient background
export const getPercentage = (time: number, startTime: number, endTime: number): number => {
	return Math.round(((time - startTime) / (endTime - startTime)) * 1000) / 10
}

export const getLocale = (l: string): string => {
	// @ts-ignore
	return Localizable().L(l)
}

export const getTitleEvent = (event: Event): string => {
	let title = getLocale("L{EventRenderer.withoutClient}")

	if (event?.patient) title = `${event?.patient?.lastName} ${event?.patient?.firstName}`
	return title
}

export const setLaboratoriesFilters = (calendarRef: MutableRefObject<any>, laboratories: Laboratory[]): void => {
	if (calendarRef && calendarRef.current && laboratories) {
		const calendar = calendarRef.current.calendarInstance
		calendar.widgetMap.laboratoryFilter.store = new Store({
			tree: true,
			data: laboratories.map((lab) => ({
				name: lab.label,
				value: lab.id,
				attendance: lab.laboratoryAttendance,
				// @ts-ignore need to update common file with agendaColor
				color: lab.agendaColor,
			})),
		})
		if (laboratories.length < 6) {
			calendar.widgetMap.laboratoryFilter.chipView.hide(true)
		}
	}
}

function isAgenda(source: AWCalendar, user: User, laboratory: Laboratory): boolean {
	if (laboratory && user) {
		return source.extraData.agendaStore.getAgenda(user, laboratory) ? true : false
	}
	return false
}

export const isNotEditable = (eventRecord: Event, currentUser: User, isAffiliate: boolean): boolean => {
	if (!isAffiliate) return false
	return !eventRecord.affiliation && eventRecord.affiliation !== currentUser?.affiliation["@id"]
}

export const beforeDragEnd = (
	{
		user,
		source,
		eventRecord,
		newStartDate,
	}: { user: User; source: AWCalendar; eventRecord: Event; newStartDate?: Date },
	agendaStore: AgendaStore
): boolean => {
	if (!eventRecord.data.laboratory) return false
	// lors de la création d’un rdv en venant du dashboard on créer des rdv temporaires que l’on doit pouvoir modifier
	// si ce n’est pas un rdv temporaire un affiliate ne peut d
	if (eventRecord["@id"] && isNotEditable(eventRecord, source.extraData.currentUser, source.extraData.isAffiliate))
		return false
	if (agendaStore.isDoctolib(eventRecord.data.user as unknown as User, eventRecord.data.laboratory)) {
		toast.error("Impossible de déplacer un rendez-vous Doctolib")
		return false
	}
	const { data, multiple } = source.extraData.attendances.getLaboratories(
		user?.["@id"],
		newStartDate || eventRecord.startDate,
		agendaStore
	)
	let laboratory
	let isDoctolib = true
	if (multiple) {
		// on sélectionne le premier laboratoire non doctolib
		for (const tmpLaboratory of data) {
			if (!agendaStore.isDoctolib(user, tmpLaboratory)) {
				laboratory = tmpLaboratory
				isDoctolib = false
				break
			}
		}
		if (laboratory && !isDoctolib) {
			toast.info(`Multiple laboratoire possible: ${laboratory?.label} a été sélectionné`)
		}
	} else if (data) {
		laboratory = data
		isDoctolib = laboratory && agendaStore.isDoctolib(user, laboratory)
	}
	if (laboratory) {
		if (isDoctolib) {
			toast.error("Impossible de déplacer un rendez-vous sur un agenda Doctolib.")
			return false
		}
		if (!isAgenda(source, user, laboratory)) {
			toast.error(`Aucun agenda définie pour ${user.lastName} dans le laboratoire ${laboratory.label}`)
			return false
		}
		const agenda = agendaStore.getAgenda(user, laboratory)
		if (
			laboratory.id !== undefined &&
			laboratory.id !== eventRecord.data.laboratory.id &&
			!eventRecord.isRecurring
		) {
			toast.warn("Ce déplacement entraîne un changement de laboratoire")
			source.eventStore.getById(eventRecord["@id"].split("/")[2]).set({
				laboratory: laboratory,
				laboratoryId: laboratory.id,
				agenda: agenda?.agendaId,
			})
		}
		return true
	}
	if (!isAgenda(source, user, eventRecord.laboratory)) {
		toast.error(`Aucun agenda définie pour ${user?.lastName} dans le laboratoire ${eventRecord?.laboratory?.label}`)
		return false
	}
	if (agendaStore.isDoctolib(user as unknown as User, eventRecord.laboratory)) {
		toast.error(
			`Impossible de déplacer le rendez-vous, l’agenda qui lie le laboratoire ${eventRecord?.laboratory?.label} et l’utilisateur ${user?.lastName} est géré par Doctolib `
		)
		return false
	}
	toast.warn("Aucun agenda définie pour cette période, le laboratoire d'origine est conservé.")
	return true
}

// set scheduler time for a new Date
export const setChangeDay = (newDay: Date, actualTime: Date): Date => {
	const dj_newDate = dayjs(newDay)
	const dj_actualTime = dayjs(actualTime)
	return dj_newDate
		.set("h", dj_actualTime.get("h"))
		.set("minute", dj_actualTime.get("minute"))
		.set("second", dj_actualTime.get("second"))
		.toDate()
}

// en venant du tableau de bord on créer des rdv temporaires que l’on doit pouvoir modifier
// il est supprimé en quittant l’agenda, la création est validé s’il y a création du patient dans le tableau de bord
export const createTemporaryEvent = async ({
	calendar,
	eventRecord,
	appointmentFromScheduleSelector,
}: {
	calendar: AWCalendar
	eventRecord: Event
	appointmentFromScheduleSelector?: any
}): Promise<void> => {
	const tempEvent = calendar.eventStore?.query(
		(record: Event) => typeof record.id === "string" && record.id.endsWith(TEMP_DASHBOARD)
	) as {
		id: string
	}[]
	// s’il y a des rdv temporaire on les supprimes
	if (tempEvent.length > 0) {
		calendar.eventStore.remove(tempEvent[0].id)
	}

	const resource = eventRecord.resource
	const startDate =
		typeof eventRecord.startDate === "string" ? dayjs(eventRecord.startDate).toDate() : eventRecord.startDate
	if (!resource) return
	const user = (resource as unknown as { data: User }).data
	// on récupère les présences de l’utilisateur pour la date du rdv
	const { data, multiple } = calendar.extraData.attendances?.getLaboratories(
		user["@id"],
		startDate,
		calendar.extraData.agendaStore
	)

	// on récupère les données du type de rdv
	const {
		color: eventColor,
		label: typeLabel,
		duration,
	} = calendar.extraData.typesStore.getTypeData(appointmentFromScheduleSelector?.type || "")
	let laboratory
	if (!multiple) {
		laboratory = data
	}
	if (multiple) {
		laboratory = data[0]
	}
	const fields: any = {
		id: eventRecord.id + TEMP_DASHBOARD,
		user: user,
		laboratory: laboratory,
		typeLabel,
		eventColor,
		status: appointmentFromScheduleSelector?.type || "",
	}

	// modification de la date de fin en fonction de la durée lié au type de rdv
	if (duration) {
		fields.endDate = dayjs(startDate)
			.add(duration || 30, "minute")
			.toDate()
	}

	// @ts-ignore, type of librairie are wrong
	await eventRecord.setAsync(fields)

	eventRecord.isCreating = false

	// permet de encadrer le rdv en rouge
	calendar.selectEvent(eventRecord)
}

interface SelectScheduleFromScheduleSelector {
	schedulesLoaded: boolean
	appointmentFromScheduleSelector?: EvtData
	settingsLoaded: boolean
	displayedUsers: string[]
	setDisplayedUsers: (u: string[] | ((s: string[]) => string[])) => void
	setSchedulesLoaded: (s: boolean | ((s: boolean) => boolean)) => void
	displayedLaboratories: number[]
	setDisplayedLaboratories: (l: number[] | ((s: number[]) => number[])) => void
	calendar: AWCalendar
}

// si l'on vient de la modal de sélection de schedule on n'affiche que le user et le laboratoire corre
export const selectScheduleFromScheduleSelector = ({
	schedulesLoaded,
	appointmentFromScheduleSelector,
	settingsLoaded,
	displayedUsers,
	setDisplayedUsers,
	setSchedulesLoaded,
	displayedLaboratories,
	setDisplayedLaboratories,
	calendar,
}: SelectScheduleFromScheduleSelector): void => {
	// il faut attendre que l’agenda soit chargé pour sélectionner le rdv
	if (!settingsLoaded || !appointmentFromScheduleSelector) return

	if (appointmentFromScheduleSelector.user) {
		// si l'user n'est pas afficher il faut demander de recharger les rdv
		if (!displayedUsers.find((user) => user.split("/")[2] === appointmentFromScheduleSelector.user?.toString())) {
			setSchedulesLoaded(false)
		}
		setDisplayedUsers(["/users/" + appointmentFromScheduleSelector.user])
	}
	if (appointmentFromScheduleSelector.laboratory) {
		// si le laboratoire n'est pas déjà affiché il faut demander de recharger les rdv
		if (!displayedLaboratories.find((lab) => lab === appointmentFromScheduleSelector.laboratory)) {
			setSchedulesLoaded(false)
		}
		setDisplayedLaboratories([appointmentFromScheduleSelector.laboratory])
	}
	// on charge la vue semaine
	calendar.views.find((view: any) => view.modeName === "weekResources")?.show()
	calendar.refresh()
	if (!appointmentFromScheduleSelector.id) {
		calendar.trigger("fetchSchedules")
		return
	}
	// Une fois les évènements chargés on peut sélectionner l’événement correspondant si l'on
	// vient de "mes-rendez-vous"
	setTimeout(() => {
		if (!appointmentFromScheduleSelector.id) return
		const selectedEvents = calendar.eventStore.getById(appointmentFromScheduleSelector.id)
		if (selectedEvents) {
			calendar.selectEvent(
				// @ts-ignore
				calendar.eventStore.getById(appointmentFromScheduleSelector.id)
			)
			// active le déplacement du rdv sélectionné au click sur l’agenda
			calendar.isMoving = true
		} else {
			console.error("event not found")
		}
	}, 1500)
}

export const setDuration = (duration: number, durationUnit: "hour" | "day" | "minute" | "m"): number => {
	// ici la duration est celle du type donc toujours en minute
	switch (durationUnit) {
		case "hour":
			return duration / 60
		case "day":
			return duration / 60 / 24
		case "minute":
		case "m":
			return duration
		default:
			return 0
	}
}

export const setDurationInMinute = (duration: number, durationUnit: "hour" | "day" | "minute" | "m"): number => {
	// ici la duration est celle du type donc toujours en minute
	switch (durationUnit) {
		case "hour":
			return duration * 60
		case "day":
			return duration * 60 * 24
		case "minute":
		case "m":
			return duration
		default:
			return 0
	}
}

export const clearInput = (input: { parentNode: any }, child: number): void => {
	while (input.parentNode.children.length > 3) {
		input.parentNode.removeChild(input.parentNode.children[child])
	}
}

export const detectPhone = (): boolean => {
	const regexPhone =
		/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
	const userAgent = window.navigator.userAgent
	return regexPhone.test(userAgent)
}

export const setStartTimeAndWorkingDays = (calendar: AWCalendar, displayedUsers: string[]): void => {
	if (!calendar.extraData.attendances) return
	const startTime = calendar.extraData.attendances?.getConfigViewFromAttendance(displayedUsers)

	// les vue qui ont déjà étés affichées sont en cache,
	// sans cela, on conserve l’affichage initiale en passant d’une vue à une autre
	calendar.activeView.viewCache?.forEach((view) => {
		if (view.visibleStartTime) {
			view.visibleStartTime = startTime
			// @ts-ignore non documenté
			if (view.scrollToVisibleStartTime) {
				// @ts-ignore non documenté
				view.scrollToVisibleStartTime()
			}
		}
	})

	// Pour les vue non en cache, il faut modifier la configuration de la vue
	calendar.views.forEach((view) => {
		if (view.visibleStartTime) {
			view.visibleStartTime = startTime
		}
		view.viewCache?.forEach((v) => {
			v.visibleStartTime = startTime
		})
	})
}

// calcul l’heure de fin de rdv en fonction de l’heure de début et de la durée
export const calculateEndDate = (startDate: Date, durationMS: number): Date => {
	return new Date(startDate.getTime() + durationMS)
}

export const onRenderView = (view: { viewCache: Widget[] }, viewId?: string): void => {
	// if the view is fetch from cache, the scroll is wrong, we force the calcul of it
	// no optimize, should be somewhere less frequent
	view.viewCache?.forEach((v) => {
		if ((viewId && v.id !== viewId) || !v.scrollable) return
		v.scrollable.y = 0
		// @ts-ignore
		v.scrollToVisibleStartTime()
	})
}

// set the height of the hour in the day view
export const updateHourHeight = (calendar: AWCalendar, hourHeight: number): void => {
	calendar.eachView((v: any) => {
		v.hourHeight = hourHeight
		v.viewCache?.forEach((view: any) => {
			if (view.updateHourHeight) {
				view.hourHeight = hourHeight
				view.updateHourHeight()
			}
		})
		if (v.config.view) {
			v.config.view.hourHeight = hourHeight
		}

		if (v.dayViewTimeAxis) {
			v.dayViewTimeAxis.hourHeight = hourHeight
			v.dayViewTimeAxis.updateHourHeight()
		}
		onRenderView(v)
	})
	calendar.hourHeight = hourHeight

	calendar.modes.dayResources.view.hourHeight = hourHeight
	calendar.modes.weekResources.view.hourHeight = hourHeight
}
