import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { noPrefix, BonLivraisonRoutePrefix, BonLivraisonRoutes } from "./routes"

import ListeBL from "pages/bl/ListeBL"
import NouveauBL from "pages/bl/NouveauBL"

function BonLivraisonRouter(): JSX.Element {
	return (
		<CardWithTabs urlprefix={BonLivraisonRoutePrefix}>
			<CardItem name={"Bon de livraison"} url={noPrefix(BonLivraisonRoutes.List)} component={ListeBL} />

			<CardItem
				name={"Bon de livraison"}
				useSlug="id"
				url={noPrefix(BonLivraisonRoutes.NewEdit)}
				onlyVisibleOnExactRoute
				component={NouveauBL}
			/>
		</CardWithTabs>
	)
}

export default BonLivraisonRouter
