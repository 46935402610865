/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react"
import { Card, CardHeader, CardBody } from "reactstrap"
import { Table, Statistic } from "antd"
import API from "../../services/API"

import { useHistory } from "react-router-dom"
import { Pie } from "@ant-design/charts"
import columnEquipment from "./Tables/Equipments"
import columnLogistic from "./Tables/Logistic"
import columnTransfers from "./Tables/Transfer"
import columnInventory from "./Tables/Inventory"
import columnSerialnumbers from "./Tables/Serialnumbers"
import AuthContext from "../../contexts/AuthContext"
import ToolTip from "react-tooltip"
import AsyncLaboratorySelect from "components/utils/AsyncLaboratorySelect"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import "./style.scss"
import uuid from "react-uuid"

const pieStatus = {
	color: ({ name }) => {
		switch (name) {
			case "ESSAI":
				return "#102477"
			case "ATTENTE":
				return "#eff2f7"
			case "VENDU":
				return "#102477"
			case "SAV":
				return "#ffab00"
			default:
				return "#eff2f7"
		}
	},
}

const pieConfig = {
	angleField: "total",
	colorField: "name",
	radius: 1.0,
	legend: false,
	interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
	label: {
		type: "inner",
		content: " ",
	},
	tooltip: {
		formatter: (data) => {
			return { name: data.name, value: data?.total?.toLocaleString("fr") }
		},
	},
}

const ListeAppareille = ({ match }) => {
	const { laboratory } = useContext(AuthContext)

	const history = useHistory()

	const [selectedLaboratory, setSelectedLaboratory] = useState(laboratory)

	const [stats, setStats] = useState({})
	const [data, setData] = useState([])

	const [isLoading, setIsLoading] = useState({ logisticProducts: true })

	const param = match?.params

	const { data: productStock, isFetching: loadingProductStock } = useQuery(
		["PRODUCT_STOCK_AVAILABLE", param.id, selectedLaboratory.warehouseIdHiboutik],
		async () => {
			let product = await API.findAll(
				"PRODUCT_WITH_STOCK_API",
				`/${param.id}?warehouseId=${selectedLaboratory.warehouseIdHiboutik}`
			)

			product.stockAvailable = product.stockAvailable?.map((stock) => ({ ...stock, key: uuid() }))

			return product
		},
		{
			placeholderData: {},
		}
	)

	const getStats = () => {
		try {
			let count = {
					ATTENTE: 0,
					ESSAI: 0,
					SAV: 0,
					VENDU: 0,
				},
				countChart = [],
				stockCurrentLaboratory = 0,
				stockAllLaboratory = 0,
				logisticWaiting = 0,
				logisticDepot = 0

			for (const eq of data.equipments ?? []) {
				if (!count?.[eq.status]) count[eq.status] = 1
				else count[eq.status]++
			}

			for (const status in count) {
				countChart.push({
					name: status,
					total: count[status],
				})
			}

			for (const logistic of data.logistics ?? []) {
				if (logistic.state === "WAITING") logisticWaiting++
				if ((logistic.depotState === "EN_DEPOT" || logistic?.isDepot) && logistic.state === "RECEIVED")
					logisticDepot++
			}
			for (const stock of productStock.stockAvailable ?? []) {
				stockCurrentLaboratory += stock.quantity
			}

			setStats({
				statusCount: count,
				statusCountChart: countChart,
				stockCurrentLaboratory,
				stockAllLaboratory,
				logisticWaiting,
				logisticDepot,
			})
		} catch (error) {
			console.error(error)
		}
	}

	const getLogisticProductsPaginated = async () => {
		try {
			setIsLoading((old) => ({ ...old, logisticProducts: true }))
			let page = 1
			let results = []

			while (true) {
				const tmp = await API.findAll(
					"LOGISTIC_PRODUCTS_API",
					`?productId=${param.id}&laboratory=${selectedLaboratory["@id"]}&page=${page}&itemsPerPage=30`,
					true
				)

				results = [...results, ...tmp["hydra:member"]]

				if (results.length >= tmp["hydra:totalItems"]) break
				page++
			}

			setData((old) => ({ ...old, logistics: results }))
			return results
		} catch (error) {
			console.error(error)
			toast.error("Impossible de récupérer les bons de livraisons associés")
		} finally {
			setIsLoading((old) => ({ ...old, logisticProducts: false }))
		}
	}

	const getPatientEquipmentsPaginated = async () => {
		try {
			setIsLoading((old) => ({ ...old, patientEquipments: true }))

			let page = 1
			let results = []

			while (true) {
				const tmp = await API.findAll(
					"PATIENT_EQUIPMENTS_API",
					`?productIdHiboutik=${param.id}&laboratory=${selectedLaboratory["@id"]}&page=${page}&pagination=true`,
					true
				)

				results = [...results, ...tmp["hydra:member"]]

				if (results.length >= tmp["hydra:totalItems"]) break
				page++
			}

			setData((old) => ({ ...old, equipments: results.map((res) => ({ ...res, key: uuid() })) }))
		} catch (error) {
			console.error(error)
			toast.error("Impossible de récupérer les équipements patients associés")
		} finally {
			setIsLoading((old) => ({ ...old, patientEquipments: false }))
		}
	}

	const getTransfersPaginated = async () => {
		try {
			setIsLoading((old) => ({ ...old, transferProducts: true }))

			let page = 1
			let results = []

			while (true) {
				const tmp = await API.findAll(
					"TRANSFER_PRODUCT_API",
					`?productId=${param.id}&page=${page}&pagination=true`,
					true
				)

				results = [...results, ...tmp["hydra:member"]]

				if (results.length >= tmp["hydra:totalItems"]) break
				page++
			}

			setData((old) => ({ ...old, transferProducts: results.map((res) => ({ ...res, key: uuid() })) }))
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading((old) => ({ ...old, transferProducts: false }))
		}
	}

	const getInventoryPaginated = async () => {
		try {
			setIsLoading((old) => ({ ...old, inventoryProducts: true }))

			let page = 1
			let results = []

			while (true) {
				const tmp = await API.findAll(
					"INVENTORY_PRODUCTS_API",
					`?productId=${param.id}&page=${page}&pagination=true&state=DIFFERENCE`,
					true
				)

				results = [...results, ...tmp["hydra:member"]]

				if (results.length >= tmp["hydra:totalItems"]) break
				page++
			}

			setData((old) => ({ ...old, inventoryProducts: results.map((res) => ({ ...res, key: uuid() })) }))
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading((old) => ({ ...old, inventoryProducts: false }))
		}
	}

	useEffect(() => {
		getStats()
	}, [data])

	useEffect(() => {
		setData({ equipments: [], histories: [], logistics: [] })

		if (loadingProductStock || !selectedLaboratory) return

		getLogisticProductsPaginated()
		getPatientEquipmentsPaginated()
		getTransfersPaginated()
		getInventoryPaginated()
	}, [loadingProductStock, selectedLaboratory])

	return (
		<>
			<CardHeader tag="h5">
				<div className="d-flex justify-content-space-between align-items-center">
					<div className="col">
						<h4>Fiche produit : {productStock?.model || "..."}</h4>
					</div>
					<div style={{ width: "300px" }}>
						<AsyncLaboratorySelect
							className="w-100"
							allowClear={false}
							size="large"
							onChange={(_, c) => {
								setSelectedLaboratory(c)
							}}
							warehouseOnly={true}
							value={selectedLaboratory}
						/>
					</div>
					<div className="col-4" style={{ maxWidth: "240px" }}>
						<div className="btn-group w-100" role="group">
							<button
								type="button"
								className="btn btn-secondary p-2 pl-3 pr-3 "
								onClick={() => {
									history.push("/parametres/catalogues/produit/" + param?.id)
								}}>
								<i className="fad fa-pencil mr-2" /> Modifier le produit
							</button>
						</div>
					</div>
				</div>
			</CardHeader>
			<CardBody>
				<div className="row mb-4 d-flex align-items-center">
					<div className="col" style={{ height: "120px" }}>
						<Pie {...pieConfig} {...pieStatus} data={stats?.statusCountChart || []} />
					</div>
					<div className="col" data-tip={"Laboratoire courant"}>
						<Statistic
							groupSeparator={" "}
							title="stock laboratoire"
							value={stats?.stockCurrentLaboratory ?? "-"}
						/>
						<ToolTip />
					</div>
					<div className="col">
						<Statistic groupSeparator={" "} title="en attente" value={stats?.statusCount?.ATTENTE ?? "-"} />
					</div>
					<div className="col">
						<Statistic groupSeparator={" "} title="en essai" value={stats?.statusCount?.ESSAI ?? "-"} />
					</div>
					<div className="col">
						<Statistic groupSeparator={" "} title="équipé" value={stats?.statusCount?.VENDU ?? "-"} />
					</div>
					<div className="col">
						<Statistic groupSeparator={" "} title="dépôt" value={stats?.logisticDepot ?? "-"} />
					</div>
					<div className="col">
						<Statistic groupSeparator={" "} title="non-réceptionné" value={stats?.logisticWaiting ?? "-"} />
					</div>
				</div>
				<div className="semi-title">
					Disponible (
					{(productStock?.stockAvailable ?? [])?.reduce((acc, product) => +product.quantity + +acc, 0) ?? 0})
				</div>
				<Table
					size={"small"}
					pagination={{ position: ["bottomLeft"] }}
					columns={columnSerialnumbers}
					loading={{
						tip: "Chargement...",
						spinning: loadingProductStock,
					}}
					showHeader
					bordered={true}
					dataSource={productStock?.stockAvailable ?? []}
					className="mb-1"
				/>
				<div className="semi-title">Patient ({data.equipments?.length ?? 0})</div>
				<Table
					size={"small"}
					pagination={{ position: ["bottomLeft"] }}
					columns={columnEquipment}
					loading={{
						tip: "Chargement...",
						spinning: isLoading?.patientEquipments,
					}}
					showHeader
					bordered={true}
					dataSource={data.equipments || []}
					className="mb-1"
				/>

				<div className="semi-title">Bon de livraisons ({data.logistics?.length ?? 0})</div>
				<Table
					size={"small"}
					pagination={{ position: ["bottomLeft"] }}
					columns={columnLogistic}
					loading={{
						tip: "Chargement...",
						spinning: isLoading?.logisticProducts,
					}}
					showHeader
					bordered={true}
					dataSource={data.logistics || []}
				/>

				<div className="semi-title">Transferts ({data.transferProducts?.length ?? 0})</div>
				<Table
					size={"small"}
					pagination={{ position: ["bottomLeft"] }}
					columns={columnTransfers}
					loading={{
						tip: "Chargement...",
						spinning: isLoading?.transferProducts,
					}}
					showHeader
					bordered={true}
					dataSource={data.transferProducts || []}
				/>

				<div className="semi-title">Inventaires ({data.inventoryProducts?.length ?? 0})</div>
				<Table
					size={"small"}
					pagination={{ position: ["bottomLeft"] }}
					columns={columnInventory}
					loading={{
						tip: "Chargement...",
						spinning: isLoading?.inventoryProducts,
					}}
					showHeader
					bordered={true}
					dataSource={data.inventoryProducts || []}
				/>
			</CardBody>
		</>
	)
}

export default ListeAppareille
