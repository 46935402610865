import { Accounting } from "@audiowizard/common"
import { CashOutflowReason } from "pages/Settings/CashOutflowReason/CashOutflowReasonSetting"

export type CashType =
	| "CINQ_CENT_EUROS"
	| "DEUX_CENT_EUROS"
	| "CENT_EUROS"
	| "CINQUANTE_EUROS"
	| "VINGT_EUROS"
	| "DIX_EUROS"
	| "CINQ_EUROS"
	| "DEUX_EUROS"
	| "UN_EUROS"
	| "CINQUANTE_CTS"
	| "VINGT_CTS"
	| "DIX_CTS"
	| "CINQ_CTS"
	| "DEUX_CTS"
	| "UN_CTS"
	| "total"

export type CashRecord = Partial<Record<CashType, number>>

export interface CashItem {
	id: number
	label: string
	name: CashType
	value: number
	type: "ESP"
}

export enum typeAccounting {
	validationEspece = "VALIDATION_ESPECE",
	reassortEspece = "REASSORT_ESPECE",
	depotEspece = "DEPOT_ESPECE",
	sortieEspece = "SORTIE_ESPECE",
}

export interface FullAccounting extends Accounting {
	cashOutflowReason?: CashOutflowReason
	data?: {
		cashfund: CashRecord
		state: CashRecord
	}
	receipt?: string
	cashOutflowVats?: CashOutflowVat[]
	cancelAccounting?: string
	replaceAccounting?: string
	cancelDate: Date
}

export interface LastState extends FullAccounting {
	state: CashRecord
	change: CashRecord
}

export const moneyItems: CashItem[] = [
	{
		id: 1,
		label: "500€",
		name: "CINQ_CENT_EUROS",
		value: 500,
		type: "ESP",
	},
	{
		id: 2,
		label: "200€",
		name: "DEUX_CENT_EUROS",
		value: 200,
		type: "ESP",
	},
	{
		id: 3,
		label: "100€",
		name: "CENT_EUROS",
		value: 100,
		type: "ESP",
	},
	{
		id: 4,
		label: "50€",
		name: "CINQUANTE_EUROS",
		value: 50,
		type: "ESP",
	},
	{
		id: 5,
		label: "20€",
		name: "VINGT_EUROS",
		value: 20,
		type: "ESP",
	},
	{
		id: 6,
		label: "10€",
		name: "DIX_EUROS",
		value: 10,
		type: "ESP",
	},
	{
		id: 7,
		label: "5€",
		name: "CINQ_EUROS",
		value: 5,
		type: "ESP",
	},
	{
		id: 8,
		label: "2€",
		name: "DEUX_EUROS",
		value: 2,
		type: "ESP",
	},
	{
		id: 9,
		label: "1€",
		name: "UN_EUROS",
		value: 1,
		type: "ESP",
	},
	{
		id: 10,
		label: "50cts",
		name: "CINQUANTE_CTS",
		value: 0.5,
		type: "ESP",
	},
	{
		id: 11,
		label: "20cts",
		name: "VINGT_CTS",
		value: 0.2,
		type: "ESP",
	},
	{
		id: 12,
		label: "10cts",
		name: "DIX_CTS",
		value: 0.1,
		type: "ESP",
	},
	{
		id: 13,
		label: "5cts",
		name: "CINQ_CTS",
		value: 0.05,
		type: "ESP",
	},
	{
		id: 14,
		label: "2cts",
		name: "DEUX_CTS",
		value: 0.02,
		type: "ESP",
	},
	{
		id: 15,
		label: "1cts",
		name: "UN_CTS",
		value: 0.01,
		type: "ESP",
	},
]

export interface CashOutflowVat {
	id?: number
	"@id"?: string
	taxRate: number
	taxFreeAmount: number
	allTaxIncludeAmount: number
	vatAmount: number
}

export const defaultTax = [
	{
		taxRate: 20,
		value: 0,
	},
	{
		taxRate: 10,
		value: 0,
	},
	{
		taxRate: 8.5,
		value: 0,
	},
	{
		taxRate: 5.5,
		value: 0,
	},
	{
		taxRate: 2.1,
		value: 0,
	},
	{
		taxRate: 0,
		value: 0,
	},
]
