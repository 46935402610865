import React from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap"
import PropTypes from "prop-types"

interface PropModalEmptyTags {
	onConfirm?: () => void
	onClose?: () => void
	emptyTags: boolean
}

const ModalEmptyTags: React.FC<PropModalEmptyTags> = ({ onConfirm, onClose, emptyTags }) => {
	return (
		<Modal isOpen={emptyTags} backdrop="static">
			<ModalHeader>
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-ear" /> {/* <-- ICON */}
					</div>

					<h6 className="mb-0">Modification non sauvegardée</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>
			<ModalBody>
				<p>Il y a des balises non renseignées sur la page. Elle apparaîtrons vide dans le document</p>
				<p>Souhaitez vous continuer l'action ?</p>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={onClose}>
					Annuler
				</Button>
				<Button color="primary" onClick={onConfirm}>
					Confirmer
				</Button>
			</ModalFooter>
		</Modal>
	)
}

ModalEmptyTags.propTypes = {
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
	emptyTags: PropTypes.bool.isRequired,
}

export default ModalEmptyTags
