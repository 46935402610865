import { useContext } from "react"

import AuthContext from "../../contexts/AuthContext"
import { LeftBar } from "awui"

const { Link } = LeftBar

export default function AffiliateLeftBar(): JSX.Element {
	const { user, patientUI, uiDisplay } = useContext(AuthContext)

	const shouldBeHidden =
		!user.gdprAgreement || patientUI || uiDisplay?.missingMandate || uiDisplay?.onboarding === true

	if (shouldBeHidden) {
		return <div className="row nav-application clearfix pt-2 justify-content-center" />
	}

	return (
		<LeftBar>
			<Link to="/tableau-de-bord" icon="user-md" label="Tableau de bord" />
			<Link to="/" icon="chart-area" label="Statistiques" />
		</LeftBar>
	)
}
