/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-throw-literal */
import { GenerateJuxtaXML, findActiveAMx, findActiveAmFromFSV } from "./utilsFSV"
import axios from "axios"
import { FSV_WEBSERVICES_API } from "config"
import { xml2json } from "xml-js"
import dayjs from "dayjs"
import { repairPrescriberRpps, sendDocumentSuivi } from "./functionsFSV"
import { Patient, Prescriber } from "@audiowizard/common"
import { modeTeletrans } from "./FSV.type"

// Fonction complètement générique pouvant être exportée dans fonction.js pour utilisation sur n'importe quelle donnée venant de xml2json
const xmljs2json = (object: { [key: string]: any }): { [key: string]: any } => {
	const result: any = {}

	for (const key in object) {
		if (["_declaration", "_attributes"].includes(key)) continue
		if (typeof object[key] !== "object") continue

		if (object[key]?._text) {
			result[key] = object[key]?._text
		} else {
			result[key] = xmljs2json(object[key])
		}
	}
	return result
}

/**
 * Fonction pour chercher de manière récursive dans un objet (générique)
 *
 * @param {any} haystack Object dans lequel rechercher la clé
 * @param {string} needle Clé à rechercher dans l'objet
 * @param {boolean} returnParent Retourne l'objet dans lequel la clé à été trouvé, ou l'objet parent
 */
const recursivelySearchForKey = (haystack: any, needle: string, returnParent = false, parent: any = null): any => {
	let foundResult = null

	for (const key in haystack) {
		if (typeof haystack[key] === "object") {
			foundResult = recursivelySearchForKey(haystack[key], needle, returnParent, haystack)
			if (foundResult) return foundResult
		}
		if (key.includes(needle)) return returnParent ? parent : haystack[key]
	}
	return foundResult
}
// /FIN

const checkForError = (data: any): boolean => {
	const error = recursivelySearchForKey(data, "erreur")
	if (error?.libelleErreur || error?.codeErreur) {
		throw {
			code: error?.codeErreur,
			message: error?.libelleErreur,
		}
	}
	return false
}

const parseXmlFile = (xmlData: string): { [key: string]: any } => {
	const data: string = xml2json(xmlData, { compact: true })
	const xmljsJson = JSON.parse(data)
	const result = xmljs2json(xmljsJson)
	return result
}

const getResponse = async (fsvData: any): Promise<any> => {
	try {
		const fsvResult = await axios.post(FSV_WEBSERVICES_API, fsvData)
		const result = parseXmlFile(fsvResult?.data)
		checkForError(result)
		return result
	} catch (error) {
		console.error(error)
		throw error
	}
}

/*
 ** Début des getters
 */

const consulterFacture = async (idFacture: number, numFiness: string): Promise<any> => {
	const fsvData = GenerateJuxtaXML("ConsulterFacture", {
		appelConsulterFacture: { idFacture, numFiness },
	})
	const dataJson = await getResponse(fsvData)
	return dataJson
}

const listeParEtat = async (finess: string, etat: string | null = null): Promise<any> => {
	const supl: any = {}

	if (etat) supl.listeEtat = etat

	const fsvData: any = GenerateJuxtaXML("ListeFseParEtat", {
		appelListeFseParEtat: { finess, ...supl },
	})
	return await getResponse(fsvData)
}

const consulterConvention = async (idtPat: any, date: string): Promise<any> => {
	const fsvData = GenerateJuxtaXML("ConsulterConventions", {
		appelConsulterConventions: {
			idtPat,
			dateReference: {
				format: "yyyyMMdd",
				value: date,
			},
		},
	})
	return await getResponse(fsvData)
}

const selectionnerConvention = async (idtPat: any, idtConvention: string, date: string): Promise<any> => {
	const fsvData = GenerateJuxtaXML("selectionnerConvention", {
		appelSelectionnerConvention: {
			idtPat,
			idtConvention,
			dateReference: {
				format: "yyyyMMdd",
				value: date,
			},
		},
	})
	return await getResponse(fsvData)
}

const consulterClient = async (idPatient: number | undefined): Promise<any> => {
	if (!idPatient) return null
	const fsvData = GenerateJuxtaXML("ConsulterClient", {
		appelConsulterClient: {
			idPatient,
		},
	})
	return await getResponse(fsvData)
}

const consulterUtilisateur = async (
	nom: string,
	prenom: string,
	typeIdentifiant: string,
	estArchive: string
): Promise<any> => {
	const fsvData = GenerateJuxtaXML("consulterUtilisateur", {
		appelConsulterUtilisateur: {
			Nom: nom,
			Prenom: prenom,
			typeIdentifiant,
			estArchive,
		},
	})
	return await getResponse(fsvData)
}

const isAldActive = async (patient: Patient): Promise<boolean | undefined> => {
	try {
		const { amo }: any = await findActiveAMx(patient)
		if (amo && amo?.ald) return true
		else return false
	} catch (error) {
		console.error(error)
	}
}

const isTpAmcSearch = async (patient: Patient): Promise<string> => {
	const patientData = await consulterClient(patient.idFSV)

	// peut-être des tableaux de couvertureAMC
	const amcCover = findActiveAmFromFSV(recursivelySearchForKey(patientData, "couvertureAMC"))

	return amcCover?.isTp ?? "false"
}

const transmettreFactureSuivi = async (
	patient: Patient,
	numNatPs: string,
	numFiness: string,
	datePrescription: string,
	dateFacturation: string,
	dateExecution: string,
	prescripteur: Prescriber,
	mode: modeTeletrans
): Promise<number | string> => {
	const documents: string = await sendDocumentSuivi()
	const idPatient: number | undefined = patient.idFSV
	const isAld: boolean = (await isAldActive(patient)) ?? false
	const isTpAmc = await isTpAmcSearch(patient)

	const data: any = {
		appelTransmettreFacture: {
			identification: {
				idPatient,
				numNatPs,
				numFiness,
				source: "AudioWizard",
				datePrescription: {
					format: "yyyyMMdd",
					value: datePrescription,
				},
				dateFacturation: {
					format: "yyyyMMdd",
					value: dateFacturation,
				},
				dateReferenceAmc: "FACT",
				ParcoursDeSoin: {
					situationParcoursDeSoin: "EXCLU",
				},
				modeSecurisation: mode,
				isTpAmo: "true",
				isTpAmc,
				prescripteur: {
					codeConditionExercice: "L",
					numIdFacPresc: prescripteur.finess?.slice(0, -1),
					numIdFacPrescCle: prescripteur.finess?.slice(-1),
					codeSpecialite: prescripteur.category === "ORL" ? "11" : "01",
					typeIdent: 1,
					rppsPresc: repairPrescriberRpps(prescripteur.rpps?.slice(0, -1)),
					rppsPrescCle: prescripteur.rpps?.slice(-1),
					estPrescipteurSncf: "false",
				},
			},
			acte: {
				codeActe: 2305927,
				qte: 1,
				montantHonoraire: 0.01,
				libelle: "Suivi",
				montantAmo: 0.01,
				montantAmc: 0,
				dateExecution: {
					format: "yyyyMMdd",
					value: dateExecution,
				},
				numero: 0,
				isAld,
			},
			scor: {
				loginGed: "?",
				urlWbsGed: "?",
				passwordGed: "?",
				idUtfMetier: "1",
				domaineGed: "FSV",
				idDossierGedPatient: "21",
				listeDocuments: documents,
			},
		},
	}

	const fsvData = GenerateJuxtaXML("TransmettreFacture", data)
	return await getResponse(fsvData)
}

const transmettrePatient = async (finess: string, rpps: string, patient: Patient): Promise<number | string> => {
	const birthDate = dayjs(patient.birthDate)
	const dateNaissance = {
		jour: birthDate.date(),
		mois: birthDate.month() + 1,
		annee: birthDate.year(),
		lunaire: false,
	}

	const fsvData = GenerateJuxtaXML("transmettrePatient", {
		appelTransmettrePatient: {
			idPatient: -1,
			idAssure: -1,
			nom: patient.lastName,
			prenom: patient.firstName,
			dateNaissance,
			numeroSS: patient.idSecu?.slice(0, 13),
			cleNumeroSS: patient.idSecu?.slice(13, 15),
			//@ts-ignore TODO: OLIVIER : A TYPER CORRECTEMENT
			rangNaissance: patient.rangNaissance || 1,
			sexe: patient.gender,
			declarationMedecinTraitant: "nonRenseigne",
			//@ts-ignore TODO: OLIVIER : A TYPER CORRECTEMENT
			codeServiceAMO: patient.codeServiceAMO,
			numFacturation: finess,
			numRpps: rpps,
			// dateDebutDroit : {
			//     format: "?",
			//     value: ""
			// }
			// dateFinDroit : {
			//     format: "?",
			//     value: ""
			// }
		},
	})
	return await getResponse(fsvData)
}

/**
 * Function for updating NOEMIE data coming from CPAMs
 *
 * @param {string} finess finess from user
 * @param {string} rpps rpps from user
 */
const verifierRetours = async (finess: string, rpps: string): Promise<any> => {
	if (!finess || !rpps) return
	const fsvData = GenerateJuxtaXML("VerifierRetours", {
		appelVerifierRetours: {
			numFiness: finess,
			numNatPs: rpps,
		},
	})
	return await getResponse(fsvData)
}

const FsvApi = {
	getResponse,
	consulterFacture,
	listeParEtat,
	consulterConvention,
	consulterClient,
	selectionnerConvention,
	consulterUtilisateur,
	transmettreFactureSuivi,
	transmettrePatient,
	parseXmlFile,
	checkForError,
	recursivelySearchForKey,
	verifierRetours,
}

export default FsvApi
