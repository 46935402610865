import { useMutation, useQueryClient } from "react-query"
import {
	userInsuranceCredentialsService,
	userInsuranceCredentialsServiceKey,
} from "../userInsuranceCredentials.service"

export default function useCreateUserInsuranceCredentials() {
	const client = useQueryClient()

	return useMutation({
		mutationFn: userInsuranceCredentialsService.create,
		onSuccess: () => {
			client.invalidateQueries([userInsuranceCredentialsServiceKey])
		},
	})
}
