import { CardItem, CardWithTabs } from "components/CardWithTabs/Card"
import { MutuellesRoutes, noPrefix } from "./routes"

import useCustomTitle from "components/Hooks/useTitle"
import { ParametreRoutePrefix } from "router/Parametre/routes"
import Mutuelles from "../../pages/mon-compte/MesMutuelles"
import UserInsuranceNetworkPage from "../../pages/mon-compte/UserInsuranceNetwork/UserInsuranceNetworkPage"

function MutuellesRouter(): JSX.Element {
	useCustomTitle("Paramètres | Mutuelles")
	return (
		<CardWithTabs urlprefix={ParametreRoutePrefix}>
			<CardItem name="Mutuelles" url={noPrefix(MutuellesRoutes.Mutuelles)} component={Mutuelles} />
			<CardItem name="Réseaux" url={noPrefix(MutuellesRoutes.Ocam)} component={UserInsuranceNetworkPage} />
		</CardWithTabs>
	)
}

export default MutuellesRouter
