import { Laboratory, SubCompany } from "@audiowizard/common"
import axios from "axios"
import AdressInput from "components/forms/AdressInput"
import FieldWithError from "components/forms/FieldWithError"
import AuthContext from "contexts/AuthContext"
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap"
import API from "services/API"
import { IsObjectEmpty, isImageSizeLegal } from "services/functions"

type SubCompanyModalProps = {
	open: boolean
	onClose: () => void
	subCompany?: SubCompany | null | undefined
	defaultValues?: {
		legalLabel: string
		legalAddress: string
		legalCity: string
		legalZipCode: string
		siret: string
		rcs: string
		capitalStock: number
		vatNumber: string
		mailCustomerInvoice: string
		logo: string
	}
}

export default function SubCompanyModal({
	open,
	onClose,
	subCompany,
	defaultValues = {
		legalLabel: "",
		legalAddress: "",
		legalCity: "",
		legalZipCode: "",
		siret: "",
		rcs: "",
		capitalStock: 0,
		vatNumber: "",
		mailCustomerInvoice: "",
		logo: "",
	},
}: SubCompanyModalProps): JSX.Element {
	const queryClient = useQueryClient()
	const { user, laboratory, setLaboratory, setLaboratories } = useContext(AuthContext)

	const [legalLabel, setlegalLabel] = useState("")
	const [legalAddress, setlegalAddress] = useState("")
	const [legalCity, setlegalCity] = useState("")
	const [legalZipCode, setlegalZipCode] = useState("")
	const [siret, setSiret] = useState("")
	const [rcs, setRcs] = useState("")
	const [capitalStock, setCapitalStock] = useState(0)
	const [vatNumber, setVatNumber] = useState("")
	const [mailCustomerInvoice, setMailCustomerInvoice] = useState("")
	const [logoUrl, setLogoUrl] = useState<string | null>()
	const [mediator, setMediator] = useState("")

	const [errors, setErrors] = useState<Record<string, string | undefined>>({})

	const isEditing = subCompany != null

	const subCompanyFormRef = useRef<HTMLFormElement>(null)

	// Sync state with subCompany
	useEffect(() => {
		setErrors({})

		if (subCompany == null) {
			setlegalLabel(defaultValues.legalLabel)
			setlegalAddress(defaultValues.legalAddress)
			setlegalCity(defaultValues.legalCity)
			setlegalZipCode(defaultValues.legalZipCode)
			setSiret(defaultValues.siret)
			setRcs(defaultValues.rcs)
			setCapitalStock(defaultValues.capitalStock)
			setVatNumber(defaultValues.vatNumber)
			setMailCustomerInvoice(defaultValues.mailCustomerInvoice)
			setLogoUrl(null)
			setMediator("")
		} else {
			setlegalLabel(subCompany.legalLabel ?? "")
			setlegalAddress(subCompany.legalAddress ?? "")
			setlegalCity(subCompany.legalCity ?? "")
			setlegalZipCode(subCompany.legalZipCode ?? "")
			setSiret(subCompany.siret ?? "")
			setRcs(subCompany.rcs ?? "")
			setCapitalStock(subCompany.capitalStock ?? 0)
			setVatNumber(subCompany.vatNumber ?? "")
			setMailCustomerInvoice(subCompany.mailCustomerInvoice ?? "")
			setLogoUrl(subCompany?.logo ?? null)
			setMediator(subCompany.mediator ?? "")
		}
	}, [subCompany])

	// Libère la mémoire utilisé par logoUrl
	useEffect(() => {
		if (logoUrl) {
			return () => {
				URL.revokeObjectURL(logoUrl)
			}
		}
	}, [logoUrl])

	const handleSubCompanyLogo = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		setErrors({})
		setLogoUrl(null)

		const logo = event.target.files?.[0]
		if (!logo) return
		// Libère la mémoire de l'ancien fichier uploadé
		if (logoUrl) {
			URL.revokeObjectURL(logoUrl)
		}

		if (!(await isImageSizeLegal(logo))) {
			setErrors((old) => ({ ...old, logo: "L'image ne respecte pas les dimensions demandées (1000px x 1000px)" }))
			return
		}

		const logoBinaryData = []
		logoBinaryData.push(logo as Blob)
		setLogoUrl(URL.createObjectURL(new Blob(logoBinaryData, { type: "application/zip" })))
	}

	const { mutate: createOrUpdateSubCompany, isLoading: isLoadingCreateOrUpdate } = useMutation(
		async () => {
			const formData = new FormData(subCompanyFormRef.current!)

			if (isEditing) {
				await API.updateSubCompany(subCompany!.id!, formData)
			} else {
				await API.create<SubCompany>("SUB_COMPANIES_API", formData)
			}
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries("SUB_COMPANY_API")
				await queryClient.invalidateQueries("SUB_COMPANIES_API")
				await queryClient.invalidateQueries("LABORATORIES_API")
				// Manually update context laboratories

				const laboratoriesList = await API.findAll<Laboratory[]>(
					"LABORATORIES_API",
					`?user=${user.id}&pagination=false`
				)
				setLaboratories(laboratoriesList)
				const newLaboratory = laboratoriesList.find((l) => l.id === laboratory.id)
				setLaboratory(newLaboratory!)

				// Reset state
				setErrors({})
				setlegalLabel("")
				setlegalAddress("")
				setlegalCity("")
				setlegalZipCode("")
				setSiret("")
				setRcs("")
				setCapitalStock(0)
				setVatNumber("")
				setMailCustomerInvoice("")

				onClose()
			},
			onError: (err) => {
				if (!axios.isAxiosError(err) || err.response?.data?.violations == null) {
					toast.error(`Erreur lors de la ${isEditing ? "mise à jour" : "création"} de la sous-société.`)
					return
				}

				const violations = err.response.data.violations as { propertyPath: string; message: string }[]
				const errorsByField = violations?.reduce<Record<string, string>>((obj, { propertyPath, message }) => {
					obj[propertyPath] = message
					return obj
				}, {})
				setErrors(errorsByField)
			},
		}
	)

	return (
		<Modal isOpen={open} toggle={onClose} centered size="lg">
			<ModalHeader tag="div">
				<div className="d-flex align-items-center">
					<div className="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
						<i className="fad fa-building" />
					</div>

					<h6 className="mb-0">{isEditing ? "Modifier" : "Ajouter"} une Sous-Société</h6>

					<button
						type="button"
						className="icon-sm icon-danger rounded-circle border-0 ml-auto mr-3 close-icon"
						title="Fermer"
						onClick={onClose}>
						<i className="fas fa-times" />
					</button>
				</div>
			</ModalHeader>

			<ModalBody>
				<form ref={subCompanyFormRef}>
					<Row>
						<Col md={12}>
							<FieldWithError
								id="legalLabel"
								name="legalLabel"
								label="Raison Sociale"
								value={legalLabel}
								onChange={(e) => setlegalLabel(e.target.value)}
								error={errors.legalLabel}
							/>
						</Col>
						<Col md={12}>
							<AdressInput
								id="fullAddress"
								value={{ adress: legalAddress, city: legalCity, cpo: legalZipCode }}
								onChange={(val) => {
									setlegalAddress(val.adress)
									setlegalCity(val.city)
									setlegalZipCode(val.cpo)
								}}
							/>
						</Col>
						<Col md={4}>
							<FieldWithError
								id="legalAddress"
								name="legalAddress"
								label="Adresse"
								value={legalAddress}
								onChange={(e) => setlegalAddress(e.target.value)}
								error={errors.legalAddress}
							/>
						</Col>
						<Col md={4}>
							<FieldWithError
								id="legalZipCode"
								name="legalZipCode"
								label="Code Postal"
								maxLength={5}
								value={legalZipCode}
								onChange={(e) => setlegalZipCode(e.target.value)}
								error={errors.legalZipCode}
							/>
						</Col>
						<Col md={4}>
							<FieldWithError
								id="legalCity"
								name="legalCity"
								label="Ville"
								value={legalCity}
								onChange={(e) => setlegalCity(e.target.value)}
								error={errors.legalCity}
							/>
						</Col>
						<Col md={4}>
							<FieldWithError
								id="siret"
								name="siret"
								label="SIRET"
								minLength={14}
								maxLength={14}
								pattern="[0-9]{14}"
								value={siret}
								onChange={(e) => setSiret(e.target.value)}
								error={errors.siret}
							/>
						</Col>
						<Col md={4}>
							<FieldWithError
								id="rcs"
								name="rcs"
								label="Ville RCS"
								value={rcs}
								onChange={(e) => setRcs(e.target.value)}
								error={errors.rcs}
							/>
						</Col>
						<Col md={4}>
							<FieldWithError
								id="vatNumber"
								name="vatNumber"
								label="TVA"
								value={vatNumber}
								onChange={(e) => setVatNumber(e.target.value)}
								error={errors.vatNumber}
							/>
						</Col>
						<Col md={6}>
							<FieldWithError
								id="capitalStock"
								name="capitalStock"
								label="Capital social"
								value={capitalStock}
								type="number"
								onChange={(e) => setCapitalStock(e.target.valueAsNumber)}
								error={errors.capitalStock}
							/>
						</Col>
						<Col md={6}>
							<FieldWithError
								id="mailCustomerInvoice"
								name="mailCustomerInvoice"
								label="Email pour réceptionner toutes les factures patients"
								value={mailCustomerInvoice}
								type="email"
								onChange={(e) => setMailCustomerInvoice(e.target.value)}
								error={errors.mailCustomerInvoice}
							/>
						</Col>
						<Col md={12}>
							<FieldWithError
								name="mediator"
								label="Médiateur de la consommation agréé"
								placeholder="Coordonnées du médiateur"
								onChange={(e) => setMediator(e.target.value)}
								value={mediator}
								error={errors.mediator}
							/>
						</Col>
						<Col md={8}>
							<div>
								<div>Ajouter votre logo</div>
								<div className="custom-file">
									<input
										type="file"
										className="custom-file-input "
										id="inputGroupFile01"
										name="logo"
										onChange={(e) => handleSubCompanyLogo(e)}
										aria-describedby="inputGroupFileAddon01"
										accept="image/*"
									/>
									<label className="custom-file-label" htmlFor="inputGroupFile01">
										Choisissez le fichier (1000px x 1000px)
									</label>
									{errors.logo && <small className="text-danger">{errors.logo}</small>}
								</div>
							</div>
						</Col>
						<Col md={4}>
							{logoUrl && (
								<div className="d-flex justify-content-center">
									<img src={logoUrl} alt="blob" width="100px" height="100px" />
								</div>
							)}
						</Col>
					</Row>
				</form>
			</ModalBody>

			<ModalFooter>
				<Button
					color="primary"
					disabled={isLoadingCreateOrUpdate || !IsObjectEmpty(errors)}
					onClick={() => createOrUpdateSubCompany()}>
					{isLoadingCreateOrUpdate && <Spinner size="sm" color="inherit" />}

					{isEditing ? "Modifier" : "Ajouter"}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
