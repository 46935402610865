import dayjs from "dayjs"
import { Dispatch, MutableRefObject, SetStateAction } from "react"

export const exportToCsv = (
	products: any[],
	setCsvConfig: Dispatch<SetStateAction<Record<string, any>>>,
	csvLinkRef: MutableRefObject<any>
): void => {
	let headers: any[] = []

	headers = [
		{
			label: "Catégorie",
			key: "categoryName",
		},
		{
			label: "Fournisseur",
			key: "supplierName",
		},
		{
			label: "Marque",
			key: "brandName",
		},
		{
			label: "Produit",
			key: "model",
		},
		{
			label: "Déclinaison",
			key: "sizeName",
		},
		{
			label: "Patient",
			key: "patientInfos",
		},
		{
			label: "Numéro de série",
			key: "productSerialNumber",
		},
		{
			label: "Quantité initiale",
			key: "originalQuantity",
		},
		{
			label: "Quantité",
			key: "newQuantity",
		},
		{
			label: "État",
			key: "state",
		},
		{
			label: "Prix d'achat unitaire",
			key: "supplyPrice",
		},
		{
			label: "Prix de vente unitaire",
			key: "price",
		},
		{
			label: "Date d'entrée",
			key: "logisticDateReceipt",
		},
		{
			label: "Status",
			key: "logisticProductIsDepot",
		},
		{
			label: "Date de fin de dépôt",
			key: "logisticProductDepotDateEnd",
		},
	]

	console.log({ products })

	setCsvConfig({
		headers,
		data: products
			.filter((product) => !product?.ghostProduct)
			.map((p) => {
				let state = p.state
				let patientInfos = ""

				if (state === "DIFFERENCE") {
					state = "Différence"
				} else if (state === "NEW") {
					state = "Nouveau produit"
				} else if (state === "NONE") {
					state = "Non précisé"
				} else if (state === "OK") {
					p.newQuantity = p.originalQuantity
				}

				if (p.patientEquipment?.length === 1) {
					patientInfos = `En ${p.patientEquipment[0].status} ${p.patientEquipment[0].patient?.lastName} ${p.patientEquipment[0].patient?.firstName}`
				} else if (p.patientEquipment?.length > 1) {
					patientInfos = "Sur plusieurs patients"
				}

				const suplData = {
					logisticDateReceipt: "--",
					logisticProductIsDepot: "--",
					logisticProductDepotDateEnd: "--",
				}

				if (p.logisticProduct) {
					const depotDateEnd = dayjs(p.logisticProduct.depotDateEnd)

					suplData.logisticDateReceipt = p.logisticProduct.logistic.dateReceipt
					suplData.logisticProductIsDepot = p.logisticProduct.isDepot ? "En dépôt" : "Ferme"
					suplData.logisticProductDepotDateEnd = p.logisticProduct.depotDateEnd

					const isDepotPassed = depotDateEnd.isBefore(dayjs())

					if (isDepotPassed) {
						suplData.logisticProductIsDepot = p.logisticProduct.isDepot ? "Dépôt dépassé" : "Ferme"
					}
				}

				return { ...p, state, patientInfos, ...suplData }
			}),
		filename: "Export_Inventaire_" + dayjs().format("DDMMYYYY_HHmm") + ".csv",
	})

	setTimeout(() => {
		// @ts-ignore
		csvLinkRef.current?.link.click()
	}, 200)
}
