import { useQuery } from "react-query"
import { getDocument } from "../getDocument"

export default function useDocument(id?: string) {
	return useQuery({
		queryFn: async () => {
			const d = await getDocument(id)

			if (!d) return null

			return d.data
		},
		queryKey: ["document", id],
		enabled: Boolean(id),
	})
}
