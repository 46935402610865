import { ParametreRoutePrefix } from "router/Parametre/routes"

export const MutuellesRoutes = {
	Mutuelles: `${ParametreRoutePrefix}/mutuelles`,
	Ocam: `${ParametreRoutePrefix}/mutuelles/reseaux`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(ParametreRoutePrefix, "")
}
