const StatusDCol = () => {
	const statusDRender = (nextSchedule) => {
		return (
			<p
				style={{
					textAlign: "center",
					margin: 0,
					color: nextSchedule ? nextSchedule.color : "lightgrey",
				}}>
				{nextSchedule ? nextSchedule.label : "Aucun"}
			</p>
		)
	}
	return {
		title: null,
		key: "patientStatus",
		dataIndex: "patient.DStatus",
		width: "100px",
		render: statusDRender,
	}
}

export default StatusDCol
