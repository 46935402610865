import { PersonnalisationRoutePrefix } from "router/Personnalisation/routes"

export const ParametreRoutePrefix = "/parametres"

export const ParametreRoutes = {
	Modele: `${ParametreRoutePrefix}/modeles`,
	RappelSms: `${ParametreRoutePrefix}/rappels-sms`,
	Personnalisation: `${PersonnalisationRoutePrefix}/personnalisation-documents`,
	TypeRendezVous: `${ParametreRoutePrefix}/types-rendez-vous`,
	Statuts: `${ParametreRoutePrefix}/statuts`,
	PersonnalisationPack: `${ParametreRoutePrefix}/personnalisation-packs`,
	PersonnalisationUtilisateurs: `${ParametreRoutePrefix}/personnalisation-utilisateurs`,
	PersonnalisationGlobal: `${ParametreRoutePrefix}/personnalisation-global`,
	Paiements: `${ParametreRoutePrefix}/paiements`,
	SortieDeCaisse: `${ParametreRoutePrefix}/sortie-caisse`,
}

export function noPrefix(prefixedRoute: string): string {
	return prefixedRoute.replace(ParametreRoutePrefix, "")
}
