import { Col, Form, InputNumber, Row } from "antd"
import { formatCurrency } from "services/functions"
import { calcHTFromTTC, calcTVAFromTTC, formatTva } from "../caisse.helpers"
import "./TaxInput.scss"

interface TaxInputProps {
	value: number
	tax: number
	setValue: (n: number) => void
}

const TaxInput = ({ tax, value, setValue }: TaxInputProps): JSX.Element => {
	return (
		<Col span={4} className={"col-tax-sortie-caisse"}>
			<Row>
				<Col span={24} className="text-center">
					<span className={"header-tax"}>TVA {formatTva(tax)}%</span>
				</Col>
			</Row>
			<Form.Item
				label={"HT :"}
				style={{
					margin: 0,
				}}>
				<span className="ant-form-text calc-tax">{formatCurrency(calcHTFromTTC(value, tax))}</span>
			</Form.Item>
			<Form.Item
				label={"TVA :"}
				style={{
					margin: 0,
				}}>
				<span className="ant-form-text calc-tax">{formatCurrency(calcTVAFromTTC(value, tax))}</span>
			</Form.Item>
			<Form.Item
				label={"TTC :"}
				style={{
					margin: 0,
				}}>
				<InputNumber
					decimalSeparator={","}
					addonAfter="€"
					precision={2}
					value={value || undefined}
					onChange={(value) => setValue(value)}
					min={0}
					placeholder={"Entrer un montant"}
				/>
			</Form.Item>
		</Col>
	)
}

export default TaxInput
