/** La sidebar est utilisée dans la configuration générale : BryntumAgendaConfig */

import { Sidebar, Button } from "@bryntum/calendar"
import { AWCalendar, Event, Resource } from "./AgendaTypes"

const toggedFilter = ({ button, source, filter }: { button: any; source: Button; filter: string }): void => {
	const calendar = button.up("calendar") as AWCalendar
	const filterId = `${filter}-filter`
	if (source.pressed) {
		calendar.eventStore.removeFilter(filterId)
	} else {
		calendar.eventStore.filter({
			id: filterId,
			property: "state",
			value: filter,
			operator: "!=",
		})
	}
}
export class MySideBar extends Sidebar {
	static get $name(): string {
		return "MySideBar"
	}

	static get type(): string {
		return "mysidebar"
	}

	static get configurable(): any {
		return {
			side: "left",
			items: {
				eventFilter: {
					weight: 140,
					filterFunction(record: Event, value: string): boolean {
						if (record.name.toLowerCase().includes(value.toLowerCase())) return true
						if (record.typeLabel.toLowerCase().includes(value.toLowerCase())) return true
						return false
					},
				},
				stateTitle: {
					type: "label",
					// Add simple title just before stateFilter
					weight: 145,
					cls: "resource-filter-title",
					html: "Statut",
				},
				stateFilter: {
					type: "buttonGroup",
					id: "statutButtons",
					weight: 150,
					items: [
						{
							id: "btn-missed",
							icon: "b-fa-exclamation-circle text-danger",
							toggleable: true,
							tooltip: "Manqué",
							onToggle({ source }: { source: Button }) {
								toggedFilter({ button: this, source, filter: "MISSED" })
							},
							text: "",
							pressed: true,
						},
						{
							id: "btn-cancelled",
							icon: "b-fa-times-circle text-warning",
							toggleable: true,
							tooltip: "Annulé",
							onToggle({ source }: { source: Button }) {
								toggedFilter({ button: this, source, filter: "CANCELLED" })
							},
							text: "",
							pressed: true,
						},
						{
							id: "btn-done",
							icon: "b-fa-check-circle text-primary",
							toggleable: true,
							tooltip: "Terminé",
							onToggle({ source }: { source: Button }) {
								toggedFilter({ button: this, source, filter: "DONE" })
							},
							text: "",
							pressed: true,
						},
					],
				},
				resourceTitle: {
					type: "label",
					// Add simple title just before resourceFilter
					weight: 185,
					cls: "resource-filter-title",
					html: "Utilisateurs",
				},
				resourceFilterFilter: {
					// Inserts just before the resourceFilter List
					weight: 190,

					// Shows a clear trigger
					clearable: true,

					placeholder: "Filter utilisateur",
					labelPosition: "above",
					type: "textfield",
					keyStrokeChangeDelay: 100,

					// "up." means resolve in owner will call on the Calendar
					onChange({ value }: { value: string }): void {
						// A filter with an id replaces any previous filter with that id.
						// Leave any other filters which may be in use in place.
						// @ts-ignore
						this.up("calendar").widgetMap.resourceFilter.store.filter({
							id: "resourceFilterFilter",
							filterBy: (r: Resource) => r.name.toLowerCase().startsWith(value.toLowerCase()), // a function which returns true to include the record
						})
					},
				},
				resourceFilter: {
					type: "myResourceFilter",
					weight: 200,
					minHeight: 200,
				},
				laboratoryTitle: {
					type: "label",
					// Add simple title just before resourceFilter
					weight: 299,
					cls: "resource-filter-title",
					html: "Laboratoires",
				},
				laboratoryFilter: {
					weight: 300,
					minHeight: 150,
					field: "name",
					type: "combo",
					multiSelect: true,
					listCls: "custom-resource-filter",
					// The value is the records selected
					valueField: null,

					// What narrowing by typing works on
					displayField: "name",

					// Show the list fixed below
					inlinePicker: true,
					contentElementCls: "content-element-cls",
					style: "color:#dddddd",
					picker: {
						itemIconTpl(record: any): any {
							const { color } = record
							let style = ""
							// Named colors are applied using CSS
							//cls = DomHelper.isNamedColor(eventColor) ? ` b-sch-foreground-${eventColor}` : '',
							// CSS style color is used as is
							if (color && color === "#ffffff") {
								style = ' style="color:#dddddd"'
							} else {
								style = color ? ` style="color:${color}"` : ""
							}
							return `<div class="b-icon b-icon-square$"${style}></div>`
						},
					},

					// We want the ChipView to scroll horizontally with no wrapping.
					chipView: {
						itemTpl: (resource: any): string => `${resource.name}`,
						scrollable: {
							overflowX: "hidden-scroll",
							overflowY: false,
						},
						style: {
							flexWrap: "wrap",
						},
					},
					onSelect({ records }: { records: any }) {
						// @ts-ignore
						const calendar = this.up("calendar") as AWCalendar
						calendar.eventStore.filter({
							id: "laboratoryFilter",
							filterBy: ({ data }: { data: any }) => {
								return records.some((lab: any) => {
									return lab.data.value === data.laboratoryId || data.readOnly
								})
							},
						})
						calendar.trigger("fetchSchedules")
					},
				},
			},
			callOnFunctions: true,
			onExpand(): any {
				// @ts-ignore
				this.up("calendar").trigger("updateSettingSidebar", { hideSidebar: false })
			},
			onCollapse(): any {
				// @ts-ignore
				this.up("calendar").trigger("updateSettingSidebar", { hideSidebar: true })
			},
		}
	}
}
