import { Laboratory, Patient, PrescriberAdvice } from "@audiowizard/common"
import { useEffect, useState } from "react"
import { isTeletransInvoiceNotDuplicate } from "../utilsFSV"
import { toast } from "react-toastify"

export function useFindLastAdviseWithError({
	isOpen,
	patient,
	laboratory,
	noDuplicateCheck,
}: {
	isOpen: boolean
	patient: Patient
	laboratory: Laboratory
	noDuplicateCheck: boolean
}): [PrescriberAdvice | undefined, string[]] {
	const [errors, setErrors] = useState<string[]>([])
	const [advice, setAdvice] = useState<PrescriberAdvice>()

	useEffect(() => {
		if (!isOpen) return
		const advices = (patient?.advices ?? []).sort(
			(a, b) => new Date(b.dateOfAdvice ?? "").getTime() - new Date(a.dateOfAdvice ?? "").getTime()
		)
		const advice = advices?.[0] || null
		setAdvice(advice)

		if (!patient?.idFSV) {
			setErrors((old) => [
				...old,
				"Veuillez lire la carte vitale du patient pour activer la télétransmission afin de réaliser la télétransmission de contrôle",
			])
		}

		if (!advice?.prescriber) {
			setErrors((old) => [...old, "Le patient n'a pas de prescripteur"])
		}
		if (advice?.prescriber && !advice?.prescriber?.rpps) {
			setErrors((old) => [...old, "Le RPPS du prescripteur est manquant"])
		}
		if (advice?.prescriber && !advice?.prescriber?.finess) {
			setErrors((old) => [...old, "Le FINESS du prescripteur est manquant"])
		}
		if (!laboratory?.finess)
			setErrors((old) => [
				...old,
				"Le numéro finess du laboratoire est manquant, veuillez l'ajouter pour procéder à la télétransmission de contrôle.",
			])

		if (noDuplicateCheck)
			isTeletransInvoiceNotDuplicate(patient.id, false)
				.then((isNoDuplicate) => {
					if (!isNoDuplicate) {
						setErrors((old) => [
							...old,
							"Une télétransmission de suivi en attente existe déjà pour ce patient.",
						])
					}
				})
				.catch((error) => toast.error(error))
		return () => {
			setErrors([])
			setAdvice(undefined)
		}
	}, [patient?.advices, isOpen])

	return [advice, errors]
}
