import { User } from "@audiowizard/common"
import { useContext, useEffect, useState } from "react"
import API from "services/API"
import AuthContext from "../../contexts/AuthContext"
import ModalPlageHorraire from "./Modal.PlageHorraire"
import useCustomTitle from "components/Hooks/useTitle"
import { Card, Col, Row } from "reactstrap"
import SectionHeader from "components/commons/SectionHeader/SectionHeader"
import SelectWithIcon from "components/commons/Select/SelectWithIcon"
import useHasRole from "../../components/Hooks/useHasRole"

function PresenceLaboratoire(): JSX.Element {
	useCustomTitle("Profil | Présence aux laboratoires")
	const { user, laboratories, laboratory } = useContext(AuthContext)
	const [team, setTeam] = useState<User[]>([])
	const [selectedUser, setSelectedUser] = useState<User | undefined>(user)
	const [selectedLaboratoryId, setSelectedLaboratoryId] = useState<number | undefined>(laboratory?.id)
	const isManager = useHasRole("ROLE_MANAGER")

	useEffect(() => {
		if (!laboratory?.id && user?.laboratories?.length) {
			setSelectedLaboratoryId(user.laboratories[0].id!)
		}
		;(async () => {
			const team = await API.findAll<User[]>("USERS_API", "?pagination=false")
			setTeam(team)
		})()
	}, [])

	return (
		<>
			<div>
				{laboratories.length === 0 ? (
					<div className="col-12 text-center">
						<h2>
							Aucun laboratoire enregistré
							<span role="img" aria-label="wizards">
								🧙‍♂️🧙‍♀️
							</span>
						</h2>
					</div>
				) : (
					<Col>
						<SectionHeader title="Gestion des plannings" />
						<Row className="my-4">
							<Col xs="6" sm="6" lg="4" style={{ whiteSpace: "nowrap" }}>
								<h6>Les jours de présence de</h6>
								<SelectWithIcon
									icon="user-md"
									options={team.map((opt) => ({
										value: opt.id + "",
										label: opt.firstName + " " + opt.lastName,
									}))}
									placeholder="Sélectionner un audioprothésiste"
									className="form-control form-control-sm"
									value={selectedUser?.id + ""}
									onChange={(e) => {
										setSelectedUser(team.find((x) => x.id === +e.target.value))
									}}
									disabled={!isManager}
								/>
							</Col>
							<Col xs="6" sm="6" lg="4" style={{ whiteSpace: "nowrap" }}>
								<h6>Les jours de présence au laboratoire</h6>
								<SelectWithIcon
									icon="hospital"
									className="form-control form-control-sm"
									options={user.laboratories?.map((lab) => ({
										value: lab.id + "",
										label: lab.label + "",
									}))}
									value={selectedLaboratoryId + ""}
									onChange={(e) => {
										setSelectedLaboratoryId(+e.target.value)
									}}
								/>
							</Col>
						</Row>
						<Card>
							<div style={{ overflow: "auto" }} className="laboratory-attendance-selector">
								<ModalPlageHorraire laboratoryId={selectedLaboratoryId} user={selectedUser} />
							</div>
						</Card>
					</Col>
				)}
			</div>
		</>
	)
}

export default PresenceLaboratoire
